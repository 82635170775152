import NumberFormat from 'react-number-format';

import './FormattedMetricValue.scss';

interface IProps {
    prefix?: string;
    suffix?: string;
    metric: string;
    value: number;
}

const FormattedMetricValue = ({ metric, value, prefix, suffix }: IProps) => {
    switch (metric) {
        case 'net_sales':
        case 'gross_sales':
        case 'total_sales':
        case 'transaction_average':
        case 'order_average':
        case 'guest_check_average':
        case 'commission':
            return (
                <NumberFormat
                    value={value}
                    displayType="text"
                    thousandSeparator
                    prefix={prefix || '$'}
                    suffix={suffix}
                    decimalScale={2}
                    fixedDecimalScale
                />
            );
        case 'transactions':
        case 'transaction_count':
        case 'total_units':
            return (
                <NumberFormat
                    value={value}
                    displayType="text"
                    thousandSeparator
                    prefix={prefix}
                    suffix={suffix}
                    decimalScale={0}
                    fixedDecimalScale
                />
            );
        case 'percent_of_total_sales':
            return (
                <NumberFormat
                    value={value}
                    displayType="text"
                    prefix={prefix}
                    suffix={suffix ?? '%'}
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                />
            );
        default:
            return (
                <NumberFormat
                    value={value}
                    displayType="text"
                    prefix={prefix}
                    suffix={suffix}
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                />
            );
    }
};

export default FormattedMetricValue;
