import { FC } from 'react';

import Button from '@components/buttons/Button';
import { AddCircleOutline as CircleIcon } from '@components/icons';

import Intercom from '@helpers/Intercom';

import './AddSparkBrandButton.scss';

export const AddSparkBrandButton: FC = () => {
    return (
        <Button
            disableHover
            className="add-spark-brands-button"
            startIcon={<CircleIcon fontSize="inherit" />}
            onClick={() => Intercom.open()}
        >
            Add Brand
        </Button>
    );
};
