import { FC } from 'react';
import 'react-dates/initialize';

import SettingsTitle from '@views/admin/components/SettingsTitle';

import {
    DO_NOT_HAVE_PERMISSIONS_MESSAGE,
    employeeEarningsExportDisplayColumnNames,
} from '@sparkplug/lib';

import { ExportSentNotice } from '@features/exports/components/ExportSentNotice';

import Form from '@components/form/Form';
import AppView from '@components/layout/AppView';
import PageHeader from '@components/layout/PageHeader';
import Paper from '@components/layout/Paper';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { IAccount } from '@app/types/AccountsTypes';

import { EmployeeEarningsConfirmationModal } from './EmployeeEarningsConfirmationModal';
import { useEmployeeEarningsFormState } from './useEmployeeEarningsFormState';

import './EmployeeEarningsExportView.scss';

export const EMPLOYEE_EARNINGS_DESCRIPTION =
    `Choose from the following options to export a list of employees and the cash amounts earned through SparkPlug.` as const;

interface EmployeeEarningsExportViewProps {
    account: IAccount;
    userEmail: string;
    userCanExport: boolean;
}
export const EmployeeEarningsExportView: FC<EmployeeEarningsExportViewProps> = ({
    account,
    userEmail,
    userCanExport,
}) => {
    const {
        // state
        startDate,
        endDate,
        confirmationModalIsVisible,
        exportSent,
        isInitiatingExport,

        // functions
        updateDateRange,
        isDayOutOfRange,
        openConfirmationModal,
        closeConfirmationModal,
        initiateExport,
    } = useEmployeeEarningsFormState({ account });

    const coreEarningsPage = (
        <>
            <p className="export-page-description">{EMPLOYEE_EARNINGS_DESCRIPTION}</p>

            <Form className="employee-earnings-export-form">
                <Form.DateRangePicker
                    id="employee-earnings-export-date-range"
                    className="export-date-range-picker"
                    label="Export date range"
                    required
                    dateStart={startDate}
                    dateEnd={endDate}
                    onApply={updateDateRange}
                    isOutsideRange={isDayOutOfRange}
                    variant="outlined"
                    color="neutral"
                    disabled={isInitiatingExport}
                />

                <section className="export-headers">
                    <h3>Export columns:</h3>
                    <p>{employeeEarningsExportDisplayColumnNames}</p>
                </section>

                <Form.Button
                    className="export-button"
                    color="blue"
                    variant="filled"
                    disabled={!userCanExport || isInitiatingExport}
                    tooltip={!userCanExport ? DO_NOT_HAVE_PERMISSIONS_MESSAGE : undefined}
                    tooltipWrapperClassName="export-button-tooltip-wrapper"
                    onClick={openConfirmationModal}
                >
                    Export
                </Form.Button>
            </Form>
        </>
    );

    return (
        <>
            <Paper className="employee-earnings-export-view">
                <PageHeader
                    title={[
                        {
                            title: 'Export',
                            url: `/${account._id}/settings/export`,
                        },
                        'Employee Cash Earnings',
                    ]}
                    metaTitle="Export Earnings"
                />

                {exportSent ? <ExportSentNotice /> : coreEarningsPage}
            </Paper>

            <EmployeeEarningsConfirmationModal
                isVisible={confirmationModalIsVisible}
                userEmail={userEmail}
                onInitiateExport={initiateExport}
                onClose={closeConfirmationModal}
            />
        </>
    );
};

export default () => {
    const { account, userCan } = useSparkplugAccount();
    const { user } = useApp();

    if (!account) {
        return <></>;
    }

    return (
        <AppView.Content title={<SettingsTitle label="Export Data" />}>
            <EmployeeEarningsExportView
                account={account}
                userEmail={user?.email ?? ''}
                userCanExport={userCan('accessBillingPortal') && account?.type === 'retailer'}
            />
        </AppView.Content>
    );
};
