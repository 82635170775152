import { useMemo } from 'react';

import { Emoji } from 'emoji-mart';
import moment from 'moment';

import { Spark } from '@sparkplug/lib';

import Paper from '@components/layout/Paper';
import SparkStatusChip from '@components/sparks/SparkStatusChip';

import { formatSparkInfo } from '@helpers/sparks';

import './CompressedSparkCard.scss';

const CompressedSparkCard = ({ spark }: { spark: Spark }) => {
    const { emoji = 'trophy', startDate, endDate, requestState, name, recurringSchedule } = spark;

    const { status } = useMemo(
        () => formatSparkInfo(startDate, endDate, requestState, recurringSchedule),
        [spark],
    );

    const dateFormatter = 'MMM Do YYYY';
    const formattedStartDate = moment(startDate).format(dateFormatter);
    const formattedEndDate = moment(endDate).format(dateFormatter);

    return (
        <Paper className="compressed-spark-card__container">
            <Emoji emoji={emoji} set="apple" size={19} />
            <section className="compressed-spark-card__stats">
                <h3>{name}</h3>
                <span>{`${formattedStartDate} - ${formattedEndDate}`}</span>
            </section>
            <SparkStatusChip dense={false} status={status} />
        </Paper>
    );
};

export default CompressedSparkCard;
