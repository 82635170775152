import MuiLinearProgress from '@mui/material/LinearProgress';

import { appendClasses } from '@helpers/ui';

import { TSparkStatusColor } from '@app/types/SparksTypes';

import './LinearProgress.scss';

type TLinearProgressProps = {
    className?: string | null;
    color?: TSparkStatusColor;
    size?: 'sm' | 'md' | 'lg';
    value: number;
    variant?: 'buffer' | 'determinate' | 'indeterminate' | 'query';
};

function LinearProgress({
    className = null,
    color = 'blue',
    value,
    size = 'md',
    variant = 'determinate',
    ...remainingProps
}: TLinearProgressProps) {
    const classNamesAppended = appendClasses([
        'linear-progress',
        className,
        `linear-progress-color-${color}`,
        `linear-progress-size-${size}`,
    ]);

    return (
        <MuiLinearProgress
            className={classNamesAppended}
            value={value}
            variant={variant}
            {...remainingProps}
        />
    );
}

export default LinearProgress;
