import { ChangeEventHandler, FC, MutableRefObject, useRef, useState } from 'react';

import { FormControl, Input } from '@mui/material';

import Button from '@components/buttons/Button';
import { Add as AddIcon, AddImageIcon, UploadIcon } from '@components/icons';
import InputLabel from '@components/inputs/InputLabel';
import Modal, { IDefaultModalProps } from '@components/overlays/Modal';
import toast from '@components/toast';

import { useFileChange } from '@hooks/UploadHooks';

import { appendClasses } from '@helpers/ui';

import './ImageField.scss';

interface ImageUploadInputProps {
    inputRef: MutableRefObject<HTMLInputElement | undefined>;
    onChange: ChangeEventHandler<HTMLInputElement>;
}

const ImageUploadInput: FC<ImageUploadInputProps> = ({ inputRef, onChange }) => {
    const onClick = () => {
        if (inputRef.current != null) {
            inputRef.current.click();
        }
    };

    return (
        <>
            <Input
                inputRef={inputRef}
                type="file"
                style={{ display: 'none' }}
                onChange={onChange}
            />
            <Button startIcon={<UploadIcon />} onClick={onClick}>
                Upload Image
            </Button>
        </>
    );
};

interface ImageUploadModalProps extends ImageUploadInputProps, IDefaultModalProps {}

const ImageUploadModal: FC<ImageUploadModalProps> = ({
    inputRef,
    isVisible,
    onChange,
    onClose,
}) => {
    return (
        <Modal isVisible={isVisible} onClose={() => onClose(false)}>
            <Modal.Content>
                <ImageUploadInput inputRef={inputRef} onChange={onChange} />
            </Modal.Content>
        </Modal>
    );
};

interface ImageFieldProps {
    className?: string;
    label?: string;
    required?: boolean;
    variant?: 'outlined' | 'smooth';
    btnLabel: string;
    btnLocation?: 'modal' | 'bottom';
    defaultValue?: string;
    onChange: (value: File) => void;
}

const ImageField = ({
    className,
    btnLabel,
    btnLocation = 'modal',
    variant = 'outlined',
    label,
    required,
    defaultValue,
    onChange,
}: ImageFieldProps) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [imgUrl, setImgUrl] = useState<string | undefined>(defaultValue);
    const inputRef = useRef<HTMLInputElement>();

    const { handleFileChange } = useFileChange();

    const classNamesAppened = appendClasses([
        className,
        'form-control',
        'form-control-custom',
        'image-field',
        imgUrl ? 'has-image' : '',
        `image-field-variant-${variant}`,
    ]);

    const onImageChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        const { fileError } = handleFileChange(event);
        if (fileError) {
            setModalOpen(false);
            toast.error(fileError);
            return;
        }
        const file = event.target.files?.[0];

        if (file) {
            const newUrl = URL.createObjectURL(file);
            setImgUrl(newUrl);
            onChange(file);
        }

        setModalOpen(false);
    };

    const onAddClick = () => {
        if (btnLocation === 'modal') {
            setModalOpen(true);
        } else if (inputRef.current) {
            inputRef.current.click();
        }
    };

    return (
        <>
            <FormControl className={classNamesAppened}>
                {label && (
                    <InputLabel shrink required={required}>
                        {label}
                    </InputLabel>
                )}
                <Button
                    className="image-field_add-button"
                    color="neutral"
                    variant="outlined"
                    onClick={onAddClick}
                >
                    {imgUrl ? (
                        <div className="image-field_img-wrapper">
                            <img src={imgUrl} alt={label} />
                        </div>
                    ) : (
                        <div className="image-field_add-button-inner">
                            {variant === 'outlined' ? (
                                <>
                                    <AddIcon />
                                    <span>{btnLabel}</span>
                                </>
                            ) : (
                                <div className="image-field_add-icon-wrapper">
                                    <AddImageIcon />
                                </div>
                            )}
                        </div>
                    )}
                </Button>
                {btnLocation === 'bottom' && (
                    <div className="image-field_bottom-upload-button">
                        <ImageUploadInput inputRef={inputRef} onChange={onImageChange} />
                    </div>
                )}
            </FormControl>

            <ImageUploadModal
                inputRef={inputRef}
                isVisible={modalOpen}
                onChange={onImageChange}
                onClose={() => {
                    setModalOpen(false);
                }}
            />
        </>
    );
};

export default ImageField;
