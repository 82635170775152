import axios from 'axios';

import {
    AccountVendorRetailerRequestPathParams,
    GetAccountVendorRetailerPosDataResponseBody,
    SparksViewFilters,
} from '@sparkplug/lib';

export default class AccountsAPI {
    static async getVendorRetailerPosData({
        groupId,
        retailerAccountId,
    }: AccountVendorRetailerRequestPathParams) {
        return (
            await axios.get<GetAccountVendorRetailerPosDataResponseBody>(
                `/api/v1/accounts/${groupId}/vendor-retailers/${retailerAccountId}/pos-data`,
            )
        ).data;
    }

    static async requestAllSparksReport(accountId: string) {
        return (
            await axios.post<void>(`/api/v1/accounts/all-sparks-report`, {
                accountId,
            })
        ).data;
    }

    static async requestSparksReport(accountId: string, filters?: SparksViewFilters) {
        return (
            await axios.post<void>(`/api/v1/accounts/sparks-report`, {
                accountId,
                filters,
            })
        ).data;
    }
}
