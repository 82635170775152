import { useMemo } from 'react';

import { Spark, SparkGoal, SparkMetric } from '@sparkplug/lib';

import { SparkTemplate } from '@features/spark-dashboard/types';

import { AwardBlockProps } from './SparkAwardsPanel';

const formatThreshold = ({ goal, metric }: { goal: SparkGoal; metric?: SparkMetric }): string => {
    const thresholdPreFormatting = goal?.threshold;
    const unitText = thresholdPreFormatting && thresholdPreFormatting === 1 ? 'unit' : 'units';

    if (metric === 'percent_of_total_sales') {
        return `${new Intl.NumberFormat().format(thresholdPreFormatting)}% of total sales`;
    }

    const threshold =
        metric === 'total_units'
            ? `${new Intl.NumberFormat().format(thresholdPreFormatting)} ${unitText}`
            : `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                  thresholdPreFormatting,
              )}`;
    return threshold;
};

const useOrderedAwardBlock = (spark: Spark | SparkTemplate): AwardBlockProps[] => {
    const { awards = [], goals = [], metric, type: sparkType } = spark;

    return useMemo(() => {
        return sparkType === 'goal'
            ? goals.map((goal, i) => {
                  const fulfilledBySparkplug = spark.fulfillmentTypes?.[i] === 'sparkplug';
                  const threshold = formatThreshold({ goal, metric });
                  return {
                      award: goal?.award,
                      fulfilledBySparkplug,
                      isUnlimited: goal?.isUnlimited,
                      threshold,
                      sparkType: 'goal',
                      blockCount: i + 1,
                  };
              })
            : awards.map((award, i) => {
                  const fulfilledBySparkplug = spark.fulfillmentTypes?.[i] === 'sparkplug';
                  return {
                      award,
                      fulfilledBySparkplug,
                      sparkType: 'leaderboard',
                      blockCount: i + 1,
                  };
              });
    }, [goals, awards]);
};

export default useOrderedAwardBlock;
