import { FC } from 'react';

import { AccountLink } from '@sparkplug/lib';

import LinkLabel from '@features/account-links/components/LinkLabel';

import Skeleton from '@components/layout/Skeleton';

import './RetailerLinkDetails.scss';

export interface RetailerAccountLink extends AccountLink {
    allLocations?: number;
}

interface RetailerLinkDetailsProps {
    accountLink?: RetailerAccountLink;
}

const RetailerLinkDetails: FC<RetailerLinkDetailsProps> = ({ accountLink }) => {
    const allLocations = accountLink?.allLocations;
    const locationCount = (accountLink?.locations || []).length;
    const locationLabel = (allLocations ?? locationCount) === 1 ? 'Location' : 'Locations';

    return (
        <div className="link-label_tagging" data-testid="account-link-label">
            {accountLink ? (
                <LinkLabel
                    imageUrl={accountLink.photo}
                    title={accountLink.accountName}
                    subtitle={
                        <>
                            {accountLink.allLocations
                                ? `${locationCount} of ${accountLink.allLocations} ${locationLabel}`
                                : `${locationCount} ${locationLabel}`}
                            <div className="markets">{accountLink.markets.join(',')}</div>
                        </>
                    }
                />
            ) : (
                <Skeleton height={50} />
            )}
        </div>
    );
};

export default RetailerLinkDetails;
