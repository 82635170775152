import axios from 'axios';

import {
    GetAllLearningResourceQueryParams,
    ILearningResource,
    PagedApiResponseBody,
} from '@sparkplug/lib';

import { useAdvancedQuery } from '@hooks/QueryHooks';

const API = {
    getAllCourses: async ({
        limit = '10',
        offset = '0',
        order = 'asc',
        status,
        accountId,
        brandId,
    }: GetAllLearningResourceQueryParams) => {
        return (
            await axios.get<PagedApiResponseBody<ILearningResource>>('/api/v1/learning-resource', {
                params: {
                    limit,
                    offset,
                    order,
                    status,
                    accountId,
                    brandId,
                },
            })
        ).data;
    },
};

export const coursesCacheKey = 'courses';

export const useGetAllCoursesQuery = (params: GetAllLearningResourceQueryParams) => {
    const { data, isLoading, error } = useAdvancedQuery(
        [coursesCacheKey, params],
        () => API.getAllCourses(params),
        {
            refetchOnMount: true,
            enabled: !!params.limit || !!params.offset,
        },
    );

    return {
        courses: data?.data ?? [],
        meta: data?.meta,
        isLoadingCourses: isLoading,
        coursesError: error,
    };
};

export const useGetAllAccountCourses = (params: GetAllLearningResourceQueryParams) => {
    const { data, isLoading, error } = useAdvancedQuery(
        [coursesCacheKey, params],
        () => API.getAllCourses(params),
        {
            refetchOnMount: true,
            enabled: !!params.limit || !!params.offset || !!params.accountId,
        },
    );

    return {
        courses: data?.data ?? [],
        meta: data?.meta,
        isLoadingCourses: isLoading,
        coursesError: error,
    };
};
