import { ChangeEvent, useEffect, useReducer } from 'react';

import UploadsAPI from '@api/UploadsAPI';

import { UPLOAD_FOLDERS } from '@sparkplug/lib';

const MAX_FILE_SIZE_BYTES = 1000000; // 1 MB

type FileState = {
    fileError: string | null;
    fileName: string | null;
    fileSize: number | null;
    fileType: string | null;
    fileContents: File | null;
};

type FileChangeAction =
    | {
          type: 'FILE_CHANGE_SUCCESS';
          fileName: string;
          fileSize: number;
          fileType: string;
          fileContents: File;
      }
    | { type: 'FILE_CHANGE_FAILURE'; fileError: string }
    | { type: 'RESET_FILE_STATE' };

const initialFileState = {
    fileError: null,
    fileName: null,
    fileSize: null,
    fileType: null,
    fileContents: null,
};

function bytesToMb(bytes: number) {
    const mb = bytes / 1000000;
    return mb;
}

export function fileChangeReducer(_state: FileState, action: FileChangeAction): FileState {
    switch (action.type) {
        case 'FILE_CHANGE_SUCCESS': {
            return {
                fileError: null,
                fileName: action.fileName,
                fileSize: action.fileSize,
                fileType: action.fileType,
                fileContents: action.fileContents,
            };
        }
        case 'FILE_CHANGE_FAILURE': {
            return {
                ...initialFileState,
                fileError: action.fileError,
            };
        }
        case 'RESET_FILE_STATE': {
            return initialFileState;
        }
        default: {
            throw new Error(`Unsupported action type: ${JSON.stringify(action)}`);
        }
    }
}

export function useFileChange() {
    const [{ fileError, fileContents, fileName, fileSize, fileType }, fileDispatch] = useReducer(
        fileChangeReducer,
        initialFileState,
    );

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return {
                type: 'FILE_CHANGE_FAILURE',
                fileError: 'No file provided for upload.',
            };
        }

        const [type, ext] = fileObj.type.split('/');
        if (!type || type !== 'image') {
            return {
                type: 'FILE_CHANGE_FAILURE',
                fileError: 'You can only upload image files.',
            };
        }

        if (!ext || !['jpg', 'jpeg', 'png', 'gif'].includes(ext)) {
            return {
                type: 'FILE_CHANGE_FAILURE',
                fileError: 'Image must be in jpg, jpeg, png, or gif format.',
            };
        }

        if (fileObj.size > MAX_FILE_SIZE_BYTES) {
            return {
                type: 'FILE_CHANGE_FAILURE',
                fileError: `File is too large, file size is ${bytesToMb(fileObj.size).toFixed(
                    2,
                )} MB, maximum allowed size - 1 MB.`,
            };
        }

        return {
            type: 'FILE_CHANGE_SUCCESS',
            fileName: fileObj.name,
            fileSize: fileObj.size,
            fileType: fileObj.type,
            fileContents: fileObj,
        };
    };

    return {
        fileError,
        fileContents,
        fileName,
        fileType,
        fileSize,
        handleFileChange,
        fileDispatch,
    };
}

async function uploadAccountLogo(accountId: string, file: any) {
    const data = await UploadsAPI.getPresignedUploadUrl({
        groupId: accountId,
        folder: UPLOAD_FOLDERS.ACCOUNT,
    });

    return UploadsAPI.upload(data, file);
}

export const useAccountLogoUpload = (accountId: string, autoUpload: boolean = false) => {
    const { fileContents, handleFileChange } = useFileChange();

    const upload = (file: any) => {
        return uploadAccountLogo(accountId, file);
    };

    useEffect(() => {
        if (autoUpload) {
            upload(fileContents);
        }
    }, [fileContents]);

    return {
        handleFileChange,
        upload,
    };
};
