import { useMemo } from 'react';

import { AccountLink } from '@sparkplug/lib';

import { useAccountLinks, useSuggestedAccountLinks } from '../../queries/BrandLinkQueries';

const useNewVendorLink = ({
    retailerAccountId,
    vendorAccountId,
    isEnabled,
}: {
    retailerAccountId: string;
    vendorAccountId: string;
    isEnabled: boolean;
}) => {
    const { isLoadingSuggestedAccountLinks, suggestedAccountLinks } = useSuggestedAccountLinks(
        retailerAccountId,
        isEnabled,
    );
    const suggestedAccount = suggestedAccountLinks.find(
        ({ accountId }) => accountId === vendorAccountId,
    );

    const vendorLink: AccountLink | undefined =
        suggestedAccount?.accountType === 'brand' ? suggestedAccount : undefined;

    return {
        vendorLinkIsReady: !isLoadingSuggestedAccountLinks,
        vendorLink,
    };
};

const useExistingVendorLink = ({
    retailerAccountId,
    vendorAccountId,
}: {
    retailerAccountId: string;
    vendorAccountId: string;
}) => {
    const { accountLinksAreReady, accountLinks, isRefetchingAccountLinks } =
        useAccountLinks(retailerAccountId);

    const existingVendorLink = useMemo(() => {
        return accountLinks?.find(({ accountId }) => vendorAccountId === accountId);
    }, [vendorAccountId, retailerAccountId, accountLinks]);

    const existingVendorLinkIsReady = useMemo(() => {
        const isRefetchingUndefined = !existingVendorLink && isRefetchingAccountLinks;
        return !isRefetchingUndefined && accountLinksAreReady;
    }, [accountLinksAreReady, isRefetchingAccountLinks, existingVendorLink]);

    return {
        existingVendorLinkIsReady,
        existingVendorLink,
    };
};

export const useCurrentVendorLink = ({
    retailerAccountId,
    vendorAccountId,
}: {
    retailerAccountId: string;
    vendorAccountId: string;
}): {
    vendorLinkIsReady: boolean;
    vendorLink?: AccountLink;
} => {
    const { existingVendorLinkIsReady, existingVendorLink } = useExistingVendorLink({
        retailerAccountId,
        vendorAccountId,
    });

    const shouldUseNewVendorLink = existingVendorLinkIsReady && !existingVendorLink;

    const { vendorLinkIsReady: newVendorLinkIsReady, vendorLink: newVendorLink } = useNewVendorLink(
        {
            retailerAccountId,
            vendorAccountId,
            isEnabled: shouldUseNewVendorLink,
        },
    );

    if (shouldUseNewVendorLink) {
        return {
            vendorLinkIsReady: newVendorLinkIsReady,
            vendorLink: newVendorLink,
        };
    }

    return { vendorLinkIsReady: existingVendorLinkIsReady, vendorLink: existingVendorLink };
};
