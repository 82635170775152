import { ComponentProps, ReactElement } from 'react';

import { Chip as MaterialUIChip, SxProps } from '@mui/material';
import { clsx } from 'clsx';

import { Close } from '@components/icons';

import { TStandardColors } from '@app/types/UITypes';

import './Chip.scss';

export type TChipVariant = 'flat' | 'outlined';

export interface IChipProps {
    className?: string;
    color: TStandardColors;
    variant?: TChipVariant;
    avatar?: ReactElement;
    icon?: ReactElement;
    label: string | ReactElement | null;
    onDelete?: () => void;
    onClick?: () => void;
    dense?: boolean;
    disabled?: boolean;
    sx?: SxProps;
}

const Chip = ({
    className = '',
    color = 'blue',
    variant = 'flat',
    avatar,
    icon,
    label,
    onDelete,
    onClick,
    dense = false,
    disabled = false,
    sx,
}: IChipProps) => {
    const props: ComponentProps<typeof MaterialUIChip> = {
        avatar,
        icon,
        label,
        onClick,
        disabled,
        clickable: onClick != null || onDelete != null,
        deleteIcon: onDelete ? (
            <div className="chip-delete-icon">
                <Close />
            </div>
        ) : undefined,
        onDelete,
        sx,
    };

    return (
        <MaterialUIChip
            className={clsx(
                className,
                'chip',
                `chip-color-${color}`,
                `chip-variant-${variant}`,
                label == null && 'chip-label-empty',
                dense && `chip-dense`,
                !onClick && 'no-hover',
            )}
            {...props}
        />
    );
};

export default Chip;
