import axios from 'axios';

import {
    DeleteNotificationPathParams,
    GetSparkResponseNotificationPathParams,
    GetSparkResponseNotificationResponseBody,
    ListNotificationsQueryParams,
    ListNotificationsResponseBody,
    Notification,
    UpdateNotificationPathParams,
    UpdateNotificationRequestBody,
} from '@sparkplug/lib';

import factory from '../log/Log';

const log = factory('NotificationsAPI');

export default class NotificationsAPI {
    static async getNotifications(
        params: ListNotificationsQueryParams,
    ): Promise<ListNotificationsResponseBody> {
        return (await axios.get(`/api/v1/notifications`, { params })).data;
    }

    static async getAllNotifications(
        userId: string,
        archived: boolean = false,
        read: boolean = true,
    ): Promise<Pick<ListNotificationsResponseBody, 'data'>> {
        let data: Notification[] = [];
        try {
            const limit = 1000;
            let offset = 0;

            let batch: Notification[] = [];

            do {
                const res = await NotificationsAPI.getNotifications({
                    user_id: userId,
                    offset,
                    limit,
                    archived,
                    read,
                });
                batch = res.data;

                data = data.concat(batch);
                offset += limit;
            } while (batch.length + 1 >= limit);
        } catch (err) {
            log.e(`getAllNotifications: ${err}`);
            throw err;
        }
        return { data };
    }

    static async updateNotification(
        { notificationId }: UpdateNotificationPathParams,
        body: UpdateNotificationRequestBody,
    ): Promise<void> {
        return axios.put(`/api/v1/notifications/${notificationId}`, body);
    }

    static async deleteNotification({
        notificationId,
    }: DeleteNotificationPathParams): Promise<void> {
        return axios.delete(`/api/v1/notifications/${notificationId}`);
    }

    static async getSparkResponseNotification({
        sparkId,
    }: GetSparkResponseNotificationPathParams): Promise<GetSparkResponseNotificationResponseBody> {
        return (await axios.get(`/api/v1/notifications/spark/response/${sparkId}`)).data;
    }
}
