import parsePhoneNumber from 'libphonenumber-js';

export const transformPhoneNumber = (value?: string) => {
  if (!value) {
    return undefined;
  }
  let phoneNumber = parsePhoneNumber(value);
  if (!phoneNumber) {
    phoneNumber = parsePhoneNumber(value, 'US');
  }
  if (phoneNumber) {
    if (!phoneNumber.isPossible()) {
      return 'invalid';
    }
    return phoneNumber.number as string;
  }
  return undefined;
};
