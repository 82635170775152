import { FC } from 'react';

import Button from '@components/buttons/Button';

interface ClaimButtonProps {}

export const ClaimButton: FC<ClaimButtonProps> = () => {
    return (
        <Button className="rewards-list_claim-button" variant="filled">
            Claim
        </Button>
    );
};
