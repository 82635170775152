import { useState } from 'react';

import { Resizable } from 're-resizable';

import './ResizeWidget.scss';

export const ResizeWidget = () => {
    const [width, setWidth] = useState<number>(1);

    return (
        <div className="resizable-container">
            <Resizable
                size={{ width, height: 30 }}
                onResize={(e, d, r, { width: offsetWidth }) => {
                    setWidth(offsetWidth <= 0 ? 1 : offsetWidth);
                }}
                enable={{
                    top: false,
                    right: true,
                    bottom: false,
                    left: false,
                    topRight: false,
                    bottomRight: false,
                    bottomLeft: false,
                    topLeft: false,
                }}
            />
        </div>
    );
};
