import axios from 'axios';
import { debounce } from 'lodash';

import {
    CashOutResponseBody,
    ClaimRewardPathParams,
    ClaimRewardResponseBody,
    ConfirmNonFbsReceiptEmployeeDepositResponseBody,
    ConfirmNonFbsReceiptRewardPathParams,
    WithdrawalType,
} from '@sparkplug/lib';

import { useApp } from '@hooks/AppHooks';
import { useAdvancedMutation } from '@hooks/QueryHooks';

import { getUserWalletQueryKey } from '../queries';

const API = {
    claimReward: async ({ rewardId }: ClaimRewardPathParams) => {
        return (await axios.post<ClaimRewardResponseBody>(`/api/v1/rewards/${rewardId}/claim`, {}))
            .data.data;
    },
    confirmNonFbsReceipt: async ({ rewardId }: ConfirmNonFbsReceiptRewardPathParams) => {
        return (
            await axios.post<ConfirmNonFbsReceiptEmployeeDepositResponseBody>(
                `/api/v1/rewards/${rewardId}/confirm-non-fbs-receipt`,
                {},
            )
        ).data.data;
    },
    cashOut: async ({ withdrawalType }: { withdrawalType: WithdrawalType }) => {
        return (
            await axios.post<CashOutResponseBody>(`/api/v1/wallet/cash-out`, { withdrawalType })
        ).data.data;
    },
};

export const useClaimRewardMutation = () => {
    const { user } = useApp();

    const { isLoading: isClaimingReward, mutate: claimReward } = useAdvancedMutation(
        (payload: { rewardId: string }) => API.claimReward(payload),
        {
            updateQuery: { queryKey: getUserWalletQueryKey(user?._id!) },
        },
    );

    return { isClaimingReward, claimReward: debounce(claimReward, 300) };
};

export const useConfirmNonFbsReceiptMutation = () => {
    const { user } = useApp();

    const { isLoading: isConfirmingNonFbsReceipt, mutate: confirmNonFbsReceipt } =
        useAdvancedMutation((payload: { rewardId: string }) => API.confirmNonFbsReceipt(payload), {
            updateQuery: { queryKey: getUserWalletQueryKey(user?._id!) },
        });

    return { isConfirmingNonFbsReceipt, confirmNonFbsReceipt: debounce(confirmNonFbsReceipt, 300) };
};

export const useCashOutMutation = () => {
    const { user } = useApp();

    const { isLoading: isCashingOut, mutate: cashOut } = useAdvancedMutation(
        (withdrawalType: WithdrawalType) => API.cashOut({ withdrawalType }),
        {
            updateQuery: { queryKey: getUserWalletQueryKey(user?._id!) },
        },
    );

    return { isCashingOut, cashOut: debounce(cashOut, 300) };
};
