import { useCallback, useState } from 'react';

import { useUpdateUserPasswordUnauthMutation } from '@core/users';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import Paper from '@components/layout/Paper';

import { useApp } from '@hooks/AppHooks';

import { getUrlParameter } from '@helpers/ui';

// import './SetupPasswordView.scss';

const SetupPasswordView = () => {
    const [errorMessage, setErrorMessage] = useState<string | null>();
    const { history } = useApp();

    const userId = getUrlParameter('user_id');
    const code = getUrlParameter('code');
    const email = getUrlParameter('email');

    const [pwd, setPwd] = useState<string>();
    const [pwdCheck, setPwdCheck] = useState<string>();

    const { updateUserPasswordUnauth } = useUpdateUserPasswordUnauthMutation();

    const onSave = useCallback(() => {
        if (userId && code) {
            updateUserPasswordUnauth(
                { userId, code, pwd, pwdCheck },
                {
                    onSuccess: () => {
                        history.push(`/login/setup/complete`);
                    },
                    onError: (err: any) => {
                        setErrorMessage(err.message);
                    },
                },
            );
        }
    }, [pwd, pwdCheck]);

    const goToForgotPassword = () => {
        history.push(`/login/forgot-password`);
    };

    const hasEmail = Boolean(email);

    return (
        <div className="login-view">
            <Paper className="login-container">
                <Paper.Title>Let&apos;s get started</Paper.Title>
                <Form>
                    <Form.Description>
                        <p>Please update your password.</p>
                    </Form.Description>
                    {hasEmail && (
                        <Form.TextField label="Email" name="email" value={email} disabled />
                    )}
                    <Form.TextField
                        required
                        label="New Password"
                        name="password"
                        type="password"
                        onChange={(event) => setPwd(event.target.value)}
                    />
                    <Form.TextField
                        required
                        label="Re-Enter New Password"
                        name="pwdCheck"
                        type="password"
                        onChange={(event) => setPwdCheck(event.target.value)}
                    />
                    {errorMessage != null && errorMessage.length > 0 && (
                        <Form.Description>
                            <p className="error-text">{errorMessage}</p>
                        </Form.Description>
                    )}
                    <Form.ActionGroup className="text-align-center" direction="vertical">
                        <Form.Button color="blue" variant="smooth" onClick={onSave}>
                            Update Password
                        </Form.Button>
                        <Button color="neutral" variant="flat" onClick={goToForgotPassword}>
                            Request new verification code
                        </Button>
                    </Form.ActionGroup>
                </Form>
            </Paper>
        </div>
    );
};

export default SetupPasswordView;
