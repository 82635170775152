import { ComponentType, Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import LocalStorage from '@data/LocalStorage';
import RetailerLoadingView from '@views/auth/RetailerLoadingView';
import { DashboardProvider } from '@views/dashboard/DashboardView/contexts/DashboardContext';
import { isEmpty, uniq } from 'lodash';

import { AccountMarketLabel } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import { Add as AddIcon, KeyboardArrowRight, RetailerIcon } from '@components/icons';
import { SidebarMenuItem } from '@components/layout/AppSidebar';
import ClickableArea from '@components/layout/ClickableArea';

import { useApp, withAppSidebar } from '@hooks/AppHooks';
import {
    useSparkplugAccount,
    useSparkplugBrandRetailer,
} from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { sortByString } from '@helpers/ui';
import { getStateAbbreviationByName } from '@helpers/util';

import { cn } from '@app/componentLibrary/utils';
import { IAccount } from '@app/types/AccountsTypes';

import { useCurrentVendorDashboardMarket } from './hooks/useCurrentVendorDashboardMarket';
import {
    LAST_ACTIVE_RETAILER_LOCAL_STORAGE_KEY,
    useVendorDashboardRedirectHandler,
} from './hooks/useVendorDashboardRedirectHandler';

const AddRetailerButton = () => {
    const { history } = useApp();
    const { account } = useSparkplugAccount();

    if (!account) {
        return <></>;
    }

    return (
        <Button
            color="blue"
            variant="smooth"
            className="dashboardView-add-retailer-button"
            onClick={() => history.push(`/${account._id}/partners/available`)}
            startIcon={<AddIcon />}
        >
            <p>Add Retailer</p>
        </Button>
    );
};

const useDashboardSidebarManager = (
    expandedMarkets: string[],
    setExpandedMarkets: Dispatch<SetStateAction<string[]>>,
) => {
    const { currentMarketAbbreviation } = useCurrentVendorDashboardMarket();
    const { history, userIsSuperAdmin } = useApp();
    const { account, currentUserMarkets, hasInventoryEntitlement } = useSparkplugAccount();
    const { brandRetailer: displayedRetailer, brandRetailerId: retailerIdFromUrl } =
        useSparkplugBrandRetailer();

    const handleMarketToggle = (market: string) => {
        if (expandedMarkets.includes(market)) {
            setExpandedMarkets(
                expandedMarkets.filter((expandedMarket) => expandedMarket !== market),
            );
        } else {
            setExpandedMarkets([...expandedMarkets, market]);
        }
    };

    const handleMarketView = (market: AccountMarketLabel) => {
        if (!account) {
            return;
        }

        const marketAbbreviation = getStateAbbreviationByName(market)?.toLocaleLowerCase();
        history.push(`/${account._id}/dashboard/market/${marketAbbreviation}`);

        setExpandedMarkets(uniq([...expandedMarkets, market]));
    };

    const { REACT_APP_VENDOR_MARKET_DASHBOARD = false } = import.meta.env;

    return useMemo(() => {
        if (!account || !retailerIdFromUrl || !account.markets || !account.retailers) {
            return [];
        }

        const sidebarMarketMap: Record<string, SidebarMenuItem> = Object.fromEntries(
            account.markets.map((market) => {
                return [
                    market,
                    REACT_APP_VENDOR_MARKET_DASHBOARD === 'true'
                        ? {
                              key: market,
                              startIcon: (
                                  <KeyboardArrowRight onClick={() => handleMarketToggle(market)} />
                              ),
                              title: (
                                  <ClickableArea onClick={() => handleMarketView(market)}>
                                      {market}
                                  </ClickableArea>
                              ),
                              children: [],
                              isBold: true,
                              isExpandable: true,
                              isExpanded: expandedMarkets.includes(market),
                              isActive: () =>
                                  Boolean(
                                      currentMarketAbbreviation &&
                                          getStateAbbreviationByName(market) ===
                                              currentMarketAbbreviation,
                                  ),
                          }
                        : {
                              key: market,
                              startIcon: <KeyboardArrowRight />,
                              title: market,
                              children: [],
                              isBold: true,
                              isExpandable: true,
                              isExpanded: expandedMarkets.includes(market),
                              onClick: () => handleMarketToggle(market),
                          },
                ];
            }),
        );

        // Nest retailers under their market
        account.retailers.forEach(({ _id, name, markets }) => {
            if (!isEmpty(markets)) {
                markets
                    .filter((market) => !!sidebarMarketMap[market])
                    .forEach((market) => {
                        const retailerItem: SidebarMenuItem = {
                            key: name,
                            title: name,
                            to: `/${account._id}/dashboard/${_id}`,
                            onClick: () => {
                                document.body.classList.remove('show-brand-retailers');
                                // if we are showing this retailer because of a search, the market dropdown may or may not be expanded already
                                if (!expandedMarkets.includes(market)) {
                                    handleMarketToggle(market);
                                }
                                LocalStorage.set(LAST_ACTIVE_RETAILER_LOCAL_STORAGE_KEY, _id);
                            },
                            isActive: () => {
                                if (currentMarketAbbreviation) {
                                    return false;
                                }

                                // Bugfix: On initial load, the retailerId in the Url is undefined
                                return retailerIdFromUrl
                                    ? retailerIdFromUrl === _id
                                    : displayedRetailer?._id === _id;
                            },
                            searchBy: [market, name],
                        };

                        sidebarMarketMap[market]?.children?.push(retailerItem);
                    });
            }
        });

        const sidebarRetailersGroupedByMarkets = Object.values(sidebarMarketMap)
            .filter((sideBarItem) => {
                return !isEmpty(sideBarItem.children);
            })
            .sort(sortByString('key', 'asc'));

        return [
            {
                key: 'root',
                startIcon: <RetailerIcon className="sidebar-header-icon" />,
                title: 'Retailers',
                children: sidebarRetailersGroupedByMarkets,
                expandedMarkets,
            },
        ];
    }, [account, displayedRetailer, retailerIdFromUrl, currentMarketAbbreviation, expandedMarkets]);
};

const useCurrentVendorRetailer = (): {
    vendorRetailerIsReady?: boolean;
    retailerIdFromUrl?: string;
} => {
    const { brandRetailerId: retailerIdFromUrl } = useParams<{
        brandRetailerId?: string;
    }>();
    const { brandRetailer } = useSparkplugBrandRetailer();
    const vendorRetailerIsReady = brandRetailer?._id === retailerIdFromUrl;

    return {
        vendorRetailerIsReady,
        retailerIdFromUrl,
    };
};

export const withVendorDashboardSidebarManager =
    (DashboardComponent: ComponentType<{ account: IAccount }>) =>
    ({ account }: { account: IAccount }) => {
        const { history } = useApp();
        const { currentMarketAbbreviation, currentMarketLabel } = useCurrentVendorDashboardMarket();
        const { vendorRetailerIsReady, retailerIdFromUrl } = useCurrentVendorRetailer();
        const [expandedMarkets, setExpandedMarkets] = useState<string[]>(
            currentMarketLabel ? [currentMarketLabel] : [],
        );

        useVendorDashboardRedirectHandler({
            account,
            retailerIdFromUrl,
            currentMarketAbbreviation,
            currentMarketLabel,
            setExpandedMarkets,
            handleHistoryPush: history.push,
        });

        const sidebarItems = useDashboardSidebarManager(expandedMarkets, setExpandedMarkets);

        const isEmptyBrand = !account?.retailers?.length;

        const DashboardComponentWithSidebar = withAppSidebar({
            sidebarItems:
                sidebarItems.length > 0
                    ? sidebarItems
                    : [
                          {
                              key: 'root',
                              startIcon: <RetailerIcon className="sidebar-header-icon" />,
                              title: 'Retailers',
                              children: [{ title: 'No Retailer Links', key: 'no-retailer-links' }],
                          },
                      ],
            className: cn('dashboard-sidebar', sidebarItems.length === 0 && 'empty-sidebar'),
            searchable: true,
            footer: <AddRetailerButton />,
            expandedKeys: expandedMarkets,
        })(
            isEmptyBrand || currentMarketAbbreviation || vendorRetailerIsReady
                ? () => <DashboardComponent account={account} />
                : RetailerLoadingView,
        );

        return (
            <DashboardProvider>
                <DashboardComponentWithSidebar />
            </DashboardProvider>
        );
    };
