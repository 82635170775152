import { ComparisonPeriodOptions } from '@constants/ChartConstants';
import moment from 'moment';

import { Spark, TComparisonPeriodOption } from '@sparkplug/lib';

import { sortByDate } from '@helpers/ui';

const _getSparksInRange = ({
    sparks,
    timelineStartDate,
    timelineEndDate,
}: {
    sparks: Spark[];
    timelineStartDate: moment.Moment;
    timelineEndDate: moment.Moment;
}) => {
    const sparksInRange = sparks
        .filter((spark) => {
            const startDateInRange =
                timelineStartDate.isSameOrBefore(spark.startDate) &&
                timelineEndDate.isSameOrAfter(spark.startDate);
            const endDateInRange =
                timelineStartDate.isSameOrBefore(spark.endDate) &&
                timelineEndDate.isSameOrAfter(spark.endDate);
            const overlapsFullRange =
                timelineStartDate.isSameOrAfter(spark.startDate) &&
                timelineEndDate.isSameOrBefore(spark.endDate);

            return startDateInRange || endDateInRange || overlapsFullRange;
        })
        .sort(sortByDate('startDate', 'asc'));

    return sparksInRange;
};

const _organizeSparksInRows = ({ sparksInRange }: { sparksInRange: Spark[] }) => {
    return sparksInRange.reduce((res, spark) => {
        for (let i = 0; i < res.length; i += 1) {
            const row = res[i];
            const lastItem = row[row.length - 1];

            if (moment(spark.startDate).isAfter(lastItem.endDate)) {
                res[i] = [...row, spark];
                return res;
            }
        }

        return [...res, [spark]];
    }, [] as Spark[][]);
};

export const _getSegmentDetails = ({
    spark,
    timelineStartDate,
    timelineEndDate,
}: {
    spark: Spark;
    timelineStartDate: moment.Moment;
    timelineEndDate: moment.Moment;
}) => {
    const sparkStartDate = moment(spark.startDate).startOf('day');
    const sparkEndDate = moment(spark.endDate).startOf('day');

    const timelineDuration = timelineEndDate.diff(timelineStartDate, 'days') + 1;
    const sparkStartDiff = sparkStartDate.diff(timelineStartDate, 'days');
    const sparkEndDiff = sparkEndDate.diff(timelineStartDate, 'days') + 1;

    const startsOutOfRange = sparkStartDate.isBefore(timelineStartDate);
    const endsOutOfRange = sparkEndDate.isAfter(timelineEndDate);

    const sparkstartDatePercentDiff = !startsOutOfRange
        ? (sparkStartDiff / timelineDuration) * 100
        : 0;
    const sparkEndPercentDiff = !endsOutOfRange ? (sparkEndDiff / timelineDuration) * 100 : 100;
    const sparkPercentOverlap = sparkEndPercentDiff - sparkstartDatePercentDiff;

    return {
        spark,
        start: sparkstartDatePercentDiff,
        end: sparkEndPercentDiff,
        width:
            sparkPercentOverlap + sparkstartDatePercentDiff > 100
                ? 100 - sparkstartDatePercentDiff
                : sparkPercentOverlap,
        startsOutOfRange,
        endsOutOfRange,
    };
};

export const getSparkTimelineData = ({
    sparks,
    timelineStartDate: rawTimelineStartDate,
    timelineEndDate: rawTimelineEndDate,
    brandRetailerId,
}: {
    sparks: Spark[];
    timelineStartDate: string;
    timelineEndDate: string;
    brandRetailerId?: string;
}) => {
    if (!brandRetailerId) {
        return [];
    }

    const timelineStartDate = moment(rawTimelineStartDate).startOf('day');
    const timelineEndDate = moment(rawTimelineEndDate).startOf('day');

    const sparksInRange = _getSparksInRange({
        sparks: sparks.filter(
            (spark) =>
                spark.groupId === brandRetailerId &&
                ['accepted', 'none'].includes(spark.requestState as string),
        ),
        timelineStartDate,
        timelineEndDate,
    });

    const sparkTimelineRows = _organizeSparksInRows({ sparksInRange });

    return sparkTimelineRows.map((row) => {
        return row.map((spark) =>
            _getSegmentDetails({
                spark,
                timelineStartDate,
                timelineEndDate,
            }),
        );
    });
};

export const getComparisonPeriodLabel = (comparisonPeriod: TComparisonPeriodOption) => {
    const currentOption = ComparisonPeriodOptions.find(({ value }) => value === comparisonPeriod);
    return currentOption!.label;
};
