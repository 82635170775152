import { AddUserIcon } from '@components/icons';
import Toolbar from '@components/toolbar/Toolbar';

import Typography from '@app/componentLibrary/Typography';
import { IAccount } from '@app/types/AccountsTypes';

export const REFER_CALL_TO_ACTION = "Don't see who you're looking for?";

export interface PartnersHubToolbarProps {
    account?: Pick<IAccount, '_id' | 'type'>;
    condensed?: boolean;
    onClick: () => void;
}
export const PartnersHubToolbar = ({ account, condensed, onClick }: PartnersHubToolbarProps) => {
    const buttonText = account?.type === 'retailer' ? 'Refer Vendor' : 'Refer Retailer';
    return (
        <Toolbar className="flex items-center justify-center ">
            {!condensed && (
                <Typography className="!text-gray-900 font-normal pt-2">
                    {REFER_CALL_TO_ACTION}
                </Typography>
            )}
            <Toolbar.Button
                variant="filled"
                color="blue"
                className="refer-button"
                startIcon={!condensed ? <AddUserIcon /> : undefined}
                onClick={onClick}
            >
                {condensed ? 'Refer' : buttonText}
            </Toolbar.Button>
        </Toolbar>
    );
};
