import axios from 'axios';

import {
    CreateAccountLinkRequestBody,
    CreateBrandLinkV2RequestBody,
    RequestAccountLinkAsVendorBody,
    RespondToAccountLinkRequestBody,
    UpdateAccountLinkRequestBody,
    UpdateBrandLinkV2RequestBody,
} from '@sparkplug/lib';

import { useAdvancedMutation } from '@hooks/QueryHooks';

import { getAccountLinksQueryKey } from '../queries';

interface AccountLinkDetails {
    retailerAccountId: string;
    vendorAccountId: string;
}

interface UpdateBrandLinkParams {
    brandLinkId: string;
    payload: UpdateBrandLinkV2RequestBody;
}

/**
 * @deprecated
 *
 * This is just for mocking the functions and each usage should
 * be replaced with the corresponding shared `/lib` payload
 */
type MockAPIPayload = {};

/**
 * @deprecated
 *
 * This is just for mocking the mutation functions and each usage
 * should be removed and declared on the mutation function params
 */
type MockMutationFnPayload = {};

type CreateMutationFnPayload = {
    retailerAccountId: string;
    vendorAccountId: string;
    body: CreateAccountLinkRequestBody;
};

type UpdateMutationFnPayload = {
    accountId: string;
    vendorAccountId: string;
    body: UpdateAccountLinkRequestBody;
};

const API = {
    sendAccountLinkRequest: async ({ retailerAccountId, vendorAccountId }: AccountLinkDetails) => {
        return axios.post<RequestAccountLinkAsVendorBody>(
            `/api/v1/groups/${vendorAccountId}/account-links/request`,
            {
                retailerAccountId,
            },
        );
    },
    respondToAccountLinkRequest: async (
        payload: RespondToAccountLinkRequestBody,
        retailerAccountId: string,
    ) => {
        const { vendorAccountId, accepted } = payload;
        return axios.post<RespondToAccountLinkRequestBody>(
            `/api/v1/groups/${retailerAccountId}/account-links/respond`,
            { vendorAccountId, accepted },
        );
    },
    createAccountLink: async ({
        retailerAccountId,
        vendorAccountId,
        body,
    }: CreateMutationFnPayload) => {
        return axios.post(
            `/api/v1/groups/${retailerAccountId}/account-links/${vendorAccountId}`,
            body,
        );
    },
    updateAccountLink: async ({ accountId, vendorAccountId, body }: UpdateMutationFnPayload) => {
        return axios.put(`/api/v1/groups/${accountId}/account-links/${vendorAccountId}`, body);
    },

    createBrandLink: async (payload: CreateBrandLinkV2RequestBody) => {
        return axios.post<void>('/api/v1/brand-links/', payload);
    },
    updateBrandLink: async ({
        brandLinkId,
        payload,
    }: {
        brandLinkId: string;
        payload: UpdateBrandLinkV2RequestBody;
    }) => {
        return axios.put<void>(`/api/v1/brand-links/${brandLinkId}`, payload);
    },
    deleteBrandLink: async ({ retailerAccountId, vendorAccountId }: AccountLinkDetails) => {
        return axios.delete(`/api/v1/groups/${retailerAccountId}/account-links/${vendorAccountId}`);
    },
};

export const useSendAccountLinkRequest = (vendorAccountId: string) => {
    const { mutate, mutateAsync, isLoading } = useAdvancedMutation(
        (retailerAccountId: string) =>
            API.sendAccountLinkRequest({ retailerAccountId, vendorAccountId }),
        {
            updateQuery: { queryKey: getAccountLinksQueryKey(vendorAccountId) },
        },
    );

    return {
        isSendingAccountLinkRequest: isLoading,
        sendAccountLinkRequest: mutate,
        sendAccountLinkRequestAsync: mutateAsync,
    };
};

export const useRespondToAccountLinkRequest = (accountId: string) => {
    const { mutate, mutateAsync, isLoading } = useAdvancedMutation(
        (payload: RespondToAccountLinkRequestBody) =>
            API.respondToAccountLinkRequest(payload, accountId),
        {
            updateQuery: { queryKey: getAccountLinksQueryKey(accountId) },
        },
    );

    return {
        isSendingAccountLinkRequest: isLoading,
        respondToAccountLinkRequest: mutate,
        respondToAccountLinkRequestAsync: mutateAsync,
    };
};

export const useCreateAccountLink = (retailerAccountId: string) => {
    const { mutate, mutateAsync, isLoading } = useAdvancedMutation(
        (payload: { vendorAccountId: string; body: CreateAccountLinkRequestBody }) =>
            API.createAccountLink({ retailerAccountId, ...payload }),
        {
            updateQuery: { queryKey: getAccountLinksQueryKey(retailerAccountId) },
        },
    );

    return {
        isCreatingAccountLink: isLoading,
        createAccountLink: mutate,
        createAccountLinkAsync: mutateAsync,
    };
};

export const useUpdateAccountLink = (retailerAccountId: string) => {
    const { mutate, isLoading } = useAdvancedMutation(
        (payload: { vendorAccountId: string; body: UpdateAccountLinkRequestBody }) =>
            API.updateAccountLink({ accountId: retailerAccountId, ...payload }),
        {
            updateQuery: { queryKey: getAccountLinksQueryKey(retailerAccountId) },
        },
    );

    return { isUpdatingAccountLink: isLoading, updateAccountLink: mutate };
};

export const useCreateBrandLink = (accountId: string) => {
    const { mutate, isLoading } = useAdvancedMutation(
        (payload: CreateBrandLinkV2RequestBody) => API.createBrandLink(payload),
        {
            updateQuery: { queryKey: getAccountLinksQueryKey(accountId) },
            toastOptions: {
                loading: 'Saving brand mapping',
                success: 'Brand mapping saved',
                error: 'Error saving brand mapping - please try again and contact support if this issue persists.',
            },
        },
    );

    return { isCreatingBrandLink: isLoading, createBrandLink: mutate };
};

export const useUpdateBrandLink = (accountId: string) => {
    const { mutate, isLoading } = useAdvancedMutation(
        (params: UpdateBrandLinkParams) => API.updateBrandLink(params),
        {
            updateQuery: { queryKey: getAccountLinksQueryKey(accountId) },
            toastOptions: {
                loading: 'Saving brand mapping',
                success: 'Brand mapping saved',
                error: 'Error saving brand mapping - please try again and contact support if this issue persists.',
            },
        },
    );

    return { isUpdatingBrandLink: isLoading, updateBrandLink: mutate };
};

export const useDeleteBrandLink = (retailerAccountId: string) => {
    const { mutate, isLoading } = useAdvancedMutation(
        (vendorAccountId: string) => API.deleteBrandLink({ retailerAccountId, vendorAccountId }),
        {
            updateQuery: { queryKey: getAccountLinksQueryKey(retailerAccountId) },
        },
    );

    return { isDeletingBrandLink: isLoading, deleteBrandLink: mutate };
};
