import { useEffect, useMemo, useState } from 'react';

import { keyBy, pullAt } from 'lodash';

import { ColumnDetail } from '@sparkplug/lib';

export type UpdateColumnVisibilityParams = { value: string; isChecked: boolean };
export type UpdateColumnOrderParams = { sourceIndex: number; destinationIndex: number };

/**
 * A hook used to maintain the internal column settings state for the ColumnSettingsManager
 * while also listening for external changes to props.columns
 */
export const useColumnSettingsState = ({ columns }: { columns: ColumnDetail[] }) => {
    const [orderedColumnValues, setOrderedColumnValues] = useState<string[]>([]);
    const [columnVisibilityByValue, setColumnVisibility] = useState<Record<string, boolean>>({});

    // Combine the orderedColumnValues with the columnVisibilityByValue
    // to create an up-to-date representation of the column settings
    const columnSettings = useMemo(() => {
        const columnsByValue = keyBy(columns, 'value');
        return orderedColumnValues.map((columnValue) => {
            const isChecked = columnVisibilityByValue[columnValue];
            const column = columnsByValue[columnValue];
            return { ...column, isChecked };
        });
    }, [orderedColumnValues, columnVisibilityByValue]);

    const initColumnSettingsState = () => {
        setOrderedColumnValues(columns.map(({ value }) => value));
        setColumnVisibility(
            Object.fromEntries(columns.map(({ value, isChecked }) => [value, isChecked])),
        );
    };

    useEffect(() => {
        initColumnSettingsState();
    }, [columns]);

    return useMemo(() => {
        return {
            columnSettings,

            resetColumnSettings: initColumnSettingsState,

            updateColumnVisibility({ value, isChecked }: UpdateColumnVisibilityParams) {
                setColumnVisibility((prevVisibilityMap) => ({
                    ...prevVisibilityMap,
                    [value]: isChecked,
                }));
            },

            /**
             * Move the column value at sourceIndex from sourceIndex to destinationIndex in orderedColumnValues
             * while maintaining the positions of the rest of orderedColumnValues
             */
            updateColumnOrder({ sourceIndex, destinationIndex }: UpdateColumnOrderParams) {
                setOrderedColumnValues((prevColumnOrder) => {
                    const sourceColumnValue = pullAt(prevColumnOrder, sourceIndex);
                    return [
                        ...prevColumnOrder.slice(0, destinationIndex),
                        sourceColumnValue,
                        ...prevColumnOrder.slice(destinationIndex),
                    ] as string[];
                });
            },
        };
    }, [columns, columnSettings]);
};
