import { fireEvent, screen, within } from '@testing-library/react';

export const getButtonByText = (text: string): HTMLElement => {
    return screen.getByText(text).closest('button') as HTMLElement;
};

export const getTextFieldByName = (name: string): HTMLElement => {
    return screen.getByTestId(`textField.${name}`);
};

export const getCheckboxByName = (
    name: string,
): {
    input: HTMLElement;
    label: HTMLElement;
} => {
    const input = screen.getByTestId(`checkbox.${name}`);
    const label = input.closest('label') as HTMLElement;

    return {
        input,
        label,
    };
};

export const getSelectByName = (name: string): HTMLElement => {
    return screen.getByTestId(`select.${name}`);
};

export const getSwitchByName = (name: string): HTMLElement => {
    return screen.getByTestId(`switch.${name}`);
};

export const querySwitchByName = (name: string): HTMLElement | undefined => {
    return screen.queryByTestId(`switch.${name}`) ?? undefined;
};

export const changeTextField = (inputElement: HTMLElement, value: string) => {
    fireEvent.change(inputElement, { target: { value } });
};

export const changeSelectFieldByElement = async (
    element: HTMLElement,
    newSelectedLabel: string,
) => {
    fireEvent.mouseDown(element);
    const listbox = within(screen.getByRole('listbox'));
    fireEvent.click(listbox.getByText(newSelectedLabel));
};

/**
 * `changeSelectField` mocks the user by using the `select` labels that the user
 * will see, but is problematic when there are multiple select fields in the form
 */
export const changeSelectField = (initialLabel: string, newSelectedLabel: string) => {
    fireEvent.mouseDown(screen.getByText(initialLabel));
    const listbox = within(screen.getByRole('listbox'));
    fireEvent.click(listbox.getByText(newSelectedLabel));
};

/**
 * `changeSelectFieldByValue` uses the MUI textfield to update the field value
 * This is best used for updating a form with multiple select fields
 */
export const changeSelectFieldByValue = (fieldName: string, value: string) => {
    const inputElement = screen.getByTestId(`select.${fieldName}`);
    fireEvent.change(inputElement, { target: { value } });
};

export const toggleSwitchField = (switchElement: HTMLElement) => {
    fireEvent.click(switchElement);
};

export const toggleSwitchFieldByName = (name: string) => {
    fireEvent.click(getSwitchByName(name));
};

export const toggleCheckboxByName = (fieldName: string) => {
    const { input } = getCheckboxByName(fieldName);
    fireEvent.click(input);
};

export const expectTextFieldHasError = (inputElement: HTMLElement) => {
    expect(inputElement.closest('div')).toHaveClass('Mui-error');
};

export const isJestRunning = import.meta.env.JEST_WORKER_ID !== undefined;
