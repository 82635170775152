import { keyBy } from 'lodash';
import moment from 'moment';

import { CourseType, HydratedPosProduct } from '@sparkplug/lib';

import { IAccount } from '@app/types/AccountsTypes';
import { IPosProduct } from '@app/types/PosTypes';
import { IOptionTree } from '@app/types/UITypes';

export const buildProductHierarchy = (
    posBrands: { _id: string; name: string; createdAt: string }[],
    posCategories: { _id: string; name: string; createdAt: string }[],
    posProducts: HydratedPosProduct[],
): IOptionTree[] => {
    const hierarchyMap: Record<string, Record<string, IPosProduct[]>> = {};

    const brandsSorted = posBrands.sort((a, b) => moment(b.createdAt).diff(a.createdAt));
    const brandMap = keyBy(
        posBrands.map((brand) => ({ ...brand, label: brand.name, value: brand._id })),
        '_id',
    );

    const categoriesSorted = posCategories.sort((a, b) => moment(b.createdAt).diff(a.createdAt));
    const categoryMap = keyBy(
        posCategories.map((category) => ({
            ...category,
            label: category.name,
            value: category._id,
        })),
        '_id',
    );

    const findRecentBrand = (brandIds: string[]) => {
        const brand = brandsSorted.find(({ _id: brandId }) => brandIds.includes(brandId));

        return brand;
    };

    const findRecentCategory = (brandIds: string[]) => {
        const category = categoriesSorted.find(({ _id: brandId }) => brandIds.includes(brandId));

        return category;
    };

    posProducts
        .sort((a, b) => {
            return a.name.localeCompare(b.name);
        })
        .forEach((product) => {
            const brand = findRecentBrand((product.brands || []).map(({ _id }) => _id));
            const category = findRecentCategory((product.categories || []).map(({ _id }) => _id));

            if (brand && category) {
                const brandId = brand._id;
                const categoryId = category._id;

                if (!hierarchyMap?.[brandId]) {
                    hierarchyMap[brandId] = {
                        [categoryId]: [],
                    };
                }
                if (!hierarchyMap?.[brandId]?.[categoryId]) {
                    hierarchyMap[brandId][categoryId] = [];
                }
                categoryMap[categoryId] = {
                    ...category,
                    value: category._id,
                    label: category.name,
                };
                hierarchyMap[brandId][categoryId].push({
                    ...product,
                    value: product._id,
                    label: product.name,
                    brands: [brand],
                    categories: [category],
                });
            }
        });

    const hierarchy = Object.keys(hierarchyMap).reduce((res, brandId) => {
        const brand: IOptionTree = { ...brandMap[brandId] };
        const categoryIds = Object.keys(hierarchyMap[brandId]);

        brand.children = categoryIds.reduce((categories, categoryId) => {
            const category: IOptionTree = { ...categoryMap[categoryId] };

            category.children = hierarchyMap?.[brandId]?.[categoryId] || [];

            return [...categories, category];
        }, [] as IOptionTree[]);

        return [...res, brand];
    }, [] as IOptionTree[]);

    if (hierarchy.length === 0) {
        return hierarchy;
    }

    return hierarchy.length > 1
        ? hierarchy.sort((a, b) => {
              return a.label.localeCompare(b.label);
          })
        : hierarchy[0].children || [];
};

export const getCourseTypeByAccount = (account: IAccount | undefined): CourseType | undefined => {
    if (account?.seedTalentEnabled) {
        return 'seedTalent';
    }

    if (account?.zoltrainEnabled) {
        return 'zoltrain';
    }

    return undefined;
};
