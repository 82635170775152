import axios from 'axios';

import {
    MultiRetailerProductSelection,
    MultiRetailerSparkParticipantGroup,
    Spark,
    SparkInternalTracking,
} from '@sparkplug/lib';

import { useAdvancedMutation } from '@hooks/QueryHooks';
// eslint-disable-next-line import/no-cycle
import { getSparkQueryKey } from '@hooks/SparksHooks/SparksHooks';

import { getSparksQueryKey } from '../queries/GetSparksQuery';

const API = {
    createMultiRetailerSpark: async (
        body: Spark & {
            multiRetailerParticipantGroups: MultiRetailerSparkParticipantGroup[];
            multiRetailerProductSelection: MultiRetailerProductSelection;
            uniqueParticipantLocationIds: string[];
            requestForSparkId?: string;
        },
    ) => {
        return (await axios.post<void>('/api/v1/sparkplug/sparks/multi-retailer', body)).data;
    },
    requestMultiRetailerSpark: async (body: {
        groupId: string;
        sparkProperties: Spark;
        multiRetailerParticipantGroups: MultiRetailerSparkParticipantGroup[];
        multiRetailerProductSelection: MultiRetailerProductSelection;
        requestForSparkId?: string;
    }) => {
        return (await axios.post<void>('/api/v1/sparkplug/sparks/request/multi-retailer', body))
            .data;
    },
    updateInternalTracking: async (body: {
        sparkId: string;
        internalTrackingUpdates: {
            status?: SparkInternalTracking['status'];
            invoiceStatus?: SparkInternalTracking['invoiceStatus'];
            payoutStatus?: SparkInternalTracking['payoutStatus'];
            notes?: SparkInternalTracking['notes'];
        };
    }) => {
        return axios.put<Partial<SparkInternalTracking>>(
            `/api/v1/pos/sparks/internal-tracking/${body.sparkId}`,

            body.internalTrackingUpdates,
        );
    },
};

export const useCreateMultiRetailerSparkMutation = ({ accountId }: { accountId: string }) => {
    const { mutate, mutateAsync, isLoading } = useAdvancedMutation(API.createMultiRetailerSpark, {
        updateQuery: { queryKey: getSparksQueryKey(accountId) },
        toastOptions: {
            loading: null,
            success: 'Spark created.',
            error: 'Something went wrong.',
        },
    });

    return {
        isCreatingMultiRetailerSpark: isLoading,
        createMultiRetailerSpark: mutate,
        createMultiRetailerSparkAsync: mutateAsync,
    };
};

export const useRequestMultiRetailerSparkMutation = ({ accountId }: { accountId: string }) => {
    const { mutate, mutateAsync, isLoading } = useAdvancedMutation(
        (payload: {
            sparkProperties: Spark;
            multiRetailerParticipantGroups: MultiRetailerSparkParticipantGroup[];
            multiRetailerProductSelection: MultiRetailerProductSelection;
            requestForSparkId?: string;
        }) =>
            API.requestMultiRetailerSpark({
                groupId: accountId,
                ...payload,
            }),
        {
            updateQuery: { queryKey: getSparksQueryKey(accountId) },
            toastOptions: {
                loading: null,
                success: 'Spark request sent.',
                error: 'Something went wrong.',
            },
        },
    );

    return {
        isRequestingMultiRetailerSpark: isLoading,
        requestMultiRetailerSpark: mutate,
        requestMultiRetailerSparkAsync: mutateAsync,
    };
};

export const useUpdateInternalTrackingMutation = ({ sparkId }: { sparkId: string }) => {
    const { mutateAsync, isLoading } = useAdvancedMutation(API.updateInternalTracking, {
        updateQuery: { queryKey: getSparkQueryKey(sparkId), useOptimistic: true },

        toastOptions: {
            loading: null,
            success: 'Internal tracking updated.',
            error: 'Something went wrong.',
        },
    });

    return {
        isUpdatingInternalTracking: isLoading,
        updateInternalTrackingAsync: mutateAsync,
    };
};
