import { FC } from 'react';

import Button from '@components/buttons/Button';
import CalloutMessage from '@components/layout/CalloutMessage';
import Modal from '@components/overlays/Modal';

interface LinkRequestModalProps {
    isVisible: boolean;
    onSend: () => void;
    onClose: () => void;
}

export const LinkRequestModal: FC<LinkRequestModalProps> = ({ isVisible, onSend, onClose }) => {
    return (
        <Modal
            className="send-link-request-modal"
            maxWidth={480}
            isVisible={isVisible}
            onClose={onClose}
        >
            <Modal.Title onClose={onClose}>Send Link Request</Modal.Title>

            <CalloutMessage
                className="mx-6 w-fit"
                color="yellow"
                title="No Brand Matches"
                message="We don’t detect any of your brands at this Retailer 
                which is required for a creating Spark incentives."
            />
            <Modal.Content>
                If you know you&apos;ve got products in store, you can send the link but please work
                with them to ensure correct brand mapping.
            </Modal.Content>

            <Modal.Actions>
                <div className="">
                    <Button color="neutral" variant="flat" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button variant="filled" onClick={onSend}>
                        Send Request
                    </Button>
                </div>
            </Modal.Actions>
        </Modal>
    );
};
