import moment from 'moment-timezone';

import BucketFactory, { TBucketsMap } from './BucketFactory';

export default class WeekBucketFactory extends BucketFactory {
    getShortName(name: string) {
        return moment(name).format('M/D');
    }

    getTooltipTitle(name: string) {
        const format = 'M/D';
        const start = moment(name).format(format);
        const end = moment(name).add(6, 'days').format(format);
        return `${start} - ${end}`;
    }

    getComparisonLabel(name: string) {
        const format = 'MMM D, YYYY';
        const start = moment(name).format(format);
        const end = moment(name).add(6, 'days').format(format);
        return `${start} - ${end}`;
    }

    getBucketName(transaction: any) {
        let dt;
        if (transaction.bucket) {
            dt = moment(transaction.bucket);
        } else {
            const date = moment(transaction.completedOn);
            dt = date.tz(transaction.timeZone);
        }
        const thisWeek = dt.week();

        let parent = moment(dt);

        // TODO: no while(true)
        // eslint-disable-next-line no-constant-condition
        while (true) {
            const reference = moment(parent).subtract(1, 'days');
            if (reference.week() !== thisWeek) {
                break;
            }
            parent = moment(reference);
        }

        return parent.format('YYYY-MM-DD');
    }

    getAllBucketNames(dateStart: string | Date, dateEnd: string | Date) {
        const bucketNames: string[] = [];
        // Start on the monday of the week
        const startDate = moment(dateStart).clone();
        const currentDate = moment(dateStart).startOf('week').add(1, 'day');

        // The server starts the weeks on mondays, so we need to adjust for that
        if (startDate.day() === 0) {
            currentDate.subtract(1, 'week');
        }

        do {
            bucketNames.push(currentDate.format('YYYY-MM-DD'));
            currentDate.add(1, 'week');
        } while (currentDate.isSameOrBefore(dateEnd, 'week'));

        return bucketNames;
    }

    getCumulativeBuckets(bucketsMap: TBucketsMap, bucketName: string) {
        return super.getCumulativeBuckets(bucketsMap, bucketName);
    }
}
