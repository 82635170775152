import React from 'react';

import { CommissionSparkIcon, GoalSparkIcon, LeaderboardSparkIcon } from '@components/icons';

import { CardImage } from '@app/componentLibrary/Card';

export function getSparkTemplateCardImage(templateType: string | undefined) {
    const cardImageHeight = 'h-[104px]';
    switch (templateType) {
        case 'leaderboard':
            return (
                <CardImage
                    image={<LeaderboardSparkIcon name="logo" className="w-20 h-20" />}
                    className={`bg-[#FFF8F0] ${cardImageHeight}`}
                />
            );
        case 'commission':
            return (
                <CardImage
                    image={<CommissionSparkIcon name="logo" className="w-20 h-20" />}
                    className={`bg-[#F4FBF8]  ${cardImageHeight}`}
                />
            );
        case 'goal':
            return (
                <CardImage
                    image={<GoalSparkIcon name="logo" className="w-20 h-20" />}
                    className={`bg-[#F2F8FB] ${cardImageHeight}`}
                />
            );
        default:
            return (
                <CardImage
                    image={<LeaderboardSparkIcon name="logo" className="w-20 h-20" />}
                    className={`bg-[#FFF8F0] ${cardImageHeight}`}
                />
            );
    }
}
