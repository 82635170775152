import { ComponentProps, ComponentType, FC, useEffect, useMemo, useRef } from 'react';

import { TableProvider } from '@contexts/TableContext';

import { AddCircleOutline } from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import Table from '@components/table/Table';

import { useModal } from '@hooks/ModalHooks';
import { useDynamicHeight } from '@hooks/UIHooks';

import { appendClasses } from '@helpers/ui';

import { IPosProduct } from '@app/types/PosTypes';

import ProductsMatched from '../components/ProductsMatched';
import DeprecatedProductSegmentToolbar from './components/DeprecatedProductSegmentToolbar';
import OldProductsRows from './components/OldProductsRows';
import ProductFilterSegmentToolbar from './components/ProductFilterSegmentToolbar';
import {
    UsePosRulesBasedSparkProductSelectorTableFilters,
    useBrandCategoryFilters,
    usePosRulesBasedProductTableState,
} from './hooks';
import { mapProductsToProductRows } from './utils';

import './PosRulesBasedSparkProductSelectorTable.scss';

interface RulesBasedSparkProductSelectorTableProps {
    calloutProps?: ComponentProps<typeof CalloutMessage>;
    products: IPosProduct[];
    tableFilters: UsePosRulesBasedSparkProductSelectorTableFilters;
    ModalNext: ComponentType<{}>;
    formHasErrorState?: boolean;
    resetFormErrorState?: () => void;
    showPricesColumn?: boolean;
}

export const PosRulesBasedProductSelectorTable: FC<RulesBasedSparkProductSelectorTableProps> = ({
    calloutProps,
    products,
    tableFilters,
    ModalNext,
    formHasErrorState = false,
    resetFormErrorState = () => {},
    showPricesColumn = true,
}) => {
    const { modalContentRef } = useModal();
    const {
        filters,
        setFilters,
        resetSearchFilter,
        applyBrandFilters,
        applyCategoryFilters,
        applyLastSoldFilter,
        onChangeSearchFilter,
        getRulesBasedFilteredProducts,
    } = tableFilters;

    useEffect(() => {
        resetFormErrorState();
    }, [filters]);

    const {
        emptyStateIsVisible,
        headCells,
        isUsingFilterSegment,
        currentTableFilterFns,
        showExcludedProductsTable,
        setShowExcludedProductsTable,
    } = usePosRulesBasedProductTableState({ showPricesColumn, tableFilters, products });

    const rulesBasedFilteredProducts = useMemo(
        () => getRulesBasedFilteredProducts(products),
        [filters],
    );

    const productRows = useMemo(() => {
        // If we are using the filter segment and showing the exclusion table, we don't want to filter out the old products
        if (isUsingFilterSegment && showExcludedProductsTable) {
            return mapProductsToProductRows(products);
        }

        // Old products should not count as part of the total unless the current filter is `allTime`
        const filteredProducts =
            filters.lastSoldAt !== 'allTime' ? applyLastSoldFilter(products) : products;

        return mapProductsToProductRows(filteredProducts);
    }, [products, isUsingFilterSegment, showExcludedProductsTable, applyLastSoldFilter]);

    const brandCategoryFilters = useBrandCategoryFilters({
        products,
        filters,
        applyBrandFilters,
        applyCategoryFilters,
        updateFilters: setFilters,
    });

    const { visibleBrandOptions } = brandCategoryFilters;

    const classNamesAppended = appendClasses([
        'pos-rules-based-spark-product-selector-table-wrapper',
        isUsingFilterSegment ? 'is-using-filter-segment' : undefined,
        showPricesColumn ? undefined : 'hide-prices-column',
    ]);

    const emptyStateRef = useRef<HTMLDivElement>(null);
    const emptyStateHeight = useDynamicHeight(modalContentRef, emptyStateRef);

    const handleViewNonQualifying = () => {
        resetSearchFilter();
        setShowExcludedProductsTable(true);
    };

    const handleHideExcludedProductsTable = () => {
        resetSearchFilter();
        setShowExcludedProductsTable(false);
    };

    return (
        <>
            {calloutProps && !showExcludedProductsTable && <CalloutMessage {...calloutProps} />}
            <TableProvider
                showPagination={false}
                headCells={headCells}
                rows={productRows}
                filters={currentTableFilterFns}
                defaultOptions={{
                    orderBy: 'name',
                }}
                footerComponent={
                    !showExcludedProductsTable && (
                        <OldProductsRows
                            headCells={headCells}
                            products={products}
                            tableFilters={tableFilters}
                        />
                    )
                }
            >
                <div className={classNamesAppended}>
                    {isUsingFilterSegment ? (
                        <ProductFilterSegmentToolbar
                            tableFilters={tableFilters}
                            brandCategoryFilters={brandCategoryFilters}
                            formHasErrorState={formHasErrorState}
                            emptyStateIsVisible={emptyStateIsVisible}
                            showExcludedProductsTable={showExcludedProductsTable}
                            onHideExcludedProductsTable={handleHideExcludedProductsTable}
                        />
                    ) : (
                        <DeprecatedProductSegmentToolbar
                            tableFilters={tableFilters}
                            brandCategoryFilters={brandCategoryFilters}
                            formHasErrorState={formHasErrorState}
                            showExcludedProductsTable={showExcludedProductsTable}
                            setShowExcludedProductsTable={setShowExcludedProductsTable}
                            emptyStateIsVisible={emptyStateIsVisible}
                        />
                    )}
                    {emptyStateIsVisible ? (
                        <EmptyStateDisplay
                            divRef={emptyStateRef}
                            className="rules-based-spark-product-selector-empty-state"
                            graphic={<></>}
                            smallText="Set product rules to determine qualifying products for the Spark"
                            actionButton={{
                                startIcon: <AddCircleOutline />,
                                label: 'Add All Products',
                                onClick: () => {
                                    setFilters({
                                        primaryFilter: 'brands',
                                        brands: visibleBrandOptions,
                                        categories: [],
                                        lastSoldAt: '-60days',
                                        hideSampleProducts: !isUsingFilterSegment,
                                        productNameFilters: [],
                                        productNameContains: [],
                                        productNameDoesNotContain: [],
                                        excludedProductIds: [],
                                    });
                                },
                            }}
                            style={{ height: emptyStateHeight }}
                        />
                    ) : (
                        <>
                            {!showExcludedProductsTable && (
                                <ProductsMatched
                                    onChangeSearchFilter={onChangeSearchFilter}
                                    matchingProducts={rulesBasedFilteredProducts}
                                    total={productRows.length}
                                    onViewNonQualifying={handleViewNonQualifying}
                                />
                            )}
                            <Table useExternalProvider variant="smooth">
                                <Table.RenderHead />
                                <Table.RenderBody
                                    emptyStateText="No products match the rules set"
                                    dynamicHeight={{
                                        infiniteScroll: true,
                                        containerRef: modalContentRef,
                                        scrollToTopElement:
                                            filters.lastSoldAt === 'allTime'
                                                ? {
                                                      getText: (itemCount) =>
                                                          `Viewing ${itemCount} products`,
                                                  }
                                                : undefined,
                                    }}
                                    highlightRowOnHover
                                />
                            </Table>
                        </>
                    )}
                </div>
                <ModalNext />
            </TableProvider>
        </>
    );
};

export default PosRulesBasedProductSelectorTable;
