import { FC } from 'react';

// eslint-disable-next-line no-restricted-imports
import { FormHelperText, FormHelperTextProps } from '@mui/material';

import './InputHelperText.scss';

interface InputHelperTextProps extends FormHelperTextProps {}

const InputHelperText: FC<InputHelperTextProps> = (props) => {
    return <FormHelperText {...props} />;
};

export default InputHelperText;
