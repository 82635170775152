import axios from 'axios';

import {
    CreateTrainingCourseRequestBody,
    GetSeedTalentTrainingCourseResponseBody,
    ITrainingCourse,
    UpdateTrainingCourseRequestBody,
} from '@sparkplug/lib';

const trainingCourseRoute = '/api/v1/training-course' as const;

const create = async (
    body: CreateTrainingCourseRequestBody,
    sparkId: string,
): Promise<ITrainingCourse> => {
    return (await axios.post(`${trainingCourseRoute}/${sparkId}`, body)).data;
};

const update = async (
    body: UpdateTrainingCourseRequestBody,
    trainingCourseId: string,
): Promise<void> => {
    return axios.put(`${trainingCourseRoute}/${trainingCourseId}`, body);
};

const courseComplete = async (
    trainingCourseId: string,
    externalTrainingCourseId: string,
    userId: string,
): Promise<void> => {
    return (
        await axios.post(`${trainingCourseRoute}/complete/${trainingCourseId}`, {
            userId,
            externalTrainingCourseId,
        })
    ).data;
};

const getSeedTalentCourse = async (trainingCourseId: string) => {
    return (
        await axios.get<GetSeedTalentTrainingCourseResponseBody>(
            `${trainingCourseRoute}/seed-talent/${trainingCourseId}`,
        )
    ).data;
};

const getZoltrainCourse = async (trainingCourseId: string) => {
    return {
        blob: (
            await axios.get<any>(`${trainingCourseRoute}/zoltrain/${trainingCourseId}`, {
                responseType: 'blob',
            })
        ).data,
    };
};

const validateWithZoltrain = async (courseId: string, accessToken: string) => {
    return axios
        .create({ headers: { 'zt-access-token': accessToken } })
        .get(`https://node.zoltrain.com/card-course-previews/${courseId}`);
};

export const TrainingCourseAPI = {
    create,
    update,
    validateWithZoltrain,
    getSeedTalentCourse,
    getZoltrainCourse,
    courseComplete,
};
