import { ComponentProps, FC, useMemo, useState } from 'react';

import { AccountLink, DO_NOT_HAVE_PERMISSIONS_MESSAGE } from '@sparkplug/lib';

import { isLinkUnmapped, useAccountLinks } from '@features/account-links';

import Button, { ButtonVariant } from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { Send as SendIcon } from '@components/icons';
import Tooltip from '@components/layout/Tooltip';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { cn } from '@app/componentLibrary/utils';
import { IOption } from '@app/types/UITypes';

import { RequestForSparkModal } from './RequestForSparkModal';
import { accountLinkOptionUtil } from './accountLinkOptionUtil';

export interface PresentationalRequestSparkButtonProps {
    userIsPermitted: boolean;
    variant?: ButtonVariant | 'icon-only';
    className?: string;
    sx?: ComponentProps<typeof Button>[`sx`];
    onClick: () => void;
}
export const PresentationalRequestSparkButton: FC<PresentationalRequestSparkButtonProps> = ({
    className,
    sx,
    variant = 'flat',
    userIsPermitted,
    onClick,
}) => {
    const disabledMessage = userIsPermitted ? undefined : DO_NOT_HAVE_PERMISSIONS_MESSAGE;
    const variantAgnosticButtonProps = {
        sx,
        className: cn('request-spark-button', 'whitespace-nowrap', className),
        disabled: !!disabledMessage,
        onClick,
    };

    if (variant === 'icon-only') {
        return (
            <Tooltip title={disabledMessage ?? 'Request Spark'} placement="top">
                <span>
                    <IconButton {...variantAgnosticButtonProps} color="blue">
                        <SendIcon />
                    </IconButton>
                </span>
            </Tooltip>
        );
    }

    return (
        <Button
            {...variantAgnosticButtonProps}
            tooltip={disabledMessage}
            startIcon={<SendIcon />}
            variant={variant as ButtonVariant}
        >
            Request Spark
        </Button>
    );
};

export interface RequestSparkButtonProps
    extends Omit<PresentationalRequestSparkButtonProps, 'onClick' | 'userIsPermitted'> {
    accountLink?: AccountLink;
}
export const RequestSparkButton: FC<RequestSparkButtonProps> = ({
    accountLink,
    ...presentationalProps
}) => {
    const { account, userCan } = useSparkplugAccount();
    const { accountLinks = [] } = useAccountLinks(
        account?._id ?? '',
        !accountLink && import.meta.env.REACT_APP_REQUEST_FOR_SPARK === 'true',
    );

    const [showRequestForSparkModal, setShowRequestForSparkModal] = useState<boolean>(false);

    const accountLinkOptions: (AccountLink & IOption)[] = useMemo(() => {
        return accountLinks
            .filter(
                (accountLinkData) =>
                    // Only show enabled account links that are mapped
                    accountLinkData.status === 'enabled' && !isLinkUnmapped(accountLinkData),
            )
            .map((accountLinkData) => {
                return {
                    ...accountLinkData,
                    value: accountLinkData.accountId,
                    label: accountLinkOptionUtil.generateLabel(accountLinkData),
                };
            });
    }, [accountLinks]);

    if (
        import.meta.env.REACT_APP_REQUEST_FOR_SPARK !== 'true' ||
        account?.type !== 'retailer' ||
        account?.connectDisabled
    ) {
        return <></>;
    }

    return (
        <>
            <PresentationalRequestSparkButton
                {...presentationalProps}
                userIsPermitted={userCan('createSpark')}
                onClick={() => setShowRequestForSparkModal(true)}
            />
            {showRequestForSparkModal && (
                <RequestForSparkModal
                    sourceAccountId={account._id}
                    vendorLinkOptions={accountLinkOptions}
                    initialVendorLink={accountLink}
                    onClose={() => setShowRequestForSparkModal(false)}
                />
            )}
        </>
    );
};
