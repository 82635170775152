import { CircularProgress as MuiCircularProgress } from '@mui/material';

import './Spinner.scss';

const Spinner = ({
    size,
    className = '',
    dataTestId,
}: {
    size?: number | string;
    className?: string;
    dataTestId?: string;
}) => {
    return (
        <MuiCircularProgress
            className={`loading-spinner ${className}`}
            size={size}
            data-testid={dataTestId || 'spinner'}
        />
    );
};

export default Spinner;
