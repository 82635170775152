import { FC } from 'react';

import { Info as InfoIcon } from '@components/icons';
import Tooltip from '@components/layout/Tooltip';

import { appendClasses } from '@helpers/ui';

import './InfoTooltip.scss';

interface InfoTooltipProps {
    className?: string;
    info: string;
}

const InfoTooltip: FC<InfoTooltipProps> = ({ className, info }) => {
    const classNamesAppended = appendClasses(['info-icon', className]);

    return (
        <Tooltip title={info} placement="top">
            <InfoIcon className={classNamesAppended} />
        </Tooltip>
    );
};

export default InfoTooltip;
