import { useCallback } from 'react';

import axios from 'axios';

import { ListSparkProductLabelsResponseBody, ProductLabels } from '@sparkplug/lib';

import { useAdvancedQuery, useQueryClient } from '@hooks/QueryHooks';

const API = {
    fetchSparkProductLabels: async (sparkId: string) => {
        return (
            (
                await axios.get<ListSparkProductLabelsResponseBody>(
                    `/api/v1/sparks/product-labels/${sparkId}`,
                )
            ).data.data ?? ([] as ProductLabels[])
        );
    },
};

export const getSparkProductLabelsCacheKey = (sparkId: string) => [
    'product-labels',
    'spark',
    sparkId,
];
export const useSparkProductLabels = (
    sparkId: string,
    options?: { autoFetch?: boolean; enabled?: boolean },
) => {
    const { data: sparkProductLabels = [], isFetched: sparkProductLabelsAreReady } =
        useAdvancedQuery(
            getSparkProductLabelsCacheKey(sparkId),
            () => API.fetchSparkProductLabels(sparkId),
            {
                enabled: !!sparkId && (options?.enabled ?? options?.autoFetch ?? true),
            },
        );

    const queryClient = useQueryClient();
    const prefetchSparkProductLabels = useCallback(async () => {
        queryClient.prefetchQuery(getSparkProductLabelsCacheKey(sparkId), () =>
            API.fetchSparkProductLabels(sparkId),
        );
    }, [queryClient, sparkId]);

    return {
        sparkProductLabels,
        sparkProductLabelsAreReady,
        prefetchSparkProductLabels,
    };
};
