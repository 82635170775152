import { useEffect, useMemo, useRef } from 'react';

import { useSpark } from '@hooks/SparksHooks/SparksHooks';

/**
 * If a requestForSpark is present and the spark does not have a groupId, set the groupId to the requestForSpark's sourceAccountId (only once).
 * If the groupId shifts to a different value, the groupId will not be automatically updated to the sourceAccountId again.
 *
 * @returns requestForSpark if it is relevant (UIRequestForSpark | undefined)
 * * If the groupId has shifted and is no longer relevant to the requestForSpark, return undefined.
 * * If there is no requestForSpark, return undefined.
 */
export const useApplyRequestForSparkDetails = () => {
    const {
        spark,
        requestForSpark,
        updateSpark,
        isCreatingMultiRetailerSpark,
        setMultiRetailerParticipantSelection,
        updateMultiRetailerParticipantGroups,
        multiRetailerParticipantGroups = [],
    } = useSpark();

    const sourceAccountIsParticipating = useMemo(() => {
        if (isCreatingMultiRetailerSpark) {
            return multiRetailerParticipantGroups.some(
                (group) => group.retailerAccountId === requestForSpark?.sourceAccountId,
            );
        }
        return requestForSpark?.sourceAccountId === spark.groupId;
    }, [multiRetailerParticipantGroups, requestForSpark?.sourceAccountId, spark.groupId]);

    /** Only apply the requestForSpark details up to one time */
    const hasAppliedRequestForSparkRef = useRef(false);
    const hasAppliedRequestForSpark = hasAppliedRequestForSparkRef.current;

    useEffect(() => {
        if (requestForSpark && !sourceAccountIsParticipating && !hasAppliedRequestForSpark) {
            if (isCreatingMultiRetailerSpark) {
                const { sourceAccountId, accountLink } = requestForSpark;
                setMultiRetailerParticipantSelection([
                    sourceAccountId,
                    ...(accountLink?.locations?.map(
                        (location) => `${sourceAccountId}.${location._id}`,
                    ) ?? []),
                ]);
                updateMultiRetailerParticipantGroups([
                    {
                        retailerAccountId: sourceAccountId,
                        participantGroups: [
                            {
                                locationIds:
                                    accountLink?.locations?.map((location) => location._id) ?? [],
                                posEmployeeProfileIds: [],
                            },
                        ],
                    },
                ]);
            } else {
                // Single Retailer Spark
                updateSpark({
                    groupId: requestForSpark.sourceAccountId,
                });
            }
            hasAppliedRequestForSparkRef.current = true;
        }
    }, [
        requestForSpark,
        spark.groupId,
        sourceAccountIsParticipating,
        isCreatingMultiRetailerSpark,
    ]);

    return !!requestForSpark && sourceAccountIsParticipating ? requestForSpark : undefined;
};
