export const currencyFormatterFactory = (numberOfDecimals = 0) => {
    return (value: string | number) => {
        return `$${Number(value).toLocaleString('en-US', {
            minimumFractionDigits: numberOfDecimals,
            maximumFractionDigits: numberOfDecimals,
        })}`;
    };
};

export const numberFormatterFactory = (numberOfDecimals = 0, emptyIfFloat = false) => {
    return (value: number) => {
        const returnValueIsInteger = numberOfDecimals === 0;
        const isFloat = Math.floor(value) === value;

        if (returnValueIsInteger && isFloat && emptyIfFloat) {
            return '';
        }

        return `${Number(value).toLocaleString('en-US', {
            minimumFractionDigits: numberOfDecimals,
            maximumFractionDigits: numberOfDecimals,
        })}`;
    };
};

export const percentFormatterFactory =
    (numberOfDecimals = 0, emptyIfFloat = false) =>
    (value: number) =>
        `${numberFormatterFactory(numberOfDecimals, emptyIfFloat)(value)}%`;
