import { FC, ReactElement, useEffect, useMemo, useState } from 'react';

import { SparkLeaderboardTableDataRow } from '@views/sparks/SparkManageView/useSparkLeaderboardTableData';

import { SparkType } from '@sparkplug/lib';

import { useSendBulksSMSMessagesByPosEmployeeProfileIdsMutation } from '@core/users';

import { TableProvider } from '@contexts/TableContext';

import SmsEnrollmentInfoIcon from '@components/charts/UserCharts/SmsEnrollmentInfoIcon';
import SMSEnrollmentChip from '@components/chips/SMSEnrollmentChip';
import Form from '@components/form/Form';
import { Close as CloseIcon, Edit as EditIcon, Send as SendIcon } from '@components/icons';
import FormattedMetricValue from '@components/layout/FormattedMetricValue';
import ComposeBulkSMSModal from '@components/overlays/ComposeBulkSMSModal';
import Table from '@components/table/Table';
import Toolbar from '@components/toolbar/Toolbar';

import { useFormContext } from '@hooks/FormHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';
import { useTableContext } from '@hooks/TableHooks';
import { useSearch } from '@hooks/UIHooks';

import { ChartLeader } from '@app/types/ChartDataTypes';
import { ITableRow, THeadCell } from '@app/types/TableTypes';
import { IAccountUser } from '@app/types/UsersTypes';

import './SparkSendSMSView.scss';

const combineHeadCells = (
    _headCells: THeadCell<SparkLeaderboardTableDataRow>[],
    bulkEditorIsVisible: boolean,
): THeadCell<SparkLeaderboardTableDataRow>[] => {
    const messageHeadCells = [
        {
            id: 'message',
            label: bulkEditorIsVisible ? 'Message' : '',
        },
    ];

    const headCells = [..._headCells, ...messageHeadCells];

    return headCells;
};

export interface ChartLeaderGoalData {
    goalsMet?: number;
    goalsTotal?: number;
    nextGoalAmount?: number;
    amountAwayFromNextGoal?: number;
}

export interface ISparkChartDataLeaderRow
    extends ITableRow<ChartLeader & IAccountUser & ChartLeaderGoalData> {
    standing: string;
    rank: number;
    metric: string;
    formattedValue: ReactElement;
}

interface IUserValueTableCells {
    sparkType: SparkType;
    row: ISparkChartDataLeaderRow;
}

const UserValueTableCells = ({ sparkType, row }: IUserValueTableCells) => {
    if (sparkType === 'leaderboard') {
        return (
            <>
                <Table.Cell align="left">{row.standing}</Table.Cell>
                <Table.Cell align="left">{row.formattedValue}</Table.Cell>
            </>
        );
    }

    if (sparkType === 'goal') {
        const isUnlimited = row.goalsTotal === -1;

        if (isUnlimited) {
            return (
                <Table.Cell align="left">
                    <strong>{row.goalsMet}</strong> goals reached
                </Table.Cell>
            );
        }

        return (
            <>
                <Table.Cell className="send-sms_user-value-two-lines" align="left">
                    <strong>{`${row.goalsMet}/${row.goalsTotal}`}</strong>
                    <br />
                    <FormattedMetricValue
                        metric={row.metric}
                        value={row.amountAwayFromNextGoal || 0}
                        suffix={row.metric === 'total_units' ? ' units away' : ' away'}
                    />
                </Table.Cell>
            </>
        );
    }

    if (sparkType === 'commission') {
        return (
            <>
                <Table.Cell className="send-sms_user-value-two-lines" align="left">
                    <strong>
                        <FormattedMetricValue metric="total_sales" value={row.value} />
                    </strong>
                    <br />
                    <span>{`${row.unitCount} units`}</span>
                </Table.Cell>
            </>
        );
    }

    return null;
};

interface SparkSMSParticipantsTableRowProps {
    onCheckUncheckUser: (row: ISparkChartDataLeaderRow) => void;
    row: ISparkChartDataLeaderRow;
    sparkType: SparkType;
    selected: string[];
    bulkSMSValue: string;
    bulkEditorIsVisible: boolean;
}

const SparkSMSParticipantsTableRow: FC<SparkSMSParticipantsTableRowProps> = ({
    onCheckUncheckUser,
    sparkType,
    row,
    selected,
    bulkSMSValue,
    bulkEditorIsVisible,
}) => {
    const posEmployeeProfileId = row.posEmployeeProfileIds?.[0];
    const fieldName = `messages[${posEmployeeProfileId}]`;
    const { detailedSparkType } = useSpark();
    const { setValue } = useFormContext();

    useEffect(() => {
        setValue(fieldName, bulkSMSValue);
    }, [bulkSMSValue]);

    return (
        <Table.Row hover tabIndex={-1}>
            {!bulkEditorIsVisible && (
                <Table.Tooltip
                    disabled={row.selectionDisabled !== true}
                    title={`Employee must be "enrolled" or "pending" to send SMS.`}
                >
                    <Table.Cell hidden scope="row">
                        <Table.Checkbox
                            disabled={row.selectionDisabled}
                            value={selected.includes(row.key)}
                            onChange={() => onCheckUncheckUser(row)}
                        />
                    </Table.Cell>
                </Table.Tooltip>
            )}
            <UserValueTableCells sparkType={sparkType} row={row} />
            <Table.Cell align="left">{row.firstName}</Table.Cell>
            <Table.Cell align="left">{row.lastName}</Table.Cell>

            <Table.Cell align="left">{row.locationStr || '--'}</Table.Cell>

            {detailedSparkType === 'leaderboardMulti' ? (
                <Table.Cell align="center">
                    <SmsEnrollmentInfoIcon
                        smsStatus={row.smsStatus}
                        isInactive={row.role === 'none'}
                    />
                </Table.Cell>
            ) : (
                <Table.Cell align="left">
                    <SMSEnrollmentChip status={row.smsStatus} isMuted={row.muteScheduledSms} />
                </Table.Cell>
            )}

            <Table.Cell className="send-sms_message-cell" align="left">
                {bulkEditorIsVisible && (
                    <Form.TextField
                        multiline
                        required
                        defaultValue={bulkSMSValue}
                        name={fieldName}
                        rows={3}
                    />
                )}
            </Table.Cell>
        </Table.Row>
    );
};

interface ISparkSMSParticipantsTableBody {
    isLoading: boolean;
    bulkEditorIsVisible: boolean;
    bulkSMSValue: string;
    sparkType: SparkType;
}

const SparkSMSParticipantsTableBody = ({
    isLoading,
    bulkEditorIsVisible,
    bulkSMSValue,
    sparkType,
}: ISparkSMSParticipantsTableBody) => {
    const { tableRows, tableFilteredRows, tableSelected, tableHeadData, tableCheckUncheckRow } =
        useTableContext<ISparkChartDataLeaderRow>();

    const selectedRows = useMemo(() => {
        if (bulkEditorIsVisible) {
            return (tableRows || []).filter(({ key }) => {
                return tableSelected.includes(key);
            });
        }

        return [];
    }, [bulkEditorIsVisible, tableRows, tableSelected]);

    const visibleRows = useMemo(() => {
        if (bulkEditorIsVisible) {
            return selectedRows;
        }

        return tableFilteredRows;
    }, [bulkEditorIsVisible, tableFilteredRows, selectedRows]);

    return (
        <Table.Body>
            {!isLoading ? (
                <>
                    {visibleRows.map((row, i) => (
                        <SparkSMSParticipantsTableRow
                            key={i}
                            row={row}
                            selected={tableSelected}
                            sparkType={sparkType}
                            bulkSMSValue={bulkSMSValue}
                            onCheckUncheckUser={tableCheckUncheckRow}
                            bulkEditorIsVisible={bulkEditorIsVisible}
                        />
                    ))}
                </>
            ) : (
                <Table.Loading rows={10} colSpan={tableHeadData?.length + 1} />
            )}
        </Table.Body>
    );
};

type TFormMessages = {
    [posEmployeeProfileId: string]: string;
};

type TFormData = {
    messages: TFormMessages;
};

const _groupMessagesIntoGroups = (messages: TFormMessages) => {
    const messageGroupMap: {
        [message: string]: {
            message: string;
            posEmployeeProfileIds: string[];
        };
    } = {};

    const posEmployeeProfileIds = Object.keys(messages || {});

    posEmployeeProfileIds.forEach((posEmployeeProfileId) => {
        const message = messages[posEmployeeProfileId];

        if (messageGroupMap?.[message] != null) {
            messageGroupMap[message]?.posEmployeeProfileIds.push(posEmployeeProfileId);
        } else {
            messageGroupMap[message] = {
                message,
                posEmployeeProfileIds: [posEmployeeProfileId],
            };
        }
    });

    return Object.values(messageGroupMap);
};

interface ISparkSMSParticipantsTableToolbar {
    searchFilter: string;
    onChangeSearchFilter: (event: any) => void;
    bulkEditorIsVisible: boolean;
    updateBulkEditorIsVisible: (isVisible: boolean) => void;
    onShowBulkCompose: () => void;
}

const SparkSMSParticipantsTableToolbar = ({
    searchFilter,
    onChangeSearchFilter,
    bulkEditorIsVisible,
    updateBulkEditorIsVisible,
    onShowBulkCompose,
}: ISparkSMSParticipantsTableToolbar) => {
    const { handleSubmit, setValue } = useFormContext();
    const { tableSelected, tableSetSelected } = useTableContext();

    const { sendBulksSMSMessagesByPosEmployeeProfileIds } =
        useSendBulksSMSMessagesByPosEmployeeProfileIdsMutation();

    const onSend = (formData: TFormData) => {
        const messageGroups = _groupMessagesIntoGroups(formData?.messages || {});

        sendBulksSMSMessagesByPosEmployeeProfileIds(messageGroups, {
            onSuccess: () => {
                updateBulkEditorIsVisible(false);
                tableSetSelected([]);
            },
            onError: (err: any) => {
                const unsentPosEmployeeProfileIds = err?.data?.unsentPosEmployeeProfileIds || [];
                const unsentMessageGroups = err?.data?.unsentMessageGroups || [];

                tableSetSelected(unsentPosEmployeeProfileIds);

                unsentMessageGroups.forEach(
                    ({
                        message,
                        posEmployeeProfileIds,
                    }: {
                        message: string;
                        posEmployeeProfileIds: string[];
                    }) => {
                        posEmployeeProfileIds.forEach((posEmployeeProfileId) => {
                            setValue(`messages.${posEmployeeProfileId}`, message);
                        });
                    },
                );
            },
        });
    };

    return (
        <Toolbar scrollOnMobile>
            <Toolbar.Search
                className="toolbar-group-start"
                name="search"
                defaultValue={searchFilter}
                onChange={onChangeSearchFilter}
            />
            {bulkEditorIsVisible ? (
                <>
                    <Toolbar.Button
                        startIcon={<EditIcon />}
                        color="blue"
                        variant="smooth"
                        onClick={onShowBulkCompose}
                    >
                        Compose Bulk SMS
                    </Toolbar.Button>
                    <Toolbar.Button
                        startIcon={<SendIcon />}
                        color="green"
                        variant="smooth"
                        onClick={handleSubmit(onSend)}
                    >
                        {`Send SMS (${tableSelected.length})`}
                    </Toolbar.Button>
                    <Toolbar.Button
                        startIcon={<CloseIcon />}
                        color="neutral"
                        variant="smooth"
                        onClick={() => {
                            updateBulkEditorIsVisible(false);
                        }}
                    >
                        Cancel
                    </Toolbar.Button>
                </>
            ) : (
                <Toolbar.Button
                    startIcon={<EditIcon />}
                    color="blue"
                    variant="smooth"
                    disabled={tableSelected?.length === 0}
                    tooltip={
                        tableSelected?.length === 0
                            ? 'Select participants in the table to message.'
                            : null
                    }
                    onClick={() => {
                        updateBulkEditorIsVisible(true);
                    }}
                >
                    Compose SMS
                </Toolbar.Button>
            )}
        </Toolbar>
    );
};

interface ISparkSMSParticipantsTable {
    isLoading: boolean;
    sparkType: SparkType;
    headCells: THeadCell<SparkLeaderboardTableDataRow>[];
    rows: SparkLeaderboardTableDataRow[];
}

// TODO: Update to use Table.Render*
const SparkSMSParticipantsTable = ({
    isLoading,
    sparkType,
    headCells,
    rows,
}: ISparkSMSParticipantsTable) => {
    const { searchFilter, onChangeSearchFilter, applySearch } = useSearch([
        'firstName',
        'lastName',
        'locationStr',
    ]);

    const [bulkEditorIsVisible, setBulkEditorIsVisible] = useState(false);

    const [bulkComposerIsVisible, setBulkComposerIsVisible] = useState(false);

    const [bulkSMSValue, setBulkSMSValue] = useState('');

    return (
        <>
            <Form>
                <TableProvider
                    rows={rows}
                    headCells={combineHeadCells(headCells, bulkEditorIsVisible)}
                    filters={[applySearch]}
                    defaultOptions={{
                        orderBy: 'rank',
                    }}
                >
                    <SparkSMSParticipantsTableToolbar
                        searchFilter={searchFilter}
                        onChangeSearchFilter={onChangeSearchFilter}
                        bulkEditorIsVisible={bulkEditorIsVisible}
                        updateBulkEditorIsVisible={(isVisible: boolean) => {
                            setBulkEditorIsVisible(isVisible);
                            setBulkSMSValue('');
                        }}
                        onShowBulkCompose={() => {
                            setBulkComposerIsVisible(true);
                        }}
                    />
                    <Table
                        useExternalProvider
                        className={bulkEditorIsVisible ? 'table-bulk-editor-visible' : ''}
                        variant="raised"
                        showPagination={false}
                    >
                        <Table.Head showCheckboxes={!bulkEditorIsVisible} />
                        <SparkSMSParticipantsTableBody
                            isLoading={isLoading}
                            sparkType={sparkType}
                            bulkEditorIsVisible={bulkEditorIsVisible}
                            bulkSMSValue={bulkSMSValue}
                        />
                    </Table>
                </TableProvider>
            </Form>
            <ComposeBulkSMSModal
                isVisible={bulkComposerIsVisible}
                onClose={(didUpdate: boolean, newBulkMessageValue?: string) => {
                    if (didUpdate) {
                        setBulkSMSValue(newBulkMessageValue || '');
                    }
                    setBulkComposerIsVisible(false);
                }}
            />
        </>
    );
};

export default SparkSMSParticipantsTable;
