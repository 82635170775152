import { ReactElement } from 'react';

import { OpenInNew } from '@mui/icons-material';

import Button from '@components/buttons/Button';

import Modal from '../Modal';

import './UpgradeModal.scss';

type TConfirmModal = {
    isVisible: boolean;
    title: string;
    content?: string | ReactElement;
    onConfirm: () => void;
    onClose: () => void;
    image?: ReactElement;
};

const UpgradeModalV2 = ({
    isVisible,
    title,
    content,
    onClose,
    onConfirm,
    image,
}: TConfirmModal) => {
    return (
        <Modal className="upgrade-modal-v2" isVisible={isVisible} onClose={onClose} size="wide">
            {image && <div className="upgrade-modal_image">{image}</div>}
            <Modal.Title className="upgrade-modal_title">{title}</Modal.Title>

            <Modal.Content className="upgrade-modal_content">{content}</Modal.Content>

            <Modal.Actions>
                <div className="upgrade-modal_action-buttons">
                    <Button color="neutral" variant="flat" onClick={onClose}>
                        Back
                    </Button>
                    <Button
                        color="blue"
                        variant="filled"
                        startIcon={<OpenInNew />}
                        onClick={onConfirm}
                    >
                        Upgrade Your Plan
                    </Button>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

export default UpgradeModalV2;
