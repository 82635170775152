import axios from 'axios';

import { CreateGiftCardPayload, PublicGiftCard } from '@sparkplug/lib';

import { useAdvancedMutation } from '@hooks/QueryHooks';

import { giftCardsCacheKey } from '../queries/GiftCardsQueries';

export const API = {
    createAndSendGiftCards: async (body: CreateGiftCardPayload) => {
        return (await axios.post(`/api/v1/gift-cards/create-and-send`, body)) as PublicGiftCard;
    },
};

export const useGiftCardsMutations = () => {
    const { mutate: createAndSendGiftCard } = useAdvancedMutation(
        async (payload: CreateGiftCardPayload) => API.createAndSendGiftCards(payload),
        {
            updateQuery: { queryKey: giftCardsCacheKey },
            toastOptions: {
                loading: 'Sending gift card...',
                success: 'Gift card sent!',
                error: 'Failed to send gift card',
            },
        },
    );

    return { createAndSendGiftCard };
};
