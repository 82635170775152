import { FC } from 'react';

import Chip from '@components/chips/Chip';

import './SparkBrandGalleryChip.scss';

interface SparkBrandGalleryChipProps {
    label: string;
    className?: string;
    dense?: boolean;
}
export const SparkBrandGalleryChip: FC<SparkBrandGalleryChipProps> = ({
    label,
    className = '',
    dense = false,
}) => (
    <Chip
        className={`spark-brand-gallery-chip ${className}`.trim()}
        color="blue"
        label={label}
        dense={dense}
    />
);
