export interface CalculatorFilterParameters<SparkType> {
  locationIds: string[];
  spark?: SparkType;
  productInternalKeys?: (string | undefined)[];
  productInternalKeyMap?: Map<string, string>;
  productIds?: (string | undefined)[];
}
export const CALCULATOR_TYPES = [
  'total_units',
  'order_average',
  'total_sales',
  'transaction_count',
  'units_per_transaction',
  'percent_of_total_sales',
  'percent_increase',
] as const;
export type CalculatorType = typeof CALCULATOR_TYPES[number];

export const CALCULATOR_FREQUENCIES = ['daily', 'weekly', 'monthly', 'yearly'] as const;
export type CalculatorFrequency = typeof CALCULATOR_FREQUENCIES[number];

export const VENDOR_CHART_CALCULATORS = ['total_units', 'total_sales'] as const;
export type VendorChartCalculatorType = typeof VENDOR_CHART_CALCULATORS[number];

export const VENDOR_CHART_CALCULATOR_BREAKDOWNS = [
  'total',
  'retailer',
  'location',
  'product',
  'employee',
  'brand',
  'category',
  'none', // TODO: this can eventually be removed when we have all charts using new endpoint pattern
] as const;
export type VendorChartCalculatorBreakdownType = typeof VENDOR_CHART_CALCULATOR_BREAKDOWNS[number];

export const RETAILER_CHART_CALCULATORS = ['total_sales', 'total_units'] as const;
export type RetailerChartCalculatorType = typeof RETAILER_CHART_CALCULATORS[number];

export const RETAILER_CHART_CALCULATOR_BREAKDOWNS = ['location', 'employee'] as const;
export type RetailerChartCalculatorBreakdownType =
  typeof RETAILER_CHART_CALCULATOR_BREAKDOWNS[number];
