import { FC, InputHTMLAttributes, ReactNode } from 'react';

import { FormControl, FormControlLabel, Switch as MaterialUISwitch } from '@mui/material';

import Tooltip from '@components/layout/Tooltip';

import { useFormContext } from '@hooks/FormHooks';

import { appendClasses } from '@helpers/ui';

import { CheckedEventOnChange } from '@app/types/UITypes';

import './Switch.scss';

export interface SwitchProps {
    className?: string;
    color?: 'purple' | 'neutral' | 'blue' | 'green' | 'red';
    variant?: 'outlined' | 'raised' | 'smooth' | 'filled';
    label?: ReactNode;
    formLabel?: string;
    name?: string;
    value: boolean;
    disabled?: boolean;
    onChange?:
        | CheckedEventOnChange
        | ((event: { target: { checked: boolean } }, checked?: boolean) => void);
    tooltip?: string;
    showIcon?: boolean;
}

const Switch: FC<SwitchProps> = ({
    className,
    color = 'neutral',
    variant = 'outlined',
    label,
    name,
    value,
    disabled,
    onChange = () => {},
    tooltip,
    showIcon,
}) => {
    const { register } = useFormContext() || {
        errors: {},
        register: null,
    };

    let inputRef;
    if (register != null) {
        inputRef = register();
    }

    const classNameAppended = appendClasses([
        className,
        'form-control',
        'switch',
        `switch-color-${color}`,
        `switch-variant-${variant}`,
        value ? 'switch-active' : 'switch-inactive',
        showIcon ? 'switch-icon' : '',
    ]);

    let SwitchComponent = (
        <MaterialUISwitch
            inputRef={inputRef}
            name={name}
            checked={value}
            disabled={disabled}
            size="medium"
            inputProps={
                {
                    'data-testid': `switch.${name}`,
                } as InputHTMLAttributes<HTMLInputElement>
            }
        />
    );

    if (label) {
        SwitchComponent = <FormControlLabel control={SwitchComponent} label={label} />;
    }

    SwitchComponent = (
        <FormControl
            className={classNameAppended}
            onClick={() => {
                if (!disabled) {
                    onChange({ target: { checked: !value } }, !value);
                }
            }}
        >
            {SwitchComponent}
        </FormControl>
    );

    if (tooltip) {
        SwitchComponent = <Tooltip title={tooltip}>{SwitchComponent}</Tooltip>;
    }

    return <>{SwitchComponent}</>;
};

export default Switch;
