import { FC, useEffect, useMemo } from 'react';

import { CHART_FREQUENCY_OPTIONS, ChartViewOptions } from '@constants/ChartConstants';
import LocalStorage from '@data/LocalStorage';
import moment, { Moment } from 'moment';

import {
    VendorChartProvider,
    VendorChartSettings,
    useVendorChartContext,
} from '@core/charts/contexts/VendorChartContext';
import { getFrequencyFromPrecision } from '@core/charts/utils/ChartsUtils';

import ComparisonChart from '@components/charts/ComparisonChart';
import { Add as AddIcon, TableChart as TableChartIcon } from '@components/icons';
import MarketIcon from '@components/icons/USMarkets/MarketIcon';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import PageHeader from '@components/layout/PageHeader';
import Paper from '@components/layout/Paper';
import Toolbar from '@components/toolbar/Toolbar';
import { getRangeByStoredValue } from '@components/toolbar/ToolbarDateRangeSelector';

import { useApp } from '@hooks/AppHooks';

import { getStateNameByAbbreviation } from '@helpers/util';

import { IAccount } from '@app/types/AccountsTypes';
import { TChartType } from '@app/types/ChartDataTypes';

import { useDashboardContext } from '../../contexts/DashboardContext';
import { BrandDashboardHeader } from '../components/BrandDashboardHeader';
import VendorMarketChart from './VendorMarketChart';
import ToolbarRetailerDropdown from './components/ToolbarRetailerDropdown';

import './VendorMarketDashboardView.scss';

export const DateRangeKey = 'sparkplug::dashboard::dateRange';
export const ViewKey = 'sparkplug::dashboard::view';
export const MetricKey = 'sparkplug::dashboard::metric';

const ChartBreakdownOptions = [
    {
        label: 'None',
        value: 'total',
    },
    {
        label: 'Retailer',
        value: 'retailer',
    },
    {
        label: 'Location',
        value: 'location',
    },
    {
        label: 'Brand',
        value: 'brand',
    },
];
export interface VendorMarketDashboardViewProps {
    account: IAccount;
    market: string;
}
export const VendorMarketDashboardView: FC<VendorMarketDashboardViewProps> = ({
    account,
    market: unformattedMarket,
}) => {
    const marketLowercased = unformattedMarket.toLocaleLowerCase();
    const marketName = getStateNameByAbbreviation(unformattedMarket.toUpperCase());

    const { history } = useApp();

    const {
        chartSettings,
        updateChartSettings,
        showComparisonWindows,
        setShowComparisonWindows,
        comparisonPeriod,
        setComparisonPeriod,
    } = useVendorChartContext();

    const COMPARETO_BREAKDOWN_REMINDER =
        'The "Compare to" view with "Breakdown" enabled is only available in Table view.';
    const viewOptions = useMemo(
        () => [
            ...ChartViewOptions.map((option) => {
                const disabled = showComparisonWindows && chartSettings.breakdown !== 'total';
                return {
                    ...option,
                    disabled,
                    tooltipProps: disabled ? { title: COMPARETO_BREAKDOWN_REMINDER } : undefined,
                };
            }),
            {
                StartIcon: TableChartIcon,
                label: 'Table',
                value: 'table',
            },
        ],
        [showComparisonWindows, chartSettings.breakdown],
    );

    const selectedRetailers = useMemo(() => {
        const selectedRetailerIdsRecord = Object.fromEntries(
            (chartSettings.retailerAccountIds ?? []).map((retailerAccountId) => [
                retailerAccountId,
                true,
            ]),
        );

        return account?.retailers?.filter(({ _id }) => selectedRetailerIdsRecord[_id]) ?? [];
    }, [chartSettings.retailerAccountIds, account.retailers]);

    const { setDateRange: setCustomContextDateRange } = useDashboardContext();

    const updateDateRange = (dateStart: Moment | null, dateEnd: Moment | null) => {
        setCustomContextDateRange({
            dateStart,
            dateEnd,
        });
        updateChartSettings({
            dateStart: moment(dateStart).format('YYYY-MM-DD'),
            dateEnd: moment(dateEnd).format('YYYY-MM-DD'),
        });
    };

    const updateBrandView = (value: TChartType) => {
        LocalStorage.set(ViewKey, value);
        updateChartSettings({
            type: value,
        });
    };

    /**
     * NOTE: Because the metric is updated by the context `updateChartSettings` function in
     * multiple components, we need to use a useEffect here to locally store the metric value
     * when the user revisits the dashboard.
     * */
    useEffect(() => {
        LocalStorage.set(MetricKey, chartSettings.metric);
    }, [chartSettings.metric]);

    const vendorIsMissingRetailerLinks = useMemo(() => {
        return (
            account?.retailers &&
            !account.retailers.some((retailer) => {
                return retailer.markets.includes(marketName);
            })
        );
    }, [account?.retailers]);

    const marketHeaderLabel = `${marketName} Retailers`;
    const pageHeaderTitle = (
        <BrandDashboardHeader
            brandName={account.name}
            retailerName={marketHeaderLabel}
            icon={<MarketIcon marketName={marketName} />}
        />
    );
    const pageMetaTitle = `${account?.name} Dashboard | ${marketHeaderLabel}`;

    return (
        <div className={`dashboard-view dashboard-${account?.type}-view`}>
            <PageHeader title={pageHeaderTitle} metaTitle={pageMetaTitle} />
            {vendorIsMissingRetailerLinks ? (
                <EmptyStateDisplay
                    addPaperWrapper
                    layout="horizontal"
                    className="add-retailer-empty-state"
                    graphic={
                        <img src="/static/images/add-retailer-placeholder.jpg" alt="placeholder" />
                    }
                    label="Get Real-Time Sales Numbers and Metrics!"
                    smallText="Link retailers to access real-time sales data, reporting, and an analytics dashboard to drive your business forward."
                    actionButton={{
                        label: 'Add Retailer',
                        onClick: () =>
                            history.push(
                                `/${
                                    account?._id
                                }/partners/available?markets=${unformattedMarket.toUpperCase()}`,
                            ),
                        startIcon: <AddIcon />,
                    }}
                />
            ) : (
                <>
                    <Toolbar scrollOnMobile>
                        <Toolbar.Text className="font-weight-400">Filter:</Toolbar.Text>

                        <ToolbarRetailerDropdown
                            account={account}
                            marketAbbreviation={marketLowercased}
                        />

                        <Toolbar.DateRangeSelector
                            id="dashboard-date-range"
                            className="toolbar-group-end"
                            dateStart={chartSettings.dateStart}
                            dateEnd={chartSettings.dateEnd}
                            onApply={updateDateRange}
                            alwaysShowDates
                            selectionStorageKey={DateRangeKey}
                            anchorDirection="right"
                            openDirection="down"
                        />
                    </Toolbar>
                    <Paper className="section">
                        <Toolbar scrollOnMobile>
                            <ComparisonChart.ToolbarDropdownToggle
                                isActive={showComparisonWindows}
                                value={comparisonPeriod}
                                precision={chartSettings.frequency}
                                onToggle={(enabled: boolean) => {
                                    setShowComparisonWindows(enabled);
                                }}
                                onChange={setComparisonPeriod}
                            />
                            <Toolbar.Dropdown
                                label="Breakdown"
                                value={chartSettings.breakdown}
                                onSelect={(value) => updateChartSettings({ breakdown: value })}
                                options={ChartBreakdownOptions}
                                clear={{
                                    active: chartSettings.breakdown !== 'total',
                                    onClear: () => {
                                        updateChartSettings({ breakdown: 'total' });
                                    },
                                }}
                            />
                            {chartSettings.type !== 'table' && (
                                <Toolbar.Dropdown
                                    className="toolbar-group-end"
                                    label="View"
                                    value={chartSettings.frequency}
                                    onSelect={(value) => updateChartSettings({ frequency: value })}
                                    options={CHART_FREQUENCY_OPTIONS}
                                />
                            )}
                            <Toolbar.Dropdown
                                className={
                                    chartSettings.type === 'table' ? 'toolbar-group-end' : ''
                                }
                                label={chartSettings.type === 'table' ? 'View' : ''}
                                value={chartSettings.type}
                                onSelect={updateBrandView}
                                options={viewOptions}
                            />
                        </Toolbar>

                        <VendorMarketChart retailers={selectedRetailers} />
                    </Paper>
                </>
            )}
        </div>
    );
};

export const withVendorMarketChartProvider =
    (Component: FC<VendorMarketDashboardViewProps>): FC<VendorMarketDashboardViewProps> =>
    ({ account, market: unformattedMarket }) => {
        const marketName = getStateNameByAbbreviation(unformattedMarket.toUpperCase());

        const {
            dateStart = moment().subtract(30, 'days').toDate(),
            dateEnd = moment().toDate(),
            precision = 'day',
        } = getRangeByStoredValue(DateRangeKey, '-30days');

        const { dateStart: customDateStart, dateEnd: customDateEnd } = useDashboardContext();

        const defaultVendorView = LocalStorage.get<VendorChartSettings['type']>(ViewKey) ?? 'line';
        const defaultVendorMetric =
            LocalStorage.get<VendorChartSettings['metric']>(MetricKey) ?? 'total_units';

        const initialChartSettings: VendorChartSettings = {
            metric: defaultVendorMetric,
            frequency: getFrequencyFromPrecision(precision),
            type: defaultVendorView,
            dateStart: moment(customDateStart || dateStart).format('YYYY-MM-DD'),
            dateEnd: moment(customDateEnd || dateEnd).format('YYYY-MM-DD'),
            breakdown: 'total',
            retailerAccountIds:
                account.retailers
                    ?.filter(({ markets }) => markets.includes(marketName))
                    ?.map(({ _id }) => _id) ?? [],
        };

        return (
            <VendorChartProvider
                vendorAccountId={account?._id}
                initialSettings={initialChartSettings}
            >
                <Component account={account} market={unformattedMarket} />
            </VendorChartProvider>
        );
    };

export default withVendorMarketChartProvider(VendorMarketDashboardView);
