import { FC } from 'react';

import { BarTooltipProps } from '@nivo/bar';

import SliceTooltip, { IPointLabel, getMutedPointLabel } from '@components/charts/SliceTooltip';
import PercentDifferenceChip from '@components/chips/PercentDifferenceChip';

import { IChartDataPointMeta, IExternalTooltipProps } from '@app/types/ChartDataTypes';

import { ComparisonColors } from './Nivo.Constants';

type TooltipDatum = {
    meta: IChartDataPointMeta;
};

interface IBarTooltipPropsOverride extends BarTooltipProps<TooltipDatum> {}

interface IDefaultBarTooltipProps
    extends BarTooltipProps<TooltipDatum>,
        Omit<IExternalTooltipProps, 'breakdown' | 'isComparisonWindow'> {}

const getSliceValuesFromTooltipData = (
    props: IDefaultBarTooltipProps,
): {
    points: IPointLabel[];
    total: string | number;
} => {
    const {
        formatter,
        totalIsSum,
        locationsMap,

        id,
        value,
        color,
        data,
    } = props;

    let total = 0;
    let nonVisibleBeforeTotal = 0;
    let nonVisibleAfterTotal = 0;

    const ids = Object.keys(data)
        .reverse()
        .filter((key) => !['id', 'meta'].includes(key));
    const points: IPointLabel[] = [];

    let nonVisibleBeforeStartIndex = -1;
    let nonVisibleAfterEndIndex = -1;
    const index = ids.indexOf(id as string);
    const visibleCount = 15;
    if (ids.length > visibleCount) {
        const defaultCountAroundIndex = Math.floor(visibleCount / 2);
        nonVisibleBeforeStartIndex = index - defaultCountAroundIndex;
        nonVisibleAfterEndIndex = index + defaultCountAroundIndex;

        if (nonVisibleBeforeStartIndex < 0) {
            nonVisibleAfterEndIndex += Math.abs(nonVisibleBeforeStartIndex);
        }

        if (nonVisibleAfterEndIndex > ids.length) {
            const afterEndDiff = nonVisibleAfterEndIndex - ids.length;
            nonVisibleBeforeStartIndex -= afterEndDiff;
        }
    }

    // Non-visible totals are still a WIP using this flag
    const showAllPoints = true;

    ids.forEach((key, i) => {
        if (!['id', 'meta'].includes(key)) {
            if (
                showAllPoints ||
                (nonVisibleBeforeStartIndex >= i && nonVisibleAfterEndIndex <= i)
            ) {
                points.push({
                    name: key,
                    color: key !== id ? '#d8d8d8' : color,
                    value: String(formatter ? formatter((data as any)[key]) : value),
                });
            } else if (i < nonVisibleBeforeStartIndex) {
                nonVisibleBeforeTotal += (data as any)[key];
            } else if (i < nonVisibleAfterEndIndex) {
                nonVisibleAfterTotal += (data as any)[key];
            }

            total += (data as any)[key];
        }
    });

    if (!totalIsSum) {
        total /= points.length;
    }

    const totalFormatted = formatter ? formatter(total) : total;
    const nonVisibleBeforeTotalFormatted = String(
        formatter ? formatter(nonVisibleBeforeTotal) : nonVisibleBeforeTotal,
    );
    const nonVisibleAfterTotalFormatted = String(
        formatter ? formatter(nonVisibleAfterTotal) : nonVisibleAfterTotal,
    );

    return {
        total: totalFormatted,
        points: [
            ...(!showAllPoints && nonVisibleBeforeTotal > 0
                ? [
                      getMutedPointLabel(
                          `Next ${ids.length - points.length} products`,
                          nonVisibleBeforeTotalFormatted,
                      ),
                  ]
                : []),
            ...points,
            ...(!showAllPoints && nonVisibleAfterTotal > 0
                ? [
                      getMutedPointLabel(
                          `Next ${ids.length - points.length} products`,
                          nonVisibleAfterTotalFormatted,
                      ),
                  ]
                : []),
        ],
    };
};

const NivoBarTotalsTooltip: FC<IDefaultBarTooltipProps> = (props) => {
    const {
        indexValue,
        data: { meta },
    } = props;

    const { total, points } = getSliceValuesFromTooltipData(props);

    const title = meta?.tooltipTitle || String(indexValue);

    return <SliceTooltip title={title} points={points} total={total} />;
};

const NivoBarBreakdownTooltip: FC<IDefaultBarTooltipProps> = (props) => {
    const {
        indexValue,
        data: { meta },
    } = props;

    const { total, points } = getSliceValuesFromTooltipData(props);

    const title = `${meta?.metricLabel} Sold`;
    const subTitle = meta?.comparisonLabel || meta?.tooltipTitle || String(indexValue);

    return <SliceTooltip title={title} subTitle={subTitle} points={points} total={total} />;
};

function parseComparisonPoints(
    data: IDefaultBarTooltipProps['data'],
    meta: IChartDataPointMeta,
    formatter: IExternalTooltipProps['formatter'],
) {
    const {
        comparisonLabel = '',
        comparisonLabelPrevious = '',
        comparisonDateKey = '',
    } = meta ?? {};

    const currentValue = data?.['*' as keyof typeof data] || 0;
    const previousValue = data?.[comparisonDateKey as keyof typeof data];

    return {
        currentPoint: {
            name: comparisonLabel,
            color: ComparisonColors[0],
            value: formatter ? formatter(currentValue as any) : currentValue,
        },
        previousPoint: previousValue
            ? {
                  name: comparisonLabelPrevious,
                  color: ComparisonColors[1],
                  value: formatter
                      ? formatter(data[comparisonDateKey as keyof typeof data] as any)
                      : data[comparisonDateKey as keyof typeof data],
              }
            : undefined,
    };
}

const NivoBarComparisonTooltip: FC<IDefaultBarTooltipProps> = ({
    formatter,
    data,
    locationsMap,
}) => {
    const { metricLabel: title, comparisonDateKey = '', currentDateKey = '' } = data.meta;

    const locationNames = Object.values(locationsMap);
    const subTitle =
        locationNames.length > 1 ? `All ${locationNames.length} Locations` : locationNames?.[0];

    const { currentPoint, previousPoint } = parseComparisonPoints(data, data.meta, formatter);

    const points = [currentPoint, previousPoint].filter((point) => point) as IPointLabel[];
    const showChip = Boolean(comparisonDateKey && currentDateKey);

    return (
        <SliceTooltip
            className="comparison-slice-tooltip"
            title={title}
            subTitle={subTitle}
            points={points}
            chip={
                showChip ? (
                    <PercentDifferenceChip
                        prevValue={data[comparisonDateKey as keyof typeof data] as any}
                        currentValue={data[currentDateKey as keyof typeof data] as any}
                    />
                ) : (
                    <></>
                )
            }
        />
    );
};

const NivoBarTooltip: any = ({
    formatter,
    totalIsSum,
    locationsMap,
    isComparisonWindow = false,
    breakdown = 'none',
    visibleIds,
}: IExternalTooltipProps) => {
    return (props: IBarTooltipPropsOverride) => {
        if (breakdown !== 'none') {
            return (
                <NivoBarBreakdownTooltip
                    formatter={formatter}
                    totalIsSum={totalIsSum}
                    locationsMap={locationsMap}
                    visibleIds={visibleIds}
                    {...props}
                />
            );
        }

        if (isComparisonWindow) {
            return (
                <NivoBarComparisonTooltip
                    formatter={formatter}
                    totalIsSum={totalIsSum}
                    locationsMap={locationsMap}
                    {...props}
                />
            );
        }

        return (
            <NivoBarTotalsTooltip
                formatter={formatter}
                totalIsSum={totalIsSum}
                locationsMap={locationsMap}
                {...props}
            />
        );
    };
};

export default NivoBarTooltip;
