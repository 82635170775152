import { FC } from 'react';

import LabelWithAdditionalChip from '../LabelWithAdditionalChip';

import './PosEmployeeProfileLocationsLabel.scss';

interface PosEmployeeProfileLocationsLabelProps {
    locationNames?: string[];
}

const PosEmployeeProfileLocationsLabel: FC<PosEmployeeProfileLocationsLabelProps> = ({
    locationNames = [],
}) => {
    const [displayedLocation, ...rest] = locationNames;
    const locationStr = locationNames.join(', ');

    return (
        <LabelWithAdditionalChip
            className="pos-employee-profile-locations-label"
            label={locationNames.length ? displayedLocation : <span>--</span>}
            count={rest.length}
            tooltip={locationStr}
        />
    );
};

export default PosEmployeeProfileLocationsLabel;
