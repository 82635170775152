import { z } from 'zod';

export interface TransactionItem {
  productName: string;
  sparkProductId: string;
  productBrandId: string;
  categoryId: string;
  productCategory: string;
  posBrandName: string;
  qualifiesForSpark: boolean;
  quantity: number;
  cost: number;
  price: number;
  retailerProductId: string;
  retailerSku: string;
  totalIncome: number;
  totalCost: number;
  unitCost: number;
  netSales: number;
  grossSales: number;
  legacyProductId: string;
  voided: boolean;
}

export interface UserTransaction {
  id: string;
  locationId: string;
  locationName: string;
  transactionDate: string;
  bucket: string;
  feesTotal: number;
  type: string;
  subTotal: number;
  discountTotal: number;
  taxesTotal: number;
  voided: boolean;
  finalTotal: number;
  flexibleEmployeeId: string;
  items: TransactionItem[];
  transactionQualifiesForSpark: boolean;
  numQualifyingItems: number;
  numTotalItems: number;
}

export const getUserSparkTransactionsSchema = z.object({
  spark_id: z.string(),
  flexible_employee_id: z.string(),
});

export type GetSparkTransactionRequestParams = z.infer<typeof getUserSparkTransactionsSchema>;

export interface SparkTransactionsForUserResponseBody {
  transactions: UserTransaction[];
}
