import { useMemo } from 'react';

import { IPublicReward } from '@sparkplug/lib';

import { useApp } from '@hooks/AppHooks';
import { useAdvancedQuery } from '@hooks/QueryHooks';

import { getCurrentUserRewards, getRewardById } from '@helpers/rewards';
import { formatNumberToCurrency } from '@helpers/ui';

import { ISparkPayout } from '@app/types/SparksTypes';

export const useCurrentUserRewards = () => {
    const { user } = useApp();

    const {
        isLoading: isLoadingCurrentUserRewards,
        data: currentUserRewards = [],
        refetch: refetchCurrentUserRewards,
    } = useAdvancedQuery(['currentUser', 'rewards'], () => getCurrentUserRewards(), {
        enabled: user?._id != null,
    });

    const validRewards = useMemo(() => {
        return currentUserRewards.filter(({ name, amount, status }) => {
            const isConfirmed = ['confirmed', 'claimed'].includes(status);
            const hasReward = name || amount > 0;

            return isConfirmed && hasReward;
        });
    }, [currentUserRewards]);

    return {
        isLoadingCurrentUserRewards,
        currentUserRewards: validRewards,
        refetchCurrentUserRewards,
    };
};

export const useRewardQuery = (rewardId: string) => {
    const { data: reward } = useAdvancedQuery(
        ['rewards', rewardId],
        () => getRewardById(rewardId),
        {
            enabled: rewardId != null,
        },
    );

    return {
        reward,
    };
};

export function mapRewardData(reward: IPublicReward): ISparkPayout {
    const { amount, posEmployeeProfileId, status, _id, deliveryLink, claimInstructions } = reward;

    const fulfilledBySparkplug = reward?.type === 'payout';

    // API stores amount as cents
    const amountAsDollars = amount / 100;
    const text = fulfilledBySparkplug
        ? formatNumberToCurrency(amountAsDollars, true)
        : reward?.name;

    const payout = {
        _id,
        amount: amountAsDollars,
        fulfilledBySparkplug,
        text,
        posEmployeeProfileId,
        deliveryLink,
        claimInstructions,
        status: status === 'confirmed' ? 'approved' : status,
    } as ISparkPayout;

    return payout;
}

export const useSparkUserReward = (sparkId: string) => {
    const { isLoadingCurrentUserRewards, currentUserRewards, refetchCurrentUserRewards } =
        useCurrentUserRewards();

    const reward = useMemo(() => {
        return currentUserRewards.find(({ sparkId: rewardSparkId }) => {
            return rewardSparkId === sparkId;
        });
    }, [currentUserRewards]);

    const userPayout = useMemo(() => {
        return reward ? mapRewardData(reward) : undefined;
    }, [reward]);

    return {
        rewardIsReady: !isLoadingCurrentUserRewards,
        reward: userPayout,
        refetchReward: refetchCurrentUserRewards,
    };
};
