import { FC, ReactElement } from 'react';

import { EmployeeSpark, Spark, SparkDisplayStatus } from '@sparkplug/lib';

import SparksListGrid from '@components/sparks/SparksListGrid';
import SparksListTable from '@components/sparks/SparksListTable';

import { isEmpty } from '@helpers/util';

import './SparksList.scss';

interface SparksListProps {
    viewMode: string;
    showTrainingIcon?: boolean;
    sparkStatus?: SparkDisplayStatus;
    isUserSparksView?: boolean;
    sparks: Spark[] | EmployeeSpark[];
    onSparkCardSelected: (spark: Pick<Spark, '_id' | 'requestState'>) => void;
    emptyStateComponent: ReactElement;
    isEmployeeView?: boolean;
}

const SparksList: FC<SparksListProps> = ({
    viewMode,
    showTrainingIcon = false,
    isUserSparksView = false,
    sparks,
    sparkStatus,
    onSparkCardSelected,
    emptyStateComponent,
    isEmployeeView = false,
}) => {
    if (isEmpty(sparks) && emptyStateComponent) {
        return emptyStateComponent;
    }

    if (viewMode === 'grid') {
        return (
            <SparksListGrid
                sparkStatus={sparkStatus}
                isEmployeeView={isEmployeeView}
                isUserSparksView={isUserSparksView}
                sparks={sparks}
                showTrainingIcon={showTrainingIcon}
                onSparkCardSelected={onSparkCardSelected}
            />
        );
    }

    if (viewMode === 'list') {
        return <SparksListTable sparks={sparks as Spark[]} onSparkSelected={onSparkCardSelected} />;
    }

    return <></>;
};

export default SparksList;
