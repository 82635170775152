import axios from 'axios';
import { isNaN, isNil, omitBy } from 'lodash';

import { ListRequestsForSparksResponseBody, ListSparkRequestsQueryParams } from '@sparkplug/lib';

import { useAdvancedQuery } from '@hooks/QueryHooks';

import { IAccount } from '@app/types/AccountsTypes';

export const REQUEST_FOR_SPARK_LIMIT_FALLBACK = 1000 as const;
export class RequestForSparksAPI {
    static async getSparkRequests(
        params: ListSparkRequestsQueryParams,
    ): Promise<ListRequestsForSparksResponseBody> {
        return (
            await axios.get(`/api/v1/request-for-spark`, {
                params: {
                    ...omitBy(params, isNil),
                    offset: params.offset ?? 0,
                    limit: params.limit ?? REQUEST_FOR_SPARK_LIMIT_FALLBACK,
                },
            })
        ).data;
    }

    static async getRequestForSparksTotalCount(accountId: string): Promise<number> {
        return (
            await axios.get(`/api/v1/request-for-spark/total`, {
                params: {
                    accountId,
                },
            })
        ).data;
    }
}

const normalizeRequestForQueryParams = (requestForSparkFilters?: ListSparkRequestsQueryParams) => {
    return omitBy(requestForSparkFilters, (value, key) => {
        if (key !== 'limit') return false;
        const limitNumber = Number(value);
        return !isNaN(limitNumber) && limitNumber <= 0;
    });
};
export const getRequestForSparksQueryKey = (
    accountId: string,
    requestForSparkFilters?: ListSparkRequestsQueryParams,
) => ['requestForSpark', 'list', accountId, JSON.stringify(requestForSparkFilters)];
export const useGetRequestsForSparksQuery = ({
    account,
    requestForSparkFilters,
}: {
    account?: IAccount;
    requestForSparkFilters?: ListSparkRequestsQueryParams;
}) => {
    const {
        isLoading: requestsForSparksAreLoading,
        data,
        refetch,
        isFetched,
    } = useAdvancedQuery(
        getRequestForSparksQueryKey(account?._id ?? '', requestForSparkFilters),
        () => {
            const normalizedRequestForSparkQueryParams =
                normalizeRequestForQueryParams(requestForSparkFilters);
            if (account?.type === 'retailer') {
                return RequestForSparksAPI.getSparkRequests({
                    sourceAccountId: account?._id,
                    targetAccountId: undefined,
                    ...normalizedRequestForSparkQueryParams,
                });
            } else {
                return RequestForSparksAPI.getSparkRequests({
                    sourceAccountId: undefined,
                    targetAccountId: account?._id,
                    ...normalizedRequestForSparkQueryParams,
                });
            }
        },
        {
            enabled: !!account?._id,
        },
    );

    return {
        requestsForSparksAreLoading,
        requestsForSparks: data?.data ?? [],
        meta: data?.meta,
        refetchRequestsForSparks: refetch,
        requestsForSparksAreReady: isFetched,
    };
};

export const getRequestForSparksTotalQueryKey = (accountId?: string) => [
    'requestForSpark',
    'total',
    accountId,
];
export const useGetRequestForSparksTotal = ({ account }: { account?: IAccount }) => {
    const { isLoading, data, refetch, isFetched } = useAdvancedQuery(
        getRequestForSparksTotalQueryKey(account?._id),
        () =>
            account?._id
                ? RequestForSparksAPI.getRequestForSparksTotalCount(account?._id)
                : Promise.resolve(0),
    );

    return { isLoading, count: data, refetch, isFetched };
};
