import { ComponentProps, FC, useState } from 'react';

import { useApp } from '@hooks/AppHooks';
import { AccountUserFilters } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { IAccountUser } from '@app/types/UsersTypes';

import EnrollmentStatisticsPanel from '../EnrollmentStatisticsPanel';
import UsersTable from '../UsersTable';
import { UserTableEditHeadCells, UserTableHeadCells } from '../UsersTable/UsersTable';

import './RetailerUsersTable.scss';

const headCells = [
    UserTableHeadCells.checkbox,
    UserTableHeadCells.fullName,
    UserTableHeadCells.locationStr,
    UserTableHeadCells.role,
    UserTableHeadCells.lastTransactionDaysAgo,
    UserTableHeadCells.contact,
    UserTableHeadCells.smsStatus,
];

const bulkEditHeadCells = [
    UserTableEditHeadCells.updateStatus,
    UserTableEditHeadCells.firstName,
    UserTableEditHeadCells.lastName,
    UserTableHeadCells.locationStr,
    UserTableEditHeadCells.role,
    UserTableHeadCells.lastTransactionDaysAgo,
    UserTableEditHeadCells.email,
    UserTableEditHeadCells.phoneNumber,
    UserTableHeadCells.smsStatus,
];

interface IRetailerUsersTableProps
    extends Pick<
        ComponentProps<typeof UsersTable>,
        'variant' | 'account' | 'accountUsers' | 'refetchAccountUsers' | 'isLoadingAccountUsers'
    > {}

const RetailerUsersTable: FC<IRetailerUsersTableProps> = ({
    isLoadingAccountUsers,
    variant = 'raised',
    account,
    accountUsers,
    refetchAccountUsers,
}) => {
    const { appIsMobile } = useApp();
    const [userFilters, setUserFilters] = useState<AccountUserFilters>({
        status: 'all',
        role: 'active',
        location: 'all',
    });

    return (
        <>
            <EnrollmentStatisticsPanel
                users={accountUsers as IAccountUser[]}
                setUserFilters={setUserFilters}
            />

            <UsersTable
                variant={variant}
                account={account}
                isLoadingAccountUsers={isLoadingAccountUsers}
                accountUsers={accountUsers}
                refetchAccountUsers={refetchAccountUsers}
                headCells={
                    appIsMobile
                        ? [UserTableHeadCells.fullName, UserTableHeadCells.smsStatus]
                        : headCells
                }
                bulkEditHeadCells={bulkEditHeadCells}
                searchFields={[
                    'firstName',
                    'lastName',
                    'fullName',
                    'locationStr',
                    'email',
                    'phoneNumber',
                    'phoneNumberFormatted',
                    'posEmployeeProfileNames',
                ]}
                filters={userFilters}
            />
        </>
    );
};

export default RetailerUsersTable;
