import { useMarketOptionsFilter } from '@features/account-links/hooks/useMarketOptionsFilter';

import Button from '@components/buttons/Button';
import { AddCircleOutline as AddIcon } from '@components/icons';
import Tooltip from '@components/layout/Tooltip';
import Toolbar from '@components/toolbar/Toolbar';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import Intercom from '@helpers/Intercom';

const MAX_MARKETS_COUNT = 51; // 50 States + D.C.

const MarketsLabel = ({ markets = [] }: { markets?: string[] }) => {
    const marketsStr = markets.join(', ');

    if (markets.length > 5) {
        const slicedArr = markets.slice(0, 3);
        const remaining = markets.length - slicedArr.length;
        const shortString = `${slicedArr.join(', ')}, ...`;

        return (
            <Tooltip title={marketsStr}>
                <span>
                    {shortString}
                    <em>{`[+${remaining}]`}</em>
                </span>
            </Tooltip>
        );
    }

    return <>{marketsStr}</>;
};

export const MarketsFilter = () => {
    const { account } = useSparkplugAccount();

    const { allAccountMarketOptions, setFilteredMarketOptions, filteredMarketOptions } =
        useMarketOptionsFilter({ account });

    if (allAccountMarketOptions.length <= 1) {
        return <MarketsLabel markets={account?.markets} />;
    }

    const marketsButton = (
        <Button
            className="add-markets-button"
            startIcon={<AddIcon />}
            onClick={() => Intercom.open()}
            variant="outlined"
        >
            Add Markets
        </Button>
    );

    return (
        <Toolbar.DropdownListSelector
            label="Markets"
            options={allAccountMarketOptions}
            selected={filteredMarketOptions}
            onApply={(selected) => setFilteredMarketOptions(selected)}
            addOnComponent={
                allAccountMarketOptions.length < MAX_MARKETS_COUNT ? marketsButton : null
            }
        />
    );
};
