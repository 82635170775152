import { FC, PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider as RQQueryClientProvider } from 'react-query';

import { defaultQueryClientOptions } from '@hooks/QueryHooks';

export const queryClient = new QueryClient({
    defaultOptions: defaultQueryClientOptions,
});

export const QueryClientProvider: FC<PropsWithChildren> = ({ children }) => {
    return <RQQueryClientProvider client={queryClient}>{children}</RQQueryClientProvider>;
};
