import { FC } from 'react';

// eslint-disable-next-line no-restricted-imports
import { InputLabelProps, InputLabel as MuiInputLabel } from '@mui/material';

const InputLabel: FC<InputLabelProps> = ({ shrink, ...props }) => {
    return <MuiInputLabel {...props} />;
};

export default InputLabel;
