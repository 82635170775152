import { ReactElement, useMemo } from 'react';

import { keyBy } from 'lodash';

import { FullChartResponseBody, GetChartResponseBody, TChartDataMetric } from '@sparkplug/lib';

import { useAccountUsersQuery } from '@core/users';

import { useAccountPosDataQuery } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { getChartFactories } from '@helpers/charts';
import {
    isCumulativeChartMetric,
    transformCloudBucketsToDataSetArray,
} from '@helpers/charts/transformCloudChartData';
import { sortByNumber } from '@helpers/ui';

import { IBucketFactory, IChartFactory } from '@app/types/CalculatorTypes';
import {
    ChartDataUser,
    ChartLeader,
    ChartStanding,
    IChartDataSettings,
    TChartData,
    TChartType,
} from '@app/types/ChartDataTypes';
import { IPosLocation } from '@app/types/PosTypes';

import { addUserDataToChartDataLeaders } from '../../helpers/charts/charts';
import { useCloudChartDataQuery } from './useCloudChartDataQuery';

export const transformBucketsToChartStandings = <
    T extends {
        _id: string;
        label: string;
        description?: string | ReactElement;
    },
>({
    objectsKeyedById,
    primaryValues,
    secondaryValues,
}: {
    objectsKeyedById: Record<string, T>;
    primaryValues: { [objectId: string]: number };
    secondaryValues?: { [objectId: string]: number };
}): ChartStanding[] => {
    const chartStanding: ChartStanding[] = [];

    Object.entries(primaryValues).forEach(([objectId, primaryValue]) => {
        const object = objectsKeyedById[objectId];

        if (object) {
            chartStanding.push({
                key: objectId,
                label: object.label,
                description: object.description,
                primaryValue,
                secondaryValue: secondaryValues?.[objectId],
                associatedUsers: [],
            });
        }
    });

    return chartStanding.sort(sortByNumber('primaryValue', 'desc'));
};
export const transformBucketsToChartLeaders = <
    T extends { _id: string; label: string; description?: string | ReactElement },
>({
    chartMetric,
    chartType,
    objectBuckets,
    objectsKeyedById,
    primaryValues,
    secondaryValues,
    missingCommissionValues,
    percentIncreaseComparisons,
    bucketFactory,
    chartFactory,
    leaderType,
    secondaryValueKey,
    includeChartDisplayData = true,
}: {
    chartMetric: TChartDataMetric;
    chartType: TChartType;
    objectBuckets: { [objectId: string]: { [bucketName: string]: number } };
    objectsKeyedById: Record<string, T>;
    primaryValues: { [objectId: string]: number };
    secondaryValues?: { [objectId: string]: number };
    missingCommissionValues?: { [objectId: string]: boolean };
    percentIncreaseComparisons?: {
        currentTotals: { [objectId: string]: number };
        previousTotals: { [objectId: string]: number };
    };
    bucketFactory: IBucketFactory;
    chartFactory: IChartFactory;
    leaderType: ChartLeader['leaderType'];
    secondaryValueKey?: 'unitCount' | 'transactionCount';
    includeChartDisplayData?: boolean;
}): ChartLeader[] => {
    const chartStandings = transformBucketsToChartStandings({
        objectsKeyedById,
        primaryValues,
        secondaryValues,
    });
    const isNonCumulativeMetric = !isCumulativeChartMetric(chartMetric);

    return chartStandings.map(({ key, label, description, primaryValue, secondaryValue }) => {
        const dataset = includeChartDisplayData
            ? transformCloudBucketsToDataSetArray(
                  objectBuckets[key] ?? {},
                  chartType,
                  !isNonCumulativeMetric,
              )
            : [];

        const allDatapoints = dataset.filter(({ value }) => !!value).map(({ value }) => value);
        const maxChartDatapoint = allDatapoints.length ? Math.max(...allDatapoints) : undefined;

        const chartData: TChartData | undefined =
            chartFactory && includeChartDisplayData
                ? chartFactory.generateChartData(bucketFactory, label, dataset)
                : undefined;

        const currentTotal = percentIncreaseComparisons?.currentTotals?.[key] ?? 0;
        const previousTotal = percentIncreaseComparisons?.previousTotals?.[key];
        const percentIncreaseComparison = previousTotal
            ? { currentTotal, previousTotal }
            : undefined;

        const chartLeader: ChartLeader = {
            flexibleEmployeeId: key,
            name: label,
            locations: description ?? '',
            value: primaryValue,
            maxChartDatapoint,
            chartData,
            leaderType,
            percentIncreaseComparison,
            hasMissingCommissions: missingCommissionValues?.[key] ?? false,
        };

        if (secondaryValueKey) {
            chartLeader[secondaryValueKey] = secondaryValue;
        }

        return chartLeader;
    });
};

export const transformCloudChartDataToChartLeaders = ({
    settings,
    cloudChartData,
    posData: { accountUsers },
    includeChartDisplayData = true,
}: {
    settings: IChartDataSettings;
    cloudChartData: GetChartResponseBody;
    posData: {
        accountUsers: ChartDataUser[];
        accountPosLocations: IPosLocation[];
    };
    includeChartDisplayData?: boolean;
}) => {
    const {
        commissions = {},
        employeeBuckets = {},
        employeeCumulativeBuckets,
        employeeTotals = {},
        employeeTransactionCounts,
        employeeComparisonTotals = {},
    } = cloudChartData as FullChartResponseBody;

    const relevantEmployeeBuckets =
        settings.type === 'line' && employeeCumulativeBuckets
            ? employeeCumulativeBuckets
            : employeeBuckets;

    const { bucketFactory, chartFactory } = getChartFactories({ settings });

    const hasCommissionValues = commissions ? Object.keys(commissions)?.length : 0;
    const commissionEarnedValues = hasCommissionValues
        ? Object.entries(commissions).reduce<{ [flexibleEmployeeId: string]: number }>(
              (res, [flexibleEmployeeId, { commissionEarned }]) => {
                  res[flexibleEmployeeId] = commissionEarned;
                  return res;
              },
              {},
          )
        : {};
    const commissionUnitCountValues = hasCommissionValues
        ? Object.entries(commissions).reduce<{ [flexibleEmployeeId: string]: number }>(
              (res, [flexibleEmployeeId, { totalQuantity }]) => {
                  res[flexibleEmployeeId] = totalQuantity;
                  return res;
              },
              {},
          )
        : {};
    const missingCommissionValues = hasCommissionValues
        ? Object.entries(commissions).reduce<{ [flexibleEmployeeId: string]: boolean }>(
              (res, [flexibleEmployeeId, { hasMissingCommissions = false }]) => {
                  res[flexibleEmployeeId] = hasMissingCommissions;
                  return res;
              },
              {},
          )
        : {};

    let percentIncreaseComparisons;
    if (settings.metric === 'percent_increase') {
        percentIncreaseComparisons = {
            currentTotals: Object.entries(employeeComparisonTotals).reduce<{
                [flexibleEmployeeId: string]: number;
            }>((totals, [flexibleEmployeeId, [currentTotal]]) => {
                // eslint-disable-next-line no-param-reassign
                totals[flexibleEmployeeId] = currentTotal;
                return totals;
            }, {}),

            previousTotals: Object.entries(employeeComparisonTotals).reduce<{
                [flexibleEmployeeId: string]: number;
            }>((totals, [flexibleEmployeeId, [, previousTotal]]) => {
                // eslint-disable-next-line no-param-reassign
                totals[flexibleEmployeeId] = previousTotal;
                return totals;
            }, {}),
        };
    }
    const userChartDataLeaders = transformBucketsToChartLeaders({
        chartMetric: settings.metric,
        chartType: settings.type,
        objectBuckets: relevantEmployeeBuckets,
        objectsKeyedById: keyBy(
            accountUsers.map(({ flexibleEmployeeId, fullName, locationNames }) => ({
                _id: flexibleEmployeeId,
                label: fullName,
                description: locationNames?.join(', ') ?? 'N/A',
            })),
            '_id',
        ),
        primaryValues: hasCommissionValues ? commissionEarnedValues : employeeTotals,
        secondaryValues: hasCommissionValues
            ? commissionUnitCountValues
            : employeeTransactionCounts,
        missingCommissionValues,
        percentIncreaseComparisons,
        bucketFactory,
        chartFactory,
        leaderType: 'user',
        secondaryValueKey: hasCommissionValues ? 'unitCount' : 'transactionCount',
        includeChartDisplayData,
    });

    return userChartDataLeaders;
};

export const useChartStandings = ({
    retailerAccountId,
    settings,
    isEnabled = true,
    includeChartDisplayData = true,
}: {
    retailerAccountId: string;
    settings: IChartDataSettings;
    isEnabled?: boolean;
    includeChartDisplayData?: boolean;
}) => {
    // TODO: new chart endpoints currently do not support sparks, or some metrics yet
    const metricNotV2Supoorted = !['total_units', 'total_sales'].includes(settings.metric);
    const breakdownType = settings.sparkId || metricNotV2Supoorted ? undefined : 'employee';
    const { isLoading: isLoadingCloudChartData, data: cloudChartData } = useCloudChartDataQuery(
        retailerAccountId,
        settings,
        isEnabled,
        breakdownType, // TODO: should just be 'employee' when we support spark charts and all metrics on new api endpoints
    );

    const { accountPosLocationsDataIsReady, accountAllPosLocations } = useAccountPosDataQuery(
        retailerAccountId,
        {
            includedDatasets: ['locations'],
        },
    );

    const { accountUsersAreReady, accountUsers } = useAccountUsersQuery(retailerAccountId);

    const chartStandingsAreReady =
        !isLoadingCloudChartData && accountPosLocationsDataIsReady && accountUsersAreReady;

    const chartDataLeaders = useMemo(() => {
        if (!cloudChartData) {
            return [];
        }

        return transformCloudChartDataToChartLeaders({
            settings,
            cloudChartData,
            posData: {
                accountPosLocations: accountAllPosLocations,
                accountUsers: accountUsers.map((accountUser) => ({
                    ...accountUser,
                    value: accountUser.flexibleEmployeeId,
                    label: accountUser.fullName,
                })),
            },
            includeChartDisplayData,
        });
    }, [settings, cloudChartData, accountAllPosLocations, accountUsers]);

    return useMemo(
        () => ({
            chartStandingsAreReady,
            chartDataLeaders: addUserDataToChartDataLeaders(chartDataLeaders, accountUsers),
        }),
        [chartStandingsAreReady, chartDataLeaders, accountUsers],
    );
};
