import { KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { parseCurrencyToNumber, stringIsCurrency } from '@helpers/ui';

import { InputEventOnChange } from '@app/types/UITypes';

const formatToMoneyField = (value: string | number): string => {
    if (!value) {
        return '';
    }

    const trimmedStr = typeof value === 'string' ? value.trim() : value.toString();
    return String(parseCurrencyToNumber(trimmedStr));
};

const formatToTextField = (value: string | number) => {
    if (!value) {
        return value;
    }

    if (typeof value === 'string') {
        const valueHasDollarSign = value[0] === '$';

        if (valueHasDollarSign || (!valueHasDollarSign && !stringIsCurrency(value))) {
            return value;
        }
    }

    return `$${value}`;
};

export const onKeyDown =
    (setError: (value: boolean) => void) => (event: KeyboardEvent<HTMLInputElement>) => {
        const isNumeric = !!event.key.match(/[0-9]/g)?.length;
        const isDecimal = event.key === '.';
        const isValidKey = event.key.length > 1; // Accept keys such as 'backspace', 'delete', 'left arrow', 'right arrow'

        // @ts-ignore event.target.value does exist and event.currentTargetValue does not
        const { value: eventValue } = event.target;
        const hasDecimal = eventValue.indexOf('.') > -1;
        const hasMaxDecimalPoints = eventValue.charAt(eventValue.length - 3) === '.';

        const possibleErrors = [
            isNumeric && hasMaxDecimalPoints,
            hasDecimal && isDecimal,
            !(isNumeric || isDecimal || isValidKey),
        ];

        const hasError = possibleErrors.some((error) => error);

        if (hasError) {
            setError(true);
            event.preventDefault();
            return;
        }

        setError(false);
    };

export const useMoneyField = ({
    isMoneyField,
    moneyFieldIsActive,
    name,
    value = '',
    onChange,
    moneyFieldErrorMessage = 'This field must be a cash value.',
    error: controlledError,
}: {
    isMoneyField: boolean;
    moneyFieldIsActive: boolean;
    name: string;
    value: string;
    onChange: InputEventOnChange;
    moneyFieldErrorMessage?: string;
    error?: boolean;
}) => {
    const [error, setError] = useState(false);
    const updateValue = useCallback(
        (_value: string | number) => {
            if (isMoneyField) {
                onChange({
                    target: {
                        value: formatToTextField(_value),
                    },
                });
            }
        },
        [moneyFieldIsActive],
    );

    const triggerUpdateValue = () => updateValue(value);
    useEffect(() => {
        if (isMoneyField) {
            triggerUpdateValue();
        }
    }, [moneyFieldIsActive]);

    const fieldValue = useMemo(() => {
        if (isMoneyField) {
            return moneyFieldIsActive ? formatToMoneyField(value) : value;
        }

        return null;
    }, [moneyFieldIsActive, value]);

    useEffect(() => {
        if (isMoneyField && fieldValue != null) {
            const rewardField: HTMLInputElement | null = document.querySelector(
                `input[name="${name}"]`,
            );
            if (rewardField?.tagName) {
                rewardField.value = fieldValue;
            }
        }
    }, [fieldValue]);

    const updatedProps = {
        className: 'money-textfield',
        type: 'number',
        error: controlledError ?? error,
        onKeyDown: onKeyDown(setError),
        helperText: error ? moneyFieldErrorMessage : undefined,
    };

    if (moneyFieldIsActive) {
        return updatedProps;
    }

    return {
        className: '',
        error: controlledError,
    };
};
