import moment from 'moment';

export interface IBucket {
    name: string;
    value: number;
}
export type TBucketsMap = Map<string, IBucket>;

export default abstract class BucketFactory {
    abstract getShortName(name: string): string;
    abstract getTooltipTitle(name: string): string;
    abstract getComparisonLabel(name: string): string;
    abstract getBucketName(transaction: any): string;
    abstract getAllBucketNames(dateStart: string | Date, dateEnd: string | Date): string[];

    generateAllBucketsAsMap(dateStart: string | Date, dateEnd: string | Date) {
        const buckets: TBucketsMap = new Map();

        this.getAllBucketNames(dateStart, dateEnd).forEach((name) => {
            buckets.set(name, { name, value: 0 });
        });

        return buckets;
    }

    getCumulativeBuckets(bucketsMap: TBucketsMap, currentBucketName: string) {
        const buckets: IBucket[] = [];

        const allBucketNames: string[] = [...bucketsMap.keys()].sort();

        allBucketNames.forEach((bucketName: string) => {
            const isCurrentBucket = currentBucketName === bucketName;
            const isAfterCurrentBucket = moment(bucketName).isAfter(moment(currentBucketName));
            const bucket = bucketsMap.get(bucketName);

            if ((isCurrentBucket || isAfterCurrentBucket) && bucket) {
                buckets.push(bucket);
            }
        });

        return buckets;
    }
}
