import { FC } from 'react';

import { IPublicUser } from '@sparkplug/lib';

import { useDeleteUsersMultiMutation } from '@core/users';

import { IDefaultModalProps } from '@components/overlays/Modal';

import { IAccount } from '@app/types/AccountsTypes';
import { IAccountUser } from '@app/types/UsersTypes';

import ConfirmModal from './ConfirmModal';

interface DeleteUsersModalProps extends IDefaultModalProps {
    account?: IAccount;
    users: IAccountUser[] | IPublicUser[];
}

const DeleteUsersModal: FC<DeleteUsersModalProps> = ({ isVisible, account, users, onClose }) => {
    const { deleteUsersMulti } = useDeleteUsersMultiMutation();

    if (users == null) {
        return null;
    }

    const message =
        account?._id != null
            ? `Are you sure you wish to delete ${users.length} users?`
            : `Are you sure you wish to purge ${users.length} users from SparkPlug?`;

    const onRemove = () => {
        const userIds = account?._id
            ? (users as IAccountUser[])
                  .filter(({ userId }) => !!userId)
                  .map(({ userId }) => userId!)
            : (users as IPublicUser[]).map(({ _id }) => _id);

        deleteUsersMulti(
            { groupId: account?._id ?? null, userIds },
            { onSuccess: () => onClose(true) },
        );
    };

    return (
        <ConfirmModal
            title="Delete users?"
            message={message}
            isVisible={isVisible}
            onConfirm={onRemove}
            onClose={() => onClose(false)}
        />
    );
};

export default DeleteUsersModal;
