import { ReactElement } from 'react';

import { SparkType } from '@sparkplug/lib';

import { CommissionSparkIcon, GoalSparkIcon, LeaderboardSparkIcon } from '@components/icons';
import Grid from '@components/layout/Grid';
import Modal from '@components/overlays/Modal';

import './SparkPickerModal.scss';

const SPARK_TYPES: { name: string; icon: ReactElement; description: string; view: SparkType }[] = [
    {
        name: 'Commission',
        icon: <CommissionSparkIcon />,
        description: 'Reward employees with a per-unit payout',
        view: 'commission',
    },
    {
        name: 'Leaderboard',
        icon: <LeaderboardSparkIcon />,
        description: 'Employees compete with each other for top prizes',
        view: 'leaderboard',
    },
    {
        name: 'Goal',
        icon: <GoalSparkIcon />,
        description: 'Reward employees and teams that hit sales goals',
        view: 'goal',
    },
];

const SparkPickerModal = ({
    isVisible,
    onClose,
    onNewSpark,
}: {
    isVisible: boolean;
    onClose: (val: boolean) => void;
    onNewSpark: (val: SparkType) => void;
}) => {
    return (
        <>
            <Modal className="spark-picker-modal wide" isVisible={isVisible} onClose={onClose}>
                <Modal.Title onClose={() => onClose(false)}>Create a Spark</Modal.Title>

                <Modal.Content>
                    <Grid>
                        {SPARK_TYPES.map(({ name, icon, description, view }) => {
                            return (
                                <Grid.Item sm={4} key={view}>
                                    <button
                                        type="button"
                                        className="spark-type"
                                        onClick={() => onNewSpark(view)}
                                    >
                                        <div className="spark-type__icon">{icon}</div>
                                        <h3>{name}</h3>
                                        <p className="spark-info">{description}</p>
                                    </button>
                                </Grid.Item>
                            );
                        })}
                    </Grid>
                </Modal.Content>
            </Modal>
        </>
    );
};

export default SparkPickerModal;
