import { WithdrawalType } from '@sparkplug/lib';

import { CashOutMethodIcon } from '../components/CashOutMethodIcon';
import { CashOutMethod } from '../types';

export type CashOutDetailsType = {
    label: string;
    warningMessage?: string;
    icon: any;
    fee: number;
    calloutHeading: string;
    calloutDescription: string;
    infoDetails?: string[];
};

export const ALL_AVAILABLE_METHODS: Record<WithdrawalType, CashOutDetailsType> = {
    [CashOutMethod.VISA]: {
        label: 'Virtual Visa US',
        icon: <CashOutMethodIcon withdrawalMethod={CashOutMethod.VISA} />,
        fee: 0.0,
        calloutHeading: 'Double-check your email',
        calloutDescription:
            'After confirming cash out, a valid <strong>email</strong> is required to receive your Virtual Visa.',
        infoDetails: [
            'Valid in 215 countries, including: USA',
            'Expires in 6 months, non-reloadable',
            'Best for online purchases',
            'Apple Pay / Google Pay compatible',
            'No currency conversion fees',
        ],
    },
    [CashOutMethod.VISA_INTERNATIONAL]: {
        label: 'Virtual Visa International',
        icon: <CashOutMethodIcon withdrawalMethod={CashOutMethod.VISA} />,
        fee: 0.0,
        calloutHeading: 'Double-check your email',
        calloutDescription:
            'After confirming cash out, a valid <strong>email</strong> is required to receive your Virtual Visa.',
    },
    [CashOutMethod.AMAZON]: {
        label: 'Amazon Gift Card',
        icon: <CashOutMethodIcon withdrawalMethod={CashOutMethod.AMAZON} />,
        fee: 0.0,
        calloutHeading: 'Double-check your email',
        calloutDescription:
            'After cashing out, a valid <strong>email</strong> is required to receive your Amazon Gift Card.',
        infoDetails: ['Valid in 9 location, including USA', 'No expiration date'],
    },
    [CashOutMethod.VENMO]: {
        label: 'Venmo',
        warningMessage:
            'Venmo transfer speeds vary and could take 1 to 3 business days to complete.',
        icon: <CashOutMethodIcon withdrawalMethod={CashOutMethod.VENMO} />,
        fee: 0.025,
        calloutHeading: 'Double-check your phone',
        calloutDescription:
            'After confirming cash out, a valid phone number that <strong>matches the phone on your Venmo</strong> account is required.',
    },
    [CashOutMethod.ACH]: {
        label: 'US Bank Deposit',
        warningMessage:
            'Bank deposit transfer speeds vary and could take 3 to 5 business days to complete.',
        icon: <CashOutMethodIcon withdrawalMethod={CashOutMethod.ACH} />,
        fee: 0.025,
        calloutHeading: 'Double-check your bank info',
        calloutDescription:
            'After confirming cash out, a valid US bank <strong>account & routing number</strong> is required.',
    },
};
