import { FC, RefObject, useEffect, useRef, useState } from 'react';

import { TrainingCourseAPI } from '@api/TrainingCourseAPI';
import { clsx } from 'clsx';
import postRobot from 'post-robot';

import { CourseType } from '@sparkplug/lib';

import Skeleton from '@components/layout/Skeleton';
import toast from '@components/toast';

import { useApp, useAppBodyClasses } from '@hooks/AppHooks';
import { useAdvancedQuery } from '@hooks/QueryHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import './TrainingCourseView.scss';

const TrainingIFrame = ({
    className,
    previewModal,
    quizIsOpen,
    courseLoaded,
    iframeRef,
}: {
    className?: string;
    quizIsOpen: boolean;
    previewModal?: boolean;
    courseLoaded?: boolean;
    iframeRef: RefObject<HTMLIFrameElement>;
}) => {
    const isFullscreen = quizIsOpen && !previewModal;

    return courseLoaded ? (
        <iframe
            className={clsx(
                'training-iframe',
                className,
                previewModal && 'is-preview',
                isFullscreen && 'is-fullscreen',
            )}
            title="training embed"
            ref={iframeRef}
        />
    ) : (
        <Skeleton count={5} className="skeleton-course-loading" />
    );
};

interface ZolTrainIFrameProps {
    trainingCourseId: string;
    previewModal?: boolean;
    onComplete: () => void;
}

const ZolTrainIFrame: FC<ZolTrainIFrameProps> = ({
    trainingCourseId,
    previewModal,
    onComplete,
}) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [quizIsOpen, setQuizIsOpen] = useState(false);

    const handleMessage = (event: MessageEvent) => {
        const isValidChildDomain = event.origin === window.location.origin;

        if (isValidChildDomain) {
            const { message } = event.data ?? {};
            switch (message) {
                case 'courseCompleted':
                    onComplete();
                    break;
                case 'zoltrainQuizClosed':
                    setQuizIsOpen(false);
                    break;
                case 'zoltrainQuizOpened':
                    setQuizIsOpen(true);
                    break;
                default:
                    break;
            }
        }
    };

    const { data: queryData, isFetched: courseLoaded } = useAdvancedQuery(
        ['trainingCourse', trainingCourseId],
        () => TrainingCourseAPI.getZoltrainCourse(trainingCourseId),
        { enabled: !!trainingCourseId },
    );

    useEffect(() => {
        if (courseLoaded && queryData) {
            const { blob } = queryData;
            const urlObject = URL.createObjectURL(blob);
            iframeRef.current?.setAttribute('src', urlObject);
        }
    }, [courseLoaded, queryData]);

    useEffect(() => {
        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <TrainingIFrame
            className="zoltrain-iframe"
            iframeRef={iframeRef}
            previewModal={previewModal}
            quizIsOpen={quizIsOpen}
            courseLoaded={courseLoaded}
        />
    );
};

interface SeedTalentIFrameProps {
    trainingCourseId: string;
    previewModal?: boolean;
    onComplete: () => void;
}

const SeedTalentIFrame: FC<SeedTalentIFrameProps> = ({
    trainingCourseId,
    previewModal,
    onComplete,
}) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const didTriggerOnComplete = useRef(false);

    const { data: queryData, isFetched: courseLoaded } = useAdvancedQuery(
        ['trainingCourse', trainingCourseId],
        () => TrainingCourseAPI.getSeedTalentCourse(trainingCourseId),
        { enabled: !!trainingCourseId },
    );

    useEffect(() => {
        if (courseLoaded && queryData) {
            const { courseUrl } = queryData;

            iframeRef.current?.setAttribute('src', courseUrl);
        }
    }, [courseLoaded, queryData]);

    const handleSeedTalentEvent = (event: any) => {
        try {
            const { action, data } = event.data;

            if (action === 'updateCMI') {
                const parsedData = JSON.parse(data.cmiData);

                const isCompleted = parsedData['cmi.completion_status'] === 'completed';
                const didPass = parsedData['cmi.success_status'] === 'passed';

                if (isCompleted && didPass && !didTriggerOnComplete.current) {
                    didTriggerOnComplete.current = true;
                    onComplete();
                }
            }
        } catch (err) {
            //
        }
    };

    useEffect(() => {
        const listener = postRobot.on('scorm', async (event: any) => {
            handleSeedTalentEvent(event);
        });

        const initListener = postRobot.on('initialize', async (event: any) => {
            handleSeedTalentEvent(event);
        });

        return () => {
            initListener.cancel();
            listener.cancel();
        };
    }, []);

    return (
        <TrainingIFrame
            className="seed-talent-iframe"
            iframeRef={iframeRef}
            previewModal={previewModal}
            quizIsOpen
            courseLoaded={courseLoaded}
        />
    );
};

export interface TrainingCourseViewProps {
    trainingCourseId?: string;
    trainingCourseType?: CourseType;
    externalTrainingCourseId: string;
    previewModal?: boolean;
    afterComplete?: () => void;
}

const TrainingCourseView: FC<TrainingCourseViewProps> = ({
    trainingCourseId,
    trainingCourseType = 'zoltrain',
    externalTrainingCourseId,
    previewModal,
    afterComplete = () => {},
}) => {
    const { user } = useApp();
    const { refetchSparkData } = useSpark();

    useAppBodyClasses(['is-training-view'], []);

    if (!user || !trainingCourseId) {
        return <></>;
    }

    const onComplete = async () => {
        // admins can preview the course via the spark details page, but we don't need to store their completion date
        if (!previewModal) {
            TrainingCourseAPI.courseComplete(trainingCourseId, externalTrainingCourseId, user._id)
                .then(() => {
                    refetchSparkData();
                    afterComplete();
                    toast.success('Training complete');
                })
                .catch(() => {
                    toast.error('Error saving course completion date.  Please try again');
                });
        }
    };

    const title = previewModal ? 'Training Course Preview' : '';

    return (
        <>
            {previewModal && (
                <div className="training-preview-title">
                    <div>
                        <h1>{title}</h1>
                    </div>
                </div>
            )}
            {trainingCourseType === 'zoltrain' && (
                <ZolTrainIFrame
                    trainingCourseId={trainingCourseId}
                    previewModal={previewModal}
                    onComplete={onComplete}
                />
            )}
            {trainingCourseType === 'seedTalent' && (
                <SeedTalentIFrame
                    trainingCourseId={trainingCourseId}
                    previewModal={previewModal}
                    onComplete={onComplete}
                />
            )}
        </>
    );
};

export default TrainingCourseView;
