import { isNull, isUndefined } from 'lodash';

import { HydratedVendorPosProduct, PosProductLocationInventory } from '@sparkplug/lib';

import { ExclamationIcon, Info } from '@components/icons';
import Tooltip from '@components/layout/Tooltip';
import Table from '@components/table/Table';

import { IPosProduct } from '@app/types/PosTypes';
import { THeadCell } from '@app/types/TableTypes';

export interface ProductInventoryRow
    extends Omit<HydratedVendorPosProduct | IPosProduct, 'inventory'> {
    key: string;
    categoryName?: string;
    inventory: PosProductLocationInventory;
    locationId?: string;
    locationName: string;
    sparkBrand?: HydratedVendorPosProduct['sparkBrand'];
}

const NotApplicableLabel = () => (
    <div className="not-applicable">
        N/A
        <Tooltip
            placement="right"
            title="Unable to retrieve the inventory information for this product at this time. This does not imply the remaining inventory is 0."
        >
            <Info fontSize="small" />
        </Tooltip>
    </div>
);

const InventoryValueLabel = ({ quantity }: { quantity: number }) => {
    return quantity === 0 ? (
        <div className="text-buttercup-700 flex items-center">
            out-of-stock
            <ExclamationIcon className="pl-1" />
        </div>
    ) : (
        <div>{quantity}</div>
    );
};

export const useProductInventoryHeadCells = (
    isRetailerDashboard: boolean,
): THeadCell<ProductInventoryRow>[] => {
    return [
        {
            id: 'name',
            sortType: 'string',
            label: 'Product',
            render: (row) => {
                return <Table.Cell>{row.name}</Table.Cell>;
            },
        },
        {
            id: 'sparkBrand.name',
            sortType: 'string',
            isHidden: !isRetailerDashboard,
            label: 'Brand',
            render: (row: ProductInventoryRow) => {
                return <Table.Cell>{row.sparkBrand?.name ?? '--'}</Table.Cell>;
            },
        },
        {
            id: 'categoryName',
            sortType: 'string',
            label: 'Category',
            render: (row) => {
                return <Table.Cell>{row.categoryName ?? '--'}</Table.Cell>;
            },
        },
        {
            id: 'locationName',
            isHidden: isRetailerDashboard,
            label: 'Location',
            render: (row) => {
                return <Table.Cell>{row.locationName}</Table.Cell>;
            },
        },
        {
            id: 'inventory.quantityAvailable',
            sortType: 'numeric',
            label: 'Remaining Inventory',
            render: (row) => {
                const quantityAvailable = row.inventory.quantityAvailable;
                const isNotApplicableValue =
                    quantityAvailable < 0 ||
                    isUndefined(quantityAvailable) ||
                    isNull(quantityAvailable);
                return (
                    <Table.Cell>
                        <div className={isNotApplicableValue ? 'not-applicable' : ''}>
                            {isNotApplicableValue ? (
                                <NotApplicableLabel />
                            ) : (
                                <InventoryValueLabel quantity={row.inventory.quantityAvailable} />
                            )}
                        </div>
                    </Table.Cell>
                );
            },
        },
    ];
};
