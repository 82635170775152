import { FC, useState } from 'react';

import { AlertIcon } from '@components/icons';
import Tooltip from '@components/layout/Tooltip';
import MissingCommissionModal from '@components/sparks/MissingCommissionCallout/MissingCommissionModal';

import { useApp } from '@hooks/AppHooks';

import './IndividualMissingCommissionCallout.scss';

interface IndividualMissingCommissionCalloutProps {
    hasMissingCommissions?: boolean;
    sparkId?: string;
}

const IndividualMissingCommissionCallout: FC<IndividualMissingCommissionCalloutProps> = ({
    hasMissingCommissions,
    sparkId,
}) => {
    const { userIsSuperAdmin } = useApp();
    const [modalIsVisible, setModalIsVisible] = useState(false);

    if (!userIsSuperAdmin || !hasMissingCommissions || !sparkId) {
        return <></>;
    }

    return (
        <>
            <Tooltip title="This user has qualifying sales where the product is missing a commission value">
                <AlertIcon
                    onClick={() => setModalIsVisible(true)}
                    className="commission-alert-icon"
                />
            </Tooltip>
            {modalIsVisible && (
                <MissingCommissionModal
                    isVisible={modalIsVisible}
                    sparkId={sparkId}
                    onClose={() => {
                        setModalIsVisible(false);
                    }}
                />
            )}
        </>
    );
};

export default IndividualMissingCommissionCallout;
