import { FC, useState } from 'react';

import { AccountType } from '@sparkplug/lib';

import Dropdown from '@components/dropdown/Dropdown';
import {
    ChatIcon,
    CardGiftcard as GiftCardIcon,
    HelpCenterOutlinedIcon,
    HelpOutline as HelpIcon,
    IdeaIcon,
} from '@components/icons';
import Badge from '@components/layout/Badge';

import { useApp } from '@hooks/AppHooks';
import {
    openHelpCenter,
    useFrillAdminAnnouncementsWidget,
    useFrillAdminFeedbackWidget,
    useFrillEmployeeAnnouncementWidget,
    useFrillEmployeeFeedbackWidget,
} from '@hooks/FrillHooks';

import Intercom from '@helpers/Intercom';

import './DesktopHelpWidget.scss';

const AnnouncementsIcon: FC<{ count: number }> = ({ count }) => (
    <Badge count={count}>
        <GiftCardIcon />
    </Badge>
);

interface DesktopHelpWidgetProps {
    accountType: AccountType;
}

const DesktopHelpWidget: FC<DesktopHelpWidgetProps> = ({ accountType }) => {
    const { isAdminApp } = useApp();

    const adminFeedbackWidget = useFrillAdminFeedbackWidget(accountType);
    const [adminAnnouncementCount, setAdminAnnouncementCount] = useState(0);
    const adminAnnoucementsWidget = useFrillAdminAnnouncementsWidget(accountType, {
        onBadgeCount: ({ count }) => setAdminAnnouncementCount(count),
    });

    const employeeFeedbackWidget = useFrillEmployeeFeedbackWidget();
    const [employeeAnnouncementCount, setEmployeeAnnouncementCount] = useState(0);
    const employeeAnnouncementsWidget = useFrillEmployeeAnnouncementWidget({
        onBadgeCount: ({ count }) => setEmployeeAnnouncementCount(count),
    });

    const contactUsMenuItem = (
        <Dropdown.MenuItem StartIcon={ChatIcon} onClick={() => Intercom.open()}>
            Contact Us
        </Dropdown.MenuItem>
    );

    const helpArticlesMenuItem = (
        <Dropdown.MenuItem StartIcon={HelpCenterOutlinedIcon} onClick={() => openHelpCenter()}>
            Help Articles
        </Dropdown.MenuItem>
    );

    const feedbackMenuItem = (
        <Dropdown.MenuItem
            StartIcon={IdeaIcon}
            onClick={() => {
                if (isAdminApp) {
                    adminFeedbackWidget.current?.open();
                } else {
                    employeeFeedbackWidget.current?.open();
                }
            }}
        >
            Share an Idea
        </Dropdown.MenuItem>
    );

    const announcementsMenuItem = (
        <Dropdown.MenuItem
            StartIcon={() => (
                <AnnouncementsIcon
                    count={isAdminApp ? adminAnnouncementCount : employeeAnnouncementCount}
                />
            )}
            onClick={() => {
                if (isAdminApp) {
                    adminAnnoucementsWidget.current?.open();
                } else {
                    employeeAnnouncementsWidget.current?.open();
                }
            }}
        >
            {`What's New`}
        </Dropdown.MenuItem>
    );

    return (
        <div className="desktop-support-widget">
            <Dropdown>
                <Dropdown.Button
                    data-testid="desktop-support-widget_button"
                    hideExpandIcon
                    color="neutral"
                    variant="flat"
                >
                    <Badge count={isAdminApp ? adminAnnouncementCount : employeeAnnouncementCount}>
                        <HelpIcon />
                    </Badge>
                </Dropdown.Button>
                <Dropdown.Menu
                    className="desktop-support-widget_menu"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    {announcementsMenuItem}

                    {feedbackMenuItem}

                    {isAdminApp && helpArticlesMenuItem}

                    {contactUsMenuItem}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default DesktopHelpWidget;
