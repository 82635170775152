export const DAY_NUMBERS = [0, 1, 2, 3, 4, 5, 6] as const;
export type DayNumber = typeof DAY_NUMBERS[number];

export const RECURRING_SCHEDULE_OPTIONS = ['daily', 'weekly', 'twice_monthly', 'monthly'] as const;
export type RecurringScheduleOption = typeof RECURRING_SCHEDULE_OPTIONS[number];

export interface RecurringSchedule<IntervalOption> {
  interval: IntervalOption;
  startDate: string; // YYYY-MM-DD
  daysOfTheWeek?: DayNumber[];
}
