import { ReactElement, ReactNode, useMemo } from 'react';

import clsx from 'clsx';

import { KeyboardArrowRight } from '@components/icons';
import { Link } from '@components/router';

import Typography from '@app/componentLibrary/Typography';

import Helmet from './Helmet';

import './PageHeader.scss';

const PageHeader = ({
    title,
    heading,
    metaTitle,
    children,
    className,
}: {
    title:
        | ReactElement
        | string
        | (
              | string
              | {
                    url: string;
                    title: string;
                }
          )[];
    heading?: string;
    metaTitle?: string;
    children?: ReactNode;
    className?: string;
}) => {
    const pageTitle = useMemo(() => {
        if (Array.isArray(title)) {
            const titleArr: any[] = [];

            title.forEach((item, i) => {
                if (i > 0) {
                    titleArr.push(<KeyboardArrowRight key={`page-header-arrow-${i}`} />);
                }
                if (typeof item === 'string') {
                    titleArr.push(item);
                } else if (typeof item === 'object') {
                    titleArr.push(
                        <Link key={`page-header-link-${i}`} to={item.url}>
                            {item.title}
                        </Link>,
                    );
                }
            });

            return titleArr;
        }

        return title;
    }, [title]);

    // TODO: Allow pageHeader to use AppHeaderMobile on mobile (?)
    // const pageTitleMobile = React.useMemo(() => {
    //     if (Array.isArray(pageTitle)) {
    //         return [...pageTitle].reverse()?.[0] || '';
    //     }

    //     return pageTitle;
    // }, [pageTitle]);

    // if (appIsMobile) {
    //     return (
    //         <AppHeaderMobile
    //             title={pageTitleMobile}
    //         />
    //     );
    // }

    return (
        <div className={clsx('page-header', className)}>
            <Helmet>
                <title>
                    {metaTitle != null ? `${metaTitle} | SparkPlug` : `${title} | SparkPlug`}
                </title>
            </Helmet>

            {heading ? (
                <div className="flex flex-col">
                    <Typography variant="sm" className="font-medium text-gray-800">
                        {pageTitle}
                    </Typography>

                    <Typography variant="2xl" className="font-bold text-gray-950">
                        {heading}
                    </Typography>
                </div>
            ) : (
                <h1 className="page-title">{pageTitle}</h1>
            )}
            {children}
        </div>
    );
};

export default PageHeader;
