import { useEffect } from 'react';

import { useEmployeeSparks } from '../queries';

export const usePrefetchBaseEmployeeData = ({ canPrefetch }: { canPrefetch: boolean }) => {
    const { prefetchSparks } = useEmployeeSparks({ autoFetch: false });

    useEffect(() => {
        if (canPrefetch) {
            prefetchSparks();
        }
    }, [canPrefetch]);
};
