import axios from 'axios';

import { GetWalletResponseBody } from '@sparkplug/lib';

import { useApp } from '@hooks/AppHooks';
import { GetCacheKeyFn, useAdvancedQuery } from '@hooks/QueryHooks';

const API = {
    getCurrentUserWallet: async () => {
        return (await axios.get<GetWalletResponseBody>('/api/v1/wallet')).data;
    },
};

export const getUserWalletQueryKey: GetCacheKeyFn = (userId) => ['wallet', userId];
export const useCurrentUserWallet = () => {
    const { user } = useApp();

    const { isFetched: currentUserWalletIsReady, data: currentUserWallet } = useAdvancedQuery(
        getUserWalletQueryKey(user?._id!),
        () => API.getCurrentUserWallet(),
        { enabled: !!user?._id },
    );

    return {
        currentUserWalletIsReady,
        currentUserWallet,
    };
};

export const useSparkUserDeposit = (sparkId: string) => {
    const { currentUserWalletIsReady: userDepositIsReady, currentUserWallet } =
        useCurrentUserWallet();

    return {
        userDepositIsReady,
        userDeposit: currentUserWallet?.deposits.find((deposit) => sparkId === deposit.sparkId),
    };
};
