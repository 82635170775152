import { FC } from 'react';

import { KeyboardArrowUp as ChevronUpIcon } from '@components/icons';

import IconButton from '../IconButton';

import './MobileScrollToTopButton.scss';

interface MobileScrollToTopButtonProps {}

const MobileScrollToTopButton: FC<MobileScrollToTopButtonProps> = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="mobile-scroll-to-top-button-container">
            <IconButton className="mobile-scroll-to-top-button" onClick={scrollToTop}>
                <ChevronUpIcon />
            </IconButton>
        </div>
    );
};

export default MobileScrollToTopButton;
