import { FC, PropsWithChildren, ReactElement } from 'react';

import { ISnap } from '@sparkplug/lib';

import { useSnapQuery } from '@features/spark-snaps/queries';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import { CircleMinusIcon, Edit as EditIcon } from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import Drawer from '@components/overlays/Drawer';

import SnapPosterThumbnail from '../../SnapPosterThumbnail';

import './CreateEditSnapDrawer.scss';

type SnapTypes = 'Spark Snap' | 'Snap Template' | 'Spark Snap' | 'Featured Snap';
export const SnapNameFormField: FC<{ snap?: ISnap; showHelper?: boolean }> = ({
    snap,
    showHelper = false,
}) => {
    return (
        <Form.TextField
            key={snap?.storifymeSnapId}
            required
            label="Name"
            name="snapName"
            defaultValue={snap?.name}
            helperText={showHelper ? 'Snap name is visible to participants.' : ''}
        />
    );
};

type CreateSnapDrawerContentProps = {
    snapType: SnapTypes;
    nameFieldOverride?: ReactElement;
};
export const CreateSnapDrawerContent: FC<CreateSnapDrawerContentProps> = ({
    snapType,
    nameFieldOverride,
}) => {
    return (
        <>
            {nameFieldOverride || <SnapNameFormField showHelper={snapType !== 'Snap Template'} />}
            <div className="create-edit-snap-drawer_snap-thumbnail">
                <SnapPosterThumbnail title="Unnamed" slideCount={0} imgSrc="" />
            </div>
        </>
    );
};

type EditSnapDrawerContentProps = {
    onOpenInEditor: (id: number) => void;
    accountId: string;
    snapId: number;
};
export const EditSnapDrawerContent: FC<EditSnapDrawerContentProps> = ({
    accountId,
    snapId,
    onOpenInEditor,
}) => {
    const { snapIsReady, snap, snapThumbnail } = useSnapQuery(
        {
            accountId,
            snapId: snapId as number,
        },
        !!snapId,
    );

    if (!snapIsReady || !snap) {
        return <></>;
    }

    const slideCount = snap?.totalPages ?? 0;

    return (
        <>
            <Form.HiddenField name="snapId" value={String(snapId)} data-testid="snapId" />
            <SnapNameFormField snap={snap} />
            <div className="create-edit-snap-drawer_snap-thumbnail">
                <SnapPosterThumbnail
                    imgSrc={snapThumbnail}
                    title={snap.name || ''}
                    slideCount={slideCount}
                />
                <div className="create-edit-snap-drawer_snap-thumbnail_edit-button">
                    <Button
                        variant="filled"
                        startIcon={<EditIcon />}
                        onClick={() => onOpenInEditor(snapId)}
                    >
                        Edit Snap
                    </Button>
                </div>
            </div>
        </>
    );
};

interface SnapDrawerProps {
    calloutMessage?: string;
    drawerTitle: string;
    isVisible: boolean;
    onClose: () => void;
    onRemove?: () => void;
    onSave: (formData: any) => void;
    snapType: SnapTypes;
    snapId?: number;
    disabled?: boolean;
    error?: string;
}

const CreateEditSnapDrawer: FC<PropsWithChildren<SnapDrawerProps>> = ({
    isVisible,
    onClose,
    onRemove,
    onSave,
    snapType,
    calloutMessage,
    children,
    drawerTitle,
    snapId,
    disabled = false,
    error,
}) => {
    return (
        <Drawer
            title={drawerTitle}
            className="create-edit-snap-drawer"
            variant="right"
            isVisible={isVisible}
            onCloseHandler={onClose}
            data-testid={`create-edit-snap-drawer${snapId || ''}`}
        >
            <CalloutMessage message={calloutMessage || ''} />
            <Form>
                {children}
                <Drawer.Footer>
                    {error && <div className="text-sienna-700 mb-4">{error}</div>}
                    {onRemove && (
                        <div>
                            <Button
                                color="red"
                                onClick={() => onRemove()}
                                startIcon={<CircleMinusIcon />}
                            >
                                <>Remove {snapType}</>
                            </Button>
                        </div>
                    )}
                    <div>
                        <Button color="neutral" onClick={() => onClose()}>
                            Cancel
                        </Button>
                        <Form.Button variant="smooth" onClick={onSave} disabled={disabled}>
                            {snapId ? 'Save' : 'Continue'}
                        </Form.Button>
                    </div>
                </Drawer.Footer>
            </Form>
        </Drawer>
    );
};

export default CreateEditSnapDrawer;
