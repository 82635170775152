import { IOption } from '@app/types/UITypes';

export const SPARK_REQUEST_STATE_OPTIONS: IOption[] = [
    {
        label: 'Pending Sparks',
        value: 'pending',
    },
    {
        label: 'Expired Sparks',
        value: 'expired',
    },
    {
        label: 'Declined Sparks',
        value: 'rejected',
    },
];

export const SPARK_TYPE_OPTIONS: IOption[] = [
    {
        label: 'Commission',
        value: 'commission',
    },
    {
        label: 'Leaderboard',
        value: 'leaderboard',
    },
    {
        label: 'Goal',
        value: 'goal',
    },
];

export const SPARK_SCHEDULE_OPTIONS: IOption[] = [
    {
        label: 'One-time',
        value: 'one-time',
    },
    {
        label: 'Recurs Daily',
        value: 'daily',
    },
    {
        label: 'Recurs Weekly',
        value: 'weekly',
    },
    {
        label: 'Recurs Twice-monthly',
        value: 'twice_monthly',
    },
    {
        label: 'Recurs Monthly',
        value: 'monthly',
    },
];

export const SPARK_TRAINING_OPTIONS: IOption[] = [
    {
        label: 'None',
        value: 'none',
    },
    {
        label: 'Required Training',
        value: 'required',
    },
    {
        label: 'Optional Training',
        value: 'optional',
    },
];
