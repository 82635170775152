import { ComponentProps, FC, ReactElement } from 'react';

import { EmployeeSpark, WalletEmployeeDeposit } from '@sparkplug/lib';

import Button, { IButtonProps } from '@components/buttons/Button';
import { ClaimedIcon, GiftCardIcon, MoneyOffCircleIcon, SandTimerIcon } from '@components/icons';

import { sparkIsCompleted } from '@helpers/sparks';

import { ISparkPayout } from '@app/types/SparksTypes';

import './SparkClaimButton.scss';

interface SparkClaimButtonProps extends Pick<IButtonProps, 'onClick'> {
    showRewardMessage?: boolean;
    spark: EmployeeSpark;
    /**
     * @deprecated remove in favor for `deposit`
     */
    payout?: ISparkPayout;
    deposit?: WalletEmployeeDeposit;
}

const SparkClaimButton: FC<SparkClaimButtonProps> = ({
    showRewardMessage = false,
    spark,
    deposit,
    onClick,
}) => {
    const sparkIsFinalized = !!spark.finalizedAt;
    const sparkHasEnded = sparkIsCompleted(spark);
    const userDidNotGetReward = sparkIsFinalized && !deposit;
    const { status: rewardStatus = '' } = deposit ?? {};
    const isClaimedReward = ['claimed', 'Claimed'].includes(rewardStatus);
    const invoicePaymentFailed =
        spark.internalTracking?.invoiceStatus === 'failed' && !isClaimedReward;
    const rewardsAreCalculating = (!sparkIsFinalized || invoicePaymentFailed) && sparkHasEnded;

    const buttonProps: ComponentProps<typeof Button> = {
        className: 'spark-claim-button',
        color: isClaimedReward ? 'green' : 'blue',
        startIcon: isClaimedReward ? <ClaimedIcon /> : <GiftCardIcon />,
        variant: 'filled',
        disableHover: false,
        onClick: deposit ? onClick : () => {},
    };

    let text = '';
    let rewardMessage: string | ReactElement | undefined;

    if (isClaimedReward) {
        text = 'Reward Claimed';
    } else if (rewardsAreCalculating) {
        buttonProps.color = 'yellow';
        buttonProps.startIcon = <SandTimerIcon />;
        buttonProps.variant = 'smooth';
        buttonProps.disableHover = true;
        rewardMessage = (
            <>
                We’re working on verifying results, please allow <strong>7</strong> business days
                following Spark completion date to receive rewards.
            </>
        );

        text = 'Verifying Results';
    } else if (userDidNotGetReward) {
        buttonProps.color = 'neutral';
        buttonProps.startIcon = <MoneyOffCircleIcon />;
        buttonProps.variant = 'smooth';
        buttonProps.disableHover = true;
        rewardMessage = `You didn't qualify for any rewards on this Spark.`;

        text = 'No Rewards';
    } else {
        text = showRewardMessage ? 'Claim Reward' : 'Unclaimed Reward';
    }

    return (
        <>
            <Button {...buttonProps}>{text}</Button>
            {showRewardMessage && rewardMessage && (
                <span className="spark-claim-button_reward-message">{rewardMessage}</span>
            )}
        </>
    );
};

export default SparkClaimButton;
