import { ComponentType, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AppContext, IAppContext } from '@contexts/AppContext';

import toast from '@components/toast';

// import { verifySmsCode } from '@helpers/auth';

export const useControlCenterApp = () => {
    const { pathname = '' } = useLocation();

    const isControlCenterApp = pathname.includes('control-center');

    return {
        isControlCenterApp,
    };
};

export const useApp = (): IAppContext => {
    const context = useContext(AppContext);
    const history = useHistory();

    if (!context) {
        // eslint-disable-next-line no-console
        console.error(`useSparkplugApp must be used within AppProvider`);
    }

    return {
        ...(context ?? {}),
        history,
    };
};

export const withApp = <P extends unknown>(Component: ComponentType<IAppContext & P>) => {
    return (props: any) => {
        const app = useApp();
        return <Component {...props} {...app} />;
    };
};

export function withMobileView(
    DesktopComponent: any,
    MobileComponent: any,
    mobileFn: (app: IAppContext) => boolean = () => true,
) {
    return (props: any) => {
        const app = useApp();

        const { appIsMobile } = app;

        if (appIsMobile && mobileFn(app)) {
            return <MobileComponent {...props} />;
        }

        return <DesktopComponent {...props} />;
    };
}

type TAuthData = {
    code?: string;
    phoneNumber?: string;
    email?: string;
};

export function useCodeAuth(
    authData: TAuthData,
    codeLength: number = 6,
    showError: boolean = true,
) {
    const { history, setIsAdminApp, verifySmsCode } = useApp();
    const [didInitialSubmit, setDidInitialSubmit] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState<null | string>(null);
    const [codeIsValid, setCodeIsValid] = useState(false);

    const verifyCode = useCallback(() => {
        const { code, phoneNumber, email } = authData;

        if (code?.length === codeLength && (phoneNumber != null || email != null)) {
            setIsSubmitting(true);
            setIsAdminApp(!!email);
            verifySmsCode({ code, phoneNumber, email })
                .then(() => {
                    setIsSubmitting(false);
                    setDidInitialSubmit(true);
                    setCodeIsValid(true);
                    setErrorMessage(null);
                })
                .catch((error) => {
                    setIsSubmitting(false);
                    setDidInitialSubmit(true);
                    setCodeIsValid(false);

                    if (showError) {
                        setErrorMessage(error.message);
                    }

                    if (showError) {
                        toast.error(error.message);
                        history.push('/login');
                    }
                });
        }
    }, [authData?.code, authData?.phoneNumber]);

    useEffect(() => {
        verifyCode();
    }, [verifyCode]);

    return {
        didInitialCodeSubmit: didInitialSubmit,
        isSubmittingCode: isSubmitting,
        codeIsValid,
        verifyCode,
        errorMessage,
    };
}
