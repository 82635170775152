import { ReactElement, useMemo, useState } from 'react';

import { has } from 'lodash';

import SearchField from '@components/inputs/SearchField';
import { NavLink } from '@components/router';

import { appendClasses } from '@helpers/ui';
import { isEmpty } from '@helpers/util';

import List from '../List';

import './AppSidebar.scss';

export function filterSidebarItems({
    items = [],
    searchFilter,
    initialExpandedKeys = [],
}: {
    items: SidebarMenuItem[];
    searchFilter?: string;
    initialExpandedKeys?: string[];
}): SidebarMenuItem[] {
    return items
        .filter(({ isVisible }) => {
            return isVisible != null ? isVisible() : true;
        })
        .filter(({ searchBy = [], children }) => {
            const isLeafNode = !children;
            const isSearchableNode = isLeafNode || !!searchBy.length;
            if (!isSearchableNode || !searchFilter) {
                return true;
            }

            return searchBy
                .map((searchField) => searchField.toLowerCase())
                .join('|')
                .includes(searchFilter.toLowerCase());
        })
        .map((item) => {
            const descendantSidebarItems = item?.children
                ? filterSidebarItems({
                      items: item.children,
                      searchFilter,
                      initialExpandedKeys,
                  })
                : undefined;

            if (has(item, 'isExpanded')) {
                // eslint-disable-next-line no-param-reassign
                item.isExpanded = searchFilter
                    ? true
                    : initialExpandedKeys.includes(item?.key ?? '');
            }

            return {
                ...item,
                children: descendantSidebarItems,
            };
        });
}

export interface SidebarMenuItem {
    key: string;
    title: string | ReactElement;
    startIcon?: ReactElement;
    endIcon?: ReactElement;
    to?: string;
    isBold?: boolean;
    isExpandable?: boolean;
    isExpanded?: boolean;
    isVisible?: () => boolean;
    isActive?: () => boolean;
    onClick?: () => void;
    component?: ReactElement;
    children?: SidebarMenuItem[];
    searchBy?: string[];
}

export type AppSidebarTitle =
    | string
    | {
          startIcon: ReactElement;
          text: string;
      };

const AppSideBarNavLink = ({ menuItem }: { menuItem: SidebarMenuItem }) => {
    const classNamesAppended = appendClasses([
        'app-sidebar_nav-navlink',
        menuItem.startIcon ? 'has-startIcon' : '',
        menuItem.endIcon ? 'has-endIcon' : '',
        menuItem.isBold ? 'is-bold' : '',
        menuItem.isExpandable ? 'is-expandable' : '',
        menuItem.isExpanded ? 'is-expanded' : '',
    ]);

    const startIcon = menuItem.startIcon ? (
        <div className="nav-navlink_start-icon">{menuItem.startIcon}</div>
    ) : undefined;

    const endIcon = menuItem.endIcon ? (
        <div className="nav-navlink_end-icon">{menuItem.endIcon}</div>
    ) : undefined;

    return (
        <>
            {menuItem.to ? (
                <NavLink
                    className={classNamesAppended}
                    activeClassName="is-active"
                    to={menuItem.to}
                    isActive={menuItem?.isActive}
                    onClick={menuItem?.onClick}
                >
                    {startIcon}
                    <span>{menuItem.title}</span>
                    {endIcon}
                </NavLink>
            ) : (
                <span className={classNamesAppended}>
                    {startIcon}
                    <span>{menuItem.title}</span>
                    {endIcon}
                </span>
            )}
        </>
    );
};

const AppSidebarItem = ({ menuItem }: { menuItem: SidebarMenuItem }) => {
    if (menuItem.component) {
        return menuItem.component;
    }

    if (isEmpty(menuItem?.children)) {
        return (
            <h2 className={menuItem?.to != null ? 'is-link' : 'is-not-link'}>
                <AppSideBarNavLink menuItem={menuItem} />
            </h2>
        );
    }

    const classNamesAppended = appendClasses([
        menuItem?.to ? 'is-link' : 'is-not-link',
        menuItem.isExpandable ? 'is-expandable' : '',
        menuItem.isActive?.() ? 'is-active' : '',
    ]);

    const childItemClassName = useMemo(() => {
        if (!menuItem.isExpandable || menuItem.isExpanded) {
            return '';
        }
        return 'items-hidden';
    }, [menuItem.isExpandable, menuItem.isExpanded]);

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions */}
            <h2 onClick={menuItem?.onClick} className={classNamesAppended}>
                <AppSideBarNavLink menuItem={menuItem} />
            </h2>
            <List className={childItemClassName}>
                {menuItem?.children?.map((menuItemChild, j) => (
                    <List.Item key={`child-${j}`}>
                        <AppSidebarItem menuItem={menuItemChild} />
                    </List.Item>
                ))}
            </List>
        </>
    );
};

export interface AppSidebarProps {
    sidebarItems: SidebarMenuItem[];
    className?: string;
    footer?: ReactElement;
    searchable?: boolean;
    title?: AppSidebarTitle;
    expandedKeys?: string[];
}
const AppSidebar = ({
    sidebarItems,
    className = '',
    footer,
    searchable = false,
    title,
    expandedKeys = [],
}: AppSidebarProps) => {
    const [searchFilter, setSearchFilter] = useState<string>();

    const titleComponent =
        typeof title === 'string' ? (
            title
        ) : (
            <span>
                {title?.startIcon}
                {title?.text}
            </span>
        );

    const visibleItems = useMemo(
        () =>
            filterSidebarItems({
                items: sidebarItems,
                searchFilter,
                initialExpandedKeys: expandedKeys,
            }),
        [sidebarItems, searchFilter, expandedKeys],
    );

    return (
        <div className={`app-sidebar ${className}`}>
            {searchable && (
                <SearchField
                    className="sidebar-search-field"
                    name="sidebar-search-field"
                    defaultValue={searchFilter}
                    onChange={(e) => setSearchFilter(e.target.value)}
                />
            )}

            {titleComponent && <div className="app-sidebar_title">{titleComponent}</div>}

            <div className="app-sidebar_nav">
                {visibleItems.map((menuItem, i) => (
                    <AppSidebarItem menuItem={menuItem} key={i} />
                ))}
            </div>

            {footer && <div className="app-sidebar_footer">{footer}</div>}
        </div>
    );
};

export default AppSidebar;
