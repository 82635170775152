import PageLoading from '@components/layout/PageLoading';

const AccountLoadingView = () => {
    return (
        <div>
            <PageLoading label="Loading retailer..." />
        </div>
    );
};

export default AccountLoadingView;
