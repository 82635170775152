import {
    ChangeEvent,
    Context,
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import moment from 'moment';

import { TableContext } from '@contexts/TableContext';

import { queryToString, useQueryParams } from '@components/router';

import { useApp } from '@hooks/AppHooks';

import { ITableContext, ITableRow, TDefaultTableOptions, THeadCell } from '@app/types/TableTypes';
import { TApplyFn } from '@app/types/UITypes';

const getOrderByValue = <V, T extends {} = {}>(obj: T, orderBy: string) => {
    const keys = orderBy.split('.');

    const value: any = keys.reduce((res: any, key) => {
        return res?.[key];
    }, obj);

    return value as V;
};

export const getOrderByValues = <V, T extends {} = {}>(a: T, b: T, orderBy: string) => {
    return [getOrderByValue<V>(a, orderBy), getOrderByValue<V>(b, orderBy)];
};

export const sortTypes: {
    [sortType: string]: <T extends {}>(
        orderBy: string,
        order: 'asc' | 'desc',
    ) => (a: T, b: T) => any;
} = {
    string: (orderBy, order) => {
        return (a, b) => {
            const [orderByA, orderByB] = getOrderByValues<string>(a, b, orderBy);

            try {
                if (!orderByA || !orderByB) {
                    return 0;
                }

                if (orderByA === '') {
                    return 1;
                } else if (orderByB === '') {
                    return -1;
                } else {
                    return order === 'asc'
                        ? orderByA.localeCompare(orderByB)
                        : orderByB.localeCompare(orderByA);
                }
            } catch (err) {
                return 0;
            }
        };
    },
    date: (orderBy, order) => {
        return (a, b) => {
            const [orderByA, orderByB] = getOrderByValues<string>(a, b, orderBy);

            try {
                if (!orderByA || !orderByB) {
                    return 0;
                }

                return order === 'asc'
                    ? moment(orderByA).valueOf() - moment(orderByB).valueOf()
                    : moment(orderByB).valueOf() - moment(orderByA).valueOf();
            } catch (err) {
                return 0;
            }
        };
    },
    numeric: (orderBy, order) => {
        return (a, b) => {
            const [orderByA, orderByB] = getOrderByValues<number>(a, b, orderBy);

            try {
                // Use Number.MIN_SAFE_INTEGER as the special "bottom" value
                if (orderByA === Number.MIN_SAFE_INTEGER && orderByB === Number.MIN_SAFE_INTEGER)
                    return 0;
                if (orderByA === Number.MIN_SAFE_INTEGER) return 1;
                if (orderByB === Number.MIN_SAFE_INTEGER) return -1;

                // Normal comparison for other cases
                if (orderByA == null || orderByB == null) {
                    return 0;
                } else {
                    return order === 'asc' ? orderByA - orderByB : orderByB - orderByA;
                }
            } catch (err) {
                return 0;
            }
        };
    },
    boolean: (orderBy, order) => {
        return (a, b) => {
            const [orderByA] = getOrderByValues<boolean>(a, b, orderBy);

            try {
                const o = orderByA === true ? -1 : 1;

                return order === 'asc' ? o : o * -1;
            } catch (err) {
                return 0;
            }
        };
    },
    arrayLength: (orderBy, order) => {
        return (a, b) => {
            const [orderByA, orderByB] = getOrderByValues<any[]>(a, b, orderBy);
            try {
                if (!orderByA || !orderByB) {
                    return 0;
                } else {
                    return order === 'asc'
                        ? orderByA.length - orderByB.length
                        : orderByB.length - orderByA.length;
                }
            } catch (err) {
                return 0;
            }
        };
    },
};

const applySortRows = <T extends {}>(headCells: THeadCell<T>[]) => {
    return (
        rows: ITableRow<T>[],
        order: 'asc' | 'desc',
        orderBy: string,
        _orderById?: string,
    ): ITableRow<T>[] => {
        const orderById = _orderById ?? orderBy;

        // Override `createdAt` to force `date` filtering
        // in case `createdAt` is not in `headCells`
        if (orderById === 'createdAt') {
            const sortedRows = [...rows];
            sortedRows.sort(sortTypes.date(orderBy, order));
            return sortedRows;
        }

        const currentHeadCell = (headCells || []).find((obj) => obj.id === orderById);

        if (currentHeadCell) {
            const { sortType } = currentHeadCell;
            const sortedRows = [...rows];

            if (sortType) {
                sortedRows.sort(sortTypes[sortType](orderBy, order));
            }

            return sortedRows;
        }

        return rows;
    };
};

export interface UseTableProps<T> {
    rows: ITableRow<T>[];
    headData: THeadCell<T>[];
    filters: TApplyFn[];
    defaultOptions: TDefaultTableOptions;
    isLoading: boolean;
    defaultSelected?: string[];
    enableColumnResizing?: boolean;
    controlled?: {
        selected: string[];
        setSelected: Dispatch<SetStateAction<string[]>>;
    };
    // This prop will set the page off of a query parameter instead of the internal state.
    // Keep in mind this is frontend only and requires more work for serverside pagination.
    // An example of an implentation that uses this is the table at /[accountId]/partners
    enableQueryParams?: boolean;
    // This prop in conjunction with `enableQueryParams` will allow for serverside pagination & filtering.
    disableFrontendFiltering?: boolean;
}

export const useTable = <T extends {}>({
    rows = [],
    headData = [],
    filters = [],
    defaultOptions = { orderBy: 'createdAt' },
    isLoading = false,
    defaultSelected = [],
    controlled,
    enableColumnResizing = false,
    enableQueryParams = false,
    disableFrontendFiltering = false,
}: UseTableProps<T>) => {
    const [orderById, setOrderById] = useState<string>();
    const {
        p,
        limit,
        order: queryParamsOrder,
        orderBy: queryParamsOrderBy,
        // This rest operator will allow for any other query params to be passed through
        // That why, this hook will not drop any query params that are not used by this hook.
        ...restQueryParams
    } = useQueryParams();
    const [order, setOrder] = useState<'asc' | 'desc'>(
        queryParamsOrder || defaultOptions?.order || 'asc',
    );
    const [orderBy, setOrderBy] = useState(queryParamsOrderBy || defaultOptions?.orderBy || '');
    const [page, setPage] = useState<number>((p && Number(p)) || 0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        (limit && Number(limit)) || defaultOptions?.rowsPerPage || 10,
    );
    const { history } = useApp();

    const [selected, setSelected] = useState<string[]>(defaultSelected);
    const updateSelected = controlled ? controlled.setSelected : setSelected;

    const applyTableSort = useCallback(
        (unsortedRows: ITableRow<T>[]) =>
            applySortRows(headData)(unsortedRows, order, orderBy, orderById),

        [headData, order, orderBy, orderById],
    );

    const filteredRows = useMemo(() => {
        return [...filters, applyTableSort].reduce((res, filter) => {
            return filter(res);
        }, rows);
    }, [rows, filters, applyTableSort]);

    const pageRows = useMemo(() => {
        if (rowsPerPage === -1 || disableFrontendFiltering) {
            return filteredRows;
        }

        const offset = page * rowsPerPage;
        const end = Math.min(filteredRows.length, page * rowsPerPage + rowsPerPage);
        return filteredRows.slice(offset, end);
    }, [filteredRows, page, rowsPerPage, headData]);

    // Only revert to first page is current page is empty
    useEffect(() => {
        if (!pageRows.length) {
            setPage(0);
        }
    }, [filters]);

    useEffect(() => {
        if (!enableQueryParams || isLoading) return;
        if (Number(p) && Number(p) !== page) {
            setPage(Number(p));
            return;
        }
        if (!p) {
            history.push({
                search: `${queryToString({
                    p: Number(page),
                    limit: rowsPerPage,
                    order,
                    orderBy,
                    ...restQueryParams,
                })}`,
            });
        }
    }, [p, isLoading, enableQueryParams]);

    const onChangeOrderBy = (
        property: string,
        optionalParams: { id?: string; order?: 'asc' | 'desc' } = {},
    ) => {
        const { id, order: _order } = optionalParams;
        const isAsc = orderBy === property && order === 'asc';

        setOrder(_order ?? (isAsc ? 'desc' : 'asc'));
        setOrderBy(property);
        setOrderById(id);
        if (enableQueryParams) {
            const qs = {
                search: `${queryToString({
                    p,
                    limit: rowsPerPage,
                    ...restQueryParams,
                    order: _order ?? (isAsc ? 'desc' : 'asc'),
                    orderBy: property,
                })}`,
            };
            history.push(qs);
        }
    };

    const onChangePage = (event: any, newPage: number) => {
        if (enableQueryParams) {
            history.push({
                search: `${queryToString({
                    p: Number(newPage),
                    limit: rowsPerPage,
                    order,
                    orderBy,
                    ...restQueryParams,
                })}`,
            });
        }
        setPage(newPage);
    };

    const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        const rowsPerPageValue = parseInt(event.target.value, 10);
        if (enableQueryParams) {
            history.push({
                search: `${queryToString({
                    p: 0,
                    limit: rowsPerPageValue,
                    order,
                    orderBy,
                    ...restQueryParams,
                })}`,
            });
        }
        setRowsPerPage(rowsPerPageValue);

        setPage(0);
    };

    const onCheckUncheckRow = ({ key }: { key: any }) => {
        updateSelected((prevSelected) => {
            return prevSelected.includes(key)
                ? prevSelected.filter((str) => str !== key)
                : [...prevSelected, key];
        });
    };

    const uncheckAll = () => {
        updateSelected([]);
    };

    return {
        tableIsLoading: isLoading,
        tableRows: rows,
        tableFilteredRows: filteredRows,
        tablePageRows: pageRows,
        tableRowsPerPage: rowsPerPage,
        tableOrder: order,
        tableOrderBy: orderBy,
        tablePage: page,
        tableChangeOrderBy: onChangeOrderBy,
        tableChangePage: onChangePage,
        tableChangeRowsPerPage: onChangeRowsPerPage,
        tableSelected: controlled ? controlled.selected : selected,
        tableSetSelected: updateSelected,
        tableCheckUncheckRow: onCheckUncheckRow,
        tableUncheckAll: uncheckAll,
        applyTableSort,
        tableHeadData: headData,
        enableColumnResizing,
    };
};

export const useTableContext = <T extends {}>() => {
    const context = useContext<ITableContext<T>>(
        TableContext as unknown as Context<ITableContext<T>>,
    );

    if (context == null) {
        // eslint-disable-next-line no-console
        console.log('Error: useTableContext must be used within TableProvider');
    }

    return context;
};
