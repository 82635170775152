import PinInput from 'react-pin-input';

import { appendClasses } from '@helpers/ui';

import './PinInputField.scss';

interface IPinInputFieldProps {
    className?: string;
    length: number;
    defaultValue?: string;
    onChange: (value: string, index?: number) => void;
}

const PinInputField = ({ className, length, defaultValue, onChange }: IPinInputFieldProps) => {
    const classNamesAppended = appendClasses(['pin-input-field', className]);

    const props = {
        length,
        initialValue: defaultValue,
        inputStyle: {
            margin: undefined,
            padding: undefined,
            height: undefined,
            width: undefined,
            border: undefined,
        },
        inputFocusStyle: {
            border: undefined,
        },
    };

    return (
        <div className={classNamesAppended}>
            <PinInput
                type="custom"
                inputMode="numeric"
                onChange={onChange}
                onComplete={onChange}
                {...props}
            />
        </div>
    );
};

export default PinInputField;
