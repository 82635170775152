import { useCallback, useState } from 'react';

import { Box } from '@mui/material';
import SettingsTitle from '@views/admin/components/SettingsTitle';
import { isEqual } from 'lodash';

import { UserSettings } from '@sparkplug/lib';

import { useSaveUserProfileMutation } from '@core/users';

import Form from '@components/form/Form';
import AppView from '@components/layout/AppView';
import Paper from '@components/layout/Paper';

import { useApp } from '@hooks/AppHooks';

import { isEmpty, setObjectProperty } from '@helpers/util';

import './AdminUserProfileView.scss';

const AdminUserProfileView = () => {
    const { user, refetchUserAppData } = useApp();
    const { firstName, lastName, email, phoneNumber, userSettings } = user || {};

    const [userData, setUserData] = useState<{
        firstName?: string;
        lastName?: string;
        email?: string;
        phoneNumber?: string;
        password?: string;
        pwdCheck?: string;
        userSettings?: UserSettings;
    }>({ firstName, lastName, email, phoneNumber, userSettings });

    const { saveUserProfile } = useSaveUserProfileMutation({});

    const updateUserData = (path: string, value: any) => {
        setUserData((prevUserData) => setObjectProperty(prevUserData, { path, value }));
    };

    const onSave = useCallback(async () => {
        saveUserProfile(
            { userParams: userData, userId: user?._id },
            {
                onSuccess: () => {
                    refetchUserAppData();
                },
            },
        );
    }, [userData]);

    return (
        <AppView.Content title={<SettingsTitle label="Plan" />}>
            <Paper className="edit-user-profile">
                <Form>
                    <Form.Grid
                        className="edit-profile-content"
                        direction="row"
                        justifyContent="space-between"
                    >
                        <Form.GridItem xs={12} sm={6} className="edit-profile_personal-info">
                            <Form.SectionTitle>Personal Information</Form.SectionTitle>

                            <Form.Grid>
                                <Form.GridItem xs={12} sm={6}>
                                    <Form.TextField
                                        label="First Name"
                                        name="firstName"
                                        defaultValue={userData.firstName || ''}
                                        onChange={(event) => {
                                            updateUserData('firstName', event.target.value);
                                        }}
                                    />
                                </Form.GridItem>

                                <Form.GridItem xs={12} sm={6}>
                                    <Form.TextField
                                        label="Last Name"
                                        name="lastName"
                                        defaultValue={userData.lastName || ''}
                                        onChange={(event) => {
                                            updateUserData('lastName', event.target.value);
                                        }}
                                    />
                                </Form.GridItem>

                                <Form.GridItem xs={12} sm={6}>
                                    <Form.TextField
                                        label="Email"
                                        name="email"
                                        defaultValue={userData.email || ''}
                                        onChange={(event) => {
                                            updateUserData('email', event.target.value);
                                        }}
                                    />
                                </Form.GridItem>

                                <Form.GridItem xs={12} sm={6}>
                                    <Form.PhoneField
                                        label="Phone Number"
                                        name="phoneNumber"
                                        defaultValue={userData.phoneNumber || ''}
                                        disabled
                                        onChange={(event) => {
                                            updateUserData('phoneNumber', event.target.value);
                                        }}
                                    />
                                </Form.GridItem>
                            </Form.Grid>
                        </Form.GridItem>

                        <Form.GridItem xs={12} sm={6} className="edit-profile_password-reset">
                            <Form.SectionTitle>Reset Password</Form.SectionTitle>

                            <Form.Grid>
                                <Form.GridItem xs={12} sm={6}>
                                    <Form.TextField
                                        type="password"
                                        label="New Password"
                                        name="password"
                                        onChange={(event) => {
                                            updateUserData('password', event.target.value);
                                        }}
                                    />
                                </Form.GridItem>
                                <Form.GridItem xs={12} sm={6}>
                                    <Form.TextField
                                        type="password"
                                        label="Confirm Password"
                                        name="pwdCheck"
                                        onChange={(event) => {
                                            updateUserData('pwdCheck', event.target.value);
                                        }}
                                    />
                                </Form.GridItem>
                            </Form.Grid>
                        </Form.GridItem>

                        <Form.GridItem xs={12} sm={6} className="edit-profile_notifications">
                            <Form.SectionTitle className="notification-section-title">
                                <span className="main">Notifications</span>
                                <span className="email">Email</span>
                            </Form.SectionTitle>
                            <Box gap={2} display="flex" flexDirection="column">
                                <Form.LabeledSwitch
                                    title="Email Notifications"
                                    subtitle="Get notified when there are changes to Sparks and/or Brand
                                        Links"
                                    name="emailNotificationsDisabled"
                                    value={!userData.userSettings?.emailNotificationsDisabled}
                                    onChange={(event: any) => {
                                        const isChecked = event.target.checked;
                                        updateUserData(
                                            'userSettings.emailNotificationsDisabled',
                                            !isChecked,
                                        );
                                    }}
                                />
                                <Form.LabeledSwitch
                                    title="Weekly Digest Notifications"
                                    subtitle="Get weekly emails detailing your Spark activity"
                                    name="weeklyDigestEnabled"
                                    value={!!userData.userSettings?.weeklyDigestEnabled}
                                    onChange={(event: any) => {
                                        const isChecked = event.target.checked;
                                        updateUserData(
                                            'userSettings.weeklyDigestEnabled',
                                            isChecked,
                                        );
                                    }}
                                />
                            </Box>
                        </Form.GridItem>
                    </Form.Grid>

                    <section className="edit-profile_buttons">
                        <Form.Button
                            color="blue"
                            variant="filled"
                            disabled={
                                isEmpty(userData) ||
                                isEqual(userData, {
                                    firstName: user?.firstName,
                                    lastName: user?.lastName,
                                    email: user?.email,
                                    phoneNumber: user?.phoneNumber,
                                    userSettings: user?.userSettings,
                                })
                            }
                            onClick={onSave}
                        >
                            Update Profile
                        </Form.Button>
                    </section>
                </Form>
            </Paper>
        </AppView.Content>
    );
};

export default AdminUserProfileView;
