import Button from '@components/buttons/Button';
import {
    CheckBox as CheckBoxChecked,
    CheckBoxOutlineBlank as CheckBoxUnchecked,
} from '@components/icons';

import { appendClasses } from '@helpers/ui';

import InputLabel from '../InputLabel';

import './DayOfWeekPicker.scss';

const weekDayOptions = [
    {
        value: 0,
        label: 'Sun',
    },
    {
        value: 1,
        label: 'Mon',
    },
    {
        value: 2,
        label: 'Tue',
    },
    {
        value: 3,
        label: 'Wed',
    },
    {
        value: 4,
        label: 'Thu',
    },
    {
        value: 5,
        label: 'Fri',
    },
    {
        value: 6,
        label: 'Sat',
    },
];

interface DayOfWeekPickerProps {
    label?: string;
    value: number[];
    variant?: 'flat' | 'filled';
    onChange: (value: number[]) => void;
    disabled?: boolean;
}

const DayOfWeekPicker = ({ label, value, variant, onChange, disabled }: DayOfWeekPickerProps) => {
    const classNamesAppended = appendClasses([
        'day-of-week-picker',
        `day-of-week-picker-variant-${variant}`,
    ]);

    const selectedBtnVariant = variant ?? 'smooth';

    const onToggleDayOfWeek = (day: { value: number; label: string }) => {
        const updatedDaysOfWeek = value.slice(0);
        if (!value.includes(day.value)) {
            updatedDaysOfWeek.push(day.value);
            updatedDaysOfWeek.sort((a, b) => {
                return a - b;
            });
        } else {
            updatedDaysOfWeek.splice(value.indexOf(day.value), 1);
        }

        onChange(updatedDaysOfWeek);
    };

    return (
        <div className={classNamesAppended}>
            {label && (
                <InputLabel className="day-of-week-picker_label" shrink required>
                    {label}
                </InputLabel>
            )}
            <article>
                {weekDayOptions.map((day, idx) => {
                    const isSelected = value.includes(day.value);
                    const CheckboxIcon = isSelected ? CheckBoxChecked : CheckBoxUnchecked;

                    return (
                        <Button
                            dense
                            key={idx}
                            disabled={disabled}
                            className={`week-day ${value.includes(day.value) ? 'selected' : ''}`}
                            color={value.includes(day.value) ? 'blue' : 'neutral'}
                            variant={value.includes(day.value) ? selectedBtnVariant : 'smooth'}
                            startIcon={<CheckboxIcon />}
                            onClick={() => onToggleDayOfWeek(day)}
                        >
                            {day.label}
                        </Button>
                    );
                })}
            </article>
        </div>
    );
};

export default DayOfWeekPicker;
