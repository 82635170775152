import { z } from 'zod';

export const courseTypes = ['zoltrain', 'seedTalent'] as const;
export const courseTypeEnumSchema = z.union([z.enum(courseTypes), z.undefined()]);
export type CourseType = typeof courseTypes[number];

const courseCompleteUserSchema = z.record(
  z.string(),
  z.preprocess((d) => {
    return typeof d === 'string' ? new Date(d) : undefined;
  }, z.date().optional()),
);
export type CourseCompleteUser = z.infer<typeof courseCompleteUserSchema>;

export const courseCompleteUsersSchema = z.union([
  z.record(z.string(), courseCompleteUserSchema),
  z.undefined(),
]);
export type CourseCompleteUsers = z.infer<typeof courseCompleteUsersSchema>;

const courseViewedUserSchema = z.record(z.string(), z.boolean().optional());
export type CourseViewedUser = z.infer<typeof courseViewedUserSchema>;

export const courseViewedUsersSchema = z.union([
  z.record(z.string(), courseViewedUserSchema),
  z.undefined(),
]);
export type CourseViewedUsers = z.infer<typeof courseViewedUsersSchema>;

export interface ITrainingCourse {
  accessToken: string;
  courseDescription: string;
  courseId: string;
  courseLength?: number;
  courseTitle?: string;
  completedUsersByCourseId?: CourseCompleteUsers;
  viewedUsersByCourseId?: CourseViewedUsers;
  courseType: CourseType;
  required?: boolean;
}

export const createTrainingCourseSchema = z.object({
  accessToken: z.string().min(1),
  courseDescription: z.string().min(1),
  courseId: z.string().min(1),
  courseLength: z.preprocess((cl) => {
    if (!cl) {
      return undefined;
    }
    return typeof cl === 'number' ? cl : parseInt(z.string().parse(cl), 10);
  }, z.number().optional()),
  courseType: courseTypeEnumSchema,
  courseTitle: z.string().optional(),
  completedUsersByCourseId: courseCompleteUsersSchema.optional(),
  viewedUsersByCourseId: courseViewedUsersSchema.optional(),
  sparkId: z.string().optional(),
  enabled: z.boolean().optional(),
  required: z.boolean().optional(),
});

export type CreateTrainingCourseRequestBody = z.infer<typeof createTrainingCourseSchema>;
export interface CreateTrainingCoursePathParams {
  sparkId: string;
}

export const updateTrainingCourseSchema = createTrainingCourseSchema.partial();
export type UpdateTrainingCourseRequestBody = z.infer<typeof updateTrainingCourseSchema>;
export interface UpdateTrainingCoursePathParams {
  trainingCourseId: string;
}

export interface CompleteTrainingCoursePathParams extends UpdateTrainingCoursePathParams {}
export interface ViewedTrainingCourseNoticePathParams extends UpdateTrainingCoursePathParams {}

export interface GetTrainingCourseRequestParams extends UpdateTrainingCoursePathParams {}
export type GetSeedTalentTrainingCourseResponseBody = {
  courseUrl: string;
};
export type GetZoltrainTrainingCourseResponseBody = string;

export interface CompleteTrainingCourseRequestBody {
  userId: string;
  externalTrainingCourseId: string;
}
export interface ViewedTrainingCourseRequestBody extends CompleteTrainingCourseRequestBody {}
