import { noop } from 'lodash';

import Button from '@components/buttons/Button';
import { PlusIcon } from '@components/icons';

export const CreateCourseButton = () => {
    return (
        <Button variant="filled" color="blue" startIcon={<PlusIcon />} onClick={noop}>
            Create Course
        </Button>
    );
};
