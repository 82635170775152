import { FC, ReactElement } from 'react';

import { clsx } from 'clsx';

import { Spark } from '@sparkplug/lib';

import { PlaceholderBrandLogo } from '@components/logos';

import './SparkAccountDisplay.scss';

export interface SparkAccountDisplayProps {
    name?: string;
    photo?: string;
    isMobile?: boolean;
    spark?: Spark;
    afterIcon?: ReactElement;
}

export const SparkAccountDisplay: FC<SparkAccountDisplayProps> = ({
    name,
    photo,
    isMobile = false,
    afterIcon,
}) => {
    return (
        <>
            <div className={clsx('account-display', isMobile ? 'mobile' : 'desktop')}>
                {photo ? (
                    <img className="account-logo" src={photo ?? ''} alt="Logo" />
                ) : (
                    <PlaceholderBrandLogo className="account-placeholder-logo" />
                )}
                {name}
                {afterIcon}
            </div>
        </>
    );
};
