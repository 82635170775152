import { MouseEvent, ReactNode, RefObject, forwardRef, useEffect, useState } from 'react';

import { Divider } from '@mui/material';

import { appendClasses } from '@helpers/ui';

import DropdownButton from './DropdownButton';
import { DropdownContext } from './DropdownContext';
import DropdownEmojiPicker from './DropdownEmojiPicker';
import DropdownIconButton from './DropdownIconButton';
import DropdownListSelector from './DropdownListSelector';
import DropdownMenu from './DropdownMenu';
import DropdownMenuItem from './DropdownMenuItem';
import DropdownPopover from './DropdownPopover';

import './Dropdown.scss';

const DropdownMenuDivider = () => {
    return <Divider variant="middle" />;
};

export interface IDropdownProps {
    color: 'neutral' | 'blue' | 'green' | 'red';
    variant: 'flat' | 'filled' | 'raised' | 'smooth' | 'outlined';
    disabled: boolean;
    children: ReactNode;
    className?: string;
    initializeExpanded?: {
        buttonRef: RefObject<HTMLButtonElement> | null;
    };
    onClose?: () => void;
}

const Dropdown = ({
    children,
    className,
    initializeExpanded,
    onClose = () => {},
    ...props
}: IDropdownProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(undefined);
        onClose();
    };

    const appendedClassName = appendClasses(['dropdown', className]);

    useEffect(() => {
        if (initializeExpanded?.buttonRef?.current) {
            setAnchorEl(initializeExpanded?.buttonRef?.current);
        }
    }, []);

    return (
        <DropdownContext.Provider
            value={{
                ...props,
                anchorEl,
                handleClick,
                handleClose,
            }}
        >
            <div className={appendedClassName}>{children}</div>
        </DropdownContext.Provider>
    );
};

Dropdown.defaultProps = {
    color: 'blue',
    variant: 'flat',
    disabled: false,
};

Dropdown.Title = forwardRef(({ children }: { children: ReactNode }, ref: any) => (
    <div ref={ref} className="dropdown-title">
        {children}
    </div>
));
Dropdown.Button = DropdownButton;
Dropdown.IconButton = DropdownIconButton;
Dropdown.Menu = DropdownMenu;
Dropdown.MenuItem = DropdownMenuItem;
Dropdown.MenuDivider = DropdownMenuDivider;
Dropdown.ListSelector = DropdownListSelector;
Dropdown.EmojiPicker = DropdownEmojiPicker;
Dropdown.Popover = DropdownPopover;
export default Dropdown;
