import { FC, createContext, useContext, useMemo, useState } from 'react';

import { Moment } from 'moment';

interface DashboardContextValue {
    dateStart?: Moment | null;
    dateEnd?: Moment | null;
    setDateRange: (updatedDateRange: {
        dateStart?: Moment | null;
        dateEnd?: Moment | null;
    }) => void;
}
export const DashboardContext = createContext<DashboardContextValue>({
    setDateRange: () => {},
});

export const useDashboardContext = () => useContext(DashboardContext);

// At the moment this context is used only for the Custom
// date range values, but it's inteded for broader use. Have Fun!
export const DashboardProvider: FC<any> = ({ children }) => {
    const [dateRange, setDateRange] = useState<{
        dateStart?: Moment | null;
        dateEnd?: Moment | null;
    }>({});

    const value = useMemo(() => ({ ...dateRange, setDateRange }), [dateRange, setDateRange]);

    return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};
