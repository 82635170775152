import { FC } from 'react';

import DropdownTreeSelect, { ITreeOptions } from '@components/inputs/DropdownTreeSelect';

import { appendClasses } from '@helpers/ui';

import { useIsInToolbar } from '../Toolbar/useIsInToolbar';
import ToolbarGroup from '../ToolbarGroup';

import './ToolbarDropdownTreeSelect.scss';

interface ToolbarDropdownTreeSelectProps {
    className?: string;
    label?: string;
    options: ITreeOptions[];
    selected: string[];
    onSelect: (selected: string[]) => void;
}

const ToolbarDropdownTreeSelect: FC<ToolbarDropdownTreeSelectProps> = ({
    className,
    label,
    options,
    selected,
    onSelect,
}) => {
    useIsInToolbar({ componentName: 'Toolbar.DropdownTreeSelect' });

    const classNameAppended = appendClasses([className, 'toolbar-dropdown']);

    return (
        <ToolbarGroup className={classNameAppended}>
            {label ? <div className="toolbar-dropdown-label">{`${label}:`}</div> : null}
            <DropdownTreeSelect selected={selected} options={options} onChange={onSelect} />
        </ToolbarGroup>
    );
};

export default ToolbarDropdownTreeSelect;
