import { FC, ReactElement } from 'react';

import { TooltipProps } from '@mui/material';

import Tooltip from '@components/layout/Tooltip';

import { appendClasses } from '@helpers/ui';

import './LabelWithAdditionalChip.scss';

interface LabelWithAdditionalChipProps extends Pick<TooltipProps, 'placement'> {
    className?: string;
    label: string | ReactElement;
    count?: number;
    tooltip?: string | ReactElement;
}

const LabelWithAdditionalChip: FC<LabelWithAdditionalChipProps> = ({
    className,
    label,
    count,
    tooltip,
    placement = 'bottom',
}) => {
    if (!count || !tooltip) {
        return <div className={className}>{label}</div>;
    }

    const classNamesAppended = appendClasses([className, 'label-with-additional-chip']);
    const labelIsString = typeof label === 'string';

    return (
        <Tooltip title={tooltip} placement={placement}>
            <div className={classNamesAppended}>
                {labelIsString ? <span>{label}</span> : label}
                <div className="additional-chip">
                    <span>{`+${count}`}</span>
                </div>
            </div>
        </Tooltip>
    );
};

export default LabelWithAdditionalChip;
