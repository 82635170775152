import { FC, useState } from 'react';

import { noop } from 'lodash';

import Button from '@components/buttons/Button';
import Modal from '@components/overlays/Modal';

import { useApp } from '@hooks/AppHooks';
import { useModal } from '@hooks/ModalHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import './SparkWizardCancelButton.scss';

interface SparkWizardCancelModalProps {
    isVisible: boolean;
    onContinue: (e: any) => void;
    onDiscardChanges: (e: any) => void;
    sparkHasChanged: boolean;
}
export const SparkWizardCancelModal: FC<SparkWizardCancelModalProps> = ({
    isVisible,
    onContinue,
    onDiscardChanges,
    sparkHasChanged,
}) => {
    const title = sparkHasChanged ? 'Unsaved Changes' : 'Cancel Spark';

    const confirmationMessage = sparkHasChanged
        ? 'Are you sure you wish to discard changes made to this Spark?'
        : 'Are you sure you wish to cancel this Spark?';

    const confirmButtonText = sparkHasChanged ? 'Yes, Discard Changes' : 'Yes, Cancel Spark';
    const cancelButtonText = sparkHasChanged ? 'No, Keep Editing' : 'No, Go Back';

    return (
        <Modal
            className="spark-wizard-cancel-modal"
            isVisible={isVisible}
            disableBackdropClick
            onClose={noop}
        >
            <Modal.Title>{title}</Modal.Title>

            <Modal.Content>{confirmationMessage}</Modal.Content>

            <Modal.Actions>
                <Button color="neutral" onClick={onContinue}>
                    {cancelButtonText}
                </Button>
                <Button color="red" variant="smooth" onClick={onDiscardChanges}>
                    {confirmButtonText}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export const SparkWizardCancelButton = ({ destination }: { destination?: string }) => {
    const [showCancelConfirmation, setShowCancelConfirmation] = useState<boolean>(false);
    const openCancelConfirmationModal = () => setShowCancelConfirmation(true);
    const closeCancelConfirmationModal = () => setShowCancelConfirmation(false);

    const {
        sparkHasChanged,
        spark: { _id: sparkId },
    } = useSpark();
    const { history } = useApp();
    const { currentStage } = useModal();

    const exitSparkWizard = () => {
        if (!destination) {
            history.goBack();
            return;
        }
        history.push(destination);
    };

    return (
        <>
            <Button
                color="white"
                variant="outlined"
                onClick={
                    // show cancel modal if
                    // 1. spark has changed
                    // 2. spark is new and we are not on the first stage
                    // other wise exit immediately if no changes have been made
                    sparkHasChanged || (!sparkId && currentStage > 0)
                        ? openCancelConfirmationModal
                        : exitSparkWizard
                }
            >
                Cancel
            </Button>

            <SparkWizardCancelModal
                isVisible={showCancelConfirmation}
                sparkHasChanged={sparkHasChanged}
                onContinue={closeCancelConfirmationModal}
                onDiscardChanges={exitSparkWizard}
            />
        </>
    );
};
