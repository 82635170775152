import { useMemo, useRef, useState } from 'react';

import { keyBy, sortBy } from 'lodash';

import { Spark } from '@sparkplug/lib';

import { UpdateCallback } from '@contexts/ModalContext';

import { useModal } from '@hooks/ModalHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import { CommissionMap } from '../helpers/getHeadCells';
import { ProductCommissionRow } from './useCommissionTableProducts';

const convertCommissionsToCommissionValuesMap = (
    commissions: Spark['commissions'],
): CommissionMap => {
    return Object.fromEntries(commissions.map(({ posProductId, value }) => [posProductId, value]));
};

export const useCommissionTableState = ({
    isVendorEditExperience,
    unsortedProductRows,
}: {
    isVendorEditExperience: boolean;
    unsortedProductRows: ProductCommissionRow[];
}) => {
    const {
        isCreatingMultiRetailerSpark,
        spark,
        sparkCommissionType,
        updateSpark,
        updateMultiRetailerProductSelection,
        multiRetailerProductSelection,
    } = useSpark();
    const { commissions: singleRetailerCommissions = [] } = spark;
    const { updateBackFn } = useModal();

    const commissions = useMemo(() => {
        if (isCreatingMultiRetailerSpark) {
            return Object.values(multiRetailerProductSelection).flatMap(
                ({ commissions: c }) =>
                    c?.map(({ posProductId, value, type }) => ({
                        posProductId,
                        value,
                        type,
                    })) ?? [],
            );
        }
        return singleRetailerCommissions;
    }, [isCreatingMultiRetailerSpark, singleRetailerCommissions, multiRetailerProductSelection]);

    const commissionValuesRef = useRef<CommissionMap>(
        convertCommissionsToCommissionValuesMap(commissions),
    );
    const [commissionValuesMap, setCommissionValuesMap] = useState<CommissionMap>(
        convertCommissionsToCommissionValuesMap(commissions),
    );

    // derived from spark data as original values
    const originalCommissionValuesMap = useMemo(
        () => convertCommissionsToCommissionValuesMap(commissions),
        [],
    );
    const [isFulfilledBySparkPlug, setIsFulfilledBySparkPlug] = useState(
        spark.fulfillmentTypes?.[0] !== 'external',
    );

    const productRows = useMemo(
        () =>
            sortBy(unsortedProductRows, [
                (product) => !!originalCommissionValuesMap[product._id],
                'name',
            ]),
        [unsortedProductRows],
    );

    const updateSparkContextCommissions = (callback?: UpdateCallback) => {
        const updatedSparkProperties: Partial<Spark> = {
            fulfillmentTypes: [isFulfilledBySparkPlug ? 'sparkplug' : 'external'],
        };
        if (isCreatingMultiRetailerSpark) {
            const productRowsById = keyBy(productRows, '_id');
            const updatedMultiRetailerProductSelection = Object.fromEntries(
                Object.entries(multiRetailerProductSelection).map(
                    ([retailerAccountId, currentRetailerProductSelection]) => [
                        retailerAccountId,
                        {
                            ...currentRetailerProductSelection,
                            commissions: currentRetailerProductSelection.posProductIds.map(
                                (posProductId) => {
                                    const name = productRowsById[posProductId]?.name;
                                    const value = commissionValuesRef.current[posProductId];

                                    return {
                                        posProductId,
                                        type: 'flat' as 'flat',
                                        name,
                                        value:
                                            typeof value === 'string' ? parseFloat(value) : value,
                                    };
                                },
                            ),
                        },
                    ],
                ),
            );

            updateMultiRetailerProductSelection(updatedMultiRetailerProductSelection);
        } else {
            if (sparkCommissionType) {
                updatedSparkProperties.commissions = productRows.map(({ _id: posProductId }) => {
                    const value = commissionValuesRef.current[posProductId];
                    return {
                        posProductId,
                        type: sparkCommissionType,
                        value: typeof value === 'string' ? parseFloat(value) : value,
                    };
                });
            } else {
                throw new Error('Spark commission type is not defined');
            }
        }

        if (isVendorEditExperience) {
            callback?.(updatedSparkProperties);
        } else {
            updateSpark(updatedSparkProperties);
        }
    };
    updateBackFn((callback) => {
        requestAnimationFrame(() => {
            updateSparkContextCommissions(callback);
        });
        return true;
    });
    const updateCommissionValue = (productId: string, commissionValue: number) => {
        commissionValuesRef.current = {
            ...commissionValuesRef.current,
            [productId]: commissionValue,
        };
        setCommissionValuesMap((prevState) => ({
            ...prevState,
            [productId]: commissionValue,
        }));
    };

    const updateDisplayedCommissionValues = (
        productIds: string[],
        applyAllValue: number | undefined,
    ) => {
        // sometimes productIds is null when first called from the useEffect in the CommissionRulesRadioGroup
        const _productIds =
            productIds.length > 0
                ? productIds
                : Object.keys(
                      isVendorEditExperience ? originalCommissionValuesMap : commissionValuesMap,
                  );

        if (applyAllValue) {
            const updatedValuesMap = {
                ...(isVendorEditExperience ? originalCommissionValuesMap : commissionValuesMap),
                ...Object.fromEntries(_productIds.map((productId) => [productId, applyAllValue])),
            };

            commissionValuesRef.current = updatedValuesMap;
            setCommissionValuesMap(updatedValuesMap);
        }
    };
    return {
        commissionValuesRef,
        isFulfilledBySparkPlug,
        updateIsFulfilledBySparkPlug: setIsFulfilledBySparkPlug,
        originalCommissionValuesMap,
        productRows,
        commissionValuesMap,
        updateCommissionValue,
        updateDisplayedCommissionValues,
        updateSparkContextCommissions,
    };
};
