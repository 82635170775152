import Button from '@components/buttons/Button';
import CalloutMessage from '@components/layout/CalloutMessage';
import Modal, { IDefaultModalProps } from '@components/overlays/Modal';

import { appendClasses } from '@helpers/ui';

import { IAccount } from '@app/types/AccountsTypes';

import './SelfServiceOnboardingInstructionsModal.scss';

interface ISelfServiceOnboardingInstructionsModalProps extends IDefaultModalProps {
    account: IAccount;
}

const SelfServiceOnboardingInstructionsModal = ({
    account,
    isVisible,
    onClose,
}: ISelfServiceOnboardingInstructionsModalProps) => {
    const downloadRoute = `/${account?._id}/settings/employees/onboarding/poster`;

    const classNamesAppended = appendClasses([
        'info-modal',
        'self-service-onboarding-instructions-modal',
    ]);

    return (
        <Modal className={classNamesAppended} isVisible={isVisible} onClose={() => onClose(false)}>
            <Modal.Title onClose={() => onClose(false)}>
                Self-Service Employee Onboarding
            </Modal.Title>

            <Modal.Content>
                <CalloutMessage
                    color="green"
                    title="Setup Instructions"
                    message="Follow the steps and instructions to get your employees onto the platform in no time!"
                />
                <p>
                    <strong>Step 1:</strong> Print out your{' '}
                    <a href={downloadRoute} target="_blank" rel="noreferrer">
                        SparkPlug Signup Poster
                    </a>
                    .
                </p>
                <p>
                    <strong>Step 2:</strong> Hang it up on a wall in your store visible to
                    employees.
                </p>
                <p>
                    <strong>Step 3:</strong> Accept employee enrollment notifications as they appear
                    in your user settings dashboard.
                </p>
            </Modal.Content>

            <Modal.Actions>
                <Button color="neutral" variant="flat" onClick={() => onClose(false)}>
                    Got it, thanks!
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default SelfServiceOnboardingInstructionsModal;
