import { FC } from 'react';

import IconButton, { IIconButtonProps } from '@components/buttons/IconButton';

import { appendClasses } from '@helpers/ui';

import { withDropdown } from './DropdownContext';

interface IDropdownIconButtonProps extends IIconButtonProps {
    handleClick?: any;
}

const DropdownIconButton: FC<IDropdownIconButtonProps> = ({
    className,
    color,
    variant,
    children,
    handleClick,
    'data-testid': dataTestId,
    onClick = () => {},
    size,
    disabled,
}) => {
    const classNamesAppended = appendClasses([className, 'dropdown-icon-btn']);

    return (
        <IconButton
            data-testid={dataTestId}
            className={classNamesAppended}
            color={color}
            variant={variant}
            size={size}
            onClick={(event: any) => {
                handleClick(event);
                onClick(event);
            }}
            disabled={disabled}
        >
            {children}
        </IconButton>
    );
};

export default withDropdown(DropdownIconButton);
