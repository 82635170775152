import { memo } from 'react';

import Button from '@components/buttons/Button';
import { ArrowRight as ArrowRightIcon } from '@components/icons';

import { appendClasses } from '@helpers/ui';

import './PanelField.scss';

interface PanelFieldProps {
    label?: string;
    value: string | number;
    onClick?: (() => void) | null;
    className?: string;
}

const PanelField = memo<PanelFieldProps>(({ label, value, className, onClick }) => {
    const appendedClassName = appendClasses(['panel-field', className]);

    if (onClick) {
        return (
            <Button
                className={appendedClassName}
                onClick={onClick}
                variant="smooth"
                color="neutral"
            >
                <>
                    {!!label && <span className="label">{label}</span>}
                    <span className="value">
                        <span>{value}</span>
                        <ArrowRightIcon className="arrow-right-icon" />
                    </span>
                </>
            </Button>
        );
    }

    return (
        <div className={appendedClassName}>
            {!!label && <span className="label">{label}</span>}
            <span className="value">{value}</span>
        </div>
    );
});

export default PanelField;
