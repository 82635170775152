import { FC, useState } from 'react';

import moment from 'moment';
import pluralize from 'pluralize';

import {
    AccountSparkBrand,
    CondensedHydratedBrandProduct,
    GetAccountVendorRetailerPosDataResponseBody,
} from '@sparkplug/lib';

import LinkLabel from '@features/account-links/components/LinkLabel';

import Skeleton from '@components/layout/Skeleton';
import Tabs from '@components/layout/Tabs';

import './BrandTabs.scss';

const getProductLabel = (products: CondensedHydratedBrandProduct[] = []): string => {
    const productCount = products.filter(({ lastSoldAt }) => {
        const soldWithin60Days = moment(lastSoldAt).isSameOrAfter(
            moment().subtract(60, 'days'),
            'day',
        );
        return soldWithin60Days;
    }).length;

    return `${productCount} ${pluralize('product', productCount)} mapped`;
};

export interface BrandTabsProps {
    selectedBrandId: string;
    updateSelectedBrand: (updatedBrandId: string) => void;
    vendorRetailerPosData?: GetAccountVendorRetailerPosDataResponseBody;
    brands: AccountSparkBrand[];
    vendorRetailerPosDataIsReady: boolean;
    brandsAreReady: boolean;
}
const BrandTabs: FC<BrandTabsProps> = ({
    brands,
    selectedBrandId,
    updateSelectedBrand,
    brandsAreReady,
    vendorRetailerPosDataIsReady,
    vendorRetailerPosData,
}) => {
    const [selected, setSelected] = useState(selectedBrandId);

    const handleTabClick = (brandId: string) => {
        setSelected(brandId);
        updateSelectedBrand(brandId);
    };

    const getProducts = (brandId: string) => {
        return vendorRetailerPosData?.productsVendorBrandMap?.[brandId ?? '']?.products ?? [];
    };

    return (
        <div className="brand-tabs">
            <h4>Your Brands</h4>
            {brandsAreReady && brands?.length ? (
                <Tabs value={selected ?? brands?.[0]?._id} color="blue" orientation="vertical">
                    {brands.map(({ _id, photo, name, isEnabled }) => (
                        <Tabs.Tab
                            onClick={() => handleTabClick(_id)}
                            key={_id}
                            label={
                                <LinkLabel
                                    imageUrl={photo}
                                    title={name}
                                    subtitle={
                                        vendorRetailerPosDataIsReady ? (
                                            `${getProductLabel(getProducts(_id))}`
                                        ) : (
                                            <Skeleton height={16} />
                                        )
                                    }
                                />
                            }
                            disabled={!isEnabled}
                            value={_id}
                        />
                    ))}
                </Tabs>
            ) : (
                <Skeleton height={300} />
            )}
        </div>
    );
};

export default BrandTabs;
