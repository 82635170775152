import { useCallback, useState } from 'react';

import { SparkDeclineResponse, sparkDeclineOptions } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import Modal from '@components/overlays/Modal';
import toast from '@components/toast';

import './SparkDeclineConfirmationModal.scss';

const DeclineFormFields = ({
    declineResponse,
    setDeclineResponse,
    submitted = false,
}: {
    declineResponse?: SparkDeclineResponse;
    setDeclineResponse: (a: SparkDeclineResponse) => void;
    submitted?: boolean;
}) => {
    return (
        <div className="decline-fields">
            <Form.Select
                required
                error={submitted && !declineResponse?.reason}
                label="Why are you declining this Spark?"
                name="spark-decline-modal_reason"
                value={declineResponse?.reason ?? ''}
                onChange={(event) =>
                    setDeclineResponse({ ...declineResponse, reason: event.target.value })
                }
                options={sparkDeclineOptions}
            />
            <Form.TextField
                label={`Comments ${declineResponse?.reason !== 'Other' ? '(optional)' : ''}`}
                required={declineResponse?.reason === 'Other'}
                error={
                    submitted && declineResponse?.reason === 'Other' && !declineResponse?.message
                }
                multiline
                rows={3}
                name="spark-decline-modal_message"
                value={declineResponse?.message ?? ''}
                onChange={(event) =>
                    setDeclineResponse({ ...declineResponse, message: event.target.value })
                }
                placeholder="Any additional feedback?"
            />
            <span className="details">
                Provide details around what you would like to see from your Vendor partners.
            </span>
        </div>
    );
};

const SparkDeclineConfirmationModal = ({
    isVisible,
    declineResponse,
    setDeclineResponse,
    onClose,
}: {
    isVisible: boolean;
    declineResponse?: SparkDeclineResponse;
    setDeclineResponse: (a: SparkDeclineResponse) => void;
    onClose: (value: boolean) => void;
}) => {
    const [submitted, setSubmitted] = useState(false);

    const handleClose = (send: boolean = false) => {
        setSubmitted(false);
        onClose(send);
    };

    const handleValidation = useCallback(() => {
        setSubmitted(true);

        if (!declineResponse?.reason) {
            toast.error('Decline reason required');
            return false;
        } else if (declineResponse?.reason === 'Other' && !declineResponse?.message) {
            toast.error('Please provide some details');
            return false;
        }
        handleClose(true);
        return true;
    }, [declineResponse]);

    return (
        <Modal
            size="narrow"
            className="spark-approval-decline-modal"
            isVisible={isVisible}
            onClose={() => handleClose()}
        >
            <Form>
                <Modal.Title onClose={() => handleClose()}>Decline Spark</Modal.Title>
                <Modal.Content>
                    <DeclineFormFields
                        declineResponse={declineResponse}
                        setDeclineResponse={setDeclineResponse}
                        submitted={submitted}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button color="neutral" variant="flat" onClick={() => handleClose()}>
                        Back
                    </Button>
                    <Button
                        className="spark-approval-modal__decline-submit"
                        color="blue"
                        variant="smooth"
                        onClick={() => handleValidation()}
                    >
                        Submit
                    </Button>
                </Modal.Actions>
            </Form>
        </Modal>
    );
};

export default SparkDeclineConfirmationModal;
