import {
    DependencyList,
    RefObject,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { debounce, get } from 'lodash';

import { parseCurrencyToNumber } from '@sparkplug/lib';

import { useQueryParamsState } from '@hooks/QueryParamsHooks';

import Intercom from '@helpers/Intercom';

export const useTabs = (initialValue: number = 0) => {
    const [currentTab, setCurrentTab] = useState<number>(initialValue);

    return [currentTab, setCurrentTab];
};

export const useRouteTabs = () => {
    const { path } = useRouteMatch();
    const { pathname } = useLocation();

    return {
        tabValue: pathname,
        currentPath: path,
    };
};

export const useSearch = (
    searchKeys: string[],
    usesSearchQueryParam: boolean = false,
    defaultValue?: string,
    numericKeys: string[] = [],
) => {
    let timer: any;

    const [query, setQuery] = usesSearchQueryParam
        ? useQueryParamsState({
              s: '',
          })
        : [{}, () => {}];
    const [searchFilter, _setSearchFilter] = useState<string>(query?.s ?? defaultValue ?? '');

    const setSearchFilter = (value: string) => {
        _setSearchFilter(value);

        if (usesSearchQueryParam) {
            setQuery(value ? { s: value } : { s: undefined });
        }
    };

    const onChangeSearchFilter = (event: any) => {
        clearTimeout(timer);

        timer = setTimeout(() => {
            const value =
                event.target.value != null ? event.target.value.trim().toLowerCase() : null;
            setSearchFilter(value);
        }, 250);
    };

    const applySearch = useCallback(
        (list: any[]) =>
            list.filter((listItem) => {
                if (listItem) {
                    if (numericKeys.length && searchFilter) {
                        // Handle numeric search
                        const searchDigits = searchFilter.replace(/[^0-9]/g, '');
                        const hasNumericMatch = numericKeys.some((key) => {
                            const value = get(listItem, key);
                            // Handle arrays for numeric fields
                            const stringValue = Array.isArray(value) ? value.join('|') : value;
                            const valueDigits = String(stringValue).replace(/[^0-9]/g, '');
                            return valueDigits.includes(searchDigits);
                        });

                        // If we have digits in the search and found a numeric match, return true
                        if (searchDigits && hasNumericMatch) {
                            return true;
                        }
                    }

                    const searchValues = searchKeys.map((searchKey) => {
                        const value = get(listItem, searchKey);

                        // Handle when the value is an array
                        const stringValue = Array.isArray(value) ? value.join('|') : value;
                        // Handle array paths (e.g., items.productName)
                        if (!value && searchKey.includes('.')) {
                            const [arrayKey, fieldKey] = searchKey.split('.');
                            const array = get(listItem, arrayKey);
                            if (Array.isArray(array)) {
                                const mappedValues = array.map((item) => get(item, fieldKey));
                                return mappedValues.join('|').toLowerCase();
                            }
                        }
                        return stringValue ? String(stringValue).toLowerCase() : '';
                    });

                    const listItemSearchStr = searchValues.filter(Boolean).join('|');

                    return listItemSearchStr.includes(searchFilter.toLowerCase());
                }
                return false;
            }),
        [searchFilter],
    );

    return {
        searchFilter,
        resetSearchFilter: () => setSearchFilter(''),
        onChangeSearchFilter,
        applySearch,
    };
};

export function useIntercom() {
    const openIntercom = () => {
        Intercom.open();
    };

    return {
        openIntercom,
    };
}

interface IUseCountdownHook {
    remainingTime: number;
    startCountdown: (duration: number) => any;
    countdownIsRunning: boolean;
}

export function useCountdown() {
    const [remainingTime, setRemainingTime] = useState<number>(0);

    const countdownIsRunning = remainingTime > 0;
    useEffect(() => {
        if (countdownIsRunning) {
            const timer = setTimeout(() => {
                setRemainingTime((prev) => prev - 1);
            }, 1000);

            return () => clearTimeout(timer);
        }
        return undefined;
    }, [countdownIsRunning, remainingTime]);

    const startCountdown = (duration: number) => setRemainingTime(duration);

    const value: IUseCountdownHook = useMemo(
        () => ({
            remainingTime,
            startCountdown,
            countdownIsRunning,
        }),
        [remainingTime, countdownIsRunning],
    );

    return value;
}

export const useResize = (cb: () => void) => {
    useEffect(() => {
        const debouncedHandleResize = debounce(cb, 500);

        window.addEventListener('resize', debouncedHandleResize);

        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, []);
};

export const useDynamicHeight = (
    containerRef: RefObject<HTMLElement> | undefined,
    childRef: RefObject<HTMLElement> | undefined,
    options: {
        defaultHeight?: number;
        dependencies?: DependencyList;
    } = {},
): number => {
    const { defaultHeight = 400, dependencies = [] } = options;
    const [height, setHeight] = useState(defaultHeight);

    const updateHeight = () => {
        if (containerRef?.current && childRef?.current) {
            const containerComputedStyle = getComputedStyle(containerRef.current);
            const containerHeight =
                containerRef.current.clientHeight -
                parseFloat(containerComputedStyle.paddingTop) -
                parseFloat(containerComputedStyle.paddingBottom);
            const containerOffsetTop = containerRef.current.getBoundingClientRect().top;
            const listOffsetTop = childRef.current.getBoundingClientRect().top;

            const listContainerOffset = listOffsetTop - containerOffsetTop;

            const updatedHeight = containerHeight - listContainerOffset;

            setHeight(updatedHeight > 0 ? updatedHeight : defaultHeight);
        }
    };

    useResize(updateHeight);

    useLayoutEffect(() => {
        updateHeight();
    }, dependencies);

    return height;
};

export const useScrollToTop = () => {
    const { listen } = useHistory();
    useEffect(() => {
        const unlisten = listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        };
    }, []);
};
