import { DATE_DISPLAY_FORMAT } from '@constants/AppConstants';
import moment from 'moment-timezone';

import BucketFactory, { TBucketsMap } from './BucketFactory';

export default class DayBucketFactory extends BucketFactory {
    getShortName(name: string) {
        return moment(name).format('M/D');
    }

    getTooltipTitle(name: string) {
        return `${moment(name).format('ddd, MMM D')}`;
    }

    getComparisonLabel(name: string) {
        return moment(name).format(`ddd ${DATE_DISPLAY_FORMAT}`);
    }

    getBucketName(transaction: any) {
        if (transaction.bucket) {
            return transaction.bucket;
        }
        const date = moment(transaction.completedOn);
        return date.tz(transaction.timeZone).format('YYYY-MM-DD');
    }

    getAllBucketNames(dateStart: string | Date, dateEnd: string | Date) {
        const bucketNames: string[] = [];
        const currentDate = moment(dateStart);

        do {
            bucketNames.push(currentDate.format('YYYY-MM-DD'));
            currentDate.add(1, 'day');
        } while (currentDate.isSameOrBefore(dateEnd));

        return bucketNames;
    }

    getCumulativeBuckets(bucketsMap: TBucketsMap, bucketName: string) {
        return super.getCumulativeBuckets(bucketsMap, bucketName);
    }
}
