import { FC, ReactElement } from 'react';

import { ListItemIcon, TableSortLabel } from '@mui/material';

import Dropdown from '@components/dropdown/Dropdown';
import { Check as CheckIcon, FilterList as FilterIcon } from '@components/icons';

import { useTableContext } from '@hooks/TableHooks';

import { appendClasses } from '@helpers/ui';

import { IOption } from '@app/types/UITypes';

import './TableDropdownSortHeadCell.scss';

interface SortOption extends IOption {
    startIcon?: ReactElement;
}

interface TableDropdownSortHeadCellProps {
    label: string;
    options: SortOption[];
    id?: string;
    defaultSortDirection?: 'asc' | 'desc';
}

const TableDropdownSortHeadCell: FC<TableDropdownSortHeadCellProps> = ({
    label,
    options,
    id,
    defaultSortDirection,
}) => {
    const { tableOrder, tableOrderBy, tableChangeOrderBy } = useTableContext();

    const active = !!options.find(({ value }) => tableOrderBy === value);

    const getOrderDirectionByValue = (value: string) => {
        const isDefaultDirection = tableOrderBy === value && tableOrder === defaultSortDirection;
        const oppositeSortDirection = defaultSortDirection === 'asc' ? 'desc' : 'asc';
        return isDefaultDirection ? oppositeSortDirection : defaultSortDirection;
    };

    const changeOrderBy = (value: string) => {
        tableChangeOrderBy(value, {
            order: getOrderDirectionByValue(value),
            id,
        });
    };

    const btnClassNamesAppended = appendClasses(['dropdown-sort-btn', active ? 'active' : '']);

    return (
        <div className="head-cell-wrapper">
            <Dropdown>
                <Dropdown.Button
                    className={btnClassNamesAppended}
                    hideExpandIcon
                    startIcon={<FilterIcon />}
                    color="neutral"
                >
                    {label}
                </Dropdown.Button>
                <Dropdown.Menu>
                    <Dropdown.Title>Sort by</Dropdown.Title>
                    {options.map(({ value, label: optionLabel, startIcon }) => (
                        <Dropdown.MenuItem key={value} onClick={() => changeOrderBy(value)}>
                            <ListItemIcon className="head-cell-dropdown-sort_check-icon">
                                {value === tableOrderBy ? <CheckIcon /> : <></>}
                            </ListItemIcon>
                            {startIcon && <ListItemIcon>{startIcon}</ListItemIcon>}
                            {optionLabel}
                        </Dropdown.MenuItem>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
            <TableSortLabel
                data-testid={`dropdown-sort-direction.${tableOrder}`}
                active={active}
                direction={tableOrder}
                onClick={() => changeOrderBy(tableOrderBy)}
            />
        </div>
    );
};

export default TableDropdownSortHeadCell;
