import { FC, useMemo } from 'react';

import Form from '@components/form/Form';
import ListSelector from '@components/inputs/ListSelector';
import { IListItem } from '@components/inputs/ListSelector/ListSelector';
import Toolbar from '@components/toolbar/Toolbar';

import { useModal } from '@hooks/ModalHooks';
import { useAccountProductsFilters } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { IPosBrand, IPosCategory } from '@app/types/PosTypes';

import './SparkProductScopeSelector.scss';

export type ProductFilterType = 'brands' | 'categories';

interface SparkProductScopeSelectorProps {
    filterType: ProductFilterType;
    productFilters: ReturnType<typeof useAccountProductsFilters>['productFilters'];
    brandOptions: IPosBrand[];
    categoryOptions: IPosCategory[];
    onUpdateBrands: (value: IListItem[]) => void;
    onUpdateCategories: (value: IListItem[]) => void;
    onSwitchScope: (value: ProductFilterType) => void;
}

const SparkProductScopeSelector: FC<SparkProductScopeSelectorProps> = ({
    filterType,
    productFilters,
    brandOptions,
    categoryOptions,
    onUpdateBrands,
    onUpdateCategories,
    onSwitchScope,
}) => {
    const { modalContentRef } = useModal();
    const options = filterType === 'brands' ? brandOptions : categoryOptions;

    const selected = useMemo(() => {
        return filterType === 'brands'
            ? brandOptions.filter((brandOption) =>
                  productFilters.brandIds.includes(brandOption._id),
              )
            : categoryOptions.filter((categoryOption) =>
                  productFilters.categoryIds.includes(categoryOption._id),
              );
    }, [productFilters, brandOptions, categoryOptions]);

    const filterByOptions = useMemo(() => {
        const brandLabel =
            productFilters.brandIds.length > 0
                ? `Brands (${
                      productFilters.brandIds.length === brandOptions.length
                          ? 'All'
                          : productFilters.brandIds.length
                  })`
                : 'Brands';

        const categoryLabel =
            productFilters.categoryIds.length > 0
                ? `Categories (${
                      productFilters.categoryIds.length === categoryOptions.length
                          ? 'All'
                          : productFilters.categoryIds.length
                  })`
                : 'Categories';

        return [
            {
                label: brandLabel,
                value: 'brands',
            },
            {
                label: categoryLabel,
                value: 'categories',
            },
        ];
    }, [productFilters, brandOptions, categoryOptions]);

    const onSelectionChanged = filterType === 'brands' ? onUpdateBrands : onUpdateCategories;

    return (
        <>
            <div className="brand-category-selector-column">
                <Form.Label>Filter By</Form.Label>
                <Toolbar>
                    <Toolbar.ButtonGroup
                        className="brand-category-button-group"
                        variant="smooth"
                        color="neutral"
                        value={filterType}
                        onSelect={onSwitchScope as (value: string) => void}
                        options={filterByOptions}
                    />
                </Toolbar>
                <ListSelector
                    useAddRemoveUI
                    showSearchIcon
                    list={options}
                    selected={selected}
                    onSelectionChanged={onSelectionChanged}
                    containerRef={modalContentRef as any}
                />
            </div>
        </>
    );
};

export default SparkProductScopeSelector;
