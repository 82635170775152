import { FC, useMemo } from 'react';
import Linkify from 'react-linkify';

import { Emoji } from 'emoji-mart';

import { WalletEmployeeDeposit } from '@sparkplug/lib';

import SwipeButton from '@components/buttons/SwipeButton';
import Chip from '@components/chips/Chip';
import Skeleton from '@components/layout/Skeleton';
import Drawer from '@components/overlays/Drawer/Drawer';
import toast from '@components/toast';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { IAccount } from '@app/types/AccountsTypes';

import { useConfirmNonFbsReceiptMutation } from '../mutations/WalletMutations';
import { useCurrentUserWallet } from '../queries/WalletQueries';

interface ConfirmNonFbsReceiptDrawerProps {
    rewardId: string;
    onClose: () => void;
    isVisible: boolean;
}

interface ConfirmNonFbsReceiptDrawerBodyProps {
    reward: WalletEmployeeDeposit;
    account?: IAccount;
    onClose: () => void;
}

export const ConfirmNonFbsReceiptDrawerBody: FC<ConfirmNonFbsReceiptDrawerBodyProps> = ({
    reward,
    account,
    onClose,
}) => {
    const { spark, claimInstructions = 'Talk to your manager', name: rewardText } = reward;
    const { name: sparkName, emoji, sparkBrand } = spark ?? {};

    const { isConfirmingNonFbsReceipt, confirmNonFbsReceipt } = useConfirmNonFbsReceiptMutation();

    const handleConfirmNonFbsReceipt = () => {
        if (reward?._id && !isConfirmingNonFbsReceipt) {
            confirmNonFbsReceipt(
                { rewardId: reward._id },
                {
                    onSuccess: () => {
                        toast.success('Reward received');
                        onClose();
                    },
                    onError: () => {
                        toast.error(
                            'Something went wrong - please try again and contact support if this issue persists.',
                        );
                        onClose();
                    },
                },
            );
        }
    };

    return (
        <>
            {sparkBrand ? (
                <img
                    className="w-[88px] h-[88px] !rounded-[50%]"
                    src={sparkBrand?.photo}
                    alt={sparkBrand?.name}
                />
            ) : (
                <div className="px-4 py-2">
                    <Emoji emoji={emoji ?? ''} set="apple" size={50} />
                </div>
            )}
            {sparkBrand?.name && (
                <div className="m-6">
                    <Chip dense label={sparkBrand?.name} color="blue" />
                </div>
            )}
            <div>
                <div className="claim-message">
                    Have you received this reward for <strong>{sparkName}</strong>? We’ll let{' '}
                    {sparkBrand?.name ? `${sparkBrand?.name} and` : ''} your store manager know.
                    <div className="payout-text !m-6 !text-aqua-600">{rewardText}</div>
                </div>
            </div>
            <div className="non-fbs-claimed">
                <div>Claim Instructions:</div>
                <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a target="blank" href={decoratedHref} key={key}>
                            {decoratedText}
                        </a>
                    )}
                >
                    {claimInstructions && claimInstructions.length > 0
                        ? claimInstructions
                        : 'Talk to Manager'}
                </Linkify>
                <SwipeButton
                    onSwipe={handleConfirmNonFbsReceipt}
                    mainText="Swipe to confirm received"
                    overlayText="Swipe to confirm received"
                    disabled={isConfirmingNonFbsReceipt}
                    className="mt-4 w-full px-6 [&_.swipezor-overlay]:!bg-aqua-600 [&_.swipezor-caret-wrapper]:!bg-aqua-600"
                />
            </div>
        </>
    );
};

const ConfirmNonFbsReceiptDrawer: FC<ConfirmNonFbsReceiptDrawerProps> = ({
    isVisible = false,
    onClose,
    rewardId,
}) => {
    const { currentUserWalletIsReady, currentUserWallet } = useCurrentUserWallet();

    const reward = useMemo(() => {
        return currentUserWallet?.deposits.filter(({ _id }) => _id === rewardId)?.[0];
    }, [rewardId]);

    const { account } = useSparkplugAccount();

    if (!reward) {
        return <></>;
    }

    if (!currentUserWalletIsReady) {
        return <Skeleton height={200} />;
    }

    return (
        <Drawer
            isVisible={isVisible}
            className="bring-drawer-to-front [&_.drawer-header]:!p-6"
            title="Confirm Reward"
            variant="bottomPartial"
            onCloseHandler={() => onClose()}
        >
            <ConfirmNonFbsReceiptDrawerBody reward={reward} account={account} onClose={onClose} />
        </Drawer>
    );
};

export default ConfirmNonFbsReceiptDrawer;
