import { useEffect, useMemo } from 'react';

import { useSparkLeaderboardTableData } from '@views/sparks/SparkManageView/useSparkLeaderboardTableData';

import { SparkProvider } from '@contexts/SparkContext';

import PageHeader from '@components/layout/PageHeader';
import PageLoading from '@components/layout/PageLoading';
import RouterTabs from '@components/layout/Tabs/RouterTabs';
import { Redirect, useParams, useRouteMatch } from '@components/router';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useSpark, useSparkChartData } from '@hooks/SparksHooks/SparksHooks';

import { sparkIsCompleted } from '@helpers/sparks';

import SparkCheckoutView from '../SparkCheckoutView';
import SparkSendSMSView from '../SparkSendSMSView';

import './SparkManageView.scss';

const SparkManageView = () => {
    const { history } = useApp();
    const { accountIsReady, account } = useSparkplugAccount();
    const { sparkIsReady, spark } = useSpark();
    const { params } = useRouteMatch<{ sparkId: string }>();

    useEffect(() => {
        if (accountIsReady && sparkIsReady) {
            const belongsToRetailer = account?._id === spark?.groupId;
            const belongsToBrand = account?._id === spark?.originatorGroupId;

            if (!belongsToRetailer && !belongsToBrand) {
                history.push(`/${account?._id}/sparks/`);
            }
        }
    }, [accountIsReady, account, sparkIsReady, spark]);

    const { rootPath, routes } = useMemo(() => {
        const { sparkId } = params;

        const path = `/${account?._id}/sparks/${sparkId}/manage`;

        const messageRoute = {
            url: `${path}/sms`,
            text: 'Message Participants',
        };

        const checkoutRoute = {
            url: `${path}/checkout`,
            text: 'Checkout Spark',
        };

        return {
            rootPath: path,
            routes: sparkIsCompleted(spark) ? [messageRoute, checkoutRoute] : [messageRoute],
        };
    }, [params.sparkId, spark.endDate]);

    const { isLoading: isLoadingTableData, headCells, rows } = useSparkLeaderboardTableData();

    // In Spark Checkout, we want to be able to group the rankings by location. Because of this,
    // we need to get the sparkStandings which groups the standings by location.
    const { sparkStandings: locationLeaderboardStandings } = useSparkChartData({
        spark,
        isEnabled: spark.detailedSparkType === 'leaderboardLocation',
    });

    return (
        <>
            {sparkIsReady ? (
                <>
                    <PageHeader
                        title={[
                            {
                                url: `/${account?._id}/sparks/`,
                                title: `${account?.name} Sparks`,
                            },
                            {
                                url: `/${account?._id}/sparks/${spark?._id}`,
                                title: spark.name,
                            },
                            'Manage Spark',
                        ]}
                        metaTitle={`Manage | ${spark.name} | Sparks`}
                    />
                    <RouterTabs color="blue" tabs={routes}>
                        <RouterTabs.Tab path={`${rootPath}/sms`}>
                            <SparkSendSMSView
                                isLoadingTableData={isLoadingTableData}
                                tableHeadCells={headCells}
                                tableRows={rows}
                            />
                        </RouterTabs.Tab>
                        <RouterTabs.Tab path={`${rootPath}/checkout`}>
                            <SparkCheckoutView
                                isLoadingTableData={isLoadingTableData}
                                tableHeadCells={headCells}
                                tableRows={rows}
                                locationLeaders={locationLeaderboardStandings}
                            />
                        </RouterTabs.Tab>
                        <RouterTabs.Tab path={rootPath}>
                            <Redirect path={rootPath} to={`${rootPath}/sms`} />
                        </RouterTabs.Tab>
                    </RouterTabs>
                </>
            ) : (
                <PageLoading label="Loading Spark..." />
            )}
        </>
    );
};

export default () => {
    const {
        sparkId,
    }: {
        sparkId?: string;
    } = useParams();

    return (
        <SparkProvider sparkId={sparkId}>
            <SparkManageView />
        </SparkProvider>
    );
};
