import { useCallback } from 'react';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import Paper from '@components/layout/Paper';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

const UnauthorizedView = () => {
    const { user, history, logOut } = useApp();
    const { account } = useSparkplugAccount();

    const goToApp = useCallback(() => {
        let url = '/login';

        if (user != null) {
            if (account?._id != null) {
                url = `/${account?._id}/`;
            } else if (user?.accounts != null && user?.accounts.length > 0) {
                url = `/${user.accounts[0]._id}/`;
            }
        }

        return history.push(url);
    }, [user, account, history]);

    const onLogOut = useCallback(() => {
        history.push('/login');
        logOut().then(() => {});
    }, [history]);

    return (
        <div className="login-view">
            <Paper className="login-container">
                <Paper.Title>Uh oh!</Paper.Title>
                <Form>
                    <Form.Description>
                        <p>
                            You don&apos;t have access for this page. If you think this is a
                            mistake, please use the chat feature to contact support.
                        </p>
                    </Form.Description>
                    {user?.role !== 'none' ? (
                        <Form.ActionGroup>
                            <Button color="blue" variant="smooth" onClick={goToApp}>
                                {user != null ? 'Go to My Dashboard' : 'Go to Login'}
                            </Button>
                        </Form.ActionGroup>
                    ) : (
                        <Form.ActionGroup>
                            <Button color="blue" variant="smooth" onClick={onLogOut}>
                                Log Out
                            </Button>
                        </Form.ActionGroup>
                    )}
                </Form>
            </Paper>
        </div>
    );
};

export default UnauthorizedView;
