import React from 'react';

import { DO_NOT_HAVE_PERMISSIONS_MESSAGE } from '@sparkplug/lib';

import { SparkTemplate } from '@features/spark-dashboard/types';

import Button from '@components/buttons/Button';
import { Add as AddIcon } from '@components/icons';
import Drawer from '@components/overlays/Drawer';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { getDetailedSparkTypeDisplayName } from '@helpers/sparks';

import Typography from '@app/componentLibrary/Typography';

import SparkTemplateCard from '../SparkTemplateCard';
import { getSparkTemplateCardImage } from '../SparkTemplateCard/SparkTemplateCardUtils';
import TemplateAwardsPanel from '../TemplateAwardsPanel/TemplateAwardsPanel';

type CreateTemplateDrawerProps = {
    isVisible: boolean;
    onClose: () => void;
    template?: SparkTemplate;
    onCreateClick: () => void;
};

const CreateTemplateDrawer: React.FC<CreateTemplateDrawerProps> = ({
    isVisible,
    onClose,
    template,
    onCreateClick,
}) => {
    const { userCan } = useSparkplugAccount();
    const userCanCreateSpark = userCan('createSpark');
    if (!template) {
        return <> </>;
    }

    const details = [
        { title: 'Spark Name', content: template.name },
        { title: 'Spark Description', content: template.description },

        {
            title: <span className="capitalize">{template.type} Type</span>,
            content: template.detailedSparkType
                ? getDetailedSparkTypeDisplayName(template.detailedSparkType)
                : '',
        },
        ...(template.type !== 'commission'
            ? [
                  {
                      title: `Number of ${template.type === 'leaderboard' ? 'Winners' : 'Goals'}`,
                      content:
                          template.type === 'goal'
                              ? template.goals?.length
                              : template.awards?.length,
                  },
              ]
            : []),
    ];

    return (
        <>
            <Drawer
                title="Spark Template"
                variant="right"
                isVisible={isVisible}
                onCloseHandler={onClose}
            >
                <div className="flex flex-col gap-5 pb-[93px]">
                    <SparkTemplateCard
                        title={template.templateName ?? ''}
                        desc={template.templateDescription ?? ''}
                        cardImage={getSparkTemplateCardImage(template.type)}
                        subHeading={template.type ?? ''}
                        showButtons={false}
                    />
                    {details.map((detail, index) => (
                        <div className="flex flex-col gap-1" key={index}>
                            <Typography variant="base" className="font-medium text-gray-800">
                                {detail.title}
                            </Typography>
                            <Typography variant="base" className="text-gray-950">
                                {detail.content}
                            </Typography>
                        </div>
                    ))}
                    {template.type !== 'commission' && <TemplateAwardsPanel template={template} />}
                </div>
                <Drawer.Footer className="flex space-x-2 justify-end">
                    <Button variant="flat" color="neutral" onClick={() => onClose()}>
                        Cancel
                    </Button>
                    <Button
                        startIcon={<AddIcon className="text-cerulean-600" />}
                        variant="smooth"
                        color="blue"
                        onClick={onCreateClick}
                        tooltip={!userCanCreateSpark ? DO_NOT_HAVE_PERMISSIONS_MESSAGE : ''}
                        disabled={!userCanCreateSpark}
                    >
                        Create Spark
                    </Button>
                </Drawer.Footer>
            </Drawer>
        </>
    );
};

export default CreateTemplateDrawer;
