import { FC, useMemo } from 'react';

import { ResponsiveLine } from '@nivo/line';

import { formatAxisLeftValue } from '@helpers/charts';
import { hexToRGBArray } from '@helpers/ui';

import { IExternalTooltipProps } from '@app/types/ChartDataTypes';

import { ComparisonColors } from './Nivo.Constants';
import Tooltip from './NivoLineTooltip';

// From '@nivo/line' which has no exported default layers for 'ResponsiveLine'
// TODO: Find way to export `LineDefaultProps`
const defaultLayers: any[] = [
    'grid',
    'markers',
    'axes',
    'areas',
    'crosshair',
    'lines',
    'points',
    'slices',
    'mesh',
    'legends',
];

const ComparisonArea = ({ series, areaGenerator, xScale, yScale }: any) => {
    const comparisonSeries = series?.[1];

    if (comparisonSeries == null) {
        return null;
    }

    const { id, data, color } = comparisonSeries;

    return (
        <path
            key={id}
            d={areaGenerator(
                data.map((d: any) => ({
                    x: xScale(d.data.x),
                    y: yScale(d.data.y),
                })),
            )}
            fill={color}
            fillOpacity={0.25}
        />
    );
};

const toNonFocusedColor = (hex: string): string => {
    const rgb = hexToRGBArray(hex);
    const alpha = 0.1;
    return `rgba(${rgb.join(', ')}, ${alpha})`;
};

type TNivoLineChartProps = {
    xLabel?: string;
    yLabel?: string;
    data: any;
    focusedId?: string;
    hiddenIds?: string[];
    xAxisLabels?: string[];
    visibleIds?: string[];
    tooltipProps: IExternalTooltipProps;
};

const NivoLineChart: FC<TNivoLineChartProps> = ({
    xLabel,
    yLabel,
    data,
    focusedId,
    xAxisLabels = [],
    hiddenIds = [],
    visibleIds = [],
    tooltipProps,
}) => {
    const { isComparisonWindow, formatter } = tooltipProps;

    const hasValidData = Array.isArray(data);

    if (!hasValidData) {
        return (
            <div className="chart-message">
                <span>Invalid chart data</span>
            </div>
        );
    }

    const showComparisonWindow = isComparisonWindow && data.length === 2;
    const layers = isComparisonWindow ? [ComparisonArea, ...defaultLayers] : defaultLayers;

    const maxValues = window.innerWidth < 767 ? 5 : 15;

    const lineToParse = showComparisonWindow ? 1 : 0;
    const defaultAxisBottomValues =
        data.length > 0 && data[lineToParse]?.data != null
            ? data[lineToParse].data.map((obj: { x: any }) => obj.x)
            : [];

    const axisBottomValues = xAxisLabels.length ? xAxisLabels : defaultAxisBottomValues;
    const hasHiddenLabels = axisBottomValues?.length > defaultAxisBottomValues?.length;

    const modBy = Math.max(1, Math.floor(axisBottomValues.length / maxValues));

    const lineWidth = axisBottomValues.length > 31 ? 2 : 4;
    const pointSize = axisBottomValues.length > 31 ? 5 : 10;
    const enablePoints = axisBottomValues.length < 60;
    const tickValues = axisBottomValues.filter((v: any, i: any) => {
        return i % modBy === 0;
    });

    const updatedData = useMemo(() => {
        if (Array.isArray(data) && (isComparisonWindow || hasHiddenLabels)) {
            return [
                ...data,
                {
                    id: 'hidden',
                    data: xAxisLabels?.map((x) => ({
                        x,
                        y: 0,
                        meta: {
                            isHidden: true,
                        },
                    })),
                },
            ];
        }

        return data.filter(({ id }) => !hiddenIds.includes(id));
    }, [data, isComparisonWindow, hasHiddenLabels, visibleIds, hiddenIds]);

    const colors = isComparisonWindow
        ? ({ id }: any) => {
              if (id === '*') {
                  return ComparisonColors[0];
              }
              if (id === 'Previous Period') {
                  return ComparisonColors[1];
              }

              return 'transparent';
          }
        : ({ id, color }: any) => {
              if (!color) {
                  return 'transparent';
              }
              if (focusedId && !hiddenIds?.includes(focusedId)) {
                  return focusedId === id ? color : toNonFocusedColor(color);
              }

              return color;
          };
    const nonVisiblePoints = data.filter(({ id }) => !visibleIds.includes(id));
    const nonVisibleCount = nonVisiblePoints.length ?? 0;
    const nonVisibleTotals = nonVisiblePoints.reduce((res, points) => {
        points.data.forEach(({ x, y }: any) => {
            if (res?.[x]) {
                res[x] += y;
            } else {
                res[x] = y;
            }
        });
        return res;
    }, {});

    return (
        <ResponsiveLine
            animate={false}
            useMesh
            data={updatedData}
            curve="monotoneX"
            crosshairType="bottom"
            enableSlices="x"
            lineWidth={lineWidth}
            sliceTooltip={Tooltip({ ...tooltipProps, nonVisibleTotals, nonVisibleCount })}
            margin={{
                top: 10,
                right: 10,
                bottom: 30,
                left: 68,
            }}
            xScale={{
                type: 'point',
            }}
            yScale={{
                type: 'linear',
                stacked: false,
                min: 'auto',
                max: 'auto',
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                tickValues,
                legend: xLabel,
                legendOffset: 36,
                legendPosition: 'middle',
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: yLabel,
                legendOffset: -40,
                legendPosition: 'middle',
                format: formatAxisLeftValue(formatter),
            }}
            yFormat={formatAxisLeftValue(formatter)}
            colors={colors}
            enablePoints={enablePoints}
            pointSize={pointSize}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="y"
            pointLabelYOffset={-12}
            legends={[]}
            layers={layers}
        />
    );
};

export default NivoLineChart;
