import './CountBadge.scss';

const CountBadge = ({
    count = 0,
    className = '',
}: {
    count: number;
    className?: string | null;
}) => {
    return (
        <div className={`count-badge ${className}`}>
            <span>{count}</span>
        </div>
    );
};

export default CountBadge;
