import { FC } from 'react';

import {
    EmployeeSpark,
    SparkMetric,
    SparkType,
    UISparkStanding,
    calculateUnlimitedGoalProgress,
    formatCurrency,
} from '@sparkplug/lib';

import { useEmployeeSparkStandings } from '@core/employees/queries';

import Skeleton from '@components/layout/Skeleton';

import './EmployeeRanking.scss';

export interface EmployeeRankingProps {
    spark: EmployeeSpark;
    currentUserStanding?: UISparkStanding;
    sparkStandingsAreReady: boolean;
}

export const EmployeeRanking: FC<EmployeeRankingProps> = ({
    spark,
    currentUserStanding,
    sparkStandingsAreReady,
}) => {
    const { _id: sparkId, type: sparkType } = spark;

    const SPARK_RANK_LABELS: { [key in SparkType]: string } = {
        commission: 'Commissions Earned',
        leaderboard: 'Leaderboard Rank',
        goal: 'Goals Reached',
    };

    const getSuffix = (n: number | undefined) => {
        if (!n) {
            return '';
        }
        return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
    };

    // an unlimited goal spark will only have one "goal" object
    const isUnlimitedGoal = spark.goals?.[0]?.isUnlimited;

    const goalThresholds = spark.goals?.map(({ threshold }) => threshold) ?? [];
    const currentUserPrimaryValue = currentUserStanding?.primaryValue ?? 0;
    const goalCount = isUnlimitedGoal
        ? Math.floor(currentUserPrimaryValue / (spark.goals?.[0]?.threshold ?? 1))
        : goalThresholds.filter((threshold) => threshold <= currentUserPrimaryValue).length;

    const getGoalAchievement = () => {
        return `${goalCount ?? ''} of ${
            isUnlimitedGoal ? 'Unlimited' : goalThresholds.length ?? ''
        }`;
    };

    const getGoalRankingValue = () => {
        const isFBS =
            (isUnlimitedGoal
                ? spark.fulfillmentTypes?.[0]
                : spark.fulfillmentTypes?.[goalCount - 1]) === 'sparkplug';
        if (goalCount < 1) {
            return '--';
        } else if (isFBS) {
            const { earned } = calculateUnlimitedGoalProgress(
                currentUserPrimaryValue,
                spark.goals?.[0],
                isFBS,
            );
            return isUnlimitedGoal
                ? earned
                : formatCurrency(spark.goals?.[goalCount - 1].award.award);
        } else {
            return 'See Details';
        }
    };

    const getCommissionsValue = () => {
        return currentUserStanding?.primaryValue && currentUserStanding?.primaryValue !== 0
            ? formatCurrency(currentUserStanding.primaryValue, true) ?? '--'
            : '--';
    };

    const getLeaderboardValue = () => {
        const { minimumThresholdToQualify, minimumTransactionsToQualify, metric } = spark;
        const { primaryValue = 0, transactionCount = 0, rank } = currentUserStanding ?? {};

        const thresholdNotMet =
            minimumThresholdToQualify && minimumThresholdToQualify > primaryValue;

        const transactionThresholdNotMet =
            minimumTransactionsToQualify && minimumTransactionsToQualify > transactionCount;

        if (
            (minimumThresholdToQualify && thresholdNotMet) ||
            (minimumTransactionsToQualify && transactionThresholdNotMet)
        ) {
            return '--';
        }

        return `${rank ?? ''}${getSuffix(rank)}`;
    };

    const SPARK_RANK_CONTENT: { [key in SparkType]: string } = {
        commission: getCommissionsValue(),
        leaderboard: getLeaderboardValue(),
        goal: getGoalAchievement(),
    };

    const SPARK_UNITS_CONTENT: { [key in SparkType]: string } = {
        commission: currentUserStanding?.secondaryValue?.toString() ?? '0',
        leaderboard: currentUserStanding?.primaryValue.toString() ?? '0',
        goal: currentUserStanding?.primaryValue.toString() ?? '0',
    };

    const SPARK_RANKING_LABEL: { [key in SparkMetric]: string } = {
        percent_increase: 'Percent Increase',
        transaction_count: 'Transactions',
        total_units: sparkType === 'goal' ? 'Earned' : 'Units Sold',
        total_sales: sparkType === 'goal' ? 'Earned' : 'Total Sales',
        order_average: 'Order Average',
        guest_check_average: 'Guest Check Avg',
        units_per_transaction: 'Units Per Transaction',
        percent_of_total_sales: sparkType === 'goal' ? 'Earned' : '% of Total Sales',
    };

    const SPARK_RANKING_VALUE: { [key in SparkMetric]: string } = {
        percent_increase: `${Math.abs(Number(SPARK_UNITS_CONTENT[sparkType])).toFixed(2)}%`,
        transaction_count: SPARK_UNITS_CONTENT[sparkType],
        total_units: sparkType === 'goal' ? getGoalRankingValue() : SPARK_UNITS_CONTENT[sparkType],
        total_sales:
            sparkType === 'goal'
                ? getGoalRankingValue()
                : formatCurrency(SPARK_UNITS_CONTENT[sparkType], true),
        order_average: formatCurrency(SPARK_UNITS_CONTENT[sparkType], true),
        guest_check_average: formatCurrency(SPARK_UNITS_CONTENT[sparkType], true),
        units_per_transaction: `${Math.abs(Number(SPARK_UNITS_CONTENT[sparkType])).toFixed(2)}`,
        percent_of_total_sales:
            sparkType === 'goal'
                ? getGoalRankingValue()
                : `${Math.abs(Number(SPARK_UNITS_CONTENT[sparkType])).toFixed(2)}%`,
    };

    return (
        <>
            {!sparkStandingsAreReady ? (
                <Skeleton
                    className="standings-skeleton"
                    data-testid="employee-ranking-skeleton"
                    height={38}
                />
            ) : (
                <section className="card-footer ranking" data-testid="employee-ranking-component">
                    <div className="ranking-left" data-testid="employee-ranking-left">
                        <span>{SPARK_RANK_LABELS[sparkType]}</span>
                        {SPARK_RANK_CONTENT[sparkType]}
                    </div>
                    <div className="ranking-right" data-testid="employee-ranking-right">
                        <span>{SPARK_RANKING_LABEL[spark.metric]}</span>
                        {SPARK_RANKING_VALUE[spark.metric]}
                    </div>
                </section>
            )}
        </>
    );
};

export default ({ spark }: { spark: EmployeeSpark }) => {
    const { currentUserStanding, sparkStandingsAreReady } = useEmployeeSparkStandings(spark?._id, {
        enabled: true,
    });

    return (
        <EmployeeRanking
            spark={spark}
            currentUserStanding={currentUserStanding}
            sparkStandingsAreReady={sparkStandingsAreReady}
        />
    );
};
