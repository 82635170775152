export const OAUTH_ERRORS = {
  // specific to Shopify
  invalid_hmac: {
    title: 'Invalid Authorization Code',
    message:
      'The authorization (HMAC) code provided by Shopify on the Oauth request was invalid. Please try initiating the process in the Shopify UI again.',
  },
  // specific to Shopify
  no_associated_user: {
    title: 'No Shopify User on Callback',
    message:
      'The Shopify Oauth callback was successful, but no associated user was found on the response. We may need to assist you with creating a SparkPlug account. Please contact support@sparkplug.app',
  },
  unknown_begin: {
    title: 'Unknown Error',
    message:
      'An unknown error occurred while initiating the Oauth connection process. Please try initiating the process in your application UI again.',
  },
  unknown_complete: {
    title: 'Unknown Error',
    message:
      'An unknown error occurred while completing the Oauth process. We may need to assist you with creating a SparkPlug account. Please contact support@sparkplug.app',
  },
  sp_no_account_found: {
    title: 'No SparkPlug Account Found',
    message:
      'We were unable to find a SparkPlug account associated with your existing email address.  We may need to assist you with finishing this process. Please contact support@sparkplug.app',
  },
};
