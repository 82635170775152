import Skeleton from '@components/layout/Skeleton';

import { IPosProduct } from '@app/types/PosTypes';

import SparkDetailCard from './SparkDetailCard';

const SparkProductsDetailCard = ({
    associatedProducts,
    selectedProducts,
    isLoading = false,
}: {
    associatedProducts: IPosProduct[];
    selectedProducts: IPosProduct[];
    isLoading?: boolean;
}) => {
    if (isLoading) {
        return <Skeleton width="100%" height={120} />;
    }

    let productsRows: any[] = [
        {
            label: 'All Products',
        },
    ];

    if (associatedProducts != null && associatedProducts.length > 0) {
        productsRows = associatedProducts.map((obj) => ({
            label: obj.name,
        }));
    } else if (selectedProducts != null && selectedProducts.length > 0) {
        productsRows = selectedProducts.map((obj) => ({
            label: obj.name,
        }));
    }

    return <SparkDetailCard title="Products" rows={productsRows} />;
};

export default SparkProductsDetailCard;
