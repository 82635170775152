import axios from 'axios';

import {
    CreateBrandLinkRequestBody,
    CreateBrandLinkResponseBody,
    DeleteBrandLinkPathParams,
    GetBrandLinkPathParams,
    GetBrandLinkResponseBody,
    ListAccountBrandLinksRequestPathParams,
    ListAccountBrandLinksResponseBody,
    ListProspectiveBrandLinksRequestBody,
    ListProspectiveBrandLinksResponseBody,
    RequestBrandLinkRequestBody,
    RespondToBrandLinkRequestBody,
    UpdateBrandLinkPathParams,
    UpdateBrandLinkRequestBody,
} from '@sparkplug/lib';

export default class BrandLinksAPI {
    static async list({ groupId }: ListAccountBrandLinksRequestPathParams) {
        return axios.get<ListAccountBrandLinksResponseBody>(
            `/api/v1/accounts/${groupId}/brandlinks`,
        );
    }

    static async get({ brandLinkId }: GetBrandLinkPathParams): Promise<GetBrandLinkResponseBody> {
        return (await axios.get(`/api/v1/brandlinks/${brandLinkId}`)).data;
    }

    static async create(body: CreateBrandLinkRequestBody): Promise<CreateBrandLinkResponseBody> {
        return (await axios.post(`/api/v1/brandlinks`, body)).data;
    }

    static async update(
        { brandLinkId }: UpdateBrandLinkPathParams,
        body: UpdateBrandLinkRequestBody,
    ): Promise<void> {
        return axios.put(`/api/v1/brandlinks/${brandLinkId}`, body);
    }

    static async delete({ brandLinkId }: DeleteBrandLinkPathParams): Promise<void> {
        return axios.delete(`/api/v1/brandlinks/${brandLinkId}`);
    }

    static async requestBrandLink(body: RequestBrandLinkRequestBody): Promise<void> {
        return axios.post(`/api/v1/brandlinks/request`, body);
    }

    static async respondToBrandLinkRequest(body: RespondToBrandLinkRequestBody): Promise<void> {
        return axios.post(`/api/v1/brandlinks/respond`, body);
    }

    static async getProspectiveBrandLinks(
        body: ListProspectiveBrandLinksRequestBody,
    ): Promise<ListProspectiveBrandLinksResponseBody> {
        return (await axios.post(`/api/v1/brandlinks/prospectives`, body)).data;
    }
}
