import { useEffect, useState } from 'react';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import SMSMessageFormFields from '@components/forms/SMSMessageFormFields';
import CalloutMessage from '@components/layout/CalloutMessage';
import Modal, { IDefaultModalProps } from '@components/overlays/Modal';

import { noop } from '@helpers/util';

import './ComposeBulkSMSModal.scss';

interface IComposeBulkSMSModal extends IDefaultModalProps {}

const ComposeBulkSMSModal = ({ isVisible = false, onClose = noop }: IComposeBulkSMSModal) => {
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (isVisible) {
            setMessage('');
        }
    }, [isVisible]);

    return (
        <Modal
            className="compose-bulk-sms-modal"
            isVisible={isVisible}
            onClose={() => onClose(false)}
        >
            <Form>
                <Modal.Title borderBottom dense onClose={() => onClose(false)}>
                    Compose Bulk SMS
                </Modal.Title>
                <Modal.Content>
                    <CalloutMessage
                        variant="default"
                        color="neutral"
                        title="Instructions"
                        message="Write a message to apply to all of the selected participants."
                    />
                    <SMSMessageFormFields message={message} setMessage={setMessage} />
                </Modal.Content>
                <Modal.Actions>
                    <Button color="neutral" variant="smooth" onClick={() => onClose(false)}>
                        Cancel
                    </Button>
                    <Modal.ValidationButton
                        color="blue"
                        variant="filled"
                        onClick={() => {
                            onClose(true, message);
                        }}
                    >
                        Apply
                    </Modal.ValidationButton>
                </Modal.Actions>
            </Form>
        </Modal>
    );
};

export default ComposeBulkSMSModal;
