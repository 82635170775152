import { useCallback, useMemo, useState } from 'react';

import { isEmpty, isUndefined, keyBy, omitBy } from 'lodash';
import moment from 'moment';

import {
    RetailerSparkFilters,
    SAMPLE_TEXT_TARGET,
    Spark,
    applyProductNameContainsFilter as applyProductNameContainsFilterFn,
    applyProductNameDoesNotContainFilter as applyProductNameDoesNotContainFilterFn,
    applyProductNameFilters as applyProductNameFiltersFn,
} from '@sparkplug/lib';

import { TExtendedListItem } from '@components/inputs/ListSelector/ListSelector';

import { useSearch } from '@hooks/UIHooks';

import { IPosProduct } from '@app/types/PosTypes';

import { LAST_SOLD_DATE_OPTIONS } from '../../constants';

export interface PosRulesBasedProductTableFilters
    extends Omit<RetailerSparkFilters, 'posBrandIds' | 'posCategoryIds'> {
    brands: TExtendedListItem<{ key: string }>[];
    categories: TExtendedListItem<{ key: string }>[];
}

export const DEFAULT_POS_RULES_BASED_PRODUCT_FILTERS: PosRulesBasedProductTableFilters = {
    primaryFilter: 'brands',
    brands: [],
    categories: [],
    productNameFilters: [],
    productNameContains: [],
    productNameDoesNotContain: [],
    lastSoldAt: '-60days',
    hideSampleProducts: false,
    excludedProductIds: [],
};

export const usePosRulesBasedProductTableFilters = ({
    isEditingExistingSpark,
    initialFilters,
    allBrandsLength = 0,
    sparkStartDate: _sparkStartDate,
}: {
    isEditingExistingSpark: boolean;
    initialFilters: Partial<Spark['retailerFilters']>;
    sparkStartDate?: string;
    allBrandsLength?: number;
}) => {
    const { applySearch, onChangeSearchFilter, resetSearchFilter } = useSearch([
        'name',
        'brand',
        'category',
    ]);
    const sparkInitialFilterValues = !isEmpty(initialFilters)
        ? omitBy(
              {
                  brands: initialFilters?.posBrandIds?.map((posBrandId) => ({
                      label: posBrandId,
                      value: posBrandId,
                  })),
                  categories: initialFilters?.posCategoryIds?.map((posCategoryId) => ({
                      label: posCategoryId,
                      value: posCategoryId,
                  })),
                  ...initialFilters,
                  posBrandIds: undefined,
                  posCategoryIds: undefined,
              },
              isUndefined,
          )
        : {};

    const [filters, setFilters] = useState<PosRulesBasedProductTableFilters>({
        ...DEFAULT_POS_RULES_BASED_PRODUCT_FILTERS,
        ...sparkInitialFilterValues,
    });

    const sparkStartDate = useMemo(
        () => (moment(_sparkStartDate).isSameOrBefore(moment()) ? _sparkStartDate : moment()),

        [_sparkStartDate],
    );

    const oldProductCutoffDate = useMemo(
        () => moment(sparkStartDate).subtract(60, 'days'),
        [sparkStartDate],
    );

    const isInitiallyEmpty = useMemo(
        () =>
            !filters.categories &&
            !filters.brands &&
            !filters.productNameFilters &&
            !isEditingExistingSpark,
        [filters],
    );

    const applyHideSampleProducts = useCallback(
        (rows: IPosProduct[]) => {
            return filters.hideSampleProducts
                ? rows.filter(({ label, categories }) => {
                      const category = categories?.[0]?.name ?? '';
                      return (
                          !label.toLowerCase().includes(SAMPLE_TEXT_TARGET) &&
                          !category?.toLowerCase().includes(SAMPLE_TEXT_TARGET)
                      );
                  })
                : rows;
        },
        [filters.hideSampleProducts],
    );

    const applyBrandFilters = useCallback(
        (rows: IPosProduct[]) => {
            if (isInitiallyEmpty) return [];

            if (!filters.brands?.length || filters.brands?.length === allBrandsLength) {
                return rows;
            }

            return rows.filter(({ brandId }) =>
                filters.brands?.map(({ value }) => value).includes(brandId ?? ''),
            );
        },
        [isInitiallyEmpty, filters.brands, allBrandsLength],
    );

    const applyCategoryFilters = useCallback(
        (rows: IPosProduct[]) => {
            if (isInitiallyEmpty) return [];
            if (!filters.categories?.length) {
                return rows;
            }

            return rows.filter(({ categoryId }) =>
                filters.categories?.map(({ value }) => value).includes(categoryId ?? ''),
            );
        },
        [isInitiallyEmpty, filters.categories],
    );

    const nameFilterFunctions = useMemo(() => {
        const applyProductNameFilters = applyProductNameFiltersFn(
            filters.productNameFilters ? filters.productNameFilters : [],
        );

        const applyProductNameContainsFilter = applyProductNameContainsFilterFn(
            filters.productNameContains ? filters.productNameContains : [],
        );

        const applyProductNameDoesNotContainFilter = applyProductNameDoesNotContainFilterFn(
            filters.productNameDoesNotContain ? filters.productNameDoesNotContain : [],
        );

        return {
            applyProductNameFilters,
            applyProductNameContainsFilter,
            applyProductNameDoesNotContainFilter,
        };
    }, [
        filters.productNameFilters,
        filters.productNameContains,
        filters.productNameDoesNotContain,
    ]);

    const applyLastSoldFilter = useCallback(
        (rows: IPosProduct[]) => {
            if (filters.lastSoldAt === 'allTime') {
                return rows;
            } else {
                const { onApply = () => () => true } =
                    LAST_SOLD_DATE_OPTIONS.find(({ value }) => filters.lastSoldAt === value) ?? {};
                const onApplyWithCutoff = onApply(sparkStartDate);
                return rows.filter(({ lastSoldAt }) => onApplyWithCutoff(lastSoldAt));
            }
        },
        [filters.lastSoldAt],
    );

    const applyIncludedProductsFilter = useCallback(
        (rows: IPosProduct[]) => {
            if (filters.excludedProductIds.length === 0) {
                return rows;
            }

            return rows.filter((product) => {
                return !filters.excludedProductIds.includes(product._id);
            });
        },
        [filters.excludedProductIds],
    );

    const applyExcludedProductsFilter = useCallback(
        (rows: IPosProduct[]) => {
            return rows.filter((product) => {
                return filters.excludedProductIds.includes(product._id);
            });
        },
        [filters.excludedProductIds],
    );

    const onViewAll = () => {
        setFilters({
            brands: [],
            categories: [],
            lastSoldAt: 'allTime',
            hideSampleProducts: false,
            productNameFilters: [],
            productNameContains: [],
            productNameDoesNotContain: [],
            excludedProductIds: [],
        });
    };

    // This returns all products regardless of the `lastSoldAt` filter
    const getFilteredProductsWithoutLastSoldAtFilter = useMemo(() => {
        return (rows: IPosProduct[]) => {
            return [
                applyHideSampleProducts,
                applyBrandFilters,
                applyCategoryFilters,
                nameFilterFunctions.applyProductNameFilters,
                nameFilterFunctions.applyProductNameContainsFilter,
                nameFilterFunctions.applyProductNameDoesNotContainFilter,
                applyIncludedProductsFilter,
            ].reduce((res, filter) => {
                return filter(res);
            }, rows);
        };
    }, [
        applyHideSampleProducts,
        applyBrandFilters,
        applyCategoryFilters,
        nameFilterFunctions,
        applyIncludedProductsFilter,
    ]);

    const getExcludedProductsWithoutLastSoldAtFilter = useMemo(() => {
        return (rows: IPosProduct[]) => {
            if (filters.excludedProductIds.length) {
                return applyExcludedProductsFilter(rows);
            }

            // Rather than create multiple "rule-excludes", let's use the filtered products to created the excluded products list
            const qualifyingProducts = getFilteredProductsWithoutLastSoldAtFilter([...rows]);
            const qualifyingProductsById = keyBy(qualifyingProducts, '_id');

            return rows.filter(({ _id }) => !qualifyingProductsById[_id]);
        };
    }, [filters, applyExcludedProductsFilter, getFilteredProductsWithoutLastSoldAtFilter]);

    const getRulesBasedFilteredProducts = useMemo(() => {
        return (rows: IPosProduct[]) => {
            const partiallyFilteredProducts = getFilteredProductsWithoutLastSoldAtFilter(rows);

            return applyLastSoldFilter(partiallyFilteredProducts);
        };
    }, [getFilteredProductsWithoutLastSoldAtFilter, applyLastSoldFilter]);

    const getFilteredOldProducts = useMemo(() => {
        return (rows: IPosProduct[]) => {
            if (filters.lastSoldAt === 'allTime') {
                return [];
            }

            const unfilteredOldProducts = rows.filter(({ lastSoldAt }) =>
                moment(lastSoldAt).isBefore(oldProductCutoffDate, 'day'),
            );

            return getFilteredProductsWithoutLastSoldAtFilter(unfilteredOldProducts);
        };
    }, [filters, _sparkStartDate, getFilteredProductsWithoutLastSoldAtFilter]);

    return {
        filters,
        setFilters,
        resetSearchFilter,
        applyHideSampleProducts,
        applyBrandFilters,
        applyCategoryFilters,
        applyLastSoldFilter,
        applyProductNameFilters: nameFilterFunctions.applyProductNameFilters,
        applyProductNameContainsFilter: nameFilterFunctions.applyProductNameContainsFilter,
        applyProductNameDoesNotContainFilter:
            nameFilterFunctions.applyProductNameDoesNotContainFilter,
        applySearch,
        applyIncludedProductsFilter,
        applyExcludedProductsFilter,
        onViewAll,
        onChangeSearchFilter,
        getRulesBasedFilteredProducts,
        getFilteredOldProducts,
        getExcludedProductsWithoutLastSoldAtFilter,
    };
};

export type UsePosRulesBasedSparkProductSelectorTableFilters = ReturnType<
    typeof usePosRulesBasedProductTableFilters
>;
