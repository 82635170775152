import { FC, useState } from 'react';

import QuickViewsAPI from '@api/QuickViewsAPI';
import { isEmpty } from 'lodash';

import { QuickViewQueryParams, QuickViewTag } from '@sparkplug/lib';

import IconButton from '@components/buttons/IconButton';
import Form from '@components/form/Form';
import { Save as SaveIcon } from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import toast from '@components/toast';

import { QuickViewPopoverWidget } from '../QuickViewPopoverWidget';

import './CreateQuickViewsWidget.scss';

const CreateQuickViewsWidgetButton = ({ openPopover }: { openPopover: () => void }) => (
    <IconButton className="create-quick-views_button" onClick={openPopover} color="blue">
        <SaveIcon />
    </IconButton>
);

interface CreateQuickViewWidgetProps {
    queryParams: QuickViewQueryParams;
    tag: QuickViewTag;
}
export const CreateQuickViewsWidget: FC<CreateQuickViewWidgetProps> = ({
    queryParams = {},
    tag,
}) => {
    const [name, setName] = useState<string>();

    const canCreateQuickView = name && !isEmpty(queryParams);

    const handleCreateQuickView = async () => {
        if (!canCreateQuickView) {
            return;
        }

        // TODO: use React-Query mutation when the list hook is set up
        QuickViewsAPI.create(tag, {
            name,
            queryParams,
        })
            .then(() => toast.success('Success'))
            .catch(() => toast.error('Error'));
    };

    return (
        <QuickViewPopoverWidget
            PopoverButton={CreateQuickViewsWidgetButton as any}
            title="Create Quick View"
            content={
                <div className="create-quick-views_content">
                    <CalloutMessage
                        color="neutral"
                        variant="default"
                        message="Save this view with the current filter applied for quick access."
                    />

                    <Form.TextField
                        className="create-quick-view_text-field"
                        name="create-quick-view"
                        label="Quick View Title"
                        value={name ?? ''}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
            }
            onSave={handleCreateQuickView}
            disableSave={!canCreateQuickView}
            onClose={() => setName(undefined)}
        />
    );
};
