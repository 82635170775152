import { parseCurrencyToNumber } from './text';

export const formatCurrency = (num: string | number, showCents = false): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: showCents ? 2 : 0,
  });
  if (typeof num === 'string') {
    return formatter.format(parseCurrencyToNumber(num));
  }

  return formatter.format(num);
};

export const formatFloat = (n: number, digits = 2): string => n.toFixed(digits);
