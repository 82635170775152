import { FC } from 'react';
import { useParams } from 'react-router';

import { useAccountLinks } from '@features/account-links';

import { useApp } from '@hooks/AppHooks';

import { IAccount } from '@app/types/AccountsTypes';

import VendorMarketDashboardView from './VendorMarketDashboardView';
import VendorRetailerDashboardView from './VendorRetailerDashboardView';
import { withVendorDashboardSidebarManager } from './withVendorDashboardSidebarManager';

import './VendorDashboardView.scss';

interface VendorDashboardViewProps {
    account: IAccount;
}
export const VendorDashboardView: FC<VendorDashboardViewProps> = ({ account }) => {
    const { market } = useParams<{ market: string }>();
    const { accountLinks = [] } = useAccountLinks(account?._id ?? '');
    const { userIsSuperAdmin } = useApp();

    if (market) {
        return <VendorMarketDashboardView account={account} market={market} />;
    }

    return (
        <VendorRetailerDashboardView
            account={account}
            accountLinks={accountLinks}
            userIsSuperAdmin={userIsSuperAdmin}
        />
    );
};

export default withVendorDashboardSidebarManager(VendorDashboardView);
