import axios from 'axios';

import {
    CreateAccountSnapRequestBody,
    CreateAccountSnapResponseBody,
    CreateAccountSnapTemplateRequestBody,
    CreateAccountSnapTemplateResponseBody,
    UpdateAccountSnapRequestBody,
    UpdateAccountSnapResponseBody,
    UpdateAccountSnapTemplateRequestBody,
    UpdateAccountSnapTemplateResponseBody,
    UpdateSparkSnapsRequestBody,
} from '@sparkplug/lib';

import { useAdvancedMutation } from '@hooks/QueryHooks';
import { getSparkQueryKey } from '@hooks/SparksHooks/SparksHooks';

import { getSnapQueryKey } from '../queries';
import { getSnapTemplatesQueryKey } from '../queries/SparkSnapsQueries';

const API = {
    createSnap: async ({
        accountId,
        snapId,
        payload,
    }: {
        accountId: string;
        snapId: number;
        payload?: CreateAccountSnapRequestBody;
    }) => {
        return (
            await axios.post<CreateAccountSnapRequestBody, { data: CreateAccountSnapResponseBody }>(
                `/api/v1/accounts/${accountId}/snaps/${snapId}/create`,
                payload,
            )
        ).data;
    },
    updateSnap: async ({
        accountId,
        snapId,
        payload,
    }: {
        accountId: string;
        snapId: number;
        payload: UpdateAccountSnapRequestBody;
    }) => {
        return (
            await axios.put<UpdateAccountSnapRequestBody, { data: UpdateAccountSnapResponseBody }>(
                `/api/v1/accounts/${accountId}/snaps/${snapId}`,
                payload,
            )
        ).data;
    },
    updateSparkSnaps: async ({
        sparkId,
        payload,
    }: {
        sparkId: string;
        payload: UpdateSparkSnapsRequestBody;
    }) => {
        return (
            await axios.put<UpdateAccountSnapRequestBody, { data: UpdateAccountSnapResponseBody }>(
                `/api/v1/sparks/${sparkId}/snaps`,
                payload,
            )
        ).data;
    },
    createSnapTemplate: async ({
        accountId,
        payload,
    }: {
        accountId: string;
        payload: CreateAccountSnapTemplateRequestBody;
    }) => {
        return (
            await axios.post<
                CreateAccountSnapTemplateRequestBody,
                { data: CreateAccountSnapTemplateResponseBody }
            >(`/api/v1/accounts/${accountId}/snap-templates`, payload)
        ).data;
    },
    updateSnapTemplate: async ({
        accountId,
        snapTemplateId,
        payload,
    }: {
        accountId: string;
        snapTemplateId: number;
        payload: UpdateAccountSnapTemplateRequestBody;
    }) => {
        return (
            await axios.put<
                UpdateAccountSnapTemplateRequestBody,
                { data: UpdateAccountSnapTemplateResponseBody }
            >(`/api/v1/accounts/${accountId}/snap-templates/${snapTemplateId}`, payload)
        ).data;
    },
    deleteSnapTemplate: async ({
        accountId,
        snapTemplateId,
    }: {
        accountId: string;
        snapTemplateId: number;
    }) => {
        await axios.delete(`/api/v1/accounts/${accountId}/snap-templates/${snapTemplateId}`);
    },
    removeSnapFromSpark: async ({
        accountId,
        sparkId,
        snapId,
    }: {
        accountId: string;
        sparkId: string;
        snapId: number;
    }) => {
        await axios.put(`/api/v1/accounts/${accountId}/sparks/${sparkId}/snaps/${snapId}`);
    },
};

export const useUpdateSnapMutation = ({
    accountId,
    snapId,
    isTemplate,
}: {
    accountId: string;
    snapId: number;
    isTemplate: boolean;
}) => {
    const { mutateAsync, isLoading } = useAdvancedMutation(
        (payload: UpdateAccountSnapRequestBody) =>
            API.updateSnap({
                accountId,
                snapId,
                payload,
            }),
        {
            updateQuery: {
                queryKey: isTemplate
                    ? getSnapTemplatesQueryKey(accountId)
                    : getSnapQueryKey(accountId, snapId),
            },
            toastOptions: {
                loading: 'Updating...',
                success: isTemplate ? 'Template name updated' : 'Snap name updated.',
                error: 'Something went wrong.',
            },
        },
    );

    return { isUpdatingSnap: isLoading, updateSnapAsync: mutateAsync };
};

export const useCreateSnapTemplateMutation = ({ accountId }: { accountId: string }) => {
    const { mutateAsync, isLoading } = useAdvancedMutation(
        (payload: CreateAccountSnapTemplateRequestBody) =>
            API.createSnapTemplate({
                accountId,
                payload,
            }),
        {
            updateQuery: { queryKey: getSnapTemplatesQueryKey(accountId) },
            toastOptions: {
                loading: 'Saving...',
                success: 'Template created.',
                error: 'Something went wrong.',
            },
        },
    );

    return { isCreatingSnapTemplate: isLoading, createSnapTemplateAsync: mutateAsync };
};

export const useUpdateSnapTemplateMutation = ({ accountId }: { accountId: string }) => {
    const { mutateAsync, isLoading } = useAdvancedMutation(
        ({
            snapTemplateId,
            payload,
        }: {
            snapTemplateId: number;
            payload: UpdateAccountSnapTemplateRequestBody;
        }) =>
            API.updateSnapTemplate({
                accountId,
                snapTemplateId,
                payload,
            }),
        {
            updateQuery: { queryKey: getSnapTemplatesQueryKey(accountId) },
            toastOptions: {
                loading: 'Updating...',
                success: 'Snap template updated.',
                error: 'Something went wrong.',
            },
        },
    );

    return { isUpdatingSnapTemplate: isLoading, updateSnapTemplateAsync: mutateAsync };
};

export const useDeleteSnapTemplateMutation = ({ accountId }: { accountId: string }) => {
    const { mutateAsync, isLoading } = useAdvancedMutation(
        ({ snapTemplateId }: { snapTemplateId: number }) =>
            API.deleteSnapTemplate({
                accountId,
                snapTemplateId,
            }),
        {
            updateQuery: { queryKey: getSnapTemplatesQueryKey(accountId) },
            toastOptions: {
                loading: 'Deleting...',
                success: 'Snap template deleted.',
                error: 'Something went wrong.',
            },
        },
    );

    return { isDeletingSnapTemplate: isLoading, deleteSnapTemplateAsync: mutateAsync };
};

export const useUpdateSparkSnapsMutation = ({ sparkId }: { sparkId: string }) => {
    const { mutateAsync, isLoading } = useAdvancedMutation(
        (payload: UpdateSparkSnapsRequestBody) =>
            API.updateSparkSnaps({
                sparkId,
                payload,
            }),
        {
            updateQuery: { queryKey: getSparkQueryKey(sparkId) },
            toastOptions: {
                loading: 'Updating...',
                success: 'Snaps updated',
                error: 'Something went wrong.',
            },
        },
    );

    return { isUpdatingSparkSnaps: isLoading, updateSparkSnapsAsync: mutateAsync };
};

export const useCreateSnapMutation = (accountId: string) => {
    const { mutateAsync, isLoading } = useAdvancedMutation(
        ({ payload, snapId }: { payload?: UpdateAccountSnapRequestBody; snapId: number }) =>
            API.createSnap({
                accountId,
                snapId,
                payload,
            }),
        {
            updateQuery: {
                queryKey: 'featuredSnaps',
            },
        },
    );
    return { isLoading, createSnapAsync: mutateAsync };
};

export const useRemoveSnapFromSpark = ({ accountId }: { accountId: string }) => {
    const { mutateAsync, isLoading } = useAdvancedMutation(
        ({ snapId, sparkId }: { snapId: number; sparkId: string }) =>
            API.removeSnapFromSpark({
                accountId,
                sparkId,
                snapId,
            }),
        {
            updateQuery: { queryKey: 'spark-snaps' },
            toastOptions: {
                loading: 'Deleting...',
                success: 'Snap deleted.',
                error: 'Something went wrong.',
            },
        },
    );

    return { isRemovingSnap: isLoading, removeSnapAsync: mutateAsync };
};
