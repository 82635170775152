import { FC, useEffect, useMemo, useState } from 'react';
import { Redirect, useLocation } from 'react-router';

import LocalStorage from '@data/LocalStorage';

import { registerPointOfSale } from '@core/accounts';

import { AccountIdKey } from '@contexts/AccountContext';

import CalloutMessage from '@components/layout/CalloutMessage';
import Paper from '@components/layout/Paper';
import Spinner from '@components/layout/Spinner';
import { Link, useParams } from '@components/router';

import { useApp } from '@hooks/AppHooks';

import { isEmpty } from '@helpers/util';

import './OAuthRedirect.scss';

export const OAuthRedirect: FC = () => {
    const { appIsReady, user } = useApp();
    const location = useLocation();
    const [oauthResponse, setOauthResponse] = useState<any>(undefined);
    const [apiResponse, setApiResponse] = useState<any>(undefined);
    const [authError, setAuthError] = useState<string | undefined>(undefined);
    const [hasApiError, setHasApiError] = useState<boolean>(false);
    const { provider }: { provider: string } = useParams();
    const supportedProviders = ['square', 'shopify'];

    const accountId = useMemo(() => {
        const idFromStorage = LocalStorage.get(AccountIdKey) as string;
        return idFromStorage || user?.accounts?.[0]?._id;
    }, [user]);

    useEffect(() => {
        if (!accountId) {
            return;
        }
        const search = new URLSearchParams(location.search);
        setOauthResponse(Object.fromEntries(search.entries()));
        if (search.has('code')) {
            registerPointOfSale({
                groupId: accountId,
                code: search.get('code')!,
                shop: search.get('shop') != null ? search.get('shop')! : undefined,
                provider,
            })
                .then((result) => {
                    setApiResponse(result);
                })
                .catch(() => {
                    setHasApiError(true);
                });
        }
        if (search.get('error') === 'access_denied') {
            setAuthError('Access denied');
        }
    }, [accountId, location]);

    if (!(accountId && appIsReady)) {
        return <Spinner />;
    }

    const ReturnLink: FC = () => {
        return (
            <>
                <Link to={`/${accountId}/settings/integrations`}>Click here</Link> to return to your
                integration settings.
            </>
        );
    };

    if (!provider || !supportedProviders.includes(provider)) {
        const message =
            provider && provider !== 'dashboard'
                ? `"${provider}" is not currently a supported OAuth provider.`
                : `There was no OAuth provider on the response url.`;

        return (
            <Paper>
                <Paper.Title>Unknown OAuth Provider</Paper.Title>
                <CalloutMessage className="margin-bottom" message={message} color="red" />
                <ReturnLink />
            </Paper>
        );
    }

    if (oauthResponse === undefined || isEmpty(oauthResponse)) {
        const message = `There was no response from the OAuth provider (${provider})`;
        return (
            <Paper>
                <Paper.Title>OAuth response missing</Paper.Title>
                <CalloutMessage className="margin-bottom" message={message} color="red" />
                <ReturnLink />
            </Paper>
        );
    }

    if (authError) {
        return (
            <Paper>
                <Paper.Title>Authorization Error</Paper.Title>
                <CalloutMessage className="margin-bottom" message={authError} color="red" />
                <ReturnLink />
            </Paper>
        );
    }

    if (hasApiError) {
        return (
            <Paper>
                <Paper.Title>Unknown Error</Paper.Title>
                <CalloutMessage
                    className="margin-bottom"
                    message="An unexpected error has occurred"
                    color="red"
                />
                <ReturnLink />
            </Paper>
        );
    }

    if (apiResponse) {
        return <Redirect to={`/${accountId}/settings/integrations`} />;
    }

    return <Spinner />;
};

export default OAuthRedirect;
