import { useMemo } from 'react';

import { useAccountUsersQuery } from '@core/users';

import { NoUsersGraphic } from '@components/graphics';
import RadioGroup from '@components/inputs/RadioGroup';
import CalloutMessage from '@components/layout/CalloutMessage';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import Grid from '@components/layout/Grid';
import Skeleton from '@components/layout/Skeleton';

import { useApp } from '@hooks/AppHooks';
import {
    useAccountPosDataQuery,
    useAppAccount,
    useSparkplugAccount,
} from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import MultiRetailerParticipantSelector from './components/MultiRetailerParticipantSelector';
import ParticipantsTreeSelector from './components/ParticipantsTreeSelector';
import ParticipantProvider, { useParticipantsContext } from './context';
import { SparkParticipantsSelectorProps } from './types';

import './SparkParticipantsSelector.scss';

const WrappedSparkParticipantsSelector = () => {
    const [
        { activeParticipantOptions, participantTypeOptions, accountPosDataIsReady },
        { onChangeType },
    ] = useParticipantsContext();

    const { user } = useApp();
    const { account: appAccount } = useAppAccount();
    const { account } = useSparkplugAccount();

    const {
        spark: { groupId, type },
        detailedSparkType,
        isCreatingMultiRetailerSpark,
    } = useSpark();
    const { accountUsersAreReady: retailerAccountUsersAreReady } = useAccountUsersQuery(groupId);
    /**
     * NOTE: Skeleton is rendered in the default export
     */
    const participantsSelector = useMemo(() => {
        if (isCreatingMultiRetailerSpark && account?.type === 'brand') {
            return <MultiRetailerParticipantSelector />;
        } else if (!activeParticipantOptions?.length) {
            // this state should only ever happen on manager goal sparks where no managers have a phone number
            return (
                <div className="no-available-participants">
                    <EmptyStateDisplay graphic={<NoUsersGraphic />} />
                    No {detailedSparkType === 'goalManager' ? 'managers' : 'participants'} are
                    currently enrolled.
                </div>
            );
        } else {
            return (
                <ParticipantsTreeSelector
                    showUserTypeFilters={
                        (appAccount?.type === 'retailer' || user?.role === 'super-admin') &&
                        detailedSparkType !== 'goalManager'
                    }
                />
            );
        }
    }, [accountPosDataIsReady, retailerAccountUsersAreReady, detailedSparkType]);

    return (
        <Grid className="spark-participants-selector">
            {participantTypeOptions.length > 1 && (
                <Grid.Item sm={3}>
                    <RadioGroup
                        color="neutral"
                        variant="button"
                        required
                        label={type === 'goal' ? 'Goal Type' : 'Leaderboard Type'}
                        value={detailedSparkType ?? ''}
                        options={participantTypeOptions}
                        onChange={(event) => {
                            onChangeType(event.target.value);
                        }}
                    />
                </Grid.Item>
            )}
            <Grid.Item
                className="participants-column"
                sm={participantTypeOptions.length > 1 ? 9 : 12}
            >
                {!isCreatingMultiRetailerSpark && detailedSparkType === 'goalTeam' && (
                    <CalloutMessage
                        message={
                            <span>
                                In a <strong>Team Goal</strong>, all selected locations combine to
                                form a single team. To create separate Team Goals for multiple
                                locations, create a new Team Goal Spark for each.
                            </span>
                        }
                    />
                )}
                {!isCreatingMultiRetailerSpark && detailedSparkType === 'goalManager' && (
                    <CalloutMessage
                        message={
                            <span>
                                In a <strong>Manager Goal</strong>, all selected locations combine
                                to form a single team. To create separate Manager Goals for multiple
                                locations, create a new Manager Goal Spark for each.
                            </span>
                        }
                    />
                )}
                {participantsSelector}
            </Grid.Item>
        </Grid>
    );
};

const SparkParticipantsSelectonWrapper = ({
    isBrandApprovalResponse = false,
    onSelectionChanged,
}: SparkParticipantsSelectorProps) => {
    const { sparkIsReady, spark, isCreatingMultiRetailerSpark } = useSpark();

    const { accountPosDataIsReady } = useAccountPosDataQuery(spark.groupId, {
        includedDatasets: ['locations'],
    });

    const { accountUsersAreReady: retailerAccountUsersAreReady } = useAccountUsersQuery(
        spark.groupId,
    );

    if (
        (!sparkIsReady || !accountPosDataIsReady || !retailerAccountUsersAreReady) &&
        !isCreatingMultiRetailerSpark
    ) {
        return <Skeleton height={400} width="100%" />;
    }

    return (
        <ParticipantProvider
            isBrandApprovalResponse={isBrandApprovalResponse}
            onSelectionChanged={onSelectionChanged}
        >
            <WrappedSparkParticipantsSelector />
        </ParticipantProvider>
    );
};

export default SparkParticipantsSelectonWrapper;
