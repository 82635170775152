import { FC, useMemo } from 'react';

import moment from 'moment';

import { HydratedBrandProduct, formatCurrency } from '@sparkplug/lib';

import Form from '@components/form/Form';
import DetailsList from '@components/layout/DetailsList';
import Spinner from '@components/layout/Spinner';
import Drawer from '@components/overlays/Drawer';

import { usePosProductData } from '../../../queries';
import { ProductTagsTableRow } from '../useProductTagsTableFilters';

import './ProductDetailsDrawer.scss';

interface ProductInfoDrawerProps {
    product?: ProductTagsTableRow;
    onClose: () => void;
}
type TDetailsRecord = Partial<
    Record<keyof ProductTagsTableRow & keyof HydratedBrandProduct, string> & {
        brand: string;
        category: string;
    }
>;

const DetailsRecord: TDetailsRecord = {
    name: 'Product Name',
    brand: 'Brand',
    category: 'Category',
    lastSoldAt: 'Last Sold Date',
    description: 'Description',
    weight: 'Weight',
    flowerType: 'Flower Type',
    strain: 'Strain',
    price: 'Price',
    thcContent: 'THC Content',
    cbdContent: 'CBD Content',
    medical: 'Medical',
    active: 'Active',
    byGram: 'By Gram',
    byPrepackage: 'By Prepackage',
    unitCost: 'Unit Cost',
    totalCost: 'Total Cost',
    weightPerUnit: 'Weight Per Unit',
    sample: 'Sample',
    availableOnline: 'Available Online',
    subcategoryName: 'Subcategory Name',
    vendorName: 'Vendor Name',
    masterCategory: 'Master Category',
    flavor: 'Flavor',
    defaultUnit: 'Default Unit',
};

export const parseValue = (value: string | number | null | boolean, key: string) => {
    if (value === null) {
        return '--';
    }

    if (key === 'lastSoldAt' && typeof value === 'string') {
        return <>{moment(value).format('MM/DD/YYYY')}</>;
    }

    if (
        ['price', 'cost'].some((monetaryWord) => key.toLowerCase().includes(monetaryWord)) &&
        typeof value !== 'boolean'
    ) {
        return formatCurrency(value, true);
    }

    switch (typeof value) {
        case 'string':
        case 'number':
            return value;
        case 'boolean':
            return value ? 'Yes' : 'No';

        default:
            return '--';
    }
};

const ProductInfoDrawer: FC<ProductInfoDrawerProps> = ({ product, onClose }) => {
    const { posProductDataIsReady, posProductData } = usePosProductData(
        product?._id || '',
        product?.groupId || '',
    );

    const productInfoDetails = useMemo(() => {
        const allData = { ...product, ...posProductData?.data };
        return Object.entries(DetailsRecord).map(([key, value]) => ({
            title: value,
            content: parseValue(allData?.[key], key),
        }));
    }, [posProductData]);

    return !product ? (
        <></>
    ) : (
        <Drawer
            className="product-details-drawer"
            onCloseHandler={onClose}
            isVisible={Boolean(product)}
            variant="right"
            title="Product Details"
        >
            <Form>
                <Form.SectionTitle>Product Details </Form.SectionTitle>
                {posProductDataIsReady ? (
                    <DetailsList layout="horizontal" condensed details={productInfoDetails || []} />
                ) : (
                    <div className="loader">
                        <Spinner /> Loading product details...
                    </div>
                )}
            </Form>
        </Drawer>
    );
};

export default ProductInfoDrawer;
