import React, { useMemo, useTransition } from 'react';

import { useDeleteLearningResource } from '@views/control-center/CCLearningResources/mutations/useDeleteLearningResource';
import moment from 'moment-timezone';

import {
    GetAllLearningResourceQueryParams,
    LearningResourceStatus,
    formatCurrency,
} from '@sparkplug/lib';

import { useGetAllAccountCourses } from '@features/courses/queries/useGetAllCourses';

import Chip from '@components/chips/Chip';
import Dropdown from '@components/dropdown/Dropdown';
import { MoreVert } from '@components/icons';
import PageLoading from '@components/layout/PageLoading';
import { queryToString, useQueryParams } from '@components/router';
import Table from '@components/table/Table';
import Toolbar from '@components/toolbar/Toolbar';

import { useApp } from '@hooks/AppHooks';
import useVendorBrands from '@hooks/BrandHooks/useVendorBrands';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { THeadCell } from '@app/types/TableTypes';

import CoursesEmptyState from '../CoursesEmptyState';

const CoursesList = () => {
    const [isPending, startTransition] = useTransition();
    const { account } = useSparkplugAccount();
    const { history } = useApp();
    const { data: vendorBrands, isLoading: isLoadingVendorBrands } = useVendorBrands(account);

    const queryParams: {
        p?: number;
        brandId?: string;
        rid?: string;
        status?: LearningResourceStatus;
    } & GetAllLearningResourceQueryParams = useQueryParams();

    const { deleteLearningResource } = useDeleteLearningResource();

    const columns: THeadCell<any>[] = [
        {
            id: 'brandName',
            label: 'Brand',
            render: (row) => (
                <Table.Cell>
                    <div className="flex items-center gap-2">
                        {row.brandPhoto && (
                            <img src={row.brandPhoto} alt="brand" className="h-10 w-10 rounded" />
                        )}
                        {row.brandName}
                        {row.status === 'published' || row.status === 'unpublished' ? (
                            <Chip
                                color={row.status === 'published' ? 'green' : 'neutral'}
                                label={row.status}
                            />
                        ) : (
                            <Chip color="yellow" label="Draft" />
                        )}
                    </div>
                </Table.Cell>
            ),
        },
        { id: 'description', label: 'Course Name' },
        {
            id: 'reward',
            label: 'Reward',
            render: (row) => (
                <Table.Cell data-testid="reward-amount">
                    {formatCurrency(row.reward / 100)}
                </Table.Cell>
            ),
        },
        {
            id: 'updatedAt',
            label: 'Last Edited',
            render: (row) => (
                <Table.Cell title={moment(row.updatedAt).format('MM/DD/YYYY hh:mm a')}>
                    {moment(row.updatedAt).format('MMM D, YYYY')}
                </Table.Cell>
            ),
        },
        {
            id: 'employeeDepositsCount',
            label: 'Completed',
        },
        {
            id: 'totalPayout',
            label: 'Total Payout',
            info: 'Based on course completions, this is the current total payout including platform fees.',
            render: (row) => (
                <Table.Cell className="text-right" data-testid="total-payout">
                    {formatCurrency(row.totalPayout / 100)}
                </Table.Cell>
            ),
        },
        {
            id: 'actions',
            label: '',
            render: (row) => (
                <Table.Cell onClick={(e) => e.stopPropagation()}>
                    <Dropdown>
                        <Dropdown.IconButton data-testid="more-vert-button">
                            <MoreVert />
                        </Dropdown.IconButton>
                        <Dropdown.Menu>
                            <Dropdown.MenuItem
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    deleteLearningResource(row.learningResourceId);
                                }}
                            >
                                Delete
                            </Dropdown.MenuItem>
                        </Dropdown.Menu>
                    </Dropdown>
                </Table.Cell>
            ),
        },
    ];

    const { courses, meta, isLoadingCourses } = useGetAllAccountCourses({
        accountId: account?._id,
        limit: String(queryParams.limit),
        offset: String(queryParams.p ?? 0),
        order: queryParams.order,
        status: queryParams.status,
        brandId: queryParams.brandId,
    });
    const brandOptions = useMemo(() => {
        return vendorBrands?.map((brand) => ({
            value: brand._id,
            label: brand.name,
        }));
    }, [vendorBrands]);
    const noFiltersSelected = !queryParams.brandId && !queryParams.status;

    // Modify the loading state logic
    const isInitialLoading = isLoadingCourses && !courses;
    const showContent = !isInitialLoading && courses;

    // Use startTransition when changing filters
    const handleBrandSelect = (option: string) => {
        startTransition(() => {
            history.push({
                search: queryToString({
                    ...queryParams,
                    brandId: option,
                }),
            });
        });
    };

    const handleStatusSelect = (option: string) => {
        startTransition(() => {
            history.push({
                search: queryToString({
                    ...queryParams,
                    status: option,
                }),
            });
        });
    };

    if (isInitialLoading) {
        return <PageLoading label="Loading Courses..." />;
    }

    if (showContent && courses.length === 0 && noFiltersSelected) {
        return <CoursesEmptyState accountType={account?.type || 'retailer'} onClick={() => {}} />;
    }

    return (
        <div>
            <Toolbar justifyContentStart>
                {account?.type === 'brand' && brandOptions && (
                    <Toolbar.Dropdown
                        label={null}
                        value={queryParams.brandId}
                        titlePlaceholder="Brand"
                        options={brandOptions || []}
                        onSelect={handleBrandSelect}
                        clear={{
                            active: !!queryParams.brandId,
                            onClear: () => {
                                const { brandId, ...restQueryParams } = queryParams;
                                history.push({
                                    search: queryToString(restQueryParams),
                                });
                            },
                        }}
                    />
                )}
                <Toolbar.Dropdown
                    label={null}
                    value={queryParams.status}
                    titlePlaceholder="Course Status"
                    options={[
                        { label: 'Published', value: 'published' },
                        { label: 'Unpublished', value: 'unpublished' },
                        { label: 'Draft', value: 'created' },
                    ]}
                    onSelect={handleStatusSelect}
                    clear={{
                        active: !!queryParams.status,
                        onClear: () => {
                            const { status, ...restQueryParams } = queryParams;
                            history.push({
                                search: queryToString(restQueryParams),
                            });
                        },
                    }}
                />
            </Toolbar>

            <Table
                enableQueryParams
                rowCountOverride={meta?.total}
                disableFrontendFiltering
                headCells={columns}
                isLoading={isLoadingCourses}
                rows={courses}
                defaultOptions={{
                    rowsPerPage: Number(queryParams.limit),
                    order: queryParams.order === 'asc' ? 'asc' : 'desc',
                    orderBy: queryParams.sort ?? 'createdAt',
                }}
                variant="raised"
                showPagination
                className="w-full"
                key={`${queryParams.brandId}-${queryParams.status}`}
            >
                <Table.RenderHead />
                <Table.RenderBody
                    onRowClick={(row) => {
                        history.push(`/courses/${row.learningResourceId}`);
                    }}
                    emptyStateText="No courses found with these filters."
                />
            </Table>
        </div>
    );
};

export default CoursesList;
