import { FC } from 'react';

import { TagColorOption } from '@sparkplug/lib';

import GroupTagPairMenuItemContent from '@features/product-tags/components/GroupTagPairMenuItemContent';

import { Info as InfoIcon } from '@components/icons';
import Tooltip from '@components/layout/Tooltip';
import Toolbar from '@components/toolbar/Toolbar';
import { MultiSelectDropdownOptions } from '@components/toolbar/ToolbarMultiSelectDropdown/ToolbarMultiSelectDropdown';

import { UseProductTagsTableFilters } from '../useProductTagsTableFilters';

import './ProductTableToolbar.scss';

export interface MultiSelectProductTagOptions extends MultiSelectDropdownOptions {
    category: string;
    color: TagColorOption;
}

export interface ProductTableToolbarProps {
    brandOptions: MultiSelectDropdownOptions[];
    categoryOptions: MultiSelectDropdownOptions[];
    tableFilters: UseProductTagsTableFilters;
    vendorAccountId?: string;
    retailerAccountId?: string;
    isRefetchingVendorRetailerPosData?: boolean;
}

interface TagsMenuItemWrapperProps {
    option: MultiSelectProductTagOptions;
}

export const TagsMenuItemWrapper: FC<TagsMenuItemWrapperProps> = ({
    option: { label, category, color },
}) => (
    <GroupTagPairMenuItemContent
        productTag={{
            name: label,
            groupName: category,
            color,
        }}
    />
);

const ProductTableToolbar: FC<ProductTableToolbarProps> = ({
    brandOptions,
    categoryOptions,
    tableFilters,
    isRefetchingVendorRetailerPosData = false,
}) => {
    const { filters, setFilters, onChangeSearchFilter } = tableFilters;
    const oldProductsTooltip = `This will hide all products sold more than 60 days ago. These “Old” products don’t require tags`;

    return (
        <Toolbar justifyContentStart scrollOnMobile>
            <Toolbar.Search
                className="table-toolbar-group-start"
                name="tags-search"
                onChange={onChangeSearchFilter}
            />
            {brandOptions.length > 0 && (
                <Toolbar.MultiSelectDropdown
                    className="table-toolbar-brand-dropdown"
                    label="Brands"
                    options={brandOptions}
                    selected={filters.brandIds}
                    onApply={(value) =>
                        setFilters((prevValue) => ({
                            ...prevValue,
                            brandIds: value,
                        }))
                    }
                />
            )}
            {categoryOptions.length > 0 && (
                <Toolbar.MultiSelectDropdown
                    className="table-toolbar-category-dropdown"
                    label="Categories"
                    options={categoryOptions}
                    selected={filters.categoryIds}
                    onApply={(value) =>
                        setFilters((prevValue) => ({
                            ...prevValue,
                            categoryIds: value,
                        }))
                    }
                />
            )}
            <Toolbar.Checkbox
                className="hide-old-products"
                label={
                    <span className="hide-old-products-label">
                        Hide&nbsp;<b>&quot;Old&quot;</b>&nbsp;products
                        <Tooltip title={oldProductsTooltip} placement="top">
                            <InfoIcon className="tooltip-icon" />
                        </Tooltip>{' '}
                    </span>
                }
                value={filters.hideOldProducts}
                onChange={() =>
                    setFilters((prevValue) => ({
                        ...prevValue,
                        hideOldProducts: !filters.hideOldProducts,
                    }))
                }
            />
        </Toolbar>
    );
};

export default ProductTableToolbar;
