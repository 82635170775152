import moment from 'moment';

export default function formatDuration(ms: number) {
    const duration = moment.duration(ms);
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const milliseconds = duration.milliseconds();

    const daysDisplay = days > 0 ? `${days}d ` : '';
    const hoursDisplay = hours > 0 ? `${hours}h ` : '';
    const minutesDisplay = minutes > 0 ? `${minutes}m ` : '';
    const secondsDisplay = seconds > 0 ? `${seconds}s ` : '';
    const millisecondsDisplay = milliseconds > 0 ? `${milliseconds}ms` : '';

    return `${daysDisplay}${hoursDisplay}${minutesDisplay}${secondsDisplay}${millisecondsDisplay}`.trim();
}
