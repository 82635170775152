import { useEffect, useState } from 'react';

import { ExpiredLinkGraphic } from '@components/graphics';
import PageLoading from '@components/layout/PageLoading';
import Paper from '@components/layout/Paper';
import CodeVerifiedOverlay from '@components/overlays/CodeVerifiedOverlay';
import { useQueryParams } from '@components/router';

import { useCodeAuth } from '@hooks/AppHooks';
import { useCountdown } from '@hooks/UIHooks';

import { sendSmsAuth } from '@helpers/auth';

import SMSConfirmationModal from '../SMSConfirmationModal';

import './AuthCodeView.scss';

type TPageParams = {
    code?: string;
    phone?: string;
    action?: string;
    // An additional string to use as a query param for the forwarding action.
    actionParams?: string;
};

const ExpiredLinkCard = ({ phoneNumber }: { phoneNumber: string }) => {
    const [showSMSConfirmationModal, setShowSMSConfirmationModal] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const { remainingTime, startCountdown, countdownIsRunning } = useCountdown();

    const canSendCode = !countdownIsRunning;

    useEffect(() => {
        if (canSendCode) {
            setShowWarning(false);
        }
    }, [canSendCode]);

    const sendSMSCode = () => {
        if (!canSendCode) {
            setShowWarning(true);
            return;
        }

        startCountdown(30); // 30 seconds
        setErrorMessage('');
        sendSmsAuth(phoneNumber)
            .then((didSend) => {
                if (didSend) {
                    setShowSMSConfirmationModal(true);
                }
            })
            .catch((err) => {
                if (err?.message != null) {
                    setErrorMessage(err?.message);
                } else {
                    setErrorMessage('An unknown error occured');
                }
            });
    };

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */
    return (
        <>
            <Paper className="expired-link-card">
                <h2>This link has expired!</h2>

                {errorMessage && <p className="error-text">{errorMessage}</p>}

                {showWarning ? (
                    <p>{` You may request another code in ${remainingTime} seconds.`}</p>
                ) : (
                    <p className="clickable" onClick={sendSMSCode}>
                        Text me a code to login.
                    </p>
                )}

                <ExpiredLinkGraphic />
            </Paper>

            {showSMSConfirmationModal && (
                // fully dismount the modal on close to reset states
                <SMSConfirmationModal
                    isVisible
                    phoneNumber={phoneNumber}
                    onClose={() => setShowSMSConfirmationModal(false)}
                />
            )}
        </>
    );
    /* estlint-enable */
};

const AuthCodeView = () => {
    const { code, phone, action, actionParams }: TPageParams = useQueryParams();
    const phoneNumber = (phone || '').replace(' ', '+');

    const { didInitialCodeSubmit, codeIsValid } = useCodeAuth(
        {
            code,
            phoneNumber,
        },
        6,
        false,
    );

    let redirectTo = '/user/sparks';

    if (action === 'claim') {
        redirectTo = '/user/wallet';
    }

    if (action === 'withdrawal') {
        redirectTo = `/user/wallet?withdrawalId=${actionParams}`;
    }

    if (action === 'help') {
        redirectTo = '/user/sparks?openIntercom=true';
    }

    return (
        <div className="auth-code-view">
            {didInitialCodeSubmit && !codeIsValid ? (
                <ExpiredLinkCard phoneNumber={phoneNumber} />
            ) : (
                <PageLoading label="Authorizing..." />
            )}
            <CodeVerifiedOverlay isVisible={codeIsValid} redirectTo={redirectTo} />
        </div>
    );
};

export default AuthCodeView;
