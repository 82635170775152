import axios from 'axios';

import { QuickAcceptRequestParams, QuickAcceptSparkDetails } from '@sparkplug/lib';

import { useAdvancedQuery } from '@hooks/QueryHooks';

export const API = {
    quickApproveSpark: async ({ sparkId, quickApprovalCode }: QuickAcceptRequestParams) => {
        return (
            await axios.get<QuickAcceptSparkDetails>(
                `/api/v1/sparkplug/sparks/${sparkId}/approve/${quickApprovalCode}`,
            )
        ).data;
    },
};

export const useQuickApproveSpark = ({
    sparkId = '',
    quickApprovalCode = '',
}: QuickAcceptRequestParams) => {
    const { data, isLoading, error } = useAdvancedQuery(['quick-approve-spark', sparkId], () =>
        API.quickApproveSpark({ sparkId, quickApprovalCode }),
    );

    return {
        isQuickApprovingSpark: isLoading,
        quickApproveResponseData: data,
        quickApproveError: error,
    };
};
