import Chip from '@components/chips/Chip';
import {
    ArrowDownward as ArrowDownIcon,
    ArrowUpward as ArrowUpIcon,
    Info as InfoIcon,
} from '@components/icons';
import Tooltip from '@components/layout/Tooltip';

import { calculatePercentIncrease } from '@helpers/charts';

import './PercentDifferenceChip.scss';

export interface IPercentDifferenceChipProps {
    prevValue: number;
    currentValue: number;
    tooltipMessage?: string;
}

const PercentDifferenceChip = ({
    prevValue,
    currentValue,
    tooltipMessage,
}: IPercentDifferenceChipProps) => {
    const [percentDiff, percentDiffFormatted] = calculatePercentIncrease(currentValue, prevValue);
    const chipColor = percentDiff > 0 ? 'green' : 'red';
    const chipIcon = percentDiff > 0 ? <ArrowUpIcon /> : <ArrowDownIcon />;

    if (!Number.isFinite(percentDiff)) {
        return null;
    }

    return (
        <div className="percent-difference-chip">
            <Chip
                color={percentDiff !== 0 ? chipColor : 'neutral'}
                icon={percentDiff !== 0 ? chipIcon : undefined}
                label={percentDiffFormatted.toString()}
            />
            {tooltipMessage && (
                <Tooltip title={tooltipMessage}>
                    <InfoIcon className="tooltip-icon" />
                </Tooltip>
            )}
        </div>
    );
};

export default PercentDifferenceChip;
