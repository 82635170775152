import { useMemo } from 'react';

import { Tooltip } from '@mui/material';

import { AccountSparkBrand, ISnap } from '@sparkplug/lib';

import IconButton from '@components/buttons/IconButton';
import Chip from '@components/chips/Chip';
import Form from '@components/form/Form';
import { Cancel as CancelIcon, Search as SearchIcon } from '@components/icons';
import Paper from '@components/layout/Paper';

import './FeaturedSnapsBrandSelector.scss';

export interface FeaturedBrandOption {
    label: string;
    value: string;
    brandImageUrl: string;
    isLive: boolean;
}
const FeaturedBrandSelectorOption = ({
    label,
    value,
    brandImageUrl,
    isLive,
}: FeaturedBrandOption) => (
    <Tooltip
        title={
            isLive
                ? 'You cannot create a new featured Snap for this brand because one is currently live.'
                : undefined
        }
        placement="top"
        key={value}
    >
        <div className="flex items-center gap-2">
            <img
                src={brandImageUrl}
                alt="brand logo"
                className="w-6 h-6 object-cover rounded-full"
            />
            <span>{label}</span>
            {isLive && <Chip label="Live" color="green" />}
        </div>
    </Tooltip>
);

interface SelectFeaturedBrandLabelProps {
    selectedBrand: FeaturedBrandOption;
    onRemove: () => void;
}
const SelectedFeaturedBrandLabel = ({ selectedBrand, onRemove }: SelectFeaturedBrandLabelProps) => (
    <Paper className="flex items-center justify-between">
        <section className="flex gap-4 items-center">
            <img
                src={selectedBrand.brandImageUrl}
                alt="brand logo"
                className="w-16 h-16 object-cover rounded-full bg-opacity-100"
            />
            <span className="font-inter text-base font-semibold leading-5 tracking-wider text-left text-gray-1000 !text-[14px]">
                {selectedBrand.label}
            </span>
        </section>
        <section className="relative">
            <IconButton className="p-2" size="small" onClick={onRemove}>
                <CancelIcon className="text-gray-800" />
            </IconButton>
        </section>
    </Paper>
);

export interface FeaturedSnapsBrandSelectorProps {
    liveFeaturedSnapsAreReady: boolean;
    liveFeaturedSnaps: ISnap[];
    accountBrands: AccountSparkBrand[];
    selectedBrandId?: string;
    onSelectBrand: (accountBrand: AccountSparkBrand) => void;
    onRemoveBrand: () => void;
}
export const FeaturedSnapsBrandSelector = ({
    liveFeaturedSnapsAreReady,
    liveFeaturedSnaps,
    accountBrands,
    selectedBrandId,
    onSelectBrand,
    onRemoveBrand,
}: FeaturedSnapsBrandSelectorProps) => {
    const featuredBrandOptions = useMemo(() => {
        if (!liveFeaturedSnapsAreReady) {
            return [];
        }

        const liveBrandIds = new Set(liveFeaturedSnaps.map((snap) => snap.brandId));
        return accountBrands.map((brand) => {
            const isLive = liveBrandIds.has(brand._id);
            return {
                ...brand,
                label: brand.name,
                value: brand._id,
                brandImageUrl: brand.photo,
                isLive,
            };
        });
    }, [liveFeaturedSnapsAreReady, liveFeaturedSnaps, accountBrands]);

    const selectedFeaturedBrand = useMemo(() => {
        return featuredBrandOptions.find(({ value }) => value === selectedBrandId);
    }, [featuredBrandOptions, selectedBrandId]);

    return selectedFeaturedBrand ? (
        <>
            <Form.HiddenField name="brandId" value={selectedFeaturedBrand.value} />
            <SelectedFeaturedBrandLabel
                selectedBrand={selectedFeaturedBrand}
                onRemove={onRemoveBrand}
            />
        </>
    ) : (
        <Form.SearchSelect
            required
            label="Brand"
            name="featured-snaps-brand-selector"
            className="featured-snaps-brand-selector"
            data-testid="featured-snaps-brand-selector"
            startIcon={<SearchIcon />}
            options={featuredBrandOptions}
            isOptionDisabled={({ isLive }) => isLive}
            placeholder="Select a Brand to feature"
            optionLabelComponent={FeaturedBrandSelectorOption}
            value=""
            onChange={onSelectBrand}
        />
    );
};
