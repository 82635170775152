import { useParams } from 'react-router';

import { AccountMarket, AccountMarketLabel } from '@sparkplug/lib';

import { getStateNameByAbbreviation } from '@helpers/util';

export const useCurrentVendorDashboardMarket = () => {
    const { market: marketAbbreviation = '' } = useParams<{ market: string }>();

    const currentMarketAbbreviation = marketAbbreviation
        ? (marketAbbreviation.toUpperCase() as AccountMarket)
        : undefined;
    const currentMarketLabel = marketAbbreviation
        ? (getStateNameByAbbreviation(currentMarketAbbreviation as string) as AccountMarketLabel)
        : undefined;

    return {
        currentMarketAbbreviation,
        currentMarketLabel,
    };
};
