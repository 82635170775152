import { FC, useMemo } from 'react';

import { compact } from 'lodash';

import { useSendBulkEnrollSMSByUserIdsMutation } from '@core/users';

import ConfirmModal from '@components/overlays/ConfirmModal';
import { IDefaultModalProps } from '@components/overlays/Modal';

import { useTableContext } from '@hooks/TableHooks';

import { isEmpty } from '@helpers/util';

import { IAccountUser } from '@app/types/UsersTypes';

interface BulkSendEnrollmentConfirmModalProps extends IDefaultModalProps {
    accountId?: string;
}

const BulkSendEnrollmentConfirmModal: FC<BulkSendEnrollmentConfirmModalProps> = ({
    accountId,
    isVisible,
    onClose,
}) => {
    if (!accountId) {
        return <></>;
    }
    const { sendBulkEnrollmentSMSByUserIds } = useSendBulkEnrollSMSByUserIdsMutation();

    const { tableRows, tableSelected, tableSetSelected } = useTableContext<
        IAccountUser & { key: string }
    >();

    const selectedUserRows = useMemo(() => {
        return tableRows?.filter((accountUser) => tableSelected.includes(accountUser.key)) || [];
    }, [tableRows, tableSelected]);

    const bulkResendEnrollment = async () => {
        if (isEmpty(selectedUserRows)) {
            onClose(false);
            return;
        }
        await sendBulkEnrollmentSMSByUserIds(
            {
                userIds: compact(
                    selectedUserRows.map(({ userId }) => {
                        return userId;
                    }),
                ),
                groupId: accountId,
            },
            {
                onSuccess: () => {
                    tableSetSelected([]);
                    onClose(true);
                },
                onError: () => {
                    onClose(false);
                },
            },
        );
    };

    return (
        <>
            <ConfirmModal
                title="Send enrollment reminder?"
                message={`Send an enrollment reminder text to the ${
                    selectedUserRows.length
                } selected user${selectedUserRows.length > 1 ? 's' : ''}?`}
                isVisible={isVisible}
                onConfirm={bulkResendEnrollment}
                onClose={() => onClose(false)}
            />
        </>
    );
};

export default BulkSendEnrollmentConfirmModal;
