import { FC, useEffect, useState } from 'react';

import { OAUTH_ERRORS } from '@sparkplug/lib';

import { AccountIdKey } from '@contexts/AccountContext';

import CalloutMessage from '@components/layout/CalloutMessage';
import Paper from '@components/layout/Paper';
import Spinner from '@components/layout/Spinner';
import { Redirect, useQueryParams } from '@components/router';
import toast from '@components/toast';

import { useApp } from '@hooks/AppHooks';

import { JwtTokenKey, UserIdKey } from '@helpers/auth';

export const OauthResponseView: FC = () => {
    const {
        token = '',
        isNewUser,
        isNewStore,
        type,
        error,
    }: {
        token?: string;
        isNewUser?: string;
        isNewStore?: string;
        type?: 'clover' | 'shopify';
        error?: string;
    } = useQueryParams();
    const [displayElement, setDisplayElement] = useState<any>(<></>);
    const { logIn, appIsReady } = useApp();
    const defaultDisplayElement = <Redirect to="/login" />;

    useEffect(() => {
        if (error) {
            const { title, message } = OAUTH_ERRORS[error as keyof typeof OAUTH_ERRORS] ?? {
                title: 'Unknown Error',
                message: `An unknown error occurred while linking the SparkPlug app to your ${type} account.`,
            };

            setDisplayElement(
                <Paper>
                    <Paper.Title>{title}</Paper.Title>
                    <CalloutMessage customIcon="alert" message={message} color="red" />
                </Paper>,
            );
            return;
        }

        if (!appIsReady) {
            setDisplayElement(<Spinner />);
            return;
        }

        if (token) {
            // On the off chance we have had a different user logged in with the same browser, we need to clear the ids we store in local storage so that the app will load the correct user (otherwise we get a 403 on the first get request)
            localStorage.removeItem(UserIdKey);
            localStorage.removeItem(AccountIdKey);

            // We need to set the JWT in local storage so that the app can pick it up and use it on future requests - this is normally handled directly in the Axios login api request, but we're bypassing that for oauth
            const existingJwt = decodeURIComponent(token);
            localStorage.setItem(JwtTokenKey, existingJwt);

            logIn({ existingJwt: token })
                .then(() => {
                    if (isNewUser === 'false' && isNewStore === 'false') {
                        // This is a return user logging in via oauth - but we don't have the accountId loaded yet - redirecting to /login with the jwt set will take care of that for us
                        setDisplayElement(defaultDisplayElement);
                    } else {
                        const redirectPath = `/login?redirectTo=oauth-success${
                            isNewUser === 'true' ? '/isNewUser' : ''
                        }${isNewStore === 'true' ? '/isNewStore' : ''}${
                            type ? `?type=${type}` : ''
                        }`;
                        setDisplayElement(<Redirect to={redirectPath} />);
                    }
                })
                .catch(() => {
                    // If there's an error with login, we should also clear the JWT from local storage
                    localStorage.removeItem(JwtTokenKey);
                    setDisplayElement(defaultDisplayElement);
                    toast.error(
                        `There was an error logging you in via Oauth - please use your SparkPlug username and password`,
                        { id: 'oauth-error' },
                    );
                });
        } else {
            setDisplayElement(defaultDisplayElement);
        }
    }, [appIsReady]);

    return displayElement;
};

export default OauthResponseView;
