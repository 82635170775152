import { useMemo } from 'react';

import { internalTrackingFieldValueNames } from '@constants/SparkConstants';
import moment from 'moment';

import { SparkDisplayStatus } from '@sparkplug/lib';

import { SparkAccountDisplay } from '@features/spark-dashboard/components/SparkAccountDisplay';
import { SparkOwnerDisplay } from '@features/spark-dashboard/components/SparkOwnerDisplay';
import { SparkDashboardTableRowData } from '@features/spark-dashboard/types';

import Tooltip from '@components/layout/Tooltip';
import { MissingCommissionCallout } from '@components/sparks/MissingCommissionCallout';
import SparkStatusChip from '@components/sparks/SparkStatusChip';
import Table from '@components/table/Table';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { IAccount } from '@app/types/AccountsTypes';
import { THeadCell } from '@app/types/TableTypes';

const payoutCallout =
    "This column shows the projected payout based on Spark's current performance, updating every 15 minutes";
const unitsSoldCallout =
    'This column displays the current number of units sold for each product, updating every 15 minutes';
const participantsCallout =
    'This column shows the current number of Spark participants, updating every 15 minutes';

const getSharedCells = (account: IAccount, sparkStatusFilter?: SparkDisplayStatus) => {
    const isActive = sparkStatusFilter === 'active';
    const isUpcoming = sparkStatusFilter === 'upcoming';
    const isComplete = sparkStatusFilter === 'complete';
    const isInboxOrOutbox = sparkStatusFilter === 'inbox/sent';
    const trainingEnabled = account?.zoltrainEnabled || account?.seedTalentEnabled;

    const sharedCells: THeadCell<SparkDashboardTableRowData>[] = [
        {
            id: 'spark.name',
            sortType: 'string',
            label: 'Spark Name',
            render: (row) => {
                return <Table.Cell>{row.spark.name}</Table.Cell>;
            },
        },
        {
            id: 'spark.description',
            label: 'Description',
            render: (row) => {
                return (
                    <Table.Cell className="spark-description">
                        <Tooltip title={row.spark.description} placement="top-start">
                            <span>{row.spark.description}</span>
                        </Tooltip>
                    </Table.Cell>
                );
            },
        },
        {
            id: 'sparkSchedule',
            sortType: 'string',
            label: 'Schedule',
            render: (row) => {
                return <Table.Cell>{row.sparkSchedule}</Table.Cell>;
            },
        },
        {
            id: 'spark.sparkCreator.fullName',
            sortType: 'string',
            label: 'Owner',
            render: (row) => {
                const firstName = row.spark.sparkCreator?.firstName ?? '';
                const lastName = row.spark.sparkCreator?.lastName ?? '';
                return (
                    <Table.Cell>
                        <SparkOwnerDisplay firstName={firstName} lastName={lastName} />
                    </Table.Cell>
                );
            },
        },
        {
            id: 'participantCount',
            info: isActive ? participantsCallout : undefined,
            sortType: 'numeric',
            isHidden: isUpcoming || isInboxOrOutbox,
            label: 'Participants',
            render: (row) => {
                const participantsFormatted = row.participants.toLocaleString();
                return <Table.Cell>{participantsFormatted}</Table.Cell>;
            },
        },
        {
            id: 'startDate',
            sortType: 'string',
            label: 'Start Date',
            render: (row) => {
                return <Table.Cell>{moment(row.spark.startDate).format('MMM D, YYYY')}</Table.Cell>;
            },
        },
        {
            id: 'endDate',
            sortType: 'string',
            label: 'End Date',
            render: (row) => {
                return <Table.Cell>{moment(row.spark.endDate).format('MMM D, YYYY')}</Table.Cell>;
            },
        },
        {
            id: 'type',
            sortType: 'string',
            label: 'Type',
            render: (row) => {
                return <Table.Cell>{row.type}</Table.Cell>;
            },
        },
        {
            id: 'payoutAmount',
            info: isActive ? payoutCallout : undefined,
            sortType: 'numeric',
            isHidden: isUpcoming || isInboxOrOutbox,
            label: isComplete ? 'Payout' : 'Current Payout',
            render: (row) => {
                if (row.spend === -1) {
                    return <Table.Cell>Non-Cash Reward</Table.Cell>;
                }

                const payoutFormatted = row.spend.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                });
                return <Table.Cell>{payoutFormatted}</Table.Cell>;
            },
        },
        {
            id: 'spark.internalTracking.payoutStatus',
            isHidden: !isComplete && account.type === 'brand',
            sortType: 'string',
            label: 'Payout Status',
            render: (row) => {
                const payoutStatus = row.spark.internalTracking?.payoutStatus;
                return (
                    <Table.Cell>
                        {internalTrackingFieldValueNames[payoutStatus ?? 'none']}
                    </Table.Cell>
                );
            },
        },
        {
            id: 'unitsSold',
            info: isActive ? unitsSoldCallout : undefined,
            sortType: 'numeric',
            label: 'Units Sold',
            isHidden: isUpcoming || isInboxOrOutbox,
            render: (row) => {
                const unitsSoldFormatted = row.sales.toLocaleString();
                return <Table.Cell>{unitsSoldFormatted} units</Table.Cell>;
            },
        },
        {
            id: 'sparkTraining',
            sortType: 'string',
            label: 'Training',
            isHidden: !trainingEnabled,
            render: (row) => {
                return (
                    <Table.Cell className="training-cell">
                        {row.spark.trainingEnabled ? (
                            <>{row.sparkTraining}</>
                        ) : (
                            <span>No Training</span>
                        )}
                    </Table.Cell>
                );
            },
        },
    ];

    return sharedCells;
};

export const getRetailerCells = (
    connectEnabled: boolean,
): THeadCell<SparkDashboardTableRowData>[] => {
    // Only show the "Vendor" column if connect is enabled
    if (connectEnabled) {
        return [
            {
                id: 'sparkVendor.name',
                sortType: 'string',
                label: 'Vendor',
                render: (row) => {
                    return <Table.Cell>{row.sparkVendor?.name ?? '--'}</Table.Cell>;
                },
            },
        ];
    }

    return [];
};

const vendorCells: THeadCell<SparkDashboardTableRowData>[] = [
    {
        id: 'spark.sparkBrand.name',
        sortType: 'string',
        label: 'Brand',
        render: (row) => {
            return <Table.Cell>{row.spark?.sparkBrand?.name ?? '--'}</Table.Cell>;
        },
    },
    {
        id: 'market',
        sortType: 'string',
        label: 'Market',
        render: (row) => {
            return <Table.Cell>{row.market ?? '--'}</Table.Cell>;
        },
    },
];

export const useSparkDashboardHeadCells = (sparkStatusFilter?: SparkDisplayStatus) => {
    const { account, connectEnabled } = useSparkplugAccount();

    return useMemo(() => {
        if (!account) {
            return [];
        }
        const sharedCells = getSharedCells(account, sparkStatusFilter);

        if (account.type === 'brand') {
            return vendorCells.concat(sharedCells);
        }

        const retailerCells = getRetailerCells(connectEnabled);
        return retailerCells.concat(sharedCells);
    }, [account, connectEnabled, sparkStatusFilter]);
};

export const getSparkCreatorCell = ({
    account,
}: {
    account: IAccount;
}): THeadCell<SparkDashboardTableRowData> => ({
    id: 'pinnedSparkData.name',
    sortType: 'string',
    sticky: 'left',
    columnManagerLabel: account?.type === 'brand' ? 'Retailer' : 'Spark Creator',
    label: () => {
        return (
            <div className="sticky-header">
                <div className="label">
                    <span>{account?.type === 'brand' ? 'Retailer' : 'Spark Creator'}</span>
                </div>
            </div>
        );
    },
    render: (row) => {
        const showStatus = ['pending', 'expired', 'rejected'].includes(
            row.sparkStatus.toLowerCase(),
        );
        return (
            <Table.Cell>
                <SparkAccountDisplay
                    spark={row.spark}
                    name={row.pinnedSparkData.name}
                    photo={row.pinnedSparkData.photo}
                    afterIcon={<MissingCommissionCallout spark={row.spark} variant="icon" />}
                />
                {showStatus && (
                    <SparkStatusChip
                        status={row.sparkStatus}
                        customLabel={row.sparkStatus === 'Rejected' ? 'Declined' : undefined}
                    />
                )}
            </Table.Cell>
        );
    },
});

export const useSparkDashboardStickyLeftCells = (): THeadCell<SparkDashboardTableRowData>[] => {
    const { account } = useSparkplugAccount();

    if (!account) {
        return [];
    }

    return useMemo(() => [getSparkCreatorCell({ account })], [account]);
};
