import { FC, ReactNode, useState } from 'react';

import moment from 'moment';

import { SparkCommissionType, formatCurrency, parseCurrencyToNumber } from '@sparkplug/lib';

import Chip from '@components/chips/Chip';
import Switch from '@components/inputs/Switch';
import TextField from '@components/inputs/TextField';
import Table from '@components/table/Table';

import { wrapSubstringWithComponent } from '@helpers/ui';

import { IAccount } from '@app/types/AccountsTypes';
import { THeadCell } from '@app/types/TableTypes';

import { ProductCommissionRow } from '../hooks/useCommissionTableProducts';

const SAMPLE_TEXT_TARGET = 'sample';

const SampleText: FC<{ children: ReactNode }> = ({ children }) => (
    <strong className="sample-text">{children}</strong>
);
export interface CommissionMap {
    [posProductId: string]: number;
}

const getHeadCells: (params: {
    accountType: IAccount['type'];
    showInvalidValues: boolean;
    commissionType: SparkCommissionType;
    isFulfilledBySparkPlug: boolean;
    updateIsFulfilledBySparkPlug: (isFBS: boolean) => void;
    updateCommissionValue: (productId: string, commissionValue: number) => void;
    commissionValuesMap: CommissionMap;
    originalCommissionValuesMap: CommissionMap;
    isVendorEditExperience: boolean;
    isCreatingMultiRetailerSpark: boolean;
    isVendorTagsRulesBasedSpark: boolean;
    isFixedCommission: boolean;
}) => THeadCell<ProductCommissionRow>[] = ({
    accountType,
    showInvalidValues,
    commissionType,
    isFulfilledBySparkPlug,
    updateIsFulfilledBySparkPlug,
    updateCommissionValue,
    commissionValuesMap,
    originalCommissionValuesMap,
    isVendorEditExperience,
    isCreatingMultiRetailerSpark,
    isVendorTagsRulesBasedSpark,
    isFixedCommission,
}) => {
    const headCells: THeadCell<ProductCommissionRow>[] = [
        {
            id: 'name',
            sortType: 'string',
            label: 'Products',
            render: (row) => {
                const hasSample = row.name.toLowerCase().includes(SAMPLE_TEXT_TARGET);

                return (
                    <Table.Cell>
                        <div>
                            {hasSample
                                ? wrapSubstringWithComponent(
                                      SAMPLE_TEXT_TARGET,
                                      row.name,
                                      SampleText,
                                  )
                                : row.name}
                        </div>
                    </Table.Cell>
                );
            },
        },
        /* Creating a MR Spark (where we add "Retailers" as a filter option) */
        {
            id: 'retailer',
            sortType: 'string',
            label: 'Retailer',
            isHidden: !isCreatingMultiRetailerSpark,
            render: (row) => {
                return <Table.Cell>{row.retailer}</Table.Cell>;
            },
        },
        {
            id: 'category',
            sortType: 'string',
            label: 'Category',
            isHidden: isCreatingMultiRetailerSpark || isVendorTagsRulesBasedSpark,
            render: (row) => {
                const hasSample = row.category?.toLowerCase().includes(SAMPLE_TEXT_TARGET);

                return (
                    <Table.Cell>
                        <div>
                            {hasSample
                                ? wrapSubstringWithComponent(
                                      SAMPLE_TEXT_TARGET,
                                      row.category!,
                                      SampleText,
                                  )
                                : row.category ?? '--'}
                        </div>
                    </Table.Cell>
                );
            },
        },
        {
            id: 'brand',
            sortType: 'string',
            label: 'Brand',
            render: (row) => (
                <Table.Cell>
                    <div>{row.brand ?? '--'}</div>
                </Table.Cell>
            ),
        },
        {
            id: 'price',
            label: 'Price',
            sortType: 'numeric',
            isHidden: accountType !== 'retailer',
            render: (row) => (
                <Table.Cell>
                    <div>{row.price ? formatCurrency(row.price, true) : '--'}</div>
                </Table.Cell>
            ),
        },
        {
            id: 'lastSoldAt',
            sortType: 'string',
            label: 'Last Sold Date',
            render: (row) => (
                <Table.Cell>
                    {row.lastSoldAt ? moment(row.lastSoldAt).format('MM/DD/YYYY') : '--'}
                </Table.Cell>
            ),
        },

        {
            id: 'tags',
            label: 'Tags',
            sortType: 'arrayLength',
            /* Editing a SR-Tags Spark (where we add "Tags" as a filter option) */
            isHidden: !isCreatingMultiRetailerSpark && !isVendorTagsRulesBasedSpark,
            render: (row) => {
                return (
                    <Table.Cell>
                        <div className="product-selector-tag-cell">
                            {row?.tags?.length ? (
                                row.tags.map((tag, i) => {
                                    return (
                                        <Chip
                                            key={i}
                                            color="light-gray"
                                            variant="flat"
                                            avatar={
                                                // viewbox that's 10x10 with a circle radius of 5
                                                <svg className="tag-icon" viewBox="0 0 10 10">
                                                    <circle cx="5" cy="5" r="5" fill={tag.color} />
                                                </svg>
                                            }
                                            label={tag.name}
                                        />
                                    );
                                })
                            ) : (
                                <span className="no-tags">No tags</span>
                            )}
                        </div>
                    </Table.Cell>
                );
            },
        },
        {
            id: 'commissionValue',
            label:
                accountType === 'retailer'
                    ? () => {
                          const [isActive, setIsActive] = useState(isFulfilledBySparkPlug);

                          const onChange = () => {
                              setIsActive(!isActive);
                              updateIsFulfilledBySparkPlug(!isActive);
                          };

                          return (
                              <div className="fbs-label">
                                  <span>Fulfilled by SparkPlug</span>
                                  <Switch
                                      name="fulfilled-by-sparkplug"
                                      value={isActive}
                                      onChange={onChange}
                                  />
                              </div>
                          );
                      }
                    : 'Commission',
            render: (row) => {
                const [commissionValue, setCommissionValue] = useState(
                    commissionValuesMap?.[row._id],
                );
                const fieldError = showInvalidValues
                    ? (commissionValue ?? 0) <= 0 ||
                      (commissionType === 'percentage' && (commissionValue ?? 0) > 100)
                    : false;

                const disabled =
                    originalCommissionValuesMap &&
                    isVendorEditExperience &&
                    originalCommissionValuesMap[row._id] !== undefined;
                const onChange = (updatedValue: string) => {
                    const parsedValue = updatedValue ? parseCurrencyToNumber(updatedValue) : 0;
                    setCommissionValue(parsedValue);
                };
                const onBlur = () => {
                    updateCommissionValue(row._id, parseCurrencyToNumber(commissionValue));
                };

                return (
                    <Table.Cell>
                        <TextField
                            disabled={(disabled && accountType === 'brand') || isFixedCommission}
                            className="commission-text"
                            placeholder={commissionType === 'percentage' ? '0' : '0.00'}
                            name={`${row._id}-value`}
                            type="number"
                            step={commissionType === 'percentage' ? 1 : 0.01}
                            isMoneyField={commissionType === 'flat'}
                            moneyFieldIsActive={commissionType === 'flat'}
                            endIcon={commissionType === 'percentage' && '%'}
                            defaultValue={commissionValue ? String(commissionValue) : ''}
                            onChange={(event) => onChange(event.target.value)}
                            onBlur={() => onBlur()}
                            error={fieldError}
                        />
                    </Table.Cell>
                );
            },
        },
    ];

    return headCells;
};
export default getHeadCells;
