import { z } from 'zod';
import { SparkInternalInvoiceStatus } from '..';

export const PaymentTypes = ['card', 'bank'] as const;
export type TPaymentType = typeof PaymentTypes[number];

export interface IPaymentMethod {
  type: TPaymentType;
  id: string;
  last4: string;
  bank?: string;
  brand?: string;
  expMonth?: number;
  expYear?: number;
}

export interface IPaymentMethodResponseBody {
  paymentMethods: IPaymentMethod[];
  defaultPaymentMethodId: string | null;
}

export const createChargeInvoiceSchema = z.object({
  amount: z.number(),
  sparkId: z.string(),
  memo: z.string(),

  paymentId: z.string().optional(),
  paymentType: z.enum(PaymentTypes).optional(),
});
createChargeInvoiceSchema.refine(
  (data) => (data.paymentId && !data.paymentType) || (!data.paymentId && data.paymentType),
  'The paymentId and paymentType must be provided together.',
);

export type CreateChargeInvoiceRequestBody = z.infer<typeof createChargeInvoiceSchema>;

export interface CreateChargeInvoiceResponseBody {
  invoiceStatus: SparkInternalInvoiceStatus;
}

export const ENABLED_STRIPE_EVENTS = [
  'invoice.paid',
  'invoice.sent',
  'invoice.payment_failed',
  'invoice.voided',
] as const;
export type EnabledStripeEvent = typeof ENABLED_STRIPE_EVENTS[number];
