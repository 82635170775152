import { useMemo } from 'react';

import { isEmpty, omitBy } from 'lodash';

import { BucketsKeyedByLabel } from '@sparkplug/lib';

import { useVendorChartContext } from '@core/charts/contexts/VendorChartContext';
import { useVendorChartBucketBreakdown } from '@core/charts/queries/ChartsQueries';

/**
 * This hook is designed to retrieve the buckets for the current and previous periods.
 * It then returns the `periodBuckets` object, which holds the buckets for each period
 * labeled accordingly for usage with the `<AdvancedComparisonChart />` component.
 * */
export const useVendorChartComparisonBuckets = () => {
    const { vendorAccountId, chartSettings, showComparisonWindows, comparisonRange } =
        useVendorChartContext();

    const {
        vendorChartBucketBreakdownIsReady: currentBucketsAreReady,
        vendorChartBucketBreakdown: currentPeriodBuckets,
    } = useVendorChartBucketBreakdown(
        {
            vendorAccountId,
            calcType: chartSettings.metric,
            breakdownType: chartSettings.breakdown,
        },
        chartSettings,
        true,
    );

    const previousPeriodChartSettings = {
        ...chartSettings,
        dateStart: comparisonRange?.previousDateStart ?? '',
        dateEnd: comparisonRange?.previousDateEnd ?? '',
    };

    const {
        vendorChartBucketBreakdownIsReady: previousBucketsAreReady,
        vendorChartBucketBreakdown: previousPeriodBuckets,
    } = useVendorChartBucketBreakdown(
        {
            vendorAccountId,
            calcType: chartSettings.metric,
            breakdownType: chartSettings.breakdown,
        },
        previousPeriodChartSettings,
        showComparisonWindows,
    );

    const periodBuckets = useMemo<BucketsKeyedByLabel>(
        () =>
            omitBy(
                {
                    currentPeriod: Object.values(currentPeriodBuckets?.buckets ?? {})?.[0] ?? {},
                    previousPeriod: showComparisonWindows
                        ? Object.values(previousPeriodBuckets?.buckets ?? {})?.[0] ?? {}
                        : {},
                },
                isEmpty,
            ),
        [currentPeriodBuckets, previousPeriodBuckets, showComparisonWindows],
    );

    const isLoadingChartData =
        !currentBucketsAreReady || (showComparisonWindows && !previousBucketsAreReady);

    return {
        isLoadingChartData,
        periodBuckets,
    };
};
