import SelfEnrollAPI from '@api/SelfEnrollAPI';

import {
    SelfEnrollPosEmployeeProfileRequestBody,
    SelfEnrollSearchRequestBody,
    SelfEnrollUnmatchedRequestBody,
} from '@sparkplug/lib';

import recaptchaVerify from './recaptchaVerify';

export const selfEnrollSearch = async ({
    groupId,
    firstName,
    lastName,
    phoneNumber,
}: SelfEnrollSearchRequestBody) => {
    const recaptcha = await recaptchaVerify('self_enroll/search');
    return SelfEnrollAPI.search({
        recaptcha,
        groupId,
        firstName,
        lastName,
        phoneNumber,
    });
};

export const selfEnrollPosEmployeeProfile = async ({
    groupId,
    posEmployeeProfileId,
    firstName,
    lastName,
    phoneNumber,
}: SelfEnrollPosEmployeeProfileRequestBody) => {
    const recaptcha = await recaptchaVerify('self_enroll/enroll');

    return SelfEnrollAPI.enrollPosEmployeeProfile({
        recaptcha,
        groupId,
        posEmployeeProfileId,
        firstName,
        lastName,
        phoneNumber,
    });
};

export const selfEnrollUnmatched = async ({
    groupId,
    firstName,
    lastName,
    phoneNumber,
}: SelfEnrollUnmatchedRequestBody) => {
    const recaptcha = await recaptchaVerify('self_enroll/unmatched');

    return SelfEnrollAPI.unmatched({
        recaptcha,
        groupId,
        firstName,
        lastName,
        phoneNumber,
    });
};
