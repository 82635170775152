import { FC } from 'react';

import { useUnenrollAndSetInactiveMultiMutation } from '@core/users';

import ConfirmModal from '@components/overlays/ConfirmModal';
import InfoModal from '@components/overlays/InfoModal';
import { IDefaultModalProps } from '@components/overlays/Modal';
import toast from '@components/toast';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useTableContext } from '@hooks/TableHooks';

import { isEmpty } from '@helpers/util';

import { IAccountUser } from '@app/types/UsersTypes';

import './BulkSetInactiveConfirmModal.scss';

interface BulkSetInactiveConfirmModalProps extends IDefaultModalProps {
    accountId?: string;
}

const BulkSetInactiveConfirmModal: FC<BulkSetInactiveConfirmModalProps> = ({
    accountId,
    isVisible,
    onClose,
}) => {
    if (!accountId) {
        return <></>;
    }
    const { account } = useSparkplugAccount();
    const { unenrollAndSetInactiveMulti: bulkSetInactive } =
        useUnenrollAndSetInactiveMultiMutation(accountId);

    const { tableRows, tableSelected, tableSetSelected } = useTableContext<
        IAccountUser & { key: string }
    >();

    const selectedUserRows =
        tableRows?.filter((accountUser) => {
            return tableSelected.includes(accountUser.key);
        }) || [];

    const selectedUserIds = selectedUserRows.map((row) => row.userId);
    const accountOwnerIncluded = selectedUserIds.includes(account?.assignedOwnerUserId);
    const bulkInactivateUsers = async () => {
        if (isEmpty(selectedUserRows)) {
            onClose(false);
            return;
        }
        if (accountOwnerIncluded) {
            onClose(false);
            toast.error('Cannot set Account Owner to inactive');
            return;
        }

        await bulkSetInactive(
            {
                users: selectedUserRows.map(
                    ({ userId, posEmployeeProfileIds, firstName, lastName }) => ({
                        userId,
                        posEmployeeProfileIds,
                        firstName,
                        lastName,
                    }),
                ),
            },
            {
                onSuccess: () => {
                    tableSetSelected([]);
                    onClose(true);
                },
                onError: () => {
                    onClose(false);
                },
            },
        );
    };

    return (
        <>
            {accountOwnerIncluded ? (
                <InfoModal isVisible={isVisible} onClose={() => onClose(false)} title="Error">
                    <>You cannot set the account owner to inactive.</>
                </InfoModal>
            ) : (
                <ConfirmModal
                    title={`Set ${selectedUserRows.length} users inactive?`}
                    message="Inactive users can be set to active again at any point."
                    isVisible={isVisible}
                    onConfirm={bulkInactivateUsers}
                    onClose={() => onClose(false)}
                />
            )}
        </>
    );
};

export default BulkSetInactiveConfirmModal;
