import { FC, useEffect, useMemo, useState } from 'react';

import { AccountSparkBrand } from '@sparkplug/lib';

import IntercomContactMessage from '@components/buttons/IntercomContactMessage';
import Skeleton from '@components/layout/Skeleton';

import { useSparkBrandsQuery } from '../../queries';
import { AddSparkBrandButton } from './AddSparkBrandButton';
import { BrandPreviewWidget } from './BrandPreviewWidget';
import { SparkBrandGalleryItem } from './SparkBrandGalleryItem';

import './SparkBrandGallery.scss';

interface SparkBrandsGalleryWidetProps {
    isLoading: boolean;
    brands: AccountSparkBrand[];
}
export const SparkBrandGalleryWidget: FC<SparkBrandsGalleryWidetProps> = ({
    isLoading,
    brands = [],
}) => {
    const [activeBrandId, setActiveBrandId] = useState<string>(brands[0]?._id);

    // on full refresh of the app, the brands array may not be immediately available for the first useState call
    // this useEffect call just ensures we properly set the initial brand
    useEffect(() => setActiveBrandId(brands[0]?._id), [brands]);

    const activeBrand = useMemo(
        () => brands.find(({ _id }) => _id === activeBrandId),
        [brands, activeBrandId],
    );

    if (isLoading) {
        return <Skeleton height={330} width="100%" />;
    }

    return (
        <div className="spark-brand-gallery-widget">
            <section className="spark-brand-logos-container">
                {!!brands?.length &&
                    brands.map((sparkBrand) => (
                        <SparkBrandGalleryItem
                            key={sparkBrand._id}
                            name={sparkBrand.name}
                            logo={sparkBrand.photo}
                            onHover={() => setActiveBrandId(sparkBrand._id)}
                            isActive={activeBrand?._id === sparkBrand._id}
                            isEnabled={sparkBrand.isEnabled}
                        />
                    ))}

                <AddSparkBrandButton />

                <IntercomContactMessage prompt="Want to update a logo or brand name?" />
            </section>

            {!!activeBrand && (
                <section className="spark-brand-preview-container">
                    <BrandPreviewWidget logo={activeBrand.photo} name={activeBrand.name} />
                </section>
            )}
        </div>
    );
};

/**
 * Disabled brands always appear at the end
 */
export const sortBrands = (brands: AccountSparkBrand[] = []): AccountSparkBrand[] => {
    const { activeBrands, disabledBrands } = brands.reduce(
        (acc, currBrand) => {
            if (currBrand.isEnabled) {
                acc.activeBrands = acc.activeBrands.concat(currBrand);
            } else {
                acc.disabledBrands = acc.disabledBrands.concat(currBrand);
            }
            return acc;
        },
        {
            // eslint-disable-next-line no-array-constructor
            activeBrands: Array<AccountSparkBrand>(),
            // eslint-disable-next-line no-array-constructor
            disabledBrands: Array<AccountSparkBrand>(),
        },
    );

    return [...activeBrands, ...disabledBrands];
};

interface SparkBrandGalleryProps {
    accountId: string;
}
const SparkBrandGallery: FC<SparkBrandGalleryProps> = ({ accountId }) => {
    const { sparkBrands, sparkBrandsAreReady } = useSparkBrandsQuery(accountId);
    const sortedBrands = useMemo(() => sortBrands(sparkBrands), [sparkBrands]);

    return (
        <div className="spark-brand-gallery">
            <header>
                Manage how your brand name and logo appear on Sparks sent to retailers and their
                employees.
            </header>
            <SparkBrandGalleryWidget isLoading={!sparkBrandsAreReady} brands={sortedBrands} />
        </div>
    );
};

export default SparkBrandGallery;
