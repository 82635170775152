import { FC } from 'react';

import Chip from '@components/chips/Chip';
import Tooltip from '@components/layout/Tooltip';

import './ManagerChip.scss';

interface ManagerChipProps {}

const ManagerChip: FC<ManagerChipProps> = () => (
    <Tooltip title="Manager">
        <span>
            <Chip dense className="name-chip manager-chip" label="M" color="yellow" />
        </span>
    </Tooltip>
);

export default ManagerChip;
