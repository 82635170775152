import { FC, PropsWithChildren, memo } from 'react';

import {
    Table as MaterialUITable,
    TableContainer as MaterialUITableContainer,
    TablePagination as MaterialUITablePagination,
} from '@mui/material';

import { ITableProvider, TableProvider } from '@contexts/TableContext';

import { useTableContext } from '@hooks/TableHooks';

import { appendClasses } from '@helpers/ui';

import { ITableRow } from '@app/types/TableTypes';

import './Table.scss';

const rowsPerPageOptions: {
    value: number;
    label: string;
}[] = [
    {
        value: 10,
        label: 'Show 10 rows',
    },
    {
        value: 15,
        label: 'Show 15 rows',
    },
    {
        value: 20,
        label: 'Show 20 rows',
    },
    {
        value: 25,
        label: 'Show 25 rows',
    },
    {
        value: 50,
        label: 'Show 50 rows',
    },
    {
        value: -1,
        label: 'Show All rows',
    },
];

interface TablePaginationProps {
    hidden: boolean;
}

const TablePagination: FC<TablePaginationProps> = ({ hidden }) => {
    const {
        tableFilteredRows,
        tableRowsPerPage,
        tablePage,
        tableChangePage,
        tableChangeRowsPerPage,
        rowCountOverride,
    } = useTableContext();

    return (
        <MaterialUITablePagination
            hidden={hidden}
            component="div"
            labelRowsPerPage={null}
            rowsPerPageOptions={rowsPerPageOptions}
            count={rowCountOverride || tableFilteredRows.length}
            rowsPerPage={tableRowsPerPage}
            page={tablePage}
            onPageChange={tableChangePage}
            onRowsPerPageChange={tableChangeRowsPerPage}
        />
    );
};

interface ITableFC {
    id?: string;
    className?: string;
    variant: 'raised' | 'flat' | 'smooth';
    useInfiniteScrollStyling?: boolean;
    sticky?: boolean;
}

const TableFC: FC<PropsWithChildren<ITableFC>> = memo(
    ({ id, className = '', variant, useInfiniteScrollStyling, children, sticky }) => {
        const classNameAppended = appendClasses([
            className,
            'sparkplug-table',
            `sparkplug-table-${variant}`,
            useInfiniteScrollStyling ? 'is-infinite-scroll' : '',
            sticky ? 'sticky-table' : '',
        ]);

        const { tableShowPagination } = useTableContext();

        return (
            <div id={id} className={classNameAppended}>
                <MaterialUITableContainer>
                    <MaterialUITable>{children}</MaterialUITable>
                </MaterialUITableContainer>
                <TablePagination hidden={!tableShowPagination} />
            </div>
        );
    },
);

interface ITable<T> extends ITableFC, ITableProvider<T> {
    useExternalProvider?: boolean;
    enableQueryParams?: boolean;
    disableFrontendFiltering?: boolean;
}

export interface ITableInternalProvider<T> extends ITable<T> {
    useExternalProvider?: false;
    rows: ITableRow<T>[];
    enableColumnResizing?: boolean;
}

export interface ITableExternalProvider<T> extends ITable<T> {
    useExternalProvider: true;
}

type TTable<T> = ITableInternalProvider<T> | ITableExternalProvider<T>;

const Table = <T extends {}>({
    useExternalProvider = false,
    rows = [],
    headCells = [],
    filters = [],
    defaultOptions,
    showPagination,
    showBulkEditor = false,
    showRowActions = false,
    showCheckboxes = false,
    enableColumnResizing = false,
    footerComponent,
    enableQueryParams = false,
    disableFrontendFiltering = false,
    rowCountOverride,
    ...tableProps
}: TTable<T>) => {
    if (useExternalProvider) {
        return <TableFC {...tableProps} />;
    }
    const { isLoading = false } = tableProps ?? {};

    return (
        <TableProvider
            isLoading={isLoading}
            rows={rows}
            headCells={headCells}
            defaultOptions={defaultOptions}
            filters={filters}
            showBulkEditor={showBulkEditor}
            showRowActions={showRowActions}
            showCheckboxes={showCheckboxes}
            showPagination={showPagination}
            enableColumnResizing={enableColumnResizing}
            footerComponent={footerComponent}
            enableQueryParams={enableQueryParams}
            disableFrontendFiltering={disableFrontendFiltering}
            rowCountOverride={rowCountOverride}
        >
            <TableFC {...tableProps} />
        </TableProvider>
    );
};

export default Table;
