import { FC, ReactElement, useMemo } from 'react';

import { DATE_DISPLAY_FORMAT } from '@constants/AppConstants';
import { ChartMetricOptions, ComparisonPeriodOptions } from '@constants/ChartConstants';
import { capitalize } from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';

import { TChartDataMetric, TComparisonPeriodOption, isAverageMetric } from '@sparkplug/lib';

import { useChartContext } from '@core/charts/components/AdvancedChart/ChartContext';
import { DeprecatedAdvancedComparisonChart } from '@core/charts/components/AdvancedComparisonChart';
import { useComparisonChartContext } from '@core/charts/components/AdvancedComparisonChart/ComparisonChartContext';

import Chart from '@components/charts/Chart';
import { ComparisonColors } from '@components/charts/nivo/Nivo.Constants';
import PercentDifferenceChip from '@components/chips/PercentDifferenceChip';
import CalloutMessage from '@components/layout/CalloutMessage';
import FormattedMetricValue from '@components/layout/FormattedMetricValue';
import Skeleton from '@components/layout/Skeleton';
import Toolbar from '@components/toolbar/Toolbar';

import { getChartMetricLabel } from '@helpers/charts';

import { IChartDataSettings, TChartDataPrecision, TChartType } from '@app/types/ChartDataTypes';
import { IPosLocation } from '@app/types/PosTypes';

import './ComparisonChart.scss';

interface ComparisonWindowToolbarDropdownToggleProps {
    isActive: boolean;
    disabled?: boolean;
    value: TComparisonPeriodOption;
    precision?: TChartDataPrecision | 'daily' | 'weekly' | 'monthly' | 'yearly';
    onToggle: (isActive: boolean) => void;
    onChange: (value: TComparisonPeriodOption) => void;
}

const ComparisonWindowToolbarDropdownToggle: FC<ComparisonWindowToolbarDropdownToggleProps> = ({
    isActive,
    disabled = false,
    value,
    precision,
    onToggle,
    onChange,
}) => {
    return useMemo(() => {
        // If the precision is weekly, we should not allow the user to select previousPeriod or previousYear - the dates frequently will not match up (meaning no data will be shown for the comparison period) unless we use the "match day of week" options
        const availableOptions = precision?.includes('week')
            ? ComparisonPeriodOptions.filter(
                  (comparisonPeriodOption) =>
                      !['previousPeriod', 'previousYear'].includes(comparisonPeriodOption.value),
              )
            : ComparisonPeriodOptions;

        return (
            <Toolbar.Dropdown
                disabled={!isActive}
                toggleDisabled={disabled}
                onToggle={onToggle}
                label="Compare to"
                value={value}
                onSelect={onChange}
                options={availableOptions}
            />
        );
    }, [value, precision, isActive, disabled]);
};

interface ChartTitleLeftProps {
    isLoading?: boolean;
    metric: TChartDataMetric;
    handleUpdateChartMetric: (value: TChartDataMetric) => void;
    titleLabel: string;
    total: number;
    totalChip?: ReactElement;
    metricOptions?: TChartDataMetric[];
}

export const ChartTitleLeft: FC<ChartTitleLeftProps> = ({
    isLoading = false,
    metricOptions = [],
    metric,
    handleUpdateChartMetric,
    titleLabel,
    total,
    totalChip,
}) => {
    return (
        <div className="chart-title_left">
            {metricOptions.length > 1 ? (
                <Toolbar className="chart-title_total-dropdown-container">
                    <Toolbar.Dropdown
                        className="chart-title_total-dropdown"
                        label=""
                        value={metric}
                        onSelect={handleUpdateChartMetric}
                        options={ChartMetricOptions.filter((metricOption) =>
                            metricOptions.includes(metricOption.value as TChartDataMetric),
                        )}
                        variant="flat"
                        afterComponent={
                            <span className="chart-title_total-title with-dropdown">
                                {titleLabel}
                            </span>
                        }
                    />
                </Toolbar>
            ) : (
                <span className="chart-title_total-title">{titleLabel}</span>
            )}
            <span className="chart-title_total">
                {!isLoading ? (
                    <>
                        <FormattedMetricValue
                            metric={metric}
                            value={typeof total === 'number' ? total : 0}
                        />
                        {totalChip}
                    </>
                ) : (
                    <Skeleton height={35} width={240} />
                )}
            </span>
        </div>
    );
};

interface ComparisonChartTitleProps {
    isLoading?: boolean;
    showLegend: boolean;
    showComparisonPeriod: boolean;
    hasHistoricalSalesData: boolean;
    metric: TChartDataMetric;
    handleUpdateChartMetric: (value: TChartDataMetric) => void;
    comparisonValues: {
        currentValue: number;
        comparisonValue: number;
        previousValue: number;
        previousTotalValue?: number;
    };
    currentStartDate: string | Date;
    currentEndDate: string | Date;
    comparisonStartDate: string | Date;
    comparisonEndDate: string | Date;
    currentPeriodLabel: string;
    breakdownLabel?: string;
    breakdownItemLabels?: string[];
    titleLabelOverride?: string;
    isSparkComparison: boolean;
    metricOptions?: TChartDataMetric[];
}

const ComparisonChartTitle: FC<ComparisonChartTitleProps> = ({
    isLoading = false,
    showLegend,
    showComparisonPeriod,
    hasHistoricalSalesData,
    metric,
    handleUpdateChartMetric,
    comparisonValues,
    currentStartDate,
    currentEndDate,
    comparisonStartDate,
    comparisonEndDate,
    currentPeriodLabel,
    breakdownLabel = 'location',
    breakdownItemLabels,
    titleLabelOverride,
    isSparkComparison,
    metricOptions = [],
}) => {
    const { currentValue, comparisonValue, previousValue, previousTotalValue } = comparisonValues;

    const titleLabel = useMemo(() => {
        if (titleLabelOverride) {
            return titleLabelOverride;
        }

        const metricLabel = metricOptions.length > 1 ? '' : getChartMetricLabel(metric);

        const breakdownLabelName =
            breakdownItemLabels?.length === 1
                ? breakdownItemLabels?.[0]
                : `All ${breakdownItemLabels?.length} ${capitalize(pluralize(breakdownLabel))}`;

        return `${metricLabel} of ${breakdownLabelName}`;
    }, [titleLabelOverride, breakdownItemLabels, metric, metricOptions]);

    const yesterdayFormatted = moment().subtract(1, 'day').format(DATE_DISPLAY_FORMAT);

    const comparisonDisplayText = `${
        isSparkComparison ? 'ROI' : 'Comparison'
    } calculated through ${yesterdayFormatted}`;
    const tooltipMessage = currentValue !== comparisonValue ? comparisonDisplayText : '';

    const ranges = [
        {
            startDate: currentStartDate,
            endDate: currentEndDate,
            color: ComparisonColors[0],
            label: currentPeriodLabel,
        },
    ];

    if (showComparisonPeriod) {
        ranges.push({
            startDate: comparisonStartDate,
            endDate: comparisonEndDate,
            color: ComparisonColors[1],
            label: 'Previous Period',
        });
    }

    // For average metrics, we need to use the totals as returned by the api calculators for the total chip calculations because they're not cumulative, and if we use comparisonValue vs previousValue (as works for cumulitive diffs like total_units or total_sales) we will just match the diff for the most recently completed day - instead of the overall avg difference
    const currentValueForTotalChip = useMemo(
        () => (isAverageMetric(metric) ? currentValue ?? 0 : comparisonValue),
        [metric, currentValue, comparisonValue, hasHistoricalSalesData, showComparisonPeriod],
    );
    const prevValueForTotalChip = useMemo(
        () => (isAverageMetric(metric) ? previousTotalValue ?? 0 : previousValue),
        [metric, previousValue, previousTotalValue, hasHistoricalSalesData, showComparisonPeriod],
    );

    const totalChip =
        showComparisonPeriod && hasHistoricalSalesData ? (
            <PercentDifferenceChip
                currentValue={currentValueForTotalChip}
                prevValue={prevValueForTotalChip}
                tooltipMessage={tooltipMessage}
            />
        ) : undefined;

    return (
        <Chart.Title
            className="chart-title-comparison-windows"
            titleLabel={titleLabel}
            metric={metric}
            chartTitleLeft={
                <ChartTitleLeft
                    isLoading={isLoading}
                    metric={metric}
                    handleUpdateChartMetric={handleUpdateChartMetric}
                    titleLabel={titleLabel}
                    total={currentValue}
                    totalChip={totalChip}
                    metricOptions={metricOptions}
                />
            }
            total={currentValue}
            totalChip={totalChip}
            ranges={!showLegend ? ranges : []}
        />
    );
};

const NoHistoricalSalesMessage: FC<{ chartType: TChartType }> = ({ chartType }) => {
    return chartType !== 'table' ? (
        <div className="no-historical-sales-data-overlay">
            <span>Historical sales data unavailable</span>
        </div>
    ) : (
        <CalloutMessage
            className="comparison-chart_no-historical-sales-callout-message"
            message="Historical sales data unavailable"
            color="yellow"
        />
    );
};

interface ComparisonChartProps {
    showLegend?: boolean;
    showComparisonPeriod: boolean;
    currentPeriodLabel: string;
    breakdownLabel?: string;
    chartSettings: IChartDataSettings;
    locations: IPosLocation[];
    isSparkComparison?: boolean;
    metricOptions?: TChartDataMetric[];
}

const ComparisonChart = ({
    showLegend = false,
    showComparisonPeriod = false,
    chartSettings,
    currentPeriodLabel,
    breakdownLabel,
    locations,
    isSparkComparison = false,
    metricOptions,
}: ComparisonChartProps) => {
    const { dateEnd, dateStart } = chartSettings;

    const { onUpdateChartSetting } = useChartContext();

    const { comparisonChartIsReady, hasNoHistoricalSalesData, comparisonValues, comparisonRange } =
        useComparisonChartContext();

    const handleUpdateChartMetric = onUpdateChartSetting('metric');

    const calculatorMetric = chartSettings?.percentIncreaseData?.metric ?? chartSettings.metric;

    return (
        <>
            {comparisonChartIsReady && (
                <ComparisonChartTitle
                    showLegend={showLegend}
                    showComparisonPeriod={showComparisonPeriod}
                    hasHistoricalSalesData={!hasNoHistoricalSalesData}
                    metric={calculatorMetric}
                    handleUpdateChartMetric={handleUpdateChartMetric}
                    comparisonValues={comparisonValues}
                    currentStartDate={dateStart}
                    currentEndDate={dateEnd}
                    comparisonStartDate={comparisonRange?.previousDateStart ?? ''}
                    comparisonEndDate={comparisonRange?.previousDateEnd ?? ''}
                    currentPeriodLabel={currentPeriodLabel}
                    breakdownLabel={breakdownLabel}
                    breakdownItemLabels={locations.map(({ label }) => label)}
                    isSparkComparison={isSparkComparison}
                    metricOptions={metricOptions}
                />
            )}
            <div className="comparison-chart-container">
                {comparisonChartIsReady &&
                    (hasNoHistoricalSalesData ||
                        (showComparisonPeriod && comparisonValues.previousValue === -1)) && (
                        <NoHistoricalSalesMessage chartType={chartSettings.type} />
                    )}

                <DeprecatedAdvancedComparisonChart
                    hideLegend={!showLegend}
                    breakdownLabel={breakdownLabel}
                    customCurrentPeriodLabel={currentPeriodLabel}
                />
            </div>
        </>
    );
};

ComparisonChart.Title = ComparisonChartTitle;
ComparisonChart.ToolbarDropdownToggle = ComparisonWindowToolbarDropdownToggle;
ComparisonChart.NoHistoricalSalesMessage = NoHistoricalSalesMessage;

export default ComparisonChart;
