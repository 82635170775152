import { FC, useEffect, useMemo } from 'react';

import ClickableSnapItem from '@features/spark-snaps/components/ClickableSnapItem';
import { useSnapQuery, useUserSnapEventsQuery } from '@features/spark-snaps/queries';

import IconButton from '@components/buttons/IconButton';
import { Edit as EditIcon } from '@components/icons';
import Skeleton from '@components/layout/Skeleton';
import Tooltip from '@components/layout/Tooltip';

import SparkSnapProductTour from '../SparkSnapProductTour';

import './SparkSnapItem.scss';

interface SparkSnapItemProps {
    accountId: string;
    snapId: number;
    onEdit?: (snapId: number) => void;
    canEdit?: boolean;
    onView: (snapId: number) => void;
    userIsAdmin?: boolean;
    sparkId?: string;
    trackingSource?: 'Spark Snaps Panel' | 'Spark Snaps Viewer';
    sparkSnapListIndex?: number;
}

const SparkSnapItem: FC<SparkSnapItemProps> = ({
    accountId,
    snapId,
    onEdit,
    canEdit = false,
    onView,
    sparkId,
    trackingSource,
    userIsAdmin = false,
    sparkSnapListIndex,
}) => {
    const { snapIsReady, snap, snapThumbnail } = useSnapQuery({
        accountId,
        snapId,
    });
    const { userSnapEventsAreReady, userSnapEvents } = useUserSnapEventsQuery({
        eventType: 'story_complete',
    });
    const { userSnapEventsAreReady: startedEventsAreReady, userSnapEvents: snapStartedEvents } =
        useUserSnapEventsQuery({
            eventType: 'story_started',
        });
    useEffect(() => {
        if (snap && !userIsAdmin && window.freshpaint && snapIsReady) {
            window.freshpaint.ready(() =>
                window.freshpaint?.track(`Element Rendered: Snap Trigger - ${trackingSource}`, {
                    snapId,
                    accountId,
                    sparkId,
                }),
            );
        }
    }, [snap, userIsAdmin, window.freshpaint, sparkId, snapIsReady]);

    const showProductTour = useMemo(
        () =>
            sparkSnapListIndex === 0 &&
            startedEventsAreReady &&
            snapStartedEvents?.filter((event) => event.sparkId)?.length === 0,
        [sparkSnapListIndex, startedEventsAreReady, snapStartedEvents],
    );

    const snapViewed = useMemo(
        () =>
            !userSnapEventsAreReady
                ? true
                : userSnapEvents?.some((event) => event.snapId === snap?.storifymeSnapId),
        [userSnapEventsAreReady, userSnapEvents, snapId],
    );

    if (!snapIsReady) {
        return (
            <div className="spark-snap-item">
                <Skeleton className="skeleton-icon" height={64} width={64} />
            </div>
        );
    }

    return (
        <>
            {snap ? (
                <div className="spark-snap-item">
                    {showProductTour && (
                        <SparkSnapProductTour onConfirm={onView} beaconTarget=".view-snap-button" />
                    )}
                    <ClickableSnapItem
                        snapId={snapId}
                        onView={onView}
                        snapThumbnail={snapThumbnail}
                        snapName={snap?.name}
                        showFlashing={!userIsAdmin}
                        viewed={snapViewed}
                    />
                    <div className="spark-snap-item_label">
                        <Tooltip title={snap?.name}>
                            <span>{snap?.name}</span>
                        </Tooltip>
                        {canEdit && onEdit && (
                            <IconButton
                                data-testid={`edit-snap-button_${snapId}`}
                                onClick={() => onEdit(snapId)}
                                className="spark-snap-item_edit-button"
                            >
                                <EditIcon />
                            </IconButton>
                        )}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default SparkSnapItem;
