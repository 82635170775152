import { SparkTemplate } from '@features/spark-dashboard/types';

import retailerTemplates from './retailerTemplates';
import vendorTemplates from './vendorTemplates';

const sparkTemplateData: {
    [key: string]: SparkTemplate;
} = [...retailerTemplates, ...vendorTemplates].reduce(
    (acc: { [key: string]: SparkTemplate }, template) => {
        acc[template._id] = template as SparkTemplate;
        return acc;
    },
    {},
);

const getSparkTemplateData = (templateName: string) => {
    const { _id, ...rawTemplateData } = sparkTemplateData[templateName];
    // remove _id from template data and add templateId because this will be spread into a spark object which has it's own _id.
    const templateData = { ...rawTemplateData, templateId: _id };
    return templateData;
};
export { getSparkTemplateData };
export default sparkTemplateData;
