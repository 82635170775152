import { useRef } from 'react';

export { useForm, useFormContext, useWatch } from 'react-hook-form';

export const useFormRef = () => {
    return useRef<HTMLFormElement | { isValid: any }>({
        isValid: () => {
            // eslint-disable-next-line no-console
            console.log('WARNING: Empty form ref');
            return true;
        },
    });
};

export const useAsyncFormSubmit = () => {};
