import { FC, ReactElement } from 'react';

import { FormControlLabel, Radio } from '@mui/material';

import Button from '@components/buttons/Button';
import Tooltip from '@components/layout/Tooltip';

import { appendClasses } from '@helpers/ui';

import { IOption, InputEventOnChange } from '@app/types/UITypes';

export interface RadioOption extends Omit<IOption, 'label'> {
    icon?: ReactElement;
    label: string | ReactElement;
    description?: string | ReactElement;
    disabled?: boolean;
    tooltipProps?: { title: string };
    endIcon?: ReactElement;
}

interface RadioGroupOptionProps {
    className?: string;
    currentValue: string;
    option: RadioOption;
    onChange: InputEventOnChange;
    showResetButton?: boolean;
    disabled?: boolean;
}
export const RadioGroupOption: FC<RadioGroupOptionProps> = ({
    className,
    currentValue,
    option,
    onChange: _onChange,
    showResetButton,
    disabled: propsDisabled = false,
}) => {
    const disabled = propsDisabled || !!option.disabled;
    const isSelected = option.value === currentValue;

    const classNamesAppended = appendClasses([
        'radio-group-item',
        isSelected ? 'selected' : 'not-selected',
        option.description ? 'has-description' : 'has-no-description',
        option.icon ? 'has-icon' : 'has-no-icon',
        showResetButton ? 'has-reset-button' : 'has-no-reset-button',
        disabled ? 'disabled' : 'not-disabled',
        className,
    ]);

    const label =
        option.description || option.icon || showResetButton ? (
            <div className="radio-group-item_advanced-label">
                {option.icon && <div className="radio-group-item_icon">{option.icon}</div>}
                <div className="radio-group-item_label-and-description">
                    <span className="radio-group-main-label">{option.label}</span>
                    {option.description && (
                        <div className="radio-group-label-description">
                            {typeof option.description === 'string' ? (
                                <p>{option.description}</p>
                            ) : (
                                option.description
                            )}
                        </div>
                    )}
                </div>
                {showResetButton && (
                    <div className="radio-group-item_reset-btn-wrapper">
                        <Button
                            variant="flat"
                            color="blue"
                            disableHover
                            onClick={() => _onChange({ target: { value: '' } })}
                        >
                            Change
                        </Button>
                    </div>
                )}
                {option.endIcon && (
                    <div className="radio-group-item_end-icon">{option.endIcon}</div>
                )}
            </div>
        ) : (
            option.label
        );

    const onChange = (_option: RadioOption) => {
        if (!showResetButton && !disabled) {
            const { value } = _option;
            _onChange({ target: { value } });
        }
    };

    const Option = (
        <div
            className={classNamesAppended}
            role="button"
            tabIndex={-1}
            onClick={() => onChange(option)}
            onKeyPress={() => onChange(option)}
        >
            <FormControlLabel
                className="radio-label"
                value={option.value}
                disabled={disabled}
                control={<Radio checked={isSelected} className="radio-icon" />}
                label={label}
            />
        </div>
    );
    return option.tooltipProps ? <Tooltip {...option.tooltipProps}>{Option}</Tooltip> : Option;
};
