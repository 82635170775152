import { DO_NOT_HAVE_PERMISSIONS_MESSAGE } from '@sparkplug/lib';

import IconButton from '@components/buttons/IconButton';
import { UnmatchedUserIcon } from '@components/icons';
import Badge from '@components/layout/Badge';
import Tooltip from '@components/layout/Tooltip';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { useSelfServiceQueueState } from '..';

import './SelfServiceQueueButton.scss';

export const SelfServiceQueueButton = () => {
    const { userCan } = useSparkplugAccount();
    const { unmatchedUsers = [], openOnboardingModal } = useSelfServiceQueueState();
    const unmatchedUsersCount = unmatchedUsers.length;
    const userCanManageUsers = userCan('manageUsers');
    return (
        <Tooltip title={!userCanManageUsers ? DO_NOT_HAVE_PERMISSIONS_MESSAGE : 'Un-matched Users'}>
            <div className="self-service-queue-button-container">
                <Badge count={unmatchedUsersCount}>
                    <IconButton
                        className="self-service-queue-button"
                        disabled={!userCanManageUsers}
                        onClick={openOnboardingModal}
                    >
                        <UnmatchedUserIcon />
                    </IconButton>
                </Badge>
            </div>
        </Tooltip>
    );
};
