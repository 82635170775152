import SparksAPI from '@api/SparksAPI';

import { useAdvancedQuery } from '@hooks/QueryHooks';

import { IAccount } from '@app/types/AccountsTypes';

export const useGetPendingSparksCountQuery = ({
    account,
    isAdminApp,
}: {
    account?: IAccount;
    isAdminApp: boolean;
}) => {
    const { isLoading, data, refetch, isFetched } = useAdvancedQuery(
        account?._id ?? '',
        () => (account?._id ? SparksAPI.getPendingSparksCount(account?._id) : Promise.resolve(0)),
        {
            enabled: isAdminApp && account?.type === 'retailer',
        },
    );

    return { isLoading, count: data, refetch, isFetched };
};
