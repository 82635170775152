import { FC, useMemo, useState } from 'react';

import { useSparkBrandsQuery } from '@features/spark-brands';
import SnapsEmptyState from '@features/spark-snaps/components/SnapsEmptyState/SnapsEmptyState';
import { useSnapViewQueue } from '@features/spark-snaps/hooks';
import {
    useDeleteSnapMutation,
    useRemoveFeaturedSnapMutation,
} from '@features/spark-snaps/mutations/FeaturedSnapMutations';
import { useFeaturedSnaps } from '@features/spark-snaps/queries/FeaturedSnapQueries';

import ConfirmModal from '@components/overlays/ConfirmModal';
import { useHistory } from '@components/router';
import Table from '@components/table/Table';
import Toolbar from '@components/toolbar/Toolbar';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { ITableRow } from '@app/types/TableTypes';

import { CreateFeaturedSnapSection } from './components/CreateFeaturedSnapsSection';
import { useFeaturedSnapFilters } from './hooks/useFeaturedSnapFilters';
import { useFeaturedSnapHeadCells } from './hooks/useFeaturedSnapHeadCells';
import { ISnapRow } from './types';

interface FeaturedSnapsTabProps {
    setSelectedBrandId: (brandId: string) => void;
}
const FeaturedSnapsTab: FC<FeaturedSnapsTabProps> = ({ setSelectedBrandId }) => {
    const { account, hasSnapsEntitlement: snapsEnabled } = useSparkplugAccount();

    const { SnapViewer, handleViewSnap } = useSnapViewQueue();
    const { sparkBrands, sparkBrandsAreReady } = useSparkBrandsQuery(
        account?._id || '',
        account?.type === 'brand',
        undefined,
        'always',
    );
    const [confirmDeleteSnapId, setConfirmDeleteSnapId] = useState<number | undefined>();
    const [confirmRemoveSnap, setConfirmRemoveSnap] = useState<{
        snapId: number;
        featuredPeriodId: string;
    }>();
    const headCells = useFeaturedSnapHeadCells({
        onDeleteClick: setConfirmDeleteSnapId,
        onRemoveClick: setConfirmRemoveSnap,
        onPreviewClick: ({ selectedSnapId, accountId }) =>
            handleViewSnap({ selectedSnapId, accountId }),
    });

    const history = useHistory();
    const { featuredSnaps = [], featuredSnapsAreReady } = useFeaturedSnaps(
        account?._id || '',
        snapsEnabled,
    );
    const {
        filters,
        setFilters,
        onChangeSearchFilter,
        applyBrandFilter,
        applyFeaturedSnapStatus,
        applySearch,
        searchFilter,
    } = useFeaturedSnapFilters({
        initialFilterValues: {},
    });
    const { deleteSnapAsync } = useDeleteSnapMutation({
        accountId: account?._id || '',
    });
    const { removeSnapAsync, isRemovingSnap } = useRemoveFeaturedSnapMutation({
        accountId: account?._id || '',
    });

    const brandOptions = useMemo(() => {
        return Object.values(account?.sparkBrands || {})
            .filter((brand) => brand.isEnabled)
            .map((brand) => ({
                value: brand._id,
                label: brand.name,
            }));
    }, [account]);

    const isAllBrands = !filters.brands.length || filters.brands.length === brandOptions.length;

    const rows: ITableRow<ISnapRow>[] = useMemo(
        () =>
            featuredSnaps.map((snap) => ({
                ...snap,
                featuredPeriods:
                    snap.featuredPeriods?.sort((a, b) => {
                        const dateA = new Date(a?.startDate || 0).getTime();
                        const dateB = new Date(b?.startDate || 0).getTime();
                        return dateB - dateA;
                    }) || [],

                status: snap.featuredPeriods?.some((period) => !period?.endDate)
                    ? 'live'
                    : 'removed',
                brandThumbnailUrl: snap.brandId ? account?.sparkBrands?.[snap.brandId]?.photo : '',
                key: String(
                    `${snap.storifymeSnapId}${snap._id}${snap.featuredPeriods?.[0]?.endDate}`,
                ),
            })),
        [featuredSnaps, account, isRemovingSnap],
    );

    const snapSectionOptions = useMemo(
        () =>
            account?.type !== 'retailer'
                ? sparkBrands
                      .filter((brand) => brand.isEnabled)
                      .map((brand) => ({
                          value: brand._id,
                          label: brand.name,
                          imgSrc: brand.photo,
                          activeSnapId: featuredSnaps.find(
                              (snap) =>
                                  snap.brandId === brand._id &&
                                  snap.featuredPeriods?.some((period) => !period?.endDate),
                          )?.storifymeSnapId,
                      }))
                : [
                      {
                          value: account?._id,
                          label: account?.name,
                          imgSrc: account?.photo || '',
                          activeSnapId: featuredSnaps.find(
                              (snap) =>
                                  snap.accountId === account?._id &&
                                  snap.featuredPeriods?.some((period) => !period?.endDate),
                          )?.storifymeSnapId,
                      },
                  ],
        [sparkBrands, sparkBrandsAreReady, featuredSnaps, featuredSnapsAreReady],
    );

    return (
        <div>
            {!snapsEnabled || (featuredSnapsAreReady && featuredSnaps.length === 0) ? (
                <SnapsEmptyState
                    snapsEnabled={snapsEnabled}
                    buttonLabel="Create Snap"
                    emptyStateProps={{
                        graphic: (
                            <img
                                src="/static/images/Vendor-ES-FeaturedSnaps.png"
                                alt="placeholder"
                            />
                        ),
                        label: 'Feature Your Brand to All of Your Retail Partners',

                        smallText:
                            'Featured Snaps are Brand stories viewable by employees at all of your linked Retail partners. Share news and engage directly with employees.',
                    }}
                    onClick={() => {
                        history.push(`?create=featured-snap`);
                    }}
                />
            ) : (
                <>
                    <div className="mb-6">
                        <CreateFeaturedSnapSection
                            options={snapSectionOptions}
                            onOptionClick={({ value, activeSnapId }) => {
                                if (activeSnapId && account?._id) {
                                    handleViewSnap({
                                        selectedSnapId: activeSnapId,
                                        accountId: account?._id,
                                    });
                                    return;
                                }
                                history.push(`?create=featured-snap`);

                                setSelectedBrandId(value);
                            }}
                        />
                    </div>
                    <Toolbar justifyContentStart>
                        <Toolbar.Search
                            name="search"
                            defaultValue={searchFilter}
                            onChange={onChangeSearchFilter}
                        />
                        {account?.type === 'brand' && (
                            <Toolbar.DropdownListSelector
                                label={`${isAllBrands ? 'All ' : ''}Brands`}
                                options={brandOptions}
                                selected={filters.brands.length ? filters.brands : brandOptions}
                                onSelectionChanged={(value) =>
                                    setFilters((prevValue) => ({
                                        ...prevValue,
                                        brands: value,
                                    }))
                                }
                                clear={{
                                    active: !isAllBrands,
                                    onClear: () => {
                                        setFilters((prevValue) => ({
                                            ...prevValue,
                                            brands: [],
                                        }));
                                    },
                                }}
                            />
                        )}
                        <Toolbar.Dropdown
                            label={null}
                            options={[
                                { label: 'Live', value: 'live' },
                                { label: 'Removed', value: 'removed' },
                            ]}
                            value={filters.featuredSnapStatus}
                            onSelect={(value) =>
                                setFilters((prevValue) => ({
                                    ...prevValue,
                                    featuredSnapStatus: value,
                                }))
                            }
                            titlePlaceholder="Snap Status"
                            clear={{
                                active: !!filters.featuredSnapStatus,
                                onClear: () => {
                                    setFilters((prevValue) => ({
                                        ...prevValue,
                                        featuredSnapStatus: undefined,
                                    }));
                                },
                            }}
                        />
                    </Toolbar>
                    <Table
                        isLoading={!featuredSnapsAreReady}
                        headCells={headCells}
                        defaultOptions={{
                            orderBy: 'status',
                            order: 'asc',
                        }}
                        rows={rows}
                        filters={[applySearch, applyBrandFilter, applyFeaturedSnapStatus]}
                        variant="raised"
                    >
                        <Table.RenderHead />
                        <Table.RenderBody />
                    </Table>
                    <ConfirmModal
                        isVisible={!!confirmRemoveSnap}
                        title="Remove Snap?"
                        message={
                            <div className="text-base font-inter  leading-5 tracking-wide break-words">
                                <strong className="font-bold">
                                    Are you sure you wish to remove this Snap?
                                </strong>{' '}
                                Employees will no longer be able to view.
                            </div>
                        }
                        confirmText="Remove"
                        onConfirm={() => {
                            if (confirmRemoveSnap) removeSnapAsync(confirmRemoveSnap);
                            setConfirmRemoveSnap(undefined);
                        }}
                        onClose={() => setConfirmRemoveSnap(undefined)}
                    />
                    <ConfirmModal
                        isVisible={!!confirmDeleteSnapId}
                        title="Delete Snap?"
                        message={
                            <div className="text-base font-inter  leading-5 tracking-wide break-words">
                                <strong className="font-bold">
                                    Are you sure you wish to delete this Snap?
                                </strong>{' '}
                                It will be removed from the table and analytics will be lost.
                            </div>
                        }
                        confirmText="Delete"
                        confirmBtnColor="red"
                        onConfirm={() => {
                            if (confirmDeleteSnapId)
                                deleteSnapAsync({ snapId: confirmDeleteSnapId });
                            setConfirmDeleteSnapId(undefined);
                        }}
                        onClose={() => setConfirmDeleteSnapId(undefined)}
                    />
                    <SnapViewer />
                </>
            )}
        </div>
    );
};

export default FeaturedSnapsTab;
