import { FC } from 'react';
import { RouteProps } from 'react-router';

import ConditionalRoute, { AccountCondition, UserCondition } from './ConditionalRoute';

interface SuperAdminRouteProps extends RouteProps {
    unauthorizedRedirect?: string;
}

const SuperAdminRoute: FC<SuperAdminRouteProps> = ({
    unauthorizedRedirect = '/',
    ...otherProps
}) => {
    const accountConditions: AccountCondition[] = [];
    const userConditions: UserCondition[] = [(user) => user?.role === 'super-admin'];

    const newProps = {
        ...otherProps,
        unauthorizedRedirect,
        userConditions,
        accountConditions,
    };

    return <ConditionalRoute {...newProps} />;
};

export default SuperAdminRoute;
