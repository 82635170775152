import { ComponentProps, FC } from 'react';

import SearchField from '@components/inputs/SearchField';

import { appendClasses } from '@helpers/ui';

import { useIsInToolbar } from '../Toolbar/useIsInToolbar';
import ToolbarGroup, { ToolbarGroupProps } from '../ToolbarGroup';

import './ToolbarSearch.scss';

interface ToolbarSearchProps extends ToolbarGroupProps, ComponentProps<typeof SearchField> {}

const ToolbarSearch: FC<ToolbarSearchProps> = ({
    className,
    name,
    defaultValue,
    alignStart,
    onChange,
    inputRef,
}) => {
    useIsInToolbar({ componentName: 'Toolbar.Search' });
    const classNameAppended = appendClasses([className, 'toolbar-search']);

    return (
        <ToolbarGroup className={classNameAppended} alignStart={alignStart}>
            <SearchField
                name={name}
                defaultValue={defaultValue}
                onChange={onChange}
                inputRef={inputRef}
            />
        </ToolbarGroup>
    );
};

export default ToolbarSearch;
