import { AccountLink } from '@sparkplug/lib';

import { IOption } from '@app/types/UITypes';

/**
 * The goal of this util is to capture the label formatting/parsing conventions in code.
 * */
export const accountLinkOptionUtil = {
    /**
     * The SearchSelect component searches by label.
     * This function generates a label that is formatted so that we can search by both account name and brand name(s).
     * Prior to displaying the label, we'll parse it to display the account name and brand name(s) separately.
     *
     * Label format: "accountName|brandName1, brandName2, ..."
     */
    generateLabel: (accountLink: AccountLink) => {
        const { accountName, brandLinks = [] } = accountLink;
        return `${accountName}|${brandLinks.map((b) => b.name).join(', ')}`;
    },

    /**
     * @returns The account name and if the account has brands, a comma separated list of brand names.
     */
    parseLabel: (accountLinkOption: IOption) => {
        const [accountName, brandNames = ''] = accountLinkOption.label.split('|');
        return {
            accountName,
            brandNames,
        };
    },
};
