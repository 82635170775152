/* eslint-disable no-console */
import { QueryClient } from 'react-query';

import APIHelper from '@api/API';
import PointOfSaleAPI from '@api/PointOfSaleAPI';
import SparkplugAPI from '@api/SparkplugAPI';
import AllUnitedStates from '@data/UnitedStates.json';
import axios from 'axios';
import { keyBy } from 'lodash';

import {
    AccountVendorRetailerRequestPathParams,
    GetAccountResponseBody,
    GetAccountVendorRetailerResponseBody,
    IPublicAccount,
    ListAccountsQueryParams,
    ListAccountsResponse,
    ListByIdAccountResponse,
    ListPaymentMethodsResponseBody,
    PaymentHealthResponseBody,
    PosCategory,
    StripeLinkResponseBody,
} from '@sparkplug/lib';

import { getAccountLinks, getAccountLinksQueryKey } from '@features/account-links/queries';

import { useAdvancedQuery } from '@hooks/QueryHooks';

import { fetchBatchedData } from '@helpers/api';
import { sortByString } from '@helpers/ui';

import { IAccount } from '@app/types/AccountsTypes';
import {
    IPosBrand,
    IPosCategory,
    IPosEmployeeProfile,
    IPosLocation,
    IPosProduct,
} from '@app/types/PosTypes';
import { IAccountUser, IPublicAccountOption } from '@app/types/UsersTypes';

import factory from '../../../log/Log';

const log = factory('AccountQueries');

const API = {
    getAccount: async (groupId: string): Promise<GetAccountResponseBody> => {
        return (await axios.get(`/api/v1/accounts/${groupId}`)).data;
    },
    getAccounts: async (params?: ListAccountsQueryParams): Promise<ListAccountsResponse> => {
        return (await axios.get('/api/v1/accounts', { params })).data;
    },
    getAccountName: async (groupId: string): Promise<string> => {
        return (await axios.get(`/api/v1/accounts/${groupId}/name`)).data;
    },
    getAllAccounts: async (userId?: string): Promise<IPublicAccount[]> => {
        try {
            const fetchFn = (offset: number, limit: number) => {
                return API.getAccounts({
                    ...(userId ? { user_id: userId } : {}),
                    offset,
                    limit,
                });
            };
            return (await APIHelper.fetchBatchedData(fetchFn)).data;
        } catch (err) {
            log.e(`getAllAccounts: ${err}`);
            throw err;
        }
    },
    getAccountsMulti: async (groupIds?: string[]): Promise<ListByIdAccountResponse> => {
        return (await axios.post(`/api/v1/accounts/multi/get`, { groupIds })).data;
    },
    getStripeUrl: async (
        groupId: string,
        returnAccountUrl?: string,
    ): Promise<StripeLinkResponseBody> => {
        return (
            await axios.get(
                `/api/v1/accounts/${groupId}/billing/stripe-link${
                    returnAccountUrl
                        ? `?returnAccountUrl=${encodeURIComponent(returnAccountUrl)}`
                        : ''
                }`,
            )
        ).data;
    },
    getPaymentMethods: async (groupId: string): Promise<ListPaymentMethodsResponseBody> => {
        return (await axios.get(`/api/v1/accounts/${groupId}/billing/payment-methods`)).data;
    },
    getVendorRetailer: async ({
        groupId,
        retailerAccountId,
    }: AccountVendorRetailerRequestPathParams): Promise<GetAccountVendorRetailerResponseBody> => {
        return (
            await axios.get(`/api/v1/accounts/${groupId}/vendor-retailers/${retailerAccountId}`)
        ).data;
    },
    getTotalProductTagCount: async (accountId: string): Promise<number> => {
        return (await axios.get(`/api/v1/accounts/${accountId}/product-tags/count`)).data;
    },
    getAccountsPaymentHealth: async (
        accountIds?: string[],
    ): Promise<PaymentHealthResponseBody[]> => {
        return (await axios.post(`/api/v1/accounts/payment-health`, { accountIds })).data;
    },
};

export const fetchGroup = (groupId: string) => {
    return API.getAccount(groupId);
};

export const fetchGroupName = (groupId: string): Promise<string> => {
    return API.getAccountName(groupId);
};

export const fetchGroupsMulti = (groupIds?: string[]) => {
    return API.getAccountsMulti(groupIds);
};

export const getRetailerProductsByBrandId = async (retailerId: string, brandId: string) => {
    const { data } = await PointOfSaleAPI.getAllProducts({
        group_id: retailerId,
        brand_id: brandId,
    });

    return data;
};

export const getAllAccounts = async (userId?: string): Promise<IPublicAccount[]> => {
    return API.getAllAccounts(userId);
};

export const getAllGroups = async (userId?: string): Promise<IPublicAccountOption[]> => {
    const groupsResponse = userId ? await API.getAllAccounts(userId) : await API.getAllAccounts();
    return groupsResponse
        .map((obj) => ({
            ...obj,
            value: obj._id,
            label: obj.name,
        }))
        .sort((a, b) => {
            return a.label.localeCompare(b.label);
        });
};

export const getAllRetailers = async () => {
    const groups = await getAllGroups();
    return groups.filter((obj) => obj.type === 'retailer');
};

export const getAllBrands = async () => {
    const groups = await getAllGroups();
    return groups.filter((obj) => obj.type === 'brand');
};

export const fetchSparkplugConfigByGroupId = (groupId: string) => {
    return SparkplugAPI.getConfigByAccountId(groupId);
};

export const getSparkplugConfigByGroupId = async (groupId: string) => {
    return new Promise((res) => {
        fetchSparkplugConfigByGroupId(groupId)
            .then((sparkplugConfigs) => {
                res(sparkplugConfigs.data.length > 0 ? sparkplugConfigs.data[0] : null);
            })
            .catch((err) => {
                console.log(err);
                res(null);
            });
    });
};

export const getLocationsByGroupId = async (groupId: string): Promise<IPosLocation[]> => {
    const { data } = await PointOfSaleAPI.getLocations({ group_id: groupId });

    return data
        .map((location) => {
            const label = `${location?.displayName ?? location?.name}${
                location?.disabled ? ' (Disabled)' : ''
            }`;
            return {
                ...location,
                label,
                value: location?._id,
            };
        })
        .sort(sortByString('label', 'asc'));
};

export const getPosEmployeeProfilesByGroupId = async (groupId: string) => {
    const posEmployeeProfiles = await PointOfSaleAPI.getUsers({ groupId });

    return posEmployeeProfiles
        .map((posEmployeeProfile: any) => ({
            ...posEmployeeProfile,
            label: posEmployeeProfile.fullName,
            value: posEmployeeProfile._id,
        }))
        .sort(sortByString('label', 'asc'));
};

export const getPosProductsByAccountId = async (
    accountId: string,
    brandId?: string,
    categoryId?: string,
    includeAssociationDetails: boolean = false,
    posProductIds?: string[],
) => {
    const fetchFn = (offset: number, limit: number) => {
        return PointOfSaleAPI.getProducts({
            group_id: accountId,
            brand_id: brandId,
            category_id: categoryId,
            offset,
            limit,
            include_association_details: includeAssociationDetails,
            posProductIds,
        });
    };

    const { data } = await fetchBatchedData(fetchFn);

    return data
        .map((obj) => ({
            ...obj,
            label: obj.name,
            value: obj._id,
        }))
        .sort(sortByString('label', 'asc'));
};

export const getAccountQueryKey = (accountId: string) => ['account', accountId];
export const getAccountByGroupId = async (
    groupId: string | undefined,
    fetchSparkplugConfig: boolean = true,
    fetchBrandlinks: boolean = true,
    queryClient?: QueryClient,
): Promise<IAccount | undefined> => {
    if (!groupId) {
        return undefined;
    }
    const group = await fetchGroup(groupId);
    const sparkplugConfig: any = fetchSparkplugConfig
        ? await getSparkplugConfigByGroupId(groupId)
        : null;

    const accountLinks = fetchBrandlinks ? await getAccountLinks(groupId) : [];

    if (queryClient && fetchBrandlinks) {
        queryClient.setQueryData(getAccountLinksQueryKey(groupId), accountLinks);
    }

    const markets = (group?.metaData?.markets || []).map((abbr) => {
        return AllUnitedStates[abbr as keyof typeof AllUnitedStates];
    });

    const roles = (group?.members || []).reduce((obj, { userId, role }) => {
        return {
            ...obj,
            [userId]: role,
        };
    }, {});

    const account = {
        ...group,
        sparkplugConfig,
        roles,
        markets,
    } as unknown as IAccount;

    if (group.type === 'brand') {
        const enabledAccountLinks = accountLinks.filter(
            (accountLink) =>
                accountLink.status === 'enabled' &&
                !!accountLink.brandLinks?.length &&
                accountLink.brandLinks?.some(({ posBrandIds }) => posBrandIds.length),
        );

        account.retailers = enabledAccountLinks.map((accountLink) => {
            return {
                _id: accountLink.accountId,
                name: accountLink.accountName,
                value: accountLink.accountId,
                label: accountLink.accountName,
                markets: (accountLink.markets || []).map(
                    (market) => AllUnitedStates[market as keyof typeof AllUnitedStates] || market,
                ),
            };
        });

        account.brandLinks = enabledAccountLinks.map((accountLink) => ({
            _id: '',
            value: accountLink.accountId,
            retailerAccountId: accountLink.accountId,
            vendorAccountId: groupId,
            accountType: 'retailer',
            state: accountLink.status,
            name: accountLink.accountName,
            label: accountLink.accountName,
            markets: accountLink.markets,
            locations: accountLink.locations,
            disabledLocations: accountLink.disabledCurrentLocations,
            mappedNamesStr: '',
            posBrandIds: accountLink.brandLinks.flatMap(({ posBrandIds }) => posBrandIds),
            posBrands: accountLink.brandLinks.flatMap(({ posBrands }) =>
                posBrands.map((posBrand) => ({
                    ...posBrand,
                    label: posBrand.name,
                    value: posBrand._id,
                })),
            ),
        }));
    } else {
        accountLinks.map((accountLink) => ({
            _id: '',
            value: accountLink.accountId,
            retailerAccountId: groupId,
            vendorAccountId: accountLink.accountId,
            accountType: 'brand',
            state: accountLink.status,
            name: accountLink.accountName,
            label: accountLink.accountName,
            markets: accountLink.markets,
            mappedNamesStr: '',
            shareInventoryData: accountLink.shareInventoryData,
            posBrandIds: accountLink.brandLinks.flatMap(({ posBrandIds }) => posBrandIds),
            posBrands: accountLink.brandLinks.flatMap(({ posBrands }) =>
                posBrands.map((posBrand) => ({
                    ...posBrand,
                    label: posBrand.name,
                    value: posBrand._id,
                })),
            ),
        }));
    }

    if (group.type === 'retailer') {
        account.allLocations = await getLocationsByGroupId(groupId);
        account.locations = account.allLocations.filter(
            ({ disabled }: IPosLocation) => disabled !== true,
        );
    }

    return account;
};
export const getAccountsPaymentHealth = (accountIds?: string[]) => {
    return API.getAccountsPaymentHealth(accountIds);
};

export const getAllRetailerAccounts = async () => {
    const promises: Promise<any>[] = [];
    const accounts = await getAllRetailers();

    accounts.forEach((account) => {
        promises.push(getLocationsByGroupId(account._id));
    });

    await Promise.all(promises);

    return accounts;
};

export const fetchPosConfigsByGroupId = async (accountId: string) => {
    const posConfigResponse = await PointOfSaleAPI.listConfigs({ groupId: accountId });
    return posConfigResponse.data;
};

export const useFetchPosConfigsByGroupId = (
    accountId: string,
    userIsSuperAdmin: boolean = true,
) => {
    const { data: posConfigs, isFetched: posConfigsAreReady } = useAdvancedQuery(
        `fetchPosConfigsByGroupId - ${accountId}`,
        () => PointOfSaleAPI.listConfigs({ groupId: accountId }),
        { enabled: userIsSuperAdmin && !!accountId },
    );

    return {
        posConfigs: posConfigs?.data,
        posConfigsAreReady,
    };
};

export const getPosBrands = async (accountId: string): Promise<IPosBrand[]> => {
    const { data = [] } = await PointOfSaleAPI.getAllBrands(accountId);

    return data
        .map((obj: any) => ({
            ...obj,
            label: obj.name,
            value: obj._id,
        }))
        .sort(sortByString('label', 'asc'));
};

export const getLocationImportConfigs = async (posConfigId: string) => {
    const config = (await PointOfSaleAPI.getConfig({ configId: posConfigId })).data;
    const locations = await getLocationsByGroupId(config.groupId);
    return Promise.all(
        locations
            .filter((location) => location.parentId === posConfigId)
            .map(async (location) => {
                const result = await PointOfSaleAPI.listLocationConfigs(location._id);
                return result.data || [];
            }),
    );
};

async function getLocationConfigs(locationId: string) {
    try {
        const locationConfigsResponse = await PointOfSaleAPI.listLocationConfigs(locationId);
        return locationConfigsResponse.data || [];
    } catch (err) {
        return [];
    }
}

export const getPosConfigDetails = async (configId: string, queryClient: QueryClient) => {
    try {
        const config = (await PointOfSaleAPI.getConfig({ configId })).data;
        const group = await API.getAccount(config.groupId);

        const locationsResponse = await getLocationsByGroupId(group._id);
        const locations = locationsResponse.filter((location) => {
            return location.parentId === config._id;
        });

        const locationInfoEntryPromises = Promise.all(
            locations.map(async (location) => {
                const [tscResponse, locationConfigs, usersResponse] = await Promise.all([
                    PointOfSaleAPI.getTransactionSummaryCount(location._id),
                    getLocationConfigs(location._id),
                    PointOfSaleAPI.getUsers({
                        locationId: location._id,
                    }),
                ]);

                const transactionSummaryCount = tscResponse.meta.total;

                const users: IAccountUser[] = usersResponse || [];
                users.sort((a, b) => {
                    return a.fullName.localeCompare(b.fullName);
                });

                return [
                    location._id,
                    {
                        users,
                        locationConfigs,
                        transactionSummaryCount,
                    },
                ];
            }),
        );

        const fetchPosBrands = () =>
            queryClient.fetchQuery(['accountPosData', group._id, 'brands'], () =>
                PointOfSaleAPI.getAllBrands(group._id),
            );
        const fetchPosCategories = () =>
            queryClient.fetchQuery(['accountPosData', group._id, 'categories'], () =>
                PointOfSaleAPI.getAllCategories(group._id),
            );
        const fetchPosProducts = () =>
            queryClient.fetchQuery(['accountPosData', group._id, 'products'], () =>
                PointOfSaleAPI.getAllProducts({
                    group_id: group._id,
                    include_association_details: true,
                }),
            );

        const [brandsResponse, categoriesResponse, productsResponse, locationInfoEntries] =
            await Promise.all([
                fetchPosBrands(),
                fetchPosCategories(),
                fetchPosProducts(),
                locationInfoEntryPromises,
            ]);

        const brands = (brandsResponse.data || []).filter(({ posConfigIds }: any) =>
            posConfigIds.includes(configId),
        );
        const categories = (categoriesResponse.data || []).filter(({ posConfigIds }: any) =>
            posConfigIds.includes(configId),
        );
        const products = (productsResponse.data || []).filter(({ posConfigIds }: any) =>
            posConfigIds.includes(configId),
        );
        const locationInfos = new Map(locationInfoEntries as any);

        return {
            config,
            group,
            locations,
            locationInfos,
            brands,
            categories,
            products,
        };
    } catch (err) {
        console.log(`componentDidMount: ${err}`);
    }

    return {};
};

export const getPosLocations = async (groupId: string): Promise<IPosLocation[]> => {
    const allPosLocations = (await getLocationsByGroupId(groupId)) || [];
    return allPosLocations;
};

export const getPosEmployeeProfiles = async (groupId: string): Promise<IPosEmployeeProfile[]> => {
    const allPosEmployeeProfiles = (await getPosEmployeeProfilesByGroupId(groupId)) || [];
    return allPosEmployeeProfiles;
};

export const getPosCategories = async (groupId: string): Promise<IPosCategory[]> => {
    const { data = [] }: { data: PosCategory[] } = await PointOfSaleAPI.getAllCategories(groupId);

    const posCategories = data
        .map((obj) => ({
            ...obj,
            label: obj.name,
            value: obj._id,
        }))
        .sort(sortByString('label', 'asc'));

    return posCategories;
};

export const getPosProducts = async (
    groupId: string,
    posProductIds?: string[],
): Promise<IPosProduct[]> => {
    const posProducts =
        (await getPosProductsByAccountId(groupId, undefined, undefined, true, posProductIds)) || [];
    return posProducts;
};

export const getStripeUrl = async (groupId: string, returnAccountUrl?: string) => {
    return API.getStripeUrl(groupId, returnAccountUrl);
};

export const generateAccountLocations = async (
    locations: IPosLocation[],
    accountUsers: IAccountUser[],
) => {
    const usersById = keyBy(
        accountUsers.filter(({ userId }) => !!userId),
        'userId',
    );

    return Promise.all(
        locations.map(async (location) => {
            const activePosEmployeeProfiles =
                location?.disabled !== true
                    ? accountUsers.filter(
                          ({ locationIds, groupRole }) =>
                              (locationIds || []).includes(location?._id) && groupRole !== 'none',
                      )
                    : [];

            const locationConfigs = await PointOfSaleAPI.listLocationConfigs(location._id)
                .then((result) => result.data)
                .catch(() => []);

            const importConfigs = locationConfigs.filter(
                ({ type }: { type: string }) => type === 'location-import-config',
            );

            const locationManagerNames =
                location?.managerUserIds
                    ?.map((userId) => usersById?.[userId]?.fullName)
                    .filter((managerName) => !!managerName) ?? [];

            return {
                ...location,
                key: location._id,
                lookbackDate: importConfigs.length ? importConfigs[0].originalLookback : undefined,
                activePosEmployeeProfiles,
                employeeCount: activePosEmployeeProfiles.length,
                managerNames: locationManagerNames.length ? locationManagerNames.join(', ') : '--',
            };
        }),
    );
};

export const getPaymentMethods = async (groupId: string) => {
    return API.getPaymentMethods(groupId);
};

export const getVendorRetailer = async ({
    groupId,
    retailerAccountId,
}: AccountVendorRetailerRequestPathParams) => {
    return API.getVendorRetailer({ groupId, retailerAccountId });
};

export const getTotalProductTagCount = async ({ accountId }: { accountId: string }) => {
    return API.getTotalProductTagCount(accountId);
};
