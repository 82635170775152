import { ElementType } from 'react';
import ReactJoyride, { BeaconRenderProps } from 'react-joyride';

import Button from '@components/buttons/Button';

import './styles.scss';

const Tooltip = ({ step, tooltipProps }: any) => (
    <div
        {...tooltipProps}
        className="cursor-pointer max-w-[256px] -top-3 shadow-lg shadow-gray-400/20 bg-white p-3"
    >
        {step.title && <div>{step.title}</div>}
        <div>{step.content}</div>
    </div>
);

const BeaconComponent = (props: BeaconRenderProps) => (
    <div {...props} className="spark-snap-beacon-container">
        <div className="spark-snap-beacon" />
    </div>
);

const SparkSnapProductTour = ({
    onConfirm,
    beaconTarget,
    run,
}: {
    onConfirm: (e: any) => void;
    beaconTarget: string;
    run?: boolean;
}) => {
    return (
        <div
            onClick={(e) => e.stopPropagation()}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    e.stopPropagation();
                }
            }}
        >
            <ReactJoyride
                disableScrolling
                tooltipComponent={Tooltip}
                run={run}
                beaconComponent={BeaconComponent as ElementType<BeaconRenderProps>}
                styles={{
                    options: {
                        primaryColor: '#0089AB',
                    },
                    tooltip: {
                        top: '50%',
                    },
                }}
                floaterProps={{ hideArrow: true }}
                steps={[
                    {
                        target: beaconTarget,
                        content: (
                            <>
                                <div className="text-lg font-bold">Introducing Spark Snaps!</div>
                                <div className="text-[14px] leading-[20px] mb-3">
                                    Check out insider tips and content to help you succeed with
                                    Sparks.
                                </div>
                                <Button
                                    variant="filled"
                                    color="blue"
                                    onClick={(e: any) => onConfirm(e)}
                                >
                                    Got it!
                                </Button>
                            </>
                        ),
                        placement: 'right',
                        placementBeacon: 'top-end',
                    },
                ]}
                disableOverlay
                hideCloseButton
                hideBackButton
            />
        </div>
    );
};
export default SparkSnapProductTour;
