import { FC } from 'react';

import { HydratedPosProduct } from '@sparkplug/lib';

import { useBulkInvalidateProducts } from '@features/product-tags/mutations';

import CalloutMessage from '@components/layout/CalloutMessage';
import ConfirmModal from '@components/overlays/ConfirmModal';
import { IDefaultModalProps } from '@components/overlays/Modal';
import toast from '@components/toast';

import { useTableContext } from '@hooks/TableHooks';

import { isEmpty } from '@helpers/util';

import './BulkSetInvalidConfirmModal.scss';

const MarkInvalidMessage = ({ productCount }: { productCount: number }) => {
    const countMessage =
        productCount === 1 ? (
            <span>
                this <strong>{productCount}</strong> product invalid, it
            </span>
        ) : (
            <span>
                these <strong>{productCount}</strong> products invalid, they
            </span>
        );
    const calloutMessage = (
        <span>
            Once you mark {countMessage} will be removed from the list and cannot be added to Sparks
        </span>
    );
    return (
        <>
            <CalloutMessage customIcon="alert" color="red" message={calloutMessage} />
            <div className="sub-message">
                You can undo this action by navigating to the Invalid tab and reactivating
            </div>
        </>
    );
};

interface BulkSetInvalidConfirmModalProps extends IDefaultModalProps {
    selectedBrandLinkId?: string;
    vendorAccountId?: string;
}

const BulkSetInvalidConfirmModal: FC<BulkSetInvalidConfirmModalProps> = ({
    selectedBrandLinkId = '',
    vendorAccountId = '',
    isVisible,
    onClose,
}) => {
    if (!selectedBrandLinkId) {
        return <></>;
    }

    const { isBulkInvalidating, bulkInvalidateProductsAsync } = useBulkInvalidateProducts(
        selectedBrandLinkId,
        vendorAccountId,
    );

    const { tableRows, tableSelected, tableUncheckAll } = useTableContext<
        HydratedPosProduct & { key: string }
    >();

    const selectedRows =
        tableRows?.filter((product) => {
            return tableSelected.includes(product.key);
        }) || [];

    const confirmBulkInvalidateProducts = async () => {
        if (isEmpty(selectedRows)) {
            onClose(false);
            return;
        }

        toast.promise(
            bulkInvalidateProductsAsync(
                {
                    productIds: selectedRows.map(({ _id }) => _id),
                },
                {
                    onSuccess: () => {
                        tableUncheckAll();
                        onClose(true);
                    },
                    onError: () => {
                        onClose(false);
                    },
                },
            ),
            {
                loading: 'Marking products invalid...',
                success: `${selectedRows.length} products marked invalid`,
                error: 'Error marking products invalid  - please try again and contact support if this issue persists.',
            },
        );
    };

    return (
        <ConfirmModal
            className="invalid-products-modal"
            title="Mark products invalid?"
            message={<MarkInvalidMessage productCount={selectedRows.length} />}
            isVisible={isVisible}
            onConfirm={confirmBulkInvalidateProducts}
            onClose={() => onClose(false)}
            confirmText="Mark Invalid"
            confirmBtnColor="red"
            disabled={isBulkInvalidating}
        />
    );
};

export default BulkSetInvalidConfirmModal;
