import { AccountLink } from '@sparkplug/lib';

import Chip, { TChipVariant } from '@components/chips/Chip';
import { CircleCheck } from '@components/icons';

import { TStandardColors } from '@app/types/UITypes';

const AccountLinkMappedBrands = ({ accountLink }: { accountLink: AccountLink }) => {
    const accountLinkDisabled = accountLink.status === 'disabled';

    return (
        <div className="chip-list account-link-mapped-brands">
            {(accountLink?.brandLinks || []).map((brandLink) => {
                const hasPosBrandsMapped = brandLink?.posBrands?.length;
                const circleCheckIcon = (
                    <CircleCheck className={accountLinkDisabled ? 'account-link-disabled' : ''} />
                );

                const chipColor = accountLinkDisabled || !hasPosBrandsMapped ? 'neutral' : 'green';
                const variant = accountLinkDisabled || hasPosBrandsMapped ? 'flat' : 'outlined';
                const avatar = hasPosBrandsMapped ? circleCheckIcon : undefined;

                return (
                    <div className="chip-list-item" key={brandLink?.brandId}>
                        <Chip
                            dense
                            color={chipColor as TStandardColors}
                            variant={variant as TChipVariant}
                            avatar={avatar}
                            label={brandLink?.name}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default AccountLinkMappedBrands;
