import { FC, useEffect, useMemo, useRef, useState } from 'react';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import {
    AddCircleOutline as AddIcon,
    RemoveCircleOutline as RemoveIcon,
    Search as SearchIcon,
} from '@components/icons';
import SearchSelect from '@components/inputs/SearchSelect';
import PosEmployeeProfileLocationsLabel from '@components/labels/PosEmployeeProfileLocationsLabel';
import PosEmployeeProfileNameLabel from '@components/labels/PosEmployeeProfileNameLabel';
import Table from '@components/table/Table';

import { appendClasses } from '@helpers/ui';

import { THeadCell } from '@app/types/TableTypes';
import { CondensedPosEmployeeProfile, IAccountUser } from '@app/types/UsersTypes';

import { IUpdatedUserData } from '../CreateEditAccountUserModal.types';

import './PosEmployeeProfilesTable.scss';

const AddPosEmployeeProfileOption = ({
    fullName,
    locationNames,
}: {
    fullName: string;
    locationNames?: string[];
}) => {
    return (
        <div className="add-pos-employee-profile-option">
            <PosEmployeeProfileNameLabel label={fullName} hideInfoIcon />
            <PosEmployeeProfileLocationsLabel locationNames={locationNames} />
        </div>
    );
};

type PosEmployeeProfileOption = IAccountUser['posEmployeeProfiles'][number];

const AddPosEmployeeProfileFooter = ({
    currentPosEmployeeProfiles = [],
    accountUsers,
    onAddPosEmployeeProfile,
}: {
    currentPosEmployeeProfiles: IAccountUser['posEmployeeProfiles'];
    accountUsers: IAccountUser[];
    onAddPosEmployeeProfile: (option: PosEmployeeProfileOption) => void;
}) => {
    const currentPosEmployeeProfileIds = currentPosEmployeeProfiles.map(({ _id }) => _id);
    const [showSearchSelectField, setShowSearchSelectField] = useState(false);

    const posEmployeeProfileOptions = useMemo(
        () =>
            accountUsers
                .filter(
                    ({ posEmployeeProfileIds, userId }) =>
                        !userId &&
                        posEmployeeProfileIds?.[0] &&
                        !currentPosEmployeeProfileIds.includes(posEmployeeProfileIds?.[0]),
                )
                .map(({ posEmployeeProfiles }) => ({
                    ...posEmployeeProfiles[0],
                    label: posEmployeeProfiles[0]?.fullName ?? '',
                    value: posEmployeeProfiles[0]?._id ?? '',
                })),
        [accountUsers],
    );

    const classNamesAppended = appendClasses([
        'add-pos-employee-profile-footer',
        showSearchSelectField ? 'show-search' : '',
    ]);

    const onSelect = (option: PosEmployeeProfileOption) => {
        setShowSearchSelectField(false);
        onAddPosEmployeeProfile(option);
    };

    return (
        <Table.Row className={classNamesAppended}>
            <Table.Cell colSpan={3}>
                {!showSearchSelectField ? (
                    <Button startIcon={<AddIcon />} onClick={() => setShowSearchSelectField(true)}>
                        Add Profile
                    </Button>
                ) : (
                    <SearchSelect
                        startIcon={<SearchIcon />}
                        options={posEmployeeProfileOptions}
                        placeholder="Search unenrolled profiles..."
                        optionLabelComponent={AddPosEmployeeProfileOption}
                        value=""
                        onChange={onSelect}
                    />
                )}
            </Table.Cell>
        </Table.Row>
    );
};

interface PosEmployeeProfilesTableProps {
    user: IAccountUser;
    accountUsers: IAccountUser[];
    updateUserData: (newUserData: Partial<IUpdatedUserData>) => void;
}

const PosEmployeeProfilesTable: FC<PosEmployeeProfilesTableProps> = ({
    user,
    accountUsers,
    updateUserData,
}) => {
    const didUpdate = useRef(false);
    const [employeeProfiles, setEmployeeProfiles] = useState(
        user.posEmployeeProfiles?.map((posEmployeeProfile) => ({
            ...posEmployeeProfile,
            key: posEmployeeProfile._id,
        })),
    );

    useEffect(() => {
        if (didUpdate.current) {
            updateUserData({
                posEmployeeProfileIds: employeeProfiles?.map(({ _id }) => _id),
            });
        }
    }, [employeeProfiles]);

    const onAddPosEmployeeProfile = (option: PosEmployeeProfileOption) => {
        didUpdate.current = true;
        setEmployeeProfiles((prevValue = []) => [
            ...prevValue,
            {
                ...option,
                key: option._id,
            },
        ]);
    };

    const onRemovePosEmployeeProfile = (_id: string) => {
        didUpdate.current = true;
        setEmployeeProfiles((prevValue) =>
            prevValue.filter((posEmployeeProfile) => posEmployeeProfile._id !== _id),
        );
    };

    const headCells: THeadCell<CondensedPosEmployeeProfile>[] = [
        {
            id: 'fullName',
            label: 'Employee Name',
            render: ({ fullName }) => (
                <Table.Cell>
                    <PosEmployeeProfileNameLabel label={fullName} hideInfoIcon />
                </Table.Cell>
            ),
        },
        {
            id: 'locations',
            label: 'Locations',
            render: ({ locationNames }) => (
                <Table.Cell>
                    <PosEmployeeProfileLocationsLabel locationNames={locationNames ?? []} />
                </Table.Cell>
            ),
        },
        {
            id: 'actions',
            render: ({ _id }) => (
                <Table.Cell>
                    <IconButton color="blue" onClick={() => onRemovePosEmployeeProfile(_id)}>
                        <RemoveIcon />
                    </IconButton>
                </Table.Cell>
            ),
        },
    ];

    return (
        <Table
            id="pos-employee-profiles-table"
            showPagination={false}
            rows={employeeProfiles ?? []}
            headCells={headCells}
            variant="raised"
            footerComponent={
                <AddPosEmployeeProfileFooter
                    currentPosEmployeeProfiles={employeeProfiles}
                    accountUsers={accountUsers}
                    onAddPosEmployeeProfile={onAddPosEmployeeProfile}
                />
            }
        >
            <Table.RenderHead />
            <Table.RenderBody />
        </Table>
    );
};

export default PosEmployeeProfilesTable;
