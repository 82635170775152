import { CSSProperties, ReactNode } from 'react';

import { GridSize, Grid as MaterialUIGrid } from '@mui/material';

import { appendClasses } from '@helpers/ui';

import './Grid.scss';

interface GridProps {
    className?: string;
    direction?: 'row' | 'column';
    justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around';
    children: ReactNode;
}

const Grid = ({ className, children, ...otherProps }: GridProps) => {
    const classNameAppended = appendClasses([className, 'grid']);

    return (
        <MaterialUIGrid spacing={2} container className={classNameAppended} {...otherProps}>
            {children}
        </MaterialUIGrid>
    );
};

interface GridItemProps {
    className?: string;
    xs?: GridSize;
    sm?: GridSize;
    md?: GridSize;
    lg?: GridSize;
    style?: CSSProperties;
    children: ReactNode;
}

Grid.Item = ({ className, children, xs = 12, ...otherProps }: GridItemProps) => {
    const classNameAppended = appendClasses([className, 'grid-item']);

    return (
        <MaterialUIGrid item className={classNameAppended} {...otherProps} xs={xs}>
            {children}
        </MaterialUIGrid>
    );
};

export default Grid;
