import Checkbox from '@components/inputs/Checkbox';
import Tooltip from '@components/layout/Tooltip';

import TableCell from '../TableCell';
import TableDropdownSortHeadCell from '../TableDropdownSortHeadCell';
import TableLoading from '../TableLoading';
import TableRenderBody from '../TableRenderBody';
import { TableBody } from '../TableRenderBody/TableRenderBody';
import TableRenderHead from '../TableRenderHead';
import { TableHead } from '../TableRenderHead/TableRenderHead';
import { TableRow } from '../TableRenderRow/TableRenderRow';
import Table from './Table';

export type { TableBulkAction } from '../TableRenderHead/TableRenderHead';

export default Object.assign(Table, {
    Cell: TableCell,
    DropdownSortHeadCell: TableDropdownSortHeadCell,
    Tooltip,
    Checkbox,
    RenderBody: TableRenderBody,
    RenderHead: TableRenderHead,

    /**
     * @deprecated using `Table.Body` directly will be deprecated. Refactor to use `Table.RenderBody`
     */
    Body: TableBody,
    /**
     * @deprecated using `Table.Head` directly will be deprecated. Refactor to use `Table.RenderHead`
     */
    Head: TableHead,
    /**
     * @deprecated using `Table.Loading` directly will be deprecated. Refactor to use `Table.RenderBody`
     * and the `isLoading` prop for `<Table />` (or `<TableProvider />` if using `<Table useExternalProvider />`)
     */
    Loading: TableLoading,
    /**
     * @deprecated using `Table.Row` directly will be deprecated. Refactor to use `Table.RenderBody` and `THeadCell.render`
     */
    Row: TableRow,
});
