import { FC } from 'react';

import { MuiInfoIcon } from '@components/icons';
import Tooltip from '@components/layout/Tooltip';

import './Divider.scss';

interface DividerProps {
    text?: string;
    tooltipText?: string;
    toolTipPlacement?: 'top' | 'bottom';
    variant?: 'default' | 'warning' | 'error';
}

const Divider: FC<DividerProps> = ({
    text,
    tooltipText,
    variant = 'default',
    toolTipPlacement = 'top',
}) => {
    return (
        <div className={`divider divider-variant-${variant}`}>
            <div className="divider-line" />
            {text && (
                <Tooltip title={tooltipText || ''} placement={toolTipPlacement}>
                    <div className="divider-text">
                        {tooltipText && <MuiInfoIcon className="divider-icon" />}
                        {text}
                    </div>
                </Tooltip>
            )}
            <div className="divider-line" />
        </div>
    );
};

export default Divider;
