import { forwardRef, useImperativeHandle, useState } from 'react';

import { RestrictedWords } from '@constants/AppConstants';

import InfoModal from '@components/overlays/InfoModal';

const _findRestrictedWords = (str: string): string[] => {
    const words = str.toLowerCase();
    return RestrictedWords.filter((word) => words.includes(word));
};

const RestrictedWordsModal = forwardRef((props, ref) => {
    const [foundRestrictedWords, setFoundRestrictedWords] = useState<string[]>([]);

    useImperativeHandle(ref, () => ({
        containsRestrictedWords(str: string): boolean {
            const foundWords = _findRestrictedWords(str);
            if (foundWords.length > 0) {
                setFoundRestrictedWords(foundWords);
                return false;
            }
            return true;
        },
    }));

    return (
        <InfoModal
            title="Restricted Words"
            isVisible={foundRestrictedWords.length > 0}
            onClose={() => setFoundRestrictedWords([])}
        >
            <p>
                Cell phone carriers often block SMS texts that contain the below words. It looks
                like you’ve used one of these words in your Spark. Please change the language in
                order to ensure employees receive their Spark Updates.
            </p>
            <p>All restricted words:</p>
            <ul className="two-column-list">
                {RestrictedWords.sort((a, b) => {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                }).map((word, i) => (
                    <li key={i}>
                        {foundRestrictedWords.includes(word) ? (
                            <strong className="error-text">{word}</strong>
                        ) : (
                            <span>{word}</span>
                        )}
                    </li>
                ))}
            </ul>
        </InfoModal>
    );
});

export default RestrictedWordsModal;
