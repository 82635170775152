import { FC } from 'react';

import { SparkPlugUserRoles } from '@constants/UserConstants';

import { IPublicUser, UserRole } from '@sparkplug/lib';

import { useSaveUserProfileMutation } from '@core/users';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import Modal, { IDefaultModalProps } from '@components/overlays/Modal';

interface UpdatedUserFormData {
    firstName: string;
    lastName: string;
    email: string;
    role: UserRole;
}

interface CreateEditSparkplugUserModalProps extends IDefaultModalProps {
    user?: IPublicUser;
}

const CreateEditSparkplugUserModal: FC<CreateEditSparkplugUserModalProps> = ({
    isVisible,
    user,
    onClose,
}) => {
    const { saveUserProfile } = useSaveUserProfileMutation({});

    const roleOptions = user
        ? SparkPlugUserRoles
        : SparkPlugUserRoles.filter((r) => r.value === 'super-admin');

    const onSave = async (payload: UpdatedUserFormData) => {
        saveUserProfile(
            { userParams: payload, userId: user?._id },
            { onSuccess: () => onClose(true) },
        );
    };

    const emailIsRequired = !user || !!user?.email;

    return (
        <Modal
            className="create-edit-user-modal"
            isVisible={isVisible}
            onClose={() => onClose(false)}
        >
            <Form>
                <Modal.Title onClose={() => onClose(false)}>
                    {user != null ? 'Edit Existing User' : 'Create New User'}
                </Modal.Title>

                <Modal.Content>
                    <Form.TextField
                        label="First Name"
                        name="firstName"
                        required
                        defaultValue={user?.firstName}
                    />
                    <Form.TextField
                        label="Last Name"
                        name="lastName"
                        required
                        defaultValue={user?.lastName}
                    />
                    <Form.TextField
                        label="Email"
                        name="email"
                        required={emailIsRequired}
                        defaultValue={user?.email}
                    />
                    <Form.Select
                        required
                        label="Type"
                        name="role"
                        defaultValue={user?.role ?? 'super-admin'}
                        options={roleOptions}
                    />
                </Modal.Content>

                <Modal.Actions>
                    <Button color="neutral" variant="flat" onClick={() => onClose(false)}>
                        Cancel
                    </Button>
                    <Form.Button color="blue" variant="flat" disabled={false} onClick={onSave}>
                        {!user ? 'Send Invite' : 'Save'}
                    </Form.Button>
                </Modal.Actions>
            </Form>
        </Modal>
    );
};

export default CreateEditSparkplugUserModal;
