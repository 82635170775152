import { FC } from 'react';

import { formatCurrency } from '@sparkplug/lib';

import './CashDiffLabel.scss';

interface CashDiffLabelProps {
    type: 'deposit' | 'withdrawal';
    amount: number;
}
export const CashDiffLabel: FC<CashDiffLabelProps> = ({ type, amount }) => {
    const amountPrefix = type === 'deposit' ? '+' : '-';
    const amountLabel = `${amountPrefix}${formatCurrency(amount / 100, true)}`;
    return <span className={`cash-diff-label cash-diff-label_${type}`}>{amountLabel}</span>;
};
