import { FC, PropsWithChildren, useMemo } from 'react';

import {
    TableCell as MaterialUITableCell,
    TableHead as MaterialUITableHead,
    TableRow as MaterialUITableRow,
    TableSortLabel as MaterialUITableSortLabel,
} from '@mui/material';

import Button, { ButtonColor } from '@components/buttons/Button';
import InfoTooltip from '@components/icons/InfoTooltip';
import Checkbox from '@components/inputs/Checkbox';

import { useTableContext } from '@hooks/TableHooks';

import { appendClasses } from '@helpers/ui';
import { isEmpty } from '@helpers/util';

import { THeadCell, TTableOrder } from '@app/types/TableTypes';

import { ResizeWidget } from './ResizeWidget';

import './TableRenderHead.scss';

interface ITableHeadCell<T> {
    headCell: THeadCell<T>;
    active: boolean;
    direction: TTableOrder;
    enableColumnResizing?: boolean;
    onChangeOrderBy: (v: string) => void;
}

const TableHeadCell = <T extends {}>({
    headCell,
    active,
    direction,
    onChangeOrderBy,
    enableColumnResizing = false,
    children,
}: PropsWithChildren<ITableHeadCell<T>>) => {
    const stickyClasses = appendClasses([
        headCell.sticky === 'left' ? 'sticky-left' : '',
        headCell.sticky === 'right' ? 'sticky-right' : '',
    ]);

    if (!isEmpty(children)) {
        return (
            <MaterialUITableCell className={stickyClasses} key={`head-cell-${headCell.id}`}>
                <div className="head-cell-wrapper">{children}</div>
            </MaterialUITableCell>
        );
    }

    const { label: Label = () => <></> } = headCell;
    const labelIsString = typeof Label === 'string';

    const LabelComponent = (
        <>
            {labelIsString ? <>{Label}</> : <Label />}
            {headCell.infoAfter && (
                <InfoTooltip className="info-icon-after" info={headCell.infoAfter} />
            )}
        </>
    );

    return (
        <MaterialUITableCell className={stickyClasses} key={`head-cell-${headCell.id}`}>
            <div className="head-cell-wrapper">
                {headCell.label && (
                    <>
                        {headCell.info && (
                            <InfoTooltip className="info-icon-before" info={headCell.info} />
                        )}

                        {headCell.sortType ? (
                            <MaterialUITableSortLabel
                                active={active}
                                direction={direction}
                                onClick={() => {
                                    onChangeOrderBy(headCell.id);
                                }}
                            >
                                {LabelComponent}
                            </MaterialUITableSortLabel>
                        ) : (
                            <span>{LabelComponent}</span>
                        )}
                    </>
                )}

                {headCell.component && <headCell.component />}

                {enableColumnResizing && !headCell.hideResize && <ResizeWidget />}
            </div>
        </MaterialUITableCell>
    );
};

export type TableBulkAction =
    | {
          startIcon?: any;
          label?: string;
          buttonColor?: ButtonColor;
          onClick?: any;
          shouldRender?: (selectedIds: string[]) => boolean;
      }
    | FC<{ selectedIds: string[] }>;

const TableHeadBulkActions = ({
    bulkActions,
    tableSelected,
}: {
    bulkActions: TableBulkAction[];
    tableSelected: string[];
}) => {
    return (
        <>
            {(bulkActions || []).map((bulkAction: TableBulkAction, i) => {
                if (typeof bulkAction === 'function') {
                    const CustomComponent = bulkAction;
                    return <CustomComponent key={i} selectedIds={tableSelected} />;
                }

                if (typeof bulkAction?.shouldRender === 'function') {
                    const isValidRender = bulkAction.shouldRender(tableSelected);
                    if (!isValidRender) {
                        return null;
                    }
                }

                return (
                    <Button
                        color={bulkAction.buttonColor}
                        variant="raised"
                        key={bulkAction.label}
                        startIcon={bulkAction.startIcon}
                        onClick={() => bulkAction?.onClick?.(tableSelected)}
                    >
                        {bulkAction?.label}
                    </Button>
                );
            })}
        </>
    );
};

interface TableHeadProps {
    showMenuButton?: boolean;
    showCheckboxes?: boolean;
    bulkActions?: TableBulkAction[] | null;
}

export const TableHead: FC<TableHeadProps> = ({
    showMenuButton = false,
    showCheckboxes = false,
    bulkActions = [],
}) => {
    const {
        tableFilteredRows,
        tableSelected,
        tableSetSelected,
        tableOrder,
        tableOrderBy,
        tableChangeOrderBy,
        tableHeadData,
        enableColumnResizing,
    } = useTableContext();

    // Keys of all the filtered rows that do not have selection disabled
    const selectableRowKeys = useMemo(
        () =>
            tableFilteredRows
                .filter((row) => row?.selectionDisabled !== true)
                .map(({ key }) => key),
        [tableFilteredRows],
    );

    // Keys of all the filtered rows that are selected
    const selectedFilteredRowKeys = useMemo(
        () => selectableRowKeys.filter((key) => tableSelected.includes(key)),
        [selectableRowKeys, tableSelected],
    );

    const allFilteredRowsAreSelected = useMemo(
        () =>
            selectableRowKeys.length > 0 &&
            selectedFilteredRowKeys.length === selectableRowKeys.length,
        [selectableRowKeys, tableSelected],
    );

    const onCheckUncheckAll = () => {
        if (selectedFilteredRowKeys.length === 0) {
            // Add all filtered row keys
            tableSetSelected([...new Set([...tableSelected, ...selectableRowKeys])]);
        } else {
            // Remove any selected row keys
            tableSetSelected(tableSelected.filter((key) => !selectedFilteredRowKeys.includes(key)));
        }
    };

    const isBulkEditMode = !isEmpty(bulkActions) && tableSelected?.length;

    const filteredHeadData = useMemo(() => {
        return tableHeadData.filter((headCell) => {
            return headCell?.type !== 'checkbox';
        });
    }, [tableHeadData, showCheckboxes]);

    const indeterminate = useMemo(
        () => !allFilteredRowsAreSelected && selectedFilteredRowKeys.length > 0,
        [allFilteredRowsAreSelected, selectedFilteredRowKeys.length],
    );

    return (
        <>
            {!isBulkEditMode ? (
                <MaterialUITableHead>
                    <MaterialUITableRow>
                        {showCheckboxes && (
                            <MaterialUITableCell>
                                <Checkbox
                                    name="tableSelectAll"
                                    indeterminate={indeterminate}
                                    value={allFilteredRowsAreSelected}
                                    onChange={onCheckUncheckAll}
                                />
                            </MaterialUITableCell>
                        )}

                        {filteredHeadData.map(
                            (headCell) =>
                                !headCell.isHidden && (
                                    <TableHeadCell
                                        key={headCell?.id}
                                        enableColumnResizing={enableColumnResizing}
                                        headCell={headCell}
                                        active={tableOrderBy === headCell.id}
                                        direction={
                                            tableOrderBy === headCell.id ? tableOrder : 'asc'
                                        }
                                        onChangeOrderBy={tableChangeOrderBy}
                                    />
                                ),
                        )}

                        {showMenuButton && <MaterialUITableCell />}
                    </MaterialUITableRow>
                </MaterialUITableHead>
            ) : (
                <MaterialUITableHead>
                    <MaterialUITableRow>
                        <MaterialUITableCell
                            colSpan={filteredHeadData.length + (showCheckboxes ? 1 : 0)}
                        >
                            <section className="table-head_bulk-actions">
                                {showCheckboxes && (
                                    <Checkbox
                                        name="tableSelectAll"
                                        value={allFilteredRowsAreSelected}
                                        onChange={onCheckUncheckAll}
                                    />
                                )}
                                <TableHeadBulkActions
                                    bulkActions={bulkActions ?? []}
                                    tableSelected={tableSelected}
                                />
                            </section>
                        </MaterialUITableCell>

                        {showMenuButton && <MaterialUITableCell />}
                    </MaterialUITableRow>
                </MaterialUITableHead>
            )}
        </>
    );
};

interface TableRenderHeadProps {
    bulkActions?: TableBulkAction[];
}

const TableRenderHead = ({ bulkActions }: TableRenderHeadProps) => {
    const { tableShowCheckboxes, tableShowRowActions } = useTableContext();

    return (
        <TableHead
            showCheckboxes={tableShowCheckboxes}
            showMenuButton={tableShowRowActions}
            bulkActions={bulkActions}
        />
    );
};

export default TableRenderHead;
