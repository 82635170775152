import List from '@components/layout/List';
import InfoModal from '@components/overlays/InfoModal';

export type TMoreDataTitles = 'Locations' | 'Employees' | 'Products' | 'Managers' | null;

interface IMoreDataModalProps {
    isVisible: boolean;
    data: string[] | any;
    onClose: (any: any) => any;
    title?: TMoreDataTitles;
}

const MoreDataModal = ({ isVisible, data, title, onClose }: IMoreDataModalProps) => {
    const isList = Array.isArray(data) && data.length > 0;

    if (!data || !title || !isList) {
        return null;
    }

    return (
        <InfoModal
            className="spark-details-content-modal wide"
            isVisible={isVisible}
            title={title}
            onClose={onClose}
        >
            {isList && (
                <List>
                    {data.map((item, i) => (
                        <List.Item key={i}>{item}</List.Item>
                    ))}
                </List>
            )}
        </InfoModal>
    );
};

export default MoreDataModal;
