import { FC, ReactElement } from 'react';

import { sum } from 'lodash';
import pluralize from 'pluralize';

import { DetailedSparkType, MultiRetailerSparkParticipantGroup } from '@sparkplug/lib';

const commissionMessage = (numberOfRetailers: number) => (
    <>
        In a <strong>Commission</strong>, across the Retailers selected,{' '}
        <strong>
            {`${numberOfRetailers} `}
            {pluralize('Spark', numberOfRetailers)} will be created
        </strong>
        , one for each of the selected Retailers.
    </>
);

const SparkCalloutMessages: Partial<
    Record<DetailedSparkType, FC<{ numberOfRetailers: number; numberOfLocations?: number }>>
> = {
    leaderboard: ({ numberOfRetailers }) => (
        <>
            In a <strong>Single Leaderboard</strong>, across the Retailers selected,{' '}
            <strong>
                {`${numberOfRetailers} `}
                {pluralize('Spark', numberOfRetailers)} will be created
            </strong>
            , one for each of the selected Retailers.
        </>
    ),
    leaderboardMulti: ({ numberOfLocations }) => (
        <>
            In a <strong>Multi-Leaderboard</strong>, across the Retailers selected,{' '}
            <strong>
                {`${numberOfLocations} `}
                {pluralize('Spark', numberOfLocations)} will be created
            </strong>
            , one for each of the selected Locations.
        </>
    ),
    leaderboardLocation: ({ numberOfRetailers }) => (
        <>
            In a <strong>Location Leaderboard</strong>, across the Retailers selected,{' '}
            <strong>
                {`${numberOfRetailers} `}
                {pluralize('Spark', numberOfRetailers)} will be created
            </strong>
            , one for each of the selected Retailers.
        </>
    ),
    goal: ({ numberOfRetailers }) => (
        <>
            In an <strong>Individual Goal</strong>, across the Retailers selected,{' '}
            <strong>
                {`${numberOfRetailers} `}
                {pluralize('Spark', numberOfRetailers)} will be created
            </strong>
            , one for each of the selected Retailers.
        </>
    ),
    goalTeam: ({ numberOfRetailers }) => (
        <>
            In a <strong>Team Goal</strong>, all selected locations combine to form a single team,{' '}
            <strong>
                {`${numberOfRetailers} `}
                {pluralize('Spark', numberOfRetailers)} will be created
            </strong>{' '}
            for each selected retailer.
        </>
    ),
    goalManager: ({ numberOfRetailers }) => (
        <>
            In a <strong>Manager Goal</strong>, all selected locations combine to form a single
            team,{' '}
            <strong>
                {`${numberOfRetailers} `}
                {pluralize('Spark', numberOfRetailers)} will be created
            </strong>{' '}
            for each selected Retailer.
        </>
    ),
    commissionFlat: ({ numberOfRetailers }) => commissionMessage(numberOfRetailers),
    commissionPercentage: ({ numberOfRetailers }) => commissionMessage(numberOfRetailers),
};

export const renderSparkCountMessage = (
    detailedSparkType: DetailedSparkType,
    numberOfRetailers: number,
    numberOfLocations?: number,
): ReactElement => {
    const Component = SparkCalloutMessages[detailedSparkType];
    return Component ? (
        <Component numberOfRetailers={numberOfRetailers} numberOfLocations={numberOfLocations} />
    ) : (
        <></>
    );
};

export const countRetailersAndLocations = (
    multiRetailerParticipantGroups: MultiRetailerSparkParticipantGroup[],
) => ({
    // move this to helper
    numberOfRetailers: multiRetailerParticipantGroups?.length ?? 0,
    numberOfLocations: multiRetailerParticipantGroups?.reduce((res, { participantGroups }) => {
        return res + sum(participantGroups.flatMap(({ locationIds }) => locationIds.length));
    }, 0),
});
