import { ReactElement } from 'react';

import { Box } from '@mui/material';

import { DO_NOT_HAVE_PERMISSIONS_MESSAGE, UIRequestForSpark } from '@sparkplug/lib';

import { UIRequestForSparkWithAccountLink } from '@features/request-for-spark/types';

import Button from '@components/buttons/Button';
// TODO follow the pattern of saving static images
import { SparkNotFound } from '@components/graphics';
import { Info as InfoIcon } from '@components/icons';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import Skeleton from '@components/layout/Skeleton';
import Tooltip from '@components/layout/Tooltip';
import Modal from '@components/overlays/Modal';

import { cn } from '@app/componentLibrary/utils';

import { RequestDetailsCallout } from '../RequestDetailsCallout';
import { RequestForSparkAccountLabel } from '../RequestForSparkAccountLabel';

const WhatDoesIgnoreDoCallout = ({ className }: { className?: string }) => (
    <Tooltip title="Feel free to ignore if the Spark request is not a good fit at this time. We do NOT let the Retailer know you’ve ignored. You can always accept this request at a later date after ignoring.">
        <div className={cn('flex items-center justify-center gap-[4px]', className)}>
            <span className="text-[12px] font-normal leading-[16px] tracking-[0.0175em] text-gray-900">
                What does ignore do?
            </span>
            <InfoIcon className="!w-[12px] !h-[12px] text-gray-600" />
        </div>
    </Tooltip>
);

export interface RespondToRequestModalProps {
    isVisible: boolean;
    requestForSpark?: UIRequestForSparkWithAccountLink;
    requestsForSparksAreReady: boolean;
    userIsPermitted: boolean;
    onViewOpenRequests: () => void;
    onAcceptAndCreate: (requestForSpark: UIRequestForSpark) => void;
    onIgnore: (requestForSpark: UIRequestForSpark) => void;
    onClose: (actionOnClose: boolean) => void;
}
export const RespondToRequestModal = ({
    isVisible,
    requestForSpark,
    requestsForSparksAreReady = false,
    userIsPermitted,
    onViewOpenRequests,
    onAcceptAndCreate,
    onIgnore,
    onClose,
}: RespondToRequestModalProps) => {
    let fallbackDisplay: ReactElement | undefined;

    const isLoading = !requestsForSparksAreReady;
    if (isLoading) {
        fallbackDisplay = (
            <>
                <Modal.Content>
                    <Skeleton width={480} height={205} />
                </Modal.Content>
                <Modal.Actions>
                    <></>
                </Modal.Actions>
            </>
        );
    }

    const requestNotFound = requestsForSparksAreReady && !requestForSpark;
    if (requestNotFound) {
        fallbackDisplay = (
            <>
                <Modal.Content>
                    <Box sx={{ width: '480px', margin: '0 auto' }}>
                        <EmptyStateDisplay
                            graphic={<SparkNotFound />}
                            label="Request not found"
                            smallText="We’re sorry, the Request you’re looking for was deleted or does not exist."
                        />
                    </Box>
                </Modal.Content>
                <Modal.Actions sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={onViewOpenRequests} color="blue" variant="filled">
                        View Open Requests
                    </Button>
                </Modal.Actions>
            </>
        );
    }

    return (
        <Modal isVisible={isVisible} onClose={onClose}>
            <Modal.Title onClose={onClose}>Spark Request</Modal.Title>
            {fallbackDisplay || (
                <>
                    <Modal.Content
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '16px',
                            width: '520px',
                        }}
                    >
                        {!!requestForSpark!.accountLink && (
                            <RequestForSparkAccountLabel
                                accountLink={requestForSpark!.accountLink}
                            />
                        )}
                        <RequestDetailsCallout requestForSpark={requestForSpark!} />
                    </Modal.Content>
                    <Modal.Actions>
                        {requestForSpark!.status !== 'ignored' && (
                            <>
                                <WhatDoesIgnoreDoCallout className="mr-auto" />
                                <Button
                                    color="neutral"
                                    variant="flat"
                                    disabled={!userIsPermitted}
                                    tooltip={
                                        !userIsPermitted
                                            ? DO_NOT_HAVE_PERMISSIONS_MESSAGE
                                            : undefined
                                    }
                                    onClick={() => {
                                        onIgnore(requestForSpark!);
                                        onClose(false);
                                    }}
                                >
                                    Ignore
                                </Button>
                            </>
                        )}
                        <Button
                            color="blue"
                            variant="smooth"
                            disabled={!userIsPermitted}
                            tooltip={!userIsPermitted ? DO_NOT_HAVE_PERMISSIONS_MESSAGE : undefined}
                            onClick={() => onAcceptAndCreate(requestForSpark!)}
                        >
                            Accept and Create
                        </Button>
                    </Modal.Actions>
                </>
            )}
        </Modal>
    );
};
