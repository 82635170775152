import { FC, useEffect, useState } from 'react';

import Dropdown from '@components/dropdown/Dropdown';
import Checkbox from '@components/inputs/Checkbox';
import { TooltipProps } from '@components/layout/Tooltip';

import { appendClasses } from '@helpers/ui';

import { useIsInToolbar } from '../Toolbar/useIsInToolbar';
import ToolbarGroup from '../ToolbarGroup';

import './ToolbarMultiSelectDropdown.scss';

export type MultiSelectDropdownOptions<T = {}> = {
    label: string;
    value: string;
    disabled?: boolean;
    tooltipProps?: Omit<TooltipProps, 'children'>;
} & T;

interface ToolbarMultiSelectDropdownProps<T> {
    className?: string;
    label: string;
    primaryOptions?: MultiSelectDropdownOptions<T>[];
    options: MultiSelectDropdownOptions<T>[];
    selected: string[];
    onApply: (value: string[]) => void;
    color?: 'neutral' | 'blue' | 'green' | 'red';
    variant?: 'flat' | 'filled' | 'raised' | 'smooth' | 'outlined';
    disabled?: boolean;
    toggleDisabled?: boolean;
    onToggle?: (enabled: boolean) => void;
    highlight?: boolean;
    applyOnClose?: boolean;
    clear?: {
        active: boolean;
        onClear: () => void;
    };
    MenuItemWrapper?: FC<{ option: MultiSelectDropdownOptions<T> }>;
}

export const ToolbarMultiSelectDropdown = <T extends unknown>({
    className,
    label,
    color = 'blue',
    disabled = false,
    variant = 'flat',
    options,
    selected,
    onApply,
    toggleDisabled = false,
    onToggle,
    highlight = false,
    applyOnClose = false,
    clear,
    MenuItemWrapper,
    primaryOptions,
}: ToolbarMultiSelectDropdownProps<T>) => {
    useIsInToolbar({ componentName: 'Toolbar.Dropdown' });
    const [internalValue, setInternalValue] = useState<{ [value: string]: boolean }>({});

    const selectedValues = Object.entries(internalValue)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([value, isSelected]) => isSelected)
        .map(([value]) => value);
    const selectedCount = selectedValues.length;

    useEffect(() => {
        setInternalValue(Object.fromEntries(selected.map((value) => [value, true])));
    }, [selected]);

    const classNameAppended = appendClasses([
        className,
        'toolbar-multi-select-dropdown',
        onToggle ? 'has-checkbox' : undefined,
        toggleDisabled ? 'disabled' : undefined,
        highlight ? 'highlighted' : undefined,
    ]);

    const clearConfig = clear || {
        active: !!selectedCount,
        onClear: () => {
            onApply([]);
        },
    };

    const titleText = clearConfig.active ? `(${selected.length}) ${label}` : label;

    const onSelect = (value: string) => {
        const newSelectedValues = {
            ...internalValue,
            [value]: !internalValue[value],
        };
        setInternalValue(newSelectedValues);

        if (!applyOnClose) {
            onApply(
                Object.entries(newSelectedValues)
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .filter(([selectedValue, isSelected]) => isSelected)
                    .map(([selectedValue]) => selectedValue),
            );
        }
    };

    const onClose = () => {
        onApply(selectedValues);
    };

    return (
        <ToolbarGroup className={classNameAppended}>
            <Dropdown color={color} variant={variant} disabled={disabled} onClose={onClose}>
                <Dropdown.Button clear={clearConfig}>{titleText}</Dropdown.Button>
                <Dropdown.Menu className="toolbar-multi-select-dropdown_menu">
                    {primaryOptions &&
                        primaryOptions.map((option, i) => (
                            <Dropdown.MenuItem
                                StartIcon={() => <Checkbox value={internalValue[option.value]} />}
                                key={`dropdown-menu-item-${i}-${option.value}-${option.label}`}
                                disabled={option.disabled}
                                onClick={() => {
                                    onSelect(option.value);
                                }}
                                tooltipProps={option.tooltipProps}
                                disableCloseOnClick
                            >
                                {MenuItemWrapper ? (
                                    <MenuItemWrapper option={option} />
                                ) : (
                                    option.label
                                )}
                            </Dropdown.MenuItem>
                        ))}
                    {primaryOptions && <Dropdown.MenuDivider />}
                    {options.map((option, i) => (
                        <Dropdown.MenuItem
                            StartIcon={() => <Checkbox value={internalValue[option.value]} />}
                            key={`dropdown-menu-item-${i}-${option.value}-${option.label}`}
                            disabled={option.disabled}
                            onClick={() => {
                                onSelect(option.value);
                            }}
                            tooltipProps={option.tooltipProps}
                            disableCloseOnClick
                        >
                            {MenuItemWrapper ? <MenuItemWrapper option={option} /> : option.label}
                        </Dropdown.MenuItem>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </ToolbarGroup>
    );
};
export default ToolbarMultiSelectDropdown;
