import { useEffect } from 'react';

import LocalStorage from '@data/LocalStorage';
import AdminEnrollmentPoster from '@views/admin/AdminEnrollmentPoster';
import OAuthRedirect from '@views/admin/OAuthRedirect';
import AuthCodeView from '@views/auth/AuthCodeView';
import EmployeeLoginView from '@views/auth/EmployeeLoginView';
import ForgotPasswordView from '@views/auth/ForgotPasswordView';
import LoginView from '@views/auth/LoginView';
import SelfEnrollView from '@views/auth/SelfEnrollView';
import SetupCompleteView from '@views/auth/SetupCompleteView';
import SetupPasswordView from '@views/auth/SetupPasswordView';
import TwoFactorAuthView from '@views/auth/TwoFactorAuthView';
import OauthResponseView from '@views/auth/oauth/OauthResponseView';
import OauthSuccessView from '@views/auth/oauth/OauthSuccessView';

import SparkQuickApproveView from '@features/spark-quick-approve/views';

import AppContent from '@components/layout/AppContent';
import AppHeader from '@components/layout/AppHeader';
import PageLoading from '@components/layout/PageLoading';
import { Redirect, Route, Router, Switch, useQueryParams } from '@components/router';

import { useApp } from '@hooks/AppHooks';
import { QueryParamProvider } from '@hooks/QueryParamsHooks';

import AccountRouter from './AccountRouter';
import ControlCenterRouter from './ControlCenterRouter';
import UserRouter from './UserRouter';
import VersionEnforcer from './VersionEnforcer';

const AccountIdKey = 'sparkplug::accountId';

const PageLoadingView = () => {
    const { externalUrl } = useQueryParams();

    useEffect(() => {
        if (externalUrl != null) {
            setTimeout(() => {
                window.location.href = externalUrl;
            }, 1500);
        }
    }, []);

    return <PageLoading label="Loading page..." />;
};

const RootView = () => {
    const { appIsReady, user, isAdminApp, userLoadingStatus, logOut } = useApp();

    const storedAccountId = LocalStorage.get(AccountIdKey);

    if (userLoadingStatus === 'error') {
        logOut().then(() => {
            return <Redirect to="/login" />;
        });
    }

    if (appIsReady) {
        // an admin CAN visit this route, but shouldn't be auto-redirected here on load
        if (
            !isAdminApp &&
            !['super-admin', 'brand-admin', 'retailer-admin'].includes(user?.role || '')
        ) {
            return <Redirect to="/user/sparks" />;
        }

        if (user?.accounts?.length) {
            return storedAccountId ? (
                <Redirect to={`/${storedAccountId}/dashboard`} />
            ) : (
                <Redirect to={`/${user?.accounts[0]._id}/dashboard`} />
            );
        }

        return <Redirect to="/login" />;
    }

    return <></>;
};

const AppRouter = () => {
    return (
        <Router>
            <VersionEnforcer />
            <AppHeader />
            <AppContent>
                <QueryParamProvider ReactRouterRoute={Route}>
                    <Switch>
                        <Route
                            path="/:sparkId/sparks/approve/:quickApprovalCode"
                            component={SparkQuickApproveView}
                        />
                        <Route path="/oauth-response" component={OauthResponseView} />
                        <Route
                            path="/oauth-success/:isNewUser?/:isNewStore?"
                            component={OauthSuccessView}
                        />
                        <Route path="/login/2fa" component={TwoFactorAuthView} />
                        <Route path="/login/setup/password" component={SetupPasswordView} />
                        <Route path="/login/setup/complete" component={SetupCompleteView} />
                        <Redirect path="/login/setup" to="/login/setup/password" />
                        <Route path="/login/forgot-password" component={ForgotPasswordView} />
                        <Route path="/login" component={LoginView} />
                        <Route path="/employee/login" component={EmployeeLoginView} />
                        <Route path="/a" exact component={AuthCodeView} />
                        <Route path="/auth" component={AuthCodeView} />
                        <Route path="/oauth/:provider" component={OAuthRedirect} />
                        <Route path="/redirect" component={PageLoadingView} />
                        <Route path="/join" component={SelfEnrollView} />
                        <Route path="/control-center" component={ControlCenterRouter} />
                        <Route path="/user" component={UserRouter} />
                        <Route
                            path="/:accountId/settings/employees/onboarding/poster"
                            component={AdminEnrollmentPoster}
                        />
                        <Route path="/:accountId" component={AccountRouter} />
                        <Route path="/" component={RootView} />
                    </Switch>
                </QueryParamProvider>
            </AppContent>
        </Router>
    );
};

export default AppRouter;
