import axios from 'axios';

import {
    ClaimRewardPathParams,
    ClaimRewardResponseBody,
    DeleteRewardPathParams,
    GetRewardPathParams,
    GetRewardResponseBody,
    ListCampaignsAndFundingSourcesResponseBody,
    ListCurrentUserRewardsQueryParams,
    ListCurrentUserRewardsResponseBody,
    ListRewardsQueryParams,
    ListRewardsResponseBody,
} from '@sparkplug/lib';

export default class RewardsAPI {
    static async getRewardById({ rewardId }: GetRewardPathParams): Promise<GetRewardResponseBody> {
        return (await axios.get(`/api/v1/rewards/${rewardId}`)).data;
    }

    static async getRewardPayoutParameters(): Promise<ListCampaignsAndFundingSourcesResponseBody> {
        return (await axios.get(`/api/v1/rewards/payout-confirm-parameters`)).data;
    }

    static async getRewards(params: ListRewardsQueryParams): Promise<ListRewardsResponseBody> {
        return (await axios.get('/api/v1/rewards', { params })).data;
    }

    static async getCurrentUserRewards({
        offset = 0,
        limit = 0,
    }: ListCurrentUserRewardsQueryParams): Promise<ListCurrentUserRewardsResponseBody> {
        return (await axios.get('/api/v1/rewards/my-rewards', { params: { offset, limit } })).data;
    }

    static async claimReward({
        rewardId,
    }: ClaimRewardPathParams): Promise<ClaimRewardResponseBody> {
        return (await axios.post(`/api/v1/rewards/${rewardId}/claim`, {})).data;
    }

    static async deleteReward({ rewardId }: DeleteRewardPathParams): Promise<void> {
        return axios.delete(`/api/v1/rewards/${rewardId}`);
    }
}
