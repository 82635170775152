import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import clsx from 'clsx';

import { THeadCell } from '@app/types/TableTypes';

import TableRenderCell from '../TableRenderCell';

interface ExpandableRowProps<T> {
    tableHeadData: THeadCell<T>[];
    row: T & { key: string; selectionDisabled?: boolean };
    collapse: (row: T & { key: string; selectionDisabled?: boolean }) => React.ReactElement;
    className?: string;
    onClick?: ((row: any, event: any) => {} | void) | undefined;
}

const ExpandableRow = <T extends {}>({
    tableHeadData,
    row,
    collapse,
    className,
    onClick,
}: ExpandableRowProps<T>) => {
    const [open, setOpen] = useState(false);

    const handleRowClick = (event: React.MouseEvent) => {
        setOpen(!open);
        if (onClick) {
            onClick(row, event);
        }
    };

    return (
        <>
            <TableRow className={clsx('[&>*]:border-b-0', className)} onClick={handleRowClick}>
                <TableCell padding="checkbox">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpen(!open);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {tableHeadData.map(
                    (headCell, i) =>
                        !headCell.isHidden && (
                            <TableRenderCell
                                key={i}
                                headCell={headCell}
                                row={row}
                                setRow={() => {}} // Placeholder, adjust as needed
                            />
                        ),
                )}
            </TableRow>
            <TableRow className="collapsable-table-row">
                <TableCell className="!py-0" colSpan={tableHeadData.length + 1}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box className="!py-0">{collapse(row)}</Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default ExpandableRow;
