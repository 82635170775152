import { FC } from 'react';

import Skeleton from '@components/layout/Skeleton';

import TableCell from '../TableCell';
import { TableRow } from '../TableRenderRow/TableRenderRow';

import './TableLoading.scss';

interface TableSkeletonRowProps {
    colSpan: number;
}

const TableSkeletonRow: FC<TableSkeletonRowProps> = ({ colSpan }) => {
    return (
        <TableRow tabIndex={-1}>
            <TableCell colSpan={colSpan}>
                <Skeleton height={25} />
            </TableCell>
        </TableRow>
    );
};

interface TableLoadingProps {
    rows: number;
    colSpan: number;
}

const TableLoading: FC<TableLoadingProps> = ({ rows, colSpan }) => (
    <>
        {[...Array(rows).keys()].map((i) => (
            <TableSkeletonRow key={i} colSpan={colSpan} />
        ))}
    </>
);

export default TableLoading;
