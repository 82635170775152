import { FC } from 'react';

import CashBalanceCard from './CashBalanceCard';

import './WalletCard.scss';

interface WalletCardProps {
    animate?: boolean;
    cashBalance: number;
    onClickCashOut: () => void;
    isLoading?: boolean;
}

const WalletCard: FC<WalletCardProps> = ({
    animate = false,
    cashBalance,
    onClickCashOut,
    isLoading = false,
}) => {
    return (
        <div className="wallet-card">
            <CashBalanceCard
                isLoading={isLoading}
                animate={animate}
                cashBalance={cashBalance}
                onClickCashOut={onClickCashOut}
            />
        </div>
    );
};

export default WalletCard;
