import { useMemo } from 'react';

import { keyBy } from 'lodash';

import { VendorTotalsChartResponseBody } from '@sparkplug/lib';

import { useVendorChartContext } from '@core/charts/contexts/VendorChartContext';
import { useVendorChartTotalBreakdown } from '@core/charts/queries/ChartsQueries';

import { calculatePercentIncrease } from '@helpers/charts';

interface VendorTableChartData extends VendorTotalsChartResponseBody {
    showComparisonPeriod?: boolean;
}

const combinePreviousChartDataWithCurrentChartData = ({
    currentPeriod,
    comparisonPeriod,
}: {
    currentPeriod: VendorTotalsChartResponseBody;
    comparisonPeriod: VendorTotalsChartResponseBody;
}): VendorTableChartData => {
    // Keep the key order but add the comparison columns after 'value'
    const index = currentPeriod.keys.findIndex((key) => key === 'value') + 1;
    const updatedKeys = [
        ...currentPeriod.keys.slice(0, index),
        'previousValue',
        'changeInValue',
        'comparisonValue',
        ...currentPeriod.keys.slice(index),
    ];

    const previousValuesByRowKey = keyBy(comparisonPeriod?.rows ?? [], 'key');
    return {
        showComparisonPeriod: true,
        keys: updatedKeys,
        rows: currentPeriod.rows.map((row) => {
            const { value: previousValue = 0 } = previousValuesByRowKey[row.key] ?? {};
            const [percentDiff] = calculatePercentIncrease(
                Number(row.value),
                Number(previousValue),
            );

            return {
                ...row,
                previousValue,
                changeInValue: Number(row.value) - Number(previousValue),
                comparisonValue: Number.isNaN(percentDiff)
                    ? Number.MIN_SAFE_INTEGER
                    : Number(percentDiff),
            };
        }),
    };
};

export const useVendorTableChartData = () => {
    const { vendorAccountId, chartSettings, comparisonRange, showComparisonWindows } =
        useVendorChartContext();

    const {
        vendorChartTotalBreakdownIsReady,
        vendorChartTotalBreakdown = {
            keys: [],
            rows: [],
        },
    } = useVendorChartTotalBreakdown(
        {
            vendorAccountId,
            calcType: chartSettings.metric,
            breakdownType: chartSettings.breakdown,
        },
        chartSettings,
        chartSettings?.type === 'table',
    );

    const {
        vendorChartTotalBreakdownIsReady: comparisonVendorChartTotalBreakdownIsReady,
        vendorChartTotalBreakdown: comparisonVendorChartTotalBreakdown = {
            keys: [],
            rows: [],
        },
    } = useVendorChartTotalBreakdown(
        {
            vendorAccountId,
            calcType: chartSettings.metric,
            breakdownType: chartSettings.breakdown,
        },
        {
            ...chartSettings,
            dateStart: comparisonRange?.previousDateStart ?? '',
            dateEnd: comparisonRange?.previousDateEnd ?? '',
        },
        chartSettings?.type === 'table' && showComparisonWindows,
    );

    const isLoading =
        !vendorChartTotalBreakdownIsReady ||
        (showComparisonWindows && !comparisonVendorChartTotalBreakdownIsReady);

    const data = useMemo<VendorTableChartData>(
        () =>
            showComparisonWindows && comparisonVendorChartTotalBreakdown
                ? combinePreviousChartDataWithCurrentChartData({
                      currentPeriod: vendorChartTotalBreakdown,
                      comparisonPeriod: comparisonVendorChartTotalBreakdown,
                  })
                : vendorChartTotalBreakdown,
        [showComparisonWindows, vendorChartTotalBreakdown, comparisonVendorChartTotalBreakdown],
    );

    return {
        isLoading,
        data,
    };
};
