import { FC, ReactElement, memo, useMemo, useState } from 'react';

import { uniqBy } from 'lodash';

import Chip from '@components/chips/Chip';
import { TExtendedListItem } from '@components/inputs/ListSelector/ListSelector';
import TextField from '@components/inputs/TextField';
import Toolbar from '@components/toolbar/Toolbar';

import { appendClasses, capitalizeFirstLetter, uuid } from '@helpers/ui';

import './ToolbarDynamicStringDropdownListSelector.scss';

const StringLabel: FC<{ chipText?: string; text: string }> = ({ chipText, text }) => {
    return (
        <>
            {chipText && (
                <Chip
                    className="product-name-label_filterType"
                    dense
                    color="neutral"
                    label={capitalizeFirstLetter(chipText)}
                />
            )}
            <span>{text}</span>
        </>
    );
};

interface DynamicRadioOptionPopoverContentProps {
    textFieldLabel: string | ReactElement;
    chipText?: string;
    updateOption: (updatedOption: TExtendedListItem<{ isValidOption: boolean }>) => void;
    selectedStringFilters: TExtendedListItem<{}>[];
}

const DynamicRadioOptionPopoverContent = ({
    textFieldLabel,
    chipText,
    updateOption,
    selectedStringFilters,
}: DynamicRadioOptionPopoverContentProps) => {
    const id = useMemo(() => uuid(), []);

    const updateOptionText = (text: string) => {
        updateOption({
            value: id,
            label: text,
            isValidOption: !!text,
            labelComponent: () => <StringLabel text={text} chipText={chipText} />,
        });
    };

    return (
        <div className="dynamic-radio-option-popover-content">
            <TextField
                name={id}
                label={textFieldLabel}
                onChange={(event) => updateOptionText(event.target.value)}
            />
        </div>
    );
};

const mapStringFilterToListItem = (str: string, chipText?: string): TExtendedListItem<{}> => ({
    value: uuid(),
    label: str,
    labelComponent: () => <StringLabel text={str} chipText={chipText} />,
});

interface ToolbarDynamicStringDropdownListSelectorProps {
    className?: string;
    buttonLabel?: string;
    initializeExpanded?: boolean;
    textFieldLabel: string | ReactElement;
    chipText: string;
    initialStringFilters: string[];
    createButtonLabel?: string;
    pluralUnitLabel?: string;
    onApply?: (updatedSelected: string[]) => void;
}

const ToolbarDynamicStringDropdownListSelector: FC<ToolbarDynamicStringDropdownListSelectorProps> =
    memo(
        ({
            className,
            buttonLabel,
            initializeExpanded,
            textFieldLabel,
            chipText,
            initialStringFilters = [],
            createButtonLabel,
            pluralUnitLabel = 'filters',
            onApply,
        }) => {
            // We need to add a value and label to the initialStringFilters to work with the ListSelector
            const initialStringFiltersListItems = useMemo<TExtendedListItem<{}>[]>(
                () => initialStringFilters.map((str) => mapStringFilterToListItem(str, chipText)),
                [],
            );

            // These control the internal state, the external state is controlled by the onApply callback
            const [selectedStringFilters, setSelectedStringFilters] = useState<
                TExtendedListItem<{}>[]
            >(initialStringFiltersListItems);
            const [stringFilters, setStringFilters] = useState<TExtendedListItem<{}>[]>(
                initialStringFiltersListItems,
            );

            const updateList = (updatedSelected: TExtendedListItem<{}>) =>
                setStringFilters((prevValue) => uniqBy([...prevValue, updatedSelected], 'label'));

            const onInternalApply = (updatedSelected: TExtendedListItem<{}>[]) => {
                const uniqueSelected = uniqBy(updatedSelected, 'label');
                setSelectedStringFilters(uniqueSelected);
                onApply?.(uniqueSelected.map(({ label }) => label));
            };

            return (
                <Toolbar.DropdownListSelector
                    className={appendClasses([className, 'dropdown-list-selector_dynamic-string'])}
                    label={buttonLabel}
                    initializeExpanded={initializeExpanded}
                    selected={selectedStringFilters}
                    options={stringFilters as any}
                    onApply={onInternalApply}
                    showCount={!!(stringFilters.length && selectedStringFilters.length)}
                    selectedCountProps={{
                        pluralUnitLabel,
                    }}
                    dynamicOptionProps={{
                        ManageListItemContent: ({ updateOption }) => (
                            <DynamicRadioOptionPopoverContent
                                updateOption={updateOption}
                                chipText={chipText}
                                textFieldLabel={textFieldLabel}
                                selectedStringFilters={selectedStringFilters}
                            />
                        ),
                        updateList,
                        createButtonLabel,
                    }}
                    hideToolbar
                    clear={{
                        active: !!selectedStringFilters.length,
                        onClear: () => onInternalApply([]),
                    }}
                />
            );
        },
    );

export default ToolbarDynamicStringDropdownListSelector;
