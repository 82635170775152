import axios from 'axios';

import {
    GetChartRequestBodyMerged,
    GetChartResponseBody,
    GetRetailerChartRequestBody,
    GetVendorChartRequestBody,
    RetailerBreakdownBucketsChartResponseBody,
    RetailerBreakdownsTotalsChartResponseBody,
    RetailerChartCalculatorBreakdownType,
    TChartDataMetric,
    VendorBreakdownBucketsChartResponseBody,
    VendorChartCalculatorBreakdownType,
    VendorTotalsChartResponseBody,
} from '@sparkplug/lib';

const chartRequestPrefix = '/api/v1/sparkplug/chart';
export default class ChartsAPI {
    static async fetchChart(
        { type }: { type: TChartDataMetric },
        body: GetChartRequestBodyMerged & { groupId?: string; retailerAccountIds?: string[] },
        breakdownType?: RetailerChartCalculatorBreakdownType,
    ): Promise<GetChartResponseBody> {
        if (type === 'percent_increase') {
            return (await axios.post(`${chartRequestPrefix}/percent_increase`, body)).data;
        }

        const shouldUseNewChartApi =
            breakdownType && ['total_sales', 'total_units'].includes(type) && !body.sparkId;

        if (shouldUseNewChartApi) {
            return ChartsAPI.fetchRetailerChartData({ type }, body, breakdownType);
        }

        return (await axios.post(`${chartRequestPrefix}/${type}`, body)).data;
    }

    static async fetchChartVendor(
        { type }: { type: 'total_sales' | 'total_units' },
        body: GetChartRequestBodyMerged & { groupId?: string; retailerAccountIds?: string[] },
        breakdownType?: VendorChartCalculatorBreakdownType,
    ): Promise<GetChartResponseBody> {
        return ChartsAPI.fetchVendorRetailerChartData({ type }, body as any, breakdownType);
    }

    static async fetchRetailerChartData(
        { type }: { type: TChartDataMetric },
        body: GetRetailerChartRequestBody & {
            groupId: string;
            locationIds?: string[] | string | undefined;
        },
        breakdownType: RetailerChartCalculatorBreakdownType = 'location',
    ): Promise<GetChartResponseBody> {
        const retailerChartRequestPrefix = `${chartRequestPrefix}/retailer`;

        if (breakdownType === 'location') {
            const [bucketResponse, totalResponse] = await Promise.all([
                axios.post<RetailerBreakdownBucketsChartResponseBody>(
                    `${retailerChartRequestPrefix}/${body.groupId}/${type}/${breakdownType}/buckets`,
                    {
                        ...body,
                        legacyLocationIds: body.locationIds,
                    },
                ),
                axios.post<RetailerBreakdownsTotalsChartResponseBody>(
                    `${retailerChartRequestPrefix}/${body.groupId}/${type}/${breakdownType}/totals`,
                    {
                        ...body,
                        legacyLocationIds: body.locationIds,
                    },
                ),
            ]);
            const { buckets: locationBuckets, datePercentage } = bucketResponse.data;
            return {
                locationBuckets,
                locationTotals: totalResponse.data,
                datePercentage,
                total: Object.values(totalResponse.data ?? {}).reduce(
                    (acc: any, cur: any) => acc + cur,
                    0,
                ),
            };
        } else {
            // breakdownType === 'employee' does not need the buckets response as we no longer display the trends section
            const totalResponse = await axios.post<RetailerBreakdownsTotalsChartResponseBody>(
                `${retailerChartRequestPrefix}/${body.groupId}/${type}/${breakdownType}/totals`,
                {
                    ...body,
                    legacyLocationIds: body.locationIds,
                },
            );

            return {
                employeeTotals: totalResponse.data,
            };
        }
    }

    static async fetchVendorRetailerChartData(
        { type }: { type: TChartDataMetric },
        body: GetVendorChartRequestBody & {
            groupId: string;
            brandGroupId: string;
            locationIds?: string[] | string | undefined;
        },
        breakdownType: VendorChartCalculatorBreakdownType = 'total',
    ): Promise<any> {
        const vendorChartRequestPrefix = `${chartRequestPrefix}/vendor`;

        const [bucketResponse, totalResponse] = await Promise.all([
            // we don't need buckets for employee breakdown, but do need totals
            breakdownType === 'employee'
                ? Promise.resolve({ data: { buckets: [] } })
                : axios.post<VendorBreakdownBucketsChartResponseBody>(
                      `${vendorChartRequestPrefix}/${body.brandGroupId}/${type}/${breakdownType}/buckets`,
                      {
                          ...body,
                          legacyLocationIds: body.locationIds,
                          retailerAccountIds: [body.groupId],
                      },
                  ),
            axios.post<VendorTotalsChartResponseBody>(
                `${vendorChartRequestPrefix}/${body.brandGroupId}/${type}/${breakdownType}/totals`,
                {
                    ...body,
                    legacyLocationIds: body.locationIds,
                    retailerAccountIds: [body.groupId],
                },
            ),
        ]);

        if (['location', 'total'].includes(breakdownType)) {
            const { buckets: locationBuckets } = bucketResponse.data;
            const { rows: locationTotalRows = [{ value: 0 } as any] } = totalResponse.data;
            return {
                locationBuckets,
                locationTotals: locationTotalRows,
                datePercentage: 1,
                total: locationTotalRows.reduce(
                    (acc: number, cur: any) => acc + (cur?.value ?? 0),
                    0,
                ),
            };
        } else if (breakdownType === 'employee') {
            return {
                employeeTotals: totalResponse.data,
                datePercentage: 1,
            };
        } else if (['brand', 'category'].includes(breakdownType)) {
            const bucketName = `${breakdownType}Buckets`;
            const { buckets } = bucketResponse.data;
            const { rows = [{ value: 0 } as any] } = totalResponse.data;

            return {
                [bucketName]: buckets,
                total: rows.reduce((acc: number, cur: any) => acc + (cur?.value ?? 0), 0),
                datePercentage: 1,
            };
        } else if (breakdownType === 'product') {
            const { buckets: productBuckets } = bucketResponse.data;
            return {
                productBuckets,
                total: totalResponse.data,
                datePercentage: 1,
            };
        }

        return {};
    }
}
