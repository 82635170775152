import { PaymentMethod } from '@sparkplug/lib';

import { DrawerIcon } from '@components/icons';
import InputLabel from '@components/inputs/InputLabel';
import PaymentMethodDropdown from '@components/inputs/PaymentMethodDropdown';
import TextField from '@components/inputs/TextField';
import CalloutMessage from '@components/layout/CalloutMessage';
import SparkPaymentSummary from '@components/sparks/SparkPaymentSummary';

import { isEmpty } from '@helpers/util';

import { TAccountType } from '@app/types/AccountsTypes';
import { IPayoutLineItem } from '@app/types/SparksTypes';

export type MultiLeaderboardSteps = 'approveOne' | 'approveFinal' | 'invoice';

interface ISparkCheckoutCustomerLabelProps {
    accountName: string;
    accountType: TAccountType;
}
const SparkCheckoutCustomerLabel = ({
    accountName,
    accountType,
}: ISparkCheckoutCustomerLabelProps) => {
    if (!accountName || !accountType) {
        return null;
    }

    return (
        <div className="spark-customer-label">
            <InputLabel shrink>Customer</InputLabel>
            <section>
                <span className="customer-name">
                    <DrawerIcon />
                    <span>{accountName}</span>
                </span>
                <span className="customer-account-type">{accountType}</span>
            </section>
        </div>
    );
};

interface ISparkCheckoutModalContentProps {
    shouldInvoice: boolean;
    currentMultiLeaderboardStep?: MultiLeaderboardSteps;
    payerAccountName: string;
    payerAccountType: TAccountType;
    balanceDue: number;
    lineItems: IPayoutLineItem[];
    paymentMethods: PaymentMethod[];
    selectedPaymentMethodId: string;
    onPaymentMethodChange: (pmId: string) => any;
    invoiceName: string;
    onInvoiceNameChange: Function;
}
const SparkCheckoutModalContent = ({
    shouldInvoice,
    payerAccountName,
    payerAccountType,
    balanceDue,
    lineItems,
    paymentMethods,
    selectedPaymentMethodId,
    onPaymentMethodChange,
    invoiceName,
    onInvoiceNameChange,
}: ISparkCheckoutModalContentProps) => {
    return (
        <section className="spark-checkout-modal-content">
            <CalloutMessage
                title="Warning"
                message="Finalize total sum of payments to be made. Once confirmed, THIS ACTION CANNOT BE UNDONE!"
                color="yellow"
            />

            <SparkPaymentSummary
                paymentValue={balanceDue}
                lineItems={lineItems}
                showStatusSection={false}
            />

            {shouldInvoice && (
                <>
                    <SparkCheckoutCustomerLabel
                        accountName={payerAccountName}
                        accountType={payerAccountType}
                    />
                    <TextField
                        value={invoiceName}
                        label="Line Item Name (max 350 characters)"
                        name="spark-checkout-invoice-name"
                        onChange={(e) => onInvoiceNameChange(e.target.value)}
                        maxLength={350} // this is applied to stripe's desc field on invoice - has limit of 500 chars, but we add up to 100 chars for the link in the api
                    />
                    {!isEmpty(paymentMethods) && (
                        <PaymentMethodDropdown
                            paymentMethods={paymentMethods}
                            selectedPaymentId={selectedPaymentMethodId}
                            onChange={onPaymentMethodChange}
                        />
                    )}
                </>
            )}
        </section>
    );
};

export default SparkCheckoutModalContent;
