import { ComponentType, FC, ReactElement, useRef } from 'react';

import { clsx } from 'clsx';

import Dropdown from '@components/dropdown/Dropdown';
import Checkbox from '@components/inputs/Checkbox';
import { TooltipProps } from '@components/layout/Tooltip';

import { useIsInToolbar } from '../Toolbar/useIsInToolbar';
import ToolbarGroup from '../ToolbarGroup';

import './ToolbarDropdown.scss';

interface ToolbarDropdownProps {
    className?: string;
    label: string | null;
    options: {
        startIcon?: ReactElement;
        StartIcon?: ComponentType;
        label: string;
        subLabel?: string;
        selectedLabel?: string;
        value: string | boolean;
        disabled?: boolean;
        tooltipProps?: Omit<TooltipProps, 'children'>;
    }[];
    value?: string | boolean;
    onSelect: (value: any) => void;
    color?: 'neutral' | 'blue' | 'green' | 'red';
    variant?: 'flat' | 'filled' | 'raised' | 'smooth' | 'outlined';
    disabled?: boolean;
    clear?: {
        active: boolean;
        onClear: () => void;
    };
    toggleDisabled?: boolean;
    onToggle?: (enabled: boolean) => void;
    highlight?: boolean;
    afterComponent?: ReactElement;
    /**
     * @description
     *
     * this prop is used if the ListSelector should default as expanded and is
     * primarily only used for the `FilterSegmentToolbar` component
     */
    initializeExpanded?: boolean;
    titlePlaceholder?: string;
}

const startIconToComponent =
    (startIcon: ReactElement): ComponentType =>
    () =>
        startIcon;

export const ToolbarDropdown: FC<ToolbarDropdownProps> = ({
    className,
    label,
    color = 'blue',
    disabled = false,
    variant = 'flat',
    options,
    value,
    onSelect,
    clear,
    toggleDisabled = false,
    onToggle,
    highlight = false,
    afterComponent,
    initializeExpanded,
    titlePlaceholder,
}) => {
    useIsInToolbar({ componentName: 'Toolbar.Dropdown' });
    const buttonRef = useRef<HTMLButtonElement>(null);

    const { StartIcon, startIcon, ...selected }: any = options.find((v) => v.value === value) || {};

    const TitleIcon = startIcon ?? (StartIcon && <StartIcon />);

    const titleText = selected?.selectedLabel != null ? selected?.selectedLabel : selected.label;

    return (
        <ToolbarGroup
            className={clsx(
                className,
                'toolbar-dropdown',
                onToggle && 'has-checkbox',
                toggleDisabled && 'disabled',
                highlight && 'highlighted',
            )}
        >
            {onToggle && (
                <Checkbox
                    disabled={toggleDisabled}
                    value={!disabled}
                    onChange={() => onToggle(disabled)}
                />
            )}
            {label && <div className="toolbar-dropdown-label">{`${label}:`}</div>}
            <Dropdown
                initializeExpanded={initializeExpanded ? { buttonRef } : undefined}
                color={color}
                variant={variant}
                disabled={disabled}
            >
                <Dropdown.Button startIcon={TitleIcon} clear={clear} buttonRef={buttonRef}>
                    {titlePlaceholder && !titleText ? titlePlaceholder : titleText}
                </Dropdown.Button>
                <Dropdown.Menu>
                    {options.map((option, i) => (
                        <Dropdown.MenuItem
                            selected={option.value === value}
                            StartIcon={
                                option.startIcon
                                    ? startIconToComponent(option.startIcon)
                                    : option.StartIcon
                            }
                            key={`dropdown-menu-item-${i}-${option.value}-${option.label}`}
                            disabled={option.disabled}
                            onClick={() => {
                                onSelect(option.value);
                            }}
                            tooltipProps={option.tooltipProps}
                        >
                            <span className="dropdown-menu-item_label">
                                <span className="label-inner">{option.label}</span>
                                {option?.subLabel != null && (
                                    <span className="label-inner_sublabel">{option?.subLabel}</span>
                                )}
                            </span>
                        </Dropdown.MenuItem>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
            {afterComponent}
        </ToolbarGroup>
    );
};
export default ToolbarDropdown;
