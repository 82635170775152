import { useEffect, useState } from 'react';

import { PublicSnapTemplate } from '@sparkplug/lib';

import { useSnapTemplateQuery } from '@features/spark-snaps/queries';

/**
 * Storifyme doesn't do a good job of sending fresh data. Occasionally this results in broken thumbnail images.
 * This hook dynamically refetches the snapTemplate data when the thumbnail image is broken.
 * This is necessary because the list snapTemplates endpoint doesn't poll for fresh data whereas fetching a single snapTemplate does.
 *
 * Note: This is separated from the `TemplateThumbnail` component to allow for easier testing.
 */
export const useFreshSnapTemplateThumbnail = ({
    snapTemplate,
    sparkplugAccountId,
}: {
    snapTemplate: PublicSnapTemplate;
    sparkplugAccountId: string;
}) => {
    const [thumbnailImgIsBroken, setThumbnailImgIsBroken] = useState<boolean>(false);

    const {
        snapTemplate: freshSnapTemplateData,
        snapTemplateIsLoading: isLoadingFreshSnapTemplateData,
    } = useSnapTemplateQuery({
        accountId: sparkplugAccountId,
        snapTemplateId: snapTemplate.id,
        /**
         * When the image is broken refetch this snapTemplate.
         * When fetching a snapTemplate we wait for up to a fixed amount of time for the Storifyme image CDN to propogate the previewImg.
         */
        enabled: thumbnailImgIsBroken,
    });

    useEffect(() => {
        /**
         * Reset the broken image state when the snapTemplate is loading.
         *
         * This should only happen once since `useSnapTemplateQuery` will only run the first time `thumbnailImgIsBroken` is set to true.
         * This ensure that we don't get into an infinite loop of fetching the snapTemplate.
         */
        if (isLoadingFreshSnapTemplateData) {
            setThumbnailImgIsBroken(false);
        }
    }, [isLoadingFreshSnapTemplateData]);

    return {
        freshThumbnail: freshSnapTemplateData?.previewImg ?? snapTemplate.previewImg,
        freshThumbnailStatus:
            freshSnapTemplateData?.previewImgStatus ?? snapTemplate.previewImgStatus,
        isLoadingFreshThumbnail: isLoadingFreshSnapTemplateData,
        thumbnailImgIsBroken,
        /**
         * @usage This function should be passed to the `onError` prop of the `img` element.
         */
        handleThumbnailImgError: () => setThumbnailImgIsBroken(true),
    };
};
