export default [
    {
        _id: '66be239a49e36f001191aadf',
        templateName: 'Increase Order Averages',
        templateDescription:
            'Encourage staff to compete in boosting average order values. This recurring leaderboard incentivizes ongoing focus on upselling and cross-selling to maximize revenue per transaction.',
        accountType: 'retailer',
        awards: [
            {
                award: '$100',
            },
            {
                award: '$50',
            },
            {
                award: '$25',
            },
        ],
        description: 'Boost order values to climb the leaderboard and win big!',
        detailedSparkType: 'leaderboard',
        fulfillmentTypes: ['sparkplug', 'sparkplug', 'sparkplug'],
        goals: [],
        metric: 'order_average',
        minimumThresholdToQualify: 0,
        name: 'Order Value Champions',
        recurringSchedule: {
            interval: 'monthly',
        },
        type: 'leaderboard',
    },
    {
        _id: '66be24b97074380012b134d5',
        templateName: 'Promote High-Margin Products',
        templateDescription:
            'Motivate sales staff to focus on high-margin products by setting individual goals based on the percentage of these products within their total sales.',
        accountType: 'retailer',
        awards: [],
        description: 'Focus on high-margin products to hit your sales goals and earn rewards!',
        detailedSparkType: 'goal',
        fulfillmentTypes: ['sparkplug', 'sparkplug', 'sparkplug'],
        goalType: 'individual',
        goals: [
            {
                award: {
                    award: '$25',
                },
                threshold: 5,
            },
            {
                award: {
                    award: '$50',
                },
                threshold: 10,
            },
            {
                award: {
                    award: '$100',
                },
                threshold: 25,
            },
        ],
        metric: 'percent_of_total_sales',
        name: 'High-Margin Hero',
        type: 'goal',
    },
    {
        _id: '66be25605a78680012b9928b',
        templateName: 'Clear Old Inventory',
        templateDescription:
            'Offer a one-time fixed commission for each unit of seasonal or expiring stock sold. This clear and direct incentive ensures a quick turnover of products, helping to make room for new inventory.',
        accountType: 'retailer',
        awards: [],
        description: 'Clear out old stock and earn a commission for each unit sold!',
        detailedSparkType: 'commissionFlat',
        fulfillmentTypes: ['sparkplug'],
        goals: [],
        metric: 'total_units',
        minimumThresholdToQualify: 0,
        name: 'Inventory Clearance',
        type: 'commission',
    },
    {
        _id: '66be26295a78680012b99292',
        templateName: 'Maximize Holiday Sales',
        templateDescription:
            'Encourage locations to compete in maximizing total sales during the holiday season. This one-time location leaderboard drives performance during peak periods, ensuring your stores capture maximum revenue.',
        accountType: 'retailer',
        awards: [
            {
                award: '$300',
            },
            {
                award: '$200',
            },
            {
                award: '$100',
            },
        ],
        commissionRules: {
            fixedValue: 0,
            type: 'fixed',
        },
        description: 'Focus on sales of selected products and win for your location!',
        detailedSparkType: 'leaderboardLocation',
        type: 'leaderboard',
        fulfillmentTypes: ['sparkplug', 'sparkplug', 'sparkplug'],
        metric: 'total_sales',
        minimumThresholdToQualify: 0,
        name: 'Holiday Sales Showdown',
        splitPayout: true,
    },
    {
        _id: '66be44da2ec42700123d4aae',
        templateName: 'Improve Upselling Techniques',
        templateDescription:
            'Set a recurring team goal to increase units per transaction. This structure encourages staff to focus on upselling, enhancing overall sales performance.',
        accountType: 'retailer',
        awards: [],
        description:
            'Focus on upselling to increase sales of selected products and achieve your goals!',
        detailedSparkType: 'goal',
        fulfillmentTypes: ['sparkplug', 'sparkplug', 'sparkplug'],
        goalType: 'individual',
        goals: [
            {
                award: {
                    award: '$20',
                },
                threshold: 100,
            },
            {
                award: {
                    award: '$50',
                },
                threshold: 300,
            },
            {
                award: {
                    award: '$125',
                },
                threshold: 500,
            },
        ],
        metric: 'total_sales',
        name: 'Upsell Excellence',
        type: 'goal',
        recurringSchedule: {
            interval: 'weekly',
        },
    },
    {
        _id: '66be29257074380012b134ec',
        templateName: 'Drive Weekly Promotions',
        templateDescription:
            'Offer a recurring commission based on total sales to drive weekly promotions. This approach rewards continuous high performance, ensuring that weekly specials are consistently pushed.',
        accountType: 'retailer',
        description: 'Drive weekly promotions to earn recurring commissions based on total sales!',
        detailedSparkType: 'commissionFlat',
        awards: [],
        fulfillmentTypes: ['sparkplug'],
        metric: 'total_units',
        minimumThresholdToQualify: 0,
        name: 'Weekly Promo Push',
        type: 'commission',
        commissionRules: {
            fixedValue: 0,
            type: 'fixed',
        },

        recurringSchedule: {
            interval: 'weekly',
        },
    },
    {
        _id: '66be46bed3c0180012ad0c7a',
        templateName: 'Increase Product Mix Diversity',
        templateDescription:
            'Quickly launch leaderboards across multiple locations to increase the percentage of selected products within total sales. This one-time leaderboard encourages diversification of the product mix, motivating staff to include underperforming products in each sale.',
        accountType: 'retailer',
        // will need actual prizes
        awards: [
            {
                award: '$50',
            },
            {
                award: '$25',
            },
            {
                award: '$15',
            },
        ],
        description: 'Increase the diversity of your product mix to climb the leaderboard and win!',
        detailedSparkType: 'leaderboardMulti',
        fulfillmentTypes: ['sparkplug', 'sparkplug', 'sparkplug'],
        metric: 'percent_of_total_sales',
        name: 'Product Mix Mastery',
        type: 'leaderboard',
    },
    {
        _id: '66be478649e36f001191b3c9',
        templateName: 'Achieve Monthly Sales Targets',
        templateDescription:
            'Set a recurring manager goal focused on total sales dollars to ensure consistent monthly performance. This approach aligns store management with overall sales targets, driving sustained progress.',
        accountType: 'retailer',
        description:
            'Achieve your monthly sales targets to drive consistent performance and earn rewards!',
        detailedSparkType: 'goalManager',
        fulfillmentTypes: ['sparkplug', 'sparkplug', 'sparkplug'],
        goalType: 'individual',
        goals: [
            {
                award: {
                    award: '$500',
                },
                threshold: 25000,
            },
            {
                award: {
                    award: '$1,000',
                },
                threshold: 45000,
            },
            {
                award: {
                    award: '$2,500',
                },
                threshold: 100000,
            },
        ],
        metric: 'total_sales',
        name: 'Monthly Sales Mastery',
        splitPayout: false,
        unitsSold: 0,
        type: 'goal',
        recurringSchedule: {
            interval: 'monthly',
        },
    },
    {
        _id: '66be482f49e36f001191b3dd',
        templateName: 'Maximize Overall Sales',
        templateDescription:
            'Create a one-time competition across all employees to see who can achieve the highest total sales. This straightforward leaderboard motivates everyone to push for the top spot, driving maximum sales and rewarding the highest performers.',
        accountType: 'retailer',
        awards: [
            {
                award: '$50',
            },
        ],
        description: 'Compete to achieve the highest total sales and become the top performer!',
        detailedSparkType: 'leaderboard',
        fulfillmentTypes: ['sparkplug'],
        goals: [],
        metric: 'total_sales',
        minimumThresholdToQualify: 0,
        name: 'Sales Superstar',
        type: 'leaderboard',
    },
];
