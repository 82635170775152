import axios from 'axios';

import { GetBrandsForVendorResponseBody } from '@sparkplug/lib';

import { useAdvancedQuery } from '@hooks/QueryHooks';

import { IAccount } from '@app/types/AccountsTypes';

const useVendorBrands = (account?: IAccount) => {
    if (!account || account.type !== 'brand') {
        return {
            data: undefined,
            isLoading: false,
            isFetched: true,
            refetch: () => {},
        };
    }
    const { data, isLoading, isFetched, refetch } = useAdvancedQuery(
        ['vendorBrands', account._id],
        async () => {
            return (
                (
                    await axios.get<GetBrandsForVendorResponseBody>(
                        `/api/v1/brands/${account._id}/brands`,
                    )
                ).data?.data ?? []
            );
        },
    );

    return {
        data,
        isLoading,
        isFetched,
        refetch,
    };
};

export default useVendorBrands;
