import { z } from 'zod';
import { AccountRole } from '../account';
import { transformPhoneNumber } from '../parser';

export interface IJsonWebTokenPayloadUser {
  userId: string;
  isSuperAdmin: boolean;
  requires2fa?: boolean;
}

export interface IJsonWebTokenPayloadAccount {
  accountId: string;
  /**
   * @deprecated Use accounts instead
   * @todo Remove this as part of PLT-65, once all old JWTs have expired
   */
  groupId?: string;
  role: AccountRole;
}

export interface IJsonWebTokenPayload {
  accounts: IJsonWebTokenPayloadAccount[];
  user: IJsonWebTokenPayloadUser;
  role?: string;
  /**
   * @deprecated Use accounts instead
   * @todo Remove this as part of PLT-65, once all old JWTs have expired
   */
  groups?: IJsonWebTokenPayloadAccount[];
  permissions: string[];
}

export const loginRequestSchema = z.object({
  password: z.string().min(1),

  email: z
    .string()
    .optional()
    .transform((email) => email?.toLowerCase()),
  phoneNumber: z.string().optional().transform(transformPhoneNumber),
});
loginRequestSchema.refine(
  (data) => !!data.email || !!data.phoneNumber,
  'Either email or phoneNumber must be provided',
);

export type LoginRequestBody = z.infer<typeof loginRequestSchema>;
export interface LoginResponseBody {
  token: string;
}

export const smsLoginRequestSchema = z.object({
  phoneNumber: z.string().min(1),
});
export type SmsLoginRequestBody = z.infer<typeof smsLoginRequestSchema>;

export interface SmsLoginResponseBody {
  firstName: string;
  authCode: string;
}

export const confirmSmsLoginRequestSchema = z
  .object({
    phoneNumber: z.string().min(1).optional(),
    authCode: z.string().min(1),
    email: z
      .string()
      .optional()
      .transform((email) => email?.toLowerCase()),
  })
  .refine((data) => !!data.phoneNumber || !!data.email, 'Email or phone number is required');
export type ConfirmSmsLoginRequestBody = z.infer<typeof confirmSmsLoginRequestSchema>;

export type ConfirmSmsLoginResponseBody = LoginResponseBody;
