import { FC } from 'react';

import { useVendorChartContext } from '@core/charts/contexts/VendorChartContext';

import { IAccountRetailers } from '@app/types/AccountsTypes';

import VendorBreakdownChart from './VendorBreakdownChart';
import VendorMarketComparisonChart from './VendorMarketComparisonChart';

import './VendorMarketChart.scss';

interface VendorMarketChartProps {
    retailers: IAccountRetailers[];
}

const VendorMarketChart: FC<VendorMarketChartProps> = ({ retailers }) => {
    const { chartSettings, showComparisonWindows } = useVendorChartContext();

    /**
     * Because we want all the logic of which chart to show to be handled by this component and not the parent
     * component where it's used, we will handle this logic here.
     */
    if (showComparisonWindows || chartSettings.breakdown === 'total') {
        return <VendorMarketComparisonChart retailers={retailers} />;
    }

    return <VendorBreakdownChart />;
};

export default VendorMarketChart;
