import { FC } from 'react';

import { InfoIcon } from '@components/icons';
import Tooltip from '@components/layout/Tooltip';

import './PosEmployeeProfileNameLabel.scss';

interface PosEmployeeProfileNameLabelProps {
    label: string;
    hideInfoIcon?: boolean;
}

const PosEmployeeProfileNameLabel: FC<PosEmployeeProfileNameLabelProps> = ({
    label,
    hideInfoIcon,
}) => {
    if (!label) {
        return <></>;
    }

    return (
        <div className="pos-name-chip-container">
            <span className="pos-name-chip">{label}</span>
            {!hideInfoIcon && (
                <Tooltip
                    title="This is the name of the employee as it appears in your POS"
                    placement="top"
                >
                    <div>
                        <InfoIcon />
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export default PosEmployeeProfileNameLabel;
