import { FC, ReactElement, useState } from 'react';

import { isEmpty } from 'lodash';
import pluralize from 'pluralize';

import Dropdown from '@components/dropdown/Dropdown';
import {
    DeleteForever,
    Edit,
    MoreVert as MoreIcon,
    Snaps as SnapsIcon,
    Visibility,
} from '@components/icons';

import './SnapPosterThumbnail.scss';

export interface SnapPosterThumbnailProps {
    imgSrc?: string;
    imgElement?: ReactElement;
    title: string;
    slideCount: number;
    dropdownMenu?: {
        onEdit?: () => void;
        onPreview?: () => void;
        onDelete?: () => void;
    };
}

const placeholder = (
    <div className="img-broken">
        <SnapsIcon />
    </div>
);

const SnapPosterThumbnail: FC<SnapPosterThumbnailProps> = ({
    imgSrc,
    imgElement,
    title,
    slideCount,
    dropdownMenu,
}) => {
    const [imgIsBroken, setImgIsBroken] = useState(false);

    const slideCountLabel = `${slideCount} ${pluralize('Slide', slideCount)}`;

    const hasDropdownMenu = !isEmpty(dropdownMenu);

    let thumbnailElement = imgElement;
    if (!imgElement) {
        const hasValidImgSrc = !imgIsBroken && !!imgSrc;
        thumbnailElement = hasValidImgSrc ? (
            <img src={imgSrc} alt={title} onError={() => setImgIsBroken(true)} />
        ) : (
            placeholder
        );
    }

    return (
        <div className="snap-poster-thumbnail">
            <div className="snap-poster-thumbnail_img">{thumbnailElement}</div>
            <div className="snap-poster-thumbnail_label">
                <div className="snap-poster-thumbnail_text">
                    <span>{title}</span>
                    <span>{slideCountLabel}</span>
                </div>
                {hasDropdownMenu && (
                    <Dropdown>
                        <Dropdown.IconButton color="neutral">
                            <MoreIcon />
                        </Dropdown.IconButton>

                        <Dropdown.Menu>
                            {dropdownMenu?.onEdit && (
                                <Dropdown.MenuItem StartIcon={Edit} onClick={dropdownMenu.onEdit}>
                                    Edit
                                </Dropdown.MenuItem>
                            )}
                            {dropdownMenu?.onPreview && (
                                <Dropdown.MenuItem
                                    StartIcon={Visibility}
                                    onClick={dropdownMenu.onPreview}
                                >
                                    Preview
                                </Dropdown.MenuItem>
                            )}
                            {dropdownMenu?.onDelete && (
                                <Dropdown.MenuItem
                                    StartIcon={DeleteForever}
                                    color="red"
                                    onClick={dropdownMenu.onDelete}
                                >
                                    Delete
                                </Dropdown.MenuItem>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </div>
        </div>
    );
};

export default SnapPosterThumbnail;
