import { useMemo } from 'react';

import { orderBy } from 'lodash';

import { Spark, SparksViewFilters } from '@sparkplug/lib';

/**
 * Sorts sparks such that the pending sparks are sorted to the top of the list.
 */
const applyDefaultOrderingToSparks = (sparks: Spark[] = []): Spark[] => {
    const { pending, rest } = sparks.reduce(
        (result, spark) => {
            if (spark.requestState === 'pending') {
                result.pending.push(spark);
            } else {
                result.rest.push(spark);
            }
            return result;
        },
        { pending: [], rest: [] } as { pending: Spark[]; rest: Spark[] },
    );

    return [
        ...orderBy(pending, ['createdAt'], ['desc']),
        ...orderBy(rest, ['createdAt'], ['desc']),
    ];
};

/**
 * The default sorting should put `pending` sparks on the top of the list. In practice, this is only applicable to the proposal section which is correlated to a `sparkFilters.status` of 'inbox/sent'.
 * Otherwise, continue to use a default ordering of sparks by a descending `createdAt`.
 */
export const useSparkDashboardDefaultOrder = ({
    sparks,
    sparkFilters,
}: {
    sparks: Spark[];
    sparkFilters?: Pick<SparksViewFilters, 'status'>;
}): Spark[] => {
    const defaultSparkOrder = useMemo(() => {
        if (sparkFilters?.status === 'inbox/sent') {
            return applyDefaultOrderingToSparks(sparks);
        }
        return orderBy(sparks, ['createdAt'], ['desc']);
    }, [sparks, sparkFilters]);

    return defaultSparkOrder;
};
