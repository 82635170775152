import { ReactElement } from 'react';

// eslint-disable-next-line no-restricted-imports
import { IconButton as MaterialUIIconButton } from '@mui/material';

import { appendClasses } from '@helpers/ui';

import { IButtonProps } from '../Button';

import './IconButton.scss';

export interface IIconButtonProps extends IButtonProps {
    children: ReactElement;
    size?: 'small' | 'medium';
}

const IconButton = ({
    className = '',
    tutorialTarget,
    color = 'neutral',
    variant = 'flat',
    children,
    disabled,
    onClick,
    size,
    sx,
    'data-testid': dataTestId,
}: IIconButtonProps) => {
    const classNameAppended = appendClasses([
        className,
        'icon-btn',
        `icon-btn-color-${color}`,
        `icon-btn-variant-${variant}`,
    ]);

    return (
        <MaterialUIIconButton
            data-intercom-target={tutorialTarget}
            data-testid={dataTestId}
            className={classNameAppended}
            onClick={onClick}
            disabled={disabled}
            size={size}
            sx={sx}
        >
            {children}
        </MaterialUIIconButton>
    );
};

export default IconButton;
