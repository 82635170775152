import { FC, useCallback, useMemo, useState } from 'react';

import { Tabs, TabsList, TabsTrigger } from '@componentLibrary';
import VendorRetailerDashboardSalesView from '@views/dashboard/DashboardView/VendorDashboardView/VendorRetailerDashboardView/VendorRetailerDashboardSalesView';
import { BrandDashboardHeader } from '@views/dashboard/DashboardView/VendorDashboardView/components/BrandDashboardHeader';

import { ListAccountLinksResponseBody } from '@sparkplug/lib';

import { RetailerInventoryView } from '@features/inventory';

import Chip from '@components/chips/Chip';
import { Add as AddIcon, SwapHoriz } from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import PageHeader from '@components/layout/PageHeader';
import UpgradeModalV2 from '@components/overlays/UpgradeModal/UpgradeModalV2';
import { useQueryParams } from '@components/router';
import CreateSparkButton from '@components/sparks/CreateSparkButton';
import Toolbar from '@components/toolbar/Toolbar';

import { useApp } from '@hooks/AppHooks';
import {
    useSparkplugAccount,
    useSparkplugBrandRetailer,
} from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { isEmpty } from '@helpers/util';

import { IAccount } from '@app/types/AccountsTypes';

import './VendorRetailerDashboardView.scss';

export interface VendorRetailerDashboardViewProps {
    account: IAccount;
    shareSalesData?: boolean;
    accountLinks?: ListAccountLinksResponseBody;
    userIsSuperAdmin?: boolean;
}

export const VendorRetailerDashboardView: FC<VendorRetailerDashboardViewProps> = ({
    account,
    accountLinks,
    userIsSuperAdmin = false,
}) => {
    const { history } = useApp();
    const { tab } = useQueryParams();
    const [upgradeModalIsVisible, setUpgradeModalIsVisible] = useState(false);
    const { hasInventoryEntitlement, inventoryEntitlementMarkets } = useSparkplugAccount();
    const { brandRetailer, brandRetailerId } = useSparkplugBrandRetailer();
    const inventoryFeatureEnabled = import.meta.env.REACT_APP_RETAILER_INVENTORY === 'true';

    const retailerMarkets = brandRetailer?.metaData?.markets;
    const isMarketEntitled = useMemo(
        () =>
            hasInventoryEntitlement &&
            (inventoryEntitlementMarkets.length === 0 ||
                inventoryEntitlementMarkets.some((market) => retailerMarkets?.includes(market))),
        [hasInventoryEntitlement, inventoryEntitlementMarkets, retailerMarkets],
    );

    const showInventoryForVendor =
        hasInventoryEntitlement && inventoryFeatureEnabled && isMarketEntitled;

    const handleModalClose = useCallback(() => {
        history.push(`?tab=sales`);
        setUpgradeModalIsVisible(false);
    }, [history]);

    const handleChange = useCallback(
        (newVal: string) => {
            if (newVal === 'inventory' && !showInventoryForVendor && !userIsSuperAdmin) {
                setUpgradeModalIsVisible(true);
            } else {
                history.push(`?tab=${newVal}`);
            }
        },
        [showInventoryForVendor, userIsSuperAdmin, history],
    );

    if (tab === 'inventory' && !showInventoryForVendor && !userIsSuperAdmin) {
        history.push(`?tab=sales`);
    }

    const isEmptyBrand = account?.type === 'brand' && isEmpty(account?.retailers);

    if (isEmptyBrand) {
        return (
            <div className="empty-state-container">
                <EmptyStateDisplay
                    layout="horizontal"
                    addPaperWrapper
                    className="add-retailer-empty-state"
                    graphic={<img src="/images/add-retailer-placeholder.jpg" alt="placeholder" />}
                    label="Get Real-Time Sales Numbers and Metrics!"
                    smallText="Link retailers to access real-time sales data, reporting, and an analytics dashboard to drive your business forward."
                    actionButton={{
                        label: 'Add Retailer',
                        onClick: () => history.push(`/${account?._id}/partners/available`),
                        startIcon: <AddIcon />,
                    }}
                />
            </div>
        );
    }
    const pageHeaderTitle = useMemo(() => {
        if (account?.type === 'brand' && account?.name && brandRetailer?.name) {
            return (
                <BrandDashboardHeader brandName={account.name} retailerName={brandRetailer.name} />
            );
        }
        return `${account?.name} Dashboard`;
    }, [account?.type, account?.name, brandRetailer?.name]);

    const pageMetaTitle = useMemo(
        () => `${account?.name} Dashboard | ${brandRetailer?.name}`,
        [account?.name, brandRetailer?.name],
    );

    const isSales = !tab || tab === 'sales';
    const showInventory = tab === 'inventory' && (showInventoryForVendor || userIsSuperAdmin);
    return (
        <div className={`dashboard-view dashboard-${account?.type}-view`}>
            <PageHeader title={pageHeaderTitle} metaTitle={pageMetaTitle}>
                <Toolbar collapseOnMobile>
                    {account?.type === 'brand' && (
                        <Toolbar.Button
                            startIcon={<SwapHoriz />}
                            variant="outlined"
                            data-qa-btn="switch-retailer-btn"
                            onClick={() => {
                                document.body.classList.add('show-brand-retailers');
                            }}
                        >
                            Switch Retailer
                        </Toolbar.Button>
                    )}
                    <CreateSparkButton
                        onClick={() =>
                            history.push(
                                `/${account?._id}/sparks/create?groupId=${brandRetailer?._id}`,
                            )
                        }
                    />
                </Toolbar>
            </PageHeader>
            {account.industries.includes('cannabis') && (
                <Tabs
                    value={tab || 'sales'}
                    onValueChange={(value) => handleChange(value)}
                    className="mb-4"
                    activationMode="manual"
                >
                    <TabsList>
                        <TabsTrigger value="sales">Sales</TabsTrigger>
                        <TabsTrigger value="inventory">
                            Inventory <Chip color="cerulean" label="New" />
                        </TabsTrigger>
                    </TabsList>
                </Tabs>
            )}
            {isSales && (
                <VendorRetailerDashboardSalesView
                    account={account}
                    accountLinks={accountLinks}
                    userIsSuperAdmin={userIsSuperAdmin}
                />
            )}
            {showInventory && (
                <>
                    {userIsSuperAdmin && !showInventoryForVendor && (
                        <CalloutMessage
                            className="mb-2"
                            color="red"
                            message={`This view is not available to this account because they do not have the inventory entitlement ${
                                inventoryEntitlementMarkets.length > 0 ? 'for this market' : ''
                            }`}
                        />
                    )}
                    <RetailerInventoryView
                        account={account}
                        userIsSuperAdmin={userIsSuperAdmin}
                        hasInventoryEntitlement={hasInventoryEntitlement}
                        inventoryEntitlementMarkets={inventoryEntitlementMarkets}
                    />
                </>
            )}

            <UpgradeModalV2
                title="Track product inventory at Retail partner locations"
                content={
                    <div className="leading-5 flex flex-col">
                        Stay ahead of the competition by ensuring your products are always in stock.
                        This feature allows you to view out-of-stock alerts at each retail partner
                        location, monitor remaining inventory levels across all stores, and optimize
                        restocking efforts to prevent lost sales.
                        <span className="pt-3 text-gray-950 font-semibold">
                            Upgrade now to keep your inventory in check and your customers
                            satisfied.
                        </span>
                    </div>
                }
                image={
                    <img src="/static/images/inventory-upgrade.jpg" alt="Update to use inventory" />
                }
                isVisible={upgradeModalIsVisible}
                onClose={handleModalClose}
                onConfirm={() => {
                    handleModalClose();
                    window.open('https://sparkplugapp.typeform.com/to/XLuG2Tk7', '_blank');
                }}
            />
        </div>
    );
};
export default VendorRetailerDashboardView;
