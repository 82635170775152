import { ArrowDownward as ArrowDownIcon, ArrowUpward as ArrowUpIcon } from '@mui/icons-material';
import { clsx } from 'clsx';

import { PercentIncreaseMetric } from '@sparkplug/lib';

import './PercentIncreaseUserStats.scss';

const formatPercentIncreaseTotal = ({
    total = 0,
    percentIncreaseMetric,
}: {
    total?: number;
    percentIncreaseMetric: PercentIncreaseMetric;
}) => {
    switch (percentIncreaseMetric) {
        case 'total_units':
            return total;
        case 'order_average':
        case 'total_sales':
        default:
            return total.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            });
    }
};

export const PercentIncreaseUserStats = ({
    percentIncrease = 0,
    comparisonTotals,
    percentIncreaseMetric,
}: {
    percentIncrease?: number;
    comparisonTotals?: { currentTotal: number; previousTotal: number };
    percentIncreaseMetric: PercentIncreaseMetric;
}) => {
    const performanceHasDeclined = percentIncrease < 0;
    const hasPreviousTotal = comparisonTotals?.previousTotal;
    const icon = percentIncrease > 0 ? <ArrowUpIcon /> : <ArrowDownIcon />;
    const percentDiffLabel = hasPreviousTotal ? (
        <div className={clsx('percent-label', performanceHasDeclined && 'red')}>
            {icon}
            {Math.abs(percentIncrease).toFixed(2)}%
        </div>
    ) : (
        <span>--</span>
    );

    const currentTotalLabel = `Current: ${formatPercentIncreaseTotal({
        total: comparisonTotals?.currentTotal,
        percentIncreaseMetric,
    })}`;
    const previousTotalLabel = `Previous: ${formatPercentIncreaseTotal({
        total: comparisonTotals?.previousTotal,
        percentIncreaseMetric,
    })}`;

    const percentIncreaseClass = performanceHasDeclined ? 'down' : 'up';
    return (
        <div className="percent-increase">
            <div className={`percent-increase__value ${percentIncreaseClass}`}>
                {percentDiffLabel}
            </div>
            <div className="percent-increase__stat">{currentTotalLabel}</div>
            <div className="percent-increase__stat">{previousTotalLabel}</div>
        </div>
    );
};
