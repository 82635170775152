import { FC, useEffect, useMemo, useState } from 'react';

import { isEqual } from 'lodash';

import { QuickViewQueryParams, QuickViewTag } from '@sparkplug/lib';

import { CreateQuickViewsWidget } from './CreateQuickViewsWidget/CreateQuickViewsWidget';
import { MainQuickViewWidget } from './MainQuickViewWidget';

import './QuickViewQueryManager.scss';

const useSavedQuickViewQueries = (tag: string) => {
    // TODO actually implement this
    return useMemo(
        () => ({
            savedQuickViewQueries: [
                {
                    _id: 'id1',
                    name: "Corey's working accounts",
                    tag,
                    queryParams: {
                        apple: 'apple',
                        orange: 'orange',
                    },
                } as any,
            ],
            savedQuickViewQueriesAreReady: true,
        }),
        [],
    );
};

export interface QuickViewQueryManagerProps {
    className?: string;
    quickViewTag: QuickViewTag;
    initialQueryParams: QuickViewQueryParams;
    queryParams: QuickViewQueryParams;
    setQueryParams: Function;
}
export const QuickViewQueryManager: FC<QuickViewQueryManagerProps> = ({
    className = '',
    quickViewTag,
    initialQueryParams,
    queryParams,
    setQueryParams,
}) => {
    const [selectedQuickViewId, setSelectedQuickViewId] = useState<string>();

    const { savedQuickViewQueries = [], savedQuickViewQueriesAreReady } =
        useSavedQuickViewQueries(quickViewTag);

    const quickViewOptions = useMemo(
        () =>
            savedQuickViewQueries.map(({ _id, name }) => ({
                value: _id,
                label: name,
            })),
        [savedQuickViewQueries],
    );

    const selectedQuickView = useMemo(
        () => savedQuickViewQueries.find(({ _id }) => _id === selectedQuickViewId),
        [selectedQuickViewId, savedQuickViewQueries],
    );

    const showCreateWidget = useMemo(
        () => !isEqual(initialQueryParams, queryParams),
        [initialQueryParams, queryParams],
    );

    useEffect(() => {
        if (selectedQuickView) {
            setQueryParams(selectedQuickView);
        }
    }, [selectedQuickView]);

    return (
        <div className={`quick-view-query-manager ${className}`}>
            <MainQuickViewWidget
                isReady={savedQuickViewQueriesAreReady}
                quickViewOptions={quickViewOptions}
                selectedQuickView={selectedQuickView}
                onSelectQuickView={(quickViewId) => setSelectedQuickViewId(quickViewId)}
            />

            {showCreateWidget && (
                <CreateQuickViewsWidget tag={quickViewTag} queryParams={queryParams} />
            )}
        </div>
    );
};
