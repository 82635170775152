import axios from 'axios';

import {
    GetVendorChartProductsWithSalesPathParams,
    GetVendorChartRequestBody,
    GetVendorChartTotalPathParams,
    GetVendorChartTotalsBreakdownPathParams,
    VendorBreakdownBucketsChartResponseBody,
    VendorCalcTotalResponseBody,
    VendorProductsWithSaleslResponseBody,
    VendorTotalsChartResponseBody,
} from '@sparkplug/lib';

import { useAdvancedQuery } from '@hooks/QueryHooks';

const API = {
    fetchVendorChartProductsWithSales: async (
        { vendorAccountId }: GetVendorChartProductsWithSalesPathParams,
        body: GetVendorChartRequestBody,
    ): Promise<VendorProductsWithSaleslResponseBody> => {
        return (
            await axios.post<VendorProductsWithSaleslResponseBody>(
                `/api/v1/sparkplug/chart/vendor/${vendorAccountId}/products_with_sales`,
                body,
            )
        ).data;
    },
    fetchVendorChartTotal: async (
        { vendorAccountId, calcType }: GetVendorChartTotalPathParams,
        body: GetVendorChartRequestBody,
    ): Promise<VendorCalcTotalResponseBody> => {
        return (
            await axios.post<VendorCalcTotalResponseBody>(
                `/api/v1/sparkplug/chart/vendor/${vendorAccountId}/${calcType}`,
                body,
            )
        ).data;
    },
    fetchVendorChartBucketBreakdown: async (
        { vendorAccountId, calcType, breakdownType }: GetVendorChartTotalsBreakdownPathParams,
        body: GetVendorChartRequestBody,
    ): Promise<VendorBreakdownBucketsChartResponseBody> => {
        return (
            await axios.post<VendorBreakdownBucketsChartResponseBody>(
                `/api/v1/sparkplug/chart/vendor/${vendorAccountId}/${calcType}/${breakdownType}/buckets`,
                body,
            )
        ).data;
    },
    fetchVendorChartTotalBreakdown: async (
        { vendorAccountId, calcType, breakdownType }: GetVendorChartTotalsBreakdownPathParams,
        body: GetVendorChartRequestBody,
    ): Promise<VendorTotalsChartResponseBody> => {
        return (
            await axios.post<VendorTotalsChartResponseBody>(
                `/api/v1/sparkplug/chart/vendor/${vendorAccountId}/${calcType}/${breakdownType}/totals`,
                body,
            )
        ).data;
    },
};

/**
 * Shared to ensure that all the hooks are not enabled if they don't have these properties
 */
const hasValidProperties = <P extends { vendorAccountId: string }>(
    params: P,
    body: GetVendorChartRequestBody,
) => {
    return (
        !!params.vendorAccountId &&
        !!body.dateStart &&
        !!body.dateEnd &&
        !!body.retailerAccountIds.length
    );
};

/**
 * We need to ensure that the body is always the same so that the cache is shared and there are
 * not any additional properties that are not needed such as `chartType`
 */
const parseRequestBody = ({
    dateStart,
    dateEnd,
    frequency,
    retailerAccountIds,
    exclusionFilterParams,
}: GetVendorChartRequestBody): GetVendorChartRequestBody => ({
    dateStart,
    dateEnd,
    frequency,
    retailerAccountIds,
    exclusionFilterParams,
});

export const useVendorChartProductsWithSales = (
    params: GetVendorChartProductsWithSalesPathParams,
    incomingBody: GetVendorChartRequestBody,
    isEnabled: boolean = true,
) => {
    const body = parseRequestBody(incomingBody);

    const { data: vendorChartProductsWithSales, isFetched: vendorChartProductsWithSalesAreReady } =
        useAdvancedQuery(
            ['vendorChartProductsWithSales', params, body],
            () => API.fetchVendorChartProductsWithSales(params, body),
            {
                enabled: isEnabled && hasValidProperties(params, body),
            },
        );

    return {
        vendorChartProductsWithSalesAreReady,
        vendorChartProductsWithSales,
    };
};

export const useVendorChartTotal = (
    params: GetVendorChartTotalPathParams,
    incomingBody: GetVendorChartRequestBody,
    isEnabled: boolean = true,
) => {
    const body = parseRequestBody(incomingBody);

    const { data: vendorChartTotal, isFetched: vendorChartTotalIsReady } = useAdvancedQuery(
        ['vendorChartTotal', params, body],
        () => API.fetchVendorChartTotal(params, body),
        {
            enabled: isEnabled && hasValidProperties(params, body),
        },
    );

    return {
        vendorChartTotalIsReady,
        vendorChartTotal,
    };
};

export const useVendorChartBucketBreakdown = (
    params: GetVendorChartTotalsBreakdownPathParams,
    incomingBody: GetVendorChartRequestBody,
    isEnabled: boolean = true,
) => {
    const body = parseRequestBody(incomingBody);

    const { data: vendorChartBucketBreakdown, isFetched: vendorChartBucketBreakdownIsReady } =
        useAdvancedQuery(
            ['vendorChartBucketBreakdown', params, body],
            () => API.fetchVendorChartBucketBreakdown(params, body),
            {
                enabled: isEnabled && hasValidProperties(params, body),
            },
        );

    return {
        vendorChartBucketBreakdownIsReady,
        vendorChartBucketBreakdown,
    };
};

export const useVendorChartTotalBreakdown = (
    params: GetVendorChartTotalsBreakdownPathParams,
    incomingBody: GetVendorChartRequestBody,
    isEnabled: boolean = true,
) => {
    const body = parseRequestBody(incomingBody);

    const { data: vendorChartTotalBreakdown, isFetched: vendorChartTotalBreakdownIsReady } =
        useAdvancedQuery(
            ['vendorChartTotalBreakdown', params, body],
            () => API.fetchVendorChartTotalBreakdown(params, body),
            {
                enabled: isEnabled && hasValidProperties(params, body),
            },
        );

    return {
        vendorChartTotalBreakdownIsReady,
        vendorChartTotalBreakdown,
    };
};
