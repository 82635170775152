import RewardsAPI from '@api/RewardsAPI';

import { IPublicReward, ListRewardsResponseBody } from '@sparkplug/lib';

import { ISparkPayout } from '@app/types/SparksTypes';

export const fetchRewards = async (
    sparkId?: string,
    posEmployeeProfileId?: string,
    offset?: number,
    limit: number = 999,
) => {
    const response = await RewardsAPI.getRewards({
        spark_id: sparkId,
        pos_employee_profile_id: posEmployeeProfileId,
        offset,
        limit,
    });

    return response;
};

export const getRewards = async (
    sparkId?: string,
    posEmployeeProfileId?: string,
    offset?: number,
    limit?: number,
): Promise<IPublicReward[]> => {
    const { data } = await fetchRewards(sparkId, posEmployeeProfileId, offset, limit);

    return data;
};

export const getCurrentUserRewards = async (): Promise<ListRewardsResponseBody['data']> => {
    return [];
};

export const getRewardPayoutParameters = async () => {
    const { data } = await RewardsAPI.getRewardPayoutParameters();

    return data;
};

export const getRewardById = async (rewardId: string) => {
    const data = await RewardsAPI.getRewardById({ rewardId });

    return data;
};

export const claimReward = async (rewardId: string) => {
    const { data } = await RewardsAPI.claimReward({ rewardId });

    return data;
};

export const deleteReward = async (rewardId: string) => {
    return RewardsAPI.deleteReward({ rewardId });
};

export const getRewardIdsFromSparkPayouts = (sparkPayouts: ISparkPayout[]): string[] => {
    const fbsRewardIds = sparkPayouts.filter(({ _id }) => _id != null).map(({ _id = '' }) => _id);

    return fbsRewardIds;
};
