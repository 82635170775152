import { useEffect, useMemo, useRef, useState } from 'react';

import { Spark } from '@sparkplug/lib';

import { IListItem } from '@components/inputs/ListSelector/ListSelector';

import { useAccountProductsFilters } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { IPosBrand, IPosCategory, IPosProduct } from '@app/types/PosTypes';

import { UseSparkProductSelectorTableFilters } from '../../SparkProductSelectorTable';
import { ProductFilterType } from '../SparkProductScopeSelector';

const initProductSelectorValues = ({ posBrandIds, posCategoryIds, productFilters }: Spark) => {
    let filterType: ProductFilterType = 'brands';
    let selectedCategoryIds: string[] = [];
    let selectedBrandIds: string[] = [];

    if (posCategoryIds?.length) {
        filterType = 'categories';
        selectedCategoryIds = posCategoryIds;
    } else if (productFilters?.categoryIds?.length) {
        filterType = 'categories';
        selectedCategoryIds = productFilters?.categoryIds || [];
    } else if (posBrandIds?.length) {
        filterType = 'brands';
        selectedBrandIds = posBrandIds;
    } else if (productFilters?.brandIds?.length) {
        filterType = 'brands';
        selectedBrandIds = productFilters?.brandIds || [];
    }

    const selectedCategories = selectedCategoryIds.map((value) => ({ value } as IPosCategory));
    const selectedBrands = selectedBrandIds.map((value) => ({ value } as IPosBrand));

    return {
        filterType,
        selectedCategoryIds,
        selectedCategories,
        selectedBrandIds,
        selectedBrands,
    };
};

type ProductFilters = ReturnType<typeof useAccountProductsFilters>['productFilters'];

export const useInitialization = ({
    spark,
    sparkPosDataIsReady,
    accountPosDataIsReady,
    setFilterType,
    updateProductFilters,
    associatedProducts,
    accountPosProducts,
    updateSelectedProductIds,
}: {
    spark: Spark;
    sparkPosDataIsReady: boolean;
    accountPosDataIsReady: boolean;
    setFilterType: (updatedFilterType: ProductFilterType) => void;
    updateProductFilters: (updatedProductFilters: ProductFilters) => void;
    associatedProducts: IPosProduct[];
    accountPosProducts: IPosProduct[];
    updateSelectedProductIds: (updatedValue: string[]) => void;
}) => {
    const [isReady, setIsReady] = useState<boolean>(false);

    useEffect(() => {
        const initialValues = initProductSelectorValues(spark);

        if (!isReady && accountPosDataIsReady && sparkPosDataIsReady) {
            setFilterType(initialValues.filterType);
            updateProductFilters({
                brandIds: initialValues.selectedBrandIds,
                categoryIds: initialValues.selectedCategoryIds,
            });

            const productsHaveBeenSelected =
                spark.posBrandIds.length ||
                spark.posCategoryIds.length ||
                spark.posProductIds.length ||
                spark.commissions.length;

            if (productsHaveBeenSelected) {
                // Initialize if all products
                const hasAllProductsSelected =
                    !spark.posBrandIds.length &&
                    !spark.posCategoryIds.length &&
                    !spark.posProductIds.length;

                if (!hasAllProductsSelected) {
                    updateSelectedProductIds(associatedProducts.map(({ value }) => value));
                }

                if (hasAllProductsSelected) {
                    updateSelectedProductIds(accountPosProducts.map(({ value }) => value));
                }
            }

            setIsReady(true);
        }
    }, [accountPosDataIsReady, sparkPosDataIsReady, associatedProducts, spark]);

    return { isReady };
};

export const useProductFilters = ({
    accountPosProducts,
}: {
    accountPosProducts: IPosProduct[];
}) => {
    const [filterType, setFilterType] = useState<ProductFilterType>('brands');

    const { productFilters, updateProductFilters, applyProductFilters } = useAccountProductsFilters(
        {
            brandIds: [],
            categoryIds: [],
        },
    );

    const filteredProductOptions = useMemo(() => {
        return [applyProductFilters].reduce((res, filterFn) => {
            return filterFn(res);
        }, accountPosProducts ?? []);
    }, [accountPosProducts, applyProductFilters]);

    return {
        filterType,
        setFilterType,
        productFilters,
        updateProductFilters,
        filteredProductOptions,
    };
};

interface IDeselectedProductsMap {
    [productId: string]: boolean;
}

export const useScope = ({
    initialDeselectedProductsMap = {},
    setFilterType,
    productFilters,
    updateProductFilters,
    accountPosProducts,
    selectedProductIds,
    updateSelectedProductIds,
    tableFilters,
}: {
    initialDeselectedProductsMap?: IDeselectedProductsMap;
    setFilterType: (updatedFilterType: ProductFilterType) => void;
    productFilters: ProductFilters;
    updateProductFilters: (updatedProductFilters: ProductFilters) => void;
    accountPosProducts: IPosProduct[];
    selectedProductIds: string[];
    updateSelectedProductIds: (updatedValue: string[]) => void;
    tableFilters: UseSparkProductSelectorTableFilters;
}) => {
    const deselectedProductMap = useRef<IDeselectedProductsMap>(initialDeselectedProductsMap);
    const state = useRef({
        // We do not want to update `deselectedProductMap` if we are updating scope options
        isUpdatingSelectedScopeOptions: false,
        confirmAction: () => {},
    });

    const [warningMessage, setWarningMessage] = useState<string>();

    const onSwitchScope = (updatedProductFilterType: ProductFilterType) => {
        const onSelect = () => {
            deselectedProductMap.current = {};
            updateSelectedProductIds([]);
            setFilterType(updatedProductFilterType);
            updateProductFilters({
                brandIds: [],
                categoryIds: [],
            });
        };

        if (productFilters.brandIds.length || productFilters.categoryIds.length) {
            setWarningMessage(
                'Changing the filter type will clear your current product selections. Do you want to continue?',
            );
            state.current.confirmAction = onSelect;
        } else {
            onSelect();
        }
    };

    const onConfirmSwitchScope = () => {
        state.current.confirmAction();
        state.current.confirmAction = () => {};
    };

    const onCancelSwitchScope = () => {
        setWarningMessage(undefined);
        state.current.confirmAction = () => {};
    };

    const updateSelectedProductsByUpdatedProductFilters = (
        updatedProductFilters: ProductFilters,
    ) => {
        state.current.isUpdatingSelectedScopeOptions = true;

        if (!updatedProductFilters.brandIds.length && !updatedProductFilters.categoryIds.length) {
            updateSelectedProductIds([]);
            return;
        }
        const { applyHideSampleProducts, applyLastSoldFilter, applySearch } = tableFilters;

        const scopeFilterFn = updatedProductFilters.brandIds.length
            ? (product: IPosProduct) =>
                  updatedProductFilters.brandIds.includes(product.brandId ?? '')
            : (product: IPosProduct) =>
                  updatedProductFilters.categoryIds.includes(product.categoryId ?? '');
        const applyScopeFn = (products: IPosProduct[]) => products.filter(scopeFilterFn);

        const filteredProductOptions = [
            applyScopeFn,
            applyHideSampleProducts,
            applyLastSoldFilter,
            applySearch,
        ].reduce((res, filterFn) => filterFn(res), accountPosProducts);

        const deselectedProductValues = Object.entries(deselectedProductMap.current)
            .map(([productId, isDeselected]) => (isDeselected ? productId : ''))
            .filter((productId) => productId);
        const newFilteredProductIds = filteredProductOptions
            .filter(({ value }) => {
                return !deselectedProductValues.includes(value);
            })
            .map(({ value }) => value);

        updateSelectedProductIds(newFilteredProductIds);
    };

    const onUpdateBrands = (selected: IListItem[]) => {
        const updatedProductFilters = {
            brandIds: selected.map(({ value }) => value),
            categoryIds: [],
        };
        updateProductFilters(updatedProductFilters);
        updateSelectedProductsByUpdatedProductFilters(updatedProductFilters);
    };

    const onUpdateCategories = (selected: IListItem[]) => {
        const updatedProductFilters = {
            brandIds: [],
            categoryIds: selected.map(({ value }) => value),
        };
        updateProductFilters(updatedProductFilters);
        updateSelectedProductsByUpdatedProductFilters(updatedProductFilters);
    };

    // Update `deselectedProductMap`
    useEffect(() => {
        if (!state.current.isUpdatingSelectedScopeOptions) {
            // Set all existing to `isDeselected`
            Object.keys(deselectedProductMap.current).forEach((productId) => {
                deselectedProductMap.current[productId] = true;
            });

            // Set all selected to `isDeselected = false`
            selectedProductIds.forEach((productId) => {
                deselectedProductMap.current[productId] = false;
            });
        }

        state.current.isUpdatingSelectedScopeOptions = false;
    }, [selectedProductIds]);

    return {
        deselectedProductMap,
        warningMessage,
        onCancelSwitchScope,
        onConfirmSwitchScope,
        onSwitchScope,
        onUpdateBrands,
        onUpdateCategories,
    };
};
