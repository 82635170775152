import { useCallback, useEffect, useMemo, useState } from 'react';

import { EmployeeSpark } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import SwipeButton from '@components/buttons/SwipeButton';
import { AnimatedGiftGraphic } from '@components/graphics';
import { GiftCardIcon, OpenInNew as OpenLinkIcon } from '@components/icons';
import Modal, { IDefaultModalProps } from '@components/overlays/Modal';
import toast from '@components/toast';

import { claimSparkPayout } from '@helpers/sparks';
import { appendClasses, formatNumberToCurrency, openUrl } from '@helpers/ui';
import { isEmpty, isFloat } from '@helpers/util';

import { ISparkPayout } from '@app/types/SparksTypes';

import './SparkPayoutClaimModal.scss';

interface ISparkPayoutClaimModalProps extends IDefaultModalProps {
    spark: EmployeeSpark;
    payout: ISparkPayout;
}

const SparkPayoutClaimModal = ({
    isVisible = false,
    onClose,
    spark,
    payout,
}: ISparkPayoutClaimModalProps) => {
    const [isClaimed, setIsClaimed] = useState(false);
    const [isClaiming, setIsClaiming] = useState(false);
    const [updatedDeliveryLink, setUpdatedDeliveryLink] = useState<string>();
    const isFulfilledBySparkplug = payout?.fulfilledBySparkplug;
    const amountHasCents = isFloat(payout?.amount);

    useEffect(() => {
        setIsClaimed(payout?.status === 'claimed');
    }, [payout?.status]);

    const onSwipe = () => {
        setIsClaiming(true);

        claimSparkPayout(payout)
            .then((newDeliveryLink) => {
                setIsClaiming(false);
                setIsClaimed(true);
                setUpdatedDeliveryLink(newDeliveryLink);
            })
            .catch(() => {
                toast.error('Something went wrong');
            });
    };

    const deliveryLink = useMemo(() => {
        if (!isFulfilledBySparkplug) {
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            const match = (payout?.claimInstructions || '').match(urlRegex);
            const parsedLink = match?.[0];
            return parsedLink;
        }

        return !isEmpty(payout?.deliveryLink) ? payout?.deliveryLink : updatedDeliveryLink;
    }, [payout?.claimInstructions, payout?.deliveryLink, updatedDeliveryLink]);

    const onClaim = useCallback(() => {
        if (!isEmpty(deliveryLink) && deliveryLink) {
            openUrl(deliveryLink);
        }
    }, [deliveryLink]);

    const classNamesAppended = useMemo(
        () =>
            appendClasses([
                'spark-payout-claim-modal',
                isClaimed ? 'is-claimed' : null,
                isFulfilledBySparkplug ? 'is-fbs' : 'is-not-fbs',
            ]),
        [isClaimed],
    );

    const message = useMemo(
        () => (
            <>
                {isClaimed
                    ? `Congrats! Use the button below to claim your prize for the `
                    : `Congrats! Swipe to claim your reward for `}
                <strong>{spark?.name}</strong>!
            </>
        ),
        [isClaimed],
    );

    const sparkPayoutText = isFulfilledBySparkplug
        ? formatNumberToCurrency(payout?.amount, amountHasCents)
        : payout?.text;
    const endIcon = isFulfilledBySparkplug ? <OpenLinkIcon /> : null;

    return (
        <Modal
            keepMounted={false}
            className={classNamesAppended}
            isVisible={isVisible}
            onClose={() => onClose(isClaimed)}
        >
            <Modal.Title onClose={() => onClose(isClaimed)}>Claim Reward</Modal.Title>
            <Modal.Content>
                <AnimatedGiftGraphic startPlay={isClaimed} />
                <h3>{isClaimed ? 'Congratulations!' : 'Claim Reward'}</h3>
                <p className="spark-payout-claim-modal_message">{message}</p>
                <div className="claim-amount">
                    <GiftCardIcon />
                    <span>{sparkPayoutText}</span>
                </div>
                {!isClaimed ? (
                    <SwipeButton
                        mainText="Swipe right to claim reward"
                        overlayText={isClaiming ? 'Claiming...' : 'Swipe right to claim reward'}
                        onSwipe={onSwipe}
                    />
                ) : (
                    <Button // TODO Tech-debt: replace this logic with <NonCashRewardClaimInstructions/>
                        className="claim-btn"
                        color="green"
                        variant="filled"
                        endIcon={endIcon}
                        onClick={onClaim}
                    >
                        {isFulfilledBySparkplug ? (
                            'Claim It'
                        ) : (
                            <span className="claim-btn-inner">
                                <strong>Claim Instructions</strong>
                                <span>{payout?.claimInstructions || 'Talk to manager'}</span>
                            </span>
                        )}
                    </Button>
                )}
            </Modal.Content>
        </Modal>
    );
};

export default SparkPayoutClaimModal;
