import { useMemo } from 'react';

import { HydratedVendorPosProduct } from '@sparkplug/lib';

import ProductInventoryTable from '@features/inventory/components/ProductInventoryTable';
import { useProductInventoryTableFilters } from '@features/inventory/components/ProductInventoryTable/useProductInventoryTableFilters';
import { SparkDetailsInventoryToolbar } from '@features/inventory/components/SparkDetailsInventoryToolbar/SparkDetailsInventoryToolbar';
import { prepareInventoryData } from '@features/inventory/utils';

import Paper from '@components/layout/Paper';

import { IPosProduct } from '@app/types/PosTypes';
import { IOption } from '@app/types/UITypes';

export interface SparkDetailsInventoryViewProps {
    showLocationsDropdown: boolean;
    locations: IOption[];
    products: (HydratedVendorPosProduct | IPosProduct)[];
    accountType?: 'brand' | 'retailer';
    retailerAccountId: string;
    inventoryEnabled: boolean;
    isLoading: boolean;
}

const SparkDetailsInventoryView = ({
    showLocationsDropdown,
    locations,
    products,
    accountType,
    retailerAccountId,
    inventoryEnabled,
    isLoading,
}: SparkDetailsInventoryViewProps) => {
    const {
        numProductsMissingInventory,
        productsByLocation,
        brandOptions,
        categoryOptions,
        productOptions,
        locationOptions,
    } = useMemo(() => prepareInventoryData(products, locations), [products, locations]);

    const {
        searchFilter,
        applySearch,
        filters,
        tableFilters,
        updateFilters,
        onChangeSearchFilter,
        visibleLocationOptions,
        selectedLocationCountProps,
    } = useProductInventoryTableFilters({
        initialFilters: {},
        isRetailerDashboard: false,
        initialProducts: productsByLocation,
        brandOptions,
        categoryOptions,
        productOptions,
        locationOptions,
    });

    return (
        <Paper className="section">
            <SparkDetailsInventoryToolbar
                {...{
                    showLocationsDropdown: showLocationsDropdown || numProductsMissingInventory > 0,
                    onChangeSearchFilter,
                    searchFilter,
                    filters,
                    updateFilters,
                    locationOptions,
                    visibleLocationOptions,
                    selectedLocationCountProps,
                }}
            />
            <ProductInventoryTable
                numProductsMissingInventory={numProductsMissingInventory}
                accountType={accountType}
                products={productsByLocation}
                inventoryEnabled={inventoryEnabled}
                retailerAccountId={retailerAccountId}
                isLoading={isLoading}
                filters={[applySearch, ...tableFilters]}
            />
        </Paper>
    );
};

export default SparkDetailsInventoryView;
