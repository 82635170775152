import { FC, useState } from 'react';

import { AccountType } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import { HelpGraphic } from '@components/graphics';
import {
    ChatIcon,
    CardGiftcard as GiftCardIcon,
    HelpCenterOutlinedIcon,
    IdeaIcon,
} from '@components/icons';
import AppHeaderMobile from '@components/layout/AppHeaderMobile';
import Badge from '@components/layout/Badge';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';

import { useApp, useAppBodyClasses } from '@hooks/AppHooks';
import {
    openHelpCenter,
    useFrillAdminAnnouncementsWidget,
    useFrillAdminFeedbackWidget,
    useFrillEmployeeAnnouncementWidget,
    useFrillEmployeeFeedbackWidget,
} from '@hooks/FrillHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import Intercom from '@helpers/Intercom';

import { HelpCenterNoDesktopGraphic } from './HelpCenterNoDesktopGraphic';

import './HelpCenterView.scss';

const NotAvailableOnDesktopDisplay: FC = () => {
    return (
        <div className="help-center-page_not-available">
            <EmptyStateDisplay
                graphic={<HelpCenterNoDesktopGraphic />}
                label="Not available on desktop"
                smallText={
                    <>
                        Please <strong>go to the Help (?) option</strong> in the top nav to access
                        the help resources.
                    </>
                }
            />
        </div>
    );
};

const AnnouncementsIcon: FC<{ count: number }> = ({ count }) => (
    <Badge count={count}>
        <GiftCardIcon />
    </Badge>
);

export const HelpCenterViewMobile: FC<{ accountType: AccountType }> = ({ accountType }) => {
    const { isAdminApp } = useApp();
    const isEmployeeApp = !isAdminApp;

    useAppBodyClasses(['app-bgWhite', 'app-content-noPadding'], []);

    const adminFeedbackWidget = useFrillAdminFeedbackWidget(accountType);
    const [adminAnnouncementCount, setAdminAnnouncementCount] = useState(0);
    const adminAnnoucementsWidget = useFrillAdminAnnouncementsWidget(accountType, {
        onBadgeCount: ({ count }) => setAdminAnnouncementCount(count),
    });

    const employeeFeedbackWidget = useFrillEmployeeFeedbackWidget();
    const [employeeAnnouncementCount, setEmployeeAnnouncementCount] = useState(0);
    const employeeAnnouncementsWidget = useFrillEmployeeAnnouncementWidget({
        onBadgeCount: ({ count }) => setEmployeeAnnouncementCount(count),
    });

    const contactUsMenuButton = (
        <Button
            variant="outlined"
            color="neutral"
            startIcon={<ChatIcon />}
            onClick={() => Intercom.open()}
        >
            Contact Us
        </Button>
    );

    const helpArticlesMenuButton = (
        <Button
            variant="outlined"
            color="neutral"
            startIcon={<HelpCenterOutlinedIcon />}
            onClick={() => openHelpCenter()}
        >
            Help Articles
        </Button>
    );

    const feedbackMenuButton = (
        <Button
            variant="outlined"
            color="neutral"
            startIcon={<IdeaIcon />}
            onClick={() => {
                if (isAdminApp) {
                    adminFeedbackWidget.current?.open();
                } else {
                    employeeFeedbackWidget.current?.open();
                }
            }}
        >
            Share an Idea
        </Button>
    );

    const announcementsMenuButton = (
        <Button
            variant="outlined"
            color="neutral"
            startIcon={
                <AnnouncementsIcon
                    count={isAdminApp ? adminAnnouncementCount : employeeAnnouncementCount}
                />
            }
            onClick={() => {
                if (isAdminApp) {
                    adminAnnoucementsWidget.current?.open();
                } else {
                    employeeAnnouncementsWidget.current?.open();
                }
            }}
        >
            {`What's New`}
        </Button>
    );

    return (
        <section className="mobile-help-center-page">
            {isEmployeeApp && <AppHeaderMobile title="Help Center" />}

            <article className="help-center_graphic-container">
                <HelpGraphic />
            </article>

            <article className="help-center_menu">
                {contactUsMenuButton}

                {isAdminApp && helpArticlesMenuButton}

                {feedbackMenuButton}

                {announcementsMenuButton}
            </article>
        </section>
    );
};

export const HelpCenterPage: FC = () => {
    const { appIsMobile } = useApp();
    const { account } = useSparkplugAccount();

    if (!account?.type) {
        return <></>;
    }

    if (appIsMobile) {
        return <HelpCenterViewMobile accountType={account.type} />;
    }

    return <NotAvailableOnDesktopDisplay />;
};
