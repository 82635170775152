import { FC, useMemo } from 'react';

import { DetailedSparkType } from '@sparkplug/lib';

import CalloutMessage from '@components/layout/CalloutMessage';

import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import {
    countRetailersAndLocations,
    renderSparkCountMessage,
} from '../../SparkParticipantsSelector/components/MultiRetailerParticipantSelector/SparkCountCalloutMessage/helpers';

import './MultiRetailerSparkPrizeCalloutMessage.scss';

interface MultiRetailerSparkPrizeCalloutMessageProps {}
const PrizeMessage = ({
    detailedSparkType,
    numberOfLocations,
}: {
    detailedSparkType: DetailedSparkType;
    numberOfLocations: number;
}) => {
    const Messages: Partial<Record<DetailedSparkType, FC<{ numberOfLocations?: number }>>> = {
        leaderboard: () => (
            <>Prizes below will be awarded once to a single set of leaderboard winners.</>
        ),
        leaderboardMulti: () => (
            <>
                Prizes below will be awarded <strong>{numberOfLocations}</strong> times over to each
                set of leaderboard winners.
            </>
        ),
        leaderboardLocation: () => (
            <>
                Prizes below will be awarded to each Team (Location), based on their final standing
                at the end of the Spark.
            </>
        ),
        goal: () => (
            <>
                If an employee hits the goal(s) below, they will be awarded the corresponding
                reward.
            </>
        ),
        goalTeam: () => (
            <>
                If the selected locations at each Retailer collectively hit the goal(s) below, the
                following reward method will apply.
            </>
        ),
        goalManager: () => (
            <>
                If the selected locations at each Retailer collectively hit the goal(s) below, the
                following reward method will apply.
            </>
        ),
    };
    const Component = Messages[detailedSparkType];
    return Component ? <Component numberOfLocations={numberOfLocations} /> : <></>;
};

const MultiRetailerSparkPrizeCalloutMessage: FC<
    MultiRetailerSparkPrizeCalloutMessageProps
> = () => {
    const { detailedSparkType, spark, multiRetailerParticipantGroups } = useSpark();

    const { numberOfRetailers, numberOfLocations } = useMemo(
        () => countRetailersAndLocations(multiRetailerParticipantGroups),
        [multiRetailerParticipantGroups],
    );

    if (!detailedSparkType || !numberOfRetailers || !numberOfLocations) {
        return <></>;
    }
    const message = renderSparkCountMessage(
        detailedSparkType,
        numberOfRetailers,
        numberOfLocations,
    );
    switch (spark.type) {
        case 'leaderboard':
            return (
                <CalloutMessage
                    message={
                        <>
                            {message}{' '}
                            <PrizeMessage
                                detailedSparkType={detailedSparkType}
                                numberOfLocations={numberOfLocations}
                            />
                        </>
                    }
                />
            );
        case 'goal':
            return (
                <CalloutMessage
                    message={
                        <>
                            {message}{' '}
                            <PrizeMessage
                                detailedSparkType={detailedSparkType}
                                numberOfLocations={numberOfLocations}
                            />
                        </>
                    }
                />
            );

        default:
            return <></>;
    }
};

export default MultiRetailerSparkPrizeCalloutMessage;
