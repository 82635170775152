import { MutableRefObject, useEffect } from 'react';

import { isEmpty } from 'lodash';

import { Spark, SparkCommissionType } from '@sparkplug/lib';

import { UpdateCallback } from '@contexts/ModalContext';

import toast from '@components/toast';

import { useFormContext } from '@hooks/FormHooks';
import { useModal } from '@hooks/ModalHooks';

import { CommissionMap } from '../helpers/getHeadCells';
import { ProductCommissionRow } from './useCommissionTableProducts';

/**
 * Because `single-commission-value` uses the react-hook-form validation, we need
 * to use the useFormContext to access the errors and formState objects. Then have
 * the toast conditionally display when the form is submitted by having
 * `formState.submitCount` as a dependency.
 */
const useSingleCommissionValidation = () => {
    const { errors, formState } = useFormContext();

    useEffect(() => {
        if (errors['single-commission-value']?.type === 'required') {
            toast.error('Products missing commission');
        }
    }, [formState.submitCount]);
};

export const useCommissionTableValidation = ({
    singleCommissionsEnabled,
    spark,
    associatedProductIds,
    originalCommissionValuesMap,
    commissionValuesRef,
    sparkCommissionType,
    updateShowInvalidValues,
    updateSparkContextCommissions,
    productRows,
}: {
    singleCommissionsEnabled: boolean;
    spark: Spark;
    associatedProductIds: string[];
    sparkCommissionType?: SparkCommissionType;
    originalCommissionValuesMap: CommissionMap;
    commissionValuesRef: MutableRefObject<CommissionMap>;
    updateShowInvalidValues: () => void;
    updateSparkContextCommissions: (callback?: UpdateCallback) => void;
    productRows: ProductCommissionRow[];
}) => {
    const { updateValidationFn } = useModal();
    useSingleCommissionValidation();

    updateValidationFn((callback) => {
        const validation = [
            () => {
                const isCreatingSpark = !spark._id;
                const hasCommissionRuleType = !!spark.commissionRules?.type;

                if (singleCommissionsEnabled && isCreatingSpark && !hasCommissionRuleType) {
                    toast.error('Please select a commission structure before continuing');

                    return false;
                }

                return true;
            },
            () => {
                let productsToUpdate = associatedProductIds;
                if (!isEmpty(spark.vendorFilters)) {
                    productsToUpdate = associatedProductIds.filter(
                        (id) => originalCommissionValuesMap[id] === undefined,
                    );
                }

                const lessThanZero = productsToUpdate.filter((productId) => {
                    const commissionValue = commissionValuesRef.current[productId];
                    return !commissionValue || commissionValue <= 0;
                });
                const hasValidValues = productsToUpdate.every((productId) => {
                    const commissionValue = commissionValuesRef.current[productId];

                    return commissionValue > 0;
                });
                const greaterThan100 = productsToUpdate.filter((productId) => {
                    const commissionValue = commissionValuesRef.current[productId];

                    return !commissionValue || commissionValue <= 0 || commissionValue >= 100;
                });

                if (
                    lessThanZero.length ||
                    (sparkCommissionType === 'percentage' && greaterThan100.length)
                ) {
                    updateShowInvalidValues();

                    toast.error(
                        `All commission values must be a number greater than zero${
                            sparkCommissionType === 'percentage' ? ' and less than 100' : ''
                        }`,
                    );

                    // scroll to the first invalid product
                    const invalidProductIds = [...lessThanZero, ...greaterThan100];
                    const productRow = productRows.find((row) =>
                        invalidProductIds.includes(row._id),
                    );
                    const el = document.querySelector(
                        `[data-testid="textField.${productRow?._id}-value"]`,
                    );
                    if (el) {
                        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                }

                return hasValidValues;
            },
        ];
        const allValid = validation.every((fn) => fn());

        // Update spark provider on stage switch
        if (allValid) {
            updateSparkContextCommissions(callback);
        }

        return allValid;
    });
};
