import { FC, ReactElement } from 'react';

import ButtonGroup, { IButtonGroupProps } from '@components/buttons/ButtonGroup';

import { appendClasses } from '@helpers/ui';

import { useIsInToolbar } from '../Toolbar/useIsInToolbar';
import ToolbarGroup from '../ToolbarGroup';

import './ToolbarButtonGroup.scss';

interface ToolbarButtonGroupProps extends Omit<IButtonGroupProps, 'children'> {
    className?: string;
    value?: string;
    onSelect: (value: string) => void;
    options: {
        value: string;
        label: string | ReactElement;
        startIcon?: ReactElement;
    }[];
}

const ToolbarButtonGroup: FC<ToolbarButtonGroupProps> = ({
    className,
    value,
    options,
    onSelect,
    ...props
}) => {
    useIsInToolbar({ componentName: 'Toolbar.ButtonGroup' });

    const classNameAppended = appendClasses([className, 'toolbar-button-group']);

    return (
        <ToolbarGroup className={classNameAppended}>
            <ButtonGroup {...props}>
                {options.map((option) => {
                    const active = option.value === value;
                    return (
                        <ButtonGroup.Button
                            key={`button-group-toggle-${option.value}`}
                            className={active ? 'active' : 'not-active'}
                            onClick={() => onSelect(option.value)}
                            startIcon={option.startIcon || null}
                        >
                            {option.label}
                        </ButtonGroup.Button>
                    );
                })}
            </ButtonGroup>
        </ToolbarGroup>
    );
};

export default ToolbarButtonGroup;
