import { FC, ReactElement } from 'react';

import './AppContentWithSidebar.scss';

const classPrefix = 'app-content-with-sidebar';

type AppContentWithSidebarProps = {
    sidebar: ReactElement;
    content: ReactElement;
};
const AppContentWithSidebar: FC<AppContentWithSidebarProps> = ({ sidebar, content }) => {
    return (
        <div className={`${classPrefix}__container`}>
            <div className={`${classPrefix}__sidebar`}>{sidebar}</div>
            <div className={`${classPrefix}__content`}>{content}</div>
        </div>
    );
};

export default AppContentWithSidebar;
