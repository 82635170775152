import { ReactNode } from 'react';

import TransactionDetailsContent from './TransactionDetailsContent';
import TransactionDetailsHeader from './TransactionDetailsHeader';

import './TransactionDetails.scss';

interface TransactionDetailsProps {
    children: ReactNode;
}
const TransactionDetails = ({ children }: TransactionDetailsProps) => {
    return <div className="transaction-details-container">{children}</div>;
};
TransactionDetails.Header = TransactionDetailsHeader;
TransactionDetails.Content = TransactionDetailsContent;

export default TransactionDetails;
