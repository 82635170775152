import { ReactElement } from 'react';

import { appendClasses } from '@helpers/ui';

import { EMPTY_COLOR } from '../nivo/Nivo.Constants';

import './SliceTooltip.scss';

export type IPointLabel = {
    name: string | ReactElement;
    value: string | ReactElement;
    color: string;
    outlined?: boolean;
    fontColor?: string;
};

interface ISliceTooltipProps {
    className?: string;
    title: string;
    subTitle?: string;
    points: IPointLabel[];
    total?: number | string;
    chip?: ReactElement;
}

export const getMutedPointLabel = (name: string, value: string): IPointLabel => {
    return {
        outlined: true,
        color: EMPTY_COLOR,
        name: <em style={{ color: EMPTY_COLOR }}>{name}</em>,
        value: <em style={{ color: EMPTY_COLOR, fontWeight: 'normal' }}>{value}</em>,
    };
};

const SliceTooltip = ({ className, title, subTitle, points, total, chip }: ISliceTooltipProps) => {
    const classNamesAppended = appendClasses(['slice-tooltip', className]);

    return (
        <div className={classNamesAppended}>
            <div className="slice-tooltip-title">
                <span className="slice-tooltip-title_label">
                    <span>{title}</span>
                    {subTitle && <span className="label_subtitle">{subTitle}</span>}
                </span>
                {total && <span className="total">{total}</span>}
                {chip}
            </div>
            <table className="slice-tooltip-points">
                <tbody>
                    {points.map(({ color, name, value, outlined }, i) => (
                        <tr key={`slice-tooltip-point-${i}`}>
                            <td>
                                {!outlined ? (
                                    <i
                                        className="slice-tooltip-point-color"
                                        style={{ backgroundColor: color }}
                                    />
                                ) : (
                                    <i
                                        className="slice-tooltip-point-color outlined"
                                        style={{ borderColor: color }}
                                    />
                                )}
                            </td>
                            <td>{name}</td>
                            <td>{value}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SliceTooltip;
