import axios from 'axios';

import { ISnap, ListAccountSnapsResponseBody } from '@sparkplug/lib';

import { useAdvancedQuery } from '@hooks/QueryHooks';

const API = {
    getFeaturedSnapsForAccount: async (accountId: string) => {
        return (
            await axios.get<ListAccountSnapsResponseBody>(
                `/api/v1/accounts/${accountId}/snaps?featured=true`,
            )
        ).data;
    },
    getFeaturedSnapsForUser: async () => {
        return (await axios.get<ISnap[]>(`/api/v1/employee-data/snaps/featured`)).data;
    },
};

export const useFeaturedSnaps = (accountId: string, snapsEnabled: boolean) => {
    const { isFetched: featuredSnapsAreReady, data } = useAdvancedQuery(
        ['featuredSnaps', accountId],
        () => API.getFeaturedSnapsForAccount(accountId || ''),
        {
            enabled: !!snapsEnabled,
        },
    );
    return { featuredSnapsAreReady, featuredSnaps: data?.snaps };
};

export const useEmployeeFeaturedSnaps = () => {
    const { isFetched: employeeFeaturedSnapsAreReady, data } = useAdvancedQuery(
        ['employeeFeaturedSnaps'],
        () => API.getFeaturedSnapsForUser(),
    );
    return { employeeFeaturedSnapsAreReady, employeeFeaturedSnaps: data };
};
