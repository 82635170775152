import { FC, useMemo, useState } from 'react';

import { PublicQuickViewQuery } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import {
    ArrowBackIos as ArrowBackIcon,
    KeyboardArrowDown as ArrowDownIcon,
} from '@components/icons';
import Spinner from '@components/layout/Spinner';

import { IOption } from '@app/types/UITypes';

import { QuickViewPopoverWidget } from '../QuickViewPopoverWidget';

import './MainQuickViewWidget.scss';

type QuickViewMenuType = 'apply-menu' | 'edit-menu' | 'delete-menu';

const ApplyQuickViewMenu = ({
    setCurrentMenu,
}: {
    setCurrentMenu: (v: QuickViewMenuType) => void;
}) => {
    return (
        <div className="apply-quick-views">
            Apply - TODO
            <Button onClick={() => setCurrentMenu('edit-menu')}>Edit</Button>
            <Button onClick={() => setCurrentMenu('delete-menu')}>Delete</Button>
        </div>
    );
};

const EditQuickViewMenu: FC = () => {
    return <div className="edit-quick-views">Edit - TODO</div>;
};

const DeleteQuickViewMenu: FC = () => {
    return <div className="delete-quick-views">Delete - TODO</div>;
};

interface MainQuickViewWidgetProps {
    isReady: boolean;
    quickViewOptions: IOption[];
    selectedQuickView?: PublicQuickViewQuery;
    onSelectQuickView: (quickViewId: string) => void;
}
export const MainQuickViewWidget: FC<MainQuickViewWidgetProps> = ({
    isReady,
    quickViewOptions = [],
    selectedQuickView,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSelectQuickView,
}) => {
    const [currentMenu, setCurrentMenu] = useState<'apply-menu' | 'edit-menu' | 'delete-menu'>(
        'apply-menu',
    );

    const { content, title } = useMemo(() => {
        if (!isReady) {
            return {
                title: 'Quick Views...',
                content: <Spinner className="quick-view_spinner" />,
            };
        }

        switch (currentMenu) {
            case 'edit-menu':
                return {
                    title: 'Manage QuickViews',
                    body: <EditQuickViewMenu />,
                };

            case 'delete-menu':
                return {
                    title: 'Delete Quick View',
                    content: <DeleteQuickViewMenu />,
                };

            case 'apply-menu':
            default:
                return {
                    title: 'Quick Views',
                    content: <ApplyQuickViewMenu setCurrentMenu={setCurrentMenu} />,
                };
        }
    }, [isReady, currentMenu, quickViewOptions, selectedQuickView]); // TODO keep this updated

    const resetWidgetState = () => setCurrentMenu('apply-menu');

    return (
        <QuickViewPopoverWidget
            PopoverButton={({ openPopover }) => (
                <Button
                    className={selectedQuickView ? 'selected' : ''}
                    onClick={openPopover}
                    endIcon={<ArrowDownIcon />}
                >
                    {selectedQuickView?.name ?? 'Apply Quick View'}
                </Button>
            )}
            title={
                <div className="main-quick-view-widget_content">
                    {currentMenu !== 'apply-menu' && (
                        <IconButton
                            className="back-button"
                            onClick={() => setCurrentMenu('apply-menu')}
                        >
                            <ArrowBackIcon fontSize="small" />
                        </IconButton>
                    )}
                    {title}
                </div>
            }
            content={content!}
            onClose={resetWidgetState}
        />
    );
};
