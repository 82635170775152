import { FC, useCallback, useState } from 'react';

import { useSaveAccountMutation } from '@core/accounts/mutations';

import LabeledSwitch from '@components/inputs/LabeledSwitch';
import AppView from '@components/layout/AppView';
import CalloutMessage from '@components/layout/CalloutMessage';
import Paper from '@components/layout/Paper';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { CheckedEventOnChange } from '@app/types/UITypes';

import './DataPrivacyView.scss';

export interface DataPrivacyViewProps {
    accountId: string;
    accountName: string;
    autoLinkingEnabled: boolean;
}
export const DataPrivacyView: FC<DataPrivacyViewProps> = ({
    accountName,
    accountId,
    autoLinkingEnabled,
}) => {
    const { saveAccount, saveAccountMutationLoading } = useSaveAccountMutation({
        account: { _id: accountId, name: accountName },
        runUpdate: false,
    });
    const [autoLinkingValue, setAutoLinkingValue] = useState(autoLinkingEnabled);

    const handleAutoLinkingToggle = useCallback<CheckedEventOnChange>(
        (event) => {
            const isChecked = event.target.checked;
            setAutoLinkingValue(isChecked);
            saveAccount({ autoLinkingEnabled: isChecked });
        },
        [saveAccount],
    );

    return (
        <div className="data-privacy-view mr-28">
            <Paper className="p-6">
                <span className="text-gray-900">Data Privacy</span>
                <CalloutMessage
                    className="mt-4 mb-2"
                    message={
                        <div>
                            <h4 className="font-semibold text-lg pb-2px">
                                How does auto-linking benefit my business?
                            </h4>
                            SparkPlug uses enterprise-grade, bank-level security and encryption to
                            give you full control over how your data is shared with your partners.
                            With the Vendor auto-linking feature, SparkPlug only links the brand in
                            your Point-of-Sale to the Vendor brands if there is an exact match. Once
                            a match is detected, a link will be established so that the Vendor can
                            offer sponsored Sparks for products in your stores.
                        </div>
                    }
                    customIcon="padlock"
                />
                <LabeledSwitch
                    disabled={saveAccountMutationLoading}
                    title="Vendor Auto-Linking"
                    subtitle="Enable this setting to grow your partnerships and remove the hassle of managing links yourself. You can always disable Vendors links after they have been established."
                    value={autoLinkingValue ?? false}
                    label={autoLinkingValue ? 'Enabled' : 'Disabled'}
                    onChange={handleAutoLinkingToggle}
                />
            </Paper>
        </div>
    );
};

export default () => {
    const { account } = useSparkplugAccount();
    return (
        <AppView.Content title="Data Privacy">
            <DataPrivacyView
                accountId={account?._id ?? ''}
                accountName={account?.name ?? ''}
                autoLinkingEnabled={account?.autoLinkingEnabled ?? false}
            />
        </AppView.Content>
    );
};
