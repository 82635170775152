import { ReactElement, ReactNode } from 'react';

import './TransactionDetails.scss';

interface TransactionDetailsContentProps {
    children?: ReactNode;
    className?: string;
    headerImage?: ReactElement;
    chip?: ReactElement;
    title?: string;
    subtitle?: string;
    rewardDetail?: ReactElement;
}

const TransactionDetailsHeader = ({
    children,
    className,
    headerImage,
    chip,
    title,
    subtitle,
    rewardDetail,
}: TransactionDetailsContentProps) => {
    return (
        <div className={`${className} transaction-header`}>
            {headerImage}
            {chip && <div className="chip">{chip}</div>}
            <div className="title">{title}</div>
            <div className="subtitle">{subtitle}</div>
            {rewardDetail && <div>{rewardDetail}</div>}

            {children}
        </div>
    );
};

export default TransactionDetailsHeader;
