import { useState } from 'react';
import toast from 'react-hot-toast';

import { TrainingCourseAPI } from '@api/TrainingCourseAPI';
import { has } from 'lodash';

import { CourseType, ITrainingCourse, Spark } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import Modal, { IDefaultModalProps } from '@components/overlays/Modal';
import { TrainingCourseForm } from '@components/sparks/TrainingCourseForm';

import { validateZoltrainCourse } from '@helpers/training';

interface CreateEditTrainingModalProps extends IDefaultModalProps {
    accountCanAddCourse?: boolean;
    accountCourseType?: CourseType;
    spark: Spark;
}

interface TrainingUpdate extends ITrainingCourse {
    trainingEnabled?: boolean;
}

export const CreateEditTrainingModal = ({
    isVisible,
    onClose,
    accountCanAddCourse,
    accountCourseType,
    spark,
}: CreateEditTrainingModalProps) => {
    const currentCourseData = has(spark, 'courseData')
        ? spark.courseData!
        : ({} as ITrainingCourse);
    const [courseData, setCourseData] = useState<ITrainingCourse>({ ...currentCourseData });
    const [trainingEnabled, setTrainingEnabled] = useState<boolean | undefined>(
        spark.trainingEnabled,
    );

    const handleSaveSuccess = (action: string) => {
        toast.success(`Training ${action}.`);
        onClose(true, true);
    };

    const onSave = async (dataToSave: TrainingUpdate) => {
        if (spark.trainingCourseId) {
            const updateBody = { ...dataToSave, enabled: trainingEnabled, sparkId: spark._id };
            TrainingCourseAPI.update(updateBody, spark.trainingCourseId)
                .then(() => handleSaveSuccess('updated'))
                .catch(() => toast.error('Error saving training entry.'));
        } else {
            TrainingCourseAPI.create({ ...dataToSave, enabled: trainingEnabled }, spark._id)
                .then(() => handleSaveSuccess('created'))
                .catch(() => toast.error('Error saving training entry.'));
        }
    };

    const validateCourseDetails = async (payload: TrainingUpdate) => {
        if (has(payload, 'trainingEnabled')) {
            setTrainingEnabled(payload.trainingEnabled);

            if (!payload.trainingEnabled) {
                onSave({ trainingEnabled: payload.trainingEnabled, ...payload });
                return;
            }
        }

        validateZoltrainCourse(payload).then((courseTitle) => {
            const updatedCourseData = { ...payload, courseTitle };
            setCourseData(updatedCourseData);
            onSave({
                ...payload,
                trainingEnabled: true, // if we get here and are validating a course, training has to be enabled
            });
        });
    };

    const onUpdate = (e: { trainingEnabled?: boolean; courseData?: ITrainingCourse }) => {
        if (has(e, 'trainingEnabled')) {
            setTrainingEnabled(!trainingEnabled);
        } else {
            setCourseData(e.courseData!);
        }
    };

    const closeAndClearUnsaved = () => {
        onClose(false);
        // tiny timeout to avoid the "flash" of content changing in the form before the modal actually closes
        setTimeout(() => {
            setCourseData(currentCourseData);
            setTrainingEnabled(spark.trainingEnabled);
        }, 250);
    };

    return (
        <Modal isVisible={isVisible} onClose={closeAndClearUnsaved}>
            <Modal.Title onClose={closeAndClearUnsaved}>Training</Modal.Title>

            <Form>
                <Modal.Content>
                    <TrainingCourseForm
                        accountCanAddCourse={accountCanAddCourse}
                        accountCourseType={accountCourseType}
                        trainingEnabled={trainingEnabled}
                        courseData={courseData}
                        onUpdate={onUpdate}
                    />
                </Modal.Content>

                <Modal.Actions>
                    <Button color="neutral" variant="flat" onClick={closeAndClearUnsaved}>
                        Cancel
                    </Button>
                    <Form.Button
                        color="blue"
                        variant="flat"
                        disabled={false}
                        onClick={async (payload) => {
                            await validateCourseDetails({
                                ...payload,
                                // `courseData` needs to be the fallback here because any property associated
                                // with a disabled `Textfield` will be undefined in the payload due to `react-hook-forms`
                                courseId: payload.courseId ?? courseData.courseId,
                                accessToken: payload.accessToken ?? courseData.accessToken,
                                required: payload.required ?? courseData.required,
                            });
                        }}
                    >
                        Save
                    </Form.Button>
                </Modal.Actions>
            </Form>
        </Modal>
    );
};
