import AccountsAPI from '@api/AccountsAPI';

import { getVendorRetailerPosDataQueryKey } from '@features/account-links';

import { useAdvancedQuery } from '@hooks/QueryHooks';

// But is here for circular dependency reasons
export const useVendorRetailerPosData = (
    vendorAccountId: string,
    retailerAccountId: string,
    isEnabled: boolean = true,
) => {
    const {
        isLoading: isLoadingVendorRetailerPosData,
        isFetched: vendorRetailerPosDataIsReady,
        refetch: refetchVendorRetailerPosData,
        data: vendorRetailerPosData,
        isRefetching: isRefetchingVendorRetailerPosData,
    } = useAdvancedQuery(
        getVendorRetailerPosDataQueryKey(vendorAccountId, retailerAccountId),
        () =>
            AccountsAPI.getVendorRetailerPosData({
                groupId: vendorAccountId,
                retailerAccountId,
            }),
        { enabled: isEnabled && !!vendorAccountId && !!retailerAccountId },
    );

    return {
        isLoadingVendorRetailerPosData,
        vendorRetailerPosDataIsReady,
        refetchVendorRetailerPosData,
        isRefetchingVendorRetailerPosData,
        vendorRetailerPosData,
    };
};
