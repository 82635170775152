import moment from 'moment';

import AllUnitedStates from '../data/UnitedStates.json';

export function getOrdinal(num: number): string {
    if (num > 3 && num < 21) return 'th';
    switch (num % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
}

export function capitalize(str: string): string {
    if (str.length === 0) {
        return str;
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const toTitleCase = (str: string) => {
    const words = str.split(/(?=[A-Z])|_/);

    const readableText = words
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    return readableText;
};
export function uniqueArray(arr: any[]): any[] {
    return [...new Set(arr)];
}

export function noop() {}

export function getToday() {
    return moment();
}

export function isEmpty(valueToCheck: any) {
    const isFalsy = !valueToCheck;
    if (isFalsy) {
        return true;
    }

    const isArray = Array.isArray(valueToCheck);
    const isEmptyArray = isArray && valueToCheck.length === 0;
    if (isEmptyArray) {
        return true;
    }

    const isMap = valueToCheck instanceof Map;
    const isEmptyMap = isMap && valueToCheck.size === 0;
    if (isEmptyMap) {
        return true;
    }

    const isSet = valueToCheck instanceof Set;
    const isEmptySet = isSet && valueToCheck.size === 0;
    if (isEmptySet) {
        return true;
    }

    const isEmptyObject =
        typeof valueToCheck === 'object' && Object.keys(valueToCheck).length === 0;
    if (!isMap && !isSet && !isArray && isEmptyObject) {
        return true;
    }

    return false;
}

// Returns true if `valuesToCheck` or any item in it is empty.
// Based on the `isEmpty` util function.
export function isAnyEmpty(valuesToCheck: any[]) {
    return isEmpty(valuesToCheck) || valuesToCheck.some((value) => isEmpty(value));
}

// Helper method for `setObjectProperty`
function _recursiveUpdate(obj: any, keys: string[] = [], value: any): any {
    const currentKey = keys[0];

    if (keys.length > 1) {
        // Remove the `currentKey` from the top of `keys`
        const nextKeys = keys.slice(1);

        // If `currentValue` is an obj, go one level deeper.
        // Otherwise, overwrite `currentValue` with an empty obj ({}) and then go one level deeper
        const currentValue = obj[currentKey];
        const nextObj = typeof currentValue === 'object' ? { ...currentValue } : {};

        return { ...obj, [currentKey]: _recursiveUpdate(nextObj, nextKeys, value) };
    } else {
        // Base Case
        // We're at the last dot (.) item of path
        return { ...obj, [currentKey]: value };
    }
}
// Immutably update `obj` with a new `value` at the given `path`
// @returns A new object
export function setObjectProperty(obj: any = {}, { path, value }: { path: string; value: any }) {
    if (typeof obj !== 'object' || typeof path !== 'string' || path?.length === 0) {
        return null;
    }

    const keys = path.split('.');
    return _recursiveUpdate(obj, keys, value);
}

export function isValidObjectId(str: string): boolean {
    return str != null ? str.match(/^[a-f\d]{24}$/i) != null : false;
}

export function downloadStringAsTxt(text: string, exportName: string, extension = '.txt') {
    const file = new File([text], `${exportName}${extension}`, {
        type: 'text/plain',
    });

    const link = document.createElement('a');
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export function downloadObjectAsJson(exportObj: any, exportName: string) {
    /* eslint-disable */
    var dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', exportName + '.json');
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
    /* eslint-enable */
}

export function getStateAbbreviationByName(name: string): string {
    return (
        Object.keys(AllUnitedStates).find(
            (key) => AllUnitedStates[key as keyof typeof AllUnitedStates] === name,
        ) || ''
    );
}

export function getStateNameByAbbreviation(abbr: string): string {
    return AllUnitedStates[abbr as keyof typeof AllUnitedStates];
}

export function isInt(n: number): boolean {
    return n % 1 === 0;
}

export function isFloat(n: number): boolean {
    return Number(n) === n && n % 1 !== 0;
}

// Immutably remove a property from a given object
export function removePropertyFromObj(propertyKey: string, obj: any) {
    const { [propertyKey]: _, ...restOfObj } = obj;
    return restOfObj;
}

/* eslint-enable */

// Helper fn to get all dates within a date range
// Specifically implemented for filtering transactionSummaries
export const getDaysBetweenDates = (startDate: any, endDate: any) => {
    const dates: string[] = [];

    if (startDate != null && endDate != null) {
        const now = moment(startDate).clone();

        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('YYYY-MM-DD'));
            now.add(1, 'days');
        }
    }

    return dates;
};
