import { BrandLinkStatusOptions } from '@constants/AccountConstants';
import moment from 'moment-timezone';

import { AccountLink, AccountType } from '@sparkplug/lib';

import Chip from '@components/chips/Chip';
import { DisabledLinkIcon } from '@components/icons';
import Tooltip from '@components/layout/Tooltip';

type AccountStatusToolTipProps = {
    accountLink: AccountLink & { accountLinkStatus?: BrandLinkStatusOptions };
    accountType?: AccountType;
};

const AccountStatusChip = ({ accountLink, accountType }: AccountStatusToolTipProps) => {
    const { accountLinkStatus, createdAt, reminderSentAt } = accountLink;

    const requestedAtMessage = `Link requested on ${moment(createdAt).format('LL')}`;
    const pendingMessage = reminderSentAt ? (
        <div className="tooltips-multiple">
            <div>{requestedAtMessage}</div>
            <div>Link reminder sent on {moment(reminderSentAt).format('LL')}</div>
        </div>
    ) : (
        requestedAtMessage
    );
    const declinedMessage = 'Link request declined';
    const getMessage = () => {
        switch (accountType) {
            case 'retailer':
                switch (accountLinkStatus?.value) {
                    case 'pending':
                        return pendingMessage;
                    case 'unmapped':
                        return 'Map products to Brands in order to receive Sparks';
                    case 'enabled':
                        return 'Sparks are enabled for this Vendor';
                    case 'disabled':
                        return 'This link is disabled. The Vendor cannot send you Spark offers or view product sales data.';
                    case 'rejected':
                        return declinedMessage;
                    default:
                        return '';
                }
            case 'brand':
                switch (accountLinkStatus?.value) {
                    case 'pending':
                        return pendingMessage;
                    case 'unmapped':
                        return 'Retailer has not mapped any products ';
                    case 'enabled':
                        return 'Sparks are enabled for this Retailer';
                    case 'disabled':
                        return 'Sparks are disabled for this Retailer';
                    case 'rejected':
                        return declinedMessage;
                    default:
                        return '';
                }
            default:
                return '';
        }
    };

    if (!accountLinkStatus) {
        return <>--</>;
    }

    return (
        <Tooltip title={getMessage()}>
            <span>
                <Chip
                    dense
                    color={accountLinkStatus.color}
                    label={accountLinkStatus.label}
                    icon={
                        accountLinkStatus?.value === 'disabled' ? <DisabledLinkIcon /> : undefined
                    }
                />
            </span>
        </Tooltip>
    );
};
export default AccountStatusChip;
