import axios from 'axios';

import { IEmployeeLearningResource } from '@sparkplug/lib';

import { useAdvancedQuery } from '@hooks/QueryHooks';

const API = {
    getEmployeeLearningResources: async () => {
        return (
            await axios.get<IEmployeeLearningResource[]>('/api/v1/employee-data/learning-resources')
        ).data;
    },
};

export const EMPLOYEE_LEARNING_RESOURCES_CACHE_KEY = 'employee-learning-resources';

export const useGetEmployeeLearningResources = () => {
    const isLearningResourcesEnabled = import.meta.env.REACT_APP_LEARNING_RESOURCES === 'true';
    const {
        data: learningResources = [],
        isLoading,
        error,
    } = useAdvancedQuery(
        EMPLOYEE_LEARNING_RESOURCES_CACHE_KEY,
        () => API.getEmployeeLearningResources(),
        {
            enabled: isLearningResourcesEnabled,
        },
    );

    return {
        learningResources,
        isLoadingLearningResources: isLoading,
        learningResourcesError: error,
    };
};
