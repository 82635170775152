import { FC, useEffect, useMemo } from 'react';

import { orderBy } from 'lodash';

import { AccountLink } from '@sparkplug/lib';

import { MarketsFilter } from '@features/account-links/components/MarketsFilter';
import { useMarketOptionsFilter } from '@features/account-links/hooks/useMarketOptionsFilter';

import { NoRetailersFoundGraphic } from '@components/graphics';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import Grid from '@components/layout/Grid';
import Spinner from '@components/layout/Spinner';
import toast from '@components/toast';
import Toolbar from '@components/toolbar/Toolbar';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useSearch } from '@hooks/UIHooks';

import { getStateNameByAbbreviation } from '@helpers/util';

import { useCreateAccountLink, useSendAccountLinkRequest } from '../../../mutations';
import { useSuggestedAccountLinks } from '../../../queries';
import { addAccountLinkSearchableFields } from '../../../utils';
import BrandLinkCard from './BrandLinkCard';

export const orderSuggestedAccountLinks = (suggestedAccountLinks: AccountLink[]) => {
    const { pendingLinks, otherLinks } = suggestedAccountLinks.reduce(
        (result, accountLink) => {
            if (accountLink.status === 'pending') {
                result.pendingLinks.push(accountLink);
            } else {
                result.otherLinks.push(accountLink);
            }

            return result;
        },
        { pendingLinks: [] as AccountLink[], otherLinks: [] as AccountLink[] },
    );

    return [
        ...orderBy(pendingLinks, ['accountName'], ['asc']),
        ...orderBy(otherLinks, ['accountName'], ['asc']),
    ];
};

export interface IAdminAccountLinksSuggestedViewProps {
    linksAreReady: boolean;
    otherAccounts: AccountLink[];
    filteredMarkets: string[];
}

export const AdminAccountLinksSuggestedView: FC<IAdminAccountLinksSuggestedViewProps> = ({
    linksAreReady,
    otherAccounts,
    filteredMarkets,
}) => {
    const autoLinkingFeatureEnabled = import.meta.env.REACT_APP_AUTO_LINKING === 'true';

    // This lets us search the `otherAccounts` array by brand name, or maybe other keys in the future...?
    const searchableAccounts = useMemo(() => {
        return addAccountLinkSearchableFields(otherAccounts);
    }, [otherAccounts]);

    const { history } = useApp();

    const { account: currentAccount, userCan } = useSparkplugAccount();
    const userCanManageLinks = userCan('manageLinks');

    const { isCreatingAccountLink, createAccountLinkAsync } = useCreateAccountLink(
        currentAccount?._id || '',
    );
    const { isSendingAccountLinkRequest, sendAccountLinkRequestAsync } = useSendAccountLinkRequest(
        currentAccount?._id || '',
    );
    const { refetchSuggestedAccountLinks } = useSuggestedAccountLinks(currentAccount?._id || '');
    const { searchFilter, onChangeSearchFilter, applySearch } = useSearch([
        'accountName',
        'mappedNamesStr',
    ]);

    const onCardButtonClick = (prospectiveAccountLink: AccountLink) => {
        if (currentAccount?.type === 'retailer') {
            const accountId = prospectiveAccountLink.accountId;
            if (autoLinkingFeatureEnabled) {
                history.push(
                    `/${currentAccount?._id}/settings/vendor/${prospectiveAccountLink.accountId}/manage`,
                );
            } else {
                toast.promise(
                    createAccountLinkAsync(
                        {
                            vendorAccountId: accountId,
                            body: {},
                        },
                        {
                            onSuccess: () => {
                                // Note: we replace here because when the user goes "back" we'd rather have them to go the main links page instead of the suggested links page
                                history.replace(
                                    `/${currentAccount._id}/settings/vendor/${prospectiveAccountLink.accountId}/manage?isNewVendorLink=true`,
                                );
                            },
                        },
                    ),
                    {
                        loading: 'Creating link...',
                        success: `Successfully linked w/ ${prospectiveAccountLink.accountName}!`,
                        error: 'Something went wrong - please try again and contact support if this issue persists.',
                    },
                );
            }
        }

        if (currentAccount?.type === 'brand') {
            const accountId = prospectiveAccountLink.accountId;
            toast.promise(
                sendAccountLinkRequestAsync(accountId, {
                    onSuccess: () => {
                        refetchSuggestedAccountLinks();
                    },
                }),
                {
                    loading: 'Sending request...',
                    success: `Link request sent to ${prospectiveAccountLink.accountName}`,
                    error: 'Something went wrong - please try again and contact support if this issue persists.',
                },
            );
        }
    };

    const filteredMarketsDict: any = useMemo(() => {
        return filteredMarkets.reduce((dict, filteredMarket) => {
            return {
                ...dict,
                [filteredMarket]: true,
            };
        }, {});
    }, [filteredMarkets]);

    const filteredAccounts: AccountLink[] = useMemo(() => {
        const searchedAccounts: AccountLink[] = applySearch(searchableAccounts);

        return searchedAccounts.filter((searchedAccount) => {
            const sharedMarkets = searchedAccount?.markets || [];

            // Check if the current `searchedAccount` has `sharedMarkets` that
            // coincide with the current `filteredMarkets`
            return sharedMarkets.some((sharedMarket: string) => {
                const sharedMarketLabel = getStateNameByAbbreviation(sharedMarket) || sharedMarket;
                return Boolean(filteredMarketsDict[sharedMarketLabel]);
            });
        });
    }, [otherAccounts, applySearch, filteredMarketsDict]);

    const orderedFilteredAccounts = useMemo(
        () => orderSuggestedAccountLinks(filteredAccounts),
        [filteredAccounts],
    );

    const viewContent = orderedFilteredAccounts.length ? (
        <Grid className="account-links-grid">
            {orderedFilteredAccounts.map((otherAccount, i) => (
                <Grid.Item key={i} sm={3}>
                    <BrandLinkCard
                        account={otherAccount}
                        onButtonClick={onCardButtonClick}
                        disableCard={
                            isSendingAccountLinkRequest ||
                            isCreatingAccountLink ||
                            !userCanManageLinks
                        }
                        allowLinkCreate
                    />
                </Grid.Item>
            ))}
        </Grid>
    ) : (
        <EmptyStateDisplay
            className="!mt-[50px]"
            graphic={<NoRetailersFoundGraphic />}
            label={currentAccount?.type === 'brand' ? 'No Retailers Found' : 'No Vendors Found'}
        />
    );

    const loading = (
        <div className="w-full mt-16 flex justify-center">
            <Spinner />
        </div>
    );

    return (
        <>
            <Toolbar justifyContentStart>
                <Toolbar.Search
                    name="search"
                    defaultValue={searchFilter}
                    onChange={onChangeSearchFilter}
                />
                <Toolbar.Text className="account-markets toolbar-group-start">
                    <MarketsFilter />
                </Toolbar.Text>
            </Toolbar>

            {linksAreReady ? viewContent : loading}
        </>
    );
};

export default () => {
    const { account } = useSparkplugAccount();
    const { suggestedAccountLinks, isSuggestedAccountLinksReady, refetchSuggestedAccountLinks } =
        useSuggestedAccountLinks(account?._id);
    const { filteredMarketsLabels } = useMarketOptionsFilter({ account });

    useEffect(() => {
        refetchSuggestedAccountLinks();
    }, [account]);

    return (
        <AdminAccountLinksSuggestedView
            linksAreReady={isSuggestedAccountLinksReady}
            otherAccounts={suggestedAccountLinks}
            filteredMarkets={filteredMarketsLabels}
        />
    );
};
