import { FC, MouseEvent, ReactElement } from 'react';

import { IPagingProperties, Spark, SparksViewFilters, TableKey } from '@sparkplug/lib';

import AdvancedTable from '@components/table/AdvancedTable';
import Table from '@components/table/Table';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { IAccount } from '@app/types/AccountsTypes';

import { ColumnSettingsManager, useSyncedColumnsAndHeadCells } from '../ColumnSettingsManager';
import { ExportSparkDashboardButton } from '../ExportSparkDashboardButton';
import { SparkAdminMobileCards } from '../SparkAdminMobileCards';
import { actionButtonCell } from './actionButtonCell';
import { useSparkDashboardDefaultOrder } from './useSparkDashboardDefaultOrder';
import {
    useSparkDashboardHeadCells,
    useSparkDashboardStickyLeftCells,
} from './useSparkDashboardHeadCells';
import { useSparkDashboardTableData } from './useSparkDashboardTableData';

import './SparkDashboardContent.scss';

export interface SparkDashboardContentProps {
    account: IAccount;
    sparks: Spark[];
    meta: IPagingProperties;
    emptyStateComponent: ReactElement;
    onSparkSelected: (spark: Spark, event?: MouseEvent<HTMLElement>) => void;
    sparkFilters?: SparksViewFilters;
    enableQueryParams?: boolean;
}
export const SparkDashboardContent: FC<SparkDashboardContentProps> = ({
    account,
    emptyStateComponent,
    sparks,
    meta,
    onSparkSelected,
    sparkFilters,
    enableQueryParams = true,
}) => {
    const { appIsMobile } = useApp();

    const baseCells = useSparkDashboardHeadCells(sparkFilters?.status);
    const stickyLeftCells = useSparkDashboardStickyLeftCells();

    const { sparkDashboardTableData, sparkDashboardDataIsReady } = useSparkDashboardTableData({
        filteredSparks: sparks,
        onSparkSelected,
    });

    const tableKey: TableKey = `spark-dashboard-${
        account?.type === 'brand' ? 'vendor' : 'retailer'
    }`;
    const { syncedColumns, syncedHeadCells, staticColumns, syncedDataIsReady } =
        useSyncedColumnsAndHeadCells({
            tableKey,
            baseCells,
            stickyLeftCells,
        });

    const isLoading = !sparkDashboardDataIsReady;
    if (!isLoading && !sparkDashboardTableData?.length) {
        return emptyStateComponent;
    }

    if (appIsMobile) {
        return (
            <SparkAdminMobileCards
                rows={sparkDashboardTableData}
                isLoading={isLoading}
                onSparkSelected={onSparkSelected}
            />
        );
    }

    return (
        <>
            {sparkDashboardDataIsReady && (
                <ExportSparkDashboardButton
                    stickyLeftCells={stickyLeftCells}
                    sparkFilters={sparkFilters}
                    baseCells={syncedHeadCells}
                    data={sparkDashboardTableData}
                />
            )}

            <AdvancedTable
                stickyLeft={stickyLeftCells}
                stickyRight={actionButtonCell}
                className="spark-dashboard-table"
                variant="raised"
                rows={sparkDashboardTableData}
                defaultOptions={{
                    orderBy: 'createdAt',
                    order: 'desc',
                }}
                isLoading={isLoading}
                headCells={syncedHeadCells}
                enableQueryParams={enableQueryParams}
                disableFrontendFiltering
                rowCountOverride={meta.total}
            >
                <Table.RenderHead />
                <Table.RenderBody
                    onRowClick={({ spark }, event) => onSparkSelected(spark, event)}
                    emptyStateText="No sparks with these filters."
                />
            </AdvancedTable>

            <ColumnSettingsManager
                tableKey={tableKey}
                columns={syncedColumns}
                staticColumns={staticColumns}
                columnsAreReady={syncedDataIsReady}
            />
        </>
    );
};

export default (props: Omit<SparkDashboardContentProps, 'account'>) => {
    // We're using this hook as opposed to setting the default order in the table because of
    // the case where we want pending sparks to be filtered to the top
    const sparks = useSparkDashboardDefaultOrder(props);

    const { account } = useSparkplugAccount();
    if (!account) {
        return <></>;
    }

    return <SparkDashboardContent {...props} sparks={sparks} account={account} />;
};
