/**
 * HiddenField is intended to register hidden fields to the FormContext
 * so that the `value` can be read in the `handleSubmit` function
 */
import { Input } from '@mui/material';

import { useFormContext } from '@hooks/FormHooks';

import './HiddenField.scss';

interface IHiddenFieldProps {
    isBoolean?: boolean;
    name: string;
    value: string;
}

const HiddenField = ({ isBoolean = false, name, value: _value }: IHiddenFieldProps) => {
    const { register } = useFormContext() || {
        register: null,
    };

    const value = isBoolean ? _value === 'true' : _value;

    const props = {
        name,
        value,
        inputRef: register != null ? register() : null,
    };

    return <Input type="hidden" {...props} />;
};

export default HiddenField;
