import { useState } from 'react';

import { internalTrackingDropdownOptions } from '@constants/SparkConstants';

import {
    SparkInternalInvoiceStatus,
    SparkInternalPayoutStatus,
    SparkInternalStatus,
    SparkInternalTracking,
} from '@sparkplug/lib';

import { useUpdateInternalTrackingMutation } from '@core/sparks/mutations/SparkMutations';

import Form from '@components/form/Form';
import Modal from '@components/overlays/Modal';

import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import { isEmpty, noop } from '@helpers/util';

import './InternalSparkTrackingModal.scss';

const InternalSparkTrackingModal = ({
    isVisible,
    onClose = noop,
    defaultInternalTracking,
}: {
    isVisible: boolean;
    defaultInternalTracking: SparkInternalTracking;
    onClose?: () => any;
}) => {
    const [fields, setFields] = useState<{
        status: SparkInternalStatus;
        invoiceStatus: SparkInternalInvoiceStatus;
        payoutStatus: SparkInternalPayoutStatus;
        notes: string;
    }>(defaultInternalTracking);

    const [updatedFields, setUpdatedFields] = useState<any>(new Set());

    const updateField = (
        fieldName: 'status' | 'invoiceStatus' | 'payoutStatus' | 'notes',
        nextValue:
            | SparkInternalStatus
            | SparkInternalInvoiceStatus
            | SparkInternalPayoutStatus
            | string,
    ) => {
        // Track the specific fields that are updated
        setUpdatedFields((prevUpdatedFields: any) => {
            return prevUpdatedFields.add(fieldName);
        });

        setFields((prevFields) => ({
            ...prevFields,
            [fieldName]: nextValue,
        }));
    };

    const { spark, refetchSparkData } = useSpark();
    const { updateInternalTrackingAsync } = useUpdateInternalTrackingMutation({
        sparkId: spark._id,
    });

    const handleSave = async () => {
        if (isEmpty(updatedFields)) {
            // Should never happen because the save button
            // should be disabled in this scenario
            return;
        }

        const internalTracking = {} as SparkInternalTracking;
        updatedFields.forEach((field: any) => {
            internalTracking[field as keyof typeof internalTracking] = (fields as any)[field];
        });

        updateInternalTrackingAsync({
            sparkId: spark._id,
            internalTrackingUpdates: internalTracking,
        }).then(() => {
            refetchSparkData();
            onClose();
        });
    };

    return (
        <Modal
            className="spark-internal-tracking-modal"
            isVisible={isVisible}
            onClose={() => onClose()}
        >
            <Modal.Title>Update Incentive Statuses</Modal.Title>

            <Form>
                <Modal.Content>
                    <Form.Select
                        label="Spark Status:"
                        name="internal-spark-status"
                        value={fields.status}
                        onChange={(event) => updateField('status', event.target.value)}
                        options={internalTrackingDropdownOptions.status}
                    />

                    <Form.Switch
                        color="red"
                        label="Special Payout "
                        name="internal-spark-invoice-sent"
                        value={fields.payoutStatus === 'special-payout'}
                        onChange={(event: any) =>
                            updateField(
                                'payoutStatus',
                                event.target.checked ? 'special-payout' : 'none',
                            )
                        }
                    />
                    <Form.TextField
                        multiline
                        rows={5}
                        label="Notes:"
                        name="internal-spark-notes"
                        value={fields.notes}
                        onChange={(event) => updateField('notes', event.target.value)}
                    />
                </Modal.Content>

                <Modal.Actions>
                    <Form.Button color="neutral" variant="flat" onClick={() => onClose()}>
                        Cancel
                    </Form.Button>
                    <Form.Button
                        color="blue"
                        variant="filled"
                        disabled={isEmpty(updatedFields)}
                        onClick={handleSave}
                    >
                        Save
                    </Form.Button>
                </Modal.Actions>
            </Form>
        </Modal>
    );
};

export default InternalSparkTrackingModal;
