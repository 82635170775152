import { FC } from 'react';

import Paper from '@mui/material/Paper';
import SettingsTitle from '@views/admin/components/SettingsTitle';

import { EmployeeEarningsIcon, ChevronRight as ForwardIcon } from '@components/icons';
import RadioGroup from '@components/inputs/RadioGroup';
import AppView from '@components/layout/AppView';
import PageHeader from '@components/layout/PageHeader';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { IAccount } from '@app/types/AccountsTypes';
import { InputEventOnChange } from '@app/types/UITypes';

import './AdminExportView.scss';

interface AdminExportViewProps {
    account: IAccount;
    history: ReturnType<typeof useApp>['history'];
}
export const AdminExportView: FC<AdminExportViewProps> = ({ account, history }) => {
    const onSelectExportType: InputEventOnChange = (e) => {
        const exportType = e.target.value;
        history.push(`/${account._id}/settings/export/${exportType}`);
    };

    return (
        <Paper className="admin-export-view">
            <PageHeader title={['Export']} metaTitle="Export" />

            <p className="export-page-description">
                Export and download your data from your SparkPlug account.
            </p>

            <RadioGroup
                className="export-selection-group"
                name="export-selection-group"
                label="Select an export option:"
                color="blue"
                direction="vertical"
                variant="button"
                options={[
                    {
                        icon: <EmployeeEarningsIcon />,
                        label: 'Employee Cash Earnings',
                        description: 'Export a list of employees and the cash amounts earned.',
                        value: 'employee-earnings',
                        endIcon: <ForwardIcon />,
                        disabled: account?.type !== 'retailer',
                    },
                ]}
                onChange={onSelectExportType}
            />
        </Paper>
    );
};

export default () => {
    const { account } = useSparkplugAccount();
    const { history } = useApp();

    if (!account) {
        return <></>;
    }

    return (
        <AppView.Content title={<SettingsTitle label="Export Data" />}>
            <AdminExportView account={account} history={history} />
        </AppView.Content>
    );
};
