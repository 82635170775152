import { BrandLinkStatuses } from '@constants/AccountConstants';
import { keyBy } from 'lodash';

import { AccountLink } from '@sparkplug/lib';

import { isLinkPending, isLinkUnmapped } from '@features/account-links/utils';

import { useQueryParamsState } from '@hooks/QueryParamsHooks';

import { ITableRow } from '@app/types/TableTypes';

export type AccountLinkStatusFilter = 'all' | 'pending' | 'disabled' | 'rejected' | 'enabled';

export const useAccountLinkTableFilters = () => {
    const [query, setQuery] = useQueryParamsState<{
        statusFilter: AccountLinkStatusFilter;
    }>({ statusFilter: 'all' });
    const { statusFilter } = query;

    const setStatusFilter = (updatedStatusFilter: AccountLinkStatusFilter) =>
        setQuery({ statusFilter: updatedStatusFilter });

    const genAccountLinkStatus = (accountLink: AccountLink) => {
        const brandLinkStatusesByValue = keyBy(BrandLinkStatuses, 'value');
        if (isLinkUnmapped(accountLink)) {
            return brandLinkStatusesByValue.unmapped;
        }
        if (isLinkPending(accountLink)) {
            return brandLinkStatusesByValue['pending-assignment'];
        }
        return brandLinkStatusesByValue[accountLink.status];
    };

    const applyStatusFilter = (list: ITableRow<AccountLink>[]) =>
        list.filter((accountLink) => {
            if (statusFilter === 'all') {
                return true;
            }
            if (isLinkUnmapped(accountLink)) {
                return statusFilter === 'unmapped';
            }

            return accountLink.status === statusFilter;
        });

    return {
        statusFilter,
        setStatusFilter,
        applyStatusFilter,
        genAccountLinkStatus,
    };
};
