import axios from 'axios';

import { GetTableSettingsResponseBody, TableKey } from '@sparkplug/lib';

import { useAdvancedQuery } from '@hooks/QueryHooks';

import { getTableSettingsCacheKey } from './getTableSettingsCacheKey';

const API = {
    async getTableSettings(tableKey: TableKey) {
        return (await axios.get<GetTableSettingsResponseBody>(`/api/v1/table-settings/${tableKey}`))
            .data;
    },
};

export const useTableSettings = ({ tableKey }: { tableKey: TableKey }) => {
    const {
        data: tableSettings,
        isFetched: tableSettingsAreReady,
        refetch: refetchTableSettings,
    } = useAdvancedQuery(getTableSettingsCacheKey(tableKey), () => API.getTableSettings(tableKey));

    return {
        tableSettings,
        tableSettingsAreReady,
        refetchTableSettings,
    };
};
