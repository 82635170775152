import { FC, useMemo } from 'react';

import { IPublicUser } from '@sparkplug/lib';

import { NoInvoicesIcon } from '@components/icons';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import Toolbar from '@components/toolbar/Toolbar';

import { useQueryParamsState } from '@hooks/QueryParamsHooks';
import { useSearch } from '@hooks/UIHooks';

import { GiftCardsTable, GiftCardsTableProps, GiftCardsTableRow } from './GiftCardsTable';

export const getSuperAdminFilterOptions = ({
    superAdminUsers,
    giftCardRows,
}: {
    superAdminUsers: IPublicUser[];
    giftCardRows: GiftCardsTableRow[];
}) => {
    // We only want to show super admins who have sent a gift card
    const senderSuperAdminSet = new Set(
        giftCardRows.map(({ createdBySuperUserId }) => createdBySuperUserId),
    );

    const superAdminOptions = superAdminUsers
        .filter(({ _id }) => senderSuperAdminSet.has(_id))
        .map(({ _id, firstName, lastName }) => ({
            value: _id,
            label: `${firstName} ${lastName}`,
        }));

    return [{ value: 'all', label: 'All Gift Card Senders' }].concat(superAdminOptions);
};

export const buildApplySenderFilterFn =
    (senderFilter: string) =>
    (giftCardsToFilter: any[] = []) => {
        if (senderFilter === 'all') {
            return giftCardsToFilter;
        }
        return giftCardsToFilter.filter(
            ({ createdBySuperUserId }) => createdBySuperUserId === senderFilter,
        );
    };

type GiftCardsTableWithFiltersProps = Omit<GiftCardsTableProps, 'filters'> & {
    superAdminUsers: IPublicUser[];
};
export const GiftCardsTableWithFilters: FC<GiftCardsTableWithFiltersProps> = ({
    isReady,
    rows,
    superAdminUsers = [],
}) => {
    const superAdminUserFilterOptions = useMemo(
        () =>
            getSuperAdminFilterOptions({
                superAdminUsers,
                giftCardRows: rows,
            }),
        [superAdminUsers, rows],
    );

    const { searchFilter, onChangeSearchFilter, applySearch } = useSearch([
        'recipientAccountName',
        'recipientUserName',
        'contactMethod',
    ]);

    const [query, setQuery] = useQueryParamsState({
        sender: 'all',
    });

    const { sender: senderFilter } = query;

    const applySenderFilter = buildApplySenderFilterFn(senderFilter);

    if (isReady && !rows?.length) {
        return <EmptyStateDisplay graphic={<NoInvoicesIcon />} label="no gift cards!" />;
    }

    return (
        <>
            <Toolbar className="toolbar-content-start" scrollOnMobile>
                <Toolbar.Search
                    name="spark-credits_search"
                    defaultValue={searchFilter}
                    onChange={onChangeSearchFilter}
                />

                <Toolbar.Dropdown
                    label={null}
                    value={senderFilter}
                    onSelect={(v) => setQuery({ sender: v })}
                    options={superAdminUserFilterOptions}
                    clear={{
                        active: senderFilter !== 'all',
                        onClear: () => setQuery({ sender: 'all' }),
                    }}
                />
            </Toolbar>

            <GiftCardsTable
                isReady={isReady}
                rows={rows}
                filters={[applySearch, applySenderFilter]}
            />
        </>
    );
};
