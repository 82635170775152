import { MutableRefObject, useEffect, useRef } from 'react';

import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import lottieWeb, { AnimationItem } from 'lottie-web';

const useInitLottieWeb = ({
    json,
    loop,
    speed,
    initialSegment,
    direction,
    onComplete,
}: {
    json: any;
    loop: boolean;
    speed: number;
    direction: number;
    onComplete?: () => void;
    /**
     * [startFrame, endFrame]
     */
    initialSegment?: [number, number];
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const animationRef = useRef<any>(null);

    useEffect(() => {
        if (containerRef.current) {
            animationRef.current = lottieWeb.loadAnimation({
                container: containerRef.current,
                renderer: 'svg',
                loop,
                autoplay: false,
                ...(initialSegment && { initialSegment }),
                // Lottie-Web can technically mutate the animationData object, so we need to clone it
                animationData: cloneDeep(json),
            });
        }

        return () => {
            if (animationRef.current) {
                animationRef.current.destroy();
            }
        };
    }, [json, loop, initialSegment?.[0], initialSegment?.[1]]);

    useEffect(() => {
        if (animationRef.current) {
            animationRef.current.setSpeed(speed);
            animationRef.current.setDirection(direction);
            animationRef.current.onComplete = onComplete;
        }
    }, [speed, direction]);

    return { animationRef, containerRef };
};

const usePlayLottieAnimation = ({
    startPlay,
    animationRef,
    onPlayStart,
}: {
    startPlay: boolean;
    animationRef: MutableRefObject<any>;
    onPlayStart?: (lottieWebAnimation?: AnimationItem) => void;
}) => {
    useEffect(() => {
        if (startPlay && animationRef.current) {
            // First set the playback segment to the full animation in case the animation
            onPlayStart?.(animationRef.current);
            // Then go to the beginning and play
            animationRef.current.play();
        }
    }, [startPlay]);
};

function LottieGraphic({
    className,
    json,
    speed = 1,
    startPlay = false,
    onPlayStart,
    direction = 1,
    loop = false,
    initialSegment,
    onComplete,
}: {
    className?: string;
    json: any;
    startPlay: boolean;
    onPlayStart?: (lottieWebAnimation?: AnimationItem) => void;
    speed?: number;
    direction?: number;
    loop?: boolean;
    /**
     * [startFrame, endFrame]
     */
    initialSegment?: [number, number];
    onComplete?: () => void;
}) {
    const { animationRef, containerRef } = useInitLottieWeb({
        json,
        loop,
        speed,
        initialSegment,
        direction,
        onComplete,
    });

    usePlayLottieAnimation({
        animationRef,
        onPlayStart,
        startPlay,
    });

    return (
        <div
            ref={containerRef}
            className={clsx('graphic', 'lottie-graphic', className)}
            data-chromatic="ignore"
        />
    );
}

export default LottieGraphic;
