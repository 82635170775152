import { AccountLink } from '@sparkplug/lib';

import { getStateNameByAbbreviation } from '@helpers/util';

import { SearchableAccountLink } from '../types';

export const addAccountLinkSearchableFields = (
    accountLinks: AccountLink[],
): SearchableAccountLink[] => {
    return accountLinks.map((accountLink) => {
        const mappedNamesStr = (accountLink?.brandLinks || [])
            .map((brandLink) => brandLink.name)
            .join(',');
        return {
            ...accountLink,
            mappedNamesStr,
        };
    });
};

// This was created because I noticed some inconsistencies in terms of the market being
// displayed as the abbreviation at times and the full name at other times
export const getValidMarketName = (market: string): string =>
    getStateNameByAbbreviation(market) || market;

export const isLinkPending = (accountLink: AccountLink) => {
    const brandLinks = accountLink.brandLinks ?? [];

    if (accountLink.status !== 'enabled') {
        return false;
    }

    return !brandLinks.some((brandLink) => brandLink?.posBrands?.length);
};

export const isLinkUnmapped = (accountLink: AccountLink) => {
    const mappedBrands = accountLink.brandLinks.flatMap(({ posBrandIds }) => posBrandIds);
    return mappedBrands.length === 0 && accountLink.status === 'enabled';
};
