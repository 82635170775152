import { FC, MouseEvent, useState } from 'react';

import { Spark } from '@sparkplug/lib';

import { UpdateSparkCommissionsWizardStage } from '@features/spark-wizard/components/CommissionCommissionsFormFields/CommissionCommissionsFormFields';

import { useSparkManagementModal } from '@contexts/SparkManagementModalContext';

import { AlertIcon } from '@components/icons';
import ClickableArea from '@components/layout/ClickableArea';
import Tooltip from '@components/layout/Tooltip';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import MissingCommissionModal from './MissingCommissionModal';

import './MissingCommissionCallout.scss';

interface MissingCommissionDisplayProps {
    missingCount: number;
    onViewProducts: () => void;
    userIsSuperAdmin?: boolean;
}

const getMissingCommissionMessage = (missingCount: number) => {
    return `There ${missingCount === 1 ? 'is' : 'are'} ${missingCount} new qualifying product${
        missingCount === 1 ? '' : 's'
    } missing a commission.`;
};
export const MissingCommissionHeader: FC<MissingCommissionDisplayProps> = ({
    missingCount,
    onViewProducts,
    userIsSuperAdmin,
}) => {
    const classNamePrefix = 'missing-commission-callout-header';
    return (
        <ClickableArea className={classNamePrefix} onClick={onViewProducts}>
            <AlertIcon className={`${classNamePrefix}_alert-icon`} />
            <span className={`${classNamePrefix}_message`}>
                {!userIsSuperAdmin && <strong>Just a heads up! </strong>}
                {getMissingCommissionMessage(missingCount)}
            </span>
            <div className={`${classNamePrefix}_view-products-button`}>
                {!userIsSuperAdmin ? 'Edit Commission' : 'View Products'}
            </div>
        </ClickableArea>
    );
};

export const MissingCommissionIcon: FC<MissingCommissionDisplayProps> = ({
    missingCount = 0,
    onViewProducts,
}) => {
    const handleViewProductsClick = (e?: MouseEvent<HTMLButtonElement>) => {
        e?.stopPropagation();
        onViewProducts();
    };

    const missingCountTooltip = getMissingCommissionMessage(missingCount);
    const classNamePrefix = 'missing-commission-callout-icon';
    return (
        <Tooltip title={missingCountTooltip}>
            <span className={classNamePrefix}>
                <ClickableArea onClick={handleViewProductsClick}>
                    <AlertIcon className={`${classNamePrefix}_alert-icon`} />
                </ClickableArea>
            </span>
        </Tooltip>
    );
};

export const useOpenSparkWizardToUpdateCommissionsStage = (spark?: Spark) => {
    const { openSparkManagementModal } = useSparkManagementModal();
    if (!openSparkManagementModal || !spark) {
        return {};
    }

    const openSparkWizardToUpdateCommissionsStage = () => {
        openSparkManagementModal('edit', {
            sparkType: spark.type,
            sparkId: spark._id,
            initialStage: UpdateSparkCommissionsWizardStage,
        });
    };

    return { openSparkWizardToUpdateCommissionsStage };
};

interface MissingCommissionCalloutProps {
    variant: 'icon' | 'header';
    spark: Spark;
}
const MissingCommissionCallout: FC<MissingCommissionCalloutProps> = ({ variant, spark }) => {
    const [isVisible, setIsVisible] = useState(false);
    const { openSparkWizardToUpdateCommissionsStage: onViewProducts } =
        useOpenSparkWizardToUpdateCommissionsStage(spark);
    const { user, userIsSuperAdmin } = useApp();
    const { refetchSparkData } = useSpark();
    const { account: sparkplugAccount } = useSparkplugAccount();
    let canEdit = false;

    if (spark?.originatorGroupId) {
        canEdit =
            !!user?.accounts.some((account) => account._id === spark?.originatorGroupId) &&
            // ensures the current account is the originating account.
            sparkplugAccount?._id === spark?.originatorGroupId;
    } else {
        canEdit = !!user?.accounts.some((account) => account._id === spark?.groupId);
    }

    if (!spark?.missingCommissionCount || !onViewProducts || !canEdit) {
        return <></>;
    }

    const handleOneViewProducts = () => {
        if (
            spark?.originatorGroupId &&
            !!user?.accounts.some((account) => account._id === spark?.originatorGroupId)
        ) {
            setIsVisible(true);
            return;
        }
        onViewProducts();
    };

    switch (variant) {
        case 'icon':
            return (
                <>
                    <MissingCommissionIcon
                        missingCount={spark.missingCommissionCount}
                        onViewProducts={handleOneViewProducts}
                    />
                    <MissingCommissionModal
                        isVisible={isVisible}
                        sparkId={spark._id}
                        onClose={() => {
                            setIsVisible(false);
                        }}
                    />
                </>
            );

        case 'header':
            return (
                <>
                    <MissingCommissionHeader
                        missingCount={spark.missingCommissionCount}
                        onViewProducts={handleOneViewProducts}
                        userIsSuperAdmin={userIsSuperAdmin}
                    />
                    <MissingCommissionModal
                        isVisible={isVisible}
                        sparkId={spark._id}
                        onClose={(shouldRefresh) => {
                            if (shouldRefresh) {
                                refetchSparkData();
                            }
                            setIsVisible(false);
                        }}
                    />{' '}
                </>
            );

        default:
            return <></>;
    }
};

export default MissingCommissionCallout;
