import { useMemo, useState } from 'react';

import { debounce } from 'lodash';
import moment, { Moment } from 'moment';

import { useInitiateEmployeeEarningsExport } from '@features/exports/mutations/EmployeeEarningsExportMutations';

import { DateRangePickerProps } from '@components/inputs/DateRangePicker/DateRangePicker';
import toast from '@components/toast';

import { IAccount } from '@app/types/AccountsTypes';

/** Manage the interactions in the EmployeeEarningsExportView in one easily testable hook */
export const useEmployeeEarningsFormState = ({ account }: { account: IAccount }) => {
    const [startDate, setStartDate] = useState<Moment | null>(null);
    const [endDate, setEndDate] = useState<Moment | null>(null);
    const [exportSent, setExportSent] = useState<boolean>(false);

    const [confirmationModalIsVisible, setConfirmationModalIsVisible] = useState<boolean>(false);

    const { initiateEmployeeEarningsExport, isInitiatingEmployeeEarningsExport } =
        useInitiateEmployeeEarningsExport({
            onSuccess: () => setExportSent(true),
        });

    return useMemo(() => {
        const updateDateRange: NonNullable<DateRangePickerProps['onApply']> = (
            updatedStartDate,
            updatedEndDate,
        ) => {
            setStartDate(updatedStartDate);
            setEndDate(updatedEndDate);
        };

        const isDayOutOfRange: NonNullable<DateRangePickerProps['isOutsideRange']> = (day) => {
            return day.isBefore(account.createdAt) || day.isAfter(moment().endOf('day'));
        };

        const openConfirmationModal = () => {
            if (startDate && endDate) {
                setConfirmationModalIsVisible(true);
            } else {
                toast.error('Please select a date range.');
            }
        };

        const initiateExport = () => {
            if (startDate && endDate) {
                initiateEmployeeEarningsExport({
                    accountId: account._id,
                    startDate: startDate.format('YYYY-MM-DD'),
                    endDate: endDate.format('YYYY-MM-DD'),
                });
            }
        };

        return {
            startDate,
            endDate,
            confirmationModalIsVisible,
            exportSent,
            isInitiatingExport: isInitiatingEmployeeEarningsExport,

            updateDateRange,
            isDayOutOfRange,
            openConfirmationModal,
            closeConfirmationModal: () => setConfirmationModalIsVisible(false),
            /** This function can only be called once within a 5-second interval */
            initiateExport: debounce(initiateExport, 5000, {
                leading: true, // Invoke immediately on the first call
                trailing: false, // Ignore subsequent calls in the interval
            }),
        };
    }, [
        account,
        startDate,
        endDate,
        exportSent,
        confirmationModalIsVisible,
        initiateEmployeeEarningsExport,
    ]);
};
