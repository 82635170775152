import ButtonGroup, {
    TButtonGroupColor,
    TButtonGroupVariant,
} from '@components/buttons/ButtonGroup';

import { appendClasses } from '@helpers/ui';

import { IOption } from '@app/types/UITypes';

interface IFormButtonGroupProps {
    className?: string;
    variant?: TButtonGroupVariant;
    color?: TButtonGroupColor;
    value: string;
    options: IOption[];
    onChange: (newValue: string) => void;
}

const FormButtonGroup = ({
    className,
    variant = 'outlined',
    color = 'neutral',
    value,
    options,
    onChange,
}: IFormButtonGroupProps) => {
    const classNamesAppended = appendClasses([className, 'form-button-group']);

    return (
        <ButtonGroup className={classNamesAppended} variant={variant} color={color}>
            {options.map((option) => {
                const active = option.value === value;

                return (
                    <ButtonGroup.Button
                        key={option.value}
                        className={active ? 'active' : 'not-active'}
                        onClick={() => onChange(option.value)}
                    >
                        {option.label}
                    </ButtonGroup.Button>
                );
            })}
        </ButtonGroup>
    );
};

export default FormButtonGroup;
