import { useRef } from 'react';

import _QRCode from 'qrcode';

import './qrcode.scss';

const QRCode = ({ url }: { url: string }) => {
    let src: string = '';
    const ref = useRef<any>();

    (_QRCode as any).toDataURL(
        url,
        { errorCorrectionLevel: 'H' },
        (err: string, imgSrc: string) => {
            src = imgSrc;
        },
    );

    return <img ref={ref} className="qr-code" src={src} alt={url} />;
};

export default QRCode;
