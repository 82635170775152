import { FC, ReactElement, cloneElement, useEffect, useMemo, useState } from 'react';

import { difference } from 'lodash';

import Dropdown from '@components/dropdown/Dropdown';
import Form from '@components/form/Form';
import { AddCircleOutline } from '@components/icons';

import Toolbar from '../Toolbar';
import ToolbarGroup from '../ToolbarGroup';

import './FilterSegmentToolbar.scss';

interface FilterSegment {
    startIcon?: ReactElement<{}>;
    label: string;
    isActive: boolean;
    element: ReactElement;
}

export const useAddFilterButtonState = ({ segments }: { segments: FilterSegment[] }) => {
    const activeSegmentDependency = segments.map(({ isActive }) => isActive).join('-');

    const segmentOptions = useMemo(
        () =>
            segments
                .filter(({ isActive }) => !isActive)
                .map(({ startIcon, label, element }) => ({
                    startIcon,
                    label,
                    value: label,
                    element,
                })),
        [activeSegmentDependency],
    );

    const [popover, setPopover] = useState<undefined | ReactElement>();

    // Reset when a new one is added
    useEffect(() => {
        setPopover(undefined);
    }, [activeSegmentDependency]);

    /**
     * Currently only defaults to expanded for the options listed in
     * the description for the FilterSegmentToolbar component
     * */
    const handleFilterSelect = (element: ReactElement) => {
        setPopover(undefined);
        requestAnimationFrame(() => {
            const clonedElement = cloneElement(element, {
                className: 'new-segment-filter',
                // NOTE: Important for the filter to be expanded on click
                initializeExpanded: true,
            });

            setPopover(clonedElement);
        });
    };

    return {
        segmentOptions,
        popover,
        handleFilterSelect,
    };
};

interface AddFilterButtonProps {
    segments: FilterSegment[];
    addButtonText: string;
}

const AddFilterButton: FC<AddFilterButtonProps> = ({ segments, addButtonText }) => {
    const { segmentOptions, popover, handleFilterSelect } = useAddFilterButtonState({ segments });

    if (!segmentOptions.length) {
        return <></>;
    }

    return (
        <>
            {popover}
            <ToolbarGroup>
                <Dropdown color="blue" variant="flat">
                    <Dropdown.Button
                        className="filter-segment_add-btn"
                        startIcon={<AddCircleOutline />}
                        hideExpandIcon
                    >
                        {addButtonText}
                    </Dropdown.Button>
                    <Dropdown.Menu className="filter-segment_add-filter-menu">
                        {segmentOptions.map((option, i) => (
                            <Dropdown.MenuItem
                                key={`dropdown-menu-item-${i}-${option.value}-${option.label}`}
                                StartIcon={
                                    option.startIcon ? () => option.startIcon ?? <></> : undefined
                                }
                                onClick={() => handleFilterSelect(option.element)}
                            >
                                <span className="dropdown-menu-item_label">
                                    <span className="label-inner">{option.label}</span>
                                </span>
                            </Dropdown.MenuItem>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </ToolbarGroup>
        </>
    );
};

interface FilterSegmentToolbarProps {
    label?: string;
    required?: boolean;
    error?: boolean;
    segments: FilterSegment[];
    addButtonText?: string;
}

/**
 *
 * @description
 *
 * Currently only works for the following segment elements that have
 * a `initializeExpanded` prop, such as:
 *
 * - `Toolbar.DynamicStringDropdownListSelector`
 * - `Toolbar.DropdownListSelector`
 */
const FilterSegmentToolbar: FC<FilterSegmentToolbarProps> = ({
    label: toolbarLabel,
    required,
    error,
    segments,
    addButtonText = 'Filter',
}) => {
    const activeSegmentDependency = segments.map(({ isActive }) => isActive).join('-');
    const [activeIndexesInOrder, setActiveIndexesInOrder] = useState<number[]>([]);

    useEffect(() => {
        setActiveIndexesInOrder((prevValue) => {
            const activeIndexes = segments
                .map(({ isActive }, i) => (isActive ? i : -1))
                .filter((index) => index > -1);
            const activeIndexesToAppend = difference(activeIndexes, prevValue);
            const activeIndexesFromPrevValue = prevValue.filter((i) => activeIndexes.includes(i));

            return [...activeIndexesFromPrevValue, ...activeIndexesToAppend];
        });
    }, [activeSegmentDependency]);

    const toolbarSegmentContent = useMemo(() => {
        const orderedSegments = activeIndexesInOrder.map((index) => segments[index]);

        return orderedSegments.map(({ label, element }) => cloneElement(element, { key: label }));
    }, [activeIndexesInOrder, segments]);

    return (
        <>
            {toolbarLabel && (
                <Form.Label required={required} error={error}>
                    {toolbarLabel}
                </Form.Label>
            )}
            <div className="filter-segment-toolbar">
                <Toolbar justifyContentStart>
                    {toolbarSegmentContent}
                    <AddFilterButton segments={segments} addButtonText={addButtonText} />
                </Toolbar>
            </div>
        </>
    );
};

export default FilterSegmentToolbar;
