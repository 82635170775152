import ToolbarButton from '../ToolbarButton';
import ToolbarButtonGroup from '../ToolbarButtonGroup';
import ToolbarCheckbox from '../ToolbarCheckbox';
import ToolbarDateRangeSelector from '../ToolbarDateRangeSelector';
import ToolbarDropdown from '../ToolbarDropdown';
import ToolbarDropdownCheckboxMenu from '../ToolbarDropdownCheckboxMenu';
import ToolbarDropdownListSelector from '../ToolbarDropdownListSelector';
import ToolbarDropdownTreeSelect from '../ToolbarDropdownTreeSelect';
import ToolbarDynamicStringDropdownListSelector from '../ToolbarDynamicStringDropdownListSelector';
import ToolbarGroup from '../ToolbarGroup';
import ToolbarMultiSelectDropdown from '../ToolbarMultiSelectDropdown';
import ToolbarQuickViewQueryManager from '../ToolbarQuickViewQueryManager';
import ToolbarSearch from '../ToolbarSearch';
import ToolbarSelect from '../ToolbarSelect';
import ToolbarText from '../ToolbarText';
import Toolbar from './Toolbar';

export default Object.assign(Toolbar, {
    Button: ToolbarButton,
    ButtonGroup: ToolbarButtonGroup,
    Checkbox: ToolbarCheckbox,
    DateRangeSelector: ToolbarDateRangeSelector,
    Dropdown: ToolbarDropdown,
    DropdownCheckboxMenu: ToolbarDropdownCheckboxMenu,
    DropdownListSelector: ToolbarDropdownListSelector,
    DropdownTreeSelect: ToolbarDropdownTreeSelect,
    Group: ToolbarGroup,
    MultiSelectDropdown: ToolbarMultiSelectDropdown,
    QuickViewQueryManager: ToolbarQuickViewQueryManager,
    Search: ToolbarSearch,
    Select: ToolbarSelect,
    DynamicStringDropdownListSelector: ToolbarDynamicStringDropdownListSelector,
    Text: ToolbarText,
});
