import { useEffect, useRef } from 'react';

import { AccountType } from '@sparkplug/lib';

import { openUrl } from '@helpers/ui';

import { useFrillToken } from './useFrillToken';

interface FrillWidgetController {
    open: () => void;
    close: () => void;
    destroy: () => void;
    identify: ({ email, name }: { email: string; name: string }) => void;
    unidentify: () => void;
}

interface FrillConfig {
    key: string;
    ssoToken: string;
    callbacks: {
        onReady: (frillWidget: FrillWidgetController) => void;
        onBadgeCount?: (payload: { count: number }) => void;
    };
}

const WidgetKeys = {
    brandAdminAnnouncements: '37dd189e-549b-4b40-9aec-1df3150bf9d4',
    retailerAdminAnnouncements: 'ab9376d5-43e5-4e87-aa69-8abd67a0233f',
    employeeAnnouncements: '056da256-c807-4ffb-9aef-e41b43f2231c',
    brandAdminFeedback: '9abf25fe-f462-484e-be6d-e16a132a4ee5',
    retailerAdminFeedback: '6328a0dc-14c8-4a25-a72a-e3da9e1bb3e2',
    employeeFeedback: '918d7dd6-660c-4b93-98ab-5a62dbcbf687',
} as const;

type WidgetKey = keyof typeof WidgetKeys;

interface UseFrillWidgetProps {
    widgetKey: WidgetKey;
    accountType: AccountType;
    callbacks?: {
        onBadgeCount?: (payload: { count: number }) => void;
    };
}

/**
 * Modified from Frill example to return a MutableRefObject to be controlled in the UI
 *
 * Original example: https://github.com/Frill-co/frill-widget-examples/blob/master/react/src/components/FrillWidget.tsx
 */
export const useFrillWidget = ({
    widgetKey,
    accountType,
    callbacks,
}: {
    widgetKey: WidgetKey;
    accountType: AccountType;
    callbacks?: {
        onBadgeCount?: (payload: { count: number }) => void;
    };
}) => {
    const widgetRef = useRef<FrillWidgetController>();
    const { frillToken, frillAuthEmail, frillAuthName } = useFrillToken(accountType);

    // This effect will run once when the component has mounted
    useEffect(() => {
        if (!frillToken) {
            return () => {};
        }

        const win = typeof window !== 'undefined' ? window : ({} as any);

        const config: FrillConfig = {
            key: WidgetKeys[widgetKey],
            ssoToken: frillToken,
            callbacks: {
                onReady: (frillWidget) => {
                    if (frillAuthEmail && frillAuthName) {
                        frillWidget.identify({
                            email: frillAuthEmail,
                            name: frillAuthName,
                        });
                    }
                    widgetRef.current = frillWidget;
                },
                onBadgeCount: callbacks?.onBadgeCount,
            },
        };

        // Let's check if the Frill script has already loaded
        if ('Frill' in win) {
            // If the Frill api is already available we can create the widget now
            widgetRef.current = win.Frill.widget(config);
        } else {
            // If the Frill api hasn't been loaded, we need to add our config to the list.
            // When the api loads, it will create all widgets in the Frill_Config list and dispatch the
            // config.callbacks.onReady event for each
            win.Frill_Config = win.Frill_Config || [];
            win.Frill_Config.push(config);
        }

        // Return a cleanup method so we can remove the widget when the component unmounts
        return () => {
            // Check if there is an active widget
            if (widgetRef.current) {
                // If there is a widget, destroy it, this will remove all event listeners and nodes added
                // to the DOM by the widget
                widgetRef.current.destroy();
            }
            // We also need to remove our config from the list so it doesn't get initialised.
            // This would only happen if the had component mounted/unmounted before the Frill api
            // had a chance to load.
            if (win.Frill_Config) {
                win.Frill_Config = win.Frill_Config.filter((c: any) => c !== config);
            }
        };
    }, [frillToken]);

    return widgetRef;
};

export const useFrillAdminAnnouncementsWidget = (
    accountType: AccountType,
    callbacks?: UseFrillWidgetProps['callbacks'],
) => {
    const widgetKey =
        accountType === 'brand' ? 'brandAdminAnnouncements' : 'retailerAdminAnnouncements';
    return useFrillWidget({ widgetKey, accountType, callbacks });
};

export const useFrillAdminFeedbackWidget = (accountType: AccountType) => {
    const widgetKey = accountType === 'brand' ? 'brandAdminFeedback' : 'retailerAdminFeedback';
    return useFrillWidget({ widgetKey, accountType });
};

export const useFrillEmployeeAnnouncementWidget = (
    callbacks?: UseFrillWidgetProps['callbacks'],
) => {
    return useFrillWidget({
        widgetKey: 'employeeAnnouncements',
        accountType: 'retailer',
        callbacks,
    });
};

export const useFrillEmployeeFeedbackWidget = () => {
    return useFrillWidget({ widgetKey: 'employeeFeedback', accountType: 'retailer' });
};

export const openHelpCenter = () => {
    const url = 'https://help.sparkplug.app/';

    openUrl(url);
};
