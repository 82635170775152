import { useMemo } from 'react';

import axios from 'axios';

import {
    CreateExternalReferralRequest,
    LedgerBalanceResponseBody,
    LedgerEntry,
    ListLedgerEntriesResponseBody,
    ListRetailerBalancesRequestParams,
    ListRetailerBalancesResponseBody,
    RetailerBalance,
    Spark,
} from '@sparkplug/lib';

import { useSparkplugUsersById } from '@core/users';

import toast from '@components/toast';

import { useAdvancedQuery } from '@hooks/QueryHooks';

import { IAccount } from '@app/types/AccountsTypes';

export const groupPlanPromoBalanceKey = 'groupPlanPromoBalance' as const;
export const retailerReferralBalancesKey = 'retailerReferralBalances' as const;

const API = {
    async listGroupSparkCreditEntries({
        groupId,
        creditsOnly = false,
    }: {
        groupId?: string;
        creditsOnly: boolean;
    }): Promise<LedgerEntry[]> {
        if (!groupId) {
            return [];
        }

        const response = (
            await axios.get<ListLedgerEntriesResponseBody>(`/api/v1/ledger`, {
                params: { groupId, creditsOnly },
            })
        ).data;

        return response.data as LedgerEntry[];
    },
    async getPlanPromoBalance(groupId?: string): Promise<number> {
        if (!groupId) {
            return 0;
        }

        const response = (
            await axios.get<LedgerBalanceResponseBody>(`/api/v1/ledger/balance`, {
                params: { groupId },
            })
        ).data;

        return response.data;
    },
    async listRetailerBalances({
        brandId,
    }: ListRetailerBalancesRequestParams): Promise<RetailerBalance[]> {
        return (
            await axios.get<ListRetailerBalancesResponseBody>(`/api/v1/ledger/balance/retailers`, {
                params: { brandId },
            })
        ).data;
    },
};

export const getGroupSparkCreditEntriesKey = (accountId: string) => ['sparkCredits', accountId];
export function useGroupSparkCreditEntries({
    groupId,
    creditsOnly = false,
}: {
    groupId?: string;
    creditsOnly?: boolean;
}) {
    const {
        isLoading,
        data = [],
        refetch,
    } = useAdvancedQuery(
        [...getGroupSparkCreditEntriesKey(groupId!), `creditsOnly=${creditsOnly}`],
        async () => API.listGroupSparkCreditEntries({ groupId, creditsOnly }),
        {
            onError: () =>
                toast.error('Unable to retrieve Spark Credit entries. Please try again.'),
            enabled: !!groupId,
        },
    );

    return useMemo(
        () => ({
            groupSparkCreditEntries: data,
            groupSparkCreditEntriesAreReady: !isLoading,
            refetchGroupSparkCreditEntries: refetch,
        }),
        [isLoading, data.length],
    );
}

export function useGroupPlanPromoBalance({ groupId }: { groupId?: string }) {
    const {
        isLoading,
        data: balance,
        refetch,
    } = useAdvancedQuery(
        [groupPlanPromoBalanceKey, groupId],
        async () => API.getPlanPromoBalance(groupId),
        {
            onError: () => toast.error('Unable to retrieve balance. Please try again.'),
            enabled: !!groupId,
        },
    );

    return useMemo(
        () => ({
            groupPlanPromoBalance: balance,
            groupPlanPromoBalanceIsReady: !isLoading,
            refetchGroupPlanPromoBalance: refetch,
        }),
        [balance, isLoading],
    );
}

export function useRetailerReferralBalances(account?: IAccount) {
    const {
        isLoading,
        data = [],
        refetch,
    } = useAdvancedQuery(
        [retailerReferralBalancesKey, account?._id],
        async () => API.listRetailerBalances({ brandId: account?._id as string }),
        {
            onError: () => toast.error('Unable to retrieve credit history. Please try again.'),
            enabled: !!(account?._id && account?.type === 'brand'),
        },
    );

    return useMemo(
        () => ({
            retailerReferralBalances: data,
            retailerReferralBalancesAreReady: !isLoading,
            refetchRetailerReferralBalances: refetch,
        }),
        [data?.length, isLoading],
    );
}

export const useSparkCreators = ({
    account,
    sparks = [],
}: {
    account: IAccount;
    sparks?: Spark[];
}) => {
    const sparkCreatorIds = sparks
        .filter(({ creatorUserId }) => !!creatorUserId)
        .map(({ creatorUserId }) => creatorUserId);

    const { usersAreReady, users, refetchUsers } = useSparkplugUsersById({
        userIds: sparkCreatorIds,
    });

    return {
        sparkCreators: users,
        sparkCreatorsAreReady: usersAreReady,
        refetchSparkCreators: refetchUsers,
    };
};

export const sendExternalReferralRequestEmail = async (
    groupId: string,
    body: CreateExternalReferralRequest,
): Promise<void> => {
    await axios.post(`/api/v1/ledger/external-referral-request/${groupId}`, body);
};
