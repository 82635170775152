import { useMemo } from 'react';

import { useVendorChartContext } from '@core/charts/contexts/VendorChartContext';
import { useVendorChartTotal } from '@core/charts/queries/ChartsQueries';

/**
 * @description
 *
 * This custom hook, useComparisonDates, is designed to calculate the dates used to fetch the current,
 * comparison, and previous values for a vendor comparison chart.
 *
 * The `currentValue` represents the total value for the current period. However, if the current
 * period is still active (i.e., the current date falls within the period), we need to utilize the
 * `comparisonValue`. This `comparisonValue` is calculated using data up until yesterday. This
 * adjustment is necessary because the total value for the current period would be incorrect due
 * to its incomplete state.
 *
 * Similarly, the previous value should be calculated based on the same number of days as the
 * current and comparison values. For example, if the current period spans two weeks but today
 * is only halfway through, the previous period's value should only be calculated for one week,
 * preserving consistency.
 *
 * Example Usage:
 *
 * const { comparisonValues } = useVendorChartComparisonValues();
 * const { currentValue, comparisonValue, previousValue } = comparisonValues;
 *
 * The current variable would hold the total value for the current period.
 * The comparison variable would hold the total value for the same period up until yesterday.
 * The previous variable would hold the total value for the previous period, calculated for a matching duration if the current period is incomplete.
 */
export const useVendorChartComparisonValues = () => {
    const {
        vendorAccountId,
        chartSettings,
        showComparisonWindows,
        comparisonRange,
        comparisonEndDates,
    } = useVendorChartContext();
    const { currentComparisonTotalEndDate, previousComparisonTotalEndDate } = comparisonEndDates;

    const { vendorChartTotalIsReady: currentTotalIsReady, vendorChartTotal: currentTotal } =
        useVendorChartTotal(
            {
                vendorAccountId,
                calcType: chartSettings.metric,
            },
            chartSettings,
            true,
        );

    // Comparison Value
    const { vendorChartTotalIsReady: comparisonTotalIsReady, vendorChartTotal: comparisonTotal } =
        useVendorChartTotal(
            {
                vendorAccountId,
                calcType: chartSettings.metric,
            },
            {
                ...chartSettings,
                dateEnd: currentComparisonTotalEndDate,
            },
            showComparisonWindows,
        );

    // Previous Value
    const { vendorChartTotalIsReady: previousTotalIsReady, vendorChartTotal: previousTotal } =
        useVendorChartTotal(
            {
                vendorAccountId,
                calcType: chartSettings.metric,
            },
            {
                ...chartSettings,
                dateStart: comparisonRange?.previousDateStart ?? '',
                dateEnd: previousComparisonTotalEndDate ?? '',
            },
            showComparisonWindows,
        );

    const isLoadingTotals =
        !currentTotalIsReady ||
        (showComparisonWindows && (!comparisonTotalIsReady || !previousTotalIsReady));

    const comparisonValues = useMemo(
        () => ({
            currentValue: currentTotal?.total ?? 0,
            comparisonValue: comparisonTotal?.total ?? 0,
            previousValue: previousTotal?.total ?? 0,
        }),
        [currentTotal?.total, comparisonTotal?.total, previousTotal?.total],
    );

    return {
        isLoadingTotals,
        comparisonValues,
    };
};
