import { FC, useMemo, useState } from 'react';

import { AxiosError } from 'axios';

import { WithdrawalType, formatCurrency } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import { CustomArrowRightIcon } from '@components/icons';
import RadioGroup from '@components/inputs/RadioGroup';
import Drawer from '@components/overlays/Drawer';
import toast from '@components/toast';

import Intercom from '@helpers/Intercom';
import { openUrl } from '@helpers/ui';

import Typography from '@app/componentLibrary/Typography';

import { ALL_AVAILABLE_METHODS } from '../../constants';
import { useCashOutMutation } from '../../mutations/WalletMutations';
import CashOutConfirmationDrawer from '../CashOutConfirmationDrawer';
import { useCashOutOptions } from './useCashOutOptions';

import './CashOutDrawer.scss';

interface CashOutDrawerProps {
    isVisible: boolean;
    balance: number;
    onClose: (doActionOnClose: boolean) => void;
    defaultValue?: WithdrawalType;
}
const CashOutDrawer: FC<CashOutDrawerProps> = ({
    isVisible,
    onClose,
    balance = 0,
    defaultValue,
}) => {
    const [selectedMethod, setSelectedMethod] = useState<WithdrawalType | undefined>(defaultValue);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const selectedMethodDetails = useMemo(
        () => selectedMethod && ALL_AVAILABLE_METHODS[selectedMethod],
        [selectedMethod],
    );

    const { isCashingOut, cashOut } = useCashOutMutation();

    const cashOutOptions = useCashOutOptions(balance);

    const handleClose = (doActionOnClose: boolean) => {
        setSelectedMethod(undefined);
        onClose(doActionOnClose);
    };

    const cashOutWallet = () => {
        if (!selectedMethod || balance <= 0) {
            return;
        }

        cashOut(selectedMethod, {
            onSuccess: ({ tremendousLink }) => {
                openUrl(tremendousLink);
                setShowConfirmation(false);
                handleClose(true);
            },
            onError: (error) => {
                setShowConfirmation(false);
                handleClose(false);
                switch ((error as AxiosError)?.response?.status) {
                    case 423:
                        toast.error('Please try again in a minute');
                        break;
                    case 422:
                        toast.success('Check your cash out history');
                        break;
                    default:
                        toast.error(
                            'Something went wrong - please try again and contact support if this issue persists.',
                        );
                }
            },
        });
    };

    const balanceIsTooHigh = balance > 2000;

    return (
        <Drawer
            className="cash-out-drawer-wrapper bring-drawer-to-front"
            variant="bottomFull"
            title="Cash Out Details"
            onCloseHandler={handleClose}
            isVisible={isVisible}
            swipeable={false}
        >
            <div className="cash-out-drawer">
                <CashOutConfirmationDrawer
                    showConfirmation={showConfirmation}
                    setShowConfirmation={setShowConfirmation}
                    selectedMethodDetails={selectedMethodDetails}
                    totalAmount={balance}
                    cashOutWallet={cashOutWallet}
                    isCashingOut={isCashingOut}
                />
                <div className="cash-out-drawer-header">
                    <div className="cash-out-drawer-label">Cash Balance</div>
                    <div className="cash-out-drawer-header-amount">
                        {formatCurrency(balance, true)}
                    </div>
                </div>
                <div className="cash-out-drawer-methods">
                    <div className="cash-out-drawer-label">Choose a method to cash out:</div>
                    <RadioGroup
                        className="cash-out-drawer-radio-group-control"
                        radioGroupClassName="cash-out-drawer-radio-group"
                        color="neutral"
                        variant="button"
                        required
                        disabled={balance === 0 || isCashingOut}
                        alignment="center"
                        value={selectedMethod || ''}
                        options={cashOutOptions}
                        onChange={({ target }) => setSelectedMethod(target.value)}
                    />
                </div>
                <Drawer.Footer className="py-4">
                    {balanceIsTooHigh ||
                    selectedMethodDetails?.warningMessage ||
                    selectedMethodDetails?.fee ? (
                        <div className="cash-out-drawer-warning">
                            {balance > 2000 && (
                                <div className="contact-us-cashout">
                                    <header>Cash Out Limit Reached</header>
                                    <p>
                                        There’s a $2,000 cash out limit on all available methods.
                                        Please contact us so we can help you cash out!
                                    </p>
                                    <Button
                                        variant="smooth"
                                        onClick={() => {
                                            onClose(false);
                                            Intercom.open();
                                        }}
                                    >
                                        Contact Us to Cash Out
                                    </Button>
                                </div>
                            )}
                        </div>
                    ) : null}
                    {!balanceIsTooHigh && (
                        <Button
                            className="w-full [&>span>svg>path]:fill-cerulean-0"
                            variant="filled"
                            color="blue"
                            disabled={!selectedMethod || balance === 0 || isCashingOut}
                            onClick={() => {
                                setShowConfirmation(true);
                            }}
                            endIcon={<CustomArrowRightIcon />}
                            data-testid="cash-out-button"
                        >
                            <Typography variant="lg" className="!text-[17px]">
                                Next: Final Details
                            </Typography>
                        </Button>
                    )}
                </Drawer.Footer>
            </div>
        </Drawer>
    );
};

export default CashOutDrawer;
