import Button from '@components/buttons/Button';
import { ChevronRight as ArrowRightIcon } from '@components/icons';
import Paper from '@components/layout/Paper';
import Skeleton from '@components/layout/Skeleton';
import SparkPaymentSummary from '@components/sparks/SparkPaymentSummary';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useSpark, useSparkInvoiceUrl, useSparkPayouts } from '@hooks/SparksHooks/SparksHooks';

import { getSparkPaymentFromPayouts } from '@helpers/sparks';
import { openUrl } from '@helpers/ui';

import { ISparkPayout, ISparkPayoutGroup } from '@app/types/SparksTypes';

import { getLineItemsFromPayoutGroups } from '../SparkPaymentSummary/SparkPaymentSummary';

import './SparkPaymentSummaryPanel.scss';

const SparkPaymentSummaryPanel = ({
    isLoadingPayouts,
    payouts,
    payoutGroups,
    payoutsFinalizedAt,
    showInvoiceButton = false,
    showCheckoutButton = false,
}: {
    isLoadingPayouts: boolean;
    payouts: ISparkPayout[];
    payoutGroups: ISparkPayoutGroup[];
    payoutsFinalizedAt?: string;
    showCheckoutButton?: boolean;
    showInvoiceButton?: boolean;
}) => {
    const { account } = useSparkplugAccount();
    const { history } = useApp();
    const { spark } = useSpark();
    const { sparkInvoiceUrl } = useSparkInvoiceUrl(spark._id!, account?._id);

    const { paymentValue } = getSparkPaymentFromPayouts(payouts);

    const redirectToCheckout = () =>
        history.push(`/${account?._id}/sparks/${spark?._id}/manage/checkout`);

    const openInvoiceUrl = () => (sparkInvoiceUrl ? openUrl(sparkInvoiceUrl) : undefined);

    const lineItems = getLineItemsFromPayoutGroups(payoutGroups);

    return (
        <Paper className="spark-checkout-payment-summary-paper">
            <Paper.Title>
                <span>Payment Summary</span>
                {showInvoiceButton && sparkInvoiceUrl && (
                    <Button onClick={openInvoiceUrl} endIcon={<ArrowRightIcon />}>
                        View Invoice
                    </Button>
                )}
                {showCheckoutButton && (
                    <Button onClick={redirectToCheckout} endIcon={<ArrowRightIcon />}>
                        Checkout Spark
                    </Button>
                )}
            </Paper.Title>
            {!isLoadingPayouts ? (
                <SparkPaymentSummary
                    view={showInvoiceButton ? 'invoice' : 'checkout'}
                    paymentValue={paymentValue}
                    payoutsFinalizedAt={payoutsFinalizedAt}
                    lineItems={lineItems}
                />
            ) : (
                <Skeleton height={103} />
            )}
        </Paper>
    );
};

export const WrappedSparkPaymentSummaryPanel = ({
    showCheckoutButton = false,
    showInvoiceButton = false,
}: {
    showCheckoutButton?: boolean;
    showInvoiceButton?: boolean;
}) => {
    const { spark, sparkSubGroups, detailedSparkType } = useSpark();
    const {
        payouts,
        payoutGroups,
        payoutsFinalizedAt,
        isLoadingPayouts,
        multiLeaderboardPayoutsFinalizedAt,
    } = useSparkPayouts(spark, sparkSubGroups);

    const isMultiLeaderboard = detailedSparkType === 'leaderboardMulti';

    return (
        <SparkPaymentSummaryPanel
            showCheckoutButton={showCheckoutButton}
            showInvoiceButton={showInvoiceButton}
            isLoadingPayouts={isLoadingPayouts}
            payouts={payouts}
            payoutGroups={payoutGroups}
            payoutsFinalizedAt={
                isMultiLeaderboard ? multiLeaderboardPayoutsFinalizedAt : payoutsFinalizedAt
            }
        />
    );
};

export default SparkPaymentSummaryPanel;
