import { keyBy } from 'lodash';

import { IPublicAccount, IPublicUser, PublicGiftCard } from '@sparkplug/lib';

import { formatPhoneNumber } from '@helpers/ui';

import { IOption } from '@app/types/UITypes';

import { GiftCardsTableRow } from './GiftCardsTable/GiftCardsTable';

export const getGiftCardsTableRows = ({
    groups = [],
    users = [],
    superAdminUsers = [],
    giftCards = [],
    campaignOptions = [],
}: {
    groups: IPublicAccount[];
    users: IPublicUser[];
    superAdminUsers: IPublicUser[];
    giftCards: PublicGiftCard[];
    campaignOptions: IOption[];
}): GiftCardsTableRow[] => {
    const groupsById = keyBy(groups, '_id');
    const usersById = keyBy(users, '_id');
    const superAdminUsersById = keyBy(superAdminUsers, '_id');

    return giftCards.map(
        ({
            _id,
            amount,
            createdAt,
            groupId,
            userId,
            createdByUserId,
            deliveryLink,
            orderParameters,
            note,
        }) => {
            const superAdminUser = superAdminUsersById[createdByUserId];
            const createdBySuperUserName = superAdminUser
                ? `${superAdminUser.firstName ?? ''} ${superAdminUser.lastName ?? ''}`.trim()
                : '--';

            const recipientUser = usersById[userId];
            const recipientUserName = recipientUser
                ? `${recipientUser.firstName ?? ''} ${recipientUser.lastName ?? ''}`.trim()
                : '--';

            const recipientAccount = groupsById[groupId];

            const campaignName =
                campaignOptions.find(({ value }) => orderParameters?.campaignId === value)?.label ??
                '--';

            const contactMethod =
                orderParameters?.recipientEmail ??
                formatPhoneNumber(orderParameters?.recipientPhone!) ??
                '--';

            return {
                key: _id,
                createdAt,
                createdBySuperUserId: createdByUserId,
                createdBySuperUserName,
                recipientAccountName: recipientAccount?.name ?? '--',
                recipientAccountType: recipientAccount?.type ?? '--',
                recipientUserName,
                contactMethod,
                campaignName,
                amount,
                deliveryLink,
                note,
            };
        },
    );
};
