import { FC } from 'react';

import { QuickViewQueryManager, QuickViewQueryManagerProps } from '../QuickViewQueryManager';
import { useIsInToolbar } from '../Toolbar/useIsInToolbar';
import ToolbarGroup from '../ToolbarGroup';

import './ToolbarQuickViewQueryManager.scss';

const ToolbarQuickViewQueryManager: FC<QuickViewQueryManagerProps> = (props) => {
    useIsInToolbar({ componentName: 'Toolbar.QuickViewQueryManager' });
    return (
        <ToolbarGroup>
            <QuickViewQueryManager {...props} />
        </ToolbarGroup>
    );
};

export default ToolbarQuickViewQueryManager;
