import { CSSProperties, ElementType, EventHandler, FC, PropsWithChildren } from 'react';

import { noop } from 'lodash';

import { cn } from '@app/componentLibrary/utils';

import './ClickableArea.scss';

interface IClickableAreaProps {
    'data-testid'?: string;
    className?: string;
    onClick?: EventHandler<any>;
    tabIndex?: number;
    style?: CSSProperties;
    disabled?: boolean;
    elementType?: ElementType;
}

const ClickableArea: FC<PropsWithChildren<IClickableAreaProps>> = ({
    'data-testid': dataTestId,
    className = '',
    onClick,
    tabIndex = -1,
    children,
    style,
    disabled = false,
    elementType,
}) => {
    const Component = elementType ?? 'div';

    return (
        <Component
            data-testid={dataTestId}
            className={cn(
                'sp-clickable-area',
                className,
                disabled && '!opacity-50  !cursor-not-allowed',
            )}
            role="button"
            tabIndex={tabIndex}
            onKeyDown={disabled ? noop : onClick}
            onClick={disabled ? noop : onClick}
            style={style}
        >
            {children}
        </Component>
    );
};

export default ClickableArea;
