import { FC, ReactNode } from 'react';

import Button from '@components/buttons/Button';
import Modal, { IDefaultModalProps } from '@components/overlays/Modal';

import { appendClasses } from '@helpers/ui';

interface InfoModalProps extends IDefaultModalProps {
    className?: string;
    isVisible: boolean;
    title?: string;
    onClose: (doActionOnClose: boolean) => void;
    children: ReactNode;
    closeText?: string;
}

const InfoModal: FC<InfoModalProps> = ({
    className,
    isVisible,
    title,
    onClose,
    children,
    size,
    closeText = 'Close',
}) => {
    const classNamesAppended = appendClasses([className, 'info-modal']);

    return (
        <Modal
            className={classNamesAppended}
            isVisible={isVisible}
            onClose={() => onClose(false)}
            size={size}
        >
            {title && <Modal.Title>{title}</Modal.Title>}
            <Modal.Content>{children}</Modal.Content>
            <Modal.Actions>
                <Button color="blue" variant="flat" disabled={false} onClick={() => onClose(false)}>
                    {closeText}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default InfoModal;
