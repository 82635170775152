import React from 'react';

import { Typography } from '@mui/material';

import UpgradeModalV2 from '@components/overlays/UpgradeModal/UpgradeModalV2';

const SnapsUpgradeConfirmModal = ({
    isVisible,
    onClose,
    onConfirm,
}: {
    isVisible: boolean;
    onClose: () => void;
    onConfirm: () => void;
}) => {
    return (
        <UpgradeModalV2
            isVisible={isVisible}
            title="Tell your own Brand story through Snaps"
            content={
                <>
                    <Typography variant="body">
                        Craft and share fully branded content with Snaps, the newest addition to
                        SparkPlug. Promote products, educate employees, gather feedback, and monitor
                        performance effortlessly. Tell your brand story.
                    </Typography>
                    <Typography fontWeight={600} mt="12px">
                        Promote your products through Snaps, upgrade your subscription.{' '}
                    </Typography>
                </>
            }
            onClose={onClose}
            onConfirm={onConfirm}
            image={<img src="/static/images/snaps-feature.jpg" alt="Update to use snaps" />}
        />
    );
};

export default SnapsUpgradeConfirmModal;
