import { z } from 'zod';
import { AccountMarket, ACCOUNT_MARKETS_RECORD } from '../account';

const entitlementSchema = z.object({
  accessStartDate: z.string(),
  maxPeriodQuantity: z.number().optional(),
  markets: z.array(z.custom<AccountMarket>()).optional(),
});

const stateAbbreviations = Object.keys(ACCOUNT_MARKETS_RECORD).map((abbr) =>
  abbr.toLowerCase(),
) as Lowercase<AccountMarket>[];

const inventoryFeatures = stateAbbreviations.map((abbr) => `inventory.${abbr}` as const);

const primaryFeatures = ['snaps', 'basicSpark', 'inventory'] as const;
export type PrimaryFeatures = typeof primaryFeatures[number];

const FeatureLookupKeysEnum = z.enum([...primaryFeatures, ...inventoryFeatures]);
export type FeatureLookupKeys = z.infer<typeof FeatureLookupKeysEnum>;

const subscriptionConfigSchema = z.object({
  accountId: z.string(),
  stripeSubscriptionId: z.string(),
  billingPeriodStart: z.string(),
  billingPeriodEnd: z.string(),
  entitlements: z.record(entitlementSchema, FeatureLookupKeysEnum),
});

export type Entitlement = z.infer<typeof entitlementSchema>;
export type SubscriptionConfig = z.infer<typeof subscriptionConfigSchema>;
