import { FC, PropsWithChildren } from 'react';

import { Box } from '@mui/material';

import { PublicSnapTemplate } from '@sparkplug/lib';

import { Snaps } from '@components/icons';
import Spinner from '@components/layout/Spinner';

import SnapPosterThumbnail, { SnapPosterThumbnailProps } from '../SnapPosterThumbnail';
import { useFreshSnapTemplateThumbnail } from './useFreshSnapTemplateThumbnail';

/**
 * Creates a consistent look for the fallback elements.
 */
const FallbackContainer: FC<PropsWithChildren<{ dataTestId?: string }>> = ({
    children,
    dataTestId,
}) => (
    <Box
        data-testid={dataTestId}
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: 'white' }}
    >
        {children}
    </Box>
);

interface TemplateThumbnailProps {
    sparkplugAccountId: string;
    snapTemplate: PublicSnapTemplate;
    dropdownMenu?: SnapPosterThumbnailProps['dropdownMenu'];
}
export const TemplateThumbnail: FC<TemplateThumbnailProps> = ({
    sparkplugAccountId,
    snapTemplate,
    dropdownMenu,
}) => {
    const {
        freshThumbnail,
        freshThumbnailStatus,
        isLoadingFreshThumbnail,
        thumbnailImgIsBroken,
        handleThumbnailImgError: handlePreviewImgError,
    } = useFreshSnapTemplateThumbnail({ snapTemplate, sparkplugAccountId });

    let previewElement = (
        <img
            src={freshThumbnail}
            alt={snapTemplate.name}
            onError={handlePreviewImgError}
            data-testid="template-thumbnail_success"
        />
    );
    if (isLoadingFreshThumbnail) {
        previewElement = (
            <FallbackContainer dataTestId="template-thumbnail_loading">
                <Spinner />
            </FallbackContainer>
        );
    } else if (freshThumbnailStatus === 'failed' || thumbnailImgIsBroken) {
        previewElement = (
            <FallbackContainer dataTestId="template-thumbnail_error">
                <div className="img-broken">
                    <Snaps />
                </div>
            </FallbackContainer>
        );
    }

    return (
        <SnapPosterThumbnail
            imgElement={previewElement}
            title={snapTemplate.name}
            slideCount={snapTemplate.slideCount || 0}
            dropdownMenu={dropdownMenu}
        />
    );
};
