import { FC } from 'react';

import Paper from '@components/layout/Paper';
import Tooltip from '@components/layout/Tooltip';

import { appendClasses } from '@helpers/ui';

import { SparkBrandGalleryChip } from './SparkBrandGalleryChip';

import './SparkBrandGalleryItem.scss';

const DisabledOverlay: FC<{ showTooltip?: boolean }> = ({ showTooltip = true }) => {
    if (showTooltip) {
        return (
            <Tooltip title="Disabled">
                <div className="spark-brand-disabled-overlay" />
            </Tooltip>
        );
    }
    return <div className="spark-brand-disabled-overlay" />;
};

interface SparkBrandGalleryItemProps {
    name: string;
    logo: string;
    onHover: () => void;
    isEnabled: boolean;
    isActive?: boolean;
    isInteractive?: boolean;
}
export const SparkBrandGalleryItem: FC<SparkBrandGalleryItemProps> = ({
    name,
    logo,
    onHover,
    isActive = false,
    isInteractive = true,
    isEnabled = true,
}) => (
    <Paper
        onMouseOver={isInteractive && isEnabled ? onHover : undefined}
        className={appendClasses([
            'spark-brand-gallery-item',
            isActive && isInteractive && isEnabled ? 'show-active-border' : undefined,
        ])}
    >
        {!isEnabled && <DisabledOverlay />}

        <div className="gallery-item-img-container">
            <img loading="lazy" src={logo} alt={`${name} logo`} />
        </div>

        <SparkBrandGalleryChip label={name} />
    </Paper>
);
