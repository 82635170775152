import PageLoading from '@components/layout/PageLoading';
import Modal from '@components/overlays/Modal';

const SparkLoadingModal = ({ isVisible, onClose }: { isVisible: boolean; onClose: () => void }) => (
    <Modal isVisible={isVisible} className="spark-loading-modal" onClose={onClose}>
        <PageLoading label="Loading Spark..." />
    </Modal>
);

export default SparkLoadingModal;
