import { AccountLink, DO_NOT_HAVE_PERMISSIONS_MESSAGE } from '@sparkplug/lib';

import { RequestSparkButton } from '@features/request-for-spark/components/RequestSparkButton';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import Chip from '@components/chips/Chip';
import Dropdown from '@components/dropdown/Dropdown';
import {
    Add as CreateIcon,
    Cancel as DeleteIcon,
    DisabledLinkIcon,
    GeneralSettingsIcon,
    LinkIcon,
    MoreHoriz as MenuIcon,
} from '@components/icons';
import Tooltip from '@components/layout/Tooltip';
import CreateSparkButton from '@components/sparks/CreateSparkButton';
import Table from '@components/table/Table';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import Typography from '@app/componentLibrary/Typography';

import { useAccountLinksTableActions } from './useAccountLinksTableActions';

/**
 * Only present for retailers.
 * Only visible when a vendor has requested a link with a retailer.
 */
const VendorLinkInboxActionCell = ({
    accountLink,
    userCanManageLinks,
}: {
    accountLink: AccountLink;
    userCanManageLinks: boolean;
}) => {
    const disabledTooltip = userCanManageLinks ? undefined : DO_NOT_HAVE_PERMISSIONS_MESSAGE;

    const { isRespondingToAccountLinkRequest, onRespondToAccountLinkRequest, onManageAccountLink } =
        useAccountLinksTableActions();

    const rejectLinkRequest = () => onRespondToAccountLinkRequest(accountLink.accountId, false);

    return (
        <Table.Cell align="right">
            <section className="flex gap-4 justify-end items-center">
                <Button
                    disabled={!userCanManageLinks}
                    tooltip={disabledTooltip}
                    startIcon={<CreateIcon className="text-cerulean-600" />}
                    variant="outlined"
                    color="blue"
                    onClick={() => onManageAccountLink(accountLink)}
                >
                    Create Link
                </Button>
                <IconButton
                    onClick={rejectLinkRequest}
                    disabled={isRespondingToAccountLinkRequest || !userCanManageLinks}
                    tooltip={disabledTooltip}
                    color="neutral"
                >
                    <DeleteIcon className="text-gray-800" />
                </IconButton>
            </section>
        </Table.Cell>
    );
};

const disabledVendorLinkMessage =
    'This link is disabled. The Vendor cannot send you Spark offers or view product sales data.';
const disabledRetailerLinkMessage = 'Sparks are disabled for this Retailer';

/**
 * Visible for retailer and vendors, though there are slight variations in the actions available.
 */
const AccountLinkActionCell = ({
    accountLink,
    userCanManageLinks,
}: {
    accountLink: AccountLink;
    userCanManageLinks: boolean;
}) => {
    const linkType = accountLink.accountType;

    const {
        onCreateSpark,
        onManageAccountLink,
        updateAccountLinkStatus,
        isUpdatingAccountLinkStatus,
    } = useAccountLinksTableActions();

    const enabledActions =
        linkType === 'brand' ? (
            <RequestSparkButton variant="outlined" accountLink={accountLink} />
        ) : (
            <CreateSparkButton
                showSplitButton={false}
                variant="outlined"
                onClick={() => onCreateSpark(accountLink)}
            />
        );

    const disabledChip = (
        <Tooltip
            title={linkType === 'brand' ? disabledVendorLinkMessage : disabledRetailerLinkMessage}
        >
            <span>
                <Chip dense color="neutral" label="Disabled" icon={<DisabledLinkIcon />} />
            </span>
        </Tooltip>
    );

    return (
        <Table.Cell align="right">
            <section className="flex gap-4 justify-end items-center">
                {accountLink.status === 'enabled' && enabledActions}

                {accountLink.status === 'disabled' && disabledChip}

                <Dropdown>
                    <Dropdown.IconButton
                        disabled={!userCanManageLinks}
                        tooltip={!userCanManageLinks ? DO_NOT_HAVE_PERMISSIONS_MESSAGE : undefined}
                        color="neutral"
                    >
                        <MenuIcon className="text-gray-800" />
                    </Dropdown.IconButton>
                    <Dropdown.Menu>
                        <Dropdown.MenuItem
                            StartIcon={() => (
                                <GeneralSettingsIcon className="h-5 w-5 text-gray-800" />
                            )}
                            onClick={() => onManageAccountLink(accountLink)}
                            disabled={!userCanManageLinks}
                        >
                            <Typography className="text-gray-900">Manage Link</Typography>
                        </Dropdown.MenuItem>
                        {linkType === 'brand' &&
                            accountLink.status === 'disabled' &&
                            !!accountLink.brandLinks.length && (
                                <Dropdown.MenuItem
                                    StartIcon={() => <LinkIcon className="h-5 w-5 text-gray-800" />}
                                    onClick={() =>
                                        updateAccountLinkStatus({
                                            accountLink,
                                            updatedStatus: 'enabled',
                                        })
                                    }
                                    disabled={!userCanManageLinks || isUpdatingAccountLinkStatus}
                                >
                                    <Typography className="text-gray-900">Enable Link</Typography>
                                </Dropdown.MenuItem>
                            )}
                        {linkType === 'brand' && accountLink.status === 'enabled' && (
                            <Dropdown.MenuItem
                                StartIcon={() => (
                                    <DisabledLinkIcon className="h-5 w-5 text-gray-800" />
                                )}
                                onClick={() =>
                                    updateAccountLinkStatus({
                                        accountLink,
                                        updatedStatus: 'disabled',
                                    })
                                }
                                disabled={!userCanManageLinks || isUpdatingAccountLinkStatus}
                            >
                                <Typography className="text-gray-900">Disable Link</Typography>
                            </Dropdown.MenuItem>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </section>
        </Table.Cell>
    );
};

export interface AccountLinksTableActionCellProps {
    accountLink: AccountLink;
}
export const AccountLinksTableActionCell = ({ accountLink }: AccountLinksTableActionCellProps) => {
    const { userCan } = useSparkplugAccount();
    const userCanManageLinks = userCan('manageLinks');

    switch (accountLink.status) {
        case 'pending':
            return (
                <VendorLinkInboxActionCell
                    accountLink={accountLink}
                    userCanManageLinks={userCanManageLinks}
                />
            );
        case 'disabled':
        case 'enabled':
        default:
            return (
                <AccountLinkActionCell
                    accountLink={accountLink}
                    userCanManageLinks={userCanManageLinks}
                />
            );
    }
};
