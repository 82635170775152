import { FC, useMemo, useState } from 'react';

import SparksAPI from '@api/SparksAPI';

import { Spark } from '@sparkplug/lib';

import CalloutMessage from '@components/layout/CalloutMessage';
import toast from '@components/toast';

import { useSparkQuery } from '@hooks/SparksHooks/SparksHooks';

import { getIntervalString } from '@helpers/recurring';
import SparksUI from '@helpers/toast/SparksUI';

import ConfirmModal from '../ConfirmModal';
import { IDefaultModalProps } from '../Modal';
import { RecurringSparkRadioGroup } from './RecurringSparkRadioGroup';

import './DeleteSparkModal.scss';

const DELETE_RECURRING_OPTIONS = ['thisSparkOnly', 'thisSparkAndFuture'] as const;

export type DeleteRecurringSparkOption = (typeof DELETE_RECURRING_OPTIONS)[number];

const RecurringSparkMessage = ({ intervalText }: { intervalText: string }) => (
    <span>
        Are you sure you wish to delete this Spark? This is a{' '}
        <strong>{intervalText} recurring Spark.</strong>
    </span>
);

interface DeleteSparkModalProps extends IDefaultModalProps {
    spark: Spark;
}

const DeleteSparkModal: FC<DeleteSparkModalProps> = ({ isVisible, onClose, spark }) => {
    const [deleteRecurringOption, setDeleteRecurringOption] =
        useState<DeleteRecurringSparkOption>('thisSparkOnly');

    const { recurringSchedule } = spark;

    const intervalString = useMemo(() => {
        return recurringSchedule
            ? getIntervalString(recurringSchedule?.interval, { capitalize: true })
            : '';
    }, [recurringSchedule?.interval]);

    const onRemove = (recurring = false) => {
        SparksUI.deleteSpark(spark._id).then(() => {
            if (recurring) {
                toast.success(`${intervalString} recurring; spark created`);
            }
            onClose(true);
        });
    };

    const onRemoveRecurring = () => {
        if (spark.recurringSparkScheduleId && deleteRecurringOption === 'thisSparkAndFuture') {
            SparksAPI.endRecurringSparkSchedule(spark._id)
                .then(() => toast.success(`${intervalString} recurring spark deleted`))
                .catch((e) => toast.error(`Something went wrong: ${e}`))
                .finally(() => onClose(true));
        } else {
            onRemove(!!spark.recurringSparkScheduleId);
        }
    };

    return recurringSchedule ? (
        <ConfirmModal
            title="Delete recurring Spark?"
            className="delete-spark-modal"
            message={
                <RecurringSparkMessage
                    intervalText={recurringSchedule.interval.replace(/_/g, '-')}
                />
            }
            isVisible={isVisible}
            onConfirm={onRemoveRecurring}
            onClose={() => onClose(false)}
            extraModalContent={
                <RecurringSparkRadioGroup
                    dateStart={spark.startDate}
                    dateEnd={spark.endDate}
                    value={deleteRecurringOption}
                    onChange={setDeleteRecurringOption}
                />
            }
        />
    ) : (
        <ConfirmModal
            title="Delete Spark?"
            className="delete-spark-modal"
            confirmBtnColor="red"
            confirmText="Delete"
            message={
                <div>
                    <CalloutMessage
                        color="red"
                        customIcon="alert"
                        message={
                            ' When deleting a Spark, all data will be deleted and participants will not be\n' +
                            '                        able to claim earnings. This action cannot be undone.'
                        }
                    />
                    <p>
                        Are you sure you want to delete the <span>{spark.name}</span> Spark?
                    </p>
                </div>
            }
            isVisible={isVisible}
            onConfirm={onRemove}
            onClose={() => onClose(false)}
        />
    );
};

export default (props: IDefaultModalProps & { sparkId?: string | null }) => {
    const { sparkId, ...otherProps } = props;
    const { spark, sparkIsReady } = useSparkQuery(sparkId || '');

    if (!sparkId || !spark || !sparkIsReady) {
        return <></>;
    }

    return <DeleteSparkModal {...otherProps} spark={spark} />;
};
