import { useState } from 'react';

import { ButtonColor } from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { Close as CloseIcon } from '@components/icons';

import { appendClasses } from '../../helpers/ui';

import './NoticeBar.scss';

type TNoticeBar = {
    message: string;
    className?: string;
    actionButton?: any;
    backgroundColor?: 'blue'; // default for now, can be extended if this is reused
    closeButtonColor?: ButtonColor;
};

export const NoticeBar = ({
    message,
    className,
    actionButton,
    backgroundColor = 'blue',
    closeButtonColor = 'white',
}: TNoticeBar) => {
    const classNamesAppended = appendClasses(['notice-bar', className, `color-${backgroundColor}`]);
    const [showNoticeBar, setShowNoticeBar] = useState(true);

    return (
        <>
            {showNoticeBar && (
                <div className={classNamesAppended}>
                    {message}
                    {actionButton}
                    <IconButton
                        className="close"
                        color={closeButtonColor}
                        onClick={() => setShowNoticeBar(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            )}
        </>
    );
};
