import axios from 'axios';

import { SubmitSurveyResponseRequestBody } from '@sparkplug/lib';

import { getUserWalletQueryKey } from '@features/wallet/queries';

import { useApp } from '@hooks/AppHooks';
import { useAdvancedMutation, useQueryClient } from '@hooks/QueryHooks';

import { EMPLOYEE_LEARNING_RESOURCES_CACHE_KEY } from '../queries/useGetEmployeeLearningResources';

const API = {
    submitSurveyResponse: async (payload: SubmitSurveyResponseRequestBody) => {
        return (
            await axios.post<SubmitSurveyResponseRequestBody>(
                `/api/v1/learning-resource/${payload.learningResourceId}/response`,
                payload,
            )
        ).data;
    },
};

export const useSubmitSurveyResponse = () => {
    const { user } = useApp();
    const queryClient = useQueryClient();

    return useAdvancedMutation(
        (payload: SubmitSurveyResponseRequestBody) => API.submitSurveyResponse(payload),
        {
            customOptions: {
                onSuccess: () => {
                    // Ensure we're invalidating with the exact same key structure
                    queryClient.invalidateQueries({
                        queryKey: [EMPLOYEE_LEARNING_RESOURCES_CACHE_KEY],
                    });
                    queryClient.refetchQueries({
                        queryKey: [EMPLOYEE_LEARNING_RESOURCES_CACHE_KEY],
                        exact: false,
                    });

                    queryClient.invalidateQueries({
                        queryKey: getUserWalletQueryKey(user?._id || ''),
                    });
                },
            },
            toastOptions: {
                loading: 'Submitting survey response...',
                success: 'Survey response submitted successfully',
                error: 'Failed to submit survey response',
            },
        },
    );
};

export const useSaveSurveyResponseProgess = () => {
    return useAdvancedMutation(
        (payload: SubmitSurveyResponseRequestBody) => API.submitSurveyResponse(payload),
        {},
    );
};
