import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    buildProductHierarchy,
    getAccountByGroupId,
    getAccountQueryKey,
    getAccountsPaymentHealth,
    getAllGroups,
    getPaymentMethods,
    getPosBrands,
    getPosCategories,
    getPosEmployeeProfiles,
    getPosLocations,
    getPosProducts,
    getTotalProductTagCount,
    getVendorRetailer,
} from '@core/accounts';
import { useAccountSparkplugUsersQueryByGroupId, useAccountUsersQuery } from '@core/users';

import { getVendorRetailerQueryKey } from '@features/account-links';
import { useVendorRetailerPosData } from '@features/product-tags/queries/useVendorRetailerPosData';

import { AccountContext, BrandRetailerContext } from '@contexts/AccountContext';

import { getBrandLinks, getProspectiveBrandLinks } from '@helpers/brandLinks';
import { getUniqueLeavesFromList } from '@helpers/ui';
import { isEmpty, isValidObjectId, noop } from '@helpers/util';

import {
    IAccount,
    IAccountPosDataContext,
    IBrandRetailer,
    TAccountType,
} from '@app/types/AccountsTypes';
import { IPosEmployeeProfile, IPosLocation, IPosProduct } from '@app/types/PosTypes';
import {
    IAccountUser,
    IHydratedPublicAccountOption,
    IPublicAccountOption,
} from '@app/types/UsersTypes';

import { useApp } from '../AppHooks';
import { useAdvancedQuery, useQueries, useQueryClient } from '../QueryHooks';

export interface AccountUserFilters {
    status?: string;
    role?: string;
    location?: string;
    market?: string;
}

export const UserFilterFns: Record<
    keyof AccountUserFilters,
    (value: string) => (user: IAccountUser) => boolean
> = {
    status: (value: string) => {
        return (user: IAccountUser) => {
            if (value === 'all') {
                return true;
            }

            if (value === 'none') {
                return !user?.smsStatus || user?.smsStatus === 'none';
            }

            return user?.smsStatus === value;
        };
    },
    role: (value: string) => {
        return (user: IAccountUser) => {
            if (value === 'all') {
                return true;
            }

            if (value === 'active') {
                return user != null ? user?.role !== 'none' : true;
            }

            if (value === 'manager') {
                return !!user?.managedLocationIds?.length;
            }

            if (!user) {
                return false;
            }
            const { role } = user;
            return role === value;
        };
    },
    location: (value: string) => {
        return (user: IAccountUser) => {
            if (value === 'all') {
                return true;
            }

            return (user?.locationIds || []).includes(value);
        };
    },
    market: (value: string) => {
        return (user: IAccountUser) => {
            const marketValues = value.split(', ');
            if (marketValues.includes('all')) {
                return true;
            }

            return (
                (user?.permissions?.markets || []).filter((market) => marketValues.includes(market))
                    .length > 0
            );
        };
    },
};

const ProductFilterFns = {
    brandIds: (value: string) => {
        return (product: IPosProduct) => {
            if (value.length === 0) {
                return true;
            }

            return (product?.brands || []).some(({ _id }) => value.includes(_id));
        };
    },
    categoryIds: (value: string) => {
        return (product: IPosProduct) => {
            if (value.length === 0) {
                return true;
            }

            return (product?.categories || []).some(({ _id }) => value.includes(_id));
        };
    },
};

export const useUserAccounts = (userId?: string, isEnabled: boolean = true) => {
    const {
        isFetched,
        data = [],
        refetch,
    } = useAdvancedQuery(['groups', userId], () => getAllGroups(userId), {
        enabled: !!userId && isEnabled,
    });

    return {
        accountsAreReady: isFetched,
        accounts: data,
        refetchAccounts: refetch,
    };
};
export const useSparkPlugAccountsPaymentHealth = (accountIds: string[]) => {
    const { data, isLoading } = useAdvancedQuery(
        ['account', accountIds, 'paymentHealth'],
        () => getAccountsPaymentHealth(accountIds),
        {
            enabled: accountIds.length > 0,
        },
    );

    return {
        paymentHealthIsReady: !isLoading,
        paymentHealth: data,
    };
};

export const useSparkplugAccounts = (
    type: TAccountType | 'all' = 'all',
    isEnabled: boolean = true,
) => {
    const {
        isFetched,
        data = [],
        refetch,
    } = useAdvancedQuery(['groups'], () => getAllGroups(), {
        enabled: isEnabled,
    });

    const accounts: IPublicAccountOption[] = useMemo(() => {
        if (type === 'all') {
            return data;
        }

        return data.filter((group) => group.type === type);
    }, [type, data]);

    const accountsWithThings: IHydratedPublicAccountOption[] = useMemo(() => {
        return accounts.map((group) => ({
            ...group,
            hubspotUrl: group.hubspotId
                ? `https://app.hubspot.com/contacts/9234261/record/2-4005971/${group.hubspotId}`
                : undefined,
        }));
    }, [accounts]);

    return {
        accountsAreReady: isFetched,
        accounts: accountsWithThings,
        refetchAccounts: refetch,
    };
};

export const useAccountQueryByGroupId = (accountId?: string, fetchBrandLinks = true) => {
    const { user, userIsAdmin } = useApp();
    const queryClient = useQueryClient();

    const {
        isLoading = true,
        isFetched,
        data: account,
        refetch: refetchAccount,
        isRefetching,
    } = useAdvancedQuery(
        getAccountQueryKey(accountId ?? ''),
        () => getAccountByGroupId(accountId, true, fetchBrandLinks && userIsAdmin, queryClient),
        {
            enabled: !!user && !!accountId,
        },
    );

    return {
        isReady: isFetched,
        isLoading,
        account,
        refetchAccount,
        isRefetching,
    };
};
export const useAppAccount = () => {
    const { accountId } = useParams<{
        accountId: string;
    }>();

    return useAccountQueryByGroupId(accountId);
};
export const useSparkplugAccount = () => {
    const context = useContext(AccountContext);

    if (!context) {
        // eslint-disable-next-line no-console
        console.log(`useSparkplugApp must be used within AccountProvider`);
    }

    return context;
};

export const withSparkplugAccount = (Component: any) => {
    return (props: any) => {
        const app = useSparkplugAccount();
        return <Component {...props} {...app} />;
    };
};

export type TPosDatasetType = 'users' | 'locations' | 'brands' | 'categories' | 'products';

type TQueryResult = {
    data: any;
    isLoading: boolean;
    isFetched: boolean;
    refetch: () => any;
};

export type TQueryInfo = {
    key: TPosDatasetType;
    queryKey: any[];
    queryFn: () => Promise<any>;
};

export type TCustomQueryOptionsFn = (datasetKey: TPosDatasetType) => {
    enabled: boolean;
    refetchOnWindowFocus: boolean;
    refetchOnMount: boolean;
    refetchOnReconnect: boolean;
    retry: boolean;
};

type TIncludedPosDatasets = 'all' | TPosDatasetType | TPosDatasetType[];

interface IAccountPosDataQueryOptions {
    isEnabled?: boolean;
    includedDatasets?: TIncludedPosDatasets;
    applyCustomOptions?: TCustomQueryOptionsFn;
}

export const useAccountPaymentMethods = (accountId: string) => {
    const { data, isLoading } = useAdvancedQuery(
        ['account', accountId, 'paymentMethods'],
        () => getPaymentMethods(accountId),
        { enabled: !!accountId },
    );

    return useMemo(() => {
        return {
            paymentMethods: data?.data || [],
            defaultPaymentMethodId:
                data?.data.find((method) => method.default)?.id ||
                data?.data.find((method) => method.type === 'bank')?.id ||
                '',
            paymentMethodsAreReady: !isLoading,
        };
    }, [data, isLoading]);
};

export const useAccountPosDataQuery = (
    accountId: string,
    queryOptions?: IAccountPosDataQueryOptions,
    posProductIds?: string[],
) => {
    const { isEnabled = true, includedDatasets = 'all', applyCustomOptions } = queryOptions || {};

    // Note: The order here correlates with `queryResults`
    // Note: Each `key` in the `posQuerySet` is passed to the optional `applyCustomOptions`
    const posQuerySet: TQueryInfo[] = [
        {
            key: 'users',
            queryKey: ['accountPosData', accountId, 'users'],
            queryFn: () => getPosEmployeeProfiles(accountId),
        },
        {
            key: 'locations',
            queryKey: ['accountPosData', accountId, 'locations'],
            queryFn: () => getPosLocations(accountId),
        },
        {
            key: 'brands',
            queryKey: ['accountPosData', accountId, 'brands'],
            queryFn: () => getPosBrands(accountId),
        },
        {
            key: 'categories',
            queryKey: ['accountPosData', accountId, 'categories'],
            queryFn: () => getPosCategories(accountId),
        },
        {
            key: 'products',
            queryKey: [
                'accountPosData',
                accountId,
                'products',
                ...(posProductIds?.length ? [posProductIds.join(',')] : []),
            ],
            queryFn: () => getPosProducts(accountId, posProductIds),
        },
    ];

    // Edge case: if 'users' are in the `includedDatasets`, then 'locations' must be as well.
    // This is because an account's active users is dependent its active locations.
    //
    // TODO - potentially add 'allUsers' to 'TPosDatasetType' for when we don't care about the active users
    // and want to unpin the users from the locations.
    const finalIncludedDatasets = useMemo(() => {
        const appendToIncludedDatasets = (keyToAppend: TPosDatasetType) => {
            if (Array.isArray(includedDatasets)) {
                return [...includedDatasets, keyToAppend];
            }
            return [includedDatasets, keyToAppend];
        };

        const includedDatasetHasUsersWithoutLocations =
            includedDatasets === 'users' ||
            (Array.isArray(includedDatasets) &&
                includedDatasets.includes('users') &&
                !includedDatasets.includes('locations'));

        return includedDatasetHasUsersWithoutLocations
            ? appendToIncludedDatasets('locations')
            : includedDatasets;
    }, [includedDatasets]);

    const queryResults: TQueryResult[] = useQueries(
        posQuerySet.map((queryInfo: TQueryInfo) => {
            const { key, queryKey, queryFn } = queryInfo;

            const isIncludedDataSet =
                finalIncludedDatasets === 'all' ||
                (Array.isArray(finalIncludedDatasets)
                    ? finalIncludedDatasets?.includes(key)
                    : finalIncludedDatasets === key);

            const enabled = isIncludedDataSet && isEnabled && isValidObjectId(accountId);

            const customOptions = applyCustomOptions?.(key);

            return {
                queryKey,
                queryFn,
                enabled,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
                refetchOnReconnect: false,
                retry: false,
                staleTime: 1000 * 60 * 30,
                ...customOptions,
            };
        }),
    );

    // Note: The order here correlates with `posQuerySet`
    const [
        usersQueryResults,
        locationsQueryResults,
        brandsQueryResults,
        categoriesQueryResults,
        productsQueryResults,
    ] = queryResults;

    const accountActivePosLocations = useMemo<IPosLocation[]>(() => {
        const allPosLocations: IPosLocation[] = locationsQueryResults?.data ?? [];
        if (!isEmpty(allPosLocations)) {
            const activePosLocations = allPosLocations.filter(({ disabled }) => disabled !== true);
            return activePosLocations;
        }

        return [];
    }, [locationsQueryResults?.data]);

    const accountActivePosEmployeeProfiles = useMemo<IPosEmployeeProfile[]>(() => {
        const allPosEmployeeProfiles = usersQueryResults?.data ?? [];
        if (!isEmpty(allPosEmployeeProfiles) && !isEmpty(accountActivePosLocations)) {
            const activePosEmployeeProfiles = allPosEmployeeProfiles.filter(
                ({ locationIds = [] }) => {
                    return locationIds.some((locationId) => {
                        return accountActivePosLocations.some(({ _id }) => locationId === _id);
                    });
                },
            );
            return activePosEmployeeProfiles;
        }

        return [];
    }, [usersQueryResults?.data, accountActivePosLocations]);

    const refetchActiveAccountPosEmployeeProfiles = () => {
        Promise.all([usersQueryResults?.refetch(), locationsQueryResults?.refetch()]);
    };

    const areIncludedDatasetsLoading = queryResults.some(({ isFetched }, i) => {
        const { key } = posQuerySet[i];

        const isIncludedDataSet =
            finalIncludedDatasets === 'all' ||
            (Array.isArray(finalIncludedDatasets)
                ? finalIncludedDatasets?.includes(key)
                : finalIncludedDatasets === key);

        return isIncludedDataSet ? !isFetched : false;
    });

    const allIncludedRefetchFns = queryResults
        .filter(({ isFetched }) => isFetched)
        .map(({ refetch }) => refetch || noop);
    const refetchAllIncludedDatasets = () => Promise.all(allIncludedRefetchFns);

    const value: IAccountPosDataContext = useMemo(() => {
        return {
            accountPosDataIsReady: !areIncludedDatasetsLoading,
            accountPosLocationsDataIsReady: locationsQueryResults?.isFetched,
            accountPosEmployeeProfilesDataIsReady: usersQueryResults?.isFetched,
            accountPosBrandsDataIsReady: brandsQueryResults?.isFetched,
            accountPosCategoriesDataIsReady: categoriesQueryResults?.isFetched,
            accountPosProductsDataIsReady: productsQueryResults?.isFetched,

            refetchAccountPosData: refetchAllIncludedDatasets || noop,
            refetchAccountPosLocations: locationsQueryResults?.refetch || noop, // both active/all locations
            refetchAllAccountPosEmployeeProfiles: usersQueryResults?.refetch || noop,
            refetchAccountPosEmployeeProfiles: refetchActiveAccountPosEmployeeProfiles,
            refetchAccountPosBrands: brandsQueryResults?.refetch || noop,
            refetchAccountPosCategories: categoriesQueryResults?.refetch || noop,
            refetchAccountPosProducts: productsQueryResults?.refetch || noop,

            accountPosLocations: accountActivePosLocations,
            accountAllPosLocations: locationsQueryResults?.data || [],
            accountPosEmployeeProfiles: accountActivePosEmployeeProfiles,
            accountAllPosEmployeeProfiles: usersQueryResults?.data || [],
            accountPosBrands: brandsQueryResults?.data || [],
            accountPosCategories: categoriesQueryResults?.data || [],
            accountPosProducts: productsQueryResults?.data || [],
        };
    }, queryResults);

    return value;
};

/**
 * Use the current account in the AccountProvider/Context and
 * fetch the `spUsers` associated to the account
 */
export const useAccountSparkplugUsersQuery = () => {
    const { account } = useSparkplugAccount();

    return useAccountSparkplugUsersQueryByGroupId(account?._id);
};

/**
 * The `useSparkplugAccountUsers` fn hooks into the AccountProvider/Context
 * and fetches the accountUsers for the current account in the context.
 * To fetch the users for a particular account, implement the
 * `useAccountUsersQuery` hook directly
 */
export const useSparkplugAccountUsers = (showDisabledLocations?: boolean) => {
    const { account } = useSparkplugAccount();

    const _showDisabledLocations = showDisabledLocations ?? false;

    return useAccountUsersQuery(account?._id, _showDisabledLocations);
};

export const useSparkplugBrandRetailer = () => {
    const context = useContext(BrandRetailerContext);
    const { account } = useSparkplugAccount();

    // Sort the retailers how they will appear on the sidebar,
    // so we pull the first one out of the array to display by
    // default. First by market, then by name of the retailer
    const sortedRetailers = useMemo(() => {
        if (account?.retailers != null) {
            return account.retailers.sort((a, b) => {
                const aMarket = a.markets[0];
                const bMarket = b.markets[0];

                if (aMarket != null && bMarket != null) {
                    return aMarket.localeCompare(bMarket);
                }

                return a.name.localeCompare(b.name);
            });
        }

        return [];
    }, [account?.retailers]);

    // Try to grab the brandRetailerId from the URL, if that doesn't work
    // it means that the first retailer for the given account is being displayed
    const params: { brandRetailerId: string } = useParams();
    const brandRetailerId = params.brandRetailerId || sortedRetailers[0]?._id;

    if (!context) {
        // eslint-disable-next-line no-console
        console.log(`useSparkplugBrandRetailer must be used within BrandRetailerProvider`);
    }

    const { changeBrandRetailerById } = context;

    useEffect(() => {
        if (account != null && account?.type === 'brand') {
            if (brandRetailerId != null) {
                changeBrandRetailerById(brandRetailerId);
            } else if (account?.retailers != null && account?.retailers.length > 0) {
                changeBrandRetailerById(account?.retailers[0]._id);
            } else {
                changeBrandRetailerById(undefined);
            }
        } else {
            changeBrandRetailerById?.(undefined);
        }
    }, [account, brandRetailerId]);

    return {
        ...context,
        brandRetailerId,
    };
};

export const useBrandRetailerByGroupId = (
    account: IAccount | undefined,
    brandRetailerGroupId: string | undefined,
) => {
    const isEnabled = Boolean(brandRetailerGroupId && account?._id && account?.type === 'brand');

    const { isLoading: isLoadingRetailerAccount, data: retailerAccount } = useAdvancedQuery(
        getVendorRetailerQueryKey(account?._id ?? '', brandRetailerGroupId ?? ''),
        () => {
            return getVendorRetailer({
                groupId: account?._id!,
                retailerAccountId: brandRetailerGroupId!,
            });
        },
        {
            enabled: isEnabled,
        },
    );

    const { isLoadingVendorRetailerPosData, vendorRetailerPosData } = useVendorRetailerPosData(
        account?._id ?? '',
        brandRetailerGroupId ?? '',
        isEnabled,
    );

    const productHierarchy = useMemo(() => {
        if (vendorRetailerPosData) {
            // Because the products are mapped to the Vendor's brands, we need to concat them into an array;
            const allMappedProducts = Object.values(
                vendorRetailerPosData.productsVendorBrandMap,
            ).flatMap(({ products }) => products);

            return buildProductHierarchy(
                vendorRetailerPosData.brands,
                vendorRetailerPosData.categories,
                allMappedProducts,
            );
        }

        return [];
    }, [vendorRetailerPosData]);

    const value = useMemo(() => {
        const brandRetailer: IBrandRetailer = retailerAccount
            ? {
                  ...retailerAccount,
                  locations:
                      retailerAccount?.activeLocations?.map((location) => ({
                          ...location,
                          value: location._id,
                          label: location.displayName ?? location.name,
                      })) ?? [],
                  productHierarchy,
                  products: getUniqueLeavesFromList(productHierarchy),
              }
            : ({} as IBrandRetailer);

        return {
            brandRetailerIsReady: !(isLoadingRetailerAccount || isLoadingVendorRetailerPosData),
            brandRetailer,
            brandRetailerGroupId,
        };
    }, [
        isLoadingRetailerAccount,
        isLoadingVendorRetailerPosData,
        brandRetailerGroupId,
        retailerAccount,
        productHierarchy,
    ]);

    return value;
};

export const withSparkplugBrandRetailer = (Component: any) => {
    return (props: any) => {
        const app = useSparkplugBrandRetailer();
        return <Component {...props} {...app} />;
    };
};

export const useAccountUsersFilters = (
    defaultFilters: AccountUserFilters = {
        status: 'all',
        role: 'all',
        location: 'all',
        market: 'all',
    },
) => {
    const [filters, setFilters] = useState<AccountUserFilters>(defaultFilters);

    const updateFilters = (newFilters: AccountUserFilters) => {
        setFilters((prevFilters) => {
            return {
                ...prevFilters,
                ...newFilters,
            };
        });
    };

    const applyFilters = useCallback(
        (users: any) => {
            let newFilteredUsers: any[] = users || [];

            Object.keys(filters).forEach((k) => {
                const v = filters[k as keyof AccountUserFilters];
                newFilteredUsers = newFilteredUsers.filter(
                    UserFilterFns[k as keyof AccountUserFilters](v!),
                );
            });

            return newFilteredUsers;
        },
        [filters],
    );

    return {
        userFilters: filters,
        updateUserFilters: updateFilters,
        applyUserFilters: applyFilters,
    };
};

interface IProductFilters {
    brandIds: string[];
    categoryIds: string[];
}

export const useAccountProductsFilters = (
    defaultFilters: IProductFilters = {
        brandIds: [],
        categoryIds: [],
    },
) => {
    const [filters, setFilters] = useState<IProductFilters>(defaultFilters);

    const updateFilters = (newFilters: { brandIds?: string[]; categoryIds?: string[] }) => {
        setFilters((prevFilters) => {
            return {
                ...prevFilters,
                ...newFilters,
            };
        });
    };

    const applyFilters = useCallback(
        (products: any) => {
            let newFilteredProducts = products || [];

            Object.keys(filters).forEach((k) => {
                const v = filters[k as keyof IProductFilters];
                newFilteredProducts = newFilteredProducts.filter(
                    ProductFilterFns[k as keyof IProductFilters](v as any),
                );
            });

            return newFilteredProducts;
        },
        [filters],
    );

    return {
        productFilters: filters,
        updateProductFilters: updateFilters,
        applyProductFilters: applyFilters,
    };
};

export function useAccountBrandLinks(account: IAccount | undefined) {
    const _getBrandLinks = async () => {
        return account != null ? getBrandLinks(account._id) : [];
    };

    const {
        isLoading,
        data = [],
        refetch,
    } = useAdvancedQuery(['account', account?._id, 'brandLinks'], () => _getBrandLinks(), {
        enabled: account != null,
    });

    return {
        brandLinksAreReady: !isLoading,
        brandLinks: data,
        refetchBrandLinks: refetch,
    };
}

export function useSuggestedBrandLinkGroups(accountId?: string, isEnabled: boolean = true) {
    const {
        isLoading: isLoadingSuggestedBrandLinks,
        data: suggestedBrandLinks = [],
        refetch: refetchSuggestedBrandLinks,
    } = useAdvancedQuery(
        ['account', accountId, 'suggestedBrandLinks'],
        () => getProspectiveBrandLinks(accountId || ''),
        { enabled: isEnabled && !!accountId },
    );

    return {
        isLoadingSuggestedBrandLinks,
        suggestedBrandLinks,
        refetchSuggestedBrandLinks,
    };
}

export function useSuggestedProductsEstimate(brandName: string, retailerGroupId: string) {
    const {
        accountPosDataIsReady: retailerPosBrandsAreReady,
        accountPosBrands: retailerPosBrands,
    } = useAccountPosDataQuery(retailerGroupId, {
        isEnabled: brandName.length > 0 && retailerGroupId.length > 0,
        includedDatasets: 'brands',
    });

    const matchingPosBrands = useMemo(() => {
        const brandNameLower = brandName.toLowerCase();

        return retailerPosBrands.filter(({ searchName }) => {
            return searchName.includes(brandNameLower);
        });
    }, [retailerPosBrands, brandName]);

    const {
        accountPosDataIsReady: retailerPosProductsAreReady,
        accountPosProducts: retailerPosProducts,
    } = useAccountPosDataQuery(retailerGroupId, {
        isEnabled: matchingPosBrands.length > 0,
        includedDatasets: 'products',
    });

    const matchingPosProducts = useMemo(() => {
        const posBrandIds = matchingPosBrands.map(({ value }) => value);

        return retailerPosProducts.filter((product) => {
            return (product?.brands || []).some((productBrand) => {
                return posBrandIds.includes(productBrand._id);
            });
        });
    }, [matchingPosBrands, retailerPosProducts]);

    const hasMatchingProducts = useMemo(() => {
        return matchingPosProducts != null && matchingPosProducts.length > 0;
    }, [matchingPosProducts]);

    const suggestedProductsAreReady = useMemo(() => {
        return retailerPosBrandsAreReady && retailerPosProductsAreReady;
    }, [retailerPosBrandsAreReady, retailerPosProductsAreReady]);

    return {
        suggestedProductsAreReady,
        hasMatchingProducts,
        matchingPosBrands,
        matchingPosProducts,
    };
}

export function useTotalProductTagCount({
    accountId = '',
    isEnabled = false,
}: {
    accountId: string;
    isEnabled: boolean;
}) {
    const { isLoading, data } = useAdvancedQuery(
        ['account', accountId, 'product-tag-count'],
        () => getTotalProductTagCount({ accountId }),
        {
            enabled: isEnabled && !!accountId,
        },
    );

    return {
        totalProductTagCountIsReady: !isLoading,
        totalProductTagCount: data,
    };
}
