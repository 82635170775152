import BillingGraphic from './Billing.svg?react';
import CombinedLeaderboardGraphic from './CombinedLeaderboard.svg?react';
import CreditCardGraphic from './CreditCardGraphic.svg?react';
import ExpiredLinkGraphic from './ExpiredLink.svg?react';
import HelpGraphic from './HelpGraphic.svg?react';
import MultiLeaderboardGraphic from './MultiLeaderboard.svg?react';

import LottieGraphic from './LottieGraphic';
import AnimatedCheckmarkJSON from './lottie/animated-checkmark.json';
import AnimatedGiftJSON from './lottie/animated-gift.json';
import AnimatedTrainingCourseBadgeJSON from './lottie/animated-training-course-badge.json';
import AnimatedLoadingFilesJSON from './lottie/loading-files.json';

export {
    CombinedLeaderboardGraphic,
    MultiLeaderboardGraphic,
    ExpiredLinkGraphic,
    BillingGraphic,
    HelpGraphic,
    CreditCardGraphic,
};
export { SparkCreditsCard } from './SparkCreditsCard';
export { ConfettiGraphic } from './Confetti/Confetti';

export function AnimatedCheckmarkGraphic({ onComplete }: { onComplete?: () => void }) {
    return (
        <LottieGraphic
            startPlay
            className="animated-checkmark-graphic"
            json={AnimatedCheckmarkJSON}
            speed={0.75}
            onComplete={onComplete}
        />
    );
}

export function AnimatedGiftGraphic({ startPlay }: { startPlay: boolean }) {
    const finalFrame = 87;
    return (
        <LottieGraphic
            className="animated-gift-graphic"
            json={AnimatedGiftJSON}
            // We want to start the animation at the final frame for visual reasons, and on play we'll start from the beginning
            initialSegment={[finalFrame, finalFrame]}
            // On play show the full the animation
            onPlayStart={(lottieWebAnimation) => lottieWebAnimation?.resetSegments(true)}
            startPlay={startPlay}
        />
    );
}

export function AnimatedTrainingCourseBadgeGraphic({ autoplay = true }: { autoplay?: boolean }) {
    return (
        <LottieGraphic
            startPlay={autoplay}
            className="training-course-badge-graphic"
            json={AnimatedTrainingCourseBadgeJSON}
            speed={0.75}
        />
    );
}

export function AnimatedLoadingFilesGraphic({
    autoplay = true,
    loop,
}: {
    autoplay?: boolean;
    loop?: boolean;
}) {
    return (
        <LottieGraphic
            startPlay={autoplay}
            loop={loop}
            className="loading-files-graphic"
            json={AnimatedLoadingFilesJSON}
        />
    );
}

export * from './EmptyStates';
