import { FC } from 'react';
import { Link } from 'react-router-dom';

import { DATE_DISPLAY_FORMAT } from '@constants/AppConstants';
import moment from 'moment';

import { Credit } from '@sparkplug/lib';

import { useGroupSparkCreditEntries } from '@features/spark-credits/queries';
import { isCredit } from '@features/spark-credits/utils';

import { NoSparksGraphic } from '@components/graphics';
import { ArrowBackIos as ArrowBackIosIcon } from '@components/icons';
import Spinner from '@components/layout/Spinner';

import { currencyFormatterFactory } from '@helpers/chartFormatters';

import './SparkCreditHistory.scss';

interface SparkCreditHistoryProps {
    basePath: string;
    accountId?: string;
}

const SparkCreditHistoryItem: FC<{ data: Credit }> = ({ data }) => {
    const formatter = currencyFormatterFactory(2);
    const { amount, creditType, createdAt } = data;

    return (
        <div className="credit-item">
            <div className="top-wrapper">
                <div className="amount">{formatter(amount)}</div>
                <div className="type">{creditType} credit</div>
            </div>
            <div className="date">
                Earned on {createdAt ? moment(createdAt).format(DATE_DISPLAY_FORMAT) : 'unknown'}
            </div>
        </div>
    );
};

const NoCreditHistory: FC = () => {
    return (
        <div className="no-credit-history">
            <NoSparksGraphic />
            <div>No Credits Found</div>
        </div>
    );
};

const SparkCreditHistory: FC<SparkCreditHistoryProps> = ({ basePath, accountId }) => {
    if (!accountId) {
        return <NoCreditHistory />;
    }

    const {
        groupSparkCreditEntries: groupCreditSparkCredit = [],
        groupSparkCreditEntriesAreReady: groupSparkCreditIsReady,
    } = useGroupSparkCreditEntries({
        groupId: accountId,
        creditsOnly: true,
    });

    return (
        <div className="history-wrapper">
            <div className="credits-card-header">
                <h1>
                    <Link to={basePath}>
                        <ArrowBackIosIcon fontSize="small" />
                    </Link>
                    Spark Credit History
                </h1>
            </div>

            {!groupSparkCreditIsReady && (
                <div className="spinner-wrapper">
                    <Spinner />
                </div>
            )}
            {groupSparkCreditIsReady && (
                <div className="history-item-wrapper">
                    {groupCreditSparkCredit.length ? (
                        groupCreditSparkCredit
                            .filter((entry): entry is Credit => isCredit(entry))
                            .filter(({ creditType }) => creditType !== 'referral')
                            .map((data, i: number) => (
                                <SparkCreditHistoryItem data={data} key={i} />
                            ))
                    ) : (
                        <NoCreditHistory />
                    )}
                </div>
            )}
        </div>
    );
};
export default SparkCreditHistory;
