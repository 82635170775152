import { FC } from 'react';

import { DetailedSparkType, Spark } from '@sparkplug/lib';

import UserCharts from '@components/charts/UserCharts';
import { NonParticipantLeader } from '@components/chips/NonParticipantChip';
import Paper from '@components/layout/Paper';

import { useApp } from '@hooks/AppHooks';

import { ChartLeader } from '@app/types/ChartDataTypes';
import { IPosLocation } from '@app/types/PosTypes';

import './GoalUserChart.scss';

interface GoalUserChartProps {
    leaders: ChartLeader[];
    spark: Spark;
    detailedSparkType?: DetailedSparkType;
    locations: IPosLocation[];
    nonParticipantLeaders: NonParticipantLeader[];
}
export const GoalUserChart: FC<GoalUserChartProps> = ({
    leaders,
    spark,
    detailedSparkType,
    locations,
    nonParticipantLeaders,
}) => {
    const { appIsMobile } = useApp();
    const isTeamGoal = detailedSparkType === 'goalTeam';
    const isCollectiveGoal = isTeamGoal || detailedSparkType === 'goalManager';
    const excludedPeople = isCollectiveGoal ? [] : nonParticipantLeaders;

    const showNonParticipantChip = spark.trainingEnabled && spark.courseData?.required;

    return (
        <>
            <Paper>
                <UserCharts
                    title="Standings"
                    hideLocations={isTeamGoal}
                    people={leaders}
                    nameColumnTitle={isCollectiveGoal ? 'Location' : undefined}
                    metric={spark.metric}
                    goals={spark.goals}
                    fulfillmentTypes={spark?.fulfillmentTypes}
                    showRanks={!isCollectiveGoal}
                    locations={locations}
                    excludedPeople={excludedPeople}
                    showNonParticipantChip={showNonParticipantChip}
                    spark={spark}
                    hideTransactions={appIsMobile}
                />
            </Paper>
        </>
    );
};
