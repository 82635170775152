import { FC, ReactNode } from 'react';

import { appendClasses } from '@helpers/ui';

import './Description.scss';

interface DescriptionProps {
    className?: string;
    children?: ReactNode;
}

const Description: FC<DescriptionProps> = ({ className = '', ...props }) => {
    const classNamesAppended = appendClasses([className, 'content-description content']);

    return <div className={classNamesAppended} {...props} />;
};

export default Description;
