import { FC, useEffect, useState } from 'react';

import { Accordion, AccordionActions, AccordionDetails, AccordionSummary } from '@mui/material';
import { isEqual, sortBy } from 'lodash';

import { PosBrand, PublicBrandLinkV2 } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import Chip from '@components/chips/Chip';
import { CheckCircle, CustomArrowRightAltIcon, CustomEditIcon, SaveAlt } from '@components/icons';
import ListSelector from '@components/inputs/ListSelector';
import Skeleton from '@components/layout/Skeleton';

import { appendClasses } from '@helpers/ui';

import { IOption } from '@app/types/UITypes';

export interface BrandMappingRowProps {
    brandLink: PublicBrandLinkV2;
    posBrandOptionsAreReady: boolean;
    posBrandOptions: IOption<PosBrand>[];
    onSave: (brandLink: PublicBrandLinkV2, posBrands: PosBrand[]) => void;
    accountLinkDisabled?: boolean;
    defaultExpanded?: boolean;
}
const BrandMappingRow: FC<BrandMappingRowProps> = ({
    brandLink,
    posBrandOptionsAreReady,
    posBrandOptions,
    onSave,
    accountLinkDisabled,
    defaultExpanded = false,
}) => {
    const initialPosBrands: IOption<PosBrand>[] = brandLink.posBrands.map((brand) => ({
        ...brand,
        value: brand._id,
        label: brand.name,
    }));
    const selectedBrandIds = initialPosBrands.map((brand) => brand._id);
    const [posBrands, setPosBrands] = useState(initialPosBrands);
    const [expanded, setExpanded] = useState(defaultExpanded);

    useEffect(() => {
        if (accountLinkDisabled) {
            setExpanded(false);
        }
    }, [accountLinkDisabled]);

    const onRemoveBrand = (id: string) => {
        setPosBrands((prevValues) => prevValues.filter((prevValue) => prevValue._id !== id));
    };

    const posBrandsMapped = posBrands?.map((posBrand) => (
        <Chip
            key={`${brandLink._id}-${posBrand._id}-chip`}
            color={accountLinkDisabled ? 'light-gray' : 'green'}
            label={posBrand.name}
            onDelete={expanded ? () => onRemoveBrand(posBrand._id) : undefined}
        />
    ));

    const saveBrandLink = () => {
        onSave(brandLink, posBrands);
        setExpanded(false);
    };

    const onUpdateBrands = (selected: IOption<PosBrand>[]) => {
        setPosBrands(selected);
    };

    const noChanges = isEqual(
        sortBy(initialPosBrands.map((b: { _id: string }) => b._id)),
        sortBy(posBrands.map((b: { _id: string }) => b._id)),
    );

    const hasPosBrandsMapped = posBrands && posBrands.length;
    const classNamesAppended = appendClasses([
        'brand-accordion',
        expanded ? 'expanded' : undefined,
    ]);

    return (
        <Accordion
            className="brand-link-accordion"
            key={brandLink._id}
            id={`brand-row-${brandLink.brandId}`}
            expanded={expanded}
            onClick={expanded ? undefined : () => setExpanded(!expanded)}
        >
            <AccordionSummary
                expandIcon={
                    <CustomEditIcon
                        className="brand-link-accordion_edit-icon"
                        data-testid="edit-brand-links"
                    />
                }
            >
                <div className={classNamesAppended}>
                    <div className="brand-details">
                        <div className="brand-details_img">
                            <img src={brandLink.photo} alt={brandLink.name} />
                        </div>
                        <Chip
                            icon={hasPosBrandsMapped ? <CheckCircle /> : <></>}
                            key={brandLink.name}
                            color={accountLinkDisabled || !hasPosBrandsMapped ? 'neutral' : 'green'}
                            label={brandLink.name}
                            variant={hasPosBrandsMapped ? 'flat' : 'outlined'}
                        />
                    </div>
                    <div className="separator">
                        <CustomArrowRightAltIcon />
                    </div>
                    <div className="mapped-pos-brands">
                        {hasPosBrandsMapped ? (
                            posBrandsMapped
                        ) : (
                            <span className="no-brands-mapped">None mapped</span>
                        )}
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails className="brand-details-accordion">
                <div className="brand-link-search-prompt" data-testid="brand-link-search-prompt">
                    Search & select brands from your POS to map to {brandLink.name}:{' '}
                </div>
                {posBrandOptionsAreReady ? (
                    <ListSelector
                        // using selection keys here so that the parents can be selected
                        useSelectionKeys
                        // When using selection keys, the selected prop should be an array of keys instead of list objects.
                        selected={selectedBrandIds}
                        headCellClass="brand-link-table"
                        showSearchIcon
                        hideSelectAll
                        list={posBrandOptions}
                        onSelectionChanged={onUpdateBrands}
                    />
                ) : (
                    <Skeleton height={400} />
                )}
            </AccordionDetails>
            <AccordionActions>
                <Button
                    data-testid="close-brand-link-row"
                    color="neutral"
                    variant="flat"
                    onClick={() => {
                        setExpanded(false);
                        setPosBrands(initialPosBrands);
                    }}
                    disableHover
                >
                    Cancel
                </Button>
                <Button
                    data-testid="save-link-button"
                    disabled={noChanges}
                    variant="smooth"
                    color="blue"
                    onClick={() => saveBrandLink()}
                    startIcon={<SaveAlt />}
                >
                    Save
                </Button>
            </AccordionActions>
        </Accordion>
    );
};
export default BrandMappingRow;
