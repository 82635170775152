import { FC, useMemo, useState } from 'react';

import moment from 'moment';

import { RetailerBalance } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import { EmptyBookGraphic } from '@components/graphics/EmptyStates';
import {
    KeyboardArrowDown as ArrowDownIcon,
    KeyboardArrowUp as ArrowUpIcon,
} from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import Paper from '@components/layout/Paper';
import Skeleton from '@components/layout/Skeleton';
import CreateSparkButton from '@components/sparks/CreateSparkButton';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { currencyFormatterFactory } from '@helpers/chartFormatters';

import './ReferralCreditsPanel.scss';

const ReferralBalanceItem: FC<RetailerBalance> = ({
    balance,
    groupId: retailerGroupId,
    groupName,
    lastEarnedDate,
    logo,
}) => {
    const { history } = useApp();
    const { account } = useSparkplugAccount();

    const routeToSparkCreate = () =>
        history.push(`/${account?._id}/sparks/create?groupId=${retailerGroupId}`);

    return (
        <div className="referral-credits_list-item">
            <div className="img-container" style={{ visibility: logo ? 'visible' : 'hidden' }}>
                <img src={logo} alt={`${groupName} logo`} />
            </div>

            <section>
                <div className="name">{groupName}</div>
                <div className={`referral-balance ${balance <= 0 ? 'grayed' : ''}`}>
                    <span>{currencyFormatterFactory(2)(balance)}</span>
                    <span>credit balance</span>
                </div>
                <div className="last-earned-date">
                    Earned on {moment(lastEarnedDate).format('ll')}
                </div>
            </section>

            {balance > 0 && !!account?._id && (
                <CreateSparkButton onClick={routeToSparkCreate} showSplitButton={false} />
            )}
        </div>
    );
};

const ReferralCreditsPanelContent: FC<{
    retailerBalances: RetailerBalance[];
    isReady: boolean;
}> = ({ retailerBalances = [], isReady }) => {
    const { account } = useSparkplugAccount();

    const [showPastCredits, setShowPastCredits] = useState<boolean>(false);

    const { activeReferralCredits, pastReferralCredits } = useMemo(
        () => ({
            activeReferralCredits: retailerBalances.filter(({ balance }) => balance > 0),
            pastReferralCredits: retailerBalances.filter(({ balance }) => balance <= 0),
        }),
        [retailerBalances],
    );

    if (!isReady || !account) {
        return <Skeleton height={330} width="100%" />;
    }

    if (retailerBalances?.length === 0) {
        return (
            <div className="referral-credits_empty-state_container">
                <EmptyStateDisplay
                    className="spark-credits-table_empty-state"
                    graphic={<EmptyBookGraphic />}
                    customContent={
                        <div className="referral-credits_empty-state_label">
                            <span>
                                There are <strong>no referral credits</strong> available.
                            </span>
                            <span>
                                Use the form above to refer a retailer
                                {!account?.referralUrl ? (
                                    '!'
                                ) : (
                                    <span>
                                        {' or '}
                                        <a
                                            href={account?.referralUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            share your referral landing page
                                        </a>
                                        {' to earn referral credits!'}
                                    </span>
                                )}
                            </span>
                        </div>
                    }
                />
            </div>
        );
    }

    return (
        <>
            {activeReferralCredits.map((activeReferralCredit) => (
                <ReferralBalanceItem {...activeReferralCredit} key={activeReferralCredit.groupId} />
            ))}

            {!!pastReferralCredits?.length && (
                <>
                    <Button
                        className="show-past-credits_button"
                        variant="smooth"
                        color="neutral"
                        endIcon={showPastCredits ? <ArrowUpIcon /> : <ArrowDownIcon />}
                        onClick={() => setShowPastCredits((prev) => !prev)}
                    >
                        <>{showPastCredits ? 'Hide' : 'View'} Past Credits</>
                    </Button>

                    {showPastCredits &&
                        pastReferralCredits.map((pastReferralCredit) => (
                            <ReferralBalanceItem
                                {...pastReferralCredit}
                                key={pastReferralCredit.groupId}
                            />
                        ))}
                </>
            )}
        </>
    );
};

export interface ReferralCreditsPanelProps {
    isReady: boolean;
    retailerReferralBalances: RetailerBalance[];
}
export const ReferralCreditsPanel: FC<{
    isReady: boolean;
    retailerReferralBalances: RetailerBalance[];
}> = ({ isReady, retailerReferralBalances = [] }) => {
    return (
        <Paper className="referral-credit-panel">
            <h1>Referral Credits</h1>
            <div className="subtext">
                Use the referral credits you&apos;ve earned towards prizes on new sparks for the{' '}
                <strong>specified retailer</strong>.
            </div>

            {!!retailerReferralBalances?.length && (
                <CalloutMessage
                    variant="outlined"
                    color="blue"
                    message="Note that Spark Credits are applied at the conclusion of a Spark."
                />
            )}

            <ReferralCreditsPanelContent
                isReady={isReady}
                retailerBalances={retailerReferralBalances}
            />
        </Paper>
    );
};
