import { FC } from 'react';

import { SparkCreditsCard } from '@components/graphics';
import { Link } from '@components/router';
import CreateSparkButton from '@components/sparks/CreateSparkButton';

import { currencyFormatterFactory } from '@helpers/chartFormatters';

import './AvailableCredits.scss';

interface AvailableCreditsProps {
    basePath: string;
    balance?: number;
}

const AvailableCredits: FC<AvailableCreditsProps> = ({ basePath, balance = 0 }) => {
    const formatter = currencyFormatterFactory(2);

    return (
        <div>
            <div className="credits-card-header">
                <h1>Spark Credits</h1>
                <Link to={`${basePath}/history`}>View History</Link>
            </div>
            <div className="subtext">
                Use Spark Credits earned from promotions towards prizes on{' '}
                <strong>any new Sparks!</strong>
            </div>
            <div className="card-wrapper">
                <SparkCreditsCard balance={formatter(balance)} />
            </div>
            <div className="card-action">
                <CreateSparkButton showSplitButton={false} />
            </div>
        </div>
    );
};
export default AvailableCredits;
