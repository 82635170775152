import { FC } from 'react';

import Button from '@components/buttons/Button';
import { SparkPendingGraphic as EmailSentGraphic } from '@components/graphics';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import './ExportSentNotice.scss';

export const EXPORT_SENT_NOTICE_TEXT = 'Your data is on the way!' as const;

export const ExportSentNotice: FC = () => {
    const { account } = useSparkplugAccount();
    const { history } = useApp();
    const goToExportsHub = () => {
        if (account) {
            history.push(`/${account._id}/settings/export`);
        }
    };

    return (
        <div className="export-sent-notice">
            <EmailSentGraphic />
            <section>
                <h3 className="export-sent-text">{EXPORT_SENT_NOTICE_TEXT}</h3>
                <p className="export-sent-subtext">
                    An email will be sent to your account with a CSV attachment once the export is
                    ready.
                </p>
            </section>
            <Button
                className="export-sent-button"
                color="blue"
                variant="filled"
                onClick={goToExportsHub}
            >
                Back to Exports
            </Button>
        </div>
    );
};
