import { RewardPayoutParameters } from '../reward';
import { PagedApiResponseBody } from '../http';
import { z } from 'zod';

export interface PublicGiftCard {
  _id: string;
  groupId: string;
  userId: string;
  amount: number;
  createdByUserId: string;
  createdAt: string;
  note?: string;

  orderId: string;
  orderParameters: RewardPayoutParameters;
  deliveryLink: string;
  deliveredAt?: string;

  deletedAt?: string;
  updatedAt?: string;
}

export type ListGiftCardsResponseBody = PagedApiResponseBody<PublicGiftCard>;

export const createGiftCardSchema = z.object({
  createdByUserId: z.string().min(1),
  userId: z.string().min(1),
  amount: z.number().positive('Received amount of zero'),
  groupId: z.string().min(1),
  campaignId: z.string().min(1),
  fundingSourceId: z.string().min(1),

  note: z.string().optional(),
  email: z.string().optional(),
  phoneNumber: z.string().optional(),
});
createGiftCardSchema.refine((data) => !!data.email || !!data.phoneNumber, {
  message: 'Must include contact method',
});

export type CreateGiftCardPayload = z.infer<typeof createGiftCardSchema>;

export interface CreateGiftCardResponseBody {
  data: PublicGiftCard;
}
