import { FC, PropsWithChildren, ReactElement } from 'react';
import ReactLazyLoad, { forceCheck as originalForceCheck } from 'react-lazyload';

import Skeleton from '@components/layout/Skeleton';

import './LazyLoad.scss';

interface LazyLoadProps {
    className?: string;
    height: number;
    offset: number;
    placeholder?: ReactElement;
}

const Placeholder = ({ height }: { height: number }) => <Skeleton style={{ height }} />;

const LazyLoad: FC<PropsWithChildren<LazyLoadProps>> = (props) => {
    const { height } = props;

    const mergedProps = {
        placeholder: <Placeholder height={height} />,
        ...props,
    };

    return <ReactLazyLoad {...mergedProps} />;
};

export { LazyLoad as default, originalForceCheck as forceCheck };
