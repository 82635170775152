import { FC, ReactElement, useRef, useState } from 'react';

import { Popover } from '@mui/material';

import Button from '@components/buttons/Button';
import Paper from '@components/layout/Paper';

import './QuickViewPopoverWidget.scss';

interface QuickViewPopoverWidgetProps {
    className?: string;
    PopoverButton: FC<{ openPopover: () => void }>;
    title: string | ReactElement;
    content: string | ReactElement;
    onSave?: () => void;
    disableSave?: Boolean;
    onClose?: () => void;
}
export const QuickViewPopoverWidget: FC<QuickViewPopoverWidgetProps> = ({
    className = '',
    PopoverButton,
    title,
    content,
    onSave,
    disableSave = false,
    onClose,
}) => {
    const popoverContainerRef = useRef();

    const [popoverAnchor, setPopoverAnchor] = useState<any>();
    const openPopover = () => setPopoverAnchor(popoverContainerRef?.current);
    const closePopover = () => setPopoverAnchor(undefined);

    const popoverIsOpen = !!popoverAnchor;

    const handleClose = () => {
        closePopover();
        onClose?.();
    };

    const handleSave = () => {
        onSave?.();
        handleClose();
    };

    return (
        <div className={`quick-view-widget ${className}`}>
            <PopoverButton openPopover={openPopover} />

            <article ref={popoverContainerRef as any}>
                <Popover
                    className="quick-view-widget_popover"
                    open={popoverIsOpen}
                    anchorEl={popoverAnchor}
                    onClose={handleClose}
                >
                    <Paper className="quick-view-widget_popover-paper" square>
                        <section className="title">{title}</section>

                        <section className="content">{content}</section>

                        <section className="actions">
                            <Button className="close-button" color="neutral" onClick={handleClose}>
                                {onSave && !disableSave ? 'Cancel' : 'Close'}
                            </Button>

                            {onSave && (
                                <Button
                                    variant="filled"
                                    onClick={handleSave}
                                    disabled={!!disableSave}
                                >
                                    Save
                                </Button>
                            )}
                        </section>
                    </Paper>
                </Popover>
            </article>
        </div>
    );
};
