import { z } from 'zod';

export const quickViewQueryTags = ['cc-accounts-table'] as const;
export type QuickViewTag = typeof quickViewQueryTags[number];

export type QuickViewQueryParams = { [queryFilter: string]: string };

export interface PublicQuickViewQuery {
  _id: string;
  name: string;
  tag: QuickViewTag;
  queryParams: QuickViewQueryParams;
  deletedAt?: string;
  createdAt?: string;
  updatedAt?: string;
}

export type CreateQuickViewQueryResponseBody = PublicQuickViewQuery;

export const createQuickViewSchema = z.object({
  name: z.string().min(1),
  queryParams: z.record(z.string()),
});
export type CreateQuickViewQueryRequestBody = z.infer<typeof createQuickViewSchema>;

export type ListQuickViewQueryResponseBody = { data: PublicQuickViewQuery[] };
export interface ListQuickViewQueryRequestParams {
  tag: QuickViewTag;
}
