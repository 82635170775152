import { z } from 'zod';

export const employeeEarningsExportDisplayColumns = [
  { key: 'employeePosProfileName', header: 'Employee POS Profile Name(s)' },
  { key: 'sparkPlugUsername', header: 'SparkPlug Username' },
  { key: 'employeePhone', header: 'Employee Phone' },
  { key: 'employeeLocations', header: 'Employee Location(s)' },
  { key: 'canonicalPosUid', header: 'Canonical POS UID(s)' },
  { key: 'externalUid', header: 'External UID' },
  { key: 'employeeStatus', header: 'Employee Status' },
  { key: 'retailerPayoutTotal', header: 'Retailer Payout Total ($)' },
  { key: 'vendorPayoutTotal', header: 'Vendor Payout Total ($)' },
];

export const employeeEarningsExportDisplayColumnNames = Object.values(
  employeeEarningsExportDisplayColumns,
)
  .map(({ header }) => header)
  .join(', ');

export const employeeEarningsExportSchema = z.object({
  accountId: z.string().min(1),
  dateStart: z.string().min(1),
  dateEnd: z.string().min(1),
});

export type EmployeeEarningsExportRequestBody = z.infer<typeof employeeEarningsExportSchema>;
