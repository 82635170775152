import { useEffect } from 'react';

import { isEqual, uniq } from 'lodash';

import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import './MultiRetailerParticipantSelector.scss';

export const useMultiRetailerParticipantSelectorState = () => {
    const {
        updateMultiRetailerParticipantGroups,
        detailedSparkType,
        multiRetailerParticipantSelection,
        setMultiRetailerParticipantSelection,
        setUniqueParticipantLocationIdsById,
    } = useSpark();

    useEffect(() => {
        const updatedUniqueParticipantLocationIds: Record<string, true> = {};

        const selectedItems = multiRetailerParticipantSelection.map((key) => {
            const [retailerAccountId, locationId, posEmployeeProfileIdsStr] = key.split('.');
            const posEmployeeProfileIds = posEmployeeProfileIdsStr?.split('|') || [];
            return { retailerAccountId, locationId, posEmployeeProfileIds };
        });

        const selectedMapByAccountId = selectedItems.reduce<
            Record<string, Record<string, string[]>>
        >((acc, item) => {
            const { retailerAccountId, locationId, posEmployeeProfileIds } = item;

            if (locationId) {
                if (!acc[retailerAccountId][locationId]) {
                    acc[retailerAccountId][locationId] = [];
                }
            }

            if (posEmployeeProfileIds?.length) {
                /**
                 * If we are setting unique posEmployeeProfileIds, that also means that this location
                 * has a unique participant selection that we need to pass to the server in the request
                 */
                updatedUniqueParticipantLocationIds[locationId] = true;

                acc[retailerAccountId][locationId] = posEmployeeProfileIds.concat(
                    acc[retailerAccountId][locationId],
                );
            }
            return acc;
        }, Object.fromEntries(selectedItems.map((item) => [item.retailerAccountId, {}])));

        const selectedMap = Object.entries(selectedMapByAccountId).map(
            ([retailerAccountId, participantsByLocationId]) => ({
                retailerAccountId,
                participantGroups:
                    detailedSparkType === 'leaderboardMulti'
                        ? // For multi-leaderboards, we need a participantGroup for each location
                          Object.entries(participantsByLocationId).map(
                              ([locationId, posEmployeeProfileIds = []]) => ({
                                  locationIds: [locationId],
                                  posEmployeeProfileIds,
                              }),
                          )
                        : // For all other Sparks, we just need one participantGroup
                          [
                              {
                                  locationIds: Object.keys(participantsByLocationId),
                                  posEmployeeProfileIds: uniq(
                                      Object.values(participantsByLocationId).flatMap(
                                          (posEmployeeProfileIds) => posEmployeeProfileIds,
                                      ),
                                  ),
                              },
                          ],
            }),
        );

        updateMultiRetailerParticipantGroups(selectedMap);
        setUniqueParticipantLocationIdsById(updatedUniqueParticipantLocationIds);
    }, [multiRetailerParticipantSelection, detailedSparkType]);

    const handleChange = (
        value: {
            /**
             * @description
             *
             * Because of the `async` values, we really only have access to `key` from the ListSelector change event
             * which is a concatenation of `${retailerAccountId}.${locationId}.${posEmployeeProfileIds.join('|')}`
             */
            key: string;
        }[],
    ) => {
        if (value.length) {
            const prevSelection = multiRetailerParticipantSelection;
            const newSelection = [...value.map((item) => item.key)];

            if (!isEqual(newSelection, prevSelection)) {
                setMultiRetailerParticipantSelection(newSelection);
            }
        } else {
            setMultiRetailerParticipantSelection([]);
        }
    };

    return { handleChange };
};
