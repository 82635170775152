import { FC, PropsWithChildren } from 'react';

import { appendClasses } from '@helpers/ui';

import { useIsInToolbar } from '../Toolbar/useIsInToolbar';

import './ToolbarGroup.scss';

export interface ToolbarGroupProps {
    alignStart?: boolean;
    alignEnd?: boolean;
    className?: string;
}

export const ToolbarGroup: FC<PropsWithChildren<ToolbarGroupProps>> = ({
    className,
    alignStart,
    alignEnd,
    children,
}) => {
    useIsInToolbar({ componentName: 'Toolbar.Group' });
    const classNameAppended = appendClasses([
        className,
        'toolbar-group',
        alignStart ? 'toolbar-group-start' : '',
        alignEnd ? 'toolbar-group-end' : '',
    ]);

    return (
        <div className={classNameAppended}>
            <div className="toolbar-group-inner">{children}</div>
        </div>
    );
};

export default ToolbarGroup;
