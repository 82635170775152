import { usePurgeUserMutation, useRemoveAndDeleteAccountMemberMutation } from '@core/users';

import { IAccount } from '@app/types/AccountsTypes';

import ConfirmModal from './ConfirmModal';

const DeleteUserModal = ({
    isVisible,
    account,
    user,
    onClose,
}: {
    isVisible: boolean;
    account: IAccount;
    user?: { _id: string; firstName: string };
    onClose: (val: boolean) => void;
}) => {
    const { removeAndDeleteAccountMember } = useRemoveAndDeleteAccountMemberMutation(
        account ?? ({} as IAccount),
    );
    const { purgeUser } = usePurgeUserMutation();

    if (!user) {
        return <></>;
    }

    const message =
        account?._id != null
            ? `Are you sure you wish to delete ${user?.firstName}?`
            : `Are you sure you wish to purge ${user?.firstName} from SparkPlug?`;

    const onRemove = () => {
        if (account?._id != null) {
            removeAndDeleteAccountMember({ userId: user?._id }, { onSuccess: () => onClose(true) });
        } else {
            purgeUser({ userId: user?._id }, { onSuccess: () => onClose(true) });
        }
    };

    return (
        <ConfirmModal
            title="Delete user?"
            message={message}
            isVisible={isVisible}
            onConfirm={onRemove}
            onClose={() => onClose(false)}
        />
    );
};

export default DeleteUserModal;
