import { FC, useMemo, useState } from 'react';

import { uniq } from 'lodash';

import ListSelector from '@components/inputs/ListSelector';
import RadioGroup from '@components/inputs/RadioGroup';
import Toolbar from '@components/toolbar/Toolbar';

import { useApp } from '@hooks/AppHooks';
import { useAppAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import { IPosLocation } from '@app/types/PosTypes';
import { IOption } from '@app/types/UITypes';
import { IAccountUser } from '@app/types/UsersTypes';

import { useParticipantsContext } from '../../context';
import {
    buildLocationUserTree,
    buildSelectedKeys,
    filterParticipants,
} from '../../utils/SparkParticipantsSelector.util';

import './ParticipantsTreeSelector.scss';

interface ParticipantsTreeSelectorProps {
    showUserTypeFilters?: boolean;
}

const userTypeFilters = [
    {
        value: 'all',
        label: 'All Types',
    },
    {
        value: 'employees',
        label: 'Employees',
    },
    {
        value: 'managers',
        label: 'Managers',
    },
];

const teamTypeOptions = [
    {
        value: 'all',
        label: 'All Employees',
        description: 'All employees will be included from selected locations.',
    },
    {
        value: 'custom',
        label: 'Customize Team',
        description: 'Select individual employees across locations.',
    },
];

export const onSelectionChanged =
    ({
        posLocations,
        activeParticipantOptions,
        setSelected,
        setSelectedLocations,
        setSelectedParticipants,
        participantsSelectable,
    }: {
        posLocations: IPosLocation[];
        activeParticipantOptions: IAccountUser[];
        setSelected: (updatedSelected: any[]) => void;
        setSelectedLocations: (selected: IPosLocation[]) => void;
        setSelectedParticipants: (selected: IOption<IAccountUser>[]) => void;
        participantsSelectable: boolean;
    }) =>
    (updatedSelected: any[]) => {
        const selectedKeys: string[] = [];
        const locationIds: string[] = updatedSelected
            .filter(({ type }) => type === 'location')
            .map(({ value }) => value);
        const flexibleEmployeeIds: string[] = updatedSelected
            .filter(({ type }) => type === 'participant')
            .map(({ flexibleEmployeeId }) => flexibleEmployeeId);

        updatedSelected.forEach(({ key }) => {
            selectedKeys.push(key);
            const splitKey = key.split('.');
            const [locationId] = splitKey;

            if (splitKey.length === 2 && locationId != null && !locationIds.includes(locationId)) {
                selectedKeys.push(locationId);
                locationIds.push(locationId);
            }
        });

        setSelected(uniq(selectedKeys));

        setSelectedLocations(posLocations.filter(({ value }) => locationIds.includes(value)));

        if (!participantsSelectable) {
            // Even if participants are not selecteable, we need to update the selected participants
            // to be able to display the selected participants in the final step.
            let participants: IOption<IAccountUser>[] = [];
            updatedSelected.forEach((location) => {
                participants = participants.concat(location.participants);
            });
            setSelectedParticipants(participants);
        }
        setSelectedParticipants(
            activeParticipantOptions
                .filter(({ flexibleEmployeeId }) =>
                    flexibleEmployeeIds.includes(flexibleEmployeeId),
                )
                .map((accountUser) => {
                    return {
                        ...accountUser,
                        value: accountUser.flexibleEmployeeId,
                        label: accountUser.fullName,
                    };
                }),
        );
    };

const ParticipantsTreeSelector: FC<ParticipantsTreeSelectorProps> = ({ showUserTypeFilters }) => {
    const [
        {
            isBrandApprovalResponse,
            activeParticipantOptions,
            selectedLocations,
            selectedParticipants,
            participantsSelectable,
            accountPosLocations: posLocations,
        },
        { setSelectedLocations, setSelectedParticipants },
    ] = useParticipantsContext();

    const defaultSelectedLocationIds = selectedLocations
        // filter to ensure location is defined to avoid UI crashes
        .filter((location) => !!location)
        .map(({ value }) => value || '');

    const defaultSelectedParticipantIds = selectedParticipants
        // filter to ensure participants are defined to avoid UI crashes
        .filter((participant) => !!participant)
        .map(({ value }) => value || '');

    const disableLocationSelection = isBrandApprovalResponse;
    const hideToolbar = isBrandApprovalResponse;

    const [userTypeFilter, setUserTypeFilter] = useState('all');
    const {
        detailedSparkType,
        updateSpark,
        spark: { originatorGroupId, teamType },
    } = useSpark();
    const { user } = useApp();
    const showAtAllLocations = detailedSparkType !== 'leaderboardMulti';
    const { account: appAccount } = useAppAccount();

    const showManagerChip =
        appAccount?.type === 'retailer' ||
        detailedSparkType === 'goalManager' ||
        user?.role === 'super-admin';

    const displayList = useMemo(() => {
        // this list is for the UI only.
        const showManagersByManagedLocations = detailedSparkType === 'goalManager';
        const emptyChildrenText =
            detailedSparkType === 'goalManager' ? 'No managers at this location' : undefined;

        const options = filterParticipants(activeParticipantOptions, userTypeFilter);
        return buildLocationUserTree(
            posLocations,
            options,
            participantsSelectable,
            disableLocationSelection,
            defaultSelectedLocationIds,
            showAtAllLocations,
            showManagerChip,
            showManagersByManagedLocations,
            emptyChildrenText,
        );
    }, [
        posLocations,
        activeParticipantOptions,
        participantsSelectable,
        disableLocationSelection,
        detailedSparkType,
        showAtAllLocations,
        userTypeFilter,
        showManagerChip,
        teamType,
    ]);

    const [selected, setSelected] = useState<string[]>(() => {
        return buildSelectedKeys(
            defaultSelectedLocationIds,
            defaultSelectedParticipantIds,
            activeParticipantOptions,
            showAtAllLocations,
        );
    });

    const handleTeamTypeChange = (value: 'all' | 'custom') => {
        if (value === 'all') {
            setSelectedParticipants([]);
        }
        updateSpark({
            teamType: value,
        });
    };

    return (
        <span id="ParticipantsTreeSelector">
            {!originatorGroupId && detailedSparkType === 'goalTeam' && (
                <div
                    className="spark-participants-selector__team-type"
                    data-testid="team-type-selector"
                >
                    <RadioGroup
                        color="blue"
                        variant="grid"
                        required
                        value={teamType}
                        label="Team Type"
                        options={teamTypeOptions}
                        onChange={({ target }) => handleTeamTypeChange(target.value)}
                    />
                </div>
            )}
            <ListSelector
                useSelectionKeys
                hideToolbar={hideToolbar}
                list={displayList}
                selected={selected}
                onSelectionChanged={onSelectionChanged({
                    posLocations,
                    activeParticipantOptions,
                    setSelected,
                    setSelectedLocations,
                    setSelectedParticipants,
                    participantsSelectable,
                })}
                toolbar={
                    showUserTypeFilters &&
                    participantsSelectable && (
                        <Toolbar>
                            <Toolbar.Dropdown
                                label={null}
                                value={userTypeFilter}
                                onSelect={(selectedUserType) => {
                                    setUserTypeFilter(selectedUserType);
                                }}
                                options={userTypeFilters}
                                clear={{
                                    active: userTypeFilter !== 'all',
                                    onClear: () => setUserTypeFilter('all'),
                                }}
                            />
                        </Toolbar>
                    )
                }
            />
        </span>
    );
};

export default ParticipantsTreeSelector;
