import { ComponentType, DependencyList, FC, useEffect } from 'react';

import AppSidebar, {
    AppSidebarProps,
    AppSidebarTitle,
    SidebarMenuItem,
} from '@components/layout/AppSidebar';
import AppView from '@components/layout/AppView';

interface IHOCHookWrapper<P> {
    (Component: ComponentType<P>): FC<P>;
}

interface IWithAppBodyClassesHOC<P = {}> {
    (classNames: string[]): IHOCHookWrapper<P>;
}

export const useAppBodyClasses = (allClassNames: string[], deps: DependencyList = []): void => {
    const classNames = allClassNames.filter((className) => className);

    useEffect(() => {
        classNames.forEach((className) => {
            document.body.classList.add(className);
        });

        return () => {
            classNames.forEach((className) => {
                document.body.classList.remove(className);
            });
        };
    }, deps);
};

export const withAppBodyClasses: IWithAppBodyClassesHOC =
    (classNames) => (Component) => (props) => {
        useAppBodyClasses(classNames, []);

        return <Component {...props} />;
    };

interface IWithAppSidebar<P = {}> {
    (sidebarProps: AppSidebarProps): IHOCHookWrapper<P>;
}
export const withAppSidebar: IWithAppSidebar =
    ({ sidebarItems, className, searchable, footer, expandedKeys }) =>
    (RawAppContentComponent) =>
    (props) => {
        const StyledAppContentComponent = withAppBodyClasses([
            'app-content-withSidebar',
            'app-content-fullWidth',
            'app-content-noPadding',
        ])(RawAppContentComponent);

        return (
            <>
                <AppSidebar
                    className={className}
                    sidebarItems={sidebarItems}
                    searchable={searchable}
                    footer={footer}
                    expandedKeys={expandedKeys}
                />

                <div className="app-main-content">
                    <StyledAppContentComponent {...props} />
                </div>
            </>
        );
    };

export function withAppView(items: SidebarMenuItem[], title: AppSidebarTitle) {
    return (Component: ComponentType) => (props: any) => {
        useAppBodyClasses(
            ['app-content-withSidebar', 'app-content-fullWidth', 'app-content-noPadding'],
            [],
        );

        return (
            <AppView sidebar={<AppSidebar title={title} sidebarItems={items} />}>
                <Component {...props} />
            </AppView>
        );
    };
}

export function withAppSubSidebar(items: SidebarMenuItem[]) {
    return (Component: ComponentType) => (props: any) => {
        const WrappedComponent = withAppBodyClasses(['app-content-withSubSidebar'])(Component);

        return (
            <>
                <AppSidebar sidebarItems={items} />
                <div className="app-main-content">
                    <WrappedComponent {...props} />
                </div>
            </>
        );
    };
}
