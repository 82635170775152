import { groupBy, sumBy } from 'lodash';

import {
    BucketsKeyedByLabel,
    FullChartResponseBody,
    HydratedPosProduct,
    ListAccountLinksResponseBody,
    PublicBrandLinkV2,
} from '@sparkplug/lib';

import { IAccount } from '@app/types/AccountsTypes';

type AdditionalProductBreakdown = 'brand' | 'category';
// NOT if the breakdown is 'product', but a more granular breakdown of product
export const isAdditionalProductBreakdown = (
    breakdown: string,
): breakdown is AdditionalProductBreakdown => {
    return ['brand', 'category'].includes(breakdown);
};

type BreakdownKeyRecord = Record<
    AdditionalProductBreakdown,
    {
        // use the `productKey` to find the unique key for grouping the product data
        productKey: 'brandId' | 'categoryId';
        // the productKey is typically a unique id, so we need to find the user-friendly label for it
        findLabelFn: (product: HydratedPosProduct) => string;
    }
>;

// To prevent duplicate code, we're using this record to map the breakdown to the product key and find label function
const BREAKDOWN_KEY_RECORD: BreakdownKeyRecord = {
    brand: {
        productKey: 'brandId',
        findLabelFn: (product) => product.brands[0]?.name ?? 'Unknown Brand',
    },
    category: {
        productKey: 'categoryId',
        findLabelFn: (product) => product.categories[0]?.name ?? 'Unknown Category',
    },
};

/**
 * @description
 *
 * Becuase the chart response only returns the product breakdown data in a bucket
 * format, we need to use the products and the chart data to create the breakdowns
 * for brands and categories and then group them into the BucketsKeyedByLabel format
 */
export const transformProductBucketsIntoBreakdownLabelBucketPairs = ({
    breakdown,
    chartProducts,
    productBuckets = {},
    accountLinks,
    account,
    userIsSuperAdmin = false,
}: {
    breakdown: AdditionalProductBreakdown;
    chartProducts: HydratedPosProduct[];
    productBuckets: FullChartResponseBody['productBuckets'];
    accountLinks?: ListAccountLinksResponseBody;
    account?: IAccount;
    userIsSuperAdmin?: boolean;
}): BucketsKeyedByLabel => {
    // Group the products by brandId or categoryId, depending on the breakdown
    const chartProductsByBreakdownId = groupBy(
        chartProducts,
        BREAKDOWN_KEY_RECORD[breakdown].productKey,
    );

    const breakdownBuckets: {
        label: string;
        buckets: { bucketName: string; bucketValue: number }[];
    }[] = Object.entries(chartProductsByBreakdownId).map(([posBrandId, groupedProducts]) => {
        let label = BREAKDOWN_KEY_RECORD[breakdown].findLabelFn(groupedProducts[0]);
        if (
            breakdown === 'brand' &&
            account?.type === 'brand' &&
            userIsSuperAdmin &&
            accountLinks
        ) {
            let brandLinks: PublicBrandLinkV2[] = [];
            accountLinks.forEach((accountLink) => {
                const matchingLinks = accountLink.brandLinks.filter((link) =>
                    link.posBrandIds.includes(posBrandId),
                );
                brandLinks = brandLinks.concat(matchingLinks);
            });
            if (brandLinks) {
                if (brandLinks.length === 1 && !label.includes(brandLinks[0]?.name)) {
                    label = `${brandLinks[0]?.name} (${label})`;
                } else {
                    const brandLinkNames = brandLinks.map((brandLink) => brandLink.name);
                    label = `${brandLinkNames.join(' & ')} (${label})`;
                }
            }
        }

        // Map the product buckets into an array of bucketName and bucketValue pairs
        const buckets = groupedProducts.flatMap((product) =>
            Object.entries(productBuckets[product.internalKey] ?? {}).map(
                ([bucketName, bucketValue]) => ({
                    bucketName,
                    bucketValue,
                }),
            ),
        );

        return {
            label,
            buckets,
        };
    });

    return Object.fromEntries(
        // Sum the buckets and return the breakdown buckets in the BucketsKeyedByLabel format
        Object.values(breakdownBuckets).map(({ buckets: bucketArray, label }) => {
            const valuesByBucketName = groupBy(bucketArray, 'bucketName');
            const summedBuckets = Object.fromEntries(
                Object.entries(valuesByBucketName).map(([bucketName, buckets]) => [
                    bucketName,
                    sumBy(buckets, 'bucketValue'),
                ]),
            );

            return [label, summedBuckets];
        }),
    );
};
