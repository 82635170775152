import { z } from 'zod';

export const bulkInvalidateReactivateProductsSchema = z.object({
  productIds: z.array(z.string()),
});

export type BulkInvalidateReactivateProductsRequestBody = z.infer<
  typeof bulkInvalidateReactivateProductsSchema
>;

export const bulkInvalidateReactivatePathParams = z.object({
  brandLinkId: z.string(),
});

export type BulkInvalidateReactivateProductsPathParams = z.infer<
  typeof bulkInvalidateReactivatePathParams
>;

export interface ProductLabels {
  productId: string;
  label: string;
}
