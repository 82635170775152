import { useEffect, useState } from 'react';

import axios from 'axios';

import { fetchGroupName } from '@core/accounts';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import lastStepImgSrc from '@components/graphics/EmployeeEnrollmentLastStep.png';
import managerNotifiedImgSrc from '@components/graphics/ManagerNotified.png';
import { Mail } from '@components/icons';
import RadioGroup from '@components/inputs/RadioGroup';
import ClickableArea from '@components/layout/ClickableArea';
import PageLoading from '@components/layout/PageLoading';
import Paper from '@components/layout/Paper';
import { Route, Switch, useQueryParams, useRouteMatch } from '@components/router';

import { useApp } from '@hooks/AppHooks';

import Intercom from '@helpers/Intercom';
import {
    selfEnrollPosEmployeeProfile,
    selfEnrollSearch,
    selfEnrollUnmatched,
} from '@helpers/selfEnroll';
import { formatPhoneNumber } from '@helpers/ui';
import { capitalize } from '@helpers/util';

import { IOption } from '@app/types/UITypes';

import factory from '../../../log/Log';

import './SelfEnrollView.scss';

const log = factory('SelfEnrollView');

interface IEnrollmentData {
    firstName: string;
    lastName: string;
    phoneNumber: string;
}

export const ManagerNotifiedView = () => {
    const message = `Thank you!  Your manager has been notified.`;

    return (
        <div className="employee-registration">
            <Paper>
                <div className="last-step-img">
                    <img src={managerNotifiedImgSrc} alt="Just one more step!" />
                </div>
                <Paper.Title className="text-align-center">We’re On It!</Paper.Title>
                <Form.Description className="text-align-center">
                    <p>{message}</p>
                </Form.Description>
            </Paper>
        </div>
    );
};

export const EmployeeLastStepView = ({ phoneNumber }: { phoneNumber: string }) => {
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    const message = `Reply to the message sent to your mobile device to verify your profile: ${formattedPhoneNumber}`;

    return (
        <div className="employee-registration">
            <Paper>
                <div className="last-step-img">
                    <img src={lastStepImgSrc} alt="Just one more step!" />
                </div>
                <Paper.Title className="text-align-center">Just one more step!</Paper.Title>
                <Form.Description className="text-align-center">
                    <p>{message}</p>
                </Form.Description>
            </Paper>
        </div>
    );
};

export const NoMatchesView = ({
    groupName,
    enrollmentData,
    onNotifyManager,
    onTryAgain,
}: {
    groupName?: string;
    enrollmentData: IEnrollmentData;
    onNotifyManager: () => Promise<void>;
    onTryAgain: () => void;
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const message = `We couldn't locate a profile in the ${groupName} POS under the name and phone you provided. Please contact your manager or try again.`;

    const onSubmit = async () => {
        setIsSubmitting(true);

        onNotifyManager()
            .catch((err) => {
                if (err?.response?.status === 409) {
                    setError('Phone number already exists');
                } else {
                    setError('Something went wrong');
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <div className="employee-registration">
            <Paper>
                <Paper.Title className="text-align-center">No Matches Found</Paper.Title>
                <Form>
                    <Form.Description className="text-align-center">
                        <p>{message}</p>
                    </Form.Description>

                    <div className="">
                        <span>You entered:</span>
                        <div className="user-enrollment-data">
                            <strong className="user-enrollment-data_name">{`${enrollmentData.firstName} ${enrollmentData.lastName}`}</strong>
                            <span className="user-enrollment-data_phone">
                                {formatPhoneNumber(enrollmentData.phoneNumber)}
                            </span>
                        </div>
                    </div>

                    {error && (
                        <Form.Description className="text-align-center">
                            <p className="error-text">{error}</p>
                        </Form.Description>
                    )}

                    <Form.ActionGroup className="text-align-center" direction="vertical">
                        <Form.Button
                            disabled={isSubmitting}
                            startIcon={<Mail />}
                            color="blue"
                            variant="filled"
                            onClick={onSubmit}
                        >
                            Notify My Manager
                        </Form.Button>
                        <Button color="neutral" variant="flat" onClick={onTryAgain}>
                            Try again.
                        </Button>
                    </Form.ActionGroup>
                </Form>
            </Paper>
        </div>
    );
};

export const EmployeeVerificationView = ({
    matches = [],
    groupName,
    onEnrollUser,
    onNoMatchesFound,
}: {
    matches: IOption[];
    groupName?: string;
    onEnrollUser: (posEmployeeProfileId: string) => Promise<void>;
    onNoMatchesFound: () => void;
}) => {
    const [posEmployeeProfileId, setPosEmployeeProfileId] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const message =
        matches.length === 1
            ? `We found an employee match in the ${groupName} POS, please select & verify your profile.`
            : `We found ${matches.length} employee matches in the ${groupName} POS, please select & verify your profile.`;

    const onSubmit = async () => {
        setIsSubmitting(true);

        onEnrollUser(posEmployeeProfileId)
            .catch((err) => {
                if (err?.response?.status === 409) {
                    setError('Phone number already exists');
                } else {
                    setError('Something went wrong');
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <div className="employee-registration">
            <Paper>
                <Paper.Title className="text-align-center">Verify Your Profile</Paper.Title>
                <Form>
                    <Form.Description className="text-align-center">
                        <p>{message}</p>
                    </Form.Description>

                    <RadioGroup
                        direction="vertical"
                        color="blue"
                        variant="outlined"
                        required
                        value={posEmployeeProfileId}
                        options={matches}
                        onChange={(event) => {
                            setPosEmployeeProfileId(event.target.value);
                        }}
                    />

                    {error && (
                        <Form.Description className="text-align-center">
                            <p className="error-text">{error}</p>
                        </Form.Description>
                    )}

                    <Form.ActionGroup className="text-align-center" direction="vertical">
                        <Form.Button
                            disabled={posEmployeeProfileId == null || isSubmitting}
                            color="blue"
                            variant="filled"
                            onClick={onSubmit}
                        >
                            Verify my profile!
                        </Form.Button>
                        <Button color="neutral" variant="flat" onClick={onNoMatchesFound}>
                            None of those are me.
                        </Button>
                    </Form.ActionGroup>
                </Form>
            </Paper>
        </div>
    );
};

export const EmployeeLandingView = ({
    groupName,
    onSearchPosEmployeeProfiles,
}: {
    groupName?: string;
    onSearchPosEmployeeProfiles: (values: IEnrollmentData) => Promise<void>;
}) => {
    const [optIn, setOptIn] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState<IEnrollmentData | undefined>(undefined);

    const onSubmit = (data: IEnrollmentData) => {
        setIsSubmitting(true);

        onSearchPosEmployeeProfiles(data)
            .catch((err: unknown) => {
                if (err && axios.isAxiosError(err) && err.response?.data?.details) {
                    setFormData(data);
                    setError(err.response.data.details);
                } else {
                    setError('Something went wrong');
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const bootAndOpenIntercom = () => {
        if (formData) {
            Intercom.boot({
                fullName: `${formData.firstName} ${formData.lastName}`,
                phone: formData.phoneNumber,
            });
            Intercom.open();
        }
    };

    return (
        <div className="employee-registration">
            {!groupName ? (
                <Paper className="employee-registration-error">
                    <Paper.Title>Uh oh!</Paper.Title>
                    <Form>
                        <Form.Description>
                            <p>
                                Looks like we had some trouble getting your registration started.
                                Please contact support for further assistance.
                            </p>
                        </Form.Description>
                    </Form>
                </Paper>
            ) : (
                <Paper>
                    <Paper.Title className="text-align-center">
                        Join {capitalize(groupName || '')}
                    </Paper.Title>
                    <Form>
                        <div className="employee-registration-form-row">
                            <Form.Description className="text-align-center">
                                <p>
                                    Enter your info to get started with the SparkPlug app and access
                                    realtime updates for your retail location.
                                </p>
                            </Form.Description>
                            <Form.TextField label="First Name" name="firstName" required />
                            <Form.TextField label="Last Name" required name="lastName" />
                            <Form.PhoneField label="Phone" name="phoneNumber" required />
                        </div>
                        <Form.Checkbox
                            className="registration-checkbox employee-registration-form-row"
                            name="enableTextUpdates"
                            description={
                                <>
                                    <div className="p">
                                        I would like to receive text updates and reminders from
                                        SparkPlug at the phone number provided. Messaging frequency
                                        will vary based on your settings.
                                    </div>
                                    <div className="p">
                                        I would like to receive one-time account authentication
                                        messages at the phone number provided.
                                    </div>
                                    <div className="p fine-print">
                                        Messaging and data rates may apply. Reply HELP for help or
                                        STOP to cancel at any time. See more at{' '}
                                        <a
                                            href="https://sparkplug.app/privacy-policy"
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            Mobile Terms of Service
                                        </a>{' '}
                                        and{' '}
                                        <a
                                            href="https://sparkplug.app/privacy-policy"
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            SparkPlug Privacy Statement
                                        </a>
                                        .
                                    </div>
                                </>
                            }
                            value={optIn}
                            onChange={(event) => setOptIn(event.target.checked)}
                        />
                        {error && (
                            <Form.Description className="text-align-center">
                                <p className="error-text">
                                    {error}
                                    {error.includes('user already exists') && (
                                        <ClickableArea
                                            className="clickable-area"
                                            onClick={() => {
                                                bootAndOpenIntercom();
                                            }}
                                        >
                                            Contact support
                                        </ClickableArea>
                                    )}
                                </p>
                            </Form.Description>
                        )}
                        <Form.ActionGroup className="text-align-center" direction="vertical">
                            <Form.Button
                                disabled={!optIn || isSubmitting}
                                showSpinner={isSubmitting}
                                color="blue"
                                variant="smooth"
                                onClick={onSubmit}
                            >
                                Get Started
                            </Form.Button>
                        </Form.ActionGroup>
                    </Form>
                </Paper>
            )}
        </div>
    );
};

export default () => {
    const { path } = useRouteMatch();
    const { history } = useApp();
    const _groupId: string = useQueryParams()?.groupId;
    const [groupId, setGroupId] = useState<string>('');
    const [groupName, setGroupName] = useState<string>();
    const [isLoading, setIsLoading] = useState(true);
    const [enrollmentData, setEnrollmentData] = useState<IEnrollmentData>({
        firstName: '',
        lastName: '',
        phoneNumber: '',
    });
    const [matches, setMatches] = useState<IOption[]>([]);

    useEffect(() => {
        if (!_groupId) {
            console.error('No groupId provided');
            setIsLoading(false);
        } else {
            setGroupId(_groupId);
            fetchGroupName(_groupId)
                .then(setGroupName, (err) => {
                    console.error('Failed to retrieve group name', err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [_groupId]);

    const handlePosEmployeeProfileSearch = async ({
        firstName,
        lastName,
        phoneNumber,
    }: IEnrollmentData) => {
        if (!firstName || !lastName) {
            return;
        }

        const results = await selfEnrollSearch({ groupId, firstName, lastName, phoneNumber });
        const options = results.map((result) => {
            const description =
                result.locationDisplayNames.length > 0
                    ? result.locationDisplayNames.join(', ')
                    : 'N/A';

            return {
                value: result._id,
                label: `${result.firstName} ${result.lastName}`,
                description,
            };
        });
        setMatches(options);
        setEnrollmentData({
            firstName,
            lastName,
            phoneNumber,
        });

        if (options.length > 0) {
            history.push(`${path}/verify`);
        } else {
            history.push(`${path}/no-matches`);
        }
    };

    const handleNoMatchesFound = () => {
        history.push(`${path}/no-matches`);
    };

    const handleEnrollUser = async (posEmployeeProfileId: string) => {
        const { firstName, lastName, phoneNumber } = enrollmentData;

        await selfEnrollPosEmployeeProfile({
            groupId,
            posEmployeeProfileId,
            firstName,
            lastName,
            phoneNumber,
        });

        history.push(`${path}/done`);
    };

    const handleTryAgain = () => {
        history.push(`${path}`);
    };

    const handleNotifyManager = async () => {
        const { firstName, lastName, phoneNumber } = enrollmentData;

        await selfEnrollUnmatched({
            groupId,
            firstName,
            lastName,
            phoneNumber,
        });

        history.push(`${path}/notified`);
    };

    return (
        <Switch>
            <Route path={`${path}/notified`} component={() => <ManagerNotifiedView />} />
            <Route
                path={`${path}/done`}
                component={() => <EmployeeLastStepView phoneNumber={enrollmentData.phoneNumber} />}
            />
            <Route
                path={`${path}/verify`}
                component={() => (
                    <EmployeeVerificationView
                        groupName={groupName}
                        matches={matches}
                        onEnrollUser={handleEnrollUser}
                        onNoMatchesFound={handleNoMatchesFound}
                    />
                )}
            />
            <Route
                path={`${path}/no-matches`}
                component={() => (
                    <NoMatchesView
                        groupName={groupName}
                        enrollmentData={enrollmentData}
                        onNotifyManager={handleNotifyManager}
                        onTryAgain={handleTryAgain}
                    />
                )}
            />
            <Route
                path={path}
                component={
                    isLoading
                        ? () => <PageLoading />
                        : () => (
                              <EmployeeLandingView
                                  groupName={groupName}
                                  onSearchPosEmployeeProfiles={handlePosEmployeeProfileSearch}
                              />
                          )
                }
            />
        </Switch>
    );
};
