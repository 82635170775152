import { useMemo } from 'react';

import NotificationsAPI from '@api/NotificationsAPI';

import { useAdvancedQuery } from './QueryHooks';

export const useSparkResponseNotification = (sparkId: string) => {
    const { data, isLoading } = useAdvancedQuery(['spark-response-notification', sparkId], () =>
        NotificationsAPI.getSparkResponseNotification({ sparkId }),
    );
    return useMemo(
        () => ({
            notification: data?.data,
            notificationIsReady: !isLoading,
        }),
        [data?.data, isLoading],
    );
};
