import { FC, useMemo } from 'react';

import { keyBy } from 'lodash';
import pluralize from 'pluralize';

import Button from '@components/buttons/Button';
import {
    ChevronLeft,
    RewardIcon,
    TagIcon,
    TextContainsIcon,
    TextDoesNotContainIcon,
} from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import Tooltip from '@components/layout/Tooltip';
import FilterSegmentToolbar from '@components/toolbar/FilterSegmentToolbar';
import Toolbar from '@components/toolbar/Toolbar';

import { useTableContext } from '@hooks/TableHooks';

import {
    UseBrandCategoryFilters,
    UsePosRulesBasedSparkProductSelectorTableFilters,
} from '../../hooks';

import './ProductFilterSegmentToolbar.scss';

const ExclusionCalloutRule: FC<{ label: string; labels: string[] }> = ({ label, labels }) => {
    return (
        <Tooltip placement="top" title={labels.join(', ')}>
            <strong className="exclusion-callout-rule">{label}</strong>
        </Tooltip>
    );
};

interface ProductFilterSegmentToolbarProps {
    tableFilters: UsePosRulesBasedSparkProductSelectorTableFilters;
    formHasErrorState?: boolean;
    brandCategoryFilters: UseBrandCategoryFilters;
    emptyStateIsVisible: boolean;
    showExcludedProductsTable?: boolean;
    onHideExcludedProductsTable?: () => void;
}

const ProductFilterSegmentToolbar: FC<ProductFilterSegmentToolbarProps> = ({
    tableFilters,
    brandCategoryFilters,
    formHasErrorState = false,
    emptyStateIsVisible,
    showExcludedProductsTable,
    onHideExcludedProductsTable,
}) => {
    const { filters, setFilters, onChangeSearchFilter } = tableFilters;
    const { tableFilteredRows } = useTableContext();

    const {
        updateBrandsFilter,
        updateCategoriesFilter,
        visibleBrandOptions,
        selectedBrandCountProps,
        visibleCategoryOptions,
        selectedCategoryCountProps,
    } = brandCategoryFilters;

    const segments = useMemo(() => {
        const brandsById = keyBy(visibleBrandOptions, 'value');

        return [
            {
                startIcon: <RewardIcon />,
                label: 'Brands',
                isActive: !!filters.brands?.length,
                exclusionCalloutElement: (
                    <ExclusionCalloutRule
                        label={
                            filters.brands?.length === visibleBrandOptions.length
                                ? '(All) Brands'
                                : `(${filters.brands.length}) ${pluralize(
                                      'Brand',
                                      filters.brands.length,
                                  )}`
                        }
                        labels={filters.brands
                            .map((brand) => brandsById[brand?.value])
                            .map((brand) => brand?.label)}
                    />
                ),
                element: (
                    <Toolbar.DropdownListSelector
                        label={`${
                            filters.brands?.length === visibleBrandOptions.length &&
                            visibleBrandOptions.length
                                ? '(All) '
                                : ''
                        }Brands`}
                        options={visibleBrandOptions}
                        selected={filters.brands || []}
                        onApply={updateBrandsFilter}
                        showCount={
                            !!filters.brands?.length &&
                            filters.brands?.length !== visibleBrandOptions.length
                        }
                        clear={{
                            active: !!filters.brands?.length,
                            onClear: () => {
                                updateBrandsFilter([]);
                            },
                        }}
                        selectedCountProps={selectedBrandCountProps}
                    />
                ),
            },
            {
                startIcon: <TagIcon />,
                label: 'Categories',
                isActive: !!filters.categories?.length,
                exclusionCalloutElement: (
                    <ExclusionCalloutRule
                        label={
                            filters.categories?.length === visibleCategoryOptions.length
                                ? '(All) Categories'
                                : `(${filters.categories.length}) ${pluralize(
                                      'Category',
                                      filters.categories.length,
                                  )}`
                        }
                        labels={filters.categories.map((category) => category.label)}
                    />
                ),
                element: (
                    <Toolbar.DropdownListSelector
                        label={`${
                            filters.categories?.length === visibleCategoryOptions.length &&
                            visibleCategoryOptions.length
                                ? '(All) '
                                : ''
                        }Categories`}
                        options={visibleCategoryOptions}
                        selected={filters.categories || []}
                        onApply={updateCategoriesFilter}
                        showCount={
                            !!filters.categories?.length &&
                            filters.categories?.length !== visibleCategoryOptions.length
                        }
                        clear={{
                            active: !!filters.categories?.length,
                            onClear: () => updateCategoriesFilter([]),
                        }}
                        selectedCountProps={selectedCategoryCountProps}
                    />
                ),
            },
            {
                startIcon: <TextContainsIcon />,
                label: 'Product Name Contains',
                isActive: !!filters.productNameContains?.length,
                exclusionCalloutElement: (
                    <ExclusionCalloutRule
                        label={`(${filters.productNameContains.length}) Product Name Contains`}
                        labels={filters.productNameContains.map(
                            (productNameContains) => productNameContains,
                        )}
                    />
                ),
                element: (
                    <Toolbar.DynamicStringDropdownListSelector
                        createButtonLabel="Create New Rule"
                        buttonLabel="Product Name Contains"
                        pluralUnitLabel="rules"
                        textFieldLabel={
                            <>
                                Product name <strong>contains:</strong>
                            </>
                        }
                        chipText="contains"
                        initialStringFilters={filters.productNameContains ?? []}
                        onApply={(productNameContains) =>
                            setFilters((prevValue) => ({
                                ...prevValue,
                                productNameContains,
                            }))
                        }
                    />
                ),
            },
            {
                startIcon: <TextDoesNotContainIcon />,
                label: 'Product Name Does Not Contain',
                isActive: !!filters.productNameDoesNotContain?.length,
                exclusionCalloutElement: (
                    <ExclusionCalloutRule
                        label={`(${filters.productNameDoesNotContain.length}) Product Name Does Not Contain`}
                        labels={filters.productNameDoesNotContain.map(
                            (productNameDoesNotContain) => productNameDoesNotContain,
                        )}
                    />
                ),
                element: (
                    <Toolbar.DynamicStringDropdownListSelector
                        createButtonLabel="Create New Rule"
                        buttonLabel="Product Name Does Not Contain"
                        pluralUnitLabel="rules"
                        textFieldLabel={
                            <>
                                Product name <strong>does not contain:</strong>
                            </>
                        }
                        chipText="does not contain"
                        initialStringFilters={filters.productNameDoesNotContain ?? []}
                        onApply={(productNameDoesNotContain) =>
                            setFilters((prevValue) => ({
                                ...prevValue,
                                productNameDoesNotContain,
                            }))
                        }
                    />
                ),
            },
        ];
    }, [filters]);

    if (showExcludedProductsTable) {
        const segmentExclusionCalloutElements = segments
            .filter((segment) => segment.isActive)
            .map(({ exclusionCalloutElement }) => exclusionCalloutElement);

        const exclusionCalloutRules =
            segmentExclusionCalloutElements.length === 2
                ? [segmentExclusionCalloutElements[0], ' and ', segmentExclusionCalloutElements[1]]
                : segmentExclusionCalloutElements.map((element, i) => {
                      const isOnlyOneFilter = segmentExclusionCalloutElements.length === 1;
                      if (isOnlyOneFilter) {
                          return element;
                      }

                      const isLastInList =
                          segmentExclusionCalloutElements.length > 1 &&
                          i === segmentExclusionCalloutElements.length - 1;
                      if (isLastInList) {
                          return <>and {element}</>;
                      }

                      return <>{element}, </>;
                  });

        return (
            <>
                <div className="excluded-products_back-nav">
                    <Button
                        color="blue"
                        variant="smooth"
                        startIcon={<ChevronLeft />}
                        onClick={onHideExcludedProductsTable}
                    >
                        Back to Qualifying Products
                    </Button>
                </div>
                <div className="excluded-products_callout">
                    <CalloutMessage
                        color="neutral"
                        message={
                            <>
                                {`These ${tableFilteredRows.length} products `}
                                <strong>do not qualify</strong>
                                {` for this Spark because they don’t match the product rules set for `}
                                {exclusionCalloutRules}.
                            </>
                        }
                    />
                </div>
                <Toolbar className="toolbar-content-start">
                    <Toolbar.Search
                        name="brand-spark-product-selector_search"
                        onChange={onChangeSearchFilter}
                    />
                </Toolbar>
            </>
        );
    }

    return (
        <FilterSegmentToolbar
            label="Qualifying Product Rules"
            addButtonText="Add Rule"
            error={formHasErrorState && emptyStateIsVisible}
            segments={segments}
        />
    );
};

export default ProductFilterSegmentToolbar;
