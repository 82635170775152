export const PosConfigTypes = [
  'alleaves',
  'biotrack',
  'blaze',
  'clover',
  'cova',
  'flourish',
  'flowhub',
  'gotab',
  'greenbits',
  'growflow',
  'iheartjane',
  'indicaonline',
  'leaflogix',
  'lightspeed',
  'meadow',
  'mjfreeway',
  'portal42',
  'posabit',
  'revel',
  'rpower',
  'shopify',
  'square',
  'sweed',
  'toast',
  'treez',
  'vend',
] as const;
export type PosConfigType = typeof PosConfigTypes[number];

export function isPosConfigType(type: string): type is PosConfigType {
  return PosConfigTypes.includes(type as any);
}
