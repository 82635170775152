import { FC } from 'react';

import { Spark } from '@sparkplug/lib';

import { SparkDashboardTableRowData } from '@features/spark-dashboard/types';

import { useAppBodyClasses } from '@hooks/AppHooks';

import { SparkAdminMobileCard } from './SparkAdminMobileCard';

import './SparkAdminMobileCards.scss';

export interface SparkAdminMobileCardProps {
    isLoading: boolean;
    rows: SparkDashboardTableRowData[];
    onSparkSelected: (spark: Spark) => void;
}
export const SparkAdminMobileCards: FC<SparkAdminMobileCardProps> = ({
    rows,
    isLoading,
    onSparkSelected,
}) => {
    useAppBodyClasses(['spark-mobile'], []);

    if (isLoading) {
        return <></>;
    }

    return (
        <div className="spark-admin-cards">
            {rows.map((row, index) => (
                <SparkAdminMobileCard row={row} key={index} onSparkSelected={onSparkSelected} />
            ))}
        </div>
    );
};
