import { FC } from 'react';

import { QuickAcceptRequestParams, Spark } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import { AlertIcon, ArrowForward, CircleCheck } from '@components/icons';
import Spinner from '@components/layout/Spinner';
import { useParams } from '@components/router';
import { SparkDetailsPanel } from '@components/sparks/SparkDetailsPanel/SparkDetailsPanel';
import toast from '@components/toast';

import { useApp } from '@hooks/AppHooks';

import { getDetailedSparkType } from '@helpers/sparks';

import { useQuickApproveSpark } from '../queries/SparkQuickApproveQueries';

import './SparkQuickApproveView.scss';

const SparkQuickApproveView: FC = () => {
    // This view does not require login, but it's possible the user is already logged in when they access the link
    const { history, user } = useApp();
    const { sparkId, quickApprovalCode }: QuickAcceptRequestParams = useParams();
    const { isQuickApprovingSpark, quickApproveResponseData, quickApproveError } =
        useQuickApproveSpark({ sparkId, quickApprovalCode });

    if (isQuickApprovingSpark) {
        return (
            <div className="quick-approve-loading">
                <div>Approving your spark...</div>
                <Spinner />
            </div>
        );
    }

    if (quickApproveError) {
        history.push('/login');
        toast.error('Error with quick approval process - please log in to approve this spark', {
            id: 'quick-approve-error',
        });
        return <></>;
    }

    const {
        _id,
        groupId,
        detailedSparkType,
        sparkVendor,
        sparkBrand,
        locationIds,
        posEmployeeProfileIds,
        posProductIds,
        requestState,
    } = quickApproveResponseData?.spark || ({} as Spark);

    const sparkAccessUrl = user
        ? `/${groupId}/sparks/${_id}`
        : `/login?redirectTo=/${groupId}/sparks/${_id}`;

    let approvalStatusElement = (
        <div className="approval-status">Unknown Status - please log in to review this Spark</div>
    );
    switch (requestState) {
        case 'expired':
            approvalStatusElement = (
                <div className="approval-status">
                    <AlertIcon className="expired" />
                    This Spark has expired and can no longer be approved.
                </div>
            );
            break;
        case 'rejected':
            approvalStatusElement = (
                <div className="approval-status">
                    <AlertIcon className="rejected" />
                    This Spark has already been rejected and can no longer be approved.
                </div>
            );
            break;
        case 'accepted':
            approvalStatusElement = (
                <div className="approval-status">
                    <CircleCheck className="success" />
                    {quickApproveResponseData?.alreadyAccepted
                        ? `This 
                Spark has already been approved!`
                        : `You've approved this Spark!`}
                </div>
            );
            break;
        default:
            break;
    }

    return (
        <div className="quick-approve">
            {approvalStatusElement}
            <SparkDetailsPanel
                spark={quickApproveResponseData!.spark}
                detailedSparkType={
                    detailedSparkType ?? getDetailedSparkType(quickApproveResponseData!.spark)
                }
                vendorGroupName={sparkVendor?.name}
                otherGroupName={sparkBrand?.name}
                locationCount={locationIds.length}
                participantCount={posEmployeeProfileIds.length}
                productCount={posProductIds.length}
            />
            <Button
                variant="filled"
                endIcon={<ArrowForward />}
                onClick={() => history.push(sparkAccessUrl)}
            >
                {user ? 'View Spark Details' : 'Login to view Spark'}
            </Button>
        </div>
    );
};

export default SparkQuickApproveView;
