import { ReactNode } from 'react';

import { appendClasses } from '@helpers/ui';

import './DrawerFooter.scss';

type DrawerFooterProps = {
    children?: ReactNode;
    className?: string;
};

const DrawerFooter = ({ children, className, ...rest }: DrawerFooterProps) => {
    const classNamesAppended = appendClasses(['drawer-footer', className]);
    return (
        <div className={classNamesAppended} {...rest}>
            {children}
        </div>
    );
};

export default DrawerFooter;
