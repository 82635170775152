import { FC, ReactNode } from 'react';

import Modal, { IDefaultModalProps } from '@components/overlays/Modal';

import { appendClasses } from '@helpers/ui';

import './MobileInfoModal.scss';

interface MobileInfoModalProps extends IDefaultModalProps {
    className?: string;
    isVisible: boolean;
    title?: string;
    onClose: (doActionOnClose: boolean) => void;
    children: ReactNode;
}

const MobileInfoModal: FC<MobileInfoModalProps> = ({
    className,
    isVisible,
    title,
    onClose,
    children,
    size,
}) => {
    const classNamesAppended = appendClasses([className, 'mobile-info-modal']);

    return (
        <Modal
            className={classNamesAppended}
            isVisible={isVisible}
            onClose={() => onClose(false)}
            size={size}
        >
            <Modal.MobileHeader onClose={onClose} title={title} />

            <Modal.Content>{children}</Modal.Content>
        </Modal>
    );
};

export default MobileInfoModal;
