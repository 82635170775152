import { FC, useMemo } from 'react';

import { BookIcon, ExclamationIcon } from '@components/icons';
import Toolbar from '@components/toolbar/Toolbar';

import { openUrl } from '@helpers/ui';

import { IOption } from '@app/types/UITypes';

import { ProductInventoryFilters } from '../ProductInventoryTable/useProductInventoryTableFilters';

interface InventoryToolbarProps {
    showLocationsDropdown: boolean;
    searchFilter: string;
    onChangeSearchFilter: (event: any) => void;
    filters: ProductInventoryFilters;
    updateFilters: (updatedFilters: Partial<ProductInventoryFilters>) => void;
    visibleLocationOptions: IOption[];
    locationOptions: IOption[];
    selectedLocationCountProps: {
        tooltipMessage: string | undefined;
        pluralUnitLabel: string;
    };
}

export const SparkDetailsInventoryToolbar: FC<InventoryToolbarProps> = ({
    showLocationsDropdown,
    searchFilter,
    onChangeSearchFilter,
    filters,
    updateFilters,
    visibleLocationOptions,
    locationOptions,
    selectedLocationCountProps,
}) => {
    const isAllLocationsSelected = useMemo(
        () =>
            locationOptions.length > 0 &&
            filters.locations &&
            filters.locations.length === locationOptions.length &&
            locationOptions.every((option) =>
                filters.locations?.some((location) => location.value === option.value),
            ),
        [locationOptions, filters.locations],
    );

    return (
        <Toolbar className="inventory-toolbar">
            {showLocationsDropdown && (
                <Toolbar.DropdownListSelector
                    label={`${isAllLocationsSelected ? '(All) ' : ''}Locations`}
                    options={visibleLocationOptions}
                    selected={filters.locations || []}
                    onApply={(locations) => updateFilters({ ...filters, locations })}
                    showCount={!isAllLocationsSelected && filters.locations?.length > 0}
                    clear={{
                        active: !!filters.locations?.length,
                        onClear: () => {
                            updateFilters({ ...filters, locations: [] });
                        },
                    }}
                    selectedCountProps={selectedLocationCountProps}
                />
            )}
            <Toolbar.Search
                name="sparks-search"
                defaultValue={searchFilter}
                onChange={onChangeSearchFilter}
            />
            <Toolbar.Checkbox
                label={
                    <div className="out-of-stock">
                        Show only&nbsp;
                        <span className="text-buttercup-700 flex items-center">
                            out-of-stock
                            <ExclamationIcon className="pl-1" />
                        </span>
                    </div>
                }
                value={filters.outOfStock}
                onChange={() => updateFilters({ ...filters, outOfStock: !filters.outOfStock })}
            />

            <Toolbar.Button
                className="read-more-button"
                alignEnd
                variant="flat"
                color="blue"
                startIcon={<BookIcon />}
                onClick={() =>
                    openUrl(
                        'https://help.sparkplug.app/en/articles/7856282-learn-more-about-inventory-tracking',
                    )
                }
            >
                Learn more about inventory
            </Toolbar.Button>
        </Toolbar>
    );
};
