import { FC } from 'react';

import { AccountRole, AccountUserExternalGroup } from '@sparkplug/lib';

import Chip from '@components/chips/Chip';
import LabelWithAdditionalChip from '@components/labels/LabelWithAdditionalChip';

import { IAccount } from '@app/types/AccountsTypes';

import './UserGroupRoleChip.scss';

const UserChip = () => <Chip color="neutral" label="User" variant="outlined" />;

const AdminChip = () => <Chip color="yellow" label="Admin" />;

export interface UserGroupRoleChipProps {
    account?: IAccount;
    groupRole?: AccountRole;
    externalGroups?: AccountUserExternalGroup[];
}

const UserGroupRoleChip: FC<UserGroupRoleChipProps> = ({ account, groupRole, externalGroups }) => {
    if (!['group-member', 'group-admin'].includes(groupRole ?? '')) {
        return <span>--</span>;
    }

    const count = externalGroups?.length;
    const label = groupRole === 'group-member' ? <UserChip /> : <AdminChip />;
    const tooltip = count
        ? [account?.name, ...externalGroups.map(({ groupName }) => groupName)].join(', ')
        : '';

    return <LabelWithAdditionalChip label={label} count={count} tooltip={tooltip} />;
};

export default UserGroupRoleChip;
