import axios from 'axios';

import {
    RespondToSparkInviteRequestBody,
    Spark,
    SparkParticipantGroup,
    UpdatePosLocationPathParams,
    UpdatePosLocationRequestBody,
} from '@sparkplug/lib';

export default class SparkplugAPI {
    static async getConfigByAccountId(groupId: string): Promise<any> {
        return (await axios.get(`/api/v1/sparkplug/config?group_id=${groupId}`)).data;
    }

    static async getConfigByType(type: string): Promise<any> {
        return (await axios.get(`/api/v1/sparkplug/config?type=${type}`)).data;
    }

    static async createConfig(props: any): Promise<any> {
        return (await axios.post(`/api/v1/sparkplug/config`, props)).data;
    }

    static async updateConfig(configId: string, props: any): Promise<any> {
        return (await axios.put(`/api/v1/sparkplug/config/${configId}`, props)).data;
    }

    static async sendSparkRequest(
        groupId: string,
        spark: Spark,
        participantGroups: SparkParticipantGroup[],
        requestForSparkId?: string,
    ) {
        const body = {
            groupId,
            spark,
            participantGroups,
            requestForSparkId,
        };

        return (await axios.post<void>(`/api/v1/sparkplug/sparks/request`, body)).data;
    }

    static async respondToSparkRequest(body: RespondToSparkInviteRequestBody): Promise<void> {
        return (await axios.post(`/api/v1/sparkplug/sparks/respond`, body)).data;
    }

    static async updatePosLocation(
        { locationId }: UpdatePosLocationPathParams,
        body: UpdatePosLocationRequestBody,
    ): Promise<void> {
        return (await axios.put(`/api/v1/sparkplug/pos/locations/${locationId}`, body)).data;
    }

    static async registerPointOfSale({
        type,
        groupId,
        code,
        shop,
    }: {
        type: string;
        groupId: string;
        code: string;
        shop?: string;
    }): Promise<any> {
        return (
            await axios.post(`/api/v1/sparkplug/pos/register/${type}?groupId=${groupId}`, {
                code,
                shop,
            })
        ).data;
    }

    static async deactivatePointOfSale(posConfigId: string): Promise<any> {
        return (await axios.post(`/api/v1/sparkplug/pos/deactivate`, { posConfigId })).data;
    }

    static async getTwilioMessages(accountId: string, posEmployeeProfileId: string): Promise<any> {
        return (await axios.get(`/api/v1/admin/twilio/${accountId}/${posEmployeeProfileId}`)).data;
    }
}
