import { isNumber, orderBy } from 'lodash';

interface Participant {
  value?: number | null;
  transactionCount?: number;
}

export const isEligibleParticipant = ({
  participant: { value, transactionCount = 0 },
  minThreshold = 0,
  minTransactionCount = 0,
}: {
  participant: Participant;
  minThreshold?: number;
  minTransactionCount?: number;
}) => {
  if (!isNumber(value)) {
    return false;
  }
  const meetsMinThreshold = value >= minThreshold;
  const meetsMinTransactionCount = transactionCount >= minTransactionCount;
  return meetsMinThreshold && meetsMinTransactionCount;
};

export const roundToHundredth = (value: number) => Math.round((value + Number.EPSILON) * 100) / 100;

export const sortSparkParticipants = ({
  sparkParticipants = [],
  minTransactionCount,
  minThreshold,
}: {
  sparkParticipants: Participant[];
  minTransactionCount?: number;
  minThreshold?: number;
}) => {
  const { eligibleParticipants = [], ineligibleParticipants = [] } = sparkParticipants
    .map((participant) => {
      const roundedParticipantValue =
        participant.value && isNumber(participant.value)
          ? roundToHundredth(participant.value)
          : participant.value;
      return { ...participant, value: roundedParticipantValue };
    })
    .reduce(
      (acc, participant) => {
        if (isEligibleParticipant({ participant, minThreshold, minTransactionCount })) {
          acc.eligibleParticipants.push(participant);
        } else {
          acc.ineligibleParticipants.push(participant);
        }

        return acc;
      },
      { eligibleParticipants: [], ineligibleParticipants: [] } as {
        eligibleParticipants: Participant[];
        ineligibleParticipants: Participant[];
      },
    );

  return [
    ...orderBy(eligibleParticipants, ['value'], ['desc']),
    ...orderBy(
      ineligibleParticipants,
      // non-numerical values should be sorted to the bottom
      ({ value }) => (isNumber(value) ? value : Number.NEGATIVE_INFINITY),
      ['desc'],
    ),
  ];
};
