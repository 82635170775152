import { DetailedSparkType, Spark, SparkDeclineResponse } from '@sparkplug/lib';

import toast from '@components/toast';

import {
    IConfirmSparkPayoutsResponse,
    createUpdateSpark as _createUpdateSpark,
    deleteSpark as _deleteSpark,
    finalizeSpark as _finalizeSpark,
    respondToBrandSparkRequest as _respondToBrandSparkRequest,
    sendBrandSparkRequest as _sendBrandSparkRequest,
} from '@helpers/sparks';

import { ISparkSubGroup } from '@app/types/SparksTypes';

export const defaultErrorMessage = 'Something went wrong.';

const toastOptions = {
    className: 'toaster',
    loading: {
        className: 'toaster message-is-null',
    },
};

const finalizeSpark: typeof _finalizeSpark = (sparkData: Spark) => {
    return new Promise((res, rej) => {
        toast.promise(
            _finalizeSpark(sparkData),
            {
                loading: 'Finalizing...',
                success: (data) => {
                    res(data as IConfirmSparkPayoutsResponse);
                    return 'Spark payouts finalized.';
                },
                error: (err) => {
                    // eslint-disable-next-line no-console
                    console.error(`[SparksUI.saveSparkPayouts]: ${err}`);
                    rej(err);
                    return defaultErrorMessage;
                },
            },
            toastOptions,
        );
    });
};

export default {
    createUpdateSpark: (
        sparkId: string | null | undefined,
        sparkData: Spark,
        detailedSparkType: DetailedSparkType,
        sparkSubGroups: ISparkSubGroup[] = [],
        requestForSparkId?: string,
    ) => {
        return new Promise((res, rej) => {
            toast.promise(
                _createUpdateSpark(
                    sparkId,
                    sparkData,
                    detailedSparkType,
                    sparkSubGroups,
                    requestForSparkId,
                ),
                {
                    loading: 'Saving Spark...',
                    success: (data) => {
                        res(data);
                        return 'Success!';
                    },
                    error: (err) => {
                        // eslint-disable-next-line no-console
                        console.error(`[SparksUI.createUpdateSpark]: ${err}`);
                        rej(err);
                        return defaultErrorMessage;
                    },
                },
                toastOptions,
            );
        });
    },

    respondToBrandSparkRequest: (
        sparkData: Spark,
        sparkSubGroups: ISparkSubGroup[],
        accepted: boolean,
        declineResponse?: SparkDeclineResponse,
    ) => {
        return new Promise((res, rej) => {
            toast.promise(
                _respondToBrandSparkRequest(sparkData, sparkSubGroups, accepted, declineResponse),
                {
                    loading: null,
                    success: (data) => {
                        res(data);
                        let sparkText;

                        if (sparkData.recurringSparkScheduleId && sparkData.recurringSchedule) {
                            switch (sparkData.recurringSchedule.interval) {
                                case 'daily':
                                    sparkText = 'Daily recurring Spark';
                                    break;
                                case 'weekly':
                                    sparkText = 'Weekly recurring Spark';
                                    break;
                                case 'twice_monthly':
                                    sparkText = 'Twice-monthly recurring Spark';
                                    break;
                                case 'monthly':
                                    sparkText = 'Monthly recurring Spark';
                                    break;
                                default:
                                    sparkText = 'Spark';
                                    break;
                            }
                        } else {
                            sparkText = 'Spark';
                        }

                        if (accepted) {
                            return `${sparkText} accepted.`;
                        }
                        return `${sparkText} declined.`;
                    },
                    error: (err) => {
                        // eslint-disable-next-line no-console
                        console.error(`[SparksUI.respondToBrandSparkRequest]: ${err}`);
                        rej(err);
                        return defaultErrorMessage;
                    },
                },
                toastOptions,
            );
        });
    },

    sendBrandSparkRequest: (
        sparkData: Spark,
        detailedSparkType: DetailedSparkType,
        sparkSubGroups: ISparkSubGroup[] = [],
        requestForSparkId?: string,
    ) => {
        return new Promise((res, rej) => {
            toast.promise(
                _sendBrandSparkRequest(
                    sparkData,
                    detailedSparkType,
                    sparkSubGroups,
                    requestForSparkId,
                ),
                {
                    loading: null,
                    success: (data) => {
                        res(data);
                        return 'Spark request sent.';
                    },
                    error: (err) => {
                        // eslint-disable-next-line no-console
                        console.error(`[SparksUI.sendBrandSparkRequest]: ${err}`);
                        rej(err);
                        return defaultErrorMessage;
                    },
                },
                toastOptions,
            );
        });
    },

    deleteSpark: (sparkId: string) => {
        return new Promise((res, rej) => {
            toast.promise(
                _deleteSpark(sparkId),
                {
                    loading: null,
                    success: (data) => {
                        res(data);
                        return 'Spark deleted.';
                    },
                    error: (err) => {
                        // eslint-disable-next-line no-console
                        console.error(`[SparksUI.deleteSpark]: ${err}`);
                        rej(err);
                        return defaultErrorMessage;
                    },
                },
                toastOptions,
            );
        });
    },

    finalizeSpark,
};
