import { Add as AddIcon } from '@components/icons';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import { useHistory } from '@components/router';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

export const AccountLinkInfoDisplay = ({ className }: { className?: string }) => {
    const { account } = useSparkplugAccount();
    const history = useHistory();

    return (
        <EmptyStateDisplay
            layout="horizontal"
            addPaperWrapper
            className={className}
            graphic={
                account?.type === 'brand' ? (
                    <img src="/static/images/vendors-partners-placeholder.jpg" alt="placeholder" />
                ) : (
                    <img
                        src="/static/images/retailers-partners-placeholder.jpg"
                        alt="placeholder"
                    />
                )
            }
            label={
                account?.type === 'brand'
                    ? 'Connect with Retail partners to get started with Sparks!'
                    : 'Connect with Vendors to receive sponsored Sparks!'
            }
            smallText={
                account?.type === 'brand'
                    ? 'Add retailers carrying your products to create incentives, boost sales, and access valuable analytics.'
                    : 'Add vendors to receive sponsored incentives, drive sales, and access exclusive brand-supported rewards and insights.'
            }
            actionButton={{
                label: account?.type === 'brand' ? 'Add Retailer' : 'Add Vendor',
                onClick: () => {
                    history.push(`/${account?._id}/partners/available`);
                },
                startIcon: <AddIcon />,
            }}
        />
    );
};
