import { FC, useEffect, useMemo } from 'react';
import NumberFormat from 'react-number-format';

import { IPublicAccount, IPublicUser } from '@sparkplug/lib';

import { useAccountSparkplugUsersQueryByGroupId } from '@core/users';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import RadioGroup from '@components/inputs/RadioGroup';
import Skeleton from '@components/layout/Skeleton';
import Modal from '@components/overlays/Modal';

import { useModal } from '@hooks/ModalHooks';

import { formatPhoneNumber } from '@helpers/ui';

import { IOption } from '@app/types/UITypes';

import './SelectGiftCardDetailsView.scss';

export const buildUserOptions = (users: IPublicUser[] = []) => {
    return users
        .filter(({ email, phoneNumber }) => email || phoneNumber)
        .map(({ _id, firstName, lastName }) => ({
            label: `${firstName} ${lastName}`,
            value: _id,
        }));
};

// Note: The `contactMethodDefaultValue` is used when the user only has one contact method option
export const getContactMethodRadioGroupDetails = (user?: IPublicUser) => {
    if (!user?.phoneNumber && !user?.email) {
        return {
            contactMethodOptions: [],
            contactMethodDefaultValue: undefined,
        };
    }

    const emailOption = {
        label: user?.email,
        value: 'email',
    };
    const phoneNumberOption = {
        label: formatPhoneNumber(user?.phoneNumber),
        value: 'phoneNumber',
    };

    const onlyHasEmail = user?.email && !user?.phoneNumber;
    if (onlyHasEmail) {
        return {
            contactMethodOptions: [emailOption],
            contactMethodDefaultValue: 'email',
        };
    }
    const onlyHasPhoneNumber = user?.phoneNumber && !user?.email;
    if (onlyHasPhoneNumber) {
        return {
            contactMethodOptions: [phoneNumberOption],
            contactMethodDefaultValue: 'phoneNumber',
        };
    }

    return {
        contactMethodOptions: [emailOption, phoneNumberOption],
        contactMethodDefaultValue: undefined,
    };
};

const useApplicableUserOptions = (accountId?: string) => {
    const { accountSparkplugUsers = [], accountSparkplugUsersAreReady } =
        useAccountSparkplugUsersQueryByGroupId(accountId);

    return useMemo(() => {
        return {
            userOptions: buildUserOptions(accountSparkplugUsers),
            userOptionsAreReady: accountSparkplugUsersAreReady,
        };
    }, [accountSparkplugUsers, accountSparkplugUsersAreReady]);
};

interface SelectGiftCardDetailsViewProps {
    account?: IPublicAccount;

    amount: number;
    onAmountChange: Function;

    campaignOptions: IOption[];
    campaignId?: string;
    onCampaignChange: Function;

    contactMethodType?: string;
    onContactMethodChange: Function;

    note?: string;
    onNoteChange: Function;

    user?: IPublicUser;
    onUserChange: Function;
}

export const SelectGiftCardDetailsView: FC<SelectGiftCardDetailsViewProps> = ({
    account,

    amount,
    onAmountChange,

    campaignOptions,
    campaignId,
    onCampaignChange,

    contactMethodType,
    onContactMethodChange,

    note,
    onNoteChange,

    user,
    onUserChange,
}) => {
    const { next, back } = useModal();
    const { userOptions, userOptionsAreReady } = useApplicableUserOptions(account?._id);

    const { contactMethodOptions, contactMethodDefaultValue } = useMemo(
        () => getContactMethodRadioGroupDetails(user),
        [user],
    );

    useEffect(() => {
        // If the User only has one contact method available, automatically use it
        if (contactMethodDefaultValue) {
            onContactMethodChange(contactMethodDefaultValue);
        }
    }, [contactMethodDefaultValue]);

    const loadingView = <Skeleton height={320} />;

    const formView = (
        <Form className="gift-card-details-form">
            <Form.TextField
                label="Recipient Account"
                name="gift-card_account-name"
                value={account?.name}
                disabled
            />

            <Form.SearchSelect
                required
                name="gift-card_user"
                label="Recipient Name"
                options={userOptions}
                value={user?._id ?? ''}
                onChange={({ value }) => onUserChange(value)}
            />

            {!!user && (
                <RadioGroup
                    required
                    name="gift-card_radiogroup"
                    label="Recipient Contact Method"
                    color="blue"
                    direction="vertical"
                    onChange={(e) => onContactMethodChange(e.target.value)}
                    options={contactMethodOptions}
                    value={contactMethodType}
                />
            )}

            <Form.SearchSelect
                required
                name="gift-card_campaign"
                label="Campaign"
                options={campaignOptions}
                value={campaignId ?? ''}
                onChange={({ value }) => onCampaignChange(value)}
            />

            <article>
                <Form.Label>Amount *</Form.Label>
                <NumberFormat
                    required
                    thousandSeparator
                    value={amount}
                    className="money-textfield"
                    name="gift-card_amount"
                    customInput={Form.TextField}
                    onValueChange={({ value }) => onAmountChange(Number(value))}
                />
            </article>

            <Form.TextField
                name="gift-card_note"
                multiline
                rows={3}
                label="Reason (Internal Tracking)"
                placeholder="Enter reason for gift..."
                value={note}
                onChange={(e) => onNoteChange(e.target.value)}
            />
        </Form>
    );

    const canMoveToNextStep =
        userOptionsAreReady && !!user && !!contactMethodType && !!campaignId && amount > 0;

    return (
        <>
            <Modal.Content>{userOptionsAreReady ? formView : loadingView}</Modal.Content>

            <Modal.Actions>
                <Button onClick={back} color="neutral">
                    Back
                </Button>

                <Button onClick={next} disabled={!canMoveToNextStep}>
                    Continue
                </Button>
            </Modal.Actions>
        </>
    );
};
