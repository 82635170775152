import { FC } from 'react';

import { Point, SliceTooltipProps } from '@nivo/line';

import SliceTooltip, { IPointLabel } from '@components/charts/SliceTooltip';
import PercentDifferenceChip from '@components/chips/PercentDifferenceChip';

import { IChartDataPointMeta, IExternalTooltipProps } from '@app/types/ChartDataTypes';

import { ComparisonColors } from './Nivo.Constants';

interface ILinePoint extends Omit<Point, 'data'> {
    data: {
        y: number;
        x: number;
        yFormatted: string;
        xFormatted: string;
        yStacked: number;
        meta: IChartDataPointMeta;
    };
}
interface ILineSliceOverride extends Omit<SliceTooltipProps['slice'], 'points'> {
    points: ILinePoint[];
}

interface IDefaultLineTooltipProps
    extends Omit<IExternalTooltipProps, 'breakdown' | 'isComparisonWindow'> {
    slice: ILineSliceOverride;
}

const getSliceValuesFromTooltipData = (
    props: IDefaultLineTooltipProps,
): {
    points: IPointLabel[];
    total: string | number;
} => {
    const { formatter, totalIsSum, slice } = props;
    // const x = slice.points[0].data.x; // TODO: Re-use for pagination

    let total = 0;

    const points: IPointLabel[] = slice.points
        .sort((a, b) => {
            return b.data.y - a.data.y;
        })
        .map((point) => {
            total += point.data.y;

            return {
                color: point.serieColor,
                name: point.serieId?.toString() || '',
                value: point.data.yFormatted,
            };
        });

    if (!totalIsSum) {
        total /= points.length;
    }

    const totalFormatted = String(formatter ? formatter(total) : total);

    const visiblePoints = points.filter((point) => point.color !== 'transparent') as IPointLabel[];

    return {
        total: totalFormatted,
        points: visiblePoints,
    };
};

const NivoLineTotalsTooltip: FC<IDefaultLineTooltipProps> = (props) => {
    const { slice } = props;
    const { meta, xFormatted } = slice.points?.[0]?.data;

    const title = meta?.tooltipTitle || xFormatted;

    const { total, points } = getSliceValuesFromTooltipData(props);

    return <SliceTooltip title={title} points={points} total={total} />;
};

const NivoLineBreakdownTooltip: FC<IDefaultLineTooltipProps> = (props) => {
    const { slice } = props;
    const { meta, xFormatted } = slice.points?.[0]?.data;

    const title = `${meta?.metricLabel} Sold`;
    const subTitle = meta?.comparisonLabel || meta?.tooltipTitle || xFormatted;

    const { total, points } = getSliceValuesFromTooltipData(props);

    return points?.length ? (
        <SliceTooltip title={title} subTitle={subTitle} points={points} total={total} />
    ) : (
        <></>
    );
};

function parseComparisonPoints(points: ILinePoint[]) {
    let currentPoint = null as unknown as ILinePoint;
    let previousPoint = null as unknown as ILinePoint;

    points
        .filter((point) => point?.data?.meta?.isHidden == null)
        .forEach((point) => {
            const isComparisonPoint = point?.data?.meta?.isComparisonDate;

            if (isComparisonPoint) {
                previousPoint = point;
            } else {
                currentPoint = point;
            }
        });

    if (currentPoint == null && previousPoint != null) {
        const metricLabel = previousPoint?.data?.meta?.metricLabel;
        const subTitle = previousPoint?.data?.meta?.subTitle;
        const name = previousPoint?.data?.meta?.comparisonLabel || '';

        currentPoint = {
            serieColor: ComparisonColors[0],
            serieId: name,
            data: {
                y: 0,
                yFormatted: '0',
                meta: {
                    isNull: true,
                    metricLabel,
                    subTitle,
                },
            },
        } as ILinePoint;
    }

    return {
        currentPoint,
        previousPoint,
    };
}

const NivoLineComparisonTooltip: FC<{
    slice: ILineSliceOverride;
    locationsMap: { [locationName: string]: string };
}> = ({ slice, locationsMap }) => {
    const { currentPoint, previousPoint } = parseComparisonPoints(slice.points);

    if (currentPoint == null && previousPoint == null) {
        return null;
    }

    const locationNames = Object.values(locationsMap);

    const title = currentPoint?.data?.meta?.metricLabel || previousPoint?.data?.meta?.metricLabel;
    const subTitle =
        locationNames.length > 1 ? `All ${locationNames.length} Locations` : locationNames?.[0];
    const points = [currentPoint, previousPoint]
        .map((point) => {
            const { comparisonLabel = '' } = point?.data?.meta ?? {};
            const name = comparisonLabel ?? point?.serieId;

            return {
                color: point?.serieColor,
                name,
                value: point?.data?.yFormatted,
            };
        })
        .filter(({ name }) => name);

    const prevValue = previousPoint?.data?.y;
    const currentValue = currentPoint?.data?.y;
    const currentValueIsNull = currentPoint?.data?.meta?.isNull;

    return (
        <SliceTooltip
            className="comparison-slice-tooltip"
            title={title}
            subTitle={subTitle}
            points={points}
            chip={
                !currentValueIsNull ? (
                    <PercentDifferenceChip prevValue={prevValue} currentValue={currentValue} />
                ) : (
                    <></>
                )
            }
        />
    );
};

const NivoLineTooltip: any = ({
    formatter,
    totalIsSum,
    locationsMap,
    isComparisonWindow = false,
    breakdown = 'none',
    nonVisibleTotals,
    nonVisibleCount,
}: IExternalTooltipProps) => {
    return ({ slice }: { slice: ILineSliceOverride }) => {
        if (breakdown !== 'none') {
            return (
                <NivoLineBreakdownTooltip
                    formatter={formatter}
                    totalIsSum={totalIsSum}
                    locationsMap={locationsMap}
                    slice={slice}
                    nonVisibleTotals={nonVisibleTotals}
                    nonVisibleCount={nonVisibleCount}
                />
            );
        }

        if (isComparisonWindow) {
            return <NivoLineComparisonTooltip slice={slice} locationsMap={locationsMap} />;
        }

        return (
            <NivoLineTotalsTooltip
                formatter={formatter}
                totalIsSum={totalIsSum}
                locationsMap={locationsMap}
                slice={slice}
            />
        );
    };
};

export default NivoLineTooltip;
