import { memo } from 'react';

import { ESparkStatusColors } from '@constants/SparkConstants';

import Chip from '@components/chips/Chip';
import Tooltip from '@components/layout/Tooltip';

import { TSparkStatus } from '@app/types/SparksTypes';

const SparkStatusChip = memo(
    ({
        status,
        customLabel,
        dense = true,
        tooltip = undefined,
    }: {
        status: TSparkStatus;
        customLabel?: string | null;
        dense?: boolean;
        tooltip?: any;
    }) => {
        const color = ESparkStatusColors[status];
        const label =
            customLabel && customLabel?.length && status !== 'Complete' ? customLabel : status;

        const StatusChipComponent = (
            <Chip
                className="spark-status-chip"
                dense={dense}
                color={color}
                label={label}
                variant={status === 'Pending' ? 'outlined' : 'flat'}
            />
        );

        if (tooltip) {
            return (
                <Tooltip title={tooltip}>
                    <div>{StatusChipComponent}</div>
                </Tooltip>
            );
        }

        return StatusChipComponent;
    },
);

export default SparkStatusChip;
