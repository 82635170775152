import { InputHTMLAttributes, ReactElement, useMemo } from 'react';

import { FormControlLabel, Checkbox as MaterialUICheckbox } from '@mui/material';

import { appendClasses } from '@helpers/ui';

import { CheckedEventOnChange } from '@app/types/UITypes';

import './Checkbox.scss';

export interface ICheckboxProps {
    className?: string;
    color?: 'neutral' | 'blue' | 'green' | 'red';
    variant?: 'outlined' | 'raised' | 'smooth' | 'flat';
    name?: string;
    description?: ReactElement | string;
    label?: string | ReactElement;
    indeterminate?: boolean;
    value: boolean;
    disabled?: boolean;
    onChange?: CheckedEventOnChange;
}

const Checkbox = ({
    className = '',
    color = 'blue',
    variant = 'flat',
    name = '',
    description = '',
    label = '',
    indeterminate = false,
    value,
    onChange,
    disabled = false,
}: ICheckboxProps) => {
    const classNameAppended = appendClasses([
        className,
        // 'form-control',
        'checkbox',
        `checkbox-color-${color}`,
        `checkbox-variant-${variant}`,
        disabled ? 'is-disabled' : 'is-not-disabled',
        description ? 'has-description' : 'has-no-description',
    ]);

    const CheckboxInput = useMemo(
        () => (
            <MaterialUICheckbox
                checked={value}
                indeterminate={indeterminate}
                onChange={onChange}
                name={name}
                disabled={disabled}
                inputProps={
                    {
                        'data-testid': `checkbox.${name}`,
                    } as InputHTMLAttributes<HTMLInputElement>
                }
            />
        ),
        [value, indeterminate, onChange],
    );

    if (label || description) {
        const _label = (
            <>
                {label && <div className="checkbox-label">{label}</div>}
                {description && <div className="checkbox-description">{description}</div>}
            </>
        );
        return (
            <FormControlLabel
                className={classNameAppended}
                control={CheckboxInput}
                label={_label}
            />
        );
    }

    return <div className={classNameAppended}>{CheckboxInput}</div>;
};

export default Checkbox;
