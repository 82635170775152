import { FC } from 'react';

import Button from '@components/buttons/Button';
import { ArrowForwardIos as ArrowIcon, Lock as LockIcon } from '@components/icons';

import { useApp } from '@hooks/AppHooks';

import './RequiredTrainingButton.scss';

interface RequiredTrainingButtonProps {
    onClick: (e: any) => void;
    className?: string;
}
export const RequiredTrainingButton: FC<RequiredTrainingButtonProps> = ({
    className = '',
    onClick,
}) => {
    const { appIsMobile } = useApp();
    const buttonLabelText = appIsMobile ? 'Training Required to Participate' : 'Training Required';
    return (
        <Button
            className={`required-training-button ${className}`.trim()}
            onClick={onClick}
            variant="smooth"
            startIcon={<LockIcon className="lock-icon" />}
            endIcon={<ArrowIcon className="arrow-forward-icon" />}
        >
            {buttonLabelText}
        </Button>
    );
};
