import { FC, ReactNode, createContext, useContext, useMemo } from 'react';

import { isEmpty, omitBy } from 'lodash';
import moment, { MomentInput } from 'moment';

import {
    BucketsKeyedByLabel,
    FullChartResponseBody,
    TChartDataMetric,
    TComparisonPeriodOption,
} from '@sparkplug/lib';

import { ComparisonDatesKeyedByCurrentDate, ComparisonRange } from '@core/charts/types/ChartsTypes';
import { sumLocationBucketsFromChartResponse } from '@core/charts/utils/ChartsUtils';

import { getDateRangeLabel } from '@components/toolbar/ToolbarDateRangeSelector';

import {
    InitialComparisonWindowState,
    calculateComparisonPeriod,
    useCloudChartDataQuery,
    useComparisonWindowState,
} from '@hooks/ChartDataHooks';

import { isCumulativeChartMetric } from '@helpers/charts';
import { getDaysBetweenDates } from '@helpers/util';

import { IChartDataSettings } from '@app/types/ChartDataTypes';

import { ChartProvider, useChartContext } from '../AdvancedChart/ChartContext';

export interface ComparisonChartContextValue
    extends ReturnType<typeof useComparisonWindowState>,
        ReturnType<typeof useCalculatedComparisonChartContextValues> {
    comparisonChartIsReady: boolean;
    comparisonRange: ComparisonRange;
    comparisonPeriodCloudChartData: FullChartResponseBody | {};
    comparisonDatesByCurrentDate: ComparisonDatesKeyedByCurrentDate;
    currentPeriodLabel: string;
}

export const DEFAULT_COMPARISON_CHART_CONTEXT_VALUE: ComparisonChartContextValue = {
    comparisonChartIsReady: false,
    showComparisonWindows: false,
    setShowComparisonWindows: () => {},
    comparisonPeriod: 'previousPeriod',
    setComparisonPeriod: () => {},
    comparisonRange: {},
    comparisonDatesByCurrentDate: {},
    comparisonPeriodCloudChartData: {},
    currentPeriodLabel: '',
    hasNoHistoricalSalesData: false,
    periodBuckets: {
        currentPeriod: {},
        previousPeriod: {},
    },
    comparisonValues: {
        currentValue: 0,
        comparisonValue: 0,
        previousValue: 0,
        previousTotalValue: 0,
    },
};
export const ComparisonChartContext = createContext<ComparisonChartContextValue>(
    DEFAULT_COMPARISON_CHART_CONTEXT_VALUE,
);
export const useComparisonChartContext = () => {
    const context = useContext(ComparisonChartContext);

    if (!context) {
        throw new Error('useComparisonChartContext must be used within a ComparisonChartProvider');
    }

    return context;
};

/**
 * @description
 *
 * This hook is designed to retrieve the current and previous date ranges required for calculating
 * comparison periods.
 *
 * - The `comparisonRange` represents the date range to compare the current period to. It is calculated
 * based on the `currentPeriodStartDate`, `currentPeriodEndDate`, and the `comparisonPeriod` option.
 *
 * - The `comparisonDatesByCurrentDate` is a record of the comparison date keyed by the current date.
 * This serves as a reference for determining which dates match and are compared to obtain the
 * comparison value.
 *
 * - The `comparisonEndDates` holds the end dates for the current and previous comparison periods.
 * This information is unnecessary if the current period is already completed. However, if the current
 * period is still active (i.e., the current date falls within the period), we need to calculate
 * the current period's end date as yesterday. This adjustment is necessary because comparing
 * an incomplete current period to a full period would yield inaccurate total values. Similarly,
 * the previous period's end date should be calculated using the same number of days as the current
 * period. For example, if the current period spans two weeks but today is only halfway through,
 * the previous period's end date should correspond to one week prior.
 */
const useComparisonDates = ({
    comparisonPeriod,
    currentPeriodStartDate,
    currentPeriodEndDate,
}: {
    comparisonPeriod: TComparisonPeriodOption;
    currentPeriodStartDate: MomentInput;
    currentPeriodEndDate: MomentInput;
}) => {
    const comparisonRange = useMemo<ComparisonRange>(
        () =>
            comparisonPeriod
                ? calculateComparisonPeriod(
                      currentPeriodStartDate,
                      currentPeriodEndDate,
                      comparisonPeriod,
                  )
                : { previousDateStart: undefined, previousDateEnd: undefined },
        [currentPeriodStartDate, currentPeriodEndDate, comparisonPeriod],
    );

    const comparisonDatesByCurrentDate = useMemo<ComparisonDatesKeyedByCurrentDate>(() => {
        const currentPeriodDates = getDaysBetweenDates(
            currentPeriodStartDate,
            currentPeriodEndDate,
        );
        const comparisonPeriodDates = getDaysBetweenDates(
            comparisonRange.previousDateStart,
            comparisonRange.previousDateEnd,
        );

        return Object.fromEntries(
            comparisonPeriodDates.map((date, index) => [currentPeriodDates[index], date]),
        );
    }, [comparisonRange, currentPeriodStartDate, currentPeriodEndDate]);

    const comparisonEndDates = useMemo(() => {
        const isActiveDateRange = moment(currentPeriodEndDate).isSameOrAfter(moment(), 'day');
        const numberOfComparisonDays = Math.abs(
            isActiveDateRange
                ? moment(currentPeriodStartDate).diff(moment(), 'days')
                : moment(currentPeriodStartDate).diff(moment(currentPeriodEndDate), 'days'),
        );
        return {
            currentComparisonTotalEndDate: isActiveDateRange
                ? moment().subtract(1, 'days').format('YYYY-MM-DD')
                : moment(currentPeriodEndDate).format('YYYY-MM-DD'),
            previousComparisonTotalEndDate: isActiveDateRange
                ? moment(comparisonRange?.previousDateStart)
                      .add(numberOfComparisonDays - 1, 'days')
                      .format('YYYY-MM-DD')
                : moment(comparisonRange?.previousDateEnd).format('YYYY-MM-DD'),
        };
    }, [currentPeriodStartDate, currentPeriodEndDate, comparisonRange]);

    return { comparisonRange, comparisonDatesByCurrentDate, comparisonEndDates };
};

export const useSharedComparisonChartContextValues = ({
    settings,
    initialComparisonSettings,
}: {
    settings: {
        dateStart: MomentInput;
        dateEnd: MomentInput;
        precision?: string; // retailer level and spark charts
        frequency?: string; // market level vendor chart
    };
    initialComparisonSettings?: InitialComparisonWindowState;
}) => {
    const currentPeriodLabel = useMemo(() => {
        return getDateRangeLabel(settings.dateStart, settings.dateEnd);
    }, [settings.dateStart, settings.dateEnd]);

    const comparisonWindowValues = useComparisonWindowState({
        initialState: initialComparisonSettings,
        precision: settings.precision ?? settings.frequency,
    });

    const comparisonDatesValues = useComparisonDates({
        comparisonPeriod: comparisonWindowValues.comparisonPeriod,
        currentPeriodStartDate: settings.dateStart,
        currentPeriodEndDate: settings.dateEnd,
    });

    return {
        ...comparisonWindowValues,
        ...comparisonDatesValues,
        currentPeriodLabel,
    };
};

/**
 * @description
 *
 * This function is used to calculate the comparison value for a specified period. We need this to account
 * for a couple different cases:
 *
 * 1. If the current range is an active range, we need to calculate the number of days from the start period to yesterday.
 *    This is done for both the current and comparison periods because while the comparison period is always a completed,
 *    The active period might mean that we only want to compare to the same number of comparable days as the current period.
 *
 * 2. If the metric is a non-cumulative metric, like order average or units per transaction, we can just return the value.
 *
 */
const generateComparisonValueFromSpecifiedPeriod = ({
    summedLocationBuckets,
    metric,
    numberOfDays,
}: {
    summedLocationBuckets?: Record<string, number>;
    metric: TChartDataMetric;
    // If the current range is an active range, this is the number of days from the start period to yesterday
    numberOfDays: number;
}): number => {
    try {
        if (isEmpty(summedLocationBuckets)) {
            return 0;
        }

        /**
         * Calculate number of days based on number of days from today or
         * the number of chart data points
         */
        const currentPosition = Math.min(
            // Active range
            numberOfDays,
            // Completed range
            Object.keys(summedLocationBuckets).length - 1,
        );

        /**
         * For non-cumulative metrics, like order average and units per transaction, we can just return
         * the value. Otherwise, we will continue on to calculate the cumulative value.
         */
        if (!isCumulativeChartMetric(metric)) {
            return Object.values(summedLocationBuckets)[currentPosition] ?? 0;
        }

        return Object.entries(summedLocationBuckets)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .reduce<number>((res, [, bucketValue], i) => {
                if (i <= currentPosition) {
                    return res + bucketValue;
                }

                return res;
            }, 0);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }

    return 0;
};

/**
 * In contrast to `useSharedComparisonChartContextValues` which stores more state-specifc values, this hook
 * `useCalculatedComparisonChartContextValues` stores the data that is calculated from the state values AND
 * necessary calculations from the query data associated with them.
 */
export const useCalculatedComparisonChartContextValues = ({
    showComparisonPeriod,
    allQueryDataIsReady,
    settings,
    currentCloudChartData,
    comparisonCloudChartData,
}: {
    showComparisonPeriod: boolean;
    allQueryDataIsReady: boolean;
    settings: IChartDataSettings;
    currentCloudChartData: FullChartResponseBody;
    comparisonCloudChartData?: FullChartResponseBody;
}) => {
    /**
     * Because the `AdvancedComparisonChart` is expecting the summed bucket totals for all the locations, but the
     * cloud chart data is bucketed by location, we need to sum the buckets for each location to get the total for
     * the charts. Ideally this should be moved to the backend, and we may want an endpoint specific to comparison
     * window charts, but this is the workaround for now.
     */
    const periodBuckets = useMemo<BucketsKeyedByLabel>(
        () =>
            omitBy(
                {
                    currentPeriod: sumLocationBucketsFromChartResponse(
                        currentCloudChartData,
                        settings.metric,
                        settings.breakdown,
                    ),
                    previousPeriod:
                        showComparisonPeriod && comparisonCloudChartData
                            ? sumLocationBucketsFromChartResponse(
                                  comparisonCloudChartData,
                                  settings.metric,
                                  settings.breakdown,
                              )
                            : {},
                },
                isEmpty,
            ),
        [showComparisonPeriod, currentCloudChartData, comparisonCloudChartData],
    );

    /**
     * Sometimes we don't have enough historical data for the comparison period, that it's worth showing. So
     * here we check if the comparison period has enough data to be useful (75% of the dates have sales).
     * */
    const hasNoHistoricalSalesData = useMemo(() => {
        return showComparisonPeriod && allQueryDataIsReady
            ? (comparisonCloudChartData?.datePercentage ?? 0) < 0.75
            : false;
    }, [showComparisonPeriod, allQueryDataIsReady, comparisonCloudChartData?.datePercentage]);

    const comparisonValues = useMemo(() => {
        /*
         * NOTE: Comparison windows only compare against completed days, so
         * this function subtracts 1 day from `numberOfDays`
         * */
        const numberOfDays = moment().diff(settings.dateStart, 'days') - 1;
        // The total include today
        const currentValue = currentCloudChartData.total;

        const comparisonValue = generateComparisonValueFromSpecifiedPeriod({
            summedLocationBuckets: periodBuckets?.currentPeriod ?? {},
            metric: settings.metric,
            numberOfDays,
        });

        const previousValue = showComparisonPeriod
            ? generateComparisonValueFromSpecifiedPeriod({
                  summedLocationBuckets: periodBuckets?.previousPeriod ?? {},
                  metric: settings.metric,
                  numberOfDays,
              })
            : -1;

        return {
            currentValue,
            comparisonValue,
            previousValue,
            previousTotalValue: comparisonCloudChartData?.total ?? -1,
        };
    }, [allQueryDataIsReady, showComparisonPeriod, settings, periodBuckets]);

    return {
        hasNoHistoricalSalesData,
        comparisonValues,
        periodBuckets,
    };
};
interface ComparisonChartProviderProps {
    retailerAccountId: string;
    initialComparisonSettings?: InitialComparisonWindowState;
    children: ReactNode;
}

/**
 * Do NOT use this Provider as an example for new chart providers. This is a deprecated pattern.
 * Use the `VendorChartContext` as an example for new chart providers.
 */
const ContextComparisonChartProvider: FC<ComparisonChartProviderProps> = ({
    retailerAccountId,
    initialComparisonSettings,
    children,
}) => {
    const {
        chartIsReady: currentPeriodChartIsReady,
        chartSettings: settings,
        cloudChartData,
    } = useChartContext();

    const {
        comparisonPeriod,
        setComparisonPeriod,
        showComparisonWindows,
        setShowComparisonWindows,
        comparisonRange,
        comparisonDatesByCurrentDate,
        currentPeriodLabel,
    } = useSharedComparisonChartContextValues({ settings, initialComparisonSettings });

    const { previousDateStart, previousDateEnd } = comparisonRange;
    const { isFetched: comparisonPeriodIsFetched, data: comparisonPeriodCloudChartData } =
        useCloudChartDataQuery(
            retailerAccountId,
            {
                ...settings,
                dateStart: previousDateStart,
                dateEnd: previousDateEnd,
            },
            Boolean(showComparisonWindows && previousDateStart && previousDateEnd),
            settings.breakdown,
        );

    const chartIsReady =
        currentPeriodChartIsReady && (showComparisonWindows ? comparisonPeriodIsFetched : true);

    const calculatedComparisonChartValues = useCalculatedComparisonChartContextValues({
        showComparisonPeriod: showComparisonWindows,
        allQueryDataIsReady: chartIsReady,
        settings,
        currentCloudChartData: cloudChartData as FullChartResponseBody,
        comparisonCloudChartData: comparisonPeriodCloudChartData as FullChartResponseBody,
    });

    const value = useMemo<ComparisonChartContextValue>(
        () => ({
            comparisonChartIsReady: chartIsReady,
            comparisonPeriod,
            setComparisonPeriod,
            showComparisonWindows,
            setShowComparisonWindows,
            comparisonRange,
            comparisonDatesByCurrentDate,
            comparisonPeriodCloudChartData: comparisonPeriodCloudChartData ?? {},
            currentPeriodLabel,
            ...calculatedComparisonChartValues,
        }),
        [
            chartIsReady,
            comparisonPeriod,
            showComparisonWindows,
            comparisonRange,
            comparisonPeriodCloudChartData,
            currentPeriodLabel,
            calculatedComparisonChartValues,
        ],
    );

    return (
        <ComparisonChartContext.Provider value={value}>{children}</ComparisonChartContext.Provider>
    );
};

export const ComparisonChartProvider = ({
    retailerAccountId,
    initialSettings,
    initialComparisonSettings,
    children,
}: {
    retailerAccountId: string;
    initialSettings: IChartDataSettings;
    initialComparisonSettings?: InitialComparisonWindowState;
    children: ReactNode;
}) => {
    return (
        <ChartProvider retailerAccountId={retailerAccountId} initialSettings={initialSettings}>
            <ContextComparisonChartProvider
                retailerAccountId={retailerAccountId}
                initialComparisonSettings={initialComparisonSettings}
            >
                {children}
            </ContextComparisonChartProvider>
        </ChartProvider>
    );
};
