import SmileEmptyBookGraphic from './SmilingEmptyBook.svg?react';
import { FC } from 'react';

import { WalletEmployeeDeposit } from '@sparkplug/lib';

import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import MobileList from '@components/layout/MobileList';

import { sortByDate } from '@helpers/ui';

import { RewardsListItem } from './RewardsListItem';

interface RewardsListProps {
    unclaimedRewards: WalletEmployeeDeposit[];
    claimedRewards: WalletEmployeeDeposit[];
    onClick: (deposit: WalletEmployeeDeposit) => void;
    isLoading?: boolean;
}
export const RewardsList: FC<RewardsListProps> = ({
    unclaimedRewards = [],
    claimedRewards = [],
    onClick,
    isLoading = false,
}) => {
    const hasUnclaimedRewards = !!unclaimedRewards.length;
    const hasClaimedRewards = !!claimedRewards.length;

    if (!hasUnclaimedRewards && !hasClaimedRewards) {
        return (
            <div className="wallet-rewards-list">
                <EmptyStateDisplay
                    graphic={<SmileEmptyBookGraphic />}
                    label="Your rewards will appear here!"
                />
            </div>
        );
    }

    const sortedClaimedRewards = claimedRewards.sort(sortByDate('claimedAt', 'desc'));

    return (
        <div className="wallet-rewards-list">
            {hasUnclaimedRewards && (
                <MobileList header="unclaimed" isLoading={isLoading}>
                    {unclaimedRewards.map((unclaimedReward) => (
                        <RewardsListItem
                            key={unclaimedReward._id}
                            reward={unclaimedReward}
                            onClick={() => onClick(unclaimedReward)}
                        />
                    ))}
                </MobileList>
            )}

            {!isLoading && hasClaimedRewards && (
                <MobileList header="claimed">
                    {sortedClaimedRewards.map((claimedReward) => (
                        <RewardsListItem
                            key={claimedReward._id}
                            reward={claimedReward}
                            onClick={() => onClick(claimedReward)}
                        />
                    ))}
                </MobileList>
            )}
        </div>
    );
};
