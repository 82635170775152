import { UserSmsStatus } from '@sparkplug/lib';

import { TStandardColors } from '@app/types/UITypes';

export const AccountUserRoles = [
    { label: 'Admin', value: 'group-admin' },
    { label: 'User', value: 'group-member' },
    { label: 'Inactive', value: 'none' },
];

export const SparkPlugUserRoles = [
    { label: 'Super Admin', value: 'super-admin' },
    { label: 'Retailer Admin', value: 'retailer-admin' },
    { label: 'Brand Admin', value: 'brand-admin' },
    { label: 'None', value: 'none' },
];

export const SMSStatuses: {
    label: string;
    color: TStandardColors;
    value: UserSmsStatus | 'muted';
    tooltipText: string;
}[] = [
    {
        label: 'Enrolled',
        color: 'green',
        value: 'enrolled',
        tooltipText: 'Employee has successfully enrolled in SparkPlug',
    },
    {
        label: 'Pending',
        color: 'yellow',
        value: 'pending',
        tooltipText: 'An enrollment invitation has been sent, but they have yet to accept',
    },
    {
        label: 'Not Enrolled',
        color: 'neutral',
        value: 'none',
        tooltipText: 'Employee is missing a valid phone number',
    },
    {
        label: 'Blocked',
        color: 'red',
        value: 'blocked',
        tooltipText: 'Employee must re-enroll by texting "START" to 36809 to receive messages',
    },
    {
        label: 'Enrolled',
        color: 'green',
        value: 'muted',
        tooltipText: 'Employee has opted out of receiving SMS notifications',
    },
];
