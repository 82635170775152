import { useMemo } from 'react';

import { Spark } from '@sparkplug/lib';

import { useAccountUsersQuery } from '@core/users';

import { useAccountPosDataQuery } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import {
    generateSparkCommissionMap,
    generateSparkCommissionMapForArchivedSpark,
    generateSparkPosData,
    hydrateSparkPosArchive,
} from '@helpers/sparks';

import { useSparkPosArchive } from './SparksHooks';

export const useSparkPosData = ({ spark, isEnabled }: { spark: Spark; isEnabled: boolean }) => {
    const isArchivedSpark = !!spark.archivedAt;

    const { isLoading: isLoadingSparkPosArchive, data: sparkPosArchive } = useSparkPosArchive(
        spark._id,
        isArchivedSpark,
    );

    const {
        accountPosDataIsReady: accountPosEmployeeProfileAndLocationDataIsReady,
        accountAllPosLocations,
    } = useAccountPosDataQuery(spark.groupId, {
        includedDatasets: ['locations'],
        isEnabled: isEnabled && !isArchivedSpark,
    });

    const { accountPosDataIsReady, accountPosBrands, accountPosCategories, accountPosProducts } =
        useAccountPosDataQuery(
            spark.groupId,
            {
                includedDatasets: ['products', 'brands', 'categories'],
                isEnabled:
                    isEnabled &&
                    accountPosEmployeeProfileAndLocationDataIsReady &&
                    !isArchivedSpark,
            },
            spark.posProductIds,
        );

    const sparkPosDataIsReady = useMemo(() => {
        return (
            (isEnabled &&
                accountPosDataIsReady &&
                accountPosEmployeeProfileAndLocationDataIsReady) ||
            (isArchivedSpark && !isLoadingSparkPosArchive)
        );
    }, [
        isEnabled,
        accountPosDataIsReady,
        accountPosEmployeeProfileAndLocationDataIsReady,
        isArchivedSpark,
        isLoadingSparkPosArchive,
    ]);

    const { accountUsers: retailerAccountUsers } = useAccountUsersQuery(spark?.groupId, true);

    const sparkPosData = useMemo(() => {
        if (
            (!isArchivedSpark && !accountPosDataIsReady) ||
            (isArchivedSpark && isLoadingSparkPosArchive)
        ) {
            return {
                locations: [],
                participants: [],
                brands: [],
                categories: [],
                products: [],
                associatedProducts: [],
            };
        }

        if (isArchivedSpark) {
            return hydrateSparkPosArchive(sparkPosArchive, spark);
        }

        return generateSparkPosData(
            spark,
            accountAllPosLocations,
            retailerAccountUsers,
            accountPosBrands,
            accountPosCategories,
            accountPosProducts,
        );
    }, [
        spark,
        accountPosDataIsReady,
        retailerAccountUsers,
        accountAllPosLocations,
        accountPosBrands,
        accountPosCategories,
        accountPosProducts,

        // Finalized spark
        isArchivedSpark,
        isLoadingSparkPosArchive,
        sparkPosArchive,
    ]);

    const sparkCommissionMap = useMemo(() => {
        if (isArchivedSpark) {
            return !isLoadingSparkPosArchive && sparkPosArchive
                ? generateSparkCommissionMapForArchivedSpark(spark, sparkPosArchive?.products)
                : undefined;
        } else {
            return accountPosDataIsReady
                ? generateSparkCommissionMap(spark, accountPosProducts || [])
                : undefined;
        }
    }, [
        spark,
        isLoadingSparkPosArchive,
        sparkPosArchive,
        accountPosDataIsReady,
        accountPosProducts,
    ]);

    return {
        sparkPosDataIsReady,
        sparkPosData,
        sparkCommissionMap,
    };
};
