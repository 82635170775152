import { FC, useMemo } from 'react';

import { DetailedSparkType } from '@sparkplug/lib';

import CalloutMessage from '@components/layout/CalloutMessage';

import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import { countRetailersAndLocations, renderSparkCountMessage } from './helpers';

import './SparkCountCalloutMessage.scss';

interface SparkCountCalloutMessageProps {}

const getParticipantMessage = (detailedSparkType: DetailedSparkType) => {
    const messages: Partial<Record<DetailedSparkType, string>> = {
        goalTeam:
            'To create separate Team Goals for multiple locations, create a new Team Goal Spark for each.',
        goalManager:
            'To create separate Manager Goals for multiple locations, create a new Manager Goal Spark for each.',
    };

    return messages[detailedSparkType];
};

const SparkCountCalloutMessage: FC<SparkCountCalloutMessageProps> = () => {
    const { detailedSparkType, spark, multiRetailerParticipantGroups } = useSpark();

    const { numberOfRetailers, numberOfLocations } = useMemo(
        () => countRetailersAndLocations(multiRetailerParticipantGroups),
        [multiRetailerParticipantGroups],
    );

    if (!detailedSparkType || !numberOfRetailers || !numberOfLocations) {
        return <></>;
    }
    const message = renderSparkCountMessage(
        detailedSparkType,
        numberOfRetailers,
        numberOfLocations,
    );
    switch (spark.type) {
        case 'leaderboard':
            return <CalloutMessage message={message} />;
        case 'goal':
            return (
                <CalloutMessage
                    message={
                        <>
                            {message} {getParticipantMessage(detailedSparkType)}
                        </>
                    }
                />
            );
        case 'commission':
            return <CalloutMessage message={message} />;

        default:
            return <></>;
    }
};

export default SparkCountCalloutMessage;
