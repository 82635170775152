import { FC, useMemo } from 'react';

import { FullChartResponseBody, Spark } from '@sparkplug/lib';

import CommissionCommissionsFormFields from '@features/spark-wizard/components/CommissionCommissionsFormFields';

import { SparkProvider } from '@contexts/SparkContext';

import Form from '@components/form/Form';
import PageLoading from '@components/layout/PageLoading';
import Modal from '@components/overlays/Modal';

import { useCloudChartDataQuery } from '@hooks/ChartDataHooks';
import { useModal } from '@hooks/ModalHooks';
import { useAccountPosDataQuery } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';

interface MissingCommissionModalProps {
    sparkId: string;
    onClose: (shouldRefresh: boolean) => void;
    isVisible: boolean;
    isVendorSparkAndCanEdit?: boolean;
}

const MissingCommissionModal: FC<MissingCommissionModalProps> = ({ onClose }) => {
    const { stageValidationFn } = useModal();
    const { saveSpark, sparkIsReady } = useSpark();

    const handleSave = async () => {
        const valid = stageValidationFn();
        if (valid) {
            stageValidationFn(async (updatedSparkProperties) => {
                await saveSpark('edit', updatedSparkProperties);
                onClose(true);
            });
        }
    };

    return sparkIsReady ? (
        <>
            <Modal.Title onClose={() => onClose(false)}>Edit Commission Spark</Modal.Title>
            <Modal.Content>
                <CommissionCommissionsFormFields isVendorEditExperience />
            </Modal.Content>
            <Modal.Actions>
                <Form.Button color="blue" onClick={onClose}>
                    Cancel
                </Form.Button>
                <Form.Button color="blue" onClick={handleSave}>
                    Save
                </Form.Button>
            </Modal.Actions>
        </>
    ) : (
        <PageLoading label="Loading Spark..." />
    );
};

export const useProductsWithSales = ({
    spark,
    isEnabled,
}: {
    spark: Spark;
    isEnabled: boolean;
}) => {
    const { data = {} } = useCloudChartDataQuery(
        spark.groupId,
        {
            dateStart: spark.startDate,
            dateEnd: spark.endDate,
            precision: 'day',
            locationIds: spark.locationIds, //  We don't need to consider multi-leaderboards here because they are not possible with commission sparks
            sparkId: spark._id,
            metric: spark.metric, //  This will always be `total_units` because this is a commission spark
        },
        isEnabled,
    );
    const chartResponse = data as Partial<FullChartResponseBody>;
    const { accountPosProductsDataIsReady, accountPosProducts } = useAccountPosDataQuery(
        spark.groupId,
        {
            includedDatasets: ['products'],
            isEnabled,
        },
    );

    const productsWithSales = useMemo(() => {
        const productInternalKeysWithSales = chartResponse?.productsWithSales?.length
            ? chartResponse.productsWithSales
            : Object.keys(chartResponse?.productBuckets ?? {});
        const productsWithSalesRecord = Object.fromEntries(
            productInternalKeysWithSales.map((internalKey) => [internalKey, true]),
        );
        const associatedProducts = accountPosProducts.filter(
            ({ internalKey }) => productsWithSalesRecord?.[internalKey],
        );

        return associatedProducts;
    }, [chartResponse.productsWithSales, accountPosProducts]);

    return { productsWithSalesAreReady: accountPosProductsDataIsReady, productsWithSales };
};

export default ({ sparkId, isVisible, onClose }: MissingCommissionModalProps) => {
    return (
        <Modal
            isVisible={isVisible}
            className="spark-loading-modal"
            onClose={onClose}
            maxWidth={2000}
        >
            <SparkProvider sparkId={sparkId}>
                <Form>
                    <MissingCommissionModal
                        isVisible={isVisible}
                        onClose={onClose}
                        sparkId={sparkId}
                    />
                </Form>
            </SparkProvider>
        </Modal>
    );
};
