import { DayNameFromNumber } from '@constants/SparkConstants';

import { DayNumber, RecurringScheduleOption } from '@sparkplug/lib';

type TGCSWDOptions = {
    isShort?: boolean;
    withAnd?: boolean;
};

export const getCommaSeparatedWeekDays = (
    days: DayNumber[] = [],
    options: TGCSWDOptions = { isShort: false, withAnd: false },
) => {
    const { isShort, withAnd } = options;

    if (days.length === 0) {
        return '';
    }

    const daysList = days.map((day) => {
        if (isShort) {
            return DayNameFromNumber[day].short;
        }

        return DayNameFromNumber[day].long;
    });

    if (daysList.length === 1) {
        return daysList[0];
    }

    const lastDay = daysList.pop();

    if (withAnd) {
        return `${daysList.join(', ')}, and ${lastDay}`;
    }

    return `${daysList.join(', ')}, ${lastDay}`;
};

type GISOptions = {
    capitalize?: boolean;
};

export const getIntervalString = (
    interval: RecurringScheduleOption,
    options: GISOptions = { capitalize: false },
) => {
    const { capitalize } = options;

    const intervalString = interval.replace(/_/g, '-');

    if (capitalize) {
        return intervalString.charAt(0).toUpperCase() + intervalString.slice(1);
    }

    return intervalString;
};
