import { ParticipantTypeOption } from '../types';

export const leaderboardParticipantTypeOptions: ParticipantTypeOption[] = [
    {
        label: 'Single Leaderboard',
        description: (
            <>
                Employees across all selected locations will compete on a single leaderboard.
                <br />
                Only 1 set of winners.
            </>
        ),
        value: 'leaderboard',
    },
    {
        label: 'Multi-Leaderboard',
        description:
            'A leaderboard is created for each selected location. Each location has its own set of winners.',
        value: 'leaderboardMulti',
    },
    {
        label: 'Location Leaderboard',
        description:
            'Stores compete as a team against other stores. All employees are rewarded top prizes',
        value: 'leaderboardLocation',
    },
];

export const goalDetailedSparkTypeOptions = (managerSparksEnabled: boolean) => {
    const goalOptions: ParticipantTypeOption[] = [
        {
            label: 'Individual Goal',
            description: 'Set sales goals and reward individual employees for their personal sales',
            value: 'goal',
        },
        {
            label: 'Team Goal',
            description:
                'Set large sales goals for the whole team to work towards. Total sales from the selected locations will be added together.',
            value: 'goalTeam',
        },
        {
            disabled: !managerSparksEnabled,
            label: 'Manager Goal',
            description:
                'Set sales goals only visible to store managers at the selected locations. Total sales from the selected locations will be added together.',
            value: 'goalManager',
        },
    ];

    return goalOptions;
};
