import { FC } from 'react';

import PageLoading from '@components/layout/PageLoading';
import { SparkplugLogo } from '@components/logos';
import Modal from '@components/overlays/Modal';

import { useApp } from '@hooks/AppHooks';

import './AccountLoadingView.scss';

const AccountLoadingView: FC<{}> = () => {
    const { userIsAdmin, appIsMobile } = useApp();

    return (
        <div>
            <PageLoading label="Loading account..." />

            <Modal
                className="account-loading-overlay"
                isVisible={!userIsAdmin && appIsMobile}
                onClose={() => {}}
            >
                <Modal.Content>
                    <div className="sparkplug-logo">
                        <SparkplugLogo />
                    </div>
                    <PageLoading label="Loading account..." />
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default AccountLoadingView;
