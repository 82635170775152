import { FC } from 'react';

import { ResponsiveBar } from '@nivo/bar';

import { formatAxisLeftValue } from '@helpers/charts';
import { hexToRGBArray } from '@helpers/ui';

import { TChartDataBreakdown } from '@app/types/ChartDataTypes';
import { TObject } from '@app/types/UtilTypes';

import { ComparisonColors, DefaultChartColors } from './Nivo.Constants';
import Tooltip from './NivoBarTooltip';

type TNivoBarChartProps = {
    stacked?: boolean;
    xLabel?: string;
    yLabel?: string;
    data: any;
    focusedId?: string;
    hiddenIds?: string[];
    visibleIds?: string[];
    tooltipProps: {
        isComparisonWindow?: boolean;
        formatter: any;
        totalIsSum: boolean;
        locationsMap: TObject;
        breakdown: TChartDataBreakdown;
    };
};

const toNonFocusedColor = (hex: string): string => {
    const rgb = hexToRGBArray(hex);
    const alpha = 0.1;
    return `rgba(${rgb.join(', ')}, ${alpha})`;
};

const NivoBarChart: FC<TNivoBarChartProps> = ({
    stacked = true,
    xLabel,
    yLabel,
    data,
    focusedId,
    hiddenIds,
    visibleIds = [],
    tooltipProps,
}) => {
    const { isComparisonWindow = false, formatter } = tooltipProps;

    const hasValidKeys = Array.isArray(data?.keys);
    const hasValidData = Array.isArray(data?.data);

    if (!hasValidKeys || !hasValidData) {
        return (
            <div className="chart-message">
                <span>Invalid chart data</span>
            </div>
        );
    }

    const maxValues = window.innerWidth < 767 ? 5 : 15;

    const axisBottomValues = data?.data ? data.data.map((obj: { id: string }) => obj.id) : [];
    const modBy = Math.max(1, Math.floor(axisBottomValues.length / maxValues));

    const tickValues = axisBottomValues.filter((v: any, i: number) => {
        return i % modBy === 0;
    });

    const keys = data.keys.filter((id: string) => !hiddenIds?.includes(id)) ?? data.keys;

    const fallbackColors = isComparisonWindow ? ComparisonColors : DefaultChartColors;

    const colors =
        data?.colors && !isComparisonWindow
            ? ({ id }: { id: string }) => {
                  if (focusedId && !hiddenIds?.includes(focusedId)) {
                      return focusedId === id
                          ? data.colors[id]
                          : toNonFocusedColor(data.colors[id]);
                  }

                  return data.colors[id];
              }
            : fallbackColors;

    return (
        <ResponsiveBar
            animate={false}
            enableLabel={false}
            data={data.data}
            groupMode={stacked ? 'stacked' : 'grouped'}
            keys={keys}
            indexBy="id"
            margin={{
                top: 4,
                right: 0,
                bottom: 30,
                left: 60,
            }}
            padding={0.3}
            colors={colors as any}
            borderColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                tickValues,
                legend: xLabel,
                legendPosition: 'middle',
                legendOffset: 32,
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: yLabel,
                legendPosition: 'middle',
                legendOffset: -40,
                format: formatAxisLeftValue(formatter),
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="white"
            tooltip={Tooltip({
                ...tooltipProps,
                visibleIds: visibleIds.filter((id) => !hiddenIds?.includes(id)),
            })}
            legends={[]}
        />
    );
};

export default NivoBarChart;
