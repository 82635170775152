import List from '@components/layout/List';
import Paper from '@components/layout/Paper';
import Skeleton from '@components/layout/Skeleton';

import './SparkDetailCard.scss';

const TypeValues = [
    {
        label: 'Total Sales',
        value: 'total_sales',
    },
    {
        label: 'Total Units',
        value: 'total_units',
    },
    {
        label: 'Order Average',
        value: 'order_average',
    },
    {
        label: 'Transactions',
        value: 'transaction_count',
    },
    {
        label: 'Units Per Transaction',
        value: 'units_per_transaction',
    },
];

export const getTypeLabel = (value: string) => {
    for (let i = 0; i < TypeValues.length; i += 1) {
        if (TypeValues[i].value === value) {
            return TypeValues[i].label;
        }
    }
    return value;
};

const SparkDetailCard = ({
    title,
    rows = [],
    isLoading = false,
}: {
    title: string;
    rows: any[];
    isLoading?: boolean;
}) => {
    if (isLoading) {
        return <Skeleton width="100%" height={120} />;
    }

    if (!isLoading && !rows.length) {
        return <></>;
    }

    return (
        <div className="spark-detail-card">
            <Paper>
                <h2>{title}</h2>
                <List className="list">
                    {rows.map((row, idx) => {
                        return <List.Item key={`${idx}`}>{row.label}</List.Item>;
                    })}
                </List>
            </Paper>
        </div>
    );
};

export default SparkDetailCard;
