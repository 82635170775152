import { FC } from 'react';

import { SaveAccountUserRoleRequestBody } from '@sparkplug/lib';

import { useSaveAccountUserRoleMutation } from '@core/users';

import Button from '@components/buttons/Button';
import TextField from '@components/inputs/TextField';
import CalloutMessage from '@components/layout/CalloutMessage';
import Modal, { IDefaultModalProps } from '@components/overlays/Modal';

import './AccountUserConflictModal.scss';

interface AccountUserConflictModalProps extends IDefaultModalProps {
    onBack: () => void;
    payload?: SaveAccountUserRoleRequestBody;
}

const AccountUserConflictModal: FC<AccountUserConflictModalProps> = ({
    isVisible,
    onClose,
    onBack,
    payload = {
        groupId: 'groupid',
        role: 'group-admin',
        email: 'test@example.com',
        userIdToDelete: 'mockUserId',
        permissions: {},
    },
}) => {
    const { saveAccountUserRole } = useSaveAccountUserRoleMutation();

    if (!payload) {
        return <></>;
    }

    const onConfirmConflict = () => {
        saveAccountUserRole(payload, { onSuccess: () => onClose(true) });
    };

    return (
        <Modal
            size="narrow"
            className="account-user-conflict-modal"
            isVisible={isVisible}
            onClose={() => onClose(false)}
        >
            <Modal.Title onClose={() => onClose(false)}>Link Admin Accounts</Modal.Title>
            <div className="callout-wrapper">
                <CalloutMessage
                    color="yellow"
                    message="The email address you entered is currently associated with an Admin at another account. Would you like to move forward with linking these accounts?"
                />
            </div>
            <Modal.Content>
                <div>
                    <p>
                        <strong>How linked Admin accounts work:</strong>
                    </p>
                    <ul className="styled">
                        <li>
                            This person will be able login to multiple accounts using the email
                            address below.
                        </li>
                        <li>This person will be able to switch between account dashboards.</li>
                        <li>
                            Linked Admin accounts cannot be associated with POS Employee profiles,
                            so any associations will be removed and require a separate user
                            re-enrollment with a phone number.
                        </li>
                    </ul>
                </div>
                <TextField
                    disabled
                    label="Email"
                    name="email"
                    data-testid="conflict-confirm-email"
                    value={payload.email}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button color="neutral" variant="flat" onClick={() => onBack()}>
                    Back
                </Button>
                <Button color="blue" variant="smooth" disabled={false} onClick={onConfirmConflict}>
                    Link Accounts
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default AccountUserConflictModal;
