import { ReactNode, createContext, useContext, useMemo, useState } from 'react';

import { useSparkplugAccountUsers } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { noop } from '@helpers/util';

import { IAccountUser } from '@app/types/UsersTypes';

import { SelfServiceQueueDrawer } from './SelfServiceQueueDrawer';

export interface ISelfServiceContext {
    isReady: boolean;
    allUsers: IAccountUser[];
    unmatchedUsers: IAccountUser[];
    refetchAllUsers: () => void;
    openOnboardingModal: () => void;
}

export const SelfServiceQueueContext = createContext<ISelfServiceContext>({
    isReady: false,
    allUsers: [],
    unmatchedUsers: [],
    refetchAllUsers: noop,
    openOnboardingModal: noop,
});

export const SelfServiceQueueProvider = ({ children }: { children: ReactNode }) => {
    const [showOnboardingModal, setShowOnboardingModal] = useState<boolean>(false);

    const { accountUsers, accountUsersAreReady, refetchAccountUsers } = useSparkplugAccountUsers();

    const selfServiceQueueState: ISelfServiceContext = useMemo(() => {
        const unmatchedUsers = accountUsers.filter(({ role }) => role === 'unmatched');
        return {
            isReady: accountUsersAreReady,
            allUsers: accountUsers,
            unmatchedUsers,
            refetchAllUsers: refetchAccountUsers,
            openOnboardingModal: () => setShowOnboardingModal(true),
        };
    }, [accountUsers, accountUsersAreReady, refetchAccountUsers]);

    return (
        <SelfServiceQueueContext.Provider value={selfServiceQueueState}>
            {children}
            <SelfServiceQueueDrawer
                isVisible={showOnboardingModal}
                onClose={() => setShowOnboardingModal(false)}
            />
        </SelfServiceQueueContext.Provider>
    );
};

export const useSelfServiceQueueState = (): ISelfServiceContext => {
    const selfServiceQueueContext = useContext(SelfServiceQueueContext);

    if (!selfServiceQueueContext) {
        throw new Error(
            'useSelfServiceQueueState must be used inside of the SelfServiceQueueProvider',
        );
    }

    return selfServiceQueueContext;
};
