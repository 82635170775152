import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { ThemeProvider } from '@mui/material/styles';
import 'reset-css';

import { AppProvider } from '@contexts/AppContext';
import { QueryClientProvider } from '@contexts/QueryContext';

import { Toaster } from '@components/toast';

import { muiTheme } from '@app/styles/theme';

import AppRouter from './AppRouter';

import './App.scss';

function App() {
    return (
        <QueryClientProvider>
            <ThemeProvider theme={muiTheme}>
                <AppProvider>
                    <div className="sparkplug-app">
                        <AppRouter />
                    </div>
                    <Toaster position="top-center" toastOptions={{ duration: 4000 }} />
                </AppProvider>
            </ThemeProvider>
        </QueryClientProvider>
    );
}

export default App;
