import { FormControl } from '@mui/material';
import { Emoji } from 'emoji-mart';

import Dropdown from '@components/dropdown/Dropdown';
import InputLabel from '@components/inputs/InputLabel';

import { appendClasses } from '@helpers/ui';

import './EmojiPicker.scss';
import 'emoji-mart/css/emoji-mart.css';

export interface IEmojiPickerProps {
    className?: string;
    color: 'neutral' | 'blue' | 'green' | 'red';
    variant: 'flat' | 'filled' | 'raised' | 'smooth' | 'outlined';
    label?: string;
    value: string;
    required?: boolean;
    onChange: (value: string) => void;
}

const EmojiPicker = ({ className, label, value, onChange, variant, color }: IEmojiPickerProps) => {
    const classes = appendClasses([
        'form-control',
        'emoji-picker',
        `emoji-picker-color-${color}`,
        `emoji-picker-variant-${variant}`,
        className,
    ]);

    return (
        <FormControl className={classes}>
            {label && (
                <InputLabel shrink required>
                    {label}
                </InputLabel>
            )}

            <Dropdown>
                <Dropdown.Button>
                    <Emoji emoji={value} set="apple" size={32} />
                </Dropdown.Button>
                <Dropdown.Popover>
                    <Dropdown.EmojiPicker value={value} onChange={onChange} />
                </Dropdown.Popover>
            </Dropdown>
        </FormControl>
    );
};

EmojiPicker.defaultProps = {
    className: null,
    variant: 'raised',
    color: 'blue',
};

export default EmojiPicker;
