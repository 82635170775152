import { ReactElement, useMemo, useRef, useState } from 'react';

import Button from '@components/buttons/Button';
import Dropdown from '@components/dropdown/Dropdown';
import { DropdownListSelectorProps } from '@components/dropdown/DropdownListSelector/DropdownListSelector';
import { Add as AddIcon } from '@components/icons';
import { TExtendedListItem } from '@components/inputs/ListSelector/ListSelector';
import Spinner from '@components/layout/Spinner';

import { appendClasses, getUniqueLeavesFromList } from '@helpers/ui';

import { IOptionTree } from '@app/types/UITypes';

import { useIsInToolbar } from '../Toolbar/useIsInToolbar';
import ToolbarGroup from '../ToolbarGroup';

import './ToolbarDropdownListSelector.scss';

interface ToolbarDropdownListSelectorProps<T>
    extends Omit<DropdownListSelectorProps<T>, 'list' | 'initialSelected'> {
    label?: string;
    color?: 'neutral' | 'blue' | 'green' | 'red';
    variant?: 'flat' | 'filled' | 'raised' | 'smooth' | 'outlined';
    disabled?: boolean;
    options: IOptionTree<T>[];
    selected: TExtendedListItem<T>[];
    isLoading?: boolean;
    onApply?: (selected: TExtendedListItem<T>[]) => void;
    onSelectionChanged?: (selected: TExtendedListItem<T>[]) => void;
    clear?: {
        active: boolean;
        onClear: () => void;
    };
    allLabel?: boolean;
    addOnComponent?: ReactElement | null;
    bottomLeftComponent?: ReactElement;
    /**
     * @description always show count if there are options
     */
    showCount?: boolean;
    hideToolbar?: boolean;
    /**
     * @description
     *
     * this prop is used if the ListSelector should default as expanded and is
     * primarily only used for the `FilterSegmentToolbar` component
     */
    initializeExpanded?: boolean;
}

const ToolbarDropdownListSelector = <T extends unknown>({
    className,
    label,
    allLabel = false,
    color = 'blue',
    disabled = false,
    variant = 'flat',
    options,
    selected = [],
    onApply,
    clear,
    showCount,
    dynamicOptionProps,
    initializeExpanded,
    isLoading = false,
    ...otherProps
}: ToolbarDropdownListSelectorProps<T>) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const allOptionsCount = useMemo(
        () => (getUniqueLeavesFromList(options) || []).length,
        [options],
    );
    useIsInToolbar({ componentName: 'Toolbar.DropdownListSelector' });
    const [dynamicEditorIsOpen, setDynamicEditorIsOpen] = useState<boolean>(
        !!dynamicOptionProps && !options.length,
    );
    const classNameAppended = appendClasses([className, 'toolbar-dropdown']);
    const listSelectorClassName = appendClasses([`${className}__dropdown-list-selector`]);
    const selectedCount = selected.length;

    let titleText = allLabel ? `All ${label}` : label;
    if (showCount !== undefined) {
        titleText = showCount ? `(${selectedCount}) ${label}` : label;
    } else if (onApply) {
        if (selectedCount !== allOptionsCount) {
            titleText = `(${selectedCount}) ${label}`;
        }
    } else if (selectedCount < allOptionsCount) {
        titleText = `(${selectedCount}) ${label}`;
    }

    return (
        <ToolbarGroup className={classNameAppended}>
            <Dropdown
                initializeExpanded={initializeExpanded ? { buttonRef } : undefined}
                color={color}
                variant={variant}
                disabled={isLoading || disabled}
            >
                <Dropdown.Button
                    buttonRef={buttonRef}
                    clear={clear}
                    hideExpandIcon={isLoading}
                    startIcon={isLoading ? <Spinner size="small" /> : undefined}
                >
                    {titleText}
                </Dropdown.Button>
                <Dropdown.Popover>
                    {dynamicOptionProps && !dynamicEditorIsOpen && (
                        <Button
                            className="toolbar-dropdown-add-option"
                            startIcon={<AddIcon />}
                            disableHover
                            onClick={() => setDynamicEditorIsOpen(true)}
                            variant="outlined"
                        >
                            {dynamicOptionProps?.createButtonLabel ?? 'Create New Filter'}
                        </Button>
                    )}
                    <Dropdown.ListSelector
                        className={listSelectorClassName}
                        list={options}
                        initialSelected={selected}
                        onApply={onApply}
                        dynamicOptionProps={
                            dynamicOptionProps && {
                                ...dynamicOptionProps,
                                setDynamicEditorIsOpen,
                                dynamicEditorIsOpen,
                            }
                        }
                        {...otherProps}
                    />
                </Dropdown.Popover>
            </Dropdown>
        </ToolbarGroup>
    );
};

export default ToolbarDropdownListSelector;
