import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';

import LocalStorage from '@data/LocalStorage';
import { keyBy, uniq } from 'lodash';

import { AccountMarket, AccountMarketLabel } from '@sparkplug/lib';

import { getStateAbbreviationByName } from '@helpers/util';

import { IAccount, IAccountRetailers } from '@app/types/AccountsTypes';

export const LAST_ACTIVE_RETAILER_LOCAL_STORAGE_KEY = 'sparkplug::dashboard::lastActiveRetailerId';

const navigateToFirstMarket = ({
    account,
    handleHistoryPush,
}: {
    account: IAccount;
    handleHistoryPush: (url: string) => void;
}) => {
    const [firstVendorRetailer] = account.retailers ?? [];

    if (firstVendorRetailer) {
        const firstMarketFromMetaData = account.metaData?.markets?.[0];
        const selectedMarket = firstVendorRetailer.markets?.find(
            (market) => getStateAbbreviationByName(market) === firstMarketFromMetaData,
        );
        const retailerMarketAbbreviation = getStateAbbreviationByName(
            selectedMarket || firstVendorRetailer.markets?.[0],
        ).toLowerCase();

        handleHistoryPush(
            `/${account?._id}/dashboard/market/${retailerMarketAbbreviation.toLowerCase()}`,
        );
    }
};

const navigateToRetailer = ({
    accountId,
    retailer,
    setExpandedMarkets,
    handleHistoryPush,
}: {
    accountId: string;
    retailer: IAccountRetailers;
    setExpandedMarkets: Dispatch<SetStateAction<string[]>>;
    handleHistoryPush: (url: string) => void;
}) => {
    const firstRetailerMarket = retailer.markets?.[0];
    if (firstRetailerMarket) {
        setExpandedMarkets((prevValue) => uniq([...prevValue, firstRetailerMarket]));
    }
    LocalStorage.set(LAST_ACTIVE_RETAILER_LOCAL_STORAGE_KEY, retailer._id);
    handleHistoryPush(`/${accountId}/dashboard/${retailer._id}`);
};

const navigateToFirstRetailer = ({
    account,
    setExpandedMarkets,
    handleHistoryPush,
}: {
    account: IAccount;
    setExpandedMarkets: Dispatch<SetStateAction<string[]>>;
    handleHistoryPush: (url: string) => void;
}) => {
    const [firstVendorRetailer] = account.retailers ?? [];

    if (firstVendorRetailer) {
        navigateToRetailer({
            accountId: account?._id,
            retailer: firstVendorRetailer,
            setExpandedMarkets,
            handleHistoryPush,
        });
    }
};

export const useVendorDashboardRedirectHandler = ({
    account,
    retailerIdFromUrl,
    currentMarketAbbreviation,
    currentMarketLabel,
    setExpandedMarkets,
    handleHistoryPush,
}: {
    account: IAccount;
    retailerIdFromUrl?: string;
    currentMarketAbbreviation?: AccountMarket;
    currentMarketLabel?: AccountMarketLabel;
    setExpandedMarkets: Dispatch<SetStateAction<string[]>>;
    handleHistoryPush: (url: string) => void;
}) => {
    const { REACT_APP_VENDOR_MARKET_DASHBOARD = false } = import.meta.env;

    const retailersByAccountId = useMemo(
        () => keyBy(account.retailers ?? [], '_id'),
        [account._id],
    );

    /**
     * This useEffect is to handle the initail load on the root dashboard `/:accountId/dashboard` when
     * there is not a retailer or market and we need to navigate to one. Market if the market dashboard
     * has been release, and Retailer if it has not.
     */
    useEffect(() => {
        if (!account) {
            return;
        }

        if (!currentMarketAbbreviation && !retailerIdFromUrl) {
            if (REACT_APP_VENDOR_MARKET_DASHBOARD === 'true') {
                navigateToFirstMarket({
                    account,
                    handleHistoryPush,
                });
            } else {
                const lastActiveRetailerId = LocalStorage.get<string | undefined>(
                    LAST_ACTIVE_RETAILER_LOCAL_STORAGE_KEY,
                );

                const storedRetailer =
                    !!lastActiveRetailerId && retailersByAccountId[lastActiveRetailerId];

                if (storedRetailer) {
                    navigateToRetailer({
                        accountId: account._id,
                        retailer: storedRetailer,
                        setExpandedMarkets,
                        handleHistoryPush,
                    });
                } else {
                    navigateToFirstRetailer({
                        account,
                        setExpandedMarkets,
                        handleHistoryPush,
                    });
                }
            }
        }
    }, []);

    /**
     * This useEffect is for handling routes when the requested retailer or market does not exist on the current
     * vendor. In that case, we want to redirect to a retailer or market on the current vendor
     */
    useEffect(() => {
        if (!account) {
            return;
        }

        if (currentMarketAbbreviation && currentMarketLabel) {
            if (REACT_APP_VENDOR_MARKET_DASHBOARD === 'true') {
                const vendorDoesNotHaveCurrentMarket =
                    !account?.markets?.includes(currentMarketLabel);
                if (vendorDoesNotHaveCurrentMarket) {
                    navigateToFirstMarket({
                        account,
                        handleHistoryPush,
                    });
                } else {
                    setExpandedMarkets((prevValue) => uniq([...prevValue, currentMarketLabel]));
                }
            }
        } else if (retailerIdFromUrl) {
            const vendorRetailer = retailersByAccountId[retailerIdFromUrl];
            const vendorDoesNotHaveRetailer = !vendorRetailer;

            if (vendorDoesNotHaveRetailer) {
                if (REACT_APP_VENDOR_MARKET_DASHBOARD === 'true') {
                    navigateToFirstMarket({
                        account,
                        handleHistoryPush,
                    });
                } else {
                    navigateToFirstRetailer({
                        account,
                        setExpandedMarkets,
                        handleHistoryPush,
                    });
                }
            } else {
                setExpandedMarkets((prevValue) =>
                    uniq([...prevValue, vendorRetailer.markets?.[0]]),
                );
            }
        }
    }, [
        retailerIdFromUrl,
        account,
        retailersByAccountId,
        currentMarketAbbreviation,
        currentMarketLabel,
    ]);
};
