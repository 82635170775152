import { ReactElement, useMemo } from 'react';

import { sumBy } from 'lodash';
import pluralize from 'pluralize';

import { MultiRetailerSparkParticipantGroup } from '@sparkplug/lib';

import LinkLabel from '@features/account-links/components/LinkLabel';

import CalloutMessage from '@components/layout/CalloutMessage';
import Skeleton from '@components/layout/Skeleton';
import Tabs from '@components/layout/Tabs';

import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import { ISparkCommissionMap } from '@app/types/SparksTypes';

import { useMultiRetailerTabManager } from './useMultiRetailerReviewTabs';
import { useSelectedRetailerCommissionMap } from './useSelectedRetailerCommissionMap';
import { SelectedRetailerPosData, useSelectedRetailerPosData } from './useSelectedRetailerPosData';

import './MultiRetailerReviewTabs.scss';

export const getParticipatingLocationCountByRetailerId = (
    multiRetailerParticipantGroups: MultiRetailerSparkParticipantGroup[],
): Record<string, number> => {
    return Object.fromEntries(
        multiRetailerParticipantGroups.map(({ retailerAccountId, participantGroups }) => {
            const participatingLocationCount = sumBy(
                participantGroups,
                ({ locationIds = [] }) => locationIds.length,
            );
            return [retailerAccountId, participatingLocationCount];
        }),
    );
};

interface MultiRetailerReviewTabsRenderProps {
    selectedRetailerPosData: SelectedRetailerPosData;
    selectedRetailerCommissionMap?: ISparkCommissionMap;
    reviewDataIsReady: boolean;
}

export const MultiRetailerReviewTabs = ({
    children,
}: {
    children: (renderProps: MultiRetailerReviewTabsRenderProps) => ReactElement;
}) => {
    const { multiRetailerParticipantGroups = [] } = useSpark();

    const { retailers, retailersAreReady, selectedRetailer, updateSelectedRetailer } =
        useMultiRetailerTabManager();

    const { selectedRetailerPosData, selectedRetailerPosDataIsReady } =
        useSelectedRetailerPosData(selectedRetailer);
    const { selectedRetailerCommissionMap, selectedRetailerCommissionMapIsReady } =
        useSelectedRetailerCommissionMap(selectedRetailer);

    const participatingLocationCountByRetailerId = useMemo(
        () => getParticipatingLocationCountByRetailerId(multiRetailerParticipantGroups),
        [multiRetailerParticipantGroups],
    );

    if (!retailersAreReady) {
        return <Skeleton height="100%" width="100%" />;
    }

    const reviewDataIsReady =
        selectedRetailerPosDataIsReady && selectedRetailerCommissionMapIsReady;
    const reviewContent = children({
        selectedRetailerPosData,
        selectedRetailerCommissionMap,
        reviewDataIsReady,
    });

    const retailerCount = retailers.length;
    const calloutMessage = (
        <span>
            You’re almost there! Please review the summary of details before proceeding. Upon
            launch,
            <strong>{` ${retailerCount} Spark requests will be sent`}</strong>, one for each of the
            participating Retailers.
        </span>
    );

    return (
        <>
            {reviewDataIsReady && (
                <CalloutMessage className="multi-retailer_callout" message={calloutMessage} />
            )}

            <header className="multi-retailer-sidebar_header">Participating Retailers</header>

            <div className="multi-retailer-tabs_container">
                <Tabs
                    className="multi-retailer-tabs_sidebar"
                    value={selectedRetailer?.retailerAccountId ?? ''}
                    color="blue"
                    orientation="vertical"
                >
                    {retailers.map(
                        ({ retailerAccountId = '', name, photo, activeLocations = [] }) => {
                            const selectedLocationCount =
                                participatingLocationCountByRetailerId[retailerAccountId] ?? 0;
                            const activeLocationCount = activeLocations.length;
                            const subTitle = `${selectedLocationCount} of ${activeLocationCount} ${pluralize(
                                'Location',
                                activeLocationCount,
                            )}`;
                            return (
                                <Tabs.Tab
                                    onClick={() => updateSelectedRetailer(retailerAccountId)}
                                    key={retailerAccountId}
                                    value={retailerAccountId}
                                    label={
                                        <LinkLabel
                                            title={name}
                                            imageUrl={photo ?? ''}
                                            subtitle={subTitle}
                                        />
                                    }
                                />
                            );
                        },
                    )}
                </Tabs>

                <div className="multi-retailer-tabs_main">{reviewContent}</div>
            </div>
        </>
    );
};
