import { FC, ReactElement, ReactNode } from 'react';

import Tabs from '@components/layout/Tabs';
import { Link } from '@components/router';

import { useRouteTabs } from '@hooks/UIHooks';

import './RouterTabs.scss';

interface RouterTabsTabProps {
    path: string;
    children: ReactNode;
}

const RouterTabsTab: FC<RouterTabsTabProps> = ({ path, children }) => {
    const { tabValue } = useRouteTabs();

    return path === tabValue ? <>{children}</> : <></>;
};
interface IRouterTabsProps {
    color?: 'blue' | 'neutral' | 'green' | 'red';
    tabs: {
        url: string;
        text: string | ReactElement;
        /**
         * @description in some cases, `to` is different than `url` like in the case with query params
         */
        to?: string;
    }[];
    onChange?: () => void;
    children: ReactNode;
}

interface RouterTabsSubComponents {
    Tab: FC<RouterTabsTabProps>;
}

const RouterTabs: FC<IRouterTabsProps> & RouterTabsSubComponents = ({
    color = 'blue',
    tabs,
    onChange = () => {}, // onChange action might be broken. Possibly because of re-render?
    children,
}) => {
    const { tabValue } = useRouteTabs();

    return (
        <>
            <Tabs color={color} value={tabValue} onChange={onChange}>
                {tabs.map(({ url, text, to }) => (
                    <Tabs.Tab key={url} label={text} value={url} component={Link} to={to ?? url} />
                ))}
            </Tabs>
            {children}
        </>
    );
};

RouterTabs.Tab = RouterTabsTab;

export default RouterTabs;
