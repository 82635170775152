import { FC } from 'react';

import {
    FormControl,
    FormLabel,
    RadioGroup as MuiRadioGroup,
    RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material';

import { useFormContext } from '@hooks/FormHooks';

import { appendClasses, uuid } from '@helpers/ui';

import { IFormField, InputEventOnChange } from '@app/types/UITypes';

import { RadioGroupOption, RadioOption } from './RadioGroupOption';

import './RadioGroup.scss';

export interface RadioGroupProps extends Omit<IFormField, 'onChange'> {
    className?: string;
    radioGroupClassName?: string;
    color: 'neutral' | 'blue' | 'green' | 'yellow' | 'red';
    variant?: 'none' | 'flat' | 'raised' | 'smooth' | 'outlined' | 'button' | 'grid';
    direction?: 'vertical' | 'horizontal';
    label?: string;
    value?: string;
    onChange?: InputEventOnChange;
    options: RadioOption[];
    disabled?: boolean;
    searchable?: boolean;
    alignment?: 'left' | 'center' | 'right';
}

const RadioGroup: FC<RadioGroupProps> = ({
    className,
    radioGroupClassName = '',
    color = 'neutral',
    name,
    variant = 'flat',
    direction = 'horizontal',
    label,
    value = '',
    required,
    onChange = () => {},
    options = [],
    disabled,
    alignment = 'left',
}) => {
    const {
        formState: { isSubmitted },
    } = useFormContext() || {
        formState: { isSubmitted: false },
    };

    const classNameAppended = appendClasses([
        className,
        'form-control',
        'radio-group',
        `radio-group-color-${color}`,
        `radio-group-variant-${variant}`,
        `radio-group-direction-${direction}`,
        `radio-group-alignment-${alignment}`,
        disabled ? 'disabled-radio-group' : '',
    ]);

    const inputName: string | undefined = name || label?.replace(/\W/g, '') || uuid();

    const props: MuiRadioGroupProps & { disabled?: boolean } = {
        onChange,
        name: inputName,
        value,
        className: radioGroupClassName,
    };

    const hasError = isSubmitted && required && !value;

    return (
        <FormControl error={hasError} className={classNameAppended}>
            {label && (
                <FormLabel component="legend" className="form-label" required={required}>
                    {label}
                </FormLabel>
            )}

            <MuiRadioGroup {...props}>
                {options.map((option, i) => (
                    <RadioGroupOption
                        key={`${value}-${i}`}
                        currentValue={value || ''}
                        option={option}
                        onChange={onChange}
                        disabled={disabled}
                    />
                ))}
            </MuiRadioGroup>
        </FormControl>
    );
};

export default RadioGroup;
