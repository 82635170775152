import { clsx } from 'clsx';

import Button from '@components/buttons/Button';
import { AnimatedTrainingCourseBadgeGraphic } from '@components/graphics';
import { PadlockIcon, StopwatchIcon } from '@components/icons';
import Modal from '@components/overlays/Modal';

import { useApp } from '@hooks/AppHooks';

import './NewTrainingNoticeModal.scss';

const RequiredTrainingCallout = () => (
    <div className="required-training-callout">
        <PadlockIcon className="required-training-callout_icon" />
        <span className="required-training-callout_message">
            Training required to participate in this Spark!
        </span>
    </div>
);

type TNewTrainingNoticeModal = {
    isVisible: boolean;
    className?: string;
    courseDescription: string;
    courseLength?: number;
    mustCompleteRequiredTraining?: boolean;
    autoplayAnimation?: boolean;
    onConfirm: () => void;
    onClose: () => void;
};

export const NewTrainingNoticeModal = ({
    isVisible,
    courseDescription,
    courseLength,
    className,
    autoplayAnimation = true,
    mustCompleteRequiredTraining = false,
    onConfirm,
    onClose,
}: TNewTrainingNoticeModal) => {
    const { history } = useApp();

    return (
        <Modal
            disableBackdropClick={mustCompleteRequiredTraining}
            size="extra-narrow"
            className={clsx('training-notice-modal', className)}
            isVisible={isVisible}
            onClose={onClose}
        >
            {mustCompleteRequiredTraining && <RequiredTrainingCallout />}

            <Modal.Title
                endIcon={
                    !mustCompleteRequiredTraining ? (
                        <Button
                            className="skip-text"
                            color="neutral"
                            variant="flat"
                            onClick={onClose}
                        >
                            Skip
                        </Button>
                    ) : undefined
                }
            >
                <span className="title-text">Training</span>
            </Modal.Title>

            <Modal.Content className="content">
                <AnimatedTrainingCourseBadgeGraphic autoplay={autoplayAnimation} />
                <div>{courseDescription}</div>

                {!!courseLength && (
                    <div className="length-wrapper">
                        <StopwatchIcon />
                        <div>{courseLength} min</div>
                    </div>
                )}
            </Modal.Content>

            <Modal.Actions>
                <div className="action-buttons">
                    <Button
                        color="blue"
                        variant="filled"
                        onClick={() => {
                            onConfirm();
                            onClose();
                        }}
                    >
                        Start Training Course
                    </Button>

                    {mustCompleteRequiredTraining && (
                        <Button
                            className="no-reward_back-button"
                            color="neutral"
                            variant="flat"
                            onClick={() => history.push('/user/sparks')}
                        >
                            Return to Your Sparks
                        </Button>
                    )}
                </div>
            </Modal.Actions>
        </Modal>
    );
};
