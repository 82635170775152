import AllUnitedStates from '@data/UnitedStates.json';

import { TAccountType } from '@app/types/AccountsTypes';

export const getMarketOptions = (accountType?: TAccountType) => {
    const options: any[] = [];

    Object.keys(AllUnitedStates).forEach((value) => {
        const label = AllUnitedStates[value as keyof typeof AllUnitedStates];

        options.push({
            value,
            label,
        });
    });

    if (accountType === 'retailer') {
        return options;
    }

    return [
        {
            value: 'all',
            label: 'All US Markets',
            expanded: true,
            children: options,
        },
    ];
};
