import { SMSStatuses } from '@constants/UserConstants';

import { UserSmsStatus } from '@sparkplug/lib';

import Chip from '@components/chips/Chip';
import { NotificationsOff } from '@components/icons';
import Tooltip from '@components/layout/Tooltip';

export interface SMSEnrollmentChipProps {
    status?: UserSmsStatus | null;
    isMuted?: boolean;
}

const SMSEnrollmentChip = ({ status: _status, isMuted = false }: SMSEnrollmentChipProps) => {
    const status = isMuted && _status === 'enrolled' ? 'muted' : _status ?? 'none';
    const smsStatus = SMSStatuses.find((obj) => status === obj.value);

    if (!smsStatus) {
        return <></>;
    }

    return (
        <Tooltip title={smsStatus.tooltipText}>
            <div>
                <Chip
                    dense
                    icon={
                        status === 'muted' ? (
                            <NotificationsOff className="sms-chip-icon" />
                        ) : undefined
                    }
                    color={smsStatus.color}
                    label={smsStatus.label}
                />
            </div>
        </Tooltip>
    );
};

export default SMSEnrollmentChip;
