import {
    ComponentType,
    FC,
    MouseEventHandler,
    ReactElement,
    ReactNode,
    RefObject,
    useRef,
} from 'react';

import { ListItemIcon, MenuItem } from '@mui/material';

import { ChevronRight as NextIcon } from '@components/icons';
import Tooltip, { TooltipProps } from '@components/layout/Tooltip';

import { appendClasses, uuid } from '@helpers/ui';

import { useDropdown, useInternalMenuContext } from './DropdownContext';
import { ControlledDropdownMenu } from './DropdownMenu';

const useNestedMenu = ({
    menuItemRef,
    nestedChildren,
}: {
    menuItemRef: RefObject<HTMLLIElement>;
    nestedChildren?: ReactNode;
}) => {
    const { activeMenuItemId, updateActiveMenuItemId } = useInternalMenuContext();

    // get the width of the menu item
    const menuItemWidth = menuItemRef.current?.offsetWidth ?? 0;

    // get the distance from the right edge of the menu item to the right edge of the viewport
    const menuItemRightEdge = menuItemRef.current?.getBoundingClientRect().right ?? 0;
    const viewportRightEdge = window.innerWidth;
    const distanceFromRightEdge = viewportRightEdge - menuItemRightEdge;

    // if the distance from the right edge of the menu item to the right edge of the viewport is less than the width of
    // the menu item, then the menu item is too close to the right edge of the viewport to fit the nested menu
    const isTooCloseToRightEdge = distanceFromRightEdge < menuItemWidth + 24;

    const menuItemId = useRef<string>(uuid());
    const nestedMenu = nestedChildren ? (
        <ControlledDropdownMenu
            anchorEl={menuItemRef.current ?? undefined}
            container={menuItemRef.current ?? undefined}
            isVisible={activeMenuItemId === menuItemId.current}
            anchorOrigin={{
                vertical: 'top',
                horizontal: isTooCloseToRightEdge ? -24 : menuItemWidth,
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: isTooCloseToRightEdge ? menuItemWidth : 0,
            }}
            onClose={() => updateActiveMenuItemId(undefined)}
        >
            {nestedChildren}
        </ControlledDropdownMenu>
    ) : undefined;
    const handleHover = () => {
        updateActiveMenuItemId(menuItemId.current);
    };

    const nestedEndIcon = nestedChildren ? <NextIcon /> : undefined;

    return {
        nestedMenu,
        handleHover,
        nestedEndIcon,
    };
};

export interface DropdownMenuItemProps {
    className?: string;
    color?: 'neutral' | 'blue' | 'green' | 'yellow' | 'red';
    onClick?: MouseEventHandler<HTMLLIElement>;
    onClose?: () => void;
    selected?: boolean;
    disabled?: boolean;
    hidden?: boolean;
    StartIcon?: ComponentType;
    endIcon?: ReactElement;
    disableCloseOnClick?: boolean;
    disableHover?: boolean;
    noPadding?: boolean;
    'data-qa-menu-item'?: string;
    tooltipProps?: Omit<TooltipProps, 'children'>;
    align?: 'center';
    children: ReactNode;
    nestedChildren?: ReactNode;
}

const DropdownMenuItem: FC<DropdownMenuItemProps> = ({
    className = '',
    color = 'neutral',
    onClick = () => {},
    selected = false,
    disabled = false,
    hidden = false,
    StartIcon,
    endIcon: endIconProp,
    children,
    disableCloseOnClick = false,
    tooltipProps,
    nestedChildren,
    align,
    noPadding,
    disableHover,
    ...otherProps
}) => {
    const menuItemRef = useRef<HTMLLIElement>(null);
    const { handleClose } = useDropdown();
    const { nestedMenu, handleHover, nestedEndIcon } = useNestedMenu({
        menuItemRef,
        nestedChildren,
    });

    if (hidden) {
        return <></>;
    }

    const { 'data-qa-menu-item': dataQaMenuItem } = otherProps;

    const handleClick: MouseEventHandler<HTMLLIElement> = (event) => {
        try {
            if (!disableCloseOnClick) {
                handleClose();
            }

            requestAnimationFrame(() => {
                onClick(event);
            });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Error: DropDownMenu handleClick', err);
        }
    };

    const autoDataQa = children && typeof children === 'string' ? children.toString() : null;

    const endIcon = nestedEndIcon || endIconProp;

    const classNameAppended = appendClasses([
        className,
        'dropdown-menu-item',
        color ? `dropdown-menu-item-color-${color}` : '',
        align ? `dropdown-menu-item-align-${align}` : '',
        endIcon ? 'has-end-icon' : '',
        noPadding ? `dropdown-menu-item-no-padding` : '',
        disableHover ? `dropdown-menu-item-disable-hover` : '',
    ]);

    const menuItem = (
        <MenuItem
            ref={menuItemRef}
            data-qa-menu-item={dataQaMenuItem || autoDataQa}
            className={classNameAppended}
            selected={selected}
            disabled={disabled}
            onClick={handleClick}
            onMouseOver={handleHover}
        >
            {StartIcon && (
                <ListItemIcon>
                    <StartIcon />
                </ListItemIcon>
            )}
            {children}
            {endIcon && (
                <ListItemIcon className="dropdown-menu-item_end-icon">{endIcon}</ListItemIcon>
            )}
        </MenuItem>
    );

    if (tooltipProps) {
        return (
            <Tooltip placement="top" {...tooltipProps}>
                <div>{menuItem}</div>
            </Tooltip>
        );
    }

    return (
        <>
            {menuItem}
            {nestedMenu}
        </>
    );
};

export default DropdownMenuItem;
