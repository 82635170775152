interface IObject {
    [key: string]: any;
}

const LocalStorage = {
    get: <T>(key: string): T => {
        const data = localStorage.getItem(key);
        return data != null ? JSON.parse(data) : null;
    },
    set: (key: string, value: number | string | IObject | boolean | null | any[] | undefined) => {
        if (value != null) {
            localStorage.setItem(key, JSON.stringify(value));
        } else {
            localStorage.removeItem(key);
        }
    },
};

export default LocalStorage;
