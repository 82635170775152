import { FC } from 'react';
import Linkify from 'react-linkify';

import moment from 'moment';

import { CircleCheck } from '@components/icons';

import './NonCashRewardClaimInstructions.scss';

interface NonCashRewardClaimInstructionsProps {
    claimInstructions?: string;
    claimedDate?: Date;
}

const NonCashRewardClaimInstructions: FC<NonCashRewardClaimInstructionsProps> = ({
    claimInstructions,
    claimedDate,
}) => {
    return (
        <div className="claim-instructions-container">
            <div className="claim-instructions-header">Claim Instructions:</div>
            <div className="claim-instructions">
                <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a target="blank" href={decoratedHref} key={key}>
                            {decoratedText}
                        </a>
                    )}
                >
                    {claimInstructions && claimInstructions.length
                        ? claimInstructions
                        : 'Talk to manager'}
                </Linkify>
            </div>
            {claimedDate && (
                <div className="flex gap-2 items-center justify-center mt-4">
                    <CircleCheck className="w-5 h-5 [&_path]:fill-aqua-600" />
                    Reward received on {moment(claimedDate).format('MMMM D, YYYY')}
                </div>
            )}
        </div>
    );
};

export default NonCashRewardClaimInstructions;
