import { ChartMetricOptions } from '@constants/ChartConstants';
import moment from 'moment';

import { FullChartResponseBody, GetChartResponseBody, TChartDataMetric } from '@sparkplug/lib';

import { IBucketFactory, IChartFactory } from '@app/types/CalculatorTypes';
import {
    ChartLabelBucketPairs,
    IChartDataSettings,
    IDataArrayItem,
    IDataSet,
    TChartData,
    TChartType,
    TransformChartDataReturnValue,
} from '@app/types/ChartDataTypes';
import { IPosLocation, IPosProduct } from '@app/types/PosTypes';

import { sortByDate } from '../ui';
import { transformCloudChartDataForTable } from './transformCloudChartDataForTable';

export const isCumulativeChartMetric = (metric: TChartDataMetric) => {
    return ['total_sales', 'total_units', 'transaction_count'].includes(metric);
};

export function getChartMetricLabel(value: string = '') {
    for (let i = 0; i < ChartMetricOptions.length; i += 1) {
        if (ChartMetricOptions[i].value === value) {
            return ChartMetricOptions[i].label;
        }
    }
    return '';
}

export const transformCloudBucketsToDataSetArray = (
    buckets: { [bucket: string]: number },
    type: TChartType,
    isCumulative: boolean,
    _today: string = moment().format('YYYY-MM-DD'),
) => {
    const today = moment(_today);

    const array = Object.entries(buckets)
        .map(([name, value]) => ({
            name,
            value,
        }))
        .sort(sortByDate('name', 'asc'));

    if (type === 'line') {
        return array.reduce<IDataArrayItem[]>((res, { name, value }, i) => {
            const prevItem = res[i - 1] ?? {};

            if (!today.isBefore(name)) {
                res.push({
                    name,
                    value: isCumulative ? value + (prevItem.value ?? 0) : value ?? prevItem.value,
                });
            }

            return res;
        }, []);
    }

    return array;
};

export const transformLabelBucketPairsToChartData = ({
    chartType,
    chartMetric,
    labelBucketPairs,
    bucketFactory,
    chartFactory,
}: {
    chartType: TChartType;
    chartMetric: TChartDataMetric;
    labelBucketPairs: ChartLabelBucketPairs;
    bucketFactory: IBucketFactory;
    chartFactory: IChartFactory;
}): TChartData => {
    const dataSets: IDataSet[] = [];
    const isCumulativeMetric = isCumulativeChartMetric(chartMetric);

    Object.entries(labelBucketPairs).forEach(([chartLabel, buckets]) => {
        const array = transformCloudBucketsToDataSetArray(buckets, chartType, isCumulativeMetric);

        dataSets.push({ name: chartLabel, dataArray: array });
    });

    const totals = dataSets.reduce<{ [name: string]: number }>((res, { name, dataArray }) => {
        res[name] = dataArray.reduce((subtotal, { value }) => subtotal + value, 0);
        return res;
    }, {});

    const dataSetsSorted = dataSets.sort((a, b) => totals[b.name] - totals[a.name]);

    const metricLabel = getChartMetricLabel(chartMetric);
    return chartFactory.generateMultiDimensionalChartData(bucketFactory, dataSetsSorted, {
        metricLabel,
    });
};

const transformProductChartData = ({
    productBuckets,
    settings,
    bucketFactory,
    chartFactory,
}: {
    productBuckets: FullChartResponseBody['productBuckets'];
    settings: IChartDataSettings;
    bucketFactory: IBucketFactory;
    chartFactory: IChartFactory;
}) => {
    const productInternalKeysWithData =
        settings?.filters?.products?.reduce((res, { internalKey, label, brands, categories }) => {
            return {
                ...res,
                [internalKey]: {
                    label,
                    brand: brands[0].name,
                    category: categories[0].name,
                },
            };
        }, {} as { [internalKey: string]: { label: string; brand: string; category: string } }) ??
        {};

    const productLabelBucketPairs: ChartLabelBucketPairs = {};
    const brandLabelBucketPairs: ChartLabelBucketPairs = {};
    const categoryLabelBucketPairs: ChartLabelBucketPairs = {};

    Object.entries(productBuckets ?? {}).forEach(([productName, buckets]) => {
        const productInternalKeyInBucket = productName in productInternalKeysWithData;
        if (productInternalKeyInBucket) {
            productLabelBucketPairs[productInternalKeysWithData[productName].label] = buckets;

            if (settings.breakdown === 'brand') {
                const brandName = productInternalKeysWithData[productName].brand;
                if (!brandLabelBucketPairs[brandName]) brandLabelBucketPairs[brandName] = {};
                Object.entries(buckets).forEach(([bucketName, value]) => {
                    if (brandLabelBucketPairs[brandName][bucketName]) {
                        brandLabelBucketPairs[brandName][bucketName] += value;
                    } else {
                        brandLabelBucketPairs[brandName][bucketName] = value;
                    }
                });
            }

            if (settings.breakdown === 'category') {
                const categoryName = productInternalKeysWithData[productName].category;
                if (!categoryLabelBucketPairs[categoryName])
                    categoryLabelBucketPairs[categoryName] = {};
                Object.entries(buckets).forEach(([bucketName, value]) => {
                    if (categoryLabelBucketPairs[categoryName][bucketName]) {
                        categoryLabelBucketPairs[categoryName][bucketName] += value;
                    } else {
                        categoryLabelBucketPairs[categoryName][bucketName] = value;
                    }
                });
            }
        }
    });

    const chartDataProducts: TChartData | undefined =
        chartFactory && settings.breakdown === 'product'
            ? transformLabelBucketPairsToChartData({
                  chartType: settings.type,
                  chartMetric: settings.percentIncreaseData?.metric ?? settings.metric,
                  labelBucketPairs: productLabelBucketPairs,
                  bucketFactory,
                  chartFactory,
              })
            : undefined;

    const chartDataBrands =
        chartFactory && settings.breakdown === 'brand'
            ? transformLabelBucketPairsToChartData({
                  chartType: settings.type,
                  chartMetric: settings.percentIncreaseData?.metric ?? settings.metric,
                  labelBucketPairs: brandLabelBucketPairs,
                  bucketFactory,
                  chartFactory,
              })
            : undefined;

    const chartDataCategories =
        chartFactory && settings.breakdown === 'category'
            ? transformLabelBucketPairsToChartData({
                  chartType: settings.type,
                  chartMetric: settings.percentIncreaseData?.metric ?? settings.metric,
                  labelBucketPairs: categoryLabelBucketPairs,
                  bucketFactory,
                  chartFactory,
              })
            : undefined;

    return {
        chartDataProducts,
        chartDataBrands,
        chartDataCategories,
    };
};

const transformLocationChartData = ({
    relevantLocationBuckets,
    accountPosLocations,
    settings,
    bucketFactory,
    chartFactory,
    isCumulativeMetric,
}: {
    relevantLocationBuckets: FullChartResponseBody['locationBuckets'];
    accountPosLocations: IPosLocation[];
    settings: IChartDataSettings;
    bucketFactory: IBucketFactory;
    chartFactory: IChartFactory;
    isCumulativeMetric: boolean;
}): {
    chartData?: TransformChartDataReturnValue['chartData'];
    chartDataAllLocations?: TransformChartDataReturnValue['chartDataAllLocations'];
} => {
    const allLocationsValues: { [bucket: string]: number } = {};
    const locationLabelBucketPairs: ChartLabelBucketPairs = {};

    Object.entries(relevantLocationBuckets).forEach(([locationId, buckets]) => {
        const location = accountPosLocations.find(({ _id }) => locationId === _id);

        if (location) {
            locationLabelBucketPairs[location.label] = buckets;

            if (settings.locationIds.includes(locationId)) {
                Object.entries(buckets).reduce((res, [name, value]) => {
                    if (res[name]) {
                        res[name] += value;
                    } else {
                        res[name] = value;
                    }
                    return res;
                }, allLocationsValues);
            }
        }
    });

    const locationCount = settings.locationIds.length;
    if (!isCumulativeMetric) {
        Object.keys(allLocationsValues).forEach((key) => {
            allLocationsValues[key] /= locationCount;
        });
    }

    const chartData = chartFactory
        ? transformLabelBucketPairsToChartData({
              chartType: settings.type,
              chartMetric: settings.percentIncreaseData?.metric ?? settings.metric,
              labelBucketPairs: locationLabelBucketPairs,
              bucketFactory,
              chartFactory,
          })
        : undefined;

    const chartDataAllLocations = chartFactory
        ? transformLabelBucketPairsToChartData({
              chartType: settings.type,
              chartMetric: settings.percentIncreaseData?.metric ?? settings.metric,
              labelBucketPairs: { '*': allLocationsValues },
              bucketFactory,
              chartFactory,
          })
        : undefined;

    return { chartData, chartDataAllLocations };
};

export const transformCloudChartData = (
    rawData: GetChartResponseBody,
    settings: IChartDataSettings,
    bucketFactory: IBucketFactory,
    chartFactory: IChartFactory,
    posData: {
        accountPosLocations: IPosLocation[];
        accountPosProducts: IPosProduct[];
    },
): TransformChartDataReturnValue => {
    const {
        locationBuckets = {},
        locationCumulativeBuckets,
        productBuckets = {},
        datePercentage = 0,
    } = rawData as FullChartResponseBody;

    const relevantLocationBuckets =
        settings.type === 'line' && locationCumulativeBuckets
            ? locationCumulativeBuckets
            : locationBuckets;

    if (settings.type === 'table') {
        return transformCloudChartDataForTable(rawData, settings, posData);
    }

    // Return empty data if there is no sales data
    if (!datePercentage) {
        const emptyChartData = chartFactory
            ? chartFactory.generateMultiDimensionalChartData(bucketFactory, [])
            : undefined;

        return {
            chartData: emptyChartData!,
            chartDataAllLocations: emptyChartData!,
            chartDataProducts: emptyChartData,
        };
    }

    const { accountPosLocations } = posData;

    const isCumulativeMetric = isCumulativeChartMetric(settings.metric);

    const { chartData, chartDataAllLocations } = transformLocationChartData({
        relevantLocationBuckets,
        accountPosLocations,
        settings,
        bucketFactory,
        chartFactory,
        isCumulativeMetric,
    });

    const {
        chartDataProducts = [],
        chartDataBrands = undefined,
        chartDataCategories = undefined,
    } = ['product', 'brand', 'category'].includes(settings.breakdown)
        ? transformProductChartData({
              productBuckets,
              settings,
              chartFactory,
              bucketFactory,
          })
        : {};

    return {
        chartData: chartData!,
        chartDataAllLocations: chartDataAllLocations!,
        chartDataProducts,
        chartDataBrands,
        chartDataCategories,
    };
};
