import { Info as InfoIcon } from '@components/icons';
import Tooltip from '@components/layout/Tooltip';

export type SelectedCountMessageProps = {
    selected: number;
    total: number;
    /** @deprecated Isn't "noun" a little weird here? Let's use `pluralUnitLabel` instead */
    noun?: string;
    pluralUnitLabel?: string;
    tooltipMessage?: string;
};

const SelectedCountMessage = ({
    selected,
    total,
    noun,
    pluralUnitLabel,
    tooltipMessage,
}: SelectedCountMessageProps) => {
    const unitLabel = pluralUnitLabel ?? noun ?? '';

    return (
        <div className="selected-count-message">
            <span>
                <strong>{selected}</strong> of <strong>{total}</strong> {unitLabel} selected
            </span>
            {tooltipMessage && (
                <Tooltip title={tooltipMessage}>
                    <InfoIcon />
                </Tooltip>
            )}
        </div>
    );
};

export default SelectedCountMessage;
