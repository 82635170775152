import { ReactElement } from 'react';

import { useAppBodyClasses } from '@hooks/AppHooks';

import { cn } from '../utils';

export interface SidebarLayoutProps {
    className?: string;
    headerClassName?: string;
    header?: ReactElement;
    sidebarClassName?: string;
    sidebar: ReactElement;
    contentClassName?: string;
    content: ReactElement;
}
const SidebarLayout = ({
    className,
    headerClassName,
    header,
    sidebarClassName,
    sidebar,
    contentClassName,
    content,
}: SidebarLayoutProps) => {
    // Remove max width limitation set by the body
    // TODO: We should remove these restrictions at the body level but this may require a larger refactor
    useAppBodyClasses(
        ['app-content-withSidebar', 'app-content-fullWidth', 'app-content-noPadding'],
        [],
    );

    return (
        <div
            className={cn(
                'w-full flex flex-col gap-8 p-8 pl-10 pr-20 max-w-[1920px] mx-auto',
                className,
            )}
        >
            {header && <div className={cn('w-full', headerClassName)}>{header}</div>}
            <div className="w-full flex flex-col sm:flex-row flex-1 gap-6">
                <div className={cn('w-full max-w-[220px]', sidebarClassName)}>{sidebar}</div>
                <div className={cn('w-full', contentClassName)}>{content}</div>
            </div>
        </div>
    );
};

/**
 * Full width sidebar layout
 */
export default SidebarLayout;
