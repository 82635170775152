import { z } from 'zod';
import { PagedApiResponseBody } from '../http';

export interface PosBrand {
  _id: string;

  createdAt?: string;
  deletedAt?: string;
  groupId: string;
  group_id?: string;
  name: string;
  posConfigIds: string[];
  searchName: string;
  updatedAt?: string;
}

export interface ListPosBrandsQueryParams {
  groupId: string;
  group_id?: string;
  limit?: number;
  offset?: number;
}
export type ListPosBrandsResponseBody = PagedApiResponseBody<PosBrand>;

export interface GetPosBrandPathParams {
  brandId: string;
}
export interface GetPosBrandResponseBody {
  data: PosBrand;
}

export interface ListPosBrandsByIdRequestBody {
  groupId: string;
  group_id?: string;
  brandIds: string[];
}
export interface ListPosBrandsByIdResponseBody {
  data: PosBrand[];
}

export const retailersCarryingBrandSchema = z.object({
  brand: z.string().min(1),
});

export type RetailersCarryingBrandRequestBody = z.infer<typeof retailersCarryingBrandSchema>;
