import { Box, Typography } from '@mui/material';
import pluralize from 'pluralize';

import { AccountLink } from '@sparkplug/lib';

import LinkLabel from '@features/account-links/components/LinkLabel';

import { LocationOn as LocationIcon } from '@components/icons';

import { useApp } from '@hooks/AppHooks';

import { getStateNameByAbbreviation } from '@helpers/util';

const SharedMarketLabel = ({
    market,
    locationCount,
}: {
    market: string;
    locationCount: number;
}) => {
    const stateName = getStateNameByAbbreviation(market);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '2px',
                transform: 'translateX(-3px)',
            }}
        >
            <LocationIcon style={{ color: '#62676e', fontSize: '1rem' }} />
            <Typography variant="body" color="gray90" style={{ margin: 0 }}>
                {`${stateName} (${pluralize('Location', locationCount, true)})`}
            </Typography>
        </Box>
    );
};

const AccountLinkSubtitle = ({
    accountLink: { accountType, brandLinks = [], locations = [], markets = [], sharedMarkets = [] },
    verbose,
}: {
    accountLink: AccountLink;
    verbose: boolean;
}) => {
    if (accountType === 'brand') {
        const brandNames = brandLinks.map((brandLink) => brandLink.name).join(', ');
        if (verbose && brandNames?.length) {
            return <div>{brandNames}</div>;
        }
        return <div>{`${pluralize('Brand', brandLinks.length, true)}`}</div>;
    }

    /**
     * The current design for the SharedMarketLabel only accommodates one shared market.
     * Currently brand/retailers only have one shared market, but this may change in the future (especially with new markets).
     */
    const showSharedMarketLabel =
        verbose && sharedMarkets.length === 1 && accountType === 'retailer';
    const locationCount = locations.length;
    return (
        <>
            {showSharedMarketLabel ? (
                <SharedMarketLabel market={sharedMarkets[0]} locationCount={locationCount} />
            ) : (
                <>
                    <div className="account-link-label-locations">
                        {pluralize('Location', locationCount, true)}
                    </div>
                    <div>{markets.join(',')}</div>
                </>
            )}
        </>
    );
};

export interface AccountLinkLabelProps {
    accountLink: AccountLink;
    showVerboseSubtitle?: boolean;
}
const AccountLinkLabel = ({ accountLink, showVerboseSubtitle = false }: AccountLinkLabelProps) => {
    const { appIsMobile } = useApp();

    return (
        <div className="account-link-label" data-testid="account-link-label">
            <LinkLabel
                variant={appIsMobile ? 'base' : 'large'}
                imageUrl={accountLink.photo}
                title={accountLink.accountName}
                accountType={accountLink.accountType}
                subtitle={
                    <AccountLinkSubtitle accountLink={accountLink} verbose={showVerboseSubtitle} />
                }
            />
        </div>
    );
};

export default AccountLinkLabel;
