import {
    ArrayParam,
    NumberParam,
    QueryParamConfigMap,
    StringParam,
    useQueryParams,
    withDefault,
} from 'use-query-params';

const paramTypeMap = {
    string: StringParam,
    number: NumberParam,
} as const;

export const useQueryParamsState = <T extends Record<string, string | number | string[]>>(
    initialValues: T,
) => {
    const queryParamConfigMap: QueryParamConfigMap = Object.entries(initialValues).reduce(
        (res, [key, value]) => {
            if (Array.isArray(value)) {
                return {
                    ...res,
                    [key]: withDefault(ArrayParam, value),
                };
            }

            const valueType = typeof value === 'string' ? 'string' : 'number';

            const paramType =
                valueType === 'string'
                    ? withDefault(paramTypeMap[valueType], value as string)
                    : withDefault(paramTypeMap[valueType], value as number);

            return {
                ...res,
                [key]: paramType,
            };
        },
        {},
    );

    return useQueryParams(queryParamConfigMap);
};
