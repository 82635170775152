import { useEffect, useMemo, useState } from 'react';

import { IPagingProperties, ListEventsQueryParams, UIEvent } from '@sparkplug/lib';

import useEventsHeadCell, { EventTableRowData } from '@features/events/hooks/useEventsHeadCell';
import { useGetEventsQuery } from '@features/events/queries/EventQueries';

import { IListItem } from '@components/inputs/ListSelector/ListSelector';
import { useQueryParams } from '@components/router';
import AdvancedTable from '@components/table/AdvancedTable';
import Table from '@components/table/Table';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { cn } from '@app/componentLibrary/utils';
import { IAccount } from '@app/types/AccountsTypes';
import { ITableRow } from '@app/types/TableTypes';

import EventTableToolbar from '../EventTableToolbar';

const EventTable = ({
    account,
    events,
    isLoading,
    meta,
    eventFilters,
}: {
    account?: IAccount;
    events: UIEvent[];
    isLoading: boolean;
    meta?: IPagingProperties;
    eventFilters: ListEventsQueryParams;
}) => {
    const { headCells, stickyLeftCells, stickyRightCells } = useEventsHeadCell();

    const rowData: ITableRow<EventTableRowData>[] = useMemo(() => {
        return events.map((event) => ({
            ...event,
            key: event._id,
        }));
    }, [events]);

    return (
        <AdvancedTable
            variant="raised"
            stickyLeft={stickyLeftCells}
            headCells={headCells}
            stickyRight={stickyRightCells}
            rows={rowData}
            className={cn('events-table', '[&_.sticky-left>.head-cell-wrapper>span]:pl-3')}
            enableQueryParams
            showPagination
            disableFrontendFiltering
            isLoading={isLoading}
            defaultOptions={{
                rowsPerPage: eventFilters.limit,
                order: eventFilters.order === 'asc' ? 'asc' : 'desc',
                orderBy: eventFilters.orderBy ?? 'createdAt',
            }}
            rowCountOverride={meta?.total}
        >
            <Table.RenderHead />
            <Table.RenderBody
                rowRenderKeyFn={(row: EventTableRowData) => `${row._id}-${row.requestStatus}`}
            />
        </AdvancedTable>
    );
};

export default ({ approvalInProgress = false }: { approvalInProgress?: boolean }) => {
    const [locations, setLocations] = useState<IListItem[]>([]);

    const { account } = useSparkplugAccount();
    const rawQueryParams = useQueryParams();
    const queryParams: ListEventsQueryParams = {
        limit: Number(rawQueryParams.limit) || 10,
        offset: Number(rawQueryParams.offset) || 0,
        accountId: account?._id!,
        ...rawQueryParams,
    };

    const { events, eventsAreLoading, meta, refetchEvents } = useGetEventsQuery({
        accountId: account?._id!,
        eventFilters: queryParams,
        locationIds: locations.map((location) => location.value).filter(Boolean),
    });

    useEffect(() => {
        refetchEvents();
    }, [locations]);

    return (
        <div className="flex flex-col gap-4">
            <EventTableToolbar locations={locations} setLocations={setLocations} />
            <EventTable
                account={account}
                events={events}
                isLoading={eventsAreLoading || approvalInProgress}
                meta={meta}
                eventFilters={queryParams}
            />
        </div>
    );
};
