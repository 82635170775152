import axios from 'axios';

import {
    CreateQuickViewQueryRequestBody,
    CreateQuickViewQueryResponseBody,
    ListQuickViewQueryRequestParams,
    ListQuickViewQueryResponseBody,
    PublicQuickViewQuery,
    QuickViewTag,
} from '@sparkplug/lib';

const quickViewRoute = '/api/v1/quick-view-queries';

export default class QuickViewsAPI {
    static async create(
        tag: QuickViewTag,
        body: CreateQuickViewQueryRequestBody,
    ): Promise<CreateQuickViewQueryResponseBody> {
        return (await axios.post(`${quickViewRoute}/${tag}`, body)) as PublicQuickViewQuery;
    }

    static async list({
        tag,
    }: ListQuickViewQueryRequestParams): Promise<ListQuickViewQueryResponseBody> {
        return (
            (
                await axios.get<ListQuickViewQueryResponseBody>(quickViewRoute, {
                    params: { tag },
                })
            ).data ?? []
        );
    }
}
