import { cn } from '@app/componentLibrary/utils';

const EventStatusChip = ({
    status,
    altStatus,
}: {
    status: 'complete' | 'upcoming' | 'active';
    altStatus?: string;
}) => {
    const tagConfig = {
        complete: {
            classes: 'bg-gray-50 text-gray-900',
            label: 'Complete',
        },
        active: {
            classes: 'bg-aqua-50 text-aqua-900',
            label: 'Active',
        },
        upcoming: {
            classes: 'bg-buttercup-50 text-buttercup-900',
            label: 'Upcoming',
        },
    };

    const { classes = 'bg-sienna-50 text-sienna-900', label = '' } = tagConfig[status] || {};

    return (
        <div
            className={cn(
                'px-2 py-[2px] rounded-md text-[10px] font-semibold uppercase text-center',
                `${classes}`,
            )}
        >
            {altStatus || label}
        </div>
    );
};

export default EventStatusChip;
