import { FC, useCallback } from 'react';

import { orderBy } from 'lodash';

import { ISnap } from '@sparkplug/lib';

import ClickableSnapItem from '@features/spark-snaps/components/ClickableSnapItem';
import { useSnapViewQueue } from '@features/spark-snaps/hooks';
import { useUserSnapEventsQuery } from '@features/spark-snaps/queries';

import Tooltip from '@components/layout/Tooltip';

import './FeaturedSnapsList.scss';

export interface FeaturedSnapsViewProps {
    snaps?: ISnap[];
    accountId: string;
}

const FeaturedSnapsList: FC<FeaturedSnapsViewProps> = ({ snaps }: FeaturedSnapsViewProps) => {
    const { handleViewSnap, SnapViewer } = useSnapViewQueue();

    const { userSnapEventsAreReady, userSnapEvents } = useUserSnapEventsQuery({
        eventType: 'story_complete',
    });

    const onViewSnap = useCallback(
        (selectedSnapId: number) => {
            const selectedSnap = snaps?.find((snap) => snap.storifymeSnapId === selectedSnapId);
            return handleViewSnap({
                selectedSnapId,
                accountId: selectedSnap?.storifymeAccountId ?? '',
                accountIdIsStorifyme: true,
            });
        },
        [snaps],
    );
    const sortedSnaps = orderBy(
        snaps,
        [
            (snap) => {
                const viewed =
                    userSnapEventsAreReady &&
                    userSnapEvents?.some((event) => event.snapId === snap.storifymeSnapId);
                return viewed;
            },
        ],
        ['asc'],
    );

    return (
        <div>
            <div className="featured-snaps-view space-x-3">
                {sortedSnaps?.map((snap) => (
                    <div className="spark-snap-item" key={snap._id}>
                        <ClickableSnapItem
                            snapThumbnail={
                                snap.brandPhoto || snap.accountPhoto || snap.thumbnailUrl
                            }
                            snapName={snap.name}
                            snapId={snap.storifymeSnapId ?? 0}
                            onView={onViewSnap}
                            showFlashing
                            viewed={
                                !userSnapEventsAreReady
                                    ? true
                                    : userSnapEvents?.some(
                                          (event) => event.snapId === snap.storifymeSnapId,
                                      )
                            }
                        />
                        <div className="spark-snap-item_label">
                            <Tooltip title={snap?.name}>
                                <span>{snap?.name}</span>
                            </Tooltip>
                        </div>
                    </div>
                ))}
            </div>
            <SnapViewer />
        </div>
    );
};

export default FeaturedSnapsList;
