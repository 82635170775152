import { useMemo } from 'react';

import { DetailedSparkType, Spark } from '@sparkplug/lib';

import { getFlexibleEmployeeIdsByPosEmployeeProfileIds, useAccountUsersQuery } from '@core/users';

import { NonParticipantLeader } from '@components/chips/NonParticipantChip';
import Paper from '@components/layout/Paper';
import Skeleton from '@components/layout/Skeleton';

import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import { ChartLeader, TChartType } from '@app/types/ChartDataTypes';
import { IPosLocation } from '@app/types/PosTypes';
import { ISparkPosData } from '@app/types/SparksTypes';
import { IAccountUser } from '@app/types/UsersTypes';

import { CommissionUserChart } from './CommissionUserChart';
import { GoalUserChart } from './GoalUserChart';
import { LeaderboardUserChart } from './LeaderboardUserChart';

const LoadingSkeleton = () => (
    <Paper>
        <Skeleton height={100} />
        <Skeleton height={100} />
        <Skeleton height={100} />
        <Skeleton height={100} />
        <Skeleton height={100} />
    </Paper>
);

interface IEmployeeMetricsSectionProps {
    spark: Spark;
    isCalculatingChartData: boolean;
    transactionSummariesExist: boolean;
    leaders: ChartLeader[];
    type: TChartType;
    locations: IPosLocation[];
}

export const filterParticipatingLeaders = ({
    detailedSparkType,
    spark,
    accountUsers,
    leaders,
    sparkPosData,
}: {
    detailedSparkType: DetailedSparkType;
    spark: Spark;
    accountUsers: IAccountUser[];
    leaders: ChartLeader[];
    sparkPosData: ISparkPosData;
}) => {
    let sparkParticipantLeaderIds: string[] = [];

    if (spark.archivedAt) {
        sparkParticipantLeaderIds =
            detailedSparkType === 'leaderboardLocation'
                ? spark.locationIds
                : sparkPosData.participants.map(({ flexibleEmployeeId }) => flexibleEmployeeId);
    } else {
        sparkParticipantLeaderIds =
            detailedSparkType === 'leaderboardLocation'
                ? spark.locationIds
                : getFlexibleEmployeeIdsByPosEmployeeProfileIds(
                      accountUsers,
                      spark.posEmployeeProfileIds,
                  );
    }

    // if leader is non-participant, add a field called 'nonParticipantReason' to the leader object
    // with either 'excludedFromSpark' or 'incompleteTraining' (for now)

    let incompleteTrainingLeaders: NonParticipantLeader[] = [];

    if (
        spark.trainingEnabled &&
        spark.courseData?.required &&
        detailedSparkType !== 'leaderboardLocation'
    ) {
        const completedTrainingLeaderIds = Object.keys(
            spark.courseData?.completedUsersByCourseId?.[spark.courseData?.courseId] || {},
        );
        const incompleteTrainingLeaderIds = sparkParticipantLeaderIds.filter(
            (flexibleEmployeeId) => !completedTrainingLeaderIds.includes(flexibleEmployeeId),
        );

        incompleteTrainingLeaders = leaders
            .filter(({ flexibleEmployeeId }) =>
                incompleteTrainingLeaderIds.includes(flexibleEmployeeId),
            )
            .map((leader) => ({
                ...leader,
                nonParticipantReason: 'incompleteTraining',
            }));
    }

    const excludedFromSpark: NonParticipantLeader[] = leaders
        .filter(({ flexibleEmployeeId }) => !sparkParticipantLeaderIds.includes(flexibleEmployeeId))
        .map((leader) => ({
            ...leader,
            nonParticipantReason: 'excludedFromSpark',
        }));

    const nonParticipantLeaders: NonParticipantLeader[] = [
        ...excludedFromSpark,
        ...incompleteTrainingLeaders,
    ].sort((a, b) => {
        const aMetric = spark.type === 'commission' ? a.unitCount : a.transactionCount;
        const bMetric = spark.type === 'commission' ? b.unitCount : b.transactionCount;

        return (bMetric || 0) - (aMetric || 0);
    });
    const nonParticipantLeaderIds = nonParticipantLeaders.map(
        ({ flexibleEmployeeId }) => flexibleEmployeeId,
    );

    const filteredLeaders = leaders.filter(
        ({ flexibleEmployeeId }) => !nonParticipantLeaderIds.includes(flexibleEmployeeId),
    );

    return {
        filteredLeaders,
        nonParticipantLeaders,
    };
};

const EmployeeMetricsSection = ({
    spark,
    isCalculatingChartData,
    transactionSummariesExist,
    leaders,
    type,
    locations,
}: IEmployeeMetricsSectionProps) => {
    const { accountUsers, accountUsersAreReady } = useAccountUsersQuery(spark.groupId);
    const { detailedSparkType, sparkPosData } = useSpark();

    const { filteredLeaders, nonParticipantLeaders } = useMemo(
        () =>
            filterParticipatingLeaders({
                detailedSparkType: detailedSparkType!,
                spark,
                accountUsers,
                leaders,
                sparkPosData,
            }),
        [
            accountUsers,
            leaders,
            spark.posEmployeeProfileIds,
            spark.locationIds,
            detailedSparkType,
            sparkPosData.participants,
        ],
    );

    if (isCalculatingChartData || !accountUsersAreReady) {
        return <LoadingSkeleton />;
    }

    if (!transactionSummariesExist) {
        return <></>;
    }

    switch (spark.type) {
        case 'commission':
            return (
                <CommissionUserChart
                    leaders={filteredLeaders}
                    type={type}
                    locations={locations}
                    nonParticipantLeaders={nonParticipantLeaders}
                />
            );

        case 'goal': {
            const isCollectiveGoal =
                detailedSparkType === 'goalTeam' || detailedSparkType === 'goalManager';
            return (
                <GoalUserChart
                    leaders={isCollectiveGoal ? leaders : filteredLeaders}
                    spark={spark}
                    detailedSparkType={detailedSparkType}
                    locations={locations}
                    nonParticipantLeaders={nonParticipantLeaders}
                />
            );
        }

        case 'leaderboard':
            return (
                <LeaderboardUserChart
                    leaders={filteredLeaders}
                    spark={spark}
                    type={type}
                    locations={locations}
                    nonParticipantLeaders={nonParticipantLeaders}
                />
            );

        default:
            return <></>;
    }
};

export default EmployeeMetricsSection;
