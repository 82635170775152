import { FC } from 'react';

import { IPublicAccount, IPublicUser, formatCurrency } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import CalloutMessage from '@components/layout/CalloutMessage';
import Paper from '@components/layout/Paper';
import Modal from '@components/overlays/Modal';

import { useModal } from '@hooks/ModalHooks';

import './ConfirmGiftCardDetailsView.scss';

const ConfirmationField = ({ label, value }: { label: string; value: string | number }) => {
    return (
        <section className="confirmation-field">
            <header>{label}</header>
            <p>{value}</p>
        </section>
    );
};

interface ConfirmGiftCardDetailsViewProps {
    account: IPublicAccount;
    user: IPublicUser;
    contactMethod: string;
    amount: number;
    campaignName: string;
    onSubmit: () => void;
    note?: string;
}
export const ConfirmGiftCardDetailsView: FC<ConfirmGiftCardDetailsViewProps> = ({
    account,
    user,
    contactMethod,
    amount,
    campaignName,
    onSubmit,
    note,
}) => {
    const { back } = useModal();

    const recipientName = `${user.firstName ?? ''} ${user.lastName ?? ''}`.trim();

    return (
        <>
            <Modal.Content>
                <CalloutMessage
                    color="yellow"
                    className="confirm-gift-card-details-view_callout"
                    message="Please make sure the following info is correct. Once sent, gift cards cannot be retracted."
                />

                <Paper className="confirm-gift-card-details-view">
                    <h2>Gift Card Details</h2>

                    <article>
                        <ConfirmationField label="Recipient Account" value={account.name} />
                        <ConfirmationField label="Recipient Name" value={recipientName} />
                        <ConfirmationField label="Recipient Contact Method" value={contactMethod} />
                        <ConfirmationField label="Amount" value={formatCurrency(amount)} />
                        <ConfirmationField label="Campaign" value={campaignName} />
                        <ConfirmationField
                            label="Reason (Internal Tracking)"
                            value={note ?? '--'}
                        />
                    </article>
                </Paper>
            </Modal.Content>

            <Modal.Actions>
                <Button onClick={back} color="neutral">
                    Back
                </Button>

                <Button onClick={onSubmit}>Send</Button>
            </Modal.Actions>
        </>
    );
};
