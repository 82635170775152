import { Spark } from '@sparkplug/lib';

import Paper from '@components/layout/Paper';

import { IPosProduct } from '@app/types/PosTypes';
import { ISparkCommissionMap } from '@app/types/SparksTypes';

import { MissingCommissionCallout } from '../MissingCommissionCallout';
import SparkProductsTable from '../SparkProductsTable';

import './SparkCommissionPayouts.scss';

const SparkCommissionPayouts = ({
    spark,
    associatedProducts,
    sparkCommissionMap,
    paginated,
}: {
    spark: Spark;
    associatedProducts: IPosProduct[];
    sparkCommissionMap?: ISparkCommissionMap;
    paginated: boolean;
}) => {
    return (
        <>
            {sparkCommissionMap != null && (
                <Paper className="commission-payouts-paper">
                    <MissingCommissionCallout variant="header" spark={spark} />
                    <Paper.Title>Commission Payouts</Paper.Title>
                    <SparkProductsTable
                        spark={spark}
                        associatedProducts={associatedProducts}
                        sparkCommissionMap={sparkCommissionMap}
                        paginated={paginated}
                    />
                </Paper>
            )}
        </>
    );
};

export default SparkCommissionPayouts;
