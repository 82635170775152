export default [
    {
        _id: '66bbcd3a2ec42700123c7415',
        templateName: 'Launch a New Product',
        templateDescription:
            'Encourage teams and build excitement with top prizes, driving the success of your new product launch. This leaderboard format motivates individual efforts and competition, ensuring engagement and strong initial sales.',
        totalDays: 30,
        accountType: 'brand',
        name: 'Product Launch Showdown',
        description: 'Push our latest product to the top! Score major prizes for top sellers!',
        type: 'leaderboard',
        detailedSparkType: 'leaderboard',
        awards: [
            {
                award: '$250',
            },
            {
                award: '$100',
            },
            {
                award: '$50',
            },
        ],
        fulfillmentTypes: ['sparkplug', 'sparkplug', 'sparkplug'],
        goals: [],
    },
    {
        _id: '66bbce3b5a78680012b8f88e',
        templateName: 'Reach New Consumers',
        templateDescription:
            "Support retailers in reaching new customers by setting a team sales goal. This collaborative approach promotes teamwork, helping them expand your product's reach and build a larger customer base.",
        totalDays: 30,
        accountType: 'brand',
        awards: [],
        description: 'Push our latest products to new customers. Top team wins a group reward!',
        detailedSparkType: 'goalTeam',
        fulfillmentTypes: ['sparkplug', 'sparkplug', 'sparkplug'],
        goalType: 'team',
        goals: [
            {
                award: {
                    award: '$10',
                },
                threshold: 500,
            },
            {
                award: {
                    award: '$25',
                },
                threshold: 1000,
            },
            {
                award: {
                    award: '$50',
                },
                threshold: 1500,
            },
        ],
        metric: 'total_units',
        name: 'Squad Goals: Better Together',
        splitPayout: false,
        type: 'goal',
    },
    {
        _id: '66bbcec57074380012b09751',
        templateName: 'Clear Old Inventory',
        templateDescription:
            'Motivate employees to clear out your old inventory by offering a fixed commission for each unit sold. This clear and direct incentive helps ensure sellers focus on moving older stock quickly and efficiently.',
        totalDays: 30,
        accountType: 'brand',
        awards: [],
        description:
            'Clear out old stock and earn extra cash per unit. The more you clear, the more you earn!',
        detailedSparkType: 'commissionFlat',
        fulfillmentTypes: ['sparkplug'],
        goals: [],
        metric: 'total_units',
        minimumThresholdToQualify: 0,
        name: 'Inventory Blitz: Sell & Score',

        type: 'commission',
        commissionRules: {
            type: 'fixed',
            fixedValue: 0,
        },
    },
    {
        _id: '66bbcf235a78680012b8f8f6',
        templateName: 'Drive Monthly Re-Orders',
        templateDescription:
            "Incentivize retail teams to boost your product's re-orders by offering a per-unit commission. This approach rewards higher sales and encourages sustained engagement, crucial for maintaining regular orders.",
        totalDays: 30,
        accountType: 'brand',
        awards: [],
        description:
            'Rack up commissions on selected items! The more you sell, the bigger your payday!',
        detailedSparkType: 'commissionFlat',
        goals: [],
        metric: 'total_units',
        minimumThresholdToQualify: 0,
        name: 'Monthly Money-Maker',
        recurringSchedule: {
            interval: 'monthly',
        },
        type: 'commission',
        commissionRules: {
            type: 'fixed',
        },
    },
    {
        _id: '66bbcfc049e36f001190e52d',
        templateName: 'Win Market Share',
        templateDescription:
            "Motivate retail teams to compete location vs. location to capture more market share. This Spark encourages teamwork within each store, driving collective effort and maximizing your product's market penetration.",
        totalDays: 30,
        accountType: 'brand',
        awards: [
            {
                award: '$300',
            },
            {
                award: '$200',
            },
            {
                award: '$100',
            },
        ],
        description:
            'Compete by selling more of our featured products. Winning location scores big!',
        detailedSparkType: 'leaderboardLocation',
        fulfillmentTypes: ['sparkplug', 'sparkplug', 'sparkplug'],
        goals: [],
        metric: 'total_units',
        name: 'Battle of the Stores',
        splitPayout: true,
        type: 'leaderboard',
    },
    {
        _id: '66bbd01e7074380012b097d1',
        templateName: 'Increase Sales on Underperforming Products',
        templateDescription:
            'Encourage employees to focus on your underperforming products by setting individual sales targets. This strategy drives their salespeople to prioritize these items, helping to turn around low sales and improve overall performance.',
        totalDays: 30,
        accountType: 'brand',
        awards: [],
        description: 'Hit your targets and earn personalized rewards!',
        detailedSparkType: 'goal',
        fulfillmentTypes: ['sparkplug', 'sparkplug', 'sparkplug'],
        goals: [
            {
                threshold: 25,
                award: {
                    award: '$10',
                },
            },
            {
                threshold: 50,
                award: {
                    award: '$25',
                },
            },
            {
                threshold: 100,
                award: {
                    award: '$75',
                },
            },
        ],
        metric: 'total_units',
        name: 'Solo Sales Surge',
        type: 'goal',
    },
];
