import { ComponentProps, FC } from 'react';

import Checkbox from '@components/inputs/Checkbox';

import { appendClasses } from '@helpers/ui';

import { useIsInToolbar } from '../Toolbar/useIsInToolbar';
import ToolbarGroup from '../ToolbarGroup';

import './ToolbarCheckbox.scss';

interface ToolbarCheckboxProps extends ComponentProps<typeof Checkbox> {}

const ToolbarCheckbox: FC<ToolbarCheckboxProps> = ({ className, ...props }) => {
    useIsInToolbar({ componentName: 'Toolbar.Checkbox' });

    const classNameAppended = appendClasses([className, 'toolbar-checkbox']);

    return (
        <ToolbarGroup className={classNameAppended}>
            <Checkbox {...props} />
        </ToolbarGroup>
    );
};

export default ToolbarCheckbox;
