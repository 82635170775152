import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useResetPasswordRequestMutation, useResetPasswordSetMutation } from '@core/users';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import { ChevronLeft as BackIcon } from '@components/icons';
import Paper from '@components/layout/Paper';

import './ForgotPasswordView.scss';

const LoginView = () => {
    const history = useHistory();
    const [stage, setStage] = useState(0);
    const [email, setEmail] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [pwd, setPwd] = useState<string>('');
    const [pwdCheck, setPwdCheck] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | null>();
    const [successMessage, setSuccessMessage] = useState<string | null>();

    const { resetPasswordRequest } = useResetPasswordRequestMutation();
    const { resetPasswordSet } = useResetPasswordSetMutation();

    const updateValue = (value: any, setFn: (val: any) => void) => {
        setErrorMessage(null);
        setFn(value);
    };

    const onRequest = useCallback(() => {
        resetPasswordRequest(email, {
            onSuccess: () => {
                setStage(1);
                setErrorMessage(null);
            },
            onError: (err: any) => {
                if (err.response.status === 404) {
                    setErrorMessage('User not found.');
                } else {
                    setErrorMessage(err.message);
                }
                // eslint-disable-next-line no-console
                console.log(err.response);
            },
        });
    }, [email]);

    const onSet = useCallback(() => {
        resetPasswordSet(
            { email, code, pwd, pwdCheck },
            {
                onSuccess: () => {
                    setSuccessMessage('Your password has been updated.');
                },
                onError: (err: any) => {
                    setErrorMessage(err.message);
                },
            },
        );
    }, [email, code, pwd, pwdCheck]);

    const goToLogin = () => {
        history.push(`/login`);
    };

    return (
        <div className="forgot-password-view">
            <Paper className="login-container" elevation={5}>
                <Paper.Title className="paper-title">Reset Password</Paper.Title>
                {stage === 0 && (
                    <Form>
                        <Form.Description>
                            <p>No worries, we’ll send you reset instructions.</p>
                        </Form.Description>
                        <Form.TextField
                            label="Email"
                            name="email"
                            onChange={(event) => setEmail(event.target.value)}
                        />
                        {errorMessage != null && errorMessage.length > 0 && (
                            <Form.Description>
                                <p className="error-text">{errorMessage}</p>
                            </Form.Description>
                        )}
                        <Form.ActionGroup className="text-align-center" direction="vertical">
                            <Form.Button
                                disabled={email == null}
                                color="blue"
                                variant="smooth"
                                onClick={onRequest}
                            >
                                Request Reset Link
                            </Form.Button>
                            <Button
                                color="neutral"
                                variant="flat"
                                startIcon={<BackIcon />}
                                onClick={goToLogin}
                            >
                                Return to Log in
                            </Button>
                        </Form.ActionGroup>
                    </Form>
                )}

                {stage === 1 && (
                    <Form>
                        <Form.Description>
                            {email != null && email.length > 0 && (
                                <p>{`We sent a code to ${email}`}</p>
                            )}
                            <p>Please enter your reset code below and set your new password</p>
                        </Form.Description>
                        <Form.TextField
                            required
                            label="Code"
                            name="code"
                            onChange={(event) => updateValue(event.target.value, setCode)}
                        />
                        <Form.TextField
                            required
                            label="Password"
                            type="password"
                            name="password"
                            onChange={(event) => updateValue(event.target.value, setPwd)}
                        />
                        <Form.TextField
                            required
                            label="Confirm Password"
                            name="pwdCheck"
                            type="password"
                            onChange={(event) => updateValue(event.target.value, setPwdCheck)}
                        />
                        {errorMessage != null && errorMessage.length > 0 && (
                            <Form.Description>
                                <p className="error-text">{errorMessage}</p>
                            </Form.Description>
                        )}
                        {successMessage != null && successMessage.length > 0 && (
                            <Form.Description>
                                <p className="success-text">{successMessage}</p>
                            </Form.Description>
                        )}
                        <Form.ActionGroup className="text-align-center" direction="vertical">
                            <Form.Button
                                disabled={email == null}
                                color="blue"
                                variant="smooth"
                                onClick={onSet}
                            >
                                {successMessage != null && successMessage.length > 0
                                    ? 'Updated!'
                                    : 'Update Password'}
                            </Form.Button>
                            <Button
                                color="neutral"
                                variant="flat"
                                startIcon={<BackIcon />}
                                onClick={goToLogin}
                            >
                                Return to Log in
                            </Button>
                        </Form.ActionGroup>
                    </Form>
                )}
            </Paper>
        </div>
    );
};

export default LoginView;
