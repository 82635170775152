import { FC, useState } from 'react';

import { useUpdateAccountLink } from '@features/account-links/mutations';

import Checkbox from '@components/inputs/Checkbox';
import CalloutMessage from '@components/layout/CalloutMessage';
import Paper from '@components/layout/Paper';
import { SectionTitle } from '@components/layout/SectionTitle';
import toast from '@components/toast';

import { CheckedEvent } from '@app/types/UITypes';

import '../shared.scss';
import './DataSharing.scss';

interface DataSharingProps {
    accountName: string;
    vendorAccountId: string;
    accountId: string;
    accountLinkDisabled?: boolean;
    shareSalesData?: boolean;
    shareInventoryData?: boolean;
}

const DataSharing: FC<DataSharingProps> = ({
    accountName,
    accountId,
    accountLinkDisabled,
    vendorAccountId,
    shareSalesData: initialShareSalesData,
    shareInventoryData: initialShareInventoryData,
}) => {
    const [shareSalesData, setShareSalesData] = useState(initialShareSalesData ?? false);
    const [shareInventoryData, setShareInventoryData] = useState(
        initialShareInventoryData ?? false,
    );
    const { isUpdatingAccountLink, updateAccountLink } = useUpdateAccountLink(accountId);

    const dataSubtitle = `Control the POS data available for ${accountName} on their dashboard.`;
    const handleShareTotalSales = ({ target }: { target: { checked: boolean } }) => {
        const updatedShareSalesData = target.checked;

        setShareSalesData(updatedShareSalesData);
        updateAccountLink(
            {
                vendorAccountId,
                body: { shareSalesData: target.checked },
            },
            {
                onSuccess: () => {
                    toast.success(`Data sharing ${target.checked ? 'enabled' : 'disabled'}`);
                },
                onError: () => {
                    toast.error(
                        'Something went wrong - please try again and contact support if this issue persists',
                    );
                },
            },
        );
    };

    const handleShareInventoryData = ({ target }: CheckedEvent) => {
        const updatedShareInventoryData = target.checked;

        setShareInventoryData(updatedShareInventoryData);
        updateAccountLink(
            {
                vendorAccountId,
                body: { shareInventoryData: target.checked },
            },
            {
                onSuccess: () => {
                    toast.success(`Inventory sharing ${target.checked ? 'enabled' : 'disabled'}`);
                },
                onError: () => {
                    toast.error(
                        'Something went wrong - please try again and contact support if this issue persists',
                    );
                },
            },
        );
    };

    return (
        <section className="data-sharing">
            <SectionTitle>Data Sharing</SectionTitle>
            <CalloutMessage
                customIcon="padlock"
                className="data-sharing-callout"
                title="How does sharing data benefit my business?"
                message="SparkPlug uses enterprise-grade, bank-level security and encryption to give you full control over how your data is shared with your partners. Sharing accurate information with vendors enables more transparency and trust, resulting in more incentives and marketing investment that support your business."
            />
            <Paper className="flex flex-col p-5">
                <span className="pb-2 text-gray-900">{dataSubtitle}</span>
                <Checkbox
                    disabled={isUpdatingAccountLink || accountLinkDisabled}
                    label="Display to Vendor remaining inventory"
                    name="displayInventory"
                    value={shareInventoryData ?? false}
                    onChange={handleShareInventoryData}
                />
                <Checkbox
                    disabled={isUpdatingAccountLink || accountLinkDisabled}
                    label="Display to Vendor sales in units and retail value"
                    name="displaySales"
                    value={shareSalesData ?? false}
                    onChange={handleShareTotalSales}
                />
            </Paper>
        </section>
    );
};

export default DataSharing;
