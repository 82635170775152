import { FC } from 'react';

import { TrainingRequiredOptions, TrainingType } from '@constants/SparkConstants';
import { isNumber } from 'lodash';

import { CourseType, ITrainingCourse } from '@sparkplug/lib';

import Form from '@components/form/Form';
import CalloutMessage from '@components/layout/CalloutMessage';
import ClickableArea from '@components/layout/ClickableArea';

import Intercom from '@helpers/Intercom';

import { useCanEditCourse } from './useCanEditCourse';

import './TrainingCourseForm.scss';

const ContactUs = () => (
    <ClickableArea className="contact-us" onClick={() => Intercom.open()}>
        contact us
    </ClickableArea>
);

const CourseLabelRecord: Record<CourseType, string> = {
    zoltrain: 'ZolTrain',
    seedTalent: 'Seed Talent',
};

export interface TrainingCourseFormProps {
    onUpdate: Function;
    accountCanAddCourse?: boolean;
    accountCourseType?: CourseType;
    trainingEnabled?: boolean;
    courseData?: ITrainingCourse;
    canEditCourse: boolean;
}
export const TrainingCourseForm: FC<TrainingCourseFormProps> = ({
    onUpdate,
    accountCanAddCourse,
    accountCourseType,
    trainingEnabled,
    courseData = {} as ITrainingCourse,
    canEditCourse,
}) => {
    if ((!accountCanAddCourse || !accountCourseType) && !trainingEnabled) {
        return <></>;
    }

    const courseType = courseData.courseType ?? accountCourseType ?? 'zoltrain';
    const courseTypeLabel = CourseLabelRecord[courseType];
    const maxDescriptionLen = 180;
    const requiredValue = courseData.required ? TrainingType.required : TrainingType.optional;

    const onToggleEnabled = () => {
        onUpdate({
            trainingEnabled: !trainingEnabled,
            courseData: {
                ...courseData,
                courseType,
            },
        });
    };

    const courseIdLabel =
        courseType === 'seedTalent'
            ? `${courseTypeLabel} Course Reference ID`
            : `${courseTypeLabel} Course ID`;

    return (
        <div className="training-course-form">
            <Form.SectionTitle>{courseTypeLabel} Integration</Form.SectionTitle>
            {trainingEnabled && !canEditCourse && (
                <CalloutMessage
                    message={
                        <>
                            This course cannot be modified because the Spark has started. If you
                            need to turn off, update course type, course ID or access token, please{' '}
                            <ContactUs />.
                        </>
                    }
                />
            )}
            <Form.Switch
                disabled={!canEditCourse}
                name="trainingEnabled"
                value={Boolean(trainingEnabled)}
                label={`${courseTypeLabel} Course`}
                onChange={onToggleEnabled}
            />
            Turn on to link training to this Spark to enhance participant performance.
            <div>
                {trainingEnabled && (
                    <div className="training-course-details">
                        <Form.RadioGroup
                            disabled={!canEditCourse}
                            label="Course Type"
                            name="Course Type"
                            required
                            value={courseData.required === undefined ? undefined : requiredValue}
                            color="blue"
                            variant="grid"
                            options={TrainingRequiredOptions}
                            onChange={(event) => {
                                const option = event.target.value === TrainingType.required;
                                onUpdate({
                                    courseData: {
                                        ...courseData,
                                        required: option,
                                    },
                                });
                            }}
                        />
                        <Form.TextField
                            disabled={!canEditCourse}
                            label={courseIdLabel}
                            name="courseId"
                            defaultValue={courseData.courseId}
                            required
                            onChange={(event) => {
                                onUpdate({
                                    courseData: {
                                        ...courseData,
                                        courseId: event.target.value,
                                    },
                                });
                            }}
                        />
                        {courseType === 'zoltrain' && (
                            <Form.TextField
                                disabled={!canEditCourse}
                                label="ZolTrain Access Token"
                                name="accessToken"
                                defaultValue={courseData.accessToken}
                                required
                                onChange={(event) => {
                                    onUpdate({
                                        courseData: {
                                            ...courseData,
                                            accessToken: event.target.value,
                                        },
                                    });
                                }}
                            />
                        )}
                        <Form.TextField
                            label="Course Description"
                            name="courseDescription"
                            helperText={`${
                                courseData.courseDescription?.length || 0
                            } / ${maxDescriptionLen}`}
                            required
                            defaultValue={courseData.courseDescription}
                            onChange={(event) => {
                                onUpdate({
                                    courseData: {
                                        ...courseData,
                                        courseDescription: event.target.value.substr(
                                            0,
                                            maxDescriptionLen,
                                        ),
                                    },
                                });
                            }}
                            max={maxDescriptionLen}
                        />
                        <Form.TextField
                            label="Course Length (optional)"
                            name="courseLength"
                            defaultValue={courseData.courseLength}
                            onChange={(event) => {
                                const courseLength = Number(event.target.value);
                                onUpdate({
                                    courseData: {
                                        ...courseData,
                                        courseLength: isNumber(courseLength)
                                            ? courseLength
                                            : undefined,
                                    },
                                });
                            }}
                            endIcon="min"
                            type="number"
                            className="number-input"
                        />

                        <Form.HiddenField name="courseType" value={courseType} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default (props: Omit<TrainingCourseFormProps, 'canEditCourse'>) => {
    // Separated for storybook
    const canEditCourse = useCanEditCourse();

    return <TrainingCourseForm {...props} canEditCourse={canEditCourse} />;
};
