import axios from 'axios';

import { useAdvancedMutation } from '@hooks/QueryHooks';

const trainingCourseRouteBase = '/api/v1/training-course' as const;

const API = {
    courseNoticeViewed: async (
        trainingCourseId: string,
        externalTrainingCourseId: string,
        userId: string,
    ) => {
        return axios.post(`${trainingCourseRouteBase}/notice-viewed/${trainingCourseId}`, {
            userId,
            externalTrainingCourseId,
        });
    },
};

export const useTrainingCourseMutations = (sparkId: string) => {
    const { mutate: updateUserHasSeenCourse } = useAdvancedMutation(
        ({
            trainingCourseId,
            externalTrainingCourseId,
            userId,
        }: {
            trainingCourseId: string;
            externalTrainingCourseId: string;
            userId: string;
        }) => API.courseNoticeViewed(trainingCourseId, externalTrainingCourseId, userId),
        {
            updateQuery: { queryKey: ['spark', sparkId] },
        },
    );

    return { updateUserHasSeenCourse };
};
