import { keyBy, uniqBy } from 'lodash';
import { AccountMarket } from '../account';

export const DO_NOT_HAVE_PERMISSIONS_MESSAGE =
  "You don't have permission to use this feature. Please contact the account owner for assistance.";

/**
 * @description
 *
 * If any of these items are added, removed, or renamed, a migration will be required.
 * to add them to the `Permissions` collection in the database.
 *
 */
const PublicPermissions = [
  { resource: 'spark', action: 'read' },
  { resource: 'spark', action: 'create' },
  { resource: 'spark', action: 'update' },
  { resource: 'spark', action: 'destroy' },
  { resource: 'productTags', action: 'create' },
  { resource: 'productTags', action: 'read' },
  { resource: 'productTags', action: 'update' },
  { resource: 'productTags', action: 'destroy' },
  { resource: 'brandlinks', action: 'create' },
  { resource: 'brandlinks', action: 'read' },
  { resource: 'brandlinks', action: 'update' },
  { resource: 'brandlinks', action: 'destroy' },
  { resource: 'users', action: 'create' },
  { resource: 'users', action: 'read' },
  { resource: 'users', action: 'update' },
  { resource: 'users', action: 'destroy' },
  { resource: 'billingportal', action: 'destroy' },
] as const;
export type PublicPermission = typeof PublicPermissions[number];

/**
 * @description
 *
 * If any of these items are added, removed, or renamed, a migration will be required.
 * to add them and/or their permissions to the `Roles` collection in the database.
 *
 */
export const RoleNames = [
  'vendor-owner',
  'vendor-admin',
  'retailer-owner',
  'retailer-admin',
  'employee',
] as const;
export type RoleName = typeof RoleNames[number];

/**
 * @description
 *
 * If any of these items are added, removed, or renamed, a migration will be required.
 * to add them and/or their permissions to the `Roles` collection in the database.
 *
 */
export const RoleRecord: Record<RoleName, PublicPermission[]> = {
  'vendor-owner': [
    { resource: 'spark', action: 'create' },
    { resource: 'spark', action: 'read' },
    { resource: 'spark', action: 'update' },
    { resource: 'spark', action: 'destroy' },
    { resource: 'productTags', action: 'create' },
    { resource: 'productTags', action: 'read' },
    { resource: 'productTags', action: 'update' },
    { resource: 'productTags', action: 'destroy' },
    { resource: 'brandlinks', action: 'create' },
    { resource: 'brandlinks', action: 'read' },
    { resource: 'brandlinks', action: 'update' },
    { resource: 'brandlinks', action: 'destroy' },
    { resource: 'users', action: 'create' },
    { resource: 'users', action: 'read' },
    { resource: 'users', action: 'update' },
    { resource: 'users', action: 'destroy' },
    { resource: 'billingportal', action: 'destroy' },
  ],
  'vendor-admin': [
    { resource: 'spark', action: 'read' },
    { resource: 'productTags', action: 'read' },
    { resource: 'brandlinks', action: 'read' },
    { resource: 'users', action: 'read' },
  ],
  'retailer-owner': [
    { resource: 'spark', action: 'create' },
    { resource: 'spark', action: 'read' },
    { resource: 'spark', action: 'update' },
    { resource: 'spark', action: 'destroy' },
    { resource: 'brandlinks', action: 'create' },
    { resource: 'brandlinks', action: 'read' },
    { resource: 'brandlinks', action: 'update' },
    { resource: 'brandlinks', action: 'destroy' },
    { resource: 'users', action: 'create' },
    { resource: 'users', action: 'read' },
    { resource: 'users', action: 'update' },
    { resource: 'users', action: 'destroy' },
    { resource: 'billingportal', action: 'destroy' },
  ],
  'retailer-admin': [
    { resource: 'spark', action: 'read' },
    { resource: 'brandlinks', action: 'read' },
    { resource: 'users', action: 'create' },
    { resource: 'users', action: 'read' },
    { resource: 'users', action: 'update' },
    { resource: 'users', action: 'destroy' },
  ],
  employee: [
    { resource: 'spark', action: 'read' },
    { resource: 'brandlinks', action: 'read' },
    { resource: 'users', action: 'read' },
  ],
};

/**
 * @description
 *
 * If any of these items are added, removed, or renamed, a migration will be required.
 * to add them to the `Roles` collection in the database.
 *
 * The distinction between Policy and Role is for the exposure to the UI. Because policies
 * can be toggled on and off for individual users, we interact with them in codebase more
 * frequently than we do with roles.
 *
 */
export const Policies = [
  'manageSparks',
  'manageProductTags',
  'manageLinks',
  'manageUsers',
  'accessBilling',
] as const;
export type Policy = typeof Policies[number];

export const ValidRetailerPolicies: Policy[] = ['manageSparks', 'accessBilling', 'manageLinks'];
export const ValidVendorPolicies: Policy[] = [
  'manageSparks',
  'manageProductTags',
  'manageLinks',
  'manageUsers',
  'accessBilling',
];

/**
 * @description
 *
 * If any of these items are added, removed, or renamed, a migration will be required.
 * to add them and/or their permissions to the `Roles` collection in the database.
 *
 */
export const PolicyRecord: Record<Policy, PublicPermission[]> = {
  manageSparks: [
    // `read` is excluded because it's a default permission in for all roles
    { resource: 'spark', action: 'create' },
    { resource: 'spark', action: 'update' },
    { resource: 'spark', action: 'destroy' },
  ],
  manageProductTags: [
    // `read` is excluded because it's a default permission in for the vendor-admin role
    { resource: 'productTags', action: 'create' },
    { resource: 'productTags', action: 'update' },
    { resource: 'productTags', action: 'destroy' },
  ],
  manageLinks: [
    // `read` is excluded because it's a default permission in for all roles
    { resource: 'brandlinks', action: 'create' },
    { resource: 'brandlinks', action: 'update' },
    { resource: 'brandlinks', action: 'destroy' },
  ],
  manageUsers: [
    // `read` is excluded because it's a default permission in for all roles
    { resource: 'users', action: 'create' },
    { resource: 'users', action: 'update' },
    { resource: 'users', action: 'destroy' },
  ],
  accessBilling: [{ resource: 'billingportal', action: 'destroy' }],
};

export const getPoliciesByPermissions = (permissions: PublicPermission[]): Policy[] => {
  const permissionsKeyedByPair = keyBy(
    permissions,
    (permission) => `${permission.resource}:${permission.action}`,
  );

  const policies = Object.entries(PolicyRecord).reduce<Policy[]>(
    (res, [policy, groupedPermissions]) => {
      const hasAllPermissionsInPolicy = groupedPermissions.every(
        (permission) => permissionsKeyedByPair[`${permission.resource}:${permission.action}`],
      );

      if (hasAllPermissionsInPolicy) {
        res.push(policy as Policy);
      }
      return res;
    },
    [],
  );

  return policies;
};

export const getPermissionsByPolicy = (policy: Policy): PublicPermission[] => PolicyRecord[policy];

export const getPermissionsByPolicies = (policies: Policy[]): PublicPermission[] =>
  uniqBy(
    policies.flatMap(getPermissionsByPolicy),
    (permission) => `${permission.resource}:${permission.action}`,
  );

export interface GetMyPermissionsPathParams {
  accountId: string;
}
export type GetMyPermissionsResponseBody = {
  data: {
    permissions: PublicPermission[];
    markets?: AccountMarket[];
  };
};
