import { useMemo } from 'react';

import moment from 'moment';

import { UIEvent } from '@sparkplug/lib';

import Chip from '@components/chips/Chip';

import Typography from '@app/componentLibrary/Typography';

interface EventDetailsProps {
    event: UIEvent;
}

export const EventDetails = ({ event }: EventDetailsProps) => {
    const eventChip = useMemo(() => {
        if (event.isExpired) return <Chip label="Expired" color="neutral" />;
        if (event.requestStatus === 'pending')
            return <Chip label="Pending" color="neutral" variant="outlined" />;
        if (event.requestStatus === 'approved') {
            const daysUntilStart = moment(event.startTime).diff(moment().startOf('day'), 'days');
            const isToday = moment(event.startTime).isSame(moment(), 'day');
            if (isToday) return <Chip label="Active" color="green" />;
            if (daysUntilStart > 0)
                return <Chip label={`In ${daysUntilStart} Days`} color="yellow" />;
        }
        if (event.requestStatus === 'declined') return <Chip label="Declined" color="red" />;
        return undefined;
    }, [event]);

    const eventStartTime = moment(event.startTime).format('h:mm A');
    const eventEndTime = moment(event.endTime).format('h:mm A');
    const eventDate = moment(event.startTime).format('MMM D, YYYY');

    return (
        <div className="w-full flex items-center flex-col gap-6">
            <div className="event-details w-[428px] bg-white p-4 rounded-md shadow-md">
                <div className="event-details__header">
                    <div className="flex flex-row justify-between">
                        <img
                            className="h-[64px] w-[64px] rounded"
                            src={event.brand?.photo}
                            alt={event.brand?.name}
                        />
                        <div className="flex flex-col gap-1">
                            {event.brand?.name && <Chip label={event.brand.name} color="blue" />}
                            {eventChip}
                        </div>
                    </div>
                    <div className="pt-3 flex flex-col gap-1">
                        <Typography variant="sm" className="text-gray-700 font-semibold">
                            {event.type.toUpperCase()}
                        </Typography>
                        <Typography variant="xl" className="text-gray-950 font-bold">
                            {event.name}
                        </Typography>
                        <Typography variant="base" className="text-gray-900">
                            {eventStartTime} - {eventEndTime}
                        </Typography>
                        <Typography variant="base" className="text-gray-900">
                            {eventDate}
                        </Typography>
                        <Typography variant="base" className="text-gray-900">
                            {event.retailerAccount?.name} -{' '}
                            {event.locations.map((location) => location.name).join(', ')}
                        </Typography>
                        <Typography variant="lg" className="text-gray-950 font-semibold py-2">
                            Event Details
                        </Typography>
                        <Typography variant="base" className="text-gray-900">
                            {event.description}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};
