import { useEffect, useMemo, useState } from 'react';

import TrainingCourseView from '@views/training/TrainingCourseView';
import { clsx } from 'clsx';
import { noop } from 'lodash';
import moment from 'moment-timezone';

import {
    DetailedSparkType,
    ITrainingCourse,
    Spark,
    UISparkStanding,
    checkForTrainingComplete,
    isEligibleParticipant,
} from '@sparkplug/lib';

import { usePrefetchBaseEmployeeData } from '@core/employees/hooks';
import { useEmployeeSparkStandings } from '@core/employees/queries/EmployeeStandingsQueries';
import { useSparkProductLabels } from '@core/sparks/queries/SparkProductQueries';

import SparkSnapsViewer from '@features/spark-snaps/components/SparkSnapsViewer';

import { SparkProvider } from '@contexts/SparkContext';

import { UserChartsRow } from '@components/charts/UserCharts';
import { ChevronRight, StopwatchIcon, TrainingCompleteIcon } from '@components/icons';
import AppHeaderMobile from '@components/layout/AppHeaderMobile';
import PanelField from '@components/layout/PanelField';
import Paper from '@components/layout/Paper';
import Skeleton from '@components/layout/Skeleton';
import MobileInfoModal from '@components/overlays/MobileInfoModal';
import { useParams } from '@components/router';
import MinThresholdCallout from '@components/sparks/MinThresholdCallout';
import SparkAwardsPanel from '@components/sparks/SparkAwardsPanel';
import SparkCard from '@components/sparks/SparkCard';

import { useApp, useAppBodyClasses } from '@hooks/AppHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import { getChartMetricLabel } from '@helpers/charts';
import { sparkIsCompleted, userHasViewedTrainingPrompt } from '@helpers/sparks';

import { ChartLeader } from '@app/types/ChartDataTypes';

import { NewTrainingNoticeModal } from './NewTrainingNoticeModal';
import SearchableListModalMobile from './SearchableListModalMobile';
import { SparkObjectivePanel } from './SparkObjectivePanel';
import { useTrainingCourseMutations } from './mutations/TrainingCourseMutations';

import './SparkDetailsViewMobile.scss';

function TrainingDetailsPanel({
    spark,
    variant = 'raised',
    openOnLoad = false,
    onClose,
}: {
    spark: Spark;
    detailedSparkType?: DetailedSparkType;
    variant?: 'raised' | 'smooth';
    openOnLoad?: boolean;
    onClose?: Function;
}) {
    const { user } = useApp();
    const [trainingCourseVisible, setTrainingCourseVisible] = useState(openOnLoad);
    const userHasCompletedCourse = checkForTrainingComplete({
        userId: user?._id,
        courseData: spark.courseData,
    });

    useMemo(() => {
        if (openOnLoad) {
            setTrainingCourseVisible(openOnLoad);
        }
    }, [openOnLoad]);

    const headerIcon = useMemo(() => {
        if (userHasCompletedCourse) {
            return (
                <div>
                    <TrainingCompleteIcon fill="#38AC89" /> Complete
                </div>
            );
        } else if (spark.courseData?.courseLength) {
            return (
                <div>
                    <StopwatchIcon /> {spark.courseData.courseLength}min course
                </div>
            );
        }
        return <></>;
    }, [spark, user]);

    return (
        <>
            <Paper className="training-details-container" variant={variant}>
                <div className="training-header-container">
                    <Paper.Title>Training</Paper.Title>
                    {headerIcon}
                </div>
                <PanelField label="" value={spark.courseData?.courseDescription || ''} />

                <Paper.DetailsButton
                    color="blue"
                    variant="flat"
                    endIcon={<ChevronRight />}
                    onClick={() => setTrainingCourseVisible(true)}
                >
                    <>{userHasCompletedCourse ? 'Review' : 'Start'} Training Course</>
                </Paper.DetailsButton>
            </Paper>

            <MobileInfoModal
                title="Training"
                className="training-fullscreen-mobile"
                isVisible={trainingCourseVisible}
                onClose={() => {
                    setTrainingCourseVisible(false);
                    onClose?.();
                }}
                size="wide"
            >
                <TrainingCourseView
                    trainingCourseId={spark.trainingCourseId}
                    trainingCourseType={spark.courseData?.courseType}
                    externalTrainingCourseId={spark.courseData?.courseId || ''}
                    afterComplete={() => setTrainingCourseVisible(false)}
                />
            </MobileInfoModal>
        </>
    );
}

export function getPersonChartDataFromStanding({
    detailedSparkType,
    participantStanding,
}: {
    detailedSparkType: DetailedSparkType;
    participantStanding?: UISparkStanding;
}) {
    if (!participantStanding) {
        return undefined;
    }

    const person = {
        name:
            participantStanding.fullName ??
            `${participantStanding.firstName} ${participantStanding.lastName}`,
        value: participantStanding?.primaryValue,
        unitCount: participantStanding?.secondaryValue,
        transactionCount: participantStanding?.transactionCount,
    } as ChartLeader;

    const locations = participantStanding?.locationName || 'N/A';
    const rank = participantStanding?.rank;

    if (detailedSparkType === 'leaderboardLocation') {
        person.name = participantStanding.locationName ?? '';
    }

    if (['goalManager', 'goalTeam'].includes(detailedSparkType)) {
        person.name = 'All Locations';
    }

    return {
        person,
        locations,
        rank,
    };
}

interface SparkStandingsPanelProps {
    spark: Spark;
    currentUserStanding?: UISparkStanding;
    sparkStandings: UISparkStanding[];
    sparkStandingsAreReady: boolean;
}
function SparkStandingsPanel({
    spark,
    currentUserStanding,
    sparkStandings,
    sparkStandingsAreReady,
}: SparkStandingsPanelProps) {
    const [modalIsVisible, setModalIsVisible] = useState(false);

    const minThreshold = spark?.minimumThresholdToQualify;
    const minTransactionCount = spark?.minimumTransactionsToQualify;
    const topIneligibleParticipantId = useMemo(() => {
        return sparkStandings.find(
            ({ primaryValue, transactionCount }) =>
                !isEligibleParticipant({
                    participant: { value: primaryValue, transactionCount },
                    minThreshold,
                    minTransactionCount,
                }),
        )?.flexibleEmployeeId;
    }, [minThreshold, minTransactionCount, sparkStandings]);

    const currentUserIsEligible = isEligibleParticipant({
        participant: {
            value: currentUserStanding?.primaryValue,
            transactionCount: currentUserStanding?.transactionCount,
        },
        minThreshold,
        minTransactionCount,
    });

    const metric = spark?.type === 'commission' ? 'commission' : spark?.metric;
    const isTeamGoal = spark?.type === 'goal' && spark?.goalType === 'team';
    const isManagerGoal = spark.detailedSparkType === 'goalManager';
    const isCollectiveGoal = isTeamGoal || isManagerGoal;
    const isThreshold = spark?.goals?.length > 0;
    const userChartData = getPersonChartDataFromStanding({
        detailedSparkType: spark.detailedSparkType!,
        participantStanding: currentUserStanding,
    });

    let moreBtnText = 'View Full Leaderboard';

    if (spark?.type === 'goal') {
        moreBtnText = 'View Full Standings';
    }

    if (spark?.type === 'commission') {
        moreBtnText = 'View All Commission Results';
    }

    const isComplete = sparkIsCompleted(spark);

    const ParticipantRow = ({ listItem }: { listItem: UISparkStanding }) => {
        const { person, locations, rank } =
            getPersonChartDataFromStanding({
                detailedSparkType: spark.detailedSparkType!,
                participantStanding: listItem,
            }) ?? {};

        const showRanks = isEligibleParticipant({
            participant: {
                value: listItem?.primaryValue,
                transactionCount: listItem?.transactionCount,
            },
            minThreshold,
            minTransactionCount,
        });

        if (!person || !locations) {
            return null;
        }

        return (
            <UserChartsRow
                highlight={listItem?.flexibleEmployeeId === currentUserStanding?.flexibleEmployeeId}
                rank={rank}
                showRanks={showRanks}
                person={person}
                locations={locations}
                isThreshold={isThreshold}
                type="bar"
                maximumIndividualLeaderValue={0}
                metric={metric}
                percentIncreaseComparison={listItem?.percentIncreaseComparison}
                goals={spark?.goals}
                hideAvatar
                hideLocations={spark?.detailedSparkType === 'leaderboardLocation'}
                spark={spark}
            />
        );
    };

    return (
        <>
            <Paper className="user-standings-container" variant="raised">
                <Paper.Title>{isComplete ? 'Final' : 'Current'} Standing</Paper.Title>

                {!sparkStandingsAreReady && (
                    <Skeleton height={180} width="100%" className="user-standings-skeleton" />
                )}

                {sparkStandingsAreReady && userChartData && (
                    <>
                        <UserChartsRow
                            rank={userChartData?.rank}
                            person={userChartData?.person}
                            locations={userChartData?.locations}
                            isThreshold={isThreshold}
                            type="bar"
                            maximumIndividualLeaderValue={0}
                            metric={metric}
                            percentIncreaseComparison={
                                currentUserStanding?.percentIncreaseComparison
                            }
                            goals={spark?.goals}
                            showRanks={!isCollectiveGoal && currentUserIsEligible}
                            hideAvatar
                            hideLocations={spark?.detailedSparkType === 'leaderboardLocation'}
                            spark={spark}
                        />

                        {!isCollectiveGoal && (
                            <Paper.DetailsButton
                                color="blue"
                                variant="flat"
                                endIcon={<ChevronRight />}
                                onClick={() => setModalIsVisible(true)}
                            >
                                {moreBtnText}
                            </Paper.DetailsButton>
                        )}
                    </>
                )}

                {sparkStandingsAreReady && !userChartData && <div>No Data</div>}
            </Paper>

            <SearchableListModalMobile
                className={clsx(
                    'spark-leaderboard-modal',
                    spark?.type === 'goal' && 'goal-standings',
                )}
                isVisible={modalIsVisible}
                onClose={() => setModalIsVisible(false)}
                title={spark?.name}
                listTitle={
                    <section className="spark-leaderboard-modal_header">
                        <div>Full Leaderboard</div>
                        <span>{getChartMetricLabel(metric, spark?.percentIncreaseData)}</span>
                    </section>
                }
                separator={{
                    listItemId: topIneligibleParticipantId,
                    element: <MinThresholdCallout className="employee-flow-min-threshold" />,
                }}
                list={sparkStandings}
                ListItemComponent={ParticipantRow}
                searchFields={['fullName', 'firstName', 'lastName', 'locationName']}
            />
        </>
    );
}

export const SparkDetailsViewMobile = () => {
    const { history, user, appIsReady } = useApp();

    const { sparkIsReady, detailedSparkType, spark } = useSpark();

    const sparkHasStarted = moment().isSameOrAfter(moment(spark?.startDate));

    const { sparkProductLabels = [], sparkProductLabelsAreReady } = useSparkProductLabels(
        spark?._id,
    );

    const {
        sparkStandings = [],
        currentUserStanding,
        sparkStandingsAreReady,
    } = useEmployeeSparkStandings(spark?._id, { enabled: sparkHasStarted });

    usePrefetchBaseEmployeeData({
        canPrefetch:
            sparkIsReady &&
            appIsReady &&
            sparkProductLabelsAreReady &&
            (sparkStandingsAreReady || !sparkHasStarted),
    });

    useAppBodyClasses(['app-content-noPadding'], []);

    const [newTrainingPromptVisible, setNewTrainingPromptVisible] = useState(false);
    const [openTrainingModalOnLoad, setOpenTrainingModalOnLoad] = useState(false);

    const { updateUserHasSeenCourse } = useTrainingCourseMutations(spark._id);

    const userHasCompletedCourse = checkForTrainingComplete({
        userId: user?._id,
        courseData: spark.courseData,
    });

    useEffect(() => {
        // Note: we useEffect here instead of just setting initial state directly in useState because the spark data is likely not available immediately on first render
        if (spark.trainingEnabled) {
            if (spark.courseData?.required) {
                setNewTrainingPromptVisible(!userHasCompletedCourse);
            } else {
                setNewTrainingPromptVisible(
                    !userHasViewedTrainingPrompt({ user, courseData: spark.courseData }),
                );
            }

            const { courseId } = spark.courseData!;
            updateUserHasSeenCourse({
                trainingCourseId: spark.trainingCourseId!,
                externalTrainingCourseId: courseId,
                userId: user!._id,
            });
        }
    }, [spark, userHasCompletedCourse]);

    const prevComponent = history?.location?.state?.prevTitle || 'Active Sparks';
    const back = {
        to: history?.location?.state?.prevUrl || '/user/sparks',
        component: prevComponent === 'complete Sparks' ? 'Completed Sparks' : prevComponent,
    };

    const { courseDescription, courseLength } =
        spark.trainingEnabled && !!spark.courseData ? spark.courseData : ({} as ITrainingCourse);

    const mustCompleteRequiredTraining =
        !!spark.trainingEnabled && !!spark.courseData?.required && !userHasCompletedCourse;

    return (
        <div className={`spark-details-view-mobile spark-type-${spark?.type}`}>
            <AppHeaderMobile hideAppFooter back={back} />

            {sparkIsReady && appIsReady ? (
                <>
                    <SparkCard isUserSparkDetailsView spark={spark} onSparkCardSelected={noop} />

                    {spark?.snaps?.creatorHasSnapsEntitlement && (
                        <Paper className="spark-details-snaps-panel" variant="rasised">
                            <Paper.Title>Spark Snaps</Paper.Title>
                            <SparkSnapsViewer spark={spark} />
                        </Paper>
                    )}

                    <SparkObjectivePanel
                        spark={spark}
                        detailedSparkType={detailedSparkType}
                        associatedProducts={sparkProductLabels}
                        isLoading={!sparkProductLabelsAreReady}
                    />

                    {spark.trainingEnabled && (
                        <TrainingDetailsPanel
                            spark={spark}
                            openOnLoad={openTrainingModalOnLoad}
                            onClose={() => {
                                if (mustCompleteRequiredTraining) {
                                    history.push('/user/sparks');
                                }
                            }}
                        />
                    )}

                    {['goal', 'leaderboard'].includes(spark.type) && (
                        <SparkAwardsPanel spark={spark} isUserSparkDetailsView />
                    )}

                    {sparkHasStarted && (
                        <SparkStandingsPanel
                            spark={spark}
                            currentUserStanding={currentUserStanding}
                            sparkStandings={sparkStandings}
                            sparkStandingsAreReady={sparkStandingsAreReady}
                        />
                    )}

                    <NewTrainingNoticeModal
                        mustCompleteRequiredTraining={mustCompleteRequiredTraining}
                        isVisible={newTrainingPromptVisible}
                        onClose={() => setNewTrainingPromptVisible(false)}
                        courseDescription={courseDescription}
                        courseLength={courseLength}
                        onConfirm={() => setOpenTrainingModalOnLoad(true)}
                    />
                </>
            ) : (
                <div className="spark-details-skeletons-container">
                    <>
                        <div className="spark-details-skeleton-header">
                            <div>
                                <Skeleton className="icon-skeleton" height={68} width={68} />
                                <Skeleton width={70} />
                            </div>
                        </div>
                        <Skeleton width="40%" />
                        <Skeleton width="75%" />
                        <Skeleton height={32} />
                    </>
                    <div className="spark-details-skeleton-body">
                        <Skeleton height={230} />
                        <Skeleton height={230} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default () => {
    const { sparkId } = useParams<{ sparkId: string }>();
    return (
        <SparkProvider sparkId={sparkId}>
            <SparkDetailsViewMobile />
        </SparkProvider>
    );
};
