import { z } from 'zod';
import { SparkRequestState } from '../spark';
import { PagedApiResponseBody } from '../http';
import { AccountMarket } from '../account';

/**
 * Copied from Storifyme docs: https://docs.storifyme.com/api#/operations/getSnapById
 *
 * NOTE: does not include all properties, just the ones we interact with
 * */
export type StorifymeSnap = {
  id: number;
  name: string;
  url: string;
  original_poster: string; // This is the story image
  created_at: Date;
  updated_at: Date;
  configuration?: {
    pages?: { id: string; name: string; [key: string]: any }[];
    [key: string]: any;
  }; // Not provided explicitly by storifyme
};

/** Get Account Snap SDK Credentials to use in the UI */
export type GetAccountSnapSdkCredentialsRequestParams = {
  accountId: string;
};

/**
 * The `accountId` and `sdkKey` are used to authenticate with the Storifyme Web SDK
 */
export type GetAccountSnapSdkCredentialsResponseBody = {
  // The `accountId` in storifyme
  accountId: string;
  sdkKey: string;
};

export type GetAccountSDKTokenResponseBody = {
  token: string;
  msUntilExpiration: number;
};

/** Capture Storifyme Snap Event */
export type CaptureStorifymeSnapEventRequestParams = {
  /**
   * Sparkplug accountId
   */
  accountId: string;
};
export type CaptureStorifymeSnapEventRequestQueryParams = {
  /**
   * The event type
   */
  action: string;

  /**
   * The snap id
   */
  story_id: string;

  /**
   * The Storifyme account id
   */
  account_id: string;

  /**
   * The userId
   */
  cid?: string;

  /**
   * The sparkId
   */
  pid?: string;

  /**
   * There are other properties that can be sent to Storifyme, but we don't know what they are yet
   */
  [key: string]: string | undefined;
};
export type CaptureStorifymeSnapEventResponseBody = void;
type BaseSparkSnap = {
  id: number;
  name: string;
  posterImg: string;
  createdAt: string;
  iframeUrl: string;
  spark: {
    _id: string;
    name: string;
    startDate: string;
    endDate: string;
    retailerAccountId: string;
    requestState?: SparkRequestState;
    retailer: {
      name: string;
    };
    sparkBrandId: string;
    sparkBrand: {
      name: string;
    };
  };
  /** The aggregate time a snap has been viewed in seconds */
  viewTime?: number;
  /**
   * The number of times the snap has been viewed. This is currently `undefined` because we
   * can't easily capture this data from Storifymy yet. This data is pending the implementation
   * of their data tracking API.
   */
  viewCount?: number;
  /**
   * The average read time for the snap. This is currently `undefined` because we
   * can't easily capture this data from Storifymy yet. This data is pending the implementation
   * of their data tracking API.
   */
  avgReadTime?: number;
};

export type SparkSnap = Omit<BaseSparkSnap, 'id'> & {
  _id: string;
  storifymeSnapId: number;
};
export const FeaturedPeriodSchema = z
  .object({
    _id: z.string().optional(),
    // Accept dates as ISO 8601 strings
    startDate: z.string().datetime().optional(),
    endDate: z.string().datetime().optional(),
  })
  .optional();

export type FeaturedPeriod = z.infer<typeof FeaturedPeriodSchema>;
export type ISnap = {
  _id?: string;
  storifymeSnapId?: number;
  storifymeAccountId?: string;
  thumbnailUrl?: string;
  iframeUrl?: string;
  accountId?: string;
  createdBy?: string;
  updatedBy?: string;
  templateId?: string;
  name?: string;
  createdAt?: Date;
  updatedAt?: Date;
  totalPages?: number;
  deletedAt?: Date;
  deletedBy?: string;
  featuredPeriods?: FeaturedPeriod[];
  brandId?: string;
  brandPhoto?: string;
  accountPhoto?: string;
  viewTime?: number;
  cumulativeViewCount?: number;
  uniqueViewCount?: number;
  brandIsEnabled?: boolean;
  markets?: AccountMarket[];
};
/** List Snaps */
export type ListAccountSnapsRequestParams = {
  accountId: string;
};
export type ListAccountSnapsRequestQueryParams = {
  featured?: boolean;
};

export type ListAccountSparkSnapsResponseBody = {
  snaps: SparkSnap[];
};
export type ListAccountSnapsResponseBody = {
  snaps: ISnap[];
};
/** Get Snap */
export type StorifymeThumbnailStatus = 'ready' | 'failed';

export const getSnapByStorifymeAccountIdRequestParamsSchema = z.object({
  storifymeAccountId: z.string(),
  snapId: z.string(),
});
export type GetSnapRequestParams = z.infer<typeof getSnapByStorifymeAccountIdRequestParamsSchema>;

export type GetSnapResponseBody = {
  snap: ISnap;
  thumbnailStatus: StorifymeThumbnailStatus;
  thumbnailUrl: string;
};

export type GetAccountSnapRequestParams = {
  accountId: string;
  snapId: string;
};

export const updateAccountSnapRequestParamsSchema = z.object({
  accountId: z.string(),
  snapId: z.string(),
});

/** Update Snap */
export type UpdateAccountSnapRequestParams = z.infer<typeof updateAccountSnapRequestParamsSchema>;

export const updateAccountSnapRequestBodySchema = z.object({
  name: z.string().optional(),
  brandId: z.string().optional(),
  featuredPeriod: FeaturedPeriodSchema,
});
export type UpdateAccountSnapRequestBody = z.infer<typeof updateAccountSnapRequestBodySchema>;
export type UpdateAccountSnapResponseBody = ISnap | null;

/**
 * Copied from email with Storifyme. This needs to be expanded based on what we discover in the network tab
 * */
export type StorifymeSnapTemplate = {
  id: number;
  name: string;
  preview_img: string;
  updated_at: Date;
};

export type PublicSnapTemplate = {
  id: number;
  sourceSnapId: number;
  name: string;
  previewImg: string;
  previewImgStatus?: StorifymeThumbnailStatus;
  slideCount: number;
  configuration: any;
  updated_at: Date;
  /** Storifyme uses the raw template data to load templates into the editor */
  rawStorifymeTemplate: any;
};

export type ListEngagementEventCSVRequestParams = { accountId: string; snapId: string };
export type ListEngagementEventCSVResponseBody = {
  spark_id: string;
  user_id: string;
  action: string;
  [key: string]: string | number;
}[];

/** List Account Snap Templates */
export type ListAccountSnapTemplatesRequestParams = {
  accountId: string;
};
export type ListAccountSnapTemplatesResponseBody = {
  snapTemplates: PublicSnapTemplate[];
};

/** Global templates are available across all accounts */
export type ListRawGlobalSnapTemplatesResponseBody = {
  globalTemplates: StorifymeSnapTemplate[];
};

export type GetAccountSnapTemplateRequestParams = {
  accountId: string;
  templateId: string;
};
export type GetAccountSnapTemplateResponseBody = {
  snapTemplate: PublicSnapTemplate;
};

/** Create Account Snap Template */
export type CreateAccountSnapTemplateRequestParams = {
  accountId: string;
};
export const createAccountSnapTemplateRequestBodySchema = z.object({
  snapId: z.number(),
});
export type CreateAccountSnapTemplateRequestBody = z.infer<
  typeof createAccountSnapTemplateRequestBodySchema
>;

/** Update Account Snap Template */
export type UpdateAccountSnapTemplateRequestParams = {
  accountId: string;
  snapTemplateId: string;
};
export const updateAccountSnapTemplateRequestBodySchema = z.object({
  snapId: z.number(),
});
export type UpdateAccountSnapTemplateRequestBody = z.infer<
  typeof updateAccountSnapTemplateRequestBodySchema
>;
export type UpdateAccountSnapTemplateResponseBody = void;

/** Update Account Snap Template */
export type DeleteAccountSnapTemplateRequestParams = {
  accountId: string;
  snapTemplateId: string;
};
export type DeleteAccountSnapTemplateResponseBody = void;

export type CreateAccountSnapTemplateResponseBody = void;

/** Create Account Snap  */
export const createAccountSnapRequestBodySchema = z.object({
  brandId: z.string().optional(),
  featuredPeriod: z
    .object({
      // Accept dates as ISO 8601 strings
      startDate: z.string().datetime().optional(),
      endDate: z.string().datetime().optional(),
    })
    .optional(),
  // TODO: fix this schema to be more specific
  markets: z.array(z.string()).optional(),
});
export const createAccountSnapRequestParamsSchema = z.object({
  accountId: z.string(),
  snapId: z.string(),
});
export type CreateAccountSnapRequestParams = z.infer<typeof createAccountSnapRequestParamsSchema>;
export type CreateAccountSnapRequestBody = z.infer<typeof createAccountSnapRequestBodySchema>;
export type CreateAccountSnapResponseBody = ISnap | null;

export const listSnapEventsRequestParamsSchema = z.object({
  snapIds: z.union([z.string(), z.array(z.string())]).optional(),
  userIds: z.union([z.string(), z.array(z.string())]).optional(),
  eventTypes: z.union([z.string(), z.array(z.string())]).optional(),
  limit: z.string().optional(),
  offset: z.string().optional(),
  sort: z.string().optional(),
  order: z.enum(['asc', 'desc']).optional(),
});

export type SnapEngagementEventProperties = {
  eventType: string;
  snapId: number;
  sparkId: string;
  userId: string;
  storifymeAccountId: string;
  /**
   * The raw data of the event.
   */
  data: Record<string, unknown> & { value: string };
};

export type ListSnapEventsResponseBody = PagedApiResponseBody<SnapEngagementEventProperties>;

export type ListSnapEventsRequestParams = z.infer<typeof listSnapEventsRequestParamsSchema>;

export const deleteAccountSnapRequestParamsSchema = z.object({
  accountId: z.string(),
  snapId: z.string(),
});
export type DeleteAccountSnapRequestParams = z.infer<typeof deleteAccountSnapRequestParamsSchema>;
export type DeleteAccountSnapResponseBody = void;

export const removeSparkSnapRequestParamSchema = z.object({
  accountId: z.string().min(1),
  sparkId: z.string().min(1),
  snapId: z
    .string()
    .min(1)
    .refine((val) => !isNaN(Number(val)), {
      message: 'snapId must be a number',
    }),
});
export type RemoveSparkSnapRequestParams = z.infer<typeof removeSparkSnapRequestParamSchema>;
