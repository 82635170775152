import { FC, ReactElement } from 'react';

import { RetailerIcon2 } from '@components/icons';

import './BrandDashboardHeader.scss';

interface BrandDashboardHeaderProps {
    brandName: string;
    retailerName: string;
    icon?: ReactElement;
}
export const BrandDashboardHeader: FC<BrandDashboardHeaderProps> = ({
    brandName,
    retailerName,
    icon,
}) => (
    <header className="brand-dashboard-header">
        <section className="brand-name">
            <span>{`${brandName} Dashboard`}</span>
        </section>

        <section className="retailer-name">
            {icon ?? <RetailerIcon2 className="retailer-icon" />}
            <span>{retailerName}</span>
        </section>
    </header>
);
