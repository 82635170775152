import { FC, useState } from 'react';

import clsx from 'clsx';

import { PlayCircle } from '@components/icons';

import './SparkSnapIcon.scss';

interface SparkSnapIconProps {
    imgUrl?: string;
    title: string;
    viewed?: boolean;
    variant?: 'default' | 'flashy';
}

const SparkSnapIcon: FC<SparkSnapIconProps> = ({ imgUrl, title, viewed, variant = 'default' }) => {
    const [imgUrlError, setImgUrlError] = useState(false);

    const hasValidImgUrl = imgUrl && !imgUrlError;

    return (
        <div
            className={clsx('spark-snap-icon', {
                '__snaps-viewed': viewed,
                [`__variant-${variant}`]: true,
            })}
        >
            {hasValidImgUrl ? (
                <img src={imgUrl} alt={title} onError={() => setImgUrlError(true)} />
            ) : (
                <div className="snap-fallback">
                    <div>
                        <PlayCircle style={{ fontSize: '24px' }} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default SparkSnapIcon;
