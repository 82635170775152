import { FC, useEffect, useMemo, useState } from 'react';

import { Markets } from '@views/admin/AdminEmployeesView/VendorMembersTable/constants';
import { clsx } from 'clsx';
import { isEmpty } from 'lodash';

import { AccountMarket } from '@sparkplug/lib';

import {
    useSaveAccountUserMutation,
    useSendResetPasswordEmailRequest,
    useSetActiveMutation,
} from '@core/users';

import Button from '@components/buttons/Button';
import Dropdown from '@components/dropdown/Dropdown';
import Form from '@components/form/Form';
import { BookIcon, MoreHoriz, RemoveCircleOutline, ResetIcon, Send } from '@components/icons';
import InputHelperText from '@components/inputs/InputHelperText';
import CalloutMessage from '@components/layout/CalloutMessage';
import ClickableArea from '@components/layout/ClickableArea';
import AccountUserConflictModal from '@components/overlays/CreateEditUserModal/CreateEditAccountUserModal/AccountUserConflictModal';
import Drawer from '@components/overlays/Drawer';
import toast from '@components/toast';

import { useApp } from '@hooks/AppHooks';
import {
    useSparkplugAccount,
    useSparkplugAccountUsers,
} from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import Intercom from '@helpers/Intercom';

import { IAccount } from '@app/types/AccountsTypes';
import { CheckedEvent, InputEvent } from '@app/types/UITypes';
import { IAccountUser, TAccountUserRoles } from '@app/types/UsersTypes';

import './CreateEditMemberDrawer.scss';

/**
 * @param userMarketPermissions - An empty array represents an 'all markets' permission
 * - An `undefined` value represents an unset market permission - which is specific to the "Invite New Member" flow
 */
const formatMarketPermissionsValue = (
    userMarketPermissions?: AccountMarket[],
): (AccountMarket | 'all')[] | undefined => {
    if (!userMarketPermissions) return undefined;
    return !userMarketPermissions?.length ? ['all'] : userMarketPermissions;
};

interface IUpdatedUserData {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    role: TAccountUserRoles;
    permissions?: {
        manageSparks?: boolean;
        manageProductTags?: boolean;
        manageLinks?: boolean;
        manageUsers?: boolean;
        accessBilling?: boolean;
        markets?: AccountMarket[];
        locationIds?: string[];
    };
}

export interface CreateEditMemberDrawerProps {
    isVisible: boolean;
    onClose: (doActionOnClose?: boolean) => void;
    markets: { label: string; value: any }[];
    account: IAccount;
    handleSetInactive: (user: IAccountUser) => void;
    userId?: string;
}

const newUserData = {
    permissions: {
        manageSparks: true,
        manageProductTags: false,
        manageLinks: false,
        manageUsers: false,
        accessBilling: false,
        markets: undefined,
    },
} as Partial<IUpdatedUserData>;

const CreateEditMemberDrawer: FC<CreateEditMemberDrawerProps> = ({
    isVisible,
    onClose,
    markets,
    account,
    userId: existingUserId,
    handleSetInactive,
}) => {
    // This feature flag doesn't actually exist but I dont want to delete code that might be used in the future
    const displayProductTagDetails = import.meta.env.REACT_APP_DISPLAY_PRODUCT_TAGS === 'true';

    const { user: userPerformingAction } = useApp();
    const { userCan } = useSparkplugAccount();
    const { saveAccountUser } = useSaveAccountUserMutation(account ?? ({} as IAccount));
    const { sendResetPasswordEmailRequest } = useSendResetPasswordEmailRequest();
    const [submitted, setSubmitted] = useState(false);
    const { setActive } = useSetActiveMutation(account?._id as string);
    const { accountUsers, refetchAccountUsers, accountUsersAreReady } = useSparkplugAccountUsers();
    const [conflictModalIsVisible, setConflictModalIsVisible] = useState(false);
    const [updatedUserData, setUpdatedUserData] = useState<Partial<IUpdatedUserData>>({});
    const existingUser = useMemo<IAccountUser | undefined>(() => {
        if (!accountUsersAreReady || !existingUserId) {
            return undefined;
        }
        return accountUsers.find((user) => user.userId === existingUserId);
    }, [existingUserId, accountUsersAreReady, accountUsers]);

    useEffect(() => {
        setUpdatedUserData({
            ...(existingUser ?? newUserData),
            // always save vendor members as admins
            role: 'group-admin',
        });
    }, [existingUserId, accountUsersAreReady, isVisible]);

    const closeDrawer = (refetchOnClose: boolean) => {
        setUpdatedUserData({});
        setSubmitted(false);
        onClose(refetchOnClose);
    };
    const onSetActive = () => {
        if (!existingUser?.userId) {
            toast.success('No user to reactivate');
            return;
        }
        setActive(
            {
                userId: existingUser?.userId,
                role: 'group-admin',
            },
            {
                onSuccess: () => refetchAccountUsers(),
            },
        );
    };
    const onSubmit = () => {
        if (!updatedUserData.firstName || !updatedUserData.lastName || !updatedUserData.email) {
            setSubmitted(true);
            toast.error(
                'Please fill out all required fields - missing fields are highlighted in red.',
            );
            return;
        }

        saveAccountUser(
            { userData: updatedUserData, userId: existingUser?.userId },
            {
                onSuccess: () => closeDrawer(true),
                onError: (err: any) => {
                    const isConflict = err?.response?.status === 409;
                    const isAdmin = updatedUserData.role === 'group-admin';
                    const isEmailUpdate = !!updatedUserData.email;

                    if (isConflict && isAdmin && isEmailUpdate) {
                        setConflictModalIsVisible(true);
                    } else {
                        toast.error(
                            'Something went wrong - please try again and contact support if this issue persists.',
                        );
                    }
                },
            },
        );
    };

    const marketOptions = useMemo(() => {
        if (markets.length > 1) {
            return [...Markets, ...markets];
        }
        return markets;
    }, [markets]);

    const getLabelText = (value: boolean) => {
        return value ? 'On' : 'Off';
    };

    const updateUserData = (key: string) => {
        return (event: InputEvent) => {
            setUpdatedUserData((prevState) => {
                return {
                    ...prevState,
                    [key]: event.target.value,
                };
            });
        };
    };

    const updateUserPermission = (key: string) => {
        return (event: CheckedEvent) => {
            setUpdatedUserData((prevState) => {
                return {
                    ...prevState,
                    permissions: { ...prevState.permissions, [key]: event.target.checked },
                };
            });
        };
    };

    const isEditingOwner =
        !!account?.assignedOwnerUserId && account?.assignedOwnerUserId === existingUserId;
    const userIsInactive = existingUser?.role === 'none';
    const disablingConditions = isEditingOwner || userIsInactive || !userCan('manageUsers');
    const resendResetPasswordButton = (row: Partial<IUpdatedUserData>) => {
        if (!row.email || userIsInactive || !existingUserId) {
            return undefined;
        }

        return (
            <Dropdown className="user-dropdown">
                <Dropdown.IconButton>
                    <MoreHoriz />
                </Dropdown.IconButton>
                <Dropdown.Menu>
                    <Dropdown.MenuItem
                        hidden={row.email == null}
                        StartIcon={Send}
                        onClick={() => sendResetPasswordEmailRequest(existingUserId)}
                    >
                        Re-send reset password email
                    </Dropdown.MenuItem>
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    return (
        <>
            <Drawer
                title={`${existingUser ? 'Edit' : 'Invite New'} Member`}
                variant="right"
                isVisible={isVisible}
                className="invite-new-member-drawer"
                onCloseHandler={closeDrawer}
            >
                <Form className="invite-new-member-form">
                    {userIsInactive && (
                        <CalloutMessage
                            message={
                                <>
                                    This member is <strong>Inactive</strong>. They cannot login or
                                    access account information.
                                    <ClickableArea
                                        className="clickable-area"
                                        onClick={() => {
                                            onSetActive();
                                        }}
                                    >
                                        Reactivate
                                    </ClickableArea>{' '}
                                    this member to enable access.
                                </>
                            }
                        />
                    )}

                    {isEditingOwner && (
                        <CalloutMessage
                            message={
                                <>
                                    This user is the account <span>Owner</span>, their details and
                                    permissions cannot be modified.
                                    <ClickableArea
                                        className="clickable-area"
                                        onClick={() => {
                                            Intercom.open();
                                            onClose();
                                        }}
                                    >
                                        Contact support
                                    </ClickableArea>
                                    &nbsp;to make any adjustments
                                </>
                            }
                        />
                    )}

                    <Form.SectionTitle>Member Profile</Form.SectionTitle>
                    <Form.Grid>
                        <Form.GridItem xs={12} sm={6}>
                            <Form.TextField
                                label="First Name"
                                name="firstName"
                                disabled={isEditingOwner || userIsInactive}
                                required
                                error={submitted && !updatedUserData.firstName}
                                value={updatedUserData.firstName ?? ''}
                                onChange={updateUserData('firstName')}
                            />
                        </Form.GridItem>

                        <Form.GridItem xs={12} sm={6}>
                            <Form.TextField
                                label="Last Name"
                                name="lastName"
                                disabled={isEditingOwner || userIsInactive}
                                value={updatedUserData.lastName ?? ''}
                                required
                                error={submitted && !updatedUserData.lastName}
                                onChange={updateUserData('lastName')}
                            />
                        </Form.GridItem>
                    </Form.Grid>

                    <Form.TextField
                        disabled={isEditingOwner || userIsInactive}
                        label="Email"
                        name="email"
                        value={updatedUserData.email ?? ''}
                        required
                        error={submitted && !updatedUserData.email}
                        onChange={updateUserData('email')}
                        helperText="Used to sign in, email notifications and product updates"
                        endIcon={resendResetPasswordButton(updatedUserData)}
                    />
                    <Form.PhoneField
                        disabled={isEditingOwner || userIsInactive}
                        label="Phone (Optional)"
                        name="phoneNumber"
                        defaultValue={updatedUserData.phoneNumber}
                        onChange={updateUserData('phoneNumber')}
                    />
                    <Form.SectionTitle className="inner-section-title">
                        Member Permissions
                        <div>
                            <a
                                href="https://help.sparkplug.app/en/articles/8279845-managing-team-member-permissions"
                                target="_blank"
                                rel="noreferrer"
                                className="learn-more"
                            >
                                <BookIcon />
                                Learn more
                            </a>
                        </div>
                    </Form.SectionTitle>
                    <Form.TreeSelect
                        controlled
                        disabled={disablingConditions}
                        name="markets"
                        label="Assigned Markets"
                        className={clsx([
                            'hide-toggles hide-search',
                            markets?.length > 1 && 'all-markets',
                        ])}
                        required
                        color="green"
                        error={submitted && !updatedUserData.permissions?.markets?.length}
                        selected={
                            formatMarketPermissionsValue(updatedUserData.permissions?.markets) ?? []
                        }
                        options={marketOptions}
                        onChange={(selectedMarkets: string[], currentSelection) => {
                            const allWasSelected = currentSelection?.value === 'all';
                            const formattedSelectedMarkets = selectedMarkets.filter((m) =>
                                allWasSelected ? m === 'all' : m !== 'all',
                            );
                            setUpdatedUserData((prevState: any) => ({
                                ...prevState,
                                permissions: {
                                    ...prevState.permissions,
                                    markets: formattedSelectedMarkets,
                                },
                            }));
                        }}
                    />
                    <InputHelperText>
                        <span>The permissions set below will apply to the assigned markets</span>
                    </InputHelperText>
                    <Form.LabeledSwitch
                        disabled={disablingConditions}
                        title="Sparks"
                        name="manageSparks"
                        subtitle="Create & manage Sparks for retailers in assigned markets"
                        value={!!updatedUserData.permissions?.manageSparks}
                        label={getLabelText(!!updatedUserData.permissions?.manageSparks)}
                        onChange={updateUserPermission('manageSparks')}
                    />
                    <Form.LabeledSwitch
                        disabled={disablingConditions}
                        title="Retail Partners"
                        name="manageLinks"
                        subtitle="Manage linked retailers and products in assigned markets"
                        value={!!updatedUserData.permissions?.manageLinks}
                        label={getLabelText(!!updatedUserData.permissions?.manageLinks)}
                        onChange={updateUserPermission('manageLinks')}
                    />
                    {displayProductTagDetails && (
                        <Form.LabeledSwitch
                            disabled={disablingConditions}
                            title="Product Tags"
                            name="manageProductTags"
                            subtitle="Create & manage product tags & tag groups"
                            value={!!updatedUserData.permissions?.manageProductTags}
                            label={getLabelText(!!updatedUserData.permissions?.manageProductTags)}
                            onChange={updateUserPermission('manageProductTags')}
                        />
                    )}
                    <Form.LabeledSwitch
                        disabled={disablingConditions}
                        title="Team Members"
                        name="manageUsers"
                        subtitle="Invite team members & manage their permissions"
                        value={!!updatedUserData.permissions?.manageUsers}
                        label={getLabelText(!!updatedUserData.permissions?.manageUsers)}
                        onChange={updateUserPermission('manageUsers')}
                    />
                    <Form.LabeledSwitch
                        disabled={disablingConditions}
                        title="Billing"
                        name="accessBilling"
                        subtitle="Access billing portal and update payment method"
                        value={!!updatedUserData.permissions?.accessBilling}
                        label={getLabelText(!!updatedUserData.permissions?.accessBilling)}
                        onChange={updateUserPermission('accessBilling')}
                    />
                </Form>
                <Drawer.Footer>
                    {account?.type && existingUser && existingUser?.role !== 'none' && (
                        <Button
                            className="user-dropdown_set-inactive-menu-item"
                            startIcon={<RemoveCircleOutline />}
                            color={userPerformingAction?.role !== 'super-admin' ? 'red' : 'yellow'}
                            onClick={() => {
                                handleSetInactive(existingUser!);
                                closeDrawer(true);
                            }}
                        >
                            Set inactive
                        </Button>
                    )}
                    {existingUser?.role === 'none' && (
                        <Button
                            color="blue"
                            variant="smooth"
                            startIcon={<ResetIcon />}
                            onClick={onSetActive}
                        >
                            Reactivate Member
                        </Button>
                    )}
                    <div>
                        <Button color="neutral" variant="flat" onClick={() => closeDrawer(false)}>
                            Cancel
                        </Button>
                        {(existingUser?.role !== 'none' || isEmpty(existingUser)) && (
                            <Button
                                color="blue"
                                variant="smooth"
                                onClick={onSubmit}
                                disabled={isEditingOwner}
                            >
                                Save Member
                            </Button>
                        )}
                    </div>
                </Drawer.Footer>
            </Drawer>
            <AccountUserConflictModal
                isVisible={conflictModalIsVisible}
                onClose={() => {
                    setConflictModalIsVisible(false);
                    closeDrawer(false);
                }}
                onBack={() => setConflictModalIsVisible(false)}
                payload={{
                    email: updatedUserData.email!,
                    groupId: account._id,
                    role: updatedUserData.role!,
                    userIdToDelete: existingUser?.userId,
                    permissions: updatedUserData.permissions ?? {},
                }}
            />
        </>
    );
};

export default CreateEditMemberDrawer;
