import { CircularProgress as MuiCircularProgress } from '@mui/material';

import { appendClasses } from '@helpers/ui';

import './PageLoading.scss';

interface IPageLoadingProps {
    className?: string;
    label?: string;
}

const PageLoading = ({ className, label }: IPageLoadingProps) => {
    const props: any = {
        className: appendClasses(['page-loading', className]),
    };

    return (
        <div {...props}>
            <MuiCircularProgress className="page-loading-progress" />
            <div className="page-loading-label">{label}</div>
        </div>
    );
};

PageLoading.defaultProps = {
    className: undefined,
    label: undefined,
};

export default PageLoading;
