import { FC } from 'react';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import RetailerSparkProductSelector from './RetailerSparkProductSelector';
import VendorSparkProductSelector from './VendorSparkProductSelector';

import './SparkProductSelector.scss';

interface SparkProductSelectorProps {}

const SparkProductSelector: FC<SparkProductSelectorProps> = () => {
    const { account } = useSparkplugAccount();

    const Component =
        account?.type === 'brand' ? VendorSparkProductSelector : RetailerSparkProductSelector;

    return <Component />;
};

export default SparkProductSelector;
