import { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { debounce } from 'lodash';

import { AccountLink } from '@sparkplug/lib';

import { useCreateRequestForSparkMutation } from '@features/request-for-spark/mutations';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import { Search as SearchIcon } from '@components/icons';
import SearchSelect from '@components/inputs/SearchSelect';
import CalloutMessage from '@components/layout/CalloutMessage';
import Modal from '@components/overlays/Modal';
import toast from '@components/toast';

import { IOption } from '@app/types/UITypes';

import { RequestForSparkAccountLabel } from '../../RequestForSparkAccountLabel';
import { accountLinkOptionUtil } from '../accountLinkOptionUtil';

import './RequestForSparkModal.scss';

type AccountLinkOption = AccountLink & IOption;

const VendorLinkOptionLabel = (accountLinkOption: AccountLink & IOption) => {
    const { accountName, brandNames } = accountLinkOptionUtil.parseLabel(accountLinkOption);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: '.2rem',
            }}
        >
            <Typography variant="body" sx={{ fontWeight: 500, margin: '0 !important' }}>
                {accountName}
            </Typography>
            &nbsp;
            {!!brandNames.length && (
                <Typography
                    variant="body"
                    sx={{ fontWeight: 500, margin: '0 !important', color: '#838E9E' }}
                >
                    {`- ${brandNames}`}
                </Typography>
            )}
        </Box>
    );
};
interface VendorLinkSelectorProps {
    vendorLinkOptions: AccountLinkOption[];
    onSelectVendorLink: (vendorLink: AccountLink) => void;
    showError: boolean;
}
const VendorLinkSelector = ({
    vendorLinkOptions,
    onSelectVendorLink,
    showError,
}: VendorLinkSelectorProps) => {
    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'column' }}
            className={clsx('request-for-spark_account-link-selector')}
        >
            <Typography
                variant="body"
                color={showError ? 'errorMessageRed' : 'gray90'}
                style={{ margin: 0 }}
            >
                Vendor*
            </Typography>
            <SearchSelect
                className="vendor-link-search-select"
                startIcon={<SearchIcon />}
                options={vendorLinkOptions}
                placeholder="Search by Vendor or Brand"
                optionLabelComponent={VendorLinkOptionLabel}
                value=""
                onChange={onSelectVendorLink}
            />
        </Box>
    );
};

interface RequestForSparkModalProps {
    sourceAccountId: string;
    vendorLinkOptions: (AccountLink & IOption)[];
    initialVendorLink?: AccountLink;
    onClose: (actionOnClose: boolean) => void;
}
export const RequestForSparkModal = ({
    sourceAccountId,
    vendorLinkOptions,
    initialVendorLink,
    onClose,
}: RequestForSparkModalProps) => {
    const [showVendorSelectorError, setShowVendorSelectorError] = useState<boolean>(false);
    const [message, setMessage] = useState<string>();
    const [selectedVendorLink, setSelectedVendorLink] = useState<AccountLink | undefined>(
        initialVendorLink,
    );

    useEffect(() => {
        if (selectedVendorLink) {
            setShowVendorSelectorError(false);
        }
    }, [selectedVendorLink]);

    const { createRequestForSparkAsync, isCreatingRequestForSpark } =
        useCreateRequestForSparkMutation();
    const sendRequestForSpark = debounce(async () => {
        if (selectedVendorLink) {
            try {
                await createRequestForSparkAsync({
                    sourceAccountId,
                    targetAccountId: selectedVendorLink.accountId,
                    message,
                });
                onClose(true);
            } catch {
                onClose(false);
            }
        } else {
            setShowVendorSelectorError(true);
            toast.error('Please select a Vendor');
        }
    }, 150);

    return (
        <Modal isVisible onClose={onClose}>
            <Modal.Title onClose={onClose}>Request Spark</Modal.Title>

            <Modal.Content sx={{ paddingTop: '8px !important' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <CalloutMessage
                        sx={{ borderRadius: '6px' }}
                        message="We’ll send a Spark request directly to the Vendor’s inbox!"
                    />

                    {selectedVendorLink ? (
                        <RequestForSparkAccountLabel
                            accountLink={selectedVendorLink}
                            onRemoveAccountLink={() => setSelectedVendorLink(undefined)}
                        />
                    ) : (
                        <VendorLinkSelector
                            vendorLinkOptions={vendorLinkOptions}
                            onSelectVendorLink={(vendorLink) => setSelectedVendorLink(vendorLink)}
                            showError={showVendorSelectorError}
                        />
                    )}

                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body" color="gray90" style={{ margin: 0 }}>
                            Request Details (Optional)
                        </Typography>
                        <Form.TextField
                            className="request-for-spark-message"
                            sx={{ width: '100%' }}
                            maxLength={180}
                            multiline
                            rows={5}
                            name="request-for-spark-message"
                            placeholder="Add additional info..."
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                        />
                        <Typography variant="body2" color="gray80" style={{ marginBottom: '10px' }}>
                            Include additional info such as desired start date and Spark type.
                        </Typography>
                    </Box>
                </Box>
            </Modal.Content>

            <Modal.Actions>
                <Button color="neutral" variant="flat" onClick={() => onClose(false)}>
                    Cancel
                </Button>
                <Button
                    color="blue"
                    variant="filled"
                    disabled={isCreatingRequestForSpark}
                    onClick={sendRequestForSpark}
                >
                    Send Request
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
