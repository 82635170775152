import { useEffect, useMemo } from 'react';

import { HelpCenterPage } from '@views/HelpCenterView';
import SparkDetailsViewMobile from '@views/sparks/SparkDetailsViewMobile';
import { UserProfileViewMobile } from '@views/user/UserProfileView';
import UserTeammateReferralView from '@views/user/UserTeammateReferralView';

import LearnAndEarnSurvey from '@features/learn-and-earn/views/LearnAndEarn';
import HomeView from '@features/user-home/views/HomeView';
import UserWalletView from '@features/wallet/views/UserWalletViewMobile';

import { AuthRoute, Redirect, Switch, useRouteMatch } from '@components/router';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useScrollToTop } from '@hooks/UIHooks';

import { isValidObjectId } from '@helpers/util';

const UserRouter = () => {
    const { path } = useRouteMatch();
    const { user, setIsAdminApp } = useApp();

    const { changeAccountById } = useSparkplugAccount();

    const accountId = useMemo(() => {
        return user?.accounts?.[0]?._id;
    }, [user]);

    useEffect(() => {
        setIsAdminApp(false);

        return () => setIsAdminApp(true);
    }, [path]);

    useEffect(() => {
        if (isValidObjectId(accountId || '')) {
            changeAccountById(accountId);
        }
    }, [accountId]);

    useScrollToTop();

    return (
        <Switch>
            <AuthRoute
                path={`${path}/sparks/complete`}
                component={() => <HomeView initialSparkStatus="complete" />}
            />
            <AuthRoute
                path={`${path}/sparks/upcoming`}
                component={() => <HomeView initialSparkStatus="upcoming" />}
            />
            <AuthRoute path={`${path}/sparks/:sparkId`} component={SparkDetailsViewMobile} />
            <AuthRoute path={`${path}/help`} component={HelpCenterPage} />
            <AuthRoute path={`${path}/sparks`} component={HomeView} />
            <AuthRoute path={`${path}/profile`} component={UserProfileViewMobile} />
            <AuthRoute path={`${path}/wallet`} component={UserWalletView} />
            <AuthRoute path={`${path}/referral`} component={UserTeammateReferralView} />
            <AuthRoute
                path={`${path}/learn-and-earn/:learningResourceId/:surveyId`}
                component={LearnAndEarnSurvey}
            />
            <Redirect from={`${path}`} to={`${path}/sparks`} />
        </Switch>
    );
};

export default UserRouter;
