import { ReactNode, useMemo } from 'react';

import { sum } from 'lodash';

import {
    FullChartResponseBody,
    PublicSparkPosArchive,
    Spark,
    TChartDataMetric,
    TComparisonPeriodOption,
} from '@sparkplug/lib';

import { useSparkPosArchive, useSparkQuery } from '@hooks/SparksHooks/SparksHooks';
import { getDefaultSparkChartDataSettings } from '@hooks/SparksHooks/useSparkCloudChartData';

import { hydrateSparkPosArchive } from '@helpers/sparks';

import {
    ChartContext,
    ChartContextValue,
    useSharedChartContextValues,
} from '../components/AdvancedChart/ChartContext';
import {
    ComparisonChartContext,
    ComparisonChartContextValue,
    ComparisonChartProvider,
    useCalculatedComparisonChartContextValues,
    useSharedComparisonChartContextValues,
} from '../components/AdvancedComparisonChart/ComparisonChartContext';

/**
 * It used to be that we only had `sparkPosArchives.charts` but now that we support showing
 * other metrics (like "Total Sales") we have to use `sparkPosArchives.otherCharts` for those
 * charts that aren't specific to the Spark's actual metric.
 */
const getRelevantChartDataFromSparkPosArchive = ({
    sparkPosArchive,
    metric,
    period,
}: {
    sparkPosArchive?: PublicSparkPosArchive;
    metric: TChartDataMetric;
    period: TComparisonPeriodOption | 'sparkPeriod';
}): FullChartResponseBody => {
    if (!sparkPosArchive) {
        return {} as FullChartResponseBody;
    }

    // First find if there is an "otherChart" for this metric
    if (sparkPosArchive.otherCharts?.[metric]) {
        return (sparkPosArchive.otherCharts[metric]?.[period] ?? {}) as FullChartResponseBody;
    }

    // Otherwise use the default chart
    if (sparkPosArchive.charts) {
        return (sparkPosArchive.charts?.[period] ?? {}) as FullChartResponseBody;
    }

    return {} as FullChartResponseBody;
};

const overrideCloudChartDataWithSpecificLocations = ({
    locationIds,
    cloudChartData,
}: {
    locationIds: string[];
    cloudChartData: FullChartResponseBody;
}): FullChartResponseBody => ({
    ...cloudChartData,
    locationBuckets: Object.fromEntries(
        Object.entries(cloudChartData?.locationBuckets ?? {})
            .filter(([locationId]) => locationIds.includes(locationId))
            .map(([locationId, buckets]) => [locationId, buckets]),
    ),
    total: sum(
        Object.entries(cloudChartData?.locationTotals ?? {})
            .filter(([locationId]) => locationIds.includes(locationId))
            .map(([, total]) => total),
    ),
});

/**
 * Because Archived Sparks don't use the charts endpoints at all, we have to create a wrapper that will hydrate
 * the ChartContext.Provider and ComparisonChartContext.Provider with the data from the sparkPosArchive to that
 * all the downstream usage is as expected.
 */
const ArchivedSparkChartProvider = ({ spark, children }: { spark: Spark; children: ReactNode }) => {
    const { isFetched: archiveIsFetched, data: sparkPosArchive } = useSparkPosArchive(spark._id);

    const initialSettings = getDefaultSparkChartDataSettings(spark);
    // Use the logic shared with the ChartContext to ensure they are still same at their core
    const sharedChartContextValues = useSharedChartContextValues({ initialSettings });
    // Use the logic shared with the ComparisonChartContext to ensure they are still same at their core
    const sharedComparisonChartContextValues = useSharedComparisonChartContextValues({
        settings: sharedChartContextValues.chartSettings,
    });

    /**
     * It's a little tricky to get the right chart data from the SparkPosArchive, so
     * let's memoize and isolate this a little bit.
     */
    const { currentCloudChartData, comparisonCloudChartData } = useMemo(() => {
        if (!sparkPosArchive) {
            return {
                currentCloudChartData: {} as FullChartResponseBody,
                comparisonCloudChartData: {} as FullChartResponseBody,
            };
        }

        const relevantChartData = {
            currentCloudChartData: getRelevantChartDataFromSparkPosArchive({
                sparkPosArchive,
                metric: sharedChartContextValues.chartSettings.metric,
                period: 'sparkPeriod',
            }),
            comparisonCloudChartData: getRelevantChartDataFromSparkPosArchive({
                sparkPosArchive,
                metric: sharedChartContextValues.chartSettings.metric,
                period: sharedComparisonChartContextValues.comparisonPeriod,
            }),
        };

        /**
         * Because we have the dropdown on the Spark details page to filter the chart for a single
         * location, we need to override the locationBuckets and the total in cloudChartData to
         * only show that location.
         *
         * Right now the UI only supports a single location or all locations, but this conditional
         * can be removed or modified to show specific locations.
         */
        if (sharedChartContextValues.chartSettings.locationIds.length === 1) {
            const { locationIds } = sharedChartContextValues.chartSettings;

            return {
                currentCloudChartData: overrideCloudChartDataWithSpecificLocations({
                    locationIds,
                    cloudChartData: relevantChartData.currentCloudChartData,
                }),
                comparisonCloudChartData: overrideCloudChartDataWithSpecificLocations({
                    locationIds,
                    cloudChartData: relevantChartData.comparisonCloudChartData,
                }),
            };
        }

        return relevantChartData;
    }, [
        sparkPosArchive,
        sharedChartContextValues.chartSettings.metric,
        sharedChartContextValues.chartSettings.locationIds,
        sharedComparisonChartContextValues.comparisonPeriod,
    ]);

    /**
     * This is the actual value to we want to save and pass down to the ChartContext.Provider
     * The types here make sure that we update this provider if we update the original ChartProvider
     */
    const chartContextValue = useMemo<ChartContextValue>(() => {
        const hydratedSparkPosArchive = hydrateSparkPosArchive(sparkPosArchive);

        const cloudChartData =
            sparkPosArchive?.otherCharts?.[sharedChartContextValues.chartSettings.metric]
                ?.sparkPeriod ?? sparkPosArchive?.charts?.sparkPeriod;

        return {
            ...sharedChartContextValues,
            chartIsReady: archiveIsFetched,
            cloudChartData: cloudChartData ?? {},
            chartLocations: hydratedSparkPosArchive.locations,
            chartProducts: [],
        };
    }, [archiveIsFetched, sparkPosArchive, sharedChartContextValues]);

    /**
     * There are lots of calculations done in the `ComparisonChartProvider`, using this shared hook
     * ensures that we are using the same logic to calculate the comparison period chart data
     * in the original provider
     */
    const calculatedComparisonChartContextValues = useCalculatedComparisonChartContextValues({
        showComparisonPeriod: sharedComparisonChartContextValues.showComparisonWindows,
        allQueryDataIsReady: archiveIsFetched,
        settings: sharedChartContextValues.chartSettings,
        currentCloudChartData,
        comparisonCloudChartData,
    });

    /**
     * This is the actual value to we want to save and pass down to the ComparisonChartContext.Provider
     * The types here make sure that we update this provider if we update the original ComparisonChartProvider
     */
    const comparisonChartContextValue = useMemo<ComparisonChartContextValue>(() => {
        return {
            ...sharedComparisonChartContextValues,
            ...calculatedComparisonChartContextValues,
            isFetchingComparisonPeriod: !archiveIsFetched,
            comparisonChartIsReady: archiveIsFetched,
            comparisonPeriodCloudChartData: comparisonCloudChartData,
        };
    }, [
        sharedComparisonChartContextValues,
        calculatedComparisonChartContextValues,
        archiveIsFetched,
        comparisonCloudChartData,
    ]);

    return (
        <ChartContext.Provider value={chartContextValue}>
            <ComparisonChartContext.Provider value={comparisonChartContextValue}>
                {children}
            </ComparisonChartContext.Provider>
        </ChartContext.Provider>
    );
};

/**
 * @deprecated This should probably be renamed to `DeprecatedSparkChartProvider`. The new `SparkChartProvider`
 * should not query the Spark at all, but instead just store the `sparkId` and the spark-chart-specific `settings`
 * to be used in the query hooks for fetching the chart data.
 *
 * NOTE: The new `SparkChartProvider` will not include `ArchivedSparkChartProvider` at all. `ArchivedSparkChartProvider`
 * will not be necessary because the new Provider will not make any queries. Instead, the query hooks will include the
 * queries to fetch the chart data.
 */
export const SparkChartProvider = ({
    sparkId,
    children,
}: {
    sparkId: string;
    children: ReactNode;
}) => {
    const { spark } = useSparkQuery(sparkId);
    const sparkIsArchived = !!spark?.archivedAt;

    if (!spark) {
        return <></>;
    }

    if (sparkIsArchived) {
        return <ArchivedSparkChartProvider spark={spark}>{children}</ArchivedSparkChartProvider>;
    }

    const initialSettings = getDefaultSparkChartDataSettings(spark);

    // Otherwise use the default providers
    return (
        <ComparisonChartProvider
            retailerAccountId={spark.groupId}
            initialSettings={initialSettings}
        >
            {children}
        </ComparisonChartProvider>
    );
};
