import axios from 'axios';

import { ListBrandProductTagGroupsResponseBody } from '@sparkplug/lib';

import { useAdvancedQuery } from '@hooks/QueryHooks';

const API = {
    getTagGroups: async ({ brandId }: { brandId: string }) => {
        return (
            await axios.get<ListBrandProductTagGroupsResponseBody>(
                `/api/v1/brands/${brandId}/tag-groups`,
            )
        ).data;
    },
    getPosProductData: async ({ productId, groupId }: { productId: string; groupId: string }) => {
        return (await axios.get(`/api/v1/pos/products/${productId}/${groupId}`)).data;
    },
};

export const getBrandTagGroupsQueryKey = (brandId?: string) => ['brand', brandId, 'tagGroups'];
export const useBrandTagGroups = (brandId: string, isEnabled: boolean = true) => {
    const { isFetched: brandTagGroupsAreReady, data: brandTagGroups } = useAdvancedQuery(
        getBrandTagGroupsQueryKey(brandId),
        () => API.getTagGroups({ brandId }),
        { enabled: isEnabled && !!brandId },
    );

    return { brandTagGroupsAreReady, brandTagGroups };
};

export const usePosProductData = (
    productId: string,
    groupId: string,
    isEnabled: boolean = true,
) => {
    const { isFetched: posProductDataIsReady, data: posProductData } = useAdvancedQuery(
        ['posProductData', productId, groupId],
        () => API.getPosProductData({ productId, groupId }),
        { enabled: isEnabled && !!productId && !!groupId },
    );

    return { posProductDataIsReady, posProductData };
};
