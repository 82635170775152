import HighchartsReact from 'highcharts-react-official';
import { FC, useMemo, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import Highcharts from 'highcharts';
import { keyBy, sumBy } from 'lodash';

import {
    BucketsKeyedByLabel,
    FullChartResponseBody,
    ListAccountLinksResponseBody,
} from '@sparkplug/lib';

import { BucketFactory } from '@core/charts/hooks/ChartsHooks';
import {
    getBucketFromTooltipContext,
    getDefaultChartOptions,
    transformChartDataToBarSeries,
    transformChartDataToLineSeries,
} from '@core/charts/utils/ChartsUtils';

import SliceTooltip from '@components/charts/SliceTooltip';
import { NoSalesData } from '@components/graphics';
import Skeleton from '@components/layout/Skeleton';

import { IAccount } from '@app/types/AccountsTypes';
import { IChartDataSettings } from '@app/types/ChartDataTypes';

import AdvancedTableChart from '../AdvancedTableChart';
import {
    isAdditionalProductBreakdown,
    transformProductBucketsIntoBreakdownLabelBucketPairs,
} from './AdvancedChartUtils';
import { useChartContext } from './ChartContext';

import './AdvancedChart.scss';

const getVisiblePoints = <T extends {}>(
    points: T[],
    middleIndex: number,
    maxPointsPerTooltip: number,
): T[] => {
    const pointsPerSide = Math.floor(maxPointsPerTooltip / 2);

    const startIndex = middleIndex - pointsPerSide;
    const endIndex = middleIndex + pointsPerSide;

    return points.slice(Math.max(0, startIndex), endIndex);
};

interface AdvancedChartProps {
    settings: Pick<IChartDataSettings, 'dateStart' | 'dateEnd' | 'type' | 'metric'>;
    data: BucketsKeyedByLabel;
    chartValueFormatter: (value: number) => string;
    chartBucketFactory: InstanceType<BucketFactory>;
}

export const AdvancedChart: FC<AdvancedChartProps> = ({
    settings,
    data,
    chartValueFormatter,
    chartBucketFactory,
}) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>();

    let currentPointIndex = 0;

    const sharedPlotOptions: Highcharts.PlotOptions = {
        series: {
            events: {
                // Cleanup for sorted points...
                mouseOver() {
                    const self = this;
                    currentPointIndex = self.index;
                },
                mouseOut() {
                    currentPointIndex = -1;
                },
            },
        },
    };

    const series = useMemo(() => {
        const buckets = chartBucketFactory.getAllBucketNames(settings.dateStart, settings.dateEnd);

        return settings.type === 'line'
            ? transformChartDataToLineSeries({ metric: settings.metric, data, buckets })
            : transformChartDataToBarSeries({ data, buckets, stack: true });
    }, [settings, data]);

    const defaultTooltipFormatter = (tooltipContext: Highcharts.TooltipFormatterContextObject) => {
        const maxPointsPerTooltip = 30;
        const points = tooltipContext.points ?? [];
        if (settings.type === 'line') {
            points.sort((a, b) => (b.point.y ?? 0) - (a.point.y ?? 0));
        }

        const currentPointSortedIndex = points.findIndex(
            ({ point }) => point.series.index === currentPointIndex,
        );
        const totalPoints = points.length;
        const visiblePoints =
            totalPoints > maxPointsPerTooltip
                ? // When there are too many points, we want to show the points closes to the hovered point
                  getVisiblePoints(points, currentPointSortedIndex, maxPointsPerTooltip)
                : points;
        const title = chartBucketFactory.getTooltipTitle(
            getBucketFromTooltipContext(tooltipContext),
        );
        const total = chartValueFormatter(sumBy(points, ({ point }) => point.y ?? 0));

        return renderToStaticMarkup(
            <SliceTooltip
                className="highcharts-tooltip"
                title={title}
                total={total}
                points={visiblePoints.map(({ point }) => ({
                    name: point.series.name,
                    value: chartValueFormatter(point.y ?? 0),
                    color: point.color?.toString() ?? '#ff0000',
                }))}
            />,
        );
    };

    const chartOptions: Highcharts.Options = {
        ...getDefaultChartOptions({
            chartValueFormatter,
            chartBucketFactory,
            showXAxisGridLine: settings.type === 'line',
        }),
        tooltip: {
            formatter() {
                return defaultTooltipFormatter(this);
            },
            useHTML: true,
            shared: true,
        },
        plotOptions: sharedPlotOptions,
        series,
    };

    const chartProps: HighchartsReact.Props = {
        ref: chartComponentRef,
        highcharts: Highcharts,
        options: chartOptions,
    };

    return (
        <div className="advanced-chart">
            <HighchartsReact {...chartProps} />
        </div>
    );
};

/**
 * @deprecated This uses context with the old charting endpoints. Now that we are starting to separate
 * these expensive endpoints into individual use-case-specific endpoints, we will create new components
 * for each use case that will use the shared `AdvancedChart` component.
 *
 * See `VendorBreakdownChart` for an example of how to use the `AdvancedChart` directly
 */

interface DeprecatedAdvancedChartProps {
    account?: IAccount;
    accountLinks?: ListAccountLinksResponseBody;
    userIsSuperAdmin?: boolean;
}
export const DeprecatedAdvancedChart: FC<DeprecatedAdvancedChartProps> = ({
    account,
    accountLinks,
    userIsSuperAdmin = false,
}) => {
    const {
        chartIsReady,
        cloudChartData,
        chartLocations,
        chartProducts,
        chartSettings,
        chartBucketFactory,
        chartValueFormatter,
    } = useChartContext();

    const labelBucketPairs = useMemo(() => {
        // Spark detail charts still use the "old" charting endpoints - we can remove this branch when we have new endpoints wired up
        if (chartSettings.sparkId) {
            if (isAdditionalProductBreakdown(chartSettings.breakdown)) {
                return transformProductBucketsIntoBreakdownLabelBucketPairs({
                    breakdown: chartSettings.breakdown,
                    chartProducts,
                    productBuckets: (cloudChartData as FullChartResponseBody)?.productBuckets ?? {},
                    accountLinks,
                    account,
                    userIsSuperAdmin,
                });
            }

            if (chartSettings.breakdown === 'product') {
                const chartProductsByInternalKey = keyBy(chartProducts, 'internalKey');
                return Object.fromEntries(
                    Object.entries(
                        (cloudChartData as FullChartResponseBody)?.productBuckets ?? {},
                    ).map(([productId, bucketData]) => [
                        chartProductsByInternalKey[productId]?.name ?? 'Unknown Product',
                        bucketData,
                    ]),
                );
            }

            // Location is the default breakdown
            const posLocationsById = keyBy(chartLocations, '_id');
            return Object.fromEntries(
                Object.entries(
                    (cloudChartData as FullChartResponseBody)?.locationBuckets ?? {},
                ).map(([locationId, bucketData]) => [
                    posLocationsById[locationId]?.displayName ?? posLocationsById[locationId]?.name,
                    bucketData,
                ]),
            );
        }

        // This is the pattern for breakdowns using the new charting endpoints
        if (chartSettings.breakdown === 'brand') {
            // TODO: with this update, we lose the ability to append the pos brand name to the display brand name, which is useful for super-admin debugging
            // we should find a way to do this in the api response
            // See https://linear.app/sparkplug/issue/PLT-704/fix-the-brand-name-pos-brand-name-super-admin-feature-on-vendor
            return cloudChartData.brandBuckets!;
        }

        if (chartSettings.breakdown === 'category') {
            return cloudChartData.categoryBuckets!;
        }

        if (chartSettings.breakdown === 'product') {
            const chartProductsByInternalKey = keyBy(chartProducts, 'internalKey');
            return Object.fromEntries(
                Object.entries((cloudChartData as FullChartResponseBody)?.productBuckets ?? {}).map(
                    ([productId, bucketData]) => [
                        chartProductsByInternalKey[productId]?.name ?? 'Unknown Product',
                        bucketData,
                    ],
                ),
            );
        }

        // Location is the default breakdown
        if (chartSettings.brandGroupId) {
            // vendor endpoint returns keyed by locationName, so we can just return the buckets
            return cloudChartData.locationBuckets!;
        }
        // but retailer endpoint returns keyed by id, so we need to map the location id to the location name
        const posLocationsById = keyBy(chartLocations, '_id');
        return Object.fromEntries(
            Object.entries((cloudChartData as FullChartResponseBody)?.locationBuckets ?? {}).map(
                ([locationId, bucketData]) => [
                    posLocationsById[locationId]?.displayName ?? posLocationsById[locationId]?.name,
                    bucketData,
                ],
            ),
        );
    }, [
        chartSettings.breakdown,
        chartSettings.brandGroupId,
        cloudChartData,
        chartLocations,
        chartProducts,
    ]);

    if (!chartIsReady) {
        return <Skeleton height={400} />;
    }

    if (!(cloudChartData as FullChartResponseBody)?.datePercentage) {
        return (
            <div className="advanced-chart_message">
                <div>
                    <NoSalesData />
                    <p>Sales data unavailable</p>
                </div>
            </div>
        );
    }

    if (chartSettings.type === 'table') {
        return (
            <AdvancedTableChart
                cloudChartData={cloudChartData as FullChartResponseBody}
                chartSettings={chartSettings}
                chartLocations={chartLocations}
                chartProducts={chartProducts}
            />
        );
    }
    return (
        <AdvancedChart
            settings={chartSettings}
            data={labelBucketPairs}
            chartBucketFactory={chartBucketFactory}
            chartValueFormatter={chartValueFormatter}
        />
    );
};
