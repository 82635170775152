import { useQuery } from 'react-query';

import { ListSparksQueryParams } from '@sparkplug/lib';

import { getSparks } from '@helpers/sparks';

import { IAccount } from '@app/types/AccountsTypes';

export const getSparksQueryKey = (accountId: string, sparkFilters?: ListSparksQueryParams) => [
    'account',
    accountId,
    'sparks',
    JSON.stringify(sparkFilters),
];
export const useGetSparksQuery = ({
    account,
    sparkFilters,
    isAdminApp,
    accountIsReady,
}: {
    account?: IAccount;
    sparkFilters?: ListSparksQueryParams;
    isAdminApp: boolean;
    accountIsReady: boolean;
}) => {
    const { isLoading, data, refetch, isFetched } = useQuery(
        getSparksQueryKey(account?._id ?? '', sparkFilters),
        ({ signal }) => {
            if (isAdminApp && accountIsReady && !!account?._id) {
                if (account?.type === 'retailer') {
                    return getSparks(account._id, undefined, sparkFilters, signal);
                } else {
                    return getSparks(undefined, account._id, sparkFilters, signal);
                }
            } else {
                return Promise.resolve({
                    sparks: [],
                    meta: {
                        current: 0,
                        limit: 0,
                        offset: 0,
                        total: 0,
                    },
                });
            }
        },
        {
            enabled: isAdminApp && accountIsReady && !!account?._id,
        },
    );

    return {
        isLoading,
        sparks: data?.sparks,
        meta: data?.meta || {
            current: 0,
            limit: 0,
            offset: 0,
            total: 0,
        },
        refetchSparks: refetch,
        sparksAreReady: isFetched,
    };
};
