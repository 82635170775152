import { default as AllProductsSelectedGraphic } from './AllProductsSelected.svg?react';
import { default as LoopGraphic } from './LoopGraphic.svg?react';
import { FC, useCallback, useMemo, useRef, useState } from 'react';

import { RetailerSparkFilters, Spark, SparkCommission, SparkCommissionType } from '@sparkplug/lib';

import Form from '@components/form/Form';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import Grid from '@components/layout/Grid';
import Skeleton from '@components/layout/Skeleton';
import ConfirmModal from '@components/overlays/ConfirmModal';
import toast from '@components/toast';

import { useModal } from '@hooks/ModalHooks';
import {
    useAccountPosDataQuery,
    useAccountProductsFilters,
} from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';
import { useTableContext } from '@hooks/TableHooks';
import { useDynamicHeight } from '@hooks/UIHooks';

import { capitalizeFirstLetter } from '@helpers/ui';

import { IPosBrand, IPosCategory, IPosProduct } from '@app/types/PosTypes';

import PosRulesBasedProductSelectorTable, {
    PosRulesBasedProductTableFilters,
    usePosRulesBasedProductTableFilters,
} from '../PosRulesBasedSparkProductSelectorTable';
import SparkProductSelectorTable, {
    ProductTableFilters,
    useProductTableFilters,
} from '../SparkProductSelectorTable';
import SparkMetricSelector from './SparkMetricSelector';
import SparkProductScopeSelector, { ProductFilterType } from './SparkProductScopeSelector';
import { useInitialization, useProductFilters, useScope } from './hooks';

import './RetailerSparkProductSelector.scss';

type ProductFilters = ReturnType<typeof useAccountProductsFilters>['productFilters'];

const SelectScopeOptionDisplay = ({ filterType }: { filterType: ProductFilterType }) => {
    const { modalContentRef } = useModal();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const height = useDynamicHeight(modalContentRef, wrapperRef, {
        defaultHeight: 400,
    });

    return (
        <div className="select-scope-option">
            <Form.Label>Select Products</Form.Label>
            <div ref={wrapperRef} className="select-scope-option_content" style={{ height }}>
                <div>
                    {`Choose at least one ${capitalizeFirstLetter(
                        filterType === 'brands' ? 'Brand' : 'Category',
                    )} to start selecting products.`}
                </div>
            </div>
        </div>
    );
};

const AllProductsSelectedDisplay = () => {
    const { modalContentRef } = useModal();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const height = useDynamicHeight(modalContentRef, wrapperRef, {
        defaultHeight: 400,
    });

    return (
        <div className="all-products-selected">
            <Form.Label>Select Products</Form.Label>
            <div ref={wrapperRef} className="empty-state-display-wrapper" style={{ height }}>
                <EmptyStateDisplay
                    graphic={<AllProductsSelectedGraphic />}
                    customContent={
                        <div className="all-products-selected_content">
                            All products included for this metric. Click{' '}
                            <span>
                                <strong>next</strong>
                                <LoopGraphic className="full-screen" />
                            </span>{' '}
                            to go to step 4.
                        </div>
                    }
                />
            </div>
        </div>
    );
};

interface GetModalNextParams {
    filterType: ProductFilterType;
    productFilters: ProductFilters;
    rulesBasedFilters?: RetailerSparkFilters;
    forceAllProducts: boolean;
    rulesBasedFilteredProducts: IPosProduct[];
    allAccountPosProductCount: number;
    sparkCommissionType: SparkCommissionType;
    updateFormErrorState: (hasError: boolean) => void;
}

export const getModalNext =
    ({
        filterType,
        productFilters,
        rulesBasedFilters,
        forceAllProducts,
        rulesBasedFilteredProducts,
        allAccountPosProductCount,
        sparkCommissionType,
        updateFormErrorState,
    }: GetModalNextParams) =>
    () => {
        const { spark, sparkCommissionMap, updateSpark, isRetailerRulesBasedSpark } = useSpark();
        // these all must be defaulted to an empty array to prevent the modal from
        // crashing because this does not always render with a table provider
        const {
            tableSelected = [],
            tableFilteredRows = [],
            tableRows = [],
        } = useTableContext<IPosProduct>();
        const { updateValidationFn, updateBackFn } = useModal();

        const selectedProductIds = isRetailerRulesBasedSpark
            ? rulesBasedFilteredProducts.map((row) => row._id)
            : tableSelected;

        const hasSelectedProducts = !!selectedProductIds.length;

        const applySparkProducts = useCallback(() => {
            const allProductsSelected = allAccountPosProductCount === selectedProductIds.length;
            const hasUniqueProductIds = tableRows.length !== selectedProductIds.length;

            const newSparkData: Pick<
                Spark,
                | 'posBrandIds'
                | 'posCategoryIds'
                | 'posProductIds'
                | 'productFilters'
                | 'commissions'
                | 'retailerFilters'
                | 'detailedSparkType'
            > = {
                posBrandIds:
                    filterType === 'brands' && !hasUniqueProductIds ? productFilters.brandIds : [],
                posCategoryIds:
                    filterType === 'categories' && !hasUniqueProductIds
                        ? productFilters.categoryIds
                        : [],
                posProductIds: hasUniqueProductIds ? selectedProductIds : [],
                productFilters: hasUniqueProductIds ? productFilters : {},
                commissions: [],
            };

            if (isRetailerRulesBasedSpark) {
                newSparkData.retailerFilters = rulesBasedFilters;
                /**
                 * If rules-based Spark, make sure to clear out all manually managed Product selections
                 *
                 * We set posProductIds for the "Review" step to display the correct products but
                 * we will unset them server-side to prevent the Spark from being saved with the
                 * wrong products
                 */
                newSparkData.posProductIds = selectedProductIds;
                newSparkData.posBrandIds = [];
                newSparkData.posCategoryIds = [];
                newSparkData.productFilters = {};
            } else if (allProductsSelected || forceAllProducts) {
                newSparkData.posBrandIds = [];
                newSparkData.posCategoryIds = [];
                newSparkData.posProductIds = [];
                newSparkData.productFilters = {};
            }

            if (spark.type === 'commission') {
                newSparkData.commissions = selectedProductIds.map((productId) => {
                    const commissionItem = sparkCommissionMap?.get(productId);

                    return {
                        posProductId: productId,
                        type: sparkCommissionType,
                        value: commissionItem ? commissionItem.value : undefined,
                    } as SparkCommission;
                });
                newSparkData.detailedSparkType =
                    sparkCommissionType === 'flat' ? 'commissionFlat' : 'commissionPercentage';
            }

            updateSpark(newSparkData);
        }, [
            filterType,
            selectedProductIds,
            tableRows,
            productFilters,
            sparkCommissionMap,
            sparkCommissionType,
            forceAllProducts,
        ]);
        updateBackFn(() => {
            requestAnimationFrame(() => {
                applySparkProducts();
            });
            return true;
        });
        updateValidationFn(() => {
            const hasFiltersSelected =
                rulesBasedFilters &&
                (rulesBasedFilters.posBrandIds.length > 0 ||
                    rulesBasedFilters.posCategoryIds.length > 0 ||
                    rulesBasedFilters.productNameFilters.length > 0 ||
                    rulesBasedFilters.productNameContains.length > 0 ||
                    rulesBasedFilters.productNameDoesNotContain.length > 0);

            const validation = [
                () => {
                    const metricIsSelected = !!(spark.type === 'commission'
                        ? sparkCommissionType
                        : spark.metric);

                    if (!metricIsSelected) {
                        toast.error('Select metric to proceed.');
                        return false;
                    }

                    return true;
                },
                () => {
                    if (!hasSelectedProducts && !forceAllProducts) {
                        toast.error('Select products to proceed.');
                        return false;
                    } else if (
                        isRetailerRulesBasedSpark &&
                        !hasFiltersSelected &&
                        !forceAllProducts
                    ) {
                        updateFormErrorState(true);
                        toast.error('Apply rules to set qualifying products before proceeding.');
                        return false;
                    }

                    return true;
                },
            ];

            const allValid = validation.every((fn) => fn());

            if (allValid) {
                requestAnimationFrame(() => {
                    applySparkProducts();
                });
            }

            return allValid;
        });

        return <></>;
    };
interface RetailerSparkProductSelectorProps {
    isRetailerRulesBasedSpark: boolean;

    spark: Spark;
    associatedProducts: IPosProduct[];
    sparkPosDataIsReady: boolean;

    accountPosDataIsReady: boolean;
    accountPosBrands: IPosBrand[];
    accountPosCategories: IPosCategory[];
    accountPosProducts: IPosProduct[];

    initialFilterValues?: Partial<ProductTableFilters>;
}

export const RetailerSparkProductSelector: FC<RetailerSparkProductSelectorProps> = ({
    isRetailerRulesBasedSpark,

    spark,
    associatedProducts,
    sparkPosDataIsReady,

    accountPosDataIsReady,
    accountPosBrands,
    accountPosCategories,
    accountPosProducts,

    initialFilterValues,
}) => {
    const [selectedProductIds, updateSelectedProductIds] = useState<string[]>([]);

    let initialCommissionType = spark.commissions?.[0]?.type;

    // if there are commissionRules but no commissions that indicates it's a cloned spark
    if (spark.commissionRules && spark?.detailedSparkType && spark.commissions.length === 0) {
        initialCommissionType =
            spark.detailedSparkType === 'commissionFlat' ? 'flat' : 'percentage';
    }
    const [sparkCommissionType, setSparkCommissionType] =
        useState<SparkCommissionType>(initialCommissionType);

    const forceAllProducts = useMemo(() => {
        return [
            'transaction_count',
            'order_average',
            'units_per_transaction',
            'guest_check_average',
        ].includes(spark.percentIncreaseData?.metric || spark.metric);
    }, [spark.metric]);

    const brandOptions = accountPosBrands;
    const categoryOptions = accountPosCategories;

    const {
        filterType,
        setFilterType,
        productFilters,
        updateProductFilters,
        filteredProductOptions,
    } = useProductFilters({
        accountPosProducts,
    });

    const { isReady } = useInitialization({
        spark,
        sparkPosDataIsReady,
        accountPosDataIsReady,
        setFilterType,
        updateProductFilters,
        updateSelectedProductIds,
        associatedProducts,
        accountPosProducts,
    });

    const shouldPreventIndividualProductSelection = isRetailerRulesBasedSpark;
    const initialRulesFilters = useMemo(() => {
        return (() => {
            if (spark.retailerFilters) return spark.retailerFilters;
            return {};
        })();
    }, [spark.retailerFilters, accountPosBrands, spark._id]);

    const rulesBasedTableFilters = usePosRulesBasedProductTableFilters({
        isEditingExistingSpark: !!spark._id,
        initialFilters: initialRulesFilters,
        sparkStartDate: spark.startDate,
        allBrandsLength: accountPosBrands.length,
    });
    const individualBasedTableFilters = useProductTableFilters({ spark, initialFilterValues });

    const {
        warningMessage,
        onSwitchScope,
        onConfirmSwitchScope,
        onCancelSwitchScope,
        onUpdateBrands,
        onUpdateCategories,
    } = useScope({
        setFilterType,
        productFilters,
        updateProductFilters,
        accountPosProducts,
        selectedProductIds,
        updateSelectedProductIds,
        tableFilters: individualBasedTableFilters,
    });

    const [formHasErrorState, setFormHasErrorState] = useState(false);
    const rulesBasedFilteredProducts = useMemo(
        () => rulesBasedTableFilters.getRulesBasedFilteredProducts(accountPosProducts),
        [individualBasedTableFilters],
    );

    const ModalNext = getModalNext({
        sparkCommissionType,
        filterType,
        productFilters,
        forceAllProducts,
        rulesBasedFilteredProducts,
        allAccountPosProductCount: accountPosProducts.length,
        updateFormErrorState: setFormHasErrorState,
        ...(shouldPreventIndividualProductSelection && {
            rulesBasedFilters: {
                primaryFilter: rulesBasedTableFilters.filters.primaryFilter,
                lastSoldAt: rulesBasedTableFilters.filters.lastSoldAt,
                hideSampleProducts: rulesBasedTableFilters.filters.hideSampleProducts,
                posBrandIds:
                    rulesBasedTableFilters.filters.brands?.map((brand) => brand.value) ?? [],
                posCategoryIds:
                    rulesBasedTableFilters.filters.categories?.map((category) => category.value) ??
                    [],
                productNameFilters: rulesBasedTableFilters.filters.productNameFilters,
                productNameContains: rulesBasedTableFilters.filters.productNameContains,
                productNameDoesNotContain: rulesBasedTableFilters.filters.productNameDoesNotContain,
                excludedProductIds:
                    (rulesBasedTableFilters.filters as PosRulesBasedProductTableFilters)
                        .excludedProductIds ?? [],
            },
        }),
    });

    const metricIsSelected = spark.type === 'commission' ? !!sparkCommissionType : !!spark.metric;

    return (
        <Grid className="retailer-spark-product-selector">
            {isReady ? (
                <>
                    <Grid.Item sm={12}>
                        <SparkMetricSelector
                            {...{
                                sparkCommissionType,
                                setSparkCommissionType,
                            }}
                            isRecurring={!!spark.recurringSchedule}
                        />
                        {(!metricIsSelected || forceAllProducts) && <ModalNext />}
                    </Grid.Item>

                    {metricIsSelected && !forceAllProducts && (
                        <>
                            {shouldPreventIndividualProductSelection ? (
                                <Grid.Item sm={12}>
                                    <PosRulesBasedProductSelectorTable
                                        showPricesColumn
                                        products={accountPosProducts}
                                        tableFilters={rulesBasedTableFilters}
                                        ModalNext={ModalNext}
                                        formHasErrorState={formHasErrorState}
                                        resetFormErrorState={() => setFormHasErrorState(false)}
                                    />
                                </Grid.Item>
                            ) : (
                                <>
                                    <Grid.Item sm={4}>
                                        <SparkProductScopeSelector
                                            {...{
                                                filterType,
                                                productFilters,
                                                brandOptions,
                                                categoryOptions,
                                                onSwitchScope,
                                                onUpdateBrands,
                                                onUpdateCategories,
                                            }}
                                        />
                                    </Grid.Item>
                                    <Grid.Item sm={8}>
                                        {productFilters.brandIds.length ||
                                        productFilters.categoryIds.length ? (
                                            <SparkProductSelectorTable
                                                hideCategoryFilter
                                                hideBrandFilter
                                                showPriceColumn
                                                showDropdownMenuFilters={!!spark.createdAt}
                                                products={filteredProductOptions}
                                                controlled={{
                                                    selected: selectedProductIds,
                                                    setSelected: updateSelectedProductIds,
                                                }}
                                                tableFilters={individualBasedTableFilters}
                                                ModalNext={ModalNext}
                                            />
                                        ) : (
                                            <>
                                                <SelectScopeOptionDisplay filterType={filterType} />
                                                <ModalNext />
                                            </>
                                        )}
                                    </Grid.Item>
                                </>
                            )}
                        </>
                    )}
                    {metricIsSelected && forceAllProducts && (
                        <Grid.Item sm={12}>
                            <AllProductsSelectedDisplay />
                            <ModalNext />
                        </Grid.Item>
                    )}
                </>
            ) : (
                <Grid.Item sm={12}>
                    <Skeleton height="100%" />
                </Grid.Item>
            )}
            <ConfirmModal
                title="Update Products?"
                message={warningMessage || ''}
                isVisible={!!warningMessage}
                onConfirm={onConfirmSwitchScope}
                onClose={onCancelSwitchScope}
            />
        </Grid>
    );
};

export default () => {
    const {
        isRetailerRulesBasedSpark,
        spark,
        sparkPosData: { associatedProducts = [] },
        sparkPosDataIsReady,
    } = useSpark();

    const { accountPosDataIsReady, accountPosBrands, accountPosCategories, accountPosProducts } =
        useAccountPosDataQuery(spark.groupId, {
            includedDatasets: ['brands', 'categories', 'products'],
        });

    return (
        <RetailerSparkProductSelector
            {...{
                isRetailerRulesBasedSpark,

                spark,
                associatedProducts,
                sparkPosDataIsReady,

                accountPosDataIsReady,
                accountPosBrands,
                accountPosCategories,
                accountPosProducts,
            }}
        />
    );
};
