import { ComponentProps, FC } from 'react';

import Select from '@components/inputs/Select';

import { appendClasses } from '@helpers/ui';

import { useIsInToolbar } from '../Toolbar/useIsInToolbar';
import ToolbarGroup from '../ToolbarGroup';

import './ToolbarSelect.scss';

interface ToolbarSelectProps extends ComponentProps<typeof Select> {
    onSelect: (value: string) => void;
}

const ToolbarSelect: FC<ToolbarSelectProps> = ({
    className,
    label,
    color = 'blue',
    disabled,
    variant = 'raised',
    options,
    value,
    onSelect,
}) => {
    useIsInToolbar({ componentName: 'ToolbarSelect' });

    const classNameAppended = appendClasses([className, 'toolbar-select']);

    return (
        <ToolbarGroup className={classNameAppended}>
            {label ? <div className="toolbar-dropdown-label">{`${label}:`}</div> : null}
            <Select
                color={color}
                variant={variant}
                disabled={disabled}
                value={value}
                options={options}
                onChange={(e) => onSelect(e.target.value)}
            />
        </ToolbarGroup>
    );
};
export default ToolbarSelect;
