import { memo } from 'react';

import Chip from '@components/chips/Chip';
import Tooltip from '@components/layout/Tooltip';

import './SparkGroupChip.scss';

const SparkGroupChip = memo(
    ({ groupName, vendorName = '' }: { groupName: string; vendorName?: string }) => {
        return (
            <Tooltip
                className="spark-group-tooltip"
                title={vendorName}
                disabled={vendorName.length < 1}
                placement="right-end"
            >
                <span>
                    <Chip
                        dense
                        className="spark-group-chip"
                        color="blue"
                        variant="flat"
                        label={groupName}
                    />
                </span>
            </Tooltip>
        );
    },
);

export default SparkGroupChip;
