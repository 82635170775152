import axios from 'axios';

import { GetSurveyResponseBody } from '@sparkplug/lib';

import { useAdvancedQuery } from '@hooks/QueryHooks';

const API = {
    getSurvey: async (surveyId: string): Promise<GetSurveyResponseBody> => {
        const response = await axios.get<GetSurveyResponseBody>(`/api/v1/survey/${surveyId}`);
        return response.data;
    },
};

export const getSurveyCacheKey = (surveyId: string) => ['survey', surveyId];

export const useGetSurvey = (surveyId: string) => {
    const {
        data: survey,
        isLoading,
        error,
    } = useAdvancedQuery(getSurveyCacheKey(surveyId), () => API.getSurvey(surveyId), {
        enabled: !!surveyId,
    });

    return {
        survey,
        isLoadingSurvey: isLoading,
        surveyError: error,
    };
};
