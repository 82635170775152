import { cloneElement, useMemo } from 'react';

import { clsx } from 'clsx';

import Table from '@components/table/Table';
import { ITableExternalProvider, ITableInternalProvider } from '@components/table/Table/Table';

import { THeadCell } from '@app/types/TableTypes';

import './AdvancedTable.scss';

export type AdvancedTableProps<T> = (ITableInternalProvider<T> | ITableExternalProvider<T>) & {
    stickyLeft: THeadCell<T>[];
    stickyRight: THeadCell<T>[];
};
const AdvancedTable = <T extends {}>({
    headCells = [],
    rows = [],
    stickyRight,
    stickyLeft,
    isLoading,
    children,
    className,
    useExternalProvider,
    enableQueryParams,
    showPagination,
    disableFrontendFiltering,
    rowCountOverride,
    defaultOptions,
    filters,
    variant = 'raised',
}: AdvancedTableProps<T>) => {
    const cells = useMemo(() => {
        const leftCells: THeadCell<T>[] = stickyLeft.map((cell) => ({
            sticky: 'left',
            ...cell,
            render: (row, setRow, ref) => {
                if (cell.render) {
                    const renderElement = cell.render(row, setRow, ref);
                    return cloneElement(renderElement, {
                        className: clsx(renderElement.props.className, 'sticky-left'),
                        children: (
                            <div className="sticky-wrap right-shadow pinned-account-cell">
                                {renderElement.props.children}
                            </div>
                        ),
                    });
                }
                return <></>;
            },
        }));
        const rightCells: THeadCell<T>[] = stickyRight.map((cell) => ({
            sticky: 'right',
            ...cell,
            label: cell.label
                ? cell.label
                : () => {
                      return (
                          <div className="sticky-header">
                              <div className="sticky-empty">.</div>
                          </div>
                      );
                  },
            render: (row, setRow, ref) => {
                if (cell.render) {
                    const renderElement = cell.render(row, setRow, ref);
                    return cloneElement(renderElement, {
                        className: clsx(renderElement.props.className, 'sticky-right'),
                        children: (
                            <div className="sticky-wrap sticky-wrap-left-shadow pinned-account-cell">
                                {renderElement.props.children}
                            </div>
                        ),
                    });
                }
                return <></>;
            },
        }));

        return [...leftCells, ...headCells, ...rightCells];
    }, [stickyLeft, stickyRight, headCells]);

    return (
        <>
            <Table
                className={clsx('advanced-table', className)}
                variant={variant}
                rows={rows}
                isLoading={isLoading}
                headCells={cells}
                useExternalProvider={useExternalProvider}
                enableQueryParams={enableQueryParams}
                showPagination={showPagination}
                disableFrontendFiltering={disableFrontendFiltering}
                filters={filters}
                defaultOptions={
                    defaultOptions ?? {
                        orderBy: 'createdAt',
                        order: 'desc',
                    }
                }
                rowCountOverride={rowCountOverride}
            >
                {children}
            </Table>
        </>
    );
};
export default AdvancedTable;
