import { UIEvent } from '@sparkplug/lib';

import { EventDetails } from '@features/events/components';
import { useApproveEventMutation, useDeclineEventMutation } from '@features/events/mutations';

import Button from '@components/buttons/Button';
import CalloutMessage from '@components/layout/CalloutMessage';
import Modal from '@components/overlays/Modal';

import Typography from '@app/componentLibrary/Typography';

const ApprovalModal = ({
    event,
    isVisible,
    onClose,
}: {
    event: UIEvent;
    isVisible: boolean;
    onClose: () => void;
}) => {
    const { declineEventAsync } = useDeclineEventMutation();
    const { approveEventAsync } = useApproveEventMutation();

    const handleDeclineEvent = async () => {
        await declineEventAsync(event._id);
        onClose();
    };

    const handleApproveEvent = async () => {
        await approveEventAsync(event._id);
        onClose();
    };

    return (
        <Modal isVisible={isVisible} onClose={onClose}>
            <Modal.Title onClose={onClose}>Event Request</Modal.Title>

            <Modal.Content className="flex flex-col gap-4">
                <CalloutMessage
                    title="Event Note:"
                    message={`${event.vendorAccount?.name} at ${
                        event.brand?.name
                    } scheduled an event ${event.requestNote ? `"${event.requestNote}"` : ''}`}
                />
                <Typography variant="lg" className="text-gray-900">
                    Event Details:
                </Typography>
                <EventDetails event={event} />
            </Modal.Content>

            <Modal.Actions>
                <Button color="red" variant="smooth" onClick={handleDeclineEvent}>
                    Decline
                </Button>
                <Button color="green" variant="smooth" onClick={handleApproveEvent}>
                    Approve
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ApprovalModal;
