import moment from 'moment-timezone';

import { Spark } from '@sparkplug/lib';

/**
 *
 * @returns duration in days
 */
export const getSparkDurationInDays = ({
    startDate,
    endDate,
}: Pick<Spark, 'startDate' | 'endDate'>) => {
    return moment.duration(moment(endDate).diff(moment(startDate))).asDays();
};

/**
 *
 * @returns comparison startDate in ISO date format
 */
export const getSparkComparisonStartDate = ({
    startDate,
    endDate,
}: Pick<Spark, 'startDate' | 'endDate'>) => {
    const duration = getSparkDurationInDays({ startDate, endDate });
    return moment(startDate).subtract(duration, 'days').format('YYYY-MM-DD');
};
