import React, { ComponentType, ReactElement, ReactNode } from 'react';
import { isPossiblePhoneNumber as _isPossiblePhoneNumber } from 'react-phone-number-input';

import { DayNameFromNumber } from '@constants/SparkConstants';
import moment from 'moment';
import { v4 } from 'uuid';

import { RecurringSparkSchedule } from '@sparkplug/lib';

import { IOptionTree } from '@app/types/UITypes';

export { formatPhoneNumber } from 'react-phone-number-input';

/**
 * @deprecated use clsx npm library
 * clsx('foo', true && 'bar', 'baz')
 * //=> 'foo bar baz'
 *
 * consult https://www.npmjs.com/package/clsx for more examples
 */
export const appendClasses = (classes: (string | null | undefined)[]): string => {
    return classes.filter((c) => c != null && c.trim().length > 0).join(' ');
};

export const formatBytes = (bytes: number, decimals = 2): string => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    // eslint-disable-next-line no-restricted-properties
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const sortByDate = (orderBy: string, order: 'asc' | 'desc') => {
    return (a: any, b: any) => {
        if (a[orderBy] == null || b[orderBy] == null) {
            return 0;
        }

        return order === 'asc'
            ? moment(a[orderBy]).valueOf() - moment(b[orderBy]).valueOf()
            : moment(b[orderBy]).valueOf() - moment(a[orderBy]).valueOf();
    };
};

export const sortByString = (orderBy: string, order: 'asc' | 'desc') => {
    return (a: any, b: any) => {
        if (a[orderBy] == null) {
            return order === 'asc' ? 1 : -1;
        }

        if (b[orderBy] == null) {
            return order === 'asc' ? -1 : 1;
        }

        return order === 'asc'
            ? a[orderBy].localeCompare(b[orderBy])
            : b[orderBy].localeCompare(a[orderBy]);
    };
};

export const sortByNumber = (orderBy: string, order: 'asc' | 'desc') => {
    return (a: any, b: any) => {
        if (a[orderBy] == null && b[orderBy] == null) {
            return 0;
        }

        if (a[orderBy] == null) {
            return 1;
        } else if (b[orderBy] == null) {
            return -1;
        } else {
            return order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
        }
    };
};

export const arraysMatch = <T extends number | string | null | undefined>(
    arr1: T[],
    arr2: T[],
    exactOrder = true,
): boolean => {
    // Check if the arrays are the same length
    if (arr1.length !== arr2.length) {
        return false;
    }

    if (!exactOrder) {
        arr1.sort();
        arr2.sort();
    }

    // Check if all items exist and are in the same order
    for (let i = 0; i < arr1.length; i += 1) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }

    // Otherwise, return true
    return true;
};

export const stringIsCurrency = (str: string): boolean => {
    return str.match(/^\$?(\d{1,3}(,\d{3})*|(\d+))(\.\d{2})?$/) != null;
};

export const parseCurrencyToNumber = (str: string): number => {
    return parseFloat(str.replace(/[$,]+/g, ''));
};

/**
 * @deprecated We have `formatCurrency` in `@sparkplug/lib` now
 */
export const formatNumberToCurrency = (num: string | number, showCents = false): string => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: showCents ? 2 : 0,
    });
    if (typeof num === 'string') {
        return formatter.format(parseCurrencyToNumber(num));
    }

    return formatter.format(num);
};

export const mapListToSelectOptions = (
    list: {
        _id: string;
        name: string;
        [key: string]: any;
    }[],
) => {
    const result: any[] = [];
    const map = new Map();

    list.forEach((item) => {
        if (!map.has(item._id)) {
            map.set(item._id, true);
            result.push({
                ...item,
                label: item.name,
                value: item._id,
            });
        }
    });

    return result;
};

export const openUrl = (url: string, disableFallback = false) => {
    const newWindow = window.open(url, '_blank');

    if (!disableFallback) {
        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            window.open(url, '_self');
            return;
        }

        try {
            newWindow?.focus();
        } catch (e) {
            window.open(url, '_self');
        }
    }
};

export const getUrlParameter = <T extends string>(key: string): T | undefined => {
    const name = key.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(window.location.search);
    return results === null ? undefined : (decodeURIComponent(results[1].replace(/\+/g, ' ')) as T);
};

export const objectFilterNull = (obj: any): any => {
    // eslint-disable-next-line
    Object.keys(obj).forEach((key) => (obj[key] === undefined ? delete obj[key] : {}));
    return obj;
};

export const isObjectDataNull = (obj: Object): boolean => {
    const filteredObj = objectFilterNull(obj);
    return Object.keys(filteredObj).length === 0;
};
export const getLeaves = (item: any): any[] =>
    item.children != null && item.children.length > 0
        ? item.children.map((childItem: any) => getLeaves(childItem)).flat()
        : [item];

export const getFlattenedStructure = (item: any): any[] => {
    const result: any[] = [];
    const traverse = (node: any) => {
        if (Array.isArray(node)) {
            node.forEach((n: any) => {
                traverse(n); // Recursively traverse each item in the array
            });
        } else {
            const { children, ...nodeWithoutChildren } = node; // Destructure children property
            // check if nodeWithoutChildren is empty
            if (Object.keys(nodeWithoutChildren).length > 0) {
                result.push(nodeWithoutChildren); // Include the current node without children property
            }

            if (children != null && children.length > 0) {
                children.forEach((childItem: any) => {
                    traverse(childItem); // Recursively traverse each child
                });
            }
        }
    };

    traverse(item);

    return result;
};

export const getUniqueLeaves = (item: any): any[] => {
    const leaves = getLeaves(item);
    const map = new Map<string, any>();

    leaves.forEach((leaf: { value: any }) => {
        map.set(leaf.value, leaf);
    });

    return Array.from(map.values());
};

export const getUniqueLeavesFromList = (list: any[]): any[] => {
    if (list != null && list.length > 0) {
        return getUniqueLeaves({
            children: list,
        });
    }

    return [];
};

export const uuid = () => {
    return v4();
};

export const buildObjsFromArray = <T extends { _id: string }>(arr: T[]): { [_id: string]: T } => {
    return arr.reduce<{ [_id: string]: T }>((res, obj) => {
        return {
            ...res,
            [obj._id]: obj,
        };
    }, {});
};

export const flattenList = (tree: any[]) => {
    let arr = [...tree];

    tree.forEach(({ children }) => {
        if (children != null && children.length > 0) {
            arr = arr.concat(flattenList(children));
        }
    });

    return arr;
};

export const findRecursiveByKey = (tree: any[], key: string) => {
    let listItem = tree.find((obj) => obj.key === key);

    if (listItem != null) {
        return listItem;
    } else {
        tree.some(({ children }) => {
            if (children != null && children.length > 0) {
                listItem = findRecursiveByKey(children, key);
            }

            return listItem != null;
        });
    }

    return listItem;
};

export const filterTreeByLeafLabels = (tree: IOptionTree[], labels: string[]) => {
    const result: IOptionTree[] = [];

    tree.forEach(({ children = [], ...item }) => {
        if (children?.length > 0) {
            const filteredChildren = filterTreeByLeafLabels(children, labels);

            if (filteredChildren.length > 0) {
                result.push({
                    ...item,
                    children: filteredChildren,
                });
            }
        } else {
            if (labels.includes(item.internalKey!)) {
                result.push(item);
            }
        }
    });

    return result;
};

export const isValidPhoneNumber = (phoneNumber: string) => _isPossiblePhoneNumber(phoneNumber);

export const mapReactFragmentChildrenToArray = (fragment: React.ReactElement) => {
    return fragment.props.children;
};

/**
 * @deprecated use `capitalize` directly from `lodash` instead
 */
export function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

// copied from `https://stackoverflow.com/questions/21646738/convert-hex-to-rgba`
export function hexToRGBArray(hex: string): number[] {
    const isValidHex = /^#([A-Fa-f0-9]{3}){1,2}$/.test(hex);
    let c: any;

    if (isValidHex) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        // eslint-disable-next-line
        c = '0x' + c.join('');
        // eslint-disable-next-line
        const rgb = [(c >> 16) & 255, (c >> 8) & 255, c & 255];
        return rgb;
    }

    throw new Error('Bad Hex');
}

export function hexToRGBA(hex: string, alpha: number = 1): string {
    const rgb = hexToRGBArray(hex);
    return `rgba(${rgb.join(', ')}, ${alpha})`;
}

export const wrapSubstringWithComponent = (
    text: string,
    haystack: string,
    Component: ComponentType<{ children: ReactNode }> = ({ children }) => <span>{children}</span>,
): (string | ReactElement)[] => {
    const regex = new RegExp(`\\b${text}\\b`, 'ig');

    const split = haystack.split(regex);
    const replacements = haystack.match(regex);

    const result = split.reduce<(string | ReactElement)[]>((res, segment, i) => {
        if (segment) {
            res.push(segment);
        }

        if (replacements?.[i]) {
            res.push(<Component key={i}>{replacements?.[i]}</Component>);
        }

        return res;
    }, []);

    return result;
};

export const formatRecurringTooltip = (recurringSchedule: RecurringSparkSchedule): string => {
    switch (recurringSchedule.interval) {
        case 'daily':
            return `This is a daily recurring Spark that will run for 1 day, and recur on the selected days of the week: ${recurringSchedule.daysOfTheWeek
                ?.map((dayNumber) => DayNameFromNumber[dayNumber].short)
                .join(', ')}.`;
        case 'weekly':
            return 'This is a weekly recurring Spark that will run for 7 days, and recur each week, Monday through Sunday.';
        case 'twice_monthly':
            return 'This is a twice-monthly recurring Spark that will run for 14 days twice a month, starting on the 1st and the 15th.';
        case 'monthly':
            return 'This is a monthly recurring Spark that will run for a full month, and recur on the 1st of each month.';
        default:
            return `This is a ${recurringSchedule.interval} recurring Spark`;
    }
};

type ApplyFn<T extends unknown> = (items: T[]) => T[];

export const applyFilterFns = <T extends unknown>(applyFns: ApplyFn<T>[], data: T[]): T[] => {
    return applyFns.reduce((res, fn) => {
        return fn(res);
    }, data);
};
