import API from '@api/API';

import { AccountType } from '@sparkplug/lib';

import { useAdvancedQuery } from '../QueryHooks';

export const useFrillToken = (groupType: AccountType) => {
    const { data: frillAuthDetails, isFetched: frillTokenIsReady } = useAdvancedQuery(
        ['frillToken', groupType],
        async () => {
            const response = await API.get().getFrillAuthDetails({ groupType });

            return response;
        },
    );

    const { frillToken, frillAuthName, frillAuthEmail } = frillAuthDetails ?? {};
    return { frillToken, frillAuthName, frillAuthEmail, frillTokenIsReady };
};
