import { useEffect, useMemo, useState } from 'react';

import { intersection } from 'lodash';
import moment from 'moment';

import { Spark } from '@sparkplug/lib';

import { fetchGroup } from '@core/accounts';

import { useAdvancedQuery } from '@hooks/QueryHooks';

import { getOtherGroupId, getOtherGroupName, isSparkExternallyCreated } from '@helpers/sparks';

import { IAccount } from '@app/types/AccountsTypes';

/**
 * Creates a markets label based on the intersection of markets from the brand and retailer
 * Or, in the case of an internal spark, creates a markets label based on the retailers markets
 */
const useCreateMarketsLabel = ({ spark, account }: { spark: Spark; account?: IAccount }) => {
    const otherGroupId = getOtherGroupId({ spark, account });

    const { data: otherGroup } = useAdvancedQuery(
        ['account', spark.groupId],
        () => (otherGroupId ? fetchGroup(otherGroupId) : Promise.resolve(undefined)),
        {
            enabled: !!otherGroupId,
        },
    );

    if (!account?.name || !account?.type) {
        return undefined;
    }

    return useMemo(() => {
        const internalSpark = !otherGroupId;
        return internalSpark
            ? account?.metaData?.markets?.join(', ') || undefined
            : intersection(
                  otherGroup?.metaData?.markets ?? [],
                  account?.metaData?.markets ?? [],
              ).join(', ') || undefined;
    }, [otherGroup, account]);
};

const useDefaultLineItemLabel = ({
    spark,
    account,
}: {
    spark: Spark;
    account?: IAccount;
}): string => {
    const marketLabel = useCreateMarketsLabel({ spark, account });
    const marketText = marketLabel ? `(${marketLabel})` : '';

    const otherGroupName = useMemo(() => getOtherGroupName(spark, account), [spark, account]);

    if (!account?.name || !account?.type) {
        return '';
    }

    const brandName = account?.type === 'brand' ? account?.name : otherGroupName;

    const hasSparkBrand = account?.type === 'brand' && (spark as Spark).sparkBrand;

    const retailerName = account?.type === 'retailer' ? account?.name : otherGroupName;

    const dateFormattingPattern = 'M/D/YY';
    const formattedSparkStart = moment(spark.startDate).format(dateFormattingPattern);
    const formattedSparkEnd = moment(spark.endDate).format(dateFormattingPattern);
    const sparkDateRange = `${formattedSparkStart} - ${formattedSparkEnd}`;

    const defaultRetailerText =
        `Incentive payout (${sparkDateRange}) @ ${retailerName} ${marketText}`.trim();

    let defaultBrandText: string;

    if (hasSparkBrand) {
        const sparkBrandName = (spark as Spark).sparkBrand?.name;
        defaultBrandText = `${brandName} - ${sparkBrandName} - ${defaultRetailerText}`;
    } else {
        defaultBrandText = `${brandName} - ${defaultRetailerText}`;
    }

    const externallyCreated = isSparkExternallyCreated(spark);
    const isBrandCreated = externallyCreated || account?.type === 'brand';
    return isBrandCreated ? defaultBrandText : defaultRetailerText;
};

export const useInvoiceLabelState = ({
    spark,
    account,
}: {
    spark: Spark;
    account?: IAccount;
}): [string, Function] => {
    const [invoiceLabel, setInvoiceLabel] = useState('');
    const defaultInvoiceLabel = useDefaultLineItemLabel({ spark, account });

    useEffect(() => {
        setInvoiceLabel(defaultInvoiceLabel);
    }, [defaultInvoiceLabel]);

    return [invoiceLabel, setInvoiceLabel];
};
