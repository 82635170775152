import { Children, ReactNode, cloneElement, isValidElement } from 'react';

type PropsFn<T> = (child: ReactNode, index?: number) => T;

export const withProps = <T extends {}>(propsFn: PropsFn<T>) => {
    return (children: ReactNode) => {
        return Children.map(children, (child, i) => {
            if (isValidElement(child)) {
                return cloneElement(child, propsFn(child, i));
            }

            return undefined;
        });
    };
};
