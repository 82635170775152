import { FC, ReactNode } from 'react';

import moment, { MomentInput } from 'moment';

import { LastSoldAtTypes } from '@sparkplug/lib';

export const LAST_SOLD_DATE_OPTIONS = [
    {
        value: LastSoldAtTypes['-60days'],
        label: 'Last 60 Days',
        onApply: (startDate: MomentInput) => {
            const cutoffDate = moment(startDate).subtract(60, 'days');

            return (lastSoldAt: MomentInput) => moment(lastSoldAt).isSameOrAfter(cutoffDate, 'day');
        },
    },
    {
        value: LastSoldAtTypes['-90days'],
        label: 'Last 90 Days',
        onApply: (startDate: MomentInput) => {
            const cutoffDate = moment(startDate).subtract(90, 'days');

            return (lastSoldAt: MomentInput) => moment(lastSoldAt).isSameOrAfter(cutoffDate, 'day');
        },
    },
    {
        value: LastSoldAtTypes['-120days'],
        label: 'Last 120 Days',
        onApply: (startDate: MomentInput) => {
            const cutoffDate = moment(startDate).subtract(120, 'days');

            return (lastSoldAt: MomentInput) => moment(lastSoldAt).isSameOrAfter(cutoffDate, 'day');
        },
    },
    {
        value: LastSoldAtTypes.allTime,
        label: 'All Time',
        onApply: (startDate: MomentInput) => {
            return (lastSoldAt: MomentInput) => true;
        },
    },
];

export const SampleText: FC<{ children: ReactNode }> = ({ children }) => (
    <strong className="sample-text">{children}</strong>
);

export const SHOW_NEW_PRODUCTS_BY_LAST_SOLD_DATE = true;

export const onlyShowProductsFilterLabel = SHOW_NEW_PRODUCTS_BY_LAST_SOLD_DATE
    ? 'Only show products sold since spark was created'
    : 'Only show new products since spark was created';

export const MULTI_RETAILER_INTERCOM_CALLOUT_MESSAGE =
    'Set rules below to determine which products qualify for this Spark; new product sales matching these rules will be automatically included.';

export const SINGLE_RETAILER_INTERCOM_CALLOUT_MESSAGE =
    'Set rules below to determine which products qualify for this Spark; new product sales matching these rules will be automatically included.';
