import { ComponentProps, MouseEvent, ReactElement, forwardRef } from 'react';

// eslint-disable-next-line no-restricted-imports
import { Button as MaterialUIButton } from '@mui/material';

import Spinner from '@components/layout/Spinner';
import Tooltip from '@components/layout/Tooltip';

import { appendClasses } from '@helpers/ui';

import { ITestIdProps } from '@app/types/UITypes';

import './Button.scss';

export type ButtonVariant = 'flat' | 'filled' | 'raised' | 'smooth' | 'outlined';
export type ButtonColor = 'neutral' | 'blue' | 'green' | 'red' | 'yellow' | 'white' | 'purple';

export interface IButtonProps extends ITestIdProps {
    ref?: any;
    tutorialTarget?: string;
    dataQa?: string;
    className?: string;
    color?: ButtonColor;
    variant?: ButtonVariant;
    children?: ReactElement | string;
    onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
    onHover?: {
        onEnter: () => void;
        onLeave: () => void;
    };
    dense?: boolean;
    disabled?: boolean;
    tooltip?: string | null;
    tooltipWrapperClassName?: string;
    endIcon?: ReactElement | null;
    startIcon?: ReactElement | null;
    showSpinner?: boolean;
    'data-qa-btn'?: string;
    type?: 'button' | 'submit' | 'reset';
    disableHover?: boolean;
    sx?: ComponentProps<typeof MaterialUIButton>[`sx`];
}

const Button = forwardRef<HTMLButtonElement, IButtonProps>(
    (
        {
            className = '',
            color = 'blue',
            variant = 'flat',
            onClick,
            dense = false,
            disabled = false,
            endIcon,
            startIcon,
            children,
            showSpinner = false,
            type = 'button',
            onHover,
            disableHover = false,
            sx,
            ...otherProps
        },
        ref,
    ) => {
        const { 'data-qa-btn': dataQaBtn, 'data-testid': dataTestId } = otherProps;

        const classNameAppended = appendClasses([
            className,
            'btn',
            `btn-color-${color}`,
            `btn-variant-${variant}`,
            disableHover ? `btn-disable-hover` : `btn-enable-hover`,
            dense ? `btn-dense` : undefined,
        ]);

        const autoDataQa =
            children && typeof children === 'string' ? children.toString() : undefined;

        const props: ComponentProps<typeof MaterialUIButton> = {
            className: classNameAppended,
            type,
            onClick,
            disabled,
            startIcon: showSpinner ? <Spinner /> : startIcon,
            endIcon,
            onMouseEnter: onHover?.onEnter,
            onMouseLeave: onHover?.onLeave,
            sx,
        };

        return (
            <MaterialUIButton
                ref={ref}
                data-qa-btn={dataQaBtn || autoDataQa}
                data-testid={dataTestId}
                {...props}
            >
                {children}
            </MaterialUIButton>
        );
    },
);

const WrappedButton = forwardRef<HTMLButtonElement, IButtonProps>(
    ({ tooltip, tooltipWrapperClassName = '', ...props }: IButtonProps, ref) => {
        if (tooltip) {
            return (
                <Tooltip title={tooltip}>
                    <div className={tooltipWrapperClassName}>
                        <Button ref={ref} {...props} />
                    </div>
                </Tooltip>
            );
        }

        return <Button ref={ref} {...props} />;
    },
);
WrappedButton.displayName = 'Button';

export default WrappedButton;
