import {
    Children,
    FC,
    ReactElement,
    ReactNode,
    RefObject,
    isValidElement,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import Tabs from '@components/layout/Tabs';

import { withProps } from '@helpers/index';

import './SmartTabs.scss';

const useStickyOnScroll = ({ elementRef }: { elementRef?: RefObject<HTMLElement> }) => {
    const [wrapperHeight, setWrapperHeight] = useState(0);
    const tabBarWrapperRef = useRef<HTMLDivElement>(null);
    const [tabBarStyles, setTabBarStyles] = useState<{
        top: number;
        paddingLeft: number;
        paddingRight: string;
        position: 'fixed';
        zIndex: 8;
        left: 0;
        right: 0;
    }>();

    useLayoutEffect(() => {
        const scrollHandler = () => {
            if (!elementRef) {
                setWrapperHeight(0);
                setTabBarStyles(undefined);
                return;
            }

            const scrollPosition = window.scrollY;
            const tabBarOffsetTop = tabBarWrapperRef.current?.offsetTop ?? 0;
            const stickyPosition =
                (elementRef.current?.offsetTop ?? 0) + (elementRef.current?.offsetHeight ?? 0);

            const tabBarShouldBeSticky = scrollPosition + stickyPosition > tabBarOffsetTop;
            if (tabBarShouldBeSticky) {
                const tabBarHeight = tabBarWrapperRef.current?.offsetHeight ?? 0;
                setWrapperHeight(tabBarHeight);

                const paddingLeft = tabBarWrapperRef.current?.offsetLeft ?? 0;
                const tabBarWidth = tabBarWrapperRef.current?.offsetWidth ?? 0;
                const paddingRight = `calc(100% - ${paddingLeft + tabBarWidth}px)`;
                setTabBarStyles({
                    position: 'fixed',
                    top: stickyPosition,
                    zIndex: 8,
                    left: 0,
                    right: 0,
                    paddingLeft,
                    paddingRight,
                });
            } else {
                setWrapperHeight(0);
                setTabBarStyles(undefined);
            }
        };

        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    return { wrapperHeight, tabBarStyles, tabBarWrapperRef };
};

type SmartTabTabPanelProps = {
    label: string | ReactElement<{ label: string }>;
    currentIndex?: number;
    index?: number;
    children: ReactNode;
    disabled?: boolean;
};

const SmartTabsTabPanel: FC<SmartTabTabPanelProps> = ({ label, currentIndex, index, children }) => {
    if (currentIndex == null) {
        // eslint-disable-next-line no-console
        console.warn(
            `WARNING: This <SmartTabs.TabPanel label={${label}}/> must be a DIRECT child of a <SmartTabs /> component`,
        );
        return null;
    }

    return (
        <Tabs.TabPanel value={currentIndex} index={index}>
            {children}
        </Tabs.TabPanel>
    );
};

export type SmartTabsProps = {
    children: ReactNode;
    externalIndex?: number;
    setExternalIndex?: (v: number) => void;
    color?: 'neutral' | 'blue' | 'green' | 'red';
    fullWidth?: boolean;
    stickyOnMobileScroll?: {
        elementRef?: RefObject<HTMLElement>;
    };
    orientation?: 'horizontal' | 'vertical';
};

const SmartTabs = ({
    children,
    externalIndex,
    setExternalIndex,
    color = 'neutral',
    fullWidth = false,
    stickyOnMobileScroll,
    orientation = 'horizontal',
}: SmartTabsProps) => {
    const [internalIndex, setInternalIndex] = useState(0);

    const currentIndex = useMemo(() => {
        return externalIndex || internalIndex;
    }, [externalIndex, internalIndex]);

    const setCurrentIndex = (i: number) =>
        setExternalIndex != null ? setExternalIndex(i) : setInternalIndex(i);

    const derivedLabels = useMemo(() => {
        return (
            Children.map(children, (child) => {
                if (isValidElement<SmartTabTabPanelProps>(child)) {
                    return { label: child?.props.label, disabled: child?.props.disabled };
                }
                return {};
            }) ?? []
        );
    }, [children]);

    const childrenWithProps = useMemo(() => {
        return withProps((child, index) => {
            return {
                currentIndex,
                index,
            };
        })(children);
    }, [currentIndex, children]);

    const { tabBarWrapperRef, wrapperHeight, tabBarStyles } = useStickyOnScroll({
        elementRef: stickyOnMobileScroll?.elementRef,
    });

    return (
        <div className={`orientation-${orientation}`}>
            <div
                ref={tabBarWrapperRef}
                className="smart-tabs-wrapper"
                style={wrapperHeight ? { height: wrapperHeight } : undefined}
            >
                <Tabs
                    color={color}
                    value={currentIndex}
                    fullWidth={fullWidth}
                    style={tabBarStyles}
                    orientation={orientation}
                >
                    {derivedLabels.map(({ label, disabled }, i) => {
                        return (
                            <Tabs.Tab
                                key={i}
                                label={label}
                                disabled={disabled}
                                onClick={() => setCurrentIndex(i)}
                            />
                        );
                    })}
                </Tabs>
            </div>
            {childrenWithProps}
        </div>
    );
};

export type TSmartTabHOC = {
    currentIndex?: number;
    index?: number;
};

SmartTabs.TabPanel = SmartTabsTabPanel;

export default SmartTabs;
