import API from '@api/API';

interface IResponse {
    data: any[];
    meta: {
        current: number;
        offset: number;
        limit: number;
        total: number;
    };
}

export const fetchBatchedData = async (
    asyncGet: (offset: number, limit: number) => Promise<IResponse>,
) => {
    return API.fetchBatchedData(asyncGet);
};
