import { ReactNode } from 'react';

import './TransactionDetails.scss';

interface TransactionDetailsContentProps {
    children: ReactNode;
    className?: string;
}

const TransactionDetailsContent = ({ children, className }: TransactionDetailsContentProps) => {
    return <div className={`transaction-content ${className}`}>{children}</div>;
};

export default TransactionDetailsContent;
