import { ComponentProps, FC, useEffect, useMemo } from 'react';

import { MoreVert as MoreIcon } from '@mui/icons-material';
import { keyBy, omit } from 'lodash';
import moment from 'moment/moment';

import {
    AccountLink,
    DO_NOT_HAVE_PERMISSIONS_MESSAGE,
    ListSparkRequestsQueryParams,
    RequestForSparkStatus,
    UIRequestForSpark,
} from '@sparkplug/lib';

import { useAccountLinks } from '@features/account-links';
import { useIgnoreRequestForSparkMutation } from '@features/request-for-spark/mutations';
import { useGetRequestsForSparksQuery } from '@features/request-for-spark/queries';
import { UIRequestForSparkWithAccountLink } from '@features/request-for-spark/types';
import { SparkAccountDisplay } from '@features/spark-dashboard/components/SparkAccountDisplay';

import Button from '@components/buttons/Button';
import Chip from '@components/chips/Chip';
import Dropdown from '@components/dropdown/Dropdown';
import { NoInboxSparksGraphic } from '@components/graphics';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import { queryToString, useHistory, useQueryParams } from '@components/router';
import CreateSparkButton from '@components/sparks/CreateSparkButton';
import AdvancedTable from '@components/table/AdvancedTable';
import Table from '@components/table/Table';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { IAccount } from '@app/types/AccountsTypes';
import { ITableRow, THeadCell } from '@app/types/TableTypes';

import { RespondToRequestModal } from '../RespondToRequestModal';

import './RequestForSparksTable.scss';

export enum ERequestStatusColors {
    open = 'neutral',
    accepted = 'green',
    ignored = 'neutral',
}

const RequestStatusChip = ({ status }: { status: RequestForSparkStatus }) => {
    const color = ERequestStatusColors[status];
    return (
        <Chip
            className="request-status-chip"
            color={color}
            label={status}
            variant={status === 'open' ? 'outlined' : 'flat'}
        />
    );
};

type RequestForSparkTableRowData = UIRequestForSparkWithAccountLink & {
    userIsPermitted: boolean;
    goToCreateSparkFromRequest: (requestForSpark: UIRequestForSpark) => void;
    ignoreRequest: (requestForSpark: UIRequestForSpark) => void;
    goToSpark: (sparkId?: string, openInNewTab?: boolean) => void;
};
const stickyHeadCells: THeadCell<RequestForSparkTableRowData>[] = [
    {
        id: 'sourceAccountName',
        label: () => {
            return (
                <div className="sticky-header">
                    <div className="label">
                        <span>Retailer</span>
                    </div>
                </div>
            );
        },
        sortType: 'string',
        render: (row) => (
            <Table.Cell>
                <SparkAccountDisplay name={row.sourceAccountName} photo={row.sourceAccountLogo} />
                <RequestStatusChip status={row.status} />
            </Table.Cell>
        ),
    },
];

const headCells: THeadCell<RequestForSparkTableRowData>[] = [
    {
        id: 'createdAt',
        label: 'Requested On',
        sortType: 'string',
        render: (row) => (
            <Table.Cell className="no-wrap">
                {moment(row.createdAt).format('MMM D, YYYY')}
            </Table.Cell>
        ),
    },
    {
        id: 'message',
        label: 'Request Details',
        sortType: 'string',
        render: (row) => (
            <Table.Cell className="message-cell">
                {row.message ?? <span className="message-placeholder-text">None Provided</span>}
            </Table.Cell>
        ),
    },
];

const stickyRightCells: THeadCell<RequestForSparkTableRowData>[] = [
    {
        id: 'action',
        sticky: 'right',
        render: ({
            goToCreateSparkFromRequest,
            ignoreRequest,
            goToSpark,
            userIsPermitted,
            ...requestForSpark
        }) => {
            const requestStatus = requestForSpark.status;

            const userPermissionTooltip: ComponentProps<typeof Dropdown.MenuItem>['tooltipProps'] =
                !userIsPermitted ? { title: DO_NOT_HAVE_PERMISSIONS_MESSAGE } : undefined;

            const acceptAndCreateMenuItem = (
                <Dropdown.MenuItem
                    disabled={!userIsPermitted}
                    tooltipProps={userPermissionTooltip}
                    onClick={() => goToCreateSparkFromRequest(requestForSpark)}
                >
                    Accept & Create
                </Dropdown.MenuItem>
            );
            const ignoreMenuItem = (
                <Dropdown.MenuItem
                    disabled={!userIsPermitted}
                    tooltipProps={userPermissionTooltip}
                    onClick={() => ignoreRequest(requestForSpark)}
                >
                    Ignore
                </Dropdown.MenuItem>
            );
            const viewSparkMenuItem = (
                <Dropdown.MenuItem onClick={() => goToSpark(requestForSpark?.sparkId, true)}>
                    Open Spark In New Tab
                </Dropdown.MenuItem>
            );

            return (
                <Table.Cell
                    align="left"
                    className="more-button"
                    onClick={(e) => e?.stopPropagation()}
                >
                    <Dropdown className="more-button">
                        <Dropdown.IconButton color="neutral">
                            <MoreIcon />
                        </Dropdown.IconButton>

                        <Dropdown.Menu>
                            {requestStatus === 'accepted' && viewSparkMenuItem}
                            {requestStatus !== 'accepted' && acceptAndCreateMenuItem}
                            {requestStatus === 'open' && ignoreMenuItem}
                        </Dropdown.Menu>
                    </Dropdown>
                </Table.Cell>
            );
        },
    },
];

export interface RequestsForSparksTableProps {
    account: IAccount;
    accountLinks?: AccountLink[];
    requestsForSparks: UIRequestForSpark[];
    isLoading?: boolean;
    totalDocuments: number;
    userIsPermitted: boolean;
    enableQueryParams?: boolean;
}
export const RequestsForSparksTable: FC<RequestsForSparksTableProps> = ({
    account,
    accountLinks,
    requestsForSparks,
    isLoading = true,
    totalDocuments,
    userIsPermitted,
    enableQueryParams = true,
}) => {
    const {
        rid: queryRequestId,
        ...restQueryParams
    }: { p?: number; rid?: string } & ListSparkRequestsQueryParams = useQueryParams();

    const history = useHistory();

    const goToRequestModal = (requestForSpark: UIRequestForSpark) => {
        history.push({
            search: queryToString({
                rid: requestForSpark._id,
                ...restQueryParams,
            }),
        });
    };

    const closeRequestModal = (_: any) => {
        history.push({ search: queryToString(restQueryParams) });
    };

    const goToOpenRequests = () => {
        history.push(`/${account?._id}/sparks/requests?status=open`);
    };

    const goToCreateSparkFromRequest = (requestForSpark: UIRequestForSparkWithAccountLink) => {
        // Store requestForSpark in location state to ultimately be used in the Spark Wizard
        history.push(`/${account?._id}/sparks/create`, { requestForSpark });
    };

    const goToSpark = (sparkId?: string, openInNewTab: boolean = false) => {
        if (openInNewTab) {
            window.open(`/${account?._id}/sparks/${sparkId}`);
        } else {
            history.push(`/${account?._id}/sparks/${sparkId}`);
        }
    };

    const clearStatusFilter = () => {
        history.push(
            `?${queryToString({
                ...omit(restQueryParams, 'status'),
            })}`,
        );
    };

    const { ignoreRequestForSparkAsync } = useIgnoreRequestForSparkMutation();
    const ignoreRequest = (requestForSpark: UIRequestForSpark) => {
        ignoreRequestForSparkAsync(requestForSpark._id);
    };

    const requestToInspect: UIRequestForSparkWithAccountLink | undefined = useMemo(() => {
        const request = requestsForSparks.find((r) => r._id === queryRequestId);
        const accountLink = accountLinks?.find((al) => al.accountId === request?.sourceAccountId);
        return request ? { ...request, accountLink } : undefined;
    }, [accountLinks, queryRequestId, requestsForSparks]);

    const rowData: ITableRow<RequestForSparkTableRowData>[] = useMemo(() => {
        const accountLinksByAccountId = keyBy(accountLinks, 'accountId');
        return requestsForSparks
            .map((requestForSpark) => {
                return {
                    ...requestForSpark,
                    key: requestForSpark._id,
                    accountLink: accountLinksByAccountId[requestForSpark.sourceAccountId],
                    userIsPermitted,
                    goToSpark,
                    goToCreateSparkFromRequest,
                    ignoreRequest,
                };
            })
            .filter((r) => r.accountLink);
    }, [requestsForSparks, accountLinks, userIsPermitted]);

    useEffect(() => {
        /**
         * If a request already has a sparkId redirect to the associated spark.
         * This should only happen when navigating to a request from a stale link (from in-app/email notifications)
         */
        if (requestToInspect?.sparkId && account) {
            goToSpark(requestToInspect.sparkId);
        }
    }, [account, requestToInspect]);

    const showOpenStatusFilterEmptyState =
        !isLoading && !rowData?.length && restQueryParams.status === 'open';
    if (showOpenStatusFilterEmptyState) {
        return (
            <EmptyStateDisplay
                contentClassName="pb-4"
                graphic={<NoInboxSparksGraphic />}
                label="No Open Spark Requests"
                actionButton={
                    <Button variant="flat" onClick={clearStatusFilter}>
                        View Past Requests
                    </Button>
                }
            />
        );
    }

    const showNoStatusFilterEmptyState = !isLoading && !rowData?.length && !restQueryParams.status;
    if (showNoStatusFilterEmptyState) {
        return (
            <EmptyStateDisplay
                contentClassName="pb-4"
                graphic={<NoInboxSparksGraphic />}
                label="No Spark Requests"
                actionButton={<CreateSparkButton className="mb-4" />}
            />
        );
    }

    return (
        <>
            <AdvancedTable
                variant="raised"
                stickyLeft={stickyHeadCells}
                headCells={headCells}
                stickyRight={stickyRightCells}
                rows={rowData}
                className="spark-requests-table"
                enableQueryParams={enableQueryParams}
                showPagination
                disableFrontendFiltering
                isLoading={isLoading}
                defaultOptions={{
                    rowsPerPage: restQueryParams.limit,
                    order: restQueryParams.order === 'asc' ? 'asc' : 'desc',
                    orderBy: restQueryParams.orderBy ?? 'createdAt',
                }}
                rowCountOverride={totalDocuments}
            >
                <Table.RenderHead />
                <Table.RenderBody
                    rowRenderKeyFn={(row: RequestForSparkTableRowData) =>
                        `${row._id}-${row.status}`
                    }
                    onRowClick={(row) => {
                        if (row.status === 'accepted') {
                            goToSpark(row.sparkId);
                        } else {
                            goToRequestModal(row);
                        }
                    }}
                />
            </AdvancedTable>

            {!!queryRequestId && (
                <RespondToRequestModal
                    isVisible
                    userIsPermitted={userIsPermitted}
                    requestsForSparksAreReady={!isLoading}
                    requestForSpark={requestToInspect}
                    onViewOpenRequests={goToOpenRequests}
                    onAcceptAndCreate={goToCreateSparkFromRequest}
                    onIgnore={ignoreRequest}
                    onClose={closeRequestModal}
                />
            )}
        </>
    );
};

export default ({
    account,
    enableQueryParams = true,
}: {
    account: IAccount;
    enableQueryParams?: boolean;
}) => {
    const { p = 0, ...queryParams }: { p?: number } & ListSparkRequestsQueryParams =
        useQueryParams();
    const { userCan } = useSparkplugAccount();
    // TODO: Update requestsForSparks endpoint to hydrate accountLinks
    const { accountLinks, accountLinksAreReady } = useAccountLinks(account?._id);
    const { requestsForSparks, requestsForSparksAreLoading, meta } = useGetRequestsForSparksQuery({
        account,
        requestForSparkFilters: {
            offset: Number(p),
            ...queryParams,
        },
    });

    return (
        <RequestsForSparksTable
            account={account}
            accountLinks={accountLinks}
            requestsForSparks={requestsForSparks}
            isLoading={requestsForSparksAreLoading || !accountLinksAreReady}
            totalDocuments={meta?.total ?? 0}
            userIsPermitted={userCan('createSpark')}
            enableQueryParams={enableQueryParams}
        />
    );
};
