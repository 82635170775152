import { useCallback, useMemo, useState } from 'react';

import { useRemoveAndDeleteAccountMemberMutation, useSaveAccountUserMutation } from '@core/users';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { NoUsersGraphic } from '@components/graphics';
import { CustomArrowRightIcon, DeleteForever } from '@components/icons';
import SearchSelect from '@components/inputs/SearchSelect';
import CalloutMessage from '@components/layout/CalloutMessage';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import Grid from '@components/layout/Grid';
import Tooltip from '@components/layout/Tooltip';
import Drawer from '@components/overlays/Drawer';
import toast from '@components/toast';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { noop, removePropertyFromObj } from '@helpers/util';

import { IAccount } from '@app/types/AccountsTypes';
import { IOption } from '@app/types/UITypes';
import { IAccountUser } from '@app/types/UsersTypes';

import { useSelfServiceQueueState } from '..';

import './SelfServiceQueueDrawer.scss';

const UnmatchedEmployeeLabel = ({ name, phoneNumber }: { name: string; phoneNumber: string }) => {
    return (
        <div className="unmatched-employee-label">
            <span className="name">{name}</span>
            <span className="phone-number">{phoneNumber}</span>
        </div>
    );
};

interface IUserMatchData {
    posEmployeeProfileId: string;
    sparkplugUserId: string;
    phoneNumber?: string;
}
const UserMatchingRow = ({
    user,
    userOptions,
    onUserMatchChange,
    onUserMatchDelete,
    isRemovingAndDeletingAccountMember,
}: {
    user: IAccountUser & { phoneNumberFormatted?: string };
    userOptions: IOption[];
    onUserMatchChange: (userData: IUserMatchData) => unknown;
    onUserMatchDelete: (sparkplugUserId: string) => void;
    isRemovingAndDeletingAccountMember: boolean;
}) => {
    const [selectedUser, setSelectedUser] = useState<string>();

    const handleChange = useCallback(
        ({ value: posEmployeeProfileId }: IOption) => {
            setSelectedUser(posEmployeeProfileId);
            onUserMatchChange({
                posEmployeeProfileId,
                sparkplugUserId: user.userId as string,
                phoneNumber: user.phoneNumber,
            });
        },
        [user],
    );

    return (
        <Grid className="ss-queue-row">
            <Grid.Item>
                <UnmatchedEmployeeLabel
                    name={user.fullName as string}
                    phoneNumber={user.phoneNumberFormatted as string}
                />
            </Grid.Item>
            <Grid.Item className="ss-queue-divider">
                <div>
                    <CustomArrowRightIcon />
                </div>
            </Grid.Item>

            <Grid.Item>
                <SearchSelect
                    placeholder="Type or choose"
                    options={userOptions}
                    value={selectedUser as string}
                    onChange={handleChange}
                />
            </Grid.Item>

            <Grid.Item className="delete-user">
                <Tooltip title="Delete User">
                    <div>
                        <IconButton
                            color="red"
                            onClick={() => onUserMatchDelete(user.userId as string)}
                            size="medium"
                            disabled={isRemovingAndDeletingAccountMember}
                        >
                            <DeleteForever />
                        </IconButton>
                    </div>
                </Tooltip>
            </Grid.Item>
        </Grid>
    );
};

export const ssQueueCalloutMessage =
    'The following employees who completed self-service onboarding could not be matched to any existing employees. Select an existing employee to map to.';

// Keeps options up to date with the currently matched users in the modal
export const getPosEmployeeProfileOptions = (
    users: IAccountUser[] = [],
    matchedUsersMap: { [key: string]: IUserMatchData },
): IOption[] => {
    const userOptions = users
        .filter(({ userId, posEmployeeProfileIds, role }) => {
            const isPosEmployeeProfile = !userId;
            const isAlreadyEnrolled = !!userId;
            const isInactive = role === 'none';
            const isMatched = Object.values(matchedUsersMap).find((matchedUser) =>
                posEmployeeProfileIds.includes(matchedUser?.posEmployeeProfileId),
            );

            return isPosEmployeeProfile && !isAlreadyEnrolled && !isInactive && !isMatched;
        })
        .map(({ fullName = '', posEmployeeProfileIds }) => ({
            label: fullName as string,
            value: posEmployeeProfileIds?.[0],
        }));

    const emptyOption = { label: '--', value: '' };
    return [emptyOption, ...userOptions];
};

export interface ISelfServiceQueueModalProps {
    isVisible?: boolean;
    onClose?: (didSaveChanges: boolean) => unknown;
}
export const SelfServiceQueueDrawer = ({
    isVisible = false,
    onClose = noop,
}: ISelfServiceQueueModalProps) => {
    const { account } = useSparkplugAccount();
    const { allUsers, unmatchedUsers, refetchAllUsers } = useSelfServiceQueueState();

    const [matchedUsersMap, setMatchedUsersMap] = useState<{ [key: string]: IUserMatchData }>({});

    const { saveAccountUser } = useSaveAccountUserMutation(account ?? ({} as IAccount));

    const { removeAndDeleteAccountMember, isRemovingAndDeletingAccountMember } =
        useRemoveAndDeleteAccountMemberMutation(account ?? ({} as IAccount));

    const handleUserMatchChange = ({
        posEmployeeProfileId,
        sparkplugUserId,
        phoneNumber,
    }: IUserMatchData) => {
        // If the empty option is selected for an unmatched user, remove them from the matchedUsersMap
        const emptyOptionWasSelected = posEmployeeProfileId === '';
        if (emptyOptionWasSelected) {
            setMatchedUsersMap((prevMap) => removePropertyFromObj(sparkplugUserId, prevMap));
            return;
        }

        // Happy/Standard path
        const payload = {
            role: 'group-member',
            sparkplugUserId,
            posEmployeeProfileId,
            phoneNumber,
        };
        setMatchedUsersMap((prevMap) => ({
            ...prevMap,
            [sparkplugUserId]: payload,
        }));
    };

    const handleUserMatchDelete = (sparkplugUserId: string) => {
        removeAndDeleteAccountMember({ userId: sparkplugUserId });
    };

    const posEmployeeProfileOptions = useMemo(
        () => getPosEmployeeProfileOptions(allUsers, matchedUsersMap),
        [allUsers, matchedUsersMap],
    );

    const closeAndCleanup = (didUpdateServer: boolean) => {
        setMatchedUsersMap({});
        onClose(didUpdateServer);
    };

    const handleSave = async (): Promise<void> => {
        const userEnrollmentPromises = Object.values(matchedUsersMap).map(
            (enrollmentPayload: IUserMatchData) => {
                return saveAccountUser({
                    userData: {
                        posEmployeeProfileIds: [enrollmentPayload.posEmployeeProfileId],
                        phoneNumber: enrollmentPayload.phoneNumber,
                        role: 'group-member',
                    },
                    userId: enrollmentPayload.sparkplugUserId,
                });
            },
        );

        return new Promise(() => {
            return toast.promise(Promise.all(userEnrollmentPromises), {
                loading: 'Resolving unmatched users...',
                success: () => {
                    refetchAllUsers();
                    closeAndCleanup(true);
                    return 'Success!';
                },
                error: 'Something went wrong.',
            });
        });
    };

    const shouldDisableSave = Object.values(matchedUsersMap).length === 0;

    return (
        <Drawer
            title="Unmatched Users"
            variant="right"
            isVisible={isVisible}
            className="self-service-queue-drawer"
            onCloseHandler={() => closeAndCleanup(false)}
        >
            <div className="content">
                {unmatchedUsers.length ? (
                    <CalloutMessage
                        title="Instructions"
                        message={ssQueueCalloutMessage}
                        color="yellow"
                    />
                ) : (
                    <></>
                )}
                <section>
                    {unmatchedUsers.length ? (
                        <>
                            <Grid className="head">
                                <Grid.Item>Unmatched Employee</Grid.Item>
                                <Grid.Item>Map to Existing Employee</Grid.Item>
                            </Grid>

                            {unmatchedUsers.map((user) => (
                                <UserMatchingRow
                                    key={user.userId}
                                    user={user}
                                    userOptions={posEmployeeProfileOptions}
                                    onUserMatchChange={handleUserMatchChange}
                                    onUserMatchDelete={handleUserMatchDelete}
                                    isRemovingAndDeletingAccountMember={
                                        isRemovingAndDeletingAccountMember
                                    }
                                />
                            ))}
                        </>
                    ) : (
                        <EmptyStateDisplay
                            label="No unmatched users."
                            graphic={<NoUsersGraphic />}
                        />
                    )}
                </section>
            </div>

            <Drawer.Footer>
                <Button onClick={() => closeAndCleanup(false)}>Cancel</Button>
                <Button variant="smooth" onClick={handleSave} disabled={shouldDisableSave}>
                    Save
                </Button>
            </Drawer.Footer>
        </Drawer>
    );
};
