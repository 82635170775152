import { DefaultChartColors } from '@components/charts/nivo/Nivo.Constants';

import { IDataArrayItem, IDataSet, ILineDataPoint } from '@app/types/ChartDataTypes';

import BucketFactory from '../buckets/BucketFactory';

const defaultLabels = {
    metricLabel: '',
};

export default class NivoLineChartFactory {
    generateChartData(
        bucketFactory: BucketFactory,
        name: string,
        dataArray: IDataArrayItem[],
        labels = defaultLabels,
        index: number = 0,
    ) {
        const colorIndex = index % DefaultChartColors.length;
        // Line chart data must always be an array
        return [
            {
                id: name,
                color: DefaultChartColors[colorIndex],
                data: dataArray.map((kvp) => {
                    const x = bucketFactory.getShortName(kvp.name);
                    const y = kvp.value > -1 ? kvp.value : 0;
                    const meta = {
                        tooltipTitle: bucketFactory.getTooltipTitle(kvp.name),
                        date: kvp.name,
                        comparisonLabel: bucketFactory.getComparisonLabel(kvp.name),
                        ...labels,
                    };
                    const point: ILineDataPoint = { x, y, meta };
                    return point;
                }),
            },
        ];
    }

    generateMultiDimensionalChartData(
        bucketFactory: BucketFactory,
        dataSets: IDataSet[],
        labels = defaultLabels,
    ) {
        return dataSets.map((dataSet, i) => {
            const chartData = this.generateChartData(
                bucketFactory,
                dataSet.name,
                dataSet.dataArray,
                labels,
                i,
            );
            return chartData?.[0];
        });
    }
}
