import React, { PropsWithChildren } from 'react';

import { Badge as MuiBadge } from '@mui/material';

import { appendClasses } from '@helpers/ui';

import './Badge.scss';

const Badge: React.FC<
    PropsWithChildren<{
        className?: string;
        count: number;
        disabled?: boolean;
    }>
> = ({ className, count, children, disabled = false }) => {
    const classNameAppended = appendClasses([className, 'badge']);

    if (disabled) {
        return <>{children}</>;
    }

    return (
        <MuiBadge className={classNameAppended} overlap="rectangular" badgeContent={count}>
            {children}
        </MuiBadge>
    );
};

export default Badge;
