import { FC, PropsWithChildren } from 'react';

import './SectionTitle.scss';

export interface SectionTitleProps {
    className?: string;
}

export const SectionTitle: FC<PropsWithChildren<SectionTitleProps>> = ({ className, children }) => (
    <header className={`content-section-title ${className}`.trim()}>{children}</header>
);
