import { FC } from 'react';

import moment from 'moment';

import { formatCurrency } from '@sparkplug/lib';

import { BrandIcon, RetailerIcon2 } from '@components/icons';
import Tooltip from '@components/layout/Tooltip';
import Table from '@components/table/Table';

import { TAccountType } from '@app/types/AccountsTypes';
import { THeadCell } from '@app/types/TableTypes';

import './GiftCardsTable.scss';

export type GiftCardsTableRow = {
    key: string;
    createdAt: string;
    createdBySuperUserId: string;
    createdBySuperUserName: string;
    recipientAccountName: string;
    recipientAccountType: TAccountType;
    recipientUserName: string;
    contactMethod: string;
    campaignName: string;
    amount: number;
    deliveryLink: string;
    note?: string;
};

const giftCardsTableHeadCells: THeadCell<GiftCardsTableRow>[] = [
    {
        id: 'createdAt',
        label: 'Date Sent',
        sortType: 'date',
        render: ({ createdAt }) => <Table.Cell>{moment(createdAt).format('ll')}</Table.Cell>,
    },
    {
        id: 'createdBySuperUserName',
        label: 'Sender',
        sortType: 'string',
        render: ({ createdBySuperUserName }) => <Table.Cell>{createdBySuperUserName}</Table.Cell>,
    },
    {
        id: 'recipientAccountName',
        label: 'Recipient Account',
        sortType: 'string',
        render: ({ recipientAccountName, recipientAccountType }) => {
            const icon = recipientAccountType === 'brand' ? <BrandIcon /> : <RetailerIcon2 />;

            return (
                <Table.Cell className="recipient-account">
                    {icon}
                    <section>
                        <span className="account-name">{recipientAccountName}</span>
                        <span className="account-type">{recipientAccountType}</span>
                    </section>
                </Table.Cell>
            );
        },
    },
    {
        id: 'recipientUserName',
        label: 'Recipient Name',
        sortType: 'string',
        render: ({ recipientUserName }) => <Table.Cell>{recipientUserName}</Table.Cell>,
    },
    {
        id: 'contactMethod',
        label: 'Contact Method',
        sortType: 'string',
        render: ({ contactMethod }) => <Table.Cell>{contactMethod}</Table.Cell>,
    },
    {
        id: 'campaignName',
        label: 'Campaign',
        sortType: 'string',
        render: ({ campaignName }) => <Table.Cell>{campaignName}</Table.Cell>,
    },
    {
        id: 'amount',
        label: 'Amount',
        sortType: 'numeric',
        render: ({ amount, deliveryLink }) => (
            <Table.Cell>
                <a href={deliveryLink} target="_blank" rel="noopener noreferrer">
                    {formatCurrency(amount)}
                </a>
            </Table.Cell>
        ),
    },
    {
        id: 'note',
        label: 'Reason',
        render: ({ note }) => {
            if (!note?.length) {
                return <Table.Cell>--</Table.Cell>;
            }

            return (
                <Table.Cell className="reason">
                    <Tooltip title={note ?? ''} placement="bottom-start">
                        <span className="text-wrapper">{note}</span>
                    </Tooltip>
                </Table.Cell>
            );
        },
    },
];

export interface GiftCardsTableProps {
    isReady: boolean;
    rows: GiftCardsTableRow[];
    filters: (<T>(items: T[]) => T[])[];
}
export const GiftCardsTable: FC<GiftCardsTableProps> = ({ isReady, rows = [], filters = [] }) => {
    return (
        <Table
            className="gift-cards-table"
            isLoading={!isReady}
            variant="raised"
            rows={rows}
            headCells={giftCardsTableHeadCells}
            filters={filters}
            defaultOptions={{
                orderBy: 'createdAt',
                order: 'desc',
            }}
        >
            <Table.RenderHead />
            <Table.RenderBody />
        </Table>
    );
};
