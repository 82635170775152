import { ComponentProps, ReactElement, Ref, useEffect, useMemo } from 'react';
import { Validate, useFormContext } from 'react-hook-form';

import {
    InputAdornment as MaterialUIInputAdornment,
    TextField as MaterialUITextField,
    TextFieldProps,
} from '@mui/material';
import { clsx } from 'clsx';

import { FocusEventOnChange, IFormField, InputEventOnChange } from '@app/types/UITypes';

import { useMoneyField } from './useMoneyField';

import './TextField.scss';

export interface ITextFieldProps extends Omit<IFormField, 'onChange' | 'label'> {
    inputRef?: any;
    className?: string;
    color?: 'neutral' | 'blue' | 'green' | 'red';
    variant?: 'outlined' | 'raised' | 'smooth' | 'filled';
    label?: string | ReactElement;
    name: string;
    placeholder?: string;
    startIcon?: any;
    endIcon?: any;
    type?: 'string' | 'search' | 'password' | 'number' | 'phone';
    multiline?: boolean;
    required?: boolean;
    validate?: Validate;
    rows?: number;
    onChange?: InputEventOnChange;
    onBlur?: FocusEventOnChange;
    min?: number;
    max?: number;
    maxLength?: number;
    step?: number;
    isValid?: boolean;
    isInvalid?: boolean;
    disabled?: boolean;
    register?: any;
    error?: boolean;
    helperText?: string;
    autoComplete?: 'new-password' | 'on' | 'off';
    disableFieldRegister?: boolean;
    isMoneyField?: boolean;
    moneyFieldIsActive?: boolean;
    moneyFieldErrorMessage?: string;
    autoFocus?: boolean;
    'data-testid'?: string;
    onValidationError?: (value?: string) => void;
    sx?: ComponentProps<typeof MaterialUITextField>[`sx`];
}

const TextFieldStartIcon = ({ icon }: { icon: string }): any => {
    return icon ? (
        <MaterialUIInputAdornment position="start">{icon}</MaterialUIInputAdornment>
    ) : null;
};

const TextFieldEndIcon = ({ icon }: { icon: string }): any => {
    return icon ? <MaterialUIInputAdornment position="end">{icon}</MaterialUIInputAdornment> : null;
};

const TextField = ({
    inputRef,
    className,
    color = 'neutral',
    variant = 'outlined',
    startIcon,
    endIcon,
    label,
    name,
    placeholder,
    value,
    defaultValue,
    type = 'string',
    required,
    multiline,
    rows,
    disabled,
    onChange = () => {},
    onBlur = () => {},
    onKeyDown = () => {},
    min,
    max,
    maxLength,
    step,
    error,
    validate,
    helperText,
    autoComplete = 'new-password',
    disableFieldRegister = false,
    isMoneyField = false,
    moneyFieldIsActive = false,
    moneyFieldErrorMessage,
    autoFocus = false,
    'data-testid': dataTestId,
    onValidationError,
    sx,
}: ITextFieldProps) => {
    const {
        errors,
        register,
        formState: { isSubmitSuccessful = true, submitCount = 0 },
        getValues,
    } = useFormContext() || {
        errors: {},
        register: null,
        formState: {},
        getValues: () => {},
    };

    const {
        className: moneyFieldClassName,
        error: moneyFieldError,
        ...moneyFieldProps
    } = useMoneyField({
        isMoneyField,
        moneyFieldIsActive,
        name,
        value: (value || defaultValue) as string,
        onChange,
        moneyFieldErrorMessage,
        error,
    });

    const props: TextFieldProps = {
        type,
        onChange,
        onKeyDown,
        onBlur,
        label,
        name,
        placeholder,
        value,
        defaultValue,
        required,
        multiline,
        rows,
        disabled,
        helperText,
        InputLabelProps: { shrink: true },
        inputRef,
        inputProps: {
            autoComplete,
            onWheel: (event) => event.currentTarget.blur(),
            'data-testid': dataTestId ?? `textField.${name}`,
            min,
            max,
            maxLength,
            step,
        },
        ...moneyFieldProps,
        autoFocus,
        sx,
    };

    // TODO: TextField.multiline and Modal.back() could cause crashing in Safari
    // when the .back() stage contains a TextField with multiline === true

    if (register != null) {
        if (inputRef == null) {
            props.inputRef = register({
                required,
                min,
                max,

                validate,
            });
        } else {
            if (!disableFieldRegister) {
                if (typeof inputRef === 'function') {
                    props.inputRef = (instance) =>
                        register(inputRef(instance), {
                            required,
                            min,
                            max,
                            validate,
                        });
                } else {
                    // TODO: Does not work. Fix TS error overridden by `as` and debug
                    props.inputRef = register(inputRef, {
                        required,
                        min,
                        max,
                        validate,
                    }) as unknown as Ref<any>;
                }
            }
        }
    }

    if (startIcon) {
        props.InputProps = {
            startAdornment: <TextFieldStartIcon icon={startIcon} />,
        };
    }

    if (endIcon) {
        props.InputProps = {
            endAdornment: <TextFieldEndIcon icon={endIcon} />,
        };
    }

    const fieldError = useMemo<boolean>(() => {
        if (moneyFieldError ?? error) {
            return true;
        }

        return !!name && !!errors[name];
    }, [error, errors, moneyFieldError]);

    useEffect(() => {
        if (!isSubmitSuccessful && fieldError && onValidationError) {
            onValidationError(getValues(name));
        }
    }, [submitCount, isSubmitSuccessful, fieldError]);

    return (
        <MaterialUITextField
            className={clsx(
                className,
                'form-control',
                'text-field',
                `text-field-color-${color}`,
                `text-field-variant-${variant}`,
                moneyFieldClassName,
                fieldError ? 'text-field-error' : '',
            )}
            error={fieldError}
            {...props}
        />
    );
};

export default TextField;
