import { memo } from 'react';

import ClickableArea from '@components/layout/ClickableArea';

import Intercom from '@helpers/Intercom';

import './IntercomContactMessage.scss';

export const IntercomContactMessage = memo(({ prompt }: { prompt: string }) => {
    const clickableContactUs = (
        <ClickableArea className="contact-us" onClick={() => Intercom.open()}>
            Contact Support
        </ClickableArea>
    );
    return (
        <span className="intercom-contact-message">
            <span className="intercom-message">{prompt}</span>
            {clickableContactUs}
        </span>
    );
});
