import { FC, useMemo } from 'react';

import { IPublicAccount } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import Modal from '@components/overlays/Modal';

import { useModal } from '@hooks/ModalHooks';

interface SelectGiftCardAccountViewProps {
    accounts: IPublicAccount[];
    accountId?: string;
    onAccountChange: Function;
}

export const SelectGiftCardAccountView: FC<SelectGiftCardAccountViewProps> = ({
    accounts = [],
    accountId,
    onAccountChange,
}) => {
    const { next } = useModal();

    const accountOptions = useMemo(() => {
        return accounts.map(({ _id, name }) => ({
            label: name,
            value: _id,
        }));
    }, [accounts]);

    return (
        <>
            <Modal.Content>
                <Form>
                    <Form.SearchSelect
                        name="gift-card_account"
                        label="Recipient Account"
                        options={accountOptions}
                        value={accountId ?? ''}
                        onChange={({ value }) => onAccountChange(value)}
                    />
                </Form>
            </Modal.Content>

            <Modal.Actions>
                <Button onClick={next} disabled={!accountId}>
                    Continue
                </Button>
            </Modal.Actions>
        </>
    );
};
