import { useMemo, useState } from 'react';

import { IPublicAccount, IPublicUser } from '@sparkplug/lib';

import { IModalContext } from '@contexts/ModalContext';

import Modal from '@components/overlays/Modal';

import { useApp } from '@hooks/AppHooks';
import { withModal } from '@hooks/ModalHooks';
import { usePayoutOptions } from '@hooks/PayoutHooks';

import { formatPhoneNumber } from '@helpers/ui';

import { IOption } from '@app/types/UITypes';

import { useGiftCardsMutations } from '../../../mutations';
import {
    ConfirmGiftCardDetailsView,
    SelectGiftCardAccountView,
    SelectGiftCardDetailsView,
} from './SendGiftCardModalSteps';

import './SendGiftCardModal.scss';

interface SendGiftCardModalProps {
    groups: IPublicAccount[];
    users: IPublicUser[];
    isVisible: boolean;
    onClose: (actionOnClose: boolean) => void;
}

type SendGiftCardModalContentProps = Omit<SendGiftCardModalProps, 'isVisible'> & {
    campaignOptions: IOption[];
    fundingSourceId: string;
} & IModalContext;

type ContactMethodType = 'email' | 'phoneNumber';

export const getContactMethodDetails = ({
    user,
    contactMethodType,
}: {
    user?: IPublicUser;
    contactMethodType?: ContactMethodType;
}) => {
    if (!contactMethodType || !user) {
        return undefined;
    }
    const contactMethod = user[contactMethodType];
    return contactMethod ? { [contactMethodType]: contactMethod } : undefined;
};

export const getContactMethodDisplay = ({
    phoneNumber,
    email,
}: {
    phoneNumber?: string;
    email?: string;
}) => {
    if (email) {
        return email;
    }
    if (phoneNumber) {
        return formatPhoneNumber(phoneNumber);
    }

    return undefined;
};

const SteppedSendGiftCardModalContent = withModal(
    ({
        groups = [],
        users = [],
        campaignOptions = [],
        fundingSourceId,
        onClose,
        currentStage,
    }: SendGiftCardModalContentProps) => {
        const [amount, setAmount] = useState<number>(0);
        const [contactMethodType, setContactMethodType] = useState<ContactMethodType>();
        const [note, setNote] = useState<string>();
        const [selectedAccountId, setSelectedAccountId] = useState<string>();
        const [selectedCampaignId, setSelectedCampaignId] = useState<string>();
        const [selectedUserId, setSelectedUserId] = useState<string>();

        const { user } = useApp();

        const selectedAccount = useMemo(() => {
            return groups.find(({ _id }) => _id === selectedAccountId);
        }, [groups, selectedAccountId]);

        const selectedUser = useMemo(() => {
            return users.find(({ _id }) => _id === selectedUserId);
        }, [users, selectedUserId]);

        const campaignName = useMemo(() => {
            return campaignOptions.find(({ value }) => value === selectedCampaignId)?.label;
        }, [campaignOptions, selectedCampaignId]);

        const contactMethodDetails = getContactMethodDetails({
            user: selectedUser,
            contactMethodType,
        });

        const { createAndSendGiftCard } = useGiftCardsMutations();
        const handleCreateAndSendGiftCard = async () => {
            const hasContactMethod =
                !!contactMethodDetails?.email || !!contactMethodDetails?.phoneNumber;
            const createdByUserId = user?._id;
            const userId = selectedUser?._id;
            const groupId = selectedAccountId;

            const canCreateGiftCard =
                amount > 0 &&
                hasContactMethod &&
                !!createdByUserId &&
                !!userId &&
                !!groupId &&
                !!selectedCampaignId &&
                !!fundingSourceId;

            if (canCreateGiftCard) {
                const payload = {
                    ...contactMethodDetails,
                    amount,
                    groupId,
                    createdByUserId,
                    userId,
                    campaignId: selectedCampaignId,
                    fundingSourceId,
                    note,
                };
                await createAndSendGiftCard(payload, {
                    onSuccess: () => {
                        onClose(true);
                    },
                    onError: () => {
                        onClose(false);
                    },
                });
            }
        };

        switch (currentStage) {
            case 0:
                return (
                    <SelectGiftCardAccountView
                        accounts={groups}
                        accountId={selectedAccountId}
                        onAccountChange={(newAccountId: string) =>
                            setSelectedAccountId(newAccountId)
                        }
                    />
                );

            case 1:
                return (
                    <SelectGiftCardDetailsView
                        account={selectedAccount}
                        amount={amount}
                        onAmountChange={(newAmount: number) => setAmount(newAmount)}
                        campaignOptions={campaignOptions}
                        campaignId={selectedCampaignId}
                        onCampaignChange={(newCampaignId: string) =>
                            setSelectedCampaignId(newCampaignId)
                        }
                        contactMethodType={contactMethodType}
                        onContactMethodChange={(newContactMethod: any) =>
                            setContactMethodType(newContactMethod)
                        }
                        note={note}
                        onNoteChange={(newNote: string) => setNote(newNote)}
                        user={selectedUser}
                        onUserChange={(newUserId: string) => setSelectedUserId(newUserId)}
                    />
                );

            case 2:
                return (
                    <ConfirmGiftCardDetailsView
                        account={selectedAccount!}
                        user={selectedUser!}
                        contactMethod={getContactMethodDisplay(contactMethodDetails ?? {}) as any}
                        amount={amount!}
                        campaignName={campaignName!}
                        onSubmit={handleCreateAndSendGiftCard}
                        note={note ?? '--'}
                    />
                );

            default:
                return <></>;
        }
    },
);

export const SendGiftCardModal = ({
    users,
    groups,
    isVisible,
    onClose,
}: SendGiftCardModalProps) => {
    const { campaignOptions, fundingSourceId } = usePayoutOptions();

    if (!isVisible) {
        return <></>;
    }

    return (
        <Modal className="send-gift-card_modal" isVisible onClose={onClose}>
            <Modal.Title onClose={onClose}>Send a Gift Card</Modal.Title>

            <Modal.Stepper stageNames={['Select Account', 'Gift Card Details', 'Review & Send']} />

            <SteppedSendGiftCardModalContent
                groups={groups}
                users={users}
                campaignOptions={campaignOptions}
                fundingSourceId={fundingSourceId}
                onClose={onClose}
            />
        </Modal>
    );
};
