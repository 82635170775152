import { FC, useEffect, useMemo, useState } from 'react';

import { uniq } from 'lodash';

import { getAccountUsers } from '@core/users';

import { useVendorBrandRetailersByBrandId } from '@features/account-links';

import ManagerChip from '@components/chips/ManagerChip';
import ListSelector from '@components/inputs/ListSelector';
import { TExtendedListItem } from '@components/inputs/ListSelector/ListSelector';
import Skeleton from '@components/layout/Skeleton';
import Toolbar from '@components/toolbar/Toolbar';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import { getStateNameByAbbreviation } from '@helpers/util';

import SparkCountCalloutMessage from './SparkCountCalloutMessage';
import { useMultiRetailerParticipantSelectorState } from './useMultiRetailerParticipantSelectorState';

import './MultiRetailerParticipantSelector.scss';

const MultiRetailerParticipantSelector: FC<{}> = () => {
    const { account } = useSparkplugAccount();

    const {
        spark: { sparkBrandId },
        detailedSparkType,
        multiRetailerParticipantSelection,
        setUniqueParticipantLocationIdsById,
        isCreatingMultiRetailerSpark,
    } = useSpark();
    const { userIsSuperAdmin } = useApp();
    const [marketsFilter, setMarketsFilter] = useState<
        TExtendedListItem<{ value: string; label: string }>[]
    >([]);
    const { handleChange } = useMultiRetailerParticipantSelectorState();
    const { vendorBrandRetailers: retailers, vendorBrandRetailersAreReady } =
        useVendorBrandRetailersByBrandId({
            brandId: sparkBrandId,
            vendorAccountId: account?._id,
            isEnabled: isCreatingMultiRetailerSpark && account?.type === 'brand',
        });
    const retailersList = useMemo<TExtendedListItem<{ key: string }>[]>(() => {
        if (!retailers) {
            return [];
        }

        const filteredRetailers =
            marketsFilter.length > 0
                ? retailers?.filter((retailer) => {
                      // return true if retailer.markets contains any strings in marketsFilter
                      return retailer?.markets?.some((market) => {
                          return marketsFilter.some((filter) => filter.value === market);
                      });
                  }) || []
                : retailers.filter((retailer) =>
                      detailedSparkType === 'goalManager'
                          ? retailer.activeLocations.some(
                                (location) => location.managerUserIds.length > 0,
                            )
                          : retailers,
                  );

        return filteredRetailers
            ?.filter(({ activeLocations }) => activeLocations.length)
            ?.map((vendorBrandRetailer) => {
                const { activeLocations, name } = vendorBrandRetailer;
                const children = activeLocations.map((location) => {
                    const { _id, name: locationName } = location;
                    return {
                        locationId: _id,
                        key: `${vendorBrandRetailer.retailerAccountId}.${_id}`,
                        value: _id,
                        label: locationName,
                        labelComponent: () => (
                            <>
                                {locationName}
                                <Skeleton height={14} width={100} style={{ marginLeft: 5 }} />
                            </>
                        ),
                        isSelectable: true,
                        retailerAccountId: vendorBrandRetailer.retailerAccountId,
                        type: 'location',
                    };
                });
                return {
                    key: vendorBrandRetailer.retailerAccountId,
                    value: vendorBrandRetailer.retailerAccountId,
                    label: name,
                    isSelectable: true,
                    retailerAccountId: vendorBrandRetailer.retailerAccountId,
                    children,
                    childrenAsync: async () => {
                        const users = await getAccountUsers({
                            groupId: vendorBrandRetailer.retailerAccountId,
                        });

                        // map original children of parent aka locations on a retailer
                        return children.map((child) => {
                            const isManagerGoalSpark = detailedSparkType === 'goalManager';
                            const grandChildren = users
                                .filter((user) => {
                                    if (isManagerGoalSpark) {
                                        return (
                                            user.managedLocationIds?.includes(child.locationId) &&
                                            !!user.hasPhoneNumber &&
                                            user.posEmployeeProfileIds.length
                                        );
                                    }
                                    return user.locationIds?.includes(child.locationId);
                                })
                                .map((user) => {
                                    return {
                                        key: `${child.key}.${user.posEmployeeProfileIds.join('|')}`,
                                        value: user.key,
                                        label: `${user.firstName} ${user.lastName}`,
                                        retailerAccountId: vendorBrandRetailer.retailerAccountId,
                                        locationId: child.locationId,
                                        labelComponent: () => (
                                            <>
                                                {user.firstName} {user.lastName}{' '}
                                                {user.managedLocationIds?.length ? (
                                                    <ManagerChip />
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        ),

                                        isSelectable: userIsSuperAdmin || isManagerGoalSpark,
                                        showArrow: !userIsSuperAdmin && !isManagerGoalSpark,
                                        type: 'employee',
                                        posEmployeeProfileIds: user.posEmployeeProfileIds,
                                    };
                                });
                            // new location child
                            return {
                                ...child,
                                label: `${child.label}`,
                                labelComponent: () => (
                                    <>
                                        {child.label}
                                        <span className="multi-retailer-selector-count">
                                            {grandChildren.length}{' '}
                                            {isManagerGoalSpark ? 'managers' : 'employees'}
                                        </span>
                                    </>
                                ),
                                children: grandChildren,
                                type: 'location',
                            };
                        });
                    },
                    type: 'retailer',
                };
            });
    }, [marketsFilter, detailedSparkType, retailers]);

    const allRetailerMarketOptions = useMemo(() => {
        return uniq(retailers.flatMap((retailer) => retailer.markets));
    }, [retailers]);

    const allAccountMarketOptions = useMemo(() => {
        return allRetailerMarketOptions.map((market) => {
            const marketLabel = market ? getStateNameByAbbreviation(market) : '';
            return {
                value: market ?? '',
                label: marketLabel,
            };
        });
    }, [allRetailerMarketOptions]);

    const marketsDropdown = (
        <Toolbar>
            <Toolbar.DropdownListSelector
                variant="flat"
                label="Markets"
                options={allAccountMarketOptions}
                selected={marketsFilter}
                onApply={(selected) => setMarketsFilter(selected)}
                showCount={marketsFilter.length > 0}
                clear={{
                    active: marketsFilter.length > 0,
                    onClear: () => {
                        setMarketsFilter([]);
                    },
                }}
            />
        </Toolbar>
    );
    useEffect(() => {
        // reset this validation check when the component mounts
        setUniqueParticipantLocationIdsById({});
    }, []);
    return (
        <div className="multi-retailer-selector">
            <SparkCountCalloutMessage />
            {vendorBrandRetailersAreReady ? (
                <ListSelector
                    useSelectionKeys
                    key={detailedSparkType}
                    list={retailersList}
                    selected={multiRetailerParticipantSelection}
                    onSelectionChanged={handleChange}
                    toolbar={marketsDropdown}
                />
            ) : (
                <Skeleton height="100%" />
            )}
        </div>
    );
};

export default MultiRetailerParticipantSelector;
