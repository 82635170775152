import { FC } from 'react';

import { isNumber } from 'lodash';

import { SparkMetric } from '@sparkplug/lib';

import Form from '@components/form/Form';

import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import { getMinThresholdDescription } from '@helpers/sparks';

const validMinTransactionMetrics: SparkMetric[] = [
    'order_average',
    'units_per_transaction',
    'guest_check_average',
];

export const MinTransactionField: FC = () => {
    const { spark, updateSpark } = useSpark();

    const showMinTransaction =
        spark.type === 'leaderboard' &&
        validMinTransactionMetrics.includes(spark.percentIncreaseData?.metric || spark.metric) &&
        spark.detailedSparkType !== 'leaderboardLocation';

    if (!showMinTransaction) {
        return <></>;
    }

    return (
        <div className="min-transaction-field-container">
            <Form.Label>Minimum Number of Transactions (Optional):</Form.Label>

            <Form.TextField
                name="spark-minimum-transaction"
                type="number"
                defaultValue={spark.minimumTransactionsToQualify}
                min={0}
                onChange={(e) => {
                    const minTransaction = Number(e.target.value);
                    if (isNumber(minTransaction)) {
                        updateSpark({ minimumTransactionsToQualify: minTransaction });
                    }
                }}
            />

            <Form.Description>{getMinThresholdDescription(spark)}</Form.Description>
        </div>
    );
};
