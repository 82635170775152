import {
    ComponentType,
    FC,
    ReactElement,
    RefObject,
    createContext,
    memo,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { has, isFunction } from 'lodash';

import IconButton from '@components/buttons/IconButton';
import Dropdown from '@components/dropdown/Dropdown';
import Form from '@components/form/Form';
import {
    AddCircleOutline,
    ExpandLess,
    ExpandMore,
    FilterList,
    RemoveCircleOutline,
    Search,
    SubdirectoryArrowRight,
} from '@components/icons';
import InfiniteScroll from '@components/layout/InfiniteScroll';
import List from '@components/layout/List';
import Tooltip from '@components/layout/Tooltip';

import { useDynamicHeight } from '@hooks/UIHooks';

import {
    appendClasses,
    findRecursiveByKey,
    flattenList,
    getFlattenedStructure,
    getLeaves,
    getUniqueLeavesFromList,
    uuid,
} from '@helpers/ui';

import './ListSelector.scss';

export interface IListItem {
    key?: string;
    value: string;
    label: string;
    labelComponent?: ComponentType<{}>;
    hideCheckbox?: boolean;
    // This can be true for a parent, but controlling the listSelector.selected prop will not initialize
    // a parent as selected. Look into using selection keys. See BrandMappingRow.tsx for an example.
    isSelectable?: boolean;
    showArrow?: boolean;
    disabled?: boolean;
    keepOptionEnabled?: boolean;
    children?: IListItem[];
    childrenAsync?: () => Promise<IListItem[]>;
}

export type TExtendedListItem<T> = IListItem & T;

type TOnSelectionChanged = (newSelectedListItems: IListItem[]) => void;

export interface IListSelectorFilter {
    active: boolean;
    fn: (list: IListItem[]) => IListItem[];
    [key: string]: any;
}

interface IListSelectorMapItem {
    isChecked?: boolean;
    item: IListItem;
}

interface IListSelectorMap extends Map<string, IListSelectorMapItem> {}

interface IListSelectorContext {
    containerRef?: RefObject<HTMLDivElement>;
    selectionMap: IListSelectorMap;
    headCells: string[];
    headCellClass?: string;
    useSelectionKeys: boolean;
    useAddRemoveUI: boolean;
    setMapItems: (isChecked: boolean, items: IListItem[]) => void;
    hideToolbar: boolean;
    showSearchIcon: boolean;
    filters: IListSelectorFilter[];
    onSetFilter: (i: number, checked: boolean) => void;
    filteredList: IListItem[];
    searchFilter: string;
    setSearchFilter: (newSearchValue: string) => void;
    onSelectionChanged: TOnSelectionChanged;
    list: IListItem[];
    hideSelectAll: boolean;
    isValid?: boolean;
    isInvalid?: boolean;
    afterLastListItem?: ReactElement;
    toolbar?: ReactElement;
}

const ListSelectorContext = createContext({} as IListSelectorContext);

const useListSelectorContext = (): IListSelectorContext => useContext(ListSelectorContext);

const getListItemSelectedKey = (
    useSelectionKeys: boolean,
    listItem: IListItem,
): string | undefined => {
    return useSelectionKeys ? listItem.key : listItem.value;
};

const listItemIsSelected = (
    useSelectionKeys: boolean,
    listItem: IListItem,
    map: IListSelectorMap,
): boolean => {
    const mapKey = getListItemSelectedKey(useSelectionKeys, listItem);
    if (mapKey) {
        const { isChecked = false } = map.get(mapKey) ?? {};
        return isChecked;
    }

    return false;
};

const mapMapToSelectedObjectArray = (map: IListSelectorMap): IListItem[] => {
    const mapItems = Array.from(map.values());

    return mapItems
        .filter(({ isChecked }) => {
            return isChecked;
        })
        .map(({ item }) => {
            return item;
        });
};

const ListSelectorToolbar = () => {
    const {
        useSelectionKeys,
        useAddRemoveUI,
        setMapItems,
        showSearchIcon,
        selectionMap,
        filters,
        onSetFilter,
        filteredList,
        searchFilter,
        setSearchFilter,
        onSelectionChanged,
        hideSelectAll,
        toolbar,
    } = useListSelectorContext();

    const searchFieldName = useMemo(() => uuid(), []);
    const [allChecked, setAllChecked] = useState(false);
    const classNameAppended = appendClasses([
        'tools-container',
        hideSelectAll ? 'hide-select-all' : 'show-select-all',
    ]);

    const selectAllTooltipTitle = useMemo(() => {
        const action = allChecked ? 'Deselect' : 'Select';
        const count = searchFilter.length > 0 ? 'filtered' : 'all';

        return `${action} ${count} items`;
    }, [searchFilter, allChecked]);

    // update `allChecked` when filters, filteredList, and map update
    useEffect(() => {
        if (filteredList != null && filteredList.length > 0) {
            const mapValues = [...selectionMap.values()];

            const leaves = useSelectionKeys
                ? flattenList(filteredList)
                : getUniqueLeavesFromList(filteredList);

            const enabledLeaves = leaves.filter(
                (v) => v?.disabled !== true || v?.keepOptionEnabled,
            );

            const everyChecked =
                enabledLeaves.length > 0 && mapValues.length >= enabledLeaves.length
                    ? enabledLeaves.every((listItem) => {
                          return listItemIsSelected(useSelectionKeys, listItem, selectionMap);
                      })
                    : false;
            setAllChecked(everyChecked);
        }
    }, [filters, filteredList, selectionMap]);

    const onCheckOrUncheckAllItems = useCallback(() => {
        if (filteredList != null && filteredList.length > 0) {
            const leaves = useSelectionKeys
                ? flattenList(filteredList)
                : getUniqueLeavesFromList(filteredList);

            const enabledLeaves = leaves.filter(
                (v) => v?.disabled !== true || v?.keepOptionEnabled,
            );

            const isChecked = !allChecked;

            setAllChecked(isChecked);
            setMapItems(isChecked, enabledLeaves);
        }
    }, [allChecked, selectionMap, filteredList, onSelectionChanged, setMapItems]);

    return (
        <div className={classNameAppended}>
            {!useAddRemoveUI && (
                <div className="select-check">
                    <Form.Checkbox value={allChecked} onChange={onCheckOrUncheckAllItems} />
                </div>
            )}
            <div className={`filter-container ${toolbar ? 'filter-container--has-toolbar' : ''}`}>
                <Form.TextField
                    className="list-selector-searchbar"
                    startIcon={showSearchIcon ? <Search /> : null}
                    defaultValue={searchFilter}
                    name={searchFieldName}
                    onChange={(event) => {
                        setSearchFilter(event.target.value);
                        setAllChecked(false);
                    }}
                />
                {toolbar}
                {!!filters?.length && (
                    <Dropdown>
                        <Dropdown.IconButton className="btn-filter">
                            <FilterList />
                        </Dropdown.IconButton>
                        <Dropdown.Menu>
                            {filters.map((filter, i) => (
                                <Dropdown.MenuItem key={`filter-${i}-${filter.active}`}>
                                    <Form.Checkbox
                                        label={filter.label}
                                        value={filter.active}
                                        onChange={(event) => onSetFilter(i, event?.target?.checked)}
                                    />
                                </Dropdown.MenuItem>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </div>
            {useAddRemoveUI && (
                <div
                    className={`select-all ${
                        allChecked ? 'select-all-all-selected' : 'select-all-not-all-selected'
                    }`}
                >
                    <IconButton
                        className={allChecked ? 'icon-btn-blue' : 'neutral'}
                        variant="flat"
                        color="neutral"
                        onClick={onCheckOrUncheckAllItems}
                    >
                        <Tooltip title={selectAllTooltipTitle}>
                            {allChecked ? <RemoveCircleOutline /> : <AddCircleOutline />}
                        </Tooltip>
                    </IconButton>
                </div>
            )}
        </div>
    );
};

const listItemIsChecked = (
    leaves: IListItem[] = [],
    map: IListSelectorMap,
    item: IListItem,
    useSelectionKeys: boolean,
): boolean => {
    if (useSelectionKeys) {
        const hasSelectedDescendants = leaves.some((leaf) =>
            listItemIsSelected(useSelectionKeys, leaf, map),
        );
        return hasSelectedDescendants || listItemIsSelected(useSelectionKeys, item, map);
    }

    if (Array.isArray(leaves) && leaves.length > 0) {
        const allLeavesChecked = leaves.every((leaf) => {
            return listItemIsSelected(false, leaf, map);
        });

        if (allLeavesChecked) {
            return true;
        }
    }

    return listItemIsSelected(useSelectionKeys, item, map);
};

const getSelectedChildrenOnExpand = ({
    children = [],
    selectionMap,
    isChecked,
    useSelectionKeys,
}: {
    children: IListItem[];
    selectionMap: IListSelectorMap;
    initialSelected?: string[];
    isChecked: boolean;
    useSelectionKeys: boolean;
}): IListItem[] => {
    if (isChecked) {
        const selectedItems = children.filter((item) => {
            const selectionKey = getListItemSelectedKey(useSelectionKeys, item) ?? '';
            return selectionMap.get(selectionKey)?.isChecked;
        });
        return selectedItems.length ? selectedItems : children;
    }

    return [];
};
interface IListItemProps {
    i: number | string;
    item: IListItem;
}

const ListItem = ({ i, item }: IListItemProps) => {
    const { selectionMap, useSelectionKeys, useAddRemoveUI, setMapItems } =
        useListSelectorContext();

    const [showChildren, setShowChildren] = useState(false);
    const [asyncChildren, setAsyncChildren] = useState<IListItem[]>([]);

    const children = useMemo(() => {
        if (item.childrenAsync && asyncChildren.length) {
            return asyncChildren;
        }

        if (item.children?.length) {
            return item.children;
        }

        return [];
    }, [item, asyncChildren]);

    const leaves = useMemo(() => {
        if (useSelectionKeys && asyncChildren.length) {
            return getFlattenedStructure({ ...item, children });
        } else {
            return getLeaves(item);
        }
    }, [item, children]);

    const isChecked = useMemo(
        () => listItemIsChecked(leaves, selectionMap, item, useSelectionKeys),
        [leaves, selectionMap, item],
    );

    const disableItem = useMemo(() => {
        if (has(item, 'keepOptionEnabled')) {
            return false;
        }
        return item?.disabled ?? false;
    }, [item]);

    const classNamesAppended = useMemo(
        () =>
            appendClasses([
                'list-item',
                isChecked ? 'is-checked' : 'is-not-checked',
                disableItem ? 'is-disabled' : 'is-not-disabled',
            ]),
        [isChecked, disableItem],
    );

    const onCheckOrUncheckItem = useCallback(
        (selection: { isChecked: boolean; item: IListItem }) => {
            if (disableItem || item.showArrow) {
                return;
            }

            const listItems: IListItem[] = [];
            const checked = selection.isChecked;
            if (Array.isArray(leaves) && leaves.length) {
                if (item.isSelectable) {
                    // This is necessary because a parent is only "selected" if all its children are selected.
                    // Therefore, 'isSelectable' is used to determine if the parent should be selected.
                    listItems.push(item);
                }
                // Note: When asyncChildren are used, "leaves" does not seem to update properly.
                leaves.forEach((leaf) => {
                    if (leaf.showArrow && !leaf.isSelectable) {
                        return;
                    }
                    listItems.push(leaf);
                });
            } else {
                listItems.push(item);
            }
            setMapItems(checked, listItems);
        },
        [selectionMap, leaves, setMapItems],
    );

    return (
        <>
            <List.Item
                className={classNamesAppended}
                data-testid={`listSelectorItem.${item.value}`}
                onClick={() =>
                    onCheckOrUncheckItem({
                        isChecked: !isChecked,
                        item,
                    })
                }
            >
                {!item.hideCheckbox && (
                    <>
                        {item.showArrow && (
                            <List.ItemIcon>
                                <SubdirectoryArrowRight />
                            </List.ItemIcon>
                        )}
                        {!useAddRemoveUI && !item.showArrow && (
                            <List.ItemIcon>
                                <Form.Checkbox value={isChecked} disabled={disableItem || false} />
                            </List.ItemIcon>
                        )}
                    </>
                )}
                {item.labelComponent ? (
                    <item.labelComponent />
                ) : (
                    <List.ItemText>{item.label}</List.ItemText>
                )}
                {useAddRemoveUI && (
                    <IconButton variant="flat" color="neutral">
                        {isChecked ? <RemoveCircleOutline /> : <AddCircleOutline />}
                    </IconButton>
                )}
                {(item.children?.length || item.childrenAsync) && (
                    <List.ItemSecondaryAction>
                        <IconButton
                            className="tree-toggle"
                            data-testid={`listSelectorItem.${item.value}.toggle`}
                            onClick={() => {
                                setShowChildren((prevValue) => !prevValue);
                                if (!asyncChildren?.length && item.childrenAsync) {
                                    item.childrenAsync().then((newChildren) => {
                                        setAsyncChildren(newChildren);

                                        const hydratedAsyncChildren = newChildren.flatMap(
                                            (parentOfAsyncItems) =>
                                                parentOfAsyncItems.children ?? [],
                                        );
                                        if (hydratedAsyncChildren) {
                                            setMapItems(
                                                true,
                                                getSelectedChildrenOnExpand({
                                                    children: hydratedAsyncChildren,
                                                    selectionMap,
                                                    isChecked,
                                                    useSelectionKeys,
                                                }),
                                            );
                                        }
                                    });
                                }
                            }}
                        >
                            {showChildren ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </List.ItemSecondaryAction>
                )}
            </List.Item>
            {!!children.length && showChildren && (
                <List className="list-item-children">
                    {children.map((childItem, j) => (
                        <ListItem i={`${i}-${j}`} key={childItem.value} item={childItem} />
                    ))}
                </List>
            )}
        </>
    );
};

const ListSelectorListHead = memo(
    ({ headCells = [], headCellClass = '' }: { headCells: string[]; headCellClass?: string }) => {
        const headClassName = `list-head-row ${headCellClass}`;
        return (
            <div className="list-selector__list-head">
                <div className={headClassName}>
                    {headCells.map((cellText, i) => (
                        <div key={i} className="list-head-item">
                            {cellText}
                        </div>
                    ))}
                </div>
            </div>
        );
    },
);

const ListSelectorList: FC<{}> = () => {
    const { list, isValid, isInvalid, containerRef, afterLastListItem } = useListSelectorContext();
    const listRef = useRef<HTMLUListElement>(null);
    const height = useDynamicHeight(containerRef, listRef, {
        defaultHeight: 400,
    });

    const classNamesAppended = useMemo(() => {
        return appendClasses([
            'list-selector-control',
            isInvalid != null && isInvalid ? 'is-invalid' : null,
            isValid != null && isValid ? 'is-valid' : null,
        ]);
    }, []);

    const memoizedList = useMemo(() => {
        return (
            <InfiniteScroll height={height}>
                {list.map((item, i) => (
                    <ListItem i={i} key={`${item.value}`} item={item} />
                ))}
                {!!afterLastListItem && (
                    <div className="list-item after-last-list-item">{afterLastListItem}</div>
                )}
            </InfiniteScroll>
        );
    }, [list]);

    return (
        <List ref={listRef} className={classNamesAppended}>
            {memoizedList}
        </List>
    );
};

const ListSelectorInfiniteScroll = () => {
    const { headCells, headCellClass, hideToolbar, useAddRemoveUI, afterLastListItem } =
        useListSelectorContext();

    const classNameAppended = appendClasses([
        'list-selector-component',
        'has-toolbar',
        'has-infinite-scroll',
        useAddRemoveUI ? 'add-remove-selector-styling' : 'default-selector-styling',
    ]);

    return (
        <div className={classNameAppended}>
            {!hideToolbar && <ListSelectorToolbar />}

            {headCells?.length !== 0 && (
                <ListSelectorListHead headCells={headCells} headCellClass={headCellClass} />
            )}
            <ListSelectorList />
        </div>
    );
};

type TSelected<T extends {}> = (string | TExtendedListItem<T>)[];

interface SearchableListItem extends IListItem {
    searchText?: string;
    labelText?: string;
}
const searchRecursive = (
    filterLowerCase: string,
    list: SearchableListItem[],
    newList: SearchableListItem[],
): void => {
    list.forEach(({ children, ...item }) => {
        const searchText: string | undefined = item?.searchText ?? item?.labelText ?? item?.label;

        if (
            isFunction(searchText?.toLowerCase) &&
            searchText?.toLowerCase()?.indexOf(filterLowerCase) !== -1
        ) {
            newList.push({
                ...item,
                children,
            });
        } else {
            const newItem: IListItem = { ...item, children: [] };

            if (children?.length) {
                searchRecursive(filterLowerCase, children, newItem.children ?? []);
            }

            if (newItem?.children?.length) {
                newList.push(newItem);
            }
        }
    });
};

// Add dynamic keys to list items if key does not exist
const mapListItems = (list: IListItem[], prefix: string = ''): IListItem[] => {
    for (let i = 0; i < list.length; i += 1) {
        const key = `${prefix}${list[i].value}`;
        if (list[i].key == null) {
            // eslint-disable-next-line no-param-reassign
            list[i].key = key;
        }
        if (list[i].children != null && (list[i].children || []).length > 0) {
            mapListItems(list[i].children || [], `${list[i].key}.`);
        }
    }

    return list;
};

const getAllListItemsFromSelectedArray = <T extends {}>(
    useSelectionKeys: boolean,
    list: IListItem[],
    selected: TSelected<T>,
): IListItem[] => {
    const listItems: IListItem[] = [];

    if (useSelectionKeys) {
        selected.forEach((key: any) => {
            const listItem = findRecursiveByKey(list, key);
            if (listItem || key) {
                listItems.push(listItem ?? { key });
            }
        });
    } else {
        const leaves = getUniqueLeavesFromList(list);

        selected.forEach((item: any) => {
            const listItem = leaves.find((obj) => obj.value === item.value);

            if (listItem != null) {
                listItems.push(listItem);
            }
        });
    }

    return listItems;
};

const generateMapFromSelectedListItems = (
    useSelectionKeys: boolean,
    selectedListItems: IListItem[],
): IListSelectorMap => {
    const newMap = new Map();

    selectedListItems.forEach((listItem) => {
        const mapKey = getListItemSelectedKey(useSelectionKeys, listItem);

        if (mapKey) {
            newMap.set(mapKey, {
                isChecked: true,
                item: listItem,
            });
        }
    });

    return newMap;
};

interface SharedListSelectorProps<T extends {}> {
    headCells?: string[];
    headCellClass?: string;
    list: TExtendedListItem<T>[];
    initialFilters?: IListSelectorFilter[];
    useSelectionKeys?: boolean;
    useAddRemoveUI?: boolean;
    selected: TSelected<T>;
    isValid?: boolean;
    isInvalid?: boolean;
    onSelectionChanged: (updatedSelectedItems: TExtendedListItem<T>[]) => void;
    hideToolbar?: boolean;
    hideSelectAll?: boolean;
    showSearchIcon?: boolean;
    containerRef?: RefObject<HTMLDivElement>;
    afterLastListItem?: ReactElement;
    toolbar?: any;
}

interface SelectionKeyListSelectorProps<T extends {}> extends SharedListSelectorProps<T> {
    useSelectionKeys: true;
    selected: string[];
}

interface DefaultListSelectorProps<T extends {}> extends SharedListSelectorProps<T> {
    useSelectionKeys?: boolean;
    selected: TExtendedListItem<T>[];
}

// interface IListSelectorProps<T extends {}> {}
type IListSelectorProps<T extends {}> =
    | SelectionKeyListSelectorProps<T>
    | DefaultListSelectorProps<T>;

const ListSelector = <T extends {}>({
    headCells = [],
    headCellClass,
    list = [],
    initialFilters = [],
    selected = [],
    useSelectionKeys = false,
    useAddRemoveUI = false,
    isValid,
    isInvalid,
    onSelectionChanged = () => {},
    hideToolbar = false,
    hideSelectAll = false,
    showSearchIcon = false,
    containerRef,
    afterLastListItem,
    toolbar,
}: IListSelectorProps<T>) => {
    const isInternalUpdate = useRef(false);
    const [selectionMap, setSelectionMap] = useState<IListSelectorMap>(new Map());
    const [searchFilter, setSearchFilter] = useState('');
    const [filteredList, setFilteredList] = useState<IListItem[]>(list);

    const [filters, setFilters] = useState<IListSelectorFilter[]>(initialFilters);

    // Update internal map when `selected` changes
    useEffect(() => {
        // possible fix: need some kind of early return because this collides with onChange functions
        if (!isInternalUpdate.current) {
            const selectedListItems = getAllListItemsFromSelectedArray(
                useSelectionKeys,
                list,
                selected,
            );
            // BUG: This conflicts with setMapItems which is called in onChange functions
            setSelectionMap(generateMapFromSelectedListItems(useSelectionKeys, selectedListItems));
        }

        if (isInternalUpdate.current) {
            isInternalUpdate.current = false;
        }
    }, [selected]);

    // Apply filters
    useEffect(() => {
        const filterLowerCase = searchFilter.trim().toLowerCase();

        let newList = [...list];

        // Search filter
        if (filterLowerCase) {
            newList = [];

            searchRecursive(filterLowerCase, list, newList);
        }

        // Prop filters
        filters.forEach((filter) => {
            if (filter.active) {
                // TODO: Remove type casting by updating types internally
                newList = filter.fn(newList) as TExtendedListItem<T>[];
            }
        });

        setFilteredList(newList);
    }, [filters, searchFilter, list]);

    const setMapItems = (isChecked: boolean, items: IListItem[]) => {
        isInternalUpdate.current = true;
        setSelectionMap((prevMap) => {
            const updatedMap = new Map(prevMap);

            items.forEach((item) => {
                const mapKey = getListItemSelectedKey(useSelectionKeys, item);

                if (mapKey != null) {
                    const mapItem = updatedMap.get(mapKey);

                    if (mapItem == null) {
                        updatedMap.set(mapKey, {
                            isChecked,
                            item,
                        });
                    } else {
                        mapItem.isChecked = isChecked;
                    }
                } else {
                    // eslint-disable-next-line no-console
                    console.error(`ListSelector could not find key for list item`, item);
                }
            });

            requestAnimationFrame(() => {
                onSelectionChanged(
                    // TODO: Remove type casting by updating types internally
                    mapMapToSelectedObjectArray(updatedMap) as TExtendedListItem<T>[],
                );
            });

            return updatedMap;
        });
    };

    const onSetFilter = (i: number, checked: boolean) => {
        setFilters((prevFilters) => {
            const newFilters = [...prevFilters];
            newFilters[i].active = checked;
            return newFilters;
        });
    };

    return (
        <ListSelectorContext.Provider
            value={{
                selectionMap,
                headCells,
                headCellClass,
                useSelectionKeys,
                useAddRemoveUI,
                setMapItems,
                hideToolbar,
                showSearchIcon,
                filters,
                onSetFilter,
                filteredList,
                searchFilter,
                setSearchFilter,
                // TODO: Remove type casting by updating types internally
                onSelectionChanged: onSelectionChanged as TOnSelectionChanged,
                list: filteredList,
                hideSelectAll,
                isValid,
                isInvalid,
                containerRef,
                afterLastListItem,
                toolbar,
            }}
        >
            <ListSelectorInfiniteScroll />
        </ListSelectorContext.Provider>
    );
};

export default ListSelector;
