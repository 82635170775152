import { FC, createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { isEmpty } from 'lodash';

import { DetailedSparkType, Spark } from '@sparkplug/lib';

import { useAccountUsersQuery } from '@core/users';

import toast from '@components/toast';

import { useApp } from '@hooks/AppHooks';
import { useModal } from '@hooks/ModalHooks';
import {
    useAccountPosDataQuery,
    useAppAccount,
    useSparkplugAccount,
} from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import { IPosLocation } from '@app/types/PosTypes';
import { IOption } from '@app/types/UITypes';
import { IAccountUser } from '@app/types/UsersTypes';

import { ParticipantsContextType, SparkParticipantsSelectorProps } from '../types';
import {
    getActivePosEmployeeProfiles,
    initializeSelectedLocations,
    initializeSelectedParticipants,
} from '../utils/SparkParticipantsSelector.util';
import {
    goalDetailedSparkTypeOptions,
    leaderboardParticipantTypeOptions,
} from '../utils/detailedSparkTypeOptions';

export const ParticipantsContext = createContext([{}, {}] as ParticipantsContextType);

const ParticipantProvider: FC<SparkParticipantsSelectorProps> = ({
    isBrandApprovalResponse = false,
    onSelectionChanged,
    children,
}) => {
    const { updateValidationFn } = useModal();

    const { account } = useSparkplugAccount();
    const { user } = useApp();
    const { account: appAccount } = useAppAccount();

    const {
        spark: {
            _id: sparkId,
            groupId,
            requestState: sparkRequestState,
            locationIds,
            posEmployeeProfileIds,
            type,
            metric,
            teamType,
        },
        detailedSparkType,
        sparkSubGroups,
        updateSparkSubGroupsByLocationsAndParticipants,
        updateSpark,
        multiRetailerParticipantGroups,
        isCreatingMultiRetailerSpark,
        setMultiRetailerParticipantSelection,
        updateMultiRetailerParticipantGroups,
        uniqueParticipantLocationIdsById,
    } = useSpark();
    const { accountPosDataIsReady, accountPosLocations } = useAccountPosDataQuery(groupId, {
        includedDatasets: ['locations'],
    });
    const { accountUsers: retailerAccountUsers } = useAccountUsersQuery(groupId);
    const activeParticipantOptions = useMemo<IAccountUser[]>(() => {
        const allActiveParticipantOptions = getActivePosEmployeeProfiles(retailerAccountUsers);

        return detailedSparkType === 'goalManager'
            ? allActiveParticipantOptions.filter(
                  ({ managedLocationIds = [], hasPhoneNumber }) =>
                      managedLocationIds.length && hasPhoneNumber,
              )
            : allActiveParticipantOptions;
    }, [detailedSparkType, retailerAccountUsers]);

    const [selectedLocations, setSelectedLocations] = useState<IPosLocation[]>(() =>
        initializeSelectedLocations({
            detailedSparkType,
            sparkSubGroups,
            locationIds,
        }),
    );

    const participantsSelectable = useMemo(() => {
        const isSuperAdminOrRetailer = user?.role === 'super-admin' || account?.type === 'retailer';

        if (detailedSparkType === 'goalManager' || teamType === 'custom') {
            return true;
        }

        if (detailedSparkType === 'goalTeam' || detailedSparkType === 'leaderboardLocation') {
            return false;
        }

        return isSuperAdminOrRetailer;
    }, [teamType, detailedSparkType, user?.role, account?.type]);

    const [selectedParticipants, setSelectedParticipants] = useState<IOption<IAccountUser>[]>(() =>
        initializeSelectedParticipants({
            isBrandApprovalResponse,
            participantsSelectable,
            sparkRequestState,
            currentUser: user!,
            detailedSparkType: detailedSparkType!,
            sparkSubGroups,
            activeParticipantOptions,
            sparkPosEmployeeProfileIds: posEmployeeProfileIds,
        }),
    );

    const checkValidation = useCallback(() => {
        // TODO add a toast for these validation errors?
        const validation = [
            () => {
                let result = true;
                if (
                    detailedSparkType === 'goalTeam' &&
                    teamType === undefined &&
                    account?.type === 'retailer'
                ) {
                    toast.error(`Select a team type before proceeding.`);
                    result = false;
                }
                if (isEmpty(selectedLocations) && !isCreatingMultiRetailerSpark) {
                    toast.error(`Select locations before proceeding.`);
                    result = false;
                }
                if (
                    participantsSelectable &&
                    isEmpty(selectedParticipants) &&
                    !isCreatingMultiRetailerSpark
                ) {
                    toast.error(
                        detailedSparkType === 'goalManager'
                            ? 'At least one manager must be selected.'
                            : 'Select participants before proceeding.',
                    );
                    result = false;
                }

                const hasMultiRetailerParticipantGroups = Boolean(
                    multiRetailerParticipantGroups.length > 0,
                );

                const hasCorrectData = multiRetailerParticipantGroups.every((group) => {
                    const expanded = uniqueParticipantLocationIdsById[group.retailerAccountId];

                    const hasSomeLocationIds = group.participantGroups.some(
                        (participantGroup) => participantGroup.locationIds.length,
                    );
                    const hasSomePosEmployeeProfileIds =
                        group.participantGroups.some(
                            (participantGroup) => participantGroup.posEmployeeProfileIds.length,
                        ) || false;

                    if (expanded) {
                        return hasSomeLocationIds && hasSomePosEmployeeProfileIds;
                    }

                    return hasSomeLocationIds;
                });

                if (
                    isCreatingMultiRetailerSpark &&
                    (!hasMultiRetailerParticipantGroups || !hasCorrectData)
                ) {
                    toast.error(
                        detailedSparkType === 'goalManager'
                            ? 'At least one manager must be selected in each retailer.'
                            : 'Select Retailer locations before proceeding.',
                    );
                    result = false;
                }
                if (!participantsSelectable && !isEmpty(selectedParticipants)) {
                    // eslint-disable-next-line no-console
                    console.error(
                        '[SparkParticipantsSelector]: Should not be able to select participants',
                    );
                    result = false;
                }

                return result;
            },
        ];

        const allValid = validation.every((fn) => fn());

        if (allValid) {
            updateSparkSubGroupsByLocationsAndParticipants(
                selectedLocations,
                selectedParticipants,
                activeParticipantOptions,
            );
        }

        return allValid;
    }, [
        updateSparkSubGroupsByLocationsAndParticipants,
        selectedLocations,
        selectedParticipants,
        participantsSelectable,
        teamType,
        detailedSparkType,
    ]);
    const onChangeType = (updatedDetailedSparkType: DetailedSparkType) => {
        const updatedSparkProperties: Partial<Spark> = {
            detailedSparkType: updatedDetailedSparkType,
        };
        if (
            (updatedDetailedSparkType === 'goalManager' || detailedSparkType === 'goalManager') &&
            isCreatingMultiRetailerSpark
        ) {
            setMultiRetailerParticipantSelection([]);
            updateMultiRetailerParticipantGroups([]);
        }

        if (updatedDetailedSparkType === 'leaderboardLocation' && metric === 'percent_increase') {
            updatedSparkProperties.metric = undefined;
        }
        switch (updatedDetailedSparkType) {
            case 'goal':
                updatedSparkProperties.goalType = 'individual';
                break;
            case 'goalTeam':
                updatedSparkProperties.goalType = 'team';
                break;

            default:
        }

        updateSpark(updatedSparkProperties);
    };

    const participantTypeOptions = useMemo(() => {
        const accountHasOneLocation = accountPosLocations.length === 1;
        if (isBrandApprovalResponse || (accountHasOneLocation && type === 'leaderboard')) {
            return [];
        }
        switch (type) {
            case 'leaderboard':
                return leaderboardParticipantTypeOptions;
            case 'goal':
                return goalDetailedSparkTypeOptions(Boolean(account?.managerSparksEnabled));
            case 'commission':
            default:
                return [];
        }
    }, [type, accountPosDataIsReady, accountPosLocations]);

    useEffect(() => {
        updateValidationFn(checkValidation);
    }, [checkValidation, updateValidationFn]);
    useEffect(() => {
        if (onSelectionChanged) {
            onSelectionChanged(selectedLocations, selectedParticipants);
        }
    }, [selectedLocations, selectedParticipants]);

    return (
        <ParticipantsContext.Provider
            value={[
                {
                    activeParticipantOptions,
                    selectedLocations,
                    participantsSelectable,
                    isBrandApprovalResponse,
                    participantTypeOptions,
                    selectedParticipants,
                    accountPosDataIsReady,
                    accountPosLocations,
                },
                {
                    setSelectedLocations,
                    setSelectedParticipants,
                    onChangeType,
                },
            ]}
        >
            {children}
        </ParticipantsContext.Provider>
    );
};

export default ParticipantProvider;
