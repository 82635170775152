import { FC, useMemo } from 'react';

import { AccountLinkStatus, PublicBrandLinkV2 } from '@sparkplug/lib';

import { useUpdateAccountLink } from '@features/account-links';

import Switch from '@components/inputs/Switch';
import Paper from '@components/layout/Paper';
import toast from '@components/toast';

import './LinkEnabled.scss';

interface LinkEnabledProps {
    photoUrl: string;
    accountId: string;
    vendorAccountId: string;
    accountName: string;
    accountLinkState: AccountLinkStatus;
    brandLinks: PublicBrandLinkV2[];
}

const LinkEnabled: FC<LinkEnabledProps> = ({
    photoUrl,
    accountId,
    vendorAccountId,
    accountName,
    accountLinkState,
    brandLinks,
}) => {
    const numBrandLinks = brandLinks.length;

    const mappedBrandLinksCount = useMemo(
        () => brandLinks.flatMap((link) => link.posBrands ?? []).length,
        [brandLinks],
    );

    const { isUpdatingAccountLink, updateAccountLink } = useUpdateAccountLink(accountId);

    const handleToggleLinkStatus = async ({ target }: { target: { checked: boolean } }) => {
        await updateAccountLink(
            {
                vendorAccountId,
                body: { state: target.checked ? 'enabled' : 'disabled' },
            },
            {
                onSuccess: () => {
                    toast.success(`Link ${target.checked ? 'enabled' : 'disabled'}`);
                },
                onError: () => {
                    toast.error(
                        'Something went wrong - please try again and contact support if this issue persists.',
                    );
                },
            },
        );
    };

    return (
        <Paper className="link-enabled">
            <div className="vendor-details">
                <img src={photoUrl} alt={accountName} />
                <div>
                    <div className="name">{accountName}</div>
                    <div className="brand-num">{numBrandLinks} Brands</div>
                </div>
            </div>
            {mappedBrandLinksCount > 0 && (
                <div>
                    <Switch
                        label="Enabled"
                        value={accountLinkState === 'enabled'}
                        onChange={handleToggleLinkStatus}
                        color={accountLinkState === 'enabled' ? 'green' : 'neutral'}
                        disabled={
                            isUpdatingAccountLink ||
                            (accountLinkState !== 'enabled' && accountLinkState !== 'disabled')
                        }
                    />
                </div>
            )}
        </Paper>
    );
};

export default LinkEnabled;
