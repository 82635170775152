import { useContext } from 'react';

import { ParticipantsContext } from './ParticipantsContext';

export const useParticipantsContext = () => {
    const context = useContext(ParticipantsContext);
    if (!context) {
        throw new Error('useParticipantsContext must be used within a ParticipantsContextProvider');
    }
    return context;
};
