import axios from 'axios';

import { ListAccountSparkBrandsResponseBody } from '@sparkplug/lib';

import { useAdvancedQuery, useQueries } from '@hooks/QueryHooks';

const API = {
    listSparkBrands: async (groupId: string, retailerAccountId?: string) => {
        const retailerQueryParam = retailerAccountId
            ? `?retailerAccountId=${retailerAccountId}`
            : '';
        return (
            await axios.get<ListAccountSparkBrandsResponseBody>(
                `/api/v1/accounts/${groupId}/spark-brands${retailerQueryParam}`,
            )
        ).data;
    },
};

export const getAccountSparkBrandsQueryKey = (accountId: string, retailerAccountId?: string) => [
    'sparkBrands',
    accountId,
    retailerAccountId,
];
export const useSparkBrandsQuery = (
    accountId: string,
    isEnabled: boolean = true,
    retailerAccountId?: string,
    refetchOnMount?: 'always',
) => {
    const { isFetched, data } = useAdvancedQuery(
        getAccountSparkBrandsQueryKey(accountId, retailerAccountId),
        () => API.listSparkBrands(accountId, retailerAccountId),
        {
            enabled: isEnabled && !!accountId,
            refetchOnMount,
        },
    );

    return { sparkBrandsAreReady: isFetched, sparkBrands: data ?? [] };
};

export const useSparkBrandsQueries = (accountIds: string[], isEnabled: boolean = true) => {
    const queries = useQueries(
        accountIds.map((accountId) => ({
            queryKey: getAccountSparkBrandsQueryKey(accountId),
            queryFn: () => API.listSparkBrands(accountId),
            enabled: isEnabled && !!accountId,
        })),
    );

    return queries;
};
