import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { isEqual, uniq } from 'lodash';

import { useSnapEditorSdk } from '@features/spark-snaps/hooks/useSnapEditorSdk';
import { useSnapViewQueue } from '@features/spark-snaps/hooks/useSnapViewQueue';
import { useCreateSnapMutation, useUpdateSnapMutation } from '@features/spark-snaps/mutations';

import Chip from '@components/chips/Chip';
import { AddCircleOutline } from '@components/icons';
import ClickableArea from '@components/layout/ClickableArea';
import Paper from '@components/layout/Paper';
import toast from '@components/toast';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import Intercom from '@helpers/Intercom';

import { cn } from '@app/componentLibrary/utils';
import { TSparkStatus } from '@app/types/SparksTypes';

import SnapsUpgradeConfirmModal from '../SnapsUpgradeConfirmModal';
import SparkSnapItem from '../SparkSnapItem';
import CreateEditSnapDrawer from './CreateEditSnapDrawer';
import {
    CreateSnapDrawerContent,
    EditSnapDrawerContent,
} from './CreateEditSnapDrawer/CreateEditSnapDrawer';

import './SparkSnapsPanel.scss';

const SNAP_DRAWER_CALLOUT =
    'Enter a Snap name that will be visible to Spark participants, then continue to create the Snap!';
interface AddSparkSnapButtonProps {
    onClick: () => void;
}
const AddSparkSnapButton: FC<AddSparkSnapButtonProps> = ({ onClick }) => {
    return (
        <div className="spark-snap-item spark-snap-item_add-button">
            <ClickableArea data-testid="add-snap-button" onClick={onClick}>
                <div className="spark-snap-item_add-icon">
                    <div>
                        <AddCircleOutline />
                    </div>
                </div>
                <span className="spark-snap-item_add-label">Add</span>
            </ClickableArea>
        </div>
    );
};

interface SparkSnapsPanelProps {
    className?: string;
    sparkId: string;
    accountId: string;
    initialSnapIds?: number[];
    creatorHasSnapsEntitlement?: boolean;
    sparkStatus: TSparkStatus;
    hideTitle?: boolean;
    onChange?: (params: {
        accountId: string;
        storifymeAccountId: string;
        snapIds: number[];
    }) => void;
}

const SparkSnapsPanel: FC<SparkSnapsPanelProps> = ({
    className,
    sparkId,
    accountId,
    initialSnapIds = [],
    onChange,
    creatorHasSnapsEntitlement,
    sparkStatus,
    hideTitle = false,
}) => {
    const { userIsAdmin } = useApp();
    const [snapIds, setSnapIds] = useState(initialSnapIds);
    const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
    const [drawerIsVisible, setDrawerIsVisible] = useState(false);
    const [drawerSnapId, setDrawerSnapId] = useState<number>();
    const { handleViewSnap, SnapViewer } = useSnapViewQueue();
    const { createSnapAsync, isLoading } = useCreateSnapMutation(accountId);
    const { account: userAccount, hasSnapsEntitlement: snapsEnabled } = useSparkplugAccount();

    const userAccountIsSparkCreator = accountId === userAccount?._id;
    // These are combined pieces of logic to control the ui/ux of the snaps panel
    const snapsDisabledAndIsCreator = !snapsEnabled && userAccountIsSparkCreator;
    const snapsEnabledAndIsCreator = snapsEnabled && userAccountIsSparkCreator;
    const canViewSparksPanel =
        userAccountIsSparkCreator || (initialSnapIds.length > 0 && creatorHasSnapsEntitlement);
    const completedNoSnaps = sparkStatus === 'Complete' && initialSnapIds.length < 1;

    /**
     * Storifyme has a bug where it sends duplicate events.
     * By capturing the previousSnapIds we ensure that we only run onChange when necessary
     */
    const previousSnapIds = useRef<number[]>(initialSnapIds);

    /**
     * Because the snap editor "onSnapSaved" event is used for both creating new snaps
     * and updating existing snaps, we need to keep track of whether the snap editor
     * is being used to create a new snap or update an existing one. If we are editing
     * an existing snap, we don't want to update the snapIds state
     */
    // TODO: Overhaul these patterns in https://linear.app/sparkplug/issue/ADM-865/cleanup-overhaul-useupdatesparksnapsmutation-patterns-in
    const isCreatingNewSnapInEditor = useRef(false);
    const handleSnapEditorSaved = async ({ snapId }: { snapId: number }) => {
        if (snapId && isCreatingNewSnapInEditor.current) {
            setSnapIds((prevSnapIds) => {
                previousSnapIds.current = prevSnapIds;
                return uniq([...prevSnapIds, snapId]);
            });
        } else {
            try {
                await createSnapAsync({ snapId });
                toast.success('Snap Saved!');
            } catch (error) {
                toast.error('Something went wrong');
            }
        }
    };

    const { openSnapEditor, accountSnapSdkCredentials } = useSnapEditorSdk({
        accountId,
        snapsEnabled: snapsEnabledAndIsCreator,
        onSnapSaved: handleSnapEditorSaved,
    });

    const handleRemoveSnap = async (id: number) => {
        setDrawerIsVisible(false);
        setSnapIds((prevSnapIds) => {
            previousSnapIds.current = prevSnapIds;
            return prevSnapIds.filter((snapId) => snapId !== id);
        });
    };

    const handleEditSnap = (snapId: number) => {
        setDrawerIsVisible(true);
        setDrawerSnapId(snapId);
    };

    const handleAddSnap = ({ snapName }: { snapName: string }) => {
        isCreatingNewSnapInEditor.current = true;
        setDrawerIsVisible(false);
        openSnapEditor({
            name: snapName, // Name of the new snap design
        });
    };

    const handleOpenSnapInEditor = (id: number) => {
        isCreatingNewSnapInEditor.current = false;
        setDrawerIsVisible(false);
        openSnapEditor({
            snapId: id,
        });
    };

    const handleViewSnapInQueue = useCallback(
        (selectedSnapId: number) =>
            handleViewSnap({ selectedSnapId, accountId, snapQueue: snapIds, sparkId }),
        [snapIds, sparkId, accountId],
    );

    const { updateSnapAsync } = useUpdateSnapMutation({
        accountId,
        snapId: drawerSnapId as number,
        isTemplate: false,
    });

    const handleUpdateSnap = async ({ snapName }: { snapName: string }) => {
        updateSnapAsync({ name: snapName });
        setDrawerIsVisible(false);
    };
    /**
     * If the snapIds change, we need to call the onChange callback
     */
    useEffect(() => {
        // TODO overhaul this pattern in https://linear.app/sparkplug/issue/ADM-865/cleanup-overhaul-useupdatesparksnapsmutation-patterns-in
        if (!isEqual(previousSnapIds.current, snapIds)) {
            onChange?.({
                accountId,
                storifymeAccountId: accountSnapSdkCredentials?.accountId ?? '',
                snapIds,
            });
        }
    }, [snapIds, accountSnapSdkCredentials?.accountId]);

    if (!canViewSparksPanel || completedNoSnaps) {
        return <></>;
    }

    return (
        <>
            <Paper className={cn('spark-snaps-panel', className)}>
                {!hideTitle && (
                    <Paper.Title>
                        Spark Snaps{' '}
                        {snapsDisabledAndIsCreator && (
                            <Chip
                                onClick={() => setOpenUpgradeModal(true)}
                                color="cerulean"
                                label="upgrade"
                                sx={{ ml: 0.5 }}
                            />
                        )}
                    </Paper.Title>
                )}
                <div className="spark-snaps">
                    {snapIds.map((snapId) => (
                        <SparkSnapItem
                            key={snapId}
                            accountId={accountId}
                            snapId={snapId}
                            onEdit={handleEditSnap}
                            canEdit={snapsEnabledAndIsCreator && sparkStatus === 'Upcoming'}
                            onView={handleViewSnapInQueue}
                            userIsAdmin={userIsAdmin}
                            sparkId={sparkId}
                            trackingSource="Spark Snaps Panel"
                        />
                    ))}
                    {userAccountIsSparkCreator && sparkStatus !== 'Complete' && (
                        <AddSparkSnapButton
                            onClick={() => {
                                if (snapsEnabled) {
                                    setDrawerIsVisible(true);
                                    setDrawerSnapId(undefined);
                                } else {
                                    setOpenUpgradeModal(true);
                                }
                            }}
                        />
                    )}
                </div>
                <SnapViewer />
            </Paper>
            <SnapsUpgradeConfirmModal
                isVisible={openUpgradeModal}
                onClose={() => setOpenUpgradeModal(false)}
                onConfirm={() => {
                    setOpenUpgradeModal(false);
                    Intercom.open();
                }}
            />
            {drawerSnapId ? (
                <CreateEditSnapDrawer
                    isVisible={drawerIsVisible}
                    onClose={() => setDrawerIsVisible(false)}
                    onRemove={() => handleRemoveSnap(drawerSnapId)}
                    onSave={handleUpdateSnap} // Adjust as needed
                    drawerTitle="Edit Snap"
                    snapType="Spark Snap"
                    calloutMessage={SNAP_DRAWER_CALLOUT}
                    snapId={drawerSnapId}
                >
                    <EditSnapDrawerContent
                        snapId={drawerSnapId}
                        accountId={accountId}
                        onOpenInEditor={handleOpenSnapInEditor}
                    />
                </CreateEditSnapDrawer>
            ) : (
                <CreateEditSnapDrawer
                    isVisible={drawerIsVisible}
                    onClose={() => setDrawerIsVisible(false)}
                    onSave={handleAddSnap}
                    drawerTitle="Add Snap"
                    snapType="Spark Snap"
                    calloutMessage={SNAP_DRAWER_CALLOUT}
                >
                    <CreateSnapDrawerContent snapType="Spark Snap" />
                </CreateEditSnapDrawer>
            )}
        </>
    );
};

export default SparkSnapsPanel;
