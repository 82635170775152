import React from 'react';

import SparkSnapIcon from '../SparkSnapIcon';

import './SnapTableCell.scss';

const SnapTableCell = ({ name, iconUrl }: { name: string; iconUrl?: string }) => {
    return (
        <div className="table-cell_snap-name">
            <div className="snap-name-container">
                <SparkSnapIcon imgUrl={iconUrl} title={name} />
                <span>{name}</span>
            </div>
        </div>
    );
};

export default SnapTableCell;
