import { Component, ReactNode } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import FormGroup from '@mui/material/FormGroup';
import MuiInputLabel from '@mui/material/InputLabel';

import Button, { IButtonProps } from '@components/buttons/Button';
import Checkbox from '@components/inputs/Checkbox';
import ChipListInput from '@components/inputs/ChipListInput';
import DatePicker from '@components/inputs/DatePicker';
import DateRangePicker from '@components/inputs/DateRangePicker';
import DropdownTreeSelect from '@components/inputs/DropdownTreeSelect';
import EmojiPicker from '@components/inputs/EmojiPicker';
import HiddenField from '@components/inputs/HiddenField';
import ImageField from '@components/inputs/ImageField';
import InputHelperText from '@components/inputs/InputHelperText';
import LabeledSwitch from '@components/inputs/LabeledSwitch';
import PhoneField from '@components/inputs/PhoneField';
import RadioGroup from '@components/inputs/RadioGroup';
import RecurringSchedulePicker from '@components/inputs/RecurringSchedulePicker';
import SearchSelect from '@components/inputs/SearchSelect';
import Select from '@components/inputs/Select';
import Switch from '@components/inputs/Switch';
import TextField from '@components/inputs/TextField';
import Description from '@components/layout/Description';
import Grid from '@components/layout/Grid';

import { appendClasses } from '@helpers/ui';

import { IUIComponent } from '@app/types/UITypes';

import FormButtonGroup from '../FormButtonGroup';

import './Form.scss';

interface FormProps {
    ref?: any;
    formRef?: any;
    className?: string;
    onSubmit?: (formData?: { [key: string]: any }) => void;
    autoComplete?: 'off' | 'new-password';
    children: ReactNode;
}

class FormClass extends Component<any, any> {
    isValid(): boolean {
        const { methods } = this.props;

        let isValid = true;

        (async () => {
            await methods.handleSubmit(
                () => {
                    isValid = true;
                },
                () => {
                    isValid = false;
                },
            )();
        })();

        return isValid;
    }

    render() {
        return <form {...this.props} />;
    }
}

const Form = ({ formRef, className = '', children, autoComplete, onSubmit }: FormProps) => {
    const methods = useForm();

    const classes = appendClasses(['form', className]);

    const formProps = {
        className: classes,
        onSubmit: onSubmit ? methods.handleSubmit(onSubmit) : (e: InputEvent) => e.preventDefault(),
        methods,
        autoComplete,
    };

    return (
        <FormProvider {...methods}>
            <FormClass ref={formRef} {...formProps}>
                {children}
            </FormClass>
        </FormProvider>
    );
};

Form.TextField = TextField;
Form.PhoneField = PhoneField;
Form.ImageField = ImageField;
Form.HiddenField = HiddenField;
Form.Select = Select;
Form.SearchSelect = SearchSelect;
Form.TreeSelect = DropdownTreeSelect;
Form.Switch = Switch;
Form.DatePicker = DatePicker;
Form.DateRangePicker = DateRangePicker;
Form.EmojiPicker = EmojiPicker;
Form.ChipListInput = ChipListInput;
Form.Checkbox = Checkbox;
Form.Group = FormGroup;
Form.ButtonGroup = FormButtonGroup;
Form.RadioGroup = RadioGroup;
Form.InputHelperText = InputHelperText;
Form.LabeledSwitch = LabeledSwitch;
// TODO: hook up to `useFormContext`
Form.RecurringSchedulePicker = RecurringSchedulePicker;

Form.Grid = Grid;
Form.GridItem = Grid.Item;

Form.SectionTitle = (props: any) => {
    const { className = '', ...otherProps } = props;
    return <div className={`form-section-title content ${className}`} {...otherProps} />;
};

Form.Description = ({ className, ...props }: { className?: string; children?: ReactNode }) => {
    const classNamesAppended = appendClasses([className, 'form-description']);

    return <Description className={classNamesAppended} {...props} />;
};

Form.Label = ({ className, ...props }: any) => {
    const classNamesAppended = appendClasses([className, 'form-label form-label-custom']);

    return <MuiInputLabel className={classNamesAppended} {...props} />;
};

interface IFormActionGroup extends IUIComponent {
    direction?: 'horizontal' | 'vertical';
}

const FormActionGroup = ({ className, direction = 'horizontal', ...props }: IFormActionGroup) => {
    const classNamesAppended = appendClasses([
        className,
        'form-action-group',
        `form-action-group-direction-${direction}`,
    ]);

    return <div className={classNamesAppended} {...props} />;
};

Form.ActionGroup = FormActionGroup;

interface FormButtonProps extends Omit<IButtonProps, 'onClick'> {
    onClick: (formData: any) => void;
    customOnError?: () => void;
}
Form.Button = ({ onClick, customOnError, ...props }: FormButtonProps) => {
    const { handleSubmit } = useFormContext();

    const onSuccess = (formData: any) => {
        onClick(formData);
    };

    const onError = (formFieldErrors: any) => {
        if (customOnError) {
            customOnError();
        }
        // eslint-disable-next-line
        console.error(formFieldErrors);
    };

    return <Button onClick={handleSubmit(onSuccess, onError)} {...props} />;
};

export default Form;
