import { DATE_DISPLAY_FORMAT } from '@constants/AppConstants';
import moment from 'moment';

import { ArrowForward } from '@components/icons';
import RadioGroup from '@components/inputs/RadioGroup';

import { DeleteRecurringSparkOption } from './DeleteSparkModal';

import './RecurringSparkRadioGroup.scss';

const formatDate = (date: string) => {
    return moment(date).format(DATE_DISPLAY_FORMAT);
};

export const RecurringSparkRadioGroup = ({
    dateStart,
    dateEnd,
    value,
    onChange,
}: {
    dateStart: string;
    dateEnd: string;
    value: DeleteRecurringSparkOption;
    onChange: (value: DeleteRecurringSparkOption) => void;
}) => {
    const startDateString = formatDate(dateStart);
    const endDateString = formatDate(dateEnd);

    return (
        <RadioGroup
            value={value}
            radioGroupClassName="delete-recurring-spark-radio-group"
            onChange={(e) => onChange(e.target.value)}
            options={[
                {
                    value: 'thisSparkOnly',
                    label: 'This Spark only',
                    description: (
                        <div className="delete-recurring-spark-radio-group_description">
                            <span>{startDateString}</span>
                            <ArrowForward fontSize="inherit" />
                            <span>{endDateString}</span>
                        </div>
                    ),
                },
                {
                    value: 'thisSparkAndFuture',
                    label: 'This and all future Sparks',
                },
            ]}
            variant="none"
            color="blue"
        />
    );
};
