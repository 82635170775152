import axios from 'axios';

import { EmployeeEarningsExportRequestBody } from '@sparkplug/lib';

import { useAdvancedMutation } from '@hooks/QueryHooks';

export interface EmployeeEarningsExportParams {
    accountId: string;
    startDate: string;
    endDate: string;
}

const API = {
    initiateEmployeeEarningsExport: async ({
        accountId,
        startDate,
        endDate,
    }: EmployeeEarningsExportParams) => {
        return axios.post<EmployeeEarningsExportRequestBody>(`/api/v1/exports/employee-earnings`, {
            accountId,
            dateStart: startDate,
            dateEnd: endDate,
        });
    },
};

export const useInitiateEmployeeEarningsExport = (options?: { onSuccess: () => void }) => {
    const { mutate, isLoading } = useAdvancedMutation(
        (payload: EmployeeEarningsExportParams) => API.initiateEmployeeEarningsExport(payload),
        {
            customOptions: { onSuccess: options?.onSuccess },
            toastOptions: {
                loading: 'Preparing export...',
                success: 'Export initiated',
                error: 'Something went wrong.',
            },
        },
    );

    return {
        initiateEmployeeEarningsExport: mutate,
        isInitiatingEmployeeEarningsExport: isLoading,
    };
};
