import { PagedApiResponseBody } from '../http';
import { z } from 'zod';

export const RewardTypes = ['payout', 'other'] as const;
export type RewardType = typeof RewardTypes[number];

export const RewardStatuses = ['pending', 'confirmed', 'claimed'] as const;
export type RewardStatus = typeof RewardStatuses[number];

export interface RewardPayoutParameters {
  amount: number;
  campaignId: string;
  externalId: string;
  fundingSourceId: string;
  recipientEmail?: string;
  recipientName: string;
  recipientPhone?: string;
}

export interface RewardPayout {
  amount: number;
  createdAt: string;
  externalId: string;
  orderId: string;
  recipientEmail?: string;
  recipientName: string;
  recipientPhone?: string;
  status: string;
  deliveryLink: string;
}

export interface FundingSource {
  method: string;
  id: string;
  meta: any;
}

export interface Campaign {
  id: string;
  description: string;
  name: string;
  products: string[];
}

export interface IPublicReward {
  _id: string;
  amount: number;
  claimedAt: Date;
  claimInstructions: string;
  confirmedAt?: string;
  confirmedByUserId?: string;
  nonFbsReceiptConfirmedAt?: Date;
  createdAt: string;
  deletedAt?: string;
  deliveryLink: string;
  name: string;
  posEmployeeProfileId?: string;
  posEmployeeProfileIds?: string[];
  userId?: string;
  sparkId: string;
  status: RewardStatus;
  type: RewardType;
  updatedAt: string;
}

export const createRewardSchema = z.object({
  amount: z.number(),
  claimInstructions: z.string().optional(),
  name: z.string().optional(),
  posEmployeeProfileId: z.string().optional(),
  userId: z.string().optional(),
  posEmployeeProfileIds: z.array(z.string()).optional(),
  sparkId: z.string().min(1),
  type: z.enum(RewardTypes),
});

export type CreateRewardRequestBody = z.infer<typeof createRewardSchema>;

export interface CreateRewardResponseBody {
  data: IPublicReward;
}

export interface GetRewardPathParams {
  rewardId: string;
}
export type GetRewardResponseBody = CreateRewardResponseBody;

export type UpdateRewardPathParams = GetRewardPathParams;

export const updateRewardSchema = createRewardSchema.partial();
export type UpdateRewardRequestBody = z.infer<typeof updateRewardSchema>;

export type DeleteRewardPathParams = GetRewardPathParams;

export interface ListRewardsQueryParams {
  spark_id?: string;
  pos_employee_profile_id?: string;
  limit?: number;
  offset?: number;
}
export type ListRewardsResponseBody = PagedApiResponseBody<IPublicReward>;

export interface ListCurrentUserRewardsQueryParams {
  limit?: number;
  offset?: number;
}

export type ListCurrentUserRewardsResponseBody = ListRewardsResponseBody;

export type ConfirmRewardPathParams = GetRewardPathParams;

export type ConfirmNonFbsReceiptRewardPathParams = GetRewardPathParams;

export interface ConfirmRewardRequestBody {
  fundingSourceId?: string;
  campaignId?: string;
}

export type ClaimRewardPathParams = GetRewardPathParams;
export type ClaimRewardResponseBody = CreateRewardResponseBody;

export interface ListCampaignsAndFundingSourcesResponseBody {
  data: {
    campaigns: Campaign[];
    fundingSources: FundingSource[];
  };
}
