import * as React from 'react';

import { UserSmsStatus } from '@sparkplug/lib';

import {
    CustomBlockedIcon,
    CustomEnrolledIcon,
    CustomInactiveIcon,
    CustomNotEnrolledIcon,
    CustomPendingIcon,
} from '@components/icons';
import Tooltip from '@components/layout/Tooltip';

const SmsEnrollmentInfoIcon = ({
    smsStatus,
    isInactive = false,
}: {
    smsStatus?: UserSmsStatus;
    isInactive?: boolean;
}): React.ReactElement | null => {
    if (isInactive) {
        return (
            <Tooltip title="SMS Status: Inactive">
                <CustomInactiveIcon />
            </Tooltip>
        );
    }

    switch (smsStatus) {
        case 'blocked':
            return (
                <Tooltip title="SMS status: Blocked">
                    <CustomBlockedIcon />
                </Tooltip>
            );
        case 'enrolled':
            return (
                <Tooltip title="SMS status: Enrolled">
                    <CustomEnrolledIcon />
                </Tooltip>
            );
        case 'pending':
            return (
                <Tooltip title="SMS status: Pending">
                    <CustomPendingIcon />
                </Tooltip>
            );
        case 'none':
        default:
            return (
                <Tooltip title="SMS status: Not Enrolled">
                    <CustomNotEnrolledIcon />
                </Tooltip>
            );
    }
};

export default SmsEnrollmentInfoIcon;
