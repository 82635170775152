import { appendClasses } from '@helpers/ui';

import './MultiPartLinearProgress.scss';

const LinearProgressSegment = ({ color, value }: { color: string; value: number }) => {
    const segmentClassName = appendClasses([
        'linear-progress-segment',
        `linear-progress-segment-color-${color}`,
    ]);

    return <div className={segmentClassName} style={{ flexBasis: `${value}%` }} />;
};

interface IMultiPartLinearProgressProps {
    className?: string;
    colors: string[];
    size?: 'sm' | 'md' | 'lg';
    values: number[];
}
const MultiPartLinearProgress = ({
    className,
    colors = [],
    values = [],
    size = 'md',
}: IMultiPartLinearProgressProps) => {
    if (colors.length !== values.length) {
        throw new Error(
            '<MultiPartLinearProgress />: Must provide an equal number of colors and values',
        );
    }

    const containerClassName = appendClasses([
        className,
        'MuiLinearProgress-root',
        'linear-progress',
        `linear-progress-size-${size}`,
    ]);

    return (
        <div className={containerClassName}>
            <div className="multipart-linear-progress-track">
                {values.map((value, i) => (
                    <LinearProgressSegment key={`${i}-${value}`} color={colors[i]} value={value} />
                ))}
            </div>
        </div>
    );
};

export default MultiPartLinearProgress;
