import { useCallback } from 'react';

import axios from 'axios';

import { ListEmployeeStandingsResponseBody, Spark } from '@sparkplug/lib';

import { useApp } from '@hooks/AppHooks';
import { useAdvancedQuery, useQueryClient } from '@hooks/QueryHooks';

const API = {
    fetchEmployeeStandings: async (sparkId: string) => {
        return (
            (
                await axios.get<ListEmployeeStandingsResponseBody>(
                    `/api/v1/employee-data/standings/${sparkId}`,
                )
            ).data.data ?? ([] as Spark[])
        );
    },
};

export const getEmployeeSparkStandingsCacheKey = (sparkId: string, userId: string) => [
    'employee-standings',
    'spark',
    sparkId,
    'user',
    userId,
];
export const useEmployeeSparkStandings = (
    sparkId: string,
    options?: { autoFetch?: boolean; enabled?: boolean },
) => {
    const { userId = '' } = useApp();

    const { data, isFetched: sparkStandingsAreReady } = useAdvancedQuery(
        getEmployeeSparkStandingsCacheKey(sparkId, userId),
        () => API.fetchEmployeeStandings(sparkId),
        {
            enabled: !!userId && !!sparkId && (options?.enabled ?? options?.autoFetch ?? true),
        },
    );

    const queryClient = useQueryClient();
    const prefetchSparkStandings = useCallback(async () => {
        queryClient.prefetchQuery(getEmployeeSparkStandingsCacheKey(sparkId, userId), () =>
            API.fetchEmployeeStandings(sparkId),
        );
    }, [queryClient, sparkId]);

    return {
        sparkStandings: data?.standings ?? [],
        currentUserStanding: data?.currentUserStanding,
        minutesSinceLastCache: data?.minutesSinceLastCache,
        sparkStandingsAreReady,
        prefetchSparkStandings,
    };
};
