/**
 * Note that this component code comes from https://github.com/zeus2198/react-swipezor, which
 * is unmaintained and not compatible with React 17
 */
import { ReactElement, useEffect, useRef, useState } from 'react';
import { SwipeEventData, SwipeableHandlers, useSwipeable } from 'react-swipeable';

import { ChevronRight as ArrowRightIcon } from '@components/icons';

import { appendClasses } from '@helpers/ui';

import Button from '../Button';

import './SwipeButton.scss';
import './swipezor.css';

function findLeft(element: Element) {
    const rec = element.getBoundingClientRect();
    return rec.left + window.scrollX;
}

interface SwipeButtonProps {
    mainText: ReactElement | string;
    overlayText: ReactElement | string;
    onSwipeDone: () => void;
    reset?: boolean;
    classList?: string;
    overlayClassList?: string;
    caretClassList?: string;
    delta?: number;
    minSwipeWidth?: number;
    minSwipeVelocity?: number;
    caret: ReactElement;
    disabled?: boolean;
    'data-testid'?: string;
}

function SwipeButton({
    mainText,
    overlayText,
    onSwipeDone,
    reset = false,
    classList = '',
    overlayClassList = '',
    caretClassList = '',
    delta = 10,
    minSwipeWidth = 0.6,
    minSwipeVelocity = 0.6,
    caret,
    disabled,
    'data-testid': dataTestId,
}: SwipeButtonProps) {
    const [overlayWidth, setOverlayWidth] = useState(40);
    const [swipeComplete, setSwipeComplete] = useState(false);
    const buttonRef = useRef<any>();

    useEffect(() => {
        if (reset) {
            setSwipeComplete(false);
            setOverlayWidth(40);
        }
    }, [reset]);

    const handlers: SwipeableHandlers = useSwipeable({
        onSwipedRight: (data: SwipeEventData) => {
            if (swipeComplete) return;
            const butWidth = buttonRef?.current?.offsetWidth;
            if (data.velocity > minSwipeVelocity) {
                setOverlayWidth(butWidth);
                setSwipeComplete(true);
                setTimeout(() => onSwipeDone(), 100);
            } else {
                const offsetLeft = findLeft(buttonRef.current);
                const startPos = Math.abs(data.initial[0] - offsetLeft);
                if (
                    startPos <= 100 &&
                    (data.event.type === 'touchend'
                        ? (data.event as any).changedTouches[0].clientX - offsetLeft
                        : (data.event as any).offsetX) >
                        minSwipeWidth * butWidth
                ) {
                    setOverlayWidth(butWidth);
                    setSwipeComplete(true);
                    setTimeout(() => onSwipeDone(), 100);
                } else {
                    setOverlayWidth(40);
                }
            }
        },
        onSwiping: (data: SwipeEventData) => {
            if (swipeComplete) return;
            const offsetLeft = findLeft(buttonRef.current);
            const startPos = Math.abs(data.initial[0] - offsetLeft);
            if (startPos <= 100) {
                if (data.event.type && data.event.type === 'touchmove') {
                    setOverlayWidth((data.event as any).touches[0].clientX - offsetLeft);
                } else {
                    setOverlayWidth((data.event as any).offsetX);
                }
            }
        },
        delta,
        trackMouse: true,
        preventDefaultTouchmoveEvent: true,
    } as any);

    if (disabled) {
        return (
            <div className={`swipezor-but ${classList}`}>
                <Button data-testid={dataTestId} color="neutral" disabled onClick={() => {}}>
                    {mainText}
                </Button>
            </div>
        );
    }

    return (
        <div
            className={`swipezor-but ${classList}`}
            data-testid={dataTestId}
            {...handlers}
            ref={(t) => {
                handlers.ref(t);
                buttonRef.current = t;
            }}
        >
            <div className={`swipezor-overlay ${overlayClassList}`} style={{ width: overlayWidth }}>
                <div className="swipezor-overlay-wrapper">
                    <div className={`swipezor-caret-wrapper ${caretClassList}`}>{caret}</div>
                    <div className="swipezor-overlay-txt">{overlayText}</div>
                </div>
            </div>
            {mainText}
        </div>
    );
}

interface ISwipeButtonProps
    extends Pick<
        SwipeButtonProps,
        'mainText' | 'overlayText' | 'reset' | 'disabled' | 'data-testid'
    > {
    className?: string;
    onSwipe: () => void;
}

const CustomSwipeButton = ({
    className,
    mainText = 'Swipe',
    overlayText = 'Swipe',
    onSwipe,
    disabled,
    ...otherProps
}: ISwipeButtonProps) => {
    const classNamesAppended = appendClasses([
        'swipe-button',
        className,
        disabled ? 'swipe-button-disabled' : '',
    ]);
    return (
        <div className={classNamesAppended}>
            <SwipeButton
                caret={<ArrowRightIcon />}
                onSwipeDone={onSwipe}
                mainText={mainText}
                overlayText={overlayText}
                disabled={disabled}
                {...otherProps}
            />
        </div>
    );
};

export default CustomSwipeButton;
