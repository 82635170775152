import { FC, useEffect, useState } from 'react';
import { SingleDatePicker as AirBnBSingleDatePicker } from 'react-dates';

import { DATE_DISPLAY_FORMAT } from '@constants/AppConstants';
import { FormControl } from '@mui/material';
import moment, { Moment, MomentInput } from 'moment';

import { InfoLetterIcon } from '@components/icons';
import InputLabel from '@components/inputs/InputLabel';
import TextField from '@components/inputs/TextField';
import ClickableArea from '@components/layout/ClickableArea';

import { appendClasses, uuid } from '@helpers/ui';

import { IFormField } from '@app/types/UITypes';

import './DatePicker.scss';

export interface DatePickerProps extends Omit<IFormField, 'onChange' | 'value'> {
    id: string;
    className?: string;
    color?: 'neutral' | 'blue' | 'green' | 'red';
    variant?: 'flat' | 'filled' | 'raised' | 'smooth' | 'outlined';
    label?: string;
    value: MomentInput;
    onChange: (value: moment.Moment | null) => void;
    isOutsideRange?: (date: moment.Moment) => boolean;
    calendarInfo?: string;
}

const DatePicker: FC<DatePickerProps> = ({
    id,
    required,
    className,
    label,
    value,
    onChange,
    variant = 'raised',
    color = 'blue',
    disabled,
    placeholder,
    isOutsideRange = () => false,
    calendarInfo,
}) => {
    const classes = appendClasses([
        'single-date-picker',
        color ? `single-date-picker-color-${color}` : null,
        variant ? `single-date-picker-variant-${variant}` : null,
        className,
    ]);
    const [isFocused, setFocused] = useState<boolean>(false);
    const [date, setDate] = useState<Moment | null>(null);

    useEffect(() => {
        if (value) {
            const newValue = moment.isMoment(value) ? value : moment(value);
            setDate(newValue);
        }
    }, [value]);

    const onDateChanged = (updatedValue: Moment | null) => {
        onChange(updatedValue);
    };

    useEffect(() => {
        if (isFocused) {
            setFocused(false);
        }
    }, [date]);

    return (
        <FormControl className={classes}>
            {label && (
                <InputLabel shrink required={required}>
                    {label}
                </InputLabel>
            )}

            {isFocused && (
                <ClickableArea
                    className="single-date-picker_overlay"
                    onClick={() => setFocused(false)}
                />
            )}

            <AirBnBSingleDatePicker
                id={id}
                appendToBody={false}
                placeholder={placeholder}
                date={date}
                onDateChange={onDateChanged}
                focused={isFocused}
                disabled={disabled}
                onFocusChange={({ focused }) => {
                    if (focused) {
                        setFocused(focused);
                    }
                }}
                isOutsideRange={isOutsideRange}
                hideKeyboardShortcutsPanel
                displayFormat={DATE_DISPLAY_FORMAT}
                calendarInfoPosition={calendarInfo ? 'top' : undefined}
                renderCalendarInfo={
                    calendarInfo
                        ? () => (
                              <div className="single-date-picker_calendar-info">
                                  <InfoLetterIcon />
                                  <span>{calendarInfo}</span>
                              </div>
                          )
                        : undefined
                }
            />
        </FormControl>
    );
};

export const MockDatePicker: FC<DatePickerProps> = ({ value, onChange, placeholder }) => {
    return (
        <TextField
            name={uuid()}
            value={value as any}
            placeholder={placeholder}
            onChange={(event) => onChange(moment(event.target.value))}
        />
    );
};

export default DatePicker;
