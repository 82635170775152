import { useMemo } from 'react';

import { VendorBrandRetailer } from '@features/account-links';

import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import { ISparkCommissionMap } from '@app/types/SparksTypes';

export const useSelectedRetailerCommissionMap = (selectedRetailer?: VendorBrandRetailer) => {
    const { spark, multiRetailerProductSelection, multiRetailerProductsAreReady } = useSpark();

    const commissionMap: ISparkCommissionMap = useMemo(() => {
        const selectedRetailerProductDetails =
            multiRetailerProductSelection?.[selectedRetailer?.retailerAccountId ?? ''];
        return new Map(
            selectedRetailerProductDetails?.commissions?.map((commission) => {
                return [commission.posProductId, { ...commission, enabled: true }];
            }),
        );
    }, [multiRetailerProductSelection, selectedRetailer]);

    if (spark.type !== 'commission') {
        return {
            selectedRetailerCommissionMap: new Map(),
            selectedRetailerCommissionMapIsReady: true,
        };
    }

    return {
        selectedRetailerCommissionMap: commissionMap,
        selectedRetailerCommissionMapIsReady: multiRetailerProductsAreReady,
    };
};
