import { useMemo } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';

import { Menu, SidebarLayout } from '@componentLibrary';
import UnauthorizedView from '@views/auth/UnauthorizedView';

import { CreateEventButton } from '@features/events/components/CreateEventButton';
import ApproveEvent from '@features/events/routes/ApproveEvent';
import { EventsInbox } from '@features/events/routes/EventsInbox';
import { EventsOutbox } from '@features/events/routes/EventsOutbox';
import { RetailerScheduledEvents } from '@features/events/routes/ScheduledEvents/RetailerScheduledEvents';
import { VendorScheduledEvents } from '@features/events/routes/ScheduledEvents/VendorScheduledEvents';

import { CalendarIcon, Inbox as InboxIcon, Send as SendIcon } from '@components/icons';
import PageHeader from '@components/layout/PageHeader';
import Toolbar from '@components/toolbar/Toolbar';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { IAccount } from '@app/types/AccountsTypes';

const AdminEventsView = ({
    account,
    connectEnabled,
}: {
    account: IAccount;
    connectEnabled: boolean;
}) => {
    const basePath = `/${account._id}/events`;
    const inboxPath = `${basePath}/inbox`;
    const sentPath = `${basePath}/sent`;

    const { pathname } = useLocation();
    const currentView = useMemo(() => {
        if (pathname.includes('inbox')) {
            return 'inbox';
        }
        if (pathname.includes('sent')) {
            return 'sent';
        }
        return 'scheduled';
    }, [pathname]);

    return (
        <SidebarLayout
            header={
                <PageHeader title={account?.name ?? ''} heading="Events">
                    <Toolbar collapseOnMobile>
                        <Toolbar.Group className="toolbar-group-end">
                            <CreateEventButton />
                        </Toolbar.Group>
                    </Toolbar>
                </PageHeader>
            }
            sidebar={
                <Menu
                    navigation={[
                        {
                            name: 'Inbox',
                            href: inboxPath,
                            icon: <InboxIcon />,
                            isHidden: account.type === 'brand',
                            current: currentView === 'inbox',
                        },
                        {
                            name: 'Sent',
                            href: sentPath,
                            icon: <SendIcon />,
                            isHidden: account.type === 'retailer',
                            current: currentView === 'sent',
                        },
                        {
                            type: 'hr',
                        },
                        {
                            name: 'Scheduled',
                            href: basePath,
                            icon: <CalendarIcon />,
                            current: currentView === 'scheduled',
                        },
                    ]}
                />
            }
            content={
                <Switch>
                    <Route
                        path={inboxPath}
                        render={() => {
                            if (!connectEnabled) {
                                return <UnauthorizedView />;
                            }
                            if (account.type !== 'retailer') {
                                return <Redirect to={basePath} />;
                            }
                            return <EventsInbox />;
                        }}
                    />
                    <Route
                        path={sentPath}
                        render={() => {
                            if (!connectEnabled) {
                                return <UnauthorizedView />;
                            }
                            if (account.type !== 'brand') {
                                return <Redirect to={basePath} />;
                            }
                            return <EventsOutbox />;
                        }}
                    />
                    <Route
                        path={`${basePath}/:eventId`}
                        render={() => {
                            if (!connectEnabled) {
                                return <UnauthorizedView />;
                            }

                            if (account.type === 'retailer') {
                                return <ApproveEvent />;
                            }

                            return null;
                        }}
                    />
                    <Route
                        path={basePath}
                        render={() => {
                            if (!connectEnabled) {
                                return <UnauthorizedView />;
                            }

                            if (account.type === 'retailer') {
                                return <RetailerScheduledEvents />;
                            }

                            return <VendorScheduledEvents />;
                        }}
                    />
                </Switch>
            }
        />
    );
};

export default () => {
    const { account, connectEnabled } = useSparkplugAccount();

    if (!account) {
        return <></>;
    }

    // TODO: Delete this once the events feature is fully released
    const eventsFeatureEnabled = import.meta.env.REACT_APP_EVENTS === 'true';
    if (!eventsFeatureEnabled) {
        return <div className="text-xl font-bold mb-2">Events coming soon!</div>;
    }

    return <AdminEventsView account={account} connectEnabled={connectEnabled} />;
};
