import { FC } from 'react';

import { Emoji, EmojiData } from 'emoji-mart';

import { EmployeeSpark, Spark } from '@sparkplug/lib';

import './SparkCardLogo.scss';

export interface SparkCardLogoProps {
    emoji?: string | EmojiData;
    sparkBrand?: Spark['sparkBrand'];
    recurringSchedule?: EmployeeSpark['recurringSchedule'];
    size?: 'normal' | 'large';
}
export const SparkCardLogo: FC<SparkCardLogoProps> = ({
    emoji,
    sparkBrand,
    recurringSchedule,
    size = 'normal',
}) => {
    const showSparkBrand = !!sparkBrand;
    const showEmoji = !!emoji && !showSparkBrand;

    return (
        <div className={`spark-card-logo card-icons-wrapper ${size}`}>
            {showEmoji && <Emoji emoji={emoji} set="apple" size={size === 'normal' ? 30 : 40} />}

            {showSparkBrand && (
                <div className={`spark-card_spark-brand-logo ${size}`}>
                    <img src={sparkBrand.photo} alt={sparkBrand.name} />
                </div>
            )}
            {/* 
            We're not supporting the recurring indicator for now
            {showRecurringIndicator && (
                <div className="recurring-icon-wrapper">
                    <RecurringSparkIcon />
                </div>
            )} */}
        </div>
    );
};
