import { isBoolean } from 'lodash';

import { DetailedSparkType, Spark, SparkType } from '@sparkplug/lib';

import RadioGroup from '@components/inputs/RadioGroup';
import { RadioOption } from '@components/inputs/RadioGroup/RadioGroupOption';

import './RewardMethodRadioGroup.scss';

export type RewardMethod = 'split' | 'individual';

const individualGoalMessage = (
    <>
        If a team hits a goal, each participating employee will be awarded the{' '}
        <strong>full amount</strong> of the corresponding reward.
    </>
);
const splitGoalMessage = (
    <>
        If a team hits a goal, the corresponding reward will be <strong>evenly split</strong> among
        participating employees.
    </>
);

const individualLeaderboardMessage = (
    <>
        The places and corresponding rewards will be awarded in <strong>total</strong> to each
        employee at that location.
    </>
);
const splitLeaderboardMessage = (
    <>
        The places and corresponding rewards will be <strong>split</strong> by each employee at that
        location.
    </>
);

const managerGoalRewardOptions: RadioOption[] = [
    {
        value: 'individual',
        label: 'Individual Manager Rewards',
        description: (
            <>
                If the store(s) hit a goal, each participating manager will be awarded the{' '}
                <strong>full amount</strong> of the corresponding reward.
            </>
        ),
    },
    {
        value: 'split',
        label: 'Managers Split Rewards',
        description: (
            <>
                If the store(s) hit a goal, the corresponding reward will be{' '}
                <strong>evenly split</strong> among participating managers.
            </>
        ),
    },
];

export const RewardMethodRadioGroup = ({
    className = '',
    sparkType,
    detailedSparkType,
    rewardMethod,
    onRewardMethodChange,
}: {
    className?: string;
    sparkType: SparkType;
    detailedSparkType?: DetailedSparkType;
    rewardMethod?: RewardMethod;
    onRewardMethodChange: (value: RewardMethod) => void;
}) => (
    <RadioGroup
        label="Reward Method*"
        name="reward-method"
        className="reward-method_container"
        variant="grid"
        color="blue"
        value={rewardMethod ?? ''}
        onChange={(e) => onRewardMethodChange(e.target.value as RewardMethod)}
        options={
            detailedSparkType === 'goalManager'
                ? managerGoalRewardOptions
                : [
                      {
                          value: 'individual',
                          label: 'Individual Employee Rewards',
                          description:
                              sparkType === 'goal'
                                  ? individualGoalMessage
                                  : individualLeaderboardMessage,
                      },
                      {
                          value: 'split',
                          label: 'Team Splits Rewards',
                          description:
                              sparkType === 'goal' ? splitGoalMessage : splitLeaderboardMessage,
                      },
                  ]
        }
    />
);

export const getInitialRewardMethodFromSpark = (spark: Spark) => {
    if (isBoolean(spark.splitPayout)) {
        return spark.splitPayout ? 'split' : 'individual';
    }

    return undefined;
};
