import { GetChartRequestBody, GetChartResponseBody, Spark } from '@sparkplug/lib';

import { useAdvancedQuery } from '@hooks/QueryHooks';

import { getCloudChartData } from '@helpers/charts';
import { getSparkPosArchive } from '@helpers/sparks';

import { IChartDataSettings } from '@app/types/ChartDataTypes';

export const getDefaultSparkChartDataSettings = (spark: Spark): IChartDataSettings => {
    const sparkIsArchived = !!(spark._id && spark.archivedAt);

    return {
        metric: spark.metric,
        dateStart: spark.startDate,
        dateEnd: spark.endDate,
        precision: 'day',
        sparkId: spark._id,
        type: 'line',
        breakdown: 'none',
        locationIds: spark.locationIds,
        sparkPosArchiveKey: sparkIsArchived ? 'sparkPeriod' : undefined,
        percentIncreaseData: spark.percentIncreaseData,
    } as IChartDataSettings;
};

const useArchivedSparkCloudChartData = (sparkId?: string, isEnabled: boolean = false) => {
    const { isLoading, data } = useAdvancedQuery(
        ['sparkPosArchive', sparkId],
        () => getSparkPosArchive(sparkId),
        {
            enabled: !!sparkId && isEnabled,
        },
    );

    return {
        isLoading,
        data: data?.charts.sparkPeriod,
    };
};

const useUnarchivedSparkCloudChartData = (spark: Spark, isEnabled = true) => {
    const params: GetChartRequestBody = {
        // locationIds is not required for the payload but is added here to prevent double chart requests on the spark details page
        locationIds: spark.locationIds,
        frequency: 'daily',
        groupId: spark.groupId,
        dateStart: spark.startDate,
        dateEnd: spark.endDate,
        sparkId: spark._id,
        percentIncreaseData: spark.percentIncreaseData,
    };

    const { isLoading, data } = useAdvancedQuery(
        ['chart', spark.metric, JSON.stringify(params)],
        () => getCloudChartData({ type: spark.metric }, params),
        {
            enabled: !!spark._id && isEnabled && !!spark.metric,
        },
    );

    return {
        isLoading,
        data,
    };
};

export const useSparkCloudChartData = (
    spark: Spark,
    isEnabled: boolean = true,
): {
    isLoading: boolean;
    data?: GetChartResponseBody;
} => {
    const { isLoading: isLoadingUnarchivedChartData, data: unarchivedChartData } =
        useUnarchivedSparkCloudChartData(spark, isEnabled && !spark.archivedAt);
    const { isLoading: isLoadingArchivedChartData, data: archivedChartData } =
        useArchivedSparkCloudChartData(spark._id, isEnabled && !!spark.archivedAt);

    if (spark.archivedAt) {
        return {
            isLoading: isLoadingArchivedChartData,
            data: archivedChartData,
        };
    }

    return {
        isLoading: isLoadingUnarchivedChartData,
        data: unarchivedChartData,
    };
};
