import { ComponentProps, ComponentType, MutableRefObject, ReactElement, useMemo } from 'react';
import 'react-dropdown-tree-select/dist/styles.css';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';

import { FormControl } from '@mui/material';

import InputLabel from '@components/inputs/InputLabel';

import { appendClasses } from '@helpers/ui';

import {
    IFormField,
    MultiSelectEventOnOptionChange,
    SelectEventOnOptionChange,
} from '@app/types/UITypes';

import './SearchSelect.scss';

type SearchSelectOption<T> = T & {
    label: string | ReactElement;
    value: string | number;
    options?: SearchSelectOption<T>[];
    isDisabled?: boolean;
};

// single-select vs multi-select
type SingleSearchSelectProps<T> = {
    mode?: 'simpleSelect';
    onChange: SelectEventOnOptionChange<T>;
};
type MultiSearchSelectProps<T> = {
    mode: 'multiSelect';
    onChange: MultiSelectEventOnOptionChange<T>;
};
type OnChangeProps<T> = SingleSearchSelectProps<T> | MultiSearchSelectProps<T>;

// sync options vs async options
type SyncSearchSelectProps<T> = {
    async?: false;
    value: (string | number) | (string | number)[];
    options: SearchSelectOption<T>[];
};
type AsyncSearchSelectProps<T> = {
    async: true;
    value: (string | number) | (string | number)[];
    options: (v: string) => Promise<SearchSelectOption<T>[] | null>;
};
type OptionsProps<T> = SyncSearchSelectProps<T> | AsyncSearchSelectProps<T>;

export type SearchSelectProps<T> = Omit<IFormField, 'onChange' | 'value'> &
    OnChangeProps<T> &
    OptionsProps<T> & {
        inputRef?: MutableRefObject<any>;
        showClear?: boolean;
        className?: string;
        color?: 'neutral' | 'blue' | 'green' | 'red';
        variant?: 'flat' | 'filled' | 'raised' | 'smooth' | 'outlined';
        label?: string;
        required?: boolean;
        minSearch?: number;
        startIcon?: ReactElement;
        placeholder?: string;
        optionLabelComponent?: ComponentType | ((option: SearchSelectOption<T>) => ReactElement);
        isOptionDisabled?: (option: any) => boolean;
        'data-testid'?: string;
    };

const SearchSelect = <T extends {}>({
    inputRef,
    required = false,
    async = false,
    showClear = false,
    className,
    mode = 'simpleSelect',
    label,
    name,
    disabled = false,
    value,
    options,
    onChange,
    variant = 'raised',
    color = 'blue',
    startIcon,
    placeholder = 'Select...',
    optionLabelComponent = (option: any) => option.label,
    isOptionDisabled,
    error = false,
    'data-testid': dataTestId,
}: SearchSelectProps<T>) => {
    const classes = appendClasses([
        className,
        'form-control',
        'form-control-custom',
        'search-select',
        `search-select-color-${color}`,
        `search-select-variant-${variant}`,
    ]);

    const props: ComponentProps<typeof ReactSelect | typeof AsyncSelect> = {
        ref: inputRef,
        isSearchable: true,
        isClearable: showClear,
        isDisabled: disabled,
        isMulti: mode === 'multiSelect',
        classNamePrefix: 'search-select',
        onChange: (updatedValue: any) => {
            if (value || updatedValue) {
                onChange(updatedValue);
            }
        },
        name,
        formatOptionLabel: optionLabelComponent as any,
        isOptionDisabled,
        options: async ? undefined : (options as any),
        loadOptions: async ? (options as any) : undefined,
        defaultOptions: async,
        placeholder: startIcon ? (
            <>
                {startIcon}
                {placeholder != null && <span>{placeholder}</span>}
            </>
        ) : (
            placeholder
        ),
    };

    const selectedOption = useMemo(() => {
        if (async) {
            // TODO: Fix type errors for `async` requiring `value` to be an `IOption`
            return value;
        }

        if (!Array.isArray(options)) {
            return null;
        }

        return mode === 'multiSelect' && Array.isArray(value)
            ? [...options].filter((opt) => value.includes(opt.value))
            : [...options].find((opt) => opt.value === value);
    }, [value, options]);

    return (
        <FormControl error={error} className={classes} data-testid={dataTestId}>
            {label && (
                <InputLabel shrink required={required}>
                    {label}
                </InputLabel>
            )}
            {!async ? (
                <ReactSelect value={selectedOption} {...props} />
            ) : (
                <AsyncSelect value={selectedOption} {...props} />
            )}
        </FormControl>
    );
};

export default SearchSelect;
