import { ComponentProps, ComponentType, FC, ReactElement, useMemo, useState } from 'react';

import { clsx } from 'clsx';

import { useGetPendingSparksCountQuery } from '@core/sparks/queries/GetPendingSparksQuery';

import { useCurrentUserWallet } from '@features/wallet/queries';

import Button from '@components/buttons/Button';
import Chip from '@components/chips/Chip';
import {
    AccountCircle as AccountCircleIcon,
    Event as EventIcon,
    GeneralSettingsIcon,
    HelpOutline as HelpIcon,
    HomeIcon,
    People as PeopleIcon,
    ReadIcon,
    Snaps as SnapsIcon,
    OfflineBolt as SparkIcon,
    SupervisorAccount,
    TrendingUp,
    WalletIcon,
} from '@components/icons';
import UserWidget from '@components/layout/AppHeader/UserWidget';
import CountBadge from '@components/layout/CountBadge';
import { SparkplugCCLogo, SparkplugLogo } from '@components/logos';
import SwitchUserViewModal from '@components/overlays/SwitchUserViewModal';
import { Link, NavLink } from '@components/router';

import { useApp, useControlCenterApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { cn } from '@app/componentLibrary/utils';
import { IAccount } from '@app/types/AccountsTypes';
import { IAuthUser } from '@app/types/UsersTypes';

import CalloutMessage from '../CalloutMessage';
import { NoticeBar } from '../NoticeBar';

import './AppHeader.scss';

interface CustomNavLinkProps extends Pick<ComponentProps<NavLink>, 'isActive' | 'onClick'> {
    text: string;
    url: string;
    icon: ReactElement;
    badgeCount?: number;
    chipText?: string;
}

const CustomNavLink: FC<CustomNavLinkProps> = ({
    text,
    url,
    isActive,
    icon,
    badgeCount,
    onClick,
    chipText,
}) => {
    const { appIsMobile } = useApp();

    const [isNavLinkActive, setIsNavLinkActive] = useState(false);

    return (
        <li className="navbar-link">
            <NavLink
                data-intercom-target={text}
                className={cn(isNavLinkActive && !!badgeCount && !appIsMobile ? '!px-[45px]' : '')}
                activeClassName="nav-link-active"
                to={url}
                isActive={(...props) => {
                    const val = isActive?.(...props);
                    setIsNavLinkActive(!!val);
                    return val ?? false;
                }}
                onClick={onClick}
            >
                <section>
                    {icon}
                    <span>{text}</span>
                    {!!badgeCount && !isNavLinkActive && <div className="mobile-badge" />}
                </section>
                {!!badgeCount && (
                    <CountBadge
                        count={badgeCount}
                        className={cn(isNavLinkActive ? 'hidden' : '')}
                    />
                )}
                {!!chipText && <Chip color="cerulean" label={chipText} />}
            </NavLink>
        </li>
    );
};

const AppLogo: FC<{ isControlCenterApp: boolean }> = ({ isControlCenterApp }) => {
    const to = !isControlCenterApp ? '/' : '/control-center';
    const Logo = () => (!isControlCenterApp ? <SparkplugLogo /> : <SparkplugCCLogo />);

    return (
        <div className="app-logo">
            <Link to={to}>
                <Logo />
            </Link>
        </div>
    );
};

export interface AppHeaderProps {
    isControlCenterApp: boolean;
    isAdminApp: boolean;
    appIsMobile: boolean;
    user?: IAuthUser;
    account?: IAccount;
    pendingSparksCount: number;
    unclaimedDepositCount: number;
    isPWA: boolean;
    connectEnabled: boolean;
}

interface NavLinkItem extends Omit<CustomNavLinkProps, 'icon'> {
    Icon: ComponentType;
    isVisible: () => boolean;
}

export const AppHeader: FC<AppHeaderProps> = ({
    isControlCenterApp,
    isAdminApp,
    appIsMobile,
    user,
    account,
    pendingSparksCount,
    unclaimedDepositCount,
    isPWA,
    connectEnabled,
}) => {
    const isEmployeeApp = !isAdminApp;
    const adminLoggedInAsParticipant =
        isEmployeeApp && user?.role === 'retailer-admin' && !appIsMobile;
    const [showSwitchUserViewModal, setShowSwitchUserViewModal] = useState(false);
    const isSuperAdmin = isAdminApp && user?.role === 'super-admin';
    const subscriptionTypeIsPaid = account?.metaData?.subscriptionType === 'paid';

    const paywallAlert = useMemo(() => {
        if (!isSuperAdmin) {
            return false;
        }

        const hasPaymentMethods = !!account?.paymentHealth?.hasPaymentMethods;
        const hasNoFailedPayment = account?.paymentHealth?.latestChargeStatus !== 'failed';

        if (!hasPaymentMethods && subscriptionTypeIsPaid) {
            return 'has no payment methods on file';
        }
        if (!hasNoFailedPayment && subscriptionTypeIsPaid) {
            return 'has a failed payment';
        }
        return false;
    }, [account]);

    const NavLinks = useMemo(() => {
        if (isControlCenterApp) {
            document.body.classList.remove('app-has-navlinks');
            return [];
        }

        const allLinks: NavLinkItem[] =
            user && account
                ? [
                      {
                          Icon: TrendingUp,
                          url: `/${account._id}/dashboard`,
                          text: 'Dashboard',
                          isVisible: () => isAdminApp,
                          isActive: (match) => {
                              return match != null;
                          },
                      },
                      {
                          Icon: SparkIcon,
                          url: `/${account._id}/sparks`,
                          badgeCount: pendingSparksCount,
                          text: 'Sparks',
                          isVisible: () => isAdminApp,
                          isActive: (match) => {
                              return match != null;
                          },
                      },
                      {
                          Icon: ReadIcon,
                          url: `/${account._id}/courses`,
                          text: 'Courses',
                          chipText: import.meta.env.REACT_APP_COURSES === 'true' ? 'NEW' : '',
                          isVisible: () =>
                              connectEnabled &&
                              isAdminApp &&
                              import.meta.env.REACT_APP_COURSES === 'true',
                          isActive: (match) => {
                              return match != null;
                          },
                      },
                      {
                          Icon: EventIcon,
                          url: `/${account._id}/events`,
                          text: 'Events',
                          isVisible: () =>
                              connectEnabled &&
                              isAdminApp &&
                              import.meta.env.REACT_APP_EVENTS === 'true',
                          isActive: (match) => {
                              return match != null;
                          },
                      },
                      {
                          Icon: SupervisorAccount,
                          url: `/${account._id}/partners`,
                          text: 'Partners',
                          isVisible: () => isAdminApp && connectEnabled,
                          isActive: (match) => {
                              return match != null;
                          },
                      },
                      {
                          Icon: HomeIcon,
                          url: `/user/sparks`,
                          text: 'Home',
                          isVisible: () => isEmployeeApp,
                          isActive: (match) => {
                              return match != null;
                          },
                      },
                      {
                          Icon: SnapsIcon,
                          url: `/${account._id}/snaps`,
                          text: 'Snaps',
                          chipText: import.meta.env.REACT_APP_COURSES !== 'true' ? 'NEW' : '',
                          isVisible: () => isAdminApp && !appIsMobile,
                          isActive: (match) => {
                              return match != null;
                          },
                      },
                      {
                          Icon: WalletIcon,
                          url: `/user/wallet`,
                          text: 'Wallet',
                          badgeCount: unclaimedDepositCount,
                          isVisible: () => isEmployeeApp,
                          isActive: (match) => !!match,
                      },
                      {
                          Icon: GeneralSettingsIcon,
                          url: `/${account._id}/settings`,
                          text: 'Settings',
                          isVisible: () => isAdminApp,
                          isActive: (match, location) => {
                              return location.pathname.includes(`/${account._id}/settings`);
                          },
                      },
                      {
                          Icon: AccountCircleIcon,
                          url: `/user/profile`,
                          text: 'Profile',
                          isVisible: () => isEmployeeApp,
                          isActive: (match) => {
                              return match != null;
                          },
                      },
                      {
                          Icon: HelpIcon,
                          url: `/${account._id}/help`,
                          text: 'Help',
                          isVisible: () => appIsMobile && !isEmployeeApp,
                          isActive: (match) => {
                              return match != null;
                          },
                      },
                      {
                          Icon: HelpIcon,
                          url: `/user/help`,
                          text: 'Help',
                          isVisible: () => appIsMobile && isEmployeeApp,
                          isActive: (match) => {
                              return match != null;
                          },
                      },
                  ]
                : [];

        const visibleLinks = allLinks.filter((link) => link != null && link.isVisible());

        if (visibleLinks.length > 0) {
            document.body.classList.add('app-has-navlinks');
        } else {
            document.body.classList.remove('app-has-navlinks');
        }

        return visibleLinks;
    }, [appIsMobile, isControlCenterApp, user, account, pendingSparksCount, unclaimedDepositCount]);

    return (
        <>
            <div className="app-header">
                {adminLoggedInAsParticipant && (
                    <NoticeBar
                        message="You are currently logged in as a participant"
                        actionButton={
                            <Button
                                onClick={() => setShowSwitchUserViewModal(true)}
                                startIcon={<PeopleIcon />}
                                variant="outlined"
                                color="blue"
                            >
                                Switch View
                            </Button>
                        }
                    />
                )}
                <div className="app-navbar">
                    <AppLogo isControlCenterApp={isControlCenterApp} />
                    {NavLinks.length > 0 && (
                        <ul
                            className={clsx(['app-navbar-menu', isPWA ? 'pwa-bottom-padding' : ''])}
                        >
                            {NavLinks.map(
                                ({ url, text, isActive, Icon, badgeCount = 0, chipText }) => (
                                    <CustomNavLink
                                        key={url}
                                        url={url}
                                        text={text}
                                        isActive={isActive}
                                        icon={<Icon />}
                                        badgeCount={badgeCount}
                                        chipText={chipText}
                                    />
                                ),
                            )}
                        </ul>
                    )}

                    <UserWidget account={account} switchUserViewFn={setShowSwitchUserViewModal} />
                    <SwitchUserViewModal
                        isVisible={showSwitchUserViewModal}
                        onClose={() => {
                            setShowSwitchUserViewModal(false);
                        }}
                    />
                </div>
                {paywallAlert && (
                    <CalloutMessage
                        color="red"
                        message={`This account ${paywallAlert} and is being shown a paywall.`}
                    />
                )}
            </div>
        </>
    );
};

export default () => {
    const { user, appIsMobile, isAdminApp, isPWA } = useApp();
    const { isControlCenterApp } = useControlCenterApp();

    const { account, connectEnabled } = useSparkplugAccount();
    const { count: pendingSparksCount = 0 } = useGetPendingSparksCountQuery({
        account,
        isAdminApp,
    });
    const { currentUserWalletIsReady, currentUserWallet } = useCurrentUserWallet();

    const unclaimedDepositCount = useMemo(() => {
        return (
            currentUserWallet?.deposits?.filter(({ status }) => status !== 'Claimed')?.length ?? 0
        );
    }, [currentUserWalletIsReady, currentUserWallet, account]);

    const props = {
        isControlCenterApp,
        isAdminApp,
        appIsMobile,
        user,
        account,
        pendingSparksCount,
        unclaimedDepositCount,
        isPWA,
        connectEnabled,
    };

    return <AppHeader {...props} />;
};
