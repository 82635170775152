import { FC } from 'react';

import Form from '@components/form/Form';
import { SwitchProps } from '@components/inputs/Switch/Switch';
import Grid from '@components/layout/Grid';

import './LabeledSwitch.scss';

interface LabeledSwitchProps extends SwitchProps {
    title: string;
    subtitle?: string;
}

const LabeledSwitch: FC<LabeledSwitchProps> = ({
    title,
    subtitle,
    className,
    color = 'neutral',
    variant = 'outlined',
    label,
    name,
    value,
    disabled,
    onChange = () => {},
    tooltip,
}) => {
    return (
        <Grid className="labeled-switch">
            <Grid.Item className="passage-container" xs={8} sm={9}>
                <h3>{title}</h3>
                <p>{subtitle}</p>
            </Grid.Item>
            <Grid.Item xs={4} sm={3} className="switch-container">
                <Form.Switch
                    name={name}
                    className={className}
                    color={color}
                    variant={variant}
                    disabled={disabled}
                    tooltip={tooltip}
                    value={value}
                    onChange={(event: any) => {
                        onChange(event);
                    }}
                    label={label}
                />
            </Grid.Item>
        </Grid>
    );
};

export default LabeledSwitch;
