import { useState } from 'react';

import { useAdvancedQuery } from '@hooks/QueryHooks';

import { getRewardPayoutParameters } from '@helpers/rewards';
import { capitalizeFirstLetter, formatNumberToCurrency } from '@helpers/ui';

import { IOption } from '@app/types/UITypes';

interface IGetPayoutOptionsResult {
    campaignOptions: IOption[];
    fundingSourceOptions: IOption[];
}
const getPayoutOptions = async (): Promise<IGetPayoutOptionsResult> => {
    const { campaigns = [], fundingSources = [] } = await getRewardPayoutParameters();

    const campaignOptions: IOption[] = [];
    const fundingSourceOptions: any[] = [];

    campaigns.forEach(({ id, name }) => {
        const campaignOption = campaignOptions.find(({ value }) => value === id);

        if (campaignOption == null) {
            campaignOptions.push({
                value: id,
                label: name,
            });
        }
    });

    fundingSources.forEach(({ id, meta = {}, method }) => {
        const fundingSourceOption = fundingSourceOptions.find(({ value }) => value === id);
        let label = capitalizeFirstLetter(method);

        if (method === 'balance') {
            const { available_cents: availableCents }: any = meta;

            label += ` (${formatNumberToCurrency(availableCents / 100, true)})`;
        }

        if (fundingSourceOption == null) {
            fundingSourceOptions.push({
                value: id,
                label,
            });
        }
    });

    return {
        campaignOptions,
        fundingSourceOptions,
    };
};

interface IUsePayoutOptionsResult {
    isLoadingPayoutOptions: boolean;
    campaignId: string;
    changeCampaignId: (cId: string) => any;
    campaignOptions: IOption[];
    fundingSourceId: string;
}
export const usePayoutOptions = (): IUsePayoutOptionsResult => {
    const [campaignId, setCampaignId] = useState<string>('');

    const { isLoading: isLoadingPayoutOptions, data = {} as IGetPayoutOptionsResult } =
        useAdvancedQuery(['sparkPayoutOptions'], () => getPayoutOptions(), {
            enabled: true,
        });

    const { campaignOptions = [], fundingSourceOptions = [] } = data;

    return {
        isLoadingPayoutOptions,
        campaignId,
        changeCampaignId: setCampaignId,
        campaignOptions,
        fundingSourceId: fundingSourceOptions?.[0]?.value,
    };
};
