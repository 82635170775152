import SparksAPI from '@api/SparksAPI';

import { useAdvancedQuery } from '@hooks/QueryHooks';

import { IAccount } from '@app/types/AccountsTypes';

export const useGetTotalSparksCountQuery = ({
    account,
    isAdminApp,
}: {
    account?: IAccount;
    isAdminApp: boolean;
}) => {
    const { isLoading, data, refetch, isFetched } = useAdvancedQuery(
        `total-sparks-${account?._id ?? ''}`,
        () => (account?._id ? SparksAPI.getTotalSparksCount(account?._id) : Promise.resolve(0)),
        {
            enabled: isAdminApp,
        },
    );

    return { totalSparksCountIsReady: isLoading, totalSparksCount: data, refetch, isFetched };
};
