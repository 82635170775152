import { Dispatch, SetStateAction, useMemo } from 'react';

import { uniqBy } from 'lodash';

import { TExtendedListItem } from '@components/inputs/ListSelector/ListSelector';

import { sortByString } from '@helpers/ui';

import { IPosProduct } from '@app/types/PosTypes';

import { PosRulesBasedProductTableFilters } from './usePosRulesBasedProductTableFilters';

export const useBrandCategoryFilters = ({
    products,
    filters,
    applyBrandFilters,
    applyCategoryFilters,
    updateFilters,
}: {
    products: IPosProduct[];
    filters: PosRulesBasedProductTableFilters;
    applyBrandFilters: (rows: IPosProduct[]) => IPosProduct[];
    applyCategoryFilters: (rows: IPosProduct[]) => IPosProduct[];
    updateFilters: Dispatch<SetStateAction<PosRulesBasedProductTableFilters>>;
}) => {
    const { primaryFilter } = filters;

    const { brandOptions, categoryOptions } = useMemo(() => {
        const options = products.reduce<{
            brands: { _id: string; name: string }[];
            categories: { _id: string; name: string }[];
        }>(
            (res, product) => {
                const category = product.categories?.find(({ _id }) => _id === product.categoryId);
                const brand = product.brands?.find(({ _id }) => _id === product.brandId);

                if (category) {
                    res.categories.push(category);
                }

                if (brand) {
                    res.brands.push(brand);
                }

                return res;
            },
            {
                brands: [],
                categories: [],
            },
        );

        return {
            brandOptions: uniqBy(options.brands, '_id')
                .map(({ _id, name }) => ({
                    value: _id,
                    label: name,
                    key: _id,
                }))
                .sort(sortByString('label', 'asc')),
            categoryOptions: uniqBy(options.categories, '_id')
                .map(({ _id, name }) => ({
                    value: _id,
                    label: name,
                    key: _id,
                }))
                .sort(sortByString('label', 'asc')),
        };
    }, [products]);

    const updateBrandsFilter = (updatedBrands: TExtendedListItem<{ key: string }>[]) => {
        const filterUpdate: Partial<PosRulesBasedProductTableFilters> = {
            brands: updatedBrands,
        };

        if (!primaryFilter) {
            filterUpdate.primaryFilter = 'brands';
        } else if (primaryFilter === 'brands' && !updatedBrands.length) {
            filterUpdate.primaryFilter = undefined;
        }

        updateFilters((prevValue) => ({
            ...prevValue,
            ...filterUpdate,
        }));
    };

    const updateCategoriesFilter = (updatedCategories: TExtendedListItem<{ key: string }>[]) => {
        const filterUpdate: Partial<PosRulesBasedProductTableFilters> = {
            categories: updatedCategories,
        };

        if (!primaryFilter) {
            filterUpdate.primaryFilter = 'categories';
        } else if (primaryFilter === 'categories' && !updatedCategories.length) {
            filterUpdate.primaryFilter = undefined;
        }

        updateFilters((prevValue) => ({
            ...prevValue,
            ...filterUpdate,
        }));
    };

    const visibleBrandOptions = useMemo(() => {
        if (!primaryFilter || primaryFilter === 'brands') {
            return brandOptions;
        }

        const filteredProducts = applyCategoryFilters(products);

        const brandsInFilteredProducts = uniqBy(
            filteredProducts.flatMap((row) => row.brands),
            '_id',
        );

        return brandsInFilteredProducts
            .map(({ _id, name }) => ({
                value: _id,
                label: name,
                key: _id,
            }))
            .sort(sortByString('label', 'asc'));
    }, [primaryFilter, brandOptions, products, applyCategoryFilters]);

    const visibleCategoryOptions = useMemo(() => {
        if (!primaryFilter || primaryFilter === 'categories') {
            return categoryOptions;
        }

        const filteredProducts = applyBrandFilters(products);

        const categoriesInFilteredProducts = uniqBy(
            filteredProducts.flatMap((row) => row.categories),
            '_id',
        );

        return categoriesInFilteredProducts
            .map(({ _id, name }) => ({
                value: _id,
                label: name,
                key: _id,
            }))
            .sort(sortByString('label', 'asc'));
    }, [primaryFilter, categoryOptions, products, applyBrandFilters]);

    const selectedBrandCountProps = useMemo(() => {
        const hiddenCount = brandOptions.length - visibleBrandOptions.length;

        const isOrAre = hiddenCount > 1 ? 'brands are' : 'brand is';
        const tooltipMessage = `${hiddenCount} ${isOrAre} hidden because there are other filters applied`;

        return {
            tooltipMessage: hiddenCount > 0 ? tooltipMessage : undefined,
            pluralUnitLabel: 'brands',
        };
    }, [brandOptions, visibleBrandOptions]);

    const selectedCategoryCountProps = useMemo(() => {
        const hiddenCount = categoryOptions.length - visibleCategoryOptions.length;

        const isOrAre = hiddenCount > 1 ? 'categories are' : 'category is';
        const tooltipMessage = `${hiddenCount} ${isOrAre} hidden because there are other filters applied`;

        return {
            tooltipMessage: hiddenCount > 0 ? tooltipMessage : undefined,
            pluralUnitLabel: 'categories',
        };
    }, [categoryOptions, visibleCategoryOptions]);

    return {
        updateBrandsFilter,
        updateCategoriesFilter,
        visibleBrandOptions,
        selectedBrandCountProps,
        visibleCategoryOptions,
        selectedCategoryCountProps,
    };
};

export type UseBrandCategoryFilters = ReturnType<typeof useBrandCategoryFilters>;
