import { useContext } from 'react';

import { IModalContext, ModalContext } from '@contexts/ModalContext';

export const useModal = (): IModalContext => {
    const context = useContext(ModalContext);

    if (!context) {
        // eslint-disable-next-line no-console
        console.error(`'useModal' must be used within <Modal />`);
    }

    return context;
};

export const withModal = (Component: any) => (props: any) => {
    const modal = useModal();

    return <Component {...modal} {...props} />;
};
