import { z } from 'zod';

export const MAX_GROUPS_PER_BRAND = 3;

export const MAX_TAGS_PER_GROUP = 15;

export const TAG_COLOR_OPTIONS = [
  '#A7B3C4', // $gray-40
  '#838E9E', // $gray-60
  '#62676E', // $gray-80
  '#20252B', // $gray-95
  '#73BBD4', // $cerulean-30
  '#07A0C3', // $cerulean-60
  '#007394', // $cerulean-70
  '#004868', // $cerulean-90
  '#75D1B1', // $aqua-30
  '#38AC89', // $aqua-60
  '#007455', // $aqua-80
  '#00331A', // $aqua-95
  '#FDBF69', // $buttercup-30
  '#F3A623', // $buttercup-50
  '#B26F00', // $buttercup-70
  '#753D00', // $buttercup-90
  '#F89574', // $burnt-sienna-30
  '#EA6940', // $burnt-sienna-50
  '#B43B17', // $burnt-sienna-70
  '#810200', // $burnt-sienna-90
] as const;

export const TAG_ASSISTANT_TAGS_DISABLED_MESSAGE =
  'You cannot add or modify tags when the Tag Assistant is enabled.';
export const TAG_ASSISTANT_GROUPS_DISABLED_MESSAGE =
  'You cannot add or modify tag groups when the Tag Assistant is enabled.';

export const TAG_ASSISTANT_LEARN_MORE_LINK =
  'https://help.sparkplug.app/en/articles/8042686-the-tag-assistant';

export type TagColorOption = typeof TAG_COLOR_OPTIONS[number];

export interface BrandProductTag {
  name: string;
  color: TagColorOption;
  _id: string;
  isAiTagged?: boolean;
}

export interface BrandProductTagGroup {
  _id: string;
  name: string;
  tags: BrandProductTag[];
}

export type ListBrandProductTagGroupsResponseBody = BrandProductTagGroup[];

export const removeProductTagSchema = z.object({
  productId: z.string(),
  tagGroupName: z.string(),
  tagName: z.string(),
  brandId: z.string(),
  retailerId: z.string(),
});

export type RemoveProductTagSchema = z.infer<typeof removeProductTagSchema>;
export type RemoveProductTagRequestBody = RemoveProductTagSchema;

export const bulkRemoveProductTagsSchema = z.object({
  productIds: z.string().array(),
  brandId: z.string(),
  retailerId: z.string(),
});
export type BulkRemoveProductTagsSchema = z.infer<typeof bulkRemoveProductTagsSchema>;
export type BulkRemoveProductTagsRequestBody = BulkRemoveProductTagsSchema;

export const assignProductTagSchema = z.object({
  productIds: z.string().array(),
  tagGroupName: z.string(),
  tagName: z.string(),
  brandId: z.string(),
  retailerId: z.string(),
});
export type AssignProductTagSchema = z.infer<typeof assignProductTagSchema>;

export type AssignProductTagRequestBody = AssignProductTagSchema;
