import { ReactElement, useEffect, useMemo, useState } from 'react';

import { DATE_DISPLAY_FORMAT } from '@constants/AppConstants';
import { ChartLegendPageSize } from '@constants/ChartConstants';
import { TablePagination as MaterialUITablePagination } from '@mui/material';
import moment from 'moment';

import { TChartDataMetric, TComparisonPeriodOption } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import { NoSalesData } from '@components/graphics';
import { Dot as DotIcon } from '@components/icons';
import FormattedMetricValue from '@components/layout/FormattedMetricValue';
import Skeleton from '@components/layout/Skeleton';

import {
    currencyFormatterFactory,
    numberFormatterFactory,
    percentFormatterFactory,
} from '@helpers/chartFormatters';
import { appendClasses } from '@helpers/ui';

import { IChartDataSettings, IChartLegendPaginationSettings } from '@app/types/ChartDataTypes';
import { IPosLocation } from '@app/types/PosTypes';

import SparkTimelineWidget from '../SparkTimelineWidget';
import TableChart from '../TableChart';
import NivoBarChart from '../nivo/NivoBarChart';
import NivoLineChart from '../nivo/NivoLineChart';

import './Chart.scss';

function formatRange(startDate: moment.MomentInput, endDate: moment.MomentInput) {
    const startDateFormatted = moment(startDate).format(DATE_DISPLAY_FORMAT);
    const endDateFormatted = moment(endDate).format(DATE_DISPLAY_FORMAT);

    return `${startDateFormatted} - ${endDateFormatted}`;
}

interface IChartTitleRange {
    startDate: string | Date;
    endDate: string | Date;
    color: string;
    label: string;
}

interface IChartTitleProps {
    className?: string;
    titleLabel: string;
    metric: TChartDataMetric;
    chartTitleLeft?: ReactElement;
    total: number;
    totalChip?: ReactElement;
    ranges?: IChartTitleRange[];
}

const ChartTitle = ({
    className,
    titleLabel,
    metric,
    chartTitleLeft,
    total,
    totalChip,
    ranges = [],
}: IChartTitleProps) => {
    const classNamesAppended = appendClasses([className, 'chart-title']);

    return (
        <div className={classNamesAppended}>
            {chartTitleLeft || (
                <div className="chart-title_left">
                    <span className="chart-title_total-title">{titleLabel}</span>
                    <span className="chart-title_total">
                        <FormattedMetricValue metric={metric} value={total} />
                        {totalChip}
                    </span>
                </div>
            )}
            {ranges.length > 0 && (
                <div className="chart-title_right">
                    <table>
                        <tbody>
                            {ranges.map(({ startDate, endDate, color, label }) => (
                                <tr key={`${color}-${label}`}>
                                    <td>{formatRange(startDate, endDate)}</td>
                                    <td>
                                        <DotIcon style={{ color }} />
                                    </td>
                                    <td>{label}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

type TLegendLabel = {
    color: string;
    label: string;
};

const ChartLegendButton = ({
    isHidden,
    color,
    label,
    disabled,
    onClick,
    onHover,
}: {
    isHidden: boolean;
    color: string;
    label: string;
    disabled: boolean;
    onClick: () => void;
    onHover: any;
}) => {
    const classNamesAppended = appendClasses([
        'chart-legend-btn',
        isHidden ? 'is-hidden' : undefined,
        disabled ? 'is-disabled' : undefined,
    ]);

    return (
        <div className="chart-legend-btn-container">
            <Button
                className={classNamesAppended}
                startIcon={<DotIcon style={{ color }} />}
                variant="flat"
                color="neutral"
                onClick={onClick}
                onHover={onHover}
            >
                <span className="legend-label">{label}</span>
            </Button>
        </div>
    );
};

const ChartLegendPagination = ({
    settings,
    count,
    onPageChange,
}: {
    settings: IChartLegendPaginationSettings;
    count: number;
    onPageChange: (i: number) => void;
}) => {
    const { pageSize, page: currentPage } = settings;

    const hasPages = count / pageSize > 1;

    if (!hasPages) {
        return <></>;
    }

    return (
        <div className="chart-legend-pagination-container">
            <MaterialUITablePagination
                hidden={false}
                component="div"
                labelRowsPerPage={null}
                rowsPerPageOptions={[pageSize]}
                count={count}
                rowsPerPage={pageSize}
                page={currentPage}
                onPageChange={(e, i) => onPageChange(i)}
            />
        </div>
    );
};

interface IChartProps {
    hideLegend?: boolean;
    showSkeleton?: boolean;
    showSparkTimeline?: boolean;
    isComparisonWindow?: boolean;
    chartData: any;
    chartSettings: IChartDataSettings;
    locations: IPosLocation[];
    xAxisLabels?: string[];
    externalLegendData?: TLegendLabel[];

    currentPeriodLabel?: string;
    showComparisonWindows?: boolean;
    comparisonPeriod?: TComparisonPeriodOption;
}
const Chart = ({
    hideLegend = false,
    showSkeleton,
    showSparkTimeline = false,
    isComparisonWindow,
    chartData,
    chartSettings,
    locations,
    xAxisLabels,
    externalLegendData,

    showComparisonWindows,
    comparisonPeriod,
}: IChartProps) => {
    const { type, metric, breakdown = 'none' } = chartSettings;

    const [hiddenChartIds, setHiddenChartIds] = useState<string[]>([]);
    const [focusedId, setFocusedId] = useState<string>();
    const [paginationSettings, setPaginationSettings] = useState({
        page: 0,
        pageSize: ChartLegendPageSize,
    });

    const [isValidBarChart, isValidLineChart] = useMemo(() => {
        return [
            type === 'bar' && Array.isArray(chartData?.keys),
            type === 'line' && Array.isArray(chartData),
        ];
    }, [type, chartData]);

    const locationsMap = useMemo(
        () =>
            locations.reduce(
                (res, location) => ({
                    ...res,
                    [location?.name]: location.label,
                }),
                {},
            ),
        [locations],
    );

    const allIds: string[] = useMemo(() => {
        if (isValidBarChart) {
            return chartData.keys;
        } else if (isValidLineChart) {
            return chartData.map(({ id }: { id: string }) => id);
        }

        return [];
    }, [isValidBarChart, isValidLineChart, chartData]);

    const visibleIds: string[] = useMemo(() => {
        const { page: currentPage, pageSize } = paginationSettings;

        const pageStart = currentPage * pageSize;

        const pageEnd = Math.min(pageStart + pageSize, allIds.length);

        return allIds.length > pageSize ? allIds.slice(pageStart, pageEnd) : allIds;
    }, [chartData, paginationSettings, isValidBarChart, isValidLineChart, allIds]);

    const legendData: TLegendLabel[] = useMemo(() => {
        if (isValidBarChart) {
            return !isComparisonWindow
                ? [...chartData.keys].map((label) => {
                      const color = chartData?.colors?.[label];

                      return {
                          color,
                          label,
                      };
                  })
                : [];
        }

        if (isValidLineChart) {
            return !isComparisonWindow
                ? chartData.map(({ id, color }: { id: string; color: string }) => {
                      return {
                          color,
                          label: id,
                      };
                  })
                : [];
        }

        return [];
    }, [isComparisonWindow, chartData, isValidBarChart, isValidLineChart]);

    const totalIsSum = ['total_sales', 'total_units', 'transaction_count'].includes(metric);
    const formatter = (() => {
        switch (metric) {
            case 'total_sales':
                return currencyFormatterFactory(0);
            case 'order_average':
                return currencyFormatterFactory(2);
            case 'units_per_transaction':
                return numberFormatterFactory(2);
            case 'percent_of_total_sales':
                return percentFormatterFactory(2);
            case 'total_units':
            case 'transaction_count':
            default:
                return numberFormatterFactory(0);
        }
    })();

    const toggleChartId = (id: string) => {
        setHiddenChartIds((prevIds) => {
            return prevIds.includes(id)
                ? prevIds.filter((prevId) => prevId !== id)
                : [...prevIds, id];
        });
    };

    // Changing the data for pagination causes the charts to lag, the
    // current workaround is to use the `rerender` flag to unmount the
    // charts and force a new rerender
    const [rerender, setRerender] = useState(false);
    useEffect(() => {
        setRerender(true);
        requestAnimationFrame(() => {
            setRerender(false);
        });
    }, [hiddenChartIds, breakdown]);

    const isEmptyChart = (() => {
        const isEmptyBarChart = type === 'bar' && !chartData?.data?.length;
        const isEmptyLineChart = type === 'line' && !chartData?.length;
        const isEmptyTableChart = type === 'table' && !chartData?.rows?.length;

        return isEmptyBarChart || isEmptyLineChart || isEmptyTableChart;
    })();

    const memoizedChart = useMemo(() => {
        if (showSkeleton) {
            return <Skeleton height={400} />;
        }

        if (isEmptyChart && !showComparisonWindows) {
            return (
                <div className="chart-message">
                    <NoSalesData />
                    <p>Sales data unavailable</p>
                </div>
            );
        }

        switch (type) {
            case 'line':
                return (
                    <NivoLineChart
                        data={chartData}
                        focusedId={focusedId}
                        hiddenIds={hiddenChartIds}
                        xAxisLabels={xAxisLabels}
                        visibleIds={visibleIds}
                        tooltipProps={{
                            isComparisonWindow,
                            breakdown,
                            totalIsSum,
                            formatter: formatter as any,
                            locationsMap,
                        }}
                    />
                );
            case 'bar':
                // Bar chart needs to unmount when transitioning `keys` in interactions
                // otherwise it lags in the `rerender` return
                return rerender ? (
                    <></>
                ) : (
                    <NivoBarChart
                        stacked={!isComparisonWindow}
                        data={chartData}
                        focusedId={focusedId}
                        hiddenIds={hiddenChartIds}
                        visibleIds={visibleIds}
                        tooltipProps={{
                            isComparisonWindow,
                            breakdown,
                            totalIsSum,
                            formatter,
                            locationsMap,
                        }}
                    />
                );
            case 'table':
                return (
                    <TableChart
                        breakdown={breakdown}
                        data={chartData}
                        locationCount={locations?.length}
                        metric={metric}
                    />
                );
            default:
                return <></>;
        }
    }, [
        rerender,
        showSkeleton,
        isEmptyChart,
        chartData,
        focusedId,
        hiddenChartIds,
        xAxisLabels,
        isComparisonWindow,
        breakdown,
        totalIsSum,
        formatter,
        locationsMap,
    ]);

    const classNamesAppended = appendClasses([
        'chart',
        `${type}-chart`,
        isEmptyChart ? 'is-empty' : 'is-not-empty',
    ]);

    const showLegend = !hideLegend && type !== 'table' && !showSkeleton && visibleIds.length > 0;
    const visibleLegendData =
        externalLegendData ?? legendData.filter(({ label }) => visibleIds.includes(label));
    const legendClassNamesAppended = appendClasses([
        'legend',
        visibleLegendData.length === 1 ? 'legend-content-centered' : '',
        allIds.length !== visibleIds.length ? 'has-pages' : '',
    ]);

    return (
        <div className={classNamesAppended}>
            <div className="chart-container">{memoizedChart}</div>

            <div className="chart-lower-container">
                {showLegend && visibleLegendData.length > 0 && (
                    <div className={legendClassNamesAppended}>
                        <div className="legend-inner">
                            <div className="legend-btn-container">
                                {visibleLegendData.map(({ color, label }, i) => (
                                    <ChartLegendButton
                                        key={`legend-${i}-${color}-${label}`}
                                        isHidden={hiddenChartIds.includes(label)}
                                        color={color}
                                        label={label}
                                        disabled={!!focusedId && focusedId !== label}
                                        onClick={() => toggleChartId(label)}
                                        onHover={
                                            !externalLegendData && allIds.length <= 15
                                                ? {
                                                      onEnter: () => setFocusedId(label),
                                                      onLeave: () => setFocusedId(undefined),
                                                  }
                                                : undefined
                                        }
                                    />
                                ))}
                            </div>
                            <ChartLegendPagination
                                count={allIds.length}
                                settings={paginationSettings}
                                onPageChange={(i) =>
                                    requestAnimationFrame(() => {
                                        setPaginationSettings((prev) => ({ ...prev, page: i }));
                                    })
                                }
                            />
                        </div>
                    </div>
                )}

                {showSparkTimeline && !showSkeleton && type !== 'table' && (
                    <SparkTimelineWidget
                        chartSettings={chartSettings}
                        comparisonPeriod={showComparisonWindows ? comparisonPeriod : undefined}
                    />
                )}
            </div>
        </div>
    );
};

Chart.Title = ChartTitle;

export default Chart;
