import { ReactNode } from 'react';

import { Popover, PopoverOrigin } from '@mui/material';

import { useDropdown } from './DropdownContext';

interface DropdownPopoverProps {
    className?: string;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    children: ReactNode;
}

const DropdownPopover = ({
    className = '',
    anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin = {
        vertical: 'top',
        horizontal: 'left',
    },
    children,
}: DropdownPopoverProps) => {
    const { anchorEl, handleClose } = useDropdown();

    return (
        <Popover
            className={className}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
        >
            {children}
        </Popover>
    );
};

export default DropdownPopover;
