import { FC } from 'react';

import SparkSnapIcon from '@features/spark-snaps/components/SparkSnapIcon';
import { useSnapViewQueue } from '@features/spark-snaps/hooks';

import ClickableArea from '@components/layout/ClickableArea';

import './ClickableSnapItem.scss';

export interface ClickableSnapItemProps {
    snapId: number;
    onView: (snapId: number) => void | ReturnType<typeof useSnapViewQueue>['handleViewSnap'];
    showFlashing?: boolean;
    snapThumbnail?: string;
    snapName?: string;
    viewed?: boolean;
}

const ClickableSnapItem: FC<ClickableSnapItemProps> = ({
    snapId,
    onView,
    snapThumbnail,
    snapName,
    showFlashing = false,
    viewed,
}: ClickableSnapItemProps) => {
    return (
        <ClickableArea
            data-testid={`view-snap-button_${snapId}`}
            onClick={() => onView(snapId)}
            className="view-snap-button"
        >
            <SparkSnapIcon
                viewed={viewed}
                imgUrl={snapThumbnail}
                title={snapName || ''}
                variant={showFlashing ? 'flashy' : 'default'}
            />
        </ClickableArea>
    );
};

export default ClickableSnapItem;
