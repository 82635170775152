import { z } from 'zod';
import { PosConfigType } from './types';

/**
 * This schema is set to strict because the required fields overlap with many other schemas, and we definitely don't want to use any interchangeably.
 */
const alleavesCredentialsSchema = z
  .object({
    username: z.string().min(1),
    password: z.string().min(1),
    token: z.string().min(1).optional(),
  })
  .strict();

export type AlleavesCredentials = z.infer<typeof alleavesCredentialsSchema>;

const biotrackCredentialsSchema = z.object({
  username: z.string().min(1),
  password: z.string().min(1),
  host: z.string().min(1),
  ca: z.string().optional(),
  key: z.string().optional(),
  cert: z.string().optional(),
});

export type BiotrackCredentials = z.infer<typeof biotrackCredentialsSchema>;

export const blazeLocationSchema = z.object({
  apiKey: z.string().min(1),
});

export type BlazeLocation = z.infer<typeof blazeLocationSchema>;

export const blazeCredentialsSchema = z.object({
  partnerKey: z.string().min(1),
  locations: z.array(blazeLocationSchema),
});

export type BlazeCredentials = z.infer<typeof blazeCredentialsSchema>;

export const covaLocationSchema = z
  .object({
    externalId: z.string().min(1),
    useInventoryPipeline: z.boolean().optional(),
  })
  .strict();

export const covaCredentialsSchema = z.object({
  username: z.string().min(1),
  password: z.string().min(1),
  clientId: z.string().min(1),
  clientSecret: z.string().min(1),
  companyId: z.string().min(1),
  useNetSales: z.boolean().optional(),
  adjustBulkSales: z.boolean().optional(),
  locationFilter: z.array(covaLocationSchema).optional(),
});

export type CovaCredentials = z.infer<typeof covaCredentialsSchema>;

export const flowhubLocationSchema = z
  .object({
    externalId: z.string().min(1),
    name: z.string().min(1),
    useInventoryPipeline: z.boolean().optional(),
  })
  .strict();

export const flowhubCredentialsSchema = z.object({
  apiKey: z.string().min(1),
  clientId: z.string().min(1),
  locations: z.array(flowhubLocationSchema).optional(),
});

export type FlowhubCredentials = z.infer<typeof flowhubCredentialsSchema>;

export const flourishCredentialsSchema = z.object({
  apiKey: z.string().min(1),
  username: z.string().min(1),
});

export type FlourishCredentials = z.infer<typeof flourishCredentialsSchema>;

export const greenbitsCredentialsSchema = z.object({
  username: z.string().min(1),
  password: z.string().min(1),
});

export type GreenbitsCredentials = z.infer<typeof greenbitsCredentialsSchema>;

export const growFlowCredentialsSchema = z.object({
  vendorKey: z.string().min(1),
});

export type GrowFlowCredentials = z.infer<typeof growFlowCredentialsSchema>;

/**
 * This schema is set to strict because the required fields overlap with many other schemas, and we definitely don't want to use any interchangeably.
 */
const goTabCredentialsSchema = z
  .object({
    accessId: z.string().min(1),
    accessSecret: z.string().min(1),
    refreshToken: z.string().optional(),
  })
  .strict();

export type GoTabCredentials = z.infer<typeof goTabCredentialsSchema>;

export const iHeartJaneSchema = z.object({
  name: z.string().min(1),
  apiKey: z.string().min(1),
});

export type IHeartJaneLocation = z.infer<typeof iHeartJaneSchema>;
/**
 * This schema is set to strict because the required fields overlap with the
 * treezCredentialsSchema, and we definitely don't want to use the two interchangeably.
 */
export const iHeartJaneCredentialsSchema = z
  .object({
    locations: z.array(iHeartJaneSchema),
  })
  .strict();

export type IHeartJaneCredentials = z.infer<typeof iHeartJaneCredentialsSchema>;

/**
 * This schema is set to strict because the required fields overlap and we definitely don't want to use the two interchangeably.
 */
const indicaOnlineLocationsSchema = z.object({
  apiKey: z.string().min(1),
  name: z.string().min(1),
  officeId: z.string().min(1),
});
export const indicaOnlineCredentialsSchema = z
  .object({
    locations: z.array(indicaOnlineLocationsSchema),
  })
  .strict();

export type IndicaOnlineCredentials = z.infer<typeof indicaOnlineCredentialsSchema>;

/**
 * This schema is set to strict because the required fields overlap with the
 * treezLocationSchema, and we definitely don't want to use the two interchangeably.
 */
export const leafLogixLocationSchema = z
  .object({
    apiKey: z.string().min(1),
    name: z.string().min(1),
    outTheDoorPricing: z.boolean().optional(),
    funnelPreOrders: z.boolean().optional(),
    preOrderPosEmployeeProfileId: z.nullable(z.string()).optional(),
    useInventoryPipeline: z.boolean().optional(),
  })
  .strict();

export type LeafLogixLocation = z.infer<typeof leafLogixLocationSchema>;

/**
 * This schema is set to strict because the required fields overlap with the
 * treezCredentialsSchema, and we definitely don't want to use the two interchangeably.
 */
export const leafLogixCredentialsSchema = z
  .object({
    locations: z.array(leafLogixLocationSchema),
  })
  .strict();

export type LeafLogixCredentials = z.infer<typeof leafLogixCredentialsSchema>;

const meadowLocationsSchema = z
  .object({
    name: z.string().min(1),
    clientKey: z.string().min(1),
    funnelPreOrders: z.boolean().optional(),
    preOrderPosEmployeeProfileId: z.string().optional(),
  })
  .strict();

/**
 * This schema is set to strict because the required fields overlap with many other schemas, and we definitely don't want to use any interchangeably.
 */
const lightspeedCredentialsSchema = z
  .object({
    accountId: z.string().min(1),
    refreshToken: z.string().min(1),
  })
  .strict();

export type LightspeedCredentials = z.infer<typeof lightspeedCredentialsSchema>;

export type MeadowLocation = z.infer<typeof meadowLocationsSchema>;

export const meadowCredentialsSchema = z
  .object({
    locations: z.array(meadowLocationsSchema),
  })
  .strict();

export type MeadowCredentials = z.infer<typeof meadowCredentialsSchema>;

export const mjFreewayCredentialsSchema = z.object({
  apiKey: z.string().min(1),
  orgId: z.string().min(1),
  userId: z.string().min(1),
});

export type MJFreewayCredentials = z.infer<typeof mjFreewayCredentialsSchema>;

const portal42LocationsSchema = z.object({
  name: z.string().min(1),
  externalId: z.string().min(1),
});

export type Portal42Location = z.infer<typeof portal42LocationsSchema>;

export const portal42CredentialsSchema = z
  .object({
    vendorApiKey: z.string().min(1),
    locations: z.array(portal42LocationsSchema),
  })
  .strict();

export type Portal42Credentials = z.infer<typeof portal42CredentialsSchema>;

const posabitLocationsSchema = z
  .object({
    name: z.string().min(1),
    apiKey: z.string().min(1),
  })
  .strict();

export type PosabitLocation = z.infer<typeof posabitLocationsSchema>;

export const posabitCredentialsSchema = z
  .object({
    locations: z.array(posabitLocationsSchema),
  })
  .strict();

export type PosabitCredentials = z.infer<typeof posabitCredentialsSchema>;

export const revelCredentialsSchema = z
  .object({
    key: z.string().min(1),
    secret: z.string().min(1),
    subdomain: z.string().min(1),
  })
  .strict();

export type RevelCredentials = z.infer<typeof revelCredentialsSchema>;

/**
 * This schema is set to strict because the required fields overlap with many other schemas, and we definitely don't want to use any interchangeably.
 */
const rPowerCredentialsSchema = z
  .object({
    apiKey: z.string().min(1),
  })
  .strict();

export type RPowerCredentials = z.infer<typeof rPowerCredentialsSchema>;

export const shopifyShopCredentialsSchema = z.object({
  shop: z.string().min(1),
  encrypted: z.string().min(1),
});

export type ShopifyShopCredentials = z.infer<typeof shopifyShopCredentialsSchema>;

export const squareMerchantCredentialsSchema = z.object({
  encrypted: z.string().min(1),
  merchantId: z.string().min(1),
  isDisconnected: z.boolean(),
  comboPermutations: z.boolean().optional(),
});

export type SquareMerchantCredentials = z.infer<typeof squareMerchantCredentialsSchema>;

export const toastRestaurantSchema = z.object({
  name: z.string().min(1),
  restaurantGuid: z.string().min(1),
  nonDineInPosEmployeeProfileId: z.string().optional(),
  funnelNonDineInOrders: z.boolean().optional(),
});

export const sweedLocationSchema = z
  .object({
    id: z.string().min(1),
    name: z.string().min(1),
    useSqlApi: z.boolean().optional(),
    pgHost: z.string().min(1).optional(),
    pgUsername: z.string().min(1).optional(),
    pgPassword: z.string().min(1).optional(),
  })
  .strict();

export type SweedLocation = z.infer<typeof sweedLocationSchema>;

/**
 * This schema is set to strict because the required fields overlap with the
 * treezCredentialsSchema, and we definitely don't want to use the two interchangeably.
 */
export const sweedCredentialsSchema = z
  .object({
    locations: z.array(sweedLocationSchema),
  })
  .strict();

export type SweedCredentials = z.infer<typeof sweedCredentialsSchema>;

export type ToastRestaurant = z.infer<typeof toastRestaurantSchema>;

export const toastCredentialsSchema = z.object({
  locations: z.array(toastRestaurantSchema),
  condadoModifiers: z.boolean().optional(),
  ballardsModifiers: z.boolean().optional(),
  masonsModifiers: z.boolean().optional(),
  bareburgerModifiers: z.boolean().optional(),
  crossroadsModifiers: z.boolean().optional(),
});

export type ToastCredentials = z.infer<typeof toastCredentialsSchema>;

/**
 * This schema is set to strict because this overlaps with the
 * leafLogixLocationSchema, and we definitely don't want to use the two interchangeably.
 */
export const treezLocationSchema = z
  .object({
    id: z.string().min(1),
    apiKey: z.string().min(1),
    name: z.string().min(1),
    mapping: z.any().optional(),
    funnelPreOrders: z.boolean().optional(),
    preOrderPosEmployeeProfileId: z.string().optional(),
    useInventoryPipeline: z.boolean().optional(),
  })
  .strict();

export type TreezLocation = z.infer<typeof treezLocationSchema>;

/**
 * This schema is set to strict because this overlaps with the
 * leafLogixCredentialsSchema, and we definitely don't want to use the two interchangeably.
 */
export const treezCredentialsSchema = z
  .object({
    locations: z.array(treezLocationSchema),
    filterSupurb: z.boolean().default(false),
  })
  .strict();

export type TreezCredentials = z.infer<typeof treezCredentialsSchema>;

export const vendCredentialsSchema = z.object({
  personalToken: z.string().min(1),
  domainPrefix: z.string().min(1),
});

export type VendCredentials = z.infer<typeof vendCredentialsSchema>;

/**
 * This schema is set to strict because merchantId is used in other schemas.
 */
export const cloverOnlineLocationSchema = z
  .object({
    apiToken: z.string().min(1),
    apiTokenExpiration: z.number().min(1),
    refreshToken: z.string().min(1),
    refreshTokenExpiration: z.number().min(1),
    merchantId: z.string().min(1),
    region: z.enum(['us', 'eu', 'la']),
  })
  .strict();

export const cloverCredentialsSchema = z
  .object({
    locations: z.array(cloverOnlineLocationSchema),
  })
  .strict();

export type CloverLocationCredentials = z.infer<typeof cloverOnlineLocationSchema>;
export type CloverCredentials = z.infer<typeof cloverCredentialsSchema>;

export const posConfigCredentialsSchema = z.union([
  alleavesCredentialsSchema,
  biotrackCredentialsSchema,
  blazeCredentialsSchema,
  cloverCredentialsSchema,
  covaCredentialsSchema,
  flourishCredentialsSchema,
  flowhubCredentialsSchema,
  goTabCredentialsSchema,
  greenbitsCredentialsSchema,
  growFlowCredentialsSchema,
  indicaOnlineCredentialsSchema,
  leafLogixCredentialsSchema,
  lightspeedCredentialsSchema,
  meadowCredentialsSchema,
  mjFreewayCredentialsSchema,
  portal42CredentialsSchema,
  posabitCredentialsSchema,
  revelCredentialsSchema,
  rPowerCredentialsSchema,
  shopifyShopCredentialsSchema,
  squareMerchantCredentialsSchema,
  sweedCredentialsSchema,
  toastCredentialsSchema,
  treezCredentialsSchema,
  vendCredentialsSchema,
]);

export type PosConfigCredentials = z.infer<typeof posConfigCredentialsSchema>;

export const posToSchemaMap: Record<PosConfigType, z.ZodSchema> = {
  alleaves: alleavesCredentialsSchema,
  biotrack: biotrackCredentialsSchema,
  blaze: blazeCredentialsSchema,
  clover: cloverCredentialsSchema,
  cova: covaCredentialsSchema,
  flourish: flourishCredentialsSchema,
  flowhub: flowhubCredentialsSchema,
  gotab: goTabCredentialsSchema,
  greenbits: greenbitsCredentialsSchema,
  growflow: growFlowCredentialsSchema,
  iheartjane: iHeartJaneCredentialsSchema,
  indicaonline: indicaOnlineCredentialsSchema,
  leaflogix: leafLogixCredentialsSchema,
  lightspeed: lightspeedCredentialsSchema,
  mjfreeway: mjFreewayCredentialsSchema,
  meadow: meadowCredentialsSchema,
  portal42: portal42CredentialsSchema,
  posabit: posabitCredentialsSchema,
  revel: revelCredentialsSchema,
  rpower: rPowerCredentialsSchema,
  square: squareMerchantCredentialsSchema,
  shopify: shopifyShopCredentialsSchema,
  sweed: sweedCredentialsSchema,
  treez: treezCredentialsSchema,
  toast: toastCredentialsSchema,
  vend: vendCredentialsSchema,
};
