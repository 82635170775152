import { useState } from 'react';

import { UIEvent } from '@sparkplug/lib';

import EventTable from '@features/events/components/EventTable';

import CalloutMessage from '@components/layout/CalloutMessage';
import { useLocation } from '@components/router';

import ApprovalModal from '../../ApproveEvent/ApprovalModal';

export const RetailerScheduledEvents = () => {
    const location = useLocation<{ event?: UIEvent }>();
    const event = location.state?.event;

    const [isApprovalModalVisible, setIsApprovalModalVisible] = useState(!!event);

    const handleApprovalModalClose = () => {
        setIsApprovalModalVisible(false);
    };

    return (
        <div data-testid="retailer-scheduled-events" className="flex flex-col gap-4">
            <CalloutMessage message="Manage pop-up, promos, trainings and other events with your Brand partners to keep employees informed and engaged." />
            <EventTable approvalInProgress={isApprovalModalVisible} />
            {event && (
                <ApprovalModal
                    event={event}
                    isVisible={isApprovalModalVisible}
                    onClose={handleApprovalModalClose}
                />
            )}
        </div>
    );
};
