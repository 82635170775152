import { ComponentProps } from 'react';

import clsx from 'clsx';

import { UIRequestForSpark } from '@sparkplug/lib';

import CalloutMessage from '@components/layout/CalloutMessage';

import './RequestDetailsCallout.scss';

export interface RequestDetailsCalloutProps {
    requestForSpark: Pick<UIRequestForSpark, 'sourceAccountName' | 'message'>;
    sx?: ComponentProps<typeof CalloutMessage>['sx'];
}
export const RequestDetailsCallout = ({
    requestForSpark: { sourceAccountName, message = '' },
    sx,
}: RequestDetailsCalloutProps) => {
    return (
        <CalloutMessage
            sx={{ width: '100%', ...sx }}
            className={clsx('request-details-callout')}
            title="Request Details"
            message={
                <>
                    <strong>{sourceAccountName}</strong> requested a Spark
                    {message?.length ? `: "${message.trim()}"` : ''}
                </>
            }
        />
    );
};
