import { Component, FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
    DateRangePicker as AirBnBDateRangePicker,
    CalendarDay,
    CalendarDayShape,
    DateRangePickerShape,
    FocusedInputShape,
    OpenDirectionShape,
} from 'react-dates';

import { DATE_DISPLAY_FORMAT } from '@constants/AppConstants';
import { FormControl } from '@mui/material';
import moment, { Moment, MomentInput } from 'moment';

import { InfoLetterIcon } from '@components/icons';
import InputLabel from '@components/inputs/InputLabel';
import TextField from '@components/inputs/TextField';

import { useResize } from '@hooks/UIHooks';

import { appendClasses, uuid } from '@helpers/ui';

import './DateRangePicker.scss';

interface CustomCalendarDayProps extends CalendarDayShape {
    appendDayModifiers?: (modifiers: Set<string>, date: Moment | null | undefined) => Set<string>;
}

const CustomCalendarDay: FC<CustomCalendarDayProps> = ({
    modifiers: initialModifiers = new Set(),
    appendDayModifiers,
    ...props
}) => {
    const ref = useRef<Component<CalendarDayShape>>(null);
    const { day: date } = props;

    const modifiers = appendDayModifiers
        ? appendDayModifiers(initialModifiers, date)
        : initialModifiers;

    return <CalendarDay ref={ref} modifiers={modifiers} {...props} />;
};

const renderCalendarDay =
    ({
        appendDayModifiers,
    }: {
        appendDayModifiers?: (
            modifiers: Set<string>,
            date: Moment | null | undefined,
        ) => Set<string>;
    }): FC<CalendarDayShape> =>
    (props) =>
        <CustomCalendarDay appendDayModifiers={appendDayModifiers} {...props} />;

export interface DateRangePickerProps
    extends Pick<
        DateRangePickerShape,
        | 'anchorDirection'
        | 'openDirection'
        | 'disabled'
        | 'startDateOffset'
        | 'endDateOffset'
        | 'isDayBlocked'
        | 'isOutsideRange'
        | 'enableOutsideDays'
        | 'renderDayContents'
        | 'maxDate'
        | 'minimumNights'
        | 'isDayHighlighted'
    > {
    id: string;
    className?: string;
    pickerClassName?: string;
    appendDayModifiers?: (modifiers: Set<string>, date: Moment | null | undefined) => Set<string>;
    color?: 'neutral' | 'blue' | 'green' | 'red';
    variant?: 'flat' | 'filled' | 'raised' | 'smooth' | 'outlined';
    label?: string;
    dateStart: MomentInput | null;
    dateEnd: MomentInput | null;
    required?: boolean;
    onApply: (startDate: Moment | null, endDate: Moment | null) => void;
    calendarInfo?: string;
}

const DateRangePicker: FC<DateRangePickerProps> = ({
    id,
    className,
    pickerClassName,
    appendDayModifiers,
    required = false,
    label = '',
    dateStart: controlledStartDate,
    dateEnd: controlledEndDate,
    onApply,
    variant = 'raised',
    color = 'blue',
    isDayBlocked = () => false,
    isOutsideRange = () => false,
    calendarInfo,
    anchorDirection,
    openDirection,
    disabled: disabledField,
    startDateOffset,
    endDateOffset,
    enableOutsideDays,
    renderDayContents,
    maxDate,
    minimumNights = 0,
    isDayHighlighted,
}) => {
    const classes = appendClasses([
        'form-control',
        'form-control-custom',
        'date-range-picker',
        color ? `date-range-picker-color-${color}` : null,
        variant ? `date-range-picker-variant-${variant}` : null,
        className,
    ]);

    const ref = useRef<any>();
    const formRef = useRef<any>();
    const [didClose, setDidClose] = useState(false);
    const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
    const [dateRange, setDateRange] = useState<{
        start: Moment | null;
        end: Moment | null;
    }>({ start: null, end: null });
    const [datePickerVerticalDirection, setDatePickerVerticalDirection] = useState(openDirection);

    useEffect(() => {
        if (controlledStartDate) {
            const start = moment.isMoment(controlledStartDate)
                ? controlledStartDate
                : moment(controlledStartDate);
            setDateRange((prevRange) => ({
                ...prevRange,
                start,
            }));
        }
        if (!controlledStartDate && disabledField === 'endDate') {
            setDateRange({
                start: null,
                end: null,
            });
        }
    }, [controlledStartDate]);

    useEffect(() => {
        if (controlledEndDate) {
            const end = moment.isMoment(controlledEndDate)
                ? controlledEndDate
                : moment(controlledEndDate);
            setDateRange((prevRange) => ({
                ...prevRange,
                end,
            }));
        }
    }, [controlledEndDate]);

    const determineCalendarPlacement = () => {
        /*
            https://github.com/react-dates/react-dates/issues/1479: If the datepicker overflows the screen vertically, it will not scroll, making anything off screen unusable.  
            
            If we wait for the datepicker element to be on screen to handle this case, we get a pretty wonky UX - and sometimes causes the datepicker to disappear completely. 
            
            Instead, we check the parent input element and if it's too close to the bottom, switch to open the datepicker "up".

            Note: the datepicker is about 300px in height - the extra 25px are a buffer for a nicer UI
        */
        let dir: OpenDirectionShape = 'down';
        if (formRef.current?.getBoundingClientRect) {
            const { bottom } = formRef.current.getBoundingClientRect();
            if (bottom + 325 >= window.innerHeight) {
                dir = 'up';
            }
        }
        setDatePickerVerticalDirection(dir);
    };

    useResize(determineCalendarPlacement);

    useLayoutEffect(() => {
        determineCalendarPlacement(); // for initial load

        if (ref.current.dayPickerContainer && focusedInput) {
            ref.current.dayPickerContainer.classList.add(pickerClassName);
        }
    }, [pickerClassName, focusedInput]);

    useEffect(() => {
        if (didClose) {
            setDidClose(false);

            onApply(dateRange.start, dateRange.end);
        }
    }, [didClose, dateRange]);

    const props: Partial<DateRangePickerShape> = {
        disabled: disabledField,
        anchorDirection,
        enableOutsideDays,
        isDayBlocked,
        isOutsideRange,
        startDateOffset,
        endDateOffset,
        renderDayContents,
        renderCalendarDay: renderCalendarDay({ appendDayModifiers }),
        maxDate,
        minimumNights,
        isDayHighlighted,
    };

    return (
        <FormControl ref={formRef} className={classes} data-chromatic="ignore">
            {label && (
                <InputLabel shrink required={required}>
                    {label}
                </InputLabel>
            )}
            <AirBnBDateRangePicker
                ref={ref}
                startDate={dateRange.start}
                startDateId={`${id}-startDate`}
                endDate={dateRange.end}
                endDateId={`${id}-endDate`}
                onDatesChange={({ startDate, endDate }) => {
                    setDateRange({
                        start: startDate != null ? startDate.startOf('day') : null,
                        end: endDate != null ? endDate.endOf('day') : null,
                    });
                }}
                focusedInput={focusedInput}
                onFocusChange={setFocusedInput}
                onClose={() => {
                    setDidClose(true);
                }}
                hideKeyboardShortcutsPanel
                appendToBody
                displayFormat={DATE_DISPLAY_FORMAT}
                calendarInfoPosition={calendarInfo ? 'top' : undefined}
                renderCalendarInfo={
                    calendarInfo
                        ? () => (
                              <div className="date-range-picker_calendar-info">
                                  <InfoLetterIcon />
                                  <span>{calendarInfo}</span>
                              </div>
                          )
                        : undefined
                }
                openDirection={datePickerVerticalDirection}
                maxDate={maxDate}
                {...props}
            />
        </FormControl>
    );
};

export const MockDateRangePicker: FC<DateRangePickerProps> = ({ dateStart, dateEnd, onApply }) => {
    return (
        <>
            <TextField
                name={uuid()}
                value={dateStart as any}
                placeholder="Start Date"
                onChange={(event) => {
                    onApply(
                        moment(event.target.value),
                        moment.isMoment(dateEnd) ? dateEnd : moment(dateEnd),
                    );
                }}
            />
            <TextField
                name={uuid()}
                value={dateEnd as any}
                placeholder="End Date"
                onChange={(event) => {
                    onApply(
                        moment.isMoment(dateStart) ? dateStart : moment(dateStart),
                        moment(event.target.value),
                    );
                }}
            />
        </>
    );
};

export default DateRangePicker;
