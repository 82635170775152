import {
    FC,
    ReactElement,
    ReactNode,
    cloneElement,
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';

import { SparkRequestState, SparkType } from '@sparkplug/lib';

import { UIRequestForSparkWithAccountLink } from '@features/request-for-spark/types';
import { SparkWizardOriginRoute } from '@features/spark-wizard/views/SparkWizardView/SparkWizardView';

import ConfirmModal from '@components/overlays/ConfirmModal';
import DeleteSparkModal from '@components/overlays/DeleteSparkModal';
import SparkApprovalModal from '@components/overlays/SparkApprovalModal';
import { useLocation } from '@components/router';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';
import { useRouteTabs } from '@hooks/UIHooks';

import Intercom from '@helpers/Intercom';

import { ClonedSparkFields, SparkCreateEditAction } from '@app/types/SparksTypes';

import { useSparks } from './SparksContext';

export type TSparkManagementModalType = SparkCreateEditAction | 'delete' | 'approval';

export type TCloseSparkManagementModal = (doActionOnClose: boolean, additionalData?: any) => any;

interface SparkModalProps {
    sparkId?: string;
    sparkType?: SparkType;
    disableSparkActions?: boolean;
    onClose?: TCloseSparkManagementModal;
    clonedSparkFields?: ClonedSparkFields;
    requestState?: SparkRequestState;
    initialStage?: number;
    originRoute?: SparkWizardOriginRoute;
}

export type TOpenSparkManagementModal = (
    modalType: TSparkManagementModalType,
    modalProps?: SparkModalProps,
) => any;

const SparkManagementModalContext = createContext<{
    /**
     * @deprecated
     *
     * This function should not be exposed outside of this context. The action-specific
     * functions should be used instead.
     *
     */
    openSparkManagementModal: TOpenSparkManagementModal;
    openCreateModal: (type: SparkType, originRoute: SparkWizardOriginRoute) => void;
    openEditModal: (
        sparkId: string,
        originRoute: SparkWizardOriginRoute,
        actionNeedsCsApproval?: boolean,
    ) => void;
    openCloneModal: (
        clonedSparkFields: ClonedSparkFields,
        originRoute: SparkWizardOriginRoute,
        clonedSparkId: string,
    ) => void;
    openDeleteModal: (sparkId: string, actionNeedsCsApproval?: boolean) => void;
    openApprovalModal: (sparkId: string) => void;
    closeSparkManagementModal: TCloseSparkManagementModal;
}>({
    openSparkManagementModal: () => {},
    openCreateModal: () => {},
    openEditModal: () => {},
    openCloneModal: () => {},
    openDeleteModal: () => {},
    openApprovalModal: () => {},
    closeSparkManagementModal: () => {},
});

/**
 * The "accept retailer request and create a Spark" flow passes the requestForSpark object through the router's location state.
 * We pick that object up here and pass it along to the SparkWizard when creating a Spark from a request.
 * @returns The requestForSpark object stored in location state.
 */
export const useGetRequestForSparkFromRouter = () => {
    const location = useLocation();
    return (location.state as { requestForSpark?: UIRequestForSparkWithAccountLink })
        ?.requestForSpark;
};

export const SparkManagementModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [visibleModal, setVisibleModal] = useState<TSparkManagementModalType | 'none'>('none');
    const [sparkId, setSparkId] = useState<string>();
    const [sparkType, setSparkType] = useState<SparkType>();
    const [clonedSparkFields, setClonedSparkFields] = useState<ClonedSparkFields>();
    const [initialStage, setInitialStage] = useState<number>();

    const [onCloseFn, setOnCloseFn] = useState<TCloseSparkManagementModal>();
    const [confirmModalProps, setConfirmModalProps] = useState<any>({
        isVisible: false,
        title: '',
        message: '',
    });

    const { account, isFullScreenWizard } = useSparkplugAccount();
    const { refetchSparkData = () => {}, spark } = useSpark();
    const { refetchSparks } = useSparks();
    const { history } = useApp();
    const { currentPath } = useRouteTabs();
    const requestForSpark = useGetRequestForSparkFromRouter();

    const isSparkDetailsView = currentPath.includes('/sparks/:sparkId');

    const showDisabledActionModal = (modalType: TSparkManagementModalType) => {
        setConfirmModalProps({
            isVisible: true,
            title: `Are you sure you want to ${modalType} this Spark?`,
            message: `This will require confirmation from the ${
                account?.type === 'retailer' ? 'brand' : 'retailer'
            } and you will be prompted to share specific details with the SparkPlug Team on why this Spark is being ${
                modalType === 'edit' ? 'edited' : 'deleted'
            }.`,
        });
    };

    // Usage: `modalProps.onClose` should only be set when you want to override `runDefaultOnClose`
    const openSparkManagementModal: TOpenSparkManagementModal = useCallback(
        (modalType, modalProps) => {
            // in spark detail view, we'll have the spark directly available, in the spark list/grid view, the caller will provide the related spark's requestState
            const requestState = spark?.requestState || modalProps?.requestState;
            const isDisabledRetailerAction =
                account?.type === 'retailer' && ['edit', 'delete'].includes(modalType);
            const isDisabledBrandAction =
                account?.type === 'brand' &&
                (modalType === 'delete' || (modalType === 'edit' && requestState !== 'pending'));
            const isDisabledAction = isDisabledRetailerAction || isDisabledBrandAction;
            if (isFullScreenWizard) {
                if (modalType === 'clone' && modalProps?.clonedSparkFields?.type) {
                    history.push(
                        `/${account?._id}/sparks/create/${modalProps?.clonedSparkFields?.type}`,
                        {
                            state: {
                                originRoute: modalProps?.originRoute,
                                clonedSparkId: modalProps?.sparkId,
                                clonedSparkFields: modalProps?.clonedSparkFields,
                            },
                        },
                    );
                    return;
                }

                if (modalType === 'create') {
                    const params = new URLSearchParams(window.location.search);
                    const queryString = params.toString() ? `?${params.toString()}` : '';
                    history.push(
                        `/${account?._id}/sparks/create/${modalProps?.sparkType}${queryString}`,
                        {
                            state: {
                                originRoute: modalProps?.originRoute,
                                /** requestForSpark should be present when creating a spark from a request */
                                requestForSpark,
                            },
                        },
                    );
                    return;
                }
                if (modalType === 'edit') {
                    history.push(`/${account?._id}/sparks/${modalProps?.sparkId}/edit`, {
                        state: { originRoute: modalProps?.originRoute },
                    });
                    return;
                }
            }

            if (modalProps?.disableSparkActions && isDisabledAction) {
                showDisabledActionModal(modalType);
            } else {
                setSparkId(modalProps?.sparkId);
                setSparkType(modalProps?.sparkType);
                setClonedSparkFields(modalProps?.clonedSparkFields);
                setInitialStage(modalProps?.initialStage);
                setOnCloseFn(() => modalProps?.onClose || null);
                setVisibleModal(modalType);
            }
        },
        [account, spark],
    );

    const openCreateModal = (createSparkType: SparkType, originRoute: SparkWizardOriginRoute) => {
        openSparkManagementModal('create', {
            sparkType: createSparkType,
            sparkId: undefined,
            originRoute,
        });
    };

    const openEditModal = (
        editSparkId: string,
        originRoute: SparkWizardOriginRoute,
        actionNeedsCsApproval?: boolean,
    ) => {
        if (actionNeedsCsApproval) {
            showDisabledActionModal('edit');
        } else {
            openSparkManagementModal('edit', {
                sparkId: editSparkId,
                sparkType: undefined,
                originRoute,
            });
        }
    };

    const openCloneModal = (
        params: ClonedSparkFields,
        originRoute: SparkWizardOriginRoute,
        clonedSparkId: string,
    ) => {
        openSparkManagementModal('clone', {
            clonedSparkFields: params,
            originRoute,
            sparkId: clonedSparkId,
        });
    };

    const openDeleteModal = (deleteSparkId: string, actionNeedsCsApproval?: boolean) => {
        if (actionNeedsCsApproval) {
            showDisabledActionModal('delete');
        } else {
            openSparkManagementModal('delete', { sparkId: deleteSparkId });
        }
    };

    const openApprovalModal = (approvalSparkId: string) => {
        openSparkManagementModal('approval', { sparkId: approvalSparkId });
    };

    // Invoked when an `onClose` function hasn't been provided via openSparkManagementModal's `modalProps`.
    // `requestAnimationFrame` is used here to help create smoother transitions onClose.
    const runDefaultOnClose: TCloseSparkManagementModal = useCallback(
        (sparkModalActionWorked, additionalData) => {
            const onApprovalModalClose = () => {
                requestAnimationFrame(() => {
                    if (sparkModalActionWorked && additionalData?.isAccepted) {
                        refetchSparks();
                        history.push(`/${account?._id}/sparks/upcoming`);
                    } else {
                        history.push(`/${account?._id}/sparks/inbox`);
                    }
                });
            };

            const onCreateCloneModalClose = () => {
                requestAnimationFrame(() => {
                    if (sparkModalActionWorked) {
                        if (account?.type === 'brand') {
                            history.push(`/${account._id}/sparks/sent`);
                        } else {
                            history.push(`/${account?._id}/sparks`);
                        }
                        refetchSparks();
                    } else {
                        history.push(`/${account?._id}/sparks`);
                    }
                });
            };

            const onEditModalClose = async () => {
                if (sparkModalActionWorked) {
                    await refetchSparks();
                    await refetchSparkData();
                }
            };

            const onDeleteModalClose = () => {
                if (sparkModalActionWorked) {
                    requestAnimationFrame(() => {
                        if (isSparkDetailsView) {
                            history.push(`/${account?._id}/sparks`);
                        }
                        refetchSparks();
                    });
                }
            };

            switch (visibleModal) {
                case 'approval':
                    return onApprovalModalClose();
                case 'create':
                case 'clone':
                    return onCreateCloneModalClose();
                case 'edit':
                    return onEditModalClose();
                case 'delete':
                    return onDeleteModalClose();
                default:
                    return null;
            }
        },
        [visibleModal, isSparkDetailsView, refetchSparks, account?._id, history.push],
    );

    const closeSparkManagementModal: TCloseSparkManagementModal = useCallback(
        (didActionWork, additionalData) => {
            if (typeof onCloseFn === 'function') {
                onCloseFn(didActionWork, additionalData);
                setOnCloseFn(undefined);
            } else {
                runDefaultOnClose(didActionWork, additionalData);
            }

            setVisibleModal('none');
            setSparkType(undefined);
            setSparkId(undefined);
            setInitialStage(undefined);
            setClonedSparkFields(undefined);
        },
        [onCloseFn, runDefaultOnClose],
    );

    const value = useMemo(
        () => ({
            openSparkManagementModal,
            openCreateModal,
            openEditModal,
            openCloneModal,
            openDeleteModal,
            openApprovalModal,
            closeSparkManagementModal,
        }),
        [openSparkManagementModal, closeSparkManagementModal],
    );

    const isApprovalModal = visibleModal === 'approval';
    const isCreateEditCloneModal =
        visibleModal === 'create' || visibleModal === 'edit' || visibleModal === 'clone';
    const sparkModalAction: SparkCreateEditAction = isCreateEditCloneModal
        ? visibleModal
        : (null as any);
    const isDeleteModal = visibleModal === 'delete';

    return (
        <SparkManagementModalContext.Provider value={value}>
            {children}

            <SparkApprovalModal
                isVisible={isApprovalModal}
                onClose={closeSparkManagementModal}
                sparkId={sparkId}
            />

            <DeleteSparkModal
                isVisible={isDeleteModal}
                onClose={closeSparkManagementModal}
                sparkId={sparkId}
            />

            <ConfirmModal
                isVisible={confirmModalProps?.isVisible}
                title={confirmModalProps?.title}
                message={<p>{confirmModalProps?.message}</p>}
                onConfirm={() => {
                    Intercom.open();
                }}
                onClose={() => {
                    setConfirmModalProps({
                        title: '',
                        message: '',
                        isVisible: false,
                    });
                }}
                confirmText="Continue"
            />
        </SparkManagementModalContext.Provider>
    );
};

export const useSparkManagementModal = () => useContext(SparkManagementModalContext);

// Helper function for `wrapWithSparkManagementModal`
function _injectSparkManagementModalActions(child: ReactElement) {
    return () => {
        const openAndCloseSparkManagementModal = useSparkManagementModal();
        return cloneElement(child, openAndCloseSparkManagementModal, null);
    };
}

// 1) Automatically wraps `WrappedComponent` in `SparkManagementModalProvider`.
// 2) Passes `openSparkManagementModal` and `closeSparkManagementModal` into `wrappedComponentProps`
export function wrapWithSparkManagementModal<WrappedComponentProps>(
    WrappedComponent: FC<WrappedComponentProps>,
) {
    return (wrappedComponentProps: WrappedComponentProps) => {
        const EnhancedWrappedComponent = _injectSparkManagementModalActions(
            <WrappedComponent {...(wrappedComponentProps as any)} />,
        );

        return (
            <SparkManagementModalProvider>
                <EnhancedWrappedComponent />
            </SparkManagementModalProvider>
        );
    };
}
