import Alabama from './mdi_alabama.svg?react';
import Alaska from './mdi_alaska.svg?react';
import Arizona from './mdi_arizona.svg?react';
import Arkansas from './mdi_arkansas.svg?react';
import California from './mdi_california.svg?react';
import Colorado from './mdi_colorado.svg?react';
import Connecticut from './mdi_connecticut.svg?react';
import Delaware from './mdi_delaware.svg?react';
import Florida from './mdi_florida.svg?react';
import Georgia from './mdi_georgia.svg?react';
import Hawaii from './mdi_hawaii.svg?react';
import Idaho from './mdi_idaho.svg?react';
import Illinois from './mdi_illinois.svg?react';
import Indiana from './mdi_indiana.svg?react';
import Iowa from './mdi_iowa.svg?react';
import Kansas from './mdi_kansas.svg?react';
import Kentucky from './mdi_kentucky.svg?react';
import Louisiana from './mdi_louisiana.svg?react';
import Maine from './mdi_maine.svg?react';
import Maryland from './mdi_maryland.svg?react';
import Massachusetts from './mdi_massachusetts.svg?react';
import Michigan from './mdi_michigan.svg?react';
import Minnesota from './mdi_minnesota.svg?react';
import Mississippi from './mdi_mississippi.svg?react';
import Missouri from './mdi_missouri.svg?react';
import Montana from './mdi_montana.svg?react';
import Nebraska from './mdi_nebraska.svg?react';
import Nevada from './mdi_nevada.svg?react';
import NewHampshire from './mdi_new_hampshire.svg?react';
import NewJersey from './mdi_new_jersey.svg?react';
import NewMexico from './mdi_new_mexico.svg?react';
import NewYork from './mdi_new_york.svg?react';
import NorthCarolina from './mdi_north_carolina.svg?react';
import NorthDakota from './mdi_north_dakota.svg?react';
import Ohio from './mdi_ohio.svg?react';
import Oklahoma from './mdi_oklahoma.svg?react';
import Oregon from './mdi_oregon.svg?react';
import Pennsylvania from './mdi_pennsylvania.svg?react';
import RhodeIsland from './mdi_rhode_island.svg?react';
import SouthCarolina from './mdi_south_carolina.svg?react';
import SouthDakota from './mdi_south_dakota.svg?react';
import Tennessee from './mdi_tennessee.svg?react';
import Texas from './mdi_texas.svg?react';
import Utah from './mdi_utah.svg?react';
import Vermont from './mdi_vermont.svg?react';
import Virginia from './mdi_virginia.svg?react';
import Washington from './mdi_washington.svg?react';
import WestVirginia from './mdi_west_virginia.svg?react';
import Wisconsin from './mdi_wisconsin.svg?react';
import Wyoming from './mdi_wyoming.svg?react';
import React, { ReactElement } from 'react';

const icons: { [key: string]: ReactElement } = {
    Alabama: <Alabama />,
    Alaska: <Alaska />,
    Arizona: <Arizona />,
    Arkansas: <Arkansas />,
    California: <California />,
    Colorado: <Colorado />,
    Connecticut: <Connecticut />,
    Delaware: <Delaware />,
    Florida: <Florida />,
    Georgia: <Georgia />,
    Hawaii: <Hawaii />,
    Idaho: <Idaho />,
    Illinois: <Illinois />,
    Indiana: <Indiana />,
    Iowa: <Iowa />,
    Kansas: <Kansas />,
    Kentucky: <Kentucky />,
    Louisiana: <Louisiana />,
    Maine: <Maine />,
    Maryland: <Maryland />,
    Massachusetts: <Massachusetts />,
    Michigan: <Michigan />,
    Minnesota: <Minnesota />,
    Mississippi: <Mississippi />,
    Missouri: <Missouri />,
    Montana: <Montana />,
    Nebraska: <Nebraska />,
    Nevada: <Nevada />,
    NewHampshire: <NewHampshire />,
    NewJersey: <NewJersey />,
    NewMexico: <NewMexico />,
    NewYork: <NewYork />,
    NorthCarolina: <NorthCarolina />,
    NorthDakota: <NorthDakota />,
    Ohio: <Ohio />,
    Oklahoma: <Oklahoma />,
    Oregon: <Oregon />,
    Pennsylvania: <Pennsylvania />,
    RhodeIsland: <RhodeIsland />,
    SouthCarolina: <SouthCarolina />,
    SouthDakota: <SouthDakota />,
    Tennessee: <Tennessee />,
    Texas: <Texas />,
    Utah: <Utah />,
    Vermont: <Vermont />,
    Virginia: <Virginia />,
    Washington: <Washington />,
    WestVirginia: <WestVirginia />,
    Wisconsin: <Wisconsin />,
    Wyoming: <Wyoming />,
};
const MarketIcon = ({ marketName }: { marketName: string }) => {
    return icons[marketName.replace(' ', '')] ?? null;
};

export default MarketIcon;
