import { useMemo } from 'react';

import { keyBy } from 'lodash';

import { ColumnDetail, TableKey } from '@sparkplug/lib';

import { useTableSettings } from '@core/users/queries';

import { ColumnSettingsOption, SparkDashboardTableRowData } from '@features/spark-dashboard/types';

import { THeadCell } from '@app/types/TableTypes';

interface SyncedColumnsAndHeadCells {
    syncedColumns: ColumnSettingsOption[];
    syncedHeadCells: THeadCell<SparkDashboardTableRowData>[];
    staticColumns: ColumnSettingsOption[];
}

const mapHeadCellToColumn = ({
    headCell,
    column,
    sticky,
}: {
    headCell: THeadCell<SparkDashboardTableRowData>;
    column?: ColumnDetail;
    sticky?: 'left' | 'right';
}): ColumnSettingsOption => ({
    value: headCell.id,
    label: typeof headCell.label === 'string' ? headCell.label : headCell.columnManagerLabel ?? '',
    isChecked: column?.isChecked ?? true,
    disabled: !!sticky,
    isHidden: !!headCell.isHidden,
});

/**
 * @return syncedColumns - maintain the order from columns while only including columns that are associated with up-to-date headCells
 * @return syncedHeadCells - maintain the order from columns while only showing headCells that are associated with visible syncedColumns
 */
const getSyncedColumnsAndHeadCells = ({
    columns,
    baseCells,
    stickyLeftCells = [],
}: {
    columns: ColumnDetail[];
    baseCells: THeadCell<SparkDashboardTableRowData>[];
    stickyLeftCells?: THeadCell<SparkDashboardTableRowData>[];
}): SyncedColumnsAndHeadCells => {
    const columnsByValue = keyBy(columns, 'value');
    const headCellsById = keyBy(baseCells, 'id');

    // Columns that directly correlate with up-to-date headCell values, all ordered based on tableSettings.columns
    const orderedColumns = columns
        .filter(({ value }) => !!value && headCellsById[value])
        .map((column) => mapHeadCellToColumn({ headCell: headCellsById[column.value], column }));
    // Columns mapped from up-to-date headCell data not present in tableSettings.columns that is not pinned
    const newlyAddedColumns = baseCells
        .filter(({ id, sticky }) => !columnsByValue[id] || (id === 'action' && sticky !== 'left'))
        .map((headCell) => mapHeadCellToColumn({ headCell }));

    const syncedColumns = [...orderedColumns, ...newlyAddedColumns];
    const syncedHeadCells = syncedColumns
        .filter(({ isChecked }) => isChecked)
        .map(({ value }) => headCellsById[value]);

    return {
        syncedColumns,
        syncedHeadCells,
        staticColumns: stickyLeftCells.map((headCell) =>
            mapHeadCellToColumn({
                headCell,
                sticky: 'left',
            }),
        ),
    };
};

/**
 * Queries TableSettings.columns. Syncs column data with static headCell data to create `syncedColumns`
 * for the ColumnSettingsManager and `syncedHeadCells` for the SparkDashboardTable.
 *
 * @returns syncedColumns - an up to date representation of the columns based on the available
 * headCells. The syncedColumns will ultimately be used in the ColumnSettingsManager
 *
 * @returns syncedHeadCells - an up to date representation of the headCells based on the column data.
 * The syncedHeadCells will ultimately be used in the SparkDashboardTable
 */
export const useSyncedColumnsAndHeadCells = ({
    tableKey,
    baseCells = [],
    stickyLeftCells = [],
}: {
    tableKey: TableKey;
    baseCells: THeadCell<SparkDashboardTableRowData>[];
    stickyLeftCells?: THeadCell<SparkDashboardTableRowData>[];
}): SyncedColumnsAndHeadCells & { syncedDataIsReady: boolean } => {
    const { tableSettings, tableSettingsAreReady } = useTableSettings({ tableKey });

    return useMemo(() => {
        const { syncedColumns, syncedHeadCells, staticColumns } = getSyncedColumnsAndHeadCells({
            columns: tableSettings?.columns ?? [],
            baseCells,
            stickyLeftCells,
        });

        return {
            syncedDataIsReady: tableSettingsAreReady,
            syncedColumns,
            syncedHeadCells,
            staticColumns,
        };
    }, [baseCells, tableSettings, tableSettingsAreReady]);
};
