import { Component } from 'react';

import moment from 'moment';

import IconButton from '@components/buttons/IconButton';
import { KeyboardArrowDown, KeyboardArrowUp } from '@components/icons';

import './TimePicker.scss';

const timeOptions = {
    hours: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
    minutes: ['00', '15', '30', '45'],
    amPm: ['AM', 'PM'],
};

interface TimePickerProps {
    value: string;
    onChange: (value: string) => void;
}

export default class TimePicker extends Component<TimePickerProps, {}> {
    onToggleTime(type: 'hour' | 'minute' | 'amPm', direction: 'up' | 'down', timeItem: string) {
        const { onChange, value } = this.props;

        let newHour: any = null;
        let newMinute: any = null;
        let newAmPm: any = null;

        if (type === 'hour') {
            const index = timeOptions.hours.indexOf(timeItem);
            if (direction === 'up') {
                if (index < timeOptions.hours.length - 1) {
                    newHour = timeOptions.hours[index + 1];
                }
                if (index === timeOptions.hours.length - 1) {
                    newHour = timeOptions.hours[0];
                }
            }
            if (direction === 'down') {
                if (index > 0) {
                    newHour = timeOptions.hours[index - 1];
                }
                if (index === 0) {
                    newHour = timeOptions.hours[timeOptions.hours.length - 1];
                }
            }
        }

        if (type === 'minute') {
            const index = timeOptions.minutes.indexOf(timeItem);
            if (direction === 'up') {
                if (index < timeOptions.minutes.length - 1) {
                    newMinute = timeOptions.minutes[index + 1];
                }
                if (index === timeOptions.minutes.length - 1) {
                    newMinute = timeOptions.minutes[0];
                }
            }
            if (direction === 'down') {
                if (index > 0) {
                    newMinute = timeOptions.minutes[index - 1];
                }
                if (index === 0) {
                    newMinute = timeOptions.minutes[timeOptions.minutes.length - 1];
                }
            }
        }

        if (type === 'amPm') {
            const index = timeOptions.amPm.indexOf(timeItem);
            if (direction === 'up') {
                if (index < timeOptions.amPm.length - 1) {
                    newAmPm = timeOptions.amPm[index + 1];
                }
                if (index === timeOptions.amPm.length - 1) {
                    newAmPm = timeOptions.amPm[0];
                }
            }
            if (direction === 'down') {
                if (index > 0) {
                    newAmPm = timeOptions.amPm[index - 1];
                }
                if (index === 0) {
                    newAmPm = timeOptions.amPm[timeOptions.amPm.length - 1];
                }
            }
        }

        // assign values to time items that were unchanged
        if (newHour == null) {
            newHour = value.substring(0, 2);
            if (newHour > 12) {
                newHour = `${+newHour - 12}`;
                if (newHour < 10) {
                    // format to 2 digits
                    newHour = `0${newHour}`;
                }
            }
        }
        if (newMinute == null) {
            newMinute = value.substring(3, 5);
        }
        if (newAmPm == null) {
            if (parseFloat(value.substring(0, 2)) < 12) {
                newAmPm = 'AM';
            } else {
                newAmPm = 'PM';
            }
        }

        // convert to 24h format
        const mtime = moment(`${newHour}:${newMinute} ${newAmPm}`, 'hh:mm A').format('HH:mm');
        newHour = mtime.substring(0, 2);
        newMinute = mtime.substring(3, 5);
        const timelineTimeOfDay = `${newHour}:${newMinute}:00`;

        onChange(timelineTimeOfDay);
    }

    render() {
        const { value } = this.props;

        // convert time from 24H to AM-PM format
        const mtime = moment(value, 'HH:mm').format('hh:mm A');
        const hour = mtime.substring(0, 2);
        const minute = mtime.substring(3, 5);
        const amPm = mtime.substring(6, 8);

        return (
            <div className="time-picker">
                {/* Hour Control */}
                <div className="control hour">
                    <IconButton
                        className="time-toggle increment"
                        onClick={() => this.onToggleTime('hour', 'up', hour)}
                    >
                        <KeyboardArrowUp />
                    </IconButton>
                    <div className="time-toggle display-value">{hour}</div>
                    <IconButton
                        className="time-toggle decrement"
                        onClick={() => this.onToggleTime('hour', 'down', hour)}
                    >
                        <KeyboardArrowDown />
                    </IconButton>
                </div>

                {/* Colon */}
                <div className="time-colon">:</div>

                {/* Minute Control */}
                <div className="control minute">
                    <IconButton
                        className="time-toggle increment"
                        onClick={() => this.onToggleTime('minute', 'up', minute)}
                    >
                        <KeyboardArrowUp />
                    </IconButton>
                    <div className="time-toggle display-value">{minute}</div>
                    <IconButton
                        className="time-toggle decrement"
                        onClick={() => this.onToggleTime('minute', 'down', minute)}
                    >
                        <KeyboardArrowDown />
                    </IconButton>
                </div>

                {/* AM-PM Control */}
                <div className="control am-pm">
                    <IconButton
                        className="time-toggle increment"
                        onClick={() => this.onToggleTime('amPm', 'up', amPm)}
                    >
                        <KeyboardArrowUp />
                    </IconButton>
                    <div className="time-toggle display-value">{amPm}</div>
                    <IconButton
                        className="time-toggle decrement"
                        onClick={() => this.onToggleTime('amPm', 'down', amPm)}
                    >
                        <KeyboardArrowDown />
                    </IconButton>
                </div>
            </div>
        );
    }
}
