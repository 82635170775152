import { FC, useMemo } from 'react';

import { keyBy, sum, uniq } from 'lodash';

import { FullChartResponseBody, HydratedPosProduct } from '@sparkplug/lib';

import TableChart from '@components/charts/TableChart';

import { calculatePercentIncrease } from '@helpers/charts';

import { IChartDataSettings, ITableChartData } from '@app/types/ChartDataTypes';
import { IPosLocation } from '@app/types/PosTypes';

import './AdvancedTableChart.scss';

const transformCloudChartDataForTableSpark = ({
    cloudChartData,
    chartSettings,
    chartLocations,
    chartProducts,
}: {
    cloudChartData: FullChartResponseBody;
    chartSettings: IChartDataSettings;
    chartLocations: IPosLocation[];
    chartProducts: HydratedPosProduct[];
}): ITableChartData => {
    const {
        total = 0,
        locationTotals = {},
        productBuckets = {},
        datePercentage = 0,
    } = cloudChartData as FullChartResponseBody;

    const productData =
        chartProducts?.map(({ internalKey, name, brands, categories }) => ({
            label: name,
            brand: brands[0]?.name ?? 'Unknown Brand',
            category: categories[0]?.name ?? 'Unknown Category',
            internalKey,
        })) ?? [];
    const productsByInternalKey = keyBy(productData, 'internalKey');

    if (!datePercentage) {
        return {
            total: 0,
            keys: [],
            rows: [],
        };
    }

    if (chartSettings.breakdown === 'location') {
        const locationsById = keyBy(chartLocations, '_id');

        return {
            total,
            keys: ['value', 'label'],
            rows: Object.entries(locationTotals).map(([locationId, value]) => {
                const location = locationsById[locationId];

                return {
                    key: locationId,
                    value,
                    label: location?.label ?? 'Unknown Location',
                };
            }),
        };
    }

    // Sometimes we get buckets for a product that we shouldn't. If we don't have a product then we should exclude those buckets
    const relevantProductBuckets = Object.entries(productBuckets).filter(
        ([internalKey]) => !!productsByInternalKey[internalKey],
    );

    if (chartSettings.breakdown === 'product') {
        return {
            total,
            keys: ['value', 'label', 'category', 'brand'],
            rows: relevantProductBuckets.map(([internalKey, buckets]) => {
                const product = productsByInternalKey[internalKey];
                const value = sum(Object.values(buckets));

                return {
                    key: product.internalKey,
                    value,
                    ...(product ?? {}),
                };
            }),
        };
    }

    if (chartSettings.breakdown === 'brand') {
        const mergedBrandTotals = new Map<string, number>();

        relevantProductBuckets.forEach(([internalKey, buckets]) => {
            const { brand } = productsByInternalKey[internalKey] ?? {};
            const value = sum(Object.values(buckets));

            const existingValue = mergedBrandTotals.get(brand) ?? 0;
            mergedBrandTotals.set(brand, existingValue + value);
        });

        return {
            total,
            keys: ['value', 'brand'],
            rows: Array.from(mergedBrandTotals.entries()).map(([brand, value]) => ({
                key: brand,
                value,
                brand,
            })),
        };
    }

    if (chartSettings.breakdown === 'category') {
        const mergedCategoryTotals = new Map<string, number>();

        relevantProductBuckets.forEach(([internalKey, buckets]) => {
            const { category } = productsByInternalKey[internalKey] ?? {};
            const value = sum(Object.values(buckets));

            const existingValue = mergedCategoryTotals.get(category) ?? 0;
            mergedCategoryTotals.set(category, existingValue + value);
        });

        return {
            total,
            keys: ['value', 'category'],
            rows: Array.from(mergedCategoryTotals.entries()).map(([category, value]) => ({
                key: category,
                value,
                category,
            })),
        };
    }

    return {
        total,
        keys: ['value'],
        rows: [
            {
                key: '*',
                value: total,
            },
        ],
    };
};

export const transformCloudChartDataForTable = ({
    cloudChartData,
    chartSettings,
    chartLocations,
    chartProducts,
}: {
    cloudChartData: FullChartResponseBody;
    chartSettings: IChartDataSettings;
    chartLocations: IPosLocation[];
    chartProducts: HydratedPosProduct[];
}): ITableChartData => {
    // TODO: remove me and associated helper when spark charts use the new api endpoints
    if (chartSettings.sparkId) {
        return transformCloudChartDataForTableSpark({
            cloudChartData,
            chartSettings,
            chartLocations,
            chartProducts,
        });
    }

    const {
        total = 0,
        locationTotals = [],
        productBuckets = {},
        brandBuckets = {},
        categoryBuckets = {},
    } = cloudChartData as any; // TODO: fix this type - once we have all charts using new endpoints, we can remove the old bucket/total types and just use the new ones

    const productData =
        chartProducts?.map(({ internalKey, name, brands, categories }) => ({
            label: name,
            brand: brands[0]?.name ?? 'Unknown Brand',
            category: categories[0]?.name ?? 'Unknown Category',
            internalKey,
        })) ?? [];
    const productsByInternalKey = keyBy(productData, 'internalKey');

    if (chartSettings.breakdown === 'location') {
        return {
            total,
            keys: ['value', 'label'],
            rows: locationTotals.map(({ key, locationName, value }: any) => {
                return {
                    key,
                    value,
                    label: locationName ?? 'Unknown Location',
                };
            }),
        };
    }

    if (chartSettings.breakdown === 'product') {
        return {
            total,
            keys: ['value', 'label', 'category', 'brand'],
            rows: Object.entries(productBuckets).map(([internalKey, buckets]: any) => {
                const product = productsByInternalKey[internalKey];
                const value = sum(Object.values(buckets));

                return {
                    key: product.internalKey,
                    value,
                    ...(product ?? {}),
                };
            }),
        };
    }

    if (chartSettings.breakdown === 'brand') {
        const mergedBrandTotals = new Map<string, number>();

        Object.entries(brandBuckets).forEach(([brandName, buckets]: any) => {
            const value = sum(Object.values(buckets));
            const existingValue = mergedBrandTotals.get(brandName) ?? 0;
            mergedBrandTotals.set(brandName, existingValue + value);
        });

        return {
            total,
            keys: ['value', 'brand'],
            rows: Array.from(mergedBrandTotals.entries()).map(([brand, value]) => ({
                key: brand,
                value,
                brand,
            })),
        };
    }

    if (chartSettings.breakdown === 'category') {
        const mergedCategoryTotals = new Map<string, number>();

        Object.entries(categoryBuckets).forEach(([categoryName, buckets]: any) => {
            const value = sum(Object.values(buckets));
            const existingValue = mergedCategoryTotals.get(categoryName) ?? 0;
            mergedCategoryTotals.set(categoryName, existingValue + value);
        });

        return {
            total,
            keys: ['value', 'category'],
            rows: Array.from(mergedCategoryTotals.entries()).map(([category, value]) => ({
                key: category,
                value,
                category,
            })),
        };
    }

    return {
        total,
        keys: ['value'],
        rows: [
            {
                key: '*',
                value: total,
            },
        ],
    };
};

export const combinePreviousChartDataWithCurrentChartData = ({
    currentPeriod,
    comparisonPeriod,
}: {
    currentPeriod: ITableChartData;
    comparisonPeriod: ITableChartData;
}): ITableChartData => {
    const previousValuesByRowKey = keyBy(comparisonPeriod?.rows ?? [], 'key');

    const keys = uniq([
        ...currentPeriod.keys,
        ...(comparisonPeriod?.keys ?? []),
        'previousValue',
        'comparisonValue',
    ]);

    return {
        ...currentPeriod,
        keys,
        showComparisonWindow: true,
        rows: currentPeriod.rows.map((row) => {
            const { value: previousValue = 0 } = previousValuesByRowKey[row.key] ?? {};
            const [percentDiff] = calculatePercentIncrease(row.value, previousValue);

            return {
                ...row,
                previousValue,
                comparisonValue: Number.isNaN(percentDiff)
                    ? Number.MIN_SAFE_INTEGER
                    : (percentDiff as number),
            };
        }),
    };
};

interface AdvancedTableChartProps {
    cloudChartData: FullChartResponseBody;
    chartSettings: IChartDataSettings;
    chartLocations: IPosLocation[];
    chartProducts: HydratedPosProduct[];

    showComparisonPeriod?: boolean;
    comparisonPeriodCloudChartData?: FullChartResponseBody;
}

const AdvancedTableChart: FC<AdvancedTableChartProps> = ({
    cloudChartData,
    chartSettings,
    chartLocations,
    chartProducts,

    showComparisonPeriod,
    comparisonPeriodCloudChartData,
}) => {
    const currentPeriod = useMemo(
        () =>
            transformCloudChartDataForTable({
                cloudChartData,
                chartSettings,
                chartLocations,
                chartProducts,
            }),
        [cloudChartData, chartSettings, chartLocations, chartProducts],
    );

    const comparisonPeriod = useMemo<ITableChartData | undefined>(
        () =>
            showComparisonPeriod && comparisonPeriodCloudChartData
                ? transformCloudChartDataForTable({
                      cloudChartData: comparisonPeriodCloudChartData,
                      chartSettings,
                      chartLocations,
                      chartProducts,
                  })
                : undefined,
        [
            showComparisonPeriod,
            comparisonPeriodCloudChartData,
            chartSettings,
            chartLocations,
            chartProducts,
        ],
    );

    const chartData = useMemo<ITableChartData>(
        () =>
            showComparisonPeriod && comparisonPeriod
                ? combinePreviousChartDataWithCurrentChartData({ currentPeriod, comparisonPeriod })
                : currentPeriod,
        [showComparisonPeriod, currentPeriod, comparisonPeriod],
    );

    return (
        <TableChart
            breakdown={chartSettings.breakdown}
            metric={chartSettings.metric}
            data={chartData}
            locationCount={chartSettings.locationIds.length}
        />
    );
};

export default AdvancedTableChart;
