import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import MuiStepper from '@mui/material/Stepper';

import './Stepper.scss';

const Stepper = ({ stage, stageNames }: { stage: number; stageNames: string[] }) => {
    return (
        <MuiStepper className="stepper" alternativeLabel activeStep={stage}>
            {stageNames.map((stageName) => {
                return (
                    <Step key={stageName}>
                        <StepLabel>{stageName}</StepLabel>
                    </Step>
                );
            })}
        </MuiStepper>
    );
};

export default Stepper;
