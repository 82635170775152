declare const grecaptcha: {
    enterprise: {
        execute: (siteKey: string, params: { action: string }) => Promise<string>;
    };
};

const recaptchaVerify = (action: string): Promise<string> => {
    if (import.meta.env.REACT_APP_RECAPTCHA_SITE_KEY === undefined) {
        throw new Error('reCAPTCHA site key is not defined');
    }
    if (!grecaptcha) {
        throw new Error("reCAPTCHA isn't loaded");
    }
    return grecaptcha.enterprise.execute(import.meta.env.REACT_APP_RECAPTCHA_SITE_KEY, { action });
};

export default recaptchaVerify;
