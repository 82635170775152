import { FC } from 'react';

import Chip from '@components/chips/Chip';
import Tooltip from '@components/layout/Tooltip';

import { ChartLeader } from '@app/types/ChartDataTypes';

export type NonParticipantReason = 'excludedFromSpark' | 'incompleteTraining';
export type NonParticipantLeader = ChartLeader & { nonParticipantReason: NonParticipantReason };

export interface NonParticipantChipProps {
    nonParticipantReason: NonParticipantReason;
}

const NonParticipantChip: FC<NonParticipantChipProps> = ({ nonParticipantReason }) => {
    const missingTraining = nonParticipantReason === 'incompleteTraining';

    return (
        <>
            {missingTraining ? (
                <Tooltip
                    title="This person has not completed the required training"
                    placement="top"
                >
                    <span>
                        <Chip
                            dense
                            className="non-participant-chip missing-training"
                            label="Training Not Complete"
                            color="red"
                        />
                    </span>
                </Tooltip>
            ) : (
                <Tooltip title="This person has not beed added to the spark" placement="top">
                    <span>
                        <Chip
                            dense
                            className="non-participant-chip excluded-from-spark"
                            label="Excluded From Spark"
                            color="neutral"
                        />
                    </span>
                </Tooltip>
            )}
        </>
    );
};

export default NonParticipantChip;
