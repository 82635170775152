import {
    ComponentType,
    Dispatch,
    ReactElement,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { isEmpty } from 'lodash';

import ListSelector, { TExtendedListItem } from '@components/inputs/ListSelector/ListSelector';

import { appendClasses } from '@helpers/ui';
import { noop } from '@helpers/util';

import { IOptionTree } from '@app/types/UITypes';

import Button from '../../buttons/Button';
import { useDropdown } from '../DropdownContext';
import SelectedCountMessage, { SelectedCountMessageProps } from './SelectedCountMessage';

import './DropdownListSelector.scss';

export interface DropdownListSelectorProps<T> {
    className?: string;
    list?: IOptionTree<T>[];
    initialSelected: TExtendedListItem<T>[];
    onApply?: (selected: TExtendedListItem<T>[]) => void;
    onSelectionChanged?: (selected: TExtendedListItem<T>[]) => void;
    addOnComponent?: ReactElement | null;
    bottomLeftComponent?: ReactElement;
    selectedCountProps?: Omit<SelectedCountMessageProps, 'selected' | 'total'>;
    dynamicOptionProps?: {
        ManageListItemContent: ComponentType<{
            updateOption: (
                updatedOption: TExtendedListItem<T & { isValidOption: boolean }>,
            ) => void;
        }>;
        updateList: (updatedOption: TExtendedListItem<T & { isValidOption: boolean }>) => void;
        setDynamicEditorIsOpen?: Dispatch<SetStateAction<boolean>>;
        dynamicEditorIsOpen?: boolean;
        createButtonLabel?: string;
    };
    hideToolbar?: boolean;
}

export const DropdownListSelector = <T extends unknown>({
    className,
    list = [],
    initialSelected = [],
    onApply,
    onSelectionChanged = noop,
    addOnComponent = null,
    bottomLeftComponent: initialBottomLeftComponent,
    selectedCountProps,
    dynamicOptionProps,
    hideToolbar,
}: DropdownListSelectorProps<T>) => {
    const { handleClose } = useDropdown();

    const classNameAppended = appendClasses([className, 'dropdown-list-selector']);

    const [selected, setSelected] = useState<TExtendedListItem<T>[]>([]);

    const onSetSelected = (values: any[]) => {
        onSelectionChanged(values);
        setSelected(values);
    };

    useEffect(() => {
        onSetSelected(initialSelected);
    }, [initialSelected]);

    const canAddDynamicOption = !!dynamicOptionProps;
    const { dynamicEditorIsOpen, setDynamicEditorIsOpen } = dynamicOptionProps || {};
    const [dynamicOption, setDynamicOption] =
        useState<TExtendedListItem<T & { isValidOption: boolean }>>();

    const updateDynamicOption = (option: TExtendedListItem<T & { isValidOption: boolean }>) => {
        setDynamicOption(option);
    };

    const onApplySelected = () => {
        handleClose();

        requestAnimationFrame(() => {
            const updatedSelected = [...selected];
            if (dynamicOption && setDynamicEditorIsOpen) {
                if (!dynamicOption.isValidOption) {
                    return;
                }

                dynamicOptionProps?.updateList(dynamicOption);
                updatedSelected.push(dynamicOption);
                setDynamicEditorIsOpen(false);
            }
            onSetSelected(updatedSelected);
            onApply?.(updatedSelected);
        });
    };

    const onCancel = () => {
        if (dynamicEditorIsOpen && list.length && setDynamicEditorIsOpen) {
            setDynamicEditorIsOpen(false);
        } else {
            handleClose();
        }
    };

    const bottomLeftComponent = useMemo(
        () =>
            !isEmpty(selectedCountProps) && !dynamicEditorIsOpen ? (
                <SelectedCountMessage
                    selected={selected.length}
                    total={list.length}
                    {...selectedCountProps}
                />
            ) : (
                initialBottomLeftComponent
            ),
        [selectedCountProps, dynamicEditorIsOpen, selected, list, initialBottomLeftComponent],
    );

    return (
        <div className={classNameAppended}>
            {dynamicEditorIsOpen && canAddDynamicOption ? (
                <dynamicOptionProps.ManageListItemContent updateOption={updateDynamicOption} />
            ) : (
                <ListSelector
                    list={list}
                    hideToolbar={hideToolbar}
                    selected={selected}
                    onSelectionChanged={onSetSelected}
                />
            )}

            {onApply ? (
                <div className="dropdown-list-selector-buttons">
                    {bottomLeftComponent ? (
                        <div className="bottom-left-component">{bottomLeftComponent}</div>
                    ) : (
                        <Button onClick={onCancel} color="neutral" variant="flat">
                            Cancel
                        </Button>
                    )}
                    <Button color="blue" variant="smooth" onClick={onApplySelected}>
                        Apply
                    </Button>
                </div>
            ) : (
                <div className="dropdown-list-selector-close-button">
                    <Button onClick={onCancel} color="neutral" variant="smooth">
                        Close
                    </Button>
                </div>
            )}

            {!!addOnComponent && addOnComponent}
        </div>
    );
};

export default DropdownListSelector;
