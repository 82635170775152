import { useMemo } from 'react';

import moment from 'moment';

import {
    CalculatorFrequency,
    GetChartRequestBodyMerged,
    GetChartResponseBody,
    RetailerChartCalculatorBreakdownType,
    VendorChartCalculatorBreakdownType,
} from '@sparkplug/lib';

import { frequencyMap, getCloudChartData, getCloudChartDataVendor } from '@helpers/charts';

import { IChartDataSettings } from '@app/types/ChartDataTypes';

import { useAdvancedQuery } from '../QueryHooks';

interface CloudChartDataSettings
    extends Pick<
        IChartDataSettings,
        | 'metric'
        | 'precision'
        | 'locationIds'
        | 'brandGroupId'
        | 'dateStart'
        | 'dateEnd'
        | 'filters'
        | 'sparkId'
        | 'percentIncreaseData'
    > {}

export const useCloudChartDataQuery = (
    retailerAccountId: string,
    settings: CloudChartDataSettings,
    isEnabled: boolean,
    breakdownType?: VendorChartCalculatorBreakdownType | RetailerChartCalculatorBreakdownType,
) => {
    const params = useMemo<GetChartRequestBodyMerged>(
        () => ({
            locationIds: settings.locationIds,
            frequency: frequencyMap[settings.precision] as CalculatorFrequency,
            groupId: retailerAccountId,
            brandGroupId: settings.brandGroupId,
            dateStart: moment(settings.dateStart).format('YYYY-MM-DD'),
            dateEnd: moment(settings.dateEnd).format('YYYY-MM-DD'),
            sparkId: settings.sparkId,
            productIds: settings.filters?.products?.map(({ _id }) => _id),
            percentIncreaseData: settings.percentIncreaseData,
        }),
        [settings, retailerAccountId, breakdownType],
    );

    const fetchChartData = (): Promise<GetChartResponseBody> => {
        /**
         * In order to show "No Sales Data" message, we need to return an empty chart data if there are no locationIds
         * so that we don't hit the endpoint (which will return an error)
         */
        if (!settings.locationIds.length) {
            return Promise.resolve({
                total: 0,
                locationTotals: {},
                locationBuckets: {},
                employeeTotals: {},
                employeeBuckets: {},
                employeeTransactionCounts: {},
                productsWithSalesByKey: [],
                productBuckets: {},
                commissions: {},
            });
        }

        return params.brandGroupId
            ? getCloudChartDataVendor(
                  { type: settings.metric as 'total_units' | 'total_sales' },
                  params,
                  breakdownType,
              )
            : getCloudChartData(
                  { type: settings.metric },
                  params,
                  breakdownType as RetailerChartCalculatorBreakdownType,
              );
    };

    const chartQueryKey = breakdownType ? `chart${breakdownType}` : 'chart';
    const newChartApiEnabledForMetricType = ['total_sales', 'total_units'].includes(settings.metric)
        ? true
        : !breakdownType;
    return useAdvancedQuery(
        [chartQueryKey, settings.metric, JSON.stringify(params)],
        fetchChartData,
        {
            // TODO: this will be updated as more api work is done
            // eventually, the only things we will check are isEnabled && !!settings.metric
            enabled:
                isEnabled &&
                !!settings.metric &&
                (newChartApiEnabledForMetricType || Boolean(params.sparkId)),
        },
    );
};
