import { ReactElement, useState } from 'react';

import Button from '@components/buttons/Button';
import TextField from '@components/inputs/TextField';

import { appendClasses } from '../../../helpers/ui';
import Modal from '../Modal';

import './ConfirmModal.scss';

type TConfirmModal = {
    isVisible: boolean;
    className?: string;
    title: string;
    message?: string | ReactElement;
    extraModalContent?: ReactElement;
    onConfirm: () => void;
    onClose: () => void;
    confirmText?: string;
    disabled?: boolean;
    closeOnConfirm?: boolean;
    confirmBtnColor?: 'blue' | 'red';
    requiredConfirmText?: string;
    requiredConfirmInputLabel?: ReactElement | string;
};

const ConfirmModal = ({
    isVisible,
    title,
    message,
    className,
    extraModalContent,
    onConfirm,
    onClose,
    disabled = false,
    confirmText = 'Confirm',
    closeOnConfirm = true,
    confirmBtnColor = 'blue',
    requiredConfirmText,
    requiredConfirmInputLabel,
}: TConfirmModal) => {
    const [inputConfirmText, setInputConfirmText] = useState<string>('');
    const classNamesAppended = appendClasses(['confirm-modal', className]);

    return (
        <Modal className={classNamesAppended} isVisible={isVisible} onClose={onClose}>
            <Modal.Title className="confirm-modal_title">{title}</Modal.Title>

            <Modal.Content className="confirm-modal_content">
                {typeof message === 'string' ? <p>{message}</p> : message}
            </Modal.Content>

            {extraModalContent && (
                <Modal.Content className="confirm-modal_extra-content">
                    {extraModalContent}
                </Modal.Content>
            )}

            {requiredConfirmText && (
                <TextField
                    label={requiredConfirmInputLabel}
                    className="required-text-input"
                    name="requiredConfirmText"
                    value={inputConfirmText}
                    onChange={(e) => setInputConfirmText(e.target.value)}
                />
            )}

            <Modal.Actions>
                <div className="confirm-modal_action-buttons">
                    <Button color="neutral" variant="flat" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        color={confirmBtnColor}
                        variant="filled"
                        disabled={
                            disabled ||
                            (Boolean(requiredConfirmText) &&
                                inputConfirmText.toLowerCase() !==
                                    requiredConfirmText?.toLowerCase())
                        }
                        onClick={() => {
                            onConfirm();
                            if (closeOnConfirm) {
                                onClose();
                            }
                        }}
                    >
                        {confirmText}
                    </Button>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

export default ConfirmModal;
