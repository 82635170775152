import { FullChartResponseBody, GetChartResponseBody } from '@sparkplug/lib';

import {
    IChartDataSettings,
    ITableChartDataRow,
    TChartData,
    TransformChartDataReturnValue,
} from '@app/types/ChartDataTypes';
import { IPosLocation, IPosProduct } from '@app/types/PosTypes';

const getChartDataProductsWithSalesByKey = ({
    accountPosProducts = [],
    productsWithSalesByKey = [],
}: {
    accountPosProducts?: IPosProduct[];
    productsWithSalesByKey?: string[];
}) => {
    const productInternalKeysMap = accountPosProducts.reduce<{
        [internalKey: string]: string;
    }>((res, { internalKey }) => {
        return {
            ...res,
            [internalKey!]: internalKey!,
        };
    }, {});

    return productsWithSalesByKey.map((key) => productInternalKeysMap[key]).filter((key) => !!key);
};

/**
 * @deprecated Remove this after we are fully migrated to Highcharts and using the new refactored charts
 */
export const transformCloudChartDataForTable = (
    rawData: GetChartResponseBody,
    settings: IChartDataSettings,
    posData: {
        accountPosLocations: IPosLocation[];
        accountPosProducts: IPosProduct[];
    },
): TransformChartDataReturnValue => {
    const {
        total = 0,
        locationTotals = {},
        productBuckets = {},
        datePercentage = 0,
    } = rawData as FullChartResponseBody;

    const productData =
        settings?.filters?.products?.map(({ label, brands, categories }) => ({
            label,
            brand: brands[0].name,
            category: categories[0].name,
        })) ?? [];
    const productInternalKeysWithData =
        settings?.filters?.products?.reduce((res, { internalKey, label, brands, categories }) => {
            return {
                ...res,
                [internalKey]: {
                    label,
                    brand: brands[0].name,
                    category: categories[0].name,
                },
            };
        }, {} as { [internalKey: string]: { label: string; brand: string; category: string } }) ??
        {};
    const { accountPosLocations, accountPosProducts } = posData;

    if (!datePercentage) {
        return {
            chartData: [],
            chartDataAllLocations: [],
            chartDataProducts: [],
            chartDataLeaders: [],
        } as unknown as TransformChartDataReturnValue;
    }

    const chartData = undefined as unknown as TChartData;
    const chartDataProducts = undefined;

    let chartDataAllLocations = {
        total,
        keys: ['value'],
        rows: [
            {
                key: '*',
                value: total,
            },
        ],
    };

    if (settings.breakdown === 'location') {
        chartDataAllLocations = {
            total,
            keys: ['value', 'label'],
            rows: Object.entries(locationTotals).reduce(
                (res: ITableChartDataRow[], [locationId, value]) => {
                    const location = accountPosLocations.find(({ _id }) => locationId === _id);

                    if (location) {
                        res.push({
                            key: locationId,
                            value,
                            label: location.label,
                        });
                    }

                    return res;
                },
                [],
            ),
        };
    }

    const productNamesMap = posData.accountPosProducts.reduce<{
        [lowerCasedProductName: string]: string;
    }>((res, { label }) => {
        return {
            ...res,
            [label.toLowerCase()]: label,
        };
    }, {});

    const productMap = accountPosProducts.reduce<{
        [productNameOrInternalKey: string]: IPosProduct;
    }>((res, product) => {
        return {
            ...res,
            [product.name.toLowerCase()]: product,
            // note: "internalKeyUndefined" is just here while we transition to products v2.  For a time, some products will be missing that internalKey - it won't be used here in that case, but we need _something_ to act as that (unused) key
            [product.internalKey || 'internalKeyUndefined']: product,
        };
    }, {});

    const lowerCasedProductNames = productData.map(({ label }) => label.toLowerCase());

    if (settings.breakdown === 'product') {
        chartDataAllLocations = {
            total,
            keys: ['value', 'label', 'category', 'brand'],
            rows: Object.entries(productBuckets).reduce(
                (res: ITableChartDataRow[], [productName, buckets]) => {
                    if (
                        lowerCasedProductNames.includes(productName) ||
                        productName in productInternalKeysWithData
                    ) {
                        const value = Object.values(buckets).reduce((prevValue, bucketValue) => {
                            return prevValue + bucketValue;
                        }, 0);

                        const category = productMap[productName]?.categories?.[0]?.name ?? '--';
                        const brand = productMap[productName]?.brands?.[0]?.name ?? '--';

                        res.push({
                            key: productName,
                            value,
                            label: productNamesMap[productName] ?? productMap[productName]?.label,
                            category,
                            brand,
                        });
                    }

                    return res;
                },
                [],
            ),
        };
    }

    if (settings.breakdown === 'brand') {
        const mergedBrandTotals = new Map<string, number>();

        Object.entries(productBuckets).forEach(([productName, buckets]) => {
            if (
                lowerCasedProductNames.includes(productName) ||
                productName in productInternalKeysWithData
            ) {
                const brand = productMap[productName]?.brands?.[0]?.name ?? '--';

                const value = Object.values(buckets).reduce((prevValue, bucketValue) => {
                    return prevValue + bucketValue;
                }, 0);

                const existingValue = mergedBrandTotals.get(brand) ?? 0;
                mergedBrandTotals.set(brand, existingValue + value);
            }
        });

        chartDataAllLocations = {
            total,
            keys: ['value', 'brand'],
            rows: Array.from(mergedBrandTotals.entries()).map(([brand, value]) => ({
                key: brand,
                value,
                brand,
            })),
        };
    }

    if (settings.breakdown === 'category') {
        const mergedCategoryTotals = new Map<string, number>();

        Object.entries(productBuckets).forEach(([productName, buckets]) => {
            if (
                lowerCasedProductNames.includes(productName) ||
                productName in productInternalKeysWithData
            ) {
                const category = productMap[productName]?.categories?.[0]?.name ?? '--';

                const value = Object.values(buckets).reduce((prevValue, bucketValue) => {
                    return prevValue + bucketValue;
                }, 0);

                const existingValue = mergedCategoryTotals.get(category) ?? 0;
                mergedCategoryTotals.set(category, existingValue + value);
            }
        });

        chartDataAllLocations = {
            total,
            keys: ['value', 'category'],
            rows: Array.from(mergedCategoryTotals.entries()).map(([category, value]) => ({
                key: category,
                value,
                category,
            })),
        };
    }

    return {
        chartData,
        chartDataAllLocations,
        chartDataProducts,
    };
};
