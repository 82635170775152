import { FC } from 'react';

import Chip from '../Chip';

import './CountChip.scss';

export interface CountChipProps {
    count: number;
}

const CountChip: FC<CountChipProps> = ({ count = 0 }) => {
    return <Chip className="count-chip" color="blue" variant="flat" label={count.toString()} />;
};

export default CountChip;
