import { useContext } from 'react';

import { ToolbarContext } from './ToolbarContext';

export const useIsInToolbar = ({ componentName }: { componentName: string }) => {
    const context = useContext(ToolbarContext);

    if (!context) {
        // eslint-disable-next-line no-console
        console.error(
            `<${componentName} /> must be a child of <Toolbar /> otherwise use the component without "Toolbar."`,
        );
    }
};
