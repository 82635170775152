import { Menu } from '@componentLibrary';

import { CirclePlay, Copy } from '@components/icons';
import { useLocation, useRouteMatch } from '@components/router';

const SnapsFeatureSidebar = () => {
    const { params } = useRouteMatch<{ accountId: string }>();
    const accountId = params.accountId;
    const location = useLocation();
    const navLinks = [
        {
            name: 'Snaps',
            href: `/${accountId}/snaps`,
            icon: <CirclePlay />,
            current: [`/${accountId}/snaps`, `/${accountId}/snaps/spark-snaps`].includes(
                location.pathname,
            ),
        },
        {
            name: 'Templates',
            href: `/${accountId}/snaps/templates`,
            icon: <Copy />,
            current: location.pathname === `/${accountId}/snaps/templates`,
        },
    ];

    return <Menu navigation={navLinks} />;
};

export default SnapsFeatureSidebar;
