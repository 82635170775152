import { useEffect, useState } from 'react';

import API from '@api/API';
import LocalStorage from '@data/LocalStorage';
import { Box, Typography } from '@mui/material';

import { useOneTimePasscode } from '@core/users';

import Button from '@components/buttons/Button';
import Paper from '@components/layout/Paper';
import CodeVerifiedOverlay from '@components/overlays/CodeVerifiedOverlay';
import { useHistory, useQueryParams } from '@components/router';

import { useApp, useCodeAuth } from '@hooks/AppHooks';

import { IAuthUser } from '@app/types/UsersTypes';

import './LoginView/LoginView.scss';

const AccountIdKey = 'sparkplug::accountId';

const TwoFactorAuthView = () => {
    const {
        code,
        email,
        redirectTo,
        authService,
        groupType,
        redirect: frillRedirectUrl,
    } = useQueryParams();
    const { sendOneTimePasscode } = useOneTimePasscode();
    const { user: appUser } = useApp();
    const [errorMessage, setErrorMessage] = useState<string | null>();
    const [sent, setSent] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [disableEmailButton, setDisableEmailButton] = useState(false);
    const [countdown, setCountdown] = useState(60);

    const { codeIsValid, verifyCode } = useCodeAuth({
        code,
        email,
    });
    const history = useHistory();
    const storedAccountId = LocalStorage.get(AccountIdKey) as string;

    const handleSendEmail = () => {
        if (!email) return;
        sendOneTimePasscode(email, {
            onSuccess: () => {
                setSent(true);
                setErrorMessage(null);
            },
            onError: (err: any) => {
                if (err.response.status === 404) {
                    setErrorMessage('User not found.');
                } else {
                    setErrorMessage(err.message);
                }
                // eslint-disable-next-line no-console
                console.log(err.response);
            },
        });
        setDisableEmailButton(true);
        setTimeout(() => {
            setDisableEmailButton(false);
        }, 60000);
    };
    useEffect(() => {
        let intervalId: number;

        if (disableEmailButton) {
            let timer = countdown;
            intervalId = setInterval(() => {
                timer -= 1;
                setCountdown(timer);
                if (timer === 0) {
                    clearInterval(intervalId);
                }
            }, 1000) as unknown as number;
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [disableEmailButton]);

    const handleSuccessfulLogin = async (user: IAuthUser) => {
        let account: any;
        let accountId: string | null = null;

        // Redirect to help referrer is a help article
        const { referrer } = document;
        if (referrer.includes('https://help.sparkplug.app')) {
            history.push(`/redirect?externalUrl=${referrer}`);
            return;
        }

        if (redirectTo != null) {
            // Excluding http:// to disable insecure redirects
            if (redirectTo.includes('https://')) {
                window.location.href = redirectTo;
            } else {
                history.push(redirectTo);
            }

            return;
        }

        if (user?.accounts && user.accounts.length === 0) {
            setIsSubmitting(false);
            return;
        }

        if (user?.role === 'none') {
            history.push(`/user/sparks`);
        } else if (user?.accounts && user.accounts.length > 0) {
            if (storedAccountId != null) {
                account = (user?.accounts || []).find(({ _id }) => _id === storedAccountId);
            }

            accountId = account != null ? storedAccountId : user?.accounts[0]?._id;

            if (accountId != null) {
                history.push(`/${accountId}/`);
            }
        } else if (user?.role === 'super-admin') {
            history.push(`/control-center/accounts/add`);
        }

        if (authService === 'frill' && frillRedirectUrl && groupType) {
            const { frillToken } = await API.get().getFrillAuthDetails({ groupType });
            const frillRedirectLink = `${frillRedirectUrl}?ssoToken=${frillToken}`;
            window.location.replace(frillRedirectLink);
        }
    };

    useEffect(() => {
        if (code?.length > 0 && email?.length > 0) {
            verifyCode();
        }
    }, [code, email]);

    useEffect(() => {
        if (appUser?._id && codeIsValid) handleSuccessfulLogin(appUser);
    }, [appUser, codeIsValid]);
    return (
        <div className="login-view">
            <Paper elevation={5} sx={{ width: '100%', maxWidth: 600, mt: 6 }}>
                <Typography variant="h5" sx={{ fontWeight: 600, mb: 2, textAlign: 'center' }}>
                    Check your email
                </Typography>
                {!email ? (
                    <Box sx={{ mb: 4, textAlign: 'left' }}>
                        <p>Sorry, we need to verify your account with a one time link.</p>
                        <p>Please contact support.</p>
                    </Box>
                ) : (
                    <>
                        {errorMessage && <p className="error-text">{errorMessage}</p>}
                        <Typography
                            variant="subtitle1"
                            sx={{ mb: 4, fontSize: '16px', textAlign: 'center' }}
                        >
                            We sent a verification email to <strong>{email}</strong>. <br />
                            Tap the link to verify your account.
                        </Typography>
                        <Box
                            sx={{
                                mb: 2,
                                width: '100%',
                                textAlign: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <>
                                Didn&apos;t get it?
                                <Button
                                    onClick={handleSendEmail}
                                    color="blue"
                                    data-testid="resend-button"
                                    disabled={disableEmailButton}
                                >
                                    <>
                                        Send Again {disableEmailButton && `in ${countdown} seconds`}
                                    </>
                                </Button>
                            </>
                        </Box>

                        {sent && (
                            <Typography variant="subtitle1">
                                We resent an email to {email}. Tap the link to verify your account.
                            </Typography>
                        )}
                    </>
                )}
            </Paper>
            <CodeVerifiedOverlay isVisible={codeIsValid} redirectTo="/" />
        </div>
    );
};

export default TwoFactorAuthView;
