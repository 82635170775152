import { FC } from 'react';

import { AdvancedChart } from '@core/charts/components/AdvancedChart';
import {
    VendorChartSettings,
    useVendorChartContext,
} from '@core/charts/contexts/VendorChartContext';
import {
    useVendorChartBucketBreakdown,
    useVendorChartTotal,
} from '@core/charts/queries/ChartsQueries';

import Chart from '@components/charts/Chart';
import { ChartTitleLeft } from '@components/charts/ComparisonChart/ComparisonChart';
import Skeleton from '@components/layout/Skeleton';

import { capitalizeFirstLetter } from '@helpers/ui';

import { VENDOR_MARKET_CHART_METRIC_OPTIONS } from '../VendorMarketChartConstants';
import VendorTableChart from '../VendorTableChart';

import './VendorBreakdownChart.scss';

const BucketChart = () => {
    const { vendorAccountId, chartSettings, chartBucketFactory, chartValueFormatter } =
        useVendorChartContext();

    const { vendorChartBucketBreakdownIsReady, vendorChartBucketBreakdown } =
        useVendorChartBucketBreakdown(
            {
                vendorAccountId,
                calcType: chartSettings.metric,
                breakdownType: chartSettings.breakdown,
            },
            chartSettings,
            true,
        );

    if (!vendorChartBucketBreakdownIsReady) {
        return <Skeleton height={400} />;
    }

    return (
        <AdvancedChart
            settings={chartSettings}
            data={vendorChartBucketBreakdown?.buckets ?? {}}
            chartBucketFactory={chartBucketFactory}
            chartValueFormatter={chartValueFormatter}
        />
    );
};

interface VendorBreakdownChartProps {}

/**
 * This chart is specifically when looking at a particular breakdown:
 * - retailer
 * - location
 * - brand
 *
 * For displaying comparison windows, use `<VendorMarketComparisonChart />`
 */
const VendorBreakdownChart: FC<VendorBreakdownChartProps> = () => {
    const { vendorAccountId, chartSettings, updateChartSettings } = useVendorChartContext();

    const { vendorChartTotalIsReady, vendorChartTotal } = useVendorChartTotal(
        {
            vendorAccountId,
            calcType: chartSettings.metric,
        },
        chartSettings,
        true,
    );

    const titleLabel = ` by ${capitalizeFirstLetter(chartSettings.breakdown)}`;

    const handleUpdateChartMetric = (updatedMetric: VendorChartSettings['metric']) =>
        updateChartSettings({ metric: updatedMetric });

    return (
        <>
            <Chart.Title
                metric={chartSettings.metric}
                total={vendorChartTotal?.total ?? 0}
                titleLabel={titleLabel}
                chartTitleLeft={
                    <ChartTitleLeft
                        isLoading={!vendorChartTotalIsReady}
                        metric={chartSettings.metric}
                        handleUpdateChartMetric={handleUpdateChartMetric as any}
                        titleLabel={titleLabel}
                        total={vendorChartTotal?.total ?? 0}
                        metricOptions={VENDOR_MARKET_CHART_METRIC_OPTIONS}
                    />
                }
            />

            {chartSettings.type === 'table' ? <VendorTableChart /> : <BucketChart />}
        </>
    );
};

export default VendorBreakdownChart;
