import API from '@api/API';
import jwtDecode from 'jwt-decode';

import { IJsonWebTokenPayload, IJsonWebTokenPayloadUser } from '@sparkplug/lib';

import { getAllAccounts } from '@core/accounts';
import { getUserData, textInterfaceGetUserContext } from '@core/users';

import { IAuthUser, IPublicAccountOption } from '@app/types/UsersTypes';

export const JwtTokenKey = 'sparkplug::jwtToken';
export const UserIdKey = 'sparkplug::userId';

const getJwtTokenData = () => {
    const jwtToken = localStorage.getItem(JwtTokenKey);
    if (jwtToken != null) {
        const jwtTokenData: IJsonWebTokenPayload = jwtDecode(jwtToken);
        return jwtTokenData;
    }

    return undefined;
};

export const getCurrentUserRole = () => {
    const jwtTokenData = getJwtTokenData();

    return jwtTokenData?.role;
};

export const logIn = async ({
    password,
    email,
    phoneNumber,
    isEmployeeLogin = false,
    existingJwt,
}: {
    password?: string;
    email?: string;
    phoneNumber?: string;
    isEmployeeLogin?: boolean;
    existingJwt?: string;
}): Promise<IJsonWebTokenPayloadUser> => {
    try {
        const jwtToken =
            existingJwt ??
            (await API.get().authenticate({
                email: email ?? undefined,
                phoneNumber: phoneNumber ?? undefined,
                password: password!,
            }));
        // We make this check prior to decoding the token because if this is true, it's not a legitimate
        // token and we need to handle it differently
        // This was a quick and dirty solution
        if (jwtToken === 'requires2fa') {
            return { requires2fa: true, userId: '', isSuperAdmin: false };
        }
        const jwtTokenData: IJsonWebTokenPayload = jwtDecode(jwtToken);

        if (jwtTokenData.role === 'none' && !isEmployeeLogin) {
            throw Error('You are not authorized. You must be an account admin to log in.');
        }

        if (!jwtTokenData.user.userId) {
            throw new Error('User ID is missing from the JWT token.');
        }

        localStorage.setItem(UserIdKey, jwtTokenData.user.userId);

        return jwtTokenData.user;
    } catch (err) {
        if (API.isAxiosError(err)) {
            if (err?.response?.data?.details === 'invalid account identifier') {
                return Promise.reject(Error('No user found'));
            }

            if (err?.response?.status === 402) {
                return Promise.reject(Error('No user found'));
            }
        }

        return Promise.reject(err);
    }
};

export const logOut = (): void => {
    localStorage.removeItem(JwtTokenKey);
    localStorage.removeItem(UserIdKey);
};

export const getCurrentUserId = (): string | null => {
    return localStorage.getItem(UserIdKey);
};

export const getUserGroups = async (userId: string): Promise<IPublicAccountOption[]> => {
    const jwtToken = getJwtTokenData();
    if (!jwtToken) {
        throw new Error('No JWT token found.');
    }

    const isSuperAdmin = jwtToken.role === 'super-admin';
    const userGroups = isSuperAdmin ? await getAllAccounts() : await getAllAccounts(userId);

    return userGroups
        .map((group) => ({
            ...group,
            label: group.name,
            value: group._id,
        }))
        .sort((a, b) => {
            return a.label.localeCompare(b.label);
        });
};

/**
 * @deprecated start migrating this to hooks like useUserAccounts and useSparkplugUser
 */
export const getUserAppData = async (
    userId: string,
): Promise<(IAuthUser & { enrollmentContexts: any[] }) | undefined> => {
    try {
        const userData = await getUserData(userId);
        const accounts = await getUserGroups(userId);

        let enrollmentContexts = [];

        try {
            enrollmentContexts = (await textInterfaceGetUserContext(userId)).data || [];
        } catch {
            enrollmentContexts = [];
        }

        return {
            ...userData,
            fullName: `${userData.firstName} ${userData.lastName}`.trim(),
            accounts,
            enrollmentContexts,
        };
    } catch (err) {
        if (API.isAxiosError(err) && err?.response?.status === 401) {
            throw Error('Unauthorized');
        } else {
            return undefined;
        }
    }
};

export const sendSmsAuth = async (phoneNumber: string): Promise<boolean> => {
    if (phoneNumber == null) {
        return Promise.reject(Error('Phone number is empty'));
    }

    await API.get().requestSmsCode({ phoneNumber });

    return true;
};

export const verifySmsCode = async (
    code: string,
    phoneNumber?: string,
    email?: string,
): Promise<{ isValidCode: boolean; userId: string }> => {
    try {
        const jwtToken = await API.get().verifySmsCode({
            authCode: code.toLowerCase(),
            phoneNumber,
            email,
        });
        const jwtTokenData: IJsonWebTokenPayload = jwtDecode(jwtToken);
        localStorage.setItem(UserIdKey, jwtTokenData.user.userId);
        return {
            isValidCode: true,
            userId: jwtTokenData.user.userId,
        };
    } catch (err) {
        return Promise.reject(err);
    }
};
