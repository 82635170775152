import { Suspense, lazy, useMemo } from 'react';

import moment from 'moment-timezone';

import Form from '@components/form/Form';
import DayOfWeekPicker from '@components/inputs/DayOfWeekPicker';
import TimePicker from '@components/inputs/TimePicker';
import Grid from '@components/layout/Grid';
import Spinner from '@components/layout/Spinner';

import { useApp } from '@hooks/AppHooks';

import './SMSNotificationsFormFields.scss';

const SamplePhoneMessages = lazy(() => import('./SamplePhoneMessages'));
export interface ISMSSettings {
    isUpdateEnabled: boolean;
    isAnnounceEnabled: boolean;
    daysOfWeek: number[];
    timeOfDay: string;
    timeZone: string;
}

interface IProps {
    settings: ISMSSettings;
    onChange: (settings: Partial<ISMSSettings>) => void;
}

const NotificationsContainer = ({ settings, onChange }: IProps) => {
    const { user } = useApp();

    const userName = user ? `${user.firstName} ${user.lastName}` : '[[logged_in user]]';

    const {
        isUpdateEnabled = false,
        isAnnounceEnabled = true,
        daysOfWeek = [0, 1, 2, 3, 4, 5, 6],
        timeOfDay = '12:00:00',
        timeZone = moment.tz.guess(true),
    } = settings;

    const timezoneNames = useMemo(() => {
        return moment.tz.names().map((tzName) => {
            return {
                label: tzName,
                value: tzName,
            };
        });
    }, []);

    return (
        <Grid className="notifications-container">
            <Grid.Item>
                <Form.Switch
                    name="isUpdateEnabled"
                    label="Spark Updates"
                    value={isUpdateEnabled}
                    onChange={(event: any) => onChange({ isUpdateEnabled: event.target.checked })}
                />
                <Form.Description>
                    <p>
                        Turn on to send text notifications to enrolled participants updating them on
                        their rankings and earnings from ongoing Sparks.
                    </p>
                </Form.Description>
                <div className="controls">
                    <Form.Group className="form-control">
                        <Form.Label>Schedule On These Days</Form.Label>
                        <DayOfWeekPicker
                            value={daysOfWeek}
                            onChange={(value) => {
                                onChange({ daysOfWeek: value });
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="form-control">
                        <Form.Label>Time</Form.Label>
                        <TimePicker
                            value={timeOfDay}
                            onChange={(value) => {
                                onChange({ timeOfDay: value });
                            }}
                        />
                    </Form.Group>
                    <br />
                </div>
                <Form.Switch
                    name="isAnnounceEnabled"
                    label="Spark Announcements"
                    value={isAnnounceEnabled}
                    onChange={(event: any) => onChange({ isAnnounceEnabled: event.target.checked })}
                />
                <Form.Description>
                    <p className="announcement-paragraph">
                        Turn on to send text announcements to enrolled participants when new Sparks
                        are starting.
                    </p>
                    <p>
                        <em className="announcement-note">
                            <strong>Note:</strong> New Spark announcements will be sent at the time
                            designated above.
                        </em>
                    </p>
                </Form.Description>
                <div className="controls">
                    <Form.SearchSelect
                        name="timeZone"
                        label="Time Zone"
                        value={timeZone}
                        onChange={(option) => {
                            onChange({ timeZone: option.value });
                        }}
                        options={timezoneNames}
                    />
                </div>
            </Grid.Item>
            <Grid.Item style={{ padding: 0 }}>
                {(isUpdateEnabled || isAnnounceEnabled) && (
                    <div className="sample-phone-messages">
                        <Suspense fallback={<Spinner />}>
                            <SamplePhoneMessages
                                userName={userName}
                                showMessage1={isUpdateEnabled}
                                showMessage2={isAnnounceEnabled}
                            />
                        </Suspense>
                    </div>
                )}
            </Grid.Item>
        </Grid>
    );
};

export default NotificationsContainer;
