import { Picker } from 'emoji-mart';

import { useDropdown } from './DropdownContext';

export interface IDropdownEmojiPickerProps {
    value: string;
    onChange: (value: string) => void;
}

const DropdownEmojiPicker = ({ value, onChange }: IDropdownEmojiPickerProps) => {
    const { handleClose } = useDropdown();

    return (
        <Picker
            set="apple"
            title="Pick your emoji…"
            emoji={value}
            showSkinTones={false}
            onSelect={(_emoji) => {
                onChange(_emoji.id!);
                handleClose();
            }}
        />
    );
};

DropdownEmojiPicker.isPopoverChild = true;
DropdownEmojiPicker.defaultProps = {
    open: false,
};

export default DropdownEmojiPicker;
