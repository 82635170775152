import { ProductInventoryRow } from '@features/inventory/components/ProductInventoryTable/useProductInventoryHeadCells';

import Table from '@components/table/Table';

import { THeadCell } from '@app/types/TableTypes';

export interface LocationInventoryRow {
    locationId: string;
    locationName: string;
    productData: ProductInventoryRow[];
    numProductsMissingInventory: number;
    outOfStockCount: number;
    key: string;
}

export const geLocationInventoryHeadCells = (): THeadCell<LocationInventoryRow>[] => [
    {
        id: 'locationName',
        label: 'Location',
        render: (row) => <Table.Cell>{row.locationName}</Table.Cell>,
    },
    {
        id: 'outOfStockCount',
        label: 'Inventory',
        render: (row) => (
            <Table.Cell>
                {row.locationId === 'missing_location' ? (
                    <span>{row.productData.length} Products</span>
                ) : (
                    <>
                        {row.outOfStockCount > 0 && (
                            <span className="text-buttercup-700 mr-2">
                                {row.outOfStockCount} Out-of-stock
                            </span>
                        )}
                        {row.numProductsMissingInventory > 0 && (
                            <span className="text-gray-700">
                                {row.numProductsMissingInventory} Missing Inventory
                            </span>
                        )}
                        {row.outOfStockCount === 0 &&
                            row.numProductsMissingInventory === 0 &&
                            'All-in-stock'}
                    </>
                )}
            </Table.Cell>
        ),
    },
];
