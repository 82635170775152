import { useMemo, useState } from 'react';

import { keyBy, sortBy } from 'lodash';

import { useVendorBrandRetailersByBrandId } from '@features/account-links';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';

export const useMultiRetailerTabManager = () => {
    const [selectedRetailerId, setSelectedRetailerId] = useState<string>('');

    const { account } = useSparkplugAccount();
    const { spark, multiRetailerParticipantGroups = [], isCreatingMultiRetailerSpark } = useSpark();

    const { vendorBrandRetailers = [], vendorBrandRetailersAreReady } =
        useVendorBrandRetailersByBrandId({
            brandId: spark?.sparkBrandId,
            vendorAccountId: account?._id,
            isEnabled:
                isCreatingMultiRetailerSpark &&
                account?.type === 'brand' &&
                !!spark?.sparkBrandId &&
                !!account?._id,
        });

    const retailers = useMemo(() => {
        const vendorRetailersMap = keyBy(vendorBrandRetailers, 'retailerAccountId');
        return sortBy(
            multiRetailerParticipantGroups
                .filter(({ retailerAccountId }) => !!vendorRetailersMap[retailerAccountId])
                .map(({ retailerAccountId }) => vendorRetailersMap[retailerAccountId])
                .filter(({ activeLocations = [] }) => !!activeLocations.length),
            'name',
        );
    }, [vendorBrandRetailers, multiRetailerParticipantGroups]);

    const selectedRetailer = useMemo(() => {
        const multiRetailerMap = keyBy(retailers, 'retailerAccountId');
        return multiRetailerMap?.[selectedRetailerId] ?? retailers?.[0];
    }, [retailers, selectedRetailerId]);

    return useMemo(() => {
        return {
            retailers,
            retailersAreReady: vendorBrandRetailersAreReady && !!selectedRetailer,
            selectedRetailer,
            updateSelectedRetailer: (retailerId: string): void => {
                setSelectedRetailerId(retailerId);
            },
        };
    }, [retailers, selectedRetailer, vendorBrandRetailersAreReady]);
};
