import { useMemo } from 'react';

import axios from 'axios';

import { CreateGiftCardPayload, ListGiftCardsResponseBody, PublicGiftCard } from '@sparkplug/lib';

import { useAdvancedQuery } from '@hooks/QueryHooks';

const API = {
    listGiftCards: async (
        queryParams?: Pick<CreateGiftCardPayload, 'createdByUserId' | 'userId'>,
    ) => {
        return (
            (
                await axios.get<ListGiftCardsResponseBody>('/api/v1/gift-cards', {
                    params: queryParams ?? {},
                })
            ).data.data ?? ([] as PublicGiftCard[])
        );
    },
};

export const giftCardsCacheKey = 'gift-cards' as const;
export const useAllGiftCardsQuery = () => {
    const { data = [], isLoading } = useAdvancedQuery([giftCardsCacheKey], () =>
        API.listGiftCards(),
    );

    return useMemo(
        () => ({
            giftCards: data,
            giftCardsAreReady: !isLoading,
        }),
        [data?.length, isLoading],
    );
};
