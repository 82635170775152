import RestrictedWordsModal from './RestrictedWordsModal';

export const containsRestrictedWordsByRef = (ref: any, str: string): boolean => {
    if (ref?.current?.containsRestrictedWords != null) {
        return ref?.current?.containsRestrictedWords(str);
    }

    return true;
};

export default RestrictedWordsModal;
