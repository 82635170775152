import { Redirect, useParams } from 'react-router-dom';

import { CheckCircle } from '@mui/icons-material';

import { EventDetails } from '@features/events/components';
import { useGetEventQuery } from '@features/events/queries/EventQueries';

import Button from '@components/buttons/Button';
import { CustomArrowRightAltIcon } from '@components/icons';
import Spinner from '@components/layout/Spinner';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import Typography from '@app/componentLibrary/Typography';

const ApproveEvent = () => {
    const { eventId } = useParams<{ eventId: string }>();
    const { account } = useSparkplugAccount();
    const { history } = useApp();

    const { data: event, isLoading } = useGetEventQuery(eventId);

    if (isLoading)
        return (
            <div className="flex flex-col justify-center items-center w-full gap-4">
                <Spinner />
                <Typography variant="lg" className="text-gray-950 py-2">
                    Loading event details...
                </Typography>
            </div>
        );

    if (!event) return <div>Event not found</div>;

    if (event.requestStatus === 'approved') {
        return (
            <div className="flex w-full items-center gap-4 flex-col">
                <div className="bg-green-500 p-2 rounded-md flex items-center gap-2">
                    <CheckCircle className="h-6 w-6 text-green-200" />
                    <Typography variant="lg" className="font-bold">
                        {`You've approved this event`}
                    </Typography>
                </div>
                <EventDetails event={event} />
                <div>
                    <Button
                        color="blue"
                        variant="filled"
                        endIcon={<CustomArrowRightAltIcon className="[&_path]:fill-white" />}
                        onClick={() => {
                            history.push(`/${account?._id}/events`);
                        }}
                    >
                        View Events
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <Redirect
            to={{
                pathname: `/${account?._id}/events`,
                state: { event },
            }}
        />
    );
};

export default ApproveEvent;
