import { useState } from 'react';

import { useSearch } from '@hooks/UIHooks';

import { ProductCommissionRow } from './useCommissionTableProducts';

export interface MultiRetailerTableFilters {
    tagIds: string[];
    brandIds: string[];
    retailerIds: string[];
    categoryIds: string[];
}
export const useMultiRetailerCommissionTableFilters = ({
    initialFilterValues,
}: {
    initialFilterValues?: Partial<MultiRetailerTableFilters>;
}) => {
    const { applySearch, onChangeSearchFilter } = useSearch([
        'name',
        'brand',
        'category',
        'retailer',
    ]);

    const [filters, setFilters] = useState<MultiRetailerTableFilters>({
        tagIds: [],
        brandIds: [],
        categoryIds: [],
        retailerIds: [],
        ...initialFilterValues,
    });

    const applyCategoryFilters = (rows: ProductCommissionRow[]) => {
        if (!filters.categoryIds.length) {
            return rows;
        }

        return rows.filter(({ categoryId }) => filters.categoryIds.includes(categoryId ?? ''));
    };

    const applyTagFilters = (rows: ProductCommissionRow[]) => {
        if (!filters.tagIds.length) {
            return rows;
        }

        return rows.filter(({ tags }) => {
            return tags?.some(({ _id }) => filters.tagIds.includes(_id ?? ''));
        });
    };

    const applyRetailerFilters = (rows: ProductCommissionRow[]) => {
        if (!filters.retailerIds.length) {
            return rows;
        }
        return rows.filter(({ groupId }) => filters.retailerIds.includes(groupId ?? ''));
    };

    return {
        filters,
        setFilters,
        applyTagFilters,
        applyCategoryFilters,
        applyRetailerFilters,
        applySearch,
        onChangeSearchFilter,
    };
};

export type UseMultiRetailerCommissionTableFilters = ReturnType<
    typeof useMultiRetailerCommissionTableFilters
>;
