import { FC, useCallback, useMemo, useState } from 'react';

import { difference, uniq } from 'lodash';

import { AccountMarket } from '@sparkplug/lib';

import { useVendorChartContext } from '@core/charts/contexts/VendorChartContext';

import { useAccountLinks } from '@features/account-links';

import { TExtendedListItem } from '@components/inputs/ListSelector/ListSelector';
import Toolbar from '@components/toolbar/Toolbar';

import { IAccount } from '@app/types/AccountsTypes';

import './ToolbarRetailerDropdown.scss';

const useVendorMarketRetailers = ({
    account,
    marketAbbreviation,
}: {
    account: IAccount;
    marketAbbreviation: string;
}) => {
    const { accountLinksAreReady, accountLinks } = useAccountLinks(account?._id);

    const marketRetailers = useMemo(() => {
        return (
            accountLinks?.filter(({ status, brandLinks, markets }) => {
                const isInMarket = markets.includes(
                    marketAbbreviation.toUpperCase() as AccountMarket,
                );

                const hasMappedBrands = !!brandLinks.flatMap(({ posBrandIds }) => posBrandIds)
                    ?.length;

                const isEnabled = ['enabled', 'none'].includes(status);

                return isInMarket && hasMappedBrands && isEnabled;
            }) ?? []
        );
    }, [accountLinks, marketAbbreviation]);

    return { marketRetailersAreReady: accountLinksAreReady, marketRetailers };
};

export const useSelectedRetailerAndLocationsState = ({
    account,
    marketAbbreviation,
}: {
    account: IAccount;
    marketAbbreviation: string;
}) => {
    const { updateChartSettings } = useVendorChartContext();
    const { marketRetailersAreReady, marketRetailers } = useVendorMarketRetailers({
        account,
        marketAbbreviation,
    });

    const [internalSelectedLocations, setInternalSelectedLocations] = useState<
        TExtendedListItem<{ retailerAccountId: string }>[]
    >([]);

    const retailerAndLocationOptionTree = useMemo(
        () =>
            marketRetailers
                ?.map((retailer) => ({
                    key: retailer.accountId,
                    value: retailer.accountId,
                    label: retailer.accountName,
                    retailerAccountId: retailer.accountId,
                    children: retailer.locations?.map((location) => ({
                        key: `${retailer.accountId}.${location._id}`,
                        value: location._id,
                        label: location.displayName ?? location.name,
                        retailerAccountId: retailer.accountId,
                    })),
                }))
                ?.filter(({ children }) => children?.length) ?? [],
        [marketRetailers],
    );

    const locationOptions = useMemo(
        () => retailerAndLocationOptionTree.flatMap(({ children = [] }) => children),
        [retailerAndLocationOptionTree],
    );

    const selectedLocations = internalSelectedLocations.length
        ? internalSelectedLocations
        : locationOptions;

    const hasUniqueRetailerLocationSelection = Boolean(
        internalSelectedLocations?.length > 0 &&
            internalSelectedLocations.length !== locationOptions.length,
    );

    const updateSelectedLocations = useCallback(
        (selected: TExtendedListItem<{ retailerAccountId: string }>[]) => {
            if (selected.length > 0) {
                const retailerAccountIds = uniq(
                    selected.map(({ retailerAccountId }) => retailerAccountId),
                );
                const selectedLocationIds = selected.map(
                    ({ value: posLocationId }) => posLocationId,
                );
                const selectedRetailerLocationIds = retailerAndLocationOptionTree?.flatMap(
                    ({ children = [] }) =>
                        children
                            ?.filter(({ retailerAccountId }) =>
                                retailerAccountIds.includes(retailerAccountId),
                            )
                            ?.map(({ value: posLocationId }) => posLocationId),
                );
                const excludedLocationIds = difference(
                    selectedRetailerLocationIds,
                    selectedLocationIds,
                );

                setInternalSelectedLocations(selected);
                updateChartSettings({
                    retailerAccountIds,
                    exclusionFilterParams: {
                        locationIds: excludedLocationIds,
                    },
                });
            }
        },
        [retailerAndLocationOptionTree],
    );

    const resetSelectedLocations = useCallback(() => {
        updateSelectedLocations(locationOptions);
    }, [locationOptions]);

    return {
        marketRetailersAreReady,
        retailerAndLocationOptionTree,
        selectedLocations,
        hasUniqueRetailerLocationSelection,
        updateSelectedLocations,
        resetSelectedLocations,
    };
};

interface ToolbarRetailerDropdownProps {
    account: IAccount;
    marketAbbreviation: string;
}

const ToolbarRetailerDropdown: FC<ToolbarRetailerDropdownProps> = ({
    account,
    marketAbbreviation,
}) => {
    const {
        marketRetailersAreReady,
        retailerAndLocationOptionTree,
        selectedLocations,
        hasUniqueRetailerLocationSelection,
        updateSelectedLocations,
        resetSelectedLocations,
    } = useSelectedRetailerAndLocationsState({ account, marketAbbreviation });

    return (
        <Toolbar.DropdownListSelector
            label="Retailer Locations"
            isLoading={!marketRetailersAreReady}
            selected={selectedLocations}
            onApply={updateSelectedLocations}
            options={retailerAndLocationOptionTree}
            showCount={hasUniqueRetailerLocationSelection}
            clear={{
                active: hasUniqueRetailerLocationSelection,
                onClear: resetSelectedLocations,
            }}
        />
    );
};

export default ToolbarRetailerDropdown;
