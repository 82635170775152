import { useMemo, useState } from 'react';

import { groupBy } from 'lodash';
import moment from 'moment/moment';

import {
    HydratedBrandProduct,
    SAMPLE_TEXT_TARGET,
    Spark,
    VendorSparkFilters,
    applyProductNameContainsFilter as applyProductNameContainsFilterFn,
    applyProductNameDoesNotContainFilter as applyProductNameDoesNotContainFilterFn,
} from '@sparkplug/lib';

import { useSearch } from '@hooks/UIHooks';

import { LAST_SOLD_DATE_OPTIONS } from '../../constants';

export const useRulesBasedProductTableFilters = ({
    spark,
    allowSearch,
}: {
    spark: Spark;
    allowSearch?: boolean;
}) => {
    const { applySearch, onChangeSearchFilter } = allowSearch
        ? useSearch(['name', 'brand', 'category'])
        : { applySearch: undefined, onChangeSearchFilter: undefined };

    const [filters, setFilters] = useState<VendorSparkFilters>({
        productTags: [],
        lastSoldAt: '-60days',
        hideSampleProducts: false,
        productNameContains: [],
        productNameDoesNotContain: [],
        ...(spark?.vendorFilters ?? {}),
    });

    const applyHideSampleProducts = (rows: HydratedBrandProduct[]) => {
        return filters.hideSampleProducts
            ? rows.filter(({ name, categories }) => {
                  const category = categories?.[0]?.name ?? '';
                  return (
                      !name.toLowerCase().includes(SAMPLE_TEXT_TARGET) &&
                      !category?.toLowerCase().includes(SAMPLE_TEXT_TARGET)
                  );
              })
            : rows;
    };

    const sparkStartDate = useMemo(
        () => (moment(spark?.startDate).isSameOrBefore(moment()) ? spark?.startDate : moment()),

        [spark],
    );

    const applyLastSoldFilter = (rows: HydratedBrandProduct[]) => {
        if (filters.lastSoldAt === 'allTime') {
            return rows;
        }
        const { onApply = () => () => true } =
            LAST_SOLD_DATE_OPTIONS.find(({ value }) => filters.lastSoldAt === value) ?? {};
        const onApplyWithCutoff = onApply(sparkStartDate);
        return rows.filter(({ lastSoldAt }) => onApplyWithCutoff(lastSoldAt));
    };

    const applyTagsFilter = (rows: HydratedBrandProduct[]) => {
        const tagsByGroupName = Object.values(groupBy(filters.productTags, 'tagGroupName'));

        return rows.filter(({ tags: brandProductTags }) =>
            tagsByGroupName.every((tagsForGroup) =>
                brandProductTags.some((brandProductTag) =>
                    tagsForGroup.some(
                        (tagForGroup) =>
                            brandProductTag.groupName === tagForGroup.tagGroupName &&
                            brandProductTag.name === tagForGroup.tagName,
                    ),
                ),
            ),
        );
    };

    const applyProductNameContainsFilter = applyProductNameContainsFilterFn(
        filters.productNameContains,
    );

    const applyProductNameDoesNotContainFilter = applyProductNameDoesNotContainFilterFn(
        filters.productNameDoesNotContain,
    );

    const onViewAll = () => {
        setFilters({
            productTags: [],
            lastSoldAt: 'allTime',
            hideSampleProducts: false,
            productNameContains: [],
            productNameDoesNotContain: [],
        });
    };

    const getRulesBasedFilteredProducts = (rows: HydratedBrandProduct[]) => {
        return [
            applyHideSampleProducts,
            applyLastSoldFilter,
            applyTagsFilter,
            applyProductNameContainsFilter,
            applyProductNameDoesNotContainFilter,
        ].reduce((res, filter) => {
            return filter(res);
        }, rows);
    };

    return {
        filters,
        setFilters,
        applyHideSampleProducts,
        applyLastSoldFilter,
        applyTagsFilter,
        applyProductNameContainsFilter,
        applyProductNameDoesNotContainFilter,
        applySearch,
        onViewAll,
        onChangeSearchFilter,
        getRulesBasedFilteredProducts,
    };
};

export type UseVendorRulesBasedSparkProductSelectorTableFilters = ReturnType<
    typeof useRulesBasedProductTableFilters
>;
