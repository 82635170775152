import Highcharts from 'highcharts';
import { groupBy, sumBy } from 'lodash';
import moment, { MomentInput } from 'moment';

import {
    BucketsKeyedByLabel,
    CalculatorFrequency,
    FullChartResponseBody,
    TChartDataMetric,
} from '@sparkplug/lib';

import { DefaultChartColors } from '@components/charts/nivo/Nivo.Constants';

import BucketFactory from '@helpers/visualization/buckets/BucketFactory';

import { TChartDataBreakdown, TChartDataPrecision } from '@app/types/ChartDataTypes';

export const getLineChartSeriesProperties = ({
    count,
}: {
    count: number;
}): Partial<Highcharts.SeriesSplineOptions> => {
    const lineWidth = count > 31 ? 2 : 4;
    const pointSize = count > 31 ? 3 : 5;
    const pointsAreVisible = count < 60;

    const result: Partial<Highcharts.SeriesSplineOptions> = {
        marker: {
            enabled: pointsAreVisible,
            fillColor: '#ffffff',
            lineColor: null as any, // In order to inherit from series color
            lineWidth: 2,
            radius: pointSize,
            symbol: 'circle',
        },

        lineWidth,
    };

    return result;
};

export const getDefaultChartOptions = ({
    chartBucketFactory,
    chartValueFormatter,
    hideLegend = false,
    showXAxisGridLine = false,
    allDataPointsZero = false,
}: {
    chartBucketFactory: BucketFactory;
    chartValueFormatter: (value: number) => string;
    hideLegend?: boolean;
    showXAxisGridLine?: boolean;
    allDataPointsZero?: boolean;
}): Highcharts.Options => {
    return {
        title: { text: '' },
        chart: {
            // Make dynamic based on series length
            height: 560,
            animation: {
                duration: 300,
            },
        },
        yAxis: {
            units: [],
            title: { text: '' },
            labels: {
                formatter(context) {
                    return chartValueFormatter(
                        typeof context.value === 'string'
                            ? parseFloat(context.value)
                            : context.value,
                    );
                },
            },
            // placeholder data if all data points are zero
            ...(allDataPointsZero ? { floor: 0, max: 4000 } : {}),
        },
        xAxis: {
            type: 'datetime',
            labels: {
                formatter(context) {
                    const bucket = moment(context.value).toISOString().split('T')[0];
                    return chartBucketFactory.getShortName(bucket);
                },
            },
            ...(showXAxisGridLine
                ? {
                      gridLineWidth: 1,
                      crosshair: { dashStyle: 'Dash', color: '#777777' },
                  }
                : {}),
        },
        plotOptions: {
            line: {
                marker: {
                    fillColor: '#ffffff',
                    lineWidth: 2,
                    symbol: 'circle',
                },
                lineWidth: 4,
            },
        },
        legend: {
            enabled: !hideLegend,
            maxHeight: 160,
            borderWidth: 1,
            borderColor: '#ccd3de',
            borderRadius: 4,
            padding: 12,
            itemStyle: {
                fontWeight: '700',
            },
            itemHiddenStyle: {
                color: '#A0A7B1',
            },
            navigation: {
                activeColor: '#0089ab',
                style: {
                    margin: '0 auto',
                },
            },
        },
        credits: {
            enabled: false,
        },
    };
};

const getCoreSeriesProperties = <T extends unknown>({
    label,
    index,
}: {
    label: string;
    index: number;
}): Partial<T> => {
    return {
        name: label,
        color: DefaultChartColors[index % DefaultChartColors.length],
        borderColor: 'none',
        legendSymbol: 'rectangle',
    } as any;
};

export const transformChartDataToLineSeries = ({
    metric,
    data,
    buckets,
    showFutureDates = false,
}: {
    metric: TChartDataMetric;
    data: BucketsKeyedByLabel;
    buckets: string[];
    showFutureDates?: boolean;
}): Highcharts.SeriesSplineOptions[] => {
    const now = moment();
    const lineChartProperties = getLineChartSeriesProperties({
        count: Object.keys(data).length,
    });

    // The line chart should increase cumulatively, unless the metric is something like order average
    const lineIsCumulative = ![
        'order_average',
        'units_per_transaction',
        'guest_check_average',
    ].includes(metric);

    return Object.entries(data).map<Highcharts.SeriesSplineOptions>(
        ([label, bucketData], index) => ({
            ...getCoreSeriesProperties<Highcharts.SeriesSplineOptions>({ label, index }),
            ...lineChartProperties,
            type: 'spline',
            stack: label,
            data: buckets.reduce<number[][]>((res, bucketName) => {
                const value = bucketData[bucketName] ?? 0;
                const x = new Date(bucketName).getTime();
                // Line charts are cumulative
                const y = lineIsCumulative ? value + (res[res.length - 1]?.[1] ?? 0) : value;

                const isAfterToday = moment(bucketName).isAfter(now, 'date');

                res.push([x, (isAfterToday && !showFutureDates ? undefined : y) as number]);

                return res;
            }, []),
        }),
    );
};

export const transformChartDataToBarSeries = ({
    data,
    buckets,
    stack: shouldStack,
    showFutureDates = false,
}: {
    data: BucketsKeyedByLabel;
    buckets: string[];
    stack: boolean;
    showFutureDates?: boolean;
}): Highcharts.SeriesColumnOptions[] => {
    const now = moment();

    return Object.entries(data).map<Highcharts.SeriesColumnOptions>(
        ([label, bucketData], index) => ({
            ...getCoreSeriesProperties<Highcharts.SeriesColumnOptions>({ label, index }),
            type: 'column',
            stacking: shouldStack ? 'normal' : undefined,
            stack: shouldStack ? 'same' : label,
            data: buckets.reduce<number[][]>((res, bucketName) => {
                const x = new Date(bucketName).getTime();
                const y = bucketData[bucketName] ?? 0;

                const isAfterToday = moment(bucketName).isAfter(now, 'date');

                res.push([x, (isAfterToday && !showFutureDates ? undefined : y) as number]);

                return res;
            }, []),
        }),
    );
};

export const getBucketFromTooltipContext = (
    tooltipContext: Highcharts.TooltipFormatterContextObject,
) => moment(tooltipContext.x).toISOString().split('T')[0];

// Comparison charts are for the entire account, so we need to sum the buckets for each location
// Except for avg metric sparks - in which case we then divide by the number of locations
export const sumLocationBucketsFromChartResponse = (
    chartData?: FullChartResponseBody,
    metric?: TChartDataMetric,
    breakdownType?: TChartDataBreakdown,
) => {
    const isAverageMetric = [
        'order_average',
        'units_per_transaction',
        'guest_check_average',
    ].includes(metric ?? '');

    let bucketsByLocationId = {};
    switch (breakdownType) {
        case 'brand':
            bucketsByLocationId = chartData?.brandBuckets ?? {};
            break;
        case 'category':
            bucketsByLocationId = chartData?.categoryBuckets ?? {};
            break;
        case 'product':
            bucketsByLocationId = chartData?.productBuckets ?? {};
            break;
        default:
            // location is the default for breakdownType
            bucketsByLocationId = chartData?.locationBuckets ?? {};
    }

    const allBuckets = Object.values(bucketsByLocationId).flatMap((bucketsForLocation: any) =>
        Object.entries(bucketsForLocation).map(([bucketDate, bucketValue]) => ({
            bucketDate,
            bucketValue,
        })),
    );

    const valuesByBucketDate = groupBy(allBuckets, 'bucketDate');

    const bucketDateEntries = Object.entries(valuesByBucketDate).map(([bucketDate, buckets]) => {
        const summedBucketValue = sumBy(buckets, 'bucketValue');
        const bucketValueForMetric = isAverageMetric
            ? summedBucketValue / (buckets.length ?? 1)
            : summedBucketValue;
        return [bucketDate, bucketValueForMetric];
    });

    return Object.fromEntries(bucketDateEntries);
};

const FREQUENCY_BY_PRECISION: Record<TChartDataPrecision, CalculatorFrequency> = {
    day: 'daily',
    week: 'weekly',
    month: 'monthly',
    year: 'yearly',
};
export const getFrequencyFromPrecision = (precision: TChartDataPrecision) =>
    FREQUENCY_BY_PRECISION[precision];

export const getDefaultFrequencyByDateRange = (
    dateStart: MomentInput,
    dateEnd: MomentInput,
): CalculatorFrequency => {
    const duration = moment(dateEnd).diff(moment(dateStart), 'days');

    if (duration <= 31) {
        return 'daily';
    } else if (duration > 31 && duration <= 91) {
        return 'weekly';
    } else if (duration > 91) {
        return 'monthly';
    }

    return 'monthly';
};
