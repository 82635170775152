import { useCallback } from 'react';

import axios from 'axios';

import { ListEmployeeSparksResponseBody, Spark } from '@sparkplug/lib';

import { useApp } from '@hooks/AppHooks';
import { useAdvancedQuery, useQueryClient } from '@hooks/QueryHooks';

const API = {
    fetchEmployeeSparks: async () => {
        return (
            (await axios.get<ListEmployeeSparksResponseBody>('/api/v1/employee-data/sparks')).data
                .data ?? ([] as Spark[])
        );
    },
};

export const getEmployeeSparksCacheKey = (userId: string) => ['employee-sparks', userId];
export const useEmployeeSparks = (options?: { autoFetch?: boolean }) => {
    const { userId = '' } = useApp();

    const {
        data: sparks = [],
        isFetched: sparksAreReady,
        refetch: refetchSparks,
    } = useAdvancedQuery(getEmployeeSparksCacheKey(userId), () => API.fetchEmployeeSparks(), {
        enabled: !!userId && (options?.autoFetch ?? true),
    });

    const queryClient = useQueryClient();
    const prefetchSparks = useCallback(async () => {
        queryClient.prefetchQuery(getEmployeeSparksCacheKey(userId), () =>
            API.fetchEmployeeSparks(),
        );
    }, [queryClient]);

    return {
        sparks,
        sparksAreReady,
        refetchSparks,
        prefetchSparks,
    };
};
