import axios from 'axios';

import {
    AssignProductTagRequestBody,
    BulkInvalidateReactivateProductsRequestBody,
    BulkRemoveProductTagsRequestBody,
    CopyBrandTagGroupRequestBody,
    CreateBrandTagGroupRequestBody,
    CreateBrandTagGroupResponseBody,
    CreateBrandTagRequestBody,
    RemoveProductTagRequestBody,
    UpdateBrandTagGroupRequestBody,
} from '@sparkplug/lib';

import {
    getAccountTagCountsQueryKey,
    getBrandLinkTagCountsQueryKey,
    getVendorRetailerPosDataQueryKey,
} from '@features/account-links';

import { queryClient } from '@contexts/QueryContext';

import { useAdvancedMutation } from '@hooks/QueryHooks';

import { getBrandTagGroupsQueryKey } from '../queries';

type CreateBrandTagGroupPayload = {
    brandId: string;
    body: CreateBrandTagGroupRequestBody;
};

type UpdateBrandTagGroupPayload = {
    brandId: string;
    tagGroupId: string;
    body: UpdateBrandTagGroupRequestBody;
};

type CopyBrandTagGroupArgs = {
    brandId: string;
    tagGroupId: string;
    body: CopyBrandTagGroupRequestBody;
};

type DeleteBrandTagGroupArgs = {
    brandId: string;
    tagGroupId: string;
};

type CreateGroupTagPayload = {
    brandId: string;
    groupId: string;
    body: CreateBrandTagRequestBody;
};

type UpdateGroupTagPayload = {
    brandId: string;
    groupId: string;
    tagId: string;
    body: CreateBrandTagRequestBody;
};

type DeleteGroupTagPayload = {
    brandId: string;
    groupId: string;
    tagId: string;
};

type BulkInvalidateReactivateProductsPayload = {
    brandLinkId: string;
    body: BulkInvalidateReactivateProductsRequestBody;
};

export interface OnChangeParams {
    tagGroupName: string;
    tagName: string;
}

const API = {
    createBrandTagGroup: async ({ brandId, body }: CreateBrandTagGroupPayload) => {
        return (
            await axios.post<CreateBrandTagGroupResponseBody>(
                `/api/v1/brands/${brandId}/tag-groups`,
                body,
            )
        ).data;
    },
    updateBrandTagGroup: async ({ brandId, tagGroupId, body }: UpdateBrandTagGroupPayload) => {
        return axios.put(`/api/v1/brands/${brandId}/tag-groups/${tagGroupId}`, body);
    },
    copyBrandTagGroup: async ({ brandId, tagGroupId, body }: CopyBrandTagGroupArgs) => {
        return axios.post<{}>(`/api/v1/brands/${brandId}/tag-groups/${tagGroupId}/copy`, body);
    },
    deleteBrandTagGroup: async ({ brandId, tagGroupId }: DeleteBrandTagGroupArgs) => {
        return axios.delete(`/api/v1/brands/${brandId}/tag-groups/${tagGroupId}`);
    },
    createGroupTag: async ({ brandId, groupId, body }: CreateGroupTagPayload) => {
        return axios.post(`/api/v1/brands/${brandId}/tag-groups/${groupId}/tags`, body);
    },
    updateGroupTag: async ({ brandId, groupId, tagId, body }: UpdateGroupTagPayload) => {
        return axios.put(`/api/v1/brands/${brandId}/tag-groups/${groupId}/tags/${tagId}`, body);
    },
    deleteGroupTag: async ({ brandId, groupId, tagId }: DeleteGroupTagPayload) => {
        return axios.delete(`/api/v1/brands/${brandId}/tag-groups/${groupId}/tags/${tagId}`);
    },
    bulkInvalidateProducts: async ({
        brandLinkId,
        body,
    }: BulkInvalidateReactivateProductsPayload) => {
        return axios.post(`/api/v1/brand-links/invalidate/${brandLinkId}`, body);
    },
    bulkReactivateProducts: async ({
        brandLinkId,
        body,
    }: BulkInvalidateReactivateProductsPayload) => {
        return axios.post(`/api/v1/brand-links/reactivate/${brandLinkId}`, body);
    },
    assignProductTag: async ({ body }: { body: AssignProductTagRequestBody }) => {
        return axios.post(`/api/v1/product-tags/assign`, body);
    },
    removeTag: async ({ body }: { body: RemoveProductTagRequestBody }) => {
        return axios.post(`/api/v1/product-tags/remove`, body);
    },
    bulkRemoveAllTags: async ({ body }: { body: BulkRemoveProductTagsRequestBody }) => {
        return axios.post(`/api/v1/product-tags/remove-all`, body);
    },
};

export const useCreateBrandTagGroup = (brandId: string) => {
    const { mutate, isLoading } = useAdvancedMutation(
        (payload: Omit<CreateBrandTagGroupPayload, 'brandId'>) =>
            API.createBrandTagGroup({ brandId, ...payload }),
        {
            updateQuery: { queryKey: getBrandTagGroupsQueryKey(brandId) },
        },
    );

    return { isCreatingBrandTagGroup: isLoading, createBrandTagGroup: mutate };
};

export const useUpdateBrandTagGroup = (brandId: string) => {
    const { mutate, isLoading } = useAdvancedMutation(
        (payload: Omit<UpdateBrandTagGroupPayload, 'brandId'>) =>
            API.updateBrandTagGroup({ brandId, ...payload }),
        {
            updateQuery: { queryKey: getBrandTagGroupsQueryKey(brandId) },
        },
    );

    return { isUpdatingBrandTagGroup: isLoading, updateBrandTagGroup: mutate };
};

export const useCopyBrandTagGroup = (brandId: string) => {
    const { mutate, isLoading } = useAdvancedMutation(
        (payload: Omit<CopyBrandTagGroupArgs, 'brandId'>) =>
            API.copyBrandTagGroup({ brandId, ...payload }).then(() =>
                queryClient.invalidateQueries(
                    getBrandTagGroupsQueryKey(payload.body.targetBrandId),
                ),
            ),
    );

    return { isCopyingBrandTagGroup: isLoading, copyBrandTagGroup: mutate };
};

export const useDeleteBrandTagGroup = (brandId: string) => {
    const { mutate, isLoading } = useAdvancedMutation(
        (payload: DeleteBrandTagGroupArgs) => API.deleteBrandTagGroup(payload),
        {
            updateQuery: { queryKey: getBrandTagGroupsQueryKey(brandId) },
        },
    );

    return { isDeletingBrandTagGroup: isLoading, deleteBrandTagGroup: mutate };
};

export const useCreateGroupTag = (brandId: string) => {
    const { mutate, isLoading } = useAdvancedMutation(
        (payload: CreateGroupTagPayload) => API.createGroupTag(payload),
        {
            updateQuery: { queryKey: getBrandTagGroupsQueryKey(brandId) },
        },
    );

    return { isCreatingTagGroup: isLoading, createGroupTag: mutate };
};

export const useUpdateGroupTag = (brandId: string) => {
    const { mutate, isLoading } = useAdvancedMutation(
        (payload: UpdateGroupTagPayload) => API.updateGroupTag(payload),
        {
            updateQuery: { queryKey: getBrandTagGroupsQueryKey(brandId) },
        },
    );

    return { isUpdatingGroupTag: isLoading, updateGroupTag: mutate };
};

export const useDeleteGroupTag = (brandId: string) => {
    const { mutate, isLoading } = useAdvancedMutation(
        (payload: DeleteGroupTagPayload) => API.deleteGroupTag(payload),
        {
            updateQuery: { queryKey: getBrandTagGroupsQueryKey(brandId) },
        },
    );

    return { isDeletingGroupTag: isLoading, deleteGroupTag: mutate };
};

export const useBulkInvalidateProducts = (brandLinkId: string, vendorAccountId?: string) => {
    const { mutate, mutateAsync, isLoading } = useAdvancedMutation(
        (payload: BulkInvalidateReactivateProductsRequestBody) =>
            API.bulkInvalidateProducts({ brandLinkId, body: payload }),
        {
            updateQuery: { queryKey: getBrandTagGroupsQueryKey(brandLinkId) },
            customOptions: {
                onSuccess: () => {
                    queryClient.invalidateQueries(getBrandLinkTagCountsQueryKey(brandLinkId));
                    if (vendorAccountId) {
                        queryClient.refetchQueries(getAccountTagCountsQueryKey(vendorAccountId));
                    }
                },
            },
        },
    );

    return {
        isBulkInvalidating: isLoading,
        bulkInvalidateProducts: mutate,
        bulkInvalidateProductsAsync: mutateAsync,
    };
};

export const useBulkReactivateProducts = (brandLinkId: string, vendorAccountId?: string) => {
    const { mutate, mutateAsync, isLoading } = useAdvancedMutation(
        (payload: BulkInvalidateReactivateProductsRequestBody) =>
            API.bulkReactivateProducts({ brandLinkId, body: payload }),
        {
            updateQuery: { queryKey: getBrandTagGroupsQueryKey(brandLinkId) },
            customOptions: {
                onSuccess: () => {
                    queryClient.invalidateQueries(getBrandLinkTagCountsQueryKey(brandLinkId));
                    if (vendorAccountId) {
                        queryClient.refetchQueries(getAccountTagCountsQueryKey(vendorAccountId));
                    }
                },
            },
        },
    );

    return {
        isBulkReactivating: isLoading,
        bulkReactivateProducts: mutate,
        bulkReactivateProductsAsync: mutateAsync,
    };
};

export const useBulkClearTags = (
    vendorAccountId: string,
    retailerAccountId: string,
    brandLinkId?: string,
) => {
    const { mutate, mutateAsync, isLoading } = useAdvancedMutation(
        (payload: BulkRemoveProductTagsRequestBody) => API.bulkRemoveAllTags({ body: payload }),
        {
            updateQuery: {
                queryKey: getVendorRetailerPosDataQueryKey(vendorAccountId, retailerAccountId),
            },
            customOptions: {
                onSuccess: () => {
                    queryClient.refetchQueries(getAccountTagCountsQueryKey(vendorAccountId));
                    if (brandLinkId) {
                        queryClient.invalidateQueries(getBrandLinkTagCountsQueryKey(brandLinkId));
                    }
                },
            },
        },
    );

    return {
        isBulkClearingTags: isLoading,
        bulkClearTags: mutate,
        bulkClearTagsAsync: mutateAsync,
    };
};

export type RemoveProductTagParams = Omit<RemoveProductTagRequestBody, 'retailerId'>;
export const useRemoveProductTag = (
    vendorAccountId: string,
    retailerId: string,
    brandLinkId?: string,
) => {
    const { mutate, isLoading } = useAdvancedMutation(
        (payload: RemoveProductTagParams) =>
            API.removeTag({
                body: {
                    ...payload,
                    retailerId,
                },
            }),
        {
            updateQuery: {
                queryKey: getVendorRetailerPosDataQueryKey(vendorAccountId, retailerId),
            },
            toastOptions: {
                loading: 'Removing tag from product',
                success: 'Tag removed',
                error: 'There was an error removing the tag from the product',
            },
            customOptions: {
                onSuccess: () => {
                    if (brandLinkId) {
                        queryClient.invalidateQueries(getBrandLinkTagCountsQueryKey(brandLinkId));
                        queryClient.refetchQueries(getAccountTagCountsQueryKey(vendorAccountId));
                    }
                },
            },
        },
    );

    return {
        isRemovingTag: isLoading,
        removeProductTag: mutate,
    };
};

export type AssignProductTagParams = Omit<AssignProductTagRequestBody, 'retailerId'>;
export const useAssignProductTag = (
    vendorAccountId: string,
    retailerId: string,
    brandLinkId?: string,
) => {
    const { mutate, isLoading, mutateAsync } = useAdvancedMutation(
        (payload: AssignProductTagParams) =>
            API.assignProductTag({
                body: {
                    ...payload,
                    retailerId,
                },
            }),
        {
            updateQuery: {
                queryKey: getVendorRetailerPosDataQueryKey(vendorAccountId, retailerId),
            },
            toastOptions: {
                loading: 'Assigning tag to product',
                success: 'Tag assigned',
                error: 'There was an error assigning the tag to the product',
            },
            customOptions: {
                onSuccess: () => {
                    if (brandLinkId) {
                        queryClient.invalidateQueries(getBrandLinkTagCountsQueryKey(brandLinkId));
                        queryClient.refetchQueries(getAccountTagCountsQueryKey(vendorAccountId));
                    }
                },
            },
        },
    );

    return {
        isAssigningTag: isLoading,
        assignProductTag: mutate,
        assignProductTagAsync: mutateAsync,
    };
};
