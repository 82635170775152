import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';

import {
    GetVendorChartRequestBody,
    VendorChartCalculatorBreakdownType,
    VendorChartCalculatorType,
} from '@sparkplug/lib';

import { InitialComparisonWindowState, useComparisonWindowState } from '@hooks/ChartDataHooks';

import BucketFactory from '@helpers/visualization/buckets/BucketFactory';
import DayBucketFactory from '@helpers/visualization/buckets/DayBucketFactory';

import { TChartType } from '@app/types/ChartDataTypes';

import { useSharedComparisonChartContextValues } from '../components/AdvancedComparisonChart/ComparisonChartContext';
import { useChartFactories } from '../hooks/ChartsHooks';
import { getDefaultFrequencyByDateRange } from '../utils/ChartsUtils';

export interface VendorChartSettings extends GetVendorChartRequestBody {
    type: TChartType;
    metric: VendorChartCalculatorType;
    breakdown: VendorChartCalculatorBreakdownType;
}

export interface VendorChartContextValue
    extends ReturnType<typeof useComparisonWindowState>,
        ReturnType<typeof useSharedComparisonChartContextValues> {
    vendorAccountId: string;
    chartSettings: VendorChartSettings;
    updateChartSettings: (updatedSettings: Partial<VendorChartSettings>) => void;
    chartValueFormatter: (value: number) => string;
    chartBucketFactory: BucketFactory;
}

const DEFAULT_VENDOR_CHART_CONTEXT_VALUE: VendorChartContextValue = {
    vendorAccountId: '',
    chartSettings: {} as any,
    updateChartSettings: () => {},
    chartValueFormatter: () => '',
    chartBucketFactory: new DayBucketFactory(),

    showComparisonWindows: false,
    setShowComparisonWindows: () => {},
    comparisonPeriod: 'previousPeriod',
    setComparisonPeriod: () => {},
    comparisonRange: {},
    comparisonDatesByCurrentDate: {},
    currentPeriodLabel: '',
    comparisonEndDates: {
        currentComparisonTotalEndDate: '',
        previousComparisonTotalEndDate: '',
    },
};

const VendorChartContext = createContext<VendorChartContextValue>(
    DEFAULT_VENDOR_CHART_CONTEXT_VALUE,
);

export const useVendorChartContext = () => useContext(VendorChartContext);

export const VendorChartProvider = ({
    vendorAccountId,
    initialSettings,
    initialComparisonSettings,
    children,
}: {
    vendorAccountId: string;
    initialSettings: VendorChartSettings;
    initialComparisonSettings?: InitialComparisonWindowState;
    children: ReactNode;
}) => {
    /**
     * Any new Chart Provider should have its own state for chart settings
     */
    const [chartSettings, setChartSettings] = useState<VendorChartSettings>(initialSettings);
    const chartFactoriesValues = useChartFactories(chartSettings);

    /**
     * Any new Chart Provider that uses comparison windows should use this hook to manage the state
     * of the comparison windows. This will handle any state such as changing the date range and providing
     * the date ranges for the comparison periods
     */
    const sharedComparisonChartContextValue = useSharedComparisonChartContextValues({
        settings: chartSettings,
        initialComparisonSettings,
    });
    const { showComparisonWindows, setShowComparisonWindows } = sharedComparisonChartContextValue;

    const setShowComparisonWindowsOverride = useCallback(
        (updatedValue: boolean) => {
            setShowComparisonWindows(updatedValue);
            if (updatedValue && chartSettings.breakdown !== 'total') {
                setChartSettings((prevChartSettings) => ({ ...prevChartSettings, type: 'table' }));
            }
        },
        [chartSettings.breakdown],
    );

    const updateChartSettings = useCallback(
        (partiallyUpdatedSettings: Partial<VendorChartSettings>) => {
            setChartSettings((prevValue) => {
                const updatedSettings = {
                    ...prevValue,
                    ...partiallyUpdatedSettings,
                };

                const isUpdatingDateRange = Boolean(
                    partiallyUpdatedSettings.dateStart && partiallyUpdatedSettings.dateEnd,
                );
                // Update frequecy if changing the date range
                if (isUpdatingDateRange) {
                    updatedSettings.frequency = getDefaultFrequencyByDateRange(
                        partiallyUpdatedSettings.dateStart,
                        partiallyUpdatedSettings.dateEnd,
                    );
                }

                // if showing comparison windows AND a breakdown other than total, we need
                // to force the chart type to be a table because 'line' and 'bar' charts cannot
                // show comparison windows with a breakdown like retailer and location
                if (showComparisonWindows && updatedSettings.breakdown !== 'total') {
                    updatedSettings.type = 'table';
                }

                return updatedSettings;
            });
        },
        [showComparisonWindows],
    );

    const value = useMemo<VendorChartContextValue>(
        () => ({
            ...chartFactoriesValues,
            ...sharedComparisonChartContextValue,
            setShowComparisonWindows: setShowComparisonWindowsOverride,
            chartSettings,
            updateChartSettings,
            vendorAccountId,
        }),
        [
            vendorAccountId,
            chartFactoriesValues,
            sharedComparisonChartContextValue,
            chartSettings,
            setShowComparisonWindowsOverride,
            updateChartSettings,
        ],
    );

    return <VendorChartContext.Provider value={value}>{children}</VendorChartContext.Provider>;
};
