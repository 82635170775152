import { FC } from 'react';

import { DetailedSparkType, Spark } from '@sparkplug/lib';

import { SparkChartProvider } from '@core/charts/contexts/SparkChartContext';

import PanelField from '@components/layout/PanelField';

import { useSparkChartData } from '@hooks/SparksHooks/SparksHooks';

import { ISparkPosData } from '@app/types/SparksTypes';

import EmployeeMetricsSection from './EmployeeMetricsSection';
import SparkDetailsChartPaper from './SparkDetailsChartPaper';

import './SparkDetailsCharts.scss';

interface SparkDetailsChartsProps {
    spark: Spark;
    sparkPosData: ISparkPosData;
    detailedSparkType: DetailedSparkType;
    isAcceptedSpark: boolean;
    openParticipantsModal?: () => void;
}
const SparkDetailsCharts: FC<SparkDetailsChartsProps> = ({
    spark,
    sparkPosData,
    detailedSparkType,
    isAcceptedSpark,
    openParticipantsModal,
}) => {
    const {
        sparkChartData,
        sparkChartSettings,
        sparkStandings,
        updateSparkChartSettings,
        isCalculatingSparkChartData,
        sparkTransactionSummariesExist,
    } = useSparkChartData({ spark });

    let leaders = sparkStandings;

    // TODO: This is super ugly but we'll fix it when we rewrite `UserCharts` and that whole flow!
    if (detailedSparkType === 'goalManager') {
        const participantCount = sparkPosData.participants?.length ?? 0;
        leaders = [
            {
                ...leaders[0],
                ...(openParticipantsModal &&
                    !!participantCount && {
                        locations: (
                            <PanelField
                                className="manager-standing-subtitle"
                                value={`${participantCount} Manager${
                                    participantCount === 1 ? '' : 's'
                                }`}
                                onClick={openParticipantsModal}
                            />
                        ),
                    }),
            },
        ];
    }

    return (
        <>
            <SparkDetailsChartPaper
                isCalculatingChartData={isCalculatingSparkChartData}
                spark={spark}
                calculatorData={sparkChartData}
                chartSettings={sparkChartSettings}
                onUpdateChartSettings={updateSparkChartSettings}
                transactionSummariesExist={sparkTransactionSummariesExist}
            />

            {isAcceptedSpark && (
                <EmployeeMetricsSection
                    spark={spark}
                    isCalculatingChartData={isCalculatingSparkChartData}
                    transactionSummariesExist={sparkTransactionSummariesExist}
                    leaders={leaders}
                    type={sparkChartSettings.type ?? 'bar'}
                    locations={sparkPosData.locations}
                />
            )}
        </>
    );
};

export default (props: SparkDetailsChartsProps) => {
    const { spark } = props;

    return (
        <SparkChartProvider sparkId={spark._id}>
            <SparkDetailsCharts {...props} />
        </SparkChartProvider>
    );
};
