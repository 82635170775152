import React, { Fragment } from 'react';

import { SparkTemplate } from '@features/spark-dashboard/types';

import {
    CircleCheck as CircleCheckIcon,
    Crown as CrownIcon,
    UnlimitedGoalIcon,
} from '@components/icons';
import { FulfilledBySparkplugIcon } from '@components/sparks/SparkAwardsPanel/SparkAwardsPanel';
import useOrderedAwardBlock from '@components/sparks/SparkAwardsPanel/useOrderedAwardBlock';

import { getOrdinal } from '@helpers/util';

import Typography from '@app/componentLibrary/Typography';

const BlockCountTrackers = {
    leaderboard: ({ count }: { count: number }) => {
        const ordinal = getOrdinal(count);
        return (
            <div className="flex items-center gap-1">
                <CrownIcon className="crown-icon w-4 h-4" />
                <div className="relative mr-2">
                    <span className="text-gray-600 font-bold">{count}</span>
                    <span className="absolute top-0 right-0 translate-x-full -translate-y-1/2 text-gray-600 text-[0.65em]">
                        {ordinal}
                    </span>
                </div>
                <span className="text-gray-600 font-bold">Place</span>
            </div>
        );
    },

    goal: ({ count, isUnlimited }: { count: number; isUnlimited: boolean }) => {
        if (isUnlimited) {
            return (
                <div className="flex items-center gap-1 text-gray-600">
                    <UnlimitedGoalIcon className="w-4 h-4" />
                    <span className="text-gray-600 font-bold">Unlimited Goal</span>
                </div>
            );
        }

        return (
            <div className="flex items-center gap-1 text-gray-600">
                <CircleCheckIcon className="w-4 h-4" />
                <span className="text-gray-600 font-bold">{`Goal ${count}`}</span>
            </div>
        );
    },
};

const TemplateAwardsPanel: React.FC<{ template: SparkTemplate }> = ({ template }) => {
    const orderedAwardBlockProps = useOrderedAwardBlock(template);

    return (
        <div className="flex flex-col gap-2 border border-solid border-gray-200 rounded-md p-4">
            {template.type === 'leaderboard' && (
                <Typography variant="lg" className="font-semibold">
                    Prizes
                </Typography>
            )}
            {template.type === 'goal' && (
                <Typography variant="lg" className="font-semibold">
                    Goals
                </Typography>
            )}
            <div className="flex items-center justify-evenly">
                {orderedAwardBlockProps.map((awardBlockProps, i) => {
                    const BlockCountTracker = BlockCountTrackers[awardBlockProps.sparkType];
                    return (
                        <Fragment key={`${i}-${awardBlockProps.award.award}`}>
                            <div className="flex flex-col items-center gap-1">
                                <BlockCountTracker
                                    count={awardBlockProps.blockCount}
                                    isUnlimited={false}
                                />
                                {awardBlockProps.sparkType === 'goal' &&
                                awardBlockProps.threshold ? (
                                    <span>
                                        <Typography
                                            variant="sm"
                                            className="text-gray-950 font-bold"
                                        >{`Sell ${awardBlockProps.threshold}`}</Typography>
                                    </span>
                                ) : null}
                                <div className="flex items-center gap-1">
                                    <Typography variant="2xl" className="text-gray-800 font-bold ">
                                        {awardBlockProps.award.award}
                                    </Typography>
                                    <FulfilledBySparkplugIcon />
                                </div>
                            </div>
                            {i !== orderedAwardBlockProps.length - 1 && (
                                <div className="h-14 w-[1px] bg-gray-200" />
                            )}
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default TemplateAwardsPanel;
