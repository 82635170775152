import { ComponentProps, ComponentType, ReactElement, useEffect } from 'react';

import { FormControl, Select as MaterialUISelect, MenuItem } from '@mui/material';

import { ExpandMore } from '@components/icons';
import InputLabel from '@components/inputs/InputLabel';
import SearchSelect from '@components/inputs/SearchSelect';

import { useFormContext } from '@hooks/FormHooks';

import { appendClasses, uuid } from '@helpers/ui';

import { IFormField, IOption, InputEventOnChange } from '@app/types/UITypes';

import './Select.scss';

export type SelectOption<T> = IOption<T> & {
    description?: ReactElement | string;
    disabled?: boolean;
};

interface SelectProps<T> extends Omit<IFormField, 'onChange'> {
    className?: string;
    color?: 'neutral' | 'blue' | 'green' | 'red';
    variant?: 'outlined' | 'raised' | 'smooth' | 'filled';
    onChange?: InputEventOnChange;
    options: SelectOption<T>[];
    searchable?: boolean;
    displayEmpty?: boolean;
    requiredWithExternalValidation?: boolean;
    OptionLabel?: ComponentType<{ option: SelectOption<T> }>;
}

const Select = <T extends {}>({
    className,
    color = 'neutral',
    name,
    variant = 'outlined',
    label,
    defaultValue,
    value,
    required = false,
    onChange: _onChange = () => {},
    options = [],
    OptionLabel,
    disabled,
    searchable = false,
    displayEmpty = false,
    error = false,
    requiredWithExternalValidation = false,
}: SelectProps<T>) => {
    const classNameAppended = appendClasses([
        className,
        'form-control',
        'select',
        `select-color-${color}`,
        `select-variant-${variant}`,
    ]);

    const { setValue, register } = useFormContext() || {
        setValue: () => {},
        register: () => {},
    };

    if (name) {
        register(name, {
            required,
        });
    }

    const onChange = (event: any) => {
        if (name) {
            setValue(name, event.target.value);
        }

        _onChange(event);
    };

    useEffect(() => {
        if (name) {
            setValue(name, value || defaultValue || '');
        }
    }, [value]);

    const inputName: string | undefined = name || label?.replace(/\W/g, '') || uuid();

    const props: ComponentProps<typeof MaterialUISelect> = {
        onChange,
        name: inputName,
        defaultValue,
        value,
        IconComponent: ExpandMore,
        disabled,
        native: options.length > 50,
        displayEmpty,
        inputProps: {
            'data-testid': `select.${name}`,
        },
    };

    const hasCustomLabel = !!OptionLabel;

    if (searchable) {
        return (
            <SearchSelect
                mode="simpleSelect"
                label={label}
                disabled={disabled}
                required={required}
                value={value || defaultValue || ''}
                options={options}
                onChange={(newValue) => {
                    if (newValue != null) {
                        onChange({
                            target: {
                                value: newValue.value,
                            },
                        });
                    }
                }}
            />
        );
    }

    return (
        <FormControl error={error} className={classNameAppended}>
            {label && (
                <InputLabel shrink required={required || requiredWithExternalValidation}>
                    {label}
                </InputLabel>
            )}

            <MaterialUISelect {...props}>
                {options.length > 50
                    ? options.map((option, i) => (
                          <option key={`${option.value}-${i}`} value={option.value}>
                              {option.label}
                          </option>
                      ))
                    : options.map((option, i) => (
                          <MenuItem
                              key={`${option.value}-${i}`}
                              value={option.value}
                              disabled={option.disabled}
                          >
                              {hasCustomLabel ? <OptionLabel option={option} /> : option.label}
                          </MenuItem>
                      ))}
            </MaterialUISelect>
        </FormControl>
    );
};

export default Select;
