import React from 'react';

import { type VariantProps, cva } from 'class-variance-authority';
import clsx from 'clsx';

const typographyVariants = cva('', {
    variants: {
        variant: {
            sm: 'text-sm tracking-[0.0175em]',
            base: 'text-base tracking-[0.0175em]',
            lg: 'text-lg tracking-[0.0175em]',
            xl: 'text-xl',
            '2xl': 'text-2xl',
        },
    },
    defaultVariants: {
        variant: 'base',
    },
});

export type TypographyProps = React.HTMLAttributes<HTMLElement> &
    VariantProps<typeof typographyVariants> & {
        component?: React.ElementType;
    };

export const Typography: React.FC<TypographyProps> = ({
    variant,
    component,
    className,
    children,
    ...props
}) => {
    const Component = component || 'p';

    return (
        <Component className={clsx(typographyVariants({ variant }), className)} {...props}>
            {children}
        </Component>
    );
};

export default Typography;
