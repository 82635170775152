import { Children, ReactNode, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

const InfiniteScrollWrapper = ({
    height,
    children: childrenNode,
}: {
    height: number;
    children: ReactNode;
}) => {
    const pageLength = 50;
    const [count, setCount] = useState(pageLength);

    const children = useMemo(() => Children.toArray(childrenNode), [childrenNode]);

    const next = () => {
        setCount((prevValue) => {
            return prevValue + pageLength;
        });
    };

    const visibleChildren = useMemo(() => children.slice(0, count), [count, children]);

    return (
        <InfiniteScroll
            dataLength={count}
            next={next}
            height={height}
            hasMore={count < children.length}
            loader={<h4>Loading...</h4>}
        >
            {visibleChildren}
        </InfiniteScroll>
    );
};

export default InfiniteScrollWrapper;
