import { FC, useEffect, useMemo, useRef, useState } from 'react';

import moment from 'moment/moment';

import { BrandProductTagGroup, HydratedBrandProduct } from '@sparkplug/lib';

import BulkSetInvalidConfirmModal from '@features/product-tags/components/BulkSetInvalidConfirmModal';
import BulkTagSelector from '@features/product-tags/components/BulkTagSelector';
import { OnChangeParams, useBulkReactivateProducts } from '@features/product-tags/mutations';
import { useVendorRetailerPosData } from '@features/product-tags/queries';

import { TableProvider } from '@contexts/TableContext';

import Dropdown from '@components/dropdown/Dropdown';
import {
    CircleMinusIcon,
    InfoOutlined,
    MoreHoriz,
    RemoveCircleOutline,
    ResetIcon,
} from '@components/icons';
import Table, { TableBulkAction } from '@components/table/Table';
import { SelectedCountLabel } from '@components/tables/UsersTable/UsersTable';
import toast from '@components/toast';

import { useTableContext } from '@hooks/TableHooks';

import { appendClasses, uuid } from '@helpers/ui';

import { THeadCell } from '@app/types/TableTypes';

import ProductDetailsDrawer from '../ProductDetailsDrawer';
import { ProductTagsTableRow, UseProductTagsTableFilters } from '../useProductTagsTableFilters';

import './ProductTable.scss';

const baseHeadCells: THeadCell<ProductTagsTableRow>[] = [
    {
        id: 'select',
        type: 'checkbox',
        render: (row) => {
            const { tableSelected: selected, tableCheckUncheckRow } = useTableContext();

            return (
                <Table.Cell className="small-column" scope="row">
                    <div>
                        <Table.Checkbox
                            disabled={row?.selectionDisabled}
                            value={selected.includes(row.key)}
                            onChange={() => tableCheckUncheckRow(row)}
                        />
                    </div>
                </Table.Cell>
            );
        },
    },
    {
        id: 'name',
        sortType: 'string',
        label: 'Product Name',
        render: (row) => {
            const { tableCheckUncheckRow } = useTableContext();

            return (
                <Table.Cell onClick={() => tableCheckUncheckRow(row)}>
                    <div>{row.name}</div>
                </Table.Cell>
            );
        },
    },
    {
        id: 'brand',
        sortType: 'string',
        label: 'Brand',
        render: (row) => {
            const { tableCheckUncheckRow } = useTableContext();
            return (
                <Table.Cell onClick={() => tableCheckUncheckRow(row)}>
                    <div>{row.brand}</div>
                </Table.Cell>
            );
        },
    },
    {
        id: 'category',
        sortType: 'string',
        label: 'Category',
        render: (row) => {
            const { tableCheckUncheckRow } = useTableContext();

            return (
                <Table.Cell onClick={() => tableCheckUncheckRow(row)}>
                    <div>{row.category}</div>
                </Table.Cell>
            );
        },
    },
    {
        id: 'lastSoldAt',
        sortType: 'date',
        label: 'Last Sold Date',
        render: (row) => {
            const { tableCheckUncheckRow } = useTableContext();

            return (
                <Table.Cell onClick={() => tableCheckUncheckRow(row)}>
                    <div>{moment(row.lastSoldAt).format('L')}</div>
                </Table.Cell>
            );
        },
    },
];

const emptyCell: THeadCell<ProductTagsTableRow> = {
    id: 'none',
    render: () => <Table.Cell />,
};

const NoProductsMapped = ({ productType }: { productType: 'active' | 'invalid' }) => {
    return (
        <div className="no-products-mapped">
            {productType === 'active' ? (
                <>
                    <h4>No products mapped</h4>
                    <div>
                        This Retailer has not associated any products from their POS to this Brand
                    </div>
                </>
            ) : (
                <>
                    <h4>No invalid products</h4>
                    <div>You have not yet marked any products as invalid for this Brand</div>
                </>
            )}
        </div>
    );
};

interface AddTagsButtonProps {
    tagGroups: BrandProductTagGroup[];
    selectedIds: string[];
    handleBulkAssignProductTag: (params: OnChangeParams) => void;
    isAssigningTag: boolean;
}

export const AddTagsButton: FC<AddTagsButtonProps> = ({
    tagGroups,
    selectedIds,
    handleBulkAssignProductTag,
    isAssigningTag,
}) => {
    return (
        <BulkTagSelector
            tagGroups={tagGroups}
            selectedIds={selectedIds}
            onAssignTag={handleBulkAssignProductTag}
            disabled={isAssigningTag}
        />
    );
};

const useTableRenderKey = ({ isRefetching }: { isRefetching: boolean }) => {
    const shouldUpdateRenderKey = useRef(false);
    const didStartRefetching = useRef(false);
    const [renderKey, setRenderKey] = useState('');
    useEffect(() => {
        if (isRefetching) {
            didStartRefetching.current = true;
        } else if (shouldUpdateRenderKey.current && didStartRefetching.current && !isRefetching) {
            setRenderKey(uuid());
            shouldUpdateRenderKey.current = false;
            didStartRefetching.current = false;
        }
    }, [isRefetching]);

    return {
        renderKey,
        triggerRenderKeyUpdate: () => {
            shouldUpdateRenderKey.current = true;
        },
    };
};

export interface ProductTableProps {
    initialDataIsReady: boolean;
    vendorRetailerPosData?: HydratedBrandProduct[];
    productType: 'active' | 'invalid';
    selectedBrandLinkId?: string;
    selectedBrandId?: string;
    vendorAccountId?: string;
    retailerAccountId?: string;
    tableFilters: UseProductTagsTableFilters;
}

const ProductTable: FC<ProductTableProps> = ({
    vendorRetailerPosData = [],
    initialDataIsReady,
    productType = 'active',
    selectedBrandLinkId = '',
    selectedBrandId = '',
    vendorAccountId = '',
    retailerAccountId = '',
    tableFilters,
}) => {
    const classNamesAppended = appendClasses(['assign-tags-table']);

    const {
        applyBrandFilters,
        applyCategoryFilters,
        applyTagFilters,
        applyLast60DaysFilter,
        applySearch,
    } = tableFilters;
    const wrapperRef = useRef<HTMLElement>(null);
    const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);
    const [showConfirmInvalidateModal, setShowConfirmInvalidateModal] = useState<boolean>(false);
    const [openProduct, setOpenProduct] = useState<ProductTagsTableRow | undefined>();
    const { refetchVendorRetailerPosData, isRefetchingVendorRetailerPosData } =
        useVendorRetailerPosData(vendorAccountId, retailerAccountId);
    const { isBulkReactivating, bulkReactivateProductsAsync } = useBulkReactivateProducts(
        selectedBrandLinkId ?? '',
        vendorAccountId,
    );
    const { renderKey: bulkActionRenderKey, triggerRenderKeyUpdate } = useTableRenderKey({
        isRefetching: isRefetchingVendorRetailerPosData,
    });

    const contextMenuCell: THeadCell<ProductTagsTableRow> = {
        id: 'contextMenu',
        render: (row) => {
            return (
                <Table.Cell width={50}>
                    <Dropdown>
                        <Dropdown.IconButton color="neutral">
                            <MoreHoriz />
                        </Dropdown.IconButton>
                        <Dropdown.Menu>
                            <Dropdown.MenuItem
                                StartIcon={() => <InfoOutlined />}
                                onClick={() => setOpenProduct(row)}
                            >
                                View Product Details
                            </Dropdown.MenuItem>
                            <Dropdown.MenuDivider />
                            <Dropdown.MenuItem
                                onClick={() => {
                                    setSelectedProductIds([row._id]);
                                    setShowConfirmInvalidateModal(true);
                                }}
                                StartIcon={() => <RemoveCircleOutline />}
                                color="red"
                            >
                                Mark Invalid
                            </Dropdown.MenuItem>
                        </Dropdown.Menu>
                    </Dropdown>
                </Table.Cell>
            );
        },
    };

    const dataWithKeys = vendorRetailerPosData.length
        ? vendorRetailerPosData.map((record) => {
              return {
                  ...record,
                  key: record._id,
                  brand: record.brands[0]?.name,
                  category: record.categories[0]?.name,
              };
          })
        : [];

    const headCells = useMemo(() => {
        return productType === 'active'
            ? [...baseHeadCells, contextMenuCell]
            : [...baseHeadCells, emptyCell];
    }, [initialDataIsReady, productType, isRefetchingVendorRetailerPosData]);

    useEffect(() => {
        // reset bulk actions state by clearing checkboxes if we...
        // a) change Active vs Invalid
        // b) change what brand we are viewing
        setSelectedProductIds([]);
    }, [productType, selectedBrandId]);

    useEffect(() => {
        if (!isRefetchingVendorRetailerPosData) setSelectedProductIds([]);
    }, [isRefetchingVendorRetailerPosData]);

    const handleBulkInactive = (success: boolean) => {
        if (success) {
            refetchVendorRetailerPosData();
        }
        setSelectedProductIds([]);
        setShowConfirmInvalidateModal(false);
    };

    const handleBulkReactivate = (selectedIds: string[]) => {
        if (!selectedIds?.length) {
            toast.error('There were no rows selected to reactivate');
            return;
        }

        toast.promise(
            bulkReactivateProductsAsync(
                {
                    productIds: selectedIds,
                },
                {
                    onSuccess: () => {
                        setSelectedProductIds([]);
                        refetchVendorRetailerPosData();
                    },
                },
            ),
            {
                loading: 'Reactivating products...',
                success: `${selectedIds.length} products reactivated`,
                error: 'Error reactivating products  - please try again and contact support if this issue persists.',
            },
        );
    };

    const bulkActions: TableBulkAction[] =
        productType === 'active'
            ? [
                  SelectedCountLabel,
                  {
                      startIcon: <CircleMinusIcon />,
                      label: 'Mark Invalid',
                      buttonColor: 'red',
                      onClick: () => setShowConfirmInvalidateModal(true),
                  },
              ]
            : [
                  SelectedCountLabel,
                  {
                      startIcon: <ResetIcon />,
                      label: 'Reactivate',
                      onClick: handleBulkReactivate,
                  },
              ];

    // To avoid the table re-rendering on every row change, we need to memoize the key with rowRenderKeyFn
    const rowRenderKeyFn = (row: ProductTagsTableRow) => `${row._id}-${bulkActionRenderKey}`;
    const rowRenderClassNameFn = (row: ProductTagsTableRow) => {
        return !moment(row.lastSoldAt).isSameOrAfter(moment().subtract(60, 'days'), 'day')
            ? 'old-product'
            : '';
    };

    return (
        <TableProvider
            showCheckboxes
            controlled={{
                selected: selectedProductIds,
                setSelected: setSelectedProductIds,
            }}
            showPagination={false}
            headCells={headCells}
            rows={dataWithKeys}
            filters={[
                applySearch,
                applyBrandFilters,
                applyCategoryFilters,
                applyTagFilters,
                applyLast60DaysFilter,
            ]}
            defaultOptions={{
                orderBy: 'name',
            }}
            isLoading={!initialDataIsReady || isBulkReactivating}
        >
            <div className={classNamesAppended}>
                <Table useExternalProvider variant="raised">
                    <Table.RenderHead bulkActions={bulkActions} />
                    <Table.RenderBody
                        rowRenderKeyFn={rowRenderKeyFn}
                        rowClassNameFn={rowRenderClassNameFn}
                        emptyStateText={(() => {
                            if (vendorRetailerPosData.length === 0) {
                                return <NoProductsMapped productType={productType} />;
                            }
                            if (dataWithKeys.length === 0) {
                                return 'No Active Products';
                            }
                            return 'No products with these filters';
                        })()}
                        dynamicHeight={{
                            infiniteScroll: true,
                            containerRef: wrapperRef,
                            scrollToTopElement: {
                                isFixed: true,
                                getText: (itemCount) =>
                                    `Viewing ${itemCount} product${itemCount === 1 ? '' : 's'}`,
                            },
                        }}
                        highlightRowOnHover
                    />
                </Table>
                <BulkSetInvalidConfirmModal
                    selectedBrandLinkId={selectedBrandLinkId}
                    vendorAccountId={vendorAccountId}
                    isVisible={showConfirmInvalidateModal}
                    onClose={handleBulkInactive}
                />
                {openProduct && (
                    <ProductDetailsDrawer
                        product={openProduct}
                        onClose={() => setOpenProduct(undefined)}
                    />
                )}
            </div>
        </TableProvider>
    );
};

export default ProductTable;
