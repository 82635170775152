import { useCallback, useState } from 'react';

import { FormControl } from '@mui/material';

import Button from '@components/buttons/Button';
import Chip from '@components/chips/Chip';
import InputLabel from '@components/inputs/InputLabel';

import { appendClasses, uuid } from '@helpers/ui';

import TextField from '../TextField';

import './ChipListInput.scss';

export interface IChipListItem {
    value: string;
    label: string;
    color?: string;
}

export interface IChipListInputProps {
    className?: string;
    color: 'neutral' | 'blue' | 'green' | 'red';
    variant: 'flat' | 'filled' | 'raised' | 'smooth' | 'outlined';
    label?: string;
    values: IChipListItem[];
    onChange: (values: IChipListItem[]) => void;
}

const ChipListInput = ({
    className,
    label,
    values,
    onChange,
    variant,
    color,
}: IChipListInputProps) => {
    const hasValues = values != null && values.length > 0;
    const classes = appendClasses([
        'form-control',
        'form-control-custom',
        'chip-list-input',
        `chip-list-input-color-${color}`,
        `chip-list-input-variant-${variant}`,
        hasValues ? 'has-items' : null,
        className,
    ]);

    const [newItemName, setNewItemName] = useState<string>('');

    const onDelete = useCallback(
        (i: number) => {
            const newValues = [...values];
            newValues.splice(i, 1);
            onChange(newValues);
        },
        [values, onChange],
    );

    const onAddItemName = useCallback(() => {
        if (newItemName != null && newItemName.length > 0) {
            const newValues = [...values];
            newValues.push({
                value: newItemName,
                label: newItemName,
            });
            setNewItemName('');
            onChange(newValues);
        }
    }, [values, newItemName, setNewItemName, onChange]);

    const onKeyDown = useCallback(
        (event: any) => {
            if (event.key === 'Enter') {
                onAddItemName();
            }
        },
        [setNewItemName],
    );

    return (
        <FormControl className={classes}>
            {label && <InputLabel shrink>{label}</InputLabel>}

            <div className="chip-list">
                {hasValues &&
                    values.map((itemName, idx) => (
                        <div key={idx} className="chip-list-item">
                            <Chip
                                label={itemName.label}
                                color="neutral"
                                onDelete={() => onDelete(idx)}
                                className="name"
                            />
                        </div>
                    ))}
            </div>

            <div className="chip-list-input-field">
                <TextField
                    name={uuid()}
                    value={newItemName}
                    onChange={(event) => setNewItemName(event.target.value)}
                    onKeyDown={onKeyDown}
                />

                <Button
                    color="blue"
                    variant="smooth"
                    onClick={onAddItemName}
                    disabled={newItemName.length === 0}
                >
                    Add
                </Button>
            </div>
        </FormControl>
    );
};

ChipListInput.defaultProps = {
    className: null,
    variant: 'raised',
    color: 'blue',
};

export default ChipListInput;
