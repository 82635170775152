import { useMemo } from 'react';

import { parsePhoneNumber } from 'libphonenumber-js';

import {
    DomesticWithdrawalTypes,
    InternationalWithdrawalTypes,
    WithdrawalType,
} from '@sparkplug/lib';

import { ALL_AVAILABLE_METHODS, CashOutDetailsType } from '@features/wallet/constants';

import { useApp } from '@hooks/AppHooks';

const isCashOutDisabled = ({
    method,
    balance,
}: {
    method: WithdrawalType;
    balance: number;
}): boolean => {
    return method === 'Virtual Visa' ? balance > 1000 : balance > 2000;
};
const getCashOutDescription = ({
    method,
    fee,
}: {
    method: WithdrawalType;
    fee?: number;
}): string => {
    const feeLabel = fee ? `${fee * 100}% fee` : 'No fee';
    return method === 'Virtual Visa' ? `$1,000 limit - ${feeLabel}` : `$2,000 limit - ${feeLabel}`;
};

export const useCashOutOptions = (balance: number = 0) => {
    const { user } = useApp();

    return useMemo(() => {
        const isInternational =
            user?.phoneNumber && parsePhoneNumber(user.phoneNumber)?.country !== 'US';
        const availableMethods: [WithdrawalType, CashOutDetailsType][] = isInternational
            ? InternationalWithdrawalTypes.map((m) => [m, ALL_AVAILABLE_METHODS[m]])
            : DomesticWithdrawalTypes.map((m) => [m, ALL_AVAILABLE_METHODS[m]]);

        return availableMethods.map(([method, details]) => ({
            value: method,
            label: details.label,
            icon: details.icon,
            disabled: isCashOutDisabled({ method: method as WithdrawalType, balance }),
            description: getCashOutDescription({
                method: method as WithdrawalType,
                fee: details.fee,
            }),
        }));
    }, [ALL_AVAILABLE_METHODS, balance, user?.phoneNumber]);
};
