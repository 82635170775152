import { useCallback, useEffect, useRef } from 'react';

import Form from '@components/form/Form';
import Grid from '@components/layout/Grid';
import RestrictedWordsModal, {
    containsRestrictedWordsByRef,
} from '@components/overlays/RestrictedWordsModal';

import { useModal } from '@hooks/ModalHooks';

const SMSMessageFormFields = ({
    message,
    setMessage,
}: {
    message: string;
    setMessage: (message: string) => void;
}) => {
    const { updateValidationFn } = useModal();

    const restrictedWordsRef = useRef();

    const checkValidation = useCallback(() => {
        const validation = [() => containsRestrictedWordsByRef(restrictedWordsRef, message)];

        const allValid = validation.every((fn) => fn());

        return allValid;
    }, [message]);

    useEffect(() => {
        updateValidationFn(checkValidation);
    }, [checkValidation, updateValidationFn]);

    return (
        <>
            <Grid className="details-container">
                <Grid.Item lg={12}>
                    <Form.TextField
                        multiline
                        rows={6}
                        label="SMS Message"
                        name="message"
                        required
                        defaultValue={message}
                        onChange={(event) => setMessage(event.target.value)}
                        helperText={`${message?.length || 0} / 160`}
                    />
                </Grid.Item>
            </Grid>

            <RestrictedWordsModal ref={restrictedWordsRef} />
        </>
    );
};

export default SMSMessageFormFields;
