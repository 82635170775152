import { ComponentType, MouseEvent, createContext, useContext } from 'react';

interface DropdownContextValue {
    anchorEl?: HTMLElement;
    handleClick: (event: MouseEvent<HTMLButtonElement>) => void;
    handleClose: () => void;
}
export const DropdownContext = createContext<DropdownContextValue>({
    handleClick: () => {},
    handleClose: () => {},
});

export const useDropdown = () => useContext(DropdownContext);
export const withDropdown =
    <Props extends {}>(Component: ComponentType<Props>) =>
    (props: Props) => {
        const dropdown = useDropdown();

        return <Component {...dropdown} {...props} />;
    };

export const InternalMenuContext = createContext<{
    activeMenuItemId?: string;
    updateActiveMenuItemId: (activeElement?: string) => void;
}>({ updateActiveMenuItemId: () => {} });
export const useInternalMenuContext = () => useContext(InternalMenuContext);
