import { FC, PropsWithChildren } from 'react';

import { appendClasses } from '@helpers/ui';

import { useIsInToolbar } from '../Toolbar/useIsInToolbar';
import ToolbarGroup from '../ToolbarGroup';

import './ToolbarText.scss';

interface IToolbarTextProps {
    className?: string;
}

const ToolbarText: FC<PropsWithChildren<IToolbarTextProps>> = ({ className, children }) => {
    useIsInToolbar({ componentName: 'Toolbar.Text' });
    const classNameAppended = appendClasses([className, 'toolbar-text']);

    return (
        <ToolbarGroup className={classNameAppended}>
            <span>{children}</span>
        </ToolbarGroup>
    );
};

export default ToolbarText;
