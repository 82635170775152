import { Dispatch, ReactNode, SetStateAction, createContext, useMemo } from 'react';

import { useTable } from '@hooks/TableHooks';

import { ITableContext, ITableRow, TDefaultTableOptions, THeadCell } from '@app/types/TableTypes';
import { TApplyFn } from '@app/types/UITypes';

export const TableContext = createContext({} as ITableContext<{}>);

export interface ITableProvider<T> {
    isLoading?: boolean;
    defaultSelected?: string[];
    rows?: ITableRow<T>[];
    headCells?: THeadCell<T>[];
    defaultOptions?: TDefaultTableOptions;
    filters?: TApplyFn[];
    children: ReactNode;
    showBulkEditor?: boolean;
    showPagination?: boolean;
    showRowActions?: boolean;
    showCheckboxes?: boolean;
    footerComponent?: ReactNode;
    enableColumnResizing?: boolean;
    controlled?: {
        selected: string[];
        setSelected: Dispatch<SetStateAction<string[]>>;
    };
    enableQueryParams?: boolean;
    rowCountOverride?: number;
    disableFrontendFiltering?: boolean;
}

export const TableProvider = <T extends {}>({
    isLoading = false,
    defaultSelected,
    rows = [],
    headCells = [],
    defaultOptions = {},
    filters = [],
    children,
    showBulkEditor = false,
    showPagination = true,
    showRowActions = false,
    showCheckboxes = false,
    enableColumnResizing = false,
    footerComponent,
    controlled,
    enableQueryParams,
    rowCountOverride,
    disableFrontendFiltering,
}: ITableProvider<T>) => {
    const table = useTable<T>({
        rows,
        headData: headCells,
        filters,
        defaultOptions,
        isLoading,
        defaultSelected,
        controlled,
        enableColumnResizing,
        enableQueryParams,
        disableFrontendFiltering,
    });

    const value = useMemo(() => {
        return {
            ...table,
            tableShowBulkEditor: showBulkEditor,
            tableShowPagination: showPagination,
            tableShowRowActions: showRowActions,
            tableShowCheckboxes: showCheckboxes,
            footerComponent,
            rowCountOverride,
        };
    }, [table, showPagination, showRowActions, showCheckboxes]);

    return (
        <TableContext.Provider value={value as unknown as ITableContext<{}>}>
            {children}
        </TableContext.Provider>
    );
};
