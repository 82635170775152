import { ReactNode, useEffect, useMemo, useState } from 'react';

import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import moment from 'moment';

import { Spark } from '@sparkplug/lib';

import ClickableArea from '@components/layout/ClickableArea';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { formatSparkInfo } from '@helpers/sparks';
import { appendClasses } from '@helpers/ui';
import { getDaysBetweenDates } from '@helpers/util';

import { IChartDataSettings } from '@app/types/ChartDataTypes';

import CompressedSparkCard from './CompressedSparkCard';

import './SparkTimeline.scss';

const TooltipWithTransparentBackground = withStyles({
    tooltip: {
        background: 'transparent',
        maxWidth: 'fit-content',
    },
})(Tooltip);

const SparkTimelineTooltip = ({ spark, children }: { spark: Spark; children: ReactNode }) => {
    return (
        <TooltipWithTransparentBackground
            className="spark-tooltip__container"
            title={<CompressedSparkCard spark={spark} />}
            placement="top"
        >
            <div className="spark-timeline__segment-content">{children}</div>
        </TooltipWithTransparentBackground>
    );
};

interface ISparkTimelineSegmentProps {
    spark: Spark;
    start: number;
    width: number;
    startsOutOfRange: boolean;
    endsOutOfRange: boolean;
}
const SparkTimelineSegment = ({
    spark,
    start,
    width,
    startsOutOfRange,
    endsOutOfRange,
}: ISparkTimelineSegmentProps) => {
    const { startDate, endDate, requestState, _id: sparkId, recurringSchedule } = spark;

    const { status } = useMemo(
        () => formatSparkInfo(startDate, endDate, requestState, recurringSchedule),
        [spark],
    );
    const className = appendClasses([
        'spark-timeline__segment',
        startsOutOfRange ? 'out-of-range-left' : '',
        endsOutOfRange ? 'out-of-range-right' : '',
        status ? `status-${status.toLowerCase()}` : '',
    ]);

    const { account } = useSparkplugAccount();
    const { history } = useApp();
    const goToSpark = () => {
        history.push(`/${account?._id}/sparks/${sparkId}`, {
            prevUrl: document.location.pathname,
        });
    };

    const runTimeStyles = {
        width: `${width}%`,
        left: `${start}%`,
    };

    return (
        <ClickableArea className={className} style={runTimeStyles} onClick={goToSpark}>
            <SparkTimelineTooltip spark={spark}>{spark.name}</SparkTimelineTooltip>
        </ClickableArea>
    );
};

interface ISparkTimelineProps {
    sparkTimelineRows: ISparkTimelineSegmentProps[][];
    chartSettings: IChartDataSettings;
    useChartTicks: boolean;
}
const SparkTimeline = ({
    sparkTimelineRows,
    chartSettings,
    useChartTicks,
}: ISparkTimelineProps) => {
    const [width, setWidth] = useState<number>();
    const [offsetLeft, setOffsetLeft] = useState<number>();
    const captureTimelineDimensions = () => {
        if (useChartTicks) {
            // Note:
            // - Line Graphs: Align the timeline with the full chart width
            // - Bar Graphs: Align the timeline with the first and last bars

            const isLineGraph = chartSettings.type === 'line';

            const chartContainerStartX = document
                .querySelector('.chart-container')
                ?.getBoundingClientRect()?.left;

            const chartSelector = 'svg > g > g:first-of-type';
            const firstBarSelector = 'svg > g > g:nth-of-type(4)';
            const lastBarSelector = 'svg > g > g:last-of-type rect';

            const chartStartX = document
                .querySelector(isLineGraph ? chartSelector : firstBarSelector)
                ?.getBoundingClientRect()?.left;

            const chartEndX = document
                .querySelector(isLineGraph ? chartSelector : lastBarSelector)
                ?.getBoundingClientRect()?.right;

            if (chartContainerStartX && chartEndX && chartStartX) {
                setOffsetLeft(chartStartX - chartContainerStartX);
                setWidth(chartEndX - chartStartX);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('resize', captureTimelineDimensions);
        return () => window.removeEventListener('resize', captureTimelineDimensions);
    }, []);

    useEffect(() => {
        // The `setTimeout` here allows us to defer this function on the callstack
        // so the DOM can render before we try to reference the necessary DOM nodes
        // otherwise this effect would run too quickly
        const deferredCaptureInvocation = setTimeout(captureTimelineDimensions);

        return () => clearTimeout(deferredCaptureInvocation);
    }, [chartSettings]);

    const runTimeStyles = {
        width,
        left: offsetLeft,
    };

    if (useChartTicks && !width) {
        return <></>;
    }

    const maxValues = window.innerWidth < 767 ? 5 : 15;
    const dates = getDaysBetweenDates(chartSettings.dateStart, chartSettings.dateEnd).map((date) =>
        moment(date).format('M/D'),
    );

    const modBy = Math.max(1, Math.floor(dates.length / maxValues));
    const showTickValue = (i: number) => i % modBy === 0;

    return (
        <div className="spark-timeline__container">
            <div
                className="spark-timeline__content"
                style={useChartTicks ? runTimeStyles : undefined}
            >
                {sparkTimelineRows.map((sparkTimelineRow, i) => (
                    <section
                        className="spark-timeline__row"
                        key={`${i}-${sparkTimelineRow[0]?.spark?._id}`}
                    >
                        {sparkTimelineRow.map((segmentProps: ISparkTimelineSegmentProps) => (
                            <SparkTimelineSegment key={segmentProps.spark._id} {...segmentProps} />
                        ))}
                    </section>
                ))}
                {!useChartTicks && (
                    <section className="spark-timeline__ticks">
                        {dates.map((date, i) => (
                            <div
                                key={date}
                                className="spark-timeline__tick"
                                style={{ width: `${100 / dates.length}%` }}
                            >
                                {showTickValue(i) && <span>{date}</span>}
                            </div>
                        ))}
                    </section>
                )}
            </div>
        </div>
    );
};

export default SparkTimeline;
