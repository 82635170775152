import { ComponentType, Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';

import { uniqBy } from 'lodash';
import moment from 'moment';

import { SAMPLE_TEXT_TARGET, Spark, formatCurrency } from '@sparkplug/lib';

import { TableProvider } from '@contexts/TableContext';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import {
    CalendarToday as CalendarIcon,
    FilterList as FilterIcon,
    Info as InfoIcon,
} from '@components/icons';
import Tooltip from '@components/layout/Tooltip';
import Table from '@components/table/Table';
import Toolbar from '@components/toolbar/Toolbar';

import { useModal } from '@hooks/ModalHooks';
import { useTableContext } from '@hooks/TableHooks';
import { useSearch } from '@hooks/UIHooks';

import { appendClasses, sortByString, wrapSubstringWithComponent } from '@helpers/ui';

import { IPosProduct } from '@app/types/PosTypes';
import { THeadCell } from '@app/types/TableTypes';

import {
    LAST_SOLD_DATE_OPTIONS,
    SHOW_NEW_PRODUCTS_BY_LAST_SOLD_DATE,
    SampleText,
    onlyShowProductsFilterLabel,
} from '../constants';

import './SparkProductSelectorTable.scss';

const coreHeadCells: THeadCell<IPosProduct & { key: string; category?: string; brand?: string }>[] =
    [
        {
            id: 'select',
            type: 'checkbox',
            render: (row) => {
                const { tableSelected: selected, tableCheckUncheckRow } = useTableContext();

                return (
                    <Table.Cell scope="row">
                        <div>
                            <Table.Checkbox
                                disabled={row?.selectionDisabled}
                                value={selected.includes(row.key)}
                                onChange={() => tableCheckUncheckRow(row)}
                            />
                        </div>
                    </Table.Cell>
                );
            },
        },
        {
            id: 'name',
            sortType: 'string',
            label: 'Products',
            render: (row) => {
                const { tableCheckUncheckRow } = useTableContext();
                const hasSample = row.name.toLowerCase().includes(SAMPLE_TEXT_TARGET);

                return (
                    <Table.Cell onClick={() => tableCheckUncheckRow(row)}>
                        <div>
                            {hasSample
                                ? wrapSubstringWithComponent(
                                      SAMPLE_TEXT_TARGET,
                                      row.name,
                                      SampleText,
                                  )
                                : row.name}
                        </div>
                    </Table.Cell>
                );
            },
        },
        {
            id: 'category',
            sortType: 'string',
            label: 'Category',
            render: (row) => {
                const { tableCheckUncheckRow } = useTableContext();
                const hasSample = row.category?.toLowerCase().includes(SAMPLE_TEXT_TARGET);

                return (
                    <Table.Cell onClick={() => tableCheckUncheckRow(row)}>
                        <div>
                            {hasSample
                                ? wrapSubstringWithComponent(
                                      SAMPLE_TEXT_TARGET,
                                      row.category!,
                                      SampleText,
                                  )
                                : row.category ?? '--'}
                        </div>
                    </Table.Cell>
                );
            },
        },
        {
            id: 'brand',
            sortType: 'string',
            label: 'Brand',
            render: (row) => {
                const { tableCheckUncheckRow } = useTableContext();
                return (
                    <Table.Cell onClick={() => tableCheckUncheckRow(row)}>
                        <div>{row.brand ?? '--'}</div>
                    </Table.Cell>
                );
            },
        },
        {
            id: 'lastSoldAt',
            sortType: 'string',
            label: () => (
                <>
                    <CalendarIcon className="calendar-icon" />
                    <span>Last Sold Date</span>
                </>
            ),
            render: (row) => {
                const { tableCheckUncheckRow } = useTableContext();
                return (
                    <Table.Cell onClick={() => tableCheckUncheckRow(row)}>
                        <div>
                            {row.lastSoldAt ? moment(row.lastSoldAt).format('MM/DD/YYYY') : '--'}
                        </div>
                    </Table.Cell>
                );
            },
        },
    ];

const priceHeadCell: THeadCell<
    IPosProduct & { key: string; category?: string; brand?: string; disableSelect?: boolean }
> = {
    id: 'price',
    label: 'Price',
    sortType: 'numeric',
    render: (row) => {
        const { tableCheckUncheckRow } = useTableContext();
        return (
            <Table.Cell onClick={() => tableCheckUncheckRow(row)}>
                <div>{row.price ? formatCurrency(row.price, true) : '--'}</div>
            </Table.Cell>
        );
    },
};

const ProductsSelected = ({ onViewAll }: { onViewAll: () => void }) => {
    const { tableSelected, tableFilteredRows, tableRows } = useTableContext();

    return (
        <div className="products-selected">
            <div>
                <strong>{`${tableSelected.length} products selected `}</strong>
                <span>{`of ${tableFilteredRows.length} matches`}</span>
            </div>
            <div>
                <span>{`${tableRows.length} products available`}</span>
                {tableFilteredRows.length < tableRows.length && (
                    <Button onClick={onViewAll} variant="flat">
                        See All
                    </Button>
                )}
            </div>
        </div>
    );
};

export interface ProductTableFilters {
    brandIds: string[];
    categoryIds: string[];
    lastSoldAt: '-60days' | '-90days' | '-120days' | 'allTime';
    hideSampleProducts: boolean;
    onlyShowNewProducts: boolean;
}

export const useProductTableFilters = ({
    spark,
    initialFilterValues,
    allBrandsLength = 0,
}: {
    spark: Spark;
    initialFilterValues?: Partial<ProductTableFilters>;
    allBrandsLength?: number;
}) => {
    const { applySearch, onChangeSearchFilter } = useSearch(['name', 'brand', 'category']);

    const [filters, setFilters] = useState<ProductTableFilters>({
        brandIds: [],
        categoryIds: [],
        lastSoldAt: '-60days',
        hideSampleProducts: true,
        onlyShowNewProducts: false,
        ...initialFilterValues,
    });

    const applyHideSampleProducts = useCallback(
        (rows: IPosProduct[]) => {
            return filters.hideSampleProducts
                ? rows.filter(({ label, categories }) => {
                      const category = categories?.[0]?.name ?? '';
                      return (
                          !label.toLowerCase().includes(SAMPLE_TEXT_TARGET) &&
                          !category?.toLowerCase().includes(SAMPLE_TEXT_TARGET)
                      );
                  })
                : rows;
        },
        [filters.hideSampleProducts],
    );

    const applyBrandFilters = useCallback(
        (rows: IPosProduct[]) => {
            if (!filters.brandIds.length || filters.brandIds?.length === allBrandsLength) {
                return rows;
            }

            return rows.filter(({ brandId }) => filters.brandIds.includes(brandId ?? ''));
        },
        [filters.brandIds],
    );

    const applyCategoryFilters = useCallback(
        (rows: IPosProduct[]) => {
            if (!filters.categoryIds.length) {
                return rows;
            }

            return rows.filter(({ categoryId }) => filters.categoryIds.includes(categoryId ?? ''));
        },
        [filters.categoryIds.length],
    );

    const sparkStartDate = useMemo(
        () => (moment(spark?.startDate).isSameOrBefore(moment()) ? spark?.startDate : moment()),

        [spark?.startDate],
    );

    const applyLastSoldFilter = useCallback(
        (rows: IPosProduct[]) => {
            if (filters.lastSoldAt === 'allTime') {
                return rows;
            } else {
                const { onApply = () => () => true } =
                    LAST_SOLD_DATE_OPTIONS.find(({ value }) => filters.lastSoldAt === value) ?? {};
                const onApplyWithCutoff = onApply(sparkStartDate);
                return rows.filter(({ lastSoldAt }) => onApplyWithCutoff(lastSoldAt));
            }
        },
        [filters.lastSoldAt],
    );

    const applyOnlyShowNewProductsFilter = useCallback(
        (rows: IPosProduct[]) => {
            const key = SHOW_NEW_PRODUCTS_BY_LAST_SOLD_DATE ? 'lastSoldAt' : 'createdAt';

            if (!filters.onlyShowNewProducts || !spark.createdAt) {
                return rows;
            }

            return rows.filter((product) => {
                const productCriteria = product[key];
                return moment(productCriteria).isAfter(spark.createdAt);
            });
        },
        [filters.onlyShowNewProducts, spark?.createdAt],
    );

    const onViewAll = () => {
        setFilters({
            brandIds: [],
            categoryIds: [],
            lastSoldAt: 'allTime',
            hideSampleProducts: false,
            onlyShowNewProducts: false,
        });
    };

    return useMemo(
        () => ({
            filters,
            setFilters,
            applyHideSampleProducts,
            applyBrandFilters,
            applyCategoryFilters,
            applyLastSoldFilter,
            applyOnlyShowNewProductsFilter,
            applySearch,
            onViewAll,
            onChangeSearchFilter,
        }),
        [filters, spark?.startDate, onChangeSearchFilter],
    );
};

export type UseSparkProductSelectorTableFilters = ReturnType<typeof useProductTableFilters>;

interface SparkProductSelectorTableProps {
    controlled?: {
        selected: string[];
        setSelected: Dispatch<SetStateAction<string[]>>;
    };
    products: IPosProduct[];
    defaultSelectedProductIds?: string[];
    hideCategoryFilter?: boolean;
    hideBrandFilter?: boolean;
    showDropdownMenuFilters?: boolean;
    showPriceColumn?: boolean;
    tableFilters: UseSparkProductSelectorTableFilters;
    ModalNext: ComponentType<{}>;
}

export const SparkProductSelectorTable: FC<SparkProductSelectorTableProps> = ({
    controlled,
    products,
    defaultSelectedProductIds,
    hideCategoryFilter,
    hideBrandFilter,
    showDropdownMenuFilters,
    showPriceColumn,
    tableFilters,
    ModalNext,
}) => {
    const { modalContentRef } = useModal();
    const {
        filters,
        setFilters,
        applyHideSampleProducts,
        applyBrandFilters,
        applyCategoryFilters,
        applyLastSoldFilter,
        applySearch,
        applyOnlyShowNewProductsFilter,
        onViewAll,
        onChangeSearchFilter,
    } = tableFilters;

    const productRows = useMemo(() => {
        return products.map((product) => {
            const category = product.categories?.find(
                ({ _id }) => _id === product.categoryId,
            )?.name;
            const brand = product.brands?.find(({ _id }) => _id === product.brandId)?.name;
            return {
                ...product,
                key: product._id,
                category,
                brand,
            };
        });
    }, [products]);

    const { brandOptions, categoryOptions } = useMemo(() => {
        const options = products.reduce<{
            brands: { _id: string; name: string }[];
            categories: { _id: string; name: string }[];
        }>(
            (res, product) => {
                const category = product.categories?.find(({ _id }) => _id === product.categoryId);
                const brand = product.brands?.find(({ _id }) => _id === product.brandId);

                if (category) {
                    res.categories.push(category);
                }

                if (brand) {
                    res.brands.push(brand);
                }

                return res;
            },
            {
                brands: [],
                categories: [],
            },
        );

        return {
            brandOptions: uniqBy(options.brands, '_id')
                .map(({ _id, name }) => ({
                    value: _id,
                    label: name,
                }))
                .sort(sortByString('label', 'asc')),
            categoryOptions: uniqBy(options.categories, '_id')
                .map(({ _id, name }) => ({
                    value: _id,
                    label: name,
                }))
                .sort(sortByString('label', 'asc')),
        };
    }, [products]);

    const headCells = [...coreHeadCells];

    if (showPriceColumn) {
        headCells.splice(headCells.length - 1, 0, priceHeadCell);
    }

    const classNamesAppended = appendClasses([
        'spark-product-selector-table-wrapper',
        showPriceColumn ? 'show-prices-column' : '',
    ]);

    return (
        <TableProvider
            showCheckboxes
            showPagination={false}
            defaultSelected={defaultSelectedProductIds}
            headCells={headCells}
            rows={productRows}
            controlled={controlled}
            filters={[
                applySearch,
                applyHideSampleProducts,
                applyBrandFilters,
                applyCategoryFilters,
                applyLastSoldFilter,
                applyOnlyShowNewProductsFilter,
            ]}
            defaultOptions={{
                orderBy: 'name',
            }}
        >
            <div className={classNamesAppended}>
                <>
                    <Form.Label>Select Products</Form.Label>
                    <ProductsSelected onViewAll={onViewAll} />
                    <Toolbar justifyContentStart>
                        <Toolbar.Search
                            name="brand-spark-product-selector_search"
                            onChange={onChangeSearchFilter}
                        />
                        {!hideCategoryFilter && (
                            <Toolbar.MultiSelectDropdown
                                label="Categories"
                                options={categoryOptions}
                                selected={filters.categoryIds}
                                onApply={(value) =>
                                    setFilters((prevValue) => ({
                                        ...prevValue,
                                        categoryIds: value,
                                    }))
                                }
                            />
                        )}
                        {!hideBrandFilter && (
                            <Toolbar.MultiSelectDropdown
                                label="Brands"
                                options={brandOptions}
                                selected={filters.brandIds}
                                onApply={(value) =>
                                    setFilters((prevValue) => ({
                                        ...prevValue,
                                        brandIds: value,
                                    }))
                                }
                            />
                        )}
                        <Toolbar.Dropdown
                            label=""
                            value={filters.lastSoldAt}
                            options={LAST_SOLD_DATE_OPTIONS}
                            onSelect={(value) =>
                                setFilters((prevValue) => ({
                                    ...prevValue,
                                    lastSoldAt: value,
                                }))
                            }
                            clear={{
                                active: filters.lastSoldAt !== 'allTime',
                                onClear: () =>
                                    setFilters((prevValue) => ({
                                        ...prevValue,
                                        lastSoldAt: 'allTime',
                                    })),
                            }}
                        />
                        <Toolbar.Checkbox
                            label={
                                <>
                                    {'Hide '}
                                    <strong>“Sample”</strong>
                                    {' products'}
                                </>
                            }
                            value={filters.hideSampleProducts}
                            onChange={() =>
                                setFilters((prevValue) => ({
                                    ...prevValue,
                                    hideSampleProducts: !filters.hideSampleProducts,
                                }))
                            }
                        />
                        <Tooltip
                            title='This will hide all products that have the word "Sample" in their product name or category'
                            placement="top"
                        >
                            <InfoIcon className="tooltip-icon" />
                        </Tooltip>

                        {showDropdownMenuFilters && (
                            <Toolbar.DropdownCheckboxMenu
                                alignEnd
                                icon={<FilterIcon />}
                                menuItems={[
                                    {
                                        label: onlyShowProductsFilterLabel,
                                        isActive: filters.onlyShowNewProducts,
                                        onChange: (isActive) =>
                                            setFilters((prevValue) => ({
                                                ...prevValue,
                                                onlyShowNewProducts: isActive,
                                            })),
                                    },
                                ]}
                            />
                        )}
                    </Toolbar>
                </>
                <Table useExternalProvider variant="smooth">
                    <Table.RenderHead />
                    <Table.RenderBody
                        emptyStateText="No products with these filters"
                        dynamicHeight={{
                            infiniteScroll: true,
                            containerRef: modalContentRef,
                            scrollToTopElement: {
                                getText: (itemCount) => `Viewing ${itemCount} products`,
                            },
                        }}
                        highlightRowOnHover
                    />
                </Table>
            </div>
            <ModalNext />
        </TableProvider>
    );
};

export default SparkProductSelectorTable;
