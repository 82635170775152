import { ComponentProps } from 'react';

import { Avatar as MuiAvatar } from '@mui/material';

interface IAvatarProps {
    src?: string;
    firstName?: string;
}

const Avatar = ({ src, firstName }: IAvatarProps) => {
    const props: ComponentProps<typeof MuiAvatar> = {
        className: 'avatar',
        src,
    };

    if (src == null && firstName != null) {
        props.children = firstName.charAt(0).toUpperCase();
    }

    return <MuiAvatar {...props} />;
};

export default Avatar;
