import { z } from 'zod';

export const tableKeys = ['spark-dashboard-retailer', 'spark-dashboard-vendor'] as const;
export type TableKey = typeof tableKeys[number];

export type ColumnDetail = {
  label: string;
  value: string;
  isChecked: boolean;
};

export interface PublicTableSettings {
  _id: string;
  tableKey: TableKey;
  userId: string;
  columns: ColumnDetail[];
  deletedAt?: string;
  createdAt?: string;
  updatedAt?: string;
}

const columnDetailSchema = z.object({
  label: z.string(),
  value: z.string(),
  isChecked: z.boolean(),
});
export const upsertTableSettingsSchema = z.object({
  columns: z.array(columnDetailSchema),
});

export type UpsertTableSettingsRequestBody = z.infer<typeof upsertTableSettingsSchema>;

export interface GetTableSettingsRequestParams {
  tableKey: TableKey;
}

export type GetTableSettingsRequestBody = Omit<UpsertTableSettingsRequestBody, 'columns'>;

export type GetTableSettingsResponseBody = PublicTableSettings;
