import { getShopifyAuthURL } from '@core/accounts';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';

import Modal from './Modal';

const IntegrationSubdomainModal = ({
    isVisible,
    onClose,
}: {
    isVisible: boolean;
    onClose: any;
}) => {
    const onSave = ({ subdomain }: { subdomain: string }) => {
        const url = getShopifyAuthURL(subdomain);
        window.location.assign(url);
    };

    return (
        <Modal isVisible={isVisible} onClose={onClose} className="integration-subdomain-modal">
            <Form>
                <Modal.Title>Shop Subdomain</Modal.Title>

                <Modal.Content>
                    <p>Please provide your Shopify subdomain</p>
                    <Form.TextField
                        label="Subdomain"
                        name="subdomain"
                        endIcon=".myshopify.com"
                        required
                        autoFocus
                    />
                </Modal.Content>

                <Modal.Actions>
                    <Button color="neutral" variant="flat" onClick={onClose}>
                        Cancel
                    </Button>
                    <Form.Button color="blue" variant="flat" onClick={onSave}>
                        Submit
                    </Form.Button>
                </Modal.Actions>
            </Form>
        </Modal>
    );
};

export default IntegrationSubdomainModal;
