import EventTable from '@features/events/components/EventTable';

import CalloutMessage from '@components/layout/CalloutMessage';

export const VendorScheduledEvents = () => {
    return (
        <div data-testid="vendor-scheduled-events" className="flex flex-col gap-4">
            <CalloutMessage message="Planning a pop-up, promo or sampling? Schedule an event at one of your Retail partner locations to keep employees informed and engaged." />
            <EventTable />
        </div>
    );
};
