import { FC } from 'react';

import { Spark } from '@sparkplug/lib';

import SparkAwardsPanel from '@components/sparks/SparkAwardsPanel';
import SparkCommissionPayouts from '@components/sparks/SparkCommissionPayouts';

import { IPosProduct } from '@app/types/PosTypes';
import { ISparkCommissionMap } from '@app/types/SparksTypes';

interface SparkRewardsPanelProps {
    spark: Spark;
    associatedProducts: IPosProduct[];
    sparkCommissionMap?: ISparkCommissionMap;
}

export const SparkRewardsPanel: FC<SparkRewardsPanelProps> = ({
    spark,
    associatedProducts,
    sparkCommissionMap,
}) => {
    if (['goal', 'leaderboard'].includes(spark.type)) {
        return <SparkAwardsPanel spark={spark} />;
    } else if (spark.type === 'commission') {
        return (
            <SparkCommissionPayouts
                spark={spark}
                associatedProducts={associatedProducts}
                sparkCommissionMap={sparkCommissionMap}
                paginated={false}
            />
        );
    }

    return null;
};
