import { FC, ReactElement } from 'react';

import Dropdown from '@components/dropdown/Dropdown';
import Checkbox from '@components/inputs/Checkbox';

import ToolbarGroup, { ToolbarGroupProps } from '../ToolbarGroup';

import './ToolbarDropdownCheckboxMenu.scss';

interface ToolbarDropdownCheckboxMenuProps extends ToolbarGroupProps {
    icon: ReactElement;
    menuItems: {
        label: string;
        isActive: boolean;
        onChange: (isActive: boolean) => void;
    }[];
}

const ToolbarDropdownCheckboxMenu: FC<ToolbarDropdownCheckboxMenuProps> = ({
    icon,
    menuItems,
    ...toolbarGroupProps
}) => {
    return (
        <ToolbarGroup {...toolbarGroupProps}>
            <Dropdown>
                <Dropdown.IconButton>{icon}</Dropdown.IconButton>
                <Dropdown.Menu>
                    {menuItems.map(({ label, isActive, onChange }) => (
                        <Dropdown.MenuItem key={label} disableCloseOnClick>
                            <Checkbox
                                label={label}
                                value={isActive}
                                onChange={(event, checked) => onChange(!!checked)}
                            />
                        </Dropdown.MenuItem>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </ToolbarGroup>
    );
};

export default ToolbarDropdownCheckboxMenu;
