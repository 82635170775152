import { TrainingCourseAPI } from '@api/TrainingCourseAPI';

import { ITrainingCourse } from '@sparkplug/lib';

import toast from '@components/toast';

export const validateZoltrainCourse = async (courseData: ITrainingCourse): Promise<string> => {
    return new Promise((resolve, reject) => {
        toast.promise(
            TrainingCourseAPI.validateWithZoltrain(courseData.courseId, courseData.accessToken),
            {
                loading: 'Validating training course',
                success: (result: any) => {
                    const courseTitle = result?.data?.title || '';
                    resolve(courseTitle);
                    return `Training course ${courseTitle} validated`;
                },
                error: () => {
                    reject();
                    return 'Invalid training course data - please double check your id and token';
                },
            },
        );
    });
};
