import { useMemo } from 'react';

import { compact, keyBy } from 'lodash';

import { HydratedBrandProduct } from '@sparkplug/lib';

import { useVendorBrandRetailersProductsByBrandId } from '@features/account-links';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';

const useSingleRetailerPosRulesProductRows = () => {
    const { sparkPosDataIsReady, sparkPosData } = useSpark();

    return {
        singleRetailerPosProductRowsAreReady: sparkPosDataIsReady,
        singleRetailerPosProductRows: sparkPosData?.associatedProducts?.map((product) => ({
            ...product,
            key: product._id,
            category: product.categories?.find(({ _id }) => _id === product.categoryId)?.name ?? '',
            brand: product.brands?.find(({ _id }) => _id === product.brandId)?.name ?? '',
        })),
    };
};

const mapHydratedBrandProductsToProductRows = (
    products: HydratedBrandProduct[],
    sparkBrandName: string,
    retailerNamesById?: Record<string, string>,
) => {
    return compact(products).map((product) => {
        return {
            ...product,
            key: product._id,
            category: product.categories?.find(({ _id }) => _id === product.categoryId)?.name ?? '',
            brand: sparkBrandName,
            retailer: retailerNamesById?.[product.groupId] ?? '',
        };
    });
};

const useSingleRetailerTagsRulesProductRows = () => {
    const { spark, sparkPosData, isVendorTagsRulesBasedSpark } = useSpark();

    const { vendorBrandRetailerProductsAreReady, vendorBrandRetailerProducts } =
        useVendorBrandRetailersProductsByBrandId({
            brandId: spark.sparkBrandId,
            vendorAccountId: spark.originatorGroupId,
            vendorBrandRetailerIds: [spark.groupId],
            isEnabled: isVendorTagsRulesBasedSpark,
        });

    const singleRetailerTagProductRows = useMemo(() => {
        if (!vendorBrandRetailerProductsAreReady || !isVendorTagsRulesBasedSpark) {
            return [];
        }

        const vendorBrandRetailerProductsById = keyBy(
            vendorBrandRetailerProducts[spark.groupId],
            '_id',
        );

        const associatedVendorBrandRetailerProducts = sparkPosData?.associatedProducts?.map(
            ({ _id: posProductId }) => vendorBrandRetailerProductsById[posProductId],
        );

        return mapHydratedBrandProductsToProductRows(
            associatedVendorBrandRetailerProducts ?? [],
            spark?.sparkBrand?.name ?? '',
        );
    }, [vendorBrandRetailerProductsAreReady, vendorBrandRetailerProducts]);

    return {
        singleRetailerTagProductRowsAreReady: vendorBrandRetailerProductsAreReady,
        singleRetailerTagProductRows,
    };
};

const useMultiRetailerProductRows = () => {
    const {
        spark,
        isCreatingMultiRetailerSpark,
        multiRetailerParticipantGroups,
        multiRetailerProductSelection,
    } = useSpark();

    const { account } = useSparkplugAccount();

    const { vendorBrandRetailerProductsAreReady, vendorBrandRetailerProducts } =
        useVendorBrandRetailersProductsByBrandId({
            brandId: spark.sparkBrandId,
            vendorAccountId: spark.originatorGroupId,
            vendorBrandRetailerIds: multiRetailerParticipantGroups.map(
                ({ retailerAccountId }) => retailerAccountId ?? '',
            ),
            isEnabled: isCreatingMultiRetailerSpark,
        });

    const multiRetailerProductRows = useMemo(() => {
        if (!vendorBrandRetailerProductsAreReady || !isCreatingMultiRetailerSpark) {
            return [];
        }

        const vendorBrandRetailerProductsById = keyBy(
            Object.values(vendorBrandRetailerProducts).flat(),
            '_id',
        );

        const multiRetailerQualifyingProductIds = Object.values(
            multiRetailerProductSelection,
        ).flatMap(({ posProductIds }) => posProductIds);

        const multiRetailerQualifyingProducts = multiRetailerQualifyingProductIds.map(
            (posProductId) => vendorBrandRetailerProductsById[posProductId],
        );

        return mapHydratedBrandProductsToProductRows(
            multiRetailerQualifyingProducts,
            spark?.sparkBrand?.name ?? '',
            Object.fromEntries(account?.retailers?.map(({ _id, name }) => [_id, name]) ?? []),
        );
    }, [vendorBrandRetailerProductsAreReady, vendorBrandRetailerProducts]);

    return {
        multiRetailerProductRowsAreReady: vendorBrandRetailerProductsAreReady,
        multiRetailerProductRows,
    };
};

export const useCommissionTableProducts = () => {
    const { isCreatingMultiRetailerSpark, isVendorTagsRulesBasedSpark } = useSpark();

    const { singleRetailerPosProductRowsAreReady, singleRetailerPosProductRows } =
        useSingleRetailerPosRulesProductRows();
    const { singleRetailerTagProductRowsAreReady, singleRetailerTagProductRows } =
        useSingleRetailerTagsRulesProductRows();
    const { multiRetailerProductRowsAreReady, multiRetailerProductRows } =
        useMultiRetailerProductRows();

    const productRowsAreReady = useMemo(() => {
        if (isCreatingMultiRetailerSpark) {
            return multiRetailerProductRowsAreReady;
        }

        if (isVendorTagsRulesBasedSpark) {
            return singleRetailerTagProductRowsAreReady;
        }

        return singleRetailerPosProductRowsAreReady;
    }, [
        isCreatingMultiRetailerSpark,
        isVendorTagsRulesBasedSpark,
        multiRetailerProductRowsAreReady,
        singleRetailerTagProductRowsAreReady,
        singleRetailerPosProductRowsAreReady,
    ]);

    const unsortedProductRows = useMemo(() => {
        if (isCreatingMultiRetailerSpark) {
            return multiRetailerProductRows;
        }

        if (isVendorTagsRulesBasedSpark) {
            return singleRetailerTagProductRows;
        }

        return singleRetailerPosProductRows;
    }, [
        isCreatingMultiRetailerSpark,
        isVendorTagsRulesBasedSpark,
        multiRetailerProductRows,
        singleRetailerTagProductRows,
        singleRetailerPosProductRows,
    ]);

    return {
        productRowsAreReady,
        unsortedProductRows,
        associatedProductIds: unsortedProductRows.map(({ _id }) => _id),
    };
};

type UseCommissionTableProducts = ReturnType<typeof useCommissionTableProducts>;
export type ProductCommissionRow = UseCommissionTableProducts['unsortedProductRows'][number] & {
    retailer?: string;
    tags?: HydratedBrandProduct['tags'];
};
