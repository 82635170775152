import moment from 'moment-timezone';

import { DetailedSparkType, Spark } from '@sparkplug/lib';

import ManagerChip from '@components/chips/ManagerChip';
import LabelWithAdditionalChip from '@components/labels/LabelWithAdditionalChip';

import {
    getSelectedLocationsFromSparkSubGroups,
    getSelectedParticipantsFromSparkSubGroups,
} from '@helpers/sparks';

import { IPosLocation } from '@app/types/PosTypes';
import { ISparkSubGroup } from '@app/types/SparksTypes';
import { IOption } from '@app/types/UITypes';
import { IAccountUser, IAuthUser } from '@app/types/UsersTypes';

export const buildLocationUserTree = (
    posLocations: IPosLocation[],
    accountUsers: IAccountUser[],
    participantsSelectable = true,
    disableLocationSelection = false,
    selectedPosLocationIds: string[] = [],
    showAtAllLocations: boolean = true,
    showManagerChip: boolean = false,
    showManagersByManagedLocations: boolean = false,
    emptyChildrenText: string = '',
) => {
    if (!posLocations?.length || !accountUsers?.length) {
        return [];
    }

    return posLocations
        .map((location) => {
            const disabled =
                disableLocationSelection && !selectedPosLocationIds.includes(location?.value);

            const children = accountUsers
                .filter((accountUser) => {
                    if (showManagersByManagedLocations) {
                        return accountUser.managedLocationIds?.includes(location._id);
                    }

                    // Use `lastTransactionLocationId` or fallback to all users locations
                    const userHasPrimaryLocation = !!accountUser.lastTransactionLocationId;
                    const isUsersPrimaryLocation =
                        accountUser.lastTransactionLocationId === location._id;
                    const userHasSoldAtLocation = accountUser.locationIds?.includes(location._id);

                    if (showAtAllLocations) {
                        return userHasSoldAtLocation;
                    }

                    return userHasPrimaryLocation ? isUsersPrimaryLocation : userHasSoldAtLocation;
                })
                .map((accountUser) => {
                    const {
                        locationIds = [],
                        locationStr,
                        lastTransactionLocationId,
                        lastTransactionDateByLocation,
                        flexibleEmployeeId,
                        label,
                        fullName,
                        managedLocationIds,
                    } = accountUser;
                    const lastSoldLocationName = posLocations.find(
                        (posLocation) => posLocation._id === lastTransactionLocationId,
                    )?.name;
                    const lastTransacitonDate =
                        lastTransactionLocationId &&
                        lastTransactionDateByLocation?.[lastTransactionLocationId];
                    return {
                        ...accountUser,
                        key: `${location.value}.${flexibleEmployeeId}`,
                        value: flexibleEmployeeId,
                        label: label ?? fullName,
                        labelComponent: () => {
                            // users can have disabled location ids in their locationIds array.
                            // we need to filter out the disabled ones by checking against posLocations.

                            const enabledLocations = locationIds.filter((id) =>
                                posLocations.find(({ _id }) => id === _id),
                            );
                            return (
                                <>
                                    <LabelWithAdditionalChip
                                        count={
                                            enabledLocations.length > 1
                                                ? enabledLocations.length
                                                : undefined
                                        }
                                        tooltip={
                                            <>
                                                Locations:{' '}
                                                {locationIds.map((id, i) => {
                                                    const userLocation = posLocations.find(
                                                        ({ _id }) => id === _id,
                                                    );
                                                    return [
                                                        i > 1 && ', ',
                                                        userLocation?.displayName ||
                                                            userLocation?.name ||
                                                            '',
                                                    ];
                                                })}{' '}
                                                <br />
                                                Last Sale: {lastSoldLocationName}
                                                {lastTransacitonDate
                                                    ? ` on ${moment(lastTransacitonDate).format(
                                                          'MMMM D, YYYY',
                                                      )}`
                                                    : ''}
                                            </>
                                        }
                                        label={label ?? fullName}
                                    />
                                    {showManagerChip && managedLocationIds?.length ? (
                                        <ManagerChip />
                                    ) : undefined}
                                </>
                            );
                        },
                        showArrow: !participantsSelectable,
                        type: 'participant',
                        disabled,
                    };
                });

            const listItem: IPosLocation & {
                type: 'location' | 'participant';
                key: string;
                children?: typeof children;
                childrenAsync?: () => Promise<typeof children>;
                participants?: typeof children;
                isSelectable?: boolean;
                remove?: boolean;
            } = {
                ...location,
                type: 'location',
                key: location.value,
                disabled,
            };

            if (participantsSelectable) {
                listItem.children = children;
                listItem.isSelectable = true;
            } else {
                listItem.childrenAsync = async () => children;
                listItem.participants = children;
            }

            // Remove if no children
            if (children.length === 0) {
                const showEmptyLocation = !!emptyChildrenText;

                if (showEmptyLocation) {
                    children.push({
                        key: '',
                        value: '',
                        label: emptyChildrenText,
                        labelComponent: () => (
                            <span className="empty-location-text">{emptyChildrenText}</span>
                        ),
                        showArrow: true,
                        disabled: true,
                    } as any);
                } else {
                    listItem.remove = true;
                }
            }

            return listItem;
        })
        .sort((a, b) => {
            if (disableLocationSelection) {
                const order = a?.disabled ? 1 : -1;
                return a?.disabled === b?.disabled ? 0 : order;
            }

            return 0;
        })
        .filter((listItem) => listItem?.remove !== true);
};

export const buildSelectedKeys = (
    selectedLocationIds: string[],
    selectedParticipantIds: string[],
    activeParticipantOptions: IAccountUser[],
    showAtAllLocations: boolean,
) => {
    const selectedKeys: string[] = [];

    selectedLocationIds.forEach((locationId) => {
        selectedKeys.push(locationId);

        activeParticipantOptions.forEach((accountUser) => {
            // Use `lastTransactionLocationId` or fallback to all users locations
            const userHasPrimaryLocation = !!accountUser.lastTransactionLocationId;
            const isUsersPrimaryLocation = accountUser.lastTransactionLocationId === locationId;
            const userHasSoldAtLocation = accountUser.locationIds?.includes(locationId);

            const canAddUserToLocation =
                userHasPrimaryLocation && !showAtAllLocations
                    ? isUsersPrimaryLocation
                    : userHasSoldAtLocation;

            if (canAddUserToLocation) {
                if (selectedParticipantIds.includes(accountUser.flexibleEmployeeId)) {
                    selectedKeys.push(`${locationId}.${accountUser.flexibleEmployeeId}`);
                }
            }
        });
    });

    return selectedKeys;
};

export const getActivePosEmployeeProfiles = (accountUsers: IAccountUser[]): IAccountUser[] => {
    return accountUsers.filter(
        (user) => user.posEmployeeProfileIds?.length && user.groupRole !== 'none',
    );
};

const filterSparkMatchingUsers = ({
    activeParticipantOptions,
    sparkPosEmployeeProfileIds,
}: {
    activeParticipantOptions: IAccountUser[];
    sparkPosEmployeeProfileIds: string[];
}): IOption<IAccountUser>[] => {
    return activeParticipantOptions
        .filter((activeEmployee) =>
            sparkPosEmployeeProfileIds.some((posEmployeeProfileId) =>
                activeEmployee.posEmployeeProfileIds.includes(posEmployeeProfileId),
            ),
        )
        .map(
            (accountUser) =>
                ({
                    ...accountUser,
                    value: accountUser.flexibleEmployeeId,
                } as IOption<IAccountUser>),
        );
};
export const initializeSelectedLocations = ({
    detailedSparkType,
    sparkSubGroups,
    locationIds,
}: {
    sparkSubGroups: ISparkSubGroup[];
    detailedSparkType?: DetailedSparkType;
    locationIds: string[];
}) => {
    if (detailedSparkType === 'leaderboardMulti') {
        return getSelectedLocationsFromSparkSubGroups(sparkSubGroups);
    }

    return locationIds.map((_id) => ({ _id, value: _id } as IPosLocation));
};

export const initializeSelectedParticipants = ({
    participantsSelectable,
    sparkRequestState,
    initialSelectedParticipants,
    detailedSparkType,
    sparkSubGroups,
    activeParticipantOptions,
    sparkPosEmployeeProfileIds,
}: {
    isBrandApprovalResponse: boolean;
    participantsSelectable: boolean;
    sparkRequestState?: Spark['requestState'];
    currentUser: IAuthUser;
    initialSelectedParticipants?: IOption<IAccountUser>[];
    detailedSparkType: DetailedSparkType;
    sparkSubGroups: ISparkSubGroup[];
    activeParticipantOptions: IAccountUser[];
    sparkPosEmployeeProfileIds: string[];
}): IOption<IAccountUser>[] => {
    if (!participantsSelectable) {
        return [];
    }

    if (initialSelectedParticipants?.length) {
        return initialSelectedParticipants;
    }

    if (sparkRequestState === 'pending') {
        if (detailedSparkType === 'goalManager') {
            return filterSparkMatchingUsers({
                activeParticipantOptions,
                sparkPosEmployeeProfileIds,
            });
        }

        return activeParticipantOptions.map(
            (accountUser) =>
                ({
                    ...accountUser,
                    value: accountUser.flexibleEmployeeId,
                } as IOption<IAccountUser>),
        );
    }

    if (detailedSparkType === 'leaderboardMulti') {
        return getSelectedParticipantsFromSparkSubGroups(sparkSubGroups).map((user) => ({
            ...user,
            value: user.flexibleEmployeeId,
        })) as IOption<IAccountUser>[];
    }

    return filterSparkMatchingUsers({ activeParticipantOptions, sparkPosEmployeeProfileIds });
};

export const filterParticipants = (
    activeParticipantOptions: IAccountUser[],
    userFilter: string,
) => {
    return activeParticipantOptions.filter((accountUser) => {
        switch (userFilter) {
            case 'managers':
                return accountUser.managedLocationIds?.length;
            case 'employees':
                return !accountUser.managedLocationIds?.length && accountUser.locationIds?.length;
            case 'admins':
                return accountUser.role === 'group-admin';
            default:
                return true;
        }
    });
};
