import { ReactNode } from 'react';
import { MemoryRouter, Route, useHistory, useLocation } from 'react-router-dom';

import { QueryParamProvider } from 'use-query-params';

import { TObject } from '@app/types/UtilTypes';

export const withHistory = (Component: any) => (props: any) => {
    const history = useHistory();

    return <Component {...props} history={history} />;
};

export const useQueryParams = (): TObject => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);

    const params: TObject = {};

    [...query.keys()].forEach((key) => {
        const values = query.getAll(key);
        params[key] = values.length > 1 ? values : values[0];
    });

    return params;
};
export const createRouteTestWrapper =
    (initialRoute: string) =>
    ({ children }: { children: ReactNode }) =>
        (
            <MemoryRouter initialEntries={[initialRoute]}>
                <QueryParamProvider ReactRouterRoute={Route}>{children}</QueryParamProvider>
            </MemoryRouter>
        );

export const queryToString = (query: any) => {
    const searchParams = new URLSearchParams();
    Object.keys(query).forEach((key) => {
        if (Array.isArray(query[key])) {
            query[key].forEach((value: string) => {
                searchParams.append(key, value);
            });
        } else {
            searchParams.set(key, query[key]);
        }
    });
    return searchParams.toString();
};
export * from 'react-router-dom';
export { BrowserRouter as Router } from 'react-router-dom';

export { default as AdminRoute } from './AdminRoute';
export { default as AuthRoute } from './AuthRoute';
export { default as ConditionalRoute } from './ConditionalRoute';
export { default as SuperAdminRoute } from './SuperAdminRoute';
