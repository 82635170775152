import { z } from 'zod';
import { transformPhoneNumber } from '../parser';

export const selfEnrollSearchSchema = z.object({
  firstName: z.string().min(1),
  groupId: z.string().min(1),
  lastName: z.string().min(1),
  phoneNumber: z.string().min(1).transform(transformPhoneNumber),
});

export type SelfEnrollSearchRequestBody = z.infer<typeof selfEnrollSearchSchema>;

export type SelfEnrollSearchResponseBody = Array<{
  _id: string;
  firstName: string;
  lastName: string;
  locationDisplayNames: string[];
}>;

export const selfEnrollPosEpSchema = z.object({
  firstName: z.string().min(1),
  groupId: z.string().min(1),
  lastName: z.string().min(1),
  phoneNumber: z.string().min(1),
  posEmployeeProfileId: z.string().min(1),
});

export type SelfEnrollPosEmployeeProfileRequestBody = z.infer<typeof selfEnrollPosEpSchema>;

export const selfEnrollUnmatchedSchema = z.object({
  firstName: z.string().min(1),
  groupId: z.string().min(1),
  lastName: z.string().min(1),
  phoneNumber: z.string().min(1).transform(transformPhoneNumber),
});

export type SelfEnrollUnmatchedRequestBody = z.infer<typeof selfEnrollUnmatchedSchema>;
