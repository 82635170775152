import { FC } from 'react';
import Confetti from 'react-confetti';

import './Confetti.scss';

interface ConfettiGraphicProps {
    initialVelocityY?: number;
    numberOfPieces?: number;
    tweenDuration?: number;
    onComplete?: () => void;
}

export const ConfettiGraphic: FC<ConfettiGraphicProps> = ({
    initialVelocityY = 10,
    numberOfPieces = 200,
    tweenDuration = 5000,
    onComplete = () => {},
}) => {
    return (
        <div className="confetti-wrapper">
            <Confetti
                initialVelocityY={initialVelocityY}
                numberOfPieces={numberOfPieces}
                tweenDuration={tweenDuration}
                recycle={false}
                onConfettiComplete={onComplete}
            />
        </div>
    );
};
