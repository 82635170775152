import { Tooltip as MaterialUITooltip, TooltipProps } from '@mui/material';

import './Tooltip.scss';

export type { TooltipProps } from '@mui/material';

const Tooltip = ({
    className = '',
    disabled = false,
    placement,
    title,
    children,
}: TooltipProps & { disabled?: boolean }) => {
    const props = {
        className,
        disableFocusListener: disabled,
        disableHoverListener: disabled,
        disableTouchListener: disabled,
        placement,
        title,
    };

    return <MaterialUITooltip {...props}>{children}</MaterialUITooltip>;
};

export default Tooltip;
