import { SparkDashboardTableRowData } from '@features/spark-dashboard/types';

import SparkMoreButton from '@components/sparks/SparkMoreButton';
import Table from '@components/table/Table';

import { THeadCell } from '@app/types/TableTypes';

export const actionButtonCell: THeadCell<SparkDashboardTableRowData>[] = [
    {
        id: 'action',
        columnManagerLabel: 'action',
        sticky: 'right',
        render: (row) => {
            const { spark } = row;

            return (
                <Table.Cell align="left">
                    <SparkMoreButton
                        className="more-button"
                        variant="vertical"
                        spark={spark}
                        originRoute="spark-dashboard"
                    />
                </Table.Cell>
            );
        },
    },
];
