import { isEmpty, keyBy } from 'lodash';
import moment from 'moment';

import { SparkParticipant } from '@app/types/SparksTypes';
import { IAccountUser } from '@app/types/UsersTypes';

export const isNewPasswordValid = (
    pwd: string | undefined,
    pwdCheck: string | undefined,
): boolean => {
    if (pwd != null && pwd.length > 7) {
        if (pwd === pwdCheck) {
            return true;
        } else {
            throw Error('Passwords do not match');
        }
    } else {
        throw Error('Password empty or not long enough');
    }
};

export const keyAccountUsersByPosEmployeeProfileIds = <
    T extends { posEmployeeProfileIds: string[] },
>(
    accountUsers: T[],
): { [posEmployeeProfileId: string]: T } => {
    return keyBy(
        accountUsers.flatMap(
            (accountUser) =>
                accountUser?.posEmployeeProfileIds?.map((posEmployeeProfileId) => ({
                    ...accountUser,
                    posEmployeeProfileId,
                })) ?? [],
        ),
        'posEmployeeProfileId',
    );
};

export const filterAccountUsersByPosEmployeeProfileIds = (
    accountUsers: IAccountUser[],
    posEmployeeProfileIds: string[],
) => {
    return accountUsers.filter((accountUser) =>
        accountUser.posEmployeeProfileIds.some((posEmployeeProfileId) =>
            posEmployeeProfileIds.includes(posEmployeeProfileId),
        ),
    );
};

export const getFlexibleEmployeeIdsByPosEmployeeProfileIds = (
    accountUsers: IAccountUser[],
    posEmployeeProfileIds: string[],
): string[] => {
    const sparkParticipantAccountUsers = filterAccountUsersByPosEmployeeProfileIds(
        accountUsers,
        posEmployeeProfileIds,
    );

    return sparkParticipantAccountUsers.map(
        ({ userId, posEmployeeProfileIds: _posEmployeeProfileIds }) =>
            userId ?? _posEmployeeProfileIds?.[0],
    );
};

/**
 * @description
 *
 * SparkPosArchives cause the flexibleEmployeeId to not match the current IAccountUser.flexibledEmployeeId
 * if the PosEPs have been updated since the spark was archived. This helper replaces `flexibledEmployeeId`
 * on the IAccountUser with the `flexibleEmployeeId` from the Archived Spark Participant.
 */
export const keyAccountUsersByArchivedSparkParticipantFlexibleEmployeeId = ({
    archivedSparkParticipants,
    accountUsers,
}: {
    archivedSparkParticipants: SparkParticipant[];
    accountUsers: IAccountUser[];
}) => {
    const accountUsersByPosEmployeeProfileId = keyAccountUsersByPosEmployeeProfileIds(accountUsers);

    const remappedUsers = archivedSparkParticipants.map(
        ({ flexibleEmployeeId, posEmployeeProfileIds }) => {
            const matchingPosEmployeeProfileId = posEmployeeProfileIds.find(
                (posEmployeeProfileId) => accountUsersByPosEmployeeProfileId[posEmployeeProfileId],
            );

            if (matchingPosEmployeeProfileId) {
                const matchingAccountUser =
                    accountUsersByPosEmployeeProfileId[matchingPosEmployeeProfileId];

                return {
                    ...matchingAccountUser,
                    flexibleEmployeeId,
                } as IAccountUser;
            }

            return {} as IAccountUser;
        },
    );

    return keyBy(remappedUsers, 'flexibleEmployeeId');
};

/**
 * @param applicableLocationIds - if omitted, all locations from `firstTransactionDateByLocation` are considered applicable.
 */
export const getFirstApplicableTransactionDate = ({
    firstTransactionDateByLocation = {},
    applicableLocationIds,
}: {
    firstTransactionDateByLocation?: { [location: string]: string };
    applicableLocationIds?: string[];
}): string | undefined => {
    if (isEmpty(firstTransactionDateByLocation)) {
        return undefined;
    }

    // We want the oldest first transaction date because that represents the user's true first transaction
    // given the fact that users can make sales at multiple locations and from multiple profiles
    const [oldestFirstTransactionDate] = Object.entries(firstTransactionDateByLocation)
        // If we want to only include specific locations, filter out locations not in that set
        .filter(([locationId]) =>
            applicableLocationIds?.length ? applicableLocationIds.includes(locationId) : true,
        )
        .map(([, transactionDate]) => transactionDate)
        .sort((a, b) => a.localeCompare(b));

    const twoWeeksAgo = moment().subtract(2, 'weeks');
    const hasApplicableFirstTransaction =
        oldestFirstTransactionDate && moment(oldestFirstTransactionDate).isAfter(twoWeeksAgo);

    return hasApplicableFirstTransaction
        ? moment(oldestFirstTransactionDate).format('ll')
        : undefined;
};

export const formatLastTransactionData = (accountUser: IAccountUser) => {
    if (!accountUser?.lastTransactionDate) {
        return {
            lastTransactionDaysAgo: undefined || Infinity,
            lastTransactionDaysAgoStr: undefined,
        };
    }

    const now = moment();
    const hour = now.get('hour');
    const minute = now.get('minute');

    const lastTransactionDate = moment(accountUser?.lastTransactionDate)
        .set('hour', hour)
        .set('minute', minute);
    const lastTransactionDaysAgo = now.diff(lastTransactionDate, 'days');

    let lastTransactionDaysAgoStr = lastTransactionDate.isSame(now, 'date')
        ? 'today'
        : moment(lastTransactionDate).fromNow();
    if (lastTransactionDaysAgo === 1) {
        lastTransactionDaysAgoStr = '1 day ago';
    }

    return {
        lastTransactionDaysAgo,
        lastTransactionDaysAgoStr,
    };
};
