import { useMemo } from 'react';

import { ListAccountLinksResponseBody, TChartDataMetric } from '@sparkplug/lib';

import { DeprecatedAdvancedChart } from '@core/charts/components/AdvancedChart';
import { useChartContext } from '@core/charts/components/AdvancedChart/ChartContext';

import Chart from '@components/charts/Chart';

import { getChartMetricLabel } from '@helpers/charts';
import { capitalizeFirstLetter } from '@helpers/ui';

import { IAccount } from '@app/types/AccountsTypes';

import { ChartTitleLeft } from '../ComparisonChart/ComparisonChart';

import './BreakdownChart.scss';

interface BreakdownChartProps {
    metricOptions?: TChartDataMetric[];
    account?: IAccount;
    accountLinks?: ListAccountLinksResponseBody;
    userIsSuperAdmin?: boolean;
}

const BreakdownChart = ({
    metricOptions = [],
    accountLinks,
    account,
    userIsSuperAdmin = false,
}: BreakdownChartProps) => {
    const { chartSettings, updateChartSettings, cloudChartData } = useChartContext();
    const { total = 0 } = cloudChartData;

    const titleLabel = useMemo(() => {
        const metricLabel =
            metricOptions.length > 1 ? '' : getChartMetricLabel(chartSettings.metric);

        return `${metricLabel} by ${capitalizeFirstLetter(chartSettings.breakdown)}`;
    }, [chartSettings.metric, metricOptions]);

    const handleUpdateChartMetric = (updatedMetric: TChartDataMetric) =>
        updateChartSettings({ metric: updatedMetric });

    return (
        <>
            <Chart.Title
                metric={chartSettings.metric}
                total={total}
                titleLabel={titleLabel}
                chartTitleLeft={
                    <ChartTitleLeft
                        metric={chartSettings.metric}
                        handleUpdateChartMetric={handleUpdateChartMetric}
                        titleLabel={titleLabel}
                        total={total}
                        metricOptions={metricOptions}
                    />
                }
            />
            <DeprecatedAdvancedChart
                account={account}
                accountLinks={accountLinks}
                userIsSuperAdmin={userIsSuperAdmin}
            />
        </>
    );
};

export default BreakdownChart;
