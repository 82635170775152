import { useState } from 'react';

import { clsx } from 'clsx';

import { Spark, SparkAward, SparkType } from '@sparkplug/lib';

import { SparkTemplate } from '@features/spark-dashboard/types';

import Button from '@components/buttons/Button';
import Dropdown from '@components/dropdown/Dropdown';
import {
    ChevronRight as ArrowRightIcon,
    CircleCheck as CircleCheckIcon,
    Crown as CrownIcon,
    Info as InfoIcon,
    Spark as SparkIcon,
    UnlimitedGoalIcon,
} from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import Paper from '@components/layout/Paper';
import Tooltip from '@components/layout/Tooltip';
import Modal from '@components/overlays/Modal';

import { useApp } from '@hooks/AppHooks';

import { formatSparkInfo } from '@helpers/sparks';
import { getOrdinal } from '@helpers/util';

import MinThresholdCallout from '../MinThresholdCallout';
import FulfillmentTable from './FulfillmentTable';
import useOrderedAwardBlock from './useOrderedAwardBlock';
import { usePayoutCalloutMessage } from './usePayoutCalloutMessage';

import './SparkAwardsPanel.scss';

export const FulfilledBySparkplugIcon = () => (
    <Tooltip title="SparkPlug will automatically pay out this prize to employees at the end of the Spark.">
        <SparkIcon className="spark-icon" />
    </Tooltip>
);

export const NFBIcon = ({ tooltip }: { tooltip?: string }) => (
    <Tooltip
        title={
            <div className="flex flex-col gap-1 max-w-[300px]">
                <span className="font-bold">Claim Instructions:</span>
                <span>{tooltip || 'Talk to your manager'}</span>
            </div>
        }
    >
        <InfoIcon className="!w-[10px] !h-[10px] cursor-pointer" />
    </Tooltip>
);
const BlockCountTrackers = {
    leaderboard: ({ count }: { count: number }) => {
        const ordinal = getOrdinal(count);
        return (
            <div className="block-counter-tracker">
                <CrownIcon className="crown-icon" />
                <span className="number">{count}</span>
                <sup>{ordinal}</sup>
                <span>Place</span>
            </div>
        );
    },

    goal: ({ count, isUnlimited }: { count: number; isUnlimited: boolean }) => {
        if (isUnlimited) {
            return (
                <div className="block-counter-tracker">
                    <UnlimitedGoalIcon />
                    <span className="number">Unlimited Goal</span>
                </div>
            );
        }

        return (
            <div className="block-counter-tracker">
                <CircleCheckIcon />
                <span className="number">{`Goal ${count}`}</span>
            </div>
        );
    },
};

export type AwardBlockProps = {
    award: SparkAward;
    threshold?: string;
    isUnlimited?: boolean;
    fulfilledBySparkplug: boolean;
    sparkType: 'leaderboard' | 'goal';
    blockCount: number;
    isUserSparkDetailsView?: boolean;
};

const AwardBlock = ({
    award,
    threshold,
    isUnlimited = false,
    fulfilledBySparkplug,
    sparkType,
    blockCount,
    isUserSparkDetailsView = false,
}: AwardBlockProps) => {
    const BlockCountTracker = BlockCountTrackers[sparkType];

    let thresholdMessage;
    if (threshold) {
        thresholdMessage = isUnlimited ? `For every ${threshold} sold` : `Sell ${threshold}`;
    }

    return (
        <div className="award-block">
            <div className={clsx(fulfilledBySparkplug && 'fulfilled-by-sparkplug', 'award')}>
                <div className="count-container">
                    <BlockCountTracker count={blockCount} isUnlimited={isUnlimited} />
                    <section className="threshold">{thresholdMessage}</section>
                </div>

                <div className="label-container">
                    <section className="label-main flex items-center justify-center gap-1">
                        <span
                            className={clsx(
                                'award-label',
                                !fulfilledBySparkplug &&
                                    'text-balance text-[clamp(0.75rem,3vw,1rem)] leading-tight break-words max-w-full overflow-hidden',
                            )}
                        >
                            {award.award}
                        </span>
                        {fulfilledBySparkplug ? (
                            <FulfilledBySparkplugIcon />
                        ) : (
                            !isUserSparkDetailsView && (
                                <NFBIcon tooltip={award.claimInstructions ?? ''} />
                            )
                        )}
                    </section>
                </div>
            </div>
        </div>
    );
};

const GoalInfoButton = () => (
    <Dropdown>
        <Dropdown.IconButton color="neutral">
            <InfoIcon className="info-icon" />
        </Dropdown.IconButton>
        <Dropdown.Popover>
            <div className="goal-spark-popover">
                <h3>
                    <span>About Spark</span>
                    &nbsp;
                    <span role="img" aria-label="lightning">
                        ⚡️
                    </span>
                    &nbsp;
                    <span>Goal</span>
                </h3>
                <p>
                    Once a user has reached a goal, they will be eligible for the prize associated
                    with the goal they accomplished.
                </p>
            </div>
        </Dropdown.Popover>
    </Dropdown>
);

const HeaderTitles: Partial<Record<SparkType, string>> = {
    leaderboard: 'Prizes',
    goal: 'Goals',
};

export const AwardsHeaderTile = ({
    spark,
    userIsAdmin,
}: {
    spark: Spark | SparkTemplate;
    userIsAdmin: boolean;
}) => {
    const { type: sparkType } = spark;
    return (
        <h2>
            {sparkType === 'goal' && userIsAdmin && <GoalInfoButton />}
            {HeaderTitles[sparkType!]}
        </h2>
    );
};

const FulfillmentModal = ({
    spark,
    isVisible,
    onClose,
}: {
    spark: Spark;
    isVisible: boolean;
    onClose: () => void;
}) => {
    return (
        <Modal
            isVisible={isVisible}
            onClose={onClose}
            className="[&_.MuiDialog-paper]:max-w-[780px]"
        >
            <Modal.Title>Non-cash Reward Fulfillment Status</Modal.Title>
            <Modal.Content>
                <FulfillmentTable spark={spark} />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>Close</Button>
            </Modal.Actions>
        </Modal>
    );
};

function SparkAwardsPanel({
    spark,
    hideTitle = false,
    variant = 'raised',
    isUserSparkDetailsView = false,
}: {
    spark: Spark;
    hideTitle?: boolean;
    variant?: 'raised' | 'smooth';
    isUserSparkDetailsView?: boolean;
}) {
    const { userIsAdmin } = useApp();

    const { type: sparkType, startDate, endDate, recurringSchedule } = spark;

    const showMinThresholdCallout =
        sparkType === 'leaderboard' &&
        (!!spark.minimumThresholdToQualify || !!spark.minimumTransactionsToQualify);

    const payoutCalloutMessage = usePayoutCalloutMessage(spark);

    const orderedAwardBlockProps = useOrderedAwardBlock(spark);

    const { status } = formatSparkInfo(startDate, endDate, spark.requestState, recurringSchedule);

    const [viewFulfillmentModal, setViewFulfillmentModal] = useState(false);

    return (
        <Paper className="awards-container" variant={variant}>
            {showMinThresholdCallout && <MinThresholdCallout />}

            {!hideTitle && sparkType && (
                <div className="awards-header">
                    <AwardsHeaderTile spark={spark} userIsAdmin={userIsAdmin} />

                    {payoutCalloutMessage && (
                        <CalloutMessage
                            hideIcon
                            className="awards-callout"
                            message={payoutCalloutMessage}
                            color="blue"
                            variant="outlined"
                        />
                    )}
                    {spark.fulfillmentTypes?.some((type) => type === 'external') &&
                        status === 'Complete' &&
                        !isUserSparkDetailsView && (
                            <Button
                                onClick={() => {
                                    setViewFulfillmentModal(true);
                                }}
                                endIcon={<ArrowRightIcon />}
                                className="!w-fit !absolute right-0"
                            >
                                View Fulfillment
                            </Button>
                        )}
                </div>
            )}

            <div className="awards-content">
                {orderedAwardBlockProps.map((awardBlockProps, i) => (
                    <AwardBlock
                        key={`${i}-${awardBlockProps.award.award}`}
                        {...awardBlockProps}
                        isUserSparkDetailsView={isUserSparkDetailsView}
                    />
                ))}
            </div>

            {viewFulfillmentModal && (
                <FulfillmentModal
                    spark={spark}
                    isVisible
                    onClose={() => setViewFulfillmentModal(false)}
                />
            )}
        </Paper>
    );
}

export default SparkAwardsPanel;
