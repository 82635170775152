import { forwardRef, useEffect, useMemo, useState } from 'react';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';

import { useFormContext } from '@hooks/FormHooks';

import TextField, { ITextFieldProps } from '../TextField';

const PhoneField = ({
    defaultValue,
    onChange,
    disabled,
    required,
    error,
    ...props
}: ITextFieldProps) => {
    const {
        setValue,
        register,
        formState: { isSubmitted },
    } = useFormContext() || {
        setValue: () => {},
        register: () => {},
    };

    const [phone, setPhone] = useState<string>(String(defaultValue) || '');

    if (props?.name) {
        register(props.name, {
            required,
        });
    }

    useEffect(() => {
        setValue(props.name, defaultValue);
    }, [defaultValue]);

    const onInputChange = (newPhone?: string) => {
        setPhone(newPhone!);

        if (props.name) {
            setValue(props.name, newPhone);
        }

        if (onChange != null) {
            onChange({
                target: {
                    value: newPhone,
                },
            });
        }
    };

    // Memoize once to prevent losing focus when typing
    const WrappedTextField = useMemo(() => {
        return forwardRef((phoneInputProps: any, ref) => {
            return <TextField inputRef={ref} {...phoneInputProps} {...props} />;
        });
    }, []);

    return (
        <PhoneInput
            country="US"
            disabled={disabled}
            required={required}
            error={error || (isSubmitted && required && !isPossiblePhoneNumber(String(phone)))}
            value={phone}
            min={required ? 6 : undefined}
            onChange={onInputChange}
            inputComponent={WrappedTextField as any}
        />
    );
};

export default PhoneField;
