import { useState } from 'react';

import SettingsTitle from '@views/admin/components/SettingsTitle';

import { getStripeUrl } from '@core/accounts';

import Button from '@components/buttons/Button';
import { BillingGraphic } from '@components/graphics';
import { FollowLinkIcon } from '@components/icons';
import AppView from '@components/layout/AppView';
import Paper from '@components/layout/Paper';
import Spinner from '@components/layout/Spinner';
import toast from '@components/toast';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import './AdminBillingPlanView.scss';

const StripePortalButton = ({ accountId }: { accountId: string }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchStripeUrl = () => {
        setIsLoading(true);
        getStripeUrl(accountId)
            .then((data) => {
                window.location.href = data.url;
            })
            .catch(() => toast.error('Unable to connect to the customer portal. Please try again.'))
            .finally(() => setIsLoading(false));
    };

    return (
        <Button
            color="neutral"
            disabled={!accountId || isLoading}
            onClick={fetchStripeUrl}
            className="manage-billing-link"
            endIcon={isLoading ? <Spinner size={15} /> : <FollowLinkIcon />}
        >
            <p>Manage Billing</p>
        </Button>
    );
};

export const AdminBillingPlanView = () => {
    const { userCan, account } = useSparkplugAccount();
    const userCanAccessBilling = userCan('accessBillingPortal');
    const accountTypeLabel = account?.type === 'retailer' ? 'Retailers' : 'Brands';

    return (
        <AppView.Content title={<SettingsTitle label="Plan" />} className="admin-employees-view">
            <Paper className="billing-plan-container">
                <header>Your SparkPlug Plan</header>

                <Paper className="customer-portal-card">
                    <BillingGraphic />

                    <section>
                        <h1>{`Sparkplug for ${accountTypeLabel}`}</h1>
                        {account && userCanAccessBilling && (
                            <StripePortalButton accountId={account._id} />
                        )}
                    </section>
                </Paper>
            </Paper>
        </AppView.Content>
    );
};
