// @ts-ignore
import { Stripe } from 'stripe';
import { z } from 'zod';

export type InvoiceStatus = Stripe.Invoice.Status;
export type SubscriptionStatus = Stripe.Subscription.Status;
export interface Invoice {
  _id: string;

  /**
   * The total amount currently due on this invoice, in USD (cents included).
   */
  amountDue: number;
  billingEmail: string;
  createdAt: string;
  paid: boolean;
  status?: InvoiceStatus;

  /**
   * The total invoice amount once finalized, in USD (cents included).  This does not
   * incorporate any amount paid towards the invoice by the customer.
   */
  total: number;
}

export interface Subscription {
  interval: {
    cadence: string;
    frequency: number;
  };
  isPaidSubscription: boolean;

  /**
   * The full cost of the next invoice for this subscription, in USD (cents included).
   */
  nextInvoiceCost: number;
  nextInvoiceDue?: string;
  status: SubscriptionStatus;
  title: string;
}

export interface Card {
  id: string;

  brand?: string;
  default?: boolean;
  expMonth?: number;
  expYear?: number;
  last4?: string;
  type: 'card';
}

export interface BankAccount {
  id: string;

  bank?: string;
  default?: boolean;
  last4?: string;
  type: 'bank';
}

export type PaymentMethod = Card | BankAccount;

export interface ListSubscriptionsPathParams {
  groupId: string;
}

export interface ListSubscriptionsResponseBody {
  count: number;
  subscriptions: Subscription[];
}

export interface ListInvoicesPathParams {
  groupId: string;
}

export interface ListInvoicesResponseBody {
  count: number;
  invoices: Invoice[];
}

export interface ListPaymentMethodsPathParams {
  groupId: string;
}

export interface ListPaymentMethodsResponseBody {
  count: number;
  data: PaymentMethod[];
}

export const subscriptionLineItemSchema = z.object({
  price_data: z.object({
    unit_amount: z.number(),
    currency: z.string().refine((value) => value === 'usd', {
      message: "Currency must be 'usd'",
    }),
    product: z.string().min(1),
    recurring: z.object({
      interval: z.enum(['day', 'week', 'month', 'year']),
      interval_count: z.number().optional(),
    }),
  }),
  quantity: z.number(),
});
export type SubscriptionLineItem = z.infer<typeof subscriptionLineItemSchema>;
