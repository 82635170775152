import { z } from 'zod';

export const EVENT_REQUEST_STATUSES = ['approved', 'declined', 'pending'] as const;
const EventRequestStatusEnum = z.enum(EVENT_REQUEST_STATUSES);
export const EVENT_TYPES = ['promo', 'training', 'popup', 'other'] as const;
const EventTypeEnum = z.enum(EVENT_TYPES);
export type EventRequestStatus = z.infer<typeof EventRequestStatusEnum>;
export type EventType = z.infer<typeof EventTypeEnum>;

export interface UIEvent {
  _id: string;
  startTime: Date;
  endTime: Date;
  vendorAccount?: {
    _id: string;
    name: string;
  };
  retailerAccount: {
    _id: string;
    name: string;
    photo: string;
  };
  brand?: {
    _id: string;
    name: string;
    photo: string;
  };
  locations: {
    _id: string;
    name: string;
  }[];
  requestStatus?: typeof EVENT_REQUEST_STATUSES[number];
  type: typeof EVENT_TYPES[number];
  isExpired?: boolean;
  name: string;
  description?: string;
  requestNote?: string;
  createdAt: Date;
}

type SortableKeys<T> = {
  [K in keyof T]: T[K] extends string | number | Date ? K : never;
}[keyof T];

export const SORTABLE_EVENT_FIELDS = ['createdAt', 'startTime', 'endTime', 'name'] as const;
const SortableEventFieldsEnum = z.enum(SORTABLE_EVENT_FIELDS);
export type SortableEventFields = typeof SORTABLE_EVENT_FIELDS[number] & SortableKeys<UIEvent>;

export const listEventsQueryParamsSchema = z.object({
  accountId: z.string(),
  brandId: z.string().optional(),
  requestStatus: z.preprocess(
    (val) => (typeof val === 'string' ? val.split(',') : val),
    z.array(EventRequestStatusEnum).optional(),
  ),
  type: z.preprocess(
    (val) => (typeof val === 'string' ? val.split(',') : val),
    z.array(EventTypeEnum).optional(),
  ),
  limit: z
    .preprocess((val) => (val ? Number(val) : undefined), z.number().positive().optional())
    .default(10),
  offset: z
    .preprocess((val) => (val ? Number(val) : undefined), z.number().nonnegative().optional())
    .default(0),
  orderBy: SortableEventFieldsEnum.optional(),
  order: z.enum(['asc', 'desc']).optional(),
  search: z.string().optional(),
});

export type ListEventsQueryParams = z.infer<typeof listEventsQueryParamsSchema>;

export const listEventsBodySchema = z.object({
  locationIds: z.array(z.string()).optional(),
});
export type ListEventsRequestBody = z.infer<typeof listEventsBodySchema>;

export const upsertEventBodySchema = z.object({
  _id: z.string().optional(),
  startTime: z.string().optional(), // required if _id is not provided
  endTime: z.string().optional(), // required if _id is not provided
  vendorAccountId: z.string().optional(),
  retailerAccountId: z.string().optional(), // required if _id is not provided
  brandId: z.string().optional(),
  locationIds: z.array(z.string()).optional(), // required if _id is not provided
  type: EventTypeEnum.optional(), // required if _id is not provided
  name: z.string().optional(), // required if _id is not provided
  description: z.string().optional(),
  requestNote: z.string().optional(),
});
export type UpsertEventRequestBody = z.infer<typeof upsertEventBodySchema>;

export const getEventCountQueryParamsSchema = z.object({
  accountId: z.string(),
  requestStatus: EventRequestStatusEnum.optional(),
});

export type GetEventCountQueryParams = z.infer<typeof getEventCountQueryParamsSchema>;
