import moment from 'moment-timezone';

import BucketFactory, { TBucketsMap } from './BucketFactory';

export default class MonthBucketFactory extends BucketFactory {
    getShortName(name: string) {
        return moment(name).format('MMM YYYY');
    }

    getTooltipTitle(name: string) {
        return `${moment(name).format('MMM YYYY')}`;
    }

    getComparisonLabel(name: string) {
        return this.getTooltipTitle(name);
    }

    getBucketName(transaction: any) {
        if (transaction.bucket) {
            return `${transaction.bucket.substring(0, 7)}-01`;
        }
        const date = moment(transaction.completedOn);
        return date.tz(transaction.timeZone).format('YYYY-MM-01');
    }

    getAllBucketNames(dateStart: string | Date, dateEnd: string | Date) {
        const bucketNames: string[] = [];
        const currentDate = moment(dateStart);

        do {
            bucketNames.push(currentDate.format('YYYY-MM-01'));
            currentDate.add(1, 'month');
        } while (currentDate.isSameOrBefore(dateEnd, 'month'));

        return bucketNames;
    }

    getCumulativeBuckets(bucketsMap: TBucketsMap, bucketName: string) {
        return super.getCumulativeBuckets(bucketsMap, bucketName);
    }
}
