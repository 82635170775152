import { FC, RefObject } from 'react';

import { Search } from '@components/icons';

import { InputEventOnChange } from '@app/types/UITypes';

import TextField from '../TextField';

interface SearchFieldProps {
    className?: string;
    color?: 'neutral' | 'blue' | 'green' | 'red';
    variant?: 'outlined' | 'raised' | 'smooth' | 'filled';
    defaultValue?: string;
    onChange: InputEventOnChange;
    name: string;
    inputRef?: RefObject<HTMLInputElement>;
}

const SearchField: FC<SearchFieldProps> = ({
    className,
    name,
    color = 'neutral',
    variant = 'outlined',
    defaultValue,
    inputRef,
    onChange = () => {},
}) => {
    const classNameAppended = `${className} search-field`;

    return (
        <TextField
            name={name}
            className={classNameAppended}
            color={color}
            variant={variant}
            type="search"
            defaultValue={defaultValue}
            startIcon={<Search />}
            onChange={onChange}
            inputRef={inputRef}
        />
    );
};

export default SearchField;
