import { CSSProperties, ChangeEvent, ReactElement, ReactNode } from 'react';
import * as React from 'react';

import { Tab as MaterialUITab, Tabs as MaterialUITabs } from '@mui/material';

import { appendClasses } from '../../../helpers/ui';

import './Tabs.scss';

interface TabPanelProps {
    children?: ReactNode;
    index?: number;
    value?: number;
}

const TabPanel = ({ value, index, children, ...other }: TabPanelProps) => {
    return (
        <div
            className="tab-panel"
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
};

export interface TabsProps {
    innerRef?: any;
    className?: string;
    value: number | string;
    color: 'neutral' | 'blue' | 'green' | 'red';
    variant?: 'default' | 'dense';
    onChange?: (event: ChangeEvent<{}>, value: number) => void;
    children: ReactNode;
    fullWidth?: boolean;
    style?: CSSProperties;
    orientation?: 'horizontal' | 'vertical';
}
/**
 * @deprecated we want to use Tabs in the componentLibrary
 */
const Tabs = ({
    innerRef,
    className = '',
    color = 'blue',
    variant = 'default',
    value,
    onChange,
    children,
    fullWidth = false,
    style,
    orientation,
}: TabsProps) => {
    const classNameAppended = appendClasses([
        className,
        'tabs',
        `tabs-color-${color}`,
        `tabs-variant-${variant} `,
        `tabs-active-${value}`,
        orientation === 'vertical' ? 'tabs-vertical' : '',
    ]);

    return (
        <MaterialUITabs
            ref={innerRef}
            className={classNameAppended}
            value={value}
            variant="scrollable"
            scrollButtons="auto"
            onChange={onChange}
            style={style}
            orientation={orientation}
            {...(fullWidth && { variant: 'fullWidth' })}
        >
            {children}
        </MaterialUITabs>
    );
};
interface TabProps {
    activeIndex?: number;
    index?: number;
    value?: string | number;
    label?: string | ReactElement;
    to?: string;
    component?: any;
    onClick?: () => void;
    disabled?: boolean;
    endIcon?: ReactElement | null;
}
const Tab = ({
    activeIndex,
    index,
    label,
    value,
    to,
    component,
    onClick,
    disabled,
    endIcon,
}: TabProps) => {
    const classNameAppended = `tab ${index === activeIndex ? 'tab-active' : ''}`;

    const autoTutorialTarget = label != null && typeof label === 'string' ? label.toString() : null;

    return (
        <MaterialUITab
            disabled={disabled}
            data-intercom-target={autoTutorialTarget}
            className={classNameAppended}
            label={
                <div className="tab-label">
                    {label}
                    {endIcon}
                </div>
            }
            component={component}
            value={value}
            to={to}
            onClick={onClick}
        />
    );
};

Tabs.Tab = Tab;
Tabs.TabPanel = TabPanel;

export default Tabs;
