export const DefaultChartColors: string[] = [
    '#92C8DD', // Cerulean-20
    '#005D7D', // Cerulean-80
    '#A6E1CB',
    '#007455',
    '#FFCB87',
    '#925600',
    '#FDAA8F',
    '#9A2301',
    '#818B99',
    '#13263A',
    '#07A0C3', // Cerulean-50
    '#59C9A5',
    '#F3A623',
    '#EA6940',
    '#2C3E53',
];

export const ComparisonColors: string[] = [
    '#07A0C3', // Cerulean-50
    '#CCD3DE', // Gray-20,
];

export const EMPTY_COLOR = '#94A3B7';
