import { FC, ReactElement, useMemo, useState } from 'react';

import { clsx } from 'clsx';
import { EmojiData } from 'emoji-mart';

import {
    DetailedSparkType,
    EmployeeSpark,
    Spark,
    SparkType,
    checkForTrainingComplete,
} from '@sparkplug/lib';

import SparkCardSnapsLauncher from '@features/spark-snaps/components/SparkCardSnapsLauncher';
import { useSnapViewQueue } from '@features/spark-snaps/hooks';
import RewardDetailsDrawer from '@features/wallet/components/RewardDetailsDrawer';
import { useSparkUserDeposit } from '@features/wallet/queries/WalletQueries';

import ManagerChip from '@components/chips/ManagerChip';
import { TrainingCompleteIcon, TrainingIcon } from '@components/icons';
import Paper from '@components/layout/Paper';
import SparkPayoutClaimModal from '@components/overlays/SparkPayoutClaimModal';
import LinearProgress from '@components/progress/LinearProgress';
import EmployeeRanking from '@components/sparks/EmployeeRanking';
import SparkClaimButton from '@components/sparks/SparkClaimButton';
import SparkGroupChip from '@components/sparks/SparkGroupChip';
import SparkStatusChip from '@components/sparks/SparkStatusChip';

import { useApp } from '@hooks/AppHooks';
import { useSparkUserReward } from '@hooks/RewardsHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { formatSparkInfo, getOtherGroupName, getSparkTypeDisplayName } from '@helpers/sparks';

import { SparkCardLogo } from '../SparkCardLogo';
import { RequiredTrainingButton } from './RequiredTrainingButton';

import './SparkCard.scss';

type SparkEmojiSectionProps = {
    emoji?: string | EmojiData;
    customLogo?: ReactElement;
    sparkBrand?: Spark['sparkBrand'];
    sparkType: SparkType;
    detailedSparkType?: DetailedSparkType;
    name: string;
    description: string;
    recurringSchedule?: EmployeeSpark['recurringSchedule'];
    range?: string;
    isRanked?: boolean;
};
export const SparkEmojiSection: FC<SparkEmojiSectionProps> = ({
    emoji,
    sparkBrand,
    sparkType,
    detailedSparkType,
    name,
    description,
    recurringSchedule,
    range,
    isRanked = false,
    customLogo,
}) => {
    const showManagerChip = detailedSparkType === 'goalManager';

    return (
        <section className="card-main">
            {customLogo ?? (
                <SparkCardLogo
                    emoji={emoji}
                    sparkBrand={sparkBrand}
                    recurringSchedule={recurringSchedule}
                />
            )}

            <div className={clsx(isRanked ? 'ranked-type' : 'type')}>
                {getSparkTypeDisplayName(sparkType)} {isRanked && range && <span>{range}</span>}
                {showManagerChip && <ManagerChip />}
            </div>

            <div className="name">{name}</div>

            <div className="description">{description}</div>
        </section>
    );
};

type SparkCardProps = {
    isUserSparkDetailsView?: boolean;
    isUserSparksView?: boolean;
    spark: EmployeeSpark;
    showTrainingIcon?: boolean;
    showTrainingRequired?: boolean;
    onSparkCardSelected: (spark: Pick<EmployeeSpark, '_id' | 'requestState'>) => void;
    showStats?: boolean;
    handleViewSnap?: ReturnType<typeof useSnapViewQueue>['handleViewSnap'];
    enableSnapsLauncher?: boolean;
    index?: number;
};
const SparkCard: FC<SparkCardProps> = ({
    isUserSparkDetailsView = false,
    isUserSparksView = false,
    spark,
    showTrainingIcon = false,
    showTrainingRequired = false,
    onSparkCardSelected,
    showStats = false,
    handleViewSnap,
    enableSnapsLauncher = false,
    index,
}) => {
    const [claimModalIsVisible, setClaimModalIsVisible] = useState(false);

    const {
        _id: sparkId,
        emoji = 'trophy',
        startDate,
        endDate,
        name = '',
        description = '',
        type: sparkType,
        detailedSparkType,
        requestState,
        recurringSchedule,
        courseData,
    } = spark;

    const {
        range,
        progress,
        status,
        color,
        currentDays,
        totalDays,
        remainingDays,
        daysUntilStart,
    } = formatSparkInfo(startDate, endDate, requestState);

    let customLabel: string | null = null;
    if (status === 'Active' && remainingDays) {
        customLabel =
            remainingDays > 1 ? `${remainingDays} Days Left` : `${remainingDays} Day Left`;
    } else if (status === 'Active' && remainingDays === 0) {
        customLabel = 'Last Day!';
    } else if (status === 'Upcoming' && daysUntilStart) {
        customLabel =
            daysUntilStart > 1
                ? `Starts in ${daysUntilStart} Days`
                : `Starts in ${daysUntilStart} Day`;
    }
    const { account } = useSparkplugAccount();
    const otherGroupName = getOtherGroupName(spark, account);
    const { user, isAdminApp, history } = useApp();

    // TODO: Remove when fully migrated to next gen payouts
    const { reward: userPayout, refetchReward } = useSparkUserReward(spark._id);

    const { userDeposit } = useSparkUserDeposit(spark._id);

    const showRewardButton = status === 'Complete' && !isAdminApp;

    const [rewardDetailsAreVisible, setRewardDetailsAreVisible] = useState(false);

    const rewardDetailsDrawer = (
        <RewardDetailsDrawer
            isVisible={rewardDetailsAreVisible}
            deposit={userDeposit}
            onClose={() => setRewardDetailsAreVisible(false)}
            showCompletedSparkButtton={false}
        />
    );

    const onClickClaimButtom = () => {
        if (isUserSparkDetailsView && userDeposit?.status === 'Claimed') {
            setRewardDetailsAreVisible(true);
        } else if (isUserSparkDetailsView && userDeposit?.status === 'Confirmed') {
            history.push(`/user/wallet?rewardId=${userDeposit._id}`);
        }
    };

    const onCloseClaimModal = (didAction: boolean) => {
        setClaimModalIsVisible(false);

        if (didAction) {
            refetchReward();
        }
    };

    const userHasCompletedCourse = checkForTrainingComplete({ userId: user?._id, courseData });

    const trainingIcon = useMemo(() => {
        if (spark.trainingEnabled && showTrainingIcon) {
            return userHasCompletedCourse ? (
                <TrainingCompleteIcon data-testid="training-complete-icon" fill="#38AC89" />
            ) : (
                <TrainingIcon data-testid="training-available-icon" fill="#CCD3DE" />
            );
        }
        return <></>;
    }, [spark, userHasCompletedCourse, showTrainingIcon]);

    const vendorGroupName = spark.vendorName ?? '';

    const showSnapsLauncher =
        enableSnapsLauncher &&
        spark?.snaps?.creatorHasSnapsEntitlement &&
        spark?.snaps?.snapIds?.length;

    return (
        <>
            <Paper
                key={sparkId}
                className={clsx('spark-card', showRewardButton && 'has-user-payout')}
                onClick={() => onSparkCardSelected(spark)}
                hoverEffect
            >
                <section className="card-header">
                    <div className="chip-section">
                        {!!otherGroupName && (
                            <SparkGroupChip
                                groupName={otherGroupName}
                                vendorName={vendorGroupName}
                            />
                        )}
                        <div className="status-wrapper">
                            {trainingIcon}
                            <SparkStatusChip status={status} customLabel={customLabel} />
                        </div>
                    </div>
                </section>

                <SparkEmojiSection
                    emoji={emoji}
                    sparkBrand={spark.sparkBrand}
                    sparkType={sparkType}
                    detailedSparkType={detailedSparkType}
                    name={name}
                    description={description}
                    recurringSchedule={recurringSchedule}
                    range={range}
                    isRanked={showStats && status === 'Active'}
                    customLogo={
                        showSnapsLauncher ? (
                            <SparkCardSnapsLauncher
                                spark={spark}
                                handleViewSnap={handleViewSnap}
                                sparkCardIndex={index}
                            />
                        ) : undefined
                    }
                />

                {showTrainingRequired && spark.courseData?.required && !userHasCompletedCourse ? (
                    <RequiredTrainingButton onClick={onSparkCardSelected} />
                ) : (
                    <>
                        {showStats && status === 'Active' ? (
                            <EmployeeRanking spark={spark} />
                        ) : (
                            <section className="card-footer">
                                <section className="date">
                                    <span>{range}</span>
                                    <span>{`${currentDays}/${totalDays} days`}</span>
                                </section>
                                <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                    color={color}
                                />
                            </section>
                        )}
                    </>
                )}

                {showRewardButton && (
                    <section className="card-action">
                        <SparkClaimButton
                            showRewardMessage={isUserSparkDetailsView}
                            spark={spark}
                            payout={userPayout!}
                            deposit={userDeposit}
                            onClick={onClickClaimButtom}
                        />
                    </section>
                )}
            </Paper>

            {rewardDetailsDrawer}

            {/* TODO: remove this modal when fully migrated to next gen payouts */}
            {userPayout && (
                <SparkPayoutClaimModal
                    isVisible={claimModalIsVisible}
                    onClose={onCloseClaimModal}
                    spark={spark}
                    payout={userPayout}
                />
            )}
        </>
    );
};

export default SparkCard;
