import { FC, useMemo } from 'react';

import { Spark } from '@sparkplug/lib';

import UserCharts from '@components/charts/UserCharts';
import { NonParticipantLeader } from '@components/chips/NonParticipantChip';
import Paper from '@components/layout/Paper';
import MinThresholdCallout from '@components/sparks/MinThresholdCallout';

import { useApp } from '@hooks/AppHooks';

import { ChartLeader, TChartType } from '@app/types/ChartDataTypes';
import { IPosLocation } from '@app/types/PosTypes';

import './LeaderboardUserChart.scss';

interface LeaderboardUserChartProps {
    leaders: ChartLeader[];
    spark: Spark;
    type: TChartType;
    locations: IPosLocation[];
    nonParticipantLeaders: NonParticipantLeader[];
}

export const LeaderboardUserChart: FC<LeaderboardUserChartProps> = ({
    leaders,
    spark,
    type,
    locations,
    nonParticipantLeaders,
}) => {
    const { appIsMobile } = useApp();
    const showNonParticipantChip = spark.trainingEnabled && spark.courseData?.required;

    const minThreshold = spark?.minimumThresholdToQualify ?? 0;
    const minTransactionCount = spark?.minimumTransactionsToQualify ?? 0;
    const topIneligibleParticipantId = useMemo(() => {
        return leaders.find(
            ({ value, transactionCount = 0 }) =>
                value < minThreshold || transactionCount < minTransactionCount,
        )?.flexibleEmployeeId;
    }, [minThreshold, minTransactionCount, leaders]);

    return (
        <Paper>
            <UserCharts
                people={leaders}
                type={type}
                metric={spark.metric}
                showRanks
                locations={locations}
                hideLocations={spark.detailedSparkType === 'leaderboardLocation'}
                hideTransactions={spark.detailedSparkType === 'leaderboardLocation' || appIsMobile}
                separator={{
                    listItemId: topIneligibleParticipantId,
                    element: <MinThresholdCallout className="leader-flow-min-threshold" />,
                }}
                excludedPeople={nonParticipantLeaders}
                showNonParticipantChip={showNonParticipantChip}
                spark={spark}
            />
        </Paper>
    );
};
