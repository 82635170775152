import { Skeleton } from '@mui/material';
import { sum } from 'lodash';

import { AccountLinkRow } from '@features/account-links/types';

const TagStatus = ({
    tagsEnabled = false,
    accountTagCountsAreReady,
    row,
}: {
    tagsEnabled?: boolean;
    accountTagCountsAreReady: boolean;
    row: AccountLinkRow;
}) => {
    if (!accountTagCountsAreReady) {
        return <Skeleton height={37} />;
    }

    const hasMappedBrands = row.brandLinks?.some((brandLink) => brandLink.posBrands.length);
    const totalProducts = sum(
        row.brandLinks?.flatMap(({ totalProductCount = 0 }) => totalProductCount),
    );

    return (
        <div
            className={`product-tag-status ${
                !hasMappedBrands ? 'product-tag-status_no-mapped' : ''
            }`}
        >
            <span className="product-tag-status-total">
                {totalProducts} Products <br />
            </span>
            {!hasMappedBrands && tagsEnabled && <span>None mapped</span>}
        </div>
    );
};

export default TagStatus;
