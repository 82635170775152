import { useEffect, useMemo, useState } from 'react';

import { IPosProduct } from '@app/types/PosTypes';

import { ProductRow, getHeadCells } from '../utils';
import { UsePosRulesBasedSparkProductSelectorTableFilters } from './usePosRulesBasedProductTableFilters';

export const usePosRulesBasedProductTableState = ({
    showPricesColumn = true,
    tableFilters,
    products,
}: {
    showPricesColumn?: boolean;
    tableFilters: UsePosRulesBasedSparkProductSelectorTableFilters;
    products: IPosProduct[];
}) => {
    const [showExcludedProductsTable, setShowExcludedProductsTable] = useState(false);

    const {
        filters,
        setFilters,
        applyHideSampleProducts,
        applyBrandFilters,
        applyCategoryFilters,
        applyLastSoldFilter,
        applyProductNameFilters,
        applyProductNameContainsFilter,
        applyProductNameDoesNotContainFilter,
        applySearch,
        applyIncludedProductsFilter,
        getExcludedProductsWithoutLastSoldAtFilter,
    } = tableFilters;

    const handleExcludeProduct = (row: ProductRow) => {
        setFilters((prevValue) => ({
            ...prevValue,
            excludedProductIds: [...prevValue.excludedProductIds, row._id],
        }));
    };

    const handleIncludeProduct = (row: ProductRow) => {
        setFilters((prevValue) => ({
            ...prevValue,
            excludedProductIds: prevValue.excludedProductIds.filter(
                (excludedProductId) => excludedProductId !== row._id,
            ),
        }));
    };

    /**
     * We do this because sometimes the excluded productIds are no longer
     * in the list of products in the table. In this case, we want to
     * make sure we only consider the actual excluded products and
     * not just the `excludedProductIds`
     *
     * NOTE: The following `useEffect` is to clean this up.
     *  */
    const hasIndividuallyExcludedProducts = useMemo(() => {
        if (filters.excludedProductIds.length) {
            return applyLastSoldFilter(products).some(({ _id }) =>
                filters.excludedProductIds.includes(_id),
            );
        }

        return false;
    }, [applyLastSoldFilter, filters.excludedProductIds.length]);

    useEffect(() => {
        if (!hasIndividuallyExcludedProducts) {
            setFilters((prevValue) => ({
                ...prevValue,
                excludedProductIds: [],
            }));
        }
    }, [hasIndividuallyExcludedProducts]);

    const isUsingFilterSegment =
        !filters.productNameFilters.length &&
        !filters.hideSampleProducts &&
        !hasIndividuallyExcludedProducts &&
        filters.lastSoldAt === '-60days';

    const headCells = useMemo(
        () =>
            getHeadCells({
                showPricesColumn,
                showExcludedProductsTable,
                isUsingFilterSegment,
                handleExcludeProduct,
                handleIncludeProduct,
            }),
        [showExcludedProductsTable, isUsingFilterSegment],
    );

    const currentTableFilterFns = showExcludedProductsTable
        ? [getExcludedProductsWithoutLastSoldAtFilter, applySearch]
        : [
              applySearch,
              applyBrandFilters,
              applyCategoryFilters,
              applyLastSoldFilter,
              ...(isUsingFilterSegment
                  ? [applyProductNameContainsFilter, applyProductNameDoesNotContainFilter]
                  : [
                        applyHideSampleProducts,
                        applyProductNameFilters,
                        applyIncludedProductsFilter,
                        applyProductNameContainsFilter,
                        applyProductNameDoesNotContainFilter,
                    ]),
          ];

    const emptyStateIsVisible =
        filters.brands.length === 0 &&
        filters.categories.length === 0 &&
        filters.productNameFilters.length === 0 &&
        filters.productNameContains.length === 0 &&
        filters.productNameDoesNotContain.length === 0;

    return {
        emptyStateIsVisible,
        headCells,
        isUsingFilterSegment,
        currentTableFilterFns,
        showExcludedProductsTable,
        setShowExcludedProductsTable,
    };
};

export type UsePosRulesBasedProductSelectorTableState = ReturnType<
    typeof usePosRulesBasedProductTableState
>;
