import { FC, ReactNode, useEffect, useState } from 'react';

import { Menu, PopoverOrigin } from '@mui/material';

import { ITestIdProps } from '@app/types/UITypes';

import { InternalMenuContext, useDropdown } from './DropdownContext';

export interface IDropdownMenuProps extends ITestIdProps {
    id?: string;
    anchorEl?: HTMLElement;
    isVisible: boolean;
    className?: string;
    onClose?: Function;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    hideBackdrop?: boolean;
    children: ReactNode;
    container?: HTMLElement;
}

export const ControlledDropdownMenu: FC<IDropdownMenuProps> = ({
    id = '',
    anchorEl,
    isVisible = false,
    className = '',
    'data-testid': dataTestId,
    onClose,
    anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin = {
        vertical: 'top',
        horizontal: 'left',
    },
    hideBackdrop,
    container,
    children,
}) => {
    const [activeMenuItemId, updateActiveMenuItemId] = useState<string>();
    const { handleClose } = useDropdown();

    useEffect(() => {
        if (!isVisible) {
            updateActiveMenuItemId(undefined);
        }
    }, [isVisible]);

    return (
        <Menu
            id={id}
            className={className}
            data-testid={dataTestId}
            PopoverClasses={{
                root: 'dropdown-popover',
            }}
            anchorEl={anchorEl}
            keepMounted
            open={isVisible}
            onClose={() => {
                handleClose();
                onClose?.();
            }}
            hideBackdrop={hideBackdrop}
            container={container}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
        >
            <InternalMenuContext.Provider value={{ activeMenuItemId, updateActiveMenuItemId }}>
                {children}
            </InternalMenuContext.Provider>
        </Menu>
    );
};

const UncontrolledDropdownMenu: FC<
    Omit<IDropdownMenuProps, 'anchorEl' | 'isVisible' | 'handleClose'>
> = (props) => {
    const { children, ...otherProps } = props;
    const { anchorEl } = useDropdown();

    return (
        <ControlledDropdownMenu anchorEl={anchorEl} isVisible={!!anchorEl} {...otherProps}>
            {children}
        </ControlledDropdownMenu>
    );
};

export default UncontrolledDropdownMenu;
