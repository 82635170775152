import { FC, memo, useMemo, useState } from 'react';

import {
    PRODUCT_NAME_FILTER_TYPES,
    ProductNameFilter,
    ProductNameFilterType,
} from '@sparkplug/lib';

import Chip from '@components/chips/Chip';
import { TExtendedListItem } from '@components/inputs/ListSelector/ListSelector';
import RadioGroup from '@components/inputs/RadioGroup';
import { RadioOption } from '@components/inputs/RadioGroup/RadioGroupOption';
import TextField from '@components/inputs/TextField';
import Toolbar from '@components/toolbar/Toolbar';

import { capitalizeFirstLetter, uuid } from '@helpers/ui';

import { InputEventOnChange } from '@app/types/UITypes';

import './ProductNameDropdown.scss';

const ProductNameLabel: FC<{ filterType?: ProductNameFilterType; text: string }> = ({
    filterType,
    text,
}) => {
    return (
        <>
            {filterType && (
                <Chip
                    className="product-name-label_filterType"
                    dense
                    color="neutral"
                    label={capitalizeFirstLetter(PRODUCT_NAME_FILTER_TYPES[filterType])}
                />
            )}
            <span>{text}</span>
        </>
    );
};

interface DynamicRadioOptionPopoverContentProps {
    updateOption: (
        updatedOption: TExtendedListItem<ProductNameFilter & { isValidOption: boolean }>,
    ) => void;
}

const DynamicRadioOptionPopoverContent = ({
    updateOption,
}: DynamicRadioOptionPopoverContentProps) => {
    const id = useMemo(() => uuid(), []);
    const [selected, setSelected] = useState<ProductNameFilterType>('CONTAINS');

    const onChangeSelected: InputEventOnChange = (event) => {
        setSelected(event.target.value);
    };

    const updateOptionText = (text: string) => {
        updateOption({
            text,
            filterType: selected,
            value: id,
            label: text,
            isValidOption: !!text && !!selected,
            labelComponent: () => <ProductNameLabel text={text} filterType={selected} />,
        });
    };

    const textField = (
        <TextField name={id} onChange={(event) => updateOptionText(event.target.value)} />
    );

    const radioOptions: RadioOption[] = useMemo(
        () => [
            {
                value: 'CONTAINS',
                label: 'contains',
                description: selected === 'CONTAINS' ? textField : undefined,
            },
            {
                value: 'DOES_NOT_CONTAIN',
                label: 'does not contain',
                description: selected === 'DOES_NOT_CONTAIN' ? textField : undefined,
            },
            {
                value: 'STARTS_WITH',
                label: 'starts with',
                description: selected === 'STARTS_WITH' ? textField : undefined,
            },
            {
                value: 'ENDS_WITH',
                label: 'ends with',
                description: selected === 'ENDS_WITH' ? textField : undefined,
            },
        ],
        [selected],
    );

    return (
        <div className="dynamic-radio-option-popover-content">
            <RadioGroup
                options={radioOptions}
                color="blue"
                value={selected}
                onChange={onChangeSelected}
            />
        </div>
    );
};

const mapProductNameFilterToListItem = (
    productNameFilter: ProductNameFilter,
): TExtendedListItem<ProductNameFilter> => ({
    ...productNameFilter,
    value: uuid(),
    label: `${PRODUCT_NAME_FILTER_TYPES[productNameFilter.filterType]} ${productNameFilter.text}`,
    labelComponent: () => (
        <ProductNameLabel text={productNameFilter.text} filterType={productNameFilter.filterType} />
    ),
});

interface ProductNameDropdownProps {
    initialProductNameFilters?: ProductNameFilter[];
    onApply?: (updatedSelected: ProductNameFilter[]) => void;
}

const ProductNameDropdown: FC<ProductNameDropdownProps> = memo(
    ({ initialProductNameFilters = [], onApply }) => {
        // We need to add a value and label to the initialProductNameFilters to work with the ListSelector
        const initialProductNameFiltersListItems = useMemo<TExtendedListItem<ProductNameFilter>[]>(
            () => initialProductNameFilters.map(mapProductNameFilterToListItem),
            [],
        );

        // These control the internal state, the external state is controlled by the onApply callback
        const [selectedProductNameFilters, setSelectedProductNameFilters] = useState<
            TExtendedListItem<ProductNameFilter>[]
        >(initialProductNameFiltersListItems);
        const [productNameFilters, setProductNameFilters] = useState<
            TExtendedListItem<ProductNameFilter>[]
        >(initialProductNameFiltersListItems);

        const updateList = (updatedSelected: TExtendedListItem<ProductNameFilter>) =>
            setProductNameFilters((prevValue) => [...prevValue, updatedSelected]);

        const onInternalApply = (updatedSelected: TExtendedListItem<ProductNameFilter>[]) => {
            setSelectedProductNameFilters(updatedSelected);
            onApply?.(updatedSelected);
        };

        return (
            <Toolbar.DropdownListSelector
                className="product-name-toolbar-dropdown"
                label="Product Name"
                selected={selectedProductNameFilters}
                options={productNameFilters as any}
                onApply={onInternalApply}
                showCount={!!(productNameFilters.length && selectedProductNameFilters.length)}
                selectedCountProps={{
                    pluralUnitLabel: 'filters',
                }}
                dynamicOptionProps={{
                    ManageListItemContent: DynamicRadioOptionPopoverContent,
                    updateList,
                }}
                hideToolbar
                clear={{
                    active: !!selectedProductNameFilters.length,
                    onClear: () => onInternalApply([]),
                }}
            />
        );
    },
);

export default ProductNameDropdown;
