import { uniqBy } from 'lodash';
import moment from 'moment';

import { SAMPLE_TEXT_TARGET, formatCurrency } from '@sparkplug/lib';

import IconButton from '@components/buttons/IconButton';
import { AddCircleOutline, RemoveCircleOutline } from '@components/icons';
import Table from '@components/table/Table';

import { wrapSubstringWithComponent } from '@helpers/ui';

import { IPosProduct } from '@app/types/PosTypes';
import { THeadCell } from '@app/types/TableTypes';

import { SampleText } from '../../constants';

export interface ProductRow extends IPosProduct {
    key: string;
    category: string;
    brand: string;
}

export const getHeadCells = ({
    showPricesColumn = true,
    showExcludedProductsTable,
    handleExcludeProduct,
    handleIncludeProduct,
    isUsingFilterSegment,
}: {
    showPricesColumn?: boolean;
    showExcludedProductsTable: boolean;
    handleExcludeProduct: (row: ProductRow) => void;
    handleIncludeProduct: (row: ProductRow) => void;
    isUsingFilterSegment: boolean;
}): THeadCell<ProductRow>[] => [
    {
        id: 'name',
        sortType: 'string',
        label: 'Product Name',
        render: (row) => {
            const hasSample = row.name.toLowerCase().includes(SAMPLE_TEXT_TARGET);

            return (
                <Table.Cell>
                    <div>
                        {hasSample
                            ? wrapSubstringWithComponent(SAMPLE_TEXT_TARGET, row.name, SampleText)
                            : row.name}
                    </div>
                </Table.Cell>
            );
        },
    },
    {
        id: 'category',
        sortType: 'string',
        label: 'Category',
        render: (row) => {
            const hasSample = row.category?.toLowerCase().includes(SAMPLE_TEXT_TARGET);

            return (
                <Table.Cell>
                    <div>
                        {hasSample
                            ? wrapSubstringWithComponent(
                                  SAMPLE_TEXT_TARGET,
                                  row.category!,
                                  SampleText,
                              )
                            : row.category ?? '--'}
                    </div>
                </Table.Cell>
            );
        },
    },
    {
        id: 'brand',
        sortType: 'string',
        label: 'Brand',
        render: (row) => {
            return (
                <Table.Cell>
                    <div>{row.brand ?? '--'}</div>
                </Table.Cell>
            );
        },
    },
    {
        id: 'price',
        label: 'Price',
        sortType: 'numeric',
        isHidden: !showPricesColumn,
        render: (row) => {
            return (
                <Table.Cell>
                    <div>{row.price ? formatCurrency(row.price, true) : '--'}</div>
                </Table.Cell>
            );
        },
    },
    {
        id: 'lastSoldAt',
        sortType: 'string',
        label: 'Last Sold Date',
        render: (row) => {
            return (
                <Table.Cell>
                    <div>{row.lastSoldAt ? moment(row.lastSoldAt).format('MM/DD/YYYY') : '--'}</div>
                </Table.Cell>
            );
        },
    },
    {
        id: 'includeExcludeProduct',
        label: '',
        isHidden: isUsingFilterSegment,
        render: (row) => {
            return (
                <Table.Cell>
                    <div>
                        {showExcludedProductsTable ? (
                            <IconButton
                                className="include-product-btn"
                                color="green"
                                onClick={() => handleIncludeProduct(row)}
                            >
                                <AddCircleOutline />
                            </IconButton>
                        ) : (
                            <IconButton
                                className="exclude-product-btn"
                                color="red"
                                onClick={() => handleExcludeProduct(row)}
                            >
                                <RemoveCircleOutline />
                            </IconButton>
                        )}
                    </div>
                </Table.Cell>
            );
        },
    },
];

export const mapProductsToProductRows = (products: IPosProduct[]): ProductRow[] =>
    uniqBy(products, '_id').map((product) => {
        const category =
            product.categories?.find(({ _id }) => _id === product.categoryId)?.name ?? '';
        const brand = product.brands?.find(({ _id }) => _id === product.brandId)?.name ?? '';
        return {
            ...product,
            key: product._id,
            category,
            brand,
        };
    });
