import { FC } from 'react';

import ConfirmModal from '@components/overlays/ConfirmModal';

import './EmployeeEarningsConfirmationModal.scss';

interface EmployeeEarningsConfirmationModalProps {
    isVisible: boolean;
    userEmail: string;
    onInitiateExport: () => void;
    onClose: () => void;
}
export const EmployeeEarningsConfirmationModal: FC<EmployeeEarningsConfirmationModalProps> = ({
    isVisible,
    userEmail,
    onInitiateExport,
    onClose,
}) => (
    <ConfirmModal
        className="employee-earnings-confirmation-modal"
        isVisible={isVisible}
        title="Export Employee Earnings"
        message={
            <span>
                An email with this export will be sent to <b>{userEmail}</b>. This may take several
                minutes to prepare.
            </span>
        }
        confirmText="Confirm"
        onConfirm={onInitiateExport}
        onClose={onClose}
    />
);
