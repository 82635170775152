import { FC, useMemo } from 'react';

import { PublicBrandLinkV2 } from '@sparkplug/lib';

import { DisabledLinkIcon } from '@components/icons';
import CalloutMessage, { CalloutMessageProps } from '@components/layout/CalloutMessage';
import { SectionTitle } from '@components/layout/SectionTitle';

import Typography from '@app/componentLibrary/Typography/Typography';

import BrandMappingRow, { BrandMappingRowProps } from './BrandMappingRow';

import '../shared.scss';
import './BrandMappings.scss';

interface BrandMappingsProps extends Omit<BrandMappingRowProps, 'brandLink'> {
    brandLinks: PublicBrandLinkV2[];
    accountName: string;
    isNewVendorLink?: boolean;
}

const BrandMappings: FC<BrandMappingsProps> = ({
    accountName,
    brandLinks = [],
    posBrandOptionsAreReady,
    posBrandOptions,
    onSave,
    accountLinkDisabled,
    isNewVendorLink,
}) => {
    const autoLinkingFeatureEnabled = import.meta.env.REACT_APP_AUTO_LINKING === 'true';

    const calloutMessageProps: CalloutMessageProps = useMemo(() => {
        const mappedBrandLinksCount = brandLinks.flatMap((link) => link.posBrands ?? []).length;

        if (autoLinkingFeatureEnabled && mappedBrandLinksCount === 0) {
            return {
                message:
                    'No brand matches found. To create a link, please map your POS brands to the Vendor brands.',
                color: 'yellow',
            };
        }

        if (accountLinkDisabled) {
            return {
                message: (
                    <Typography>
                        This link is disabled and <strong>{accountName}</strong> cannot send you
                        Spark offers or view product sales data.
                    </Typography>
                ),
                overrideIcon: <DisabledLinkIcon />,
            };
        }

        return {
            message: (
                <div>
                    <div>
                        Manage the <strong>{accountName}</strong> link by mapping brands from your
                        POS to each brand below.
                    </div>
                </div>
            ),
        };
    }, [accountName, accountLinkDisabled, autoLinkingFeatureEnabled, brandLinks, isNewVendorLink]);

    return (
        <section className="brand-mappings">
            <SectionTitle>Brand Mappings</SectionTitle>
            <CalloutMessage {...calloutMessageProps} />
            <div className="brand-mappings-rows">
                {brandLinks.map((brandLink) => (
                    <BrandMappingRow
                        key={brandLink.brandId}
                        posBrandOptionsAreReady={posBrandOptionsAreReady}
                        brandLink={brandLink}
                        posBrandOptions={posBrandOptions}
                        onSave={onSave}
                        accountLinkDisabled={accountLinkDisabled}
                    />
                ))}
            </div>
        </section>
    );
};

export default BrandMappings;
