import { FC, useMemo, useState } from 'react';

import Button from '@components/buttons/Button';
import TableCell from '@components/table/TableCell';
import TableRenderRow from '@components/table/TableRenderRow';
import { TableRow } from '@components/table/TableRenderRow/TableRenderRow';

import { useTableContext } from '@hooks/TableHooks';

import { IPosProduct } from '@app/types/PosTypes';
import { THeadCell } from '@app/types/TableTypes';

import { UsePosRulesBasedSparkProductSelectorTableFilters } from '../../hooks';
import { ProductRow, mapProductsToProductRows } from '../../utils';

import './OldProductsRows.scss';

interface OldProductsRowsProps {
    headCells: THeadCell<ProductRow>[];
    tableFilters: UsePosRulesBasedSparkProductSelectorTableFilters;
    products: IPosProduct[];
}

const OldProductsRows: FC<OldProductsRowsProps> = ({ headCells, tableFilters, products }) => {
    const { applyTableSort } = useTableContext<ProductRow>();

    const [oldProductRowsAreVisible, setOldProductRowsAreVisible] = useState(false);

    const { filters, getFilteredOldProducts } = tableFilters;

    const oldProductRows = useMemo<ProductRow[]>(() => {
        const filteredOldProducts = getFilteredOldProducts(products);
        const unsortedRows = mapProductsToProductRows(filteredOldProducts);

        return applyTableSort(unsortedRows);
    }, [filters, applyTableSort]);

    if (!oldProductRows.length) {
        return <></>;
    }

    return (
        <>
            <TableRow>
                <TableCell className="old-products-toggle" colSpan={headCells.length}>
                    {oldProductRowsAreVisible ? (
                        <>
                            <span>
                                Products below are not included because they were sold more than 60
                                days ago. If sold again, they will be automatically included as
                                qualifying products.
                            </span>
                            <Button
                                color="blue"
                                variant="flat"
                                className="hide-old-products-btn"
                                onClick={() => setOldProductRowsAreVisible(false)}
                            >
                                Hide old products
                            </Button>
                        </>
                    ) : (
                        <Button
                            className="show-old-products-btn"
                            onClick={() => setOldProductRowsAreVisible(true)}
                        >
                            Show old products
                        </Button>
                    )}
                </TableCell>
            </TableRow>
            {oldProductRowsAreVisible &&
                oldProductRows.map((row) => (
                    <TableRenderRow
                        key={row.key}
                        tableHeadData={headCells}
                        row={row}
                        isSelected={false}
                        hover
                        className="is-old-product"
                    />
                ))}
        </>
    );
};

export default OldProductsRows;
