import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { hotjar } from 'react-hotjar';

import App from './core/app/App';
import reportWebVitals from './reportWebVitals';

import './index.css';

const { REACT_APP_ENV, REACT_APP_SENTRY_KEY, REACT_APP_HOTJAR_SITE_ID } = import.meta.env;

/**
 * Initialize Sentry only if key is present
 */
if (REACT_APP_ENV === 'prod' && REACT_APP_SENTRY_KEY) {
    Sentry.init({
        dsn: REACT_APP_SENTRY_KEY,
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        // How often to record user sessions. Right now, we only want to record errors so this is 0%
        replaysSessionSampleRate: 0,
        // This is the sample rate to record sessions when an error occurs. Right now, this is 100% so that we record all errors
        replaysOnErrorSampleRate: 1.0,

        // This is to determine trace sampling.
        tracesSampler: (samplingContext) => {
            if (samplingContext.transactionContext.name.startsWith('/user')) {
                return 0.5; // For employee app 50% sampling rate
            }
            return 0.2; // Default to 20% sampling for other routes
        },

        ignoreErrors: [
            /ResizeObserver/i,
            /Network Error/i,
            /Failed to fetch/i,
            /Failed to load resource/i,
            /Load failed/i,
            /Request failed with status code 401/i,
            /Event buffer exceeded maximum size/i, // internal Sentry error - we can remove this when we update the version of Sentry used in the UI
        ],
    });
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <StrictMode>
        <App />
    </StrictMode>,
);

/**
 * Initialize Hotjar only if site ID is present
 */

const hotJarVersion = 6;
if (REACT_APP_HOTJAR_SITE_ID) {
    hotjar.initialize({ id: Number(REACT_APP_HOTJAR_SITE_ID), sv: hotJarVersion });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
