import PageLoading from '@components/layout/PageLoading';

const AppLoadingView = () => {
    return (
        <div>
            <PageLoading label="Loading SparkPlug..." />
        </div>
    );
};

export default AppLoadingView;
