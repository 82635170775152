import { useMemo } from 'react';

import { uniq } from 'lodash';
import moment from 'moment';

import { useVendorChartProductsWithSales } from '@core/charts/queries/ChartsQueries';

import { filterTreeByLeafLabels } from '@helpers/ui';

import { IChartDataSettings } from '@app/types/ChartDataTypes';
import { IPosProduct } from '@app/types/PosTypes';
import { IOptionTree } from '@app/types/UITypes';

export const useProductsOptionsWithSales = ({
    vendorAccountId,
    vendorRetailerId,
    currentDateRange,
    previousDateRange = {},
    productHierarchy = [],
}: {
    vendorAccountId: string;
    vendorRetailerId: string;
    currentDateRange: Pick<IChartDataSettings, 'dateStart' | 'dateEnd'>;
    previousDateRange?: Partial<
        Pick<IChartDataSettings, 'dateStart' | 'dateEnd'> & { isEnabled: boolean }
    >;
    productHierarchy: IOptionTree[];
}) => {
    const { vendorChartProductsWithSales, vendorChartProductsWithSalesAreReady } =
        useVendorChartProductsWithSales(
            {
                vendorAccountId,
            },
            {
                dateStart: moment(currentDateRange.dateStart).format('YYYY-MM-DD'),
                dateEnd: moment(currentDateRange.dateEnd).format('YYYY-MM-DD'),
                frequency: 'daily',
                retailerAccountIds: [vendorRetailerId],
            },
        );

    const previousPeriodIsEnabled =
        !!previousDateRange.isEnabled &&
        !!previousDateRange.dateStart &&
        !!previousDateRange.dateEnd;
    const {
        vendorChartProductsWithSales: previousPeriodProductsWithSales,
        vendorChartProductsWithSalesAreReady: previousPeriodProductsWithSalesAreReady,
    } = useVendorChartProductsWithSales(
        {
            vendorAccountId,
        },
        {
            dateStart: moment(previousDateRange.dateStart).format('YYYY-MM-DD'),
            dateEnd: moment(previousDateRange.dateEnd).format('YYYY-MM-DD'),
            frequency: 'daily',
            retailerAccountIds: [vendorRetailerId],
        },
        previousPeriodIsEnabled,
    );

    // All brand retailer products visible for the current date range
    const productsOptionsWithSales = useMemo<IOptionTree<IPosProduct>[]>(() => {
        const productsWithSales = uniq([
            ...(vendorChartProductsWithSales?.productsWithSales ?? []),
            ...(previousPeriodIsEnabled
                ? previousPeriodProductsWithSales?.productsWithSales ?? []
                : []),
        ]);

        return filterTreeByLeafLabels(
            productHierarchy,
            productsWithSales,
        ) as IOptionTree<IPosProduct>[];
    }, [
        productHierarchy,
        vendorChartProductsWithSales?.productsWithSales,
        previousPeriodIsEnabled,
        previousPeriodProductsWithSales?.productsWithSales,
    ]);

    return {
        productsOptionsWithSales,
        productOptionsAreReady:
            vendorChartProductsWithSalesAreReady && previousPeriodIsEnabled
                ? previousPeriodProductsWithSalesAreReady
                : true,
    };
};
