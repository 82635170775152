import axios from 'axios';
import { isNaN, isNil, omitBy } from 'lodash';

import { ListEventsQueryParams, PagedApiResponseBody, UIEvent } from '@sparkplug/lib';

import { queryToString } from '@components/router';

import { useAdvancedQuery } from '@hooks/QueryHooks';

const EVENT_LIMIT_FALLBACK = 10 as const;

class EventAPI {
    static async getEvents(
        params: Partial<ListEventsQueryParams>,
        locationIds?: string[],
    ): Promise<PagedApiResponseBody<UIEvent>> {
        const normalizedParams = {
            ...omitBy(
                {
                    ...params,
                    locationIds: undefined,
                },
                isNil,
            ),
            offset: params.offset ?? 0,
            limit: params.limit ?? EVENT_LIMIT_FALLBACK,
        };
        return (
            await axios.post<PagedApiResponseBody<UIEvent>>(
                `/api/v1/event/list?${queryToString(normalizedParams)}`,
                {
                    locationIds,
                },
            )
        ).data;
    }

    static async getEvent(eventId: string): Promise<UIEvent> {
        return (await axios.get<{ data: UIEvent }>(`/api/v1/event/${eventId}`)).data?.data;
    }
}

const normalizeEventQueryParams = (
    eventFilters?: ListEventsQueryParams,
): Partial<ListEventsQueryParams> => {
    return omitBy(eventFilters, (value, key) => {
        if (key !== 'limit') return false;
        const limitNumber = Number(value);
        return !isNaN(limitNumber) && limitNumber <= 0;
    });
};

export const getEventsQueryKey = (accountId: string, eventFilters?: ListEventsQueryParams) => [
    'event',
    'list',
    accountId,
    JSON.stringify(eventFilters),
];

export const useGetEventsQuery = ({
    accountId,
    eventFilters,
    locationIds,
}: {
    accountId: string;
    eventFilters?: ListEventsQueryParams;
    locationIds?: string[];
}) => {
    const {
        isLoading: eventsAreLoading,
        data,
        refetch,
        isFetched,
    } = useAdvancedQuery(
        getEventsQueryKey(accountId, eventFilters),
        () => {
            const normalizedEventFilters = normalizeEventQueryParams(eventFilters);
            return EventAPI.getEvents(
                {
                    ...normalizedEventFilters,
                    accountId,
                },
                locationIds,
            );
        },
        {
            enabled: !!accountId,
        },
    );

    return {
        eventsAreLoading,
        events: data?.data ?? [],
        meta: data?.meta,
        refetchEvents: refetch,
        eventsAreReady: isFetched,
    };
};

export const useGetEventQuery = (eventId: string) => {
    return useAdvancedQuery(['event', eventId], () => EventAPI.getEvent(eventId));
};
