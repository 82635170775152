import { FC } from 'react';
// eslint-disable-next-line no-restricted-imports
import ReactLoadingSkeleton, { SkeletonProps } from 'react-loading-skeleton';

import { appendClasses } from '@helpers/ui';

import './Skeleton.scss';

const Skeleton: FC<SkeletonProps> = ({ className, ...otherProps }) => {
    const classNameAppended = appendClasses([className, 'skeleton']);

    return <ReactLoadingSkeleton className={classNameAppended} {...otherProps} />;
};

export default Skeleton;
