import axios from 'axios';

import {
    BatchTextUsersRequestBody,
    CreatePosConfigRequestBody,
    CreatePosConfigResponseBody,
    GetPosConfigPathParams,
    GetPosConfigResponseBody,
    HydratedPosProduct,
    ListPosBrandsQueryParams,
    ListPosBrandsResponseBody,
    ListPosCategoriesQueryParams,
    ListPosCategoriesResponseBody,
    ListPosConfigsQueryParams,
    ListPosConfigsResponseBody,
    ListPosLocationsQueryParams,
    ListPosLocationsResponseBody,
    ListPosProductsQueryParams,
    ListPosProductsResponseBody,
    PosConfigType,
    PosProduct,
    ReimportPosConfigQueryParams,
    UpdatePosConfigPathParams,
    UpdatePosConfigRequestBody,
    VerifyCredsRequestBody,
} from '@sparkplug/lib';

import { getTracer } from '@helpers/tracer';

import API from './API';

export default class PointOfSaleAPI {
    static async getLocations({
        group_id: groupId,
    }: ListPosLocationsQueryParams): Promise<ListPosLocationsResponseBody> {
        return (await axios.get(`/api/v1/pos/locations?group_id=${groupId}`)).data;
    }

    static async getTransactionSummaryCount(locationId: string): Promise<any> {
        const url = `/api/v1/pos/locations/${locationId}/transactions/summaries/count`;
        return (await axios.get(url)).data;
    }

    static async getUsers({
        groupId,
        locationId,
        firstName,
        lastName,
    }: {
        groupId?: string;
        locationId?: string;
        firstName?: string;
        lastName?: string;
    }): Promise<any> {
        let queryString = '';

        if (groupId) {
            queryString += `group_id=${groupId}&`;
        }
        if (locationId) {
            queryString += `location_id=${locationId}&`;
        }
        if (firstName) {
            queryString += `fname=${firstName}&`;
        }
        if (lastName) {
            queryString += `lname=${lastName}`;
        }

        return (await axios.get(`/api/v1/pos/employee-profiles?${queryString}`)).data;
    }

    static async updateUser(posEmployeeProfileId: string, props: any): Promise<any> {
        return (await axios.put(`/api/v1/pos/employee-profiles/${posEmployeeProfileId}`, props))
            .data;
    }

    static async getUserById(userId: string): Promise<any> {
        return (await axios.get(`/api/v1/pos/employee-profiles/${userId}`)).data;
    }

    static async sendPosEmployeeProfilesSMS(payload: BatchTextUsersRequestBody) {
        return (await axios.post<void>(`/api/v1/pos/employee-profiles/multi/sms`, payload)).data;
    }

    static async listConfigs(
        params: ListPosConfigsQueryParams,
    ): Promise<ListPosConfigsResponseBody> {
        return (await axios.get('/api/v1/pos/config', { params })).data;
    }

    static async listLocationConfigs(locationId: string): Promise<any> {
        return (await axios.get(`/api/v1/pos/config/location/${locationId}`)).data;
    }

    static async reimportConfig(
        configId: string,
        params?: ReimportPosConfigQueryParams,
    ): Promise<void> {
        return (await axios.post(`/api/v1/pos/config/${configId}/reimport`, null, { params })).data;
    }

    static async getConfig({
        configId,
    }: GetPosConfigPathParams): Promise<GetPosConfigResponseBody> {
        return (await axios.get(`/api/v1/pos/config/${configId}`)).data;
    }

    static async updateConfig(
        { configId }: UpdatePosConfigPathParams,
        body: UpdatePosConfigRequestBody,
    ): Promise<void> {
        return (await axios.put(`/api/v1/pos/config/${configId}`, body)).data;
    }

    static async createConfig(
        body: CreatePosConfigRequestBody,
    ): Promise<CreatePosConfigResponseBody> {
        return (await axios.post('/api/v1/pos/config', body)).data;
    }

    static async verifyPosCredentials(
        type: PosConfigType,
        body: VerifyCredsRequestBody,
    ): Promise<void> {
        return (await axios.post(`/api/v1/pos/credentials/${type}`, body)).data;
    }

    static async getCategories(
        params: ListPosCategoriesQueryParams,
    ): Promise<ListPosCategoriesResponseBody> {
        return (await axios.get('/api/v1/pos/categories', { params })).data;
    }

    static async getAllCategories(groupId: string): Promise<any> {
        const fetchFn = (offset: number, limit: number) => {
            return PointOfSaleAPI.getCategories({ groupId, offset, limit });
        };
        return API.fetchBatchedData(fetchFn);
    }

    static async getBrands(params: ListPosBrandsQueryParams): Promise<ListPosBrandsResponseBody> {
        return (await axios.get('/api/v1/pos/brands', { params })).data;
    }

    static getAllBrands(groupId: string): Promise<any> {
        const fetchFn = (offset: number, limit: number) => {
            return PointOfSaleAPI.getBrands({ groupId, offset, limit });
        };
        return API.fetchBatchedData(fetchFn);
    }

    static async getProducts(
        params: ListPosProductsQueryParams,
    ): Promise<ListPosProductsResponseBody> {
        if (params.posProductIds?.length) {
            const { posProductIds, ...rest } = params;
            return (
                await axios.post(
                    `/api/v1/pos/products`,
                    { posProductIds },
                    {
                        params: {
                            ...rest,
                            offset: params.offset ?? 0,
                            limit: params.limit ?? 100,
                        },
                    },
                )
            ).data;
        }
        return (
            await axios.get(`/api/v1/pos/products`, {
                params: {
                    ...params,
                    offset: params.offset ?? 0,
                    limit: params.limit ?? 100,
                },
            })
        ).data;
    }

    static async getAllProducts(
        params: ListPosProductsQueryParams,
    ): Promise<{ data: Array<PosProduct | HydratedPosProduct> }> {
        const span = getTracer('ui').startSpan('getAllProducts');
        const fetchFn = (offset: number, limit: number) => {
            return PointOfSaleAPI.getProducts({ ...params, offset, limit });
        };

        const result = await API.fetchBatchedData(fetchFn);
        span.end();
        return result;
    }
}
