import { useState } from 'react';

import VendorMembersTable from '@views/admin/AdminEmployeesView/VendorMembersTable';
import SettingsTitle from '@views/admin/components/SettingsTitle';

import { GroupAdd as GroupAddIcon, RocketIcon } from '@components/icons';
import AppView from '@components/layout/AppView';
import SelfServiceOnboardingInstructionsModal from '@components/overlays/SelfServiceOnboardingInstructionsModal';
import RetailerUsersTable from '@components/tables/RetailerUsersTable';
import {
    SelfServiceQueueButton,
    SelfServiceQueueProvider,
} from '@components/tables/UsersTable/SelfServiceQueue';
import Toolbar from '@components/toolbar/Toolbar';

import {
    useSparkplugAccount,
    useSparkplugAccountUsers,
} from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { IAccount, TAccountType } from '@app/types/AccountsTypes';

import './AdminEmployeesView.scss';

const SelfEnrollToolbar = ({
    accountType,
    onClickSelfServiceOnboarding,
}: {
    accountType: TAccountType;
    onClickSelfServiceOnboarding: () => void;
}) => {
    if (accountType !== 'retailer') {
        return <></>;
    }

    return (
        <div className="self-enroll-wrapper">
            <Toolbar className="hide-on-mobile">
                <Toolbar.Text>
                    <RocketIcon className="rocket" />
                    <span>Want to get set up fast? Try</span>
                </Toolbar.Text>
                <Toolbar.Button
                    startIcon={<GroupAddIcon />}
                    color="blue"
                    variant="filled"
                    onClick={onClickSelfServiceOnboarding}
                >
                    Self-Service Onboarding
                </Toolbar.Button>
            </Toolbar>
            <SelfServiceQueueProvider>
                <SelfServiceQueueButton />
            </SelfServiceQueueProvider>
        </div>
    );
};

const AdminEmployeesView = () => {
    const { account } = useSparkplugAccount();

    const { accountUsers, refetchAccountUsers, accountUsersAreReady } = useSparkplugAccountUsers();

    const [showOnboardingModal, setShowOnboardingModal] = useState(false);

    const props = {
        isLoadingAccountUsers: !accountUsersAreReady,
        account,
        accountUsers,
        refetchAccountUsers,
    };

    return (
        <AppView.Content
            title={account?.type === 'brand' ? 'Team Members' : <SettingsTitle label="Users" />}
            toolbar={
                <SelfEnrollToolbar
                    accountType={account?.type as TAccountType}
                    onClickSelfServiceOnboarding={() => setShowOnboardingModal(true)}
                />
            }
            className="admin-employees-view"
        >
            {account?.type === 'retailer' ? (
                <RetailerUsersTable {...props} />
            ) : (
                <VendorMembersTable {...props} />
            )}
            <SelfServiceOnboardingInstructionsModal
                account={account as IAccount}
                isVisible={showOnboardingModal}
                onClose={() => {
                    setShowOnboardingModal(false);
                }}
            />
        </AppView.Content>
    );
};

export default AdminEmployeesView;
