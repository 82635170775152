import { FC } from 'react';

import Button, { IButtonProps } from '@components/buttons/Button';

import { appendClasses } from '@helpers/ui';

import { useIsInToolbar } from '../Toolbar/useIsInToolbar';
import ToolbarGroup, { ToolbarGroupProps } from '../ToolbarGroup';

import './ToolbarButton.scss';

interface ToolbardButtonProps extends ToolbarGroupProps, IButtonProps {}

const ToolbarButton: FC<ToolbardButtonProps> = ({
    className,
    alignStart,
    alignEnd,
    ...buttonProps
}) => {
    useIsInToolbar({ componentName: 'Toolbar.Button' });
    const classNameAppended = appendClasses([className, 'toolbar-button']);
    const toolbarGroupProps = { alignStart, alignEnd };

    return (
        <ToolbarGroup className={classNameAppended} {...toolbarGroupProps}>
            <Button {...buttonProps} />
        </ToolbarGroup>
    );
};

export default ToolbarButton;
