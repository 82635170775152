import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';

import { BrandProductTag, TAG_COLOR_OPTIONS, TagColorOption } from '@sparkplug/lib';

import { useBrandTagGroups } from '@features/product-tags/queries';

import Button from '@components/buttons/Button';
import ColorSelector from '@components/inputs/ColorSelector';
import TextField from '@components/inputs/TextField';
import Spinner from '@components/layout/Spinner';

const defaultTagForm = {
    name: '',
    color: TAG_COLOR_OPTIONS[0],
    _id: '',
};
export const TagForm: FC<{
    tagGroupId: string;
    selectedBrandId: string;
    setIsCreatingTag: Dispatch<SetStateAction<boolean>>;
    onSave: (tagForm: BrandProductTag) => void;
    isLoading?: boolean;
    tag?: BrandProductTag;
}> = ({ tagGroupId, setIsCreatingTag, selectedBrandId, onSave, isLoading, tag }) => {
    const [tagForm, setTagForm] = useState<BrandProductTag>(tag || defaultTagForm);
    const [error, setError] = useState<string | undefined>();
    const { brandTagGroupsAreReady, brandTagGroups = [] } = useBrandTagGroups(selectedBrandId);

    const existingTagNames = useMemo(
        () =>
            brandTagGroups
                .find((group) => group._id === tagGroupId)
                ?.tags.map((t) => t.name.toLowerCase())
                .filter((x) => x !== tagForm.name.toLowerCase()) ?? [],

        [brandTagGroupsAreReady, brandTagGroups],
    );

    const checkExists = (newValue: string) => existingTagNames?.includes(newValue.toLowerCase());

    const errors = {
        required: 'A tag name is required',
        exists: 'A tag with this name already exists',
    };

    const handleColorChange = (value: TagColorOption) => {
        setTagForm({ ...tagForm, color: value });
    };

    const handleChange = (newValue: string) => {
        if (newValue === '') {
            setError(errors.required);
        } else if (checkExists(newValue)) {
            setError(errors.exists);
        } else {
            setError(undefined);
        }
        setTagForm({ ...tagForm, name: newValue });
    };

    const handleSave = () => {
        if (error) return;
        onSave(tagForm);
    };

    return (
        <div className="tag-form" data-testid="tag-form">
            <div className="tag-form-inputs">
                <div className="tag-color-selector">
                    <ColorSelector
                        onChange={(value) => handleColorChange(value)}
                        value={tagForm.color}
                    />
                </div>
                <TextField
                    error={!!error}
                    name="tag-name"
                    className="tag-form-input"
                    data-testid="tag-form-name"
                    value={tagForm.name}
                    required
                    onChange={(e) => handleChange(e.target.value)}
                    autoFocus
                    helperText={error}
                    maxLength={80}
                    autoComplete="off"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSave();
                        }
                    }}
                />
            </div>
            <Button onClick={() => setIsCreatingTag(false)}>Cancel</Button>
            <Button
                onClick={() => handleSave()}
                disabled={isLoading || !!error || tagForm.name === ''}
                endIcon={isLoading ? <Spinner /> : null}
            >
                Save
            </Button>
        </div>
    );
};
