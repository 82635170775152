import { useState } from 'react';

import moment from 'moment/moment';

import { HydratedBrandProduct } from '@sparkplug/lib';

import { useSearch } from '@hooks/UIHooks';

export interface ProductTagsTableFilters {
    brandIds: string[];
    categoryIds: string[];
    tagIds: string[];
    hideOldProducts: boolean;
}

export interface ProductTagsTableRow extends HydratedBrandProduct {
    key: string;
    brand: string;
    category: string;
}

export const useProductTagsTableFilters = ({
    initialFilterValues,
}: {
    initialFilterValues?: Partial<ProductTagsTableFilters>;
}) => {
    const { applySearch, onChangeSearchFilter } = useSearch(['name', 'brand', 'category']);

    const [filters, setFilters] = useState<ProductTagsTableFilters>({
        brandIds: [],
        categoryIds: [],
        tagIds: [],
        hideOldProducts: true,
        ...initialFilterValues,
    });

    const applyBrandFilters = (rows: ProductTagsTableRow[]) => {
        if (!filters.brandIds.length) {
            return rows;
        }

        return rows.filter(({ brands }) => {
            return brands.some(({ _id }) => filters.brandIds.includes(_id ?? ''));
        });
    };

    const applyCategoryFilters = (rows: ProductTagsTableRow[]) => {
        if (!filters.categoryIds.length) {
            return rows;
        }

        return rows.filter(({ categories }) => {
            return categories.some(({ _id }) => filters.categoryIds.includes(_id ?? ''));
        });
    };

    const applyLast60DaysFilter = (rows: ProductTagsTableRow[]) => {
        if (!filters.hideOldProducts) {
            return rows;
        }

        return rows.filter(({ lastSoldAt }) => {
            return moment(lastSoldAt).isSameOrAfter(moment().subtract(60, 'days'), 'day');
        });
    };

    const applyTagFilters = (rows: ProductTagsTableRow[]) => {
        if (!filters.tagIds.length) {
            return rows;
        }

        return rows.filter(({ tags }) => {
            const filterForTags = tags?.some(({ _id }) => filters.tagIds.includes(_id ?? ''));
            const filterForNoTags = !tags || !tags.length;

            return filters.tagIds.includes('none')
                ? filterForTags || filterForNoTags
                : filterForTags;
        });
    };

    return {
        filters,
        setFilters,
        applyBrandFilters,
        applyCategoryFilters,
        applyTagFilters,
        applyLast60DaysFilter,
        applySearch,
        onChangeSearchFilter,
    };
};

export type UseProductTagsTableFilters = ReturnType<typeof useProductTagsTableFilters>;
