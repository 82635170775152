import { useState } from 'react';

import { keyBy } from 'lodash';

import { ISnap } from '@sparkplug/lib';

import { useSearch } from '@hooks/UIHooks';

import { IOption } from '@app/types/UITypes';

type ISnapRow = ISnap & {
    views: number;
    totalViewTime: number;
    brandThumbnailUrl?: string;
    key: string;
};
interface FeaturedSnapFilters {
    featuredSnapStatus?: 'removed' | 'live';
    brands: IOption[];
}

export const useFeaturedSnapFilters = ({
    initialFilterValues,
}: {
    initialFilterValues?: Partial<FeaturedSnapFilters>;
}) => {
    const [filters, setFilters] = useState({
        featuredSnapStatus: undefined,
        brands: [],
        ...initialFilterValues,
    });

    const { searchFilter, onChangeSearchFilter, applySearch } = useSearch(['name']);

    const applyBrandFilter = (snaps: ISnapRow[]) => {
        if (!filters.brands.length) {
            return snaps;
        }

        const selectedBrandsByValue = keyBy(filters.brands, 'value');

        return snaps.filter((snap) => snap.brandId && selectedBrandsByValue[snap.brandId]);
    };

    const applyFeaturedSnapStatus = (snaps: ISnapRow[]) => {
        return snaps.filter((snap) => {
            if (!filters.featuredSnapStatus) {
                return true;
            }
            if (filters.featuredSnapStatus === 'live') {
                return !snap.featuredPeriods?.[0]?.endDate;
            }

            return snap.featuredPeriods?.[0]?.endDate;
        });
    };
    return {
        filters,
        setFilters,
        applyBrandFilter,
        applyFeaturedSnapStatus,
        applySearch,
        searchFilter,
        onChangeSearchFilter,
    };
};
