import { useMemo } from 'react';

import { BankAccount, Card, PaymentMethod } from '@sparkplug/lib';

import { BankIcon, CardIcon } from '@components/icons';
import Select from '@components/inputs/Select';

import { appendClasses } from '@helpers/ui';
import { isEmpty } from '@helpers/util';

import { IOption } from '@app/types/UITypes';

import './PaymentMethodDropdown.scss';

const paymentIconMap = {
    bank: <BankIcon />,
    card: <CardIcon />,
};

const PaymentMethodLabel = ({
    paymentType,
    labelText,
}: {
    paymentType: 'bank' | 'card';
    labelText: string;
}) => {
    return (
        <div className="payment-method-label">
            {paymentIconMap[paymentType]}
            <span>{labelText}</span>
        </div>
    );
};

const getCardLabel = (cardInfo: PaymentMethod) => {
    const { last4, brand, expMonth = 0, expYear } = cardInfo as Card;

    const formattedExpMonth = expMonth < 10 ? `0${expMonth}` : expMonth;

    const labelText = `${brand} ****${last4} ${formattedExpMonth}/${expYear}`;
    return <PaymentMethodLabel paymentType="card" labelText={labelText} />;
};

const getBankLabel = (bankInfo: PaymentMethod) => {
    const { bank, last4 } = bankInfo as BankAccount;

    const labelText = `${bank} ****${last4}`;
    return <PaymentMethodLabel paymentType="bank" labelText={labelText} />;
};

const formatPaymentMethodsForSelect = (paymentMethods: PaymentMethod[]): IOption[] => {
    const options = paymentMethods.map((paymentMethod) => {
        const label =
            paymentMethod.type === 'bank'
                ? getBankLabel(paymentMethod)
                : getCardLabel(paymentMethod);

        return {
            value: paymentMethod.id,
            label,
        };
    });

    const emptyPaymentMethodOption = { value: '', label: 'Send without charging' };
    return [emptyPaymentMethodOption, ...(options as any)];
};

const PaymentMethodDropdown = ({
    paymentMethods,
    selectedPaymentId,
    onChange,
    className,
    disabled = false,
}: {
    paymentMethods: PaymentMethod[];
    selectedPaymentId: string;
    onChange: (paymentMethodId: string) => void;
    className?: string;
    disabled?: boolean;
}) => {
    const paymentMethodOptions = useMemo(
        () => formatPaymentMethodsForSelect(paymentMethods),
        [paymentMethods],
    );

    const fullClassName = appendClasses(['payment-method-dropdown', className]);
    return (
        <Select
            displayEmpty
            className={fullClassName}
            label="Payment Method"
            options={paymentMethodOptions}
            value={selectedPaymentId}
            disabled={disabled || isEmpty(paymentMethods)}
            onChange={(e) => onChange(e.target.value)}
        />
    );
};

export default PaymentMethodDropdown;
