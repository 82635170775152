import { SparkDisplayStatus } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import CalloutMessage from '@components/layout/CalloutMessage';
import Modal from '@components/overlays/Modal';

import { useApp } from '@hooks/AppHooks';

import './ExportSparksModal.scss';

type TExportSparksModal = {
    isVisible: boolean;
    onConfirm: () => void;
    onClose: () => void;
    initialStatus: SparkDisplayStatus;
};

const ExportSparksModal = ({
    isVisible,
    onConfirm,
    onClose,
    initialStatus,
}: TExportSparksModal) => {
    const { user } = useApp();
    /*
    const [checkedStatuses, setCheckedStatuses] = useState([initialStatus]);

    Future feature: allow user to select which statuses to include in the export
    Backend query does not support this yet.

    const statuses: SparkDisplayStatus[] = ['active', 'upcoming', 'complete', 'inbox/sent'];
    const handleCheckboxChange = (status: SparkDisplayStatus) => {
        setCheckedStatuses((prevStatuses) => {
            if (prevStatuses.includes(status)) {
                return prevStatuses.filter((s) => s !== status);
            } else {
                return [...prevStatuses, status];
            }
        });
    };
    */
    return (
        <Modal isVisible={isVisible} onClose={onClose} className="export-modal">
            <Modal.Title className="export-modal_title">
                Export <span className="export-modal_status">{initialStatus}</span> Sparks
            </Modal.Title>
            <Modal.Content className="export-modal_content">
                <CalloutMessage
                    className="export-modal_callout-message"
                    message={
                        <>
                            Any filters applied to the Sparks table will be reflected in the CSV
                            export.
                        </>
                    }
                />
                {/* 
                <div className="export-modal_options-label">
                    Select which Spark statuses to include in the export:
                </div>
                {statuses.map((status) => {
                    return (
                        <div key={status} className="export-modal_option">
                            <Form.Checkbox
                                label={status.charAt(0).toUpperCase() + status.slice(1)}
                                value={checkedStatuses.includes(status)}
                                onChange={() => handleCheckboxChange(status)}
                            />
                        </div>
                    );
                })} */}
                <p className="export-modal_message">
                    {user?.email} will receive an email containing the CSV. Please allow a few
                    minutes for this to arrive.
                </p>
            </Modal.Content>
            <Modal.Actions>
                <div className="export-modal_action-buttons">
                    <Button color="neutral" variant="flat" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        color="blue"
                        variant="filled"
                        onClick={() => {
                            onConfirm();
                            onClose();
                        }}
                    >
                        Confirm
                    </Button>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

export default ExportSparksModal;
