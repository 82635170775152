import { useMemo, useState } from 'react';

import { SparkCommissionType } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import { FileCopy as CopyIcon } from '@components/icons';
import TextField from '@components/inputs/TextField';
import Toolbar from '@components/toolbar/Toolbar';

import { useTableContext } from '@hooks/TableHooks';

import { CommissionMap } from '../../helpers/getHeadCells';
import { ProductCommissionRow } from '../../hooks/useCommissionTableProducts';

import './ToolbarCommissionInput.scss';

export const UpdateSparkCommissionsWizardStage = 3 as const;

interface ToolbarCommissionInputProps {
    updateDisplayedCommissionValues: (
        productIds: string[],
        applyAllValue: number | undefined,
    ) => void;
    commissionType: SparkCommissionType;
    isVendorEditExperience: boolean;
    originalCommissionValuesMap: CommissionMap;
}

const ToolbarCommissionInput = ({
    updateDisplayedCommissionValues,
    commissionType,
    isVendorEditExperience,
    originalCommissionValuesMap,
}: ToolbarCommissionInputProps) => {
    const [applyAllValue, setApplyAllValue] = useState<number>();
    const { tableFilteredRows } = useTableContext<ProductCommissionRow>();

    const onUpdateDisplayedCommissionValues = () => {
        let visibleProductIds = tableFilteredRows.map(({ _id }) => _id);
        if (isVendorEditExperience) {
            visibleProductIds = visibleProductIds.filter(
                (id) => originalCommissionValuesMap[id] === undefined,
            );
        }
        updateDisplayedCommissionValues(visibleProductIds, applyAllValue);
    };
    const commissionlessRowsCount = useMemo(
        () => tableFilteredRows.length - Object.keys(originalCommissionValuesMap).length,
        [tableFilteredRows],
    );

    return (
        <Toolbar.Group alignEnd>
            <Button
                startIcon={<CopyIcon />}
                color="neutral"
                variant="flat"
                onClick={onUpdateDisplayedCommissionValues}
            >
                {`Copy to ${
                    isVendorEditExperience && commissionlessRowsCount > 0
                        ? commissionlessRowsCount
                        : tableFilteredRows.length
                } products displayed`}
            </Button>
            <TextField
                className="commission-copy-all-field"
                type="number"
                min={0.01}
                step={0.01}
                name="commission-items-apply-all-value"
                isMoneyField={commissionType === 'flat'}
                moneyFieldIsActive={commissionType === 'flat'}
                endIcon={commissionType === 'percentage' && '%'}
                onChange={(event) => setApplyAllValue(event.target.value)}
            />
        </Toolbar.Group>
    );
};

export default ToolbarCommissionInput;
