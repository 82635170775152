import BrandLinksAPI from '@api/BrandLinksAPI';
import NotificationsAPI from '@api/NotificationsAPI';
import SparksAPI from '@api/SparksAPI';
import moment from 'moment';

import { fetchGroup } from '@core/accounts';

import { sortByDate } from '@helpers/ui';

const fetchNotifications = (userId: string, archived = false, read = false) => {
    return NotificationsAPI.getAllNotifications(userId, archived, read);
};

const buildBrandLinkRequestNotification = async (notification: any) => {
    const data = {
        ...notification,
    };

    try {
        const brandLinkId = notification.data.brandLinkId;
        const brandLink = (await BrandLinksAPI.get({ brandLinkId })).data;
        const brand = await fetchGroup(brandLink.vendorAccountId);

        data.data.brandName = brand.name;
        data.data.retailerGroupId = brandLink.retailerAccountId;
        data.data.brandGroupId = brand._id;
        data.data.brandLinkState = brandLink.state;
    } catch (err) {
        return null;
    }

    return data;
};

const buildBrandSparkRequestNotification = async (notification: any) => {
    const data = {
        ...notification,
    };

    try {
        const spark = (await SparksAPI.getSpark({ sparkId: notification.data.sparkId })).data;
        data.data.sparkBrandGroupId = spark?.originatorGroupId;
        data.data.sparkRequestState = spark?.requestState;
        data.data.sparkStartDate = spark?.startDate;
    } catch (err) {
        return null;
    }

    return data;
};

const buildBrandSparkResponseNotification = async (notification: any) => {
    const data = {
        ...notification,
    };

    try {
        const spark = (await SparksAPI.getSpark({ sparkId: notification.data.sparkId })).data;
        data.data.sparkName = spark?.name;
        data.data.sparkStartDate = spark?.startDate;
    } catch (err) {
        return null;
    }

    return data;
};

export const getNotifications = async (userId: string, archived = false, read = false) => {
    const { data } = await fetchNotifications(userId, archived, read);

    return data || [];
};

export const getNotificationItems = async (userId: string, archived = false, read = false) => {
    const promises: Promise<any>[] = [];
    const notificationItems: any[] = [];
    const notifications = await getNotifications(userId, archived, read);

    notifications.forEach((notification) => {
        if (notification.type === 'brand-link-request') {
            promises.push(
                buildBrandLinkRequestNotification(notification).then((obj) => {
                    notificationItems.push(obj);
                }),
            );
        } else if (notification.type === 'spark-request') {
            promises.push(
                buildBrandSparkRequestNotification(notification).then((obj) => {
                    notificationItems.push(obj);
                }),
            );
        } else if (notification.type === 'spark-response') {
            promises.push(
                buildBrandSparkResponseNotification(notification).then((obj) => {
                    notificationItems.push(obj);
                }),
            );
        } else {
            notificationItems.push(notification);
        }
    });

    await Promise.all(promises);

    return notificationItems.filter((obj) => obj != null).sort(sortByDate('createdAt', 'desc'));
};

const updateNotification = async (
    notificationId: string,
    notificationProps: {
        archivedAt?: string;
        readAt?: string;
    },
) => {
    return NotificationsAPI.updateNotification({ notificationId }, notificationProps);
};

export const markNotificationRead = (notificationId: string) => {
    return updateNotification(notificationId, {
        readAt: moment().toISOString(),
    });
};
