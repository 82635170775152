import Paper from '@components/layout/Paper';

import SparkDetailsContent from '../SparkDetailsContent/SparkDetailsContent';

import './SparkDetailsCard.scss';

const SparkDetailsCard = ({ spark }: { spark: any }) => {
    return (
        <Paper className="spark-details-card">
            <Paper.Title>Spark Details</Paper.Title>
            <SparkDetailsContent spark={spark} />
        </Paper>
    );
};

export default SparkDetailsCard;
