import { FC, useEffect, useRef, useState } from 'react';

import { keyBy, sortBy } from 'lodash';

import { PublicSnapTemplate } from '@sparkplug/lib';

import SnapsEmptyState from '@features/spark-snaps/components/SnapsEmptyState/SnapsEmptyState';
import CreateEditSnapDrawer from '@features/spark-snaps/components/SparkSnapsPanel/CreateEditSnapDrawer';
import {
    CreateSnapDrawerContent,
    EditSnapDrawerContent,
} from '@features/spark-snaps/components/SparkSnapsPanel/CreateEditSnapDrawer/CreateEditSnapDrawer';
import { TemplateThumbnail } from '@features/spark-snaps/components/TemplateThumbnail';
import { useSnapViewQueue } from '@features/spark-snaps/hooks';
import { useSnapEditorSdk } from '@features/spark-snaps/hooks/useSnapEditorSdk';
import {
    useCreateSnapTemplateMutation,
    useDeleteSnapTemplateMutation,
    useUpdateSnapMutation,
    useUpdateSnapTemplateMutation,
} from '@features/spark-snaps/mutations';
import { useSnapTemplatesQuery } from '@features/spark-snaps/queries';

import { SnapsPlaceholder } from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import Paper from '@components/layout/Paper';
import { queryToString, useHistory, useLocation, useQueryParams } from '@components/router';
import Toolbar from '@components/toolbar/Toolbar';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useSearch } from '@hooks/UIHooks';

import './SparkSnapsTemplatesView.scss';

const CALLOUT_MESSAGE =
    'Create a template to easily apply consistent styling and formatting across your Snaps on Sparks.';

export const useSnapTemplateHandlers = ({
    accountId,
    snapsEnabled,
    snapTemplates,
}: {
    accountId: string;
    snapsEnabled: boolean;
    snapTemplates: PublicSnapTemplate[];
}) => {
    const { handleViewSnap, SnapViewer } = useSnapViewQueue();

    const { isCreatingSnapTemplate, createSnapTemplateAsync } = useCreateSnapTemplateMutation({
        accountId,
    });
    const { isUpdatingSnapTemplate, updateSnapTemplateAsync } = useUpdateSnapTemplateMutation({
        accountId,
    });
    const { deleteSnapTemplateAsync } = useDeleteSnapTemplateMutation({
        accountId,
    });

    const snapTemplatesById = keyBy(snapTemplates, 'id');

    const currentTemplateId = useRef(0);
    const handleTemplateSaved = async ({ snapId }: { snapId: number }) => {
        const snapTemplateId = currentTemplateId.current;
        if (snapTemplateId) {
            if (snapId && !isUpdatingSnapTemplate) {
                await updateSnapTemplateAsync({
                    snapTemplateId,
                    payload: { snapId },
                });
            }
        } else {
            if (snapId && !isCreatingSnapTemplate) {
                await createSnapTemplateAsync({ snapId });
            }
        }
    };

    const { openSnapEditor } = useSnapEditorSdk({
        accountId,
        snapsEnabled,
        onSnapSaved: handleTemplateSaved,
    });

    const handleCreateTemplate = (templateName: string) => {
        openSnapEditor({
            name: templateName,
        });
    };

    const handleEditTemplate = (sourceSnapId: number, templateId?: number) => {
        if (templateId) currentTemplateId.current = templateId;
        const canEdit = snapTemplates.some((template) => template.sourceSnapId === sourceSnapId);
        /**
         * NOTE: For some reason this doesn't work, the Storifyme request returns a `401`. It seems the snap
         * is not accessible because it gets soft deleted after the template is created from it? Gotta check
         * in with the team over at Storifyme to see how we should open the snap editor for editing a template.
         */
        if (canEdit) {
            openSnapEditor({
                snapId: sourceSnapId,
            });
        }
    };

    const handlePreviewTemplate = (snapTemplateId: number) => {
        const snapTemplate = snapTemplatesById[snapTemplateId];

        if (snapTemplate) {
            handleViewSnap({ accountId, selectedSnapId: snapTemplate.sourceSnapId });
        }
    };
    const handleDeleteTemplate = (snapTemplateId: number) => {
        deleteSnapTemplateAsync({ snapTemplateId });
    };

    return {
        handleCreateTemplate,
        handleEditTemplate,
        handlePreviewTemplate,
        handleDeleteTemplate,
        openSnapEditor,
        SnapViewer,
    };
};

interface SparkSnapsTemplatesViewProps {
    accountId: string;
    snapsEnabled: boolean;
}

const SparkSnapTemplatesView: FC<SparkSnapsTemplatesViewProps> = ({ accountId, snapsEnabled }) => {
    const { snapTemplatesAreReady, snapTemplates = [] } = useSnapTemplatesQuery(
        { accountId },
        snapsEnabled,
    );
    const { create: createQueryParam, ...restQueryParams } = useQueryParams();
    const location = useLocation();
    const history = useHistory();

    const { searchFilter, onChangeSearchFilter, applySearch } = useSearch(['name']);
    const [drawerIsVisible, setDrawerIsVisible] = useState(createQueryParam);
    const [drawerSnapId, setDrawerSnapId] = useState<number>();
    const [drawerTemplateId, setDrawerTemplateId] = useState<number>(0);
    const {
        handleCreateTemplate,
        handleEditTemplate,
        handlePreviewTemplate,
        handleDeleteTemplate,
        SnapViewer,
    } = useSnapTemplateHandlers({ accountId, snapsEnabled, snapTemplates });
    const { updateSnapAsync } = useUpdateSnapMutation({
        accountId,
        snapId: drawerSnapId as number,
        isTemplate: true,
    });

    const handleUpdateSnap = async ({ snapName }: { snapName: string }) => {
        updateSnapAsync({ name: snapName });
        setDrawerIsVisible(false);
        setDrawerSnapId(undefined);
    };

    const filteredSnapTemplates: PublicSnapTemplate[] = sortBy(
        applySearch(snapTemplates ?? []),
        'sourceSnapId',
    );
    const handleAddTemplate = ({ snapName }: { snapName: string }) => {
        setDrawerIsVisible(false);
        setDrawerSnapId(undefined);
        setDrawerTemplateId(0);
        handleCreateTemplate(snapName);
        history.push({ search: queryToString(restQueryParams) });
    };

    const openEditTemplateDrawer = (template: PublicSnapTemplate) => {
        setDrawerTemplateId(template.id);
        setDrawerSnapId(template.sourceSnapId);
        setDrawerIsVisible(true);
    };

    const onDeleteTemplate = (snapTemplateId: number) => {
        setDrawerIsVisible(false);
        setDrawerSnapId(undefined);
        setDrawerTemplateId(0);
        handleDeleteTemplate(snapTemplateId);
    };

    const editTemplate = (id: number) => {
        setDrawerTemplateId(0);
        setDrawerIsVisible(false);
        handleEditTemplate(id, drawerTemplateId);
    };

    useEffect(() => {
        if (createQueryParam) {
            setDrawerIsVisible(true);
        }
    }, [createQueryParam]);

    return (
        <>
            {(snapTemplatesAreReady && snapTemplates.length === 0) || !snapsEnabled ? (
                <SnapsEmptyState
                    buttonLabel="Create Template"
                    onClick={() => {
                        setDrawerSnapId(undefined);
                        setDrawerTemplateId(0);
                        setDrawerIsVisible(true);
                    }}
                    snapsEnabled={snapsEnabled}
                    emptyStateProps={{
                        graphic: <img src="/static/images/snaps-templates.jpg" alt="placeholder" />,
                        label: 'Effortlessly create stunning Snaps with templates',
                        smallText: `Select from a wide range of elements & templates to inspire your creativity and leverage your library of brand assets.`,
                    }}
                />
            ) : (
                <>
                    <Toolbar justifyContentStart>
                        <Toolbar.Search
                            name="search"
                            defaultValue={searchFilter}
                            onChange={onChangeSearchFilter}
                        />
                    </Toolbar>
                    <Paper className="spark-snap-templates_container">
                        <CalloutMessage message="Create and manage Snap templates here to make it simple for building new Featured Snaps or Snaps on Sparks." />

                        <div className="spark-snap-templates">
                            {snapTemplates.length > 0 ? (
                                <>
                                    {filteredSnapTemplates.map((snapTemplate) => (
                                        <TemplateThumbnail
                                            key={snapTemplate.id}
                                            sparkplugAccountId={accountId}
                                            snapTemplate={snapTemplate}
                                            dropdownMenu={{
                                                onPreview: () =>
                                                    handlePreviewTemplate(snapTemplate.id),
                                                onDelete: () =>
                                                    handleDeleteTemplate(snapTemplate.id),
                                                onEdit: () => openEditTemplateDrawer(snapTemplate),
                                            }}
                                        />
                                    ))}
                                </>
                            ) : (
                                <div className="snaps-placeholder">
                                    <SnapsPlaceholder />
                                    <h1>No Snap templates created</h1>
                                    Create a new template or save a previously created Snap as a
                                    template
                                </div>
                            )}
                        </div>
                        <SnapViewer />
                    </Paper>
                </>
            )}
            {drawerSnapId ? (
                <CreateEditSnapDrawer
                    drawerTitle="Edit Snap Template"
                    isVisible={drawerIsVisible}
                    calloutMessage={CALLOUT_MESSAGE}
                    onClose={() => {
                        setDrawerSnapId(undefined);
                        setDrawerTemplateId(0);
                        setDrawerIsVisible(false);
                    }}
                    onRemove={() => onDeleteTemplate(drawerSnapId)}
                    onSave={handleUpdateSnap}
                    snapType="Snap Template"
                    snapId={drawerSnapId}
                >
                    <EditSnapDrawerContent
                        accountId={accountId}
                        onOpenInEditor={editTemplate}
                        snapId={drawerSnapId}
                    />
                </CreateEditSnapDrawer>
            ) : (
                <CreateEditSnapDrawer
                    drawerTitle="Create Snap Template"
                    isVisible={drawerIsVisible}
                    calloutMessage={CALLOUT_MESSAGE}
                    onClose={() => {
                        setDrawerIsVisible(false);
                        const newSearch = queryToString(restQueryParams);
                        history.push({ search: newSearch });
                    }}
                    onSave={({ snapName }) => handleAddTemplate({ snapName })}
                    snapType="Snap Template"
                >
                    <CreateSnapDrawerContent snapType="Snap Template" />
                </CreateEditSnapDrawer>
            )}
        </>
    );
};

export default () => {
    const { account, hasSnapsEntitlement } = useSparkplugAccount();

    if (!account) {
        return <></>;
    }

    return <SparkSnapTemplatesView accountId={account._id} snapsEnabled={hasSnapsEntitlement} />;
};
