import { FC } from 'react';

import { HydratedBrandProductTag } from '@sparkplug/lib';

import { ChevronRight, Dot } from '@components/icons';

import './GroupTagPairMenuItemContent.scss';

interface GroupTagPairMenuItemContentProps {
    productTag: Pick<HydratedBrandProductTag, 'groupName' | 'name' | 'color'>;
}

const GroupTagPairMenuItemContent: FC<GroupTagPairMenuItemContentProps> = ({ productTag }) => {
    const { groupName, name: tagName, color } = productTag;

    return (
        <>
            {productTag.groupName && (
                <>
                    <span className="tag-dropdown-list-item-category">{groupName}</span>
                    <ChevronRight className="tag-dropdown-list-item-chevron" />
                </>
            )}
            {color && <Dot style={{ color }} className="tag-dropdown-list-item-color" />}
            <span className="tag-dropdown-list-item-name">{tagName}</span>
        </>
    );
};

export default GroupTagPairMenuItemContent;
