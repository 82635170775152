import toast, { Toaster } from 'react-hot-toast';

import './Toaster.scss';

export { Toaster };

export type TToastPromiseMessageParameters = Parameters<typeof toast.promise>[1];
type TToastOptions = Parameters<typeof toast.promise>[2];

function toastPromiseOverride<T>(
    promise: Promise<T>,
    messages: TToastPromiseMessageParameters,
    opts: TToastOptions = {},
) {
    const loading =
        messages?.loading == null
            ? {
                  className: 'toaster message-is-null',
              }
            : {};

    toast.promise(promise, messages, {
        ...opts,
        className: 'toast',
        loading,
        success: {
            duration: 3000,
            ...(opts.success || {}),
        },
    });
}

export default {
    ...toast,
    promise: toastPromiseOverride,
};
