import { CalculatorFrequency, SparkMetric, TComparisonPeriodOption } from '@sparkplug/lib';

import { BarChart, ShowChart } from '@components/icons';

export const ChartPrecisionOptions = [
    {
        label: 'Daily',
        value: 'day',
    },
    {
        label: 'Weekly',
        value: 'week',
    },
    {
        label: 'Monthly',
        value: 'month',
    },
    {
        label: 'Yearly',
        value: 'year',
    },
];

export const CHART_FREQUENCY_OPTIONS: { label: string; value: CalculatorFrequency }[] = [
    {
        label: 'Daily',
        value: 'daily',
    },
    {
        label: 'Weekly',
        value: 'weekly',
    },
    {
        label: 'Monthly',
        value: 'monthly',
    },
    {
        label: 'Yearly',
        value: 'yearly',
    },
];

export const ChartMetricOptions = [
    {
        label: 'Total Sales',
        value: 'total_sales',
    },
    {
        label: 'Total Units',
        value: 'total_units',
    },
    {
        label: 'Order Average',
        value: 'order_average',
    },
    {
        label: 'Transactions',
        value: 'transaction_count',
    },
    {
        label: 'Units Per Transaction',
        value: 'units_per_transaction',
    },
    {
        label: 'Percent of Total Sales',
        value: 'percent_of_total_sales',
    },
    {
        label: 'Guest Check Average',
        value: 'guest_check_average',
    },
];

export const ChartMetricLabelMap: Record<Exclude<SparkMetric, 'percent_increase'>, string> = {
    total_sales: 'Total Sales',
    total_units: 'Total Units',
    order_average: 'Order Average',
    guest_check_average: 'Guest Check Average',
    transaction_count: 'Transactions',
    units_per_transaction: 'Units Per Transaction',
    percent_of_total_sales: 'Percent of Total Sales',
};
export const ComparisonPeriodOptions: {
    value: TComparisonPeriodOption;
    label: string;
    subLabel?: string;
    selectedLabel?: string;
}[] = [
    {
        value: 'previousPeriodMatchDay',
        label: 'Previous Period',
        subLabel: '(match day of week)',
        selectedLabel: 'Previous Period (match day of week)',
    },
    {
        value: 'previousYearMatchDay',
        label: 'Previous Year',
        subLabel: '(match day of week)',
        selectedLabel: 'Previous Year (match day of week)',
    },
    {
        value: 'previousPeriod',
        label: 'Previous Period',
    },
    {
        value: 'previousYear',
        label: 'Previous Year',
    },
];

export const ChartViewOptions = [
    {
        StartIcon: BarChart,
        label: 'Column',
        value: 'bar',
    },
    {
        StartIcon: ShowChart,
        label: 'Line',
        value: 'line',
    },
];

export const ChartLegendPageSize = 15;
