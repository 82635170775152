import { FC, useCallback } from 'react';

import { Spark } from '@sparkplug/lib';

import { useSnapViewQueue } from '@features/spark-snaps/hooks/useSnapViewQueue';

import SparkSnapItem from '../SparkSnapItem';

import './SparkSnapsViewer.scss';

interface SparkSnapsViewerProps {
    spark: Pick<Spark, '_id' | 'snaps'>;
}

const SparkSnapsViewer: FC<SparkSnapsViewerProps> = ({ spark }) => {
    const { accountId = '', snapIds: snapQueue = [] } = spark.snaps ?? {};

    if (!accountId || !snapQueue.length) {
        return <></>;
    }

    const { handleViewSnap, SnapViewer } = useSnapViewQueue();

    const sparkId = spark._id;
    const handleViewSnapInQueue = useCallback(
        (selectedSnapId: number) =>
            handleViewSnap({ selectedSnapId, accountId, snapQueue, sparkId }),
        [accountId, snapQueue, sparkId],
    );

    return (
        <div className="spark-snaps-viewer">
            {snapQueue.map((snapId, index) => (
                <SparkSnapItem
                    key={snapId}
                    accountId={accountId}
                    snapId={snapId}
                    onView={handleViewSnapInQueue}
                    trackingSource="Spark Snaps Viewer"
                    sparkSnapListIndex={index}
                />
            ))}
            <SnapViewer />
        </div>
    );
};

export default SparkSnapsViewer;
