import { z } from 'zod';
import { PagedApiResponseBody } from '../http';

export const REQUEST_FOR_SPARK_STATUSES = ['open', 'accepted', 'ignored'] as const;
const RequestForSparkStatusEnum = z.enum(REQUEST_FOR_SPARK_STATUSES);
export type RequestForSparkStatus = z.infer<typeof RequestForSparkStatusEnum>;

export interface CoreRequestForSpark {
  /** Max of 180 characters */
  message?: string;
  status: RequestForSparkStatus;
  createdAt: string;
  updatedAt?: string;
}
export interface UIRequestForSpark extends CoreRequestForSpark {
  _id: string;
  /** As of now, this will always be a retailer's account Id */
  sourceAccountId: string;
  sourceAccountName?: string;
  sourceAccountLogo?: string;
  /** As of now, this will always be a vendor's account Id */
  targetAccountId: string;
  targetAccountName?: string;
  sparkId?: string;
}

export const authenticatedRequestForSparkContextSchema = z.object({
  userId: z.string(),
});
export const createRequestForSparkBodySchema = z.object({
  sourceAccountId: z.string(),
  targetAccountId: z.string(),
  message: z.string().max(180).optional(),
});
export type CreateRequestForSparkRequestBody = z.infer<typeof createRequestForSparkBodySchema>;
export interface UpsertRequestForSparkResponseBody {
  data: UIRequestForSpark;
}

// Ensure that the sortable RequestForSpark fields are a subset of the keys in UIRequestForSpark.
const SORTABLE_REQUEST_FOR_SPARK_FIELDS: Array<Partial<keyof UIRequestForSpark>> = [
  'createdAt',
  'updatedAt',
  'status',
  'sourceAccountName',
  'targetAccountName',
];
const SortableRequestForSparkFieldsEnum = z.enum(SORTABLE_REQUEST_FOR_SPARK_FIELDS as any);
export const listRequestsForSparksQueryParamsSchema = z.object({
  sourceAccountId: z.string().optional(),
  targetAccountId: z.string().optional(),
  status: RequestForSparkStatusEnum.optional(),
  limit: z.preprocess((val) => (val ? Number(val) : undefined), z.number().positive()),
  offset: z.preprocess((val) => (val ? Number(val) : undefined), z.number().nonnegative()),
  sort: SortableRequestForSparkFieldsEnum.optional(),
  order: z.enum(['asc', 'desc']).optional(),
  search: z.string().optional(),
});

export interface ListSparkRequestsQueryParams {
  sourceAccountId?: string;
  targetAccountId?: string;
  status?: string;
  limit?: number;
  offset?: number;
  sort?: string;
  order?: string;
  search?: string;
  orderBy?: string;
}

export type ListRequestsForSparksQueryParams = z.infer<
  typeof listRequestsForSparksQueryParamsSchema
>;

export const updateRequestForSparkPathParamSchema = z.object({
  requestForSparkId: z.string().min(1),
});
export type UpdateRequestForSparkPathParam = z.infer<typeof updateRequestForSparkPathParamSchema>;

export const acceptRequestForSparkBodySchema = z.object({
  sparkId: z.string().min(1),
});
export type AcceptRequestForSparkRequestBody = z.infer<typeof acceptRequestForSparkBodySchema>;

export type ListRequestsForSparksResponseBody = PagedApiResponseBody<UIRequestForSpark>;
