import { FC } from 'react';

import { ESparkStatusColors } from '@constants/SparkConstants';

import {
    Completed as CompletedIcon,
    RadioButtonUnchecked as PendingIcon,
    Running as RunningIcon,
    Scheduled as ScheduledIcon,
} from '@components/icons';

import './SparkStatusIcon.scss';

const sparkStatusIcons = {
    active: <RunningIcon className={`status-icon icon-color-${ESparkStatusColors.Active}`} />,
    upcoming: <ScheduledIcon className={`status-icon icon-color-${ESparkStatusColors.Upcoming}`} />,
    completed: (
        <CompletedIcon className={`status-icon icon-color-${ESparkStatusColors.Complete}`} />
    ),
    pending: <PendingIcon className="status-icon icon-color-pending" />,
};

interface SparkStatusIconProps {
    status: 'active' | 'upcoming' | 'completed' | 'pending';
}

const SparkStatusIcon: FC<SparkStatusIconProps> = ({ status }) => {
    return sparkStatusIcons[status] ?? <></>;
};

export default SparkStatusIcon;
