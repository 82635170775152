import { FC, useEffect, useState } from 'react';

import { useApp } from '@hooks/AppHooks';
import { useQueryClient } from '@hooks/QueryHooks';

import { useSnapQuery } from '../../queries';

import './useSnapViewQueue.scss';

/**
 * Temporary fix. Storify me is running into issues with their 'stories' service in dev mode.
 */
export const cleanSnapUrlForDev = (snapUrl: string) => {
    if (import.meta.env.REACT_APP_API !== 'production' && snapUrl.startsWith('https://stories.')) {
        return snapUrl.replace('https://stories.', 'https://');
    }
    return snapUrl;
};

export const appendQueryParamsToSnapUrl = (
    snapUrl: string,
    { userId, sparkId }: { userId?: string; sparkId?: string },
) => {
    let finalSnapUrl = snapUrl;

    if (userId) {
        finalSnapUrl = `${finalSnapUrl}&cid=${userId}`;
    }
    if (sparkId) {
        finalSnapUrl = `${finalSnapUrl}&pid=${sparkId}`;
    }

    return finalSnapUrl;
};

export const useSnapViewQueue = () => {
    const [snapViewQueue, setSnapViewQueue] = useState<number[]>([]);
    const [accountId, setAccountId] = useState<string>('');
    const [storifymeAccountId, setStorifymeAccountId] = useState<string>('');
    const [sparkId, setSparkId] = useState<string>();
    const [snapUrl, setSnapUrl] = useState<string>();
    const queryClient = useQueryClient();

    const { snap: currentSnap } = useSnapQuery(
        storifymeAccountId
            ? { storifymeAccountId, snapId: snapViewQueue?.[0] }
            : {
                  accountId,
                  // Always use the first snap in the queue
                  snapId: snapViewQueue?.[0],
              },
        !!snapViewQueue.length && (!!accountId || !!storifymeAccountId),
    );

    const { userId, isAdminApp } = useApp();
    // Only track engagement for non-admin users
    const employeeUserId = !isAdminApp ? userId : undefined;

    const handleIframeMessage = (event: MessageEvent<any>) => {
        if (event.origin.includes('storifyme')) {
            const data = JSON.parse(event.data);
            // If the users closes the snap, clear the queue
            if (data.action === 'STORY_CLOSED') {
                setSnapViewQueue([]);
                setAccountId('');
                setSparkId(undefined);
            }

            // If we reach the end of the snap, remove it from the queue so that we load the next snap
            if (data.action === 'OPEN_NEXT_STORY') {
                setSnapViewQueue((prevValue) => prevValue.slice(1));
            }
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleIframeMessage);
        return () => window.removeEventListener('message', handleIframeMessage);
    }, []);

    useEffect(() => {
        if (snapViewQueue.length) {
            /**
             * Only update the snap URL if we have the snap. This softens the
             * flash of an empty iframe when the snap is not ready, but could
             * be improved by showing a loading state or another transition
             */
            if (currentSnap?.storifymeSnapId === snapViewQueue[0]) {
                /**
                 * NOTE: We have to add the `widget=showClose` query param to the snap URL to show the close button
                 * `widget` is actually supposed to be an widget id set up in the Storifyme dashboard, but we can't do this
                 * for all accounts, but we can mock the close button by adding the query param
                 */
                const updatedSnapUrl = `${currentSnap?.iframeUrl}?widget=showClose`;
                setSnapUrl(updatedSnapUrl);
            }
        } else {
            // If the queue is empty, remove the snap URL
            setSnapUrl('');
        }
        if (snapViewQueue.length === 0) {
            // This will refetch the user snap events so that we can update the UI
            // this happens each time the snapviewqueue is empty which is essentially whenever the viewer was closed.
            queryClient.invalidateQueries({
                predicate: (query) =>
                    query.queryKey[0] === 'user-snap-events' &&
                    (query.queryKey[1] === 'story_complete' ||
                        query.queryKey[1] === 'story_started'),
                refetchActive: true,
                refetchInactive: true,
            });
        }
    }, [snapViewQueue?.[0], currentSnap?.iframeUrl]);

    const handleViewSnap = ({
        selectedSnapId,
        accountId: snapAccountId,
        sparkId: snapSparkId,
        snapQueue = [],
        accountIdIsStorifyme = false,
    }: {
        selectedSnapId: number;
        accountId: string;
        sparkId?: string;
        snapQueue?: number[];
        accountIdIsStorifyme?: boolean;
    }) => {
        if (!accountIdIsStorifyme) {
            setAccountId(snapAccountId);
        } else {
            setStorifymeAccountId(snapAccountId);
        }
        if (snapQueue?.length) {
            const snapIndex = snapQueue.indexOf(selectedSnapId);
            const updatedSnapViewQueue = snapQueue.slice(snapIndex);
            setSparkId(snapSparkId);
            setSnapViewQueue(updatedSnapViewQueue);
        } else {
            // Sometimes we don't have a snapId array. This allows us to open a single snap without a queue
            setSnapViewQueue([selectedSnapId]);
        }
    };

    const SnapViewer: FC<{}> = () => {
        useEffect(() => {
            if (!snapUrl) {
                return undefined;
            }
            const handleEsc = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    setSnapViewQueue([]);
                }
            };
            window.addEventListener('keydown', handleEsc);

            return () => {
                window.removeEventListener('keydown', handleEsc);
            };
        }, []);

        if (!snapUrl) {
            return <></>;
        }

        return (
            <div className="snap-viewer">
                <iframe
                    name="snapFrame"
                    id="snapFrame"
                    className="snap-viewer_iframe"
                    src={appendQueryParamsToSnapUrl(cleanSnapUrlForDev(snapUrl), {
                        userId: employeeUserId,
                        sparkId,
                    })}
                    title={currentSnap?.name}
                />
            </div>
        );
    };

    return {
        handleViewSnap,
        SnapViewer,
    };
};
