import { useState } from 'react';

import Button from '@components/buttons/Button';
import PinInputField from '@components/inputs/PinInputField';
import CodeVerifiedOverlay from '@components/overlays/CodeVerifiedOverlay';
import Modal from '@components/overlays/Modal';

import { useCodeAuth } from '@hooks/AppHooks';
import { useCountdown } from '@hooks/UIHooks';

import { sendSmsAuth } from '@helpers/auth';
import { formatPhoneNumber } from '@helpers/ui';

import './SMSConfirmationModal.scss';

const codeLength = 6;

function SMSConfirmationModal({
    isVisible,
    phoneNumber,
    onClose,
}: {
    isVisible: boolean;
    phoneNumber: string;
    onClose: (val?: boolean) => void;
}) {
    const [code, setCode] = useState<string>('');

    const { remainingTime, startCountdown, countdownIsRunning } = useCountdown();

    const { codeIsValid, isSubmittingCode, verifyCode, errorMessage } = useCodeAuth(
        {
            code,
            phoneNumber,
        },
        codeLength,
    );

    const title = errorMessage != null ? 'Login Failed.' : 'We just texted you';

    const phoneNumberFormatted = formatPhoneNumber(phoneNumber);
    const message =
        errorMessage ||
        `Please enter the verification code we just sent to ${phoneNumberFormatted}.`;
    const messageClassName = errorMessage != null ? 'error' : '';

    const redirectTo = '/user/sparks';

    const resendCodeMessage = countdownIsRunning
        ? `Retry again in ${remainingTime} seconds`
        : "Didn't get a code?";

    return (
        <>
            <Modal
                isVisible={isVisible}
                onClose={() => onClose(!errorMessage)}
                className="sms-confirmation-modal"
            >
                <Modal.Title onClose={() => onClose()}>&nbsp;</Modal.Title>
                <Modal.Content>
                    <h3>{title}</h3>
                    <p className={messageClassName}>{message}</p>
                    <PinInputField
                        length={codeLength}
                        defaultValue={code}
                        onChange={(value) => {
                            setCode(value);
                        }}
                    />
                    <Button
                        color="blue"
                        variant="smooth"
                        disabled={isSubmittingCode}
                        onClick={() => verifyCode()}
                    >
                        {isSubmittingCode ? 'Submitting...' : 'Submit'}
                    </Button>

                    <br />

                    <Button
                        color="neutral"
                        variant="flat"
                        disabled={countdownIsRunning}
                        onClick={() => {
                            startCountdown(30);
                            sendSmsAuth(phoneNumber);
                        }}
                    >
                        {resendCodeMessage}
                    </Button>
                </Modal.Content>
            </Modal>
            <CodeVerifiedOverlay isVisible={codeIsValid} redirectTo={redirectTo} />
        </>
    );
}

export default SMSConfirmationModal;
