import { Dispatch, FC, SetStateAction } from 'react';

import moment from 'moment';

import { IEmployeeWithdrawal, WithdrawalFees, formatCurrency } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import { ChatBubble, FollowLinkIcon } from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import DetailsList from '@components/layout/DetailsList';
import Drawer from '@components/overlays/Drawer';

import Intercom from '@helpers/Intercom';

import { ALL_AVAILABLE_METHODS } from '../../constants';
import { CashOutMethod } from '../../types';
import { CashOutMethodIcon } from '../CashOutMethodIcon';
import TransactionDetails from '../TransactionDetails';

import './CashOutHistoryItemDrawer.scss';

interface CashOutHistoryItemDrawerProps {
    isVisible: boolean;
    cashOutHistoryItem?: IEmployeeWithdrawal;
    onClose: Dispatch<SetStateAction<{} | undefined>>;
}

const CashOutHistoryItemDrawer: FC<CashOutHistoryItemDrawerProps> = ({
    isVisible,
    onClose,
    cashOutHistoryItem,
}) => {
    const details = [
        ...(cashOutHistoryItem?.withdrawalFee && cashOutHistoryItem?.withdrawalType
            ? [
                  {
                      title: `Fees (${WithdrawalFees[cashOutHistoryItem.withdrawalType]}%)`,
                      content: (
                          <span>
                              A{' '}
                              <strong>
                                  {formatCurrency(cashOutHistoryItem.withdrawalFee / 100, true)}
                              </strong>{' '}
                              fee was applied to this cash out
                          </span>
                      ),
                  },
              ]
            : []),
        { title: 'Status', content: cashOutHistoryItem?.status },
        {
            title: 'Cash Out Date',
            content: moment(cashOutHistoryItem?.createdAt).format('MMMM Do, YYYY [at] h:mma'),
        },
        {
            title: 'Cash Out ID',
            content: <strong>{cashOutHistoryItem?.tremendousId}</strong>,
        },
    ];
    const showWarning =
        cashOutHistoryItem?.withdrawalType &&
        (CashOutMethod.ACH === cashOutHistoryItem.withdrawalType ||
            CashOutMethod.VENMO === cashOutHistoryItem?.withdrawalType);

    const warningMessage = cashOutHistoryItem?.withdrawalType
        ? ALL_AVAILABLE_METHODS[cashOutHistoryItem?.withdrawalType]?.warningMessage
        : undefined;

    const viewPaymentMessage =
        cashOutHistoryItem?.withdrawalType &&
        `View ${ALL_AVAILABLE_METHODS[cashOutHistoryItem?.withdrawalType]?.label} Payment`;

    return (
        <Drawer
            variant="right"
            title="Cash Out Details"
            onCloseHandler={onClose}
            isVisible={isVisible}
            className="bring-drawer-to-front"
        >
            {cashOutHistoryItem && (
                <TransactionDetails>
                    <TransactionDetails.Header className="cash-out-header">
                        {showWarning && warningMessage && (
                            <CalloutMessage
                                className="payment-method-warning"
                                message={warningMessage}
                            />
                        )}
                        <CashOutMethodIcon withdrawalMethod={cashOutHistoryItem?.withdrawalType} />
                        <div className="cash-out-method"> {cashOutHistoryItem?.withdrawalType}</div>
                        <div className="cash-out-date">
                            {moment(cashOutHistoryItem?.createdAt).format('MMMM Do')}
                        </div>
                        <div className="cash-out-amount">
                            - {`${formatCurrency((cashOutHistoryItem?.amount ?? 0) / 100, true)}`}
                        </div>
                        <Button
                            color="blue"
                            variant="smooth"
                            endIcon={<FollowLinkIcon />}
                            onClick={() => {
                                window.open(cashOutHistoryItem?.tremendousLink, '_blank');
                            }}
                        >
                            {viewPaymentMessage}
                        </Button>
                    </TransactionDetails.Header>
                    <TransactionDetails.Content>
                        <DetailsList details={details} className="details-list-container" />
                        <Button
                            color="blue"
                            variant="flat"
                            startIcon={<ChatBubble />}
                            onClick={() => {
                                onClose(undefined);
                                Intercom.open();
                            }}
                        >
                            Need Help?
                        </Button>
                    </TransactionDetails.Content>
                </TransactionDetails>
            )}
        </Drawer>
    );
};

export default CashOutHistoryItemDrawer;
