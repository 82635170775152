import { memo, useEffect, useMemo, useState } from 'react';

import { DATE_DISPLAY_FORMAT } from '@constants/AppConstants';
import moment from 'moment';

import { Spark, TComparisonPeriodOption } from '@sparkplug/lib';

import { useGetSparksQuery } from '@core/sparks/queries/GetSparksQuery';

import Dropdown from '@components/dropdown/Dropdown';
import Checkbox from '@components/inputs/Checkbox';
import Paper from '@components/layout/Paper';
import Tooltip from '@components/layout/Tooltip';
import { SparksListTable } from '@components/sparks';
import CreateSparkButton from '@components/sparks/CreateSparkButton';
import { getDateRangeLabel } from '@components/toolbar/ToolbarDateRangeSelector';

import { useApp } from '@hooks/AppHooks';
import { calculateComparisonPeriod } from '@hooks/ChartDataHooks';
import {
    useSparkplugAccount,
    useSparkplugBrandRetailer,
} from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { IChartDataSettings } from '@app/types/ChartDataTypes';
import { CheckedEventOnChange } from '@app/types/UITypes';

import SparkTimeline from './SparkTimeline';
import { getComparisonPeriodLabel, getSparkTimelineData } from './SparkTimelineWidgetUtils';

import './SparkTimelineWidget.scss';

const ComparisonDropdownLabel = memo(({ label, dateStart, dateEnd }: IDateRangeLabelData) => {
    const dateFormat = 'MMM Do YYYY';
    const formattedStartDate = moment(dateStart).format(dateFormat);
    const formattedEndDate = moment(dateEnd).format(dateFormat);

    return (
        <div className="spark-timeline-widget__dropdown-label">
            <h3>{label}</h3>
            <span>{`${formattedStartDate} - ${formattedEndDate}`}</span>
        </div>
    );
});

type TDisplayedComparisonPeriod = 'current' | 'previous';

interface IDateRangeLabelData {
    label: string;
    dateStart: string;
    dateEnd: string;
}
interface ISparkTimelineWidgetControlsProps {
    showToggle: boolean;
    disabled: boolean;
    currentPeriodLabelData?: IDateRangeLabelData;
    previousPeriodLabelData?: IDateRangeLabelData;
    setDisplayedComparisonPeriod: Function;
    displayedComparisonPeriod: TDisplayedComparisonPeriod;
    showTimeline: boolean;
    toggleShowTimeline: CheckedEventOnChange;
}
const SparkTimelineWidgetControls = ({
    showToggle = true,
    disabled,
    currentPeriodLabelData,
    previousPeriodLabelData,
    setDisplayedComparisonPeriod,
    displayedComparisonPeriod,
    showTimeline,
    toggleShowTimeline,
}: ISparkTimelineWidgetControlsProps) => {
    const showComparisonOptions = !!currentPeriodLabelData && !!previousPeriodLabelData;

    const periodLabelMap = {
        current: currentPeriodLabelData?.label,
        previous: previousPeriodLabelData?.label,
    };

    return (
        <div className="spark-timeline-widget__controls">
            {showToggle && (
                <Checkbox
                    disabled={disabled}
                    name="display-spark-timeline-checkbox"
                    label="Display Spark Timelines"
                    value={showTimeline}
                    onChange={toggleShowTimeline}
                />
            )}

            {showComparisonOptions && showTimeline && !disabled && (
                <Dropdown className="spark-timeline-widget__dropdown">
                    <Dropdown.Button variant="flat">
                        {periodLabelMap[displayedComparisonPeriod]}
                    </Dropdown.Button>

                    <Dropdown.Menu>
                        <Dropdown.MenuItem
                            selected={displayedComparisonPeriod === 'current'}
                            onClick={() => setDisplayedComparisonPeriod?.('current')}
                        >
                            <ComparisonDropdownLabel {...currentPeriodLabelData} />
                        </Dropdown.MenuItem>

                        <Dropdown.MenuItem
                            selected={displayedComparisonPeriod === 'previous'}
                            onClick={() => setDisplayedComparisonPeriod?.('previous')}
                        >
                            <ComparisonDropdownLabel {...previousPeriodLabelData} />
                        </Dropdown.MenuItem>
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </div>
    );
};

interface SparkTimelineWidgetProps {
    chartSettings: IChartDataSettings;
    comparisonPeriod?: TComparisonPeriodOption;
    useChartTicks?: boolean;
    showToggle?: boolean;
}
const SparkTimelineWidget = ({
    chartSettings,
    comparisonPeriod,
    useChartTicks = true,
    showToggle = true,
}: SparkTimelineWidgetProps) => {
    const [showTimeline, setShowTimeline] = useState<boolean>(!useChartTicks);
    const toggleShowTimeline = () => setShowTimeline((prev) => !prev);

    const [displayedComparisonPeriod, setDisplayedComparisonPeriod] =
        useState<TDisplayedComparisonPeriod>('current');

    // Get date range data
    const { dateStart, dateEnd } = chartSettings;
    const { previousDateStart, previousDateEnd } = useMemo(() => {
        return comparisonPeriod
            ? calculateComparisonPeriod(dateStart, dateEnd, comparisonPeriod)
            : { previousDateStart: undefined, previousDateEnd: undefined };
    }, [dateStart, dateEnd, comparisonPeriod]);

    // Get SparkTimeline data
    const { history, isAdminApp } = useApp();
    const { account, accountIsReady } = useSparkplugAccount();

    const { sparks = [] } = useGetSparksQuery({
        account,
        sparkFilters: {
            requestState: JSON.stringify(['accepted', 'none']),
        },
        isAdminApp,
        accountIsReady,
    });
    const { brandRetailerId } = useSparkplugBrandRetailer();

    const showPreviousComparisonPeriod =
        !!comparisonPeriod && displayedComparisonPeriod === 'previous';

    const [timelineStartDate, timelineEndDate] = (() => {
        return [
            showPreviousComparisonPeriod ? previousDateStart : dateStart,
            showPreviousComparisonPeriod ? previousDateEnd : dateEnd,
        ];
    })();

    const sparkTimelineData = useMemo(
        () =>
            getSparkTimelineData({
                sparks,
                timelineStartDate,
                timelineEndDate,
                brandRetailerId,
            }),
        [sparks, timelineStartDate, timelineEndDate, brandRetailerId],
    );

    useEffect(() => {
        const comparisonModeWasToggledOff = !comparisonPeriod;
        if (comparisonModeWasToggledOff) {
            setDisplayedComparisonPeriod('current');
        }
    }, [comparisonPeriod]);

    // TODO: this may be useful when this component is available
    // for all chart interval/precision types
    // useEffect(() => {
    //     // Note: Without this effect, we experience some
    //     // jitteriness when periods are being recalculated
    //     setShowTimeline(false);
    // }, [currentPeriodLabel]);

    const chartIsDaily = !useChartTicks || chartSettings.precision === 'day';
    useEffect(() => {
        if (!chartIsDaily) {
            setShowTimeline(false);
        }
    }, [chartIsDaily]);

    const currentPeriodLabel = useMemo(() => {
        return getDateRangeLabel(chartSettings.dateStart, chartSettings.dateEnd);
    }, [chartSettings.dateStart, chartSettings.dateEnd]);

    const hasSparksToDisplay = !!sparkTimelineData?.length;
    const sparkTimeline = hasSparksToDisplay ? (
        <>
            {chartSettings.type !== 'table' ? (
                <SparkTimeline
                    chartSettings={chartSettings}
                    sparkTimelineRows={sparkTimelineData}
                    useChartTicks={useChartTicks}
                />
            ) : (
                <SparksListTable
                    sparks={sparkTimelineData.reduce(
                        (res, rowSparks) => [...res, ...rowSparks.map(({ spark }) => spark)],
                        [] as Spark[],
                    )}
                    onSparkSelected={(spark: Spark) =>
                        history.push(`/${account?._id}/sparks/${spark._id}`)
                    }
                />
            )}
        </>
    ) : (
        <div className="spark-timeline-widget__no-sparks">
            <span>
                {`No Sparks for this period ${moment(timelineStartDate).format(
                    DATE_DISPLAY_FORMAT,
                )} - ${moment(timelineEndDate).format(DATE_DISPLAY_FORMAT)}`}
            </span>
            {!showPreviousComparisonPeriod && (
                <CreateSparkButton
                    variant="flat"
                    onClick={() =>
                        history.push(`/${account?._id}/sparks/create?groupId=${brandRetailerId}`)
                    }
                    showSplitButton={false}
                />
            )}
        </div>
    );

    const sparkTimelineControls = (
        <SparkTimelineWidgetControls
            showToggle={showToggle}
            disabled={!chartIsDaily}
            showTimeline={showTimeline}
            toggleShowTimeline={toggleShowTimeline}
            displayedComparisonPeriod={displayedComparisonPeriod}
            setDisplayedComparisonPeriod={setDisplayedComparisonPeriod}
            currentPeriodLabelData={
                comparisonPeriod
                    ? {
                          dateStart,
                          dateEnd,
                          label: currentPeriodLabel,
                      }
                    : undefined
            }
            previousPeriodLabelData={
                comparisonPeriod
                    ? {
                          dateStart: previousDateStart as string,
                          dateEnd: previousDateEnd as string,
                          label: getComparisonPeriodLabel(comparisonPeriod),
                      }
                    : undefined
            }
        />
    );

    return (
        <div className="spark-timeline-widget">
            {showTimeline && sparkTimeline}

            {chartIsDaily ? (
                sparkTimelineControls
            ) : (
                <Tooltip
                    title={'Set chart view frequency to "Daily" to display the Spark Timeline'}
                >
                    <div>{sparkTimelineControls}</div>
                </Tooltip>
            )}
        </div>
    );
};

interface SparkTimelineWidgetExternalProps extends SparkTimelineWidgetProps {
    displayType?: 'paper' | 'default';
}

export default ({ displayType = 'default', ...initialProps }: SparkTimelineWidgetExternalProps) => {
    const props: SparkTimelineWidgetProps = initialProps;

    if (displayType === 'paper') {
        props.useChartTicks = false;
        props.showToggle = false;
    }

    if (displayType === 'default') {
        return <SparkTimelineWidget {...props} />;
    }

    return (
        <Paper data-chromatic="ignore">
            <Paper.Title>Spark Timelines</Paper.Title>
            <SparkTimelineWidget {...props} />
        </Paper>
    );
};
