import { ReactElement, ReactNode, createContext, useContext, useMemo } from 'react';

import { appendClasses } from '@helpers/ui';

import './AppView.scss';

interface IAppViewProps {
    sidebar?: any;
    children: ReactNode;
}

const AppViewContext = createContext<any>({});

const AppView = ({ sidebar, children }: IAppViewProps) => {
    const value = useMemo(() => {
        return {
            sidebar,
        };
    }, [sidebar]);
    return <AppViewContext.Provider value={value}>{children}</AppViewContext.Provider>;
};

interface IAppViewContentProps {
    className?: string;
    title?: string | ReactElement;
    toolbar?: ReactElement;
    children?: any;
}

AppView.Content = ({ className, title, toolbar, children }: IAppViewContentProps) => {
    const classNamesAppended = appendClasses(['app-view', className]);

    const { sidebar } = useContext(AppViewContext);

    return (
        <div className={classNamesAppended}>
            {sidebar}
            <div className="app-view_content">
                <div className="app-view_content-title">
                    <h1>{title || <>&nbsp;</>}</h1>
                    {toolbar}
                </div>
                <div className="app-view_content-content">{children}</div>
            </div>
        </div>
    );
};

export default AppView;
