import { useMemo } from 'react';

import { groupBy, keyBy } from 'lodash';

import {
    DetailedSparkType,
    Spark,
    UISparkStanding,
    checkForTrainingComplete,
} from '@sparkplug/lib';

import { formatSparkInfo } from '@helpers/sparks';

import { getDefaultSparkChartDataSettings } from './useSparkCloudChartData';
import { useSparkPosData } from './useSparkPosData';
import { useSparkStandings } from './useSparkStandings';

const isTeamBasedSpark = (detailedSparkType?: DetailedSparkType) => {
    const teamBasedSparkTypes: DetailedSparkType[] = ['goalTeam', 'leaderboardLocation'];
    return !!detailedSparkType && teamBasedSparkTypes.includes(detailedSparkType);
};

/**
 * @description `useSparkIndividualStandings` takes the Spark Standings for the given Spark type and
 * turns them into a list of individual standings for each participant. Most of these Spark
 * types use the `spark.posEmployeeProfileIds` while more complex Spark types (like
 * Location Leaderboards and Team Goal) use different logic to determine the individual
 * participant standings. See the descriptions below for the unique use cases.
 */
export const useSparkIndividualStandings = ({
    spark,
    includeChartDisplayData = true,
}: {
    spark: Spark;
    includeChartDisplayData?: boolean;
}): { isLoading: boolean; sparkParticipantStandings: UISparkStanding[] } => {
    const { detailedSparkType, courseData, trainingEnabled } = spark;
    const { sparkStandingsAreReady, sparkStandings, flexibleEmployeeIdsWithSales } =
        useSparkStandings({
            spark,
            chartSettings: getDefaultSparkChartDataSettings(spark),
            isEnabled: true,
            includeChartDisplayData,
        });

    const finalSparkStandings = useMemo(() => {
        const trainingIsRequired = !!trainingEnabled && courseData?.required;
        if (trainingIsRequired && !isTeamBasedSpark(detailedSparkType)) {
            return sparkStandings.filter(({ flexibleEmployeeId }) => {
                return checkForTrainingComplete({
                    userId: flexibleEmployeeId,
                    courseData,
                });
            });
        }

        return sparkStandings;
    }, [courseData?.required, sparkStandings]);

    const { sparkPosDataIsReady, sparkPosData } = useSparkPosData({ spark, isEnabled: true });

    const { participants, locations } = sparkPosData;

    const sparkParticipantStandings: UISparkStanding[] = useMemo(() => {
        const { status } = formatSparkInfo(
            spark.startDate,
            spark.endDate,
            spark.requestState,
            spark.recurringSchedule,
        );

        if (
            !['Complete', 'Active'].includes(status) ||
            !detailedSparkType ||
            !sparkPosDataIsReady ||
            !sparkStandingsAreReady
        ) {
            return [];
        }

        const sparkLocationsById = keyBy(locations, '_id');

        /**
         * @description For Team and Manager Goals, the participants share the same rank and value.
         * In this conditional, we return an array of participants with the same rank and value.
         */
        if (['goalTeam', 'goalManager'].includes(detailedSparkType)) {
            const isTeamGoal = detailedSparkType === 'goalTeam';

            return participants
                .map<UISparkStanding>(
                    ({
                        flexibleEmployeeId,
                        firstName,
                        lastName,
                        locationIds,
                        posEmployeeProfileIds,
                        lastTransactionLocationId,
                    }) => {
                        const rank = 1;

                        // For team goals, the user needs to have made a sale to qualify.
                        // If they haven't, we set their value to 0
                        const userHasNotMadeTeamGoalSale =
                            isTeamGoal &&
                            !flexibleEmployeeIdsWithSales.includes(flexibleEmployeeId);
                        const primaryValue = userHasNotMadeTeamGoalSale
                            ? 0
                            : finalSparkStandings?.[0]?.value;

                        const locationName =
                            locationIds
                                ?.map((locationId) => sparkLocationsById?.[locationId]?.label)
                                .filter((label) => label)
                                .join(', ') ?? 'N/A';

                        return {
                            firstName,
                            lastName,
                            locationName,
                            flexibleEmployeeId,
                            posEmployeeProfileIds,
                            primaryValue,
                            rank,
                            lastTransactionLocationId,
                        };
                    },
                )
                .sort((a, b) => b.primaryValue - a.primaryValue);
        }

        /**
         * @description For Location Leaderboards, the participants share the rank and value of
         * their primary location (the last location they made a sale at). In this conditional,
         * we override the participants values with those of their primary location.
         */
        if (detailedSparkType === 'leaderboardLocation') {
            const participantsByLastTransactionLocationId = groupBy(
                participants,
                'lastTransactionLocationId',
            );

            return finalSparkStandings
                .reduce<UISparkStanding[]>((res, locationChartLeader, i) => {
                    const rank = i + 1;
                    const primaryValue = finalSparkStandings?.[i]?.value;
                    const locationParticipants =
                        participantsByLastTransactionLocationId[
                            locationChartLeader.flexibleEmployeeId
                        ];

                    locationParticipants.forEach(
                        ({
                            flexibleEmployeeId,
                            firstName,
                            lastName,
                            locationIds,
                            posEmployeeProfileIds,
                            lastTransactionLocationId,
                        }) => {
                            const locationName =
                                locationIds
                                    ?.map((locationId) => sparkLocationsById?.[locationId]?.label)
                                    .filter((label) => label)
                                    .join(', ') ?? 'N/A';

                            res.push({
                                firstName,
                                lastName,
                                locationName,
                                flexibleEmployeeId,
                                posEmployeeProfileIds,
                                primaryValue:
                                    // If user hasn't made sales we want to set their primaryValue to 0
                                    flexibleEmployeeIdsWithSales.includes(flexibleEmployeeId)
                                        ? primaryValue
                                        : 0,
                                rank,
                                lastTransactionLocationId,
                            });
                        },
                    );

                    return res;
                }, [])
                .sort((a, b) => b.primaryValue - a.primaryValue);
        }

        const participantsByFlexibleEmployeeId = keyBy(participants, 'flexibleEmployeeId');

        return finalSparkStandings.reduce<UISparkStanding[]>(
            (
                res,
                {
                    flexibleEmployeeId,
                    value: primaryValue,
                    unitCount: secondaryValue,
                    transactionCount,
                    percentIncreaseComparison,
                },
            ) => {
                // If standing is for a non participant, do not add them to the individual standings
                const participant = participantsByFlexibleEmployeeId[flexibleEmployeeId];
                if (!participant) {
                    return res;
                }

                const rank = res.length + 1;
                const {
                    firstName,
                    lastName,
                    locationIds,
                    posEmployeeProfileIds,
                    lastTransactionLocationId,
                } = participant;

                const locationName =
                    locationIds
                        ?.map((locationId) => sparkLocationsById?.[locationId]?.label)
                        .filter((label) => label)
                        .join(', ') ?? 'N/A';

                res.push({
                    firstName,
                    lastName,
                    locationName,
                    flexibleEmployeeId,
                    posEmployeeProfileIds,
                    primaryValue,
                    secondaryValue,
                    rank,
                    lastTransactionLocationId,
                    transactionCount,
                    percentIncreaseComparison,
                });

                return res;
            },
            [],
        );
    }, [
        spark,
        sparkPosDataIsReady,
        participants,
        locations,
        sparkStandingsAreReady,
        finalSparkStandings,
    ]);

    return {
        isLoading: !sparkPosDataIsReady || !sparkStandingsAreReady,
        sparkParticipantStandings,
    };
};
