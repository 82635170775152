import { FC } from 'react';

import Button from '@components/buttons/Button';
import { ChevronLeft, Info as InfoIcon } from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import Tooltip from '@components/layout/Tooltip';
import Toolbar from '@components/toolbar/Toolbar';

import ProductSegment from '../../../ProductSegment';
import ProductNameDropdown from '../../../SparkProductSelectorTable/ProductNameDropdown';
import {
    UseBrandCategoryFilters,
    UsePosRulesBasedSparkProductSelectorTableFilters,
} from '../../hooks';

import './DeprecatedProductSegmentToolbar.scss';

interface DeprecatedProductSegmentToolbarProps {
    tableFilters: UsePosRulesBasedSparkProductSelectorTableFilters;
    formHasErrorState?: boolean;
    showExcludedProductsTable: boolean;
    setShowExcludedProductsTable: (updatedValue: boolean) => void;
    brandCategoryFilters: UseBrandCategoryFilters;
    emptyStateIsVisible: boolean;
}

/**
 * @description
 *
 * This component is deprecated and should not be used. We now use the ProductFilterSegmentToolbar
 * component when creating the new Sparks in the wizard and eventually all existing Sparks
 * will eventually be cutover to no longer use this Toolbar
 *
 */
const DeprecatedProductSegmentToolbar: FC<DeprecatedProductSegmentToolbarProps> = ({
    tableFilters,
    brandCategoryFilters,
    formHasErrorState = false,
    showExcludedProductsTable,
    setShowExcludedProductsTable,
    emptyStateIsVisible,
}) => {
    if (showExcludedProductsTable) {
        return (
            <>
                <div className="excluded-products_back-nav">
                    <Button
                        color="blue"
                        variant="smooth"
                        startIcon={<ChevronLeft />}
                        onClick={() => setShowExcludedProductsTable(false)}
                    >
                        Back to Qualifying Products
                    </Button>
                </div>
                <div className="excluded-products_callout">
                    <CalloutMessage
                        color="neutral"
                        message="These products will be excluded from this Spark only. To remove from this exclusion list, select the + icon."
                    />
                </div>
            </>
        );
    }

    const { filters, setFilters } = tableFilters;

    const {
        updateBrandsFilter,
        updateCategoriesFilter,
        visibleBrandOptions,
        selectedBrandCountProps,
        visibleCategoryOptions,
        selectedCategoryCountProps,
    } = brandCategoryFilters;

    const isAllBrands = Boolean(
        (filters.brands?.length === visibleBrandOptions.length && visibleBrandOptions.length) ||
            // This handles a saved "All Brands" Spark because the primary filter has been set but the brands filter is empty
            (filters.primaryFilter === 'brands' && !filters.brands?.length),
    );

    const defaultToolbar = (
        <Toolbar justifyContentStart>
            <Toolbar.DropdownListSelector
                label={`${isAllBrands ? '(All) ' : ''}Brands`}
                options={visibleBrandOptions}
                selected={filters.brands || []}
                onApply={updateBrandsFilter}
                showCount={
                    !!filters.brands?.length &&
                    filters.brands?.length !== visibleBrandOptions.length
                }
                clear={{
                    active: !!filters.brands?.length || isAllBrands,
                    onClear: () => {
                        updateBrandsFilter([]);
                    },
                }}
                selectedCountProps={selectedBrandCountProps}
            />

            <Toolbar.DropdownListSelector
                label={`${
                    filters.categories?.length === visibleCategoryOptions.length &&
                    visibleCategoryOptions.length
                        ? '(All) '
                        : ''
                }Categories`}
                options={visibleCategoryOptions}
                selected={filters.categories || []}
                onApply={updateCategoriesFilter}
                showCount={
                    !!filters.categories?.length &&
                    filters.categories?.length !== visibleCategoryOptions.length
                }
                clear={{
                    active: !!filters.categories?.length,
                    onClear: () => updateCategoriesFilter([]),
                }}
                selectedCountProps={selectedCategoryCountProps}
            />

            <ProductNameDropdown
                initialProductNameFilters={filters.productNameFilters || []}
                onApply={(productNameFilters) =>
                    setFilters((prevValue) => ({
                        ...prevValue,
                        productNameFilters,
                    }))
                }
            />
            <Toolbar.DynamicStringDropdownListSelector
                createButtonLabel="Create New Rule"
                buttonLabel="Product Name Contains"
                pluralUnitLabel="rules"
                textFieldLabel={
                    <>
                        Product name <strong>contains:</strong>
                    </>
                }
                chipText="contains"
                initialStringFilters={filters.productNameContains ?? []}
                onApply={(productNameContains) =>
                    setFilters((prevValue) => ({
                        ...prevValue,
                        productNameContains,
                    }))
                }
            />
            <Toolbar.DynamicStringDropdownListSelector
                createButtonLabel="Create New Rule"
                buttonLabel="Product Name Does Not Contain"
                pluralUnitLabel="rules"
                textFieldLabel={
                    <>
                        Product name <strong>does not contain:</strong>
                    </>
                }
                chipText="does not contain"
                initialStringFilters={filters.productNameDoesNotContain ?? []}
                onApply={(productNameDoesNotContain) =>
                    setFilters((prevValue) => ({
                        ...prevValue,
                        productNameDoesNotContain,
                    }))
                }
            />

            <Toolbar.Checkbox
                label={
                    <div>
                        <span>
                            {'Include '}
                            <strong>“Sample”</strong>
                            {' products'}
                        </span>
                        <Tooltip
                            title="Select to include products with the word “sample” in their product name or category"
                            placement="top"
                        >
                            <InfoIcon className="tooltip-icon" />
                        </Tooltip>
                    </div>
                }
                value={!filters.hideSampleProducts}
                onChange={() =>
                    setFilters((prevValue) => ({
                        ...prevValue,
                        hideSampleProducts: !filters.hideSampleProducts,
                    }))
                }
            />

            {
                /**
                 * in order to gradually transition to always hiding old products, this checkbox
                 * is only visible on existing sparks that are set to allTime. that way we can uncheck
                 * this and convert them to the new default `-60days`
                 */
                filters.lastSoldAt === 'allTime' && (
                    <Toolbar.Checkbox
                        label={
                            <div data-testid="checkbox-last-sold-at">
                                <span>
                                    {'Include '}
                                    <strong>old</strong>
                                    {' products'}
                                </span>
                                <Tooltip
                                    title="Select to include products sold more than 60 days ago"
                                    placement="top"
                                >
                                    <InfoIcon className="tooltip-icon" />
                                </Tooltip>
                            </div>
                        }
                        value={filters.lastSoldAt === 'allTime'}
                        onChange={() => {
                            const updatedLastSoldAt =
                                filters.lastSoldAt === '-60days' ? 'allTime' : '-60days';

                            setFilters((prevValue) => ({
                                ...prevValue,
                                lastSoldAt: updatedLastSoldAt,
                            }));
                        }}
                    />
                )
            }

            {!!(!showExcludedProductsTable && filters.excludedProductIds.length) && (
                <Toolbar.Button
                    alignEnd
                    color="neutral"
                    onClick={() => setShowExcludedProductsTable(true)}
                >
                    {`Excluded (${filters.excludedProductIds.length})`}
                </Toolbar.Button>
            )}
        </Toolbar>
    );

    return (
        <ProductSegment
            toolbar={defaultToolbar}
            required
            error={formHasErrorState && emptyStateIsVisible}
        />
    );
};

export default DeprecatedProductSegmentToolbar;
