import axios from 'axios';

import { useAdvancedMutation } from '@hooks/QueryHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { getEventsQueryKey } from '../queries';

const API = {
    async declineEvent(eventId: string) {
        return (await axios.post<null>(`/api/v1/event/${eventId}/decline`)).status;
    },
    async approveEvent(eventId: string) {
        return (await axios.post<null>(`/api/v1/event/${eventId}/approve`)).status;
    },
    async deleteEvent(eventId: string) {
        return (await axios.delete<null>(`/api/v1/event/${eventId}`)).status;
    },
};

export const useDeclineEventMutation = () => {
    const { account } = useSparkplugAccount();

    const { mutateAsync, isLoading } = useAdvancedMutation(
        (eventId: string) => API.declineEvent(eventId),
        {
            toastOptions: {
                loading: 'Declining event...',
                success: 'Event declined',
                error: 'Something went wrong.',
            },
            updateQuery: { queryKey: getEventsQueryKey(account?._id!).filter(Boolean) },
        },
    );

    return {
        isDecliningEvent: isLoading,
        declineEventAsync: mutateAsync,
    };
};

export const useApproveEventMutation = () => {
    const { account } = useSparkplugAccount();

    const { mutateAsync, isLoading } = useAdvancedMutation(
        (eventId: string) => API.approveEvent(eventId),
        {
            toastOptions: {
                loading: 'Approving event...',
                success: 'Event approved',
                error: 'Something went wrong.',
            },
            updateQuery: { queryKey: getEventsQueryKey(account?._id!).filter(Boolean) },
        },
    );

    return {
        isApprovingEvent: isLoading,
        approveEventAsync: mutateAsync,
    };
};

export const useDeleteEventMutation = () => {
    const { account } = useSparkplugAccount();

    const { mutateAsync, isLoading } = useAdvancedMutation(
        (eventId: string) => API.deleteEvent(eventId),
        {
            toastOptions: {
                loading: 'Deleting event...',
                success: 'Event deleted',
                error: 'Something went wrong.',
            },
            updateQuery: { queryKey: getEventsQueryKey(account?._id!).filter(Boolean) },
        },
    );

    return {
        isDeletingEvent: isLoading,
        deleteEventAsync: mutateAsync,
    };
};
