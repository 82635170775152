import { FC, ReactNode } from 'react';

import clsx from 'clsx';

import Dropdown from '@components/dropdown/Dropdown';
import { MoreHoriz } from '@components/icons';

import { ToolbarContext } from './ToolbarContext';

import './Toolbar.scss';

interface ToolbarProps {
    justifyContentStart?: boolean;
    collapseOnMobile?: boolean;
    scrollOnMobile?: boolean;
    className?: string;
    children?: ReactNode;
}

const Toolbar: FC<ToolbarProps> = ({
    justifyContentStart = false,
    collapseOnMobile = false,
    scrollOnMobile = false,
    className,
    children,
}) => {
    return (
        <ToolbarContext.Provider value={{}}>
            <div
                className={clsx(
                    'toolbar-container',
                    { 'toolbar-collapse-on-mobile': collapseOnMobile },
                    { 'toolbar-scroll-on-mobile': scrollOnMobile },
                    { 'toolbar-content-start': justifyContentStart },
                    className,
                )}
            >
                <div className="toolbar">{children}</div>
                {collapseOnMobile && (
                    <div className="toolbar-mobile">
                        <Dropdown>
                            <Dropdown.IconButton>
                                <MoreHoriz />
                            </Dropdown.IconButton>
                            <Dropdown.Popover>
                                <div className="toolbar-mobile-popover">{children}</div>
                            </Dropdown.Popover>
                        </Dropdown>
                    </div>
                )}
            </div>
        </ToolbarContext.Provider>
    );
};

export default Toolbar;
