import { useMemo, useState } from 'react';

// import { debounce, uniqBy } from 'lodash';
import { debounce } from 'lodash';

import { EVENT_REQUEST_STATUSES, EVENT_TYPES, ListEventsQueryParams } from '@sparkplug/lib';

import { IListItem } from '@components/inputs/ListSelector/ListSelector';
import { queryToString, useQueryParams } from '@components/router';
import Toolbar from '@components/toolbar/Toolbar';

import { useApp } from '@hooks/AppHooks';
import useVendorBrands from '@hooks/BrandHooks/useVendorBrands';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { IBrandLink } from '@app/types/BrandLinksTypes';
import { IPosLocation } from '@app/types/PosTypes';
import { IOption, IOptionTree } from '@app/types/UITypes';

interface ILocationOptions {
    optionTree: IOptionTree<{}>[];
    allLocations: IOption<{ key: string }>[];
}

const getLocationOptionsForBrands = (
    brandLinks: IBrandLink[] = [],
    includeDisabledLocations?: boolean,
): ILocationOptions => {
    const formatLocations = (locations: IPosLocation[]) =>
        locations.map((location) => ({
            label: location.displayName ?? location.name,
            value: location._id,
            key: location._id,
        }));

    let optionTree: IOptionTree<{}>[] = [];
    let allLocations: IOption<{ key: string }>[] = [];
    if (brandLinks.length) {
        optionTree = brandLinks
            .filter(({ locations }) => locations?.length)
            .map(({ label, retailerAccountId, locations = [], disabledLocations = [] }) => {
                const formattedLocations = formatLocations([
                    ...locations,
                    ...(includeDisabledLocations ? disabledLocations : []),
                ]);
                allLocations = allLocations.concat(formattedLocations);

                return {
                    label,
                    value: retailerAccountId,
                    children: formattedLocations,
                };
            });
    }

    return {
        optionTree,
        allLocations,
    };
};

const getLocationOptionsForRetailers = (
    accountLocations: IOption<IPosLocation>[] = [],
): ILocationOptions => {
    return {
        optionTree: accountLocations,
        allLocations: accountLocations.map((location) => ({ ...location, key: location._id })),
    };
};

const EventTableToolbar = ({
    locations,
    setLocations,
}: {
    locations: IListItem[];
    setLocations: (locations: IListItem[]) => void;
}) => {
    const { account } = useSparkplugAccount();
    const { history } = useApp();
    const queryParams = useQueryParams();

    const { data: vendorBrands, isLoading: isLoadingVendorBrands } = useVendorBrands(account);

    const [searchFilter, setSearchFilter] = useState('');

    const updateEventFilters = (
        filter: keyof ListEventsQueryParams,
        value: string | string[] | undefined,
    ) => {
        const sanitizedValue = Array.isArray(value) ? value.filter(Boolean).join(',') : value;
        if (!sanitizedValue) {
            delete queryParams[filter];
            history.push(`?${queryToString(queryParams)}`);
            return;
        }
        history.push(
            `?${queryToString({
                ...queryParams,
                ...(filter && { [filter]: sanitizedValue || undefined }),
            })}`,
        );
    };

    const getEventFilterValue = (filter: keyof ListEventsQueryParams) => {
        const value = decodeURIComponent(queryParams[filter] || '');
        const values = value.split(',').filter(Boolean);
        return values.length ? values : [];
    };

    const handleSearchChange = (value: string) => {
        updateEventFilters('search', value);
    };

    const debouncedHandleSearchChange = debounce(handleSearchChange, 250);

    const locationOptions: ILocationOptions = useMemo(() => {
        return account?.type === 'brand'
            ? getLocationOptionsForBrands(account?.brandLinks)
            : getLocationOptionsForRetailers(account?.locations);
    }, [account]);

    const handleLocationsChange = (updatedLocations: IListItem[]) => {
        if (updatedLocations.length === locationOptions.allLocations.length) {
            setLocations([]);
        } else {
            setLocations(updatedLocations);
        }
    };

    const brandOptions = useMemo(() => {
        return vendorBrands?.map((brand) => ({
            value: brand._id,
            label: brand.name,
        }));
    }, [vendorBrands]);

    const getBrandOptions = (brandIds: string[]) => {
        return (
            brandOptions
                ?.filter((brand) => brandIds.includes(brand.value))
                .map((brand) => ({ ...brand, key: brand.value })) || []
        );
    };

    const isAllBrands = useMemo(() => {
        return (
            !getEventFilterValue('brandId').length ||
            getEventFilterValue('brandId').length === brandOptions?.length
        );
    }, [brandOptions]);

    return (
        <Toolbar scrollOnMobile justifyContentStart className="[&_.toolbar]:mb-0">
            <Toolbar.Search
                name="events-search"
                defaultValue={searchFilter}
                onChange={(ev) => {
                    setSearchFilter(ev.target.value);
                    debouncedHandleSearchChange(ev.target.value);
                }}
            />

            <Toolbar.DropdownListSelector
                label={account?.type === 'brand' ? 'Retailer Locations' : 'Locations'}
                selected={locations.length ? locations : locationOptions.allLocations}
                options={locationOptions.optionTree}
                onSelectionChanged={(v) => {
                    handleLocationsChange(v);
                }}
                clear={{
                    active:
                        locations.length !== locationOptions.allLocations.length &&
                        !!locations.length,
                    onClear: () => {
                        handleLocationsChange(locationOptions.allLocations);
                    },
                }}
            />

            {account?.type === 'brand' && brandOptions && (
                <Toolbar.DropdownListSelector
                    label={`${isAllBrands ? 'All ' : ''}Brands`}
                    options={brandOptions}
                    selected={
                        getEventFilterValue('brandId').length
                            ? getBrandOptions(getEventFilterValue('brandId'))
                            : brandOptions
                    }
                    onSelectionChanged={(value) => {
                        if (value.length === brandOptions.length) {
                            updateEventFilters('brandId', []);
                        } else {
                            updateEventFilters(
                                'brandId',
                                value.map((v) => v.value),
                            );
                        }
                    }}
                    clear={{
                        active: !isAllBrands,
                        onClear: () => {
                            updateEventFilters('brandId', []);
                        },
                    }}
                />
            )}

            <Toolbar.MultiSelectDropdown
                label="Type"
                options={EVENT_TYPES.map((type) => ({ label: type, value: type }))}
                selected={getEventFilterValue('type')}
                onApply={(value) => updateEventFilters('type', value)}
            />

            <Toolbar.MultiSelectDropdown
                label="Status"
                options={EVENT_REQUEST_STATUSES.map((status) => ({ label: status, value: status }))}
                selected={getEventFilterValue('requestStatus')}
                onApply={(value) => updateEventFilters('requestStatus', value)}
            />
        </Toolbar>
    );
};

export default EventTableToolbar;
