import { ComponentType, FC, useMemo } from 'react';

import {
    ChartMetricOptions,
    ChartPrecisionOptions,
    ChartViewOptions,
} from '@constants/ChartConstants';
import moment, { Moment } from 'moment';

import { useFetchPosConfigsByGroupId } from '@core/accounts';
import { DeprecatedAdvancedChart } from '@core/charts/components/AdvancedChart';
import { useChartContext } from '@core/charts/components/AdvancedChart/ChartContext';
import { ComparisonChartProvider } from '@core/charts/components/AdvancedComparisonChart/ComparisonChartContext';

import { Add } from '@components/icons';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import PageHeader from '@components/layout/PageHeader';
import Paper from '@components/layout/Paper';
import Toolbar from '@components/toolbar/Toolbar';

import Intercom from '@helpers/Intercom';

import { IAccount } from '@app/types/AccountsTypes';
import { IChartDataSettings, TChartDataPrecision } from '@app/types/ChartDataTypes';
import { IPosLocation } from '@app/types/PosTypes';

import DashboardUserCharts from '../components/DashboardUserCharts';
import { useDashboardContext } from '../contexts/DashboardContext';

import './RetailerDashboardView.scss';

export interface RetailerDashboardViewProps {
    account: IAccount;
}
export const RetailerDashboardView: FC<RetailerDashboardViewProps> = ({ account }) => {
    // This should be replaced with a `useRetailerChartContext` sort of hook
    const { chartSettings, updateChartSettings, onUpdateChartSetting } = useChartContext();

    const locationOptions = useMemo(() => account?.locations ?? [], [account]);

    const selectedLocations = useMemo(() => {
        return locationOptions.filter(({ value }) => chartSettings.locationIds.includes(value));
    }, [chartSettings.locationIds, locationOptions]);

    const { setDateRange: setCustomContextDateRange } = useDashboardContext();

    const updateDateRange = (dateStart: Moment | null, dateEnd: Moment | null) => {
        setCustomContextDateRange({
            dateStart,
            dateEnd,
        });
        updateChartSettings({ dateStart, dateEnd });
    };

    const updateFrequency = (value: TChartDataPrecision) => {
        updateChartSettings({
            precision: value,
        });
    };

    const updateSelectedLocations = (selected: IPosLocation[]) => {
        if (selected.length > 0) {
            updateChartSettings({
                locationIds: selected.map((obj) => obj.value),
            });
        }
    };

    const pageHeaderTitle = `${account?.name}`;
    const pageMetaTitle = `${account?.name} Dashboard`;

    const { posConfigs, posConfigsAreReady } = useFetchPosConfigsByGroupId(account?._id);

    return (
        <div className={`dashboard-view dashboard-${account?.type}-view`}>
            <PageHeader title={pageHeaderTitle} metaTitle={pageMetaTitle} heading="Dashboard" />
            {(posConfigsAreReady && !posConfigs) || (posConfigs && posConfigs.length < 1) ? (
                <EmptyStateDisplay
                    layout="horizontal"
                    addPaperWrapper
                    className="connect-pos-empty-state"
                    label="Connect your POS system to get sales insights"
                    smallText="Integrate your POS system to track employee sales and create Spark incentives. Gain valuable insights to drive better business decisions."
                    graphic={
                        <img src="/static/images/connect-pos-integration.jpg" alt="placeholder" />
                    }
                    actionButton={{
                        label: 'Add POS Integration',
                        onClick: () => {
                            Intercom.open();
                        },
                        startIcon: <Add />,
                    }}
                />
            ) : (
                <>
                    <Toolbar scrollOnMobile>
                        <Toolbar.DateRangeSelector
                            id="dashboard-date-range"
                            dateStart={chartSettings.dateStart}
                            dateEnd={chartSettings.dateEnd}
                            onApply={updateDateRange}
                            alwaysShowDates
                        />
                        <Toolbar.Dropdown
                            label="Frequency"
                            value={chartSettings.precision}
                            onSelect={updateFrequency}
                            options={ChartPrecisionOptions}
                        />

                        <Toolbar.DropdownListSelector
                            label="Locations"
                            selected={selectedLocations || []}
                            onApply={updateSelectedLocations}
                            options={locationOptions || []}
                            clear={{
                                active:
                                    (selectedLocations || []).length !==
                                    (locationOptions || []).length,
                                onClear: () => {
                                    updateSelectedLocations(locationOptions);
                                },
                            }}
                        />

                        <Toolbar.Dropdown
                            label={null}
                            className="toolbar-group-end"
                            value={chartSettings.type}
                            onSelect={onUpdateChartSetting('type')}
                            options={ChartViewOptions}
                        />
                    </Toolbar>
                    <Paper className="section">
                        <Toolbar>
                            <Toolbar.Dropdown
                                className="toolbar-group-start section-title"
                                label={null}
                                value={chartSettings.metric}
                                onSelect={onUpdateChartSetting('metric')}
                                options={ChartMetricOptions.filter(({ value }) => {
                                    if (
                                        [
                                            'units_per_transaction',
                                            'percent_of_total_sales',
                                        ].includes(value)
                                    ) {
                                        return false;
                                    }

                                    // Only include 'guest_check_average' if 'food service' is in account.industries
                                    if (value === 'guest_check_average') {
                                        return account.industries?.includes('food service');
                                    }

                                    return true;
                                })}
                            />
                        </Toolbar>

                        {/**
                         * This should be replaced with some sort of `RetailerChart` component that uses
                         * a new `RetailerChartContext` and the `AdvancedChart` component
                         */}
                        <DeprecatedAdvancedChart />
                    </Paper>
                    <Paper className="section">
                        <DashboardUserCharts
                            retailerAccountId={account._id}
                            chartSettings={chartSettings}
                        />
                    </Paper>
                </>
            )}
        </div>
    );
};

const withRetailerChartProvider =
    (DashboardComponent: ComponentType<RetailerDashboardViewProps>) =>
    ({ account }: RetailerDashboardViewProps) => {
        const dateStart = moment().subtract(30, 'days').toDate();
        const dateEnd = moment().toDate();
        const { dateStart: customDateStart, dateEnd: customDateEnd } = useDashboardContext();

        const initialChartSettings: IChartDataSettings = {
            locationIds: (account?.locations ?? []).map(({ value }) => value),
            metric: 'total_sales',
            precision: 'day',
            type: 'bar',
            dateStart: customDateStart || dateStart,
            dateEnd: customDateEnd || dateEnd,
            breakdown: 'location',
        };

        return (
            <ComparisonChartProvider
                retailerAccountId={account?._id ?? ''}
                initialSettings={initialChartSettings}
            >
                <DashboardComponent account={account} />
            </ComparisonChartProvider>
        );
    };

export default withRetailerChartProvider(RetailerDashboardView);
