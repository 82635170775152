import { Industry } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import IntercomContactMessage from '@components/buttons/IntercomContactMessage';
import { CopyLinkIcon } from '@components/icons';
import toast from '@components/toast';

import { appendClasses } from '@helpers/ui';

import './AccountDetailsWidget.scss';

const CopyLinkWidget = ({ linkUrl }: { linkUrl: string }) => (
    <div className="copy-link-widget">
        <span className="link-url">{linkUrl}</span>
        <Button
            onClick={() =>
                navigator.clipboard
                    .writeText(linkUrl)
                    .then(() => toast.success('Referral link copied!'))
            }
            startIcon={<CopyLinkIcon />}
            color="blue"
            variant="filled"
        >
            Copy Link
        </Button>
    </div>
);

export interface AccountDetails {
    accountName: string;
    markets?: string[];
    industries?: Industry[];
    referralUrl?: string;
    logo?: string;
}
export const AccountDetailsWidget = ({
    accountName,
    markets = [],
    industries = [],
    referralUrl,
    logo,
}: AccountDetails) => {
    const marketsLabel = markets?.length === 51 ? 'All Markets' : markets.join(', ');

    return (
        <div className="account-details-content">
            <article>
                <div className="logo-container">
                    <img src={logo} alt={accountName} />
                </div>

                <div
                    className={appendClasses([
                        'info-container',
                        !referralUrl ? 'no-referral' : null,
                    ])}
                >
                    <div className="title">{accountName}</div>

                    <section>
                        <div className="markets">
                            <span className="info-label">Markets:</span>
                            <span className="info-content">{marketsLabel}</span>
                        </div>

                        <div className="industry">
                            <span className="info-label">Industries:</span>
                            <span className="info-content">{industries.join(', ')}</span>
                        </div>
                    </section>

                    {referralUrl && <CopyLinkWidget linkUrl={referralUrl} />}
                </div>
            </article>

            <IntercomContactMessage prompt="Want to update account details?" />
        </div>
    );
};
