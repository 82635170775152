import axios from 'axios';

import {
    SelfEnrollPosEmployeeProfileRequestBody,
    SelfEnrollSearchRequestBody,
    SelfEnrollSearchResponseBody,
    SelfEnrollUnmatchedRequestBody,
} from '@sparkplug/lib';

export default class SelfEnrollAPI {
    static async search({
        recaptcha,
        ...body
    }: SelfEnrollSearchRequestBody & { recaptcha: string }): Promise<SelfEnrollSearchResponseBody> {
        return (
            await axios.post('/api/v1/sparkplug/users/enroll/self-serve/search', body, {
                headers: {
                    'x-recaptcha-token': recaptcha,
                },
            })
        ).data;
    }

    static async enrollPosEmployeeProfile({
        recaptcha,
        ...body
    }: SelfEnrollPosEmployeeProfileRequestBody & { recaptcha: string }): Promise<void> {
        return axios.post('/api/v1/sparkplug/users/enroll/self-serve', body, {
            headers: {
                'x-recaptcha-token': recaptcha,
            },
        });
    }

    static unmatched({
        recaptcha,
        ...body
    }: SelfEnrollUnmatchedRequestBody & { recaptcha: string }): Promise<void> {
        return axios.post('/api/v1/sparkplug/users/enroll/self-serve/unmatched', body, {
            headers: {
                'x-recaptcha-token': recaptcha,
            },
        });
    }
}
