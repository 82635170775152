import { FC, ReactNode } from 'react';

import './DetailsList.scss';

export type Detail = {
    title: string;
    content: string | ReactNode;
};

interface DetailsListProps {
    details: Detail[];
    className?: string;
    layout?: 'horizontal' | 'vertical';
    condensed?: boolean;
}

export const DetailsList: FC<DetailsListProps> = ({
    details,
    className = '',
    layout,
    condensed,
}) => {
    const layoutClassName = layout ? `details-list-${layout}` : '';
    const condensedClassName = condensed ? 'details-list-condensed' : '';

    return (
        <ul className={`${className} details-list ${layoutClassName} ${condensedClassName}`}>
            {details.map(({ title, content }) => (
                <li key={title}>
                    <div className="title">{title}</div>
                    <div className="content">{content}</div>
                </li>
            ))}
        </ul>
    );
};
