import PreviewPhoneGraphic from './PreviewPhoneGraphic.svg?react';
import { FC } from 'react';

import { SparkBrandGalleryChip } from '../SparkBrandGalleryChip';

import './BrandPreviewWidget.scss';

interface BrandPreviewWidgetProps {
    name: string;
    logo: string;
}
export const BrandPreviewWidget: FC<BrandPreviewWidgetProps> = ({ logo, name }) => (
    <div className="brand-preview-widget">
        <SparkBrandGalleryChip label={name} dense />

        <div className="brand-preview-logo-container">
            <img loading="lazy" src={logo} alt={`${name} logo`} />
        </div>

        <PreviewPhoneGraphic />
    </div>
);
