import { FC, Fragment, ReactElement, useMemo } from 'react';

import { clsx } from 'clsx';

import SearchField from '@components/inputs/SearchField';
import List from '@components/layout/List';
import Modal, { IDefaultModalProps } from '@components/overlays/Modal';

import { useSearch } from '@hooks/UIHooks';

import './SearchableListModalMobile.scss';

interface ISearchableListModalMobileProps extends IDefaultModalProps {
    title: string;
    list: any[];
    listTitle: string | ReactElement;
    ListItemComponent?: FC<any>;
    searchFields: string[];
    separator?: {
        listItemId?: string;
        element: ReactElement;
    };
}

const SearchableListModalMobile = ({
    isVisible,
    onClose,
    className,
    title,
    listTitle,
    list,
    searchFields,
    ListItemComponent,
    separator,
}: ISearchableListModalMobileProps) => {
    const { onChangeSearchFilter, applySearch } = useSearch(searchFields);

    const filteredList = useMemo(() => {
        return applySearch(list) ?? [];
    }, [list, applySearch]);

    return (
        <Modal
            className={clsx(className, 'modal-mobile')}
            isVisible={isVisible}
            onClose={() => onClose(false)}
        >
            <Modal.Title onClose={() => onClose(false)}>{title}</Modal.Title>

            <Modal.Content>
                <div className="searchable-list_upper">
                    <SearchField name="search" onChange={onChangeSearchFilter} />
                    <h2>{listTitle}</h2>
                </div>

                {ListItemComponent == null ? (
                    <List>
                        {filteredList.map((listItem, i) => (
                            <List.Item key={i}>{listItem?.label}</List.Item>
                        ))}
                    </List>
                ) : (
                    filteredList.map((listItem, i) => {
                        const isTopIneligibleParticipant =
                            separator?.element &&
                            separator?.listItemId &&
                            listItem?.flexibleEmployeeId === separator?.listItemId;
                        return (
                            <Fragment key={listItem?.flexibleEmployeeId ?? i}>
                                {isTopIneligibleParticipant && separator?.element}
                                <ListItemComponent listItem={listItem} />
                            </Fragment>
                        );
                    })
                )}
            </Modal.Content>
        </Modal>
    );
};

export default SearchableListModalMobile;
