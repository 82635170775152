import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react';

import { AccountUserRoles, SMSStatuses, SparkPlugUserRoles } from '@constants/UserConstants';
import { Send as SendIcon } from '@mui/icons-material';
import { isUndefined, keyBy, omitBy } from 'lodash';

import { DO_NOT_HAVE_PERMISSIONS_MESSAGE, IPublicUser } from '@sparkplug/lib';

import {
    BulkAccountUserData,
    SaveAccountUsersMultiError,
    useEnrollUser,
    useSaveUsersMultiMutation,
    useSendResetPasswordEmailRequest,
    useUnenrollAndSetInactiveMutation,
} from '@core/users';

import { TableProvider } from '@contexts/TableContext';

import Chip from '@components/chips/Chip';
import ManagerChip from '@components/chips/ManagerChip';
import SMSEnrollmentChip from '@components/chips/SMSEnrollmentChip';
import UserGroupRoleChip from '@components/chips/UserGroupRoleChip';
import Dropdown from '@components/dropdown/Dropdown';
import Form from '@components/form/Form';
import { NoUsersGraphic } from '@components/graphics';
import {
    Add,
    Close,
    CustomEditIcon,
    CustomEmailIcon,
    CustomSmsIcon,
    DeleteForever,
    Done,
    Edit,
    MoreHoriz,
    RemoveCircleOutline,
    RemovePersonIcon,
    SaveAlt,
    Send,
    WarningOutlined,
} from '@components/icons';
import PosEmployeeProfileLocationsLabel from '@components/labels/PosEmployeeProfileLocationsLabel';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import Tooltip from '@components/layout/Tooltip';
import DeleteUserModal from '@components/overlays/DeleteUserModal';
import DeleteUsersModal from '@components/overlays/DeleteUsersModal';
import Table, { TableBulkAction } from '@components/table/Table';
import BulkSendEnrollmentConfirmModal from '@components/tables/UsersTable/BulkSendEnrollmentConfirmModal/BulkSendEnrollmentConfirmModal';
import toast from '@components/toast';
import Toolbar from '@components/toolbar/Toolbar';

import { useApp } from '@hooks/AppHooks';
import { useFormContext, useWatch } from '@hooks/FormHooks';
import {
    AccountUserFilters,
    useAccountUsersFilters,
    useSparkplugAccount,
} from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useTableContext } from '@hooks/TableHooks';
import { useSearch } from '@hooks/UIHooks';

import { isValidPhoneNumber } from '@helpers/ui';
import { isEmpty } from '@helpers/util';

import { IAccount } from '@app/types/AccountsTypes';
import { THeadCell } from '@app/types/TableTypes';
import { IAccountUser } from '@app/types/UsersTypes';

import CreateEditSparkplugUserModal from '../../overlays/CreateEditUserModal/CreateEditSparkplugUserModal';
import CreateEditUserDrawer from '../RetailerUsersTable/CreateEditUserDrawer/CreateEditUserDrawer';
import BulkSetInactiveConfirmModal from './BulkSetInactiveConfirmModal';

import './UsersTable.scss';

const Locations = [
    {
        label: 'All Locations',
        value: 'all',
    },
];

const AccountRoleFilterOptions = [
    {
        label: 'All Types',
        value: 'all',
    },
    {
        label: 'Active Types',
        value: 'active',
    },
].concat(AccountUserRoles);

const SparkPlugRoleFilterOptions = [
    {
        label: 'All Types',
        value: 'all',
    },
].concat(SparkPlugUserRoles);

const Status = [
    {
        label: 'All SMS Statuses',
        value: 'all',
    },
].concat(SMSStatuses.filter((status) => status.value !== 'muted'));

const isAccountUser = (
    accountType: IAccount['type'] | undefined,
    row: UserRow,
): row is AccountUserRow => {
    return !!accountType;
};

interface UserTableActionButtonsProps {
    accountUsers: AccountUserRow[];
    onBulkDelete: (selectedUsers: AccountUserRow[]) => void;
    onAdd: () => void;
}

const UserTableActionButtons = ({
    accountUsers,
    onBulkDelete,
    onAdd,
}: UserTableActionButtonsProps) => {
    const { user } = useApp();
    const { userCan } = useSparkplugAccount();
    const { tableSelected } = useTableContext();
    const userCanManageUsers = userCan('manageUsers');
    // Since `tableSelected` only returns keys,
    // this memo returns tableSelected users with
    // a sparkplug user account
    const tableSelectedSparkplugUsers = useMemo(() => {
        if (tableSelected != null && tableSelected.length > 0) {
            return accountUsers.filter((accountUser) => {
                return accountUser?.userId != null && tableSelected.includes(accountUser.key);
            });
        }

        return [];
    }, [accountUsers, tableSelected]);

    return (
        <>
            {tableSelected.length > 1 ? (
                <>
                    {user?.role === 'super-admin' &&
                        tableSelectedSparkplugUsers.length > 0 &&
                        false && (
                            <Toolbar.Button
                                startIcon={<DeleteForever />}
                                color="red"
                                variant="smooth"
                                onClick={() => onBulkDelete(tableSelectedSparkplugUsers)}
                            >
                                {`Delete (${tableSelectedSparkplugUsers.length})`}
                            </Toolbar.Button>
                        )}
                </>
            ) : (
                <Toolbar.Button
                    startIcon={<Add />}
                    color="blue"
                    variant="smooth"
                    disabled={!userCanManageUsers}
                    tooltip={!userCanManageUsers ? DO_NOT_HAVE_PERMISSIONS_MESSAGE : undefined}
                    onClick={() => onAdd()}
                >
                    Invite Admin
                </Toolbar.Button>
            )}
        </>
    );
};

interface UsersBulkSaveButtonProps {
    account?: IAccount;
    refetchAccountUsers: () => void;
    onSuccess: () => void;
}

const UsersBulkSaveButton: FC<UsersBulkSaveButtonProps> = ({
    account,
    refetchAccountUsers,
    onSuccess,
}) => {
    const { tableRows, tableUncheckAll, tableSetSelected } = useTableContext<AccountUserRow>();
    const { control } = useFormContext();
    const users = useWatch<BulkAccountUserData>({ control, name: 'users' });

    const { saveUsersMulti } = useSaveUsersMultiMutation(account! ?? ({} as IAccount));

    const updateCount = useMemo(
        () =>
            Object.values(users ?? {}).reduce<number>((subTotal, user) => {
                if ((user as any).updated === 'true') {
                    return subTotal + 1;
                }

                return subTotal;
            }, 0),
        [users],
    );

    const onSaveBulk = useCallback((formData: { users: BulkAccountUserData }) => {
        const tableRowsMap = keyBy(tableRows, 'flexibleEmployeeId');

        const bulkDataValues = Object.values(formData.users)
            .map(({ updated, key, ...userData }) => {
                const initialUser = tableRowsMap[key];

                const { firstName, lastName, role, email, phoneNumber } = userData;

                return updated === 'true'
                    ? omitBy(
                          {
                              flexibleEmployeeId: initialUser?.flexibleEmployeeId,
                              userId: initialUser?.userId,
                              posEmployeeProfileIds: initialUser?.posEmployeeProfileIds,
                              firstName,
                              lastName,
                              role,
                              email,
                              phoneNumber,
                          } as Partial<BulkAccountUserData>,
                          isUndefined,
                      )
                    : undefined;
            })
            .filter((userData) => userData) as Partial<BulkAccountUserData>[];

        const invalidPhoneNumberEntries = bulkDataValues.filter(({ phoneNumber }) => {
            return phoneNumber && !isValidPhoneNumber(phoneNumber);
        });

        if (invalidPhoneNumberEntries.length) {
            const errorMsg =
                invalidPhoneNumberEntries.length === 1
                    ? '1 user has an invalid phone number entered'
                    : `${invalidPhoneNumberEntries.length} users have invalid phone numbers entered`;
            toast.error(errorMsg);
        } else {
            saveUsersMulti(
                { bulkUserData: bulkDataValues },
                {
                    onSuccess: () => {
                        onSuccess();
                        tableUncheckAll();
                    },
                    onError: (err) => {
                        if (
                            err instanceof SaveAccountUsersMultiError &&
                            err.data.unsavedUserData.length
                        ) {
                            const newBulkEditData: BulkEditUserData = {};
                            const newSelectedRowKeys: string[] = [];

                            err.data.unsavedUserData.forEach((userData) => {
                                newSelectedRowKeys.push(userData.flexibleEmployeeId!);
                                newBulkEditData[userData.flexibleEmployeeId!] = userData;
                            });

                            tableSetSelected((prevSelected: string[]) => {
                                if (prevSelected.length) {
                                    return prevSelected.filter((rowKey) =>
                                        newSelectedRowKeys.includes(rowKey),
                                    );
                                }

                                return [];
                            });
                        }
                    },
                    onSettled: () => {
                        refetchAccountUsers();
                    },
                },
            );
        }
    }, []);

    return (
        <Toolbar.Group>
            <Form.Button startIcon={<SaveAlt />} color="blue" variant="smooth" onClick={onSaveBulk}>
                {`Save (${updateCount})`}
            </Form.Button>
        </Toolbar.Group>
    );
};

const UserRowActions = ({
    row,
    onShowEditUser,
    onShowResetPassword,
    onResendEnrollmentText,
    onSetInactive,
}: {
    row: UserRow;
    onShowEditUser: (row: UserRow) => void;
    onShowDeleteUser: (row: UserRow) => void;
    onShowResetPassword: (row: UserRow) => void;
    onResendEnrollmentText: (row: UserRow) => void;
    onSetInactive: (row: UserRow) => void;
}) => {
    const { user } = useApp();
    const { account } = useSparkplugAccount();

    const isCCView = !account?.type;
    const userId = isAccountUser(account?.type, row) ? row.userId : row._id;

    return (
        <Table.Cell>
            <Dropdown className="user-dropdown">
                <Dropdown.IconButton data-testid={`user-dropdown.${row.key}`}>
                    <MoreHoriz />
                </Dropdown.IconButton>
                <Dropdown.Menu data-testid={`user-dropdown-menu.${row.key}`}>
                    <Dropdown.MenuItem
                        className="user-dropdown_edit-menu-item"
                        StartIcon={Edit}
                        onClick={() => onShowEditUser(row)}
                    >
                        Edit user
                    </Dropdown.MenuItem>
                    <Dropdown.MenuItem
                        hidden={row.email == null}
                        StartIcon={Send}
                        onClick={() => onShowResetPassword(row)}
                    >
                        Re-send reset password email
                    </Dropdown.MenuItem>
                    <Dropdown.MenuItem
                        hidden={
                            isCCView ||
                            userId == null ||
                            row.smsStatus === 'enrolled' ||
                            row.phoneNumber == null ||
                            row.role === 'none'
                        }
                        StartIcon={Send}
                        onClick={() => onResendEnrollmentText(row)}
                    >
                        Re-send enrollment text
                    </Dropdown.MenuItem>

                    {!(isCCView || row.role === 'none') && <Dropdown.MenuDivider />}

                    <Dropdown.MenuItem
                        hidden={isCCView || row.role === 'none'}
                        className="user-dropdown_set-inactive-menu-item"
                        StartIcon={RemoveCircleOutline}
                        color={user?.role !== 'super-admin' ? 'red' : 'yellow'}
                        onClick={() => {
                            onSetInactive(row);
                        }}
                    >
                        Set inactive
                    </Dropdown.MenuItem>
                </Dropdown.Menu>
            </Dropdown>
        </Table.Cell>
    );
};

const ContactIcon = ({ variant, tooltip }: { variant: 'email' | 'phone'; tooltip?: string }) => {
    const disabled = tooltip == null;

    const contactComponent =
        variant === 'email' ? (
            <CustomEmailIcon className={disabled ? 'disabled' : ''} />
        ) : (
            <CustomSmsIcon className={disabled ? 'disabled' : ''} />
        );

    if (tooltip == null) {
        return contactComponent;
    }

    return (
        <Tooltip title={tooltip}>
            <div>{contactComponent}</div>
        </Tooltip>
    );
};

const LocationStrDisplay = ({ locationStr }: { locationStr?: string }) => {
    if (!locationStr) {
        return <span>--</span>;
    }

    const locationsArr = locationStr.split(',');

    return <PosEmployeeProfileLocationsLabel locationNames={locationsArr} />;
};

const YouChip = () => <Chip dense className="name-chip" label="You" color="blue" />;
const OwnerChip = () => <Chip color="blue" label="Owner" />;

export const UserTableHeadCells: { [key: string]: THeadCell<UserRow> } = {
    checkbox: {
        id: 'select',
        type: 'checkbox',
        render: (row) => {
            const { tableSelected: selected, tableCheckUncheckRow } = useTableContext();

            return (
                <Table.Cell scope="row">
                    <Table.Checkbox
                        disabled={row?.selectionDisabled}
                        value={selected.includes(row.key)}
                        onChange={() => tableCheckUncheckRow(row)}
                    />
                </Table.Cell>
            );
        },
    },
    fullName: {
        id: 'fullName',
        sortType: 'string',
        label: 'Name',
        render: (row) => {
            const { user, appIsMobile } = useApp();
            const { account } = useSparkplugAccount();
            const rowIsManager = isAccountUser(account?.type, row)
                ? !!row.managedLocationIds?.length
                : false;
            const rowIsAuthUser = isAccountUser(account?.type, row)
                ? user?._id === row.userId
                : user?._id === row._id;
            const sparkplugUserRole = SparkPlugUserRoles.find(
                (role) => role.value === row.role,
            )?.label;

            let roleString = sparkplugUserRole ?? '--';
            if (row.isOwner && account?.type === 'retailer') {
                roleString = 'Owner';
            } else if (isAccountUser(account?.type, row)) {
                if (!['group-member', 'group-admin'].includes(row.groupRole ?? '')) {
                    roleString = '--';
                } else {
                    roleString = row.groupRole === 'group-admin' ? 'Admin' : 'User';
                }
            }

            return (
                <Table.Cell align="left">
                    <div>
                        {row.firstName} {row.lastName}
                        {rowIsManager && <ManagerChip />}
                        {rowIsAuthUser && <YouChip />}
                    </div>
                    {appIsMobile && <div>{roleString}</div>}
                </Table.Cell>
            );
        },
    },
    // Note: fullName above is used for display - but firstName and lastName are also present for bulk edit operations
    firstName: {
        id: 'firstName',
        sortType: 'string',
        label: 'First Name',
        render: (row) => {
            const { user } = useApp();
            const { account } = useSparkplugAccount();
            const rowIsManager = isAccountUser(account?.type, row)
                ? !!row.managedLocationIds?.length
                : false;
            const rowIsAuthUser = isAccountUser(account?.type, row)
                ? user?._id === row.userId
                : user?._id === row._id;

            return (
                <Table.Cell align="left">
                    {row.firstName}
                    {rowIsManager && <ManagerChip />}
                    {rowIsAuthUser && <YouChip />}
                </Table.Cell>
            );
        },
    },
    lastName: {
        id: 'lastName',
        sortType: 'string',
        label: 'Last Name',
        render: ({ lastName }) => <Table.Cell align="left">{lastName}</Table.Cell>,
    },
    locationStr: {
        id: 'locationStr',
        sortType: 'string',
        label: 'Location(s)',
        render: ({ locationStr }) => (
            <Table.Cell align="left">
                <LocationStrDisplay locationStr={locationStr} />
            </Table.Cell>
        ),
    } as THeadCell<AccountUserRow> as THeadCell<UserRow>,
    role: {
        id: 'roleFormatted',
        sortType: 'string',
        label: 'Type',
        render: (row) => {
            const { account } = useSparkplugAccount();
            const isAccountOwner = row.isOwner;

            const sparkplugUserRole = SparkPlugUserRoles.find(
                (role) => role.value === row.role,
            )?.label;
            return (
                <Table.Cell className="no-wrap" align="left">
                    {isAccountOwner && account?.type === 'retailer' ? (
                        <OwnerChip />
                    ) : (
                        <>
                            {isAccountUser(account?.type, row) ? (
                                <UserGroupRoleChip
                                    account={account!}
                                    groupRole={row.groupRole}
                                    externalGroups={row.externalGroups}
                                />
                            ) : (
                                sparkplugUserRole ?? '--'
                            )}
                        </>
                    )}
                </Table.Cell>
            );
        },
    },
    lastTransactionDaysAgo: {
        id: 'lastTransactionDaysAgo',
        sortType: 'numeric',
        label: 'Last Transaction',
        render: ({ lastTransactionDaysAgoStr }) => (
            <Table.Cell align="left">{lastTransactionDaysAgoStr ?? '--'}</Table.Cell>
        ),
    } as THeadCell<AccountUserRow> as THeadCell<UserRow>,
    contact: {
        id: 'contact',
        label: 'Contact',
        render: ({ email, phoneNumber, phoneNumberFormatted }) => {
            return (
                <Table.Cell align="left">
                    <div className="contacts">
                        <ContactIcon variant="email" tooltip={email} />

                        <ContactIcon
                            variant="phone"
                            tooltip={
                                // We need to check if `phoneNumber` exists because
                                // `phoneNumberFormatted` is defaulted to '--' upstream
                                // so it will always return a truthy value
                                phoneNumber && phoneNumberFormatted
                            }
                        />
                    </div>
                </Table.Cell>
            );
        },
    },
    smsStatus: {
        id: 'smsStatusFormatted',
        sortType: 'string',
        label: 'SMS Status',
        render: ({ smsStatus, muteScheduledSms }) => (
            <Table.Cell align="left">
                <SMSEnrollmentChip status={smsStatus} isMuted={muteScheduledSms} />
            </Table.Cell>
        ),
    },
    groupName: {
        id: 'groupName',
        sortType: 'string',
        label: 'Account',
        render: (row) => <Table.Cell align="left">{row.groupName || '--'}</Table.Cell>,
    } as THeadCell<PublicUserRow> as THeadCell<UserRow>,
    email: {
        id: 'email',
        sortType: 'string',
        label: 'Email',
        render: (row) => <Table.Cell align="left">{row.email || '--'}</Table.Cell>,
    },
    phoneNumber: {
        id: 'phoneNumber',
        sortType: 'string',
        label: 'Phone Number',
        render: (row) => <Table.Cell align="left">{row.phoneNumberFormatted}</Table.Cell>,
    },
};

export const UserTableEditHeadCells: { [key: string]: THeadCell<UserRow> } = {
    updateStatus: {
        id: 'updateStatus',
        render: (row, updateRow) => {
            const { tableRows } = useTableContext<UserRow>();
            const { register, setValue } = useFormContext();

            const initialRow = tableRows.find(({ key }) => key === row.key);
            const isUpdated = useMemo(
                () =>
                    ['firstName', 'lastName', 'role', 'phoneNumber', 'email'].some(
                        (property) =>
                            row[property as keyof UserRow] !==
                            initialRow?.[property as keyof UserRow],
                    ),
                [row, initialRow],
            );

            useEffect(() => {
                setValue(`users[${row.key}][updated]`, isUpdated);
            }, [isUpdated]);

            return (
                <Table.Tooltip
                    title={
                        isUpdated
                            ? 'This user will be saved. Click to remove.'
                            : 'This row uses existing or default info. Update this row or this user will not be saved.'
                    }
                >
                    <Table.Cell align="left">
                        <input
                            type="hidden"
                            name={`users[${row.key}][updated]`}
                            value={String(isUpdated)}
                            ref={register}
                        />
                        <input
                            type="hidden"
                            name={`users[${row.key}][key]`}
                            value={row.key}
                            ref={register}
                        />
                        {isUpdated ? (
                            <Done
                                className="success-text"
                                onClick={() => updateRow(initialRow ?? row)}
                            />
                        ) : (
                            <WarningOutlined className="warning-text" />
                        )}
                    </Table.Cell>
                </Table.Tooltip>
            );
        },
    },
    firstName: {
        id: 'firstName',
        sortType: 'string',
        label: 'First Name',
        render: (row, updateRow) => {
            return (
                <Table.Cell align="left">
                    <Form.TextField
                        className="email-text-field"
                        placeholder="First Name"
                        disabled={row.isOwner}
                        name={`users[${row.key}][firstName]`}
                        required
                        defaultValue={row.firstName}
                        onChange={(event) =>
                            updateRow(
                                (prevRow) =>
                                    ({
                                        ...prevRow,
                                        firstName: event.target.value,
                                    } as UserRow),
                            )
                        }
                    />
                </Table.Cell>
            );
        },
    },
    lastName: {
        id: 'lastName',
        sortType: 'string',
        label: 'Last Name',
        render: (row, updateRow) => {
            return (
                <Table.Cell align="left">
                    <Form.TextField
                        className="email-text-field"
                        placeholder="Last Name"
                        name={`users[${row.key}][lastName]`}
                        required
                        disabled={row.isOwner}
                        defaultValue={row.lastName}
                        onChange={(event) =>
                            updateRow(
                                (prevRow) =>
                                    ({
                                        ...prevRow,
                                        lastName: event.target.value,
                                    } as UserRow),
                            )
                        }
                    />
                </Table.Cell>
            );
        },
    },
    role: {
        id: 'role',
        sortType: 'string',
        label: 'Type',
        render: (row, updateRow) => {
            const { account } = useSparkplugAccount();
            const isCCView = !account?.type;
            const isOwner = row.isOwner;
            const options = isCCView ? SparkPlugUserRoles : AccountUserRoles;
            if (isOwner) {
                options.push({ label: 'Owner', value: 'owner' });
            }

            return (
                <Table.Cell className="no-wrap" align="left">
                    <Form.Select
                        name={`users[${row.key}][role]`}
                        defaultValue={isOwner ? 'owner' : row.role ?? 'group-member'}
                        disabled={isOwner}
                        options={options}
                        onChange={(event) =>
                            updateRow(
                                (prevRow) =>
                                    ({
                                        ...prevRow,
                                        role: event.target.value,
                                    } as UserRow),
                            )
                        }
                    />
                </Table.Cell>
            );
        },
    },
    email: {
        id: 'email',
        sortType: 'string',
        label: 'Email',
        render: (row, updateRow) => {
            return (
                <Table.Cell align="left">
                    <Form.TextField
                        className="email-text-field"
                        placeholder="Email"
                        name={`users[${row.key}][email]`}
                        disabled={
                            ['group-member', 'none'].includes(row.role ?? '') ||
                            typeof row.role === 'undefined' ||
                            row.isOwner
                        }
                        required={row.role === 'group-admin'}
                        defaultValue={row.email}
                        onChange={(event) =>
                            updateRow(
                                (prevRow) =>
                                    ({
                                        ...prevRow,
                                        email: event.target.value,
                                    } as UserRow),
                            )
                        }
                        error={!!(row.role === 'group-admin' && !row.email)}
                    />
                </Table.Cell>
            );
        },
    },
    phoneNumber: {
        id: 'phoneNumber',
        sortType: 'string',
        label: 'Phone Number',
        render: (row, updateRow) => {
            return (
                <Table.Cell align="left">
                    <Form.PhoneField
                        defaultValue={row.phoneNumber}
                        placeholder="Phone Number"
                        name={`users[${row.key}][phoneNumber]`}
                        disabled={
                            row.role === 'none' || !row.posEmployeeProfileIds?.length || row.isOwner
                        }
                        onChange={(event) =>
                            updateRow(
                                (prevRow) =>
                                    ({
                                        ...prevRow,
                                        phoneNumber: event.target.value,
                                    } as UserRow),
                            )
                        }
                        error={!!(row.phoneNumber && !isValidPhoneNumber(row.phoneNumber))}
                    />
                </Table.Cell>
            );
        },
    },
};

const TableBulkDeleteUsersModal: FC<ComponentProps<typeof DeleteUsersModal>> = ({
    account,
    isVisible,
    users,
    onClose,
}) => {
    const { tableUncheckAll } = useTableContext();

    return (
        <DeleteUsersModal
            account={account}
            isVisible={isVisible}
            users={users}
            onClose={(refetchUsers) => {
                onClose(refetchUsers);
                if (refetchUsers) {
                    tableUncheckAll();
                }
            }}
        />
    );
};

export const SelectedCountLabel = ({ selectedIds }: { selectedIds: string[] }) => {
    if (isEmpty(selectedIds)) {
        return null;
    }

    return <span className="users-table_selected-label">{`${selectedIds.length} Selected`}</span>;
};

interface BulkEditUserData {
    [rowKey: string]: Partial<BulkAccountUserData>;
}

type AccountUserRow = IAccountUser & {
    key: string;
    isOwner?: boolean;
};
export type PublicUserRow = IPublicUser & {
    key: string;
    isOwner?: boolean;
    phoneNumberFormatted?: string;
    groupName?: string;
};
type UserRow = AccountUserRow | PublicUserRow;

interface UsersTableProps {
    variant?: 'raised' | 'flat';
    account?: IAccount;
    isLoadingAccountUsers: boolean;
    accountUsers: UserRow[];
    refetchAccountUsers: () => void;
    headCells: THeadCell<UserRow>[];
    bulkEditHeadCells?: THeadCell<UserRow>[];
    searchFields: string[];
    filters?: AccountUserFilters;
}

const UsersTable: FC<UsersTableProps> = ({
    variant = 'raised',
    account,
    isLoadingAccountUsers,
    accountUsers: _accountUsers,
    refetchAccountUsers,
    headCells,
    bulkEditHeadCells = [],
    searchFields,
    filters,
}) => {
    const { appIsMobile } = useApp();
    const [showBulkEdit, setShowBulkEdit] = useState(false);
    const [showCreateEditDrawer, setShowCreateEditDrawer] = useState(false);
    const [showCreateEditUserModalCC, setShowCreateEditUserModalCC] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDeleteMultiModal, setShowDeleteMultiModal] = useState(false);
    const [currentEditUser, setCurrentEditUser] = useState<
        IPublicUser | IAccountUser | IPublicUser[] | IAccountUser[]
    >();
    const [showConfirmInactiveModal, setShowConfirmInactiveModal] = useState<boolean>(false);
    const [showBulkSMSModal, setShowBulkSMSModal] = useState<boolean>(false);
    const { userCan } = useSparkplugAccount();
    const userCanManageUsers = userCan('manageUsers');

    const { enrollUser } = useEnrollUser();
    const { unenrollAndSetInactive: setInactive } = useUnenrollAndSetInactiveMutation(
        account?._id as string,
    );
    const { sendResetPasswordEmailRequest } = useSendResetPasswordEmailRequest();

    const formatRoleForSort = (accountUser: any) => {
        if (isAccountUser(account?.type, accountUser)) {
            if (!['group-member', 'group-admin'].includes(accountUser.groupRole ?? '')) {
                return '--';
            } else {
                return accountUser.groupRole === 'group-member' ? 'user' : 'admin';
            }
        } else {
            return (
                SparkPlugUserRoles.find((role) => role.value === accountUser.role)?.label ?? '--'
            );
        }
    };

    const accountUsers = useMemo<UserRow[]>(
        () =>
            account
                ? (_accountUsers as IAccountUser[]).map((accountUser) => {
                      const isOwner =
                          account?.assignedOwnerUserId !== undefined &&
                          accountUser?.userId === account?.assignedOwnerUserId;
                      const smsStatusFormatted =
                          SMSStatuses.find((obj) => accountUser.smsStatus === obj.value)?.label ??
                          'none';

                      // this is not pretty, but works to sort the table by our complicated role display logic
                      const roleFormatted =
                          isOwner && account?.type === 'retailer'
                              ? 'owner'
                              : formatRoleForSort(accountUser) ?? '--';

                      return {
                          ...accountUser,
                          key: accountUser.flexibleEmployeeId,
                          // necessary to sort by SmsStatus in the table as we use some custom formatting
                          smsStatusFormatted,
                          // necessary to sort by Type in the table as we use some custom formatting
                          roleFormatted,
                          isOwner,
                          posEmployeeProfileNames: accountUser.posEmployeeProfiles
                              ?.map(({ fullName }) => fullName)
                              ?.join('|'),
                      };
                  })
                : (_accountUsers as IPublicUser[]).map((accountUser) => {
                      const smsStatusFormatted =
                          SMSStatuses.find((obj) => accountUser.smsStatus === obj.value)?.label ??
                          'none';
                      const roleFormatted = formatRoleForSort(accountUser) ?? '--';
                      return {
                          ...accountUser,
                          key: accountUser._id,
                          smsStatusFormatted,
                          roleFormatted,
                          isOwner: false,
                      };
                  }),
        [account, _accountUsers],
    );

    const { userFilters, updateUserFilters, applyUserFilters } = useAccountUsersFilters({
        status: 'all',
        role: 'all',
        location: 'all',
    });

    const { searchFilter, onChangeSearchFilter, applySearch } = useSearch(searchFields);

    const locationOptions = useMemo(() => {
        const options = account?.locations || [];

        return [...Locations, ...options];
    }, [account?.locations]);

    const typesOptions = useMemo(() => {
        if (['retailer', 'brand'].includes(account?.type ?? '')) {
            const hasInactive =
                account?.roles != null
                    ? Object.values(account?.roles).some((role) => role === 'none')
                    : false;

            if (!hasInactive) {
                return AccountRoleFilterOptions.filter(
                    ({ value }) => !['active', 'none'].includes(value),
                );
            }

            // Add `manager` option for retailers
            if (account?.type === 'retailer') {
                return [
                    ...AccountRoleFilterOptions.slice(0, 2),
                    { value: 'manager', label: 'Manager' },
                    ...AccountRoleFilterOptions.slice(2),
                ];
            }

            return AccountRoleFilterOptions;
        }

        if (account?.type == null) {
            return SparkPlugRoleFilterOptions;
        }

        return [];
    }, [account]);

    useEffect(() => {
        if (filters) {
            updateUserFilters({
                ...filters,
                role: typesOptions.some(({ value }) => value === 'active') ? 'active' : 'all',
            });
        }
    }, [filters, typesOptions]);

    // In order to make sure that we are getting the latest saved user data, we need to find the user from the accountUsers array
    const getRowByKey = (key: string) => accountUsers.find((row) => row.key === key);

    const currentHeadCells = useMemo(() => {
        const finalHeadCells =
            showBulkEdit && !isEmpty(bulkEditHeadCells) ? bulkEditHeadCells : headCells;

        if (!userCanManageUsers) {
            const bulkSelectHeadCell = headCells.find((headCell) => headCell.id === 'select');
            if (bulkSelectHeadCell) {
                bulkSelectHeadCell.isHidden = true;
            }
        }

        return [
            ...finalHeadCells,
            ...(!showBulkEdit
                ? [
                      {
                          id: 'actions',
                          isHidden: !userCanManageUsers,
                          render: (_row) => (
                              <UserRowActions
                                  row={_row}
                                  onShowEditUser={(row) => {
                                      setCurrentEditUser(getRowByKey(row.key));
                                      if (isEmpty(account)) {
                                          setShowCreateEditUserModalCC(true);
                                      } else {
                                          setShowCreateEditDrawer(true);
                                      }
                                  }}
                                  onShowDeleteUser={(row) => {
                                      setCurrentEditUser(row);
                                      setShowDeleteModal(true);
                                  }}
                                  onShowResetPassword={(row) => {
                                      sendResetPasswordEmailRequest(
                                          isAccountUser(account?.type, row)
                                              ? row.userId ?? ''
                                              : row._id,
                                      );
                                  }}
                                  onResendEnrollmentText={(row) => {
                                      if (account && isAccountUser(account?.type, row)) {
                                          enrollUser(
                                              { accountId: account._id, userId: row.userId },
                                              { onSuccess: () => refetchAccountUsers() },
                                          );
                                      }
                                  }}
                                  onSetInactive={(row) => {
                                      if (row.isOwner) {
                                          toast.error(
                                              'Cannot set this member to inactive because they are the Account Owner',
                                          );
                                      } else if (account && isAccountUser(account?.type, row)) {
                                          setInactive(
                                              {
                                                  userId: row.userId,
                                                  userData: row,
                                              },
                                              {
                                                  onSuccess: () => refetchAccountUsers(),
                                              },
                                          );
                                      }
                                  }}
                              />
                          ),
                      } as THeadCell<UserRow>,
                  ]
                : []),
        ];
    }, [showBulkEdit, accountUsers]);

    const handleAdd = () => {
        setCurrentEditUser(undefined);
        if (isEmpty(account)) {
            // no account means a super admin is adding another super admin via the control center
            setShowCreateEditUserModalCC(true);
        } else {
            setShowCreateEditDrawer(true);
        }
    };

    const bulkActions: TableBulkAction[] | undefined =
        !showBulkEdit && account
            ? [
                  SelectedCountLabel,
                  {
                      startIcon: <CustomEditIcon />,
                      label: 'Edit',
                      onClick: () => {
                          setShowBulkEdit(true);
                      },
                  },
                  {
                      startIcon: <SendIcon />,
                      label: 'Re-send enrollment text',
                      shouldRender: (selectedIds) => {
                          if (account?.type !== 'retailer') {
                              return false;
                          }

                          // if user is enrolled or is missing a phone number it is ineligible for a re-enrollment sms
                          const hasIneligibleUsers = useMemo(() => {
                              return selectedIds.some((selectedIdKey) => {
                                  return accountUsers?.some((accountUser) => {
                                      return (
                                          accountUser?.key === selectedIdKey &&
                                          (accountUser?.smsStatus === 'enrolled' ||
                                              !accountUser?.phoneNumber)
                                      );
                                  });
                              });
                          }, [selectedIds, accountUsers]);
                          return !hasIneligibleUsers;
                      },
                      onClick: () => setShowBulkSMSModal(true),
                  },
                  {
                      startIcon: <RemovePersonIcon />,
                      label: 'Set Inactive',
                      shouldRender: (selectedIds) => {
                          if (account?.type !== 'retailer') {
                              return false;
                          }

                          const hasInactiveUsers = useMemo(() => {
                              return selectedIds.some((selectedIdKey) => {
                                  return accountUsers?.some((accountUser) => {
                                      return (
                                          accountUser?.key === selectedIdKey &&
                                          accountUser?.role === 'none'
                                      );
                                  });
                              });
                          }, [selectedIds, accountUsers]);

                          return !hasInactiveUsers;
                      },
                      onClick: () => setShowConfirmInactiveModal(true),
                  },
              ]
            : undefined;

    return (
        <div className="admin-employees-view">
            {isEmpty(accountUsers) && !isLoadingAccountUsers ? (
                <div className="empty-user-table">
                    <EmptyStateDisplay
                        graphic={<NoUsersGraphic />}
                        label="no users found"
                        actionButton={{
                            label: 'Create New Admin',
                            onClick: handleAdd,
                            startIcon: <Add />,
                        }}
                    />
                </div>
            ) : (
                <Form>
                    <TableProvider
                        showCheckboxes={!showBulkEdit && userCanManageUsers && !appIsMobile}
                        showBulkEditor={showBulkEdit}
                        headCells={currentHeadCells}
                        rows={accountUsers}
                        filters={[applyUserFilters, applySearch]}
                        defaultOptions={{
                            orderBy: 'firstName',
                        }}
                    >
                        <Toolbar scrollOnMobile>
                            {!showBulkEdit ? (
                                <>
                                    <Toolbar.Search
                                        name="search"
                                        defaultValue={searchFilter}
                                        onChange={onChangeSearchFilter}
                                    />
                                    {(account?.type === 'retailer' || account?.type == null) && (
                                        <>
                                            <Toolbar.Dropdown
                                                label={null}
                                                value={userFilters.role}
                                                onSelect={(value) =>
                                                    updateUserFilters({ role: value })
                                                }
                                                options={typesOptions}
                                                clear={{
                                                    active: userFilters.role !== 'all',
                                                    onClear: () =>
                                                        updateUserFilters({ role: 'all' }),
                                                }}
                                            />
                                            <Toolbar.Dropdown
                                                label={null}
                                                value={userFilters.status}
                                                onSelect={(value) =>
                                                    updateUserFilters({ status: value })
                                                }
                                                options={Status}
                                                clear={{
                                                    active: userFilters.status !== 'all',
                                                    onClear: () =>
                                                        updateUserFilters({ status: 'all' }),
                                                }}
                                            />
                                            {account?.type === 'retailer' && (
                                                <Toolbar.Dropdown
                                                    label={null}
                                                    className="toolbar-end"
                                                    value={userFilters.location}
                                                    onSelect={(value) =>
                                                        updateUserFilters({ location: value })
                                                    }
                                                    options={locationOptions}
                                                    clear={{
                                                        active: userFilters.location !== 'all',
                                                        onClear: () =>
                                                            updateUserFilters({ location: 'all' }),
                                                    }}
                                                />
                                            )}
                                        </>
                                    )}
                                    {account?.type === 'brand' && (
                                        <>
                                            <Toolbar.Dropdown
                                                label={null}
                                                value={userFilters.role}
                                                onSelect={(value) =>
                                                    updateUserFilters({ role: value })
                                                }
                                                options={typesOptions}
                                                clear={{
                                                    active: userFilters.role !== 'all',
                                                    onClear: () =>
                                                        updateUserFilters({ role: 'all' }),
                                                }}
                                            />
                                        </>
                                    )}
                                    <UserTableActionButtons
                                        accountUsers={accountUsers as AccountUserRow[]}
                                        onBulkDelete={(selectedUsers) => {
                                            setCurrentEditUser(selectedUsers);
                                            setShowDeleteMultiModal(true);
                                        }}
                                        onAdd={handleAdd}
                                    />
                                </>
                            ) : (
                                <>
                                    <UsersBulkSaveButton
                                        account={account}
                                        refetchAccountUsers={refetchAccountUsers}
                                        onSuccess={() => {
                                            setShowBulkEdit(false);
                                        }}
                                    />
                                    <Toolbar.Button
                                        startIcon={<Close />}
                                        color="neutral"
                                        variant="smooth"
                                        onClick={() => {
                                            setShowBulkEdit(false);
                                        }}
                                    >
                                        Cancel
                                    </Toolbar.Button>
                                </>
                            )}
                        </Toolbar>

                        <Table
                            useExternalProvider
                            showPagination={!showBulkEdit}
                            variant={variant || 'raised'}
                        >
                            <Table.RenderHead bulkActions={bulkActions} />
                            {!isLoadingAccountUsers ? (
                                <Table.RenderBody />
                            ) : (
                                <Table.Body>
                                    <Table.Loading
                                        rows={10}
                                        colSpan={currentHeadCells.length + (showBulkEdit ? 0 : 2)}
                                    />
                                </Table.Body>
                            )}
                        </Table>

                        <BulkSetInactiveConfirmModal
                            accountId={account?._id}
                            isVisible={showConfirmInactiveModal}
                            onClose={(isSuccess) => {
                                if (isSuccess) {
                                    refetchAccountUsers();
                                }
                                setShowConfirmInactiveModal(false);
                            }}
                        />
                        <BulkSendEnrollmentConfirmModal
                            accountId={account?._id}
                            isVisible={showBulkSMSModal}
                            onClose={(isSuccess) => {
                                if (isSuccess) {
                                    refetchAccountUsers();
                                }
                                setShowBulkSMSModal(false);
                            }}
                        />
                    </TableProvider>
                </Form>
            )}

            <CreateEditUserDrawer
                account={account ?? ({} as IAccount)}
                user={currentEditUser as IAccountUser}
                accountUsers={accountUsers as IAccountUser[]}
                isVisible={showCreateEditDrawer}
                onClose={(refetchUsers) => {
                    setCurrentEditUser(undefined);
                    setShowCreateEditDrawer(false);
                    if (refetchUsers) {
                        refetchAccountUsers();
                    }
                }}
            />

            {isEmpty(account) && (
                <CreateEditSparkplugUserModal
                    isVisible={showCreateEditUserModalCC}
                    user={currentEditUser as IPublicUser}
                    onClose={(refetchUsers) => {
                        setCurrentEditUser(undefined);
                        setShowCreateEditUserModalCC(false);
                        if (refetchUsers) {
                            refetchAccountUsers();
                        }
                    }}
                />
            )}

            <DeleteUserModal
                account={account!}
                isVisible={showDeleteModal}
                // `as any` is a workaround here, but we will most likely get rid of this component all together
                user={
                    currentEditUser && !Array.isArray(currentEditUser)
                        ? {
                              _id:
                                  (currentEditUser as IAccountUser).userId ??
                                  (currentEditUser as IPublicUser)._id,
                              firstName: (currentEditUser as IPublicUser | IAccountUser).firstName,
                          }
                        : undefined
                }
                onClose={(refetchUsers) => {
                    setShowDeleteModal(false);
                    if (refetchUsers) {
                        refetchAccountUsers();
                    }
                }}
            />

            <TableBulkDeleteUsersModal
                account={account}
                isVisible={showDeleteMultiModal}
                users={currentEditUser as IAccountUser[] | IPublicUser[]}
                onClose={(refetchUsers) => {
                    setShowDeleteMultiModal(false);
                    if (refetchUsers) {
                        refetchAccountUsers();
                    }
                }}
            />
        </div>
    );
};

const WrappedUsersTable = ({ accountUsers = [], ...props }: UsersTableProps) => {
    const { account } = props;
    // Filter unmatched users out of the UsersTable rows
    const filteredAccountUsers = account
        ? (accountUsers as IAccountUser[]).filter((user) => user?.role !== 'unmatched')
        : accountUsers;
    return <UsersTable {...props} accountUsers={filteredAccountUsers} />;
};

export default WrappedUsersTable;
