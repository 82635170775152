import { FC } from 'react';

import { Spark } from '@sparkplug/lib';

import {
    MultiRetailerReviewTabs,
    SelectedRetailerPosData,
} from '@features/spark-wizard/components/MultiRetailerReviewTabs';

import SparkAwardsPanel from '@components/sparks/SparkAwardsPanel';
import SparkDetailCard from '@components/sparks/SparkDetailCard';
import SparkDetailsCard from '@components/sparks/SparkDetailsCard';
import SparkProductsDetailCard from '@components/sparks/SparkProductsDetailCard';

import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import SparkWizardSnapPanel from '../SparkWizardSnapPanel';

import './LeaderboardReview.scss';

interface LeaderboardReviewProps {
    spark: Spark;
    sparkPosData: SelectedRetailerPosData;
    reviewDataIsReady: boolean;
}
const LeaderboardReview: FC<LeaderboardReviewProps> = ({
    spark,
    sparkPosData,
    reviewDataIsReady,
}) => {
    const { brands, locations, participants, categories, associatedProducts, products } =
        sparkPosData;

    const brandsRows =
        brands != null && brands.length > 0
            ? brands
            : [
                  {
                      label: 'All Brands',
                  },
              ];

    const categoriesRows =
        categories != null && categories.length > 0
            ? categories
            : [
                  {
                      label: 'All Categories',
                  },
              ];

    const isLoading = !reviewDataIsReady;

    return (
        <div className="container form-container review-container">
            <SparkDetailsCard spark={spark} />

            <SparkWizardSnapPanel />

            <SparkAwardsPanel spark={spark} />

            <SparkDetailCard title="Locations" rows={locations} isLoading={isLoading} />

            <SparkDetailCard title="Participants" rows={participants} isLoading={isLoading} />

            <SparkDetailCard title="Brands" rows={brandsRows} isLoading={isLoading} />

            <SparkDetailCard title="Categories" rows={categoriesRows} isLoading={isLoading} />

            <SparkProductsDetailCard
                associatedProducts={associatedProducts}
                selectedProducts={products}
                isLoading={isLoading}
            />
        </div>
    );
};

export default () => {
    const { spark, sparkPosData, sparkPosDataIsReady, isCreatingMultiRetailerSpark } = useSpark();

    if (isCreatingMultiRetailerSpark) {
        return (
            <MultiRetailerReviewTabs>
                {({ selectedRetailerPosData, reviewDataIsReady }) => (
                    <LeaderboardReview
                        spark={spark}
                        sparkPosData={selectedRetailerPosData}
                        reviewDataIsReady={reviewDataIsReady}
                    />
                )}
            </MultiRetailerReviewTabs>
        );
    }

    return (
        <LeaderboardReview
            spark={spark}
            sparkPosData={sparkPosData}
            reviewDataIsReady={sparkPosDataIsReady}
        />
    );
};
