import { useHistory } from 'react-router';

import { AnimatedCheckmarkGraphic } from '@components/graphics';
import { SparkplugLogo } from '@components/logos';
import Modal from '@components/overlays/Modal';

import './CodeVerifiedOverlay.scss';

interface ICodeVerifiedOverlayProps {
    isVisible: boolean;
    redirectTo: string;
}

const CodeVerifiedOverlay = ({ isVisible, redirectTo }: ICodeVerifiedOverlayProps) => {
    const history = useHistory();

    return (
        <Modal className="code-verified-overlay" isVisible={isVisible} onClose={() => {}}>
            <Modal.Content>
                <div className="sparkplug-logo">
                    <SparkplugLogo />
                </div>
                <AnimatedCheckmarkGraphic onComplete={() => history.push(redirectTo)} />
            </Modal.Content>
        </Modal>
    );
};

export default CodeVerifiedOverlay;
