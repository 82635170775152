import { CSSProperties } from 'react';

import { createTheme as createMuiTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface TypographyVariants {
        displayMedium: CSSProperties;
        displaySmall: CSSProperties;
        body: CSSProperties;
    }
    interface TypographyVariantsOptions {
        displayMedium?: CSSProperties;
        displaySmall?: CSSProperties;
        body?: CSSProperties;
    }

    interface Palette {
        gray0: string;
        gray5: string;
        gray10: string;
        gray20: string;
        gray30: string;
        gray40: string;
        gray50: string;
        gray60: string;
        gray70: string;
        gray80: string;
        gray90: string;
        gray95: string;
        gray100: string;

        errorMessageRed: string;
    }

    interface PaletteOptions {
        gray0?: string;
        gray5?: string;
        gray10?: string;
        gray20?: string;
        gray30?: string;
        gray40?: string;
        gray50?: string;
        gray60?: string;
        gray70?: string;
        gray80?: string;
        gray90?: string;
        gray95?: string;
        gray100?: string;
        gray200?: string;

        errorMessageRed?: string;
    }
}
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        displayMedium: true;
        displaySmall: true;
        body: true;
    }
}
export const muiTheme = createMuiTheme({
    palette: {
        gray0: '#f8fafe',
        gray5: '#e9edf3',
        gray10: '#dbe0e9',
        gray20: '#ccd3de',
        gray30: '#b9c3d1',
        gray40: '#a7b3c4',
        gray50: '#94a3b7',
        gray60: '#838e9e',
        gray70: '#727a86',
        gray80: '#62676e',
        gray90: '#40454b',
        gray95: '#20252b',
        gray100: '#000000',

        errorMessageRed: '#d32f2f',
    },
    typography: {
        displayMedium: {
            fontSize: '1.86rem',
            lineHeight: '2.28rem',
            fontWeight: 400,
        },
        displaySmall: {
            fontSize: '1.43rem',
            lineHeight: '2rem',
            fontWeight: 400,
        },
        body: {
            fontSize: '1rem',
            fontWeight: '400',
            lineHeight: '1.43rem',
            letterSpacing: 0.24,
        },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    // Map the new variant to render a <h1> by default
                    displayMedium: 'h3',
                    displaySmall: 'h3',
                    body: 'p',
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    '&.Mui-active': {
                        color: '#4ba0b5',
                    },
                    '&.Mui-completed': {
                        color: '#4ba0b5',
                        fill: '#2c3e53',
                    },
                },
            },
        },
    },
});
