import { Spark } from '@sparkplug/lib';

import { SparkTemplate } from '@features/spark-dashboard/types';

import { useApp } from '@hooks/AppHooks';
import { useSparkLocations } from '@hooks/SparksHooks/SparksHooks';

import { getDetailedSparkType } from '@helpers/sparks';

export const usePayoutCalloutMessage = (spark: Spark | SparkTemplate) => {
    const { userIsAdmin, isAdminApp } = useApp();
    const detailedSparkType = getDetailedSparkType(spark);
    const sparkLocations = useSparkLocations();

    const isManagerGoal = detailedSparkType === 'goalManager';
    const isTeamGoal = spark.type === 'goal' && spark.goalType === 'team';
    const isLocationLeaderboard = detailedSparkType === 'leaderboardLocation';
    const isMultiLeaderboard = detailedSparkType === 'leaderboardMulti';

    if (!spark.splitPayout && (userIsAdmin || !isAdminApp)) {
        return undefined;
    }

    if (isMultiLeaderboard) {
        const locationCount = sparkLocations.length;
        // '1 time', '2 times', etc
        const payoutCountText = `${locationCount} time${locationCount === 1 ? '' : 's'}`;
        return (
            <>
                <span>{`Prizes below will be awarded `}</span>
                <strong>{payoutCountText}</strong>
                <span>, once for each set of leaderboard winners.</span>
            </>
        );
    }

    if (isTeamGoal || isManagerGoal || isLocationLeaderboard) {
        return spark.splitPayout ? (
            <>
                Prize <strong>split</strong> evenly among participants
            </>
        ) : (
            <>
                Prize awarded in <strong>total</strong> to each participant
            </>
        );
    }

    return undefined;
};
