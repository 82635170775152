import axios from 'axios';

import { CreateRequestForSparkRequestBody } from '@sparkplug/lib';

import { useAdvancedMutation } from '@hooks/QueryHooks';

const API = {
    createRequestForSpark: async (payload: CreateRequestForSparkRequestBody) => {
        return (
            await axios.post<CreateRequestForSparkRequestBody, { data: any }>(
                `/api/v1/request-for-spark`,
                payload,
            )
        ).data;
    },
    ignoreRequestForSpark: async (requestForSparkId: string) => {
        return (await axios.put(`/api/v1/request-for-spark/${requestForSparkId}/ignore`)).data;
    },
};

export const useCreateRequestForSparkMutation = () => {
    const { mutateAsync, isLoading } = useAdvancedMutation(
        (payload: CreateRequestForSparkRequestBody) => API.createRequestForSpark(payload),
        {
            updateQuery: { queryKey: 'requestForSpark' },
            toastOptions: {
                loading: 'Sending request...',
                success: 'Spark request sent!',
                error: 'Something went wrong.',
            },
        },
    );

    return {
        isCreatingRequestForSpark: isLoading,
        createRequestForSparkAsync: mutateAsync,
    };
};

export const useIgnoreRequestForSparkMutation = () => {
    const { mutateAsync, isLoading } = useAdvancedMutation(
        (requestForSparkId: string) => API.ignoreRequestForSpark(requestForSparkId),
        {
            updateQuery: { queryKey: 'requestForSpark' },
            toastOptions: {
                loading: 'Ignoring request...',
                success: 'Request ignored!',
                error: 'Something went wrong.',
            },
        },
    );

    return {
        isIgnoringRequestForSpark: isLoading,
        ignoreRequestForSparkAsync: mutateAsync,
    };
};
