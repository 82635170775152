import { FC, ReactElement, ReactNode } from 'react';

import { times } from 'lodash';

import { appendClasses } from '@helpers/ui';

import Skeleton from '../Skeleton';

import './MobileList.scss';

interface MobileListItemProps {
    label?: string | ReactElement;
    description?: string;
    startElement?: ReactElement;
    mainElement?: ReactElement;
    endElement?: ReactElement;
    className?: string;
    onClick?: () => void;
}
const MobileListItem: FC<MobileListItemProps> = ({
    label,
    description,
    startElement,
    mainElement,
    endElement,
    className = '',
    onClick,
}) => {
    const defaultCenterElement = (
        <>
            <span className="mobile-list-item_label">{label}</span>

            {!!description && <span className="mobile-list-item_description">{description}</span>}
        </>
    );

    const classNamesAppended = appendClasses(['mobile-list-item', className]);

    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        <li className={classNamesAppended} onClick={onClick} onKeyDown={onClick} role="button">
            {!!startElement && <section className="mobile-list-item_start">{startElement}</section>}

            <section className="mobile-list-item_content">
                {mainElement || defaultCenterElement}
            </section>

            {!!endElement && <section className="mobile-list-item_end">{endElement}</section>}
        </li>
    );
};

const MobileListSkeletonItem = () => (
    <MobileListItem
        startElement={<Skeleton width={48} height={48} />}
        mainElement={<Skeleton width="100%" height={48} />}
    />
);

interface MobileListProps {
    className?: string;
    header?: string;
    children: ReactNode;
    isLoading?: boolean;
}
const MobileList = ({ header, className = '', children, isLoading = false }: MobileListProps) => {
    if (isLoading) {
        return (
            <div className={`mobile-list ${className} mobile-list_loading`.trim()}>
                <ul>{times(7, MobileListSkeletonItem)}</ul>
            </div>
        );
    }

    return (
        <div className={`mobile-list mobile-list_show-separator ${className}`.trim()}>
            <span className="mobile-list-header">{header}</span>
            <ul>{children}</ul>
        </div>
    );
};

MobileList.Item = MobileListItem;
export default MobileList;
