import { FC, ReactNode, createContext, useContext, useMemo } from 'react';

import { Spark, SparkDisplayStatus } from '@sparkplug/lib';

import { useGetSparksQuery } from '@core/sparks/queries/GetSparksQuery';

import { useSparksFilters } from '@features/spark-dashboard/hooks/useSparkFilters';

import { useQueryParams } from '@components/router';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { noop } from '@helpers/util';

import { ISparksContext } from '@app/types/SparksTypes';

export const SparksContext = createContext<ISparksContext>({
    refetchSparks: noop,
    sparks: [],
    meta: {
        current: 0,
        limit: 0,
        offset: 0,
        total: 0,
    },
    sparksAreReady: false,
    sparksIsLoading: false,
    sparkFilters: {
        location: [],
        status: 'active',
        schedule: [],
        training: [],
        type: [],
        requestState: undefined,
    },
    updateSparkFilters: (sparks: Spark[]): Spark[] => sparks,
});

export const useSparks = () => useContext(SparksContext);

export const SparksProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { account, accountIsReady } = useSparkplugAccount();

    const { isAdminApp } = useApp();

    const {
        p = 0,
        limit = 10,
        orderBy = 'createdAt',
        order = 'desc',
    }: { p?: number; limit?: number; orderBy?: string; order?: string } = useQueryParams();

    const initialSparkStatus = useMemo(() => {
        // this is the last segment of the route
        const statusSegment = window.location.pathname.split('/').pop();
        if (
            statusSegment &&
            ['active', 'upcoming', 'complete', 'sent', 'requests', 'inbox'].includes(statusSegment)
        ) {
            if (statusSegment === 'inbox' || statusSegment === 'sent') return 'inbox/sent';
            if (statusSegment === 'requests') return 'inbox/requests';
            return statusSegment as SparkDisplayStatus;
        }
        return 'active';
    }, [isAdminApp]);

    const { sparkFilters, updateSparkFilters } = useSparksFilters({
        initialFilters: {
            location: [],
            type: [],
            status: initialSparkStatus,
            schedule: [],
            training: [],
            search: '',
        },
    });

    const {
        refetchSparks,
        sparksAreReady,
        sparks = [],
        meta,
        isLoading,
    } = useGetSparksQuery({
        account,
        sparkFilters: {
            status: sparkFilters.status === 'inbox/sent' ? 'inbox' : sparkFilters.status,
            offset: p * limit,
            limit: +limit || 10,
            types: sparkFilters.type.join(','),
            locationId: sparkFilters.location.map((l) => l.value).join(','),
            schedule: sparkFilters.schedule.join(','),
            training: sparkFilters.training.join(','),
            requestState: sparkFilters.requestState,
            orderBy,
            order,
            search: sparkFilters.search,
        },
        isAdminApp,
        accountIsReady,
    });

    const value: ISparksContext = useMemo(
        () => ({
            refetchSparks,
            sparksAreReady,
            sparks,
            meta,
            sparkFilters,
            updateSparkFilters,
            sparksIsLoading: isLoading,
        }),
        [sparksAreReady, refetchSparks, sparks, meta, updateSparkFilters, sparkFilters, isLoading],
    );

    return <SparksContext.Provider value={value}>{children}</SparksContext.Provider>;
};
