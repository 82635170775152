import moment from 'moment';

async function createHash(input: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

async function sha256(value: string) {
    return createHash(value);
}

export async function getSquareAuthURL(groupId: string) {
    const { REACT_APP_SQUARE_APP_ID, REACT_APP_API } = import.meta.env;

    const scopes = [
        'PAYMENTS_READ',
        'ORDERS_READ',
        'ITEMS_READ',
        'EMPLOYEES_READ',
        'MERCHANT_PROFILE_READ',
    ];
    const state = await sha256(`${groupId}${moment().format('YYYYMMDD')}`);

    const useProd = ['production', 'demo'].includes(String(REACT_APP_API));
    const query = new URLSearchParams({
        client_id: REACT_APP_SQUARE_APP_ID!,
        state,
        session: String(!useProd),
    });

    const domain = useProd ? 'squareup' : 'squareupsandbox';

    return `https://connect.${domain}.com/oauth2/authorize?${query.toString()}&scope=${scopes.join(
        '+',
    )}`;
}
