import { useMemo } from 'react';

import { DATE_DISPLAY_FORMAT } from '@constants/AppConstants';
import { difference, uniq, uniqBy } from 'lodash';
import moment from 'moment';

import { HydratedBrandProduct, Spark } from '@sparkplug/lib';

import { useVendorBrandRetailersProductsByBrandId } from '@features/account-links';

import Chip from '@components/chips/Chip';
import Table from '@components/table/Table';

import { formatSparkCommissionItemValue } from '@helpers/sparks';

import { IPosProduct } from '@app/types/PosTypes';
import { ISparkCommissionMap } from '@app/types/SparksTypes';
import { THeadCell } from '@app/types/TableTypes';

import './SparkCommissionPayouts/SparkCommissionPayouts.scss';

const getProductDiffProperties = ({
    addedProductIds,
    removedProductIds,
    productId,
}: {
    addedProductIds: string[];
    removedProductIds: string[];
    productId: string;
}): { wasAdded?: boolean; wasRemoved?: boolean } => {
    if (addedProductIds.includes(productId)) {
        return { wasAdded: true, wasRemoved: false };
    }

    if (removedProductIds.includes(productId)) {
        return { wasRemoved: true, wasAdded: false };
    }

    return { wasRemoved: false, wasAdded: false };
};

export const buildSparkProductRows = ({
    previousAssociatedProducts,
    associatedProducts,
    sparkCommissionMap,
    spark,
    isVendorTagsRulesBasedSpark,
}: {
    previousAssociatedProducts?: IPosProduct[] | HydratedBrandProduct[];
    associatedProducts: IPosProduct[] | HydratedBrandProduct[];
    sparkCommissionMap?: ISparkCommissionMap;
    spark: Spark;
    isVendorTagsRulesBasedSpark: boolean;
}) => {
    const hasPreviousProducts = !!previousAssociatedProducts?.length;
    const associatedProductIds = associatedProducts.map(({ _id }) => _id);
    const previousAssociatedProductIds = previousAssociatedProducts?.map(({ _id }) => _id);

    const addedProductIds = difference(associatedProductIds, previousAssociatedProductIds ?? []);
    const removedProductIds = difference(previousAssociatedProductIds ?? [], associatedProductIds);

    const tableProducts = hasPreviousProducts
        ? uniqBy([...associatedProducts, ...previousAssociatedProducts], ({ _id }) => _id)
        : associatedProducts;

    return tableProducts.map((product) => {
        const commissionItem =
            spark?.type === 'commission' ? sparkCommissionMap?.get(product?._id) : null;

        const productDiffProps = hasPreviousProducts
            ? getProductDiffProperties({
                  addedProductIds,
                  removedProductIds,
                  productId: product._id,
              })
            : undefined;

        const key: string = [
            product._id,
            productDiffProps?.wasAdded ?? false,
            productDiffProps?.wasRemoved ?? false,
            commissionItem?.value ?? 0,
        ].join('-');

        return {
            ...product,
            ...(productDiffProps ?? {}),
            key,
            label: product.name,
            brandNames: (product.brands || []).map(({ name }) => name).join(', '),
            categoryNames: (product.categories || []).map(({ name }) => name).join(', '),
            commissionItem,
            tags: isVendorTagsRulesBasedSpark ? (product as HydratedBrandProduct).tags : [],
            sparkType: spark.type,
        };
    });
};

type SparkProductRow = ReturnType<typeof buildSparkProductRows>[number];

const getHeadCells: (params: {
    spark: Spark;
    isVendorTagsRulesBasedSpark: boolean;
}) => THeadCell<SparkProductRow>[] = ({ spark, isVendorTagsRulesBasedSpark }) => {
    const headCells: THeadCell<SparkProductRow>[] = [
        {
            id: 'name',
            sortType: 'string',
            label: 'Product',
            render: ({ label, wasRemoved, wasAdded }) => {
                const chipColor = wasAdded ? 'green' : 'red';
                const chipLabel = wasAdded ? 'Added' : 'Removed';

                return (
                    <Table.Cell>
                        <span className="spark-product-table_product-name">{label}</span>
                        {(wasRemoved || wasAdded) && (
                            <Chip
                                className="product-diff-chip"
                                dense
                                color={chipColor}
                                label={chipLabel}
                            />
                        )}
                    </Table.Cell>
                );
            },
        },
        {
            id: 'brandNames',
            sortType: 'string',
            label: 'Brand',
            render: ({ brandNames }) => <Table.Cell>{brandNames}</Table.Cell>,
        },
        {
            id: 'categoryNames',
            sortType: 'string',
            label: 'Category',
            render: ({ categoryNames }) => <Table.Cell>{categoryNames}</Table.Cell>,
        },
        {
            id: 'commissionItem.value',
            sortType: 'numeric',
            label: 'Commission',
            isHidden: spark?.type !== 'commission',
            infoAfter:
                spark.fulfillmentTypes?.[0] === 'external'
                    ? 'Commissions fulfilled by Retailer'
                    : undefined,
            render: ({ commissionItem }) => (
                <Table.Cell>
                    {commissionItem ? formatSparkCommissionItemValue(commissionItem) : '--'}
                </Table.Cell>
            ),
        },
        {
            id: 'lastSoldAt',
            sortType: 'string',
            isHidden: !!spark?.archivedAt,
            label: 'Last Sold At',
            render: ({ lastSoldAt }) => (
                <Table.Cell>{moment(lastSoldAt).format(DATE_DISPLAY_FORMAT)}</Table.Cell>
            ),
        },
    ];
    return headCells;
};

interface ISparkProductsTableProps {
    spark: Spark;
    associatedProducts: IPosProduct[] | HydratedBrandProduct[];
    previousAssociatedProducts?: IPosProduct[] | HydratedBrandProduct[];
    sparkCommissionMap?: ISparkCommissionMap;
    paginated?: boolean;
    isVendorTagsRulesBasedSpark?: boolean;
}

const SparkProductsTable = ({
    spark,
    associatedProducts,
    previousAssociatedProducts,
    sparkCommissionMap,
    paginated = true,
    isVendorTagsRulesBasedSpark = false,
}: ISparkProductsTableProps) => {
    const { vendorBrandRetailerProducts } = useVendorBrandRetailersProductsByBrandId({
        brandId: spark.sparkBrandId,
        vendorAccountId: spark.originatorGroupId,
        vendorBrandRetailerIds: [spark.groupId],
    });
    const associatedProductIds = associatedProducts.map(({ _id }) => _id);

    const matchedProducts = uniq(
        Object.entries(vendorBrandRetailerProducts).flatMap(([accountId, products]) => {
            return products.filter((product) => associatedProductIds.includes(product._id));
        }),
    );

    const productRows = useMemo(() => {
        return buildSparkProductRows({
            previousAssociatedProducts,
            associatedProducts: isVendorTagsRulesBasedSpark ? matchedProducts : associatedProducts,
            sparkCommissionMap,
            spark,
            isVendorTagsRulesBasedSpark,
        });
    }, [associatedProducts, spark?.type, sparkCommissionMap, previousAssociatedProducts]);

    const tableHeadCells = getHeadCells({ spark, isVendorTagsRulesBasedSpark });

    return (
        <Table
            className="spark-products-table"
            variant="flat"
            rows={productRows}
            headCells={tableHeadCells}
            showPagination={paginated}
            defaultOptions={{
                orderBy: 'name',
            }}
        >
            <Table.RenderHead />
            <Table.RenderBody />
        </Table>
    );
};

export default SparkProductsTable;
