import { z } from 'zod';

export interface AdminUpdateAccountRequestPathParams {
  accountId: string;
}

export const adminUpdateAccountSchema = z.object({
  tagAssistantEnabled: z.boolean().optional(),
  tagAssistantMessageDismissed: z.boolean().optional(),
});

export type AdminUpdateAccountRequestBody = z.infer<typeof adminUpdateAccountSchema>;
