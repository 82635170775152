import { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AdminUserProfileView } from '@views/user/UserProfileView';

import { AccountSparkBrands } from '@sparkplug/lib';

import { DataPrivacyView, VendorLinksManageView } from '@features/account-links/views';
import { AdminExportView, EmployeeEarningsExportView } from '@features/exports/views';
import { AssigningProductTagsView } from '@features/product-tags';
import { AdminCreditsView } from '@features/spark-credits/views/AdminCreditsView';

import { UIPermission } from '@contexts/AccountContext/useUserAccountPermissions';

import { MobileViewUnavailableGraphic } from '@components/graphics';
import {
    DownloadIcon,
    GeneralSettingsIcon,
    IntegrationsIcon,
    KeyboardArrowRight,
    Notifications as NotificationsIcon,
    PadlockIcon,
    ImportExport as PlanIcon,
    Settings as SettingsIcon,
    SparkCreditsIcon,
    Storefront as StorefrontIcon,
    AccountCircle as UserProfileIcon,
    SupervisorAccount as UsersIcon,
} from '@components/icons';
import { SidebarMenuItem } from '@components/layout/AppSidebar';
import AppView from '@components/layout/AppView';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import List from '@components/layout/List';
import Paper from '@components/layout/Paper';
import { NavLink } from '@components/router';

import { useApp, useAppBodyClasses, withAppView } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { IAccount } from '@app/types/AccountsTypes';

import { AdminBillingPlanView } from '../AdminBilling';
import AdminEmployeesView from '../AdminEmployeesView';
import AdminEnrollmentPoster from '../AdminEnrollmentPoster';
import AdminGeneralSettingsView from '../AdminGeneralSettingsView';
import AdminIntegrationsView from '../AdminIntegrationsView';
import AdminLocationsView from '../AdminLocationsView';
import AdminSparkSettingsView from '../AdminSparkSettingsView';

import './AccountAdminView.scss';

const UnavailableView = () => {
    return (
        <div className="account-admin-view_settings-unavailable">
            <EmptyStateDisplay
                graphic={<MobileViewUnavailableGraphic />}
                label="Not available on mobile"
                smallText={
                    <>
                        This setting cannot be configured on mobile. Please{' '}
                        <strong>go to desktop</strong> to edit account settings.
                    </>
                }
            />
        </div>
    );
};

const AccountAdminView = () => {
    const { account } = useSparkplugAccount();
    const { userId, userIsSuperAdmin } = useApp();

    const userIsAccountOwner = account?.assignedOwnerUserId === userId;

    if (account == null) {
        return null;
    }
    return (
        <>
            <Switch>
                <Route path={`/${account._id}/settings/profile`} component={AdminUserProfileView} />
                <Route
                    path={`/${account._id}/settings/sparks`}
                    component={AdminSparkSettingsView}
                />

                <Route
                    path={`/${account._id}/settings/vendor/:vendorId/manage`}
                    component={VendorLinksManageView}
                />
                <Route
                    path={`/${account._id}/settings/partner-links/:retailerAccountId`}
                    component={AssigningProductTagsView}
                />
                {account.type === 'retailer' && (userIsAccountOwner || userIsSuperAdmin) && (
                    <Route
                        path={`/${account._id}/settings/data-privacy`}
                        component={DataPrivacyView}
                    />
                )}

                <Route
                    path={`/${account._id}/settings/employees/onboarding/poster`}
                    component={AdminEnrollmentPoster}
                />
                <Route
                    path={`/${account._id}/settings/general`}
                    component={AdminGeneralSettingsView}
                />
                <Route path={`/${account._id}/settings/employees`} component={AdminEmployeesView} />
                <Route path={`/${account._id}/settings/locations`} component={AdminLocationsView} />
                <Route
                    path={`/${account._id}/settings/integrations`}
                    component={AdminIntegrationsView}
                />
                <Route
                    path={`/${account._id}/settings/credits/manage`}
                    component={AdminCreditsView}
                />
                <Route
                    path={`/${account._id}/settings/export/employee-earnings`}
                    component={EmployeeEarningsExportView}
                />
                <Route path={`/${account._id}/settings/export`} component={AdminExportView} />
                <Route
                    path={`/${account._id}/settings/billing/plan`}
                    component={AdminBillingPlanView}
                />

                <Redirect
                    from={`/${account._id}/settings`}
                    to={`/${account._id}/settings/employees`}
                />
            </Switch>
        </>
    );
};
export const buildSidebarItems = ({
    userCan,
    account,
    connectEnabled,
    userIsSuperAdmin,
    userIsAccountOwner,
}: {
    userCan: (permission: UIPermission) => boolean;
    userIsAdmin: boolean;
    account: (IAccount & { sparkBrands?: AccountSparkBrands }) | undefined;
    connectEnabled: boolean;
    userIsSuperAdmin: boolean;
    userIsAccountOwner: boolean;
}): SidebarMenuItem[] => {
    return account?._id
        ? [
              {
                  key: 'Me',
                  title: 'Me',
                  children: [
                      {
                          key: 'Profile',
                          title: 'Profile',
                          startIcon: <UserProfileIcon />,
                          to: `/${account._id}/settings/profile`,
                      },
                  ],
              },
              {
                  key: 'Team',
                  title: 'Team',
                  children: [
                      {
                          key: 'Employees',
                          title: account?.type === 'brand' ? 'Members' : 'Users',
                          startIcon: <UsersIcon />,
                          to: `/${account._id}/settings/employees`,
                      },
                  ],
              },
              {
                  key: 'Account',
                  title: 'Account',
                  isVisible: () => connectEnabled || account?.type === 'retailer',
                  children: [
                      {
                          key: 'Locations',
                          title: 'Locations',
                          isVisible: () => account?.type === 'retailer',
                          startIcon: <StorefrontIcon />,
                          to: `/${account?._id}/settings/locations`,
                      },
                      {
                          key: 'General',
                          title: 'General',
                          isVisible: () => account?.type === 'brand',
                          startIcon: <GeneralSettingsIcon />,
                          to: `/${account?._id}/settings/general`,
                      },
                      {
                          key: 'Integrations',
                          title: 'Integrations',
                          isVisible: () =>
                              account?.type === 'retailer' &&
                              !account?.industries?.includes('cannabis'),
                          startIcon: <IntegrationsIcon />,
                          to: `/${account?._id}/settings/integrations`,
                      },
                      {
                          key: 'Referral Credits',
                          title: 'Referral Credits',
                          isVisible: () => true,
                          startIcon: <SparkCreditsIcon />,
                          to: `/${account?._id}/settings/credits/manage`,
                      },
                      {
                          key: 'Data Privacy',
                          title: 'Data Privacy',
                          isVisible: () =>
                              connectEnabled &&
                              account?.type === 'retailer' &&
                              (userIsAccountOwner || userIsSuperAdmin),
                          startIcon: <PadlockIcon />,
                          to: `/${account?._id}/settings/data-privacy`,
                      },
                      {
                          key: 'Export',
                          title: 'Export',
                          isVisible: () => account?.type === 'retailer',
                          startIcon: <DownloadIcon />,
                          to: `/${account?._id}/settings/export`,
                      },
                  ],
              },
              {
                  key: 'Notifications',
                  title: 'Notifications',
                  isVisible: () => account?.type === 'retailer',
                  children: [
                      {
                          key: 'SMS Updates',
                          title: 'SMS Updates',
                          startIcon: <NotificationsIcon />,
                          to: `/${account._id}/settings/sparks`,
                      },
                  ],
              },
              {
                  key: 'Billing',
                  title: 'Billing',
                  isVisible: () => userCan('accessBillingPortal'),
                  children: [
                      {
                          key: 'Plan',
                          title: 'Plan',
                          startIcon: <PlanIcon />,
                          to: `/${account._id}/settings/billing/plan`,
                      },
                      // TODO
                      //   {
                      //       title: 'Payment info',
                      //       startIcon: <PaymentIcon />,
                      //       to: `/${account._id}/settings/billing/payment`,
                      //   },
                      //   {
                      //       title: 'Invoices',
                      //       startIcon: <InvoicesIcon />,
                      //       to: `/${account._id}/settings/billing/invoices`,
                      //   },
                  ],
              },
          ]
        : [];
};

export const buildmenuItemsMobile = ({
    userCan,
    account,
    connectEnabled,
}: {
    userCan: (permission: UIPermission) => boolean;
    userIsAdmin: boolean;
    account: (IAccount & { sparkBrands?: AccountSparkBrands }) | undefined;
    connectEnabled: boolean;
}): SidebarMenuItem[] => {
    return account?._id
        ? [
              {
                  key: 'Profile',
                  title: 'Profile',
                  startIcon: <UserProfileIcon />,
                  to: `/${account._id}/settings/profile`,
              },
              {
                  key: 'Team',
                  title: account?.type === 'brand' ? 'Manage Members' : 'Manage Users',
                  startIcon: <UsersIcon />,
                  to: `/${account._id}/settings/employees`,
              },
              {
                  key: 'Locations',
                  title: 'Locations',
                  isVisible: () => account?.type === 'retailer',
                  startIcon: <StorefrontIcon />,
                  to: `/${account?._id}/settings/locations`,
              },
              // add when we have mobile snaps views ready
              // {
              //     key: 'Snaps',
              //     title: 'Snaps',
              //     startIcon: <SnapsIcon />,
              //     to: `/${account?._id}/snaps`,
              // },
              {
                  key: 'Referral Credits',
                  title: 'Referral Credits',
                  startIcon: <SparkCreditsIcon />,
                  to: `/${account?._id}/settings/credits/manage`,
              },
              {
                  key: 'Export',
                  title: 'Export Data',
                  isVisible: () => account?.type === 'retailer',
                  startIcon: <DownloadIcon />,
                  to: `/${account?._id}/settings/export`,
              },
              {
                  key: 'SMS Updates',
                  title: 'SMS Updates',
                  isVisible: () => account?.type === 'retailer',
                  startIcon: <NotificationsIcon />,
                  to: `/${account._id}/settings/sparks`,
              },
              {
                  key: 'Plan',
                  title: 'Plan',
                  isVisible: () => userCan('accessBillingPortal'),
                  startIcon: <PlanIcon />,
                  to: `/${account._id}/settings/billing/plan`,
              },
          ]
        : [];
};

export const AdminSettingsViewMobile = (menuItemsMobile: SidebarMenuItem[]) => {
    return () => (
        <AppView.Content
            title={
                <>
                    <SettingsIcon />
                    <span>Settings</span>
                </>
            }
        >
            <Paper className="admin-settings-mobile">
                {menuItemsMobile.map(({ key, to, title, startIcon }) => (
                    <List.Item key={key}>
                        <NavLink to={to!}>
                            <span className="left-side">
                                {startIcon}
                                <span>{title}</span>
                            </span>
                            <KeyboardArrowRight />
                        </NavLink>
                    </List.Item>
                ))}
            </Paper>
        </AppView.Content>
    );
};

interface AccountAdminViewMobileProps {
    menuItemsMobile: SidebarMenuItem[];
}
const AccountAdminViewMobile = ({ menuItemsMobile }: AccountAdminViewMobileProps) => {
    const { account } = useSparkplugAccount();

    if (account == null) {
        return null;
    }

    const settingsComponent = AdminSettingsViewMobile(menuItemsMobile);
    return (
        <>
            <Switch>
                <Route path={`/${account._id}/settings/profile`} component={AdminUserProfileView} />
                <Route
                    path={`/${account._id}/settings/sparks`}
                    component={AdminSparkSettingsView}
                />
                <Route path={`/${account._id}/settings/employees`} component={AdminEmployeesView} />
                <Route
                    path={`/${account._id}/settings/locations`}
                    component={UnavailableView}
                />{' '}
                {/* TODO: AdminLocationsView */}
                <Route
                    path={`/${account._id}/settings/credits/manage`}
                    component={AdminCreditsView}
                />
                <Route
                    path={`/${account._id}/settings/export/employee-earnings`}
                    component={EmployeeEarningsExportView}
                />
                <Route path={`/${account._id}/settings/export`} component={AdminExportView} />
                <Route
                    path={`/${account._id}/settings/vendor/:vendorId/manage`}
                    component={VendorLinksManageView}
                />
                <Route
                    path={`/${account._id}/settings/billing/plan`}
                    component={AdminBillingPlanView}
                />
                <Route path={`/${account._id}/settings`} component={settingsComponent} />
            </Switch>
        </>
    );
};

export default () => {
    const { account, connectEnabled, userCan } = useSparkplugAccount();
    const { appIsMobile, userIsAdmin, userIsSuperAdmin, userId } = useApp();

    const userIsAccountOwner = account?.assignedOwnerUserId === userId;

    useAppBodyClasses(['account-admin-view']);

    const sidebarItems = useMemo<SidebarMenuItem[]>(
        () =>
            buildSidebarItems({
                userCan,
                userIsAdmin,
                account,
                connectEnabled,
                userIsSuperAdmin,
                userIsAccountOwner,
            }),
        [account],
    );

    const sidebarItemsMobile = useMemo<SidebarMenuItem[]>(
        () => buildmenuItemsMobile({ userCan, userIsAdmin, account, connectEnabled }),
        [account],
    );

    const title = {
        startIcon: <SettingsIcon />,
        text: 'Settings',
    };

    if (appIsMobile) {
        if (account?.type === 'brand') {
            return <UnavailableView />;
        }
        return (
            <AppView>
                <AccountAdminViewMobile menuItemsMobile={sidebarItemsMobile} />
            </AppView>
        );
    }

    const WrappedComponent = withAppView(sidebarItems, title)(AccountAdminView);

    return <WrappedComponent />;
};
