import { Paper as MaterialUIPaper } from '@mui/material';

import Button, { IButtonProps } from '@components/buttons/Button';

import { appendClasses } from '@helpers/ui';

import { IUIComponent } from '@app/types/UITypes';

import './Paper.scss';

const Paper = ({ className, children, hoverEffect, variant, ...otherProps }: any) => {
    const classNameAppended = appendClasses([
        'paper',
        className,
        hoverEffect ? 'paper-has-hover-effect' : null,
        variant != null ? `paper-variant-${variant}` : null,
    ]);

    return (
        <MaterialUIPaper className={classNameAppended} {...otherProps}>
            {children}
        </MaterialUIPaper>
    );
};

Paper.Title = ({ className, children }: IUIComponent) => {
    const classNameAppended = appendClasses([className, 'paper-title']);

    return (
        <h3 data-testid="paper-title" className={classNameAppended}>
            {children}
        </h3>
    );
};

Paper.DetailsButton = (props: IButtonProps) => {
    return (
        <div className="paper_details-button-container">
            <Button {...props} />
        </div>
    );
};

export default Paper;
