import moment from 'moment';
import { IPublicAccount } from '../account';
import { RecurringScheduleOption } from '../calendar';
import { ITrainingCourse } from '../training-course';
import { formatCurrency as formatNumberToCurrency, parseCurrencyToNumber } from '../util';
import {
  ProductNameFilter,
  ProductNameFilterType,
  RecurringScheduleLabel,
  Spark,
  SparkFulfillmentType,
  SparkGoal,
  SparkInternalTracking,
} from './spark.core';
import { TChartDataMetric } from '../chart';

export const SAMPLE_TEXT_TARGET = 'sample';

export const accountCanCreateSpark = (
  account: Pick<IPublicAccount, 'type' | 'metaData'>,
): boolean => {
  return account.type === 'brand' || account.metaData?.subscriptionType === 'paid';
};

export const checkForTrainingComplete = ({
  userId,
  courseData,
}: {
  userId?: string;
  courseData?: ITrainingCourse;
}): boolean => {
  return !!userId && !!courseData?.completedUsersByCourseId?.[courseData?.courseId]?.[userId];
};

export const percentOfGoal = (value: number, goal: number) => {
  return Math.min(Math.floor((value / goal) * 100), 100);
};

const recurringScheduleLabelRecord: Record<
  RecurringScheduleOption,
  Omit<RecurringScheduleLabel, 'One-Time'>
> = {
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  twice_monthly: 'Twice Monthly',
};
export const getRecurringSparkScheduleLabel = (
  scheduleValue?: RecurringScheduleOption,
): RecurringScheduleLabel => {
  if (!scheduleValue) {
    return 'One-Time';
  }
  return recurringScheduleLabelRecord[scheduleValue] as RecurringScheduleLabel;
};

export const isFulfilledBySparkplug = ({
  type,
  fulfillmentTypes = [],
}: Pick<Spark, 'type' | 'fulfillmentTypes'>) => {
  if (type === 'commission') {
    return fulfillmentTypes[0] !== 'external';
  }

  return fulfillmentTypes.some((fulfillmentType) => fulfillmentType === 'sparkplug');
};

export const calculateUnlimitedGoalProgress = (
  value: number,
  unlimitedGoal: SparkGoal,
  isFBS: boolean,
) => {
  const thresholdSteps = unlimitedGoal.threshold;

  // TODO remove these after migration
  const awardSteps = parseCurrencyToNumber(
    typeof unlimitedGoal.award === 'string' ? unlimitedGoal.award : unlimitedGoal.award.award,
  );

  const numberOfGoals = value / thresholdSteps;
  const goalValue = value % thresholdSteps;
  const percentOfNextGoal = percentOfGoal(goalValue, thresholdSteps);

  const goalsMet = Math.floor(numberOfGoals);
  const previousGoal = goalsMet * thresholdSteps;
  const nextGoal =
    goalValue > 0
      ? Math.ceil(numberOfGoals) * thresholdSteps
      : (numberOfGoals + 1) * thresholdSteps;

  const earned = isFBS
    ? formatNumberToCurrency(goalsMet * awardSteps, true)
    : `${goalsMet} x ${
        typeof unlimitedGoal.award === 'string' ? unlimitedGoal.award : unlimitedGoal.award.award
      }`;

  return {
    previousGoal,
    percentOfNextGoal,
    nextGoal,
    earned,
    goalsMet,
  };
};

/**
 *
 * @returns The previous period's endDate in ISO format
 */
export const getPreviousPeriodEndDate = ({
  prevStartDate,
  currentPeriod,
}: {
  prevStartDate: string;
  currentPeriod: {
    startDate: string;
    endDate: string;
  };
}): string => {
  const currentPeriodDays = moment(currentPeriod.endDate).diff(currentPeriod.startDate, 'days');
  return moment(prevStartDate).add(currentPeriodDays, 'days').format('YYYY-MM-DD');
};

export const getGoalProgressByValue = (
  goals: SparkGoal[],
  fulfillmentTypes: SparkFulfillmentType[],
  value: number,
): {
  goalsMet: number;
  goalsTotal: number;
  nextGoalAmount: number;
  currentAward?: string;
  claimInstructions?: string;
  isCurrentFBS: boolean;
  amountAwayFromNextGoal: number;
} => {
  const isUnlimited = goals?.[0]?.isUnlimited;

  if (isUnlimited) {
    const isFBS = fulfillmentTypes?.[0] === 'sparkplug';
    const { nextGoal, goalsMet: unlimitedGoalsMet } = calculateUnlimitedGoalProgress(
      value,
      goals?.[0],
      isFBS,
    );

    // TODO remove after migration
    const goalAward = typeof goals[0].award === 'string' ? goals[0].award : goals[0].award.award;
    let currentAward = `${unlimitedGoalsMet} x ${goalAward}`;
    if (isFBS) {
      const awardInDollars = parseCurrencyToNumber(goalAward) * unlimitedGoalsMet;
      currentAward = formatNumberToCurrency(awardInDollars);
    }

    return {
      goalsMet: unlimitedGoalsMet,
      goalsTotal: -1,
      nextGoalAmount: nextGoal,
      currentAward,
      claimInstructions: goals[0].award.claimInstructions ?? '',
      isCurrentFBS: isFBS,
      amountAwayFromNextGoal: nextGoal - value,
    };
  }

  let currentAward;
  let isCurrentFBS = false;
  let goalsMet = 0;
  let nextGoalAmount = goals?.[0]?.threshold || 0;
  let claimInstructions;

  // TODO remove type check after migration
  goals.forEach(({ threshold }, i) => {
    if (value >= threshold) {
      currentAward = typeof goals[i].award === 'string' ? goals[i].award : goals[i].award.award;
      isCurrentFBS = fulfillmentTypes?.[i] === 'sparkplug';
      goalsMet = i + 1;
      nextGoalAmount = goals?.[i + 1]?.threshold || value;
      claimInstructions = goals[i].award.claimInstructions ?? '';
    }
  });

  return {
    goalsMet,
    goalsTotal: goals?.length,
    nextGoalAmount,
    currentAward,
    isCurrentFBS,
    amountAwayFromNextGoal: nextGoalAmount - value,
    claimInstructions,
  };
};

export const getDefaultSparkInternalTracking = (spark?: {
  internalTracking?: SparkInternalTracking;
}): SparkInternalTracking => {
  const internalTracking = spark?.internalTracking;

  return {
    status: internalTracking?.status || 'none',
    invoiceId: internalTracking?.invoiceId || '',
    invoiceStatus: internalTracking?.invoiceStatus || 'none',
    payoutStatus: internalTracking?.payoutStatus || 'none',
    notes: internalTracking?.notes || '',
    changelog: internalTracking?.changelog || [],
  };
};

const PRODUCT_NAME_FILTER_FNS: Record<
  ProductNameFilterType,
  (lowerCasedProductName: string, text: string) => boolean
> = {
  CONTAINS: (productName, text) => {
    return productName.includes(text);
  },
  DOES_NOT_CONTAIN: (productName, text) => {
    return !productName.includes(text);
  },
  STARTS_WITH: (productName, text) => {
    return productName.startsWith(text);
  },
  ENDS_WITH: (productName, text) => {
    return productName.endsWith(text);
  },
} as const;

export const applyProductNameFilters =
  (productNameFilters: ProductNameFilter[]) =>
  <T extends { name: string }>(posProducts: T[]): T[] => {
    if (!productNameFilters?.length) {
      return posProducts;
    }

    return posProducts.filter(({ name: productName }) =>
      // We want to use OR logic for the filters, so we return true if any of the filters match
      productNameFilters.some(({ filterType, text }) =>
        PRODUCT_NAME_FILTER_FNS[filterType](productName.toLowerCase(), text.toLowerCase()),
      ),
    );
  };

export const applyProductNameContainsFilter =
  (filters: string[]) =>
  <T extends { name: string }>(posProducts: T[]): T[] => {
    if (!filters?.length) {
      return posProducts;
    }

    return posProducts.filter(({ name: productName }) =>
      // We want to use OR logic for the filters, so we return true if any of the filters match
      filters.some((text) =>
        PRODUCT_NAME_FILTER_FNS.CONTAINS(productName.toLowerCase(), text.toLowerCase()),
      ),
    );
  };

export const applyProductNameDoesNotContainFilter =
  (filters: string[]) =>
  <T extends { name: string }>(posProducts: T[]): T[] => {
    if (!filters?.length) {
      return posProducts;
    }

    return posProducts.filter(({ name: productName }) =>
      // We want to use OR logic for the filters, so we return true if any of the filters match
      filters.every((text) =>
        PRODUCT_NAME_FILTER_FNS.DOES_NOT_CONTAIN(productName.toLowerCase(), text.toLowerCase()),
      ),
    );
  };

export const isAverageMetric = (metric: TChartDataMetric) => {
  return ['order_average', 'units_per_transaction', 'guest_check_average'].includes(metric ?? '');
};
