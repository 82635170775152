import axios from 'axios';

import {
    GetPresignedUrlPathParams,
    GetPresignedUrlQueryParams,
    GetPresignedUrlResponseBody,
} from '@sparkplug/lib';

const axiosWithoutDefaults = axios.create();

export default class UploadsAPI {
    static async getPresignedUploadUrl({
        groupId,
        folder,
        filename,
    }: GetPresignedUrlPathParams &
        GetPresignedUrlQueryParams): Promise<GetPresignedUrlResponseBody> {
        return (
            await axios.get(
                `/api/v1/uploads/${groupId}/${folder}${filename ? `?filename=${filename}` : ''}`,
            )
        ).data;
    }

    static async upload(
        presignedUrlData: GetPresignedUrlResponseBody,
        file: any,
    ): Promise<{ key: string; cacheUrl: string }> {
        const { url, key, fields, cacheUrl } = presignedUrlData;
        const uploadFormData: any = new FormData();
        Object.entries(fields).forEach(([field, value]) => uploadFormData.append(field, value));
        uploadFormData.append('file', file);
        await axiosWithoutDefaults.post(url, uploadFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return {
            key,
            cacheUrl,
        };
    }
}
