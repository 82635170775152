import { FC, useEffect, useMemo, useState } from 'react';

import { AccountRole, DO_NOT_HAVE_PERMISSIONS_MESSAGE, Policy } from '@sparkplug/lib';

import {
    useEnrollUser,
    useSaveAccountUserMutation,
    useSendResetPasswordEmailRequest,
    useSendResetPasswordTextRequest,
    useUnenrollUser,
} from '@core/users';

import Button from '@components/buttons/Button';
import Dropdown from '@components/dropdown/Dropdown';
import Form from '@components/form/Form';
import { BookIcon, InfoIcon, MoreHoriz } from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import ClickableArea from '@components/layout/ClickableArea';
import Tooltip from '@components/layout/Tooltip';
import AccountUserConflictModal from '@components/overlays/CreateEditUserModal/CreateEditAccountUserModal/AccountUserConflictModal';
import { IUpdatedUserData } from '@components/overlays/CreateEditUserModal/CreateEditAccountUserModal/CreateEditAccountUserModal.types';
import ExternalGroupsTable from '@components/overlays/CreateEditUserModal/CreateEditAccountUserModal/ExternalGroupsTable';
import PosEmployeeProfilesTable from '@components/overlays/CreateEditUserModal/CreateEditAccountUserModal/PosEmployeeProfilesTable';
import Drawer from '@components/overlays/Drawer';

import { useApp } from '@hooks/AppHooks';

import Intercom from '@helpers/Intercom';
import { isValidPhoneNumber } from '@helpers/ui';

import { IAccount } from '@app/types/AccountsTypes';
import { CheckedEvent } from '@app/types/UITypes';
import { IAccountUser, IUserData, TAccountUserRoles } from '@app/types/UsersTypes';

import './CreateEditUserDrawer.scss';

const PhoneDropdown = ({
    account,
    existingUser,
    updatedUserData,
    setDidUpdate,
    onClose,
}: {
    account: IAccount;
    existingUser?: IAccountUser;
    updatedUserData: Partial<IUserData>;
    setDidUpdate: (didUpdate: boolean) => void;
    onClose: (didUpdate: boolean) => void;
}) => {
    if (!existingUser) {
        return <></>;
    }

    const { enrollUser } = useEnrollUser();
    const { unenrollUser } = useUnenrollUser(existingUser);
    const { sendResetPasswordTextRequest } = useSendResetPasswordTextRequest();

    const onUnenroll = async () => {
        unenrollUser(
            { accountId: account._id, userId: existingUser.userId! },
            {
                onSuccess: () => {
                    setDidUpdate(true);
                    onClose(true);
                },
            },
        );
    };

    const onResend = async () => {
        enrollUser(
            { accountId: account._id, userId: existingUser.userId },
            { onSuccess: () => setDidUpdate(true) },
        );
    };

    const onReset = () => {
        if (existingUser?.userId) {
            sendResetPasswordTextRequest(existingUser.userId);
        }
    };

    return updatedUserData.role !== 'none' && existingUser.phoneNumber ? (
        <Dropdown>
            <Dropdown.IconButton>
                <MoreHoriz />
            </Dropdown.IconButton>
            <Dropdown.Menu>
                <Dropdown.MenuItem
                    onClick={onUnenroll}
                    hidden={existingUser?.smsStatus !== 'enrolled'}
                >
                    Unenroll
                </Dropdown.MenuItem>
                <Dropdown.MenuItem
                    onClick={onResend}
                    hidden={existingUser?.smsStatus === 'enrolled'}
                >
                    Re-send enrollment text
                </Dropdown.MenuItem>
                <Dropdown.MenuItem onClick={onReset} hidden>
                    Send reset password text
                </Dropdown.MenuItem>
            </Dropdown.Menu>
        </Dropdown>
    ) : (
        <></>
    );
};

const EmailDropdown = ({
    existingUser,
    updatedUserData,
}: {
    existingUser?: IAccountUser;
    updatedUserData: Partial<IUserData>;
}) => {
    const { sendResetPasswordEmailRequest } = useSendResetPasswordEmailRequest();

    if (!existingUser) {
        return null;
    }

    const onReset = () => {
        if (existingUser?.userId) {
            sendResetPasswordEmailRequest(existingUser?.userId);
        }
    };

    const userIsAdmin = existingUser.role === 'group-admin';
    const emailAddressIsUnchanged =
        !updatedUserData.email || existingUser.email === updatedUserData.email;

    return existingUser.email && userIsAdmin && emailAddressIsUnchanged ? (
        <Dropdown>
            <Dropdown.IconButton>
                <MoreHoriz />
            </Dropdown.IconButton>
            <Dropdown.Menu>
                <Dropdown.MenuItem onClick={onReset}>Send reset password email</Dropdown.MenuItem>
            </Dropdown.Menu>
        </Dropdown>
    ) : (
        <></>
    );
};

interface AccountUserRoleOption {
    label: string;
    value: AccountRole | 'owner';
}

interface CreateEditAccountUserDrawerProps {
    isVisible: boolean;
    onClose: (doActionOnClose?: boolean) => void;
    user?: IAccountUser & { smsStatusFormatted?: string };
    account: IAccount;
    accountUsers: IAccountUser[];
}

export const CreateEditAccountUserDrawer: FC<CreateEditAccountUserDrawerProps> = ({
    isVisible,
    user: existingUser,
    account,
    accountUsers,
    onClose,
}) => {
    const [didUpdate, setDidUpdate] = useState(false);
    const [phoneNumberValid, setPhoneNumberValid] = useState(true);
    const [conflictModalIsVisible, setConflictModalIsVisible] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [updatedUserData, setUpdatedUserData] = useState<
        Partial<IAccountUser & { smsStatusFormatted?: string }>
    >(existingUser ?? {});

    const { saveAccountUser } = useSaveAccountUserMutation(account ?? ({} as IAccount));

    // Reset all state when drawer closes or existingUser changes
    useEffect(() => {
        if (!isVisible) {
            setDidUpdate(false);
            setPhoneNumberValid(true);
            setConflictModalIsVisible(false);
            setSubmitted(false);
            setUpdatedUserData({});
        } else if (existingUser && !existingUser?.userId) {
            const {
                firstName = '',
                lastName = '',
                posEmployeeProfileIds = [],
                role: existingRole,
            } = existingUser;

            const role =
                existingRole ?? (posEmployeeProfileIds.length ? 'group-member' : 'group-admin');

            setUpdatedUserData({
                firstName,
                lastName,
                posEmployeeProfileIds,
                role,
            });
        } else if (!existingUser) {
            setUpdatedUserData({
                role: 'group-admin',
                ...(account.type === 'retailer'
                    ? { permissions: { manageSparks: false, accessBilling: false } }
                    : {}),
            });
        } else if (existingUser) {
            setUpdatedUserData({
                permissions: existingUser.permissions,
            });
        }
    }, [isVisible, existingUser, account.type]);

    const user = {
        ...existingUser,
        ...updatedUserData,
    };

    const hasExternalGroups = !!existingUser?.externalGroups?.length;

    const isNewUser = useMemo(() => !existingUser, [existingUser]);

    const updateUserData = (newUserData: Partial<IUpdatedUserData>) => {
        setPhoneNumberValid(true);

        setUpdatedUserData((prevState: any) => {
            return {
                ...prevState,
                ...newUserData,
            };
        });
    };

    const updateUserPermission = (key: Policy) => {
        return (event: CheckedEvent) => {
            setUpdatedUserData((prevState: Partial<IUpdatedUserData>) => {
                const checked = event.target.checked;
                return {
                    ...prevState,
                    permissions: { ...prevState.permissions, [key]: checked },
                };
            });
        };
    };

    const { user: currentUser, userIsSuperAdmin } = useApp();
    const isUserRole = (role: TAccountUserRoles) => user?.role === role;
    const isSmsUser = ['blocked', 'pending', 'enrolled'].includes(user?.smsStatus as string);
    const shouldRequirePhoneNumber = Boolean(
        isSmsUser && user?.role === 'group-member' && user?.posEmployeeProfileIds?.length,
    );
    const isEditingOwner =
        !!account?.assignedOwnerUserId && account?.assignedOwnerUserId === user?.userId;
    const userIsOwner = currentUser?._id === account?.assignedOwnerUserId;

    const closeDrawer = (refetchOnClose: boolean) => {
        onClose(refetchOnClose);
    };

    const onSave = () => {
        setSubmitted(true);
        if (shouldRequirePhoneNumber || user?.phoneNumber) {
            if (!isValidPhoneNumber(user?.phoneNumber ?? '')) {
                setPhoneNumberValid(false);
                return;
            }
        }

        const overriddenUserData = { ...updatedUserData };

        // Always include the role if user is an admin, even if it hasn't changed
        if (user.role === 'group-admin' && !overriddenUserData.role) {
            overriddenUserData.role = 'group-admin';
        }

        // if we're updating a user to retailer-admin and no permissions are explicitly assigned and no existing permissions are on the user, we need to provide the default permissions or the api will error out _after_ creating the user but _before_ creating the groupmembership
        const { permissions: updatedPermissions } = overriddenUserData;
        const { permissions: existingPermissions } = existingUser ?? {};
        const shouldUseDefaultRetailerPermissions =
            !existingPermissions &&
            !updatedPermissions &&
            overriddenUserData.role === 'group-admin' &&
            account.type === 'retailer';
        if (shouldUseDefaultRetailerPermissions) {
            overriddenUserData.permissions = { manageSparks: false, accessBilling: false };
        }

        // but if the role is then changed back to a normal "group-member" or "inactive", we don't want to pass any permissions at all
        if (['none', 'group-member'].includes(overriddenUserData.role ?? '')) {
            delete overriddenUserData.permissions;
        }

        saveAccountUser(
            { userData: overriddenUserData, userId: user?.userId },
            {
                onSuccess: () => {
                    onClose(true);
                },
                onError: (err: any) => {
                    const isConflict = err?.response?.status === 409;
                    const isAdmin = user.role === 'group-admin';
                    const isEmailUpdate = !!user.email;

                    if (isConflict && isAdmin && isEmailUpdate) {
                        setConflictModalIsVisible(true);
                    }
                },
            },
        );
    };

    const userRoleOptions = useMemo(() => {
        const hasPosEPs = !!user.posEmployeeProfileIds?.length;
        const hasExistingUser = !!existingUser;

        const roles: AccountUserRoleOption[] = [
            {
                label: 'Admin',
                value: 'group-admin',
            },
        ];

        if (hasPosEPs) {
            roles.push({
                label: 'User',
                value: 'group-member',
            });
        }

        if (hasExistingUser) {
            roles.push({
                label: 'Inactive',
                value: 'none',
            });
        }

        if (isEditingOwner) {
            roles.push({
                label: 'Owner',
                value: 'owner',
            });
        }

        return roles;
    }, [account.type, existingUser?.userId, user.posEmployeeProfileIds]);

    const getLabelText = (value: boolean) => {
        return value ? 'On' : 'Off';
    };

    const existingHeader = 'Edit User Profile';

    if (!isVisible) {
        return <></>;
    }

    return (
        <>
            <Drawer
                title={
                    !isNewUser || user?.posEmployeeProfileIds?.length
                        ? existingHeader
                        : 'Create New Admin'
                }
                variant="right"
                isVisible={isVisible}
                className="invite-edit-member-drawer"
                onCloseHandler={closeDrawer}
            >
                <Form autoComplete="new-password">
                    {isNewUser && account.type === 'retailer' && (
                        <CalloutMessage
                            variant="default"
                            color="neutral"
                            message={
                                <p>
                                    To create a SparkPlug admin account for a non-sales employee,
                                    like a manager or administrator, add their user information
                                    below.
                                </p>
                            }
                        />
                    )}

                    {isEditingOwner && (
                        <CalloutMessage
                            message={
                                <>
                                    This user is the account <span>Owner</span>, their details and
                                    permissions cannot be modified.
                                    <ClickableArea
                                        className="contact-us"
                                        onClick={() => {
                                            Intercom.open();
                                            onClose(didUpdate);
                                        }}
                                    >
                                        Contact support
                                    </ClickableArea>
                                    &nbsp;if you need to change the account owner.
                                </>
                            }
                        />
                    )}

                    <Form.SectionTitle>SparkPlug User Details</Form.SectionTitle>
                    <Form.Grid className="wrap-lg-nowrap">
                        <Form.GridItem xs={12}>
                            <Form.Select
                                label="Type"
                                name="role"
                                disabled={isEditingOwner}
                                value={isEditingOwner ? 'owner' : user?.role ?? ''}
                                onChange={(event) => {
                                    updateUserData({
                                        role: event.target.value as IUpdatedUserData['role'],
                                    });
                                }}
                                options={userRoleOptions}
                            />
                        </Form.GridItem>

                        <Form.GridItem xs={12} sm={6}>
                            <Form.TextField
                                label="First Name"
                                name="firstName"
                                required
                                error={submitted && !user?.firstName}
                                disabled={isEditingOwner && !userIsSuperAdmin}
                                value={user?.firstName}
                                onChange={(event) => {
                                    updateUserData({ firstName: event.target.value });
                                }}
                            />
                        </Form.GridItem>

                        <Form.GridItem xs={12} sm={6}>
                            <Form.TextField
                                label="Last Name"
                                name="lastName"
                                required
                                error={submitted && !user?.lastName}
                                disabled={isEditingOwner && !userIsSuperAdmin}
                                value={user?.lastName}
                                onChange={(event) => {
                                    updateUserData({ lastName: event.target.value });
                                }}
                            />
                        </Form.GridItem>

                        {account.type === 'retailer' && (
                            <>
                                <Form.GridItem xs={12} sm={6}>
                                    <Form.PhoneField
                                        label="Phone Number"
                                        name="phoneNumber"
                                        disabled={
                                            isUserRole('none') ||
                                            !user?.posEmployeeProfileIds?.length ||
                                            (isEditingOwner && !userIsSuperAdmin)
                                        }
                                        required={shouldRequirePhoneNumber}
                                        error={submitted && !phoneNumberValid}
                                        defaultValue={user?.phoneNumber}
                                        endIcon={
                                            <PhoneDropdown
                                                account={account}
                                                existingUser={existingUser}
                                                updatedUserData={updatedUserData}
                                                setDidUpdate={setDidUpdate}
                                                onClose={(refetch) => onClose(refetch)}
                                            />
                                        }
                                        onChange={(event) => {
                                            updateUserData({
                                                phoneNumber: event.target.value ?? '',
                                            });
                                        }}
                                    />
                                </Form.GridItem>
                                <Form.GridItem xs={12} sm={6}>
                                    <Form.TextField
                                        label="SMS Status"
                                        name="smsStatus"
                                        value={user?.smsStatusFormatted || '--'}
                                        readOnly
                                        disabled
                                    />
                                </Form.GridItem>
                            </>
                        )}

                        <Form.GridItem xs={12}>
                            <Form.TextField
                                label="Email"
                                name="email"
                                required={isUserRole('group-admin')}
                                disabled={
                                    isUserRole('group-member') ||
                                    isUserRole('none') ||
                                    (isEditingOwner && !userIsSuperAdmin)
                                }
                                defaultValue={user?.email}
                                endIcon={
                                    <EmailDropdown
                                        existingUser={existingUser}
                                        updatedUserData={updatedUserData}
                                    />
                                }
                                onChange={(event) => {
                                    updateUserData({ email: event.target.value });
                                }}
                                helperText={
                                    hasExternalGroups
                                        ? 'This email is linked to multiple accounts, updating here will update on the other accounts listed below'
                                        : undefined
                                }
                            />
                        </Form.GridItem>
                    </Form.Grid>

                    {hasExternalGroups && (
                        <>
                            <Form.SectionTitle>
                                <span>Linked Admin Accounts</span>
                            </Form.SectionTitle>
                            <ExternalGroupsTable user={user as IAccountUser} />
                        </>
                    )}

                    {!hasExternalGroups && account.type === 'retailer' && (
                        <>
                            <Form.SectionTitle>
                                <span>POS Employee Profiles</span>
                                <Tooltip title="Add profiles here to combine POS employees">
                                    <InfoIcon className="info-icon" />
                                </Tooltip>
                            </Form.SectionTitle>
                            <PosEmployeeProfilesTable
                                user={user as IAccountUser}
                                accountUsers={accountUsers ?? []}
                                updateUserData={updateUserData}
                            />
                        </>
                    )}

                    {isUserRole('group-admin') && account.type === 'retailer' && (
                        <>
                            <Form.SectionTitle className="section-padding">
                                <div>User Permissions</div>
                                <div>
                                    <a
                                        href="https://help.sparkplug.app/en/articles/8055239-managing-admin-user-permissions"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="learn-more"
                                    >
                                        <BookIcon />
                                        Learn more
                                    </a>
                                </div>
                            </Form.SectionTitle>
                            <Form.LabeledSwitch
                                title="Sparks"
                                name="manageSparks"
                                disabled={(!userIsSuperAdmin && !userIsOwner) || isEditingOwner}
                                tooltip={
                                    (!userIsSuperAdmin && !userIsOwner) || isEditingOwner
                                        ? DO_NOT_HAVE_PERMISSIONS_MESSAGE
                                        : undefined
                                }
                                subtitle="Access to creating, accepting & managing Sparks"
                                value={!!user.permissions?.manageSparks}
                                label={getLabelText(!!user.permissions?.manageSparks)}
                                onChange={updateUserPermission('manageSparks')}
                            />
                            <Form.LabeledSwitch
                                title="Vendor Partners"
                                name="manageLinks"
                                disabled={(!userIsSuperAdmin && !userIsOwner) || isEditingOwner}
                                tooltip={
                                    (!userIsSuperAdmin && !userIsOwner) || isEditingOwner
                                        ? DO_NOT_HAVE_PERMISSIONS_MESSAGE
                                        : undefined
                                }
                                subtitle="Manage linked vendors and mapped products"
                                value={!!user.permissions?.manageLinks}
                                label={getLabelText(!!user.permissions?.manageLinks)}
                                onChange={updateUserPermission('manageLinks')}
                            />
                            {!account?.metaData?.billingDisabled && (
                                <Form.LabeledSwitch
                                    title="Billing & Exports"
                                    name="accessBilling"
                                    disabled={(!userIsSuperAdmin && !userIsOwner) || isEditingOwner}
                                    tooltip={
                                        (!userIsSuperAdmin && !userIsOwner) || isEditingOwner
                                            ? DO_NOT_HAVE_PERMISSIONS_MESSAGE
                                            : undefined
                                    }
                                    subtitle="Access billing portal and data exports"
                                    value={!!user.permissions?.accessBilling}
                                    label={getLabelText(!!user.permissions?.accessBilling)}
                                    onChange={updateUserPermission('accessBilling')}
                                />
                            )}
                        </>
                    )}

                    <Drawer.Footer>
                        <Button color="neutral" variant="flat" onClick={() => onClose(didUpdate)}>
                            Cancel
                        </Button>
                        <Form.Button
                            color="blue"
                            variant="smooth"
                            disabled={false}
                            onClick={onSave}
                        >
                            {isNewUser ? 'Send Invite' : 'Save'}
                        </Form.Button>
                    </Drawer.Footer>
                </Form>
            </Drawer>

            <AccountUserConflictModal
                isVisible={conflictModalIsVisible}
                onClose={() => {
                    setConflictModalIsVisible(false);
                    onClose(didUpdate);
                }}
                onBack={() => setConflictModalIsVisible(false)}
                payload={{
                    email: user.email!,
                    groupId: account._id,
                    role: user.role!,
                    userIdToDelete: user?.userId,
                    permissions: user.permissions ?? {},
                }}
            />
        </>
    );
};

export default CreateEditAccountUserDrawer;
