import moment from 'moment-timezone';

import BucketFactory, { TBucketsMap } from './BucketFactory';

export default class YearBucketFactory extends BucketFactory {
    getShortName(name: string) {
        return moment(name).format('YYYY');
    }

    getTooltipTitle(name: string) {
        return this.getShortName(name);
    }

    getComparisonLabel(name: string) {
        return this.getTooltipTitle(name);
    }

    getBucketName(transaction: any) {
        if (transaction.bucket) {
            return `${transaction.bucket.substring(0, 4)}-01-01`;
        }
        const date = moment(transaction.completedOn);
        return date.tz(transaction.timeZone).format('YYYY-01-01');
    }

    getAllBucketNames(dateStart: string | Date, dateEnd: string | Date) {
        const bucketNames: string[] = [];
        const currentDate = moment(dateStart).startOf('year');

        do {
            bucketNames.push(currentDate.format('YYYY-01-01'));
            currentDate.add(1, 'year');
        } while (currentDate.isSameOrBefore(dateEnd, 'year'));

        return bucketNames;
    }

    getCumulativeBuckets(bucketsMap: TBucketsMap, bucketName: string) {
        return super.getCumulativeBuckets(bucketsMap, bucketName);
    }
}
