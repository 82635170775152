import { AccountLink, DO_NOT_HAVE_PERMISSIONS_MESSAGE } from '@sparkplug/lib';

import CalloutMessage from '@components/layout/CalloutMessage';
import ClickableArea from '@components/layout/ClickableArea';
import Tooltip from '@components/layout/Tooltip';

import Typography from '@app/componentLibrary/Typography/Typography';
import { IAccount } from '@app/types/AccountsTypes';

interface AutoLinkingDisabledCalloutProps {
    className?: string;
    userCanEnableAutoLinking?: boolean;
    onEnableAutoLinkingClick?: () => void;
}
const AutoLinkingDisabledCallout = ({
    className,
    userCanEnableAutoLinking = false,
    onEnableAutoLinkingClick,
}: AutoLinkingDisabledCalloutProps) => {
    const tooltipMessage = userCanEnableAutoLinking ? undefined : DO_NOT_HAVE_PERMISSIONS_MESSAGE;

    return (
        <CalloutMessage
            color="yellow"
            className={className}
            message={
                <Typography className="text-gray-950">
                    Vendor auto-linking is disabled which requires you to manually accept and create
                    links to the Vendor brands you carry.{' '}
                    <ClickableArea
                        elementType="span"
                        className="text-cerulean-600 hover:text-cerulean-800 cursor-pointer font-medium"
                        onClick={onEnableAutoLinkingClick}
                        disabled={!userCanEnableAutoLinking}
                    >
                        <Tooltip title={tooltipMessage}>
                            <span>Enable auto linking</span>
                        </Tooltip>
                    </ClickableArea>
                </Typography>
            }
        />
    );
};

interface AutoLinkingEnabledCalloutProps {
    className?: string;
    linkType: AccountLink['accountType'];
}
const AutoLinkingEnabledCallout = ({ className, linkType }: AutoLinkingEnabledCalloutProps) => {
    const calloutMessage =
        linkType === 'retailer'
            ? 'Retailer links are automatically created when we detect your brands in a retailer’s point-of-sale system.'
            : `Vendor links are automatically created when we detect a vendor's brands in your point-of-sale system.`;

    return <CalloutMessage className={className} message={calloutMessage} />;
};

interface AutoLinkingInboxCalloutProps {
    className?: string;
}
const AutoLinkingInboxCallout = ({ className }: AutoLinkingInboxCalloutProps) => {
    return (
        <CalloutMessage
            className={className}
            message="These vendors have requested a link. We can’t find these brands in your point-of-sale system, but you can create a link by mapping brands yourself."
        />
    );
};

interface AutoLinkingNoMatchCalloutProps {
    className?: string;
    linkType: AccountLink['accountType'];
}
const AutoLinkingNoMatchCallout = ({ className, linkType }: AutoLinkingNoMatchCalloutProps) => {
    const calloutMessage =
        linkType === 'retailer'
            ? `These retailers on SparkPlug are available to link with, but we don’t detect any of your brands in the retailer’s point-of-sale system.`
            : `These vendors on SparkPlug are available to link with, but we don’t detect any of these vendor’s brands in your point-of-sale system.`;

    return <CalloutMessage className={className} message={calloutMessage} />;
};

interface AutoLinkingInfoCalloutProps {
    account: IAccount;
    className?: string;
    currentView: 'mapped' | 'inbox' | 'available';
    userCanEnableAutoLinking: boolean;
    onEnableAutoLinkingClick: () => void;
}
export const AutoLinkingInfoCallout = ({
    account,
    className,
    onEnableAutoLinkingClick,
    userCanEnableAutoLinking,
    currentView,
}: AutoLinkingInfoCalloutProps) => {
    const accountNoAutoLinking = account.type === 'retailer' && !account.autoLinkingEnabled;
    if (accountNoAutoLinking) {
        return (
            <AutoLinkingDisabledCallout
                className={className}
                userCanEnableAutoLinking={userCanEnableAutoLinking}
                onEnableAutoLinkingClick={onEnableAutoLinkingClick}
            />
        );
    }

    const linkType = account.type === 'brand' ? 'retailer' : 'brand';
    switch (currentView) {
        case 'inbox':
            return <AutoLinkingInboxCallout className={className} />;
        case 'available':
            return <AutoLinkingNoMatchCallout className={className} linkType={linkType} />;
        case 'mapped':
        default:
            return <AutoLinkingEnabledCallout className={className} linkType={linkType} />;
    }
};
