import { FC } from 'react';

import './SparkOwnerDisplay.scss';

export interface SparkOwnerProps {
    firstName: string;
    lastName: string;
}
export const SparkOwnerDisplay: FC<SparkOwnerProps> = ({ firstName, lastName }) => {
    return (
        <>
            <div className="spark-owner">
                <div className="initial-container">
                    <span>
                        {firstName.slice(0, 1)}
                        {lastName.slice(0, 1)}
                    </span>
                </div>
                {`${firstName} ${lastName}`}
            </div>
        </>
    );
};
