import axios from 'axios';

import { TableKey, UpsertTableSettingsRequestBody } from '@sparkplug/lib';

import { useAdvancedMutation } from '@hooks/QueryHooks';

import { getTableSettingsCacheKey } from './getTableSettingsCacheKey';

const API = {
    async saveTableSettings({
        tableKey,
        payload,
    }: {
        tableKey: TableKey;
        payload: UpsertTableSettingsRequestBody;
    }) {
        return axios.put<UpsertTableSettingsRequestBody>(
            `/api/v1/table-settings/${tableKey}`,
            payload,
        );
    },
};

export const useSaveTableSettings = ({ tableKey }: { tableKey: TableKey }) => {
    const { mutateAsync, isLoading } = useAdvancedMutation(
        (payload: UpsertTableSettingsRequestBody) => API.saveTableSettings({ tableKey, payload }),
        {
            updateQuery: { queryKey: getTableSettingsCacheKey(tableKey) },
            toastOptions: {
                loading: 'Saving...',
                success: 'Saved',
                error: 'Error. Unable to update table settings',
            },
        },
    );

    return {
        tableSettingsAreSaving: isLoading,
        saveTableSettings: mutateAsync,
    };
};
