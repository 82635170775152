import { DefaultChartColors } from '@components/charts/nivo/Nivo.Constants';

import { IBarChartPoint, IDataArrayItem, IDataSet } from '@app/types/ChartDataTypes';

import BucketFactory from '../buckets/BucketFactory';

const defaultLabels = {
    metricLabel: '',
};

export default class NivoBarChartFactory {
    generateChartData(
        bucketFactory: BucketFactory,
        name: string,
        dataArray: IDataArrayItem[],
        labels = defaultLabels,
    ) {
        const keys = [name];
        const values: IBarChartPoint[] = [];

        dataArray.forEach((kvp) => {
            const id = bucketFactory.getShortName(kvp.name);

            const x = name;
            const y = kvp.value;
            const point = {
                id,
                meta: {
                    tooltipTitle: bucketFactory.getTooltipTitle(kvp.name),
                    date: kvp.name,
                    comparisonLabel: bucketFactory.getComparisonLabel(kvp.name),
                    ...labels,
                },
                [x]: y,
            };
            values.push(point);
        });

        return { keys, colors: { [name]: DefaultChartColors[0] }, data: values };
    }

    generateMultiDimensionalChartData(
        bucketFactory: BucketFactory,
        dataSets: IDataSet[],
        labels = defaultLabels,
    ) {
        if (dataSets.length > 1) {
            const keys: string[] = [];
            const data: {
                [name: string]: IBarChartPoint;
            } = {};
            const colors: {
                [id: string]: string;
            } = {};

            dataSets.forEach((dataSet, index) => {
                const colorIndex = index % DefaultChartColors.length;

                const { name, dataArray } = dataSet;

                keys.push(name);
                colors[name] = DefaultChartColors[colorIndex];
                dataArray.forEach((kvp) => {
                    const id = bucketFactory.getShortName(kvp.name);

                    if (data[id] == null) {
                        data[id] = {
                            id,
                            meta: {
                                tooltipTitle: bucketFactory.getTooltipTitle(kvp.name),
                                comparisonLabel: bucketFactory.getComparisonLabel(kvp.name),
                                date: kvp.name,
                                ...labels,
                            },
                            [name]: kvp.value,
                        } as IBarChartPoint;
                    } else {
                        data[id][name] = kvp.value;
                    }
                });
            });

            return {
                keys,
                colors,
                data: Object.values(data),
            };
        } else if (dataSets.length === 1) {
            return this.generateChartData(
                bucketFactory,
                dataSets?.[0]?.name ?? '',
                dataSets?.[0]?.dataArray ?? [],
                labels,
            );
        } else {
            return {
                keys: [],
                colors: [],
                values: [],
            };
        }
    }
}
