import { useMemo, useState } from 'react';

import { isEmpty } from 'lodash';

import { IPublicAccount, Spark } from '@sparkplug/lib';

interface UseSparkQualifyingProductsStateParams {
    account?: IPublicAccount;
    spark?: Spark;
}

export type SparkQualifyingProductsState =
    | 'multiRetailer'
    | 'retailerPosRules'
    | 'vendorTagsRules'
    | 'vendorPosRules';

export const useSparkQualifyingProductsState = ({
    account,
    spark,
}: UseSparkQualifyingProductsStateParams): {
    isRulesBasedSpark: boolean;
    isRetailerRulesBasedSpark: boolean;
    isCreatingMultiRetailerSpark: boolean;
    isVendorTagsRulesBasedSpark: boolean;
    isVendorPosRulesBasedSpark: boolean;
    updateSparkQualifyingProductsState: (updatedState: SparkQualifyingProductsState) => void;
} => {
    /**
     * We don't have to calculate individual flags for each state because the Wizard only cares about one.
     * So it may be that we just want this one variable that controls these flags and we can initialize
     * and manually control what it is (in the case of switching from manually-managed to rules-based)
     */
    const [sparkQualifyingProductsState, setSparkQualifyingProductsState] =
        useState<SparkQualifyingProductsState>();

    const isCreatingSpark = !spark?._id;
    const isEditingSpark = !!spark?._id;
    const currentAccountIsRetailer = account?.type === 'retailer';
    const currentAccountIsVendor = account?.type === 'brand';
    const accountHasTagsRulesEnabled = !!account?.rulesBasedSparksEnabled;
    const hasPosFilters = !isEmpty(spark?.retailerFilters);
    const isVendorSponsored = !!spark?.originatorGroupId;

    const isRetailerRulesBasedSpark = useMemo(() => {
        if (sparkQualifyingProductsState) {
            return sparkQualifyingProductsState === 'retailerPosRules';
        }

        if (currentAccountIsRetailer) {
            return isEditingSpark ? hasPosFilters && !isVendorSponsored : true;
        }

        return false;
    }, [currentAccountIsRetailer, isEditingSpark, isVendorSponsored, hasPosFilters]);

    const isCreatingMultiRetailerSpark = false;

    const isVendorTagsRulesBasedSpark = false;

    const isVendorPosRulesBasedSpark = useMemo(() => {
        if (sparkQualifyingProductsState) {
            return sparkQualifyingProductsState === 'vendorPosRules';
        }

        // Return true if either of the previous conditions would have been true
        if (currentAccountIsVendor && isCreatingSpark) {
            return true; // This covers both the multiRetailer and vendorTagsRules cases
        }

        if (isVendorSponsored) {
            return true; // This covers the existing vendorTagsRules sponsored case
        }

        return false;
    }, [sparkQualifyingProductsState, currentAccountIsVendor, isVendorSponsored, isCreatingSpark]);

    return {
        isRulesBasedSpark:
            isRetailerRulesBasedSpark || isVendorPosRulesBasedSpark || isVendorTagsRulesBasedSpark,
        isRetailerRulesBasedSpark,
        isCreatingMultiRetailerSpark,
        isVendorTagsRulesBasedSpark,
        isVendorPosRulesBasedSpark,
        updateSparkQualifyingProductsState: setSparkQualifyingProductsState,
    };
};
