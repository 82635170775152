import { FC, ReactElement, useState } from 'react';

import { PlaceholderLogo } from '@features/account-links/views/AccountLinksView/SuggestedLinkTab/BrandLinkCard/BrandLinkCard';

import { TAccountType } from '@app/types/AccountsTypes';

import './LinkLabel.scss';

export interface LinkLabelProps {
    imageUrl: string;
    title: string;
    subtitle?: string | ReactElement;
    variant?: 'base' | 'large';
    accountType?: TAccountType;
}

const LinkLabel: FC<LinkLabelProps> = ({
    imageUrl,
    title,
    subtitle,
    variant = 'base',
    accountType = 'retailer',
}) => {
    const [imgIsBroken, setImgIsBroken] = useState<boolean>(false);

    return (
        <div>
            <div className={`link-label link-label_${variant}`}>
                <div className="link-label-image">
                    {imageUrl && !imgIsBroken ? (
                        <img src={imageUrl} alt={title} onError={() => setImgIsBroken(true)} />
                    ) : (
                        <PlaceholderLogo accountType={accountType} />
                    )}
                </div>
                <div>
                    <div className="link-label-title">{title}</div>
                    <div className="link-label-subtitle">{subtitle}</div>
                </div>
            </div>
        </div>
    );
};

export default LinkLabel;
