import axios from 'axios';

import { SparkTransactionsForUserResponseBody } from '@sparkplug/lib';

export default class TransactionsAPI {
    static async getSparkTransactionsByFlexibleEmployeeId({
        sparkId,
        flexibleEmployeeId,
    }: {
        sparkId: string;
        flexibleEmployeeId: string;
    }): Promise<SparkTransactionsForUserResponseBody> {
        return (
            await axios.get(
                `/api/v1/transactions?spark_id=${sparkId}&flexible_employee_id=${flexibleEmployeeId}`,
            )
        ).data;
    }
}
