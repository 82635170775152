import { useCallback, useEffect, useState } from 'react';

import moment from 'moment';

import { generateAccountLocations } from '@core/accounts';

import IconButton from '@components/buttons/IconButton';
import Chip from '@components/chips/Chip';
import { Edit } from '@components/icons';
import AppView from '@components/layout/AppView';
import EditRetailerLocationModal from '@components/overlays/EditRetailerLocationModal/';
import { useParams } from '@components/router';
import Table from '@components/table/Table';

import {
    useSparkplugAccount,
    useSparkplugAccountUsers,
} from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { THeadCell } from '@app/types/TableTypes';

import './AdminLocationsView.scss';

type LocationTableRow = Awaited<ReturnType<typeof generateAccountLocations>>[number];

const AdminLocationsView = () => {
    const { account, refetchAccountLocations } = useSparkplugAccount();
    const { accountUsers, accountUsersAreReady } = useSparkplugAccountUsers();
    const { locationId }: { locationId?: string } = useParams();
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [currentLocation, setCurrentLocation] = useState<any>();
    const [locations, setLocations] = useState<LocationTableRow[]>([]);
    const [isFetchingLocations, setFetchingLocations] = useState(true);

    const updateLocationList = useCallback(() => {
        setFetchingLocations(true);
        refetchAccountLocations();
    }, [refetchAccountLocations]);

    useEffect(() => {
        if (account != null && accountUsersAreReady) {
            generateAccountLocations(account?.allLocations, accountUsers).then((data) => {
                setFetchingLocations(false);
                setLocations(data);
            });
        }
    }, [account, accountUsersAreReady, accountUsers]);

    useEffect(() => {
        if (locationId != null && locations.filter((obj) => obj != null).length > 0) {
            const location = locations.find(({ _id }) => locationId === _id);
            if (location != null) {
                setCurrentLocation(location);
                setShowAddEditModal(true);
            }
        }
    }, [locations, locationId]);

    const onEdit = (row: any) => {
        setCurrentLocation(row);
        setShowAddEditModal(true);
    };

    const headCells: THeadCell<LocationTableRow>[] = [
        {
            id: 'label',
            sortType: 'string',
            label: 'Location Name',
            render: ({ label }) => <Table.Cell align="left">{label}</Table.Cell>,
        },
        {
            id: 'managerNames',
            sortType: 'string',
            label: 'Manager(s)',
            render: ({ managerNames }) => <Table.Cell align="left">{managerNames}</Table.Cell>,
        },
        {
            id: 'employeeCount',
            sortType: 'numeric',
            label: 'Active Employees',
            render: ({ activePosEmployeeProfiles }) => (
                <Table.Cell align="left">{activePosEmployeeProfiles.length}</Table.Cell>
            ),
        },
        {
            id: 'lookbackDate',
            sortType: 'date',
            label: 'Sales Lookback Date',
            render: ({ lookbackDate }) => (
                <Table.Cell align="left">
                    {lookbackDate ? moment(lookbackDate).format('LL') : 'Unknown'}
                </Table.Cell>
            ),
        },
        {
            id: 'disabled',
            sortType: 'boolean',
            label: 'Status',
            render: ({ disabled }) => (
                <Table.Cell align="left">
                    <Chip
                        color={disabled ? 'neutral' : 'green'}
                        label={disabled ? 'Disabled' : 'Active'}
                    />
                </Table.Cell>
            ),
        },
        {
            id: 'actions',
            label: '',
            render: (row) => (
                <Table.Cell align="right">
                    <IconButton onClick={() => onEdit(row)}>
                        <Edit />
                    </IconButton>
                </Table.Cell>
            ),
        },
    ];

    return (
        <AppView.Content title="Locations" className="admin-locations-view">
            <Table
                variant="raised"
                isLoading={isFetchingLocations}
                headCells={headCells}
                rows={locations}
                defaultOptions={{
                    orderBy: 'name',
                }}
            >
                <Table.RenderHead />
                <Table.RenderBody />
            </Table>

            <EditRetailerLocationModal
                isVisible={showAddEditModal}
                location={currentLocation}
                onClose={(update) => {
                    if (update) {
                        updateLocationList();
                    }
                    setShowAddEditModal(false);
                }}
            />
        </AppView.Content>
    );
};

export default AdminLocationsView;
