import { useCallback } from 'react';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import Paper from '@components/layout/Paper';

import { useApp } from '@hooks/AppHooks';

// import './SetupCompleteView.scss';

const SetupCompleteView = () => {
    const { user, history } = useApp();

    const goToApp = useCallback(() => {
        if (user != null) {
            if (user.accounts.length > 0) {
                history.push(`/${user.accounts[0]._id}/`);
            }
        } else {
            history.push('/login');
        }
    }, [user, history]);

    return (
        <div className="login-view">
            <Paper className="login-container">
                <Paper.Title>Success!</Paper.Title>
                <Form>
                    <Form.Description>
                        <p>Your password has been updated!</p>
                    </Form.Description>
                    <Form.ActionGroup>
                        <Button color="blue" variant="smooth" onClick={goToApp}>
                            {user != null ? 'Go to My Dashboard' : 'Go to Login'}
                        </Button>
                    </Form.ActionGroup>
                </Form>
            </Paper>
        </div>
    );
};

export default SetupCompleteView;
