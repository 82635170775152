import { CalculatorFrequency, TChartDataMetric } from '@sparkplug/lib';

import {
    currencyFormatterFactory,
    numberFormatterFactory,
    percentFormatterFactory,
} from '@helpers/chartFormatters';
import DayBucketFactory from '@helpers/visualization/buckets/DayBucketFactory';
import MonthBucketFactory from '@helpers/visualization/buckets/MonthBucketFactory';
import WeekBucketFactory from '@helpers/visualization/buckets/WeekBucketFactory';
import YearBucketFactory from '@helpers/visualization/buckets/YearBucketFactory';

import { TChartDataPrecision } from '@app/types/ChartDataTypes';

const BUCKET_FACTORIES = [
    DayBucketFactory,
    WeekBucketFactory,
    MonthBucketFactory,
    YearBucketFactory,
] as const;
export type BucketFactory = (typeof BUCKET_FACTORIES)[number];

const BUCKET_FACTORY_RECORD_BY_PRECISION: Record<TChartDataPrecision, BucketFactory> = {
    day: DayBucketFactory,
    week: WeekBucketFactory,
    month: MonthBucketFactory,
    year: YearBucketFactory,
};

const BUCKET_FACTORY_RECORD_BY_FREQUENCY: Record<CalculatorFrequency, BucketFactory> = {
    daily: DayBucketFactory,
    weekly: WeekBucketFactory,
    monthly: MonthBucketFactory,
    yearly: YearBucketFactory,
};

const FORMATTER_RECORD: Record<TChartDataMetric, (value: number) => string> = {
    total_sales: currencyFormatterFactory(0),
    order_average: currencyFormatterFactory(2),
    units_per_transaction: numberFormatterFactory(2),
    percent_of_total_sales: percentFormatterFactory(2),
    total_units: numberFormatterFactory(0),
    transaction_count: numberFormatterFactory(0),
    commission: numberFormatterFactory(0),
    percent_increase: percentFormatterFactory(2),
    guest_check_average: currencyFormatterFactory(2),
};

type ChartFactoriesParams =
    | {
          /**
           * @deprecated moving forward, we should use `frequency` instead of `precision`
           * */
          precision: TChartDataPrecision;
          metric: TChartDataMetric;
          frequency?: undefined;
      }
    | {
          frequency: CalculatorFrequency;
          metric: TChartDataMetric;
          precision?: undefined;
      };
export const useChartFactories = ({
    frequency,
    precision,
    metric,
}: ChartFactoriesParams): {
    chartValueFormatter: (value: number) => string;
    // Maybe we should rename this?
    chartBucketFactory: InstanceType<BucketFactory>;
} => {
    if (frequency) {
        return {
            chartValueFormatter: FORMATTER_RECORD[metric],
            chartBucketFactory: new BUCKET_FACTORY_RECORD_BY_FREQUENCY[frequency](),
        };
    }

    return {
        chartValueFormatter: FORMATTER_RECORD[metric],
        chartBucketFactory: new BUCKET_FACTORY_RECORD_BY_PRECISION[precision](),
    };
};
