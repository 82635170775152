import { FC, ReactElement } from 'react';

import Form from '@components/form/Form';

import './ProductSegment.scss';

interface ProductSegmentProps {
    toolbar: ReactElement;
    required?: boolean;
    error?: boolean;
}

const ProductSegment: FC<ProductSegmentProps> = ({ toolbar, required, error }) => {
    return (
        <>
            <Form.Label required={required} error={error}>
                Product Filters
            </Form.Label>
            <div className="product-segment">{toolbar}</div>
        </>
    );
};

export default ProductSegment;
