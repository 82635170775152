/* eslint-disable no-nested-ternary */
import { useMemo, useState } from 'react';

import { MoreVert as MoreIcon } from '@mui/icons-material';
import moment from 'moment';

import { UIEvent } from '@sparkplug/lib';

import SnapTableCell from '@features/spark-snaps/components/SnapTableCell/SnapTableCell';

import Dropdown from '@components/dropdown/Dropdown';
import Tooltip from '@components/layout/Tooltip';
import ConfirmModal from '@components/overlays/ConfirmModal';
import Table from '@components/table/Table';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { cn } from '@app/componentLibrary/utils';
import { THeadCell } from '@app/types/TableTypes';

import EventStatusChip from '../components/EventStatusChip';
import { useDeclineEventMutation, useDeleteEventMutation } from '../mutations';

export type EventTableRowData = UIEvent;

const ViewMore = ({
    eventId,
    showDecline = false,
    showDelete = false,
}: {
    eventId: string;
    showDecline?: boolean;
    showDelete?: boolean;
}) => {
    const { account } = useSparkplugAccount();
    const { declineEventAsync } = useDeclineEventMutation();
    const { deleteEventAsync } = useDeleteEventMutation();

    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const handleDeclineEvent = async () => {
        await declineEventAsync(eventId);
    };

    const handleDeleteEvent = async () => {
        await deleteEventAsync(eventId);
        setShowDeleteConfirm(false);
    };

    return (
        <>
            <Dropdown className="more-button">
                <Dropdown.IconButton color="neutral">
                    <MoreIcon />
                </Dropdown.IconButton>

                <Dropdown.Menu>
                    <Dropdown.MenuItem>Edit Event</Dropdown.MenuItem>
                    {showDelete && (
                        <Dropdown.MenuItem onClick={() => setShowDeleteConfirm(true)}>
                            Delete Event
                        </Dropdown.MenuItem>
                    )}
                    {showDecline && (
                        <Dropdown.MenuItem onClick={handleDeclineEvent}>
                            Decline Event
                        </Dropdown.MenuItem>
                    )}
                </Dropdown.Menu>
            </Dropdown>
            <ConfirmModal
                isVisible={showDeleteConfirm}
                title="Delete Event?"
                message={
                    <div className="text-base font-inter  leading-5 tracking-wide break-words">
                        <strong className="font-bold">
                            Are you sure you wish to delete this event?
                        </strong>{' '}
                        It will be removed from your view as well as the employees.
                    </div>
                }
                confirmText="Delete"
                confirmBtnColor="red"
                onConfirm={() => {
                    handleDeleteEvent();
                }}
                onClose={() => setShowDeleteConfirm(false)}
            />
        </>
    );
};

const getEventType = (event: UIEvent) => {
    if (event.type === 'popup') return 'Pop-up';
    if (event.type === 'training') return 'Training';
    if (event.type === 'promo') return 'Promo';
    return 'Other';
};

const getEventStatus = (event: UIEvent) => {
    if (new Date(event.endTime) < new Date()) return <EventStatusChip status="complete" />;
    if (new Date(event.startTime) > new Date())
        return <EventStatusChip status="upcoming" altStatus={moment(event.startTime).fromNow()} />;
    return <EventStatusChip status="active" />;
};

const useEventsHeadCell = () => {
    const { account } = useSparkplugAccount();
    const accountType = account?.type ?? 'brand';

    const headCells: THeadCell<EventTableRowData>[] = useMemo(() => {
        return [
            {
                id: 'vendor',
                label: accountType === 'retailer' ? 'Vendor' : 'Brand',
                render: (row) => (
                    <Table.Cell className="message-cell">
                        {accountType === 'retailer' ? row.vendorAccount?.name : row.brand?.name}
                    </Table.Cell>
                ),
            },
            {
                id: 'type',
                label: 'Type',
                render: (row) => (
                    <Table.Cell className="message-cell">{getEventType(row)}</Table.Cell>
                ),
            },
            {
                id: 'location',
                label: 'Location',
                render: (row) => (
                    <Table.Cell className="message-cell">
                        {row.locations.length === 0 ? (
                            'All Locations'
                        ) : row.locations.length === 1 ? (
                            row.locations[0].name
                        ) : (
                            <Tooltip title={row.locations.map((l) => l.name).join(', ')}>
                                <div className="w-5 h-4 flex items-center justify-center text-[10px] text-gray-800 border border-solid border-gray-400 rounded">{` +${
                                    row.locations.length - 1
                                }`}</div>
                            </Tooltip>
                        )}
                    </Table.Cell>
                ),
            },
            {
                id: 'createdAt',
                sortType: 'date',
                label: 'Date(s)',
                render: (row) => (
                    <Table.Cell className="message-cell">
                        {moment(row.startTime).format('MMM D') !==
                        moment(row.endTime).format('MMM D')
                            ? `${moment(row.startTime).format('MMM D')} - ${moment(
                                  row.endTime,
                              ).format('D YYYY')}`
                            : moment(row.startTime).format('MMM D YYYY')}
                    </Table.Cell>
                ),
            },
            {
                id: 'time',
                label: 'Time',
                render: (row) => (
                    <Table.Cell className="message-cell">
                        {moment(row.startTime).format('MMM D') !==
                        moment(row.endTime).format('MMM D')
                            ? `${moment(row.endTime).diff(moment(row.startTime), 'days')} days`
                            : `${moment(row.startTime).format('h:mm A')} - ${moment(
                                  row.endTime,
                              ).format('h:mm A')}`}
                    </Table.Cell>
                ),
            },
            {
                id: 'name',
                sortType: 'string',
                label: 'Name',
                render: (row) => <Table.Cell className="message-cell">{row.name}</Table.Cell>,
            },
            {
                id: 'description',
                label: 'Description',
                render: (row) => (
                    <Table.Cell className="message-cell">{row.description}</Table.Cell>
                ),
            },
        ];
    }, [accountType]);

    const stickyLeftCells: THeadCell<EventTableRowData>[] = useMemo(() => {
        return [
            {
                id: 'creator',
                label: accountType === 'retailer' ? 'Event Creator' : 'Retailer',
                sortType: 'string',
                render: (row) => (
                    <Table.Cell className={cn('no-wrap', '[&>div]:pr-4')}>
                        <SnapTableCell
                            name={
                                accountType === 'retailer'
                                    ? row.brand?.name ?? ''
                                    : row.retailerAccount.name
                            }
                            iconUrl={row.brand?.photo}
                        />
                        <span>{getEventStatus(row)}</span>
                    </Table.Cell>
                ),
            },
        ];
    }, [accountType]);

    const stickyRightCells: THeadCell<EventTableRowData>[] = useMemo(() => {
        return [
            {
                id: 'action',
                columnManagerLabel: 'action',
                sticky: 'right',
                render: (row) => {
                    return (
                        <Table.Cell align="left">
                            <ViewMore
                                eventId={row._id}
                                showDecline={
                                    accountType === 'retailer' && new Date(row.endTime) > new Date()
                                }
                                showDelete={
                                    (account?._id === row.retailerAccount._id &&
                                        !row.vendorAccount?._id) ||
                                    account?._id === row.vendorAccount?._id
                                }
                            />
                        </Table.Cell>
                    );
                },
            },
        ];
    }, []);

    return { headCells, stickyLeftCells, stickyRightCells };
};

export default useEventsHeadCell;
