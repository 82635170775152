import { useMemo } from 'react';

import { keyBy } from 'lodash';

import {
    DetailedSparkType,
    FullChartResponseBody,
    GetChartResponseBody,
    Spark,
    sortSparkParticipants,
} from '@sparkplug/lib';

import { filterAccountUsersByPosEmployeeProfileIds, useAccountUsersQuery } from '@core/users';

import {
    transformBucketsToChartLeaders,
    transformCloudChartDataToChartLeaders,
} from '@hooks/ChartDataHooks/useChartStandings';

import { addUserDataToChartDataLeaders, getChartFactories } from '@helpers/charts';
import { getDetailedSparkType } from '@helpers/sparks';
import { sortByString } from '@helpers/ui';

import { ChartLeader, IChartDataSettings } from '@app/types/ChartDataTypes';
import { IPosLocation } from '@app/types/PosTypes';
import { ArchivedSparkPosData, SparkParticipant } from '@app/types/SparksTypes';
import { IAccountUser } from '@app/types/UsersTypes';

import { useSparkCloudChartData } from './useSparkCloudChartData';
import { useSparkPosData } from './useSparkPosData';

const getChartLeaderParticipatingLocationNames = (
    locationIds: string[],
    locationMap: { [locationId: string]: IPosLocation },
): string => {
    return locationIds
        .map((locationId) => locationMap[locationId]?.label ?? '')
        .filter((locationName) => locationName)
        .join(', ');
};

export const getAllSparkLeaders = ({
    sparkIsArchived,
    spark,
    sparkPosData,
    chartDataLeaders = [],
    accountUsers,
}: {
    sparkIsArchived: boolean;
    spark: Spark;
    sparkPosData: {
        participants: SparkParticipant[];
        locations: IPosLocation[];
    };
    chartDataLeaders?: ChartLeader[];
    accountUsers: IAccountUser[];
}) => {
    const flexibleEmployeeIdsWithSales = (chartDataLeaders || []).map(
        ({ flexibleEmployeeId }) => flexibleEmployeeId,
    );

    const sparkParticipantAccountUsers = filterAccountUsersByPosEmployeeProfileIds(
        accountUsers,
        spark.posEmployeeProfileIds,
    );

    const accountUsersMap = keyBy(accountUsers, 'flexibleEmployeeId');

    const sparkParticipantsWithNoSales: Pick<
        IAccountUser,
        'flexibleEmployeeId' | 'locationIds' | 'label' | 'fullName' | 'smsStatus'
    >[] = !sparkIsArchived
        ? sparkParticipantAccountUsers.filter(({ flexibleEmployeeId }) => {
              return !flexibleEmployeeIdsWithSales.includes(flexibleEmployeeId);
          })
        : sparkPosData.participants
              .filter(({ flexibleEmployeeId }) => {
                  return !flexibleEmployeeIdsWithSales.includes(flexibleEmployeeId);
              })
              .map(({ flexibleEmployeeId, locationIds, label = '' }) => {
                  const accountUserData = accountUsersMap[flexibleEmployeeId];

                  return {
                      flexibleEmployeeId,
                      locationIds,
                      label,
                      fullName: label,
                      smsStatus: accountUserData?.smsStatus,
                      firstTransactionDateByLocation:
                          accountUserData?.firstTransactionDateByLocation,
                  };
              });

    const locationMap = keyBy(sparkPosData.locations, '_id');

    const noSalesChartDataLeaders: ChartLeader[] = sparkParticipantsWithNoSales
        .map((accountUser) => ({
            flexibleEmployeeId: accountUser.flexibleEmployeeId,
            locations:
                getChartLeaderParticipatingLocationNames(
                    accountUser.locationIds ?? [],
                    locationMap,
                ) ?? 'N/A',
            name: accountUser?.label ?? accountUser?.fullName,
            transactionCount: 0,
            value: 0,
            total: 0,
            ...(spark.type === 'commission'
                ? {
                      unitCount: 0,
                  }
                : {}),
            smsStatus: accountUser.smsStatus,
        }))
        .sort(sortByString('name', 'asc'));

    const sortedParticipants = [
        ...(sortSparkParticipants({
            sparkParticipants: chartDataLeaders,
            minTransactionCount: spark.minimumTransactionsToQualify ?? 0,
            minThreshold: spark.minimumThresholdToQualify ?? 0,
        }) as ChartLeader[]),
        ...noSalesChartDataLeaders,
    ];

    return addUserDataToChartDataLeaders(sortedParticipants, accountUsers);
};

const transformCloudChartDataToSparkLeaders = ({
    spark,
    detailedSparkType,
    settings,
    cloudChartData,
    posData: { locations, participants, nonParticipantsWithSales },
    accountUsers,
    includeChartDisplayData = true,
}: {
    spark: Spark;
    detailedSparkType: DetailedSparkType;
    settings: IChartDataSettings;
    cloudChartData: GetChartResponseBody;
    posData: {
        participants: SparkParticipant[];
        locations: IPosLocation[];
        nonParticipantsWithSales: SparkParticipant[];
    };
    accountUsers: IAccountUser[];
    includeChartDisplayData?: boolean;
}): ChartLeader[] => {
    const {
        total,
        locationBuckets = {},
        locationCumulativeBuckets,
        locationTotals = {},
    } = cloudChartData as FullChartResponseBody;

    const relevantLocationBuckets =
        settings.type === 'line' && locationCumulativeBuckets
            ? locationCumulativeBuckets
            : locationBuckets;

    const { bucketFactory, chartFactory } = getChartFactories({ settings });

    if (['goalTeam', 'goalManager'].includes(detailedSparkType)) {
        const locationCount = settings.locationIds?.length ?? 0;

        let locationLabel = 'All Locations';
        if (locationCount === 1) {
            const [locationId] = settings.locationIds;
            const location = locations.find(({ _id }) => locationId === _id);

            if (location) {
                locationLabel = location.name;
            }
        }

        return [
            { flexibleEmployeeId: locationLabel, locations: '', name: locationLabel, value: total },
        ];
    }

    if (detailedSparkType === 'leaderboardLocation') {
        return transformBucketsToChartLeaders({
            chartMetric: settings.metric,
            chartType: settings.type,
            objectBuckets: relevantLocationBuckets,
            objectsKeyedById: keyBy(
                locations.map(({ _id, label }) => ({ label, _id })),
                '_id',
            ),
            primaryValues: locationTotals,
            bucketFactory,
            chartFactory,
            leaderType: 'location',
            includeChartDisplayData,
        });
    }

    const sparkIsArchived = !!spark.archivedAt;
    const participantStandingsWithSales = transformCloudChartDataToChartLeaders({
        settings,
        cloudChartData: cloudChartData ?? ({} as GetChartResponseBody),
        posData: {
            accountPosLocations: locations,
            accountUsers: (sparkIsArchived
                ? [...participants, ...nonParticipantsWithSales]
                : accountUsers
            ).map((sparkParticipant) => ({
                ...sparkParticipant,
                value: sparkParticipant.flexibleEmployeeId,
                label: sparkParticipant.fullName,
            })),
        },
        includeChartDisplayData,
    });

    return getAllSparkLeaders({
        sparkIsArchived,
        spark,
        sparkPosData: { participants, locations },
        chartDataLeaders: participantStandingsWithSales,
        accountUsers,
    });
};

export const useSparkStandings = ({
    spark,
    chartSettings,
    isEnabled = true,
    includeChartDisplayData = true,
}: {
    spark: Spark;
    chartSettings: IChartDataSettings;
    isEnabled?: boolean;
    includeChartDisplayData?: boolean;
}) => {
    const detailedSparkType = getDetailedSparkType(spark) ?? 'leaderboard';

    const { isLoading, data: cloudChartData } = useSparkCloudChartData(spark, isEnabled);
    const { sparkPosDataIsReady, sparkPosData } = useSparkPosData({ spark, isEnabled });
    const { accountUsersAreReady, accountUsers } = useAccountUsersQuery(spark.groupId, true);

    const sparkStandings = useMemo(() => {
        if (!cloudChartData || !sparkPosData) {
            return [];
        }

        return transformCloudChartDataToSparkLeaders({
            spark,
            detailedSparkType,
            settings: chartSettings,
            cloudChartData,
            posData: {
                participants: sparkPosData.participants,
                locations: sparkPosData.locations,
                nonParticipantsWithSales:
                    (sparkPosData as ArchivedSparkPosData).nonParticipantsWithSales ?? [],
            },
            accountUsers,
            includeChartDisplayData,
        });
    }, [cloudChartData, sparkPosData, spark, detailedSparkType, chartSettings, accountUsers]);

    const flexibleEmployeeIdsWithSales = useMemo(() => {
        const { commissions = {}, employeeTotals = {} } = cloudChartData ?? {};
        return spark.type === 'commission'
            ? Object.entries(commissions)
                  .filter(([_, { totalQuantity }]) => !!totalQuantity)
                  .map(([flexibleEmployeeId]) => flexibleEmployeeId)
            : Object.entries(employeeTotals)
                  .filter(([_, value]) => !!value)
                  .map(([flexibleEmployeeId]) => flexibleEmployeeId);
    }, [cloudChartData, spark.type]);

    return {
        sparkStandingsAreReady: !isLoading && sparkPosDataIsReady && accountUsersAreReady,
        sparkStandings,
        flexibleEmployeeIdsWithSales,
    };
};
